import type { CalendarEventTemplate } from './calendar-event-templates';
import type { EditableZoomHostFilter, RoomFilter, ZoomHostFilter } from './schedule-templates';

export enum HiringMeetingAttendeeFilterExpressionFilterableType {
  HiringTeam = 'hiring_team',
  Interviewers = 'interviewers',
  User = 'user',
}

export enum HiringMeetingAttendeeFilterExpressionInterviewersFilterableId {
  CurrentStage = 'current_stage',
  PreviousStages = 'previous_stages',
}

export interface HiringMeetingTemplate {
  id: string;
  account_id: string;
  name: string;
  duration_minutes: number;
  video_conferencing_enabled: boolean;
  zoom_host_filters: ZoomHostFilter[] | null;
  room_filters: RoomFilter[] | null;
  calendar_event_template_id: string;
  calendar_event_template: CalendarEventTemplate;
  hiring_meeting_attendee_filters: HiringMeetingAttendeeFilter[] | null;
  linked_resources: number;
  inline: boolean;
  favorite: boolean;
  created_at: string;
  updated_at: string;
  archived: boolean;
}

export interface HiringMeetingAttendeeFilter {
  id: string;
  hiring_meeting_template_id: string;
  position: number;
  hiring_meeting_attendee_filter_expressions: HiringMeetingAttendeeFilterExpression[];
  created_at: string;
}

export interface HiringMeetingAttendeeFilterExpression {
  id: string;
  hiring_meeting_attendee_filter_id: string;
  negated: boolean;
  filterable_id: string;
  filterable_type: HiringMeetingAttendeeFilterExpressionFilterableType;
  created_at: string;
}

export type EditableHiringMeetingTemplate = Omit<
  HiringMeetingTemplate,
  | 'id'
  | 'account_id'
  | 'zoom_host_filters'
  | 'hiring_meeting_attendee_filters'
  | 'linked_resources'
  | 'inline'
  | 'favorite'
  | 'created_at'
  | 'updated_at'
  | 'archived'
> & {
  zoom_host_filters: EditableZoomHostFilter[] | null;
  hiring_meeting_attendee_filters: EditableHiringMeetingAttendeeFilter[] | null;
};

export type EditableHiringMeetingAttendeeFilter = Omit<
  HiringMeetingAttendeeFilter,
  | 'id'
  | 'hiring_meeting_template_id'
  | 'position'
  | 'hiring_meeting_attendee_filter_expressions'
  | 'created_at'
> & {
  hiring_meeting_attendee_filter_expressions: EditableHiringMeetingAttendeeFilterExpression[];
};

export type EditableHiringMeetingAttendeeFilterExpression = Omit<
  HiringMeetingAttendeeFilterExpression,
  | 'id'
  | 'hiring_meeting_attendee_filter_id'
  | 'created_at'
>;
