import { capitalize, find } from 'lodash';
import { useMemo } from 'react';

import Tag from '../../data-display/Tag';
import Tooltip from '../../utils/Tooltip';
import type { User } from '../../../../types';
import { HiringTeamRole } from '../../../../types';
import { StyledSelectInput } from './styles';
import { useSession } from '../../../../hooks/use-session';
import { useUsers } from '../../../../hooks/queries/users';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Group, Option } from '../SelectInput/types';

const hiringTeamRoles: string[] = [
  HiringTeamRole.Coordinator,
  HiringTeamRole.Recruiter,
  HiringTeamRole.HiringManager,
  HiringTeamRole.Sourcer,
];

enum groupLabel {
  HiringTeam = 'Hiring Team',
  Users = 'Users',
}

interface Props {
  className?: string;
  getUserDisabledTooltipMessage?: (user: User) => string | undefined;
  getUserIsDisabled?: (user: User) => boolean;
  isDisabled?: boolean;
  onChange?: (newValue: OnChangeValue<Option<string>, false>, actionMeta: ActionMeta<Option<string>>) => void;
  value?: string;
}

const AutomaticRequesterSelectInput = ({
  className,
  getUserDisabledTooltipMessage,
  getUserIsDisabled,
  isDisabled = false,
  onChange,
  value,
}: Props) => {
  const { account, currentUser } = useSession();

  const { data: users } = useUsers({ archived: true });

  const options = useMemo<Group<string, Option<string>>[]>(() => {
    if (!users) {
      return [];
    }

    return [
      {
        label: groupLabel.HiringTeam,
        options: hiringTeamRoles.map((role) => ({
          value: role,
          label: role,
        })),
      },
      {
        label: groupLabel.Users,
        options: users.users
        .filter(({ directory_archived, user_archived }) => !user_archived && !directory_archived)
        .map((user) => {
          const canSendEmail = Boolean(account?.email_domain || user.gem_can_send_email);
          const isDisabled = !user.ats_id || !canSendEmail || getUserIsDisabled?.(user);

          return {
            value: user.id,
            label: user.name || user.email,
            isDisabled,
            tooltip: isDisabled ? (
              <Tooltip
                id={`automatic-requester-select-input-tooltip-${user.id}`}
                position="top"
                value={
                  !user.ats_id ?
                    `${user.name || user.email} doesn't have an account in ${capitalize(account?.ats_type)}, so they can't be the requester.` :
                    (!canSendEmail ?
                      `${user.name || user.email} has not given email access to Gem, so they can't be the requester.` :
                      getUserDisabledTooltipMessage?.(user) || '')
                }
              />
            ) : undefined,
          };
        })
        .sort((a, b) => {
          // Move disabled users to the bottom.
          if (a.isDisabled) {
            return 1;
          }
          if (b.isDisabled) {
            return -1;
          }
          // Move the current user to the top.
          if (a.value === currentUser?.id) {
            return -1;
          }
          if (b.value === currentUser?.id) {
            return 1;
          }
          // Otherwise, sort by name.
          return a.label < a.label ? -1 : 1;
        }),
      },
    ];
  }, [currentUser?.id, users, account?.email_domain]);

  const selectedOption = useMemo(() => {
    if (!value) {
      return;
    }
    const label = hiringTeamRoles.includes(value) ? groupLabel.HiringTeam : groupLabel.Users;
    const group = find(options, ['label', label]);
    return find(group?.options, ['value', value]);
  }, [options, value]);

  return (
    <div className={className}>
      <StyledSelectInput
        formatOptionLabel={(option) => (
          <div
            data-for={option.tooltip ? option.tooltip.props.id : undefined}
            data-tip={option.tooltip ? true : undefined}
          >
            <Tag
              annotateCurrentUser
              type={hiringTeamRoles.includes(option.value) ? 'hiring_team' : 'user'}
              value={option.value}
            />
            {option.tooltip}
          </div>
        )}
        helperText={<>Who the request will come from. This will populate the <b>Requester</b> token in the email.</>}
        isDisabled={isDisabled}
        isRequired
        label="Requester"
        onChange={onChange}
        options={options}
        value={selectedOption}
      />
    </div>
  );
};

export default AutomaticRequesterSelectInput;
