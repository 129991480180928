import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft, faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { useCallback } from 'react';

import Avatar from 'components/library/data-display/Avatar';
import {
  StyledCardContainer,
  StyledDivider,
  StyledSection,
  StyledCard,
  StyledQuote,
  StyledSource,
  StyledSourceRole,
  StyledArrowButton,
} from './styles';
import TESTIMONIALS from './data';

import type { ReactNode } from 'react';

const TestimonialSection = () => {
  const renderArrowPrev = useCallback((onClickHandler: () => void, hasPrev: boolean, label: string): ReactNode => {
    return (
      <StyledArrowButton
        aria-label={label}
        onClick={() => {
          analytics.track('Testimonial Arrow Button Clicked', { type: 'prev' });
          onClickHandler();
        }}
        type="button"
      >
        <FontAwesomeIcon icon={faChevronLeft} />
      </StyledArrowButton>
    );
  }, []);

  const renderArrowNext = useCallback((onClickHandler: () => void, hasNext: boolean, label: string): ReactNode => {
    return (
      <StyledArrowButton
        aria-label={label}
        onClick={() => {
          analytics.track('Testimonial Arrow Button Clicked', { type: 'next' });
          onClickHandler();
        }}
        type="button"
      >
        <FontAwesomeIcon icon={faChevronRight} />
      </StyledArrowButton>
    );
  }, []);

  return (
    <>
      <StyledDivider />
      <StyledSection id="testimonials">
        <h2>
          Here&apos;s what others think.
        </h2>
        <Carousel
          autoFocus={false}
          autoPlay
          centerMode
          centerSlidePercentage={100}
          infiniteLoop
          interval={60000} // 1 minute
          labels={{
            item: 'Testimonial',
            leftArrow: 'Previous testimonial',
            rightArrow: 'Next testimonial',
          }}
          renderArrowNext={renderArrowNext}
          renderArrowPrev={renderArrowPrev}
          showIndicators={false}
          showStatus={false}
          showThumbs={false}
        >
          {TESTIMONIALS.map((testimonial) => (
            <StyledCardContainer key={testimonial.name}>
              <StyledCard
                key={testimonial.name}
              >
                <StyledQuote>
                  {testimonial.quote}
                </StyledQuote>
                <StyledSource>
                  <Avatar
                    alt={testimonial.name}
                    img={testimonial.photo}
                    size="large"
                  />
                  <div>
                    {testimonial.name}
                    <StyledSourceRole>
                      <span>
                        {testimonial.role}
                        {testimonial.company && <>, {testimonial.company.name}</>}
                      </span>
                      {testimonial.company && (
                        <Avatar
                          img={testimonial.company.logo}
                          size="small"
                          type="company"
                        />
                      )}
                    </StyledSourceRole>
                  </div>
                </StyledSource>
              </StyledCard>
            </StyledCardContainer>
          ))}
        </Carousel>
      </StyledSection>
    </>
  );
};

export default TestimonialSection;
