import Image from './Image';
import Token from './Token';
import { ElementType } from '../../../../types';

import type { EditorType, TokensResponse } from '../../../../types';
import type { RenderElementProps } from 'slate-react';

interface Props extends RenderElementProps {
  pendingPreviewMessage: string;
  tokens?: TokensResponse;
  type: `${EditorType}`;
}

const Element = (props: Props) => {
  const { attributes, children, element } = props;

  switch (element.type) {
    case ElementType.Image:
      return <Image {...props}>{children}</Image>;
    case ElementType.Link:
      return <a {...attributes} href={element.url}>{children}</a>;
    case ElementType.ListItem:
      return <li {...attributes}>{children}</li>;
    case ElementType.OrderedList:
      return <ol {...attributes}>{children}</ol>;
    case ElementType.Token:
      return <Token {...props}>{children}</Token>;
    case ElementType.UnorderedList:
      return <ul {...attributes}>{children}</ul>;
    default:
      return <div {...attributes}>{children}</div>;
  }
};

export default Element;
