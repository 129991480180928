import Moment from 'moment-timezone';
import { useMemo } from 'react';

import Avatar from '../../../../library/data-display/Avatar';
import ListItem from '../../../../library/data-display/ListItem';
import Table from '../../../../library/data-display/Table';
import { StyledInterviewTablePhase, StyledTableContainer } from '../../TrainingProgramList/styles';
import { formatMoment, TimeFormat } from '../../../../../libraries/time';
import { useUsersMap } from '../../../../../hooks/queries/users';

import type { TableSchema } from '../../../../library/data-display/Table';
import type { TrainingSession } from '../../../../../types';
import { correctPath } from 'libraries/gem';

const getLinkToSchedule = ({ interviewer }: TrainingSession) => interviewer?.interview.schedule.application ? correctPath(`/app/candidates/${interviewer?.interview.schedule.application.id}/schedules/${interviewer?.interview.schedule.id}`) : undefined;

interface Props {
  onPageNumberChange: (pageNumber: number) => void;
  pageNumber: number;
  totalCount: number;
  trainingSessions: TrainingSession[];
}

const TrainingProgramInterviewHistoryTable = ({
  onPageNumberChange,
  pageNumber,
  totalCount,
  trainingSessions,
}: Props) => {
  const users = useUsersMap({ archived: true });

  // These are all non-manual sessions that are guaranteed to have an interviewer.
  const columns = useMemo<TableSchema<TrainingSession>>(() => [{
    header: 'Interview',
    displayValue: ({ interviewer }) => interviewer?.interview.name,
  }, {
    header: 'Job',
    displayValue: ({ interviewer }) => interviewer?.interview.schedule.stage.job.name,
  }, {
    header: 'Candidate',
    displayValue: ({ interviewer }) => interviewer?.interview.schedule.application?.candidate.name || <span className="redacted">Redacted</span>,
  }, {
    header: 'Trainee',
    displayValue: ({ user_id, interviewer }) => (
      <ListItem
        className={interviewer?.interview.schedule.application ? undefined : 'extra-padding'}
        label={users[user_id]?.name}
        leftIcon={<Avatar
          showUserTooltip={false}
          size="small"
          type="user"
          userId={user_id}
        />}
      />
    ),
  }, {
    header: 'Phase At Interview Time',
    displayValue: ({ phase_name, interviewer }) => {
      const isCompleted = !Moment(interviewer?.interview.start_time).isAfter(Moment());

      return (
        <StyledInterviewTablePhase>
          {!isCompleted ?
            <>
              To be determined
              <div className="prediction">Prediction: {phase_name}</div>
            </> :
            phase_name
          }
        </StyledInterviewTablePhase>
      );
    },
  }, {
    header: 'Date',
    displayValue: ({ interviewer }) => formatMoment(Moment(interviewer?.interview.start_time), TimeFormat.LongMonthDayYear),
  }], [users]);

  return (
    <StyledTableContainer>
      <Table
        className="training-program-interview-history-table"
        data={trainingSessions}
        getRowLink={getLinkToSchedule}
        isPaginated
        layout="vertical"
        onPageNumberChange={onPageNumberChange}
        pageNumber={pageNumber}
        schema={columns}
        totalCount={totalCount}
      />
    </StyledTableContainer>
  );
};

export default TrainingProgramInterviewHistoryTable;
