import type { ReactNode } from 'react';

interface Testimonial {
  name: string;
  role: string;
  photo?: string;
  company?: {
    name: string;
    logo: string;
  };
  quote: string | ReactNode;
}

const TESTIMONIALS: Testimonial[] = [{
  name: 'Paulina Srey',
  role: 'Talent Acquisition Coordinator',
  photo: '/scheduling/src/assets/images/paulina-srey.png',
  /*
  company: {
    name: 'QuantCo',
    logo: 'https://logo.clearbit.com/quantco.com',
  },
  */
  quote: <>InterviewPlanner is super intuitive to use. It has proven to be absolutely crucial in scaling our company during a high-growth phase. We <em>doubled the interviews</em> scheduled from the year prior. The team is so receptive to feature requests; it&apos;s frankly the best customer service I&apos;ve ever received. Plus, our engineering team loves InterviewPlanner! That&apos;s rare praise for a recruiting tool.</>,
}, {
  name: 'Sara Ali',
  role: 'Senior Technical Recruiter',
  photo: '/scheduling/src/assets/images/sara-ali.jpeg',
  quote: <>As my company&apos;s recruiting coordinator, sourcer, and recruiter, InterviewPlanner makes my scheduling workflows so much easier. I spend <em>exponentially less time</em> going back and forth with candidates as things get scheduled super quickly. Working with the InterviewPlanner team is <em>such a joy</em>. They are willing to step in and help when needed, and they have already released several new product features just based off of my team&apos;s feedback.</>,
}, {
  name: 'Viola Schmitz',
  role: 'Pipeline & Recruitment Analytics Manager',
  photo: '/scheduling/src/assets/images/viola-schmitz.jpeg',
  quote: <>InterviewPlanner has been a <em>game-changer</em> for our scheduling flow. Automated self-scheduling, interviewer capacity limitations, global working hour adjustments, and super neat and straightforward calendar descriptions have helped tremendously to streamline our recruitment processes. We love the simplicity of the tool and the hands-on customer support that improves InterviewPlanner at any time we give them feedback.</>,
}, {
  name: 'Maria Murage',
  role: 'Senior Talent Operations Coordinator',
  photo: '/scheduling/src/assets/images/maria-murage.jpeg',
  quote: <>Scheduling onsites has become so much easier now! It literally takes me <em>less than a minute</em> to schedule a full onsite loop instead of 10-15 minutes. I&apos;m so happy that I&apos;m having fun doing it.</>,
}, {
  name: 'Jamey Iaccino',
  role: 'Recruiting and People Operations Leader',
  photo: '/scheduling/src/assets/images/jamey-iaccino.png',
  quote: <>Automation and efficiency are at the core of every <em>great candidate experience</em>. InterviewPlanner supercharges your team&apos;s capabilities in the flip of a switch.</>,
}, {
  name: 'Alex King',
  role: 'Executive Assistant to CEOs',
  photo: '/scheduling/src/assets/images/alex-king.png',
  quote: <>Without hesitation, I would recommend InterviewPlanner to anyone seeking a scheduling tool. It&apos;s not just about the <em>outstanding product</em>; their customer success team is genuinely unparalleled in their dedication and expertise. They have addressed all my inquiries and swiftly implemented <em>tailor-made adjustments</em> for our team. It&apos;s remarkable how they were able to make same-day modifications to enhance our candidate experience!</>,
}, {
  name: 'Sierra Hebert',
  role: 'Recruiting Operations Specialist',
  photo: '/scheduling/src/assets/images/sierra-hebert.jpeg',
  quote: <>The InterviewPlanner team took the time to ensure I understood how to use their product and <em>customized their training to my specific recruiting needs</em>. What really stood out to me was how effectively and efficiently they responded to my questions.</>,
}, {
  name: 'Mika Maksimovic',
  role: 'Recruitment Operations',
  photo: '/scheduling/src/assets/images/mika-maksimovic.jpeg',
  quote: <>InterviewPlanner is a <em>fantastic tool</em> that simplifies what could otherwise be a tedious process. The team incorporates the user&apos;s needs and desires into new features and are <em>always on hand to assist</em> and provide troubleshooting support. Bonus points for being super nice to work with too!</>,
}];

export default TESTIMONIALS;
