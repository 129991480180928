import { useMemo } from 'react';

import Tag from './Tag';
import { InterviewerFilterableType } from '../../../types';
import { useEligibilities } from '../../../hooks/queries/users';

export interface Props {
  filters: {
    id?: string;
    interviewer_filter_expressions: {
      id?: string;
      negated: boolean;
      filterable_type: `${InterviewerFilterableType}`;
      filterable_id: string;
    }[] | null;
  }[];
}

const Filters = ({ filters }: Props) => {
  const { data: eligibilities } = useEligibilities();

  const eligibilitiesMap = useMemo<Record<string, boolean>>(() => {
    return (eligibilities || []).reduce<Record<string, boolean>>((acc, eligibility) => {
      return ({ ...acc, [`${eligibility.id}:${eligibility.trainee || false}`]: true });
    }, {});
  }, [eligibilities]);

  return (
    <div className="filters">
      {filters.map((filter, i) => (
        <div className="filter-container" key={`filter-${filter.id || i}`}>
          {i > 0 &&
            <div className="filter-separator">
              or
            </div>
          }
          <div className="filter">
            {(filter.interviewer_filter_expressions || []).map((expression) => (
              <Tag
                hasTrainingProgram={(
                  expression.filterable_type === InterviewerFilterableType.Training ||
                  (expression.filterable_type === InterviewerFilterableType.Eligibility && eligibilitiesMap[`${expression.filterable_id}:true`])
                )}
                isNegated={expression.negated}
                isTrainee={expression.filterable_type === InterviewerFilterableType.Training}
                key={`interviewer-filter-expression-${expression.id || i}`}
                type={expression.filterable_type}
                value={expression.filterable_id}
              />
            ))}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Filters;
