import { Slate } from 'slate-react';

import Editor from './Editor';

import type { CustomEditor } from '../../../../types';
import type { Descendant } from 'slate';
import type { Props as EditorProps } from './Editor';

interface Props extends EditorProps {
  editor: CustomEditor;
}

const EditorInput = (props: Props) => {
  const { editor, setValue, value } = props;

  const handleChange = (newValue: Descendant[]) => {
    if (setValue) {
      setValue(newValue);
    }
  };

  return (
    <Slate
      editor={editor}
      onChange={handleChange}
      value={value}
    >
      <Editor {...props} />
    </Slate>
  );
};

export default EditorInput;
