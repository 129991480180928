import { Route } from 'react-router-dom';
import { useEffect } from 'react';
import { useQueryClient } from 'react-query';

import LaunchDarklyIdentifier from 'components/library/utils/LaunchDarklyProvider/LaunchDarklyIdentifier';
import { useSession } from '../../../hooks/use-session';
import { usersParams } from '../../../hooks/queries/users';

import type { RouteProps } from 'react-router-dom';

const SecureRoute = (props: RouteProps) => {
  const queryClient = useQueryClient();

  const {
    isSessionLoading,
    session,
  } = useSession();

  useEffect(() => {
    queryClient.prefetchQuery(usersParams({ archived: true }));
  });

  useEffect(() => {
    if (!isSessionLoading && !session) {
      const redirectUri = window.location.pathname + window.location.search;
      window.location.href = `/login?to=${encodeURIComponent(redirectUri)}`;
    }
  }, [isSessionLoading, session]);

  if (isSessionLoading || !session) {
    return null;
  }

  return (
    <LaunchDarklyIdentifier>
      <Route {...props} />
    </LaunchDarklyIdentifier>
  );
};

export default SecureRoute;
