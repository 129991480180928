import { faArchive, faArrowUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from './Button';
import Tooltip from '../utils/Tooltip';

import type { MouseEventHandler } from 'react';

interface Props {
  id: string;
  isArchived: boolean;
  onArchive?: MouseEventHandler<HTMLButtonElement>;
  onUnarchive?: MouseEventHandler<HTMLButtonElement>;
  size?: 'small' | 'large';
}

const ArchiveButton = ({ id, isArchived, onArchive, onUnarchive, size }: Props) => (
  <Button
    className={`btn-archive${isArchived ? ' btn-unarchive-icon' : ''}`}
    color="gray"
    iconRight={
      <>
        {isArchived && <FontAwesomeIcon className="icon-arrow" icon={faArrowUp} />}
        <FontAwesomeIcon icon={faArchive} />
      </>
    }
    onClick={isArchived ? onUnarchive : onArchive}
    size={size}
    tooltip={
      <Tooltip
        id={`${id}-archive-button`}
        position="top"
        value={isArchived ? 'Unarchive' : 'Archive'}
      />
    }
  />
);

export default ArchiveButton;
