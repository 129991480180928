import BusinessHoursCheckboxInput from './BusinessHoursCheckboxInput';
import { allDays } from '../../../../types/business-hours';
import { StyledHelperText, StyledLabel, StyledTableContainer } from './styles';

import type { Dispatch, ReactNode, SetStateAction } from 'react';
import type { EditableBusinessHour } from '../../../../types/business-hours';

interface Props {
  businessHours: EditableBusinessHour[];
  className?: string;
  label?: ReactNode;
  helperText?: ReactNode;
  isDisabled?: boolean;
  isTimezoneClearable?: boolean;
  setBusinessHours: Dispatch<SetStateAction<EditableBusinessHour[]>>;
}

const BusinessHoursInput = ({
  businessHours,
  className,
  label,
  helperText,
  isDisabled = false,
  isTimezoneClearable = false,
  setBusinessHours,
}: Props) => {
  return (
    <div className={className}>
      {label && <StyledLabel>{label}</StyledLabel>}
      <StyledTableContainer>
        {allDays.map((day) => (
          <BusinessHoursCheckboxInput
            businessHours={businessHours}
            day={day}
            isDisabled={isDisabled}
            isTimezoneClearable={isTimezoneClearable}
            key={day}
            setBusinessHours={setBusinessHours}
          />
        ))}
      </StyledTableContainer>
      {helperText && <StyledHelperText>{helperText}</StyledHelperText>}
    </div>
  );
};

export default BusinessHoursInput;
