import { useMutation } from 'react-query';

import InterviewPlanner from '../../libraries/interviewplanner';

import type { TrainingOverride } from '../../types';

export interface CreateTrainingOverridePayload {
  user_id: string;
  training_phase_id: string;
  number_of_interviews: number;
}

interface CreateTrainingOverrideMutationVariables {
  trainingProgramId: string;
  payload: CreateTrainingOverridePayload;
}

export const useCreateTrainingOverride = () => {
  return useMutation<TrainingOverride, Error, CreateTrainingOverrideMutationVariables>(({ trainingProgramId, payload }) => {
    return InterviewPlanner.request('POST', `/training-programs/${trainingProgramId}/overrides`, payload, null, false);
  });
};

export interface UpdateTrainingOverridePayload {
  number_of_interviews?: number;
}

interface UpdateTrainingOverrideMutationVariables {
  trainingProgramId: string;
  id: string;
  payload: UpdateTrainingOverridePayload;
}

export const useUpdateTrainingOverride = () => {
  return useMutation<TrainingOverride, Error, UpdateTrainingOverrideMutationVariables>(({ trainingProgramId, id, payload }) => {
    return InterviewPlanner.request('POST', `/training-programs/${trainingProgramId}/overrides/${id}`, payload, null, false);
  });
};

interface DeleteTrainingOverrideMutationVariables {
  trainingProgramId: string;
  id: string;
}

export const useDeleteTrainingOverride = () => {
  return useMutation<TrainingOverride, Error, DeleteTrainingOverrideMutationVariables>(({ trainingProgramId, id }) => {
    return InterviewPlanner.request('DELETE', `/training-programs/${trainingProgramId}/overrides/${id}`);
  });
};
