import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Tooltip from '../Tooltip';
import { StyledText } from './styles';

import type { ReactNode } from 'react';

interface Props {
  id: string;
  text?: ReactNode;
  tooltipHelperText?: ReactNode;
  tooltipText: ReactNode;
}

const Infotip = ({ id, text, tooltipHelperText, tooltipText }: Props) => {
  return (
    <>
      <StyledText
        data-for={id}
        data-tip
      >
        <FontAwesomeIcon icon={faInfoCircle} />
        {text && <span>{text}</span>}
      </StyledText>
      <Tooltip
        helperText={tooltipHelperText}
        id={id}
        position="top"
        value={tooltipText}
      />
    </>
  );
};

export default Infotip;
