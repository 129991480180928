import { forwardRef, useEffect, useMemo, useState } from 'react';
import { uniqueId } from 'lodash';

import type { FocusEventHandler, ReactNode, ChangeEventHandler, Ref, ChangeEvent } from 'react';

export enum InputType {
  Email = 'email',
  Number = 'number',
  Password = 'password',
  Text = 'text',
}

interface Props {
  className?: string;
  helperText?: ReactNode;
  id?: string;
  leftIcon?: JSX.Element;
  isAutofocus?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  label?: string;
  name?: string;
  numberMax?: number;
  numberMin?: number;
  numberStep?: number;
  onBlur?: FocusEventHandler<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  placeholder?: string;
  rightIcon?: JSX.Element;
  type?: `${InputType}`;
  value?: string | number;
}

const TextInputInner = ({
  className,
  helperText,
  id,
  isAutofocus = false,
  isDisabled = false,
  isRequired = false,
  label,
  leftIcon,
  name,
  numberMax,
  numberMin = 0,
  numberStep = 1,
  onBlur,
  onChange,
  onFocus,
  placeholder,
  rightIcon,
  type = InputType.Text,
  value = '',
}: Props, ref: Ref<HTMLInputElement>) => {
  const [controlledValue, setControlledValue] = useState(value);
  useEffect(() => {
    setControlledValue(value);
  }, [value]);

  id = useMemo(() => id || uniqueId('text-input-'), [id]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => setControlledValue(e.target.value);

  return (
    <div className={`input text-input${className ? ` ${className}` : ''}`}>
      {label && <label htmlFor={id}>{label}</label>}
      <div className="text-input-container">
        {leftIcon && <div className="icon icon-left">{leftIcon}</div>}
        <input
          autoFocus={isAutofocus}
          disabled={isDisabled}
          id={id}
          max={type === 'number' ? numberMax : undefined}
          min={type === 'number' ? numberMin : undefined}
          name={name}
          onBlur={onBlur}
          onChange={onChange || handleChange}
          onFocus={onFocus}
          placeholder={placeholder}
          ref={ref}
          required={isRequired}
          step={type === 'number' ? numberStep : undefined}
          type={type}
          value={value || controlledValue}
        />
        {rightIcon && <div className="icon icon-right">{rightIcon}</div>}
      </div>
      {helperText && <div className="helper-text">{helperText}</div>}
    </div>
  );
};

const TextInput = forwardRef(TextInputInner);

export default TextInput;
