import Moment from 'moment-timezone';
import { isEmpty } from 'lodash';

import Flash from '../../../../../../library/utils/Flash';
import { calculateInterviewChanges, formatInterviewNames } from './helpers';
import { formatMoment, TimeFormat } from '../../../../../../../libraries/time';
import { liveCodingLabels } from '../../../../../../../types';
import { useRoomsMap } from '../../../../../../../hooks/queries/rooms';
import { useSession } from '../../../../../../../hooks/use-session';
import { useUsersMap } from '../../../../../../../hooks/queries/users';

import type { Schedule, EditableSchedule } from '../../../../../../../types';

interface Props {
  originalSchedule: Schedule;
  newSchedule: EditableSchedule;
}

const UnsavedEditsFlash = ({ newSchedule, originalSchedule }: Props) => {
  const { account } = useSession();
  const rooms = useRoomsMap();
  const users = useUsersMap({ archived: true });

  const oldDate = formatMoment(Moment.tz(originalSchedule.interviews[0].start_time, originalSchedule.timezone), TimeFormat.LongDayOfWeekMonthDay);
  const newDate = formatMoment(Moment.tz(newSchedule.interviews[0].start_time, newSchedule.timezone), TimeFormat.LongDayOfWeekMonthDay);

  const oldRoomId = originalSchedule.interviews[0].room_id || null;
  const newRoomId = newSchedule.interviews[0].room_id || null;

  const oldZoomHostId = originalSchedule.interviews[0].zoom_host_id || null;
  const newZoomHostId = newSchedule.interviews[0].zoom_host_id || null;

  const oldVideoConferencingEnabled = originalSchedule.schedule_template.video_conferencing_enabled;
  const newVideoConferencingEnabled = newSchedule.schedule_template.video_conferencing_enabled;

  const interviewChanges = calculateInterviewChanges(originalSchedule, newSchedule);
  const interviewsWithUpdatedScorecard = interviewChanges.filter(({ changes }) => changes.scorecard);
  const interviewsWithUpdatedFeedbackForm = interviewChanges.filter(({ changes }) => changes.feedbackForm);
  const interviewsWithUpdatedLiveCodingEnabled = interviewChanges.filter(({ changes }) => changes.liveCodingEnabled);
  const interviewsWithUpdatedInterviewers = interviewChanges.filter(({ changes }) => changes.interviewers);
  const interviewsWithUpdatedOptionalities = interviewChanges.filter(({ changes }) => changes.optionalities);
  const interviewsWithUpdatedTrainings = interviewChanges.filter(({ changes }) => changes.trainings);
  const interviewsWithUpdatedTimes = interviewChanges.filter(({ changes }) => changes.times);
  const removedInterviews = interviewChanges.filter(({ changes }) => changes.removedInterview);
  const newInterviews = interviewChanges.filter(({ changes }) => changes.newInterview);

  const hasUnsavedEdits = Boolean(
    oldDate !== newDate ||
    oldRoomId !== newRoomId ||
    oldZoomHostId !== newZoomHostId ||
    oldVideoConferencingEnabled !== newVideoConferencingEnabled ||
    interviewsWithUpdatedScorecard.length ||
    interviewsWithUpdatedFeedbackForm.length ||
    interviewsWithUpdatedLiveCodingEnabled.length ||
    interviewsWithUpdatedInterviewers.length ||
    interviewsWithUpdatedOptionalities.length ||
    interviewsWithUpdatedTrainings.length ||
    interviewsWithUpdatedTimes.length ||
    removedInterviews.length ||
    newInterviews.length
  );

  return (
    <Flash
      message={(
        <>
          The following changes will be applied when you save:
          <ul>
            {newDate !== oldDate &&
              <li>
                You have moved this schedule to a different day: <b>{newDate}</b>.
              </li>
            }
            {!newVideoConferencingEnabled && oldVideoConferencingEnabled &&
              <li>
                You have removed video conferencing from this schedule.
              </li>
            }
            {newVideoConferencingEnabled && !oldVideoConferencingEnabled &&
              <li>
                You have added video conferencing to this schedule.
              </li>
            }
            {!Boolean(newRoomId) && Boolean(oldRoomId) &&
              <li>
                You have removed the room from this schedule.
              </li>
            }
            {Boolean(newRoomId) && newRoomId !== oldRoomId &&
              <li>
                This schedule will now take place in <b>{rooms[newRoomId!]?.name}</b>.
              </li>
            }
            {Boolean(newZoomHostId) && Boolean(oldZoomHostId) && newZoomHostId !== oldZoomHostId &&
              <li>
                You have changed the Zoom host to be <b>{newSchedule.interviews[0].zoom_host_type === 'room' ? rooms[newZoomHostId!]?.name : (users[newZoomHostId!]?.name || users[newZoomHostId!]?.email)}</b>.
              </li>
            }
            {!isEmpty(removedInterviews) &&
              <li>
                You have removed {formatInterviewNames(removedInterviews)}.
              </li>
            }
            {!isEmpty(newInterviews) &&
              <li>
                You have added {formatInterviewNames(newInterviews)}.
              </li>
            }
            {!isEmpty(interviewsWithUpdatedScorecard) &&
              <li>
                You have edited the scorecard link for {formatInterviewNames(interviewsWithUpdatedScorecard)}.
              </li>
            }
            {!isEmpty(interviewsWithUpdatedFeedbackForm) &&
              <li>
                You have edited the feedback form for {formatInterviewNames(interviewsWithUpdatedFeedbackForm)}.
              </li>
            }
            {!isEmpty(interviewsWithUpdatedLiveCodingEnabled) &&
              <li>
                You have edited the {account?.live_coding_type ? liveCodingLabels[account.live_coding_type] : 'live coding'} link for {formatInterviewNames(interviewsWithUpdatedLiveCodingEnabled)}.
              </li>
            }
            {!isEmpty(interviewsWithUpdatedInterviewers) &&
              <li>
                You have edited interviewers for {formatInterviewNames(interviewsWithUpdatedInterviewers)}.
              </li>
            }
            {!isEmpty(interviewsWithUpdatedOptionalities) &&
              <li>
                You have edited whether interviewers are optional for {formatInterviewNames(interviewsWithUpdatedOptionalities)}.
              </li>
            }
            {!isEmpty(interviewsWithUpdatedTrainings) &&
              <li>
                You have edited the associated training program for {formatInterviewNames(interviewsWithUpdatedTrainings)}.
              </li>
            }
            {!isEmpty(interviewsWithUpdatedTimes) &&
              <li>
                You have edited times for {formatInterviewNames(interviewsWithUpdatedTimes)}.
              </li>
            }
          </ul>
        </>
      )}
      showFlash={hasUnsavedEdits}
      type="info"
    />
  );
};

export default UnsavedEditsFlash;
