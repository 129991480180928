import { useEffect, useState } from 'react';

import Flash from '../../library/utils/Flash';
import InterviewLimitSelectInput from '../../library/inputs/InterviewLimitSelectInput';
import Section from '../../library/layout/Section';
import { useAccount, useUpdateAccount } from '../../../hooks/queries/accounts';

const CompanyInterviewLimitsSection = () => {
  const { data: account } = useAccount();

  const [isEditing, setIsEditing] = useState(false);

  const [dailyInterviewLimit, setDailyInterviewLimit] = useState(account!.daily_interview_limit || -1);
  const [weeklyInterviewLimit, setWeeklyInterviewLimit] = useState(account!.weekly_interview_limit || -1);

  const updateAccountMutation = useUpdateAccount();

  useEffect(() => {
    setDailyInterviewLimit(account!.daily_interview_limit || -1);
  }, [account!.daily_interview_limit]);

  useEffect(() => {
    setWeeklyInterviewLimit(account!.weekly_interview_limit || -1);
  }, [account!.weekly_interview_limit]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setDailyInterviewLimit(account!.daily_interview_limit || -1);
    setWeeklyInterviewLimit(account!.weekly_interview_limit || -1);
    setIsEditing(false);
    updateAccountMutation.reset();
  };

  const handleSave = async () => {
    updateAccountMutation.reset();

    try {
      await updateAccountMutation.mutateAsync({
        id: account!.id,
        payload: {
          daily_interview_limit: dailyInterviewLimit,
          weekly_interview_limit: weeklyInterviewLimit,
        },
      });
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  return (
    <Section
      isEditable
      isEditing={isEditing}
      isSaving={updateAccountMutation.isLoading}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Interview limits"
    >
      <Flash
        message={updateAccountMutation.error?.message}
        showFlash={updateAccountMutation.isError}
        type="danger"
      />
      <Flash
        isDismissible
        message="Successfully updated!"
        showFlash={updateAccountMutation.isSuccess}
        type="success"
      />
      <InterviewLimitSelectInput
        dailyInterviewLimit={dailyInterviewLimit}
        helperText="Use these to set a hard cap on the number of interviews per day and week for all interviewers. This can be overridden for specific interviewers."
        isDisabled={!isEditing || updateAccountMutation.isLoading}
        setDailyInterviewLimit={setDailyInterviewLimit}
        setWeeklyInterviewLimit={setWeeklyInterviewLimit}
        weeklyInterviewLimit={weeklyInterviewLimit}
      />
    </Section>
  );
};

export default CompanyInterviewLimitsSection;
