import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { orderBy } from 'lodash';
import Moment from 'moment-timezone';
import { useEffect, useMemo } from 'react';

import Button from 'components/library/inputs/Button';
import DateInput from 'components/library/inputs/DateInput';
import DurationInput from 'components/library/inputs/DurationInput';
import ExpandableCheckboxInput from 'components/library/inputs/ExpandableCheckboxInput';
import SelectInput from 'components/library/inputs/SelectInput';
import TimeRangeInput from 'components/library/inputs/TimeRangeInput';
import UserSelectInput from 'components/library/inputs/UserSelectInput';
import ZoomHostSelectInput from 'components/library/inputs/ZoomHostSelectInput';
import Tooltip from 'components/library/utils/Tooltip';
import { useAccount } from 'hooks/queries/accounts';
import { useStageInterviews } from 'hooks/queries/stage-interviews';
import { createEmptyInterviewBatchTime } from './helpers';
import { StyledAddTimeButton, StyledCandidatesPerSlotTextInput, StyledExpandedContentContainer, StyledInterviewDetails, StyledTable } from './styles';

import type { Dispatch, SetStateAction } from 'react';
import type { TableSchema } from 'components/library/data-display/Table/types';
import type { EditableInterviewBatchTime, ZoomHostType } from 'types';

interface Props {
  jobId?: string;
  times: EditableInterviewBatchTime[];
  setTimes: Dispatch<SetStateAction<EditableInterviewBatchTime[]>>;
}

const EditSuperdayTimesTable = ({ jobId, times, setTimes }: Props) => {
  const { data: account } = useAccount();
  const { data: stageInterviews } = useStageInterviews(
    { jobs: jobId ? [jobId] : [] },
    { enabled: Boolean(jobId) },
  );

  useEffect(() => {
    if (times.length === 0) {
      setTimes([createEmptyInterviewBatchTime(Moment.tz.guess())]);
    }
  }, []);

  const stageInterviewOptions = useMemo(() => {
    if (!stageInterviews) {
      return [];
    }
    return orderBy(stageInterviews.stage_interviews, ['stage.position', 'position']).map(({ id, name, stage }) => ({
      value: id,
      label: name,
      secondaryText: stage.name,
    }));
  }, [stageInterviews]);

  const setTime = (updatedTime: EditableInterviewBatchTime, i: number) => setTimes(times.map((time, j) => (i === j ? updatedTime : time)));

  const schema = useMemo<TableSchema<EditableInterviewBatchTime>>(() => [{
    header: 'Interview Date',
    displayEditValue: ({ start_time, end_time, timezone }, i) => {
      const selectedDate = start_time ? Moment.tz(start_time, timezone).set({ hour: 0, minute: 0, second: 0, millisecond: 0 }).toISOString() : '';
      return (
        <div onClick={(e) => e.stopPropagation()}>
          <DateInput
            disablePastDates
            isClearable={false}
            onChange={(date) => {
              if (!start_time) {
                return setTime({
                  ...times[i],
                  start_time: date,
                  end_time: date,
                }, i);
              }
              const year = Moment(date).year();
              const month = Moment(date).month();
              const day = Moment(date).date();
              const updatedTime = {
                ...times[i],
                start_time: Moment.tz(start_time, timezone).set({
                  year,
                  month,
                  date: day,
                }).toISOString(),
                end_time: Moment.tz(end_time, timezone).set({
                  year,
                  month,
                  date: day,
                }).toISOString(),
              };
              return setTime(updatedTime, i);
            }}
            value={selectedDate}
          />
        </div>
      );
    },
  }, {
    header: 'Times',
    displayEditValue: ({ start_time, end_time, timezone }, i) => {
      return (
        <div onClick={(e) => e.stopPropagation()}>
          <TimeRangeInput
            endValue={Moment.tz(end_time, timezone).format('HH:mm')}
            isTimezoneClearable={false}
            isTimezoneRequired
            onEndChange={(option) => {
              const time = option?.value;
              const updatedTime = {
                ...times[i],
                end_time: Moment.tz(end_time || Moment(), timezone).set({
                  hour: time ? parseInt(time.split(':')[0], 10) : 0,
                  minute: time ? parseInt(time.split(':')[1], 10) : 0,
                }).toISOString(),
              };
              return setTime(updatedTime, i);
            }}
            onStartChange={(option) => {
              const time = option?.value;
              const updatedTime = {
                ...times[i],
                start_time: Moment.tz(start_time || Moment(), timezone).set({
                  hour: time ? parseInt(time.split(':')[0], 10) : 0,
                  minute: time ? parseInt(time.split(':')[1], 10) : 0,
                }).toISOString(),
              };
              return setTime(updatedTime, i);
            }}
            onTimezoneChange={(option) => setTime({ ...times[i], timezone: option!.value }, i)}
            startValue={Moment.tz(start_time, timezone).format('HH:mm')}
            timezoneValue={timezone}
            withTimezone
          />
        </div>
      );
    },
  }, {
    header: '',
    displayEditValue: (_, i) => {
      return (
        <Button
          color="no-outline"
          iconLeft={<FontAwesomeIcon icon={faTrashAlt} />}
          onClick={() => setTimes((prev) => prev.filter((_, index) => i !== index))}
          size="small"
          tooltip={
            <Tooltip
              id={`${i}-delete-button`}
              position="top"
              value="Remove time"
            />
          }
        />
      );
    },
  }], [setTime, stageInterviews, stageInterviewOptions]);

  return (
    <>
      <StyledTable
        data={times}
        displayExpandedContent={({ interview_template, candidates_per_slot, stage_interview_id, video_conferencing_enabled, zoom_host_id, zoom_host_type }, i) => {
          const selectedInterviewerIds = (interview_template.interviewer_templates || []).map(({ interviewer_filters }) => interviewer_filters?.[0]?.interviewer_filter_expressions?.[0]?.filterable_id).filter((obj) => Boolean(obj)) as string[];
          return (
            <StyledExpandedContentContainer>
              <h6>
                Interview Details
              </h6>
              <StyledInterviewDetails>
                <SelectInput
                  isDisabled={!jobId || !stageInterviewOptions.length}
                  label="Interview"
                  onChange={(option) => {
                    const stageInterview = stageInterviews?.stage_interviews.find(({ id }) => id === option?.value);
                    setTime({
                      ...times[i],
                      interview_template: {
                        ...times[i].interview_template,
                        duration_minutes: stageInterview?.interview_template?.duration_minutes || 30,
                      },
                      stage_interview_id: option?.value || '',
                    }, i);
                  }}
                  options={stageInterviewOptions}
                  placeholder="Select an interview"
                  value={stageInterviewOptions.find(({ value }) => value === stage_interview_id)}
                />
                <DurationInput
                  isAbbreviatedUnits
                  isRequired
                  label="Duration"
                  onChange={(value) => setTime({
                    ...times[i],
                    interview_template: {
                      ...interview_template,
                      duration_minutes: value,
                    },
                  }, i)}
                  value={interview_template.duration_minutes}
                />
              </StyledInterviewDetails>
              <StyledInterviewDetails>
                <UserSelectInput
                  includeOnlyATSUsers
                  isClearable
                  isMulti
                  label="Interviewers"
                  onChange={(option) => {
                    return setTime({
                      ...times[i],
                      interview_template: {
                        ...interview_template,
                        interviewer_templates: (option || []).map(({ value }, i) => ({
                          id: times[i]?.interview_template.interviewer_templates?.[i]?.id,
                          optional: false,
                          interviewer_filters: [{
                            interviewer_filter_expressions: [{
                              negated: false,
                              filterable_id: value,
                              filterable_type: 'user',
                            }],
                          }],
                        })),
                      },
                    }, i);
                  }}
                  placeholder="Select interviewers"
                  value={selectedInterviewerIds}
                />
                <StyledCandidatesPerSlotTextInput
                  // helperText="The number of candidates that can be interviewed together. Usually 1, except for group interviews."
                  isRequired
                  label="Candidate Group Size"
                  numberMin={1}
                  onChange={(e) => setTime({
                    ...times[i],
                    candidates_per_slot: parseInt(e.target.value, 10),
                  }, i)}
                  type="number"
                  value={candidates_per_slot}
                />
              </StyledInterviewDetails>
              <ExpandableCheckboxInput
                isChecked={video_conferencing_enabled}
                label="Include video conferencing."
                onChange={(e) => setTime({
                  ...times[i],
                  video_conferencing_enabled: e.target.checked,
                }, i)}
              >
                {video_conferencing_enabled && account?.video_conferencing_type === 'zoom' && (
                  <ZoomHostSelectInput
                    label="Zoom Host"
                    onChange={(option) => setTime({
                      ...times[i],
                      zoom_host_id: option?.value,
                      zoom_host_type: option?.type,
                    }, i)}
                    selectedZoomHostId={zoom_host_id}
                    selectedZoomHostType={zoom_host_type as ZoomHostType}
                  />
                )}
              </ExpandableCheckboxInput>
            </StyledExpandedContentContainer>
          );
        }}
        getRowIsExpanded={({ stage_interview_id }) => Boolean(stage_interview_id)}
        isEditing
        layout="vertical"
        schema={schema}
      />
      <StyledAddTimeButton
        color="no-outline"
        iconLeft={<FontAwesomeIcon icon={faPlus} />}
        onClick={() => setTimes([...times, createEmptyInterviewBatchTime(times[0]?.timezone || Moment.tz.guess())])}
        size="small"
        value="Add Time"
      />
    </>
  );
};

export default EditSuperdayTimesTable;
