import { concat, find, orderBy, uniqBy } from 'lodash';
import { useMemo, useState } from 'react';

import SelectInput from '../SelectInput';
import Tag from '../../data-display/Tag';
import Tooltip from '../../utils/Tooltip';
import { ZoomHostFilterableType } from '../../../../types';
import { useRooms, useRoomTags } from '../../../../hooks/queries/rooms';
import { useUsers, useUserTags } from '../../../../hooks/queries/users';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';

interface Expression {
  filterable_id: string;
  filterable_type: `${ZoomHostFilterableType}`;
  isDisabled?: boolean;
  label: string;
  tooltip?: JSX.Element;
  value: string;
}

export interface Option extends Expression {
  name: string;
  negated: boolean;
}

const roleOptions: Option[] = [{
  filterable_id: 'scheduler',
  filterable_type: ZoomHostFilterableType.Role,
  label: 'Scheduler',
  value: 'scheduler',
  name: 'Scheduler',
  negated: false,
}, {
  filterable_id: 'first_interviewer',
  filterable_type: ZoomHostFilterableType.Role,
  label: 'First Interviewer',
  value: 'first_interviewer',
  name: 'First Interviewer',
  negated: false,
}];

const createOptions = (expressions: Expression[]): Option[] => orderBy(
  concat(
    expressions.map<Option>((expression) => ({
      ...expression,
      negated: false,
      name: expression.label,
    })),
    expressions.filter(({ isDisabled }) => !isDisabled).map<Option>((expression) => ({
      ...expression,
      label: `not ${expression.label}`,
      negated: true,
      name: expression.label,
    }))
  ),
  ['isDisabled', 'name', 'negated'],
  ['asc', 'asc', 'asc']
);

export interface Props {
  excludeFirstInterviewerOption?: boolean;
  isDisabled?: boolean;
  name?: string;
  onChange: (newValue: OnChangeValue<Option, true>, actionMeta: ActionMeta<Option>) => void;
  selectedFilterExpressions: {
    filterable_id: string;
    filterable_type: `${ZoomHostFilterableType}`;
    negated: boolean;
  }[];
}

const ZoomHostFilterInput = ({
  excludeFirstInterviewerOption = false,
  isDisabled = false,
  name,
  onChange,
  selectedFilterExpressions,
}: Props) => {
  const { data: userTags } = useUserTags();
  const { data: roomTags } = useRoomTags();
  const { data: users } = useUsers({ archived: true });
  const { data: rooms } = useRooms({ archived: true });

  const [initialFilterExpressions] = useState(selectedFilterExpressions);

  const tagOptions = useMemo<Expression[]>(() => uniqBy((userTags || []).concat(roomTags || []), 'id').map((tag) => ({
    filterable_type: ZoomHostFilterableType.Tag,
    filterable_id: tag.id,
    label: tag.id,
    value: tag.id,
  })), [userTags]);
  const userOptions = useMemo<Expression[]>(() => (users?.users || []).filter(({ directory_archived, user_archived }) => !user_archived && !directory_archived).map((user) => ({
    filterable_type: ZoomHostFilterableType.User,
    filterable_id: user.id,
    label: user.name || user.email,
    value: user.id,
    isDisabled: !user.video_conferencing_id,
    tooltip: !user.video_conferencing_id ? (
      <Tooltip
        id={user.id}
        position="top"
        value={`${user.name || user.email} doesn't have a Zoom account, so they can't be a Zoom host.`}
      />
    ) : undefined,
  })), [users]);
  const roomOptions = useMemo<Expression[]>(() => (rooms?.rooms || []).filter(({ directory_archived, user_archived }) => !user_archived && !directory_archived).map((room) => ({
    filterable_type: ZoomHostFilterableType.Room,
    filterable_id: room.id,
    label: room.name,
    value: room.id,
    isDisabled: !room.video_conferencing_id,
    tooltip: !room.video_conferencing_id ? (
      <Tooltip
        id={room.id}
        position="top"
        value={`${room.name} isn't a Zoom Room, so it can't be a Zoom host.`}
      />
    ) : undefined,
  })), [rooms]);

  const isSingleSelectedValue = useMemo(() => (
    selectedFilterExpressions && selectedFilterExpressions.length === 1 &&
    (selectedFilterExpressions[0].filterable_type === ZoomHostFilterableType.User || selectedFilterExpressions[0].filterable_type === ZoomHostFilterableType.Room || selectedFilterExpressions[0].filterable_type === ZoomHostFilterableType.Role) &&
    !selectedFilterExpressions[0].negated
  ), [selectedFilterExpressions]);

  const hasSelectedFilters = useMemo(() => selectedFilterExpressions.length > 0, [selectedFilterExpressions]);

  const options = useMemo(() => [{
    label: 'Role',
    options: roleOptions.filter((option) => {
      if (excludeFirstInterviewerOption && option.value === 'first_interviewer') {
        return false;
      }
      if (isSingleSelectedValue) {
        const selectedFilterExpression = selectedFilterExpressions[0];
        return selectedFilterExpression.filterable_type === ZoomHostFilterableType.Role && selectedFilterExpression.filterable_id === option.filterable_id;
      }
      return !hasSelectedFilters;
    }),
    type: ZoomHostFilterableType.Role,
  }, {
    label: 'Tags',
    options: isSingleSelectedValue ? [] : createOptions([
      ...tagOptions,
      ...(initialFilterExpressions || []).filter((expression) => expression.filterable_type === ZoomHostFilterableType.Tag && !find(tagOptions, ['filterable_id', expression.filterable_id])).map((tag) => ({ ...tag, value: tag.filterable_id, label: tag.filterable_id })),
    ]),
    type: ZoomHostFilterableType.Tag,
  }, {
    label: 'Users',
    options: createOptions(userOptions).filter((option) => {
      if (isSingleSelectedValue) {
        const selectedFilterExpression = selectedFilterExpressions[0];
        return selectedFilterExpression.filterable_type === ZoomHostFilterableType.User && selectedFilterExpression.filterable_id === option.filterable_id;
      }
      if (hasSelectedFilters) {
        return option.negated;
      }
      return true;
    }),
    type: ZoomHostFilterableType.User,
  }, {
    label: 'Rooms',
    options: createOptions(roomOptions).filter((option) => {
      if (isSingleSelectedValue) {
        const selectedFilterExpression = selectedFilterExpressions[0];
        return selectedFilterExpression.filterable_type === ZoomHostFilterableType.Room && selectedFilterExpression.filterable_id === option.filterable_id;
      }
      if (hasSelectedFilters) {
        return option.negated;
      }
      return true;
    }),
    type: ZoomHostFilterableType.User,
  }], [tagOptions, userOptions, selectedFilterExpressions, initialFilterExpressions, isSingleSelectedValue, hasSelectedFilters]);

  return (
    <SelectInput<string, Option, true>
      className="zoom-host-filter-input"
      formatOptionLabel={(option, { context }) => (
        <div
          className="zoom-host-filter-option-container"
          data-for={option.tooltip ? option.tooltip.props.id : undefined}
          data-tip={option.tooltip ? true : undefined}
        >
          {context === 'menu' && option.filterable_type === ZoomHostFilterableType.Role ? (
            option.label
          ) : (
            <Tag
              isNegated={option.negated}
              type={option.filterable_type}
              value={option.filterable_id}
            />
          )}
          {option.tooltip}
        </div>
      )}
      isClearable
      isDisabled={isDisabled}
      isMulti
      isRequired
      maxMenuHeight={300}
      name={name}
      noOptionsMessage={() => isSingleSelectedValue ? <>You&apos;ve already selected a specific Zoom host. To add more possible Zoom hosts to the pool, click on <b>Add Alternate Rule</b> below.</> : undefined}
      onChange={onChange}
      options={options}
      placeholder="Tag, role, specific user or room"
      value={selectedFilterExpressions ?
        selectedFilterExpressions.map((expression) => {
          const optionsOfSameType = find(options, ['type', expression.filterable_type])!.options;
          return find(optionsOfSameType, {
            filterable_id: expression.filterable_id,
            filterable_type: expression.filterable_type,
            negated: expression.negated,
          })!;
        }) :
        undefined
      }
    />
  );
};

export default ZoomHostFilterInput;
