export enum FavoriteResourceType {
  Application = 'Application',
  CalendarEventTemplate = 'CalendarEventTemplate',
  EmailTemplate = 'EmailTemplate',
  HiringMeetingTemplate = 'HiringMeetingTemplate',
  InterviewTemplate = 'InterviewTemplate',
  Job = 'Job',
}

export interface Favorite {
  user_id: string;
  resource_id: string;
  resource_type: FavoriteResourceType;
  created_at: string;
}
