import { useEffect, useState } from 'react';

import ColorInput from 'components/library/inputs/ColorInput';
import FileDropInput from 'components/library/inputs/FileDropInput';
import Flash from 'components/library/utils/Flash';
import { StyledContainer, StyledInputsContainer } from './styles';
import { StyledModal } from '../styles';
import TextInput from 'components/library/inputs/TextInput';
import { useAccount, useUpdateAccount } from 'hooks/queries/accounts';

import type { BlobWithName } from 'components/library/inputs/FileDropInput';
import type { ChangeEvent } from 'react';
import type { UpdateAccountPayload } from 'hooks/queries/accounts';

const MAX_LOGO_FILE_SIZE_BYTES = 5242880;

interface Props {
  isOpen: boolean;
  onToggle: () => void;
}

const SetCompanyBrandingModal = ({ isOpen, onToggle }: Props) => {
  const { data } = useAccount();
  const account = data!;

  const [name, setName] = useState(account?.name || '');
  const [color, setColor] = useState(account?.color || '');
  const [logo, setLogo] = useState<string | BlobWithName | undefined>(account?.logo_url || undefined);

  const updateAccountMutation = useUpdateAccount();

  useEffect(() => {
    setLogo(account?.logo_url || undefined);
  }, [account?.logo_url]);

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => setName(e.target.value);
  const handleColorChange = (color: { hex: string } | null) => setColor(color ? color.hex : '');
  const handleLogoChange = (file: BlobWithName | null) => setLogo(file || undefined);

  const handleToggle = () => {
    onToggle();
  };

  const handleSave = async () => {
    updateAccountMutation.reset();

    try {
      const payload: UpdateAccountPayload = {
        name,
        color,
      };
      if (logo && typeof logo !== 'string') {
        payload.logo = logo;
      }
      await updateAccountMutation.mutateAsync({
        id: account.id,
        payload,
      });
      onToggle();
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  return (
    <StyledModal
      isOpen={isOpen}
      isSubmitting={updateAccountMutation.isLoading}
      onSubmit={handleSave}
      onToggle={handleToggle}
      submitButtonValue="Save"
      title="Add company branding?"
    >
      <Flash
        message={updateAccountMutation.error?.message}
        showFlash={updateAccountMutation.isError}
        type="danger"
      />
      <Flash
        message={<>We use these settings to customize the availability requests and self-scheduling requests that you send to candidates.</>}
        showFlash
        type="info"
      />
      <StyledContainer>
        <FileDropInput
          file={logo}
          fileTypes={['image/png']}
          helperText="We recommend using a logo that's at least 300px tall for clear image quality."
          isDisabled={updateAccountMutation.isLoading}
          label="Logo"
          maxFileSizeBytes={MAX_LOGO_FILE_SIZE_BYTES}
          onChange={handleLogoChange}
        />
        <StyledInputsContainer>
          <TextInput
            isDisabled={updateAccountMutation.isLoading}
            label="Name"
            onChange={handleNameChange}
            value={name}
          />
          <ColorInput
            isDisabled={updateAccountMutation.isLoading}
            label="Brand Color"
            onChange={handleColorChange}
            selectedColor={color}
          />
        </StyledInputsContainer>
      </StyledContainer>
    </StyledModal>
  );
};

export default SetCompanyBrandingModal;
