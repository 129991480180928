import classnames from 'classnames';
import { uniqueId } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import type { ReactNode, ChangeEvent, ChangeEventHandler } from 'react';

interface Props {
  className?: string;
  helperText?: ReactNode;
  id?: string;
  isChecked?: boolean;
  isDisabled?: boolean;
  label?: ReactNode;
  name?: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  tooltip?: JSX.Element;
}

const CheckboxInput = ({
  className,
  helperText,
  isChecked,
  id,
  isDisabled = false,
  label,
  name,
  onChange,
  tooltip,
}: Props) => {
  const [controlledValue, setControlledValue] = useState(isChecked || false);
  useEffect(() => {
    setControlledValue(isChecked || false);
  }, [isChecked]);

  id = useMemo(() => id || uniqueId('checkbox-input-'), [id]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => setControlledValue(e.target.checked);

  return (
    <>
      <label
        className={classnames(['input', 'checkbox-input', className])}
        data-cy="checkbox-input"
        htmlFor={id}
      >
        <input
          checked={isChecked || controlledValue || false}
          disabled={isDisabled}
          id={id}
          name={name}
          onChange={onChange || handleChange}
          type="checkbox"
        />
        <span
          className="checkbox"
          data-for={tooltip ? tooltip.props.id : undefined}
          data-tip={tooltip ? true : undefined}
        />
        <span className={`input-label${label ? '' : ' empty-label'}`}>{label}</span>
        {helperText && <div className="helper-text">{helperText}</div>}
      </label>
      {tooltip}
    </>
  );
};

export default CheckboxInput;
