import Moment from 'moment-timezone';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import Avatar from '../../../../../library/data-display/Avatar';
import ListItem from '../../../../../library/data-display/ListItem';
import Section from '../../../../../library/layout/Section';
import Table from '../../../../../library/data-display/Table';
import { formatMoment, TimeFormat } from '../../../../../../libraries/time';
import { rsvpAvatarStatus } from '../../../../../../types';
import { useSchedule } from '../../../../../../hooks/queries/schedules';
import { useUsersMap } from '../../../../../../hooks/queries/users';

import type { Interview } from '../../../../../../types';
import type { TableSchema } from '../../../../../library/data-display/Table';

const ImportedScheduleInterviewsSection = () => {
  const { scheduleId } = useParams<{ id: string; scheduleId: string }>();

  const schedule = useSchedule(scheduleId).data!;
  const users = useUsersMap({ archived: true });

  const schema = useMemo<TableSchema<Interview>>(() => [{
    header: 'Time',
    displayValue: ({ start_time }) => formatMoment(Moment(start_time).tz(Moment.tz.guess()), TimeFormat.LongDayOfWeekMonthDayWithTimeAndTimezoneAndComma),
  }, {
    header: 'Interview',
    displayValue: ({ name }) => name,
  }, {
    header: 'Interview Panel',
    displayValue: ({ interviewers }) => (
      <div className="interview-panel">
        {(interviewers || []).map(({ interviewer_template, user_id, rsvp }, i) => (
          <ListItem
            key={`interviewer-${i}`}
            label={
              <span>
                {users[user_id!]?.name || users[user_id!]?.email}
                {interviewer_template.optional && <span className="optional-interviewer"> (Optional)</span>}
              </span>
            }
            leftIcon={
              <Avatar
                size="small"
                status={rsvpAvatarStatus[rsvp].status}
                tooltipHelperText={rsvpAvatarStatus[rsvp].tooltipHelperText}
                userId={user_id!}
              />
            }
          />
        ))}
      </div>
    ),
  }], [users]);

  return (
    <Section title="Interviews">
      <Table data={schedule.interviews} layout="vertical" schema={schema} />
    </Section>
  );
};

export default ImportedScheduleInterviewsSection;
