import Moment from 'moment-timezone';
import { find, isEqual } from 'lodash';

import type { EditableInterview, EditableSchedule, Schedule } from '../../../../../../../types';
import { trainingProgramEligibilityOfSlot } from '../../../../../../../libraries/training';

interface Changes {
  newInterview?: true;
  feedbackForm?: true;
  liveCodingEnabled?: true;
  interviewers?: true;
  optionalities?: true;
  scorecard?: true;
  trainings?: true;
  times?: true;
  removedInterview?: true;
}

interface InterviewChanges {
  name: string;
  changes: Changes;
}

export const calculateInterviewChanges = (oldSchedule: Schedule, newSchedule: EditableSchedule): InterviewChanges[] => {
  const removedInterviews: InterviewChanges[] = oldSchedule.interviews
  .filter((interview) => !find(newSchedule.interviews, ['id', interview.id]))
  .map((interview) => ({
    name: interview.name,
    changes: { removedInterview: true },
  }));

  const newAndUpdatedInterviews: InterviewChanges[] = newSchedule.interviews.map((interview) => {
    const matchingPrevInterview = find(oldSchedule.interviews, ['id', interview.id]);
    if (!matchingPrevInterview) {
      return {
        name: interview.name,
        changes: { newInterview: true },
      };
    }

    const changes: Changes = {};

    if (matchingPrevInterview.feedback_form_id !== interview.feedback_form_id) {
      changes.feedbackForm = true;
    }

    if (matchingPrevInterview.scorecard_stage_interview_id !== interview.scorecard_stage_interview_id) {
      changes.scorecard = true;
    }

    if (matchingPrevInterview.interview_template.live_coding_enabled !== interview.interview_template.live_coding_enabled) {
      changes.liveCodingEnabled = true;
    }

    const prevInterviewerUserIds = matchingPrevInterview.interviewers ? matchingPrevInterview.interviewers.map(({ user_id }) => user_id) : [];
    const interviewerUserIds = interview.interviewers ? interview.interviewers.map(({ user_id }) => user_id) : [];
    if (!isEqual(prevInterviewerUserIds, interviewerUserIds)) {
      changes.interviewers = true;
    }

    const prevOptionalities = matchingPrevInterview.interviewers ? matchingPrevInterview.interviewers.map(({ interviewer_template }) => interviewer_template.optional) : [];
    const optionalities = interview.interviewers ? interview.interviewers.map(({ interviewer_template }) => interviewer_template.optional) : [];
    if (prevOptionalities.length === optionalities.length && !isEqual(prevOptionalities, optionalities)) {
      // Only show the optionality message if they didn't add/remove
      // interviewers.
      changes.optionalities = true;
    }

    const prevTrainings = matchingPrevInterview.interviewers ? matchingPrevInterview.interviewers.map(({ interviewer_template }) => trainingProgramEligibilityOfSlot(interviewer_template.interviewer_filters || [])) : [];
    const trainings = interview.interviewers ? interview.interviewers.map(({ interviewer_template }) => trainingProgramEligibilityOfSlot(interviewer_template.interviewer_filters || [])) : [];
    if (prevTrainings.length === trainings.length && !isEqual(prevTrainings, trainings)) {
      // Only show the training message if they didn't add/remove
      // interviewers.
      changes.trainings = true;
    }

    const prevStartTime = Moment.tz(matchingPrevInterview.start_time, oldSchedule.timezone);
    const prevEndTime = Moment.tz(matchingPrevInterview.start_time, oldSchedule.timezone).add(matchingPrevInterview.interview_template.duration_minutes, 'minutes');
    const startTime = Moment.tz(interview.start_time, newSchedule.timezone);
    const endTime = Moment.tz(interview.start_time, newSchedule.timezone).add(interview.interview_template.duration_minutes, 'minutes');

    // Only check if the times changed (not the date).
    if (prevStartTime.format('HH:mm') !== startTime.format('HH:mm') || prevEndTime.format('HH:mm') !== endTime.format('HH:mm')) {
      changes.times = true;
    }

    return {
      name: interview.name,
      changes,
    };
  });

  return [...removedInterviews, ...newAndUpdatedInterviews];
};

export const formatInterviewNames = (interviews: (EditableInterview | InterviewChanges)[]) => (
  <span>
    {interviews.map(({ name }, i) => (
      <span key={`interview-name-${i}`}>
        {i !== 0 && interviews.length > 2 && ','}
        {i !== 0 && i === interviews.length - 1 && ' and'}
        <b> {name}</b>
      </span>
    ))}
  </span>
);
