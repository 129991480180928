import { createContext, useContext, useEffect, useMemo } from 'react';

import { useAuth } from './queries/auth';

import type { Account, User, Session } from '../types';
import type { InterviewPlannerError } from '../libraries/interviewplanner';
import type { ReactNode } from 'react';
import type { UseQueryResult } from 'react-query';

interface SessionContextState {
  account?: Account;
  currentUser?: User;
  isSessionFetching: boolean;
  isSessionLoading: boolean;
  session?: Session;
  sessionError?: InterviewPlannerError;
  refetchSession?: () => Promise<UseQueryResult>;
}

// This is the context for keeping track of the current user's session. You
// should keep track of the session with a useState call in the same component
// that uses the Provider, recreate an array using useMemo, and then pass it
// into the Provider. Make sure you don't pass an array literal into the
// Provider. This can cause re-rendering issues as noted here:
// https://reactjs.org/docs/context.html#caveats.
const SessionContext = createContext<SessionContextState>({
  isSessionFetching: false,
  isSessionLoading: false,
});

interface Props {
  children: ReactNode;
}

export const SessionProvider = ({ children }: Props) => {
  const {
    data: session,
    error: sessionError,
    isFetching: isSessionFetching,
    isLoading: isSessionLoading,
    refetch: refetchSession,
  } = useAuth({
    refetchInterval: 30 * 60 * 1000, // 30m which matches the interval in Gem
    refetchOnMount: 'always',
    refetchOnWindowFocus: 'always',
  });

  const contextValue: SessionContextState = useMemo(() => ({
    account: session?.account,
    currentUser: session?.user,
    isSessionFetching,
    isSessionLoading,
    session,
    sessionError: sessionError || undefined,
    refetchSession,
  }), [
    isSessionFetching,
    isSessionLoading,
    refetchSession,
    session,
    sessionError,
  ]);

  useEffect(() => {
    if (!isSessionLoading && session) {
      analytics.identify(session.user_id, {
        language: window.navigator.language,
      });
    }
  }, [isSessionLoading, session]);

  return (
    <SessionContext.Provider value={contextValue}>
      {children}
    </SessionContext.Provider>
  );
};

export const useSession = () => {
  return useContext(SessionContext);
};
