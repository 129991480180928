import { faAngleDown, faAngleUp, faArrowRight, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { isValidElement, useMemo, useState } from 'react';
import { mapValues } from 'lodash';

import Button from 'components/library/inputs/Button';
import { formatDuration, formatPositions, formatTime } from 'libraries/formatters';
import InterviewerTemplatesSummary from 'components/library/data-display/InterviewerTemplatesSummary';
import Table from 'components/library/data-display/Table';
import { StyledExpandingDetailsContainer, StyledEmptyValue } from './styles';

import type { InterviewTemplate, InterviewerTemplate } from 'types';
import type { ReactNode } from 'react';
import type { TableSchema } from 'components/library/data-display/Table';

interface Props {
  additionalDetails?: ReactNode;
  parentTemplate?: InterviewTemplate;
  updates: Partial<InterviewTemplate>; // interview template updates only
}

const ExpandingDetails = ({ additionalDetails, parentTemplate, updates }: Props) => {
  const [isExpanded, setIsExpanded] = useState<boolean>(false);

  // The displayValue function always checks if the value is a React element first, to display the arrow.
  const schema = useMemo<TableSchema<Partial<InterviewTemplate>>>(() => [
    Boolean(updates.duration_minutes) && {
      header: 'Duration',
      displayValue: ({ duration_minutes }) => isValidElement(duration_minutes) ? duration_minutes : formatDuration(duration_minutes!),
    },
    updates.positions && {
      header: 'Positions',
      displayValue: ({ positions }) => isValidElement(positions) ? positions : formatPositions(positions),
    },
    Boolean(updates.time_window_start) && {
      header: 'Time Window Start',
      displayValue: ({ time_window_start }) => isValidElement(time_window_start) ? time_window_start : formatTime(time_window_start),
    },
    Boolean(updates.time_window_end) && {
      header: 'Time Window End',
      displayValue: ({ time_window_end }) => isValidElement(time_window_end) ? time_window_end : formatTime(time_window_end),
    },
    updates.live_coding_enabled && {
      header: 'Live Coding Link',
      displayValue: ({ live_coding_enabled }) => isValidElement(live_coding_enabled) ? live_coding_enabled : (
        live_coding_enabled ?
          <FontAwesomeIcon className="check" icon={faCheck} /> :
          <StyledEmptyValue>&mdash;</StyledEmptyValue>
      ),
    },
    Boolean(updates.candidate_facing_name) && {
      header: 'Candidate-Facing Name',
      displayValue: ({ candidate_facing_name }) => isValidElement(candidate_facing_name) ? candidate_facing_name : (candidate_facing_name || <StyledEmptyValue>&mdash;</StyledEmptyValue>),
    },
    Boolean(updates.candidate_facing_details) && {
      header: 'Candidate-Facing Details',
      displayValue: ({ candidate_facing_details }) => isValidElement(candidate_facing_details) ? candidate_facing_details : (
        candidate_facing_details ?
          <div dangerouslySetInnerHTML={{ __html: candidate_facing_details }} /> :
          <StyledEmptyValue>Not set</StyledEmptyValue>
      ),
    },
    Boolean(updates.interviewer_templates) && {
      header: 'Panel',
      displayValue: ({ interviewer_templates }) => (
        isValidElement(interviewer_templates) ?
          interviewer_templates :
          <InterviewerTemplatesSummary interviewerTemplates={interviewer_templates as InterviewerTemplate[]} />
      ),
    },
  ], [updates]);

  return (
    <>
      <Button
        color="no-outline"
        iconRight={<FontAwesomeIcon icon={isExpanded ? faAngleUp : faAngleDown} />}
        onClick={() => setIsExpanded(!isExpanded)}
        size="small"
        value="What changed?"
      />
      {isExpanded && (
        <StyledExpandingDetailsContainer>
          <Table
            data={(
              parentTemplate ?
                [
                  parentTemplate,
                  mapValues(updates, () => <FontAwesomeIcon icon={faArrowRight} />) as Partial<InterviewTemplate>,
                  updates,
                ] :
                [updates]
            )}
            layout="horizontal"
            schema={schema}
          />
          {additionalDetails}
        </StyledExpandingDetailsContainer>
      )}
    </>
  );
};

export default ExpandingDetails;
