import Moment from 'moment';
import { Breadcrumb } from 'react-breadcrumbs';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { capitalize } from 'lodash';
import { useMediaQuery } from 'react-responsive';

import ArchiveButton from '../../../library/inputs/ArchiveButton';
import Flash from '../../../library/utils/Flash';
import InterviewerDetails from './InterviewerDetails';
import InterviewerInterviewHistory from './InterviewerInterviewHistory';
import InterviewerSchedulingPreferences from './InterviewerSchedulingPreferences';
import Label from '../../../library/utils/Label';
import PageHeader from '../../../library/layout/PageHeader';
import StatusIndicator from '../../../library/utils/StatusIndicator';
import { formatMoment, TimeFormat } from '../../../../libraries/time';
import { useSession } from '../../../../hooks/use-session';
import { useUpdateUser, useUser } from '../../../../hooks/queries/users';
import { correctPath } from 'libraries/gem';

const Interviewer = () => {
  const { id } = useParams<{ id: string }>();

  const { account, currentUser } = useSession();

  const {
    data: user,
    error: userError,
    isLoading: isUserLoading,
  } = useUser(id);

  const updateUserMutation = useUpdateUser();

  const isSmallScreen = useMediaQuery({ query: '(max-width: 1000px)' });

  const handleArchive = () => {
    updateUserMutation.reset();
    updateUserMutation.mutate({ id, payload: { archived: true } });
  };

  const handleUnarchive = () => {
    updateUserMutation.reset();
    updateUserMutation.mutate({ id, payload: { archived: false } });
  };

  if (isUserLoading) {
    return null;
  }

  return (
    <Breadcrumb
      data={{
        title: user?.name || id,
        pathname: correctPath(`/app/interviewers/${id}`),
      }}
    >
      <div className="interviewer-container">
        <Flash
          message={userError?.message}
          showFlash={Boolean(userError)}
          type="danger"
        />
        <Flash
          isDismissible
          message={updateUserMutation.error?.message}
          onDismiss={updateUserMutation.reset}
          showFlash={updateUserMutation.isError}
          type="danger"
        />
        <Flash
          isDismissible
          message={`Interviewer ${user?.user_archived ? 'archived' : 'unarchived'}.`}
          onDismiss={updateUserMutation.reset}
          showFlash={updateUserMutation.isSuccess}
          type="success"
        />
        {user &&
          <>
            <PageHeader
              details={
                <>
                  {account?.ats_type ?
                    <StatusIndicator color={user.ats_id ? 'green' : 'gray'}>
                      {user.ats_id ? 'Has' : 'No'} {capitalize(account.ats_type)} account
                    </StatusIndicator> :
                    null
                  }
                  {account?.ats_type && !isSmallScreen && <span className="separator">|</span>}
                  {!isSmallScreen && <span>Imported {formatMoment(Moment(user.created_at), TimeFormat.LongMonthDayYear)}</span>}
                </>
              }
              navbarLinks={[{
                label: 'Scheduling Preferences',
                location: correctPath(`/app/interviewers/${id}/scheduling-preferences`),
              }, {
                label: 'Details',
                location: correctPath(`/app/interviewers/${id}/details`),
              }, {
                label: 'Interview History',
                location: correctPath(`/app/interviewers/${id}/interview-history`),
              }]}
              title={user.name}
              titleIcon={user.user_archived || user.directory_archived ? <Label color="gray">Archived</Label> : undefined}
            >
              {id !== currentUser?.id && (
                <ArchiveButton
                  id={id}
                  isArchived={user.user_archived || user.directory_archived}
                  onArchive={handleArchive}
                  onUnarchive={handleUnarchive}
                />
              )}
            </PageHeader>
            <Switch>
              <Redirect exact from={correctPath('/app/interviewers/:id')} to={correctPath('/app/interviewers/:id/scheduling-preferences')} />
              <Route component={InterviewerSchedulingPreferences} path={correctPath('/app/interviewers/:id/scheduling-preferences')} />
              <Route component={InterviewerDetails} path={correctPath('/app/interviewers/:id/details')} />
              <Route component={InterviewerInterviewHistory} path={correctPath('/app/interviewers/:id/interview-history')} />
            </Switch>
          </>
        }
      </div>
    </Breadcrumb>
  );
};

export default Interviewer;
