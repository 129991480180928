import styled from 'styled-components';

import SelectInput from '../SelectInput';

// The typing for this is weird because of styled-components. This is inspired from
// https://github.com/styled-components/styled-components/issues/1803#issuecomment-857092410
export const StyledSelectInput = styled(SelectInput)`
  /* This allows the Avatar circle to not get cropped by the input padding. */
  .select-input__value-container,
  .select-input__single-value {
    overflow: visible;
  }
` as typeof SelectInput;
