import styled from 'styled-components';

import Card from 'components/library/layout/Card';

export const StyledSection = styled.section`
  flex-direction: column;
  max-width: 95vw;

  p {
    color: ${({ theme }) => theme.colors.doveGray};
  }

  .carousel-root {
    width: 900px;
    max-width: 100%;
  }

  .carousel {
    display: flex;
    align-items: center;
  }

  @media only screen and (max-width: 930px) {
    max-width: 100vw;

    /* This important is only necessary because of the .home-container section styles from home.scss. That style block
     * takes precedence over these styles, so it wouldn't otherwise overwrite them. When those styles have been removed,
     * then we can remove the important. */
    padding: 60px 0 !important;
  }
`;

export const StyledDivider = styled.hr`
  border: .5px solid ${({ theme }) => theme.colors.mercuryGray};
  border-right-width: 0;
  border-left-width: 0;
  width: 100%;
  max-width: 1100px;
`;

export const StyledCardContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 280px;
  padding: 1px;

  @media only screen and (max-width: 580px) {
    min-height: 60vh;
  }
`;

export const StyledCard = styled(Card)`
  margin: 0 auto;
  padding-bottom: 0;
  text-align: left;
  width: 100%;

  .card-content {
    color: ${({ theme }) => theme.colors.mineshaftBlack};

    .avatar {
      border: none;

      &.avatar-large {
        height: 42px;
        width: 42px;
      }
    }
  }
`;

export const StyledQuote = styled.div`
  line-height: 1.8;

  em {
    color: ${({ theme }) => theme.colors.zodiacBlue};
    font-style: normal;
    font-weight: bold;
  }
`;

export const StyledSource = styled.div`
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.mercuryGray};
  display: flex;
  gap: 1em;
  margin-top: 1em;
  padding: 1em 0 1em 0;
  text-align: left;
`;

export const StyledSourceRole = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.doveGray};
  display: flex;
  font-size: .8rem;
  letter-spacing: .06em;
  text-transform: uppercase;

  .avatar {
    margin-left: .5em;
  }
`;

export const StyledArrowButton = styled.button`
  align-items: center;
  border-radius: 50%;
  border: none;
  color: ${({ theme }) => theme.colors.curiousBlue};
  cursor: pointer;
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  height: 42px;
  justify-content: center;
  margin: 1em;
  width: 42px;
  z-index: 1;

  &:hover {
    background: ${({ theme }) => theme.colors.alabasterWhite};
  }

  @media only screen and (max-width: 930px) {
    margin: 0;
    position: relative;
  }
`;
