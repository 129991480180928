import { useCallback } from 'react';

import { StyledStatusSelectInput } from './styles';

import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../../../library/inputs/SelectInput/types';

const options: Option<string>[] = [{
  label: 'Trainee',
  value: 'trainee',
}, {
  label: 'Graduate',
  value: 'graduate',
}];

interface Props {
  trainee: boolean;
  onChange: (newTrainee: boolean) => void;
}

const TrainingStatusSelectInput = ({ trainee, onChange }: Props) => {
  const handleChange = useCallback((option: OnChangeValue<Option<string>, false>) => {
    onChange(option!.value === 'trainee');
  }, [onChange]);

  return (
    <StyledStatusSelectInput
      isRequired
      onChange={handleChange}
      options={options}
      value={trainee ? options[0] : options[1]}
    />
  );
};

export default TrainingStatusSelectInput;
