import Moment from 'moment';
import { formatISO } from 'date-fns';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import AvailabilityPicker from '../../../../../library/inputs/AvailabilityPicker';
import Flash from '../../../../../library/utils/Flash';
import LoadingSpinner from '../../../../../library/utils/LoadingSpinner';
import Section from '../../../../../library/layout/Section';
import { useAvailability, useUpdateAvailability } from '../../../../../../hooks/queries/availabilities';

import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../../../../library/inputs/SelectInput/types';
import type { TimeSlot } from '../../../../../library/inputs/AvailabilityPicker/types';
import type { UpdateAvailabilityPayload } from '../../../../../../hooks/queries/availabilities';

const SuggestedTimesSection = () => {
  const { availabilityId } = useParams<{ id: string; availabilityId: string }>();

  const { data: availability } = useAvailability(availabilityId);

  const updateAvailabilityMutation = useUpdateAvailability();

  const [isEditing, setIsEditing] = useState(false);
  const [timezone, setTimezone] = useState(Moment.tz.guess());
  const [suggestedTimes, setSuggestedTimes] = useState<TimeSlot[]>(availability?.availability_template?.suggested_times || []);

  useEffect(() => {
    if (availability?.stage) {
      setTimezone(
        availability.availability_template?.business_hours?.find((bh) => bh.timezone)?.timezone ||
        availability.stage.schedule_template?.business_hours?.find((bh) => bh.timezone)?.timezone ||
        Moment.tz.guess()
      );
    }
  }, [availabilityId, Boolean(availability?.stage)]);

  useEffect(() => {
    setSuggestedTimes(availability?.availability_template?.suggested_times || []);
  }, [availability?.availability_template?.suggested_times]);

  const handleTimezoneChange = (option: OnChangeValue<Option<string>, false>) => {
    setTimezone(option?.value || Moment.tz.guess());
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setSuggestedTimes(availability?.availability_template?.suggested_times || []);
    setIsEditing(false);
    updateAvailabilityMutation.reset();
  };

  const handleSave = async () => {
    updateAvailabilityMutation.reset();

    const payload: UpdateAvailabilityPayload = {
      availability_template: {
        suggested_times: suggestedTimes.map(({ start_time, end_time }) => ({
          start_time: start_time instanceof Date ? formatISO(start_time) : start_time,
          end_time: end_time instanceof Date ? formatISO(end_time) : end_time,
        })),
      },
    };

    try {
      const data = await updateAvailabilityMutation.mutateAsync({ id: availabilityId, payload });
      setSuggestedTimes(data.availability_template?.suggested_times || []);
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  const isEditable = Boolean(availability?.manual || !Boolean(availability?.ats_id));

  return (
    <Section
      className="times-section"
      isEditable={isEditable}
      isEditing={isEditing}
      isSaving={updateAvailabilityMutation.isLoading}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Suggested times"
    >
      <Flash
        message={updateAvailabilityMutation.error?.message}
        showFlash={updateAvailabilityMutation.isError}
        type="danger"
      />
      <Flash
        isDismissible
        message="Successfully updated!"
        onDismiss={updateAvailabilityMutation.reset}
        showFlash={updateAvailabilityMutation.isSuccess}
        type="success"
      />
      {availability?.stage ?
        ((availability.availability_template?.suggested_times || isEditing) ?
          <AvailabilityPicker
            allowPastTimes={Boolean(availability.availability_template?.suggested_times)}
            availabilities={suggestedTimes || []}
            businessHours={availability.availability_template?.business_hours || availability.stage.schedule_template?.business_hours || []}
            controlledTimezone={timezone}
            dataDescriptor="suggested time"
            eventTitle="Suggested"
            isDraggable={isEditing}
            isRequired={false}
            minDuration={availability.availability_template?.minimum_duration_minutes}
            onTimezoneChange={handleTimezoneChange}
            setAvailabilities={isEditing ? setSuggestedTimes : undefined}
            showEventWarnings={false}
            showQuickSelectOptions={false}
          /> :
          <div className="no-availability">No suggested times provided</div>
        ) :
        <LoadingSpinner />
      }
    </Section>
  );
};

export default SuggestedTimesSection;
