import { useEffect, useRef, useState } from 'react';

import ColorPickerMenuList from './ColorPickerMenuList';
import SelectInput from '../SelectInput';
import { ColorProvider } from './use-color';

import type { ReactNode } from 'react';
import type { SelectInstance } from 'react-select';
import type { Group, Option } from '../SelectInput/types';
import type { InputActionMeta } from 'react-select';

interface Props {
  helperText?: ReactNode;
  label?: string;
  isDisabled?: boolean;
  onChange: (value: { hex: string } | null) => void;
  selectedColor: string;
}

const ColorInput = ({ helperText, isDisabled = false, label, onChange, selectedColor }: Props) => {
  const [inputValue, setInputValue] = useState(selectedColor || '');

  const ref = useRef<SelectInstance<Option<string>, false, Group<string, Option<string>>>>(null);

  useEffect(() => {
    if (selectedColor !== inputValue) {
      setInputValue(selectedColor);
    }
  }, [selectedColor]);

  const handleChange = (option: Option<string> | null) => {
    ref.current?.blur();
    onChange(option ? { hex: option.value } : null);
    ref.current?.focus();
  };

  const handleInputChange = (input: string, { action }: InputActionMeta) => {
    if (action === 'input-change') {
      onChange(input ? { hex: input.startsWith('#') ? input : `#${input}` } : null);
    }
  };

  const handleFocus = () => {
    setInputValue(selectedColor);
  };

  // This is done because stylelint complains about the camelCase nature of the
  // selectedColor variable. This is a false positive, but it seems like we'll
  // need to upgrade to stylelint v14 to fix it, and that's a bigger change.
  const background = selectedColor;

  return (
    <ColorProvider color={selectedColor} setColor={onChange}>
      <SelectInput
        className="color-input"
        components={{ MenuList: ColorPickerMenuList }}
        helperText={helperText}
        icon={<div className={`color-swatch${selectedColor ? '' : ' empty'}`} style={{ background }} />}
        inputValue={inputValue}
        isClearable
        isDisabled={isDisabled}
        label={label}
        onChange={handleChange}
        onFocus={handleFocus}
        onInputChange={handleInputChange}
        options={selectedColor ? [{ label: selectedColor, value: selectedColor }] : []}
        placeholder="Select a color or enter a hex code"
        ref={ref}
        value={selectedColor ? { label: selectedColor, value: selectedColor } : undefined}
      />
    </ColorProvider>
  );
};

export default ColorInput;
