import Moment from 'moment-timezone';
import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import Avatar from 'components/library/data-display/Avatar';
import ListItem from 'components/library/data-display/ListItem';
import Section from 'components/library/layout/Section';
import Flash from 'components/library/utils/Flash';
import { useInterviewBatch, useUpdateInterviewBatch } from 'hooks/queries/interview-batches';
import { useUsersMap } from 'hooks/queries/users';
import { correctPath } from 'libraries/gem';
import { formatMoment, TimeFormat } from 'libraries/time';
import { StyledTabContainer } from '../styles';
import { StyledDateCreatedContainer, StyledTable } from './styles';

import type { InterviewBatch } from 'types';
import type { TableSchema } from 'components/library/data-display/Table';

const InterviewBatchDetails = () => {
  const { id: interviewBatchId } = useParams<{ id: string }>();

  const interviewBatch = useInterviewBatch(interviewBatchId).data!;
  const users = useUsersMap({ archived: true });

  const [name, setName] = useState(interviewBatch.name);
  const [isEditing, setIsEditing] = useState(false);

  const updateInterviewBatchMutation = useUpdateInterviewBatch();

  const schema = useMemo<TableSchema<InterviewBatch>>(() => [{
    header: 'Name',
    displayValue: ({ name }) => name,
  }, {
    header: 'Job',
    displayValue: ({ job }) => job?.name,
  }, {
    header: 'Date Created',
    displayValue: ({ created_at, creator_id }) => (
      <StyledDateCreatedContainer>
        <span>
          {formatMoment(Moment(created_at), TimeFormat.LongMonthDayYear)} by
        </span>
        <ListItem
          label={users[creator_id]?.name || users[creator_id]?.email || creator_id}
          leftIcon={(
            <Avatar
              showUserTooltip={false}
              size="small"
              userId={creator_id}
            />
          )}
        />
      </StyledDateCreatedContainer>
    ),
  }], [
    isEditing,
    updateInterviewBatchMutation,
    interviewBatch,
    users,
  ]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    updateInterviewBatchMutation.reset();
    setName(interviewBatch.name);
    setIsEditing(false);
  };

  const handleSave = async () => {
    updateInterviewBatchMutation.reset();

    try {
      updateInterviewBatchMutation.mutateAsync({
        id: interviewBatchId,
        payload: {
          name,
        },
      });
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  return (
    <Breadcrumb
      data={{
        title: 'Details',
        pathname: correctPath(`/app/superdays/${interviewBatchId}/details`),
      }}
    >
      <StyledTabContainer>
        <Helmet>
          <title>{interviewBatch.name} | Details | Superdays | Gem Scheduling</title>
        </Helmet>
        <Section
          // TODO: Make editable
          // isEditable
          isEditing={isEditing}
          onCancel={handleCancel}
          onEdit={handleEdit}
          onSave={handleSave}
          title="Details"
        >
          <Flash
            message={updateInterviewBatchMutation.error?.message}
            showFlash={updateInterviewBatchMutation.isError}
            type="danger"
          />
          <Flash
            message="Successfully updated!"
            showFlash={updateInterviewBatchMutation.isSuccess}
            type="success"
          />
          <StyledTable
            data={[interviewBatch] as InterviewBatch[]}
            isEditing={isEditing}
            layout="horizontal"
            schema={schema}
          />
        </Section>
      </StyledTabContainer>
    </Breadcrumb>
  );
};

export default InterviewBatchDetails;
