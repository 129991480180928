import Moment from 'moment-timezone';
import { groupBy, sum } from 'lodash';
import { useHistory } from 'react-router-dom';
import { useMemo, useState } from 'react';

import Modal from '../layout/Modal';
import RadioButtonGroupInput from '../inputs/RadioButtonGroupInput';
import pluralize from '../../../libraries/pluralize';
import { formatMoment, TimeFormat } from '../../../libraries/time';

import type { ChangeEvent, MouseEventHandler } from 'react';
import type { RadioButtonOption } from '../inputs/RadioButtonGroupInput';
import { correctPath } from 'libraries/gem';

interface Props {
  application: {
    id: string;
    held_schedules: {
      id: string;
      interviews: {
        start_time: string;
      }[];
      timezone: string;
    }[] | null;
    current_stage?: {
      name: string;
    };
    candidate: {
      name?: string;
    };
  };
  isOpen: boolean;
  onToggle?: MouseEventHandler<HTMLButtonElement>;
}

const UseHeldScheduleModal = ({ application, isOpen, onToggle }: Props) => {
  const history = useHistory();

  const options = useMemo<RadioButtonOption[]>(() => {
    const schedulesByBlockId = groupBy(application.held_schedules || [], 'block_id');
    const scheduleOptions: RadioButtonOption[] = [];
    // Single-block schedules
    schedulesByBlockId['null']?.forEach((schedule) => {
      scheduleOptions.push({
        label: <span>Use schedule with {schedule.interviews.length} interview{schedule.interviews.length === 1 ? '' : 's'} on hold for <b>{formatMoment(Moment.tz(schedule.interviews[0].start_time, schedule.timezone), TimeFormat.LongDayOfWeekMonthDayAtTimeAndTimezone)}</b></span>,
        value: schedule.id,
      });
    });
    // Multi-block schedules
    Object.entries(schedulesByBlockId).filter(([blockId]) => blockId !== 'null')
    .forEach(([, schedules]) => {
      const totalNumberOfInterviews = sum(schedules.map(({ interviews }) => interviews.length));
      scheduleOptions.push({
        label: (
          <span>
            Use multi-block schedule with {totalNumberOfInterviews} {pluralize('interview', totalNumberOfInterviews)} on hold:
            <ul>
              {schedules.map(({ id, interviews, timezone }) => (
                <li key={id}>
                  {interviews.length} {pluralize('interview', interviews.length)} on <b>{formatMoment(Moment.tz(interviews[0].start_time, timezone), TimeFormat.LongDayOfWeekMonthDayAtTimeAndTimezone)}</b>
                </li>
              ))}
            </ul>
          </span>
        ),
        value: schedules[0].id,
      });
    });

    return [
      ...scheduleOptions,
      {
        label: 'Create a new schedule',
        value: 'new',
      },
    ];
  }, [application.held_schedules]);

  const [selectedSchedule, setSelectedSchedule] = useState(options[0].value);

  const handleOptionChange = (e: ChangeEvent<HTMLInputElement>) => setSelectedSchedule(e.target.value);

  const handleSubmit = () => {
    if (selectedSchedule === 'new') {
      history.push(correctPath(`/app/candidates/${application.id}/schedule`));
    } else {
      history.push(correctPath(`/app/candidates/${application.id}/schedule/review?schedule=${selectedSchedule}`));
    }
  };

  if (options.length === 1) {
    return null;
  }

  // Subtract 1 for the 'Create a new schedule' option
  const numberOfHeldSchedules = options.length - 1;

  return (
    <Modal
      isOpen={isOpen}
      onSubmit={handleSubmit}
      onToggle={onToggle}
      submitButtonValue={selectedSchedule === 'new' ? 'Schedule' : 'Skip to Review Step'}
      title="Use schedule on hold?"
    >
      You have put {numberOfHeldSchedules === 1 ? 'a schedule' : `${numberOfHeldSchedules} schedules`} on hold for <b>{application?.current_stage?.name}</b> for <b>{application.candidate.name}</b>. You can skip to the end of the scheduling workflow by using {application.held_schedules?.length === 1 ? 'this schedule' : 'one of these schedules'}.
      <div className="use-held-schedule-options">
        <RadioButtonGroupInput
          layout="vertical"
          name="held-schedule"
          onChange={handleOptionChange}
          options={options}
          value={selectedSchedule}
        />
      </div>
    </Modal>
  );
};

export default UseHeldScheduleModal;
