import { Breadcrumbs as ReactBreadcrumbs } from 'react-breadcrumbs';

const Breadcrumbs = () =>
  <ReactBreadcrumbs
    className="breadcrumbs-container"
    separator="/"
  />
;

export default Breadcrumbs;
