import Moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';
import { faUnlink } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';

import Avatar from '../../../../../library/data-display/Avatar';
import CopyToClipboardButton from '../../../../../library/inputs/CopyToClipboardButton';
import Flash from '../../../../../library/utils/Flash';
import ListItem from '../../../../../library/data-display/ListItem';
import LoadingSpinner from '../../../../../library/utils/LoadingSpinner';
import OutboundLink from '../../../../../library/navigation/OutboundLink';
import Section from '../../../../../library/layout/Section';
import Table from '../../../../../library/data-display/Table';
import Tag from '../../../../../library/data-display/Tag';
import Tooltip from '../../../../../library/utils/Tooltip';
import { formatMoment, TimeFormat } from '../../../../../../libraries/time';
import { useApplication } from '../../../../../../hooks/queries/applications';
import { useCalendars } from '../../../../../../hooks/queries/calendars';
import { useSchedule } from '../../../../../../hooks/queries/schedules';
import { useSession } from '../../../../../../hooks/use-session';
import { useUsersMap } from '../../../../../../hooks/queries/users';
import { videoConferencingLabels } from '../../../../../../types';

import type { Schedule } from '../../../../../../types';
import type { TableSchema } from '../../../../../library/data-display/Table';
import { correctPath } from 'libraries/gem';

const ScheduleSummarySection = () => {
  const { id, scheduleId } = useParams<{ id: string; scheduleId: string }>();

  const { account } = useSession();
  const {
    data: calendars,
    error: calendarsError,
    isLoading: isCalendarsLoading,
  } = useCalendars();
  const { data: application } = useApplication(id);
  const { data: schedule } = useSchedule(scheduleId);
  const users = useUsersMap({ archived: true });

  const schema = useMemo<TableSchema<Schedule>>(() => [{
    header: 'Interview Date',
    displayValue: ({ interviews, timezone }) => formatMoment(Moment.tz(interviews[0].start_time, timezone), TimeFormat.LongDayOfWeekMonthDayYear),
  }, {
    header: 'Interview Time',
    displayValue: ({ interviews, timezone }) => {
      const firstInterview = interviews[0];
      const lastInterview = interviews[interviews.length - 1];
      return (
        <span>
          {formatMoment(Moment.tz(firstInterview.start_time, timezone), TimeFormat.Time)}&ndash;{formatMoment(Moment.tz(lastInterview.start_time, timezone).add(lastInterview.interview_template.duration_minutes, 'minutes'), TimeFormat.TimeWithTimezone)}
        </span>
      );
    },
  }, schedule?.self_scheduling_link && {
    header: (
      <span className="self-scheduling-link-header">
        Link
        {(schedule.self_scheduling_link.stage_id !== application?.current_stage_id || schedule.self_scheduling_link.status === 'cancelled') && (
          <>
            <span data-for={`${schedule.self_scheduling_link.id}-link-expired`} data-tip>
              <FontAwesomeIcon icon={faUnlink} />
            </span>
            <Tooltip
              id={`${schedule.self_scheduling_link.id}-link-expired`}
              position="top"
              value={schedule.self_scheduling_link.status === 'cancelled' ? 'Cancelled' : 'Expired'}
            />
          </>
        )}
      </span>
    ),
    displayValue: ({ self_scheduling_link: { id, stage_id, status } }) => (
      <div className="schedule-link">
        <Link
          className={(stage_id !== application?.current_stage_id || status === 'cancelled') ? 'expired' : undefined}
          target="_blank"
          to={correctPath(`/schedule/${id}`)}
        >
          {window.origin}{correctPath(`/schedule/${id}`)}
        </Link>
        {stage_id === application?.current_stage_id && status !== 'cancelled' && (
          <CopyToClipboardButton textToCopy={`${window.origin}${correctPath(`/schedule/${id}`)}`} />
        )}
      </div>
    ),
  }, Boolean(schedule?.candidate_event_location) && {
    header: 'Location',
    displayValue: ({ candidate_event_location, interviews }) => (
      interviews[0].video_conferencing_meeting_id && candidate_event_location ?
        <OutboundLink
          href={candidate_event_location}
          label="Schedule Summary Table Video Conferencing Link"
        >
          {candidate_event_location}
        </OutboundLink> :
        candidate_event_location
    ),
  }, Boolean(schedule?.schedule_template.video_conferencing_enabled && schedule.interviews[0].video_conferencing_passcode && account?.video_conferencing_type) && {
    header: `${videoConferencingLabels[account!.video_conferencing_type!]} Passcode`,
    displayValue: ({ interviews }) => interviews[0].video_conferencing_passcode,
  }, Boolean(schedule?.schedule_template.video_conferencing_enabled && account?.video_conferencing_type === 'zoom' && schedule.interviews[0].zoom_host_id) && {
    header: 'Zoom Host',
    displayValue: ({ interviews }) => (
      interviews[0].zoom_host_type === 'user' && interviews[0].zoom_host_id ?
        <ListItem
          label={users[interviews[0].zoom_host_id]?.name || users[interviews[0].zoom_host_id]?.email}
          leftIcon={<Avatar
            showUserTooltip={false}
            size="small"
            userId={interviews[0].zoom_host_id}
          />}
        /> :
        <Tag
          type="room"
          value={interviews[0].zoom_host_id!}
        />
    ),
  }, {
    header: 'Room',
    displayValue: ({ interviews }) => (
      interviews[0].room_id ?
        <Tag
          type="room"
          value={interviews[0].room_id}
        /> :
        <i>No room</i>
    ),
  }, {
    header: 'Scheduling Calendar',
    displayValue: ({ scheduling_calendar_email }) => {
      if (calendarsError) {
        return (
          <Flash
            message={calendarsError.message}
            showFlash
            type="danger"
          />
        );
      }

      if (isCalendarsLoading) {
        return <LoadingSpinner />;
      }

      // TODO: Display a better error. This can happen if the current user
      // doesn't have access to the scheduling calendar of this schedule.
      return calendars?.calendars[scheduling_calendar_email]?.name || 'Unknown';
    },
  }, {
    header: 'Scheduling Calendar for Candidate Events',
    displayValue: ({ candidate_scheduling_calendar_email }) => {
      if (calendarsError) {
        return (
          <Flash
            message={calendarsError.message}
            showFlash
            type="danger"
          />
        );
      }

      if (isCalendarsLoading) {
        return <LoadingSpinner />;
      }

      // TODO: Display a better error. This can happen if the current user
      // doesn't have access to the candidate scheduling calendar of this schedule.
      return calendars?.calendars[candidate_scheduling_calendar_email]?.name || 'Unknown';
    },
  }, {
    header: 'Date Scheduled',
    displayValue: ({ created_at, creator_id, self_scheduling_link_id }) => (
      <div className="date-scheduled">
        <span>{formatMoment(Moment(created_at), TimeFormat.LongMonthDayYear)}{creator_id && !self_scheduling_link_id && ' by'}</span>
        {creator_id && !self_scheduling_link_id &&
          <ListItem
            label={users[creator_id]?.name || users[creator_id]?.email}
            leftIcon={<Avatar
              showUserTooltip={false}
              size="small"
              userId={creator_id}
            />}
          />
        }
      </div>
    ),
  }], [
    application,
    calendars,
    calendarsError,
    isCalendarsLoading,
    schedule,
    users,
  ]);

  if (!schedule) {
    return null;
  }

  return (
    <Section className="summary-section" title="Summary">
      <Table
        data={[schedule]}
        layout="horizontal"
        schema={schema}
      />
    </Section>
  );
};

export default ScheduleSummarySection;
