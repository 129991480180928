import { Competitor } from 'types';
import FeatureComparisonTable from './FeatureComparisonTable';
import Section from 'components/library/layout/Section';

const GoodTime = () => {
  return (
    <div>
      <Section title="Overview">
        <p>
          GoodTime was the first automated scheduling platform to become popular with hiring teams. They have the broadest feature set and support the widest variety of scheduling workflows. However, they have recently had reliability issues and bugs, and we have heard reports of declining quality of customer support. GoodTime is also notoriously complicated to learn and configure, though it is powerful if done right.
        </p>
        <FeatureComparisonTable competitors={[Competitor.GoodTime]} />
      </Section>
      <Section title="Candidate experience">
        <p>
          InterviewPlanner allows you to send emails that have a personal touch to them, like they were sent directly from the scheduler&apos;s inbox rather than from an automated tool. GoodTime sends emails that are heavy on company branding and styles, which creates a more automated feel to the process, but may be preferred by some companies who like the branded look.
        </p>
        <p>
          InterviewPlanner and GoodTime both allow you to customize candidate-facing pages, like availability requests and self-scheduling links, with your company&apos;s logo and brand color.
        </p>
      </Section>
      <Section title="Interviewer experience">
        <p>
          InterviewPlanner and GoodTime both take into account interviewers&apos; preferences such as interview limits, interviewer working hours, keywords for events that can be booked over. InterviewPlanner and GoodTime both automatically try to balance interview load when selecting from a pool of interviewers.
        </p>
      </Section>
      <Section title="Scheduling">
        <p>
          InterviewPlanner and GoodTime both automatically generate schedule options that consider interviewer and candidate availability, interviewer preferences, and more. You can fully customize every aspect of the schedule in InterviewPlanner, but not easily in GoodTime. You can re-order interviews, swap interviewers, add video conferencing, etc. from within the scheduling workflow in InterviewPlanner. Only in InterviewPlanner, you can also view interviewers&apos; calendars in a side-by-side view as you edit your schedule.
        </p>
        <p>
          Both platforms let you configure default settings for interview loops, including interview durations, interviewers, breaks, and position constraints.
        </p>
        <p>
          Rescheduling or updating interviews is possible in both platforms. With GoodTime, you can automate swapping an interviewer if the original interviewer declines their invite.
        </p>
        <p>
          InterviewPlanner and GoodTime both allow you to request availability from candidates via a link where they can submit times. GoodTime can intelligently suggest times based on interviewers&apos; calendars. InterviewPlanner does not have plans to support this. (<i>Why?</i> Complex loops with multiple interviewers can lead to no or very few conflict-free schedules being shown to the candidate.)
        </p>
        <p>
          InterviewPlanner and GoodTime both allow you to send candidates self-scheduling links for single interviews. Only GoodTime supports self-scheduling for a series of back-to-back interviews, while InterviewPlanner does not have plans to support this. (<i>Why?</i> Most teams we have spoken with prefer the coordinator to schedule these, as leaving these in the candidate&apos;s hands tend to result in higher reschedule rates and sometimes very few conflict-free schedules being shown to the candidate. However, it is on InterviewPlanner&apos;s roadmap to have a link that allows a candidate to self-schedule multiple interviews at a time that are not back-to-back).
        </p>
        <p>
          Only GoodTime has functionality for scheduling group interviews, where multiple candidates are interviewed together.
        </p>
      </Section>
      <Section title="Interviewer skills and training">
        <p>
          InterviewPlanner and GoodTime both let you tag interviewers with skills and attributes. Both platforms let you use and combine these tags to construct interviewer pools.
        </p>
        <p>
          InterviewPlanner and GoodTime both have interviewer training programs. InterviewPlanner intelligently updates trainees&apos; roles in all future training sessions if an interview is cancelled or rescheduled. We are unsure how GoodTime handles changes to interviews and whether it affects interviewer training progress.
        </p>
      </Section>
      <Section title="In our opinion">
        <p>
          GoodTime is a powerful platform. However, they are often far too expensive and they have lost the trust of many teams due to their bugginess as they&apos;ve grown. Many teams we&apos;ve spoken with are looking to make a switch.
        </p>
      </Section>
    </div>
  );
};

export default GoodTime;
