import { useQuery } from 'react-query';

import InterviewPlanner from 'libraries/interviewplanner';

import type { EditableZoomHostFilter, ZoomHostType } from 'types';
import type { UseQueryOptions } from 'react-query';

export enum QueryKey {
  ResolveZoomHosts = 'ResolveZoomHosts',
}

interface ResolveZoomHostsQuery {
  zoomHostFilters: EditableZoomHostFilter[];
}

export const useResolveZoomHosts = (query: ResolveZoomHostsQuery, options?: UseQueryOptions<ZoomHost[], Error>) => {
  return useQuery(resolveZoomHostsParams(query, options));
};

export const resolveZoomHostsParams = (query: ResolveZoomHostsQuery, options: UseQueryOptions<ZoomHost[], Error> = {}) => {
  const payload = {
    zoom_host_filters: query.zoomHostFilters,
  };
  const expressions = query.zoomHostFilters.map((filter) => filter.zoom_host_filter_expressions);
  const hasEmptyExpressions = expressions.some((exp) => exp.length === 0);

  return {
    queryKey: [QueryKey.ResolveZoomHosts, payload],
    queryFn: () => {
      return InterviewPlanner.request<ZoomHost[]>('POST', '/zoom/resolve', payload);
    },
    ...options,
    enabled: options.enabled !== undefined ? options.enabled : Boolean(query.zoomHostFilters && query.zoomHostFilters.length > 0 && !hasEmptyExpressions),
  };
};

interface ZoomHost {
  id: string;
  type: ZoomHostType;
}
