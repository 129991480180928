import type { Text } from 'slate';

// This is very similar to RenderLeafProps from slate-react, but with the only
// difference that attributes is optional. This is necessary because we reuse
// the Leaf component within the Token component (so we get rich text styling),
// but if we manually pass in the attributes, it starts throwing errors because
// Slate thinks that this Leaf was rendered by it, not by Token. More info here:
// https://github.com/ianstormtaylor/slate/issues/4871
interface Props {
  children: any;
  leaf: Text;
  text: Text;
  attributes?: {
    'data-slate-leaf': true;
  };
}

const Leaf = ({ attributes, children, leaf }: Props) => {
  if (leaf.bold) {
    children = <b>{children}</b>;
  }

  if (leaf.italic) {
    children = <i>{children}</i>;
  }

  if (leaf.underline) {
    children = <u>{children}</u>;
  }

  return <span {...attributes}>{children}</span>;
};

export default Leaf;
