import type { UpdateInterviewBatchPayload } from 'hooks/queries/interview-batches';
import type { EditableInterviewBatchTime, InterviewBatchTime } from 'types';

export function constructUpdatePayload (times: EditableInterviewBatchTime[]): UpdateInterviewBatchPayload {
  return {
    times: times.map((time) => ({
      id: time.id,
      start_time: time.start_time,
      end_time: time.end_time,
      timezone: time.timezone,
      stage_interview_id: time.stage_interview_id,
      interview_template: {
        id: time.interview_template?.id,
        duration_minutes: time.interview_template?.duration_minutes || 30,
        interviewer_templates: (time.interview_template?.interviewer_templates || []).map((template) => ({
          id: template.id,
          optional: template.optional,
          include_past_interviewers: template.include_past_interviewers,
          interviewer_filters: (template.interviewer_filters || []).map((filter) => ({
            id: filter.id,
            interviewer_filter_expressions: (filter.interviewer_filter_expressions || []).map((expression) => ({
              id: expression.id,
              negated: expression.negated,
              filterable_id: expression.filterable_id,
              filterable_type: expression.filterable_type,
            })),
          })),
        })),
      },
      candidates_per_slot: time.candidates_per_slot,
      video_conferencing_enabled: time.video_conferencing_enabled,
      zoom_host_id: time.zoom_host_id,
      zoom_host_type: time.zoom_host_type,
    })),
  };
}

export function constructEditableBatchTime (time: InterviewBatchTime): EditableInterviewBatchTime {
  return {
    id: time.id,
    stage_interview_id: time.stage_interview_id,
    interview_template: {
      id: time.interview_template?.id,
      duration_minutes: time.interview_template?.duration_minutes || 30,
      interviewer_templates: (time.interview_template?.interviewer_templates || []).map((template) => ({
        id: template.id,
        optional: template.optional,
        include_past_interviewers: template.include_past_interviewers,
        interviewer_filters: (template.interviewer_filters || []).map((filter) => ({
          id: filter.id,
          interviewer_filter_expressions: (filter.interviewer_filter_expressions || []).map((expression) => ({
            id: expression.id,
            negated: expression.negated,
            filterable_id: expression.filterable_id,
            filterable_type: expression.filterable_type,
          })),
        })),
      })),
    },
    start_time: time.start_time,
    end_time: time.end_time,
    timezone: time.timezone,
    candidates_per_slot: time.candidates_per_slot,
    video_conferencing_enabled: time.video_conferencing_enabled,
    zoom_host_id: time.zoom_host_id,
    zoom_host_type: time.zoom_host_type,
  };
}
