import { useMediaQuery } from 'react-responsive';
import { useMemo } from 'react';

import { competitorLabels, competitorLogos } from 'types';
import Data from './data';
import FeatureDisplayValue from './FeatureDisplayValue';
import ListItem from 'components/library/data-display/ListItem';
import { StyledAvatar, StyledTable } from './styles';

import type { Competitor } from 'types';
import type { FeatureComparison } from './data';
import type { TableSchema } from 'components/library/data-display/Table';
import type { TableSchemaItem } from '../../../library/data-display/Table/types';

interface Props {
  competitors: Competitor[];
}

const FeatureComparisonTable = ({ competitors }: Props) => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' });

  const schema = useMemo<TableSchema<FeatureComparison>>(() => [
    {
      header: '',
      displayValue: ({ feature }) => feature,
    },
    {
      header: isSmallScreen ? (
        <StyledAvatar
          alt="InterviewPlanner logo"
          img="/scheduling/src/assets/images/logo-150x150.png"
          size="large"
          type="company"
        />
      ) : (
        <ListItem
          label="InterviewPlanner"
          leftIcon={(
            <StyledAvatar
              alt="InterviewPlanner logo"
              img="/scheduling/src/assets/images/logo-150x150.png"
              size="large"
              type="company"
            />
          )}
        />
      ),
      displayValue: ({ ourValue }) => (
        <FeatureDisplayValue value={ourValue} />
      ),
    },
    ...competitors.map<TableSchemaItem<FeatureComparison>>((competitor) => ({
      header: isSmallScreen ? (
        <StyledAvatar
          alt={`${competitorLabels[competitor]} logo`}
          img={competitorLogos[competitor]}
          size="large"
          type="company"
        />
      ) : (
        <ListItem
          label={competitorLabels[competitor]}
          leftIcon={(
            <StyledAvatar
              alt={`${competitorLabels[competitor]} logo`}
              img={competitorLogos[competitor]}
              size="large"
              type="company"
            />
          )}
        />
      ),
      displayValue: ({ competitorsValues }) => (
        <FeatureDisplayValue value={competitorsValues[competitor]} />
      ),
    })),
  ], [competitors, isSmallScreen]);

  return (
    <StyledTable
      data={Data}
      layout="vertical"
      schema={schema}
    />
  );
};

export default FeatureComparisonTable;
