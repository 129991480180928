import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import MultiStepFormStep from 'components/library/inputs/MultiStepFormStep';
import { useApplication } from 'hooks/queries/applications';
import { useStage } from 'hooks/queries/stages';
import { Step, useNewManualSchedule } from '../use-new-manual-schedule';
import useQueryState from 'hooks/use-query-state';
import InterviewPlanMenu from './InterviewPlanMenu';

const ScheduleStep = () => {
  const { id } = useParams<{ id: string }>();
  const [stageParam] = useQueryState<string>('stage', '');

  const application = useApplication(id).data!;
  const stage = useStage(application.job_id, stageParam || application.current_stage_id).data!;

  const { setCompletedStep } = useNewManualSchedule();

  const handleNext = () => {
    setCompletedStep(Step.Schedule + 1);
  };

  return (
    <Breadcrumb
      data={{
        title: '1. Schedule',
        pathname: `/app/candidates/${id}/manual-schedule/schedule`,
      }}
    >
      <MultiStepFormStep
        isFirstStep
        nextButtonValue="Events and Emails"
        nextLocation={`/app/candidates/${id}/manual-schedule/events-and-emails`}
        onNext={handleNext}
      >
        <Helmet>
          <title>1. Schedule | Manually schedule {application.candidate.name || 'Unknown'} for {application.current_stage?.name} | Gem Scheduling</title>
        </Helmet>
        {stage.stage_interviews && (
          <InterviewPlanMenu
            stageInterviews={stage.stage_interviews}
          />
        )}
      </MultiStepFormStep>
    </Breadcrumb>
  );
};

export default ScheduleStep;
