import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: table;
`;

export const StyledLabel = styled.label`
  font-size: .7rem;
  font-weight: 600;
  margin: .5em 0;
`;

export const StyledRow = styled.div`
  display: table-row;
`;

export const StyledCell = styled.div`
  display: table-cell;
  padding: 0 .5em;

  &:first-child {
    padding-left: 0;
  }

  &:last-child {
    padding-right: 0;
  }
`;
