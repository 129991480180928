import { keyBy } from 'lodash';
import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import InterviewPlanner from '../../libraries/interviewplanner';

import type { Room, RoomTag } from '../../types';
import type { UseQueryOptions } from 'react-query';

export enum QueryKey {
  RetrieveRoom = 'RetrieveRoom',
  ListRooms = 'ListRooms',
  ListRoomTags = 'ListRoomTags',
}

export const useRoom = (id: string, options: UseQueryOptions<Room, Error> = {}) => {
  return useQuery<Room, Error>([QueryKey.RetrieveRoom, id], () => {
    return InterviewPlanner.request('GET', `/rooms/${id}`);
  }, {
    ...options,
    enabled: options.enabled !== undefined ? options.enabled : Boolean(id),
  });
};

interface ListRoomsQuery {
  limit?: number;
  offset?: number;
  name?: string;
  archived?: boolean;
  room_tag?: string[];
}

interface ListRoomsData {
  rooms: Room[];
  total: number;
}

export const useRooms = <TData = ListRoomsData>(query: ListRoomsQuery = {}, options: UseQueryOptions<ListRoomsData, Error, TData> = {}) => {
  // This query can't be canceled because the promise being returned from this
  // function (which is a result of the async keyword) doesn't have a cancel
  // function on it. If we want it to be cancellable, we can't use async/await,
  // and we need to make sure the cancel function from the promise returned from
  // InterviewPlanner.request is preserved.
  return useQuery<ListRoomsData, Error, TData>([QueryKey.ListRooms, query], async () => {
    const pageSize = 100;
    const paginatedQuery = {
      limit: pageSize,
      offset: 0,
      ...query,
    };

    const rooms: Room[] = [];
    let total = 0;
    let totalLimit: number | undefined;

    while (totalLimit === undefined || rooms.length < totalLimit) {
      const response = await InterviewPlanner.request<ListRoomsData>('GET', '/rooms', null, paginatedQuery);
      total = response.total;
      if (response.rooms.length === 0) {
        break;
      }

      rooms.push(...response.rooms);
      totalLimit = query.limit! < total ? query.limit : total;
      paginatedQuery.offset = paginatedQuery.offset + paginatedQuery.limit;
    }

    return { rooms, total };
  }, {
    refetchOnMount: true,
    staleTime: 5000,
    ...options,
  });
};

export interface RoomMap {
  [id: string]: Room;
}

export const useRoomsMap = (query?: ListRoomsQuery, options?: UseQueryOptions<ListRoomsData, Error>) => {
  const { data: rooms } = useRooms(query, options);
  return useMemo<RoomMap>(() => (rooms ? keyBy(rooms.rooms, 'id') : {}), [rooms]);
};

export interface UpdateRoomPayload {
  room_tags?: string[];
  archived?: boolean;
}

interface UpdateRoomMutationVariables {
  id: string;
  payload?: UpdateRoomPayload;
}

export const useUpdateRoom = () => {
  const queryClient = useQueryClient();

  return useMutation<Room, Error, UpdateRoomMutationVariables>(({ id, payload }) => {
    return InterviewPlanner.request('POST', `/rooms/${id}`, payload, null, false);
  }, {
    onSuccess: (_data, { id, payload }) => {
      queryClient.invalidateQueries([QueryKey.RetrieveRoom, id]);
      queryClient.invalidateQueries([QueryKey.ListRooms]);
      if (payload?.room_tags) {
        // If we updated room tags for a room, we need to reload the room tags.
        queryClient.invalidateQueries([QueryKey.ListRoomTags]);
      }
    },
  });
};

export const useRoomTags = (options: UseQueryOptions<RoomTag[], Error> = {}) => {
  return useQuery<RoomTag[], Error>([QueryKey.ListRoomTags], () => {
    return InterviewPlanner.request('GET', '/rooms/tags');
  }, options);
};
