import Moment from 'moment-timezone';
import classnames from 'classnames';
import { find } from 'lodash';

import CalendarEventPreview from './CalendarEventPreview';
import Popover from '../utils/Popover';
import { useUsersMap } from '../../../hooks/queries/users';

import type { Attendee, ShowAs } from '../../../types';
import type { PopoverProps } from 'reactstrap';
import type { ReactNode } from 'react';

interface Props {
  className?: string;
  event: {
    title: ReactNode;
    start: Date;
    end: Date;
    private?: boolean;
    attendees?: Attendee[];
    timezone: string;
    show_as?: ShowAs;
    buffer_time: boolean;
  };
  target: PopoverProps['target'];
}

const CalendarEventPreviewPopover = ({ className, event, target }: Props) => {
  const users = useUsersMap({ archived: true });

  return (
    <Popover
      className={classnames('calendar-event-preview-popover', className)}
      position="auto-start"
      target={target}
    >
      <CalendarEventPreview
        endTime={Moment.tz(event.end, event.timezone).toDate()}
        guests={event.attendees?.map((attendee) => {
          const guest: any = {
            name: attendee.name || attendee.email,
            description: attendee.email,
            rsvp: attendee.rsvp,
          };
          const internalUser = find(Object.values(users), (user) => user.email === attendee.email);
          if (internalUser) {
            guest.id = internalUser.id;
            guest.name = internalUser.name || internalUser.email;
          }
          return guest;
        })}
        isPrivate={event.private}
        showAs={event.show_as}
        startTime={Moment.tz(event.start, event.timezone).toDate()}
        timezone={event.timezone}
        title={(event.buffer_time ? 'Buffer window for ' : '') + event.title}
      />
    </Popover>
  );
};

export default CalendarEventPreviewPopover;
