import * as cookie from 'cookie';

const csrfKey = 'cssessionkey' as const;

/**
 * This function is pretty much copied from the Gem codebase (client/utils.js#zsFormPost). The goal is to submit a form
 * through JS that includes the CSRF token in the payload.
 *
 * @param url - URL to POST to.
 * @param data - Optional form data to include in the request.
 */
export function formPost (url: string, data: { [key: string]: string } = {}) {
  // create a new form, append it to the DOM
  const form = document.createElement('form');
  form.action = url;
  form.method = 'post';
  document.body.appendChild(form);

  Object.keys(data).forEach((key) => {
    const elt = document.createElement('input');
    elt.type = 'hidden';
    elt.name = key;
    elt.value = data[key];
    form.appendChild(elt);
  });

  addCsrfToForm(form);
  form.submit();
}

function addCsrfToForm (form: HTMLFormElement) {
  for (const input of form.elements) {
    if (input.getAttribute('name') === csrfKey) {
      // the form already has this input!
      return;
    }
  }

  const csrf = document.createElement('input');
  csrf.type = 'hidden';
  csrf.name = csrfKey;
  csrf.value = getCsrfToken();
  form.appendChild(csrf);
}

export function getCsrfToken (): string {
  return cookie.parse(document.cookie)[csrfKey]!;
}
