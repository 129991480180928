import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import BusinessHoursInput from '../../../../library/inputs/BusinessHoursInput';
import Flash from '../../../../library/utils/Flash';
import Section from '../../../../library/layout/Section';
import { useUpdateUser, useUser } from '../../../../../hooks/queries/users';

import type { EditableBusinessHour } from '../../../../../types/business-hours';

const WorkingHoursSection = () => {
  const { id } = useParams<{ id: string }>();

  const { data: user } = useUser(id);

  const [isEditing, setIsEditing] = useState(false);
  const [businessHours, setBusinessHours] = useState<EditableBusinessHour[]>((user?.business_hours || user?.default_business_hours || []).map((bh) => {
    return {
      ...bh,
      start_time: bh.start_time === '00:00' ? '' : bh.start_time,
      end_time: bh.end_time === '24:00' ? '' : bh.end_time,
      timezone: bh.timezone || user?.default_timezone,
    };
  }));

  const updateUserMutation = useUpdateUser();

  useEffect(() => {
    setBusinessHours((user?.business_hours || user?.default_business_hours || []).map((bh) => {
      return {
        ...bh,
        start_time: bh.start_time === '00:00' ? '' : bh.start_time,
        end_time: bh.end_time === '24:00' ? '' : bh.end_time,
        timezone: bh.timezone || user?.default_timezone,
      };
    }));
  }, [user]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setBusinessHours((user?.business_hours || user?.default_business_hours || []).map((bh) => {
      return {
        ...bh,
        start_time: bh.start_time === '00:00' ? '' : bh.start_time,
        end_time: bh.end_time === '24:00' ? '' : bh.end_time,
        timezone: bh.timezone || user?.default_timezone,
      };
    }));
    setIsEditing(false);
  };

  const handleSave = async () => {
    updateUserMutation.reset();

    try {
      await updateUserMutation.mutateAsync({
        id,
        payload: {
          business_hours: businessHours.map((bh) => ({
            day: bh.day,
            start_time: bh.start_time || '00:00',
            end_time: bh.end_time || '24:00',
            timezone: bh.timezone || undefined,
          })),
        },
      });
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  return (
    <Section
      isEditable
      isEditing={isEditing}
      isSaving={updateUserMutation.isLoading}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Working hours"
    >
      <Flash
        message={updateUserMutation.error?.message}
        showFlash={updateUserMutation.isError}
        type="danger"
      />
      <Flash
        isDismissible
        message="Successfully updated!"
        showFlash={updateUserMutation.isSuccess}
        type="success"
      />
      <BusinessHoursInput
        businessHours={businessHours}
        isDisabled={!isEditing || updateUserMutation.isLoading}
        setBusinessHours={setBusinessHours}
      />
    </Section>
  );
};

export default WorkingHoursSection;
