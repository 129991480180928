import { isEmpty, isEqual, keyBy, mapValues, omit, pickBy, toPairs } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import Flash from '../../../../../../../library/utils/Flash';
import InterviewsTable from './InterviewsTable';
import LoadingSpinner from '../../../../../../../library/utils/LoadingSpinner';
import Section from '../../../../../../../library/layout/Section';
import { constructUpdateInterviewTemplatePayload } from './helpers';
import { interviewTemplateParams, useCreateInterviewTemplate, useUpdateInterviewTemplate } from '../../../../../../../../hooks/queries/interview-templates';
import { useCreateStageInterview, useDeleteStageInterview, useUpdateStageInterview } from '../../../../../../../../hooks/queries/stage-interviews';
import { useSession } from '../../../../../../../../hooks/use-session';
import { useStage } from '../../../../../../../../hooks/queries/stages';

import type { CreateStageInterviewPayload } from '../../../../../../../../hooks/queries/stage-interviews';
import type { StageInterview } from '../../../../../../../../types';
import type { StageInterviewPayload } from './types';
import { sortPositions } from '../../../../../../../../libraries/interview-templates';

const constructPayloads = (stageInterviews: Record<string, StageInterview>): Record<string, StageInterviewPayload> => mapValues(
  stageInterviews,
  (stageInterview): StageInterviewPayload => ({
    id: stageInterview.id,
    name: stageInterview.name,
    position: stageInterview.position,
    feedback_form_id: stageInterview.feedback_form_id,
    interview_template_id: stageInterview.interview_template_id,
    interview_template: stageInterview.interview_template ? {
      ...stageInterview.interview_template,
      interviewer_templates: stageInterview.interview_template.interviewer_templates ? stageInterview.interview_template.interviewer_templates.map((interviewerTemplate) => ({
        ...interviewerTemplate,
        interviewer_filters: (interviewerTemplate.interviewer_filters || []).map((filter) => ({
          ...filter,
          interviewer_filter_expressions: filter.interviewer_filter_expressions || [],
        })),
      })) : [],
    } : undefined,
  })
);

const InterviewsSection = () => {
  const queryClient = useQueryClient();

  const { id: jobId, stageId } = useParams<{ id: string; stageId: string }>();

  const { account } = useSession();
  const { data: stage, refetch: refetchStage } = useStage(jobId, stageId);

  const stageInterviews = useMemo(() => keyBy(stage?.stage_interviews, 'id'), [stage?.stage_interviews]);

  const [isFetching, setIsFetching] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [clickedSubmit, setClickedSubmit] = useState(false);
  const [stageInterviewPayloads, setStageInterviewPayloads] = useState(constructPayloads(stageInterviews));
  const [errors, setErrors] = useState<string[]>([]);

  const createInterviewTemplateMutation = useCreateInterviewTemplate();
  const updateInterviewTemplateMutation = useUpdateInterviewTemplate();
  const createStageInterviewMutation = useCreateStageInterview();
  const updateStageInterviewMutation = useUpdateStageInterview();
  const deleteStageInterviewMutation = useDeleteStageInterview();

  useEffect(() => {
    // We don't want any in-progress changes to be overwritten if the stage reloads while we're still editing.
    if (!isEditing) {
      setStageInterviewPayloads(constructPayloads(stageInterviews));
    } else {
      // If we're editing and the payloads changed, then it might be because a new interview template was created and
      // attached to the stage interview, so we need to copy that data in, especially the linked_interviews.
      const newPayloads = constructPayloads(stageInterviews);
      setStageInterviewPayloads((prevState) => ({
        ...prevState,
        ...mapValues(newPayloads, (newPayloads, stageInterviewId) => ({
          ...prevState[stageInterviewId],
          interview_template: prevState[stageInterviewId]?.interview_template ? {
            ...prevState[stageInterviewId].interview_template!,
            linked_interviews: newPayloads.interview_template?.linked_interviews,
          } : newPayloads.interview_template,
        })),
      }));
    }
  }, [stageInterviews, isEditing]);

  useEffect(() => {
    if (clickedSubmit && !isEmpty(errors)) {
      setIsEditing(true);
    }
  }, [errors]);

  const handleEdit = useCallback(() => {
    setClickedSubmit(false);
    setIsEditing(true);
  }, []);

  const handleCancel = useCallback(() => {
    setStageInterviewPayloads(constructPayloads(stageInterviews));
    setIsEditing(false);
  }, [stageInterviews]);

  // NOTE: This is the handle for the "Save" button at the top of the section. This does not affect the "Create
  // Template" button or the "Save Changes" in the inline interview template form.
  const handleSave = useCallback(async () => {
    setClickedSubmit(true);
    setIsEditing(false);
    setErrors([]);
    setIsFetching(true);

    const allErrors: string[] = [];

    // Make sure that all custom stage interviews have a name.
    for (const id in stageInterviewPayloads) {
      if (!stageInterviewPayloads[id].name) {
        setErrors(['An interview name is required for all interviews.']);
        setIsEditing(true);
        setIsFetching(false);
        return;
      }
    }

    // Update interview templates that have been edited
    const updateInterviewTemplatePayloads = await Promise.all(
      Object.values(stageInterviewPayloads)
      .filter(({ interview_template_id, interview_template }) => interview_template_id && interview_template && interview_template.id && interview_template.id !== 'new')
      .map(async ({ interview_template_id, interview_template }) => {
        // We just filtered by interview_template_id and interview_template, so it's always defined.
        const originalInterviewTemplate = await queryClient.fetchQuery(interviewTemplateParams(interview_template_id!));
        return {
          id: interview_template_id,
          name: originalInterviewTemplate.name,
          payload: constructUpdateInterviewTemplatePayload(interview_template!, originalInterviewTemplate),
        };
      })
    );

    await Promise.all(
      updateInterviewTemplatePayloads
      .filter(({ payload }) => !isEmpty(payload))
      .map(async ({ id, name, payload }) => {
        try {
          // We filtered by id earlier, so it's always defined.
          await updateInterviewTemplateMutation.mutateAsync({ id: id!, payload });
        } catch (err) {
          if (err instanceof Error) {
            allErrors.push(`${name}: ${err.message}`);
          }
        }
      })
    );

    // Create new interview templates
    const newInterviewTemplatePayloads = pickBy(stageInterviewPayloads, (payloads) => payloads.interview_template_id === 'new');

    await Promise.all(
      toPairs(newInterviewTemplatePayloads).map(
        async ([stageInterviewId, { interview_template, name }]) => {
          try {
            const data = await createInterviewTemplateMutation.mutateAsync({
              payload: {
                ...omit(interview_template, ['id']),
                positions: (interview_template?.positions || []).length > 0 ? sortPositions(interview_template?.positions) : undefined,
                candidate_facing_details: interview_template?.candidate_facing_details && interview_template.candidate_facing_details !== '<br>' ? interview_template.candidate_facing_details : undefined,
              },
            });
            setStageInterviewPayloads((prevState) => ({
              ...prevState,
              [stageInterviewId]: {
                ...prevState[stageInterviewId],
                interview_template_id: data.id,
              },
            }));
            newInterviewTemplatePayloads[stageInterviewId].interview_template_id = data.id;
          } catch (err) {
            if (err instanceof Error) {
              allErrors.push(`${name}: ${err.message}`);
            }
          }
        }
      )
    );

    // Create new interviews
    const newInterviews = pickBy(stageInterviewPayloads, ({ id }) => id && id.startsWith('new'));
    await Promise.all(
      toPairs(newInterviews).map(
        async ([stageInterviewId, { feedback_form_id, interview_template_id, name, position }]) => {
          try {
            const payload: CreateStageInterviewPayload = {
              name,
              feedback_form_id,
              interview_template_id: newInterviewTemplatePayloads[stageInterviewId] ? newInterviewTemplatePayloads[stageInterviewId].interview_template_id : interview_template_id,
              position,
            };
            const data = await createStageInterviewMutation.mutateAsync({ jobId, stageId, payload });
            setStageInterviewPayloads((prevState) => ({
              ...omit(prevState, [stageInterviewId]),
              [data.id]: {
                ...prevState[stageInterviewId],
                id: data.id,
              },
            }));
          } catch (err) {
            if (err instanceof Error) {
              allErrors.push(`${name}: ${err.message}`);
            }
          }
        }
      )
    );

    // Update stage interviews with names and interview template links
    const updateStageInterviewPayloads = pickBy(
      stageInterviewPayloads,
      ({ name, feedback_form_id, position, interview_template_id }, stageInterviewId) => (
        stageInterviews[stageInterviewId] && (
          (!isEqual(interview_template_id, stageInterviews[stageInterviewId].interview_template_id)) ||
          (!isEqual(feedback_form_id, stageInterviews[stageInterviewId].feedback_form_id)) ||
          (!stageInterviews[stageInterviewId].ats_id && !isEqual(name, stageInterviews[stageInterviewId].name)) ||
          (!stageInterviews[stageInterviewId].ats_id && !isEqual(position, stageInterviews[stageInterviewId].position))
        )
      )
    );

    await Promise.all(
      toPairs(updateStageInterviewPayloads).map(
        async ([stageInterviewId, { name, position, feedback_form_id, interview_template_id }]) => {
          try {
            await updateStageInterviewMutation.mutateAsync({
              id: stageInterviewId,
              jobId,
              stageId,
              payload: {
                name: stageInterviews[stageInterviewId] && stageInterviews[stageInterviewId].ats_id ? undefined : name,
                position: stageInterviews[stageInterviewId] && stageInterviews[stageInterviewId].ats_id ? undefined : position,
                feedback_form_id,
                interview_template_id,
              },
            });
          } catch (err) {
            if (err instanceof Error) {
              allErrors.push(`${stageInterviews[stageInterviewId].name}: ${err.message}`);
            }
          }
        }
      )
    );

    // Delete stage interviews
    const stageInterviewsToDelete = Object.keys(stageInterviews).filter((id) => !Boolean(stageInterviewPayloads[id]));
    await Promise.all(stageInterviewsToDelete.map(async (stageInterviewId) => {
      try {
        await deleteStageInterviewMutation.mutateAsync({
          id: stageInterviewId,
          jobId,
          stageId,
        });
      } catch (err) {
        if (err instanceof Error) {
          allErrors.push(`${stageInterviews[stageInterviewId].name}: ${err.message}`);
        }
      }
    }));

    // After we've done everything, and if there are no errors, to make sure the payloads get updated appropriately, we
    // refetch the stage. Since we set isEditing to false already, it should make the payloads fresh. We only do with no
    // errors because otherwise, it might wipe out in progress changes.
    if (allErrors.length === 0) {
      await refetchStage();
    }

    setErrors(allErrors);
    setIsFetching(false);
  }, [stageInterviewPayloads]);

  const isSaving = clickedSubmit && isFetching;
  const isSuccess = clickedSubmit && !isFetching && isEmpty(errors);

  return (
    <Section
      isEditable
      isEditing={isEditing}
      isSaving={isSaving}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Interviews"
    >
      <Flash
        message={`You have not added any interviews for this stage. You can add interviews ${account?.ats_type === 'greenhouse' ? 'in your ATS or ' : ''} by clicking the Edit button.`}
        showFlash={!isEditing && isEmpty(stageInterviewPayloads)}
        type="info"
      />
      <Flash
        message="You may add custom interviews, such as breaks or greetings. These will not be added to your ATS and will not have attached scorecards."
        showFlash={(isEditing || isSaving) && account?.ats_type === 'greenhouse'}
        type="info"
      />
      <Flash
        isDismissible
        message="Successfully updated!"
        showFlash={isSuccess}
        type="success"
      />
      <Flash
        message={errors?.join(', ')}
        showFlash={clickedSubmit && !isEmpty(errors) && isEditing}
        type="danger"
      />
      {stageInterviewPayloads ?
        <InterviewsTable
          clickedSubmit={clickedSubmit}
          isEditing={isEditing}
          isSaving={isSaving}
          setClickedSubmit={setClickedSubmit}
          setStageInterviewPayloads={setStageInterviewPayloads}
          stageInterviewPayloads={stageInterviewPayloads}
        /> :
        <LoadingSpinner />
      }
    </Section>
  );
};

export default InterviewsSection;
