import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faSearch } from '@fortawesome/free-solid-svg-icons';
import { Helmet } from 'react-helmet-async';
import { Link } from 'react-router-dom';
import { useCallback } from 'react';

import Button from 'components/library/inputs/Button';
import TextInput from 'components/library/inputs/TextInput';
import Section from 'components/library/layout/Section';
import Flash from 'components/library/utils/Flash';
import LoadingSpinner from 'components/library/utils/LoadingSpinner';
import useSyncStateWithQuery from 'hooks/use-sync-state-with-query';
import { useInterviewBatches } from 'hooks/queries/interview-batches';
import { correctPath } from 'libraries/gem';
import SuperdayListTable from './SuperdayListTable';
import { StyledContainer, StyledFiltersContainer, StyledHeaderActions } from './styles';

import type { ChangeEvent } from 'react';

const SuperdayList = () => {
  const [search, querySearch, setSearch] = useSyncStateWithQuery<string>('q', '', { delay: 200 });
  const [showArchived, , setShowArchived] = useSyncStateWithQuery<string>('show_archived', 'false');
  const [pageNumber, queryPageNumber, setPageNumber] = useSyncStateWithQuery<string>('page', '1');

  const {
    data: interviewBatches,
    error,
    isFetching,
  } = useInterviewBatches({
    archived: showArchived === 'true',
    name: querySearch,
    limit: 10,
    offset: 10 * (parseInt(queryPageNumber, 10) - 1),
  });

  const handleSearchChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
    if (parseInt(pageNumber, 10) > 1) {
      setPageNumber('1', { method: 'replace' });
    }
  }, [pageNumber]);

  const handleShowArchivedChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setShowArchived(JSON.stringify(e.target.checked));
  }, []);

  const handlePageNumberChange = useCallback((value: number) => {
    setPageNumber(value.toString());
  }, []);

  return (
    <StyledContainer>
      <Helmet>
        <title>Superdays | Gem Scheduling</title>
      </Helmet>
      <Section
        headerActions={
          <StyledHeaderActions>
            <Link to={correctPath('/app/superdays/create')}>
              <Button
                color="gem-blue"
                iconLeft={<FontAwesomeIcon icon={faPlus} />}
                value="New superday"
              />
            </Link>
          </StyledHeaderActions>
        }
        title="Superdays"
      >
        <StyledFiltersContainer>
          <TextInput
            isAutofocus
            label="Search"
            leftIcon={isFetching ? <LoadingSpinner /> : <FontAwesomeIcon icon={faSearch} />}
            onChange={handleSearchChange}
            placeholder="Superday name"
            value={search}
          />
        </StyledFiltersContainer>
        <Flash
          message={error?.message}
          showFlash={Boolean(error)}
          type="danger"
        />
        {interviewBatches && (
          <SuperdayListTable
            interviewBatches={interviewBatches.interview_batches}
            onPageNumberChange={handlePageNumberChange}
            onShowArchivedChange={handleShowArchivedChange}
            pageNumber={parseInt(pageNumber, 10)}
            showArchived={showArchived === 'true'}
            totalCount={interviewBatches.total}
          />
        )}
      </Section>
    </StyledContainer>
  );
};

export default SuperdayList;
