import { keyBy } from 'lodash';
import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import InterviewPlanner from '../../libraries/interviewplanner';

import type { HiringMeetingTemplate } from '../../types';
import type { UseQueryOptions } from 'react-query';
import type { ZoomHostFilterableType } from '../../types';

export enum QueryKey {
  ListHiringMeetingTemplates = 'ListHiringMeetingTemplates',
  RetrieveHiringMeetingTemplate = 'RetrieveHiringMeetingTemplate',
}

export const useHiringMeetingTemplate = (id?: string, options?: UseQueryOptions<HiringMeetingTemplate, Error>) => {
  return useQuery(hiringMeetingTemplateParams(id, options));
};

export const hiringMeetingTemplateParams = (id: string | undefined, options: UseQueryOptions<HiringMeetingTemplate, Error> = {}) => {
  return {
    queryKey: [QueryKey.RetrieveHiringMeetingTemplate, id],
    queryFn: async () => {
      return await InterviewPlanner.request<HiringMeetingTemplate>('GET', `/hiring-meeting-templates/${id}`);
    },
    ...options,
    enabled: options.enabled !== undefined ? options.enabled : (Boolean(id) && id !== 'new'),
  };
};

interface ListHiringMeetingTemplatesQuery {
  limit?: number;
  offset?: number;
  name?: string;
  archived?: boolean;
}

interface ListHiringMeetingTemplatesData {
  hiring_meeting_templates: HiringMeetingTemplate[];
  total: number;
}

export const useHiringMeetingTemplates = (query: ListHiringMeetingTemplatesQuery = {}, options: UseQueryOptions<ListHiringMeetingTemplatesData, Error> = {}) => {
  // This query can't be canceled because the promise being returned from this
  // function (which is a result of the async keyword) doesn't have a cancel
  // function on it. If we want it to be cancellable, we can't use async/await,
  // and we need to make sure the cancel function from the promise returned from
  // InterviewPlanner.request is preserved.
  return useQuery<ListHiringMeetingTemplatesData, Error>([QueryKey.ListHiringMeetingTemplates, query], async () => {
    const pageSize = 100;
    const paginatedQuery = {
      limit: pageSize,
      offset: 0,
      ...query,
    };

    const hiringMeetingTemplates = [];
    let total = 0;
    let totalLimit;

    while (totalLimit === undefined || hiringMeetingTemplates.length < totalLimit) {
      const response = await InterviewPlanner.request<ListHiringMeetingTemplatesData>('GET', '/hiring-meeting-templates', null, paginatedQuery);
      total = response.total;
      if (response.hiring_meeting_templates.length === 0) {
        break;
      }

      hiringMeetingTemplates.push(...response.hiring_meeting_templates);
      totalLimit = query.limit! < total ? query.limit : total;
      paginatedQuery.offset = paginatedQuery.offset + paginatedQuery.limit;
    }

    return { hiring_meeting_templates: hiringMeetingTemplates, total };
  }, options);
};

interface HiringMeetingTemplateMap {
  [id: string]: HiringMeetingTemplate;
}

export const useHiringMeetingTemplatesMap = (query?: ListHiringMeetingTemplatesQuery, options?: UseQueryOptions<ListHiringMeetingTemplatesData, Error>) => {
  const { data: hiringMeetingTemplates } = useHiringMeetingTemplates(query, options);
  return useMemo<HiringMeetingTemplateMap>(() => hiringMeetingTemplates ? keyBy(hiringMeetingTemplates.hiring_meeting_templates, 'id') : {}, [hiringMeetingTemplates]);
};

export interface CreateHiringMeetingTemplatePayload {
  name: string;
  duration_minutes: number;
  video_conferencing_enabled: boolean;
  zoom_host_filters?: {
    zoom_host_filter_expressions: {
      filterable_id: string;
      filterable_type: `${ZoomHostFilterableType}`;
      negated: boolean;
    }[];
  }[];
  room_filters?: {
    room_filter_expressions: {
      negated: boolean;
      filterable_id: string;
      filterable_type: string;
    }[];
  }[];
  calendar_event_template_id: string;
  hiring_meeting_attendee_filters: {
    hiring_meeting_attendee_filter_expressions: {
      negated: boolean;
      filterable_id: string;
      filterable_type: string;
    }[];
  }[];
}

interface CreateHiringMeetingTemplateMutationVariables {
  payload: CreateHiringMeetingTemplatePayload;
}

export const useCreateHiringMeetingTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation<HiringMeetingTemplate, Error, CreateHiringMeetingTemplateMutationVariables>(({ payload }) => {
    return InterviewPlanner.request('POST', '/hiring-meeting-templates', payload);
  }, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKey.ListHiringMeetingTemplates]);
      queryClient.setQueryData([QueryKey.RetrieveHiringMeetingTemplate, data.id], data);
    },
  });
};

export interface UpdateHiringMeetingTemplatePayload {
  name?: string;
  duration_minutes?: number;
  video_conferencing_enabled?: boolean;
  zoom_host_filters?: {
    zoom_host_filter_expressions: {
      filterable_id: string;
      filterable_type: `${ZoomHostFilterableType}`;
      negated: boolean;
    }[];
  }[];
  room_filters?: {
    room_filter_expressions: {
      negated: boolean;
      filterable_id: string;
      filterable_type: string;
    }[];
  }[];
  calendar_event_template_id?: string;
  hiring_meeting_attendee_filters?: {
    hiring_meeting_attendee_filter_expressions: {
      negated: boolean;
      filterable_id: string;
      filterable_type: string;
    }[];
  }[];
  archived?: boolean;
}

interface UpdateHiringMeetingTemplateMutationVariables {
  id: string;
  payload: UpdateHiringMeetingTemplatePayload;
}

export const useUpdateHiringMeetingTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation<HiringMeetingTemplate, Error, UpdateHiringMeetingTemplateMutationVariables>(({ id, payload }) => {
    return InterviewPlanner.request('POST', `/hiring-meeting-templates/${id}`, payload);
  }, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKey.ListHiringMeetingTemplates]);
      queryClient.setQueryData([QueryKey.RetrieveHiringMeetingTemplate, data.id], data);
    },
  });
};
