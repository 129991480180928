import Label from 'components/library/utils/Label';
import { Competitor } from 'types';

import type { ReactNode } from 'react';

export type FeatureValue = boolean | string | ReactNode;

export interface FeatureComparison {
  feature: string;
  ourValue: FeatureValue;
  competitorsValues: Record<Competitor, FeatureValue>;
  winningDetails?: { (key: Competitor | 'interviewplanner'): string };
}

const data: FeatureComparison[] = [{
  feature: 'Intelligent schedule generation',
  ourValue: true,
  competitorsValues: {
    [Competitor.Greenhouse]: false,
    [Competitor.GoodTime]: true,
    [Competitor.Lever]: false,
    [Competitor.ModernLoop]: true,
    [Competitor.Prelude]: true,
  },
}, {
  feature: 'Easy customization of schedule options',
  ourValue: true,
  competitorsValues: {
    [Competitor.Greenhouse]: <Label color="gray">N/A</Label>,
    [Competitor.GoodTime]: false,
    [Competitor.Lever]: <Label color="gray">N/A</Label>,
    [Competitor.ModernLoop]: true,
    [Competitor.Prelude]: false,
  },
}, {
  feature: 'Company branding',
  ourValue: true,
  competitorsValues: {
    [Competitor.Greenhouse]: false,
    [Competitor.GoodTime]: true,
    [Competitor.Lever]: false,
    [Competitor.ModernLoop]: true,
    [Competitor.Prelude]: true,
  },
}, {
  feature: 'Multi-day scheduling',
  ourValue: true,
  competitorsValues: {
    [Competitor.Greenhouse]: false,
    [Competitor.GoodTime]: true,
    [Competitor.Lever]: false,
    [Competitor.ModernLoop]: true,
    [Competitor.Prelude]: true,
  },
}, {
  feature: 'Automatically initiate candidate communication when moved into a stage',
  ourValue: true,
  competitorsValues: {
    [Competitor.Greenhouse]: <Label color="gray">Some Plans</Label>,
    [Competitor.GoodTime]: true,
    [Competitor.Lever]: false,
    [Competitor.ModernLoop]: false,
    [Competitor.Prelude]: false,
  },
}, {
  feature: 'Automatic follow-ups for availability and schedule requests',
  ourValue: true,
  competitorsValues: {
    [Competitor.Greenhouse]: false,
    [Competitor.GoodTime]: true,
    [Competitor.Lever]: false,
    [Competitor.ModernLoop]: false,
    [Competitor.Prelude]: true,
  },
}, {
  feature: 'Automatic interviewer load balancing',
  ourValue: true,
  competitorsValues: {
    [Competitor.Greenhouse]: false,
    [Competitor.GoodTime]: true,
    [Competitor.Lever]: false,
    [Competitor.ModernLoop]: true,
    [Competitor.Prelude]: false,
  },
}, {
  feature: 'Requesting candidate availability',
  ourValue: true,
  competitorsValues: {
    [Competitor.Greenhouse]: true,
    [Competitor.GoodTime]: true,
    [Competitor.Lever]: false,
    [Competitor.ModernLoop]: true,
    [Competitor.Prelude]: true,
  },
}, {
  feature: 'Self-scheduling a single interview',
  ourValue: true,
  competitorsValues: {
    [Competitor.Greenhouse]: true,
    [Competitor.GoodTime]: true,
    [Competitor.Lever]: true,
    [Competitor.ModernLoop]: true,
    [Competitor.Prelude]: true,
  },
}, {
  feature: 'Placing schedules on hold',
  ourValue: true,
  competitorsValues: {
    [Competitor.Greenhouse]: false,
    [Competitor.GoodTime]: true,
    [Competitor.Lever]: false,
    [Competitor.ModernLoop]: true,
    [Competitor.Prelude]: true,
  },
}, {
  feature: 'Conflict keywords',
  ourValue: true,
  competitorsValues: {
    [Competitor.Greenhouse]: false,
    [Competitor.GoodTime]: true,
    [Competitor.Lever]: false,
    [Competitor.ModernLoop]: true,
    [Competitor.Prelude]: true,
  },
}, {
  feature: 'Interview limits',
  ourValue: true,
  competitorsValues: {
    [Competitor.Greenhouse]: false,
    [Competitor.GoodTime]: true,
    [Competitor.Lever]: false,
    [Competitor.ModernLoop]: true,
    [Competitor.Prelude]: true,
  },
}, {
  feature: 'Interviewer training programs',
  ourValue: true,
  competitorsValues: {
    [Competitor.Greenhouse]: false,
    [Competitor.GoodTime]: true,
    [Competitor.Lever]: false,
    [Competitor.ModernLoop]: true,
    [Competitor.Prelude]: true,
  },
}, {
  feature: 'Bulk scheduling',
  ourValue: false,
  competitorsValues: {
    [Competitor.Greenhouse]: false,
    [Competitor.GoodTime]: true,
    [Competitor.Lever]: false,
    [Competitor.ModernLoop]: false,
    [Competitor.Prelude]: true,
  },
}, {
  feature: 'Pricing',
  ourValue: '$',
  competitorsValues: {
    [Competitor.Greenhouse]: <Label color="gray">No additional cost</Label>,
    [Competitor.GoodTime]: '$$$',
    [Competitor.Lever]: <Label color="gray">No additional cost</Label>,
    [Competitor.ModernLoop]: '$',
    [Competitor.Prelude]: '$$',
  },
}];

export default data;
