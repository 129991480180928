import Moment from 'moment';
import { Breadcrumb } from 'react-breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Redirect, Route, Switch, useParams } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive';

import ArchiveButton from '../../../library/inputs/ArchiveButton';
import Button from '../../../library/inputs/Button';
import EmailTemplateDetails from './EmailTemplateDetails';
import EmailTemplateLinkedStages from './EmailTemplateLinkedStages';
import Flash from '../../../library/utils/Flash';
import Label from '../../../library/utils/Label';
import PageHeader from '../../../library/layout/PageHeader';
import StatusIndicator from '../../../library/utils/StatusIndicator';
import { emailTemplateTypeLabels, FavoriteResourceType } from '../../../../types';
import { formatMoment, TimeFormat } from '../../../../libraries/time';
import { useEmailTemplate, useUpdateEmailTemplate } from '../../../../hooks/queries/email-templates';
import { correctPath } from 'libraries/gem';

const EmailTemplate = () => {
  const { id } = useParams<{ id: string }>();

  const {
    data: emailTemplate,
    error: emailTemplateError,
    isLoading: isEmailTemplateLoading,
  } = useEmailTemplate(id);

  const updateEmailTemplateMutation = useUpdateEmailTemplate();

  const isSmallScreen = useMediaQuery({ query: '(max-width: 1000px)' });

  const handleArchive = () => {
    updateEmailTemplateMutation.reset();
    updateEmailTemplateMutation.mutate({ id, payload: { archived: true } });
  };

  const handleUnarchive = () => {
    updateEmailTemplateMutation.reset();
    updateEmailTemplateMutation.mutate({ id, payload: { archived: false } });
  };

  if (isEmailTemplateLoading) {
    return null;
  }

  if (emailTemplateError) {
    return (
      <Breadcrumb
        data={{
          title: id,
          pathname: correctPath(`/app/email-templates/${id}`),
        }}
      >
        <div className="email-template-container">
          <Flash
            message={emailTemplateError.message}
            showFlash
            type="danger"
          />
        </div>
      </Breadcrumb>
    );
  }

  if (!emailTemplate) {
    return null;
  }

  return (
    <Breadcrumb
      data={{
        title: emailTemplate.name || id,
        pathname: correctPath(`/app/email-templates/${id}`),
      }}
    >
      <div className="email-template-container">
        <Flash
          isDismissible
          message={updateEmailTemplateMutation.error?.message}
          onDismiss={updateEmailTemplateMutation.reset}
          showFlash={updateEmailTemplateMutation.isError}
          type="danger"
        />
        <Flash
          isDismissible
          message={`Email template ${emailTemplate.archived ? 'archived' : 'unarchived'}.`}
          onDismiss={updateEmailTemplateMutation.reset}
          showFlash={updateEmailTemplateMutation.isSuccess}
          type="success"
        />
        <>
          <PageHeader
            details={
              <>
                <span>{emailTemplateTypeLabels[emailTemplate.type]}</span>
                <span className="separator">|</span>
                <StatusIndicator color={emailTemplate.linked_resources ? 'green' : 'gray'}>
                  Used in <Link to={correctPath(`/app/email-templates/${emailTemplate.id}/linked-stages`)}>{emailTemplate.linked_resources || 0} active interview{emailTemplate.linked_resources !== 1 && 's'}</Link>
                </StatusIndicator>
                {!isSmallScreen && <span className="separator">|</span>}
                {!isSmallScreen && <span>Created {formatMoment(Moment(emailTemplate.created_at), TimeFormat.LongMonthDayYear)}</span>}
                {!isSmallScreen && emailTemplate.created_at !== emailTemplate.updated_at && <span className="separator">|</span>}
                {!isSmallScreen && emailTemplate.created_at !== emailTemplate.updated_at && <span>Updated {formatMoment(Moment(emailTemplate.updated_at), TimeFormat.LongMonthDayYear)}</span>}
              </>
            }
            favorite={emailTemplate.favorite}
            navbarLinks={[{
              label: 'Details',
              location: correctPath(`/app/email-templates/${emailTemplate.id}/details`),
            }, {
              label: 'Linked Stages',
              location: correctPath(`/app/email-templates/${emailTemplate.id}/linked-stages`),
            }]}
            resourceId={emailTemplate.id}
            resourceType={FavoriteResourceType.EmailTemplate}
            title={emailTemplate.name}
            titleIcon={emailTemplate.archived ? <Label color="gray">Archived</Label> : undefined}
          >
            <Link
              to={{
                pathname: correctPath('/app/email-templates/create'),
                search: `?base=${emailTemplate.id}`,
              }}
            >
              <Button
                color="gem-outline"
                iconLeft={<FontAwesomeIcon icon={faPlus} />}
                size="small"
                value="New template from base"
              />
            </Link>
            <ArchiveButton
              id={id}
              isArchived={emailTemplate.archived}
              onArchive={handleArchive}
              onUnarchive={handleUnarchive}
            />
            {/* <DeleteButton id={id} /> */}
          </PageHeader>
          <Switch>
            <Redirect exact from={correctPath('/app/email-templates/:id')} to={correctPath('/app/email-templates/:id/details')} />
            <Route component={EmailTemplateDetails} path={correctPath('/app/email-templates/:id/details')} />
            <Route component={EmailTemplateLinkedStages} path={correctPath('/app/email-templates/:id/linked-stages')} />
          </Switch>
        </>
      </div>
    </Breadcrumb>
  );
};

export default EmailTemplate;
