import styled from 'styled-components';

import OutboundLink from 'components/library/navigation/OutboundLink';

export const StyledOutboundLink = styled(OutboundLink)`
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }
`;
