import classnames from 'classnames';
import { CSSTransition } from 'react-transition-group';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useEffect, useMemo, useState } from 'react';
import {
  faCheckCircle,
  faExclamationCircle,
  faExclamationTriangle,
  faInfoCircle,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';

import Button from '../inputs/Button';
import { getAccessibleContrastColors } from '../../../libraries/color';

import type { CSSProperties, ReactNode } from 'react';
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';

export enum FlashType {
  Danger = 'danger',
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
}

const icons: Record<FlashType, IconDefinition> = {
  [FlashType.Danger]: faExclamationCircle,
  [FlashType.Info]: faInfoCircle,
  [FlashType.Success]: faCheckCircle,
  [FlashType.Warning]: faExclamationTriangle,
};

interface Props {
  className?: string;
  brandColor?: string;
  isDismissible?: boolean;
  onDismiss?: () => void;
  showFlash: boolean;
  message?: ReactNode;
  type: `${FlashType}`;
  style?: CSSProperties;
}

const Flash = ({ className, brandColor, isDismissible = false, onDismiss, showFlash, style, type, message }: Props) => {
  const [isDismissed, setIsDismissed] = useState<boolean>(false);

  const customStyles = useMemo(() => {
    if (!brandColor) {
      return style;
    }
    const { background, text } = getAccessibleContrastColors(brandColor);
    return {
      background,
      color: text,
      ...(style || {}),
    };
  }, [brandColor, style]);

  useEffect(() => {
    if (showFlash) {
      setIsDismissed(false);
    }
  }, [showFlash]);

  const handleDismiss = () => {
    setIsDismissed(true);
    onDismiss?.();
  };

  return (
    <CSSTransition
      classNames="flash-transition"
      exit={false}
      in={showFlash && !isDismissed}
      timeout={250}
      unmountOnExit
    >
      <div
        className={classnames(['flash', `flash-${type}`, className])}
        data-cy={`${type}-flash`}
        style={customStyles}
      >
        {<FontAwesomeIcon className="flash-icon" icon={icons[type]} />}
        <div className="flash-message">
          {message}
        </div>
        {isDismissible &&
          <Button
            color="gray"
            iconRight={<FontAwesomeIcon className="flash-dismiss-icon" icon={faTimes} />}
            onClick={handleDismiss}
            size="small"
          />
        }
      </div>
    </CSSTransition>
  );
};

export default Flash;
