import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';
import { useRef } from 'react';

import type { ChangeEvent, Dispatch, SetStateAction } from 'react';
import type { EditorAttachment } from '../../../../../types';

interface Props {
  isDisabled: boolean;
  setAttachments: Dispatch<SetStateAction<EditorAttachment[]>>;
}

const AttachmentsToolbarOption = ({ isDisabled, setAttachments }: Props) => {
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleClick = () => {
    fileInputRef.current?.click();
  };

  const handleAttachmentsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const files = e.target.files;
    if (files) {
      setAttachments((prevAttachments) => [...(prevAttachments || []), ...files]);
    }
  };

  return (
    <div
      className="editor-toolbar-option editor-toolbar-option-long"
      onClick={handleClick}
    >
      <input
        className="hidden-file-input"
        disabled={isDisabled}
        multiple
        onChange={handleAttachmentsChange}
        ref={fileInputRef}
        type="file"
      />
      <div className="editor-toolbar-option-icon">
        <FontAwesomeIcon icon={faPaperclip} />
      </div>
      <div className="editor-toolbar-option-text">Attach files</div>
    </div>
  );
};

export default AttachmentsToolbarOption;
