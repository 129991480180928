import { sortBy } from 'lodash';

export function sortPositions (positions: number[] | undefined): number[] | undefined {
  if (!positions) {
    return positions;
  }

  return sortBy(positions, [(position) => {
    if (position > 0) {
      // It's a positive value, so we just return it to be sorted ascending.
      return position;
    }
    // It's a negative value, so we want it sorted ascending, but after the positive values. So -3, -2, -1. We add 100
    // to it just to make it a large number so it will come after the positive values, and keep the ascending order.
    return position + 100;
  }]);
}
