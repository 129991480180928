import Moment from 'moment';

import ATSImportedIcon from '../../../../library/data-display/ATSImportedIcon';
import StatusIndicator from '../../../../library/utils/StatusIndicator';
import { formatMoment, TimeFormat } from '../../../../../libraries/time';
import { statusIndicatorValues } from './types';

import type { Application } from '../../../../../types';
import type { ApplicationScheduleWithType } from './types';

interface Props {
  application: Application;
  schedule: ApplicationScheduleWithType;
}

const ScheduleMenuLabel = ({ application, schedule }: Props) => (
  <div className="schedule-menu-label">
    <div className="status">
      <StatusIndicator
        color={schedule.type === 'schedule' && schedule.hold ? 'orange' : statusIndicatorValues[schedule.status].color}
        tooltipId={`schedule-${schedule.id}-status-indicator`}
        tooltipText={schedule.type === 'schedule' && schedule.hold ? 'On Hold' : statusIndicatorValues[schedule.status].label}
      />
    </div>
    <div className="content">
      <div className="stage" title={`${schedule.stage.name}${schedule.stage.job_id !== application.job_id ? ` (${schedule.stage.job.name})` : ''}`}>{schedule.stage.name}{schedule.stage.job_id !== application.job_id ? ` (${schedule.stage.job.name})` : ''}</div>
      {schedule.type === 'schedule' && <div className="date">{formatMoment(Moment(schedule.interviews[0].start_time), TimeFormat.LongMonthDayYear)}</div>}
      {schedule.type === 'self_scheduling_link' && (
        <div className="description">{schedule.status === 'requested' ? 'Pending candidate response' : 'Self-scheduling request'}</div>
      )}
    </div>
    {schedule.type === 'schedule' && schedule.imported_from_ats && (
      <div className="imported">
        <ATSImportedIcon isImported />
      </div>
    )}
  </div>
);

export default ScheduleMenuLabel;
