import Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { NavLink } from 'react-router-dom';
import { faEnvelope, faHeart } from '@fortawesome/free-solid-svg-icons';
import { faLinkedinIn, faTwitter } from '@fortawesome/free-brands-svg-icons';

import OutboundLink from '../library/navigation/OutboundLink';

const Footer = () =>
  <>
    <footer>
      <div className="footer-section logo-section">
        <NavLink className="logo" to="/">
          <img
            alt="InterviewPlanner logo"
            className="logo"
            src="/scheduling/src/assets/images/logo.svg"
          />
        </NavLink>
        <div className="social-links-section">
          <OutboundLink
            className="btn btn-social btn-twitter"
            externalLinkIcon={false}
            href="https://twitter.com/interviewplannr"
            label="Twitter Website Footer"
          >
            <FontAwesomeIcon icon={faTwitter} />
          </OutboundLink>
          <OutboundLink
            className="btn btn-social btn-linkedin"
            externalLinkIcon={false}
            href="https://linkedin.com/company/interviewplanner"
            label="LinkedIn Website Footer"
          >
            <FontAwesomeIcon icon={faLinkedinIn} />
          </OutboundLink>
          <OutboundLink
            className="btn btn-social btn-email"
            externalLinkIcon={false}
            href="mailto:hello@interviewplanner.com"
            label="Email Website Footer"
          >
            <FontAwesomeIcon icon={faEnvelope} />
          </OutboundLink>
        </div>
      </div>

      <div className="footer-section">
        <h6>
          Product
        </h6>
        <NavLink to="/#features">Features</NavLink>
        <NavLink to="/features/integrations">Integrations</NavLink>
        <NavLink to="/features/interviewer-training">Interviewer Training</NavLink>
      </div>

      <div className="footer-section">
        <h6>
          Company
        </h6>
        <NavLink to="/about">About</NavLink>
        <OutboundLink
          externalLinkIcon={false}
          href="https://interviewplanner.com/blog/"
          label="Blog Footer">
          Blog
        </OutboundLink>
      </div>

      <div className="footer-section">
        <h6>
          Resources
        </h6>
        <OutboundLink
          externalLinkIcon={false}
          href="https://support.gem.com/hc/en-us/categories/23477652183703-Gem-Scheduling"
          label="Gem Zendesk Support Footer">
          Support
        </OutboundLink>
        <NavLink to="/privacy">Privacy</NavLink>
      </div>
    </footer>
    <footer className="footer-copyright">
      <span>
        &copy; {Moment().year()} InterviewPlanner |
        Made with <FontAwesomeIcon className="emphasis" icon={faHeart} /> in San Francisco
      </span>
    </footer>
  </>
;

export default Footer;
