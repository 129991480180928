import Moment from 'moment-timezone';

import { RSVP } from '../types';

import type { Conflict, Event, Meeting } from '../types';

/**
 * Helper function to assert that the passed in event is a Zoom Meeting.
 *
 * @param meeting The possible event or meeting.
 */
export function isMeeting (meeting: Conflict): meeting is Meeting {
  return Boolean((meeting as Meeting).topic);
}

/**
 * Helper function to assert that the passed in event is a Calendar Event.
 *
 * @param event The possible event or meeting.
 */
export function isEvent (event: Conflict): event is Event {
  return Boolean((event as Event).ical_uid);
}

interface Interview {
  id?: string;
  start_time: string;
  interview_template: {
    duration_minutes: number;
  };
}

interface Schedule {
  candidate_event_ical_uid?: string;
  candidate_event_location?: string;
  timezone: string;
}

/**
 * This function is the client-side version of determining if an event for a specific user is actually a conflict
 * relative to a specific interview.
 *
 * @param event The conflict in question. This could either be an Event or a Zoom Meeting.
 * @param interview The interview we're checking the conflict against.
 * @param schedule The schedule that the interview is associated with.
 * @param interviewerEventICalUIds An array of iCal UIDs for all the interviews in a schedule.
 * @param checkIfIgnored Whether we want to take ignored status into account. We do this for various reasons like if
 * we're checking room conflicts (since room events don't have a concept of being ignored; Google Calendar will decline
 * the invitation if that spot is taken, no matter what) or if we're filtering events, but we want to show ignored
 * conflicts on the frontend.
 * @param userEmail The email of the specific user we're checking for.
 */
export function isConflict (event: Conflict, interview: Interview, schedule: Schedule, interviewerEventICalUIds: (string | undefined)[], checkIfIgnored: boolean = true, userEmail?: string): boolean {
  if (checkIfIgnored && isEvent(event) && event.ignored) {
    return false;
  }

  if (isEvent(event) && userEmail) {
    const attendee = event.attendees?.find((a) => a.email === userEmail);
    if (attendee && attendee.rsvp === RSVP.Declined) {
      // The user declined this event, so we shouldn't count it as a conflict.
      return false;
    }
  }

  if (isEvent(event) && (interviewerEventICalUIds.includes(event.ical_uid) || schedule.candidate_event_ical_uid === event.ical_uid)) {
    return false;
  }

  if (isMeeting(event) && event.join_url && event.join_url === schedule.candidate_event_location) {
    return false;
  }

  if (isEvent(event) && event.interview_limit_metadata) {
    // This event is an interview limit event. We need to check if this
    // interview is being considered for this interview limit event, and if so,
    // subtract it from the count. If, once subtracted, it's under the
    // threshold, don't consider it a conflict. Otherwise, consider it a
    // conflict.
    if (interview.id && event.interview_limit_metadata.interview_ids.includes(interview.id)) {
      // This interview was considered when creating this limit event.
      return (
        event.interview_limit_metadata.interview_limit !== -1 && // -1 means that there is no limit.
          event.interview_limit_metadata.interview_ids.length - 1 >= event.interview_limit_metadata.interview_limit
      );
    }
  }

  const interviewStart = Moment.tz(interview.start_time, schedule.timezone);
  const interviewEnd = interviewStart.clone().add(interview.interview_template.duration_minutes, 'minutes');

  return Moment(event.start_time).isBefore(interviewEnd) && interviewStart.isBefore(Moment(event.end_time));
}
