import { Breadcrumb } from 'react-breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Route, Switch } from 'react-router-dom';
import { faEnvelope } from '@fortawesome/free-solid-svg-icons';

import EmailTemplate from './EmailTemplate';
import EmailTemplateCreate from './EmailTemplateCreate';
import EmailTemplateList from './EmailTemplateList';
import { correctPath } from 'libraries/gem';

const EmailTemplates = () => (
  <Breadcrumb
    data={{
      title: <><FontAwesomeIcon icon={faEnvelope} />Email Templates</>,
      pathname: correctPath('/app/email-templates'),
    }}
  >
    <Switch>
      <Route component={EmailTemplateList} exact path={correctPath('/app/email-templates')} />
      <Route component={EmailTemplateCreate} path={correctPath('/app/email-templates/create')} />
      <Route component={EmailTemplate} path={correctPath('/app/email-templates/:id')} />
    </Switch>
  </Breadcrumb>
);

export default EmailTemplates;
