import classnames from 'classnames';
import { startCase } from 'lodash';
import { useCallback, useMemo } from 'react';

import Tag from '../../data-display/Tag';
import { HiringMeetingAttendeeFilterExpressionFilterableType, HiringMeetingAttendeeFilterExpressionInterviewersFilterableId, HiringTeamRole } from '../../../../types';
import { StyledSelectInput } from './styles';
import { useUsers } from '../../../../hooks/queries/users';

import type { EditableHiringMeetingAttendeeFilter } from '../../../../types';
import type { Group, Option as SelectOption } from '../SelectInput/types';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';

interface Option extends SelectOption<string> {
  filterable_type: HiringMeetingAttendeeFilterExpressionFilterableType;
  filterable_id: string;
}

interface Props {
  className?: string;
  isDisabled?: boolean;
  onChange: (newValue: EditableHiringMeetingAttendeeFilter[]) => void;
  value?: EditableHiringMeetingAttendeeFilter[];
}

const HiringMeetingAttendeeSelectInput = ({
  className,
  isDisabled,
  onChange,
  value,
}: Props) => {
  const { data: users } = useUsers({ archived: true });

  const options = useMemo<Group<string, Option>[]>(() => {
    return [{
      label: 'Interviewers',
      options: [{
        filterable_type: HiringMeetingAttendeeFilterExpressionFilterableType.Interviewers,
        filterable_id: HiringMeetingAttendeeFilterExpressionInterviewersFilterableId.CurrentStage,
        label: <Tag type="hiring_meeting_interviewers" value="stage" />,
        value: 'Interviewers for stage',
      }, {
        filterable_type: HiringMeetingAttendeeFilterExpressionFilterableType.Interviewers,
        filterable_id: HiringMeetingAttendeeFilterExpressionInterviewersFilterableId.PreviousStages,
        label: <Tag type="hiring_meeting_interviewers" value="previous stages" />,
        value: 'Interviewers for previous stages',
      }],
    }, {
      label: 'Hiring Team',
      options: Object.entries(HiringTeamRole).map(([, role]) => ({
        filterable_type: HiringMeetingAttendeeFilterExpressionFilterableType.HiringTeam,
        filterable_id: role,
        label: <Tag type="hiring_team" value={role} />,
        value: startCase(role),
      })),
    }, {
      label: 'Individuals',
      options: (users?.users || []).filter(({ directory_archived, user_archived }) => !user_archived && !directory_archived).map((user) => ({
        filterable_type: HiringMeetingAttendeeFilterExpressionFilterableType.User,
        filterable_id: user.id,
        label: <Tag type="user" value={user.id} />,
        value: user.name || user.email,
      })),
    }];
  }, [users]);

  const selectedOptions = useMemo<Option[]>(() => {
    // Currently, we don't support having more than one hiring meeting attendee filter, so we just pull out the
    // expressions from the first one if it exists.
    const expressions = value?.[0]?.hiring_meeting_attendee_filter_expressions || [];
    return expressions.map((expression) => {
      switch (expression.filterable_type) {
        case HiringMeetingAttendeeFilterExpressionFilterableType.Interviewers:
          return options[0].options.find((option) => option.filterable_id === expression.filterable_id);
        case HiringMeetingAttendeeFilterExpressionFilterableType.HiringTeam:
          return options[1].options.find((option) => option.filterable_id === expression.filterable_id);
        case HiringMeetingAttendeeFilterExpressionFilterableType.User:
          return options[2].options.find((option) => option.filterable_id === expression.filterable_id);
      }
    }).filter((option): option is Option => Boolean(option));
  }, [options, value]);

  const handleChange = useCallback((options: OnChangeValue<Option, true>) => {
    onChange([{
      hiring_meeting_attendee_filter_expressions: options.map((option) => ({
        filterable_type: option.filterable_type,
        filterable_id: option.filterable_id,
        negated: false,
      })),
    }]);
  }, [onChange]);

  return (
    <StyledSelectInput<string, Option, true>
      className={classnames(['input-hiring-meeting-attendee-select-input', className])}
      isClearable
      isDisabled={isDisabled}
      isMulti
      isRequired
      label="Attendees"
      onChange={handleChange}
      options={options}
      placeholder="Select hiring meeting attendees"
      value={selectedOptions}
    />
  );
};

export default HiringMeetingAttendeeSelectInput;
