import styled from 'styled-components';

import Section from 'components/library/layout/Section';

export const StyledSection = styled(Section)`
  margin-bottom: 50px;

  .flash {
    margin-bottom: 1em;
  }
`;

export const StyledFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;

  .flash {
    margin-bottom: 1em;
  }
`;

export const StyledFormRowContainer = styled.div`
  display: flex;
  gap: 1em;

  .input {
    flex: 1;
  }
`;
