import Moment from 'moment-timezone';
import { isEmpty, keyBy } from 'lodash';
import { useMemo } from 'react';
import { useParams } from 'react-router-dom';

import Avatar from '../../../../../library/data-display/Avatar';
import CalendarEventPreview from '../../../../../library/data-display/CalendarEventPreview';
import Flash from '../../../../../library/utils/Flash';
import ListItem from '../../../../../library/data-display/ListItem';
import LoadingSpinner from '../../../../../library/utils/LoadingSpinner';
import Table from '../../../../../library/data-display/Table';
import { constructScheduleTokens } from '../helpers';
import { formatMoment, TimeFormat } from '../../../../../../libraries/time';
import { isTraineeSlot } from '../../../../../../libraries/training';
import { useAccount } from '../../../../../../hooks/queries/accounts';
import { useApplication } from '../../../../../../hooks/queries/applications';
import { useFeedbackForms } from '../../../../../../hooks/queries/feedback-forms';
import { useMultipleRenders } from '../../../../../../hooks/queries/tokens';
import { useNewSchedule } from '../use-new-schedule';
import { useRoomsMap } from '../../../../../../hooks/queries/rooms';
import { useUsersMap } from '../../../../../../hooks/queries/users';

import type { Block, Interview, Schedule } from '../types';
import type { TableSchema } from '../../../../../library/data-display/Table';

interface Props {
  block: Block;
  scheduleTemplate: Schedule['schedule_template'];
  timezone: string;
}

const InterviewTable = ({ block, scheduleTemplate, timezone }: Props) => {
  const { id } = useParams<{ id: string }>();

  const { data: account } = useAccount();
  const application = useApplication(id).data!;
  const { data: feedbackForms } = useFeedbackForms();
  const rooms = useRoomsMap();
  const users = useUsersMap({ archived: true });

  const { schedule } = useNewSchedule();

  const feedbackFormsById = useMemo(() => keyBy(feedbackForms?.feedback_forms, 'id'), [feedbackForms]);

  const isVercel = account?.id === '3b4efdfa-ada5-4846-ade9-d91eaf1eaa38' ||
    (account?.name?.toLowerCase().includes('vercel'));
  // Vercel's definition of phone interview is if it doesn't have a location and doesn't have video conferencing
  // enabled.
  const isPhoneInterview = !schedule.schedule_template.candidate_calendar_event_template?.location &&
    !schedule.schedule_template.video_conferencing_enabled;

  // interviewer event titles
  const {
    data: renderedInterviewerEventTitles,
    errors: interviewerEventTitleErrors,
  } = useMultipleRenders(block.interviews.map((_, i) => ({
    type: 'interviewer_event_description',
    plain_text: true,
    schedule: constructScheduleTokens(application, schedule, [block], rooms, users)?.[0],
    interview_index: i,
    text: (isVercel && isPhoneInterview ? 'Phone Interview - ' : '') + account?.interviewer_event_title,
  })), {
    enabled: Boolean(account?.interviewer_event_title),
  });

  // interviewer event descriptions
  const {
    data: renderedInterviewerEventDescriptions,
    errors: interviewerEventDescriptionErrors,
  } = useMultipleRenders(block.interviews.map((_, i) => ({
    type: 'interviewer_event_description',
    plain_text: false,
    schedule: constructScheduleTokens(application, schedule, [block], rooms, users)?.[0],
    interview_index: i,
    // TODO: Add 'text' when it is supported
  })));

  const schema = useMemo<TableSchema<Interview>>(() => [{
    header: 'Time',
    displayValue: ({ interview_template: { duration_minutes }, start_time }) => (
      <span>
        {formatMoment(Moment.tz(start_time, timezone), TimeFormat.Time)}&ndash;{formatMoment(Moment.tz(start_time, timezone).add(duration_minutes, 'minutes'), TimeFormat.TimeWithTimezone)}
      </span>
    ),
  }, {
    header: 'Interview',
    displayValue: ({ name }) => name,
  }, account?.ats_type === 'lever' && {
    header: 'Feedback Form',
    displayValue: ({ feedback_form_id }) => {
      if (!feedback_form_id) {
        return <span className="no-template">No feedback form</span>;
      }
      return feedbackFormsById[feedback_form_id]?.name;
    },
  }, {
    header: 'Interview Panel',
    displayValue: ({ interviewers }) => (
      isEmpty(interviewers) ? (
        <i>No interviewers</i>
      ) : (
        <div className="interview-panel">
          {interviewers.map(({ interviewer_template, selected_user: { user_id } }, i) => (
            <ListItem
              key={`interviewer-${i}`}
              label={(
                <span>
                  {users[user_id!]?.name || users[user_id!]?.email}
                  {interviewer_template.optional && <span className="optional-interviewer"> (Optional)</span>}
                  {isTraineeSlot(interviewer_template.interviewer_filters) && <span className="trainee-interviewer"> (Trainee)</span>}
                </span>
              )}
              leftIcon={
                <Avatar
                  showUserTooltip={false}
                  size="small"
                  userId={user_id!}
                />
              }
            />
          ))}
        </div>
      )
    ),
  }], [
    account,
    feedbackFormsById,
    timezone,
    users,
  ]);
  /* eslint-enable react/display-name, react/prop-types */

  return (
    <Table
      collapseRowTooltipText="Hide internal calendar event"
      data={block.interviews}
      displayExpandedContent={(interview, index) => {
        return (
          <>
            <Flash
              message={interviewerEventTitleErrors?.[index]?.message}
              showFlash={Boolean(interviewerEventTitleErrors?.[index])}
              type="danger"
            />
            <Flash
              message={interviewerEventDescriptionErrors?.[index]?.message}
              showFlash={Boolean(interviewerEventDescriptionErrors?.[index])}
              type="danger"
            />
            <CalendarEventPreview
              description={renderedInterviewerEventDescriptions?.[index]?.rendered_text || <LoadingSpinner />}
              endTime={Moment.tz(interview.start_time, timezone).add(interview.interview_template.duration_minutes, 'minutes').toDate()}
              guests={interview.interviewers.map(({ interviewer_template: { description, optional, interviewer_filters }, selected_user: { user_id } }) => ({
                id: user_id!,
                description: `${description || 'Interviewer'}${isTraineeSlot(interviewer_filters) ? ' (Trainee)' : ''}${optional ? ' (Optional)' : ''}`,
              }))}
              isVideoConferencingEnabled={scheduleTemplate.video_conferencing_enabled}
              location={scheduleTemplate.video_conferencing_enabled ? (block.video_conferencing_link && (!block.selected_zoom_host || block.current_zoom_host_id === block.selected_zoom_host.id) ? block.video_conferencing_link : undefined) : undefined}
              roomId={block.selected_room?.room_id || undefined}
              startTime={Moment.tz(interview.start_time, timezone).toDate()}
              timezone={timezone}
              title={renderedInterviewerEventTitles?.[index]?.rendered_text || <LoadingSpinner />}
            />
          </>
        );
      }}
      expandRowTooltipText="Show internal calendar event"
      layout="vertical"
      schema={schema}
    />
  );
};

export default InterviewTable;
