import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Avatar from 'components/library/data-display/Avatar';
import Table from 'components/library/data-display/Table';

export const StyledAvatar = styled(Avatar)`
  margin: auto;

  .avatar {
    border: none;
  }
`;

export const StyledCheckmarkIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.funGreen};
`;

export const StyledXIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.brightRed};
`;

// The typing for this is weird because of styled-components. This is inspired from
// https://github.com/styled-components/styled-components/issues/1803#issuecomment-857092410
export const StyledTable = styled(Table)`
  margin-top: 2em;

  tr {
    border-left: none;
    border-right: none;

    th {
      background: none;
      font-weight: normal;
      font-size: .9em;

      .list-item {
        margin: auto;
        width: fit-content;
      }
    }

    td:not(:first-child) {
      text-align: center;
    }

    th:first-child,
    td:first-child {
      border-right: 1px solid ${({ theme }) => theme.colors.mercuryGray};
    }
  }

  thead > tr {
    border-top: none;
  }

  tbody > tr:last-child {
    border-bottom: none;
  }
` as typeof Table;
