import * as MomentBase from 'moment';
import useResizeObserver from 'use-resize-observer';
import { extendMoment } from 'moment-range';
import { groupBy, max, min, orderBy } from 'lodash';
import { useEffect, useMemo, useRef } from 'react';

import Avatar from '../../../../library/data-display/Avatar';
import Label from '../../../../library/utils/Label';
import Table from '../../../../library/data-display/Table';
import { formatMoment, TimeFormat } from '../../../../../libraries/time';
import { useUsersMap } from '../../../../../hooks/queries/users';

import type { Dispatch, SetStateAction } from 'react';
import type { Interview } from '../../../../../types';
import type { InterviewerReportRowBase } from '../../types';
import type { TableSchema } from '../../../../library/data-display/Table';
import { correctPath } from 'libraries/gem';

const Moment = extendMoment(MomentBase);

const pageSize = 100;

const formatDateRange = (start: MomentBase.Moment, end: MomentBase.Moment): string => {
  const isSameMonth = Moment(start).isSame(Moment(end), 'month');
  const startDate = start ? formatMoment(Moment(start), TimeFormat.ShortMonthDay) : ' ';
  const endDate = end ? formatMoment(Moment(end), isSameMonth ? TimeFormat.DayOfMonth : TimeFormat.ShortMonthDay) : ' ';
  return `${startDate}–${endDate}`;
};

type DailyBreakdownRow = InterviewerReportRowBase & {
  interviewsByDay: { [date: string]: Interview[] };
  interviewsByWeek: { [date: string]: Interview[] };
  totalInterviews: number;
};

interface Props {
  endDate?: string | MomentBase.Moment;
  interviews: Interview[];
  pageNumber: number;
  search: string;
  setPageNumber: Dispatch<SetStateAction<string>>;
  startDate?: string | MomentBase.Moment;
}

const DailyBreakdownTable = ({ endDate, interviews, pageNumber, search, setPageNumber, startDate }: Props) => {
  const users = useUsersMap({ archived: true });

  const tableContainerRef = useRef<HTMLDivElement>(null);
  const { width: tableContainerWidth } = useResizeObserver({ ref: tableContainerRef });

  useEffect(() => {
    const tablePaginationHeaderElement = tableContainerRef.current?.querySelector<HTMLElement>('.table-pagination-header');
    if (tablePaginationHeaderElement) {
      const width = `calc(${tableContainerWidth}px - 2em)`;
      tablePaginationHeaderElement.style.width = width;
      tablePaginationHeaderElement.setAttribute('style', `width: ${width}`);
    }
  }, [tableContainerWidth]);

  const dateRange = useMemo(() => {
    let start = startDate;
    if (start) {
      start = (Moment.isMoment(start) ? start : Moment(start)).startOf('day');
    }

    let end = endDate;
    if (end) {
      end = (Moment.isMoment(end) ? end : Moment(end)).endOf('day');
    }

    return { start, end };
  }, [startDate, endDate]);

  const data = useMemo<DailyBreakdownRow[]>(() => {
    const userInterviews: { [key: string]: InterviewerReportRowBase } = {};

    interviews.forEach((interview) => (interview.interviewers || []).forEach(({ user_id }) => {
      if (!userInterviews[user_id]) {
        userInterviews[user_id] = {
          id: user_id,
          name: users[user_id]?.name,
          email: users[user_id]?.email,
          interviews: [],
          directory_archived: users[user_id]?.directory_archived,
          user_archived: users[user_id]?.user_archived,
        };
      }
      userInterviews[user_id].interviews.push(interview);
    }));

    const interviewers: DailyBreakdownRow[] = Object.values(userInterviews).map((user) => {
      const interviewsInDateRange = user.interviews.filter(({ start_time }) => {
        if (dateRange.start && dateRange.end) {
          return Moment(start_time).isBetween(dateRange.start, dateRange.end);
        }
        if (dateRange.start) {
          return Moment(start_time).isSameOrAfter(dateRange.start);
        }
        if (dateRange.end) {
          return Moment(start_time).isSameOrBefore(dateRange.end);
        }
        return true;
      });

      return {
        ...user,
        interviewsByDay: groupBy(interviewsInDateRange, ({ start_time }) => Moment(start_time).startOf('day').format('YYYY-MM-DD')),
        interviewsByWeek: groupBy(interviewsInDateRange, ({ start_time }) => Moment(start_time).startOf('week').format('YYYY-MM-DD')),
        totalInterviews: interviewsInDateRange.length,
      };
    });

    return orderBy(interviewers, ['name'], ['asc']);
  }, [interviews, users]);

  const filteredData = useMemo<DailyBreakdownRow[]>(() => {
    const lowercaseSearch = search.toLowerCase();
    return data.filter(({ totalInterviews, email, name }) => totalInterviews > 0 && (!lowercaseSearch || email?.toLowerCase().includes(lowercaseSearch) || name?.toLowerCase().includes(lowercaseSearch)));
  }, [data, search]);

  const page = useMemo<DailyBreakdownRow[]>(() => {
    const pageIndex = pageNumber - 1;
    const offset = pageSize * pageIndex;
    return filteredData.slice(offset, offset + pageSize);
  }, [filteredData, pageNumber]);

  const columns = useMemo<TableSchema<DailyBreakdownRow>>(() => {
    const interviewTimes = interviews.map(({ start_time }) => start_time);
    const firstDay = dateRange.start ? Moment(dateRange.start) : Moment(min(interviewTimes));
    const lastDay = dateRange.end ? Moment(dateRange.end) : Moment(max(interviewTimes));
    const days = Array.from(Moment.range(firstDay, lastDay).by('day'));

    const schema: TableSchema<DailyBreakdownRow> = [{
      header: '',
      displayValue: ({ directory_archived, email, id, name, user_archived }) => (
        <div className="interviewers-table-interviewer">
          <Avatar
            showUserTooltip={false}
            size="small"
            userId={id}
          />
          {name || email}
          {(user_archived || directory_archived) && <Label color="gray">Archived</Label>}
        </div>
      ),
    }];

    days.forEach((day) => {
      schema.push({
        header: <span><span className="subtitle">{formatMoment(day, TimeFormat.ShortDayOfWeek)}</span><br />{formatMoment(day, TimeFormat.ShortMonthDay)}</span>,
        key: day.format('YYYY-MM-DD'),
        id: ({ id }) => `user-${id}-${day.format('YYYY-MM-DD')}`,
        displayValue: ({ interviewsByDay }) => {
          const interviewsForDay = interviewsByDay[day.format('YYYY-MM-DD')];
          return interviewsForDay ? interviewsForDay.length : <span className="empty">&ndash;</span>;
        },
        displayPopoverContent: ({ interviewsByDay }) => {
          const interviewsForDay = interviewsByDay[day.format('YYYY-MM-DD')];
          if (!interviewsForDay || !interviewsForDay.length) {
            return null;
          }
          return (
            <Table
              className="daily-load-table-popover"
              data={interviewsForDay}
              getRowLink={({ schedule }) => schedule.application ? correctPath(`/app/candidates/${schedule.application.id}/schedules/${schedule.id}`) : undefined}
              layout="vertical"
              schema={[{
                header: 'Interview',
                displayValue: ({ name }) => name,
                hasLinkStyleOnHover: true,
              }, {
                header: 'Job',
                displayValue: ({ schedule }) => schedule.stage.job.name,
              }, {
                header: 'Candidate',
                displayValue: ({ schedule }) => schedule.application?.candidate.name || <span className="redacted">Redacted</span>,
              }, {
                header: 'Time',
                displayValue: ({ start_time }) => formatMoment(Moment(start_time).tz(Moment.tz.guess()), TimeFormat.TimeWithTimezone),
              }]}
            />
          );
        },
        getCellClassName: ({ interviewsByDay }) => {
          const interviewsForDay = interviewsByDay[day.format('YYYY-MM-DD')];
          const numInterviews = interviewsForDay ? interviewsForDay.length : 0;
          if (numInterviews === 0) {
            return 'empty';
          }
          if (numInterviews === 1) {
            return 'link';
          }
          if (numInterviews > 1) {
            return 'link warning';
          }
          return undefined;
        },
      });

      const isLastDayOfWeek = day.isSame(Moment(day).endOf('week'), 'day');
      const isLastDay = day.isSame(lastDay, 'day');
      if (isLastDayOfWeek || isLastDay) {
        const startOfWeek = Moment(day).startOf('week');
        const totalRangeStart = Moment.max(startOfWeek, firstDay);
        const totalRangeEnd = Moment.min(day, lastDay);
        schema.push(({
          header: <span className="total-header"><span className="subtitle">{formatDateRange(totalRangeStart, totalRangeEnd)}</span><br />Total</span>,
          key: `total-${startOfWeek.format('YYYY-MM-DD')}`,
          displayValue: ({ interviewsByWeek }) => {
            const interviewsForWeek = interviewsByWeek[startOfWeek.format('YYYY-MM-DD')];
            return interviewsForWeek ? interviewsForWeek.length : <span className="empty">&ndash;</span>;
          },
          getCellClassName: () => 'total',
        }));
      }
    });

    return schema;
  }, [interviews]);

  return (
    <div className="table-container" ref={tableContainerRef}>
      <Table
        data={page}
        dataDescriptor="interviewers"
        isPaginated
        layout="vertical"
        onPageNumberChange={(value) => setPageNumber(value.toString())}
        pageNumber={pageNumber}
        pageSize={pageSize}
        schema={columns}
        showGrid
        totalCount={filteredData.length}
      />
    </div>
  );
};

export default DailyBreakdownTable;
