import { isEqual, pickBy } from 'lodash';

import type { RecursiveNullPartial } from '../types/helpers';

export function differingValues<T extends { [key: string]: any }, U extends RecursiveNullPartial<T>> (newObject: T, existingObject: U): Partial<T> {
  return pickBy(newObject, (value, key) => {
    const existingValue = existingObject[key];
    const areBothValuesEmpty = !existingValue && !value;
    return !areBothValuesEmpty && !isEqual(value, existingValue);
  }) as Partial<T>;
}
