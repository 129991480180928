import { orderBy, range } from 'lodash';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import InterviewPlanner from '../../libraries/interviewplanner';
import { QueryKey as ApplicationQueryKey } from './applications';
import { QueryKey as InterviewQueryKeys } from './interviews';

import type { Event, MatchingPool, Meeting, Schedule } from '../../types';
import type { InterviewerFilterableType } from '../../types';
import type { UseQueryOptions, FetchQueryOptions } from 'react-query';
import type { ZoomHostType } from '../../types';
import type { ZoomHostFilterableType } from '../../types';

export enum QueryKey {
  GenerateSchedules = 'GenerateSchedules',
  RetrieveSchedule = 'RetrieveSchedule',
}

export const useSchedule = (id: string, options: UseQueryOptions<Schedule, Error> = {}) => {
  return useQuery<Schedule, Error>([QueryKey.RetrieveSchedule, id], () => {
    return InterviewPlanner.request('GET', `/schedules/${id}`);
  }, {
    ...options,
    enabled: options.enabled !== undefined ? options.enabled : Boolean(id),
  });
};

interface GenerateSchedulesPayloadInterviewerTemplate {
  description?: string;
  optional: boolean;
  include_past_interviewers: boolean;
  interviewer_filters: {
    interviewer_filter_expressions: {
      negated: boolean;
      filterable_id: string;
      filterable_type: `${InterviewerFilterableType}`;
    }[];
  }[];
}

interface GenerateSchedulesPayloadInterviewTemplate {
  name: string;
  duration_minutes: number;
  live_coding_enabled?: boolean;
  position?: number;
  time_window_start?: string;
  time_window_end?: string;
  candidate_facing_name?: string;
  candidate_facing_details?: string;
  interviewer_templates: GenerateSchedulesPayloadInterviewerTemplate[];
}

export interface GenerateSchedulesPayload {
  application_id: string;
  availabilities: {
    start_time: string;
    end_time: string;
  }[];
  maximum_blocks: number;
  minimum_blocks: number;
  minimum_minutes_between_blocks: number;
  offset?: number;
  page_size?: number;
  timezone: string;
  schedule_template: {
    video_conferencing_enabled: boolean;
    business_hours: {
      day: string;
      start_time: string;
      end_time: string;
      timezone?: string;
    }[];
    zoom_host_filters?: {
      zoom_host_filter_expressions: {
        negated: boolean;
        filterable_id: string;
        filterable_type: string;
      }[];
    }[];
    room_filters?: {
      room_filter_expressions: {
        negated: boolean;
        filterable_id: string;
        filterable_type: string;
      }[];
    }[];
  };
  stage_interviews: {
    id: string;
    name: string;
    feedback_form_id?: string;
    interview_template: GenerateSchedulesPayloadInterviewTemplate;
  }[];
}

interface GenerateSchedulesData {
  conflicts: {
    [blockCount: number]: {
      [conflictCount: number]: number;
    };
  };
  events: EventResponse;
  users: {
    id: string;
  }[];
  rooms: {
    id: string;
  }[];
  zoom_hosts: {
    id: string;
    type: `${ZoomHostType}`;
  }[];
  matching_pool: MatchingPool;
  total_counts?: {
    [blockCount: number]: number;
  };
  completed?: {
    [blockCount: number]: boolean;
  };
  number_completed?: {
    [blockCount: number]: number;
  };
}

interface EventResponse {
  [date: string]: {
    users: {
      [id: string]: {
        events: Event[];
        meetings: Meeting[];
      };
    };
    rooms: {
      [id: string]: {
        events: Event[];
        meetings: Meeting[];
      };
    };
    zoom_hosts: {
      [id: string]: {
        events: Event[];
        meetings: Meeting[];
      };
    };
  };
}

export interface GenerateBlock {
  interviews: {
    stage_interview_id: string;
    name: string;
    start_time: string;
    interview_template: GenerateSchedulesPayloadInterviewTemplate;
    interviewers: {
      interviewer_template: GenerateSchedulesPayloadInterviewerTemplate;
      potential_users: {
        user_id: string;
        daily_interview_count: number;
        weekly_interview_count: number;
      }[];
      selected_user: {
        user_id: string;
        daily_interview_count: number;
        weekly_interview_count: number;
      };
    }[];
    feedback_form_id?: string;
  }[];
  potential_rooms?: {
    room_id: string;
  }[];
  selected_room?: {
    room_id: string;
  };
  potential_zoom_hosts?: {
    id: string;
    type: `${ZoomHostType}`;
  }[];
  selected_zoom_host?: {
    id: string;
    type: `${ZoomHostType}`;
  };
}

export interface GenerateScheduleOption {
  conflicts: number;
  blocks: GenerateBlock[];
}

export interface ConflictTotals {
  [conflicts: string]: number;
}

/*
The v1 response looked like this:
{
  conflicted_totals: { [conflicts: number]: number };
  schedules: {
    interviews: [];
    conflicts: number;
  }[];
  total: number;
  events: {
    [day: 'YYYY-MM-DD']: {
      users: { [id: string]: Event[] };
      rooms: { [id: string]: Event[] };
      zoom_hosts: { [id: string]: Event[] };
    }
  };
}
We transform the v2 response to look like:
{ [numberOfBlocks: number]: V1Response }
*/
interface TransformedData {
  [numberOfBlocks: string]: {
    conflicted_totals: ConflictTotals;
    events: EventResponse;
    schedules: GenerateScheduleOption[];
    completed: boolean;
    total: number;
    number_completed: number;
  };
}
export const transformGenerateSchedulesResponse = (response: GenerateSchedulesData, payload: GenerateSchedulesPayload): TransformedData => {
  const transformedResponse: TransformedData = {};
  range(payload.minimum_blocks, payload.maximum_blocks + 1).forEach((numberOfBlocks) => {
    const scheduleOptions = response.matching_pool[numberOfBlocks];
    const scheduleOptionsOrderedByConflicts = orderBy(scheduleOptions, 'c');
    const transformedScheduleOptions = scheduleOptionsOrderedByConflicts.map((scheduleOption) => ({
      conflicts: scheduleOption.c,
      blocks: scheduleOption.b.map((block) => ({
        interviews: block.s.map((interview) => {
          const stageInterview = payload.stage_interviews[interview.x];
          return {
            stage_interview_id: stageInterview.id,
            name: stageInterview.name,
            start_time: new Date(interview.s * 1000).toISOString(),
            interview_template: stageInterview.interview_template,
            interviewers: interview.i.map((interviewer, i) => ({
              interviewer_template: stageInterview.interview_template.interviewer_templates?.[i],
              potential_users: interviewer.p.map((potentialUser) => ({
                user_id: response.users[potentialUser.x].id,
                daily_interview_count: potentialUser.d,
                weekly_interview_count: potentialUser.w,
              })),
              selected_user: {
                user_id: response.users[interviewer.s.x].id,
                daily_interview_count: interviewer.s.d,
                weekly_interview_count: interviewer.s.w,
              },
            })),
            feedback_form_id: stageInterview.feedback_form_id,
          };
        }),
        potential_rooms: block.pr?.map((room) => ({
          room_id: response.rooms[room.x].id,
        })) || [],
        selected_room: block.sr ? {
          room_id: response.rooms[block.sr.x].id,
        } : undefined,
        potential_zoom_hosts: block.pz?.map((potentialZoomHost) => ({
          id: response.zoom_hosts[potentialZoomHost.x].id,
          type: response.zoom_hosts[potentialZoomHost.x].type,
        })) || [],
        selected_zoom_host: block.sz ? {
          id: response.zoom_hosts[block.sz.x].id,
          type: response.zoom_hosts[block.sz.x].type,
        } : undefined,
      })),
    }));

    transformedResponse[numberOfBlocks] = {
      conflicted_totals: response.conflicts[numberOfBlocks],
      events: {},
      schedules: transformedScheduleOptions,
      completed: response.completed?.[numberOfBlocks] ?? true,
      total: response.total_counts?.[numberOfBlocks] || transformedScheduleOptions.length,
      number_completed: response.number_completed?.[numberOfBlocks] || 1000000,
    };
  });
  return transformedResponse;
};

export const generateSchedulesParams = (payload: GenerateSchedulesPayload, options: FetchQueryOptions<GenerateSchedulesData, Error> = {}): UseQueryOptions<GenerateSchedulesData, Error> => {
  return {
    queryKey: [QueryKey.GenerateSchedules, payload],
    queryFn: () => {
      return InterviewPlanner.request('POST', '/v2/schedules/generate', payload, undefined, false, true);
    },
    ...options,
  };
};

export interface CreateSchedulePayload {
  application_id: string;
  send_interviewer_notifications?: boolean;
  send_candidate_notifications?: boolean;
  timezone: string;
  candidate_timezone?: string;
  scheduling_calendar_email: string;
  candidate_scheduling_calendar_email?: string;
  hold?: boolean;
  delete_held_schedules?: boolean;
  schedule_template: {
    candidate_event_title: string;
    candidate_event_description: string;
    candidate_event_location?: string;
    candidate_event_additional_attendees?: string[];
    candidate_event_additional_optional_attendees?: string[];
    business_hours: {
      day: string;
      start_time: string;
      end_time: string;
      timezone?: string;
    }[];
    onsite: boolean;
    mark_interviewer_events_as_private: boolean;
    mark_candidate_events_as_private: boolean;
    video_conferencing_enabled: boolean;
    zoom_host_filters?: {
      zoom_host_filter_expressions: {
        filterable_id: string;
        filterable_type: `${ZoomHostFilterableType}`;
        negated: boolean;
      }[];
    }[];
    create_hiring_channel: boolean;
    confirmation_email_template?: {
      id?: string;
      name: string;
      subject: string;
      sender_name: string;
      sender_email: string;
      cc_emails?: string[];
      bcc_emails?: string[];
      body: string;
      attachments?: ({
        name: string;
        new?: boolean;
        index?: number;
        file?: File;
      } | File)[];
    };
    multi_block_confirmation_email_template?: {
      id?: string;
      name: string;
      subject: string;
      sender_name: string;
      sender_email: string;
      cc_emails?: string[];
      bcc_emails?: string[];
      body: string;
      attachments?: ({
        name: string;
        new?: boolean;
        index?: number;
        file?: File;
      } | File)[];
    };
    room_filters?: {
      room_filter_expressions: {
        negated: boolean;
        filterable_id: string;
        filterable_type: string;
      }[];
    }[];
  };
  schedules: ({
    // For held schedules.
    id?: string;
  } | {
    // For new schedules.
    interviews: {
      id?: string;
      stage_interview_id: string;
      feedback_form_id?: string;
      room_id?: string;
      zoom_host_id?: string;
      zoom_host_type?: string;
      start_time: string;
      interview_template: {
        name: string;
        duration_minutes: number;
        live_coding_enabled?: boolean;
        position?: number;
        time_window_start?: string;
        time_window_end?: string;
        candidate_facing_name?: string;
        candidate_facing_details?: string;
      };
      interviewers?: {
        id?: string;
        user_id: string;
        interviewer_template: {
          description?: string;
          optional: boolean;
          interviewer_filters: {
            interviewer_filter_expressions: {
              negated: boolean;
              filterable_id: string;
              filterable_type: string;
            }[];
          }[];
        };
      }[];
    }[];
  })[];
}

interface CreateScheduleMutationVariables {
  payload?: CreateSchedulePayload;
}

interface CreateScheduleData {
  schedules: Schedule[];
  total: number;
}

export const useCreateSchedule = () => {
  const queryClient = useQueryClient();

  return useMutation<CreateScheduleData, Error, CreateScheduleMutationVariables>(({ payload }) => {
    let isMultipartForm = false;

    const emailTemplate = payload?.schedule_template?.confirmation_email_template || payload?.schedule_template?.multi_block_confirmation_email_template;
    if (emailTemplate?.attachments) {
      emailTemplate.attachments = emailTemplate.attachments.map((attachment) => {
        const isNew = attachment instanceof File;
        isMultipartForm = isMultipartForm || isNew;
        return {
          name: attachment.name,
          new: isNew,
          index: isNew ? undefined : attachment.index,
          file: isNew ? attachment : undefined,
        };
      });
    }

    return InterviewPlanner.request('POST', '/v2/schedules', payload, null, isMultipartForm);
  }, {
    onSuccess: (data, { payload }) => {
      queryClient.invalidateQueries([InterviewQueryKeys.ListInterviews]);
      queryClient.invalidateQueries([ApplicationQueryKey.ListApplications]);
      queryClient.invalidateQueries([ApplicationQueryKey.RetrieveApplication, payload?.application_id]);
      data.schedules.forEach((schedule) => queryClient.setQueryData([QueryKey.RetrieveSchedule, schedule.id], schedule));
    },
  });
};

export interface UpdateSchedulePayload {
  send_interviewer_notifications?: boolean;
  send_candidate_notifications?: boolean;
  update_names?: boolean;
  update_descriptions?: boolean;
  confirming_held_schedule?: boolean;
  schedule_template?: {
    candidate_event_title?: string;
    candidate_event_description?: string;
    candidate_event_location?: string;
    candidate_event_additional_attendees?: string[];
    candidate_event_additional_optional_attendees?: string[];
    video_conferencing_enabled?: boolean;
    confirmation_email_template?: {
      id?: string;
      enabled: boolean;
      subject?: string;
      sender_name?: string;
      sender_email?: string;
      cc_emails?: string[];
      bcc_emails?: string[];
      body?: string;
      attachments?: ({
        name: string;
        new: boolean;
        index?: number;
        file?: File;
      } | File)[];
    };
    multi_block_confirmation_email_template?: {
      id?: string;
      enabled: boolean;
      subject?: string;
      sender_name?: string;
      sender_email?: string;
      cc_emails?: string[];
      bcc_emails?: string[];
      body?: string;
      attachments?: ({
        name: string;
        new: boolean;
        index?: number;
        file?: File;
      } | File)[];
    };
    room_filters?: {
      room_filter_expressions: {
        negated: boolean;
        filterable_id: string;
        filterable_type: string;
      }[];
    }[];
  };
  interviews?: {
    id?: string;
    stage_interview_id: string;
    feedback_form_id?: string;
    room_id?: string;
    zoom_host_id?: string;
    zoom_host_type?: string;
    start_time: string;
    interview_template: {
      name: string;
      duration_minutes: number;
      live_coding_enabled?: boolean;
      candidate_facing_name?: string;
      candidate_facing_details?: string;
    };
    interviewers?: {
      id?: string;
      user_id: string;
      interviewer_template: {
        description?: string;
        optional: boolean;
        interviewer_filters: {
          interviewer_filter_expressions: {
            negated: boolean;
            filterable_id: string;
            filterable_type: string;
          }[];
        }[];
      };
    }[];
  }[];
}

interface UpdateScheduleMutationVariables {
  id: string;
  payload?: UpdateSchedulePayload;
}

export const useUpdateSchedule = () => {
  const queryClient = useQueryClient();

  return useMutation<Schedule, Error, UpdateScheduleMutationVariables>(({ id, payload }) => {
    let isMultipartForm = false;

    if (payload?.schedule_template?.confirmation_email_template?.attachments) {
      payload.schedule_template.confirmation_email_template.attachments = payload.schedule_template.confirmation_email_template.attachments.map((attachment) => {
        const isNew = attachment instanceof File;
        isMultipartForm = isMultipartForm || isNew;
        return {
          name: attachment.name,
          new: isNew,
          index: isNew ? undefined : attachment.index,
          file: isNew ? attachment : undefined,
        };
      });
    }

    if (payload?.schedule_template?.multi_block_confirmation_email_template?.attachments) {
      payload.schedule_template.multi_block_confirmation_email_template.attachments = payload.schedule_template.multi_block_confirmation_email_template.attachments.map((attachment) => {
        const isNew = attachment instanceof File;
        isMultipartForm = isMultipartForm || isNew;
        return {
          name: attachment.name,
          new: isNew,
          index: isNew ? undefined : attachment.index,
          file: isNew ? attachment : undefined,
        };
      });
    }

    return InterviewPlanner.request('POST', `/schedules/${id}`, payload, null, isMultipartForm);
  }, {
    onSuccess: (data, { payload }) => {
      // We're invalidating the application here because we want to make sure
      // that the schedule arrays are up-to-date. We rely on those arrays a lot,
      // so we want to update them when we know we need to.
      queryClient.invalidateQueries([ApplicationQueryKey.RetrieveApplication, data.application_id]);
      queryClient.invalidateQueries([InterviewQueryKeys.ListInterviews]);
      if (payload?.schedule_template?.multi_block_confirmation_email_template) {
        // We've edited the multi-block confirmation email template, so we
        // should invalidate all retrieved schedules so that they will be
        // fetched again. This is because we want to invalidate all schedules of
        // the same block_id, but we can't get that specific.
        queryClient.invalidateQueries([QueryKey.RetrieveSchedule]);
      }
      queryClient.setQueryData([QueryKey.RetrieveSchedule, data.id], data);
    },
  });
};

interface DeleteScheduleQuery {
  send_interviewer_notifications?: boolean;
  send_candidate_notifications?: boolean;
}

interface DeleteScheduleMutationVariables {
  id: string;
  query?: DeleteScheduleQuery;
}

export const useDeleteSchedule = () => {
  const queryClient = useQueryClient();

  return useMutation<Schedule, Error, DeleteScheduleMutationVariables>(({ id, query }) => {
    return InterviewPlanner.request('DELETE', `/schedules/${id}`, null, query);
  }, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([InterviewQueryKeys.ListInterviews]);
      queryClient.invalidateQueries([ApplicationQueryKey.ListApplications]);
      queryClient.invalidateQueries([ApplicationQueryKey.RetrieveApplication, data.application_id]);
      queryClient.setQueryData([QueryKey.RetrieveSchedule, data.id], data);
    },
  });
};
