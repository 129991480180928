import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { forwardRef, useMemo } from 'react';
import { uniqueId } from 'lodash';

import TextInput from './TextInput';

import type { ReactNode, Ref } from 'react';

interface Props {
  aspectRatio?: number;
  className?: string;
  constrainProportions: boolean;
  heightPlaceholder?: string;
  heightValue: string;
  helperText?: ReactNode;
  id?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  label?: string;
  name?: string;
  setHeight: (height: string) => void;
  setWidth: (width: string) => void;
  widthPlaceholder?: string;
  widthValue: string;
}

const DimensionsInputInner = ({
  aspectRatio,
  className,
  constrainProportions,
  heightPlaceholder = 'Height',
  heightValue,
  helperText,
  id,
  isDisabled = false,
  isRequired = false,
  label,
  name,
  setHeight,
  setWidth,
  widthPlaceholder = 'Width',
  widthValue,
}: Props, ref: Ref<HTMLInputElement>) => {
  id = useMemo(() => id || uniqueId('dimensions-input-'), [id]);

  const handleWidthBlur = () => {
    if (aspectRatio && constrainProportions) {
      const width = parseFloat(widthValue);
      if (!isNaN(width)) {
        setHeight(`${Math.round(width / aspectRatio)}`);
      }
    }
  };

  const handleHeightBlur = () => {
    if (aspectRatio && constrainProportions) {
      const height = parseFloat(heightValue);
      if (!isNaN(height)) {
        setWidth(`${Math.round(height * aspectRatio)}`);
      }
    }
  };

  return (
    <div className={`input dimensions-input${className ? ` ${className}` : ''}`}>
      {label && <label htmlFor={id}>{label}</label>}
      <div className="dimensions-input-container" id={id}>
        <TextInput
          id={`${id}-width`}
          isDisabled={isDisabled}
          isRequired={isRequired}
          name={`width${name ? `-${name}` : ''}`}
          onBlur={handleWidthBlur}
          onChange={(e) => setWidth(e.target.value)}
          placeholder={widthPlaceholder}
          ref={ref}
          value={widthValue}
        />
        <FontAwesomeIcon icon={faTimes} />
        <TextInput
          id={`${id}-height`}
          isDisabled={isDisabled}
          isRequired={isRequired}
          name={`height${name ? `-${name}` : ''}`}
          onBlur={handleHeightBlur}
          onChange={(e) => setHeight(e.target.value)}
          placeholder={heightPlaceholder}
          value={heightValue}
        />
      </div>
      {helperText && <div className="helper-text">{helperText}</div>}
    </div>
  );
};

const DimensionsInput = forwardRef(DimensionsInputInner);

export default DimensionsInput;
