import Moment from 'moment';
import { useMemo, useState } from 'react';

import ArchiveButton from '../../../library/inputs/ArchiveButton';
import Avatar from '../../../library/data-display/Avatar';
import FavoriteButton from '../../../library/inputs/FavoriteButton';
import Flash from '../../../library/utils/Flash';
import JobStatusIndicator from '../../../library/utils/JobStatusIndicator';
import Label from '../../../library/utils/Label';
import Table from '../../../library/data-display/Table';
import { FavoriteResourceType } from '../../../../types';
import { formatMoment, TimeFormat } from '../../../../libraries/time';
import { useUpdateJob } from '../../../../hooks/queries/jobs';

import type { ChangeEvent } from 'react';
import type { Job } from '../../../../types';
import type { TableSchema } from '../../../library/data-display/Table';
import { correctPath } from 'libraries/gem';

const getRowLink = ({ id }: Job) => correctPath(`/app/jobs/${id}`);

interface Props {
  jobs: Job[];
  onPageNumberChange?: (pageNumber: number) => void;
  onShowArchivedChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  pageNumber: number;
  showArchived: boolean;
  totalCount: number;
}

const JobListTable = ({
  jobs,
  onPageNumberChange,
  onShowArchivedChange,
  pageNumber,
  showArchived,
  totalCount,
}: Props) => {
  const [lastAction, setLastAction] = useState({
    id: '',
    isUndo: false,
    type: '',
  });

  const updateJobMutation = useUpdateJob();

  const handleArchive = (id: string, undo: boolean) => {
    setLastAction({
      id,
      type: 'archive',
      isUndo: undo,
    });
    updateJobMutation.reset();
    updateJobMutation.mutate({ id, payload: { archived: true } });
  };

  const handleUnarchive = (id: string, undo: boolean) => {
    setLastAction({
      id,
      type: 'unarchive',
      isUndo: undo,
    });
    updateJobMutation.reset();
    updateJobMutation.mutate({ id, payload: { archived: false } });
  };

  const handleUndo = () => {
    if (lastAction.type === 'archive') {
      handleUnarchive(lastAction.id, true);
    }
    if (lastAction.type === 'unarchive') {
      handleArchive(lastAction.id, true);
    }
  };

  const columns = useMemo<TableSchema<Job>>(() => [{
    header: '',
    displayValue: ({ favorite, id }) => (
      <FavoriteButton favorite={favorite} resourceId={id} resourceType={FavoriteResourceType.Job} />
    ),
    isClickable: true,
  }, {
    header: 'Name',
    displayValue: ({ archived, name, stages, ats_created_at }) => {
      const schedulableStages = (stages || []).filter(({ schedule_template_id }) => Boolean(schedule_template_id)).length;
      return (
        <div className="job-list-table-name-container">
          <span className="job-list-table-name">{name}</span>
          {Moment(ats_created_at).isAfter(Moment().add(-1, 'week')) && schedulableStages === 0 && <Label color="green">New</Label>}
          {archived && <Label color="gray">Archived</Label>}
        </div>
      );
    },
    hasLinkStyleOnHover: true,
  }, {
    header: 'Status',
    displayValue: ({ status }) => <JobStatusIndicator status={status} />,
  }, {
    header: 'Schedulable Stages',
    displayValue: ({ stages }) => (stages || []).filter(({ schedule_template_id }) => Boolean(schedule_template_id)).length,
  }, {
    header: 'Office',
    displayValue: ({ offices }) => (
      <span className={offices ? '' : 'unset'}>
        {offices ?
          offices.map(({ id, name }) => <span key={id}>{name}<br /></span>) :
          'Anywhere'
        }
      </span>
    ),
  }, {
    header: 'Hiring Panel',
    displayValue: (job) => (
      <div className="hiring-panel-container">
        {[
          { role: 'Sourcer', userId: job.sourcer_id },
          { role: 'Recruiter', userId: job.recruiter_id },
          { role: 'Coordinator', userId: job.coordinator_id },
          { role: 'Hiring Manager', userId: job.hiring_manager_id },
        ].map((member) => {
          return (
            <Avatar
              alt={member.userId ? undefined : 'Unassigned'}
              key={`${job.id}-${member.role}`}
              size="small"
              tooltipHelperText={member.role}
              userId={member.userId}
            />
          );
        })}
      </div>
    ),
  }, {
    header: 'Date Created',
    displayValue: ({ ats_created_at }) => formatMoment(Moment(ats_created_at), TimeFormat.LongMonthDayYear),
  }, {
    header: 'Actions',
    isClickable: true,
    displayValue: ({ archived, id }) => (
      <ArchiveButton
        id={`${id}-archive-button`}
        isArchived={archived}
        onArchive={() => handleArchive(id, false)}
        onUnarchive={() => handleUnarchive(id, false)}
      />
    ),
  }], [
    handleArchive,
    handleUnarchive,
  ]);

  return (
    <div className="job-list-table-container">
      <Flash
        isDismissible
        message={(
          <span>
            Job {lastAction.type}d.&nbsp;
            {!lastAction.isUndo && <a href="#" onClick={handleUndo}>Undo?</a>}
          </span>
        )}
        showFlash={lastAction.id ? updateJobMutation.isSuccess : false}
        type="success"
      />
      <Flash
        message={updateJobMutation.error?.message}
        onDismiss={updateJobMutation.reset}
        showFlash={updateJobMutation.isError}
        type="danger"
      />
      <Table
        data={jobs}
        getRowLink={getRowLink}
        isPaginated
        layout="vertical"
        onPageNumberChange={onPageNumberChange}
        onShowArchivedChange={onShowArchivedChange}
        pageNumber={pageNumber}
        schema={columns}
        showArchived={showArchived}
        totalCount={totalCount}
      />
    </div>
  );
};

export default JobListTable;
