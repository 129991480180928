import Moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck, faCircle, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useId, useRef } from 'react';

import InterviewSummary from '../InterviewSummary';
import Popover from '../../utils/Popover';
import Table from '../Table';
import Tooltip from '../../utils/Tooltip';
import pluralize from '../../../../libraries/pluralize';
import { StepType } from './types';
import { StyledCheckbox, StyledCircle, StyledIgnoredCircle, StyledPhaseLabel, StyledStep, StyledStepDivider, StyledSubtitle } from './styles';
import { formatMoment, TimeFormat } from '../../../../libraries/time';

import type { Step } from './types';
import type { TrainingPhase, TrainingSession } from '../../../../types';
import { correctPath } from 'libraries/gem';

interface Props {
  graduated: boolean;
  isEditing: boolean;
  onSessionChange: (session: TrainingSession | undefined, phase: TrainingPhase, checked: boolean) => void;
  step: Step;
  total: number;
}

const TrainingProgressStep = ({
  graduated,
  isEditing,
  onSessionChange,
  step,
  total,
}: Props) => {
  const id = useId();

  const ref = useRef<HTMLDivElement>(null);

  return (
    <StyledStep className={!isEditing && step.session?.ignored_at ? 'ignored' : ''}>
      {isEditing ? (
        <StyledCheckbox
          className={step.type}
          isChecked={(
            step.session && ((!step.session.interviewer && !step.session.deleted_at) || (step.session.interviewer && Moment(step.session.interviewer.interview.start_time).isBefore(Moment()) && !step.session.ignored_at))
          )}
          isDisabled={graduated}
          onChange={(e) => onSessionChange(step.session, step.phase, e.target.checked)}
          tooltip={graduated ? (
            <Tooltip
              id={`${id}-checkbox-tooltip`}
              value="You cannot edit the history of a graduate."
            />
          ) : undefined}
        />
      ) : (
        step.session?.ignored_at ? (
          <>
            <StyledIgnoredCircle
              data-for={`${id}-ignored-tooltip`}
              data-tip
              id={`${id}-popover-target`}
              onClick={(e) => e.stopPropagation()}
              ref={ref}
            >
              <FontAwesomeIcon icon={faEyeSlash} />
            </StyledIgnoredCircle>
            <Tooltip
              helperText="(click to view details)"
              id={`${id}-ignored-tooltip`}
              value="Ignored"
            />
          </>
        ) : (
          <>
            <StyledCircle
              className={step.type}
              data-for={`${id}-step-tooltip`}
              data-tip
              onClick={(e) => e.stopPropagation()}
              ref={ref}
            >
              {step.type === StepType.Completed ? (
                <FontAwesomeIcon icon={faCheck} />
              ) : step.type === StepType.Upcoming ? (
                <FontAwesomeIcon icon={faCircle} />
              ) : null}
            </StyledCircle>
            <Tooltip
              helperText={(
                step.type === StepType.Unscheduled ?
                  step.heldSessions ? '(click to view hold details)' : undefined :
                  !step.manuallyReported ? '(click to view details)' : undefined
              )}
              id={`${id}-step-tooltip`}
              value={(
                step.type === StepType.Unscheduled ?
                  'Not scheduled' :
                  step.type === StepType.Upcoming ?
                    `Scheduled${step.excess ? ' in excess' : ''}` :
                    `Completed${step.excess ? ' in excess' : ''}`
              )}
            />
          </>
        )
      )}
      {(isEditing || !step.session?.ignored_at) && (
        <>
          <StyledPhaseLabel title={step.phaseName}>
            {step.phaseName}
          </StyledPhaseLabel>
          <StyledSubtitle>
            {step.manuallyReported ? (
              'Manual'
            ) : step.date ? (
              <span title={formatMoment(Moment(step.date), TimeFormat.LongMonthDayYearWithTime)}>{formatMoment(Moment(step.date), TimeFormat.MonthDayYearWithSlashes)}</span>
            ) : step.heldSessions ? (
              <i>{step.heldSessions.length} {pluralize('schedule', step.heldSessions.length)} on hold</i>
            ) : null}
          </StyledSubtitle>
          {total > 1 && <StyledStepDivider className={step.excess || (isEditing && step.session?.ignored_at) ? 'dashed' : undefined} />}
        </>
      )}
      {!isEditing && step.session?.interviewer && (
        <Popover
          position="bottom"
          target={ref}
        >
          <InterviewSummary
            interviewId={step.session.interviewer.interview_id}
          />
        </Popover>
      )}
      {!isEditing && step.heldSessions && (
        <Popover
          position="bottom"
          target={ref}
        >
          <Table
            className="daily-load-table-popover"
            data={step.heldSessions}
            getRowLink={({ interviewer }) => interviewer!.interview.schedule.application ? correctPath(`/app/candidates/${interviewer!.interview.schedule.application.id}/schedules/${interviewer!.interview.schedule.id}`) : undefined}
            layout="vertical"
            schema={[{
              header: 'Interview',
              displayValue: ({ interviewer }) => interviewer!.interview.name,
              hasLinkStyleOnHover: true,
            }, {
              header: 'Job',
              displayValue: ({ interviewer }) => interviewer!.interview.schedule.stage.job.name,
            }, {
              header: 'Candidate',
              displayValue: ({ interviewer }) => interviewer!.interview.schedule.application?.candidate.name || <span className="redacted">Redacted</span>,
            }, {
              header: 'Time',
              displayValue: ({ interviewer }) => {
                const date = Moment(interviewer!.interview.start_time);
                return <span title={formatMoment(date, TimeFormat.LongMonthDayYearWithTime)}>{formatMoment(date, TimeFormat.ShortMonthDayYear)}</span>;
              },
            }]}
          />
        </Popover>
      )}
    </StyledStep>
  );
};

export default TrainingProgressStep;
