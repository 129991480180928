import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { find, isEmpty, startCase } from 'lodash';
import { useState } from 'react';

import Button from './Button';
import SelectInput from './SelectInput';
import StatusIndicator from '../utils/StatusIndicator';
import Tag from '../data-display/Tag';

import type { Dispatch, SetStateAction } from 'react';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from './SelectInput/types';

const options = ['sourcer', 'recruiter', 'hiring_manager', 'coordinator'].map((id) => ({
  label: startCase(id),
  value: id,
}));

interface Props {
  additionalAttendees?: string[];
  additionalOptionalAttendees?: string[];
  isDisabled?: boolean;
  setAdditionalAttendees: Dispatch<SetStateAction<string[]>>;
  setAdditionalOptionalAttendees: Dispatch<SetStateAction<string[]>>;
}

const CalendarEventTemplateAdvancedSettings = ({
  additionalAttendees,
  additionalOptionalAttendees,
  isDisabled = false,
  setAdditionalAttendees,
  setAdditionalOptionalAttendees,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const handleAdditionAttendeesChange = (options: OnChangeValue<Option<string>, true>) => {
    setAdditionalAttendees(options ? options.map(({ value }) => value) : []);
  };

  const handleAdditionOptionalAttendeesChange = (options: OnChangeValue<Option<string>, true>) => {
    setAdditionalOptionalAttendees(options ? options.map(({ value }) => value) : []);
  };

  const hasAdvancedSettings = !isEmpty(additionalAttendees) || !isEmpty(additionalOptionalAttendees);

  return (
    <div className="calendar-event-template-advanced-settings">
      <div className={`button-header status${isExpanded ? ' expanded' : ''}`}>
        <Button
          color="no-outline"
          iconLeft={<StatusIndicator color={hasAdvancedSettings ? 'green' : 'gray'} />}
          onClick={toggleIsExpanded}
          size="small"
          value={<span>Advanced settings<FontAwesomeIcon icon={isExpanded ? faAngleUp : faAngleDown} /></span>}
        />
      </div>
      {isExpanded &&
        <div className="expanded-content">
          <div className="advanced-settings">
            <SelectInput
              formatCreateLabel={(value) => `Use email address "${value}"`}
              formatOptionLabel={(option) => (
                find(options, ['value', option.value]) ?
                  <Tag type="hiring_team" value={option.value} /> :
                  option.label
              )}
              helperText="Since we create separate events for the candidate and interviewers, you can use this to add people to the candidate's calendar event."
              isClearable
              isCreatable
              isDisabled={isDisabled}
              isMulti
              label="Additional Attendees"
              noOptionsMessage={() => null}
              onChange={handleAdditionAttendeesChange}
              options={options}
              placeholder="Select hiring team members or type emails"
              value={additionalAttendees ? additionalAttendees.map((attendee) => find(options, ['value', attendee]) || { value: attendee, label: attendee }) : undefined}
            />
            <SelectInput
              formatCreateLabel={(value) => `Use email address "${value}"`}
              formatOptionLabel={(option) => (
                find(options, ['value', option.value]) ?
                  <Tag type="hiring_team" value={option.value} /> :
                  option.label
              )}
              helperText="Use this to add additional people that will be marked as optional."
              isClearable
              isCreatable
              isDisabled={isDisabled}
              isMulti
              label="Additional Optional Attendees"
              noOptionsMessage={() => null}
              onChange={handleAdditionOptionalAttendeesChange}
              options={options}
              placeholder="Select hiring team members or type emails"
              value={additionalOptionalAttendees ? additionalOptionalAttendees.map((attendee) => find(options, ['value', attendee]) || { value: attendee, label: attendee }) : undefined}
            />
          </div>
        </div>
      }
    </div>
  );
};

export default CalendarEventTemplateAdvancedSettings;
