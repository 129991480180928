import { uniqueId } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import type { ChangeEvent, ChangeEventHandler, ReactNode } from 'react';

interface Props {
  id?: string;
  isDisabled?: boolean;
  isSwitch?: boolean;
  isOn?: boolean;
  offLabel?: ReactNode;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onLabel?: ReactNode;
}

const ToggleInput = ({
  id,
  isDisabled = false,
  isSwitch = true,
  isOn,
  offLabel,
  onChange,
  onLabel,
}: Props) => {
  const [controlledValue, setControlledValue] = useState<boolean>(isOn || false);
  useEffect(() => {
    setControlledValue(isOn || false);
  }, [isOn]);

  id = useMemo(() => id || uniqueId('toggle-input-'), [id]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => setControlledValue(e.target.checked);

  return (
    <div className="input toggle-input">
      {offLabel &&
        <label
          className={`input-label label-off${controlledValue ? '' : ' selected'}`}
          htmlFor={id}
        >
          {offLabel}
        </label>
      }
      <label className="slider-container" htmlFor={id}>
        <input
          checked={isOn || controlledValue}
          disabled={isDisabled}
          id={id}
          onChange={onChange || handleChange}
          type="checkbox"
        />
        <span className={`slider${isSwitch ? ' switch' : ''}`} />
      </label>
      {onLabel &&
        <label
          className={`input-label label-on${controlledValue ? ' selected' : ''}`}
          htmlFor={id}
        >
          {onLabel}
        </label>
      }
    </div>
  );
};

export default ToggleInput;
