import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faBars, faBell /* faSearch */ } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive';

// import TextInput from '../library/inputs/TextInput';
import Breadcrumbs from './Breadcrumbs';
import Avatar from '../library/data-display/Avatar';
import Badge from '../library/utils/Badge';
import Button from '../library/inputs/Button';
import Tooltip from '../library/utils/Tooltip';
import { useLDFlags } from '../../hooks/use-ld-flags';
import { useSession } from '../../hooks/use-session';

import type { MouseEventHandler } from 'react';
import { correctPath } from 'libraries/gem';

interface Props {
  isMenuExpanded: boolean;
  toggleIsMenuExpanded: MouseEventHandler<HTMLButtonElement>;
}

const ActionBar = ({ isMenuExpanded, toggleIsMenuExpanded }: Props) => {
  const isMobile = useMediaQuery({ query: '(max-width: 750px)' });

  const { hideTaskBadge } = useLDFlags();

  const { currentUser } = useSession();

  return (
    <div className="action-bar">
      <div className="left-container">
        {!isMenuExpanded &&
          <div className="action-button">
            <Button
              color="gray"
              iconRight={<FontAwesomeIcon icon={faBars} />}
              onClick={toggleIsMenuExpanded}
              tooltip={!isMobile ?
                <Tooltip
                  id="action-bar-menu-toggle"
                  position="bottom"
                  value="Show menu"
                /> :
                undefined
              }
            />
          </div>
        }
        <Breadcrumbs />
        {/* TODO: Add search bar
          <TextInput
            leftIcon={<FontAwesomeIcon icon={faSearch} />}
            placeholder="Search for anything..."
          />
        */}
      </div>
      <div className="right-container">
        {!hideTaskBadge && (
          <Link className="action-button task-action-button" to={correctPath('/app/tasks')}>
            <Button
              color="gray"
              iconRight={<><FontAwesomeIcon icon={faBell} /><Badge value={<>&nbsp;</>} /></>}
              tooltip={!isMobile ?
                <Tooltip
                  id="action-bar-tasks"
                  position="bottom"
                  value="Tasks"
                /> :
                undefined
              }
            />
          </Link>
        )}
        <div className="action-button">
          {currentUser && /* TODO: Show a menu on click */
            <Avatar
              showUserTooltip={false}
              size="large"
              userId={currentUser.id}
            />
          }
        </div>
      </div>
    </div>
  );
};

export default ActionBar;
