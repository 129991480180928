import Moment from 'moment';
import { maxBy, minBy, orderBy, sum, uniqBy } from 'lodash';
import { useMemo } from 'react';

import Avatar from 'components/library/data-display/Avatar';
import Label from 'components/library/utils/Label';
import Table from 'components/library/data-display/Table';
import { formatDuration } from 'libraries/formatters';
import { useUsersMap } from 'hooks/queries/users';

import type { Dispatch, SetStateAction } from 'react';
import type { Interview } from 'types';
import type { InterviewerReportRowBase } from '../../types';
import type { TableSchema } from 'components/library/data-display/Table';
import { correctPath } from 'libraries/gem';

const pageSize = 100;

type UtilizationSummaryRow = InterviewerReportRowBase & {
  rank: number;
  totalInterviews: number;
  totalTimeMinutes: number;
  averageInterviewsPerWeek: number;
};

const columns: TableSchema<UtilizationSummaryRow> = [{
  header: '',
  displayValue: ({ rank }) => <span className="interviewers-table-rank">{rank}</span>,
}, {
  header: 'Interviewer',
  displayValue: ({ directory_archived, email, id, name, user_archived }) => (
    <div className="interviewers-table-interviewer">
      <Avatar
        showUserTooltip={false}
        size="small"
        userId={id}
      />
      {name || email}
      {(user_archived || directory_archived) && <Label color="gray">Archived</Label>}
    </div>
  ),
  hasLinkStyleOnHover: true,
}, {
  header: 'Total Interviews',
  displayValue: ({ totalInterviews }) => totalInterviews,
}, {
  header: 'Total Time',
  displayValue: ({ totalTimeMinutes }) => formatDuration(totalTimeMinutes),
}, {
  header: 'Average Utilization',
  displayValue: ({ averageInterviewsPerWeek }) => <span>{averageInterviewsPerWeek.toFixed(2)} interview{averageInterviewsPerWeek === 1 ? '' : 's'}/week</span>,
}, {
  header: 'Types of Interviews',
  displayValue: ({ interviews }) => uniqBy(interviews, 'stage_interview_id').length,
}];

interface Props {
  endDate?: string | Moment.Moment;
  interviews: Interview[];
  pageNumber: number;
  search: string;
  setPageNumber: Dispatch<SetStateAction<string>>;
  startDate?: string | Moment.Moment;
}

const UtilizationSummaryTable = ({ interviews, pageNumber, search, setPageNumber, startDate, endDate }: Props) => {
  const users = useUsersMap({ archived: true });

  const dateRange = useMemo(() => {
    let start = startDate;
    if (start) {
      start = (Moment.isMoment(start) ? start : Moment(start)).startOf('day');
    }

    let end = endDate;
    if (end) {
      end = (Moment.isMoment(end) ? end : Moment(end)).endOf('day');
    }

    return { start, end };
  }, [startDate, endDate]);

  const statsRange = useMemo(() => {
    if (interviews.length === 0) {
      return { start: Moment(), end: Moment() };
    }

    let start = dateRange.start;
    if (!start) {
      // If there's no start date passed in, start at the earliest interview.
      const earliestInterview = minBy(interviews, 'start_time');
      // minBy could return undefined, but only if interviews is empty, which we
      // check for above, so we should always have an interview.
      start = Moment(earliestInterview!.start_time);
    }

    let end = dateRange.end;
    if (!end) {
      // If there's no end date passed in, end at the latest interview.
      const latestInterview = maxBy(interviews, 'start_time');
      // maxBy could return undefined, but only if interviews is empty, which we
      // check for above, so we should always have an interview.
      end = Moment(latestInterview!.start_time);
    }

    return {
      start: Moment(start).startOf('isoWeek'),
      end: Moment(end).endOf('isoWeek'),
    };
  }, [interviews, dateRange]);

  const data = useMemo<UtilizationSummaryRow[]>(() => {
    const userInterviews: { [key: string]: InterviewerReportRowBase } = {};

    interviews.forEach((interview) => (interview.interviewers || []).forEach(({ user_id }) => {
      if (!userInterviews[user_id]) {
        userInterviews[user_id] = {
          id: user_id,
          name: users[user_id]?.name,
          email: users[user_id]?.email,
          interviews: [],
          directory_archived: users[user_id]?.directory_archived,
          user_archived: users[user_id]?.user_archived,
        };
      }
      userInterviews[user_id].interviews.push(interview);
    }));

    const numWeeks = statsRange.end.diff(statsRange.start, 'week') + 1;

    const interviewers = Object.values(userInterviews).map((user) => {
      const interviewsInDateRange = user.interviews.filter(({ start_time }) => {
        if (dateRange.start && dateRange.end) {
          return Moment(start_time).isBetween(dateRange.start, dateRange.end);
        }
        if (dateRange.start) {
          return Moment(start_time).isSameOrAfter(dateRange.start);
        }
        if (dateRange.end) {
          return Moment(start_time).isSameOrBefore(dateRange.end);
        }
        return true;
      });

      return {
        ...user,
        interviews: interviewsInDateRange,
        averageInterviewsPerWeek: user.interviews.length / numWeeks,
        totalTimeMinutes: sum(interviewsInDateRange.map(({ interview_template }) => interview_template.duration_minutes)),
        totalInterviews: interviewsInDateRange.length,
      };
    });

    return orderBy(interviewers, ['totalInterviews', 'totalTime'], ['desc', 'desc']).map<UtilizationSummaryRow>((interviewer, i) => ({
      ...interviewer,
      rank: i + 1,
    }));
  }, [interviews, users]);

  const filteredData = useMemo<UtilizationSummaryRow[]>(() => {
    const lowercaseSearch = search.toLowerCase();
    return data.filter(({ totalInterviews, email, name }) => totalInterviews > 0 && (!lowercaseSearch || email?.toLowerCase().includes(lowercaseSearch) || name?.toLowerCase().includes(lowercaseSearch)));
  }, [data, search]);

  const page = useMemo<UtilizationSummaryRow[]>(() => {
    const pageIndex = pageNumber - 1;
    const offset = pageSize * pageIndex;
    return filteredData.slice(offset, offset + pageSize);
  }, [filteredData, pageNumber]);

  return (
    <Table
      data={page}
      dataDescriptor="interviewers"
      getRowLink={({ id }) => correctPath(`/app/interviewers/${id}/interview-history`)}
      isPaginated
      layout="vertical"
      onPageNumberChange={(value) => setPageNumber(value.toString())}
      pageNumber={pageNumber}
      pageSize={pageSize}
      schema={columns}
      totalCount={filteredData.length}
    />
  );
};

export default UtilizationSummaryTable;
