import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom';

import Button from './Button';

import type { FormEvent, MouseEvent, ReactNode } from 'react';

interface Props {
  backButtonValue?: string;
  backLocation?: string; // Required if not first step.
  isFirstStep?: boolean;
  isLastStep?: boolean;
  children: ReactNode;
  className?: string;
  isSubmitting?: boolean;
  nextButtonIsDisabled?: boolean;
  nextButtonValue?: string;
  nextLocation?: string; // Required if not last step.
  onBack?: () => void;
  onNext?: () => Promise<boolean | void> | boolean | void;
  rightButtons?: ReactNode;
}

const MultiStepFormStep = ({
  backButtonValue = 'Back',
  backLocation,
  children,
  className,
  isFirstStep = false,
  isLastStep = false,
  isSubmitting,
  nextButtonIsDisabled = false,
  nextButtonValue,
  nextLocation,
  onBack,
  onNext,
  rightButtons,
}: Props) => {
  const history = useHistory();

  const classes = `multi-step-form-step${className ? ` ${className}` : ''}`;

  const handleBack = (e: MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    onBack?.();
    history.push(backLocation!);
  };

  const handleNext = async (e: FormEvent) => {
    e.preventDefault();
    let goToNextLocation = true;
    if (onNext) {
      // If onNext explicitly returns false, don't go to the next location.
      const shouldGoToNextLocation = await onNext();
      if (shouldGoToNextLocation === false) {
        goToNextLocation = false;
      }
    }
    nextLocation && goToNextLocation && history.push(nextLocation);
  };

  const buttons = {
    back: <Button
      color="no-outline"
      iconLeft={<FontAwesomeIcon icon={faAngleLeft} />}
      onClick={handleBack}
      size="large"
      value={backButtonValue}
    />,
    next: <Button
      color="gem-outline"
      iconRight={<FontAwesomeIcon icon={faAngleRight} />}
      isDisabled={nextButtonIsDisabled}
      size="large"
      type="submit"
      value={nextButtonValue}
    />,
    submit: <Button
      color="gem-blue"
      size="large"
      type="submit"
      value={nextButtonValue}
    />,
    submitting: <Button
      color="gem-blue"
      iconRight={<FontAwesomeIcon icon={faCircleNotch} spin />}
      isDisabled
      size="large"
      value="Submitting..."
    />,
  };

  if (!rightButtons) {
    rightButtons = (
      <>
        {!isLastStep && buttons.next}
        {isLastStep && !isSubmitting && buttons.submit}
        {isLastStep && isSubmitting && buttons.submitting}
      </>
    );
  }

  return (
    <form className={classes} onSubmit={handleNext}>
      <div className="multi-step-form-step-content">
        {children}
      </div>
      <div className="multi-step-form-step-actions">
        <div className="left-buttons">
          {!isFirstStep && buttons.back}
        </div>
        <div className="right-buttons">
          {rightButtons}
        </div>
      </div>
    </form>
  );
};

export default MultiStepFormStep;
