import { Breadcrumb } from 'react-breadcrumbs';
import { Redirect, Route, Switch, useLocation, useParams } from 'react-router-dom';
import { useState } from 'react';

import Section from 'components/library/layout/Section';
import StepProgressBar from 'components/library/navigation/StepProgressBar';
import Flash from 'components/library/utils/Flash';
import LoadingSpinner from 'components/library/utils/LoadingSpinner';

import { useApplication } from 'hooks/queries/applications';
import { useStage } from 'hooks/queries/stages';
import useQueryState from 'hooks/use-query-state';
import { useSession } from 'hooks/use-session';
import { ApplicationStatus, EmailTemplateType } from 'types';
import { initializeSchedulePayload } from './helpers';
import ScheduleStep from './ScheduleStep';
import { NewManualScheduleProvider, useNewManualSchedule } from './use-new-manual-schedule';
import EmailSettingsWarningModal from '../EmailSettingsWarningModal';

// To enable the inner component to access the context value, it needs to be nested
// under the provider, so we need this wrapper component to add that nesting.
const CandidateManualSchedule = () => {
  return (
    <NewManualScheduleProvider>
      <CandidateManualScheduleInner />
    </NewManualScheduleProvider>
  );
};

export default CandidateManualSchedule;

const CandidateManualScheduleInner = () => {
  const { id } = useParams<{ id: string }>();
  const { search } = useLocation();
  const [stageParam] = useQueryState<string>('stage', '');

  const { account, currentUser } = useSession();
  const canSendEmail = Boolean(account?.email_domain || currentUser?.gem_can_send_email);
  const [isEmailSettingsWarningModalOpen, setIsEmailSettingsWarningModalOpen] = useState(!canSendEmail);

  const {
    completedStep,
    setSchedule,
  } = useNewManualSchedule();

  const {
    data: application,
    error: applicationError,
    isLoading: isApplicationLoading,
  } = useApplication(id);

  const {
    data: stage,
    error: stageError,
    isLoading: isStageLoading,
  } = useStage(application?.job_id, stageParam || application?.current_stage_id, {
    onSuccess: (data) => {
      setSchedule(initializeSchedulePayload(application!, data));
    },
  });

  const isLoading = isApplicationLoading || isStageLoading;
  const isError = Boolean(applicationError || stageError);
  const canSchedule = application && application.status === ApplicationStatus.Active;

  if (isLoading && !isError) {
    return <LoadingSpinner />;
  }

  return (
    <Breadcrumb
      data={{
        title: 'Manual Schedule',
        pathname: `/app/candidates/${application?.id}/manual-schedule`,
      }}
    >
      <Flash
        message="You cannot schedule an inactive candidate."
        showFlash={!canSchedule}
        type="danger"
      />
      <Flash
        message={applicationError?.message}
        showFlash={Boolean(applicationError)}
        type="danger"
      />
      <Flash
        message={stageError?.message}
        showFlash={Boolean(stageError)}
        type="danger"
      />
      {canSchedule && application && stage && (
        <Section
          title={`Manually schedule ${application.candidate.name} for ${stage.name}`}
        >
          <EmailSettingsWarningModal
            isOpen={isEmailSettingsWarningModalOpen}
            onToggle={() => setIsEmailSettingsWarningModalOpen(false)}
            type={EmailTemplateType.ConfirmationEmail}
          />
          <StepProgressBar
            completedStep={completedStep}
            steps={[{
              label: 'Schedule',
              location: `/app/candidates/${application.id}/manual-schedule/schedule`,
            }, {
              label: 'Events & Emails',
              location: `/app/candidates/${application.id}/manual-schedule/events-and-emails`,
            }, {
              label: 'Review',
              location: `/app/candidates/${application.id}/manual-schedule/review`,
            }]}
          />
          <div>
            <Switch>
              <Redirect
                exact
                from="/app/candidates/:id/manual-schedule"
                to={{
                  pathname: '/app/candidates/:id/manual-schedule/schedule',
                  search,
                }}
              />
              <Route component={ScheduleStep} path="/app/candidates/:id/manual-schedule/schedule" />
              <Route component={() => <>events</>} path="/app/candidates/:id/manual-schedule/events-and-emails" />
              <Route component={() => <>review</>} path="/app/candidates/:id/manual-schedule/revview" />
            </Switch>
          </div>
        </Section>
      )}
    </Breadcrumb>
  );
};
