export enum Competitor {
  Greenhouse = 'greenhouse',
  GoodTime = 'goodtime',
  Lever = 'lever',
  ModernLoop = 'modernloop',
  Prelude = 'prelude',
}

export const competitorLabels: Record<Competitor, string> = {
  [Competitor.Greenhouse]: 'Greenhouse',
  [Competitor.GoodTime]: 'GoodTime',
  [Competitor.Lever]: 'Lever',
  [Competitor.ModernLoop]: 'ModernLoop',
  [Competitor.Prelude]: 'Prelude',
};

export const competitorLogos: Record<Competitor, string> = {
  [Competitor.Greenhouse]: '/scheduling/src/assets/images/greenhouse-logo.svg',
  [Competitor.GoodTime]: 'https://logo.clearbit.com/api/goodtime.io',
  [Competitor.Lever]: '/scheduling/src/assets/images/lever-logo.svg',
  [Competitor.ModernLoop]: 'https://logo.clearbit.com/api/modernloop.com',
  [Competitor.Prelude]: 'https://logo.clearbit.com/api/calendly.com',
};
