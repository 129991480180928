import { capitalize } from 'lodash';
import { useState } from 'react';

import Flash from './Flash';
import { InterviewerFilterableType } from '../../../types';
import { useSession } from '../../../hooks/use-session';
import { useUsersMap } from '../../../hooks/queries/users';

import type { CreateInterviewTemplatePayload } from '../../../hooks/queries/interview-templates';

enum PreFillType {
  Panel = 'panel',
  Pool = 'pool',
}

const INTERVIEWER_PRE_FILL_TYPE_DESCRIPTIONS: Record<PreFillType, string> = {
  [PreFillType.Panel]: 'separate panelists',
  [PreFillType.Pool]: 'alternates for a single interviewer slot',
};

const DEFAULT_PRE_FILL_TYPE = PreFillType.Panel;

interface Props {
  handleInterviewerTemplatesChange: (templates: CreateInterviewTemplatePayload['interviewer_templates']) => void;
  interviewerIds: string[];
  showFlash: boolean;
}

const PreFillInterviewerTemplatesFlash = ({ handleInterviewerTemplatesChange, interviewerIds, showFlash }: Props) => {
  const [preFillType, setPreFillType] = useState(DEFAULT_PRE_FILL_TYPE);

  const { account } = useSession();
  const users = useUsersMap({ archived: true });

  const activeInterviewerIds = interviewerIds.filter((id) => {
    const user = users[id];
    return user && !user.user_archived && !user.directory_archived;
  });

  const preFillInterviewerTemplates = (type: PreFillType) => {
    if (type === PreFillType.Pool) {
      handleInterviewerTemplatesChange([{
        optional: false,
        include_past_interviewers: true,
        interviewer_filters: (activeInterviewerIds || []).map((id) => ({
          interviewer_filter_expressions: [{
            negated: false,
            filterable_id: id,
            filterable_type: InterviewerFilterableType.User,
          }],
        })),
      }]);
    }
    if (type === PreFillType.Panel) {
      handleInterviewerTemplatesChange((activeInterviewerIds || []).map((id) => ({
        optional: false,
        include_past_interviewers: true,
        interviewer_filters: [{
          interviewer_filter_expressions: [{
            negated: false,
            filterable_id: id,
            filterable_type: InterviewerFilterableType.User,
          }],
        }],
      })));
    }
  };

  const togglePreFillType = () => {
    const newPreFillType = preFillType === PreFillType.Pool ? PreFillType.Panel : PreFillType.Pool;
    preFillInterviewerTemplates(newPreFillType);
    setPreFillType(newPreFillType);
  };

  return (
    <Flash
      message={(interviewerIds && interviewerIds.length === 1 ?
        <span>
          We have pre-filled the default interviewer from {capitalize(account?.ats_type)}.
        </span> :
        <span>
          We have pre-filled interviewers from {capitalize(account?.ats_type)} as {INTERVIEWER_PRE_FILL_TYPE_DESCRIPTIONS[preFillType]}.&nbsp;
          <a
            onClick={() => togglePreFillType()}
            role="button"
          >
            Click here if they should be {INTERVIEWER_PRE_FILL_TYPE_DESCRIPTIONS[preFillType === 'panel' ? 'pool' : 'panel']} instead.
          </a>
          &nbsp;Modify if needed.
        </span>

      )}
      showFlash={showFlash}
      type="info"
    />
  );
};

export default PreFillInterviewerTemplatesFlash;
