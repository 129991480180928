export enum ZoomHostType {
  Room = 'room',
  User = 'user',
}

export interface Meeting {
  id: string;
  host_id: string;
  topic: string;
  start_time: string;
  end_time: string;
  duration: number; // in minutes
  timezone: string;
  join_url: string;
  password?: string;
  buffer_time: boolean;
}
