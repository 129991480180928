import * as Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { extendMoment } from 'moment-range';
import { faClock } from '@fortawesome/free-solid-svg-icons';
import { find, uniqueId } from 'lodash';
import { forwardRef, useMemo } from 'react';

import LoadingSpinner from '../utils/LoadingSpinner';
import SelectInput from './SelectInput';
import TimezoneSelectInput from './TimezoneSelectInput';
import { formatMoment, TimeFormat } from '../../../libraries/time';
import { useLDFlags } from '../../../hooks/use-ld-flags';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Group, Option } from './SelectInput/types';
import type { ReactNode, Ref } from 'react';
import type { SelectInstance } from 'react-select';

const moment = extendMoment(Moment);

const isMinimumDuration = (start: string, end: string, minDuration: number): boolean => {
  const startTime = moment(start, 'HH:mm');
  const earliestEndTime = startTime.add(minDuration, 'minutes');
  const endTime = moment(end, 'HH:mm');
  return endTime.isSameOrAfter(earliestEndTime);
};

interface Props {
  areTimesClearable?: boolean;
  areTimesRequired?: boolean;
  className?: string;
  endPlaceholder?: string;
  endValue?: string;
  helperText?: ReactNode;
  id?: string;
  isDisabled?: boolean;
  isLoading?: boolean;
  isRequired?: boolean;
  isTimezoneClearable?: boolean;
  isTimezoneRequired?: boolean;
  label?: ReactNode;
  minDuration?: number;
  name?: string;
  onEndChange?: (newValue: OnChangeValue<Option<string>, false>, actionMeta: ActionMeta<Option<string>>) => void;
  onStartChange?: (newValue: OnChangeValue<Option<string>, false>, actionMeta: ActionMeta<Option<string>>) => void;
  onTimezoneChange?: (newValue: OnChangeValue<Option<string>, false>, actionMeta: ActionMeta<Option<string>>) => void;
  startPlaceholder?: string;
  startValue?: string;
  timezonePlaceholder?: string;
  timezoneValue?: string;
  withTimezone?: boolean;
}

const TimeRangeInputInner = ({
  areTimesClearable = true,
  areTimesRequired = false,
  className,
  endPlaceholder = 'End',
  endValue,
  helperText,
  id,
  isDisabled = false,
  isLoading = false,
  isRequired = false,
  isTimezoneClearable,
  isTimezoneRequired,
  label,
  minDuration = 0,
  name,
  onEndChange,
  onStartChange,
  onTimezoneChange,
  startPlaceholder = 'Start',
  startValue,
  timezonePlaceholder = 'Timezone',
  timezoneValue,
  withTimezone = false,
}: Props, ref: Ref<SelectInstance<Option<string>, false, Group<string, Option<string>>>>) => {
  id = useMemo(() => id || uniqueId('time-range-input-'), [id]);

  const { distinctSchedulingWindows } = useLDFlags();

  const timeOptions = useMemo<Option<string>[]>(() => Array.from(moment(moment().startOf('day')).range('day').by('minute', { step: distinctSchedulingWindows ? 5 : 15 })).map((m) => ({
    label: formatMoment(m, TimeFormat.Time),
    value: m.format('HH:mm'),
  })).filter((option) => option.value !== '00:00'), [distinctSchedulingWindows]);

  const clockIcon = useMemo(() => (isLoading ?
    <LoadingSpinner /> :
    <FontAwesomeIcon icon={faClock} />
  ), [isLoading]);

  return (
    <div className={`input time-range-input${className ? ` ${className}` : ''}`}>
      {label && <label htmlFor={id}>{label}</label>}
      <div className="time-input-container" id={id}>
        <SelectInput
          icon={clockIcon}
          id={`${id}-start`}
          isClearable={areTimesClearable}
          isDisabled={isDisabled}
          isRequired={areTimesRequired}
          name={`start${name ? `-${name}` : ''}`}
          onChange={onStartChange}
          options={timeOptions}
          placeholder={startPlaceholder}
          ref={ref}
          value={startValue ? find(timeOptions, ['value', startValue]) : null}
        />
        <span className="range-dash">&ndash;</span>
        <SelectInput
          icon={clockIcon}
          id={`${id}-end`}
          isClearable={areTimesClearable}
          isDisabled={isDisabled}
          isRequired={areTimesRequired}
          name={`end${name ? `-${name}` : ''}`}
          onChange={onEndChange}
          options={timeOptions.filter((option) => (startValue ?
            isMinimumDuration(startValue, option.value, minDuration) :
            true
          ))}
          placeholder={endPlaceholder}
          value={endValue ? find(timeOptions, ['value', endValue]) : null}
        />
        {withTimezone && onTimezoneChange &&
          <TimezoneSelectInput
            isClearable={isTimezoneClearable}
            isDisabled={isDisabled}
            isRequired={isTimezoneRequired !== undefined ? isTimezoneRequired : isRequired || Boolean(startValue || endValue)}
            onChange={onTimezoneChange}
            placeholder={timezonePlaceholder}
            value={timezoneValue}
          />
        }
      </div>
      {helperText && <div className="helper-text">{helperText}</div>}
    </div>
  );
};

const TimeRangeInput = forwardRef(TimeRangeInputInner);

export default TimeRangeInput;
