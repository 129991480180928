import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch, faComment } from '@fortawesome/free-solid-svg-icons';
import Moment from 'moment-timezone';
import { useEffect, useState } from 'react';

import Button from 'components/library/inputs/Button';
import Flash from 'components/library/utils/Flash';
import TextAreaInput from 'components/library/inputs/TextAreaInput';
import { useUpdateAssignment } from 'hooks/queries/assignments';
import { useUsersMap } from 'hooks/queries/users';
import { formatMoment, TimeFormat } from 'libraries/time';
import { StyledAssignmentBanner, StyledContainer, StyledNoteContainer } from './styles';

import type { Assignment } from 'types';
import type { ChangeEvent } from 'react';

interface Props {
  assignment: Assignment;
}

const CandidateAssignmentBanner = ({ assignment }: Props) => {
  const users = useUsersMap({ archived: true });

  const [isExpanded, setIsExpanded] = useState(false);
  const [note, setNote] = useState(assignment.note);

  useEffect(() => {
    setNote(assignment?.note);
  }, [assignment]);

  const updateAssignmentMutation = useUpdateAssignment();

  const handleNoteChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  };

  const saveNote = async () => {
    updateAssignmentMutation.reset();

    try {
      await updateAssignmentMutation.mutateAsync({
        id: assignment.id,
        payload: { note },
      });
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  return (
    <StyledAssignmentBanner>
      <StyledContainer>
        <span>
          {assignment.assignee_id === assignment.creator_id ? (
            <span>
              <span>Self-assigned by </span>
              <b>{users[assignment.assignee_id]?.name || 'team'}</b>
            </span>
          ) : (
            <span>
              <span>{users[assignment.creator_id]?.name || 'team'}</span>
              <span> assigned to </span>
              <b>{users[assignment.assignee_id]?.name || 'team'}</b>
            </span>
          )}
          <span> on {formatMoment(Moment(assignment.created_at).tz(Moment.tz.guess()), TimeFormat.ShortMonthDayYearWithTimeAndTimezone)}.</span>
        </span>
        {note === assignment.note ? (
          <Button
            color="no-outline"
            iconLeft={<FontAwesomeIcon icon={faComment} />}
            onClick={() => setIsExpanded(!isExpanded)}
            value={isExpanded ? 'Collapse note' : (assignment.note ? 'View note' : 'Add a note')}
          />
        ) : (
          <div>
            <Button
              color="no-outline"
              onClick={() => {
                setNote(assignment.note);
                setIsExpanded(false);
              }}
              value="Cancel"
            />
            <Button
              color="gem-blue"
              iconRight={updateAssignmentMutation.isLoading ? <FontAwesomeIcon icon={faCircleNotch} spin /> : undefined}
              isDisabled={updateAssignmentMutation.isLoading}
              onClick={() => saveNote()}
              value={updateAssignmentMutation.isLoading ? 'Saving...' : 'Save note'}
            />
          </div>
        )}
      </StyledContainer>
      {isExpanded && (
        <StyledNoteContainer>
          <Flash
            message={updateAssignmentMutation.error?.message}
            showFlash={Boolean(updateAssignmentMutation.error)}
            type="danger"
          />
          <TextAreaInput
            helperText="Additional information for coordinating these interviews, e.g. preferred interviewers or unique scheduling requirements."
            isDisabled={updateAssignmentMutation.isLoading}
            isRequired
            maxLength={500}
            onChange={handleNoteChange}
            value={note}
          />
        </StyledNoteContainer>
      )}
    </StyledAssignmentBanner>
  );
};

export default CandidateAssignmentBanner;
