import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faClipboard } from '@fortawesome/free-solid-svg-icons';
import { uniqueId } from 'lodash';
import { useMemo } from 'react';

import Button from './Button';
import Tooltip from '../utils/Tooltip';

interface Props {
  textToCopy: string;
}

const CopyToClipboardButton = ({ textToCopy }: Props) => {
  const id = useMemo(() => uniqueId('copy-to-clipboard-'), []);

  return (
    <Button
      className="btn-main-action"
      color="gem-outline"
      iconRight={<FontAwesomeIcon icon={faClipboard} />}
      onClick={() => navigator.clipboard.writeText(textToCopy)}
      size="small"
      tooltip={(
        <Tooltip
          event="mousedown"
          eventOff="mouseleave"
          id={id}
          position="top"
          value="Copied!"
        />
      )}
      value="Copy"
    />
  );
};

export default CopyToClipboardButton;
