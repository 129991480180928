import { Text } from 'slate';

import { isToken } from '../../components/library/inputs/EditorInput/plugins/tokens';

import type { CustomElement, FormattedBase, Token } from '../../types';
import type { Descendant } from 'slate';

// The value that's passed in is an array of children.
export function slateValueToHtml (value: Descendant[]): string {
  // If there's no children yet, just return the empty string.
  if (!value) {
    return '';
  }

  return value.map((node) => toHtml(node)).join('');
}

function toHtml (node: Descendant): string {
  if (Text.isText(node)) {
    // Leaf conversion.
    return handleRichText(node, encodeHtml(node.text));
  }

  const children = node.children.map((node) => toHtml(node)).join('');

  // Element conversion.
  switch (node.type) {
    case 'image':
      return `<img alt="${encodeHtml(node.alt)}" height="${node.height}" src="${encodeHtml(node.src)}" width="${node.width}">`;
    case 'link':
      return `<a href="${encodeHtml(node.url)}" target="_blank">${children}</a>`;
    case 'list_item':
      return `<li>${children}</li>`;
    case 'ordered_list':
      return `<ol>${children}</ol>`;
    case 'paragraph':
      if (children?.trim() === '') {
        return '<br>';
      }
      return `<span>${children}</span><br>`;
    case 'token':
      return handleRichText(node, `{{ ${node.token} }}`);
    case 'unordered_list':
      return `<ul>${children}</ul>`;
    default:
      return children;
  }
}

// This isn't full encoding. This is just the bare minimum.
function encodeHtml (text: string | undefined | null): string {
  return (text || '')
  .replace(/&/g, '&amp;')
  .replace(/</g, '&lt;')
  .replace(/>/g, '&gt;');
}

function handleRichText (node: FormattedBase, string: string): string {
  if (node.bold) {
    string = `<b>${string}</b>`;
  }
  if (node.italic) {
    string = `<i>${string}</i>`;
  }
  if (node.underline) {
    string = `<u>${string}</u>`;
  }
  return string;
}

export function getTokensFromSlateValue (value: Descendant[]): Token[] {
  const nodes = nodesFromSlateValue(value, isToken);

  const tokens = [];
  for (const node of nodes) {
    tokens.push(node.token);
  }

  return tokens;
}

function *nodesFromSlateValue<T extends Descendant> (value: Descendant[] | null, match: (node: Descendant) => node is T): Generator<T, void, void> {
  if (!value) {
    return;
  }

  for (const node of value) {
    if (match(node)) {
      yield node;
    }
    const element = node as CustomElement | null;
    yield *nodesFromSlateValue(element?.children || null, match);
  }
}
