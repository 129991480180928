import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

import { constructDirectoryCalendarHref } from './helpers';
import { directoryCalendarLabels } from 'types';
import DirectoryCalendarEditWarningModal from './DirectoryCalendarEditWarningModal';
import DirectoryCalendarIconTooltip from './DirectoryCalendarIconTooltip';
import OutboundLink from 'components/library/navigation/OutboundLink';
import { StyledButton } from './styles';
import { useSession } from 'hooks/use-session';
import useUserPreference, { UserPreference } from 'hooks/use-user-preference';

interface Props {
  directoryCalendarId?: string;
  directoryEventId?: string;
}

const OpenInDirectoryCalendarButton = ({ directoryCalendarId, directoryEventId }: Props) => {
  const { account } = useSession();

  const [showEditWarning, setShowEditWarning] = useUserPreference(UserPreference.ShowDirectoryCalendarEditWarning);
  const [isEditWarningModalOpen, setIsEditWarningModalOpen] = useState<boolean>(false);

  if (!account?.directory_type) {
    return null;
  }

  const eventUrl = constructDirectoryCalendarHref(account.directory_type, directoryCalendarId, directoryEventId);

  if (!eventUrl) {
    return null;
  }

  // When clicking opens the warning modal, the target element should be a button.
  if (showEditWarning) {
    return (
      <>
        <StyledButton
          color="gem-outline"
          iconRight={<FontAwesomeIcon icon={faExternalLinkAlt} />}
          onClick={() => setIsEditWarningModalOpen(true)}
          size="small"
          tooltip={<DirectoryCalendarIconTooltip id={directoryEventId!} />}
        />
        <DirectoryCalendarEditWarningModal
          eventUrl={eventUrl}
          isOpen={isEditWarningModalOpen}
          onToggle={(e, shouldHideInFuture) => {
            if (shouldHideInFuture) {
              setShowEditWarning(false);
            }
            setIsEditWarningModalOpen(!setIsEditWarningModalOpen);
          }}
        />
      </>
    );
  }

  // When clicking navigates to the calendar event url, the target element should be a link.
  return (
    <OutboundLink
      externalLinkIcon={false}
      href={eventUrl}
      label={`Open in ${directoryCalendarLabels[account.directory_type]}`}
    >
      <StyledButton
        color="gem-outline"
        iconRight={<FontAwesomeIcon icon={faExternalLinkAlt} />}
        role="none"
        size="small"
        tooltip={<DirectoryCalendarIconTooltip id={directoryEventId!} />}
      />
    </OutboundLink>
  );
};

export default OpenInDirectoryCalendarButton;
