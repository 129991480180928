import Moment from 'moment';
import { useMemo } from 'react';
import { useQuery } from 'react-query';

import InterviewPlanner from '../../libraries/interviewplanner';
import { ScheduleStatus } from '../../types';

import type { HiringTeamRoleWithInterviewer, Interview } from '../../types';
import type { UseQueryOptions } from 'react-query';

export enum QueryKey {
  RetrieveInterview = 'RetrieveInterview',
  ListInterviews = 'ListInterviews',
}

export const useInterview = (id?: string, options: UseQueryOptions<Interview, Error> = {}) => {
  return useQuery<Interview, Error>([QueryKey.RetrieveInterview, id], () => {
    return InterviewPlanner.request('GET', `/interviews/${id}`);
  }, {
    ...options,
    enabled: options.enabled !== undefined ? options.enabled : Boolean(id),
  });
};

interface ListInterviewsQuery {
  limit?: number;
  offset?: number;
  schedule_status?: ScheduleStatus[];
  start_time_start?: string;
  start_time_end?: string;
  including_user?: string;
  including_role?: HiringTeamRoleWithInterviewer[];
  jobs?: string[];
  stage_interviews?: string[];
  training_programs?: string[];
  sort_by?: string[];
}

interface ListInterviewsData {
  interviews: Interview[];
  total: number;
}

export const useInterviews = <TData = ListInterviewsData>(query: ListInterviewsQuery = {}, options: UseQueryOptions<ListInterviewsData, Error, TData> = {}) => {
  // This query can't be canceled because the promise being returned from this
  // function (which is a result of the async keyword) doesn't have a cancel
  // function on it. If we want it to be cancellable, we can't use async/await,
  // and we need to make sure the cancel function from the promise returned from
  // InterviewPlanner.request is preserved.
  return useQuery<ListInterviewsData, Error, TData>([QueryKey.ListInterviews, query], async () => {
    const pageSize = 100;
    const paginatedQuery = {
      limit: pageSize,
      offset: 0,
      ...query,
    };

    const interviews: Interview[] = [];
    let total = 0;
    let totalLimit: number | undefined;

    while (totalLimit === undefined || interviews.length < totalLimit) {
      const response = await InterviewPlanner.request<ListInterviewsData>('GET', '/interviews', null, paginatedQuery);
      total = response.total;
      if (response.interviews.length === 0) {
        break;
      }

      interviews.push(...response.interviews);
      totalLimit = query.limit! < total ? query.limit : total;
      paginatedQuery.offset = paginatedQuery.offset + paginatedQuery.limit;
    }

    return { interviews, total };
  }, {
    refetchOnMount: true,
    staleTime: 5000,
    ...options,
  });
};

interface WeeklyInterviewLoadData {
  [userId: string]: Interview[];
}

export const useWeeklyInterviewLoad = (date: string) => {
  const startOfWeek = useMemo(() => Moment(date).startOf('isoWeek').format(), [date]);
  const endOfWeek = useMemo(() => Moment(date).endOf('isoWeek').format(), [date]);

  const { data: interviews } = useInterviews({
    schedule_status: [ScheduleStatus.Confirmed],
    start_time_start: startOfWeek,
    start_time_end: endOfWeek,
  });

  return useMemo(() => {
    const userInterviews: WeeklyInterviewLoadData = {};
    interviews?.interviews.forEach((interview) => {
      interview.interviewers?.forEach(({ user_id }) => {
        if (!userInterviews[user_id]) {
          userInterviews[user_id] = [];
        }
        userInterviews[user_id].push(interview);
      });
    });
    return userInterviews;
  }, [interviews]);
};
