import styled from 'styled-components';

import Modal from 'components/library/layout/Modal';

export const StyledModal = styled(Modal)`
  max-height: 90vh;

  @media only screen and (max-width: 700px) {
    width: 95vw !important;
  }
`;
