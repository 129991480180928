import { Link, useParams } from 'react-router-dom';
import { pick } from 'lodash';
import { useEffect, useState } from 'react';

import EmailPreview from '../../../../../library/data-display/EmailPreview';
import EmailTemplateForm from '../../../../../library/inputs/EmailTemplateForm';
import EmailTemplateSummary from '../../../../../library/data-display/EmailTemplateSummary';
import ErrorTokenFlash from '../../../../../library/utils/ErrorTokenFlash';
import Flash from '../../../../../library/utils/Flash';
import Section from '../../../../../library/layout/Section';
import { constructScheduleTokens } from './helpers';
import { useApplication } from '../../../../../../hooks/queries/applications';
import { useTokens } from '../../../../../../hooks/queries/tokens';
import { useUpdateSchedule } from '../../../../../../hooks/queries/schedules';
import { useUpdateSelfSchedulingLink } from '../../../../../../hooks/queries/self-scheduling-links';
import { useRoomsMap } from '../../../../../../hooks/queries/rooms';
import { useUsersMap } from '../../../../../../hooks/queries/users';

import type { CreatableEmailTemplate, Token } from '../../../../../../types';
import type { ScheduleWithType } from './types';
import { correctPath } from 'libraries/gem';

interface Props {
  isEditable: boolean;
  resource: ScheduleWithType;
}

const CandidateConfirmationEmailSection = ({ isEditable, resource }: Props) => {
  const { id, scheduleId } = useParams<{ id: string; scheduleId: string }>();

  const { data: application } = useApplication(id);
  const rooms = useRoomsMap();
  const users = useUsersMap({ archived: true });

  const isMultiBlock = Boolean(resource.type === 'schedule' && resource.block_id);
  const emailType = isMultiBlock ? 'multi_block_confirmation_email' : 'confirmation_email';
  const originalEmailTemplate = isMultiBlock ? resource.schedule_template.multi_block_confirmation_email_template : resource.schedule_template.confirmation_email_template;

  const {
    data: tokens,
    error: tokensError,
  } = useTokens({
    type: emailType,
    schedule: constructScheduleTokens(application!, resource, rooms, users),
  }, {
    enabled: isEditable || resource.type === 'self_scheduling_link',
  });

  const updateScheduleMutation = useUpdateSchedule();
  const updateSelfSchedulingLinkMutation = useUpdateSelfSchedulingLink();
  const mutation = resource.type === 'schedule' ? updateScheduleMutation : updateSelfSchedulingLinkMutation;

  const [isEditing, setIsEditing] = useState(false);
  const [isEnabled, setIsEnabled] = useState(Boolean(originalEmailTemplate));
  const [emailTemplate, setEmailTemplate] = useState(originalEmailTemplate as CreatableEmailTemplate | undefined);
  const [errorTokens, setErrorTokens] = useState<Token[]>([]);

  const email = originalEmailTemplate?.emails?.[0];

  useEffect(() => {
    setIsEnabled(Boolean(originalEmailTemplate));
    setEmailTemplate(originalEmailTemplate as CreatableEmailTemplate | undefined);
    setIsEditing(false);
  }, [scheduleId]);

  useEffect(() => {
    setIsEnabled(Boolean(originalEmailTemplate));
    setEmailTemplate(originalEmailTemplate as CreatableEmailTemplate | undefined);
  }, [originalEmailTemplate]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEnabled(Boolean(originalEmailTemplate));
    setEmailTemplate(originalEmailTemplate as CreatableEmailTemplate | undefined);
    setIsEditing(false);
  };

  const handleSave = async () => {
    mutation.reset();

    const payload = {
      schedule_template: {
        [`${emailType}_template`]: {
          enabled: isEnabled,
          ...pick(emailTemplate, [
            'id',
            'subject',
            'sender_name',
            'sender_email',
            'cc_emails',
            'bcc_emails',
            'body',
            'attachments',
          ]),
        },
      },
    };

    try {
      await mutation.mutateAsync({ id: scheduleId, payload });
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  return (
    <Section
      className="candidate-confirmation-email-section"
      isEditable={isEditable}
      isEditing={isEditing}
      isSaving={mutation.isLoading}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Candidate confirmation email"
    >
      <Flash
        isDismissible
        message={
          <>
            Successfully updated! {resource.type === 'schedule' && (
              <>
                {isEnabled ?
                  'This email still has not been sent to the candidate. To send it to them, ' :
                  'No email will be sent to the candidate when you '
                }
                <Link to={correctPath(`/app/candidates/${id}/schedule/review?schedule=${scheduleId}`)}>
                  confirm this schedule
                </Link>.
              </>
            )}
          </>
        }
        showFlash={mutation.isSuccess}
        type="success"
      />
      <Flash
        message={tokensError?.message}
        showFlash={Boolean(tokensError)}
        type="danger"
      />
      <Flash
        message={mutation.error?.message}
        showFlash={mutation.isError}
        type="danger"
      />
      <Flash
        message="This email failed to send. Please confirm the candidate's email address and re-send a confirmation outside InterviewPlanner."
        showFlash={Boolean(email && email.status === 'failed')}
        type="danger"
      />
      <Flash
        message="Changes to this confirmation email will apply to all blocks in this multi-block schedule."
        showFlash={isEditing && isMultiBlock}
        type="warning"
      />
      <ErrorTokenFlash errorTokens={errorTokens} />
      {/*TODO: this doesn't work anymore with held schedules (since the email's created_at is when the schedule was held*/}
      {/*<Flash*/}
      {/*  message="This email was never sent. Please contact support."*/}
      {/*  showFlash={Boolean(email) && !Boolean(email.sent_at) && !schedule.hold && email && Moment(email.created_at).isBefore(Moment().subtract(1, 'hours'))}*/}
      {/*  type="warning"*/}
      {/*/>*/}
      {isEditing ? (
        <EmailTemplateForm
          emailTemplate={emailTemplate}
          enabledCheckboxLabel="Send confirmation email to candidate."
          isEnabled={isEnabled}
          pendingPreviewMessage="This token will be filled in when the email is sent to the candidate."
          setEmailTemplate={setEmailTemplate}
          setErrorTokens={setErrorTokens}
          setIsEnabled={setIsEnabled}
          tokens={tokens}
          type={emailType}
        />
      ) : (
        resource.type === 'schedule' ? (
          email ? (
            <EmailPreview
              attachments={originalEmailTemplate.attachments}
              bccEmails={email.bcc_emails}
              body={email.body}
              ccEmails={email.cc_emails}
              senderEmail={email.sender_email}
              senderName={email.sender_name}
              sentAt={email.sent_at}
              subject={email.subject}
              to={email.recipient_email}
            />
          ) : (
            <span className="no-email">A confirmation email {isEditable ? 'will not be' : 'was not'} sent through InterviewPlanner.</span>
          )
        ) : (
          originalEmailTemplate ? (
            tokens && (
              <EmailTemplateSummary
                emailTemplate={originalEmailTemplate}
                jobId={application?.job.id}
                pendingPreviewMessage="This token will be filled in when the candidate selects a time for the interview."
                showFlash={false}
                tokens={tokens}
              />
            )
          ) : (
            <span className="no-email">A confirmation email {isEditable ? 'will not be' : 'was not'} sent through InterviewPlanner.</span>
          )
        )
      )}
    </Section>
  );
};

export default CandidateConfirmationEmailSection;
