import styled from 'styled-components';

import CheckboxInput from '../../library/inputs/CheckboxInput';
import DurationInput from '../../library/inputs/DurationInput';
import EditorInput from '../../library/inputs/EditorInput';
import ErrorTokenFlash from '../../library/utils/ErrorTokenFlash';
import Flash from '../../library/utils/Flash';
import HiringMeetingAttendeeSelectInput from '../../library/inputs/HiringMeetingAttendeeSelectInput';
import RoomSelectInput from '../../library/inputs/RoomSelectInput';
import TextInput from '../../library/inputs/TextInput';
import TokenInput from '../../library/inputs/TokenInput';
import ZoomHostFiltersBuilder from '../../library/inputs/ZoomHostFiltersBuilder';

export const StyledFlash = styled(Flash)`
  margin-bottom: 1em;
`;

export const StyledErrorTokenFlash = styled(ErrorTokenFlash)`
  margin-bottom: 1em;
`;

export const StyledFormContainer = styled.div`
  display: flex;
  width: 100%;
  gap: 1em;
`;

export const StyledTextInput = styled(TextInput)`
  flex: 1;
  margin-bottom: 1em;
`;

export const StyledDurationInput = styled(DurationInput)`
  margin-bottom: 1em;
`;

export const StyledCheckboxInput = styled(CheckboxInput)`
  margin-bottom: 1em;
  margin-top: 1.5em;
`;

export const StyledZoomHostFiltersBuilder = styled(ZoomHostFiltersBuilder)`
  border-left: 1px solid ${({ theme }) => theme.colors.mercuryGray || theme.colors.PRODUCT.SECONDARY_BORDER};
  margin-bottom: 2em;
  margin-left: 2.5em;
  padding-left: 1em;
  margin-top: 0;
`;

export const StyledRoomSelectInput = styled(RoomSelectInput)`
  margin-bottom: 1em;
`;

export const StyledHiringMeetingAttendeeSelectInput = styled(HiringMeetingAttendeeSelectInput)`
  margin-bottom: 1em;
`;

export const StyledTokenInput = styled(TokenInput)`
  flex: 1;
  margin-bottom: 1em;
`;

export const StyledEditorInput = styled(EditorInput)`
  margin-bottom: 1em;
`;
