import { Breadcrumb } from 'react-breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { find, keyBy, partition } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import Button from '../../../library/inputs/Button';
import Flash from '../../../library/utils/Flash';
import OutboundLink from '../../../library/navigation/OutboundLink';
import Tag from '../../../library/data-display/Tag';
import TextInput from '../../../library/inputs/TextInput';
import Tooltip from '../../../library/utils/Tooltip';
import pluralize from '../../../../libraries/pluralize';
import { StyledAddPhaseButton, StyledNumberOfInterviewsTextInput, StyledTable } from '../styles';
import { StyledEligibilityOptionLabel, StyledEligibilitySelectInput, StyledSection, StyledSubmitButtonContainer } from './styles';
import { useCreateTrainingProgram } from '../../../../hooks/queries/training-programs';
import { useEligibilities } from '../../../../hooks/queries/users';

import type { ActionMeta } from 'react-select';
import type { EditableTrainingPhase } from '../../../../types';
import type { FormEvent } from 'react';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option as BaseOption } from '../../../library/inputs/SelectInput/types';
import type { TableSchema } from '../../../library/data-display/Table';
import { correctPath } from 'libraries/gem';

const eligibilitySupportArticleUrl = 'https://support.gem.com/hc/en-us/articles/23491953144727-How-do-I-manage-my-interviewer-spreadsheet#eligibilities';
const trainingProgramSupportArticleUrl = 'https://support.gem.com/hc/en-us/articles/23491859669271-What-is-a-training-program#1-creating-a-training-program';

type Option = BaseOption<string> & {
  hasTrainingProgram: boolean;
  tooltip?: JSX.Element;
  userCount: number;
  traineeCount?: number;
};

const TrainingProgramCreate = () => {
  const history = useHistory();

  const { data: eligibilitiesFromServer } = useEligibilities({ include_counts: true });

  const [phases, setPhases] = useState<EditableTrainingPhase[]>(() => [{}]);
  const [eligibility, setEligibility] = useState('');
  const [eligibilities, setEligibilities] = useState(eligibilitiesFromServer || []);

  const createTrainingProgramMutation = useCreateTrainingProgram();

  useEffect(() => {
    setEligibilities(eligibilitiesFromServer || []);
  }, [eligibilitiesFromServer]);

  const eligibilityOptions = useMemo<Option[]>(() => {
    const [traineeEligibilities, regularEligibilities] = partition(eligibilities, 'trainee');
    const traineeLookup = keyBy(traineeEligibilities, 'id');

    return regularEligibilities.filter(({ trainee }) => !trainee).map((eligibility) => ({
      value: eligibility.id,
      hasTrainingProgram: Boolean(eligibility.training_program_id),
      isDisabled: Boolean(eligibility.training_program_id),
      userCount: eligibility.user_count || 0,
      traineeCount: eligibility.training_program_id ? traineeLookup[eligibility.id]?.user_count || 0 : undefined,
      tooltip: Boolean(eligibility.training_program_id) ? (
        <Tooltip
          id={eligibility.id}
          position="top"
          value="This eligibility is already used in a training program."
        />
      ) : undefined,
    }));
  }, [eligibilities]);

  const phasesSchema = useMemo<TableSchema<EditableTrainingPhase>>(() => [{
    header: '',
    getCellClassName: () => 'training-phase-table-index',
    displayEditValue: (_, i) => i + 1,
  }, {
    header: 'Phase Name',
    displayEditValue: ({ name }, i) => (
      <TextInput
        isRequired
        onChange={(e) => setPhases((prev) => prev.map((phase, index) => (
          i === index ?
            { ...phase, name: e.target.value } :
            phase
        )))}
        value={name}
      />
    ),
  }, {
    header: 'Number of Interviews',
    getCellClassName: () => 'training-phase-table-number-of-interviews',
    displayEditValue: ({ number_of_interviews }, i) => (
      <StyledNumberOfInterviewsTextInput
        isRequired
        numberMax={100}
        numberMin={0}
        onChange={(e) => setPhases((prev) => prev.map((phase, index) => (
          i === index ?
            { ...phase, number_of_interviews: e.target.value ? parseInt(e.target.value, 10) : undefined } :
            phase
        )))}
        type="number"
        value={number_of_interviews}
      />
    ),
  }, {
    header: '',
    getCellClassName: () => 'training-phase-table-actions',
    isClickable: true,
    displayEditValue: (_, i) => (
      <Button
        className="btn-delete"
        color="gray"
        iconRight={<FontAwesomeIcon icon={faTrashAlt} />}
        onClick={() => setPhases((prev) => prev.filter((_, index) => i !== index))}
        size="large"
        tooltip={
          <Tooltip
            id={`${i}-delete-button`}
            position="top"
            value="Remove phase"
          />
        }
      />
    ),
  }], []);

  const handleEligibilityChange = useCallback((option: OnChangeValue<Option, false>, actionMeta: ActionMeta<Option>) => {
    if (actionMeta.action === 'create-option') {
      // Update our list of eligibilities to include this new one.
      setEligibilities((prev) => [
        ...prev,
        {
          id: option!.value,
          trainee: false,
          created_at: '',
        },
      ]);
    }
    setEligibility(option!.value);
  }, []);

  const handleSubmit = useCallback(async (e: FormEvent) => {
    e.preventDefault();
    createTrainingProgramMutation.reset();

    try {
      const data = await createTrainingProgramMutation.mutateAsync({
        payload: {
          eligibility,
          training_phases: phases.map((phase) => ({
            // These are required inputs, so they shouldn't be undefined.
            name: phase.name!,
            number_of_interviews: phase.number_of_interviews!,
            approver: phase.approver,
          })),
        },
      });
      history.push(correctPath(`/app/training-programs/${data.id}`));
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  }, [createTrainingProgramMutation, history]);

  return (
    <Breadcrumb
      data={{
        title: 'Create',
        pathname: correctPath('/app/training-programs/create'),
      }}
    >
      <StyledSection title="Create a new training program">
        <Flash
          message={createTrainingProgramMutation.error?.message}
          showFlash={createTrainingProgramMutation.isError}
          type="danger"
        />
        <Flash
          message={<>A training program consists of phases that require a number of interviews to complete. Upon graduation, interviewers receive an interview eligibility. <OutboundLink href={trainingProgramSupportArticleUrl} label="Create Training Program - Training Program Support Article">Learn more.</OutboundLink></>}
          showFlash
          type="info"
        />
        <form onSubmit={handleSubmit}>
          <StyledEligibilitySelectInput
            formatCreateLabel={(value) => `New interview eligibility "${value}"`}
            formatOptionLabel={(option) => {
              // The type for formatOptionLabel isn't correct. Fix this once
              // https://github.com/JedWatson/react-select/issues/5064 is addressed.
              const { __isNew__ } = option as any;
              return (
                __isNew__ ?
                  option.label :
                  <StyledEligibilityOptionLabel
                    data-for={option.tooltip ? option.tooltip.props.id : undefined}
                    data-tip={option.tooltip ? true : undefined}
                  >
                    <Tag
                      hasTrainingProgram={option.hasTrainingProgram}
                      type="eligibility"
                      value={option.value}
                    />
                    {option.tooltip}
                    <span>
                      {option.hasTrainingProgram ? (
                        `${option.traineeCount} ${pluralize('trainee', option.traineeCount)}, ${option.userCount} ${pluralize('graduate', option.userCount)}`
                      ) : (
                        `${option.userCount} ${pluralize('interviewer', option.userCount)}`
                      )}
                    </span>
                  </StyledEligibilityOptionLabel>
              );
            }}
            helperText={<>You can create a new eligibility for this program or attach an existing one. <OutboundLink href={eligibilitySupportArticleUrl} label="Create Training Program - Eligibility Support Article">What is an interview eligibility?</OutboundLink></>}
            isAutofocus
            isCreatable
            isRequired
            label="Eligibility Received Upon Graduation"
            onChange={handleEligibilityChange}
            options={eligibilityOptions}
            value={eligibility ? find(eligibilityOptions, ['value', eligibility]) : undefined}
          />
          <StyledTable
            data={phases}
            isEditing
            layout="vertical"
            schema={phasesSchema}
          />
          <StyledAddPhaseButton
            color="no-outline"
            iconLeft={<FontAwesomeIcon icon={faPlus} />}
            onClick={() => setPhases((prev) => [...prev, {}])}
            size="small"
            value="Add Training Phase"
          />
          <StyledSubmitButtonContainer>
            <Button
              color="gem-blue"
              size="large"
              type="submit"
              value="Create Training Program"
            />
          </StyledSubmitButtonContainer>
        </form>
      </StyledSection>
    </Breadcrumb>
  );
};

export default TrainingProgramCreate;
