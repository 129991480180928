import { useEffect, useState } from 'react';

import CheckboxInput from 'components/library/inputs/CheckboxInput';
import Flash from 'components/library/utils/Flash';
import { StyledSection } from './styles';
import { useAccount, useUpdateAccount } from 'hooks/queries/accounts';

import type { ChangeEvent } from 'react';

const CompanyInclusionSection = () => {
  const account = useAccount().data!;

  const [isEditing, setIsEditing] = useState(false);

  const [enablePronouns, setEnablePronouns] = useState(account.enable_pronouns);
  const [enablePronunciation, setEnablePronunciation] = useState(account.enable_pronunciation);

  const updateAccountMutation = useUpdateAccount();

  useEffect(() => {
    setEnablePronouns(account.enable_pronouns);
  }, [account.enable_pronouns]);

  useEffect(() => {
    setEnablePronunciation(account.enable_pronunciation);
  }, [account.enable_pronunciation]);

  const handleEnablePronounsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEnablePronouns(e.target.checked);
  };

  const handleEnablePronunciationChange = (e: ChangeEvent<HTMLInputElement>) => {
    setEnablePronunciation(e.target.checked);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setEnablePronouns(account.enable_pronouns);
    setEnablePronunciation(account.enable_pronunciation);
    setIsEditing(false);
    updateAccountMutation.reset();
  };

  const handleSave = async () => {
    updateAccountMutation.reset();

    try {
      await updateAccountMutation.mutateAsync({
        id: account.id,
        payload: {
          enable_pronouns: enablePronouns,
          enable_pronunciation: enablePronunciation,
        },
      });
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  return (
    <StyledSection
      isEditable
      isEditing={isEditing}
      isSaving={updateAccountMutation.isLoading}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Inclusion"
    >
      <Flash
        message={updateAccountMutation.error?.message}
        showFlash={updateAccountMutation.isError}
        type="danger"
      />
      <Flash
        isDismissible
        message="Successfully updated!"
        showFlash={updateAccountMutation.isSuccess}
        type="success"
      />
      <CheckboxInput
        isChecked={enablePronouns}
        isDisabled={!isEditing || updateAccountMutation.isLoading}
        label={<>Ask candidates to provide their pronouns when requesting availability.</>}
        onChange={handleEnablePronounsChange}
      />
      <CheckboxInput
        isChecked={enablePronunciation}
        isDisabled={!isEditing || updateAccountMutation.isLoading}
        label={<>Ask candidates to record their name pronunciation when requesting availability.</>}
        onChange={handleEnablePronunciationChange}
      />
    </StyledSection>
  );
};

export default CompanyInclusionSection;
