import Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive';

import Button from '../Button';
import TimezoneSelectInput from '../TimezoneSelectInput';
import Tooltip from '../../utils/Tooltip';
import { formatMoment, TimeFormat } from '../../../../libraries/time';
import { useAvailabilityPickerContext } from './use-context';

import type { Event } from './types';
import type { ToolbarProps } from 'libraries/react-big-calendar';

const AvailabilityPickerToolbar = ({ date, onNavigate, view }: ToolbarProps<Event>) => {
  const { brandColor, onTimezoneChange, timezone } = useAvailabilityPickerContext();

  const isSmallScreen = useMediaQuery({ query: '(max-width: 750px)' });

  const handleBack = () => {
    onNavigate('PREV');
  };

  const handleNext = () => {
    onNavigate('NEXT');
  };

  const handleJumpToToday = () => {
    onNavigate('TODAY');
  };

  const todayButtonStyles = brandColor ? {
    background: 'white',
    border: `1px solid ${brandColor}`,
    color: 'initial',
  } : undefined;

  return (
    <div className="calendar-toolbar">
      <div className="today-container">
        <Button
          color="gem-outline"
          onClick={handleJumpToToday}
          size="small"
          style={todayButtonStyles}
          value="Today"
        />
      </div>
      <div className="date-container">
        <div className="button-container left">
          <Button
            color="gray"
            iconRight={<FontAwesomeIcon icon={faAngleLeft} />}
            onClick={handleBack}
            size="large"
            tooltip={isSmallScreen ?
              undefined :
              <Tooltip
                id="availability-picker-toolbar-back"
                position="left"
                value={`Previous ${view}`}
              />
            }
          />
        </div>
        <div className="header">
          {view === 'day' && formatMoment(Moment(date), TimeFormat.LongMonthDayYear)}
          {view === 'week' && <span>{formatMoment(Moment(date).startOf('week'), TimeFormat.LongMonthDayYear)}&ndash;{formatMoment(Moment(date).endOf('week'), TimeFormat.LongMonthDayYear)}</span>}
        </div>
        <div className="button-container right">
          <Button
            color="gray"
            iconRight={<FontAwesomeIcon icon={faAngleRight} />}
            onClick={handleNext}
            size="large"
            tooltip={isSmallScreen ?
              undefined :
              <Tooltip
                id="availability-picker-toolbar-next"
                position="right"
                value={`Next ${view}`}
              />
            }
          />
        </div>
      </div>
      <div className="timezone-picker-container">
        <TimezoneSelectInput
          brandColor={brandColor}
          isRequired
          onChange={onTimezoneChange}
          value={timezone}
        />
      </div>
    </div>
  );
};

export default AvailabilityPickerToolbar;
