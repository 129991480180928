// This is inspired by
// https://github.com/therealparmesh/object-to-formdata/blob/01b80c3c126c8641f4f30a4f80c9a8c5fe2b37ea/src/index.js
// The only thing different is that it uses dot notation instead of bracket
// notation e.g. attachments.0.name instead of attachments[0][name].
const isUndefined = (value: any): value is undefined => value === undefined;

const isNull = (value: any): value is null => value === null;

const isBoolean = (value: any): value is boolean => typeof value === 'boolean';

const isObject = (value: any): value is Object => value === Object(value);

const isArray = (value: any): value is any[] => Array.isArray(value);

const isDate = (value: any): value is Date => value instanceof Date;

const isBlob = (value: any): value is Blob =>
  value &&
  typeof value.size === 'number' &&
  typeof value.type === 'string' &&
  typeof value.slice === 'function';

export const isFile = (value: any): value is File =>
  typeof value.size === 'number' &&
  typeof value.type === 'string' &&
  typeof value.slice === 'function' &&
  typeof value.name === 'string' &&
  (typeof value.lastModifiedDate === 'object' ||
    typeof value.lastModified === 'number');

interface Config {
  indices?: boolean;
  booleansAsIntegers?: boolean;
}

export const serialize = (obj: any, cfg: Config, fd?: FormData, pre: string = '') => {
  const {
    indices = false,
    booleansAsIntegers = false,
  } = cfg;
  fd = fd || new FormData();

  if (isUndefined(obj)) {
    return fd;
  } else if (isNull(obj)) {
    return fd;
  } else if (isBoolean(obj)) {
    if (booleansAsIntegers) {
      fd.append(pre, obj ? '1' : '0');
    } else {
      fd.append(pre, `${obj}`);
    }
  } else if (isArray(obj)) {
    if (obj.length) {
      obj.forEach((value, index) => {
        const haveIndex = isObject(value) && !isFile(value) && !isBlob(value) && !isDate(value);
        let key = pre;
        if (haveIndex) {
          key += `.${indices ? index : ''}`;
        }

        serialize(value, cfg, fd, key);
      });
    }
  } else if (isDate(obj)) {
    fd.append(pre, obj.toISOString());
  } else if (isObject(obj) && !isFile(obj) && !isBlob(obj)) {
    Object.keys(obj).forEach((prop) => {
      const value = obj[prop];

      if (isArray(value)) {
        while (prop.length > 2 && prop.lastIndexOf('[]') === prop.length - 2) {
          prop = prop.substring(0, prop.length - 2);
        }
      }

      const key = pre ? `${pre}.${prop}` : prop;

      serialize(value, cfg, fd, key);
    });
  } else {
    fd.append(pre, obj);
  }

  return fd;
};

export const downloadFileFromUrl = async (url: string, name: string): Promise<File> => {
  const response = await fetch(url);
  const data = await response.blob();
  return new File([data], name, {
    type: data.type,
  });
};
