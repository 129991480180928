import { Link } from 'react-router-dom';

import { StyledFooter, StyledLogoImage } from './styles';

const PoweredByGemFooter = () => (
  <StyledFooter>
    Powered by
    <Link className="logo" to="/">
      <StyledLogoImage
        alt="Gem Logo"
        src="/scheduling/src/assets/images/gem-logotype.svg"
      />
    </Link>
  </StyledFooter>
);

export default PoweredByGemFooter;
