import styled from 'styled-components';

interface Props {
  isAlternatingLayout?: boolean;
}

export const StyledContainer = styled.div<Props>`
  & > section {
    &:nth-child(even) {
      flex-direction: ${(props) => props.isAlternatingLayout ? 'row-reverse' : 'row'};

      @media only screen and (max-width: 800px) {
        flex-direction: column;
        padding: 0;
        padding-top: 2em;
      }
    }
  }
`;
