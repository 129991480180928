import PropTypes from 'prop-types'
import React from 'react'

import { navigate } from './utils/constants'
import TimeGrid from './TimeGrid'

class Day extends React.Component {
  render() {
    /**
     * This allows us to default min, max, and scrollToTime
     * using our localizer. This is necessary until such time
     * as TimeGrid is converted to a functional component.
     */
    let {
      date,
      localizer,
      // CUSTOM: Added default min
      min = localizer.startOf(new Date(), 'day'),
      max = localizer.endOf(new Date(), 'day'),
      scrollToTime = localizer.startOf(new Date(), 'day'),
      enableAutoScroll = true,
      // CUSTOM: Added useMinForDayView
      useMinForDayView,
      ...props
    } = this.props
    let range

    // TODO: Use this original code once we use a custom localizer.
    // range = Day.range(date, { localizer: localizer })

    if (min && useMinForDayView) {
      range = Day.range(min, { localizer: localizer })
    } else {
      range = Day.range(date, { localizer: localizer })
    }

    return (
      <TimeGrid
        {...props}
        range={range}
        eventOffset={10}
        localizer={localizer}
        min={min}
        max={max}
        scrollToTime={scrollToTime}
        enableAutoScroll={enableAutoScroll}
      />
    )
  }
}

Day.propTypes = {
  date: PropTypes.instanceOf(Date).isRequired,
  localizer: PropTypes.any,
  min: PropTypes.instanceOf(Date),
  max: PropTypes.instanceOf(Date),
  scrollToTime: PropTypes.instanceOf(Date),
  enableAutoScroll: PropTypes.bool,
  // CUSTOM
  useMinForDayView: PropTypes.bool,
}

Day.range = (date, { localizer }) => {
  return [localizer.startOf(date, 'day')]
}

Day.navigate = (date, action, { localizer }) => {
  switch (action) {
    case navigate.PREVIOUS:
      return localizer.add(date, -1, 'day')

    case navigate.NEXT:
      return localizer.add(date, 1, 'day')

    default:
      return date
  }
}

Day.title = (date, { localizer }) => localizer.format(date, 'dayHeaderFormat')

export default Day
