import { useCallback, useEffect, useState } from 'react';

import useQueryState from './use-query-state';

import type { Options as UseQueryStateOptions, HistoryMethod, SetQueryValueOptions } from './use-query-state';
import type { SetStateAction } from 'react';

interface Options extends UseQueryStateOptions {
  delay?: number;
}

const useSyncStateWithQuery = <T extends string | string[]>(queryKey: string, defaultValue: T, options: Options = {}): [T, T, (value: SetStateAction<T>, options?: SetQueryValueOptions) => void] => {
  const delay = options.delay || 0;

  const [queryValue, setQueryValue] = useQueryState<T>(queryKey, defaultValue, {
    array: options.array,
    stripDefault: options.stripDefault,
  });
  const [value, setValue] = useState(queryValue);
  const [historyMethod, setHistoryMethod] = useState<HistoryMethod>('replace');
  const [debouncedValue, setDebouncedValue] = useState(queryValue);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => clearTimeout(handler);
  }, [value]);

  // Updates query param if the state value changes
  useEffect(() => {
    const delimitedQueryValue = Array.isArray(queryValue) ? queryValue.join(',') : queryValue;
    const delimitedDebouncedValue = Array.isArray(debouncedValue) ? debouncedValue.join(',') : debouncedValue;

    if (delimitedDebouncedValue !== delimitedQueryValue) {
      setQueryValue(debouncedValue as T, { method: historyMethod });
    }
  }, [debouncedValue]);

  // Update the state value if the query param changes
  // (through the forward/back buttons on the browser)
  useEffect(() => {
    const delimitedQueryValue = Array.isArray(queryValue) ? queryValue.join(',') : queryValue;
    const delimitedDebouncedValue = Array.isArray(debouncedValue) ? debouncedValue.join(',') : debouncedValue;

    if (delimitedDebouncedValue !== delimitedQueryValue) {
      setValue(queryValue);
    }
  }, [JSON.stringify(queryValue)]);

  const setValueAndMethod = useCallback((newValue: SetStateAction<T>, options: SetQueryValueOptions = {}) => {
    setValue(newValue);
    setHistoryMethod(options.method || 'push');
  }, []);

  return [value, queryValue, setValueAndMethod];
};

export default useSyncStateWithQuery;
