import Moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { uniq } from 'lodash';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

import Button from '../../../../../library/inputs/Button';
import CalendarEventTemplateSummary from '../../../../../library/data-display/CalendarEventTemplateSummary';
import Flash from '../../../../../library/utils/Flash';
import pluralize from '../../../../../../libraries/pluralize';
import { Step, useNewSchedule } from '../use-new-schedule';
import { constructScheduleTokens } from '../helpers';
import { useApplication } from '../../../../../../hooks/queries/applications';
import { useMultipleRenders } from '../../../../../../hooks/queries/tokens';
import { useRoomsMap } from '../../../../../../hooks/queries/rooms';
import { useUsersMap } from '../../../../../../hooks/queries/users';

import type { Block, Schedule } from '../types';

interface Props {
  blocks: Block[];
  scheduleTemplate: Schedule['schedule_template'];
  timezone: string;
}

const CandidateCalendarEvents = ({ blocks, scheduleTemplate, timezone }: Props) => {
  const { id } = useParams<{ id: string }>();

  const application = useApplication(id).data!;

  const {
    completedStep,
    schedule,
  } = useNewSchedule();
  const rooms = useRoomsMap();
  const users = useUsersMap({ archived: true });

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  // candidate event titles
  const {
    data: renderedCandidateEventTitles,
    errors: candidateEventTitleErrors,
  } = useMultipleRenders(blocks.map((block) => ({
    type: 'candidate_calendar_event',
    plain_text: true,
    schedule: constructScheduleTokens(application, schedule, [block], rooms, users)?.[0],
    text: scheduleTemplate.candidate_calendar_event_template?.title,
  })), {
    enabled: completedStep >= Step.Review,
  });

  // candidate event descriptions
  const {
    data: renderedCandidateEventDescriptions,
    errors: candidateEventDescriptionErrors,
  } = useMultipleRenders(blocks.map((block) => ({
    type: 'candidate_calendar_event',
    plain_text: false,
    schedule: constructScheduleTokens(application, schedule, [block], rooms, users)?.[0],
    text: scheduleTemplate.candidate_calendar_event_template?.description,
  })), {
    enabled: completedStep >= Step.Review,
  });

  const blocksToDisplay = isExpanded ? blocks : [blocks[0]];

  const errorMessages = uniq(
    [...candidateEventTitleErrors, ...candidateEventDescriptionErrors]
    .filter((error): error is Error => Boolean(error))
    .map(({ message }) => message)
  );

  return (
    <>
      <Flash
        message={(
          <ul>
            {errorMessages.map((errorMessage, errorMessageIndex) => (
              <li
                key={`candidate-calendar-event-error-message-${errorMessageIndex}`}
              >
                {errorMessage}
              </li>
            ))}
          </ul>
        )}
        showFlash={errorMessages.length > 0}
        type="danger"
      />
      {blocksToDisplay.map((block, index) => {
        const interviews = block.interviews;
        return (
          <div
            className="block-candidate-calendar-event-container"
            key={`candidate-calendar-event-${index}`}
          >
            <CalendarEventTemplateSummary
              applicationId={application.id}
              calendarEventTemplate={{
                title: renderedCandidateEventTitles?.[index]?.rendered_text || '',
                description: renderedCandidateEventDescriptions?.[index]?.rendered_text || '',
                location: scheduleTemplate.video_conferencing_enabled ? (block.video_conferencing_link && (!block.selected_zoom_host || block.current_zoom_host_id === block.selected_zoom_host.id) ? block.video_conferencing_link : undefined) : scheduleTemplate.candidate_calendar_event_template?.location,
                additional_attendees: scheduleTemplate.candidate_calendar_event_template?.additional_attendees,
                additional_optional_attendees: scheduleTemplate.candidate_calendar_event_template?.additional_optional_attendees,
                type: 'candidate_calendar_event',
              }}
              candidateName={application.candidate.name}
              endTime={Moment.tz(interviews[interviews.length - 1].start_time, timezone).add(interviews[interviews.length - 1].interview_template.duration_minutes, 'minutes').toDate()}
              isDateDisplayed={blocks.length > 1}
              isVideoConferencingEnabled={scheduleTemplate.video_conferencing_enabled}
              startTime={Moment.tz(interviews[0].start_time, timezone).toDate()}
              timezone={timezone}
            />
            {index === 0 && blocks.length > 1 && (
              <Button
                color="no-outline"
                onClick={toggleIsExpanded}
                size="small"
                value={(
                  <span>
                    {isExpanded ? 'Hide' : 'Show'} {blocks.length - 1} other candidate calendar {pluralize('event', blocks.length - 1)}
                    <FontAwesomeIcon icon={isExpanded ? faAngleUp : faAngleDown} />
                  </span>
                )}
              />
            )}
          </div>
        );
      })}
    </>
  );
};

export default CandidateCalendarEvents;
