import type { BaseEditor, BaseElement, BaseSelection, BaseText } from 'slate';
import type { HistoryEditor } from 'slate-history';
import type { ReactEditor } from 'slate-react';
import type { Token } from './input';

// Editors
export type CustomEditor = BaseEditor & ReactEditor & HistoryEditor;

// Elements
export enum ElementType {
  Image = 'image',
  Link = 'link',
  ListItem = 'list_item',
  OrderedList = 'ordered_list',
  Paragraph = 'paragraph',
  Token = 'token',
  UnorderedList = 'unordered_list',
}

export interface ImageElement extends BaseElement {
  type: ElementType.Image;
  alt?: string;
  height?: string;
  width?: string;
  src: string;
}

export interface LinkElement extends BaseElement {
  type: ElementType.Link;
  url: string;
}

export interface ListItemElement extends BaseElement {
  type: ElementType.ListItem;
}

export interface OrderedListElement extends BaseElement {
  type: ElementType.OrderedList;
}

export interface ParagraphElement extends BaseElement {
  type: ElementType.Paragraph;
}

export interface TokenElement extends BaseElement, FormattedBase {
  type: ElementType.Token;
  token: Token;
}

export interface UnorderedListElement extends BaseElement {
  type: ElementType.UnorderedList;
}

export type ListElementType = ElementType.OrderedList | ElementType.UnorderedList;
export type ListElement = OrderedListElement | UnorderedListElement;

export type CustomElement =
    | ImageElement
    | LinkElement
    | ListItemElement
    | OrderedListElement
    | ParagraphElement
    | TokenElement
    | UnorderedListElement;

// Selection
// We're currently not overriding it, but we're referencing CustomSelection
// instead of BaseSelection so it's easier to refactor if we do need to make a
// custom selection type.
export type CustomSelection = BaseSelection;

// Texts
export enum InlineStyleType {
  Bold = 'bold',
  Italic = 'italic',
  Underline = 'underline',
}

export interface FormattedBase {
  bold?: boolean;
  italic?: boolean;
  underline?: boolean;
}

export interface FormattedText extends BaseText, FormattedBase {}

export type CustomText = FormattedText;

// Slate's typing is weird. Without this, all the types returned by slate
// functions will always be the `Base` versions of them. This creates
// incompatibility with other packages like slate-react. It's explained more
// here: https://docs.slatejs.org/concepts/12-typescript
declare module 'slate' {
  interface CustomTypes {
    Editor: CustomEditor;
    Element: CustomElement;
    Selection: CustomSelection;
    Text: CustomText;
  }
}
