import { Transforms } from 'slate';

import { htmlToSlateValue } from '../../../../../libraries/editor/html-to-slate-value';

import type { CustomEditor } from '../../../../../types';

export const withPaste = (editor: CustomEditor): CustomEditor => {
  const { insertData } = editor;

  editor.insertData = (data: DataTransfer) => {
    // Handle pasting images from clipboard. This is commented out because this inserts data URIs into the email body,
    // but most email clients don't display data URIs, so the images don't actually show up when sent. The real solution
    // would look similar, but instead of just inserting the data URI, we would upload the image to our backend for it
    // to be hosted. This is more effort than it's worth right now, so we'll just leave this here until we can circle
    // back to it.
    // const files = data.files;
    // if (files && files.length > 0) {
    //   for (const file of files) {
    //     const reader = new FileReader();
    //     const [mime] = file.type.split('/');
    //
    //     if (mime === 'image') {
    //       reader.addEventListener('load', async () => {
    //         // This will always be a string since we read it as a data URL.
    //         const src = reader.result as string;
    //         const dimensions = await dimensionsForUrl(src);
    //
    //         if (dimensions.error) {
    //           captureException(dimensions.error);
    //         } else {
    //           // This takes the screen's pixel density into account. This makes screenshots not as large. I don't
    //           // actually know if this causes other problems though, so we might need to remove this in the future.
    //           const scale = window.devicePixelRatio;
    //           insertImage(editor, {
    //             alt: 'Image',
    //             height: `${Math.floor(dimensions.height / scale)}`,
    //             src,
    //             width: `${Math.floor(dimensions.width / scale)}`,
    //           });
    //         }
    //       });
    //
    //       reader.readAsDataURL(file);
    //     }
    //   }
    //   return;
    // }

    let text = data.getData('text/plain');
    let content = data.getData('text/html');

    if (!content && text) {
      // sanitize HTML
      text = text
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/"/g, '&quot;');

      content = `<span>${text.replace(/\n/g, '<br>')}</span>`;
    }

    if (content) {
      const fragment = htmlToSlateValue(content);
      try {
        Transforms.insertFragment(editor, fragment);
      } catch (_) {
        // We're catching the error, but we don't need to do anything with it.
      }
      return;
    }

    insertData(data);
  };

  return editor;
};
