import Moment from 'moment-timezone';
import { Breadcrumb } from 'react-breadcrumbs';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { useEffect } from 'react';

import EventStep from './EventStep';
import LoadingSpinner from 'components/library/utils/LoadingSpinner';
import PreferencesStep from './PreferencesStep';
import ReviewStep from './ReviewStep';
import Section from 'components/library/layout/Section';
import { NewHiringMeetingProvider, useNewHiringMeeting } from './use-new-hiring-meeting';
import { StyledStepProgressBar } from './styles';
import { VideoConferencingTool } from 'types';
import { createEmptyZoomHostFilter } from '../../../../../libraries/zoom-hosts';
import { useAccount } from 'hooks/queries/accounts';
import { useApplication } from 'hooks/queries/applications';

import type { HiringMeetingTemplate } from 'types';
import { correctPath } from 'libraries/gem';

// To enable the inner component to access the context value, it needs to be nested
// under the provider, so we need this wrapper component to add that nesting.
const CandidateHiringMeetingCreate = () => {
  return (
    <NewHiringMeetingProvider>
      <CandidateHiringMeetingCreateInner />
    </NewHiringMeetingProvider>
  );
};

export default CandidateHiringMeetingCreate;

const CandidateHiringMeetingCreateInner = () => {
  const { id } = useParams<{ id: string }>();

  const {
    data: application,
    isLoading,
  } = useApplication(id);

  const { data: account } = useAccount();

  const {
    completedStep,
    hiringMeeting,
    setHiringMeeting,
  } = useNewHiringMeeting();

  useEffect(() => {
    if (!isLoading && account && application && !hiringMeeting.application_id) {
      // TODO: Fill with values from first hiring meeting template associated with the current stage.
      const defaultHiringMeetingTemplate = undefined as HiringMeetingTemplate | undefined;
      setHiringMeeting({
        hiring_meeting_template: {
          id: defaultHiringMeetingTemplate?.id,
          name: defaultHiringMeetingTemplate?.name || 'Inline Hiring Meeting Template',
          duration_minutes: defaultHiringMeetingTemplate?.duration_minutes || 30,
          video_conferencing_enabled: defaultHiringMeetingTemplate?.video_conferencing_enabled || false,
          zoom_host_filters: account.video_conferencing_type === VideoConferencingTool.Zoom ? defaultHiringMeetingTemplate?.zoom_host_filters || createEmptyZoomHostFilter() : undefined,
          calendar_event_template: {
            id: defaultHiringMeetingTemplate?.calendar_event_template_id,
            title: defaultHiringMeetingTemplate?.calendar_event_template.title || '',
            description: defaultHiringMeetingTemplate?.calendar_event_template.description || '',
            location: defaultHiringMeetingTemplate?.calendar_event_template.location || '',
          },
          hiring_meeting_attendee_filters: defaultHiringMeetingTemplate?.hiring_meeting_attendee_filters || [],
        },
        application_id: application.id,
        scheduling_calendar_email: account.scheduling_calendar_email || '',
        mark_event_as_private: false,
        timezone: Moment.tz.guess(),
        start_time: '',
      });
    }
  }, [Boolean(!isLoading && account && !hiringMeeting.application_id)]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <Breadcrumb
      data={{
        title: 'Schedule Hiring Meeting',
        pathname: correctPath(`/app/candidates/${application?.id}/schedule-hiring-meeting`),
      }}
    >
      {application && (
        <Section
          title={`Schedule a hiring meeting for ${application.candidate.name}`}
        >
          <StyledStepProgressBar
            completedStep={completedStep}
            steps={[{
              label: 'Preferences',
              location: correctPath(`/app/candidates/${application.id}/schedule-hiring-meeting/preferences`),
            }, {
              label: 'Event',
              location: correctPath(`/app/candidates/${application.id}/schedule-hiring-meeting/event`),
            }, {
              label: 'Review',
              location: correctPath(`/app/candidates/${application.id}/schedule-hiring-meeting/review`),
            }]}
          />
          <Switch>
            <Redirect exact from={correctPath('/app/candidates/:id/schedule-hiring-meeting')} to={correctPath('/app/candidates/:id/schedule-hiring-meeting/preferences')} />
            <Route component={PreferencesStep} path={correctPath('/app/candidates/:id/schedule-hiring-meeting/preferences')} />
            <Route component={EventStep} path={correctPath('/app/candidates/:id/schedule-hiring-meeting/event')} />
            <Route component={ReviewStep} path={correctPath('/app/candidates/:id/schedule-hiring-meeting/review')} />
          </Switch>
        </Section>
      )}
    </Breadcrumb>
  );
};
