import Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretLeft, faCaretRight } from '@fortawesome/free-solid-svg-icons';
import { useDayPicker, useNavigation } from 'react-day-picker';

import Button from '../Button';
import Tooltip from '../../utils/Tooltip';
import { useDatePickerContext } from './use-context';

import type { CaptionProps } from 'react-day-picker';

// Mostly inspired from
// https://github.com/gpbl/react-day-picker/blob/246d7ea0e2878d89b7336fb1ab7c2ef71f122c1e/packages/react-day-picker/src/components/CaptionNavigation/CaptionNavigation.tsx
const DateRangePickerNavbar = ({ displayMonth }: CaptionProps) => {
  const { onMonthChange } = useDayPicker();
  const { previousMonth, nextMonth, goToMonth } = useNavigation();

  const { brandColor, fromMonth } = useDatePickerContext();
  const isFirstMonth = fromMonth && Moment(displayMonth).isSame(fromMonth, 'month');

  const handlePreviousClick = () => {
    if (!previousMonth) {
      return;
    }
    goToMonth(previousMonth);
    onMonthChange?.(previousMonth);
  };

  const handleNextClick = () => {
    if (!nextMonth) {
      return;
    }
    goToMonth(nextMonth);
    onMonthChange?.(nextMonth);
  };

  return (
    <div className="day-picker-navbar">
      <Button
        color="gray"
        iconRight={<FontAwesomeIcon icon={faCaretLeft} />}
        isDisabled={isFirstMonth}
        onClick={handlePreviousClick}
        size="large"
        style={!isFirstMonth && brandColor ? { color: brandColor } : undefined}
        tooltip={isFirstMonth ? undefined : <Tooltip
          id="day-picker-previous-month"
          position="top"
          value="Previous month"
        />}
      />
      <h5 className="month">{Moment.months()[displayMonth.getMonth()]} {displayMonth.getFullYear()}</h5>
      <Button
        color="gray"
        iconRight={<FontAwesomeIcon icon={faCaretRight} />}
        onClick={handleNextClick}
        size="large"
        style={brandColor ? { color: brandColor } : undefined}
        tooltip={<Tooltip
          id="day-picker-next-month"
          position="top"
          value="Next month"
        />}
      />
    </div>
  );
};

export default DateRangePickerNavbar;
