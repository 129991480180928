import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const StyledNavLink = styled(NavLink)`
  align-items: center;
  border-bottom: 2px solid transparent;
  color: ${({ theme }) => theme.colors.doveGray || theme.colors.PRODUCT.PRIMARY_CONTENT};
  display: flex;
  flex-direction: column;
  font-weight: 500;
  height: 100%;
  justify-content: center;
  min-height: 60px;
  min-width: 75px;
  padding: 0 25px;
  text-align: center;
  text-decoration: none !important;

  &:not(.disabled):hover {
    color: ${({ theme }) => theme.colors.bayBlue || theme.colors.BRAND.GEM_BLUE};
  }

  &.active {
    border-bottom: 2px solid ${({ theme }) => theme.colors.curiousBlue || theme.colors.BRAND.GEM_BLUE};
    color: ${({ theme }) => theme.colors.mineshaftBlack || theme.colors.BRAND.GEM_BLUE};
  }

  &.disabled {
    background: ${({ theme }) => theme.colors.alabasterWhite};
    color: ${({ theme }) => theme.colors.silverGray};
    font-weight: normal;

    &:hover {
      cursor: default;
    }
  }
`;

export const StyledSecondaryText = styled.div`
  font-size: .85em;
  font-weight: normal;
  letter-spacing: initial;
  text-transform: none;
`;
