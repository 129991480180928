import Modal from '../library/layout/Modal';
import { formatMoment, TimeFormat } from '../../libraries/time';

import type Moment from 'moment-timezone';
import type { MouseEventHandler } from 'react';

interface Props {
  brandColor?: string;
  endTime: Moment.Moment;
  isSubmitting: boolean;
  isOpen: boolean;
  onDelete: MouseEventHandler<HTMLButtonElement>;
  onToggle: MouseEventHandler<HTMLButtonElement>;
  startTime: Moment.Moment;
}

const SelfScheduleCancelConfirmModal = ({
  brandColor,
  endTime,
  isSubmitting,
  isOpen,
  onDelete,
  onToggle,
  startTime,
}: Props) => {
  return (
    <Modal
      brandColor={brandColor}
      cancelButtonValue="Don't Cancel Interview"
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      onSubmit={onDelete}
      onToggle={onToggle}
      submitButtonValue="Cancel Interview"
      submittingButtonValue="Cancelling..."
      title="Cancel interview?"
    >
      <p>
        You are cancelling your interview, which is scheduled for <b>{formatMoment(startTime, TimeFormat.LongDayOfWeekMonthDay)}</b> at <b>{formatMoment(startTime, TimeFormat.Time)}&ndash;{formatMoment(endTime, TimeFormat.TimeWithTimezone)}</b>.
      </p>
      <p>
        All calendar events will be deleted, and we will notify your coordinator. You can reschedule this interview later if you wish.
      </p>
    </Modal>
  );
};

export default SelfScheduleCancelConfirmModal;
