import Moment from 'moment';
import { Breadcrumb } from 'react-breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Redirect, Route, Switch, useParams } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive';

import ArchiveButton from '../../../library/inputs/ArchiveButton';
import Button from '../../../library/inputs/Button';
import Flash from '../../../library/utils/Flash';
import HiringMeetingTemplateDetails from './HiringMeetingTemplateDetails';
// import HiringMeetingTemplateLinkedStages from './HiringMeetingTemplateLinkedStages';
import Label from '../../../library/utils/Label';
import PageHeader from '../../../library/layout/PageHeader';
import { FavoriteResourceType } from '../../../../types';
import { formatMoment, TimeFormat } from '../../../../libraries/time';
import { useHiringMeetingTemplate, useUpdateHiringMeetingTemplate } from '../../../../hooks/queries/hiring-meeting-templates';
import { correctPath } from 'libraries/gem';

const HiringMeetingTemplate = () => {
  const { id } = useParams<{ id: string }>();

  const {
    data: hiringMeetingTemplate,
    error: hiringMeetingTemplateError,
    isLoading: isHiringMeetingTemplateLoading,
  } = useHiringMeetingTemplate(id);

  const updateHiringMeetingTemplateMutation = useUpdateHiringMeetingTemplate();

  const isSmallScreen = useMediaQuery({ query: '(max-width: 1000px)' });

  const handleArchive = () => {
    updateHiringMeetingTemplateMutation.reset();
    updateHiringMeetingTemplateMutation.mutate({ id, payload: { archived: true } });
  };

  const handleUnarchive = () => {
    updateHiringMeetingTemplateMutation.reset();
    updateHiringMeetingTemplateMutation.mutate({ id, payload: { archived: false } });
  };

  if (isHiringMeetingTemplateLoading) {
    return null;
  }

  if (hiringMeetingTemplateError) {
    return (
      <Breadcrumb
        data={{
          title: id,
          pathname: correctPath(`/app/hiring-meeting-templates/${id}`),
        }}
      >
        <div className="hiring-meeting-template-container">
          <Flash
            message={hiringMeetingTemplateError.message}
            showFlash
            type="danger"
          />
        </div>
      </Breadcrumb>
    );
  }

  if (!hiringMeetingTemplate) {
    return null;
  }

  return (
    <Breadcrumb
      data={{
        title: hiringMeetingTemplate.name || id,
        pathname: correctPath(`/app/hiring-meeting-templates/${id}`),
      }}
    >
      <div className="hiring-meeting-template-container">
        <Flash
          isDismissible
          message={updateHiringMeetingTemplateMutation.error?.message}
          onDismiss={updateHiringMeetingTemplateMutation.reset}
          showFlash={updateHiringMeetingTemplateMutation.isError}
          type="danger"
        />
        <Flash
          isDismissible
          message={`Hiring meeting template ${hiringMeetingTemplate.archived ? 'archived' : 'unarchived'}.`}
          onDismiss={updateHiringMeetingTemplateMutation.reset}
          showFlash={updateHiringMeetingTemplateMutation.isSuccess}
          type="success"
        />
        <>
          <PageHeader
            details={
              <>
                {/*TODO: bring back once you can attach hiring meeting templates to stages*/}
                {/*<StatusIndicator color={hiringMeetingTemplate.linked_resources ? 'green' : 'gray'}>*/}
                {/*  Used in <Link to={correctPath(/app/hiring-meeting-templates/${hiringMeetingTemplate.id}/linked-stages`)}>{hiringMeetingTemplate.linked_resources || 0} active interview{hiringMeetingTemplate.linked_resources !== 1 && 's'}</Link>*/}
                {/*</StatusIndicator>*/}
                {/*{!isSmallScreen && <span className="separator">|</span>}*/}
                {!isSmallScreen && <span>Created {formatMoment(Moment(hiringMeetingTemplate.created_at), TimeFormat.LongMonthDayYear)}</span>}
                {!isSmallScreen && hiringMeetingTemplate.created_at !== hiringMeetingTemplate.updated_at && <span className="separator">|</span>}
                {!isSmallScreen && hiringMeetingTemplate.created_at !== hiringMeetingTemplate.updated_at && <span>Updated {formatMoment(Moment(hiringMeetingTemplate.updated_at), TimeFormat.LongMonthDayYear)}</span>}
              </>
            }
            favorite={hiringMeetingTemplate.favorite}
            navbarLinks={[{
              label: 'Details',
              location: correctPath(`/app/hiring-meeting-templates/${hiringMeetingTemplate.id}/details`),
            // }, {
            //   label: 'Linked Stages',
            //   location: correctPath(/app/hiring-meeting-templates/${hiringMeetingTemplate.id}/linked-stages`),
            }]}
            resourceId={hiringMeetingTemplate.id}
            resourceType={FavoriteResourceType.HiringMeetingTemplate}
            title={hiringMeetingTemplate.name}
            titleIcon={hiringMeetingTemplate.archived ? <Label color="gray">Archived</Label> : undefined}
          >
            <Link
              to={{
                pathname: correctPath('/app/hiring-meeting-templates/create'),
                search: `?base=${hiringMeetingTemplate.id}`,
              }}
            >
              <Button
                color="gem-outline"
                iconLeft={<FontAwesomeIcon icon={faPlus} />}
                size="small"
                value="New template from base"
              />
            </Link>
            <ArchiveButton
              id={id}
              isArchived={hiringMeetingTemplate.archived}
              onArchive={handleArchive}
              onUnarchive={handleUnarchive}
            />
            {/* <DeleteButton id={id} /> */}
          </PageHeader>
          <Switch>
            <Redirect exact from={correctPath('/app/hiring-meeting-templates/:id')} to={correctPath('/app/hiring-meeting-templates/:id/details')} />
            <Route component={HiringMeetingTemplateDetails} path={correctPath('/app/hiring-meeting-templates/:id/details')} />
            {/*TODO: bring back once you can attach hiring meeting templates to stages*/}
            {/*<Route component={HiringMeetingTemplateLinkedStages} path={correctPath('/app/hiring-meeting-templates/:id/linked-stages')} />*/}
          </Switch>
        </>
      </div>
    </Breadcrumb>
  );
};

export default HiringMeetingTemplate;
