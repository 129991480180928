import classnames from 'classnames';
import isHotkey from 'is-hotkey';
import { Editable, ReactEditor, useSlate } from 'slate-react';
import { Editor as SlateEditor, Node, Range, Text, Transforms } from 'slate';
import { pullAt, uniqueId } from 'lodash';
import { useMemo } from 'react';

import Attachment from './Attachment';
import Button from '../Button';
import Element from './Element';
import Leaf from './Leaf';
import Suggestions from './use-suggestions/Suggestions';
import TokenToolbarOption from './Toolbar/TokenToolbarOption';
import Toolbar from './Toolbar';
import useSuggestions from './use-suggestions';
import { InlineStyleType } from '../../../../types';
import { getCurrentListElementType, indentList, isList, outdentList, toggleBlock, toggleMark } from './helper';
import { htmlToSlateValue } from '../../../../libraries/editor/html-to-slate-value';
import { moveSelectionToEnd } from '../../../../libraries/editor';
import { slateValueToHtml } from '../../../../libraries/editor/slate-value-to-html';
import { slateValueToText } from '../../../../libraries/editor/slate-value-to-text';

import type { ClipboardEvent, Dispatch, KeyboardEvent, ReactNode, SetStateAction } from 'react';
import type { Descendant } from 'slate';
import type { EditorAttachment, EditorType, TokensResponse } from '../../../../types';
import type { RenderElementProps } from 'slate-react';

enum HotKey {
  B = 'mod+b',
  I = 'mod+i',
  U = 'mod+u',
}

const HOTKEY_MAPPINGS: Record<HotKey, InlineStyleType> = {
  [HotKey.B]: InlineStyleType.Bold,
  [HotKey.I]: InlineStyleType.Italic,
  [HotKey.U]: InlineStyleType.Underline,
};

export interface Props {
  allowImages?: boolean;
  allowTokens?: boolean;
  attachments?: EditorAttachment[];
  className?: string;
  exampleHtmlContent?: string;
  helperText?: ReactNode;
  id?: string;
  isDisabled?: boolean;
  isPlainText?: boolean;
  isRequired?: boolean;
  label?: ReactNode;
  pendingPreviewMessage: string;
  preventNewLines?: boolean;
  setAttachments?: Dispatch<SetStateAction<EditorAttachment[]>>;
  setValue?: Dispatch<SetStateAction<Descendant[]>>;
  showTokenButton?: boolean;
  showToolbar?: boolean;
  tokens?: TokensResponse;
  type: `${EditorType}`;
  value: Descendant[];
}

// We need to separate out the bulk of the component from the instantiation of
// the SlateProvider because useSuggestions uses useSlate, so it needs to be
// nested within it.
const Editor = ({
  allowImages = false,
  allowTokens = true,
  attachments,
  className,
  exampleHtmlContent,
  helperText,
  id,
  isDisabled = false,
  isPlainText = false,
  isRequired = false,
  label,
  pendingPreviewMessage,
  preventNewLines = false,
  setAttachments,
  setValue,
  showTokenButton = false,
  showToolbar = true,
  tokens,
  type,
  value,
}: Props) => {
  const editor = useSlate();

  id = id || useMemo(() => uniqueId('editor-input-'), []);

  const {
    active,
    addToken,
    dropdownRef,
    filteredSuggestions,
    handleOptionMouseDown,
    handleOptionMouseEnter,
    handleOptionMouseLeave,
    scrollSuggestionsDropdown,
    setActive,
    setShowSuggestions,
    showSuggestions,
  } = useSuggestions({
    tokens,
    type,
  });

  // This is allow clicking on any part of the editor, and it will focus on it.
  const handleMainClick = () => {
    if (!isDisabled && !ReactEditor.isFocused(editor)) {
      moveSelectionToEnd(editor);
      ReactEditor.focus(editor);
    }
  };

  // This is a single function that needs to handle all logic for key binding,
  // depending on what state we're in. To make it easier to digest, there are
  // other handler functions that are defined and called from this one. These
  // functions return a boolean signaling whether the key press was handled or
  // not.
  const handleKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
    // Handle suggestion navigation.
    if (showSuggestions && filteredSuggestions.length > 0) {
      const isHandled = handleSuggestions(event);
      if (isHandled) {
        return null;
      }
    }

    // Handle rich text.
    if (!isPlainText) {
      const isHandled = handleRichText(event);
      if (isHandled) {
        return null;
      }
    }

    // Handle list modification.
    if (isList(editor)) {
      const isHandled = handleLists(event);
      if (isHandled) {
        return null;
      }
    }

    // Handle new line prevention.
    if (preventNewLines) {
      const isHandled = handleNewLines(event);
      if (isHandled) {
        return null;
      }
    }
  };

  const handleSuggestions = (event: KeyboardEvent<HTMLDivElement>) => {
    let newActive;
    switch (event.key) {
      case 'ArrowDown':
        event.preventDefault();

        newActive = active;

        while (true) {
          if (filteredSuggestions.length === 0 || filteredSuggestions.every(({ value }) => tokens?.[value].disabled)) {
            // Mod of 0 is NaN.
            newActive = -1;
          } else {
            newActive = (newActive + 1) % filteredSuggestions.length;
          }

          // If the new active is disabled, process the increment logic again.
          if (filteredSuggestions[newActive] && tokens?.[filteredSuggestions[newActive].value].disabled) {
            continue;
          }
          break;
        }
        setActive(newActive);
        scrollSuggestionsDropdown(newActive);
        return true;
      case 'ArrowUp':
        event.preventDefault();

        newActive = active;

        while (true) {
          if (filteredSuggestions.length === 0 || filteredSuggestions.every(({ value }) => tokens?.[value].disabled)) {
            // Mod of 0 is NaN.
            newActive = -1;
          } else if (newActive === 0) {
            // Mod of negative number is the mod of it's absolute value, but then
            // made negative, which we don't want, so we need to catch that case
            // specifically.
            newActive = filteredSuggestions.length - 1;
          } else {
            newActive = (newActive - 1) % filteredSuggestions.length;
          }

          // If the new active is disabled, process the increment logic again.
          if (filteredSuggestions[newActive] && tokens?.[filteredSuggestions[newActive].value].disabled) {
            continue;
          }
          break;
        }
        setActive(newActive);
        scrollSuggestionsDropdown(newActive);
        return true;
      case 'Escape':
        setShowSuggestions(false);
        return true;
      case 'Tab':
      case 'Enter':
        event.preventDefault();
        addToken();
        return true;
    }

    return false;
  };

  const handleRichText = (event: KeyboardEvent<HTMLDivElement>) => {
    let isHandled = false;

    Object.values(HotKey).forEach((hotkey: HotKey) => {
      if (isHotkey(hotkey, event.nativeEvent)) {
        isHandled = true;
        event.preventDefault();
        const style = HOTKEY_MAPPINGS[hotkey];
        toggleMark(editor, style);
      }
    });

    return isHandled;
  };

  const handleLists = (event: KeyboardEvent<HTMLDivElement>) => {
    // Allow tabbing and shift-tabbing to indent and outdent.
    if (event.key === 'Tab') {
      event.preventDefault();
      if (event.shiftKey) {
        outdentList(editor);
      } else {
        indentList(editor);
      }
      return true;
    }

    // If the current text node is empty, and they press Enter, toggle the list.
    const path = editor.selection?.focus.path;
    if (!path) {
      return false;
    }
    const textNode = Node.get(editor, path);
    if (event.key === 'Enter' && Text.isText(textNode) && textNode.text === '') {
      event.preventDefault();
      // Google's functionality is to completely toggle the list if this is a
      // trailing list item (even if it's nested), but if there's another
      // non-empty list item that appears after the current one, it just
      // outdents instead. We are always completely toggling, and I think that's
      // fine for now, but it's something to think about in the future.
      const listElementType = getCurrentListElementType(editor);
      if (listElementType) {
        toggleBlock(editor, listElementType);
        return true;
      }
    }

    return false;
  };

  const handleNewLines = (event: KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      return true;
    }

    return false;
  };

  const handleRemoveAttachment = (i: number) => {
    setAttachments?.((prevAttachments) => {
      const attachmentsCopy = prevAttachments.slice();
      pullAt(attachmentsCopy, [i]);
      return attachmentsCopy;
    });
  };

  const handleCopyCut = (event: ClipboardEvent<HTMLDivElement>) => {
    const selection = window.getSelection();

    // Completely skip event handling if clipboardData is not supported (IE11 is
    // out). Also skip if there is no selection ranges.
    if (!event.clipboardData || selection?.rangeCount === 0) {
      return;
    }

    const fragment = editor.getFragment();

    if (fragment?.length > 0) {
      const html = slateValueToHtml(fragment);
      const text = slateValueToText(fragment);

      event.clipboardData.setData('text/plain', text);
      event.clipboardData.setData('text/html', html);

      event.preventDefault();
    }
  };

  const handleCopy = (event: ClipboardEvent<HTMLDivElement>) => {
    handleCopyCut(event);
  };

  const handleCut = (event: ClipboardEvent<HTMLDivElement>) => {
    handleCopyCut(event);

    // Remove the selected text. This is pulled from
    // https://github.com/ianstormtaylor/slate/blob/083a3da2203840e337dd1e5b6c6896096a7f7c6c/packages/slate-react/src/components/editable.tsx#L710-L721
    const { selection } = editor;

    if (selection) {
      if (Range.isExpanded(selection)) {
        SlateEditor.deleteFragment(editor);
      } else {
        const node = Node.parent(editor, selection.anchor.path);
        if (SlateEditor.isVoid(editor, node)) {
          Transforms.delete(editor);
        }
      }
    }
  };

  const fillExampleHtmlContent = () => {
    if (setValue && exampleHtmlContent) {
      setValue(htmlToSlateValue(exampleHtmlContent));
    }
  };

  return (
    <div className={classnames('input', 'editor-input', className)}>
      <div className="label-container">
        {label && <label htmlFor={id}>{label}</label>}
        {exampleHtmlContent && !isDisabled && (
          <Button
            className="btn-link"
            color="no-outline"
            onClick={fillExampleHtmlContent}
            size="small"
            value="Try example content"
          />
        )}
      </div>
      <div className={`editor-wrapper ${isDisabled ? 'disabled' : ''}`}>
        {showToolbar && (
          <Toolbar
            allowImages={allowImages}
            allowTokens={allowTokens}
            isDisabled={isDisabled}
            setAttachments={setAttachments}
          />
        )}
        <div
          className="editor-main"
          onClick={handleMainClick}
        >
          <Editable
            onCopy={handleCopy}
            onCut={handleCut}
            onKeyDown={handleKeyDown}
            readOnly={isDisabled}
            renderElement={(props: RenderElementProps) => (
              <Element
                {...props}
                pendingPreviewMessage={pendingPreviewMessage}
                tokens={tokens}
                type={type}
              >
                {props.children}
              </Element>
            )}
            renderLeaf={Leaf}
          />
          <Suggestions
            active={active}
            filteredSuggestions={filteredSuggestions}
            onOptionMouseDown={handleOptionMouseDown}
            onOptionMouseEnter={handleOptionMouseEnter}
            onOptionMouseLeave={handleOptionMouseLeave}
            pendingPreviewMessage={pendingPreviewMessage}
            ref={dropdownRef}
            showSuggestions={!isDisabled && showSuggestions}
            tokens={tokens}
          />
        </div>
        {showTokenButton && (
          <div className="editor-token-button">
            <TokenToolbarOption
              isDisabled={isDisabled}
              showText={false}
              showTooltip
            />
          </div>
        )}
      </div>
      {attachments && attachments.length > 0 && (
        <div className="attachments-container">
          {attachments.map((attachment, i) => (
            <Attachment
              file={attachment}
              isEditing={!isDisabled}
              key={attachment.name}
              onRemove={() => handleRemoveAttachment(i)}
            />
          ))}
        </div>
      )}
      {helperText && <div className="helper-text">{helperText}</div>}
      <div className="editor-input-hidden-validation-container">
        <input className="editor-input-hidden-validation" onChange={() => {}} required={isRequired} value={slateValueToText(value)} />
      </div>
    </div>
  );
};

export default Editor;
