import Moment from 'moment-timezone';
import bytes from 'bytes-iec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperPlane, faPaperclip } from '@fortawesome/free-solid-svg-icons';

import Avatar from './Avatar';
import Card from '../layout/Card';
import OutboundLink from '../navigation/OutboundLink';
import { formatMoment, TimeFormat } from '../../../libraries/time';
import { isExistingEditorAttachment } from '../../../types';

import type { EditorAttachment } from '../../../types';
import type { ReactNode } from 'react';

interface Props {
  attachments?: EditorAttachment[];
  bccEmails?: string[];
  body: ReactNode;
  ccEmails?: string[];
  senderEmail: string;
  senderName: string;
  sentAt?: string;
  subject: ReactNode;
  to: ReactNode;
}

const EmailPreview = ({ attachments, bccEmails, body, ccEmails, senderName, senderEmail, sentAt, subject, to }: Props) => {
  return (
    <Card className="email-preview">
      <div className="email-section subject">
        <h3>{subject}</h3>
        {sentAt && (
          <span className="sent-at">
            <FontAwesomeIcon className="sent-icon" icon={faPaperPlane} />
            {formatMoment(Moment.tz(sentAt, Moment.tz.guess()), TimeFormat.ShortMonthDayYearWithTimeAndTimezoneAndComma)}
          </span>
        )}
      </div>
      <div className="email-content-container">
        <Avatar
          alt={senderName}
          size="large"
          type="candidate" // This is just to show initials.
        />
        <div className="email-content">
          <div className="email-section sender">
            <span>
              <span className="sender-name">{senderName}</span>&nbsp;
              <span className="sender-email">&lt;{senderEmail}&gt;</span>
            </span>
          </div>
          <div className="email-section recipients">
            <span>
              to {to}
            </span>
            {ccEmails && ccEmails.length ? (
              <span>
                , {ccEmails.join(', ')}
              </span>
            ) : null}
            {bccEmails && bccEmails.length ? (
              <span>
                , bcc: {bccEmails.join(', ')}
              </span>
            ) : null}
          </div>
          {typeof body === 'string' ?
            <div
              className="email-section body"
              dangerouslySetInnerHTML={{ __html: body }}
            /> :
            <div className="email-section body">
              {body}
            </div>
          }
          {attachments && attachments.length > 0 &&
            <div className="email-section attachments">
              <b>{attachments.length} Attachment{attachments.length > 1 ? 's' : ''}</b>
              <div className="attachments-container">
                {attachments.map((attachment) => (
                  <div className="attachment" key={attachment.name}>
                    <FontAwesomeIcon className="attachment-icon" icon={faPaperclip} />
                    {isExistingEditorAttachment(attachment) && attachment.link ?
                      <OutboundLink className="file-name" href={attachment.link} label="Email Template Attachment">
                        {attachment.name}
                      </OutboundLink> :
                      <span className="file-name">
                        {attachment.name}
                      </span>
                    }
                    {!isExistingEditorAttachment(attachment) && attachment.size &&
                      <>
                        &nbsp;
                        <span className="file-size">
                          ({bytes.format(attachment.size, { mode: 'binary', unitSeparator: ' ' })})
                        </span>
                      </>
                    }
                  </div>
                ))}
              </div>
            </div>
          }
        </div>
      </div>
    </Card>
  );
};

export default EmailPreview;
