import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import Flash from '../../../../library/utils/Flash';
import Section from '../../../../library/layout/Section';
import SelectInput from '../../../../library/inputs/SelectInput';
import Tag from '../../../../library/data-display/Tag';
import { useEligibilities, useUpdateUser, useUser, useUserTags } from '../../../../../hooks/queries/users';

import type { ActionMeta } from 'react-select';
import type { EligibilityOption } from '../../InterviewerList/types';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../../../library/inputs/SelectInput/types';

const TagsSection = () => {
  const { id } = useParams<{ id: string }>();

  const { data: user } = useUser(id);

  const { data: allEligibilities } = useEligibilities();
  const eligibilityOptionsFromServer = useMemo<EligibilityOption[]>(() => (allEligibilities || []).map((eligibility) => ({
    label: eligibility.id,
    value: `${eligibility.id}${eligibility.trainee || false}`,
    trainee: eligibility.trainee,
    training_program_id: eligibility.training_program_id,
  })), [allEligibilities]);
  const [eligibilityOptions, setEligibilityOptions] = useState<EligibilityOption[]>(eligibilityOptionsFromServer);

  const { data: allUserTags } = useUserTags();
  const tagOptionsFromServer = useMemo<Option<string>[]>(() => (allUserTags || []).map((tag) => ({
    label: tag.id,
    value: tag.id,
  })), [allUserTags]);
  const [tagOptions, setTagOptions] = useState<Option<string>[]>(tagOptionsFromServer);

  const [isEditing, setIsEditing] = useState(false);

  const [eligibilities, setEligibilities] = useState<EligibilityOption[]>((user?.eligibilities || []).map((eligibility) => ({
    label: eligibility.id,
    value: `${eligibility.id}${eligibility.trainee || false}`,
    trainee: eligibility.trainee,
    training_program_id: eligibility.training_program_id,
  })));
  const [tags, setTags] = useState<Option<string>[]>((user?.user_tags || []).map((tag) => ({
    label: tag.id,
    value: tag.id,
  })));

  const eligibilitiesMap = useMemo<Record<string, boolean>>(() => eligibilities
  .reduce<Record<string, boolean>>((acc, eligibility) => {
    return ({ ...acc, [`${eligibility.label}:${eligibility.trainee || false}`]: true });
  }, {}), [eligibilities]);

  const updateUserMutation = useUpdateUser();

  useEffect(() => {
    setEligibilityOptions(eligibilityOptionsFromServer);
  }, [JSON.stringify(eligibilityOptionsFromServer)]);

  useEffect(() => {
    setTagOptions(tagOptionsFromServer);
  }, [JSON.stringify(tagOptionsFromServer)]);

  const handleEligibilitiesChange = (options: OnChangeValue<EligibilityOption, true>, actionMeta: ActionMeta<EligibilityOption>) => {
    if (actionMeta.action === 'create-option') {
      // update our complete list of eligibilities to include this new one
      setEligibilityOptions((prev) => [
        ...prev,
        { label: options[options.length - 1].label, value: options[options.length - 1].value, trainee: false },
      ]);
    }

    const optionsMap = (options || [])
    .reduce<Record<string, boolean>>((acc, eligibility) => {
      return ({ ...acc, [`${eligibility.label}:${eligibility.trainee || false}`]: true });
    }, {});
    setEligibilities(
      options ?
        options
        .map(({ label, value, trainee, training_program_id }) => ({ label, value, trainee, training_program_id }))
        .filter((({ label, trainee }) => {
          if (!trainee) {
            return true;
          }
          return !optionsMap[`${label}:false`];
        })) :
        []
    );
  };

  const handleTagsChange = (options: OnChangeValue<Option<string>, true>, actionMeta: ActionMeta<Option<string>>) => {
    if (actionMeta.action === 'create-option') {
      // update our complete list of tags to include this new one
      setTagOptions([
        ...tagOptions,
        { label: options[options.length - 1].label, value: options[options.length - 1].value },
      ]);
    }

    setTags(options ? options.map(({ label, value }) => ({ label, value })) : []);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setEligibilityOptions(eligibilityOptionsFromServer);
    setTagOptions(tagOptionsFromServer);
    setEligibilities((user?.eligibilities || []).map((eligibility) => ({
      label: eligibility.id,
      value: `${eligibility.id}${eligibility.trainee || false}`,
      trainee: eligibility.trainee,
      training_program_id: eligibility.training_program_id,
    })));
    setTags((user?.user_tags || []).map((tag) => ({
      label: tag.id,
      value: tag.id,
    })));
    setIsEditing(false);
  };

  const handleSave = async () => {
    updateUserMutation.reset();

    try {
      await updateUserMutation.mutateAsync({
        id,
        payload: {
          eligibilities_v2: eligibilities.map(({ label, trainee }) => ({
            id: label,
            trainee: trainee || false,
          })),
          user_tags: tags.map(({ value }) => value),
        },
      });
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  return (
    <Section
      className="interviewer-details-tags-section"
      isEditable
      isEditing={isEditing}
      isSaving={updateUserMutation.isLoading}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Tags"
    >
      <Flash
        message={updateUserMutation.error?.message}
        showFlash={updateUserMutation.isError}
        type="danger"
      />
      <Flash
        isDismissible
        message="Successfully updated!"
        showFlash={updateUserMutation.isSuccess}
        type="success"
      />
      <SelectInput
        className="select-input-interviewers-eligibility"
        formatCreateLabel={(value) => `Add new interview eligibility "${value}"`}
        formatOptionLabel={(option) => {
          // The type for formatOptionLabel isn't correct. Fix this once
          // https://github.com/JedWatson/react-select/issues/5064 is addressed.
          const { __isNew__, label, trainee, training_program_id } = option as any;
          return (
            __isNew__ ?
              label :
              <Tag
                hasTrainingProgram={Boolean(training_program_id)}
                isNegated={false}
                isTrainee={trainee}
                type="eligibility"
                value={label}
              />
          );
        }}
        isClearable
        isCreatable
        isDisabled={!isEditing || updateUserMutation.isLoading}
        isMulti
        label="Interview Eligibilities"
        onChange={handleEligibilitiesChange}
        options={eligibilityOptions.filter((option) => {
          if (!option.trainee) {
            // If this isn't a trainee tag, always show it.
            return true;
          }
          return !eligibilitiesMap[`${option.label}:false`];
        })}
        placeholder="Add interview eligibilities"
        value={eligibilities}
      />
      <SelectInput
        className="select-input-interviewers-tag"
        formatCreateLabel={(value) => `Add new tag "${value}"`}
        formatOptionLabel={(option) => {
          // The type for formatOptionLabel isn't correct. Fix this once
          // https://github.com/JedWatson/react-select/issues/5064 is addressed.
          const { __isNew__, label, value } = option as any;
          return (
            __isNew__ ?
              label :
              <Tag
                isNegated={false}
                type="tag"
                value={value}
              />
          );
        }}
        isClearable
        isCreatable
        isDisabled={!isEditing || updateUserMutation.isLoading}
        isMulti
        label="Tags"
        onChange={handleTagsChange}
        options={tagOptions}
        placeholder="Add tags"
        value={tags}
      />
    </Section>
  );
};

export default TagsSection;
