import Moment from 'moment-timezone';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { find, findIndex, keyBy, uniqueId } from 'lodash';
import { useCallback, useMemo } from 'react';

import Avatar from '../../../../library/data-display/Avatar';
import Button from '../../../../library/inputs/Button';
import DeleteButton from '../../../../library/inputs/DeleteButton';
import ListItem from '../../../../library/data-display/ListItem';
import StatusIndicator from '../../../../library/utils/StatusIndicator';
import TrainingInterviewerSelectInput from './TrainingInterviewerSelectInput';
import TrainingProgressBar from '../../../../library/data-display/TrainingProgressBar';
import TrainingStatusSelectInput from './TrainingStatusSelectInput';
import { PendingChangeType } from './types';
import { StyledCurrentPhaseName } from '../styles';
import { StyledNumberOfInterviewsTextInput, StyledTable as StyledPhaseTable } from '../../styles';
import { StyledTable, StyledTableContainer } from './styles';
import { correctTrainingSessions, currentPhase } from '../../../../../libraries/training';
import { deleteAtIndex } from './helpers';

import type { ChangeEvent, Dispatch, SetStateAction } from 'react';
import type { PendingChange, PendingChangeUser } from './types';
import type { TableSchema } from '../../../../library/data-display/Table';
import type {
  TrainingOverride,
  TrainingPhase,
  TrainingProgram,
  TrainingProgramUser,
  TrainingSession,
} from '../../../../../types';

const displayRowArrowLabel = ({ training_overrides }: PendingChangeUser) => (
  <StatusIndicator color={training_overrides && training_overrides.length > 0 ? 'green' : 'gray'} >
    <span className="btn btn-small btn-no-outline">
      Custom Phases
    </span>
  </StatusIndicator>
);

const getRowIsExpanding = ({ trainee, training_sessions }: PendingChangeUser) => {
  const filteredTrainingSessions = (training_sessions || []).filter((session) => !session.interviewer || session.interviewer.interview.schedule.status === 'confirming' || session.interviewer.interview.schedule.status === 'confirmed');
  return trainee || filteredTrainingSessions.length !== 0;
};

interface Props {
  isEditing: boolean;
  onPageNumberChange?: (pageNumber: number) => void;
  onShowGraduatedChange: (event: ChangeEvent<HTMLInputElement>) => void;
  originalTrainingProgramUsers: TrainingProgramUser[];
  pageNumber?: number;
  pendingChanges: PendingChange[];
  setPendingChanges: Dispatch<SetStateAction<PendingChange[]>>;
  setTrainingProgramUsers: Dispatch<SetStateAction<PendingChangeUser[] | undefined>>;
  showGraduated: boolean;
  totalCount: number;
  trainingProgram: TrainingProgram;
  trainingProgramUsers: PendingChangeUser[];
}

const TrainingProgramInterviewersTable = ({
  isEditing,
  onPageNumberChange,
  onShowGraduatedChange,
  originalTrainingProgramUsers,
  pageNumber,
  pendingChanges,
  setPendingChanges,
  setTrainingProgramUsers,
  showGraduated,
  totalCount,
  trainingProgram,
  trainingProgramUsers,
}: Props) => {
  const isAnyExpanding = useMemo(() => trainingProgramUsers.some((user) => getRowIsExpanding(user)), [trainingProgramUsers]);
  const trainingProgramUserIds = useMemo(() => trainingProgramUsers.map((user) => user.id).filter((id): id is string => Boolean(id)), [trainingProgramUsers]);

  const originalTrainingProgramUsersMap = useMemo<Record<string, TrainingProgramUser>>(() => {
    return originalTrainingProgramUsers.reduce((acc, user) => {
      return {
        ...acc,
        [user.id]: user,
      };
    }, {});
  }, [originalTrainingProgramUsers]);

  const columns = useMemo<TableSchema<PendingChangeUser>>(() => [{
    header: 'Interviewer',
    displayValue: (user) => (
      user.isNew ?
        // This div that stops event propagation is necessary so that clicking on the select input doesn't expand the
        // custom phases section of the table row.
        <div onClick={(e) => e.stopPropagation()}>
          <TrainingInterviewerSelectInput
            originalTrainingProgramUsersMap={originalTrainingProgramUsersMap}
            selectedUser={user}
            setPendingChanges={setPendingChanges}
            setTrainingProgramUsers={setTrainingProgramUsers}
            trainingProgramId={trainingProgram.id}
            trainingProgramUserIds={trainingProgramUserIds}
          />
        </div> :
        <ListItem
          label={user.name}
          leftIcon={<Avatar
            showUserTooltip={false}
            size="small"
            type="user"
            userId={user.id}
          />}
        />
    ),
  }, {
    header: 'Status',
    getCellClassName: () => 'training-program-status-cell',
    displayValue: (user) => {
      const phase = currentPhase(trainingProgram, user);
      return (
        <>
          <div>{user.trainee ? 'Trainee' : 'Graduate'}</div>
          {user.trainee && phase && <StyledCurrentPhaseName>{phase}</StyledCurrentPhaseName>}
        </>
      );
    },
    displayEditValue: (user, index) => (
      // This div that stops event propagation is necessary so that clicking on the select input doesn't expand the
      // custom phases section of the table row.
      <div onClick={(e) => e.stopPropagation()}>
        <TrainingStatusSelectInput
          onChange={(newTrainee) => {
            setTrainingProgramUsers((prev) => prev?.map((u, stateIndex) => {
              if (stateIndex !== index) {
                return u;
              }
              return {
                ...u,
                trainee: newTrainee,
              };
            }));
            setPendingChanges((prev) => {
              const userAddedIndex = findIndex(prev, (change) => change.type === PendingChangeType.TrainingProgramUserCreate && (user.id ? user.id === change.user.id : user.uniqueId === change.user.uniqueId));
              if (userAddedIndex !== -1) {
                // This user is a newly added user, so we should just change its nested user.trainee field, but not add
                // a new pending change.
                return [
                  ...prev.slice(0, userAddedIndex),
                  {
                    ...prev[userAddedIndex],
                    user: {
                      ...prev[userAddedIndex].user,
                      trainee: newTrainee,
                    },
                  },
                  ...prev.slice(userAddedIndex + 1),
                ];
              }

              const inverseType = newTrainee ? PendingChangeType.TrainingProgramUserGraduate : PendingChangeType.TrainingProgramUserUngraduate;
              const inverseIndex = findIndex(prev, (change) => change.type === inverseType && (user.id ? user.id === change.user.id : user.uniqueId === change.user.uniqueId));
              if (inverseIndex !== -1) {
                // We had already toggled this user's training status before, so this removes that previous pending
                // change, so it gets toggled back to what it was originally.
                return deleteAtIndex(prev, inverseIndex);
              }

              return [
                ...prev,
                {
                  type: newTrainee ? PendingChangeType.TrainingProgramUserUngraduate : PendingChangeType.TrainingProgramUserGraduate,
                  user: {
                    ...user,
                    trainee: newTrainee,
                  },
                },
              ];
            });
          }}
          trainee={user.trainee}
        />
      </div>
    ),
  }, {
    header: 'Training Program',
    getCellClassName: () => 'training-program-progress-bar-cell',
    displayValue: (user, index) => {
      const { training_overrides, training_sessions, trainee } = user;
      return (
        <TrainingProgressBar
          graduated={!trainee}
          isEditing={isEditing}
          onSessionChange={(session, phase, checked) => {
            const now = Moment();
            const newSessionId = uniqueId('new:');
            const addingNewSession = !session || (session.interviewer && !Moment(session.interviewer.interview.start_time).isBefore(now));

            if (!addingNewSession) {
              // An existing session is being updated.
              const manual = !session.interviewer;
              setTrainingProgramUsers((prev) => prev?.map((u, stateIndex) => {
                if (stateIndex !== index) {
                  return u;
                }
                const newUser = {
                  ...u,
                  training_sessions: u.training_sessions?.map((existingSession) => {
                    if (session.id !== existingSession.id) {
                      return existingSession;
                    }
                    if (session.id.startsWith('new:')) {
                      // This is a session that was just manually checked, and it's now being unchecked. So instead of
                      // marking it as "deleted", we just delete it from the list. We do this by returning null here and
                      // then filtering it out.
                      return null;
                    }
                    const key = manual ? 'deleted_at' : 'ignored_at';
                    return {
                      ...existingSession,
                      [key]: checked ? undefined : now.format(),
                    };
                  }).filter((session): session is TrainingSession => Boolean(session)) || null,
                };
                newUser.training_sessions = correctTrainingSessions(trainingProgram, newUser);
                return newUser;
              }));
            } else {
              // There's no session, so this is checking an empty checkbox for the first time, so we need to create a
              // new session.
              setTrainingProgramUsers((prev) => prev?.map((u, stateIndex) => {
                if (stateIndex !== index) {
                  return u;
                }
                const newUser = {
                  ...u,
                  training_sessions: [
                    ...(u.training_sessions || []),
                    {
                      id: newSessionId,
                      training_phase_id: phase.id,
                      user_id: u.id || '',
                      phase_name: phase.name,
                      created_at: now.format(),
                      updated_at: now.format(),
                    },
                  ],
                };
                newUser.training_sessions = correctTrainingSessions(trainingProgram, newUser);
                return newUser;
              }));
            }

            setPendingChanges((prev) => {
              // Determine the type and the inverse type of this change.
              const type = addingNewSession || (session && !session.interviewer) ?
                checked ? PendingChangeType.TrainingSessionCreate : PendingChangeType.TrainingSessionDelete :
                checked ? PendingChangeType.TrainingSessionUnignore : PendingChangeType.TrainingSessionIgnore;
              const inverseType = (() => {
                switch (type) {
                  case PendingChangeType.TrainingSessionCreate:
                    return PendingChangeType.TrainingSessionDelete;
                  case PendingChangeType.TrainingSessionDelete:
                    return PendingChangeType.TrainingSessionCreate;
                  case PendingChangeType.TrainingSessionIgnore:
                    return PendingChangeType.TrainingSessionUnignore;
                  case PendingChangeType.TrainingSessionUnignore:
                    return PendingChangeType.TrainingSessionIgnore;
                }
              })();

              // Check if there is already a pending change of the inverse type so that we can search for the session
              // in question to determine if we need to remove an existing pending change instead of adding a new one.
              const inverseChangeIndex = findIndex(prev, (change) => change.type === inverseType && (user.id ? change.user.id === user.id : change.user.uniqueId === user.uniqueId));
              if (!addingNewSession && inverseChangeIndex !== -1) {
                const change = prev[inverseChangeIndex];
                if (change.type === inverseType) {
                  const sessionIndex = findIndex<{ id: string }>(change.sessions, (s) => s.id === session.id);
                  if (sessionIndex !== -1) {
                    // This session already exists as a pending change, so we should remove it.
                    if (change.sessions.length === 1) {
                      // We're about to remove a session, so if it's the last session in this change, we should just
                      // remove the whole change.
                      return deleteAtIndex(prev, inverseChangeIndex);
                    }
                    return [
                      ...prev.slice(0, inverseChangeIndex),
                      change.type === PendingChangeType.TrainingSessionCreate ? {
                        ...change,
                        sessions: deleteAtIndex(change.sessions, sessionIndex),
                      } : {
                        ...change,
                        sessions: deleteAtIndex(change.sessions, sessionIndex),
                      },
                      ...prev.slice(inverseChangeIndex + 1),
                    ];
                  }
                }
              }

              // Since we know that we don't need to remove anything, we go through and add it. Whether we add a
              // completely new pending change or add to an existing one depends on if the existing one already
              // exists.
              const changeIndex = findIndex(prev, (change) => change.type === type && (user.id ? change.user.id === user.id : change.user.uniqueId === user.uniqueId));
              if (changeIndex === -1) {
                // An existing pending change for this type and user doesn't exist yet, so we'll add it.
                return [
                  ...prev,
                  type === PendingChangeType.TrainingSessionCreate ? {
                    type,
                    user,
                    sessions: [{
                      id: newSessionId,
                      phase,
                    }],
                  } : {
                    type,
                    user,
                    sessions: [session!],
                  },
                ];
              } else {
                // We already have a pending change for this type and user, so we're just going to append to it.
                const change = prev[changeIndex];
                return [
                  ...prev.slice(0, changeIndex),
                  change.type === PendingChangeType.TrainingSessionCreate ? {
                    ...change,
                    sessions: [...change.sessions, {
                      id: newSessionId,
                      phase,
                    }],
                    // This ternary isn't necessary for logic reasons, but it's necessary for type reasons. The else
                    // case will never actually happen.
                  } : change.type === type ? {
                    ...change,
                    sessions: [...change.sessions, session!],
                  } : change,
                  ...prev.slice(changeIndex + 1),
                ];
              }
            });
          }}
          trainingOverrides={training_overrides || []}
          trainingProgram={trainingProgram}
          trainingSessions={training_sessions || []}
        />
      );
    },
  }, isEditing && {
    header: '',
    isClickable: true,
    getCellClassName: () => classnames(['action-buttons-container', isAnyExpanding && 'additional-column-after']),
    displayValue: (user) => {
      const originalUser = originalTrainingProgramUsersMap[user.id!];
      // We want to disable deletion if this user is already on the training program and if they have any pending
      // changes.
      const isDisabled = originalUser && pendingChanges.some((change) => change.user.id === user.id);

      return (
        <DeleteButton
          alwaysRed
          id={user.id || user.uniqueId || ''}
          isDisabled={isDisabled}
          onClick={(e) => {
            e.stopPropagation();
            setTrainingProgramUsers((prev) => prev?.filter((u) => user.isNew ? u.uniqueId !== user.uniqueId : u.id !== user.id));
            setPendingChanges((prev) => {
              if (user.isNew && !originalUser) {
                // If this user was newly added and wasn't originally a user (e.g. we didn't just delete them, then add
                // them again), just remove all pending changes for this user (including the addition). This is preferred
                // over adding them and then deleting them. Since the user might not have been selected yet, we use the
                // unique ID to find all changes instead of the user ID.
                return prev.filter((change) => change.user.uniqueId !== user.uniqueId);
              }
              return [
                ...prev,
                {
                  type: PendingChangeType.TrainingProgramUserDelete,
                  user,
                },
              ];
            });
          }}
          tooltipText={isDisabled ? 'You can\'t delete an interviewer with unsaved changes.' : 'Delete'}
        />
      );
    },
  }], [isEditing, isAnyExpanding, trainingProgram, originalTrainingProgramUsersMap, pendingChanges, trainingProgramUserIds]);

  const displayExpandedContent = useCallback((user: PendingChangeUser, index: number) => {
    interface Phase {
      phase: TrainingPhase;
      override?: TrainingOverride;
    }
    const overridesByPhaseId = keyBy(user.training_overrides || [], 'training_phase_id');
    const phases = (trainingProgram.training_phases || []).map<Phase>((phase) => {
      if (overridesByPhaseId[phase.id]) {
        return {
          phase,
          override: overridesByPhaseId[phase.id],
        };
      }
      return { phase };
    });

    return (
      <StyledPhaseTable
        className="training-program-overrides-table"
        data={phases}
        isEditing={isEditing}
        layout="vertical"
        schema={[{
          header: '',
          getCellClassName: () => 'training-phase-table-index',
          displayValue: (_, i) => i + 1,
        }, {
          header: 'Phase Name',
          displayValue: ({ phase }) => phase.name,
        }, {
          header: 'Number of Interviews',
          getCellClassName: () => 'training-phase-table-number-of-interviews',
          displayValue: ({ phase, override }) => override ? override.number_of_interviews : `Default (${phase.number_of_interviews})`,
          displayEditValue: ({ phase, override }) => (
            <StyledNumberOfInterviewsTextInput
              className="override"
              isDisabled={!user.trainee}
              numberMax={100}
              numberMin={0}
              onChange={(e) => {
                const originalUser = originalTrainingProgramUsersMap[user.id!];
                const now = Moment();
                const newOverrideId = uniqueId('new:');
                const newNumber = e.target.value ? parseInt(e.target.value, 10) : undefined;

                setTrainingProgramUsers((prev) => prev?.map((u, stateIndex) => {
                  if (stateIndex !== index) {
                    return u;
                  }
                  // If there is no override ID, this will always not find something.
                  const overrideIndex = findIndex(u.training_overrides || [], ({ id }) => override?.id === id);
                  if (overrideIndex === -1 && newNumber === undefined) {
                    // We didn't find a corresponding override, but we also don't have a new number to set, so we just ignore it.
                    return u;
                  }
                  if (overrideIndex === -1 && newNumber !== undefined) {
                    // We didn't find a corresponding override, so we need to either find an original one or add one.
                    const originalOverride = find(originalUser?.training_overrides || [], (override) => override.training_phase_id === phase.id && override.user_id === user.id);
                    if (originalOverride) {
                      return {
                        ...u,
                        training_overrides: [
                          ...(u.training_overrides || []),
                          {
                            ...originalOverride,
                            number_of_interviews: newNumber,
                          },
                        ],
                      };
                    }
                    return {
                      ...u,
                      training_overrides: [
                        ...(u.training_overrides || []),
                        {
                          id: newOverrideId,
                          user_id: u.id || '',
                          training_phase_id: phase.id,
                          number_of_interviews: newNumber,
                          created_at: now.format(),
                          updated_at: now.format(),
                        },
                      ],
                    };
                  }
                  if (newNumber === undefined) {
                    // We have an override, but we don't have a number, so that means we need to clear out the existing
                    // override.
                    return {
                      ...u,
                      training_overrides: deleteAtIndex(u.training_overrides!, overrideIndex),
                    };
                  }
                  // We have an override, and we have a new number, so we just need to update the existing override with
                  // this new number.
                  return {
                    ...u,
                    training_overrides: [
                      ...u.training_overrides!.slice(0, overrideIndex),
                      {
                        ...u.training_overrides![overrideIndex],
                        number_of_interviews: newNumber,
                      },
                      ...u.training_overrides!.slice(overrideIndex + 1),
                    ],
                  };
                }));

                setPendingChanges((prev) => {
                  if (newNumber === undefined) {
                    // We're removing an override. If this is a newly created one, we need to remove the creation
                    // pending change, but if it's not, we need to add a deletion pending change. In both scenarios, we
                    // need to remove any potential update pending changes.
                    const purgedUpdates = prev.filter((change) => !(change.type === PendingChangeType.TrainingOverrideUpdate && change.override.id === override?.id));

                    const addIndex = findIndex(purgedUpdates, (change) => change.type === PendingChangeType.TrainingOverrideCreate && change.override.id === override?.id);
                    if (addIndex !== -1) {
                      // This was newly created, so we just remove the creation pending change.
                      return deleteAtIndex(purgedUpdates, addIndex);
                    }

                    // This is an existing override, so we add a deletion pending change.
                    return [
                      ...purgedUpdates,
                      {
                        type: PendingChangeType.TrainingOverrideDelete,
                        user,
                        phase,
                        override: override!,
                      },
                    ];
                  }

                  // We're adding/updating an override.
                  const deleteIndex = findIndex(prev, (change) => change.type === PendingChangeType.TrainingOverrideDelete && change.phase.id === phase.id && (user.id ? change.user.id === user.id : change.user.uniqueId === user.uniqueId));
                  if (deleteIndex !== -1) {
                    // This is an existing override that we previously marked as deleted. This means that we're adding a
                    // new value. Firstly, we need to extract that override from the deletion pending change (since
                    // that's the only reference we have to it right now), and then we need to remove the deletion
                    // pending change.
                    const originalOverride = find(originalUser?.training_overrides || [], (override) => override.training_phase_id === phase.id && override.user_id === user.id);
                    const purgedUpdates = deleteAtIndex(prev, deleteIndex);

                    if (originalOverride!.number_of_interviews === newNumber) {
                      // We've reset the value to the original value, so we don't need to add an update pending change.
                      return purgedUpdates;
                    }

                    // We've changed the value from the original, so we need to add an update pending change with the
                    // new value.
                    return [
                      ...purgedUpdates,
                      {
                        type: PendingChangeType.TrainingOverrideUpdate,
                        user,
                        phase,
                        override: originalOverride!,
                        value: newNumber,
                      },
                    ];
                  }

                  if (!override) {
                    // We're adding a new override.
                    return [
                      ...prev,
                      {
                        type: PendingChangeType.TrainingOverrideCreate,
                        user,
                        phase,
                        override: {
                          id: newOverrideId,
                        },
                        value: newNumber,
                      },
                    ];
                  }

                  const addIndex = findIndex(prev, (change) => change.type === PendingChangeType.TrainingOverrideCreate && change.override.id === override.id);
                  if (addIndex !== -1) {
                    // This is a newly created override, so we just need to update the value in the pending change.
                    return [
                      ...prev.slice(0, addIndex),
                      {
                        ...prev[addIndex],
                        value: newNumber,
                      },
                      ...prev.slice(addIndex + 1),
                    ];
                  }

                  const updateIndex = findIndex(prev, (change) => change.type === PendingChangeType.TrainingOverrideUpdate && change.override.id === override.id);
                  if (updateIndex !== -1) {
                    // This is an existing update pending change. If the new value is the same as the original, we need
                    // to remove this pending change. If it's different, we just need to update it.
                    if (override.number_of_interviews === newNumber) {
                      return deleteAtIndex(prev, updateIndex);
                    }

                    return [
                      ...prev.slice(0, updateIndex),
                      {
                        ...prev[updateIndex],
                        value: newNumber,
                      },
                      ...prev.slice(updateIndex + 1),
                    ];
                  }

                  // There are no update pending changes, so we need to add one.
                  return [
                    ...prev,
                    {
                      type: PendingChangeType.TrainingOverrideUpdate,
                      user,
                      phase,
                      override,
                      value: newNumber,
                    },
                  ];
                });
              }}
              placeholder={`Default (${phase.number_of_interviews})`}
              type="number"
              value={override?.number_of_interviews}
            />
          ),
        }]}
      />
    );
  }, [isEditing]);

  const handleAddInterviewer = useCallback(() => {
    const user = {
      trainee: true,
      training_sessions: null,
      training_overrides: null,
      isNew: true,
      uniqueId: uniqueId(),
    };
    setTrainingProgramUsers((prev) => [
      user,
      ...(prev || []),
    ]);
    setPendingChanges((prev) => {
      return [
        ...prev,
        {
          type: PendingChangeType.TrainingProgramUserCreate,
          user,
        },
      ];
    });
  }, [setTrainingProgramUsers]);

  return (
    <StyledTableContainer>
      <StyledTable
        additionalHeaderElements={isEditing && (
          <Button
            color="gem-outline"
            iconLeft={<FontAwesomeIcon icon={faPlus} />}
            onClick={handleAddInterviewer}
            size="small"
            value="Add interviewer"
          />
        )}
        data={trainingProgramUsers}
        displayExpandedContent={displayExpandedContent}
        displayRowArrowLabel={displayRowArrowLabel}
        getRowIsExpanding={getRowIsExpanding}
        isArchivedDisabled={isEditing}
        isEditing={isEditing}
        isPaginated
        layout="vertical"
        onPageNumberChange={onPageNumberChange}
        onShowArchivedChange={onShowGraduatedChange}
        pageNumber={pageNumber}
        rowArrowButtonSize="small"
        schema={columns}
        showArchived={showGraduated}
        showArchivedLabel="Show graduates"
        totalCount={totalCount}
      />
    </StyledTableContainer>
  );
};

export default TrainingProgramInterviewersTable;
