import { Breadcrumb } from 'react-breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Route, Switch } from 'react-router-dom';
import { faGraduationCap } from '@fortawesome/free-solid-svg-icons';

import TrainingProgram from './TrainingProgram';
import TrainingProgramCreate from './TrainingProgramCreate';
import TrainingProgramList from './TrainingProgramList';
import { correctPath } from 'libraries/gem';

const TrainingPrograms = () =>
  <Breadcrumb
    data={{
      title: <><FontAwesomeIcon icon={faGraduationCap} />Training Programs</>,
      pathname: correctPath('/app/training-programs'),
    }}
  >
    <Switch>
      <Route component={TrainingProgramList} exact path={correctPath('/app/training-programs')} />
      <Route component={TrainingProgramCreate} path={correctPath('/app/training-programs/create')} />
      <Route component={TrainingProgram} path={correctPath('/app/training-programs/:id')} />
    </Switch>
  </Breadcrumb>
;

export default TrainingPrograms;
