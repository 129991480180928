import { Breadcrumb } from 'react-breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Route, Switch } from 'react-router-dom';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';

import CalendarEventTemplate from './CalendarEventTemplate';
import CalendarEventTemplateCreate from './CalendarEventTemplateCreate';
import CalendarEventTemplateList from './CalendarEventTemplateList';
import { correctPath } from 'libraries/gem';

const CalendarEventTemplates = () => (
  <Breadcrumb
    data={{
      title: <><FontAwesomeIcon icon={faCalendar} />Calendar Event Templates</>,
      pathname: correctPath('/app/calendar-event-templates'),
    }}
  >
    <Switch>
      <Route component={CalendarEventTemplateList} exact path={correctPath('/app/calendar-event-templates')} />
      <Route component={CalendarEventTemplateCreate} path={correctPath('/app/calendar-event-templates/create')} />
      <Route component={CalendarEventTemplate} path={correctPath('/app/calendar-event-templates/:id')} />
    </Switch>
  </Breadcrumb>
);

export default CalendarEventTemplates;
