import type {
  HiringMeetingTemplate,
  HiringMeetingAttendeeFilterExpressionFilterableType,
  EditableHiringMeetingTemplate,
} from './hiring-meeting-templates';
import type { HiringTeamRole } from './hiring-team-roles';
import type { RSVP } from './events';
import type { Room } from './rooms';
import type { User } from './users';
import type { ZoomHostType } from './zoom';

export enum HiringMeetingStatus {
  Confirming = 'confirming',
  Confirmed = 'confirmed',
  Cancelling = 'cancelling',
  Cancelled = 'cancelled',
}

export interface HiringMeeting {
  id: string;
  account_id: string;
  hiring_meeting_template_id: string;
  hiring_meeting_template: HiringMeetingTemplate;
  application_id: string;
  application: Application;
  stage_id: string;
  stage: Stage;
  creator_id: string;
  creator: User;
  status: HiringMeetingStatus;
  scheduling_calendar_email: string;
  mark_event_as_private: boolean;
  timezone: string;
  start_time: string;
  calendar_event_ical_uid?: string;
  calendar_event_title: string;
  calendar_event_description: string;
  calendar_event_location?: string;
  calendar_event_additional_attendees: string[];
  calendar_event_additional_optional_attendees: string[];
  room_id?: string;
  room?: Room;
  room_rsvp?: RSVP;
  video_conferencing_meeting_id?: string;
  video_conferencing_passcode?: string;
  zoom_host_id?: string;
  zoom_host_type?: ZoomHostType;
  hiring_meeting_attendees: HiringMeetingAttendee[] | null;
  created_at: string;
  updated_at: string;
}

interface Application {
  id: string;
  candidate_id: string;
  candidate: Candidate;
  job_id: string;
  job: Job;
}

interface Candidate {
  id: string;
  name?: string;
}

interface Stage {
  id: string;
  name: string;
  job_id: string;
  job: Job;
}

interface Job {
  id: string;
  name: string;
}

export interface HiringMeetingAttendee {
  id: string;
  hiring_meeting_id: string;
  attendee_id: string;
  attendee_type: HiringMeetingAttendeeFilterExpressionFilterableType;
  rsvp: RSVP;
  rsvp_comment?: string;
  created_at: string;
  updated_at: string;
}

export interface HiringMeetingAttendeeUser {
  id: string;
  is_chosen_as_individual: boolean;
  hiring_team_roles?: HiringTeamRole[];
  interview_names?: string[];
}

export interface EditableHiringMeeting {
  id: string;
  hiring_meeting_template: EditableHiringMeetingTemplate;
  application_id?: string;
  stage: Stage;
  scheduling_calendar_email: string;
  mark_event_as_private: boolean;
  timezone: string;
  start_time: string;
  calendar_event_location?: string;
  room_id?: string;
  room_rsvp?: RSVP;
  zoom_host_id?: string;
  zoom_host_type?: ZoomHostType;
}
