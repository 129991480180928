import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize, isEmpty, keyBy, mapValues, omit, orderBy, uniqueId } from 'lodash';
import { faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { useQueryClient } from 'react-query';

import ATSImportedIcon from '../../../../../../../../library/data-display/ATSImportedIcon';
import Button from '../../../../../../../../library/inputs/Button';
import FeedbackFormSelectInput from '../../../../../../../../library/inputs/FeedbackFormSelectInput';
import InterviewTemplateInlineForm from './InterviewTemplateInlineForm';
import InterviewTemplateSelectInput from '../../../../../../../../library/inputs/InterviewTemplateSelectInput';
import InterviewTemplateSummary from '../../../../../../../../library/data-display/InterviewTemplateSummary';
import Label from '../../../../../../../../library/utils/Label';
import OutboundLink from '../../../../../../../../library/navigation/OutboundLink';
import Table from '../../../../../../../../library/data-display/Table';
import TextInput from '../../../../../../../../library/inputs/TextInput';
import Tooltip from '../../../../../../../../library/utils/Tooltip';
import { ATS } from '../../../../../../../../../types';
import { interviewTemplateParams } from '../../../../../../../../../hooks/queries/interview-templates';
import { useFeedbackForms } from '../../../../../../../../../hooks/queries/feedback-forms';
import { useJob } from '../../../../../../../../../hooks/queries/jobs';
import { useSession } from '../../../../../../../../../hooks/use-session';
import { useStage } from '../../../../../../../../../hooks/queries/stages';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { ChangeEvent, Dispatch, SetStateAction } from 'react';
import type { InterviewTemplatePayload, StageInterviewPayload } from '../types';
import type { Option } from '../../../../../../../../library/inputs/SelectInput/types';
import type { StageInterview } from '../../../../../../../../../types';
import type { TableSchema } from '../../../../../../../../library/data-display/Table';

const INTERVIEW_TEMPLATE_INPUT_NAME_PREFIX = 'interview-template-select-input-stage-interview-' as const;
const FEEDBACK_FORM_INPUT_NAME_PREFIX = 'feedback-form-select-input-stage-interview-' as const;

const constructEmptyInterviewTemplate = (interview?: StageInterview, jobName?: string): InterviewTemplatePayload => ({
  id: 'new',
  name: interview && interview.name ? `[${jobName}] ${interview.name}` : '',
  duration_minutes: interview && interview.ats_duration_minutes || 0,
  live_coding_enabled: false,
  positions: undefined,
  time_window_start: undefined,
  time_window_end: undefined,
  candidate_facing_name: undefined,
  candidate_facing_details: undefined,
  interviewer_templates: [
    {
      description: '',
      optional: false,
      include_past_interviewers: true,
      interviewer_filters: [
        {
          interviewer_filter_expressions: [],
        },
      ],
    },
  ],
});

interface Props {
  isEditing: boolean;
  isSaving: boolean;
  clickedSubmit: boolean;
  setClickedSubmit: Dispatch<SetStateAction<boolean>>;
  stageInterviewPayloads: Record<string, StageInterviewPayload>;
  setStageInterviewPayloads: Dispatch<SetStateAction<Record<string, StageInterviewPayload>>>;
}

const InterviewsTable = ({
  isEditing,
  isSaving,
  stageInterviewPayloads,
  setStageInterviewPayloads,
  clickedSubmit,
  setClickedSubmit,
}: Props) => {
  const queryClient = useQueryClient();

  const { id: jobId, stageId } = useParams<{ id: string; stageId: string }>();

  const { account } = useSession();
  const { data: job } = useJob(jobId);
  const { data: stage } = useStage(jobId, stageId);
  const { data: feedbackForms } = useFeedbackForms();

  const stageInterviews = useMemo(() => keyBy(stage?.stage_interviews, 'id'), [stage?.stage_interviews]);

  const handleAddStageInterview = () => {
    const newStageInterviewId = uniqueId('new_');
    setStageInterviewPayloads((prevState) => ({
      ...prevState,
      [newStageInterviewId]: {
        id: newStageInterviewId,
        name: '',
        position: Object.keys(stageInterviewPayloads).length,
      },
    }));
  };

  useEffect(() => {
    if (isEditing && isEmpty(stageInterviewPayloads)) {
      handleAddStageInterview();
    }
  }, [isEditing]);

  const handleRemoveStageInterview = (stageInterviewId: string) => {
    setStageInterviewPayloads((prevState) => {
      const removedPosition = prevState[stageInterviewId].position!;
      return mapValues(
        omit(prevState, [stageInterviewId]),
        (stageInterview: StageInterviewPayload) => ({
          ...stageInterview,
          position: stageInterview.position! > removedPosition ? stageInterview.position! - 1 : stageInterview.position,
        })
      );
    });
  };

  const handleStageInterviewNameChange = (stageInterviewId: string, e: ChangeEvent<HTMLInputElement>) => {
    setStageInterviewPayloads((prevState) => ({
      ...prevState,
      [stageInterviewId]: {
        ...prevState[stageInterviewId],
        name: e.target.value,
      },
    }));
  };

  const handleInterviewTemplateChange = useCallback(async (option: OnChangeValue<Option<string>, false>, actionMeta: ActionMeta<Option<string>>) => {
    if (!actionMeta.name) {
      // name should always be set.
      return;
    }
    const stageInterviewId = actionMeta.name.replace(INTERVIEW_TEMPLATE_INPUT_NAME_PREFIX, '');
    const interviewTemplate = option && option.value !== 'new' ? await queryClient.fetchQuery(interviewTemplateParams(option.value)) : null;

    setStageInterviewPayloads((prevState) => ({
      ...prevState,
      [stageInterviewId]: {
        ...prevState[stageInterviewId],
        interview_template_id: option ? option.value : '',
        interview_template: option ? (
          interviewTemplate ?
            {
              ...interviewTemplate,
              interviewer_templates: interviewTemplate.interviewer_templates ? interviewTemplate.interviewer_templates.map((interviewerTemplate) => ({
                ...interviewerTemplate,
                interviewer_filters: (interviewerTemplate.interviewer_filters || []).map((filter) => ({
                  ...filter,
                  interviewer_filter_expressions: filter.interviewer_filter_expressions || [],
                })),
              })) : [],
            } :
            constructEmptyInterviewTemplate(stageInterviews[stageInterviewId], job?.name)
        ) : undefined,
      },
    }));
  }, [stageInterviews]);

  const handleFeedbackFormChange = useCallback((option: OnChangeValue<Option<string>, false>, actionMeta: ActionMeta<Option<string>>) => {
    if (!actionMeta.name) {
      // name should always be set.
      return;
    }
    const stageInterviewId = actionMeta.name.replace(FEEDBACK_FORM_INPUT_NAME_PREFIX, '');

    setStageInterviewPayloads((prevState) => ({
      ...prevState,
      [stageInterviewId]: {
        ...prevState[stageInterviewId],
        feedback_form_id: option ? option.value : '',
      },
    }));
  }, []);

  const handleSetInterviewTemplate = useCallback((stageInterviewId: string, interviewTemplate: Partial<InterviewTemplatePayload>) => {
    setStageInterviewPayloads((prevState) => ({
      ...prevState,
      [stageInterviewId]: {
        ...(prevState[stageInterviewId] || {}),
        interview_template_id: interviewTemplate.id || prevState[stageInterviewId]?.interview_template_id,
        interview_template: {
          ...(prevState[stageInterviewId]?.interview_template || {}),
          // The types are e bit weird because of all the optionals.
          ...interviewTemplate as InterviewTemplatePayload,
        },
      },
    }));
  }, []);

  const handleSetStageInterviewId = useCallback((stageInterviewId: string, newId: string) => {
    setStageInterviewPayloads((prevState) => ({
      ...omit(prevState, [stageInterviewId]),
      [newId]: {
        ...prevState[stageInterviewId],
        id: newId,
      },
    }));
  }, []);

  const data = useMemo<StageInterviewPayload[]>(() => orderBy(Object.values(stageInterviewPayloads), 'position'), [stageInterviewPayloads]);
  const hasAtsUnschedulableInterviews = useMemo(() => Object.values(stageInterviewPayloads).some(({ id }) => stageInterviews[id] && stageInterviews[id].ats_schedulable !== null && !stageInterviews[id].ats_schedulable), [stageInterviewPayloads, stageInterviews]);
  const feedbackFormsById = useMemo(() => keyBy(feedbackForms?.feedback_forms, 'id'), [feedbackForms]);

  const columns = useMemo<TableSchema<StageInterviewPayload>>(() => [account?.ats_type !== 'lever' && {
    header: '',
    displayValue: ({ id }) => {
      const isImported = Boolean(stageInterviews[id]?.ats_id);
      return (
        <ATSImportedIcon
          isImported={isImported}
          nonImportedTooltipValue="Custom interview"
        />
      );
    },
  }, {
    header: 'Interview Name',
    displayValue: ({ id, name }) => (
      <>
        {name}
        {stageInterviews[id] && stageInterviews[id].ats_schedulable !== null && !stageInterviews[id].ats_schedulable ?
          <Label
            color="gray"
            tooltip={
              <Tooltip
                id={`ats-unschedulable-label-${id}`}
                position="top"
                value={`You have not marked this interview as schedulable in ${capitalize(account?.ats_type)}.`}
              />
            }
          >
            Unschedulable
          </Label> :
          ''
        }
      </>
    ),
    displayEditValue: ({ id, name }) => (
      stageInterviews[id] && stageInterviews[id].ats_id ?
        <>
          {name}
          {stageInterviews[id] && stageInterviews[id].ats_schedulable !== null && !stageInterviews[id].ats_schedulable ?
            <Label
              color="gray"
              tooltip={
                <Tooltip
                  id={`ats-unschedulable-label-${id}`}
                  position="top"
                  value={`You have not marked this interview as schedulable in ${capitalize(account?.ats_type)}.`}
                />
              }
            >
              Unschedulable
            </Label> :
            ''
          }
        </> :
        <div onClick={(e) => e.stopPropagation()}>
          <TextInput
            onChange={(e) => handleStageInterviewNameChange(id, e)}
            value={name}
          />
        </div>
    ),
  }, {
    header: 'Default Interview Template',
    displayValue: ({ interview_template }) => {
      if (!interview_template) {
        return <span className="no-template">No template</span>;
      }
      return interview_template.name;
    },
    displayEditValue: ({ id, interview_template_id }) => (
      <div onClick={(e) => e.stopPropagation()}>
        <InterviewTemplateSelectInput
          isDisabled={Boolean(stageInterviews[id] && stageInterviews[id].ats_schedulable !== null && !stageInterviews[id].ats_schedulable)}
          isInlineCreatable
          name={`${INTERVIEW_TEMPLATE_INPUT_NAME_PREFIX}${id}`}
          onChange={handleInterviewTemplateChange}
          value={interview_template_id}
        />
      </div>
    ),
  }, account?.ats_type === 'lever' && {
    header: 'Feedback Form',
    displayValue: ({ feedback_form_id }) => {
      if (!feedback_form_id) {
        return <span className="no-template">No feedback form</span>;
      }
      return feedbackFormsById[feedback_form_id]?.name;
    },
    displayEditValue: ({ id, feedback_form_id }) => (
      <div onClick={(e) => e.stopPropagation()}>
        <FeedbackFormSelectInput
          name={`${FEEDBACK_FORM_INPUT_NAME_PREFIX}${id}`}
          onChange={handleFeedbackFormChange}
          selectedFeedbackFormId={feedback_form_id}
        />
      </div>
    ),
  }, isEditing && {
    header: '',
    displayValue: undefined,
    displayEditValue: ({ id }) => (
      stageInterviews[id] && stageInterviews[id].ats_id ?
        null :
        <div onClick={(e) => e.stopPropagation()}>
          <Button
            className="btn-delete btn-delete-interview"
            color="gray"
            iconRight={<FontAwesomeIcon icon={faTrashAlt} />}
            onClick={() => handleRemoveStageInterview(id)}
            size="large"
            tooltip={
              <Tooltip
                id={`${id}-delete-button`}
                position="top"
                value="Remove interview"
              />
            }
          />
        </div>
    ),
  }], [
    account,
    feedbackFormsById,
    handleInterviewTemplateChange,
    handleFeedbackFormChange,
    isEditing,
    stageInterviews,
  ]);

  const displayExpandedContent = useCallback(({ id, interview_template, interview_template_id, name, position }: StageInterviewPayload) => {
    const stageInterview = stageInterviews[id] || {};
    const oldInterviewTemplateId = stageInterview.interview_template_id;
    const isUpdated = oldInterviewTemplateId !== interview_template_id;

    const linkedInterviews = interview_template?.linked_interviews || null;
    const isEditable = (!isUpdated && linkedInterviews === 1) || (isUpdated && !linkedInterviews);
    return (
      interview_template_id && interview_template ?
        ((isEditing || isSaving) && isEditable ?
          <InterviewTemplateInlineForm
            interviewTemplate={interview_template}
            setClickedSubmit={setClickedSubmit}
            setInterviewTemplate={(template) => handleSetInterviewTemplate(id, template)}
            setStageInterviewId={(newId) => handleSetStageInterviewId(id, newId)}
            stageInterviewId={id}
            stageInterviewName={name}
            stageInterviewPosition={position}
          /> :
          <InterviewTemplateSummary
            id={interview_template_id}
            showFlash={isEditing}
            stageInterviewName={stageInterview.name}
          />
        ) :
        null
    );
  }, [handleSetInterviewTemplate, handleSetStageInterviewId, isEditing, isSaving, stageInterviews]);

  const getRowIsExpanded = useCallback(({ interview_template_id }: StageInterviewPayload) => {
    if (isEditing && interview_template_id === 'new') {
      return true;
    }
    if (isEditing && clickedSubmit) {
      return false;
    }
    return false;
  }, [isEditing, stageInterviews]);

  if (!isEditing && isEmpty(stageInterviewPayloads)) {
    return null;
  }

  const showImportedColumn = columns[0] && columns[0].header === '';

  return (
    <>
      <Table
        className={`${isEditing ? 'table-editing' : ''}${showImportedColumn ? ' show-imported-column' : ''}`}
        collapseRowTooltipText="Hide template details"
        data={data}
        displayExpandedContent={displayExpandedContent}
        expandRowTooltipText="Show template details"
        getRowIsExpanded={getRowIsExpanded}
        isEditing={isEditing}
        layout="vertical"
        schema={columns}
      />
      {isEditing &&
        <Button
          className="btn-add-interview"
          color="no-outline"
          iconLeft={<FontAwesomeIcon icon={faPlus} />}
          onClick={handleAddStageInterview}
          size="small"
          value="Add Interview"
        />
      }
      {hasAtsUnschedulableInterviews && account?.ats_type === ATS.Greenhouse &&
        <div className="helper-text">
          <OutboundLink
            href="https://support.gem.com/hc/en-us/articles/23491571522839-What-is-an-unschedulable-interview-in-Greenhouse"
            label="ATS Unschedulable Helper Text"
          >
            What is an unschedulable interview?
          </OutboundLink>
        </div>
      }
    </>
  );
};

export default InterviewsTable;
