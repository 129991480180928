import { Fragment } from 'react';

import Token from '../inputs/EditorInput/Token';
import { ElementType } from '../../../types';

import type { EditorType, TokensResponse, Token as TokenType } from '../../../types';

export const TOKEN_REGEX_NON_GLOBAL = /{{\s*(.+?)\s*}}/;
export const TOKEN_REGEX_WITH_TRIGGER = /({{\s*.+?\s*}})/g;

interface Props {
  pendingPreviewMessage: string;
  tokens?: TokensResponse;
  type: `${EditorType}`;
  value: string;
}

const TokenizedString = ({ pendingPreviewMessage, tokens, type, value }: Props) => {
  return (
    <>
      {value.split(TOKEN_REGEX_WITH_TRIGGER).map((part, i) => {
        if (TOKEN_REGEX_WITH_TRIGGER.test(part)) {
        // While part.match could return null, it almost definitely won't
        // because of our .test right before this.
          const token = part.match(TOKEN_REGEX_NON_GLOBAL)?.[1];
          return (
            <Token
              element={{ token: token as TokenType, type: ElementType.Token, children: [{ text: '' }] }}
              key={i}
              pendingPreviewMessage={pendingPreviewMessage}
              tokens={tokens}
              type={type}
            />
          );
        }

        // All of this weirdness with the arrays is to make sure that leading
        // and trailing whitespace shows up correctly. Just having a string with
        // leading or trailing whitespace doesn't render it properly. Neither
        // doesn't replacing the spaces with &nbsp; within the string. So we get
        // the counts of leading and trailing whitespace using .search and use
        // that to iterate and render a JSX &nbsp; which does work.
        return (
          <Fragment key={i}>
            {Array.from({ length: part.search(/\S/) }).map((_, i) => {
              return <Fragment key={i}>&nbsp;</Fragment>;
            })}
            {part.replace(/^\s+/g, '').replace(/\s+$/g, '')}
            {Array.from({ length: part.split('').reverse().join('').search(/\S/) }).map((_, i) => {
              return <Fragment key={i}>&nbsp;</Fragment>;
            })}
          </Fragment>
        );
      })}
    </>
  );
};

export default TokenizedString;
