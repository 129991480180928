import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import Moment from 'moment';
import { Breadcrumb } from 'react-breadcrumbs';
import { Link, Redirect, Route, Switch, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import ArchiveButton from '../../../library/inputs/ArchiveButton';
import Button from '../../../library/inputs/Button';
import Flash from '../../../library/utils/Flash';
import InterviewTemplateDetails from './InterviewTemplateDetails';
import InterviewTemplateLinkedInterviews from './InterviewTemplateLinkedInterviews';
import Label from '../../../library/utils/Label';
import PageHeader from '../../../library/layout/PageHeader';
import StatusIndicator from '../../../library/utils/StatusIndicator';
import { FavoriteResourceType } from '../../../../types';
import { formatMoment, TimeFormat } from '../../../../libraries/time';
import { useInterviewTemplate, useUpdateInterviewTemplate } from '../../../../hooks/queries/interview-templates';
import { correctPath } from 'libraries/gem';

const InterviewTemplate = () => {
  const { id } = useParams<{ id: string }>();

  const {
    data: interviewTemplate,
    error: interviewTemplateError,
    isLoading: isInterviewTemplateLoading,
  } = useInterviewTemplate(id);

  const updateInterviewTemplateMutation = useUpdateInterviewTemplate();

  const isSmallScreen = useMediaQuery({ query: '(max-width: 1000px)' });

  const handleArchive = () => {
    updateInterviewTemplateMutation.reset();
    updateInterviewTemplateMutation.mutate({ id, payload: { archived: true } });
  };

  const handleUnarchive = () => {
    updateInterviewTemplateMutation.reset();
    updateInterviewTemplateMutation.mutate({ id, payload: { archived: false } });
  };

  if (isInterviewTemplateLoading) {
    return null;
  }

  if (interviewTemplateError) {
    return (
      <Breadcrumb
        data={{
          title: id,
          pathname: correctPath(`/app/interview-templates/${id}`),
        }}
      >
        <div className="interview-template-container">
          <Flash
            message={interviewTemplateError.message}
            showFlash
            type="danger"
          />
        </div>
      </Breadcrumb>
    );
  }

  if (!interviewTemplate) {
    return null;
  }

  return (
    <Breadcrumb
      data={{
        title: interviewTemplate.name || id,
        pathname: correctPath(`/app/interview-templates/${id}`),
      }}
    >
      <div className="interview-template-container">
        <Flash
          isDismissible
          message={updateInterviewTemplateMutation.error?.message}
          onDismiss={updateInterviewTemplateMutation.reset}
          showFlash={updateInterviewTemplateMutation.isError}
          type="danger"
        />
        <Flash
          isDismissible
          message={`Interview template ${interviewTemplate.archived ? 'archived' : 'unarchived'}.`}
          onDismiss={updateInterviewTemplateMutation.reset}
          showFlash={updateInterviewTemplateMutation.isSuccess}
          type="success"
        />
        <>
          <PageHeader
            details={
              <>
                <StatusIndicator color={interviewTemplate.linked_interviews ? 'green' : 'gray'}>
                  Used in <Link to={correctPath(`/app/interview-templates/${interviewTemplate.id}/linked-interviews`)}>{interviewTemplate.linked_interviews || 0} active interview{interviewTemplate.linked_interviews !== 1 && 's'}</Link>
                </StatusIndicator>
                {!isSmallScreen && <span className="separator">|</span>}
                {!isSmallScreen && <span>Created {formatMoment(Moment(interviewTemplate.created_at), TimeFormat.LongMonthDayYear)}</span>}
                {!isSmallScreen && interviewTemplate.created_at !== interviewTemplate.updated_at && <span className="separator">|</span>}
                {!isSmallScreen && interviewTemplate.created_at !== interviewTemplate.updated_at && <span>Updated {formatMoment(Moment(interviewTemplate.updated_at), TimeFormat.LongMonthDayYear)}</span>}
              </>
            }
            favorite={interviewTemplate.favorite}
            navbarLinks={[{
              label: 'Details',
              location: correctPath(`/app/interview-templates/${interviewTemplate.id}/details`),
            }, {
              label: 'Linked Interviews',
              location: correctPath(`/app/interview-templates/${interviewTemplate.id}/linked-interviews`),
            }]}
            resourceId={interviewTemplate.id}
            resourceType={FavoriteResourceType.InterviewTemplate}
            title={interviewTemplate.name}
            titleIcon={interviewTemplate.archived ? <Label color="gray">Archived</Label> : undefined}
          >
            <Link
              to={{
                pathname: correctPath('/app/interview-templates/create'),
                search: `?base=${interviewTemplate.id}`,
              }}
            >
              <Button
                color="gem-outline"
                iconLeft={<FontAwesomeIcon icon={faPlus} />}
                size="small"
                value="New template from base"
              />
            </Link>
            <ArchiveButton
              id={id}
              isArchived={interviewTemplate.archived}
              onArchive={handleArchive}
              onUnarchive={handleUnarchive}
            />
            {/* <DeleteButton id={id} /> */}
          </PageHeader>
          <Switch>
            <Redirect exact from={correctPath('/app/interview-templates/:id')} to={correctPath('/app/interview-templates/:id/details')} />
            <Route component={InterviewTemplateDetails} path={correctPath('/app/interview-templates/:id/details')} />
            <Route component={InterviewTemplateLinkedInterviews} path={correctPath('/app/interview-templates/:id/linked-interviews')} />
          </Switch>
        </>
      </div>
    </Breadcrumb>
  );
};

export default InterviewTemplate;
