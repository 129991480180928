import type { Account } from './accounts';
import type { EmailTemplate } from './email-templates';
import type { User } from './users';

export enum EmailStatus {
  Pending = 'pending',
  Succeeded = 'succeeded',
  Failed = 'failed',
}

export interface Email {
  id: string;
  ats_id?: string;
  account_id: string;
  account: Account;
  email_template_id: string;
  email_template: EmailTemplate;
  application_id?: string;
  stage_id: string;
  subject: string;
  sender_name: string;
  sender_email: string;
  recipient_name: string | null;
  recipient_email: string;
  cc_emails: string[];
  bcc_emails: string[];
  body: string;
  status: EmailStatus;
  sender_id: string;
  sender: User;
  sent_at?: string;
  created_at: string;
  updated_at: string;
}
