export interface InterviewTemplate {
  id: string;
  name: string;
  duration_minutes: number;
  /**
   * @deprecated
   */
  position?: number;
  positions?: number[];
  time_window_start?: string;
  time_window_end?: string;
  candidate_facing_name?: string;
  candidate_facing_details?: string;
  live_coding_enabled: boolean;
  linked_interviews: number;
  inline: boolean;
  archived: boolean;
  deleted: boolean;
  interviewer_templates: InterviewerTemplate[] | null;
  favorite: boolean;
  created_at: string;
  updated_at: string;
}

export interface InterviewerTemplate {
  id: string;
  interview_template_id: string;
  description?: string;
  optional: boolean;
  include_past_interviewers: boolean;
  interviewer_filters: InterviewerFilter[] | null;
  created_at: string;
  updated_at: string;
}

export interface InterviewerFilter {
  id: string;
  interviewer_template_id: string;
  position: number;
  interviewer_filter_expressions: InterviewerFilterExpression[] | null;
  created_at: string;
}

export enum InterviewerFilterableType {
  Eligibility = 'eligibility',
  HiringTeam = 'hiring_team',
  Tag = 'tag',
  Training = 'training',
  User = 'user',
}

export interface InterviewerFilterExpression {
  id: string;
  interviewer_filter_id: string;
  negated: boolean;
  filterable_id: string;
  filterable_type: InterviewerFilterableType;
  created_at: string;
}

export interface EditableInterviewTemplate {
  name: string;
  duration_minutes: number;
  position?: number;
  time_window_start?: string;
  time_window_end?: string;
  candidate_facing_name?: string;
  candidate_facing_details?: string;
  live_coding_enabled: boolean;
  interviewer_templates?: EditableInterviewerTemplate[] | null;
}

export interface EditableInterviewerTemplate {
  id?: string;
  description?: string;
  optional: boolean;
  include_past_interviewers: boolean;
  interviewer_filters?: EditableInterviewerFilter[] | null;
}

export interface EditableInterviewerFilter {
  interviewer_filter_expressions: EditableInterviewerFilterExpression[] | null;
}

export interface EditableInterviewerFilterExpression {
  negated: boolean;
  filterable_id: string;
  filterable_type: `${InterviewerFilterableType}`;
}
