import styled from 'styled-components';

import Card from 'components/library/layout/Card';

export const StyledCard = styled(Card)`
  margin-bottom: 2em;
  padding: 8px 2em;

  &:last-child {
    margin-bottom: 70px;
  }

  .card-header {
    border-bottom: 1px solid ${({ theme }) => theme.colors.PRODUCT.SECONDARY_BORDER};
    margin-bottom: 12px;

    .card-title > h3 {
      font-weight: 500;
    }
  }

  .card-content {
    color: inherit;

    p {
      font-size: inherit;
    }
  }
`;

export const StyledInterviewBatchInputContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

export const StyledInterviewDetailsBatchMessage = styled.div`
  color: ${({ theme }) => theme.colors.PRODUCT.SECONDARY_CONTENT};
  font-style: italic;
  margin-bottom: 1em;
`;
