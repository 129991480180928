import { Editor, Element, Node, Path, Point, Range, Transforms } from 'slate';
import { pick } from 'lodash';

import { ElementType, InlineStyleType } from '../../../../types';
import { isToken } from './plugins/tokens';

import type {
  CustomEditor,
  CustomElement,
  ImageElement,
  LinkElement,
  ListElement,
  ListElementType,
  ParagraphElement,
} from '../../../../types';
import type { BasePoint, NodeEntry } from 'slate';

const INCOMPLETE_TOKEN_REGEX = /{{\s*(.*)$/g;
const LIST_ELEMENT_TYPES: ElementType[] = [ElementType.OrderedList, ElementType.UnorderedList];

interface Dimension {
  width: number;
  height: number;
  error?: Error;
}

export const dimensionsForUrl = async (url: string): Promise<Dimension> => {
  const img = document.createElement('img');

  const dimensions = await new Promise<Dimension>((resolve) => {
    img.onload = () => {
      resolve({ width: img.width, height: img.height });
    };

    img.onerror = (event, source, lineno, colno, error) => {
      resolve({ error, width: 0, height: 0 });
    };

    img.style.visibility = 'hidden';
    img.style.position = 'fixed';
    img.style.bottom = '0px';
    img.style.left = '0px';
    img.style.height = 'auto';
    img.style.width = 'auto';

    document.body.appendChild(img);

    img.src = url;
  });

  if (img.parentElement) {
    img.parentElement.removeChild(img);
  }

  return dimensions;
};

export const getClosestElementOfType = <T extends CustomElement>(editor: CustomEditor, matcher: (element: CustomElement) => element is T): [T, Path] | [] => {
  let path = editor.selection?.focus.path;
  if (!path) {
    return [];
  }

  while (path.length > 0) {
    const node = Node.get(editor, path);
    const element = node as CustomElement;
    if (element && matcher(element)) {
      return [element, path];
    }
    path = Path.parent(path);
  }

  return [];
};

export const getCurrentListDepth = (editor: CustomEditor): number => {
  let depth = 0;
  let path = editor.selection?.focus.path;
  if (!path) {
    return 0;
  }

  while (path.length > 0) {
    const node = Node.get(editor, path);
    if (Element.isElement(node) && LIST_ELEMENT_TYPES.includes(node.type)) {
      depth++;
    }
    path = Path.parent(path);
  }

  return depth;
};

export const getCurrentListElementType = (editor: CustomEditor): ListElementType | undefined => {
  const elementType = LIST_ELEMENT_TYPES.find((elementType) => isElementTypeActive(editor, elementType));
  if (elementType) {
    return elementType as ListElementType;
  }
};

interface IncompleteToken {
  range: {
    anchor: Point;
    focus: Point;
  } | null;
  text: string | null;
}

export const getIncompleteToken = (editor: CustomEditor): IncompleteToken => {
  if (!editor.selection) {
    return { range: null, text: null };
  }

  // We get info about the beginning of the line so that we can ensure that we
  // only stay within the current line.
  const nodeEntry = getLineNodeEntry(editor);
  if (!nodeEntry) {
    return { range: null, text: null };
  }
  const path = nodeEntry[1];
  const beginningOfLine = { path, offset: 0 };
  // We start at the selection and go back character by character until we
  // find an incomplete token.
  const edges = Range.edges(editor.selection);
  let start: BasePoint | undefined = edges[0];
  const end = edges[1];
  let selectedIncompleteToken = false;
  let text = '';
  while (!selectedIncompleteToken) {
    start = Editor.before(editor, start, {
      distance: 1,
      unit: 'character',
    });
    if (!start || Point.isBefore(start, beginningOfLine)) {
      // We've breached the previous line, so we should just bail out now.
      return { range: null, text: null };
    }
    text = Editor.string(editor, { anchor: start, focus: end });
    selectedIncompleteToken = INCOMPLETE_TOKEN_REGEX.test(text);
  }

  return {
    range: {
      anchor: start,
      focus: end,
    },
    text,
  };
};

export const getLineNodeEntry = (editor: CustomEditor): NodeEntry | undefined => {
  // Get the closest block element (which essentially maps to a line in the
  // editor) so that we can just get the text from the beginning of the line
  // to the cursor.
  const nodes = Editor.nodes(editor, {
    match: (node) => !Editor.isEditor(node) && Element.isElement(node),
  });

  let lastEntry;
  for (const entry of nodes) {
    lastEntry = entry;
  }

  return lastEntry;
};

export const indentList = (editor: CustomEditor): void => {
  if (!isList(editor)) {
    return;
  }
  const elementType = getCurrentListElementType(editor);
  if (!elementType) {
    return;
  }

  const block: ListElement = { type: elementType, children: [] };
  Transforms.wrapNodes(editor, block);
};

export const insertImage = (editor: CustomEditor, props: Omit<ImageElement, 'type' | 'children'>): void => {
  const text = { text: '' };
  const image: ImageElement = { type: ElementType.Image, children: [text], ...props };
  Transforms.insertNodes(editor, image);
};

export const isElementTypeActive = (editor: CustomEditor, elementType: ElementType): boolean => {
  // When changing the contents of the editor quickly (e.g. adding a bunch of
  // content and then clicking the Cancel button), the selection might be out of
  // bounds, so we wrap this in a try/catch just to be safe.
  try {
    const [match] = Editor.nodes(editor, {
      match: (node) => !Editor.isEditor(node) && Element.isElement(node) && node.type === elementType,
    });

    return !!match;
  } catch (_) {
    return false;
  }
};

export const isList = (editor: CustomEditor): boolean => {
  return LIST_ELEMENT_TYPES.some((elementType) => isElementTypeActive(editor, elementType));
};

export const isMarkActive = (editor: CustomEditor, inlineStyle: InlineStyleType): boolean => {
  // When changing the contents of the editor quickly (e.g. adding a bunch of
  // content and then clicking the Cancel button), the selection might be out of
  // bounds, so we wrap this in a try/catch just to be safe.
  try {
    let marks = Editor.marks(editor);

    // Since marks are only checked on text nodes, we need to take care of
    // checking for marks on token nodes.
    const [token] = getClosestElementOfType(editor, isToken);
    if (token) {
      marks = {
        ...marks,
        ...pick(token, [InlineStyleType.Bold, InlineStyleType.Italic, InlineStyleType.Underline]),
      };
    }

    return marks ? marks[inlineStyle] === true : false;
  } catch (_) {
    return false;
  }
};

export const outdentList = (editor: CustomEditor): void => {
  if (!isList(editor)) {
    return;
  }

  const depth = getCurrentListDepth(editor);

  Transforms.unwrapNodes(editor, {
    match: (node) => !Editor.isEditor(node) && Element.isElement(node) && LIST_ELEMENT_TYPES.includes(node.type),
    split: true,
  });

  if (depth === 1) {
    const node: ParagraphElement = { type: ElementType.Paragraph, children: [] };
    Transforms.setNodes(editor, node);
  }
};

export const toggleBlock = (editor: CustomEditor, elementType: ElementType): void => {
  const isActive = isElementTypeActive(editor, elementType);
  const isList = LIST_ELEMENT_TYPES.includes(elementType);

  // If they toggle a list, we want to unwrap it for every level, but if it's
  // not a list, then we just want to do it once.
  const depth = isList ? getCurrentListDepth(editor) : 1;
  for (let i = 0; i < depth; i++) {
    Transforms.unwrapNodes(editor, {
      match: (node) => !Editor.isEditor(node) && Element.isElement(node) && LIST_ELEMENT_TYPES.includes(node.type),
      split: true,
    });
  }

  Transforms.setNodes<Node>(editor, {
    type: isActive ? ElementType.Paragraph : isList ? ElementType.ListItem : elementType,
  });

  if (!isActive && isList) {
    Transforms.wrapNodes(editor, { type: elementType, children: [] } as CustomElement);
  }
};

export const toggleMark = (editor: CustomEditor, inlineStyleType: InlineStyleType): void => {
  const isActive = isMarkActive(editor, inlineStyleType);

  if (isActive) {
    Editor.removeMark(editor, inlineStyleType);
  } else {
    Editor.addMark(editor, inlineStyleType, true);
  }
};

export const unwrapLink = (editor: CustomEditor): void => {
  Transforms.unwrapNodes(editor, {
    match: (node) => !Editor.isEditor(node) && Element.isElement(node) && node.type === ElementType.Link,
  });
};

export const wrapLink = (editor: CustomEditor, text: string, url?: string): void => {
  if (!url) {
    // The text is a URL.
    url = text;
  }

  if (isElementTypeActive(editor, ElementType.Link)) {
    unwrapLink(editor);
  }

  const { selection } = editor;
  const isCollapsed = selection && Range.isCollapsed(selection);
  const link: LinkElement = {
    type: ElementType.Link,
    url,
    children: isCollapsed ? [{ text }] : [],
  };

  if (isCollapsed) {
    Transforms.insertNodes(editor, link);
  } else {
    Transforms.wrapNodes(editor, link, { split: true });
    Transforms.collapse(editor, { edge: 'end' });
  }
};
