import { Link, useHistory, useRouteMatch } from 'react-router-dom';
import { maxBy, some } from 'lodash';
import { useCallback, useMemo, useState } from 'react';

import EmailCandidateModal from 'components/library/data-display/EmailCandidateModal';
import SplitButton from 'components/library/inputs/SplitButton';
import Tooltip from 'components/library/utils/Tooltip';
import UseHeldScheduleModal from 'components/library/data-display/UseHeldScheduleModal';
import { ApplicationSchedulingStatus, SchedulingWorkflow } from '../../../types';
import { ButtonColor } from 'components/library/inputs/Button';
import { useLDFlags } from 'hooks/use-ld-flags';
import { useSession } from 'hooks/use-session';
import { useUpdateStage } from 'hooks/queries/stages';

import type { Application } from '../../../types';
import type { ButtonSize } from '../../library/inputs/Button';
import type { Dispatch, ReactNode, SetStateAction } from 'react';
import { correctPath } from 'libraries/gem';

interface Props {
  application: Application;
  setErrorFlashMessage: Dispatch<SetStateAction<ReactNode>>;
  setSuccessFlashMessage: Dispatch<SetStateAction<ReactNode>>;
  size: `${ButtonSize}`;
}

const CandidateActionButton = ({ application, setErrorFlashMessage, setSuccessFlashMessage, size }: Props) => {
  const history = useHistory();

  const { hiringMeetings } = useLDFlags();

  const { account, currentUser } = useSession();

  const schedulePageMatch = useRouteMatch<{ id: string; scheduleId: string }>({
    path: correctPath('/app/candidates/:id/schedules/:scheduleId'),
  });

  const updateStageMutation = useUpdateStage();

  const [emailCandidateModalIsOpen, setEmailCandidateModalIsOpen] = useState(false);
  const [heldScheduleModalIsOpen, setHeldScheduleModalIsOpen] = useState(false);

  const toggleEmailCandidateOpen = useCallback(() => setEmailCandidateModalIsOpen((prev) => !prev), [setEmailCandidateModalIsOpen]);
  const toggleHeldScheduleOpen = useCallback(() => setHeldScheduleModalIsOpen((prev) => !prev), [setHeldScheduleModalIsOpen]);

  const hasHeldSchedules = application?.held_schedules && application.held_schedules.length > 0;
  const canSendEmail = Boolean(account?.email_domain || currentUser?.gem_can_send_email);

  // Rendering the EmailCandidateModal, even when it's closed, is expensive and
  // slows down the candidate list page, so instead of always rendering it, only
  // render it when it's open.
  const emailCandidateModal = useMemo(() => (
    emailCandidateModalIsOpen ?
      <EmailCandidateModal
        applicationId={application.id}
        isOpen={emailCandidateModalIsOpen}
        onToggle={() => toggleEmailCandidateOpen()}
      /> :
      null
  ), [application.id, emailCandidateModalIsOpen, toggleEmailCandidateOpen]);

  const heldScheduleModal = useMemo(() => (
    hasHeldSchedules ?
      <UseHeldScheduleModal
        application={application}
        isOpen={heldScheduleModalIsOpen}
        onToggle={() => toggleHeldScheduleOpen()}
      /> :
      null
  ), [application.id, hasHeldSchedules, heldScheduleModalIsOpen, toggleHeldScheduleOpen]);

  const buttons = useMemo(() => {
    const availabilityToEdit = (
      application?.active_availabilities ?
        maxBy(application.active_availabilities.filter(({ ats_id }) => !Boolean(ats_id)), 'created_at') :
        null
    );

    return {
      requestAvailability: {
        value: 'Request Availability',
        linkTo: correctPath(`/app/candidates/${application.id}/request-availability`),
        color: ButtonColor.GemBlue,
      },
      submitAvailability: {
        value: 'Submit Availability',
        linkTo: correctPath(`/app/candidates/${application.id}/submit-availability`),
        color: ButtonColor.GemOutline,
      },
      editAvailability: {
        value: 'Edit Availability',
        linkTo: correctPath(`/app/candidates/${application.id}/availabilities/${availabilityToEdit ? availabilityToEdit.id : ''}`),
        color: ButtonColor.GemOutline,
      },
      followUp: {
        value: 'Follow Up',
        onClick: toggleEmailCandidateOpen,
        isDisabled: !canSendEmail,
        tooltip: canSendEmail ? undefined : (
          <Tooltip
            id={`${application.id}-email-disabled-tooltip`}
            value="Sending emails is disabled until Gem is granted email access."
          />
        ),
      },
      selfSchedule: {
        value: 'Self-Schedule',
        linkTo: correctPath(`/app/candidates/${application.id}/self-schedule`),
        color: ButtonColor.GemBlue,
      },
      schedule: {
        value: 'Schedule',
        linkTo: !hasHeldSchedules ? correctPath(`/app/candidates/${application.id}/schedule`) : undefined,
        onClick: hasHeldSchedules ? toggleHeldScheduleOpen : undefined,
        color: ButtonColor.GemBlue,
      },
      scheduleHiringMeeting: hiringMeetings ? {
        value: 'Hiring Meeting',
        linkTo: correctPath(`/app/candidates/${application.id}/schedule-hiring-meeting`),
        color: ButtonColor.GemOutline,
      } : undefined,
      editSchedule: {
        value: 'Edit Schedule',
        onClick: () => {
          // TODO: This should probably be done with a #edit URL fragment, and
          // the schedule page listens for that fragment and scrolls down
          // accordingly.
          if (!schedulePageMatch || !(application.active_schedules || []).map(({ id }) => id).includes(schedulePageMatch.params.scheduleId)) {
            history.push(correctPath(`/app/candidates/${application.id}/schedules`));
          }
          // Check every 100 ms if the page has rendered and there is a schedule section.
          // If the query is cached, the schedule section will render immediately.
          // Otherwise, the schedule section will render when the query completes.
          const scrollInterval = setInterval(() => {
            const scheduleSection = document.querySelector('.schedule-section');
            if (scheduleSection !== null) {
              scheduleSection.scrollIntoView({ behavior: 'smooth' });
              // TODO: Figure out how to wait for the scroll to finish before the button is clicked
              // scheduleSection.querySelector('.section-header-actions > button')?.click();
              clearInterval(scrollInterval);
            }
          }, 100);
        },
        color: ButtonColor.GemOutline,
      },
      emailCandidate: {
        value: 'Email Candidate',
        onClick: toggleEmailCandidateOpen,
        color: ButtonColor.GemOutline,
        isDisabled: !canSendEmail,
        tooltip: canSendEmail ? undefined : (
          <Tooltip
            id={`${application.id}-email-disabled-tooltip`}
            value="Sending emails is disabled until Gem is granted email access."
          />
        ),
      },
      enableStageForScheduling: {
        value: 'Enable Stage for Scheduling',
        onClick: async () => {
          if (!application.current_stage_id) {
            // This shouldn't ever happen since an application only wouldn't have a current stage if they are inactive
            // (i.e. hired or rejected), but this is just in case.
            return;
          }

          updateStageMutation.reset();
          setErrorFlashMessage(null);
          setSuccessFlashMessage(null);

          try {
            await updateStageMutation.mutateAsync({
              id: application.current_stage_id,
              jobId: application.job_id,
              payload: {
                schedulable: true,
                default_workflow: SchedulingWorkflow.ScheduleNow,
              },
            });
            setSuccessFlashMessage(<>Successfully enabled! You can now schedule this candidate. You can also configure default scheduling preferences on the stage <Link to={correctPath(`/app/jobs/${application.job_id}/stages/${application.current_stage_id}`)}>here</Link>.</>);
            analytics.track('Stage Enabled For Scheduling From Action Button', { stage_id: application.current_stage_id, application_id: application.id });
          } catch (err) {
            if (err instanceof Error) {
              setErrorFlashMessage(err.message);
            }
          }
        },
        color: ButtonColor.GemOutline,
      },
    };
  }, [application.id, application?.active_availabilities, application?.active_schedules, hasHeldSchedules, schedulePageMatch]);

  const scheduleButtons = useMemo(() => {
    const defaultWorkflow = application.current_stage?.schedule_template?.default_workflow || 'schedule_now';
    return (
      defaultWorkflow === 'self_schedule' ?
        [buttons.selfSchedule, buttons.schedule] :
        [buttons.schedule, buttons.selfSchedule]
    );
  }, [application.current_stage?.schedule_template?.default_workflow, buttons]);

  const buttonId = `action-button-${application.id}`;

  if (application.scheduling_status === 'ready_to_request_availability') {
    return (
      <>
        <SplitButton
          buttons={[
            buttons.requestAvailability,
            buttons.submitAvailability,
            ...scheduleButtons,
            buttons.emailCandidate,
            buttons.scheduleHiringMeeting,
          ]}
          color="gem-blue"
          id={buttonId}
          size={size}
        />
        {emailCandidateModal}
        {heldScheduleModal}
      </>
    );
  }

  if (application.scheduling_status === 'availability_requested') {
    return (
      <>
        <SplitButton
          buttons={[
            buttons.followUp,
            buttons.editAvailability,
            ...scheduleButtons,
            buttons.scheduleHiringMeeting,
          ]}
          color="gem-outline"
          id={buttonId}
          size={size}
        />
        {emailCandidateModal}
        {heldScheduleModal}
      </>
    );
  }

  const isRequestAvailabilityEnabled = Boolean(application?.current_stage?.availability_template_id);
  const hasActiveAvailabilityLink = some(application.active_availabilities, ({ manual, ats_id }) => !manual && !Boolean(ats_id));
  const hasInterviewPlannerManagedAvailability = some(application.active_availabilities, ({ manual, ats_id }) => manual || !Boolean(ats_id));

  if (application.scheduling_status === 'scheduling_link_sent') {
    return (
      <>
        <SplitButton
          buttons={[
            buttons.followUp,
            ...scheduleButtons,
            buttons.scheduleHiringMeeting,
          ]}
          color="gem-outline"
          id={buttonId}
          size={size}
        />
        {emailCandidateModal}
        {heldScheduleModal}
      </>
    );
  }

  if (application.scheduling_status === 'ready_to_schedule' || application.scheduling_status === 'cancelled') {
    return (
      <>
        <SplitButton
          buttons={[
            ...scheduleButtons,
            hasInterviewPlannerManagedAvailability ? buttons.editAvailability : null,
            !hasActiveAvailabilityLink && isRequestAvailabilityEnabled ? buttons.requestAvailability : null,
            !hasInterviewPlannerManagedAvailability ? buttons.submitAvailability : null,
            buttons.emailCandidate,
            buttons.scheduleHiringMeeting,
          ]}
          color="gem-blue"
          id={buttonId}
          size={size}
        />
        {emailCandidateModal}
        {heldScheduleModal}
      </>
    );
  }

  if (application.scheduling_status === 'scheduled') {
    return (
      <>
        <SplitButton
          buttons={[
            buttons.editSchedule,
            hasInterviewPlannerManagedAvailability ? buttons.editAvailability : null,
            !hasActiveAvailabilityLink && isRequestAvailabilityEnabled ? buttons.requestAvailability : null,
            !hasInterviewPlannerManagedAvailability ? buttons.submitAvailability : null,
            ...scheduleButtons,
            buttons.emailCandidate,
            buttons.scheduleHiringMeeting,
          ]}
          color="gem-outline"
          id={buttonId}
          size={size}
        />
        {emailCandidateModal}
        {heldScheduleModal}
      </>
    );
  }

  if (application.scheduling_status === ApplicationSchedulingStatus.Unschedulable && application.current_stage_id) {
    return (
      <SplitButton
        buttons={[
          buttons.enableStageForScheduling,
        ]}
        color="gem-outline"
        id={buttonId}
        size={size}
      />
    );
  }

  return null;
};

export default CandidateActionButton;
