import { useState } from 'react';

import { StyledInterviewContainer } from './styles';

import type { StageInterview } from 'types';

interface Props {
  stageInterview: StageInterview;
}

const InterviewDetails = ({ stageInterview }: Props) => {
  const [isExpanded] = useState(true);

  return (
    <StyledInterviewContainer expanded={isExpanded}>
      <h3>{stageInterview.name}</h3>
    </StyledInterviewContainer>
  );
};

export default InterviewDetails;
