import Moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendarTimes } from '@fortawesome/free-solid-svg-icons';
import { groupBy, keyBy, mapValues, orderBy, partition, pick, sum } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import Button from '../../library/inputs/Button';
import CheckboxInput from '../../library/inputs/CheckboxInput';
import Flash from '../../library/utils/Flash';
import Modal from '../../library/layout/Modal';
import Tooltip from '../../library/utils/Tooltip';
import pluralize from '../../../libraries/pluralize';
import { HiringMeetingStatus } from '../../../types';
import { formatMoment, TimeFormat } from '../../../libraries/time';
import { useDeleteHiringMeeting } from '../../../hooks/queries/hiring-meetings';
import { useDeleteSchedule } from '../../../hooks/queries/schedules';
import { useSession } from '../../../hooks/use-session';

import type { Application } from '../../../types';
import type { ReactNode, ChangeEvent } from 'react';

enum MeetingType {
  HiringMeeting = 'hiring_meeting',
  Schedule = 'schedule',
}

interface MeetingCheckbox {
  id: string;
  label: ReactNode;
  type: MeetingType;
}

interface Props {
  application: Application;
  onSuccess: (scheduleIds: string[], hiringMeetingIds: string[]) => void;
}

const CancelMeetingButton = ({ application, onSuccess }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState('');

  const { account } = useSession();

  const deleteScheduleMutation = useDeleteSchedule();
  const deleteHiringMeetingMutation = useDeleteHiringMeeting();

  const schedules = useMemo(() => orderBy([
    ...(application.active_schedules || []),
    ...(application.held_schedules || []),
  ].filter(({ imported_from_ats }) => !imported_from_ats), ['created_at']), [application.active_schedules, application.held_schedules]);

  const hiringMeetings = useMemo(() => orderBy(
    (application.all_hiring_meetings || [])
    .filter(({ status }) => status === HiringMeetingStatus.Confirmed || status === HiringMeetingStatus.Confirming), ['created_at']
  ), [application.current_stage_id, application.all_hiring_meetings]);

  const meetingCheckboxes = useMemo<MeetingCheckbox[]>(() => {
    const checkboxes: MeetingCheckbox[] = [];
    const [heldSchedules, confirmedSchedules] = partition(schedules, 'hold');
    const [heldMultiBlockSchedules, heldSingleBlockSchedules] = partition(heldSchedules, 'block_id');

    confirmedSchedules.forEach((schedule) => {
      checkboxes.push({
        label: <span>Cancel {schedule.interviews.length} {pluralize('interview', schedule.interviews.length)} scheduled for <b>{formatMoment(Moment.tz(schedule.interviews[0].start_time, schedule.timezone), TimeFormat.LongDayOfWeekMonthDayAtTimeAndTimezone)}</b></span>,
        id: schedule.id,
        type: MeetingType.Schedule,
      });
    });

    heldSingleBlockSchedules.forEach((schedule) => {
      checkboxes.push({
        label: <span>Cancel {schedule.interviews.length} held {pluralize('interview', schedule.interviews.length)} scheduled for <b>{formatMoment(Moment.tz(schedule.interviews[0].start_time, schedule.timezone), TimeFormat.LongDayOfWeekMonthDayAtTimeAndTimezone)}</b></span>,
        id: schedule.id,
        type: MeetingType.Schedule,
      });
    });

    const heldSchedulesByBlockId = groupBy(heldMultiBlockSchedules, 'block_id');
    Object.entries(heldSchedulesByBlockId).forEach(([, schedules]) => {
      const totalNumberOfInterviews = sum(schedules.map(({ interviews }) => interviews.length));
      checkboxes.push({
        label: (
          <span>
            Cancel {totalNumberOfInterviews} held {pluralize('interview', totalNumberOfInterviews)} scheduled in multiple blocks:
            <ul>
              {schedules.map(({ id, interviews, timezone }) => (
                <li key={id}>
                  {interviews.length} {pluralize('interview', interviews.length)} on <b>{formatMoment(Moment.tz(interviews[0].start_time, timezone), TimeFormat.LongDayOfWeekMonthDayAtTimeAndTimezone)}</b>
                </li>
              ))}
            </ul>
          </span>
        ),
        id: schedules[0].id,
        type: MeetingType.Schedule,
      });
    });

    hiringMeetings.forEach((hiringMeeting) => {
      checkboxes.push({
        label: <span>Cancel hiring meeting scheduled for <b>{formatMoment(Moment.tz(hiringMeeting.start_time, hiringMeeting.timezone), TimeFormat.LongDayOfWeekMonthDayAtTimeAndTimezone)}</b></span>,
        id: hiringMeeting.id,
        type: MeetingType.HiringMeeting,
      });
    });

    return checkboxes;
  }, [schedules, hiringMeetings]);

  const [meetingsToCancel, setMeetingsToCancel] = useState<Record<string, boolean>>(() => {
    // If there is more than one schedule that can be cancelled, have none of
    // them checked from the beginning. If there is only one, then check it. But
    // things get tricky with held multi-block schedules since those are
    // cancelled together. So if every schedule is a held schedule with the same
    // block ID, then it's the same as "a single schedule", so we set it to true
    // for all of them.
    const defaultValue = meetingCheckboxes.length === 1 ||
      (hiringMeetings.length === 0 && schedules.every(({ hold, block_id }) => hold && block_id === schedules[0].block_id));

    return [...schedules, ...hiringMeetings].reduce((acc, meeting) => ({
      ...acc,
      [meeting.id]: defaultValue,
    }), {});
  });

  const schedulesById = useMemo(() => keyBy(schedules, 'id'), [schedules]);
  const hiringMeetingsById = useMemo(() => keyBy(hiringMeetings, 'id'), [hiringMeetings]);

  const [
    areAllSelectedMeetingsHeld,
    _areSomeSelectedMeetingsHeld,
    areAllSelectedMeetingsHiringMeetings,
    _areSomeSelectedMeetingsHiringMeetings,
    areAllAvailableMeetingsHeld,
    areSomeAvailableMeetingsHeld,
    areAllAvailableMeetingsSchedules,
    _areSomeAvailableMeetingsSchedules,
    areAllAvailableMeetingsHiringMeetings,
    _areSomeAvailableMeetingsHiringMeetings,
  ] = useMemo(() => {
    const allMeetingIds = Object.keys(meetingsToCancel);
    const selectedMeetingIds = allMeetingIds.filter((id) => meetingsToCancel[id]);
    return [
      selectedMeetingIds.length > 0 && selectedMeetingIds.every((id) => schedulesById[id]?.hold),
      selectedMeetingIds.length > 0 && selectedMeetingIds.some((id) => schedulesById[id]?.hold),
      selectedMeetingIds.length > 0 && selectedMeetingIds.every((id) => hiringMeetingsById[id]),
      selectedMeetingIds.length > 0 && selectedMeetingIds.some((id) => hiringMeetingsById[id]),
      allMeetingIds.every((id) => schedulesById[id]?.hold),
      allMeetingIds.some((id) => schedulesById[id]?.hold),
      meetingCheckboxes.every(({ type }) => type === MeetingType.Schedule),
      meetingCheckboxes.some(({ type }) => type === MeetingType.Schedule),
      meetingCheckboxes.every(({ type }) => type === MeetingType.HiringMeeting),
      meetingCheckboxes.some(({ type }) => type === MeetingType.HiringMeeting),
    ];
  }, [schedulesById, meetingsToCancel, meetingCheckboxes]);

  const [deleteScheduleOptions, setDeleteScheduleOptions] = useState({
    send_interviewer_notifications: !areAllSelectedMeetingsHeld,
    send_candidate_notifications: true,
  });

  useEffect(() => {
    // If the schedules or hiring meetings change, we need to update meetingsToCancel. If we now only have one meeting
    // (or if there are only schedules and all of them are held and have the same block ID), set it to be cancelled
    // (since we don't show the checkboxes for that case). If it's not that case, we need to remove any IDs for
    // schedules that are no longer valid (maybe because they were already cancelled). By doing it this way with pick,
    // it preserves any existing toggles in meetingsToCancel.
    const shouldSet = schedules.length + hiringMeetings.length === 1 ||
      (hiringMeetings.length === 0 && schedules.every(({ hold, block_id }) => hold && block_id === schedules[0].block_id));

    if (shouldSet) {
      setMeetingsToCancel([...schedules, ...hiringMeetings].reduce((acc, meeting) => ({
        ...acc,
        [meeting.id]: true,
      }), {}));
    } else {
      setMeetingsToCancel((prev) => pick(prev, [...schedules, ...hiringMeetings].map(({ id }) => id)));
    }
  }, [schedules, hiringMeetings]);

  useEffect(() => {
    if (areAllSelectedMeetingsHeld) {
      setDeleteScheduleOptions(() => ({
        send_candidate_notifications: false,
        send_interviewer_notifications: false,
      }));
    }
  }, [areAllSelectedMeetingsHeld]);

  const handleDeleteScheduleOptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDeleteScheduleOptions((prevOptions) => ({
      ...prevOptions,
      [e.target.name]: e.target.checked,
    }));
  };

  const toggleModal = () => {
    if (error) {
      setError('');
    }
    setShowModal(!showModal);
  };

  const handleCancelMeetingChange = (e: ChangeEvent<HTMLInputElement>) => {
    const [id, type] = e.target.name.split(':');

    if (type === MeetingType.Schedule) {
      // For held multi-block schedules, update meetingsToCancel with other schedules in block
      const schedule = schedulesById[id];
      if (schedule?.hold && Boolean(schedule?.block_id)) {
        const associatedScheduleBlocks = schedules.filter(({ block_id }) => block_id === schedule.block_id);
        setMeetingsToCancel((prev) => ({
          ...prev,
          ...mapValues(keyBy(associatedScheduleBlocks, 'id'), () => e.target.checked),
        }));
      } else {
        setMeetingsToCancel((prev) => ({
          ...prev,
          [id]: e.target.checked,
        }));
      }
    } else {
      setMeetingsToCancel((prev) => ({
        ...prev,
        [id]: e.target.checked,
      }));
    }
  };

  const handleCancelMeetings = async () => {
    deleteScheduleMutation.reset();
    deleteHiringMeetingMutation.reset();

    const scheduleIds = schedules.map(({ id }) => id).filter((id) => meetingsToCancel[id]);
    const hiringMeetingIds = hiringMeetings.map(({ id }) => id).filter((id) => meetingsToCancel[id]);

    const responses = await Promise.all([
      ...scheduleIds.map(async (scheduleId) => {
        try {
          await deleteScheduleMutation.mutateAsync({ id: scheduleId, query: deleteScheduleOptions });
        } catch (error) {
          // I don't know a better way to surface all potential errors.
          if (error instanceof Error) {
            return error;
          }
        }
      }),
      ...hiringMeetingIds.map(async (hiringMeetingId) => {
        try {
          await deleteHiringMeetingMutation.mutateAsync({ id: hiringMeetingId, send_notifications: deleteScheduleOptions.send_interviewer_notifications });
        } catch (error) {
          // I don't know a better way to surface all potential errors.
          if (error instanceof Error) {
            return error;
          }
        }
      }),
    ]);

    const errors = responses.filter((e): e is Error => Boolean(e));
    if (errors.length > 0) {
      setError(errors.map(({ message }) => message).join(', '));
    } else {
      onSuccess(scheduleIds, hiringMeetingIds);
      setShowModal(false);
    }
  };

  if (schedules.length + hiringMeetings.length === 0) {
    return null;
  }

  return (
    <>
      <Button
        className="btn-delete"
        color="gray"
        iconRight={<FontAwesomeIcon icon={faCalendarTimes} />}
        onClick={toggleModal}
        size="large"
        tooltip={
          <Tooltip
            id={`${application.id}-cancel-meeting-button`}
            position="top"
            value={`Cancel ${areAllAvailableMeetingsSchedules ? 'schedule' : 'meeting'}${meetingCheckboxes.length === 1 ? '' : 's'}`}
          />
        }
      />
      <Modal
        cancelButtonValue={`No, keep ${meetingCheckboxes.length === 1 ? `this${areAllAvailableMeetingsSchedules && schedules[0].hold ? ' held' : ''} ${areAllAvailableMeetingsSchedules ? 'schedule' : 'meeting'}` : `these${areAllAvailableMeetingsSchedules && areAllAvailableMeetingsHeld ? ' held' : ''} ${areAllAvailableMeetingsSchedules ? 'schedules' : 'meetings'}`}`}
        isOpen={showModal}
        isSubmitting={deleteScheduleMutation.isLoading || deleteHiringMeetingMutation.isLoading}
        onSubmit={handleCancelMeetings}
        onToggle={toggleModal}
        showSubmitButton={Object.values(meetingsToCancel).filter(Boolean).length > 0}
        submitButtonValue={`Yes, cancel ${meetingCheckboxes.length === 1 ? 'all' : 'these'} events`}
        submittingButtonValue="Cancelling..."
        title={`Cancel${areAllAvailableMeetingsSchedules && areAllAvailableMeetingsHeld ? ' held' : ''} ${areAllAvailableMeetingsSchedules ? 'schedule' : 'meeting'}${meetingCheckboxes.length === 1 ? '' : 's'}?`}
      >
        <Flash
          message={error}
          showFlash={Boolean(error)}
          type="danger"
        />
        {meetingCheckboxes.length === 1 ?
          (areAllAvailableMeetingsSchedules ? (
            <p>
              You are cancelling{schedules[0].hold ? ' a held' : ''} <b>{schedules[0].stage.name}</b> for <b>{application.candidate.name}</b>, scheduled {schedules[0].hold && schedules[0].block_id ? (
                <>in multiple blocks:
                  <ul>
                    {schedules.filter(({ block_id }) => block_id === schedules[0].block_id).map((schedule) => (
                      <li key={schedule.id}>
                        <b>{formatMoment(Moment.tz(schedule.interviews[0].start_time, schedule.timezone), TimeFormat.LongDayOfWeekMonthDayAtTimeAndTimezone)}</b>
                      </li>
                    ))}
                  </ul>
                </>
              ) : <>for <b>{formatMoment(Moment.tz(schedules[0].interviews[0].start_time, schedules[0].timezone), TimeFormat.LongDayOfWeekMonthDayAtTimeAndTimezone)}</b>.</>}
            </p>
          ) : (
            <p>
              You are cancelling a hiring meeting for <b>{hiringMeetings[0].stage.name}</b> for <b>{application.candidate.name}</b>, scheduled for <b>{formatMoment(Moment.tz(hiringMeetings[0].start_time, hiringMeetings[0].timezone), TimeFormat.LongDayOfWeekMonthDayAtTimeAndTimezone)}</b>.
            </p>
          )) :
          <p>
            You are cancelling {areAllAvailableMeetingsSchedules ? 'interview' : 'meeting'}{meetingCheckboxes.length === 1 ? '' : 's'} for <b>{[...schedules, ...hiringMeetings].every((meeting) => meeting.stage.id === (schedules[0] || hiringMeetings[0]).stage.id) ? (schedules[0] || hiringMeetings[0]).stage.name : 'multiple stages'}</b> for <b>{application.candidate.name}</b>.
          </p>
        }
        {meetingCheckboxes.length > 1 &&
          <div className="modal-checkbox-options">
            {meetingCheckboxes.map(({ label, id, type }) => (
              <CheckboxInput
                isChecked={meetingsToCancel[id]}
                key={id}
                label={label}
                name={`${id}:${type}`}
                onChange={handleCancelMeetingChange}
              />
            ))}
          </div>
        }
        {/* Microsoft 365 doesn't allow customizing whether to send email notifications or not. It will always send notifications. */}
        {account?.directory_type !== 'microsoft365' ? (
          <div className="modal-checkbox-options">
            {!areAllAvailableMeetingsHeld && !areAllAvailableMeetingsHiringMeetings && (
              <CheckboxInput
                isChecked={areAllSelectedMeetingsHeld || areAllSelectedMeetingsHiringMeetings ? false : deleteScheduleOptions.send_candidate_notifications}
                isDisabled={areAllSelectedMeetingsHeld || areAllSelectedMeetingsHiringMeetings}
                label={`Send an email notification to the candidate.${areSomeAvailableMeetingsHeld ? ' This only applies for non-held schedules.' : ''}`}
                name="send_candidate_notifications"
                onChange={handleDeleteScheduleOptionChange}
              />
            )}
            <CheckboxInput
              isChecked={deleteScheduleOptions.send_interviewer_notifications}
              label="Send an email notification to interviewers."
              name="send_interviewer_notifications"
              onChange={handleDeleteScheduleOptionChange}
            />
          </div>
        ) : null}
        <p>
          All calendar events will be deleted. You can reschedule {meetingCheckboxes.length === 1 ? `this ${areAllAvailableMeetingsSchedules ? 'interview' : 'meeting'}` : `these ${areAllAvailableMeetingsSchedules ? 'interviews' : 'meetings'}`} later if you wish.
        </p>
      </Modal>
    </>
  );
};

export default CancelMeetingButton;
