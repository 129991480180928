import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize } from 'lodash';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import EditorInput from 'components/library/inputs/EditorInput';
import Flash from 'components/library/utils/Flash';
import Section from 'components/library/layout/Section';
import { slateValueToHtml } from 'libraries/editor/slate-value-to-html';
import Table from 'components/library/data-display/Table';
import { useSession } from 'hooks/use-session';
import { useSlateEditor } from 'hooks/use-slate-editor';
import { useUpdateUser, useUser } from 'hooks/queries/users';

import type { TableSchema } from 'components/library/data-display/Table';
import type { User } from 'types';

const SummarySection = () => {
  const { id } = useParams<{ id: string }>();

  const { account } = useSession();
  const { data: user } = useUser(id);

  const [isEditing, setIsEditing] = useState(false);
  const [internalNotesSlateEditor, internalNotesSlateValue, setInternalNotesSlateValue, setInternalNotes] = useSlateEditor(user?.internal_notes || '<br>');

  const updateUserMutation = useUpdateUser();

  useEffect(() => {
    setInternalNotes(user?.internal_notes || '<br>');
  }, [user?.internal_notes]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setInternalNotes(user?.internal_notes || '');
  };

  const handleSave = async () => {
    updateUserMutation.reset();

    try {
      await updateUserMutation.mutateAsync({
        id,
        payload: {
          internal_notes: slateValueToHtml(internalNotesSlateValue),
        },
      });
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  /* eslint-disable react/display-name */
  const schema = useMemo<TableSchema<User>>(() => [{
    header: 'Name',
    displayValue: ({ name }) => name,
  }, {
    header: 'Email',
    displayValue: ({ email }) => <a href={`mailto:${email}`}>{email}</a>,
  }, account?.ats_type && {
    header: `Has ${capitalize(account.ats_type)} Account`,
    displayValue: ({ ats_id }) => (
      ats_id ?
        <FontAwesomeIcon className="check" icon={faCheck} /> :
        <span className="empty">&mdash;</span>
    ),
  }, {
    header: 'Internal Notes',
    displayValue: ({ internal_notes }) => internal_notes ? <div dangerouslySetInnerHTML={{ __html: internal_notes }} /> : <span className="unset">Not set</span>,
    displayEditValue: () => (
      <EditorInput
        allowTokens={false}
        editor={internalNotesSlateEditor}
        helperText={<>You may store internal details, e.g. interviewer bio, time-of-day preferences. These will <i>not</i> be included in candidate or interviewer communication.</>}
        pendingPreviewMessage=""
        setValue={setInternalNotesSlateValue}
        type="candidate_calendar_event"
        value={internalNotesSlateValue}
      />
    ),
  }], [account?.ats_type, internalNotesSlateEditor, internalNotesSlateValue, setInternalNotesSlateValue]);

  if (!user) {
    return null;
  }

  return (
    <Section
      className="summary-section"
      isEditable
      isEditing={isEditing}
      isSaving={updateUserMutation.isLoading}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Summary"
    >
      <Flash
        message={updateUserMutation.error?.message}
        showFlash={updateUserMutation.isError}
        type="danger"
      />
      <Flash
        isDismissible
        message="Successfully updated!"
        showFlash={updateUserMutation.isSuccess}
        type="success"
      />
      <Table
        data={[user]}
        isEditing={isEditing}
        layout="horizontal"
        schema={schema}
      />
    </Section>
  );
};

export default SummarySection;
