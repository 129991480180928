import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Tag from 'components/library/data-display/Tag';

export const StyledTag = styled(Tag)`
  background: ${({ theme }) => theme.colors.PRODUCT.SECONDARY_BACKGROUND};
  border-radius: 4px;
  border: 1px solid ${({ theme }) => theme.colors.PRODUCT.PRIMARY_BORDER};
  font-size: .8em !important;
  padding: 8px !important;
`;

export const StyledIconContainer = styled.div`
  display: flex;
`;

export const StyledUserIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.BRAND.GEM_BLUE};
  font-size: 14px;
`;

export const StyledHiringTeamIcon = styled(FontAwesomeIcon)`
  color: #ffb672;
  font-size: 14px;
`;

export const StyledInterviewerIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.PURPLES.PURPLE_300};
  font-size: 14px;
`;

export const StyledReasonList = styled.ul`
  margin: 0;
  padding-inline-start: 1.75em;

  &.has-helper-text {
    margin-bottom: .5em;
  }
`;
