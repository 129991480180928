import Moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize } from 'lodash';
import { faRotate } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

import Button from '../../library/inputs/Button';
import Flash from '../../library/utils/Flash';
import Modal from '../../library/layout/Modal';
import { formatMoment, TimeFormat } from '../../../libraries/time';
import { useAccount } from '../../../hooks/queries/accounts';
import { useLatestSync, useStartSync } from '../../../hooks/queries/syncs';

import type { Dispatch, SetStateAction } from 'react';

interface Props {
  setManualSyncStarted: Dispatch<SetStateAction<boolean>>;
}

const ManualATSSyncButton = ({ setManualSyncStarted }: Props) => {
  const account = useAccount().data!;
  const { data: sync, isLoading } = useLatestSync(account?.ats_type, {
    refetchInterval: 10_000,
    refetchOnMount: 'always',
    refetchOnWindowFocus: 'always',
  });

  const [isOpen, setIsOpen] = useState(false);

  const startSyncMutation = useStartSync();

  if (!account?.ats_type || isLoading) {
    return null;
  }

  const handleSubmit = async () => {
    startSyncMutation.reset();
    setManualSyncStarted(false);

    try {
      await startSyncMutation.mutateAsync({
        payload: { source: account.ats_type! },
      });
      setManualSyncStarted(true);
      setIsOpen(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  return (
    <>
      <Button
        color="gem-outline"
        iconRight={<FontAwesomeIcon icon={faRotate} />}
        onClick={() => setIsOpen(true)}
        size="small"
        value="Run manual sync"
      />
      <Modal
        isOpen={isOpen}
        isSubmitting={startSyncMutation.isLoading}
        onSubmit={handleSubmit}
        onToggle={() => setIsOpen((prev) => !prev)}
        submitButtonValue="Start sync"
        submittingButtonValue="Starting..."
        title={`Sync latest data from ${capitalize(account?.ats_type)}?`}
      >
        <Flash
          message={startSyncMutation.error?.message}
          showFlash={startSyncMutation.isError}
          type="danger"
        />
        <p>
          This will fetch the most up-to-date information for all open jobs and active candidates.
        </p>
        <p>
          {sync ? <>The last sync finished at <b>{formatMoment(Moment(sync.completed_at).tz(Moment.tz.guess()), TimeFormat.LongMonthDayYearWithTimeAndTimezone)}</b>.</> : 'A sync hasn\'t finished running yet. You might want to wait a bit before trying a manual sync.'}
        </p>
      </Modal>
    </>
  );
};

export default ManualATSSyncButton;
