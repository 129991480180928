import { Directory } from 'types';

export function constructDirectoryCalendarHref (directoryType: Directory, calendarId?: string, eventId?: string): string | undefined {
  switch (directoryType) {
    // Based on https://stackoverflow.com/questions/53928044/how-do-i-construct-a-link-to-a-google-calendar-event/53928045#53928045
    case Directory.Google:
      if (calendarId && eventId) {
        const event = eventId.replace('@google.com', '');
        const calendar = calendarId.replace('@group.calendar.google.com', '@g');
        const b64EncodedId = btoa(`${event} ${calendar}`);
        const strippedB64EncodedId = b64EncodedId.replace(/==$/, '');
        return `https://www.google.com/calendar/event?eid=${strippedB64EncodedId}`;
      }
    // TODO: Microsoft
  }
  return undefined;
}
