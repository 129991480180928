import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faCaretDown } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useRef, useState } from 'react';

import Button from './Button';
import OutboundLink from '../navigation/OutboundLink';
import Popover from '../utils/Popover';

import type { ButtonColor, ButtonSize } from './Button';
import type { MouseEventHandler, ReactNode } from 'react';

interface ButtonProps {
  color?: `${ButtonColor}`;
  value: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  linkTo?: string;
  isDisabled?: boolean;
  isOutboundLink?: boolean;
  tooltip?: JSX.Element;
  // Required if isOutboundLink is true.
  label?: string;
}

interface Props {
  buttons: (ButtonProps | null | undefined)[];
  className?: string;
  color: `${ButtonColor}`;
  id: string;
  isDisabled?: boolean;
  size: `${ButtonSize}`;
}

const SplitButton = ({ buttons, className, color, id, isDisabled, size }: Props) => {
  const [width, setWidth] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [isRendered, setIsRendered] = useState(false);

  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (isRendered && ref.current) {
      setWidth(ref.current.clientWidth);
    }
  }, [isRendered]);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  const filteredButtons = buttons.filter((button): button is ButtonProps => Boolean(button));

  if (filteredButtons.length === 0) {
    return null;
  }

  const primaryButton = filteredButtons[0];
  const secondaryButtons = filteredButtons.length === 1 ? null : filteredButtons.slice(1);

  return (
    <div className={classNames('split-button', secondaryButtons && 'with-secondary-buttons', className)} ref={ref}>
      {primaryButton.linkTo && !isDisabled ?
        (primaryButton.isOutboundLink ?
          <OutboundLink
            externalLinkIcon={false}
            href={primaryButton.linkTo}
            label={primaryButton.label!}
          >
            <Button
              className="primary-button"
              color={primaryButton.color || color}
              id={id}
              size={size}
              value={primaryButton.value}
            />
          </OutboundLink> :
          <Link className="primary-button" to={primaryButton.linkTo}>
            <Button
              className="primary-button"
              color={primaryButton.color || color}
              id={id}
              size={size}
              value={primaryButton.value}
            />
          </Link>
        ) :
        <Button
          className="primary-button"
          color={primaryButton.color || color}
          id={id}
          isDisabled={isDisabled}
          onClick={primaryButton.onClick}
          size={size}
          value={primaryButton.value}
        />
      }
      {secondaryButtons &&
        <>
          <Button
            className="dropdown-arrow"
            color={primaryButton.color || color}
            iconRight={<FontAwesomeIcon icon={faCaretDown} />}
            id={`${id}-dropdown-arrow`}
            isDisabled={isDisabled}
            size={size}
          />
          <Popover
            className="split-button-popover"
            isOpen={isOpen}
            position="bottom-end"
            setIsOpen={setIsOpen}
            target={`${id}-dropdown-arrow`}
          >
            <div className="dropdown-menu" style={{ minWidth: width }}>
              {secondaryButtons.map((secondaryButton, i) => (
                <div className="secondary-button" key={`secondary-button-${id}-${i}`}>{
                  secondaryButton.linkTo && !isDisabled ?
                    (secondaryButton.isOutboundLink ?
                      <OutboundLink
                        externalLinkIcon={false}
                        href={secondaryButton.linkTo}
                        label={secondaryButton.label!}
                      >
                        <Button
                          className="secondary-button"
                          color={secondaryButton.color || color}
                          id={id}
                          isDisabled={secondaryButton.isDisabled}
                          size={size}
                          tooltip={secondaryButton.tooltip}
                          value={secondaryButton.value}
                        />
                      </OutboundLink> :
                      <Link to={secondaryButton.linkTo}>
                        <Button
                          color={secondaryButton.color || color}
                          isDisabled={secondaryButton.isDisabled}
                          size={size}
                          tooltip={secondaryButton.tooltip}
                          value={secondaryButton.value}
                        />
                      </Link>
                    ) :
                    <Button
                      color={secondaryButton.color || color}
                      isDisabled={secondaryButton.isDisabled}
                      onClick={(e) => {
                        if (secondaryButton.onClick) {
                          setIsOpen(false);
                          secondaryButton.onClick(e);
                        }
                      }}
                      size={size}
                      tooltip={secondaryButton.tooltip}
                      value={secondaryButton.value}
                    />
                }</div>
              ))}
            </div>
          </Popover>
        </>
      }
    </div>
  );
};

export default SplitButton;
