import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
#root {
  height: 100%;
  width: 100%;
}

body {
  background: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.mineshaftBlack};
  font-family: ${({ theme }) => theme.fonts.text};
  font-size: 16px;
  margin: 0;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: none;
}

h1 {
  font-family: ${({ theme }) => theme.fonts.header};
  font-size: 2.7rem;
  font-weight: normal;
  letter-spacing: .02em;
}

h2 {
  color: ${({ theme }) => theme.colors.zodiacBlue};
  font-family: ${({ theme }) => theme.fonts.header};
  font-weight: bold;
  font-size: 1.8rem;
}

h3 {
  font-weight: bold;
}

h4 {
  font-weight: normal;
}

h6 {
  color: ${({ theme }) => theme.colors.doveGray};
  font-size: .7rem;
  font-weight: normal;
  letter-spacing: .1rem;
  text-decoration: none;
  text-transform: uppercase;
}

p {
  line-height: 1.5;
}

a {
  color: ${({ theme }) => theme.colors.curiousBlue};
  font-weight: 600;
  padding: 4px 1px;

  &:hover {
    color: ${({ theme }) => theme.colors.bayBlue};
    text-decoration: underline;
    transition: .2s text-decoration linear;
  }
}

button {
  background: none;
}

pre {
  background: ${({ theme }) => theme.colors.alabasterWhite};
  overflow: scroll;
  padding: 1em;
}

.btn {
  border: none;
  border-radius: 5px;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-weight: bold;
  font-size: .95em;
  letter-spacing: .8px;
  text-decoration: none;
  transition: .2s background linear, .2s border linear;
  white-space: nowrap;

  &:disabled,
  &.btn-disabled {
    cursor: default;
  }

  &:focus {
    outline: none;
  }

  &.btn-link {
    font-size: .7rem;
    font-weight: 600;
    letter-spacing: .1rem;
    text-transform: uppercase;
  }

  &.btn-small {
    font-size: .7rem;
    font-weight: 600;
    letter-spacing: .02rem;
    padding: .5em .9em;
    text-transform: uppercase;
  }

  &.btn-large {
    font-size: 14px;
    font-weight: bold;
    letter-spacing: .8px;
    padding: 8px 25px;
    text-transform: uppercase;
  }

  &.btn-blue-outline {
    border: 1px solid ${({ theme }) => theme.colors.curiousBlue};
    box-shadow: none;
    color: ${({ theme }) => theme.colors.zodiacBlue};

    &.btn-disabled,
    &:disabled {
      background: ${({ theme }) => theme.colors.mercuryGray} !important;
      border: 1px solid ${({ theme }) => theme.colors.mercuryGray} !important;
      color: ${({ theme }) => theme.colors.doveGray} !important;
    }

    &:not(:disabled):not(.btn-disabled):hover {
      background: ${({ theme }) => theme.colors.foamBlue};
    }
  }

  &.btn-green {
    background: ${({ theme }) => theme.colors.funGreen};
    color: white;

    &:not(:disabled):hover {
      background: darken(${({ theme }) => theme.colors.funGreen}, 10%);
    }
  }

  &.btn-orange {
    background: ${({ theme }) => theme.colors.neonOrange};
    border: 1px solid ${({ theme }) => theme.colors.neonOrange};
    color: ${({ theme }) => theme.colors.zodiacBlue};

    &:not(:disabled):hover {
      background: darken(${({ theme }) => theme.colors.neonOrange}, 10%);
      border: 1px solid darken(${({ theme }) => theme.colors.neonOrange}, 10%);
    }

    &:disabled {
      background: desaturate(${({ theme }) => theme.colors.neonOrange}, 25%);
      color: desaturate(${({ theme }) => theme.colors.zodiacBlue}, 25%);
    }

    .spinner {
      color: inherit;
    }
  }

  &.btn-gray {
    color: lighten(${({ theme }) => theme.colors.doveGray}, 20%);

    .avatar {
      border: none;
    }

    &:disabled {
      color: ${({ theme }) => theme.colors.mercuryGray};
    }

    &:not(.btn-disabled):not(:disabled):hover {
      color: ${({ theme }) => theme.colors.bayBlue};

      &.btn-delete {
        color: ${({ theme }) => theme.colors.brightRed};
      }

      .avatar {
        filter: brightness(80%);
      }
    }
  }

  &.btn-delete {
    &.btn-delete-always-red {
      color: ${({ theme }) => theme.colors.brightRed};

      &.btn-disabled,
      &:disabled {
        color: ${({ theme }) => theme.colors.silverGray};
      }
    }

    .icon-right {
      margin-left: 0;
    }
  }

  &.btn-no-outline {
    color: ${({ theme }) => theme.colors.curiousBlue};

    &.btn-disabled,
    &:disabled {
      color: ${({ theme }) => theme.colors.doveGray} !important;
    }

    &:not(:disabled):not(.btn-disabled) {
      &:hover {
        color: ${({ theme }) => theme.colors.bayBlue};
        text-decoration: underline;

        .avatar {
          text-decoration: none;
        }
      }
    }
  }

  .icon-left {
    margin-right: .5em;
  }

  .icon-right {
    margin-left: .5em;
  }
}
`;

export default GlobalStyles;
