import { createContext, useContext, useMemo } from 'react';

import type { ReactNode } from 'react';
import type { Conflict } from 'types';
import type { AvailabilityTimeSlot, PotentialRoom, PotentialZoomHost } from './types';

export interface CalendarScheduleContextState {
  availabilityTimeSlots?: AvailabilityTimeSlot[];
  eventConflictCheckFunctions: ((event: Conflict, checkIfIgnored: boolean, userEmail?: string) => boolean)[];
  headerDate: Date;
  headerTitle?: string;
  onDateChange?: (date: Date) => void;
  onRoomChange?: (selectedRoom: PotentialRoom) => void;
  onZoomHostChange?: (selectedZoomHost: PotentialZoomHost) => void;
  potentialZoomHosts: PotentialZoomHost[];
  scheduleId: string;
  selectedRoomId: string | null;
  selectedZoomHostId: string | null;
  showZoomHost: boolean;
  timezone: string;
}

const CalendarScheduleContext = createContext<CalendarScheduleContextState>({
  availabilityTimeSlots: [],
  eventConflictCheckFunctions: [],
  headerDate: new Date(),
  potentialZoomHosts: [],
  scheduleId: '',
  selectedRoomId: null,
  selectedZoomHostId: null,
  showZoomHost: false,
  timezone: '',
});

interface Props extends CalendarScheduleContextState {
  children: ReactNode;
}

export const CalendarScheduleProvider = ({
  children,
  availabilityTimeSlots,
  eventConflictCheckFunctions,
  headerDate,
  headerTitle,
  onDateChange,
  onRoomChange,
  onZoomHostChange,
  potentialZoomHosts,
  scheduleId,
  selectedRoomId,
  selectedZoomHostId,
  showZoomHost,
  timezone,
}: Props) => {
  const contextValue = useMemo<CalendarScheduleContextState>(() => ({
    availabilityTimeSlots,
    eventConflictCheckFunctions,
    headerDate,
    headerTitle,
    onDateChange,
    onRoomChange,
    onZoomHostChange,
    potentialZoomHosts,
    scheduleId,
    selectedRoomId,
    selectedZoomHostId,
    showZoomHost,
    timezone,
  }), [
    availabilityTimeSlots,
    eventConflictCheckFunctions,
    headerDate,
    headerTitle,
    onDateChange,
    onRoomChange,
    onZoomHostChange,
    potentialZoomHosts,
    scheduleId,
    selectedRoomId,
    selectedZoomHostId,
    showZoomHost,
    timezone,
  ]);

  return (
    <CalendarScheduleContext.Provider value={contextValue}>
      {children}
    </CalendarScheduleContext.Provider>
  );
};

export const useCalendarScheduleContext = () => {
  return useContext(CalendarScheduleContext);
};
