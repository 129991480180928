import { useEffect, useRef, useState } from 'react';

import RecorderInput from '../../library/inputs/RecorderInput';
import TextInput from '../../library/inputs/TextInput';
import {
  StyledCheckboxInput,
  StyledHr,
  StyledOptionalText,
  StyledPronounsContainer,
  StyledPronunciationContainer,
  StyledSection,
  StyledTable,
  StyledTextInput,
  StyledWrapper,
} from './styles';
import { useDidUpdateEffect } from '../../../hooks/use-did-update-effect';

import type { ChangeEvent, Dispatch, SetStateAction } from 'react';
import type { Pronouns } from '../../../types';

const createDefaultPronouns = (): Pronouns => ({
  he_him: false,
  she_her: false,
  they_them: false,
  xe_xem: false,
  ze_hir: false,
  ey_em: false,
  hir_hir: false,
  fae_faer: false,
  hu_hu: false,
  self_describe: '',
});

interface Props {
  brandColor?: string;
  candidateName?: string;
  isPronounsInputFreeText?: boolean;
  originalSelfDescribe?: string;
  pronouns: Pronouns | null;
  pronunciation: File | null;
  setPronouns: Dispatch<SetStateAction<Pronouns | null>>;
  setPronunciation: Dispatch<SetStateAction<File | null>>;
  setPronunciationExists: Dispatch<SetStateAction<boolean | undefined>>;
  showPronouns?: boolean;
  showPronunciation?: boolean;
}

const AvailabilityAdditionalInformationSection = ({
  brandColor,
  candidateName,
  isPronounsInputFreeText,
  originalSelfDescribe,
  pronouns,
  pronunciation,
  setPronouns,
  setPronunciation,
  setPronunciationExists,
  showPronouns,
  showPronunciation,
}: Props) => {
  const [isSelfDescribe, setIsSelfDescribe] = useState<boolean>(Boolean(originalSelfDescribe));

  const selfDescribeInputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setIsSelfDescribe(Boolean(originalSelfDescribe));
  }, [originalSelfDescribe]);

  useDidUpdateEffect(() => {
    if (isSelfDescribe) {
      selfDescribeInputRef.current?.focus();
    }
  }, [isSelfDescribe]);

  const handlePronounsChange = (e: ChangeEvent<HTMLInputElement>) => {
    const key = e.target.name.replace('pronouns-', '');
    const value = e.target.checked;

    setPronouns((prev) => ({
      ...(prev || createDefaultPronouns()),
      [key]: value,
    }));
  };

  const handleIsSelfDescribeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsSelfDescribe(e.target.checked);
    if (!e.target.checked) {
      // Since we're unchecking self-describe, we need to clear out the existing
      // value.
      setPronouns((prev) => ({
        ...(prev || createDefaultPronouns()),
        self_describe: '',
      }));
    }
  };

  const handleSelfDescribeChange = (e: ChangeEvent<HTMLInputElement>) => {
    setPronouns((prev) => ({
      ...(prev || createDefaultPronouns()),
      self_describe: e.target.value,
    }));
  };

  return (
    <StyledWrapper>
      <StyledSection title="Additional information">
        {showPronouns && (
          <StyledPronounsContainer>
            <div>
              Let us know your pronouns so that we can address you correctly. <StyledOptionalText>(Optional)</StyledOptionalText>
            </div>
            {isPronounsInputFreeText ? (
              <StyledTextInput
                onChange={handleSelfDescribeChange}
                placeholder="Enter your pronouns"
                value={pronouns?.self_describe}
              />
            ) : (
              <>
                <StyledTable>
                  <tbody>
                    <tr>
                      <td>
                        <StyledCheckboxInput
                          isChecked={pronouns?.he_him}
                          label="he/him"
                          name="pronouns-he_him"
                          onChange={handlePronounsChange}
                        />
                      </td>
                      <td>
                        <StyledCheckboxInput
                          isChecked={pronouns?.she_her}
                          label="she/her"
                          name="pronouns-she_her"
                          onChange={handlePronounsChange}
                        />
                      </td>
                      <td>
                        <StyledCheckboxInput
                          isChecked={pronouns?.they_them}
                          label="they/them"
                          name="pronouns-they_them"
                          onChange={handlePronounsChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <StyledCheckboxInput
                          isChecked={pronouns?.xe_xem}
                          label="xe/xem"
                          name="pronouns-xe_xem"
                          onChange={handlePronounsChange}
                        />
                      </td>
                      <td>
                        <StyledCheckboxInput
                          isChecked={pronouns?.ze_hir}
                          label="ze/hir"
                          name="pronouns-ze_hir"
                          onChange={handlePronounsChange}
                        />
                      </td>
                      <td>
                        <StyledCheckboxInput
                          isChecked={pronouns?.ey_em}
                          label="ey/em"
                          name="pronouns-ey_em"
                          onChange={handlePronounsChange}
                        />
                      </td>
                    </tr>
                    <tr>
                      <td>
                        <StyledCheckboxInput
                          isChecked={pronouns?.hir_hir}
                          label="hir/hir"
                          name="pronouns-hir_hir"
                          onChange={handlePronounsChange}
                        />
                      </td>
                      <td>
                        <StyledCheckboxInput
                          isChecked={pronouns?.fae_faer}
                          label="fae/faer"
                          name="pronouns-fae_faer"
                          onChange={handlePronounsChange}
                        />
                      </td>
                      <td>
                        <StyledCheckboxInput
                          isChecked={pronouns?.hu_hu}
                          label="hu/hu"
                          name="pronouns-hu_hu"
                          onChange={handlePronounsChange}
                        />
                      </td>
                    </tr>
                  </tbody>
                </StyledTable>
                <StyledHr />
                <StyledCheckboxInput
                  isChecked={isSelfDescribe}
                  label={isSelfDescribe ? (
                    <>
                      Self-describe
                      <TextInput
                        isRequired
                        onChange={handleSelfDescribeChange}
                        placeholder="Enter your pronouns"
                        ref={selfDescribeInputRef}
                        value={pronouns?.self_describe}
                      />
                    </>
                  ) : 'Self-describe'}
                  onChange={handleIsSelfDescribeChange}
                />

              </>
            )}
          </StyledPronounsContainer>
        )}
        {showPronunciation && (
          <StyledPronunciationContainer>
            <div>
              Tell us how we should pronounce your name. <StyledOptionalText>(Optional)</StyledOptionalText>
            </div>
            <RecorderInput
              brandColor={brandColor}
              file={pronunciation}
              fileLabel="name pronunciation"
              fileName={`${candidateName || 'name'}.mp3`}
              maxSeconds={5}
              onChange={(file) => {
                setPronunciation(file);
                setPronunciationExists(Boolean(file));
              }}
              size="small"
            />
          </StyledPronunciationContainer>
        )}
      </StyledSection>
    </StyledWrapper>
  );
};

export default AvailabilityAdditionalInformationSection;
