import {
  faAt,
  faCalendarAlt,
  faClock,
  faFont,
  faKey,
  faLink,
  faList,
  faPhone,
  faUserClock,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Tooltip from '../../../utils/Tooltip';
import { Token } from '../../../../../types';

import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import type { MouseEvent } from 'react';
import type { TokenResponseItem } from '../../../../../types';

const icons: Record<Token, IconDefinition> = {
  [Token.AvailabilityRequestLink]: faLink,
  [Token.CandidateEmail]: faAt,
  [Token.CandidateFirstName]: faFont,
  [Token.CandidateFullName]: faFont,
  [Token.CandidateLastName]: faFont,
  [Token.CandidateLinkedInURL]: faLink,
  [Token.CandidatePhoneNumber]: faPhone,
  [Token.CandidateWebsite]: faLink,
  [Token.CoordinatorEmail]: faAt,
  [Token.CoordinatorFirstName]: faFont,
  [Token.CoordinatorFullName]: faFont,
  [Token.CoordinatorLastName]: faFont,
  [Token.CoordinatorPhoneNumber]: faPhone,
  [Token.HiringManagerEmail]: faAt,
  [Token.HiringManagerFirstName]: faFont,
  [Token.HiringManagerFullName]: faFont,
  [Token.HiringManagerLastName]: faFont,
  [Token.HiringManagerPhoneNumber]: faPhone,
  [Token.InterviewDuration]: faClock,
  [Token.InterviewEndTime]: faClock,
  [Token.InterviewLiveCodingLink]: faLink,
  [Token.InterviewName]: faFont,
  [Token.InterviewScorecardLink]: faLink,
  [Token.InterviewStartTime]: faClock,
  [Token.JobName]: faFont,
  [Token.JobOfficeName]: faFont,
  [Token.JobPostName]: faFont,
  [Token.JobStageAgenda]: faList,
  [Token.JobStageName]: faFont,
  [Token.MeEmail]: faAt,
  [Token.MeFirstName]: faFont,
  [Token.MeFullName]: faFont,
  [Token.MeLastName]: faFont,
  [Token.MePhoneNumber]: faPhone,
  [Token.RecruiterEmail]: faAt,
  [Token.RecruiterFirstName]: faFont,
  [Token.RecruiterFullName]: faFont,
  [Token.RecruiterLastName]: faFont,
  [Token.RecruiterPhoneNumber]: faPhone,
  [Token.RequesterEmail]: faAt,
  [Token.RequesterFirstName]: faFont,
  [Token.RequesterFullName]: faFont,
  [Token.RequesterLastName]: faFont,
  [Token.RequesterPhoneNumber]: faPhone,
  [Token.ScheduleAgendaInCandidateTimezoneWithCustomFormat]: faList,
  [Token.ScheduleAgendaInCandidateTimezoneWithZoomLinks]: faList,
  [Token.ScheduleAgendaInCandidateTimezoneWithoutInterviewNames]: faList,
  [Token.ScheduleAgendaInCandidateTimezoneWithoutInterviewers]: faList,
  [Token.ScheduleAgendaInCandidateTimezone]: faList,
  [Token.ScheduleAgendaInScheduleTimezoneWithCustomFormat]: faList,
  [Token.ScheduleAgendaInScheduleTimezoneWithZoomLinks]: faList,
  [Token.ScheduleAgendaInScheduleTimezoneWithoutInterviewNames]: faList,
  [Token.ScheduleAgendaInScheduleTimezoneWithoutInterviewers]: faList,
  [Token.ScheduleAgendaInScheduleTimezone]: faList,
  [Token.ScheduleCandidateTimezoneLongForm]: faFont,
  [Token.ScheduleCandidateTimezone]: faFont,
  [Token.ScheduleDateInCandidateTimezone]: faCalendarAlt,
  [Token.ScheduleDateWithoutYearInCandidateTimezone]: faCalendarAlt,
  [Token.ScheduleDateWithoutYear]: faCalendarAlt,
  [Token.ScheduleDate]: faCalendarAlt,
  [Token.ScheduleDatesInCandidateTimezone]: faCalendarAlt,
  [Token.ScheduleDatesWithoutYearInCandidateTimezone]: faCalendarAlt,
  [Token.ScheduleDatesWithoutYear]: faCalendarAlt,
  [Token.ScheduleDates]: faCalendarAlt,
  [Token.ScheduleDayOfTheWeekInCandidateTimezone]: faFont,
  [Token.ScheduleDayOfTheWeek]: faFont,
  [Token.ScheduleDaysOfTheWeekInCandidateTimezone]: faFont,
  [Token.ScheduleDaysOfTheWeek]: faFont,
  [Token.ScheduleEndTimeInCandidateTimezone]: faClock,
  [Token.ScheduleEndTime]: faClock,
  [Token.ScheduleInterviewerFirstNames]: faFont,
  [Token.ScheduleInterviewerFirstNamesWithLinkedinLinks]: faFont,
  [Token.ScheduleInterviewerFullNames]: faFont,
  [Token.ScheduleInterviewerFullNamesWithLinkedinLinks]: faFont,
  [Token.ScheduleLocation]: faFont,
  [Token.ScheduleRoomName]: faFont,
  [Token.ScheduleRoomNames]: faFont,
  [Token.ScheduleStartTimeInCandidateTimezone]: faClock,
  [Token.ScheduleStartTime]: faClock,
  [Token.ScheduleTimezoneLongForm]: faFont,
  [Token.ScheduleTimezone]: faFont,
  [Token.ScheduleTraineeFirstNames]: faFont,
  [Token.ScheduleTraineeFullNames]: faFont,
  [Token.ScheduleVideoConferencingLink]: faLink,
  [Token.ScheduleVideoConferencingLinks]: faLink,
  [Token.ScheduleVideoConferencingPasscode]: faKey,
  [Token.ScheduleVideoConferencingPasscodes]: faKey,
  [Token.SchedulerEmail]: faAt,
  [Token.SchedulerFirstName]: faFont,
  [Token.SchedulerFullName]: faFont,
  [Token.SchedulerLastName]: faFont,
  [Token.SchedulerPhoneNumber]: faPhone,
  [Token.SelfSchedulingRequestLink]: faLink,
  [Token.SourcerEmail]: faAt,
  [Token.SourcerFirstName]: faFont,
  [Token.SourcerFullName]: faFont,
  [Token.SourcerLastName]: faFont,
  [Token.SourcerPhoneNumber]: faPhone,
};

interface Props {
  index: number;
  isActive: boolean;
  onMouseDown: (event: MouseEvent<HTMLSpanElement>, index: number) => void;
  onMouseEnter: (event: MouseEvent<HTMLSpanElement>, index: number) => void;
  onMouseLeave: (event: MouseEvent<HTMLSpanElement>) => void;
  pendingPreviewMessage: string;
  token?: TokenResponseItem;
  value: Token;
}

const SuggestionOption = ({ index, isActive, onMouseDown, onMouseEnter, onMouseLeave, pendingPreviewMessage, token, value }: Props) => {
  const parts = value.split('.');
  const property = parts.pop();
  const root = parts.join('.');
  const isHTML = value.startsWith('Job.Stage.Agenda') || value.startsWith('Schedule.Agenda') || value.includes('WithLinkedinLinks');

  if (!token) {
    return null;
  }

  return (
    <span
      className={`editor-suggestions-option ${isActive ? 'editor-suggestions-option-active' : ''} ${token.disabled ? 'editor-suggestions-option-disabled' : ''}`}
      data-index={index}
      onMouseDown={token.disabled ? (e) => e.preventDefault() : (event) => onMouseDown(event, index)}
      onMouseEnter={(event) => onMouseEnter(event, index)}
      onMouseLeave={onMouseLeave}
    >
      <div className="editor-suggestions-option-icon">
        <FontAwesomeIcon icon={icons[value]} />
      </div>
      <div className="editor-suggestions-option-value">
        <b>{root}</b>.{property}
      </div>
      <div className="editor-suggestions-option-token">
        <div className="editor-suggestions-option-token-text">
          {token.value ? isHTML ? <span dangerouslySetInnerHTML={{ __html: token.value.split('<br>')[0] }} /> : token.value : null}
          {token.example ? <>(e.g. {isHTML ? <span dangerouslySetInnerHTML={{ __html: token.example.split('<br>')[0] }} /> : token.example})</> : null}
        </div>
        <div className="editor-suggestions-option-token-icon">
          {token.disabled ? <>&mdash;</> : null}
          {token.pending && (
            <>
              <FontAwesomeIcon
                data-for={`tooltip-pending-${value}`}
                data-tip
                icon={faUserClock}
              />
              <Tooltip
                id={`tooltip-pending-${value}`}
                position="top"
                value={pendingPreviewMessage}
              />
            </>
          )}
        </div>
      </div>
    </span>
  );
};

export default SuggestionOption;
