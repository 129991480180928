import Moment from 'moment-timezone';
import { useEffect, useMemo, useState } from 'react';

import Avatar from 'components/library/data-display/Avatar';
import CheckboxInput from 'components/library/inputs/CheckboxInput';
import Label from 'components/library/utils/Label';
import ListItem from 'components/library/data-display/ListItem';
import LoadingSpinner from 'components/library/utils/LoadingSpinner';
import TrainingProgramSelectInput from 'components/library/inputs/TrainingProgramSelectInput';
import { useEvents } from 'hooks/use-events';
import { formatMoment, TimeFormat } from 'libraries/time';
import EventAttendeeAvatarGroup from '../EventAttendeeAvatarGroup';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from 'components/library/inputs/SelectInput/types';
import type { DetailEventConflict, DetailEventInterview, DetailEventTrainingSession } from './types';

const OUTSIDE_WORKING_HOURS_EVENT_TITLE = 'Outside Working Hours';

enum ResourceType {
  Room = 'room',
  User = 'user',
  ZoomHost = 'zoom_host',
}

enum Category {
  Trainings = 'trainings',
  Conflicts = 'conflicts',
  Interviews = 'interviews',
}

interface Props {
  conflicts: DetailEventConflict[] | null;
  date: Date;
  id: string;
  interviews?: DetailEventInterview[];
  onOptionalityChange?: (optional: boolean) => void;
  onTrainingProgramChange?: (trainingProgram: OnChangeValue<Option<string>, false>, actionMeta: ActionMeta<Option<string>>) => void;
  optional?: boolean;
  resource: `${ResourceType}`;
  selectedTrainingProgram?: string | null;
  showOptionalityHeader?: boolean;
  showTrainingProgramHeader?: boolean;
  timezone: string;
  trainingSessions?: DetailEventTrainingSession[];
}

const CalendarScheduleDetailsPane = ({
  conflicts,
  date,
  id,
  interviews,
  onOptionalityChange,
  onTrainingProgramChange,
  optional,
  resource,
  selectedTrainingProgram,
  showOptionalityHeader = false,
  showTrainingProgramHeader = false,
  timezone,
  trainingSessions,
}: Props) => {
  const hasInterviews = Boolean(interviews);

  const [category, setCategory] = useState(Boolean(selectedTrainingProgram) ? Category.Trainings : Category.Conflicts);
  const items = useMemo(() => {
    if (category === Category.Trainings) {
      return trainingSessions;
    }
    if (category === Category.Conflicts) {
      return conflicts;
    }
    return interviews;
  }, [category, conflicts, interviews, trainingSessions]);

  const { fetchEvents } = useEvents();

  useEffect(() => {
    if (conflicts === null) {
      fetchEvents({
        date: Moment(date).tz(timezone).format('YYYY-MM-DD'),
        timezone,
        [`${resource}_ids`]: [id],
      });
    }
  }, [conflicts]);

  return (
    <div className="conflict-details">
      {showOptionalityHeader && onOptionalityChange && (
        <div className="optionality-header">
          <CheckboxInput
            isChecked={optional}
            label="Invite as optional."
            name={id}
            onChange={(e) => onOptionalityChange(e.target.checked)}
          />
        </div>
      )}
      {showTrainingProgramHeader && onTrainingProgramChange && (
        <div
          className="training-program-header"
          // This allows clicking on the select input instead of the popover handling the event.
          onClick={(e) => e.stopPropagation()}
        >
          <TrainingProgramSelectInput
            onChange={onTrainingProgramChange}
            selectedTrainingProgram={selectedTrainingProgram}
            valueType="eligibility"
          />
        </div>
      )}
      <div className="header">
        {Boolean(selectedTrainingProgram) && (
          <h6
            className={category === 'trainings' ? 'active' : undefined}
            onClick={() => setCategory(Category.Trainings)}
            role={Boolean(selectedTrainingProgram) ? 'button' : undefined}
          >
            Trainings
          </h6>
        )}
        <h6
          className={category === 'conflicts' ? 'active' : undefined}
          onClick={hasInterviews ? () => setCategory(Category.Conflicts) : undefined}
          role={hasInterviews ? 'button' : undefined}
        >
          Conflicts
        </h6>
        {hasInterviews &&
          <h6
            className={category === 'interviews' ? 'active' : undefined}
            onClick={() => setCategory(Category.Interviews)}
            role={hasInterviews ? 'button' : undefined}
          >
            Interview Load
          </h6>
        }
      </div>
      {conflicts === null ?
        <LoadingSpinner className="loading-spinner" /> :
        !items || items.length === 0 ?
          <div className="empty">
            {category === 'conflicts' && 'No conflicts'}
            {category === 'trainings' && 'No training session history'}
            {category === 'interviews' && 'No interviews this week'}
          </div> :
          <div className="list">
            {items.map((event, i) => {
              if (event.title === OUTSIDE_WORKING_HOURS_EVENT_TITLE) {
                return (
                  <div className="conflict" key={`conflict-${i}`}>
                    <div className="title">
                      {event.title}
                    </div>
                  </div>
                );
              }

              const eventStartTime = Moment.tz(event.start_time, timezone);
              const eventEndTime = Moment.tz(event.end_time, timezone);

              let eventStart = category === 'conflicts' ? formatMoment(eventStartTime, TimeFormat.Time) : formatMoment(eventStartTime, TimeFormat.LongDayOfWeekMonthDayWithTimeAndComma);
              let eventEnd = formatMoment(eventEndTime, TimeFormat.TimeWithTimezone);

              if (!eventStartTime.isSame(eventEndTime, 'day')) {
                // the days are different
                if (eventStartTime.format('HH:mm') === '00:00' && eventEndTime.format('HH:mm') === '00:00') {
                  // the times are 00:00 to 00:00, so we should just show the dates
                  eventStart = formatMoment(eventStartTime, TimeFormat.LongDayOfWeekMonthDay);
                  eventEnd = formatMoment(eventEndTime, TimeFormat.LongDayOfWeekMonthDay);
                } else {
                  // the event goes from a specific time to another specific time, so show the date and time
                  eventStart = formatMoment(eventStartTime, TimeFormat.LongDayOfWeekMonthDayWithTimeAndComma);
                  eventEnd = formatMoment(eventEndTime, TimeFormat.LongDayOfWeekMonthDayWithTimeAndTimezoneAndComma);
                }
              }

              return (
                <div className="conflict" key={`conflict-${i}`}>
                  {(event as DetailEventConflict).ignored && (
                    <div className="ignored-label">
                      <Label color="gray">Conflict Ignored</Label>
                    </div>
                  )}
                  {event.start_time && (
                    <div className="time">
                      {eventStart}&ndash;{eventEnd}
                    </div>
                  )}
                  <div className="title">
                    {event.title}
                    {(event as DetailEventTrainingSession).phase_name && <span className="training-phase"> ({(event as DetailEventTrainingSession).phase_name})</span>}
                  </div>
                  {((event as DetailEventInterview).attendees || (event as DetailEventInterview).candidate) && (
                    <div className="attendees">
                      {category !== 'conflicts' &&
                        <ListItem
                          label={(event as DetailEventInterview).candidate}
                          leftIcon={
                            <Avatar
                              alt={(event as DetailEventInterview).candidate}
                              showUserTooltip={false}
                              size="small"
                              type="candidate"
                            />
                          }
                          secondaryText={(event as DetailEventInterview).job}
                        />
                      }
                      {category === 'conflicts' && (event as DetailEventInterview).attendees && <EventAttendeeAvatarGroup attendees={(event as DetailEventInterview).attendees || []} />}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
      }
    </div>
  );
};

export default CalendarScheduleDetailsPane;
