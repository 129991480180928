import Moment from 'moment';
import { Breadcrumb } from 'react-breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, Redirect, Route, Switch, useParams } from 'react-router-dom';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive';

import ArchiveButton from '../../../library/inputs/ArchiveButton';
import Button from '../../../library/inputs/Button';
import CalendarEventTemplateDetails from './CalendarEventTemplateDetails';
import CalendarEventTemplateLinkedStages from './CalendarEventTemplateLinkedStages';
import Flash from '../../../library/utils/Flash';
import Label from '../../../library/utils/Label';
import PageHeader from '../../../library/layout/PageHeader';
import StatusIndicator from '../../../library/utils/StatusIndicator';
import { calendarEventTemplateTypeLabels, FavoriteResourceType } from '../../../../types';
import { formatMoment, TimeFormat } from '../../../../libraries/time';
import { useCalendarEventTemplate, useUpdateCalendarEventTemplate } from '../../../../hooks/queries/calendar-event-templates';
import { correctPath } from 'libraries/gem';

const CalendarEventTemplate = () => {
  const { id } = useParams<{ id: string }>();

  const {
    data: calendarEventTemplate,
    error: calendarEventTemplateError,
    isLoading: isCalendarEventTemplateLoading,
  } = useCalendarEventTemplate(id);

  const updateCalendarEventTemplateMutation = useUpdateCalendarEventTemplate();

  const isSmallScreen = useMediaQuery({ query: '(max-width: 1000px)' });

  const handleArchive = () => {
    updateCalendarEventTemplateMutation.reset();
    updateCalendarEventTemplateMutation.mutate({ id, payload: { archived: true } });
  };

  const handleUnarchive = () => {
    updateCalendarEventTemplateMutation.reset();
    updateCalendarEventTemplateMutation.mutate({ id, payload: { archived: false } });
  };

  if (isCalendarEventTemplateLoading) {
    return null;
  }

  if (calendarEventTemplateError) {
    return (
      <Breadcrumb
        data={{
          title: id,
          pathname: correctPath(`/app/calendar-event-templates/${id}`),
        }}
      >
        <div className="calendar-event-template-container">
          <Flash
            message={calendarEventTemplateError.message}
            showFlash
            type="danger"
          />
        </div>
      </Breadcrumb>
    );
  }

  if (!calendarEventTemplate) {
    return null;
  }

  return (
    <Breadcrumb
      data={{
        title: calendarEventTemplate.name || id,
        pathname: correctPath(`/app/calendar-event-templates/${id}`),
      }}
    >
      <div className="calendar-event-template-container">
        <Flash
          isDismissible
          message={updateCalendarEventTemplateMutation.error?.message}
          onDismiss={updateCalendarEventTemplateMutation.reset}
          showFlash={updateCalendarEventTemplateMutation.isError}
          type="danger"
        />
        <Flash
          isDismissible
          message={`Calender event template ${calendarEventTemplate.archived ? 'archived' : 'unarchived'}.`}
          onDismiss={updateCalendarEventTemplateMutation.reset}
          showFlash={updateCalendarEventTemplateMutation.isSuccess}
          type="success"
        />
        <>
          <PageHeader
            details={
              <>
                <span>{calendarEventTemplateTypeLabels[calendarEventTemplate.type]}</span>
                <span className="separator">|</span>
                <StatusIndicator color={calendarEventTemplate.linked_resources ? 'green' : 'gray'}>
                  Used in <Link to={correctPath(`/app/calendar-event-templates/${calendarEventTemplate.id}/linked-stages`)}>{calendarEventTemplate.linked_resources || 0} active interview{calendarEventTemplate.linked_resources !== 1 && 's'}</Link>
                </StatusIndicator>
                {!isSmallScreen && <span className="separator">|</span>}
                {!isSmallScreen && <span>Created {formatMoment(Moment(calendarEventTemplate.created_at), TimeFormat.LongMonthDayYear)}</span>}
                {!isSmallScreen && calendarEventTemplate.created_at !== calendarEventTemplate.updated_at && <span className="separator">|</span>}
                {!isSmallScreen && calendarEventTemplate.created_at !== calendarEventTemplate.updated_at && <span>Updated {formatMoment(Moment(calendarEventTemplate.updated_at), TimeFormat.LongMonthDayYear)}</span>}
              </>
            }
            favorite={calendarEventTemplate.favorite}
            navbarLinks={[{
              label: 'Details',
              location: correctPath(`/app/calendar-event-templates/${calendarEventTemplate.id}/details`),
            }, {
              label: 'Linked Stages',
              location: correctPath(`/app/calendar-event-templates/${calendarEventTemplate.id}/linked-stages`),
            }]}
            resourceId={calendarEventTemplate.id}
            resourceType={FavoriteResourceType.CalendarEventTemplate}
            title={calendarEventTemplate.name}
            titleIcon={calendarEventTemplate.archived ? <Label color="gray">Archived</Label> : undefined}
          >
            <Link
              to={{
                pathname: correctPath('/app/calendar-event-templates/create'),
                search: `?base=${calendarEventTemplate.id}`,
              }}
            >
              <Button
                color="gem-outline"
                iconLeft={<FontAwesomeIcon icon={faPlus} />}
                size="small"
                value="New template from base"
              />
            </Link>
            <ArchiveButton
              id={id}
              isArchived={calendarEventTemplate.archived}
              onArchive={handleArchive}
              onUnarchive={handleUnarchive}
            />
            {/* <DeleteButton id={id} /> */}
          </PageHeader>
          <Switch>
            <Redirect exact from={correctPath('/app/calendar-event-templates/:id')} to={correctPath('/app/calendar-event-templates/:id/details')} />
            <Route component={CalendarEventTemplateDetails} path={correctPath('/app/calendar-event-templates/:id/details')} />
            <Route component={CalendarEventTemplateLinkedStages} path={correctPath('/app/calendar-event-templates/:id/linked-stages')} />
          </Switch>
        </>
      </div>
    </Breadcrumb>
  );
};

export default CalendarEventTemplate;
