import NavbarLinks from '../navigation/NavbarLinks';

import type { ReactNode } from 'react';
import FavoriteButton from '../inputs/FavoriteButton';
import type { FavoriteResourceType } from '../../../types';

interface Props {
  children: ReactNode;
  details: ReactNode;
  favorite?: boolean;
  navbarLinks?: {
    label: ReactNode;
    location: string;
  }[];
  resourceId?: string;
  resourceType?: FavoriteResourceType;
  title: ReactNode;
  titleIcon?: JSX.Element;
}

const PageHeader = ({
  children,
  details,
  favorite,
  navbarLinks,
  resourceId,
  resourceType,
  title,
  titleIcon,
}: Props) => {
  return (
    <div className="page-header-container">
      <div className="page-header">
        <div className="left-container">
          <div className="title-container">
            {favorite !== undefined && resourceId && resourceType && (
              <FavoriteButton favorite={favorite} resourceId={resourceId} resourceType={resourceType} />
            )}
            <h1 className="title">
              {title}
            </h1>
            {titleIcon &&
              <div className="title-icon">
                {titleIcon}
              </div>
            }
          </div>
          <div className="details">
            {details}
          </div>
        </div>
        <div className="right-container">
          {children}
        </div>
      </div>
      {navbarLinks && <NavbarLinks links={navbarLinks} />}
    </div>
  );
};

export default PageHeader;
