import { Link } from 'react-router-dom';
import { NotificationType } from '../../../types';

import type { NotificationMetadata } from '../../../types';
import { correctPath } from 'libraries/gem';

type Props = NotificationMetadata;

const TaskDescriptionText = (props: Props) => {
  switch (props.type) {
    case NotificationType.AvailabilityRequestCancelled:
      return <>Availability needs to be re-requested for <b><Link to={correctPath(`/app/candidates/${props.metadata.application_id}`)}>{props.metadata.candidate_name} ({props.metadata.job_name})</Link></b> for <b>{props.metadata.stage_name}</b>.</>;
    case NotificationType.CandidateDeclined:
      return <><b><Link to={correctPath(`/app/candidates/${props.metadata.application_id}`)}>{props.metadata.candidate_name} ({props.metadata.job_name})</Link></b> declined <b>{props.metadata.event_name}</b>.</>;
    case NotificationType.GoogleTokenError:
      return <>There was an error connecting to your <b>Google</b> account. Sign out and sign back in to fix this.</>;
    case NotificationType.GreenhouseAPIKeyError:
      return <>Your <b>Greenhouse</b> API key is invalid.</>;
    case NotificationType.GreenhousePermissionError:
      return <>Your <b>Greenhouse</b> API key does not have the necessary permission: <b>{props.metadata.permission}</b>.</>;
    case NotificationType.InterviewerDeclined:
      return <><b>{props.metadata.interviewer_name}</b> declined <b>{props.metadata.event_name}</b> for <b><Link to={correctPath(`/app/candidates/${props.metadata.application_id}`)}>{props.metadata.candidate_name} ({props.metadata.job_name})</Link></b>.</>;
    case NotificationType.JobNewlyImported:
      return <>We synced a new job: <b>{props.metadata.job_name}</b>.</>;
    case NotificationType.LeverAPIKeyError:
      if (props.metadata.state === 'wrong') {
        return <>Your <b>Lever</b> API key was created for another integration. Make sure you create an API key that&apos;s not tied to any other application.</>;
      }
      return <>Your <b>Lever</b> API key is invalid.</>;
    case NotificationType.LeverPermissionsError:
      return <>Your <b>Lever</b> API key does not have the necessary permissions.</>;
    case NotificationType.Microsoft365AuthError:
      return <>There was an error connecting to your <b>Microsoft 365</b> account. This is most likely because Admin Consent was revoked. Read more about Admin Consent.</>;
    case NotificationType.ReadyToRequestAvailability:
      return <><b><Link to={correctPath(`/app/candidates/${props.metadata.application_id}`)}>{props.metadata.candidate_name} ({props.metadata.job_name})</Link></b> is ready to be sent an availability request for <b>{props.metadata.stage_name}</b>.</>;
    case NotificationType.ReadyToSchedule:
      return <><b><Link to={correctPath(`/app/candidates/${props.metadata.application_id}`)}>{props.metadata.candidate_name} ({props.metadata.job_name})</Link></b> is ready for <b>{props.metadata.stage_name}</b>.</>;
    case NotificationType.RoomDeclined:
      return <><b>{props.metadata.room_name}</b> declined <b>{props.metadata.event_name}</b> for <b><Link to={correctPath(`/app/candidates/${props.metadata.application_id}`)}>{props.metadata.candidate_name} ({props.metadata.job_name})</Link></b>.</>;
    case NotificationType.ScheduleCancelled:
      return <><b><Link to={correctPath(`/app/candidates/${props.metadata.application_id}`)}>{props.metadata.candidate_name} ({props.metadata.job_name})</Link></b> needs to be re-scheduled for <b>{props.metadata.stage_name}</b>.</>;
    case NotificationType.SlackTokenError:
      return <>There was an error connecting to your <b>Slack</b> account. Re-connect your Slack account to fix this.</>;
    case NotificationType.StageInterviewNewlyImported:
      return <>We synced a new interview: <b>{props.metadata.stage_interview_name}</b> in <b>{props.metadata.stage_name}</b> for <b>{props.metadata.job_name}</b>.</>;
    case NotificationType.StageNewlyImported:
      return <>We synced a new stage: <b>{props.metadata.stage_name}</b> for <b>{props.metadata.job_name}</b>.</>;
    case NotificationType.ZoomTokenError:
      return <>There was an error connecting to your <b>Zoom</b> account. Re-connect your Zoom account to fix this.</>;
  }
};

export default TaskDescriptionText;
