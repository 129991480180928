import styled, { keyframes } from 'styled-components';

export const indeterminateProgressAnimation = keyframes`
  from {
    left: -50%;
  }

  to {
    left: 100%;
  }
`;

export const StyledBar = styled.div`
  background: ${({ theme }) => theme.colors.BLUES.BLUE_100};
  border-radius: 10px;
  height: 18px;
  overflow: hidden;
  position: relative;
  width: 100%;
`;

export const StyledCompletedBar = styled.div<{ $isDeterminate: boolean }>`
  animation: ${({ $isDeterminate }) => $isDeterminate ? undefined : indeterminateProgressAnimation} 2s infinite;
  background: ${({ theme }) => theme.colors.BLUES.BLUE_300};
  border-radius: 10px;
  bottom: 0;
  height: 100%;
  position: absolute;
  top: 0;
  transition: all 700ms ease;
  width: 25%;
`;
