import { StyledContainer, StyledHiringMeetingAttendeeTag } from './styles';

import type { HiringMeetingAttendeeUser } from '../../../../types';

interface Props {
  attendees: HiringMeetingAttendeeUser[];
}

const AttendeePool = ({ attendees }: Props) => {
  return (
    <StyledContainer>
      {attendees.map(({ id, is_chosen_as_individual, hiring_team_roles, interview_names }) => (
        <StyledHiringMeetingAttendeeTag
          hiringTeamRoles={hiring_team_roles}
          interviewNames={interview_names}
          isChosenAsIndividual={is_chosen_as_individual}
          key={id}
          userId={id}
        />
      ))}
    </StyledContainer>
  );
};

export default AttendeePool;
