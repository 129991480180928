import { AvailabilityStatus } from '../../../../../types';
import { StatusIndicatorColor } from '../../../../library/utils/StatusIndicator';

import type { ApplicationAvailability as Availability } from '../../../../../types';
import type { Option as SelectInputOption } from '../../../../library/inputs/SelectInput/types';

export const statusIndicatorValues: Record<AvailabilityStatus, { label: string; color: StatusIndicatorColor }> = {
  [AvailabilityStatus.Cancelled]: { label: 'Cancelled', color: StatusIndicatorColor.Red },
  [AvailabilityStatus.Requested]: { label: 'Requested', color: StatusIndicatorColor.Orange },
  [AvailabilityStatus.Submitted]: { label: 'Received', color: StatusIndicatorColor.Green },
};

export type AvailabilityWithTotalSubmittedMinutes = Availability & { totalSubmittedMinutes?: number };

export interface Option extends SelectInputOption<string> {
  availability: AvailabilityWithTotalSubmittedMinutes;
}
