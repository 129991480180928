import { find, orderBy } from 'lodash';
import { useMemo } from 'react';

import Tag from '../../data-display/Tag';
import { StyledSelectInput } from './styles';
import { useTrainingPrograms } from '../../../../hooks/queries/training-programs';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option as SelectOption } from '../SelectInput/types';

export type Option = SelectOption<string> & {
  eligibility: string;
};

enum ValueType {
  Id = 'id',
  Eligibility = 'eligibility',
}

interface Props<M extends boolean> {
  helperText?: string;
  isDisabled?: boolean;
  isMulti?: M;
  isRequired?: boolean;
  label?: string;
  name?: string;
  onChange: (newValue: OnChangeValue<Option, M>, actionMeta: ActionMeta<Option>) => void;
  selectedTrainingProgram?: string | string[] | null;
  valueType: `${ValueType}`;
}

const TrainingProgramSelectInput = <M extends boolean = false>({
  helperText,
  isDisabled = false,
  isMulti,
  isRequired = false,
  label,
  name,
  onChange,
  selectedTrainingProgram,
  valueType,
}: Props<M>) => {
  const { data: trainingPrograms } = useTrainingPrograms();

  const options = useMemo<Option[]>(() => orderBy((trainingPrograms?.training_programs || []).map((trainingProgram) => ({
    id: trainingProgram.id,
    eligibility: trainingProgram.eligibility,
    value: valueType === ValueType.Id ? trainingProgram.id : trainingProgram.eligibility,
    label: trainingProgram.eligibility,
  })), 'name'), [trainingPrograms]);

  const selected = useMemo<OnChangeValue<Option, M> | undefined>(() => {
    if (!isMulti) {
      return selectedTrainingProgram ? find(options, ['value', selectedTrainingProgram])! as OnChangeValue<Option, M> : undefined;
    }

    let selectedPrograms: string[] | null | undefined;
    if (typeof selectedTrainingProgram === 'string') {
      selectedPrograms = [selectedTrainingProgram];
    } else {
      selectedPrograms = selectedTrainingProgram;
    }
    return selectedPrograms ?
      // The types with a dynamic isMulti is a bit weird. We need to explicitly
      // cast this to be compatible.
      selectedPrograms.map((value) => find(options, ['value', value])!) as unknown as OnChangeValue<Option, M> :
      undefined;
  }, [isMulti, options, selectedTrainingProgram]);

  return (
    <StyledSelectInput
      formatOptionLabel={(option) => (
        <Tag
          hasTrainingProgram
          isTrainee
          type="eligibility"
          value={option.eligibility}
        />
      )}
      helperText={helperText}
      isClearable
      isDisabled={isDisabled}
      isMulti={isMulti}
      isRequired={isRequired}
      label={label}
      name={name}
      onChange={onChange}
      options={options}
      placeholder={isDisabled ? undefined : 'Select a training program'}
      value={selected}
    />
  );
};

export default TrainingProgramSelectInput;
