import type { EditableInterviewBatchTime } from 'types';

export const createEmptyInterviewBatchTime = (timezone: string): EditableInterviewBatchTime => {
  return {
    stage_interview_id: '',
    interview_template: {
      duration_minutes: 0,
      interviewer_templates: [],
    },
    candidates_per_slot: 1,
    start_time: '',
    end_time: '',
    timezone,
    video_conferencing_enabled: false,
  };
};
