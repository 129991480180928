import { useMutation, useQuery, useQueryClient } from 'react-query';

import InterviewPlanner from '../../libraries/interviewplanner';
import { QueryKey as ApplicationQueryKey } from './applications';

import type { Availability } from '../../types';
import type { BusinessHourDay } from '../../types/business-hours';
import type { InterviewPlannerError } from '../../libraries/interviewplanner';
import type { UseQueryOptions } from 'react-query';

export enum QueryKey {
  RetrieveAvailability = 'RetrieveAvailability',
}

export const useAvailability = (id: string, options: UseQueryOptions<Availability, InterviewPlannerError> = {}) => {
  return useQuery<Availability, InterviewPlannerError>([QueryKey.RetrieveAvailability, id], () => {
    return InterviewPlanner.request('GET', `/availabilities/${id}`);
  }, {
    enabled: options.enabled !== undefined ? options.enabled : Boolean(id),
    ...options,
  });
};

export interface CreateAvailabilityPayload {
  application_id: string;
  manual: boolean;

  // Required if manual is true.
  allow_past_times?: boolean;
  timezone?: string;
  time_slots?: {
    start_time: string;
    end_time: string;
  }[];

  // Required if manual is false.
  availability_template?: {
    enforce: boolean;
    rolling_window_days: number;
    business_hours: {
      day: BusinessHourDay;
      start_time: string;
      end_time: string;
      timezone?: string | null;
    }[];
    advanced_notice_hours: number;
    suggest_times: boolean;
    minimum_duration_minutes: number;
    total_duration_minutes: number;
    availability_request_email_template?: {
      id?: string;
      name: string;
      subject: string;
      sender_name: string;
      sender_email: string;
      cc_emails?: string[];
      bcc_emails?: string[];
      body: string;
      attachments?: {
        name: string;
        new?: boolean;
        index?: number;
        file?: File;
      }[];
    };
    suggested_times?: {
      start_time: string;
      end_time: string;
    }[];
  };
}

interface CreateAvailabilityMutationVariables {
  payload?: CreateAvailabilityPayload;
}

export const useCreateAvailability = () => {
  const queryClient = useQueryClient();

  return useMutation<Availability, Error, CreateAvailabilityMutationVariables>(({ payload }) => {
    let isMultipartForm = false;

    if (payload?.availability_template?.availability_request_email_template?.attachments) {
      payload.availability_template.availability_request_email_template.attachments = payload.availability_template.availability_request_email_template.attachments.map((attachment) => {
        const isNew = attachment instanceof File;
        isMultipartForm = isMultipartForm || isNew;
        return {
          name: attachment.name,
          new: isNew,
          index: isNew ? undefined : attachment.index,
          file: isNew ? attachment : undefined,
        };
      });
    }

    return InterviewPlanner.request('POST', '/availabilities', payload, null, isMultipartForm);
  }, {
    onSuccess: (data, { payload }) => {
      queryClient.invalidateQueries([ApplicationQueryKey.ListApplications]);
      queryClient.invalidateQueries([ApplicationQueryKey.RetrieveApplication, payload?.application_id]);
      queryClient.setQueryData([QueryKey.RetrieveAvailability, data.id], data);
    },
  });
};

export interface UpdateAvailabilityPayload {
  timezone?: string;
  time_slots?: {
    id?: string;
    start_time: string;
    end_time: string;
  }[];
  availability_template?: {
    enforce?: boolean;
    rolling_window_days?: number;
    business_hours?: {
      day: string;
      start_time: string;
      end_time: string;
      timezone: string;
    }[];
    advanced_notice_hours?: number;
    minimum_duration_minutes?: number;
    total_duration_minutes?: number;
    suggested_times?: {
      start_time: string;
      end_time: string;
    }[];
  };
}

interface UpdateAvailabilityMutationVariables {
  id: string;
  payload?: UpdateAvailabilityPayload;
}

export const useUpdateAvailability = () => {
  const queryClient = useQueryClient();

  return useMutation<Availability, Error, UpdateAvailabilityMutationVariables>(({ id, payload }) => {
    return InterviewPlanner.request('POST', `/availabilities/${id}`, payload);
  }, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([ApplicationQueryKey.ListApplications]);
      queryClient.invalidateQueries([ApplicationQueryKey.RetrieveApplication, data.application_id]);
      queryClient.setQueryData([QueryKey.RetrieveAvailability, data.id], data);
    },
  });
};

interface DeleteAvailabilityMutationVariables {
  id: string;
}

export const useDeleteAvailability = () => {
  const queryClient = useQueryClient();

  return useMutation<Availability, Error, DeleteAvailabilityMutationVariables>(({ id }) => {
    return InterviewPlanner.request('DELETE', `/availabilities/${id}`);
  }, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([ApplicationQueryKey.ListApplications]);
      queryClient.invalidateQueries([ApplicationQueryKey.RetrieveApplication, data.application_id]);
      queryClient.setQueryData([QueryKey.RetrieveAvailability, data.id], data);
    },
  });
};

interface UpdateAvailabilityTimeSlotsPayload {
  timezone: string;
  time_slots: {
    id?: string;
    start_time: string;
    end_time: string;
  }[];
  pronouns?: {
    he_him?: boolean;
    she_her?: boolean;
    they_them?: boolean;
    xe_xem?: boolean;
    ze_hir?: boolean;
    ey_em?: boolean;
    hir_hir?: boolean;
    fae_faer?: boolean;
    hu_hu?: boolean;
    self_describe?: string;
  };
  pronunciation_exists?: boolean; // Pass in false to clear it out.
  pronunciation?: File;
}

interface UpdateAvailabilityTimeSlotsMutationVariables {
  id: string;
  payload: UpdateAvailabilityTimeSlotsPayload;
}

export const useUpdateAvailabilityTimeSlots = () => {
  const queryClient = useQueryClient();

  return useMutation<Availability, InterviewPlannerError, UpdateAvailabilityTimeSlotsMutationVariables>(({ id, payload }) => {
    const isMultipartForm = Boolean(payload.pronunciation);
    return InterviewPlanner.request('POST', `/availabilities/${id}/slots`, payload, null, isMultipartForm);
  }, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([ApplicationQueryKey.ListApplications]);
      queryClient.invalidateQueries([ApplicationQueryKey.RetrieveApplication, data.application_id]);
      queryClient.setQueryData([QueryKey.RetrieveAvailability, data.id], data);
    },
  });
};
