import { useQuery } from 'react-query';
import { correctPath } from '../../libraries/gem';

export enum QueryKey {
  RetrieveVersion = 'RetrieveVersion',
}

interface VersionResponse {
  version: string;
  date: Date;
}

export const useVersion = () => {
  return useQuery<VersionResponse>([QueryKey.RetrieveVersion], async () => {
    const resp = await fetch(correctPath('/version'), { method: 'GET' });
    const text = await resp.text();
    // The response of /version is a string in the format of
    // version:unix_timestamp_of_deploy, so we trim off newlines and split it by
    // : to get the individual parts.
    const [version, dateString] = text.trim().split(':');
    // The date string is number of seconds since epoch and new Date takes in
    // milliseconds, so we need to convert it.
    const date = new Date(parseInt(dateString, 10) * 1000);
    return {
      version,
      date,
    };
  }, {
    refetchInterval: 30_000, // 30s
    refetchOnReconnect: 'always',
    refetchOnWindowFocus: 'always',
  });
};
