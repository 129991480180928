import { Fragment } from 'react';

import { StyledInterviewCount } from './styles';

interface Props {
  phases?: {
    name: string;
    number_of_interviews: number;
  }[] | null;
}

const TrainingPhaseList = ({ phases }: Props) => {
  if (!phases) {
    return null;
  }

  return (
    <span>
      {phases.map((phase, i) => (
        <Fragment key={`${phase.name}-${i}`}>
          {phase.name} <StyledInterviewCount>({phase.number_of_interviews})</StyledInterviewCount>{i !== phases.length - 1 ? ', ' : ''}
        </Fragment>
      ))}
    </span>
  );
};

export default TrainingPhaseList;
