import Avatar from '../Avatar';
import ListItem from '../ListItem';
import { videoConferencingLogos, VideoConferencingTool } from '../../../../types';

import type { ResourceCalendarResource } from './types';
import type { ResourceHeaderProps } from 'libraries/react-big-calendar';

const ResourceCalendarScheduleHeader = ({ resource }: ResourceHeaderProps<ResourceCalendarResource>) => (
  <div className="calendar-header">
    <ListItem
      label={resource.name}
      leftIcon={
        <Avatar
          size="small"
          type="user"
          userId={resource.id.replaceAll(':zoom', '')}
        />
      }
      rightIcon={resource.id.endsWith(':zoom') ? (
        <Avatar
          alt="Zoom user"
          img={videoConferencingLogos[VideoConferencingTool.Zoom]}
          size="small"
        />
      ) : undefined}
    />
  </div>
);

export default ResourceCalendarScheduleHeader;
