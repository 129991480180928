import Moment from 'moment-timezone';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import AttendeePool from '../../../../../../library/data-display/AttendeePool';
import AvailabilityPicker from '../../../../../../library/inputs/AvailabilityPicker';
import Flash from 'components/library/utils/Flash';
import HiringMeetingTable from './HiringMeetingTable';
import UnsavedEditsFlash from './UnsavedEditsFlash';
import UpdateHiringMeetingConfirmModal from './UpdateHiringMeetingConfirmModal';
import ZoomHostSelectInput from '../../../../../../library/inputs/ZoomHostSelectInput';
import { BusinessHourReferenceType } from '../../../../../../../types/business-hours';
import { StyledExpandableCheckboxInput, StyledHiringMeetingAttendeeSelectInput, StyledSection } from '../styles';
import { defaultBusinessHours } from '../../../../../../../libraries/business-hours';
import { directoryCalendarLabels, ScheduleStatus } from 'types';
import { formatMoment, TimeFormat } from '../../../../../../../libraries/time';
import { useApplication } from '../../../../../../../hooks/queries/applications';
import { useEvents } from '../../../../../../../hooks/use-events';
import { useHiringMeeting, useResolveHiringMeetingAttendees } from 'hooks/queries/hiring-meetings';
import { useRoomsMap } from '../../../../../../../hooks/queries/rooms';
import { useSession } from 'hooks/use-session';
import { useUsersMap } from 'hooks/queries/users';

import type { ChangeEvent } from 'react';
import type { EditableHiringMeeting, EditableHiringMeetingAttendeeFilter, HiringMeeting } from 'types';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option as ZoomHostOption } from '../../../../../../library/inputs/ZoomHostSelectInput';
import type { Option } from '../../../../../../library/inputs/SelectInput/types';
import type { TimeSlot } from '../../../../../../library/inputs/AvailabilityPicker/types';

const HiringMeetingSection = () => {
  const { id, hiringMeetingId } = useParams<{ id: string; hiringMeetingId: string }>();

  const { account } = useSession();
  const application = useApplication(id).data!;
  const originalHiringMeeting = useHiringMeeting(hiringMeetingId).data!;
  const rooms = useRoomsMap();
  const users = useUsersMap({ archived: true });
  const { events, fetchEvents } = useEvents();

  const [hiringMeeting, setHiringMeeting] = useState<EditableHiringMeeting>(originalHiringMeeting);
  const [timeSlot, setTimeSlot] = useState<TimeSlot[]>([{
    start_time: Moment(hiringMeeting.start_time).toDate(),
    end_time: Moment(hiringMeeting.start_time).add(hiringMeeting.hiring_meeting_template.duration_minutes, 'minutes').toDate(),
  }]);
  const [selectedDate, setSelectedDate] = useState<Date>(() => Moment(hiringMeeting.start_time).toDate());
  const [timezone, setTimezone] = useState(originalHiringMeeting.timezone);
  const [eventsAreLoading, setEventsAreLoading] = useState(false);
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const date = useMemo(() => Moment(timeSlot[0].start_time).tz(hiringMeeting.timezone).format('YYYY-MM-DD'), [timeSlot, hiringMeeting.timezone]);

  const backgroundEventTimeSlots = useMemo<TimeSlot[]>(() => {
    return (application.all_schedules || [])
    .filter((schedule) => schedule.status === ScheduleStatus.Confirmed || schedule.status === ScheduleStatus.Confirming)
    .map((schedule) => {
      const firstInterview = schedule.interviews[0];
      const lastInterview = schedule.interviews[schedule.interviews.length - 1];
      return {
        title: schedule.stage.name,
        start_time: firstInterview.start_time,
        end_time: Moment(lastInterview.start_time).add(lastInterview.interview_template.duration_minutes, 'minutes').format(),
      };
    });
  }, [application]);

  const zoomHostMeetings = useMemo(() => {
    if (!hiringMeeting.hiring_meeting_template.video_conferencing_enabled || account?.video_conferencing_type !== 'zoom' || !hiringMeeting.zoom_host_id) {
      return null;
    }
    return events[date]?.[timezone]?.zoom_hosts[hiringMeeting.zoom_host_id]?.meetings;
  }, [hiringMeeting.hiring_meeting_template.video_conferencing_enabled, account, events, date, timezone, hiringMeeting.zoom_host_id]);

  const { data: originalAttendees } = useResolveHiringMeetingAttendees({
    applicationId: originalHiringMeeting.application_id,
    hiringMeetingAttendeeFilters: (originalHiringMeeting.hiring_meeting_template.hiring_meeting_attendee_filters || []).map((filter) => ({
      hiring_meeting_attendee_filter_expressions: filter.hiring_meeting_attendee_filter_expressions.map((exp) => ({
        negated: exp.negated,
        filterable_id: exp.filterable_id,
        filterable_type: exp.filterable_type,
      })),
    })),
  });

  const { data: attendees } = useResolveHiringMeetingAttendees({
    applicationId: originalHiringMeeting.application_id,
    hiringMeetingAttendeeFilters: (hiringMeeting.hiring_meeting_template.hiring_meeting_attendee_filters || []).map((filter) => ({
      hiring_meeting_attendee_filter_expressions: filter.hiring_meeting_attendee_filter_expressions.map((exp) => ({
        negated: exp.negated,
        filterable_id: exp.filterable_id,
        filterable_type: exp.filterable_type,
      })),
    })),
  });

  useEffect(() => {
    if (!attendees || attendees.length === 0) {
      return;
    }
    const userIds = attendees.map(({ id }) => id);
    const start = Moment(selectedDate).tz(timezone).startOf('week');
    // TODO: It would be nice if the backend supported a range of dates, which
    // isn't hard to do, but if it did, we'd have to update useEvents to handle
    // that.
    for (let i = 0; i < 7; i++) {
      const date = start.clone().add(i, 'day').format('YYYY-MM-DD');
      const fetchInterviewerEvents = async () => {
        setEventsAreLoading(true);
        await fetchEvents({
          date,
          timezone,
          user_ids: userIds,
          // While we do load Zoom meetings, we don't actually display them in the picker. Right now, we just display
          // events.
          zoom_host_ids: hiringMeeting.zoom_host_id ? [hiringMeeting.zoom_host_id] : [],
        });
        setEventsAreLoading(false);
      };
      fetchInterviewerEvents();
    }
  }, [selectedDate, attendees, timezone, hiringMeeting.zoom_host_id]);

  // This could cause some issues if originalHiringMeeting gets reloaded frequently.
  // But we need to be mindful that sometimes, originalHiringMeeting updates, and we
  // need to pull in those changes. This happens after a successful update.
  useEffect(() => {
    setHiringMeeting(originalHiringMeeting);
  }, [originalHiringMeeting]);

  useEffect(() => {
    setShowConfirmModal(false);
    setIsEditing(false);
    setIsSuccess(false);
  }, [hiringMeetingId]);

  const handleIsVideoConferencingEnabledChange = (e: ChangeEvent<HTMLInputElement>) => {
    setHiringMeeting((prev) => ({
      ...prev,
      hiring_meeting_template: {
        ...prev.hiring_meeting_template,
        video_conferencing_enabled: e.target.checked,
      },
    }));
  };

  const handleZoomHostChange = (option: OnChangeValue<ZoomHostOption, false>) => {
    setHiringMeeting((prev) => ({
      ...prev,
      zoom_host_id: option?.value,
      zoom_host_type: option?.type,
    }));
  };

  const handleAttendeeFiltersChange = (filters: EditableHiringMeetingAttendeeFilter[]) => {
    setHiringMeeting((prev) => ({
      ...prev,
      hiring_meeting_template: {
        ...prev.hiring_meeting_template,
        hiring_meeting_attendee_filters: filters,
      },
    }));
  };

  const handleTimezoneChange = (option: OnChangeValue<Option<string>, false>) => setTimezone(option ? option.value : '');

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setHiringMeeting(originalHiringMeeting);
    setIsEditing(false);
  };

  const handleUpdateSuccess = (newlyUpdatedHiringMeeting: HiringMeeting) => {
    setIsSuccess(true);
    setHiringMeeting(newlyUpdatedHiringMeeting);
    setIsEditing(false);
  };

  const handleSave = () => {
    setShowConfirmModal(true);
  };

  const toggleConfirmModal = () => {
    setShowConfirmModal(!showConfirmModal);
  };

  const declinedAttendees = (originalHiringMeeting.hiring_meeting_attendees || []).filter(({ rsvp }) => rsvp === 'declined');

  // The Zoom host conflict warning dynamically changes in edit mode, depending on if you enable video conferencing or
  // not. This is because there's no other way to see in the calendar schedule what the conflicts are for the Zoom host.
  const zoomHostConflicts = useMemo(() => {
    return zoomHostMeetings ? zoomHostMeetings.filter((meeting) => {
      if (meeting.join_url === hiringMeeting.calendar_event_location) {
        return false;
      }
      const meetingStart = Moment(meeting.start_time).tz(hiringMeeting.timezone);
      const meetingEnd = Moment(meeting.end_time).tz(hiringMeeting.timezone);
      const hiringMeetingStart = Moment(timeSlot[0].start_time).tz(hiringMeeting.timezone);
      const hiringMeetingEnd = Moment(timeSlot[0].end_time).tz(hiringMeeting.timezone);
      return hiringMeetingStart.isBefore(meetingEnd) && meetingStart.isBefore(hiringMeetingEnd);
    }) : [];
  }, [
    hiringMeeting.calendar_event_location,
    hiringMeeting.timezone,
    timeSlot,
    zoomHostMeetings,
  ]);

  return (
    <StyledSection
      isEditable
      isEditing={isEditing}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Hiring meeting"
    >
      <Flash
        isDismissible
        message={`Successfully updated! It may take up to a minute to see changes in ${directoryCalendarLabels[account?.directory_type!]}.`}
        showFlash={isSuccess}
        type="success"
      />
      <Flash
        message="The room has declined the invite."
        showFlash={originalHiringMeeting.room_rsvp === 'declined'}
        type="warning"
      />
      <Flash
        message={(
          <div>
            The following attendees have declined their invites.
            <ul>
              {declinedAttendees.map(({ attendee_id }, i) => (
                <li key={`interviewer-decline-${i}`}>
                  {users[attendee_id]?.name || users[attendee_id]?.email}
                </li>
              ))}
            </ul>
          </div>
        )}
        showFlash={declinedAttendees.length > 0}
        type="warning"
      />
      <Flash
        message={hiringMeeting.zoom_host_id && (
          <div>
            The Zoom host, <b>{hiringMeeting.zoom_host_type === 'room' ? rooms[hiringMeeting.zoom_host_id]?.name : (users[hiringMeeting.zoom_host_id]?.name || users[hiringMeeting.zoom_host_id]?.email)}</b>, is hosting other meetings during this time. The video connection will be interrupted. Please resolve the following conflicts outside InterviewPlanner.
            <ul>
              {zoomHostConflicts.map((conflict, i) => (
                <li key={`zoom-host-conflict-${i}`}>
                  {conflict.buffer_time ? 'Buffer window for ' : ''}<b>{conflict.topic}</b> at <b>{formatMoment(Moment.tz(conflict.start_time, hiringMeeting.timezone), TimeFormat.Time)}&ndash;{formatMoment(Moment.tz(conflict.end_time, hiringMeeting.timezone), TimeFormat.TimeWithTimezone)}</b>.
                </li>
              ))}
            </ul>
          </div>
        )}
        showFlash={Boolean(hiringMeeting.zoom_host_id && account && zoomHostConflicts.length >= account.zoom_host_concurrent_meeting_limit)}
        type="warning"
      />
      {isEditing ?
        <>
          <UnsavedEditsFlash
            newHiringMeeting={hiringMeeting}
            newHiringMeetingAttendees={attendees}
            newTimeSlot={timeSlot[0]}
            originalHiringMeeting={originalHiringMeeting}
            originalHiringMeetingAttendees={originalAttendees}
          />
          <StyledExpandableCheckboxInput
            isChecked={hiringMeeting.hiring_meeting_template.video_conferencing_enabled}
            label="Include video conferencing."
            onChange={handleIsVideoConferencingEnabledChange}
          >
            {hiringMeeting.hiring_meeting_template.video_conferencing_enabled && account?.video_conferencing_type === 'zoom' && (
              <ZoomHostSelectInput
                label="Zoom Meeting Host"
                onChange={handleZoomHostChange}
                selectedZoomHostId={hiringMeeting.zoom_host_id}
                selectedZoomHostType={hiringMeeting.zoom_host_type}
                zoomHostFilters={hiringMeeting.hiring_meeting_template.zoom_host_filters || []}
              />
            )}
          </StyledExpandableCheckboxInput>
          <StyledHiringMeetingAttendeeSelectInput
            onChange={handleAttendeeFiltersChange}
            value={hiringMeeting.hiring_meeting_template.hiring_meeting_attendee_filters || []}
          />
          {attendees && attendees?.length > 0 && <AttendeePool attendees={attendees} />}
          <AvailabilityPicker
            availabilities={timeSlot}
            backgroundEventTimeSlots={backgroundEventTimeSlots}
            businessHours={defaultBusinessHours(BusinessHourReferenceType.AvailabilityTemplate)}
            controlledTimezone={timezone}
            directory={account?.directory_type}
            eventTitle="Hiring Meeting"
            interviewerEventsAreLoading={eventsAreLoading}
            interviewerIds={attendees?.map(({ id }) => id)}
            isMulti={false}
            isRequired
            isSimplifiedViewByDefault
            minDuration={hiringMeeting.hiring_meeting_template.duration_minutes}
            onDateChange={(date) => setSelectedDate(date)}
            onTimezoneChange={handleTimezoneChange}
            setAvailabilities={setTimeSlot}
            showAvailabilitiesSummary={false}
            showEventWarnings={false}
            showInterviewerEventFilters
            showQuickSelectOptions={false}
          />
          <UpdateHiringMeetingConfirmModal
            isOpen={showConfirmModal}
            newHiringMeeting={hiringMeeting}
            newHiringMeetingAttendees={attendees}
            newTimeSlot={timeSlot[0]}
            onSuccess={handleUpdateSuccess}
            onToggle={toggleConfirmModal}
            originalHiringMeeting={originalHiringMeeting}
            originalHiringMeetingAttendees={originalAttendees}
          />
        </> :
        <HiringMeetingTable hiringMeeting={originalHiringMeeting} />
      }
    </StyledSection>
  );
};

export default HiringMeetingSection;
