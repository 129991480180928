import { CalendarEventTemplateType } from '../../../../types';

import type { CreatableCalendarEventTemplate } from '../../../../types';

export const NEW_CALENDAR_EVENT_TEMPLATE: CreatableCalendarEventTemplate = {
  name: '',
  type: undefined,
  title: '',
  description: '',
  additional_attendees: [],
  additional_optional_attendees: [],
};

export const DEFAULT_CALENDAR_EVENT_CONTENT: Record<CalendarEventTemplateType, { title: string; description: string }> = {
  [CalendarEventTemplateType.CandidateCalendarEvent]: {
    title: 'Interview',
    description: `<span>{{Candidate.FirstName}},</span><br>
<br>
<span>Thank you for taking the time to interview for the {{Job.Name}} position. We're excited to move forward with the process.</span><br>
<br>
<span>You will be speaking with {{Schedule.InterviewerFirstNames}}. Please find your schedule attached below.</span><br>
<br>
<span>{{Schedule.AgendaInCandidateTimezone}}</span><br>
<br>
<span>If you have any questions leading up to the interview, please don't hesitate to reach out!</span><br>
<br>
<span>–{{Scheduler.FirstName}}</span>`.replace(/\n/g, ''),
  },
  [CalendarEventTemplateType.HiringMeetingCalendarEvent]: {
    title: 'Hiring Meeting',
    description: `<span><b>Candidate</b>: {{Candidate.FullName}}</span><br>
<span><b>Job</b>: {{Job.Name}}</span><br>
<span><b>Stage</b>: {{Job.Stage.Name}}</span>`.replace(/\n/g, ''),
  },
};
