import StatusIndicator, { StatusIndicatorColor } from './StatusIndicator';
import { ApplicationSchedulingStatus } from '../../../types';

const statusIndicatorValues: Record<ApplicationSchedulingStatus, { label: string; color: StatusIndicatorColor }> = {
  [ApplicationSchedulingStatus.AvailabilityRequested]: {
    label: 'Availability Requested',
    color: StatusIndicatorColor.Green,
  },
  [ApplicationSchedulingStatus.Cancelled]: {
    label: 'Cancelled',
    color: StatusIndicatorColor.Red,
  },
  [ApplicationSchedulingStatus.Inactive]: {
    label: 'Inactive',
    color: StatusIndicatorColor.Gray,
  },
  [ApplicationSchedulingStatus.ReadyToRequestAvailability]: {
    label: 'Ready to Request Availability',
    color: StatusIndicatorColor.Orange,
  },
  [ApplicationSchedulingStatus.ReadyToSchedule]: {
    label: 'Ready to Schedule',
    color: StatusIndicatorColor.Orange,
  },
  [ApplicationSchedulingStatus.Scheduled]: {
    label: 'Scheduled',
    color: StatusIndicatorColor.Green,
  },
  [ApplicationSchedulingStatus.SchedulingLinkSent]: {
    label: 'Scheduling Link Sent',
    color: StatusIndicatorColor.Green,
  },
  [ApplicationSchedulingStatus.Unschedulable]: {
    label: 'Unschedulable',
    color: StatusIndicatorColor.Gray,
  },
};

interface Props {
  schedulingStatus: ApplicationSchedulingStatus;
}

const SchedulingStatusIndicator = ({ schedulingStatus }: Props) => (
  <StatusIndicator color={statusIndicatorValues[schedulingStatus].color}>
    {statusIndicatorValues[schedulingStatus].label}
  </StatusIndicator>
);

export default SchedulingStatusIndicator;
