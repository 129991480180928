import FeatureComparisonTable from '../FeatureComparisonTable';
import { StyledModal } from './styles';

import type { Competitor } from 'types';
import type { MouseEventHandler } from 'react';

interface Props {
  competitors: Competitor[];
  isOpen: boolean;
  onToggle: MouseEventHandler<HTMLButtonElement>;
}

const FullCompetitorComparisonModal = ({ competitors, isOpen, onToggle }: Props) => {
  return (
    <StyledModal
      cancelButtonValue="Close"
      isOpen={isOpen}
      onToggle={onToggle}
      showSubmitButton={false}
      title="Comparison table of scheduling features"
    >
      <FeatureComparisonTable competitors={competitors} />
    </StyledModal>
  );
};

export default FullCompetitorComparisonModal;
