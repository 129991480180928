import { useMemo } from 'react';
import { uniqueId } from 'lodash';

import Button from '../inputs/Button';
import Popover from '../utils/Popover';
import Tooltip from '../utils/Tooltip';

import type { ReactChild } from 'react';

export enum AvatarGroupSize {
  Large = 'large',
  Small = 'small',
}

interface Props {
  avatars: ReactChild[];
  id?: string;
  maxDisplayLength?: number;
  size: `${AvatarGroupSize}`;
}

const AvatarGroup = ({ avatars, id, maxDisplayLength, size }: Props) => {
  id = useMemo(() => id || uniqueId('avatar-group-'), [id]);

  const avatarsToDisplay = maxDisplayLength && avatars.length > maxDisplayLength ? avatars.slice(0, maxDisplayLength - 1) : avatars;
  const avatarsInPopover = maxDisplayLength && avatars.length > maxDisplayLength && avatars.slice(maxDisplayLength - 1);

  return (
    <div className="avatar-group">
      {avatarsToDisplay}
      {avatarsInPopover &&
        <>
          <Button
            className="btn-view-more"
            color="gray"
            iconRight={
              <div className={`avatar avatar-${size} avatar-user`}>
                +{avatarsInPopover.length}
              </div>
            }
            id={`${id}-btn-view-more`}
            tooltip={
              <Tooltip
                id={`${id}-view-more-tooltip`}
                position="top"
                value="View more"
              />
            }
          />
          <Popover
            position="bottom"
            target={`${id}-btn-view-more`}
          >
            <div className="avatar-group">
              {avatarsInPopover}
            </div>
          </Popover>
        </>
      }
    </div>
  );
};

export default AvatarGroup;
