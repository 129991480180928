import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize, isEmpty, isEqual, orderBy, remove } from 'lodash';
import { useMemo, useState } from 'react';

import { ATS, atsLabels } from 'types';
import Button from 'components/library/inputs/Button';
import { AtsHrefType, constructAtsHref } from 'libraries/candidates';
import Flash, { FlashType } from 'components/library/utils/Flash';
import OutboundLink from 'components/library/navigation/OutboundLink';
import SelectInput from 'components/library/inputs/SelectInput';
import { StyledCheckboxInput, StyledKeepInterviewActionInput } from './styles';
import Tooltip from 'components/library/utils/Tooltip';
import { useAccount } from 'hooks/queries/accounts';

import type { ChangeEvent } from 'react';
import type { AddInterviewAction, KeepInterviewAction, CopyInterviewActions } from '../types';
import type { Option } from 'components/library/inputs/SelectInput/types';
import type { Stage } from 'types';

interface Props {
  currentStage: Stage;
  onChange: (copyInterviewActions: CopyInterviewActions) => void;
  templateStage: Stage;
  value: CopyInterviewActions;
}

const CopyInterviewActionsInput = ({ currentStage, onChange, templateStage, value }: Props) => {
  const { data: account } = useAccount();
  const ats = useMemo(() => account?.ats_type ? atsLabels[account.ats_type] : 'your ATS', [account?.ats_type]);

  const keepInterviewActions = useMemo(() => value.keep, [value]);
  const addInterviewActions = useMemo(() => value.add, [value]);
  const templateStageInterviewsThatCannotBeAdded = useMemo(() => (
    account?.ats_type === ATS.Greenhouse ?
      (templateStage.stage_interviews || []).filter(({ ats_id, id }) => ats_id && !keepInterviewActions.some(({ templateStageInterview }) => templateStageInterview?.id === id)) :
      []
  ), [account?.ats_type, templateStage.stage_interviews, keepInterviewActions]);
  const [viewTemplateStageInterviewsThatCannotBeAdded, setViewTemplateStageInterviewsThatCannotBeAdded] = useState<boolean>(false);

  const templateStageInterviewOptions: Option<string>[] = useMemo(() => {
    return (templateStage.stage_interviews || []).map(({ id, name }) => ({
      label: name,
      value: id,
    }));
  }, [templateStage.stage_interviews, addInterviewActions]);

  const handleKeepInterviewActionChange = (e: ChangeEvent<HTMLInputElement>, action: KeepInterviewAction) => {
    const isNowSelected = e.target.checked;
    const updatedKeepInterviewActions = keepInterviewActions.map((oldAction) => (
      isEqual(oldAction, action) ? { ...oldAction, isSelected: isNowSelected } : oldAction
    ));

    let updatedAddInterviewActions = [...addInterviewActions];
    if (action.templateStageInterview) {
      if (isNowSelected) {
        // If an interview will be kept and there is a selected template interview,
        // remove the checkbox to add the template interview so that it cannot be added redundantly.
        remove(updatedAddInterviewActions, ({ templateStageInterview }) => action.templateStageInterview?.id === templateStageInterview.id);
      } else if (!Boolean(action.templateStageInterview.ats_id)) {
        // If an interview will not be kept, but there was previously a template interview selected for it,
        // allow the template interview to be added again (unless it is synced from the ATS).
        // It should be selected to add by default.
        updatedAddInterviewActions.push({
          templateStageInterview: action.templateStageInterview,
          isSelected: true,
        });
      }
    }
    updatedAddInterviewActions = orderBy(updatedAddInterviewActions, 'templateStageInterview.position');

    onChange({
      keep: updatedKeepInterviewActions,
      add: updatedAddInterviewActions,
    });
  };

  const handleTemplateStageInterviewChange = (option: Option<string> | null, action: KeepInterviewAction) => {
    const newTemplateStageInterview = templateStage.stage_interviews?.find(({ id }) => id === option?.value);

    const updatedKeepInterviewActions = keepInterviewActions.map((oldAction) => (
      isEqual(oldAction, action) ? { ...oldAction, templateStageInterview: newTemplateStageInterview } : oldAction
    ));

    let updatedAddInterviewActions = [...addInterviewActions];
    // If a template interview is selected for copying to an existing interview,
    // remove the checkbox to add the template interview so that it cannot be added redundantly.
    if (newTemplateStageInterview) {
      remove(updatedAddInterviewActions, ({ templateStageInterview }) => newTemplateStageInterview.id === templateStageInterview.id);
    }
    // If there was a previously selected template interview, allow it to be added again (unless it is synced from the ATS).
    // It should be selected to add by default.
    if (action.templateStageInterview && !Boolean(action.templateStageInterview.ats_id)) {
      updatedAddInterviewActions.push({
        templateStageInterview: action.templateStageInterview,
        isSelected: true,
      });
    }
    updatedAddInterviewActions = orderBy(updatedAddInterviewActions, 'templateStageInterview.position');

    onChange({
      keep: updatedKeepInterviewActions,
      add: updatedAddInterviewActions,
    });
  };

  const handleAddInterviewActionChange = (e: ChangeEvent<HTMLInputElement>, action: AddInterviewAction) => {
    const updatedAddInterviewActions = addInterviewActions.map((oldAction) => (
      isEqual(oldAction, action) ? { ...oldAction, isSelected: e.target.checked } : oldAction
    ));
    onChange({
      keep: keepInterviewActions,
      add: updatedAddInterviewActions,
    });
  };

  return (
    <>
      <Flash
        message={
          <>
            <p>
              We cannot add some interviews because they require {capitalize(account?.ats_type)} scorecards.
              <Button
                color="no-outline"
                iconRight={<FontAwesomeIcon icon={viewTemplateStageInterviewsThatCannotBeAdded ? faCaretUp : faCaretDown} />}
                onClick={() => setViewTemplateStageInterviewsThatCannotBeAdded((prevValue) => !prevValue)}
                size="small"
                value={`${viewTemplateStageInterviewsThatCannotBeAdded ? 'Hide' : 'Show'} interviews`}
              />
            </p>
            {viewTemplateStageInterviewsThatCannotBeAdded && (
              <>
                <ul>
                  {templateStageInterviewsThatCannotBeAdded.map(({ id, name }) => <li key={id}><b>{name}</b></li>)}
                </ul>
                <p>
                  If you want to add these interviews,&nbsp;
                  <OutboundLink
                    href={constructAtsHref(account!, AtsHrefType.InterviewPlan, { jobAtsId: currentStage.job.ats_id })}
                    label={`Open ${capitalize(account!.ats_type!)} interview plan`}
                  >
                    edit your {capitalize(account!.ats_type!)} interview plan
                  </OutboundLink>
                  .
                </p>
              </>
            )}
          </>
        }
        showFlash={!isEmpty(templateStageInterviewsThatCannotBeAdded)}
        type={FlashType.Info}
      />
      {keepInterviewActions.map((action) => (
        <StyledKeepInterviewActionInput key={action.stageInterview.id}>
          <StyledCheckboxInput
            isChecked={action.isSelected}
            isDisabled={Boolean(action.stageInterview.ats_id)}
            label={<span>Keep <b>{action.stageInterview.name}</b>{action.isSelected ? ' and copy it from' : '.'}</span>}
            onChange={(e) => handleKeepInterviewActionChange(e, action)}
            tooltip={action.stageInterview.ats_id ?
              <Tooltip
                id={`${action.stageInterview.id}-copy-preferences-tooltip`}
                value={`We cannot delete an interview that is in your ${ats} interview plan.`}
              /> :
              undefined
            }
          />
          {action.isSelected && (
            <SelectInput
              isClearable
              menuPortalTarget={document.querySelector('.ReactModalPortal') as HTMLElement || undefined}
              onChange={(option) => handleTemplateStageInterviewChange(option, action)}
              options={templateStageInterviewOptions}
              placeholder="current settings"
              value={templateStageInterviewOptions.find(({ value }) => value === action.templateStageInterview?.id) || null}
            />
          )}
        </StyledKeepInterviewActionInput>
      ))}
      {addInterviewActions.map((action) => (
        <StyledCheckboxInput
          isChecked={action.isSelected}
          key={action.templateStageInterview.id}
          label={<span>Add <b>{action.templateStageInterview.name}</b>.</span>}
          onChange={(e) => handleAddInterviewActionChange(e, action)}
        />
      ))}
    </>
  );
};

export default CopyInterviewActionsInput;
