import { orderBy, sumBy, toPairs } from 'lodash';

import { formatNumberAsWord } from '../../../../../../libraries/formatters';

import type { ConflictTotals } from '../../../../../../hooks/queries/schedules';

interface Props {
  conflictTotals: ConflictTotals;
}

const ScheduleOptionsConflictSummary = ({ conflictTotals }: Props) => {
  const conflictCounts = toPairs(conflictTotals).map((pair) => ({
    numConflicts: parseInt(pair[0], 10),
    numSchedules: pair[1],
  }));
  const sortedConflictCounts = orderBy(conflictCounts, 'numConflicts');
  const totalRemainderSchedules = sortedConflictCounts.length > 2 && sumBy(sortedConflictCounts.slice(2), 'numSchedules');

  if (sortedConflictCounts[0].numConflicts === 0) {
    return (
      <p>
        <span>
          We found zero-conflict options! 🎉
        </span>
      </p>
    );
  }

  return (
    <p>
      <span>
        We found {sortedConflictCounts[0].numSchedules === 1 ? 'a schedule' : 'schedules'} with {formatNumberAsWord(sortedConflictCounts[0].numConflicts)} conflict{sortedConflictCounts[0].numConflicts === 1 ? '' : 's'}
      </span>
      {sortedConflictCounts.length > 1 ?
        <span>
          &nbsp;and {sortedConflictCounts[1].numSchedules === 1 ? 'a schedule' : 'schedules'} with {formatNumberAsWord(sortedConflictCounts[1].numConflicts)} conflict{sortedConflictCounts[1].numConflicts === 1 ? '' : 's'}.
        </span> :
        '.'
      }
      {sortedConflictCounts.length > 2 &&
        <span>
          &nbsp;We found {totalRemainderSchedules === 1 ? 'an additional schedule' : 'additional schedules'} with {formatNumberAsWord(sortedConflictCounts[2].numConflicts)} or more conflicts.
        </span>
      }
    </p>
  );
};

export default ScheduleOptionsConflictSummary;
