import Moment from 'moment-timezone';

import { formatMoment, TimeFormat } from '../../../../../../libraries/time';
import { useRoomsMap } from '../../../../../../hooks/queries/rooms';
import { useUsersMap } from '../../../../../../hooks/queries/users';

import type { Conflict } from '../types';

const OUTSIDE_WORKING_HOURS_EVENT_TITLE = 'Outside Working Hours';
const DAILY_INTERVIEW_LIMIT_REACHED_EVENT_TITLE = 'Daily Interview Limit Reached';
const WEEKLY_INTERVIEW_LIMIT_REACHED_EVENT_TITLE = 'Weekly Interview Limit Reached';

interface Props {
  conflicts: Conflict[];
  timezone: string;
}

const ScheduleConflictList = ({ conflicts, timezone }: Props) => {
  const rooms = useRoomsMap();
  const users = useUsersMap({ archived: true });

  return (
    <ul>
      {conflicts.map((conflict, i) => {
        const resourceName = conflict.type === 'room' ? rooms[conflict.resourceId]?.name : (users[conflict.resourceId]?.name || users[conflict.resourceId]?.email);
        if (conflict.event.title === OUTSIDE_WORKING_HOURS_EVENT_TITLE) {
          return (
            <li key={`conflict-${i}`}>
              <b>{conflict.interview.name} ({formatMoment(Moment.tz(conflict.interview.start_time, timezone), TimeFormat.Time)}&ndash;{formatMoment(Moment.tz(conflict.interview.start_time, timezone).add(conflict.interview.duration_minutes, 'minutes'), TimeFormat.TimeWithTimezone)})</b> is outside of {resourceName}&apos;s working hours.
            </li>
          );
        }
        if (conflict.event.title === DAILY_INTERVIEW_LIMIT_REACHED_EVENT_TITLE) {
          return (
            <li key={`conflict-${i}`}>
              {resourceName} has reached their <b>daily interview limit</b>.
            </li>
          );
        }
        if (conflict.event.title === WEEKLY_INTERVIEW_LIMIT_REACHED_EVENT_TITLE) {
          return (
            <li key={`conflict-${i}`}>
              {resourceName} has reached their <b>weekly interview limit</b>.
            </li>
          );
        }

        const conflictStartTime = Moment.tz(conflict.event.start_time, timezone);
        const conflictEndTime = Moment.tz(conflict.event.end_time, timezone);

        let conflictStart = formatMoment(conflictStartTime, TimeFormat.Time);
        let conflictEnd = formatMoment(conflictEndTime, TimeFormat.TimeWithTimezone);

        if (!conflictStartTime.isSame(conflictEndTime, 'day')) {
          // the days are different
          if (conflictStartTime.format('HH:mm') === '00:00' && conflictEndTime.format('HH:mm') === '00:00') {
            // the times are 00:00 to 00:00, so we should just show the dates
            conflictStart = formatMoment(conflictStartTime, TimeFormat.LongDayOfWeekMonthDay);
            conflictEnd = formatMoment(conflictEndTime, TimeFormat.LongDayOfWeekMonthDay);
          } else {
            // the event goes from a specific time to another specific time, so show the date and time
            conflictStart = formatMoment(conflictStartTime, TimeFormat.LongDayOfWeekMonthDayWithTimeAndComma);
            conflictEnd = formatMoment(conflictEndTime, TimeFormat.LongDayOfWeekMonthDayWithTimeAndTimezoneAndComma);
          }
        }

        return (
          <li key={`conflict-${i}`}>
            {conflict.isZoomConflict || conflict.event.buffer_time ?
              '' :
              (conflict.event.title === 'Busy' || conflict.event.private) ?
                `${resourceName} is busy ` :
                `${resourceName} has `
            }
            {conflict.event.buffer_time && `Buffer window for ${conflict.event.title === 'Busy' ? 'private ' : ''}`}
            <b>
              {!conflict.isZoomConflict && conflict.event.title === 'Busy' ?
                '' :
                `${conflict.event.title} `
              }
              ({conflictStart}&ndash;{conflictEnd})
            </b>{' '}
            during{' '}
            <b>
              {conflict.interview.name} ({formatMoment(Moment.tz(conflict.interview.start_time, timezone), TimeFormat.Time)}&ndash;{formatMoment(Moment.tz(conflict.interview.start_time, timezone).add(conflict.interview.duration_minutes, 'minutes'), TimeFormat.TimeWithTimezone)})
            </b>
          </li>
        );
      })}
    </ul>
  );
};

export default ScheduleConflictList;
