export enum EditorType {
  AvailabilityMessage = 'availability_message',
  AvailabilityRequestEmail = 'availability_request_email',
  CandidateCalendarEvent = 'candidate_calendar_event',
  CandidateEventDescription = 'candidate_event_description',
  HiringChannelName = 'hiring_channel_name',
  ConfirmationEmail = 'confirmation_email',
  HiringMeetingCalendarEvent = 'hiring_meeting_calendar_event',
  InterviewerEventDescription = 'interviewer_event_description',
  ManualEmail = 'manual_email',
  MultiBlockConfirmationEmail = 'multi_block_confirmation_email',
  SelfSchedulingRequestEmail = 'self_scheduling_request_email',
}

export enum Token {
  AvailabilityRequestLink = 'AvailabilityRequest.Link',
  CandidateEmail = 'Candidate.Email',
  CandidateFirstName = 'Candidate.FirstName',
  CandidateFullName = 'Candidate.FullName',
  CandidateLastName = 'Candidate.LastName',
  CandidateLinkedInURL = 'Candidate.LinkedInURL',
  CandidatePhoneNumber = 'Candidate.PhoneNumber',
  CandidateWebsite = 'Candidate.Website',
  CoordinatorEmail = 'Coordinator.Email',
  CoordinatorFirstName = 'Coordinator.FirstName',
  CoordinatorFullName = 'Coordinator.FullName',
  CoordinatorLastName = 'Coordinator.LastName',
  CoordinatorPhoneNumber = 'Coordinator.PhoneNumber',
  HiringManagerEmail = 'HiringManager.Email',
  HiringManagerFirstName = 'HiringManager.FirstName',
  HiringManagerFullName = 'HiringManager.FullName',
  HiringManagerLastName = 'HiringManager.LastName',
  HiringManagerPhoneNumber = 'HiringManager.PhoneNumber',
  InterviewDuration = 'Interview.Duration',
  InterviewEndTime = 'Interview.EndTime',
  InterviewLiveCodingLink = 'Interview.LiveCodingLink',
  InterviewName = 'Interview.Name',
  InterviewScorecardLink = 'Interview.ScorecardLink',
  InterviewStartTime = 'Interview.StartTime',
  JobName = 'Job.Name',
  JobOfficeName = 'Job.Office.Name',
  JobPostName = 'Job.PostName',
  JobStageAgenda = 'Job.Stage.Agenda',
  JobStageName = 'Job.Stage.Name',
  MeEmail = 'Me.Email',
  MeFirstName = 'Me.FirstName',
  MeFullName = 'Me.FullName',
  MeLastName = 'Me.LastName',
  MePhoneNumber = 'Me.PhoneNumber',
  RecruiterEmail = 'Recruiter.Email',
  RecruiterFirstName = 'Recruiter.FirstName',
  RecruiterFullName = 'Recruiter.FullName',
  RecruiterLastName = 'Recruiter.LastName',
  RecruiterPhoneNumber = 'Recruiter.PhoneNumber',
  RequesterEmail = 'Requester.Email',
  RequesterFirstName = 'Requester.FirstName',
  RequesterFullName = 'Requester.FullName',
  RequesterLastName = 'Requester.LastName',
  RequesterPhoneNumber = 'Requester.PhoneNumber',
  ScheduleAgendaInCandidateTimezone = 'Schedule.AgendaInCandidateTimezone',
  ScheduleAgendaInCandidateTimezoneWithCustomFormat = 'Schedule.AgendaInCandidateTimezoneWithCustomFormat',
  ScheduleAgendaInCandidateTimezoneWithZoomLinks = 'Schedule.AgendaInCandidateTimezoneWithZoomLinks',
  ScheduleAgendaInCandidateTimezoneWithoutInterviewNames = 'Schedule.AgendaInCandidateTimezoneWithoutInterviewNames',
  ScheduleAgendaInCandidateTimezoneWithoutInterviewers = 'Schedule.AgendaInCandidateTimezoneWithoutInterviewers',
  ScheduleAgendaInScheduleTimezone = 'Schedule.AgendaInScheduleTimezone',
  ScheduleAgendaInScheduleTimezoneWithCustomFormat = 'Schedule.AgendaInScheduleTimezoneWithCustomFormat',
  ScheduleAgendaInScheduleTimezoneWithZoomLinks = 'Schedule.AgendaInScheduleTimezoneWithZoomLinks',
  ScheduleAgendaInScheduleTimezoneWithoutInterviewNames = 'Schedule.AgendaInScheduleTimezoneWithoutInterviewNames',
  ScheduleAgendaInScheduleTimezoneWithoutInterviewers = 'Schedule.AgendaInScheduleTimezoneWithoutInterviewers',
  ScheduleCandidateTimezone = 'Schedule.CandidateTimezone',
  ScheduleCandidateTimezoneLongForm = 'Schedule.CandidateTimezoneLongForm',
  ScheduleDate = 'Schedule.Date',
  ScheduleDateInCandidateTimezone = 'Schedule.DateInCandidateTimezone',
  ScheduleDateWithoutYear = 'Schedule.DateWithoutYear',
  ScheduleDateWithoutYearInCandidateTimezone = 'Schedule.DateWithoutYearInCandidateTimezone',
  ScheduleDates = 'Schedule.Dates',
  ScheduleDatesInCandidateTimezone = 'Schedule.DatesInCandidateTimezone',
  ScheduleDatesWithoutYear = 'Schedule.DatesWithoutYear',
  ScheduleDatesWithoutYearInCandidateTimezone = 'Schedule.DatesWithoutYearInCandidateTimezone',
  ScheduleDayOfTheWeek = 'Schedule.DayOfTheWeek',
  ScheduleDayOfTheWeekInCandidateTimezone = 'Schedule.DayOfTheWeekInCandidateTimezone',
  ScheduleDaysOfTheWeek = 'Schedule.DaysOfTheWeek',
  ScheduleDaysOfTheWeekInCandidateTimezone = 'Schedule.DaysOfTheWeekInCandidateTimezone',
  ScheduleEndTime = 'Schedule.EndTime',
  ScheduleEndTimeInCandidateTimezone = 'Schedule.EndTimeInCandidateTimezone',
  ScheduleInterviewerFirstNames = 'Schedule.InterviewerFirstNames',
  ScheduleInterviewerFirstNamesWithLinkedinLinks = 'Schedule.InterviewerFirstNamesWithLinkedinLinks',
  ScheduleInterviewerFullNames = 'Schedule.InterviewerFullNames',
  ScheduleInterviewerFullNamesWithLinkedinLinks = 'Schedule.InterviewerFullNamesWithLinkedinLinks',
  ScheduleLocation = 'Schedule.Location',
  ScheduleRoomName = 'Schedule.RoomName',
  ScheduleRoomNames = 'Schedule.RoomNames',
  ScheduleStartTime = 'Schedule.StartTime',
  ScheduleStartTimeInCandidateTimezone = 'Schedule.StartTimeInCandidateTimezone',
  ScheduleTimezone = 'Schedule.Timezone',
  ScheduleTimezoneLongForm = 'Schedule.TimezoneLongForm',
  ScheduleTraineeFirstNames = 'Schedule.TraineeFirstNames',
  ScheduleTraineeFullNames = 'Schedule.TraineeFullNames',
  ScheduleVideoConferencingLink = 'Schedule.VideoConferencingLink',
  ScheduleVideoConferencingLinks = 'Schedule.VideoConferencingLinks',
  ScheduleVideoConferencingPasscode = 'Schedule.VideoConferencingPasscode',
  ScheduleVideoConferencingPasscodes = 'Schedule.VideoConferencingPasscodes',
  SchedulerEmail = 'Scheduler.Email',
  SchedulerFirstName = 'Scheduler.FirstName',
  SchedulerFullName = 'Scheduler.FullName',
  SchedulerLastName = 'Scheduler.LastName',
  SchedulerPhoneNumber = 'Scheduler.PhoneNumber',
  SelfSchedulingRequestLink = 'SelfSchedulingRequest.Link',
  SourcerEmail = 'Sourcer.Email',
  SourcerFirstName = 'Sourcer.FirstName',
  SourcerFullName = 'Sourcer.FullName',
  SourcerLastName = 'Sourcer.LastName',
  SourcerPhoneNumber = 'Sourcer.PhoneNumber',
}

export interface EditorSuggestion {
  value: Token;
  normalized: string;
}

export interface ExistingEditorAttachment {
  name: string;
  link?: string;
}

export type EditorAttachment =
  | ExistingEditorAttachment
  | File;

export function isExistingEditorAttachment (attachment: EditorAttachment): attachment is ExistingEditorAttachment {
  return attachment.hasOwnProperty('link');
}
