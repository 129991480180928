import styled from 'styled-components';

import DateRangeInput from '../DateRangeInput';

export const StyledDateRangeInput = styled(DateRangeInput)`
  &.input.date-range-input {
    min-width: auto;
    max-width: 231px;
    width: 231px;
  }

  .date-picker {
    width: 100%;

    .rdp-months {
      width: 100%;

      .rdp-day {
        padding: 7px;
      }
    }
  }
`;
