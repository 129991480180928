import Moment from 'moment-timezone';
import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize } from 'lodash';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { useMemo, useState } from 'react';

import Button from '../library/inputs/Button';
import { formatDuration } from '../../libraries/formatters';
import { formatMoment, TimeFormat } from '../../libraries/time';
import { getAccessibleTextColor } from '../../libraries/color';

import type { MouseEventHandler } from 'react';

interface Props {
  action: 'cancel' | 'reschedule';
  brandColor?: string;
  enabled: boolean;
  endTime: Moment.Moment;
  noticeHours?: number;
  onButtonClick: MouseEventHandler<HTMLButtonElement>;
  startTime: Moment.Moment;
}

const UpdateQuestion = ({ action, brandColor, enabled, endTime, noticeHours, onButtonClick, startTime }: Props) => {

  const [isExpanded, setIsExpanded] = useState(false);

  const threshold = useMemo(() => {
    if (!enabled || noticeHours === undefined) {
      return null;
    }

    return startTime.clone().add(-1 * noticeHours, 'hours');
  }, [enabled, enabled && noticeHours, startTime]);

  const styles = useMemo(() => {
    if (!brandColor) {
      return undefined;
    }

    return {
      color: getAccessibleTextColor('white', [brandColor, '#666666']),
    };
  }, [brandColor]);

  const handleExpandToggle = () => {
    setIsExpanded((prev) => !prev);
  };

  return (
    <div className="question">
      <span
        className={classnames(['question-link', brandColor && 'link-custom-styled'])}
        onClick={handleExpandToggle}
        style={styles}
      >
        <span className="question-text">
          Can I {action}?
        </span>
        &nbsp;
        <FontAwesomeIcon icon={isExpanded ? faAngleUp : faAngleDown} />
      </span>
      {(() => {
        // This is a IIFE because there's a lot of branching logic that's not
        // well-suited for a bunch of ternary expressions. It could be pulled
        // out into its own component, but that seemed too heavy (i.e. we'd be
        // passing in all of the same props that's passed into UpdateQuestion).
        const now = Moment();

        // The answer is not expanded.
        if (!isExpanded) {
          return null;
        }

        // This action is not enabled.
        if (!threshold || noticeHours === undefined) {
          return <p>To {action} your schedule, please reach out to your coordinator.</p>;
        }

        // The time limit to do this action has not passed yet.
        if (threshold.isAfter(now)) {
          return (
            <>
              <p>
                Yes! You have until <b>{formatMoment(threshold, TimeFormat.LongDayOfWeekMonthDay)}</b> at <b>{formatMoment(threshold, TimeFormat.TimeWithTimezone)}</b> to {action} your interview if something comes up.
              </p>
              <p>
                We kindly ask that you are considerate of our team&apos;s schedules and only {action} if you need to.
              </p>
              <div className="button-container">
                <Button
                  brandColor={brandColor}
                  color="gem-blue"
                  onClick={onButtonClick}
                  value={`${capitalize(action)} Interview`}
                />
              </div>
            </>
          );
        }

        // The interview has already completely passed.
        if (endTime.isSameOrBefore(now)) {
          return (
            <p>
              Because your interview has passed, please reach out to your coordinator.
            </p>
          );
        }

        // The interview has started, but hasn't ended yet.
        if (startTime.isSameOrBefore(now)) {
          return (
            <p>
              Because your interview has started, please reach out to your coordinator.
            </p>
          );
        }

        // We are passed the threshold, but the interview hasn't started yet.
        return (
          <p>
            Because your interview is less than {formatDuration(noticeHours * 60)} from now, please reach out to your coordinator.
          </p>
        );
      })()}
    </div>
  );
};

export default UpdateQuestion;
