import Moment from 'moment-timezone';
import { useCallback, useMemo } from 'react';

import Avatar from 'components/library/data-display/Avatar';
import AvatarGroup from 'components/library/data-display/AvatarGroup';
import { formatDate, formatDuration } from 'libraries/formatters';
import { formatMoment, TimeFormat } from 'libraries/time';
import InterviewSlotsTable from './InterviewSlotsTable';
import { StyledTimesTable } from './styles';

import type { TableSchema } from 'components/library/data-display/Table';
import type { InterviewBatchTime } from 'types';

interface Props {
  times: InterviewBatchTime[];
  totalCount: number;
}

const InterviewBatchTimesTable = ({
  times,
  totalCount,
}: Props) => {
  const columns = useMemo<TableSchema<InterviewBatchTime>>(() => [{
    header: 'Date',
    displayValue: ({ start_time, timezone }) => formatDate(start_time, timezone),
  }, {
    header: 'Times',
    displayValue: ({ start_time, end_time, timezone }) => `${formatMoment(Moment.tz(start_time, timezone), TimeFormat.Time)} - ${formatMoment(Moment.tz(end_time, timezone), TimeFormat.TimeWithTimezone)}`,
  }, {
    header: 'Interview',
    displayValue: ({ stage_interview }) => stage_interview?.name,
  }, {
    header: 'Duration',
    displayValue: ({ interview_template }) => formatDuration(interview_template?.duration_minutes || 0, true),
  }, {
    header: 'Candidates per Slot',
    displayValue: ({ candidates_per_slot }) => candidates_per_slot,
  }, {
    header: 'Interviewer(s)',
    displayValue: ({ interview_template }) => {
      const userIds = (interview_template?.interviewer_templates || [])
      .map(({ interviewer_filters }) => interviewer_filters?.[0]?.interviewer_filter_expressions?.[0]?.filterable_id)
      .filter((userId) => Boolean(userId));
      return (
        <AvatarGroup
          avatars={userIds.map((userId) => <Avatar key={userId} size="small" userId={userId} />)}
          size="small"
        />
      );
    },
  }], [times]);

  const displayExpandedContent = useCallback((batchTime: InterviewBatchTime) => {
    return (
      <InterviewSlotsTable
        slots={batchTime.slots}
        timezone={batchTime.timezone}
      />
    );
  }, []);

  return (
    <StyledTimesTable
      collapseRowTooltipText="Hide slots"
      data={times}
      dataDescriptor="times"
      displayExpandedContent={displayExpandedContent}
      expandRowTooltipText="View slots"
      layout="vertical"
      schema={columns}
      showResultsCount
      totalCount={totalCount}
    />
  );
};

export default InterviewBatchTimesTable;
