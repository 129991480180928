import LinkToolbarOption from './LinkToolbarOption';
import TokenToolbarOption from './TokenToolbarOption';
import AttachmentsToolbarOption from './AttachmentsToolbarOption';
import BasicToolbarOption from './BasicToolbarOption';
import ImageToolbarOption from './ImageToolbarOption';
import { ElementType, InlineStyleType } from '../../../../../types';

import type { Dispatch, SetStateAction } from 'react';
import type { EditorAttachment } from '../../../../../types';

interface Props {
  allowImages: boolean;
  allowTokens?: boolean;
  isDisabled: boolean;
  setAttachments?: Dispatch<SetStateAction<EditorAttachment[]>>;
}

const Toolbar = ({ allowImages, allowTokens = true, isDisabled, setAttachments }: Props) => {
  return (
    <div className="editor-toolbar">
      <BasicToolbarOption isDisabled={isDisabled} style={InlineStyleType.Bold} />
      <BasicToolbarOption isDisabled={isDisabled} style={InlineStyleType.Italic} />
      <BasicToolbarOption isDisabled={isDisabled} style={InlineStyleType.Underline} />
      <LinkToolbarOption isDisabled={isDisabled} />
      {allowImages && <ImageToolbarOption isDisabled={isDisabled} />}
      <BasicToolbarOption isDisabled={isDisabled} style={ElementType.UnorderedList} />
      <BasicToolbarOption isDisabled={isDisabled} style={ElementType.OrderedList} />
      {allowTokens && <TokenToolbarOption isDisabled={isDisabled} />}
      {setAttachments && (
        <AttachmentsToolbarOption
          isDisabled={isDisabled}
          setAttachments={setAttachments}
        />
      )}
    </div>
  );
};

export default Toolbar;
