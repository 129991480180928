import classnames from 'classnames';

import type { ReactNode } from 'react';

interface Props {
  className?: string;
  label: ReactNode;
  leftIcon?: ReactNode;
  rightIcon?: ReactNode;
  secondaryText?: ReactNode;
}

const ListItem = ({ className, label, leftIcon, rightIcon, secondaryText }: Props) =>
  <div className={classnames(['list-item', className])}>
    {leftIcon &&
      <div className="left-icon-container">
        {leftIcon}
      </div>
    }
    <div className="list-item-label-container">
      <span className="list-item-label">{label}</span>
      <span className="secondary-text">{secondaryText}</span>
    </div>
    {rightIcon &&
      <div className="right-icon-container">
        {rightIcon}
      </div>
    }
  </div>
;

export default ListItem;
