import { useMutation, useQuery, useQueryClient } from 'react-query';

import InterviewPlanner from '../../libraries/interviewplanner';
import { QueryKey as ApplicationQueryKey } from './applications';

import type { EditableAttachment, Email } from '../../types';
import type { UseQueryOptions } from 'react-query';

export enum QueryKey {
  ListEmails = 'ListEmails',
}

interface ListEmailsQuery {
  limit?: number;
  offset?: number;
}

interface ListEmailsData {
  emails: Email[];
  total: number;
}

export const useEmails = (applicationId: string, query: ListEmailsQuery = {}, options: UseQueryOptions<ListEmailsData, Error> = {}) => {
  return useQuery<ListEmailsData, Error>([QueryKey.ListEmails, applicationId, query], () => {
    return InterviewPlanner.request('GET', `/applications/${applicationId}/emails`, null, query);
  }, options);
};

export interface CreateEmailPayload {
  email_template: {
    id?: string;
    subject: string;
    sender_name: string;
    sender_email: string;
    cc_emails?: string[];
    bcc_emails?: string[];
    body: string;
    attachments?: EditableAttachment[];
    archived?: boolean;
  };
}

interface CreateEmailMutationVariables {
  applicationId: string;
  payload?: CreateEmailPayload;
}

export const useCreateEmail = () => {
  const queryClient = useQueryClient();

  return useMutation<Email, Error, CreateEmailMutationVariables>(({ applicationId, payload }) => {
    let isMultipartForm = false;

    if (payload?.email_template?.attachments) {
      payload.email_template.attachments = payload.email_template.attachments.map((attachment) => {
        const isNew = attachment instanceof File;
        isMultipartForm = isMultipartForm || isNew;
        return {
          name: attachment.name,
          new: isNew,
          index: isNew ? undefined : attachment.index,
          file: isNew ? attachment : undefined,
        };
      });
    }

    return InterviewPlanner.request('POST', `/applications/${applicationId}/emails`, payload, null, isMultipartForm);
  }, {
    onSuccess: (_data, { applicationId }) => {
      queryClient.invalidateQueries([QueryKey.ListEmails, applicationId]);
      queryClient.invalidateQueries([ApplicationQueryKey.RetrieveApplication, applicationId]);
    },
  });
};
