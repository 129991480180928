import styled from 'styled-components';

import ExpandableCheckboxInput from '../../../../../library/inputs/ExpandableCheckboxInput';
import Flash from '../../../../../library/utils/Flash';
import HiringMeetingAttendeeSelectInput from '../../../../../library/inputs/HiringMeetingAttendeeSelectInput';
import Section from 'components/library/layout/Section';
import Table from 'components/library/data-display/Table';

export const StyledContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.PRODUCT.SECONDARY_BORDER};
  border-radius: 6px;
  flex-grow: 1;
  height: max-content;
  margin-left: 1em;
`;

export const StyledFlash = styled(Flash)`
  margin: 1em;
`;

export const StyledSection = styled(Section)`
  margin: 1em 0;

  .flash {
    margin: 0;
    margin-bottom: 1em;
  }
`;

// The typing for this is weird because of styled-components. This is inspired from
// https://github.com/styled-components/styled-components/issues/1803#issuecomment-857092410
export const StyledHiringMeetingTable = styled(Table)`
  th {
    vertical-align: middle;
  }
` as typeof Table;

// The typing for this is weird because of styled-components. This is inspired from
// https://github.com/styled-components/styled-components/issues/1803#issuecomment-857092410
export const StyledSummaryTable = styled(Table)`
  th {
    vertical-align: middle;
  }

  .list-item,
  .tag-room {
    padding: 0;
  }
` as typeof Table;

export const StyledDateScheduledContainer = styled.div`
  align-items: center;
  display: flex;

  .list-item {
    margin-left: 1em;
  }
`;

export const StyledHiringMeetingAttendeeSelectInput = styled(HiringMeetingAttendeeSelectInput)`
  /* Needs to be > 1 to be on top of other select input icons (since the icon has a z-index of 1). */
  z-index: 2;
`;

export const StyledExpandableCheckboxInput = styled(ExpandableCheckboxInput)`
  margin-bottom: 1.5em;
`;
