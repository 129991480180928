import classnames from 'classnames';

import type { ReactNode } from 'react';

export enum BannerType {
  Info = 'info',
  Warning = 'warning',
}

interface Props {
  children: ReactNode;
  className?: string;
  type?: `${BannerType}`;
}

const Banner = ({ children, className, type = BannerType.Info }: Props) => (
  <div className={classnames('banner', `banner-${type}`, className)}>
    {children}
  </div>
);

export default Banner;
