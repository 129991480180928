import { CSSTransition } from 'react-transition-group';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import innerText from 'react-innertext';
import { useMemo, useState } from 'react';

import Button from '../../library/inputs/Button';
import Card from '../../library/layout/Card';

import type { ReactNode } from 'react';

interface Props {
  answer: ReactNode;
  question: ReactNode;
}

const PricingQuestion = ({ answer, question }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const questionText = useMemo(() => innerText(question), [question]);
  const icon = useMemo(() => <FontAwesomeIcon icon={isExpanded ? faAngleUp : faAngleDown} />, [isExpanded]);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
    analytics.track(`${isExpanded ? 'Closed' : 'Opened'} Pricing Question`, { question: questionText });
  };

  return (
    <Card
      actions={
        <Button
          color="no-outline"
          iconRight={icon}
          onClick={toggleExpand}
          size="small"
          value={`${isExpanded ? 'Hide' : 'Show'} Answer`}
        />
      }
      className="pricing-question"
      title={question}
    >
      <CSSTransition
        appear
        classNames="answer-transition"
        in={isExpanded}
        timeout={1000}
      >
        <div className="answer">
          {answer}
        </div>
      </CSSTransition>
    </Card>
  );
};

export default PricingQuestion;
