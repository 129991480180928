import { Transforms } from 'slate';

import { Token } from '../../types';
import { isFile } from '../form-data';

import type { EditorSuggestion, CustomEditor, EditorType, EditableAttachment } from '../../types';

function convertToSuggestion (token: Token): EditorSuggestion {
  return {
    value: token,
    normalized: `{{${token.toLowerCase()}}}`,
  };
}

export const SUGGESTIONS_BY_TYPE: Record<EditorType, EditorSuggestion[]> = {
  availability_message: [
    Token.CandidateEmail,
    Token.CandidateFirstName,
    Token.CandidateFullName,
    Token.CandidateLastName,
    Token.CandidateLinkedInURL,
    Token.CandidatePhoneNumber,
    Token.CandidateWebsite,
    Token.CoordinatorEmail,
    Token.CoordinatorFirstName,
    Token.CoordinatorFullName,
    Token.CoordinatorLastName,
    Token.CoordinatorPhoneNumber,
    Token.HiringManagerEmail,
    Token.HiringManagerFirstName,
    Token.HiringManagerFullName,
    Token.HiringManagerLastName,
    Token.HiringManagerPhoneNumber,
    Token.JobName,
    Token.JobOfficeName,
    Token.JobPostName,
    Token.JobStageAgenda,
    Token.JobStageName,
    Token.RecruiterEmail,
    Token.RecruiterFirstName,
    Token.RecruiterFullName,
    Token.RecruiterLastName,
    Token.RecruiterPhoneNumber,
    Token.RequesterEmail,
    Token.RequesterFirstName,
    Token.RequesterFullName,
    Token.RequesterLastName,
    Token.RequesterPhoneNumber,
    Token.SourcerEmail,
    Token.SourcerFirstName,
    Token.SourcerFullName,
    Token.SourcerLastName,
    Token.SourcerPhoneNumber,
  ].map(convertToSuggestion),
  availability_request_email: [
    Token.AvailabilityRequestLink,
    Token.CandidateEmail,
    Token.CandidateFirstName,
    Token.CandidateFullName,
    Token.CandidateLastName,
    Token.CandidateLinkedInURL,
    Token.CandidatePhoneNumber,
    Token.CandidateWebsite,
    Token.CoordinatorEmail,
    Token.CoordinatorFirstName,
    Token.CoordinatorFullName,
    Token.CoordinatorLastName,
    Token.CoordinatorPhoneNumber,
    Token.HiringManagerEmail,
    Token.HiringManagerFirstName,
    Token.HiringManagerFullName,
    Token.HiringManagerLastName,
    Token.HiringManagerPhoneNumber,
    Token.JobName,
    Token.JobOfficeName,
    Token.JobPostName,
    Token.JobStageAgenda,
    Token.JobStageName,
    Token.MeEmail,
    Token.MeFirstName,
    Token.MeFullName,
    Token.MeLastName,
    Token.MePhoneNumber,
    Token.RecruiterEmail,
    Token.RecruiterFirstName,
    Token.RecruiterFullName,
    Token.RecruiterLastName,
    Token.RecruiterPhoneNumber,
    Token.RequesterEmail,
    Token.RequesterFirstName,
    Token.RequesterFullName,
    Token.RequesterLastName,
    Token.RequesterPhoneNumber,
    Token.SourcerEmail,
    Token.SourcerFirstName,
    Token.SourcerFullName,
    Token.SourcerLastName,
    Token.SourcerPhoneNumber,
  ].map(convertToSuggestion),
  candidate_calendar_event: [
    Token.CandidateEmail,
    Token.CandidateFirstName,
    Token.CandidateFullName,
    Token.CandidateLastName,
    Token.CandidateLinkedInURL,
    Token.CandidatePhoneNumber,
    Token.CandidateWebsite,
    Token.CoordinatorEmail,
    Token.CoordinatorFirstName,
    Token.CoordinatorFullName,
    Token.CoordinatorLastName,
    Token.CoordinatorPhoneNumber,
    Token.HiringManagerEmail,
    Token.HiringManagerFirstName,
    Token.HiringManagerFullName,
    Token.HiringManagerLastName,
    Token.HiringManagerPhoneNumber,
    Token.InterviewName,
    Token.JobName,
    Token.JobOfficeName,
    Token.JobPostName,
    Token.JobStageAgenda,
    Token.JobStageName,
    Token.RecruiterEmail,
    Token.RecruiterFirstName,
    Token.RecruiterFullName,
    Token.RecruiterLastName,
    Token.RecruiterPhoneNumber,
    Token.ScheduleAgendaInCandidateTimezone,
    Token.ScheduleAgendaInCandidateTimezoneWithCustomFormat,
    Token.ScheduleAgendaInCandidateTimezoneWithZoomLinks,
    Token.ScheduleAgendaInCandidateTimezoneWithoutInterviewNames,
    Token.ScheduleAgendaInCandidateTimezoneWithoutInterviewers,
    Token.ScheduleAgendaInScheduleTimezone,
    Token.ScheduleAgendaInScheduleTimezoneWithCustomFormat,
    Token.ScheduleAgendaInScheduleTimezoneWithZoomLinks,
    Token.ScheduleAgendaInScheduleTimezoneWithoutInterviewNames,
    Token.ScheduleAgendaInScheduleTimezoneWithoutInterviewers,
    Token.ScheduleCandidateTimezone,
    Token.ScheduleCandidateTimezoneLongForm,
    Token.ScheduleDate,
    Token.ScheduleDateInCandidateTimezone,
    Token.ScheduleDateWithoutYear,
    Token.ScheduleDateWithoutYearInCandidateTimezone,
    Token.ScheduleDayOfTheWeek,
    Token.ScheduleDayOfTheWeekInCandidateTimezone,
    Token.ScheduleEndTime,
    Token.ScheduleEndTimeInCandidateTimezone,
    Token.ScheduleInterviewerFirstNames,
    Token.ScheduleInterviewerFirstNamesWithLinkedinLinks,
    Token.ScheduleInterviewerFullNames,
    Token.ScheduleInterviewerFullNamesWithLinkedinLinks,
    Token.ScheduleLocation,
    Token.ScheduleRoomName,
    Token.ScheduleStartTime,
    Token.ScheduleStartTimeInCandidateTimezone,
    Token.ScheduleTimezone,
    Token.ScheduleTimezoneLongForm,
    Token.ScheduleTraineeFirstNames,
    Token.ScheduleTraineeFullNames,
    Token.ScheduleVideoConferencingLink,
    Token.ScheduleVideoConferencingPasscode,
    Token.SchedulerEmail,
    Token.SchedulerFirstName,
    Token.SchedulerFullName,
    Token.SchedulerLastName,
    Token.SchedulerPhoneNumber,
    Token.SourcerEmail,
    Token.SourcerFirstName,
    Token.SourcerFullName,
    Token.SourcerLastName,
    Token.SourcerPhoneNumber,
  ].map(convertToSuggestion),
  candidate_event_description: [
    Token.CandidateEmail,
    Token.CandidateFirstName,
    Token.CandidateFullName,
    Token.CandidateLastName,
    Token.CandidateLinkedInURL,
    Token.CandidatePhoneNumber,
    Token.CandidateWebsite,
    Token.CoordinatorEmail,
    Token.CoordinatorFirstName,
    Token.CoordinatorFullName,
    Token.CoordinatorLastName,
    Token.CoordinatorPhoneNumber,
    Token.HiringManagerEmail,
    Token.HiringManagerFirstName,
    Token.HiringManagerFullName,
    Token.HiringManagerLastName,
    Token.HiringManagerPhoneNumber,
    Token.InterviewName,
    Token.JobName,
    Token.JobOfficeName,
    Token.JobPostName,
    Token.JobStageAgenda,
    Token.JobStageName,
    Token.RecruiterEmail,
    Token.RecruiterFirstName,
    Token.RecruiterFullName,
    Token.RecruiterLastName,
    Token.RecruiterPhoneNumber,
    Token.ScheduleAgendaInCandidateTimezone,
    Token.ScheduleAgendaInCandidateTimezoneWithCustomFormat,
    Token.ScheduleAgendaInCandidateTimezoneWithZoomLinks,
    Token.ScheduleAgendaInCandidateTimezoneWithoutInterviewNames,
    Token.ScheduleAgendaInCandidateTimezoneWithoutInterviewers,
    Token.ScheduleAgendaInScheduleTimezone,
    Token.ScheduleAgendaInScheduleTimezoneWithCustomFormat,
    Token.ScheduleAgendaInScheduleTimezoneWithZoomLinks,
    Token.ScheduleAgendaInScheduleTimezoneWithoutInterviewNames,
    Token.ScheduleAgendaInScheduleTimezoneWithoutInterviewers,
    Token.ScheduleCandidateTimezone,
    Token.ScheduleCandidateTimezoneLongForm,
    Token.ScheduleDate,
    Token.ScheduleDateInCandidateTimezone,
    Token.ScheduleDateWithoutYear,
    Token.ScheduleDateWithoutYearInCandidateTimezone,
    Token.ScheduleDayOfTheWeek,
    Token.ScheduleDayOfTheWeekInCandidateTimezone,
    Token.ScheduleEndTime,
    Token.ScheduleEndTimeInCandidateTimezone,
    Token.ScheduleInterviewerFirstNames,
    Token.ScheduleInterviewerFirstNamesWithLinkedinLinks,
    Token.ScheduleInterviewerFullNames,
    Token.ScheduleInterviewerFullNamesWithLinkedinLinks,
    Token.ScheduleLocation,
    Token.ScheduleRoomName,
    Token.ScheduleStartTime,
    Token.ScheduleStartTimeInCandidateTimezone,
    Token.ScheduleTimezone,
    Token.ScheduleTimezoneLongForm,
    Token.ScheduleTraineeFirstNames,
    Token.ScheduleTraineeFullNames,
    Token.ScheduleVideoConferencingLink,
    Token.ScheduleVideoConferencingPasscode,
    Token.SchedulerEmail,
    Token.SchedulerFirstName,
    Token.SchedulerFullName,
    Token.SchedulerLastName,
    Token.SchedulerPhoneNumber,
    Token.SourcerEmail,
    Token.SourcerFirstName,
    Token.SourcerFullName,
    Token.SourcerLastName,
    Token.SourcerPhoneNumber,
  ].map(convertToSuggestion),
  confirmation_email: [
    Token.CandidateEmail,
    Token.CandidateFirstName,
    Token.CandidateFullName,
    Token.CandidateLastName,
    Token.CandidateLinkedInURL,
    Token.CandidatePhoneNumber,
    Token.CandidateWebsite,
    Token.CoordinatorEmail,
    Token.CoordinatorFirstName,
    Token.CoordinatorFullName,
    Token.CoordinatorLastName,
    Token.CoordinatorPhoneNumber,
    Token.HiringManagerEmail,
    Token.HiringManagerFirstName,
    Token.HiringManagerFullName,
    Token.HiringManagerLastName,
    Token.HiringManagerPhoneNumber,
    Token.InterviewName,
    Token.JobName,
    Token.JobOfficeName,
    Token.JobPostName,
    Token.JobStageAgenda,
    Token.JobStageName,
    Token.MeEmail,
    Token.MeFirstName,
    Token.MeFullName,
    Token.MeLastName,
    Token.MePhoneNumber,
    Token.RecruiterEmail,
    Token.RecruiterFirstName,
    Token.RecruiterFullName,
    Token.RecruiterLastName,
    Token.RecruiterPhoneNumber,
    Token.ScheduleAgendaInCandidateTimezone,
    Token.ScheduleAgendaInCandidateTimezoneWithCustomFormat,
    Token.ScheduleAgendaInCandidateTimezoneWithZoomLinks,
    Token.ScheduleAgendaInCandidateTimezoneWithoutInterviewNames,
    Token.ScheduleAgendaInCandidateTimezoneWithoutInterviewers,
    Token.ScheduleAgendaInScheduleTimezone,
    Token.ScheduleAgendaInScheduleTimezoneWithCustomFormat,
    Token.ScheduleAgendaInScheduleTimezoneWithZoomLinks,
    Token.ScheduleAgendaInScheduleTimezoneWithoutInterviewNames,
    Token.ScheduleAgendaInScheduleTimezoneWithoutInterviewers,
    Token.ScheduleCandidateTimezone,
    Token.ScheduleCandidateTimezoneLongForm,
    Token.ScheduleDate,
    Token.ScheduleDateInCandidateTimezone,
    Token.ScheduleDateWithoutYear,
    Token.ScheduleDateWithoutYearInCandidateTimezone,
    Token.ScheduleDayOfTheWeek,
    Token.ScheduleDayOfTheWeekInCandidateTimezone,
    Token.ScheduleEndTime,
    Token.ScheduleEndTimeInCandidateTimezone,
    Token.ScheduleInterviewerFirstNames,
    Token.ScheduleInterviewerFirstNamesWithLinkedinLinks,
    Token.ScheduleInterviewerFullNames,
    Token.ScheduleInterviewerFullNamesWithLinkedinLinks,
    Token.ScheduleLocation,
    Token.ScheduleRoomName,
    Token.ScheduleStartTime,
    Token.ScheduleStartTimeInCandidateTimezone,
    Token.ScheduleTimezone,
    Token.ScheduleTimezoneLongForm,
    Token.ScheduleTraineeFirstNames,
    Token.ScheduleTraineeFullNames,
    Token.ScheduleVideoConferencingLink,
    Token.ScheduleVideoConferencingPasscode,
    Token.SchedulerEmail,
    Token.SchedulerFirstName,
    Token.SchedulerFullName,
    Token.SchedulerLastName,
    Token.SchedulerPhoneNumber,
    Token.SourcerEmail,
    Token.SourcerFirstName,
    Token.SourcerFullName,
    Token.SourcerLastName,
    Token.SourcerPhoneNumber,
  ].map(convertToSuggestion),
  hiring_channel_name: [
    Token.CandidateFirstName,
    Token.CandidateFullName,
    Token.CandidateLastName,
    Token.JobName,
    Token.JobOfficeName,
    Token.JobPostName,
  ].map(convertToSuggestion),
  hiring_meeting_calendar_event: [
    Token.CandidateEmail,
    Token.CandidateFirstName,
    Token.CandidateFullName,
    Token.CandidateLastName,
    Token.CandidateLinkedInURL,
    Token.CandidatePhoneNumber,
    Token.CandidateWebsite,
    Token.CoordinatorEmail,
    Token.CoordinatorFirstName,
    Token.CoordinatorFullName,
    Token.CoordinatorLastName,
    Token.CoordinatorPhoneNumber,
    Token.HiringManagerEmail,
    Token.HiringManagerFirstName,
    Token.HiringManagerFullName,
    Token.HiringManagerLastName,
    Token.HiringManagerPhoneNumber,
    Token.JobName,
    Token.JobOfficeName,
    Token.JobPostName,
    Token.JobStageAgenda,
    Token.JobStageName,
    Token.RecruiterEmail,
    Token.RecruiterFirstName,
    Token.RecruiterFullName,
    Token.RecruiterLastName,
    Token.RecruiterPhoneNumber,
    Token.SchedulerEmail,
    Token.SchedulerFirstName,
    Token.SchedulerFullName,
    Token.SchedulerLastName,
    Token.SchedulerPhoneNumber,
    Token.SourcerEmail,
    Token.SourcerFirstName,
    Token.SourcerFullName,
    Token.SourcerLastName,
    Token.SourcerPhoneNumber,
  ].map(convertToSuggestion),
  interviewer_event_description: [
    Token.CandidateEmail,
    Token.CandidateFirstName,
    Token.CandidateFullName,
    Token.CandidateLastName,
    Token.CandidateLinkedInURL,
    Token.CandidatePhoneNumber,
    Token.CandidateWebsite,
    Token.CoordinatorEmail,
    Token.CoordinatorFirstName,
    Token.CoordinatorFullName,
    Token.CoordinatorLastName,
    Token.CoordinatorPhoneNumber,
    Token.HiringManagerEmail,
    Token.HiringManagerFirstName,
    Token.HiringManagerFullName,
    Token.HiringManagerLastName,
    Token.HiringManagerPhoneNumber,
    Token.InterviewDuration,
    Token.InterviewEndTime,
    Token.InterviewName,
    Token.InterviewScorecardLink,
    Token.InterviewStartTime,
    Token.JobName,
    Token.JobOfficeName,
    Token.JobPostName,
    Token.JobStageAgenda,
    Token.JobStageName,
    Token.RecruiterEmail,
    Token.RecruiterFirstName,
    Token.RecruiterFullName,
    Token.RecruiterLastName,
    Token.RecruiterPhoneNumber,
    Token.ScheduleAgendaInCandidateTimezone,
    Token.ScheduleAgendaInCandidateTimezoneWithCustomFormat,
    Token.ScheduleAgendaInCandidateTimezoneWithZoomLinks,
    Token.ScheduleAgendaInCandidateTimezoneWithoutInterviewNames,
    Token.ScheduleAgendaInCandidateTimezoneWithoutInterviewers,
    Token.ScheduleAgendaInScheduleTimezone,
    Token.ScheduleAgendaInScheduleTimezoneWithCustomFormat,
    Token.ScheduleAgendaInScheduleTimezoneWithZoomLinks,
    Token.ScheduleAgendaInScheduleTimezoneWithoutInterviewNames,
    Token.ScheduleAgendaInScheduleTimezoneWithoutInterviewers,
    Token.ScheduleCandidateTimezone,
    Token.ScheduleCandidateTimezoneLongForm,
    Token.ScheduleDate,
    Token.ScheduleDateInCandidateTimezone,
    Token.ScheduleDateWithoutYear,
    Token.ScheduleDateWithoutYearInCandidateTimezone,
    Token.ScheduleDayOfTheWeek,
    Token.ScheduleDayOfTheWeekInCandidateTimezone,
    Token.ScheduleEndTime,
    Token.ScheduleEndTimeInCandidateTimezone,
    Token.ScheduleInterviewerFirstNames,
    Token.ScheduleInterviewerFirstNamesWithLinkedinLinks,
    Token.ScheduleInterviewerFullNames,
    Token.ScheduleInterviewerFullNamesWithLinkedinLinks,
    Token.ScheduleLocation,
    Token.ScheduleRoomName,
    Token.ScheduleStartTime,
    Token.ScheduleStartTimeInCandidateTimezone,
    Token.ScheduleTimezone,
    Token.ScheduleTimezoneLongForm,
    Token.ScheduleTraineeFirstNames,
    Token.ScheduleTraineeFullNames,
    Token.ScheduleVideoConferencingLink,
    Token.ScheduleVideoConferencingPasscode,
    Token.SchedulerEmail,
    Token.SchedulerFirstName,
    Token.SchedulerFullName,
    Token.SchedulerLastName,
    Token.SchedulerPhoneNumber,
    Token.SourcerEmail,
    Token.SourcerFirstName,
    Token.SourcerFullName,
    Token.SourcerLastName,
    Token.SourcerPhoneNumber,
  ].map(convertToSuggestion),
  manual_email: [
    Token.AvailabilityRequestLink,
    Token.CandidateEmail,
    Token.CandidateFirstName,
    Token.CandidateFullName,
    Token.CandidateLastName,
    Token.CandidateLinkedInURL,
    Token.CandidatePhoneNumber,
    Token.CandidateWebsite,
    Token.CoordinatorEmail,
    Token.CoordinatorFirstName,
    Token.CoordinatorFullName,
    Token.CoordinatorLastName,
    Token.CoordinatorPhoneNumber,
    Token.HiringManagerEmail,
    Token.HiringManagerFirstName,
    Token.HiringManagerFullName,
    Token.HiringManagerLastName,
    Token.HiringManagerPhoneNumber,
    Token.InterviewName,
    Token.JobName,
    Token.JobOfficeName,
    Token.JobPostName,
    Token.JobStageAgenda,
    Token.JobStageName,
    Token.MeEmail,
    Token.MeFirstName,
    Token.MeFullName,
    Token.MeLastName,
    Token.MePhoneNumber,
    Token.RecruiterEmail,
    Token.RecruiterFirstName,
    Token.RecruiterFullName,
    Token.RecruiterLastName,
    Token.RecruiterPhoneNumber,
    Token.RequesterEmail,
    Token.RequesterFirstName,
    Token.RequesterFullName,
    Token.RequesterLastName,
    Token.RequesterPhoneNumber,
    Token.ScheduleAgendaInCandidateTimezone,
    Token.ScheduleAgendaInCandidateTimezoneWithCustomFormat,
    Token.ScheduleAgendaInCandidateTimezoneWithZoomLinks,
    Token.ScheduleAgendaInCandidateTimezoneWithoutInterviewNames,
    Token.ScheduleAgendaInCandidateTimezoneWithoutInterviewers,
    Token.ScheduleAgendaInScheduleTimezone,
    Token.ScheduleAgendaInScheduleTimezoneWithCustomFormat,
    Token.ScheduleAgendaInScheduleTimezoneWithZoomLinks,
    Token.ScheduleAgendaInScheduleTimezoneWithoutInterviewNames,
    Token.ScheduleAgendaInScheduleTimezoneWithoutInterviewers,
    Token.ScheduleCandidateTimezone,
    Token.ScheduleCandidateTimezoneLongForm,
    Token.ScheduleDate,
    Token.ScheduleDateInCandidateTimezone,
    Token.ScheduleDateWithoutYear,
    Token.ScheduleDateWithoutYearInCandidateTimezone,
    Token.ScheduleDayOfTheWeek,
    Token.ScheduleDayOfTheWeekInCandidateTimezone,
    Token.ScheduleEndTime,
    Token.ScheduleEndTimeInCandidateTimezone,
    Token.ScheduleInterviewerFirstNames,
    Token.ScheduleInterviewerFirstNamesWithLinkedinLinks,
    Token.ScheduleInterviewerFullNames,
    Token.ScheduleInterviewerFullNamesWithLinkedinLinks,
    Token.ScheduleLocation,
    Token.ScheduleRoomName,
    Token.ScheduleStartTime,
    Token.ScheduleStartTimeInCandidateTimezone,
    Token.ScheduleTimezone,
    Token.ScheduleTimezoneLongForm,
    Token.ScheduleTraineeFirstNames,
    Token.ScheduleTraineeFullNames,
    Token.ScheduleVideoConferencingLink,
    Token.ScheduleVideoConferencingPasscode,
    Token.SchedulerEmail,
    Token.SchedulerFirstName,
    Token.SchedulerFullName,
    Token.SchedulerLastName,
    Token.SchedulerPhoneNumber,
    Token.SelfSchedulingRequestLink,
    Token.SourcerEmail,
    Token.SourcerFirstName,
    Token.SourcerFullName,
    Token.SourcerLastName,
    Token.SourcerPhoneNumber,
  ].map(convertToSuggestion),
  multi_block_confirmation_email: [
    Token.CandidateEmail,
    Token.CandidateFirstName,
    Token.CandidateFullName,
    Token.CandidateLastName,
    Token.CandidateLinkedInURL,
    Token.CandidatePhoneNumber,
    Token.CandidateWebsite,
    Token.CoordinatorEmail,
    Token.CoordinatorFirstName,
    Token.CoordinatorFullName,
    Token.CoordinatorLastName,
    Token.CoordinatorPhoneNumber,
    Token.HiringManagerEmail,
    Token.HiringManagerFirstName,
    Token.HiringManagerFullName,
    Token.HiringManagerLastName,
    Token.HiringManagerPhoneNumber,
    Token.JobName,
    Token.JobOfficeName,
    Token.JobPostName,
    Token.JobStageAgenda,
    Token.JobStageName,
    Token.MeEmail,
    Token.MeFirstName,
    Token.MeFullName,
    Token.MeLastName,
    Token.MePhoneNumber,
    Token.RecruiterEmail,
    Token.RecruiterFirstName,
    Token.RecruiterFullName,
    Token.RecruiterLastName,
    Token.RecruiterPhoneNumber,
    Token.ScheduleAgendaInCandidateTimezone,
    Token.ScheduleAgendaInCandidateTimezoneWithCustomFormat,
    Token.ScheduleAgendaInCandidateTimezoneWithZoomLinks,
    Token.ScheduleAgendaInCandidateTimezoneWithoutInterviewNames,
    Token.ScheduleAgendaInCandidateTimezoneWithoutInterviewers,
    Token.ScheduleAgendaInScheduleTimezone,
    Token.ScheduleAgendaInScheduleTimezoneWithCustomFormat,
    Token.ScheduleAgendaInScheduleTimezoneWithZoomLinks,
    Token.ScheduleAgendaInScheduleTimezoneWithoutInterviewNames,
    Token.ScheduleAgendaInScheduleTimezoneWithoutInterviewers,
    Token.ScheduleCandidateTimezone,
    Token.ScheduleCandidateTimezoneLongForm,
    Token.ScheduleDates,
    Token.ScheduleDatesInCandidateTimezone,
    Token.ScheduleDatesWithoutYear,
    Token.ScheduleDatesWithoutYearInCandidateTimezone,
    Token.ScheduleDaysOfTheWeek,
    Token.ScheduleDaysOfTheWeekInCandidateTimezone,
    Token.ScheduleEndTime,
    Token.ScheduleEndTimeInCandidateTimezone,
    Token.ScheduleInterviewerFirstNames,
    Token.ScheduleInterviewerFirstNamesWithLinkedinLinks,
    Token.ScheduleInterviewerFullNames,
    Token.ScheduleInterviewerFullNamesWithLinkedinLinks,
    Token.ScheduleRoomNames,
    Token.ScheduleStartTime,
    Token.ScheduleStartTimeInCandidateTimezone,
    Token.ScheduleTimezone,
    Token.ScheduleTimezoneLongForm,
    Token.ScheduleTraineeFirstNames,
    Token.ScheduleTraineeFullNames,
    Token.ScheduleVideoConferencingLinks,
    Token.ScheduleVideoConferencingPasscodes,
    Token.SchedulerEmail,
    Token.SchedulerFirstName,
    Token.SchedulerFullName,
    Token.SchedulerLastName,
    Token.SchedulerPhoneNumber,
    Token.SourcerEmail,
    Token.SourcerFirstName,
    Token.SourcerFullName,
    Token.SourcerLastName,
    Token.SourcerPhoneNumber,
  ].map(convertToSuggestion),
  self_scheduling_request_email: [
    Token.CandidateEmail,
    Token.CandidateFirstName,
    Token.CandidateFullName,
    Token.CandidateLastName,
    Token.CandidateLinkedInURL,
    Token.CandidatePhoneNumber,
    Token.CandidateWebsite,
    Token.CoordinatorEmail,
    Token.CoordinatorFirstName,
    Token.CoordinatorFullName,
    Token.CoordinatorLastName,
    Token.CoordinatorPhoneNumber,
    Token.HiringManagerEmail,
    Token.HiringManagerFirstName,
    Token.HiringManagerFullName,
    Token.HiringManagerLastName,
    Token.HiringManagerPhoneNumber,
    Token.InterviewDuration,
    Token.InterviewName,
    Token.JobName,
    Token.JobOfficeName,
    Token.JobPostName,
    Token.JobStageAgenda,
    Token.JobStageName,
    Token.MeEmail,
    Token.MeFirstName,
    Token.MeFullName,
    Token.MeLastName,
    Token.MePhoneNumber,
    Token.RecruiterEmail,
    Token.RecruiterFirstName,
    Token.RecruiterFullName,
    Token.RecruiterLastName,
    Token.RecruiterPhoneNumber,
    Token.RequesterEmail,
    Token.RequesterFirstName,
    Token.RequesterFullName,
    Token.RequesterLastName,
    Token.RequesterPhoneNumber,
    Token.SelfSchedulingRequestLink,
    Token.SourcerEmail,
    Token.SourcerFirstName,
    Token.SourcerFullName,
    Token.SourcerLastName,
    Token.SourcerPhoneNumber,
  ].map(convertToSuggestion),
};

export function moveSelectionToEnd (editor: CustomEditor): void {
  // We can't select anything if there's nothing there.
  if (editor.children.length === 0) {
    return;
  }
  Transforms.select(editor, [editor.children.length - 1]);
  Transforms.collapse(editor, { edge: 'end' });
}

export function areAttachmentsDifferent (attachments1: EditableAttachment[], attachments2: EditableAttachment[]): boolean {
  if (attachments1.length !== attachments2.length) {
    return true;
  }
  return attachments1.some((attachment1, i) => {
    const attachment2 = attachments2[i];
    if (isFile(attachment1) || isFile(attachment2)) {
      // If either of the attachments are files, then it was newly updated, so it's guaranteed different.
      return true;
    }
    // If it's not a file, link is guaranteed to be defined.
    const link1 = stripQueryParams(attachment1.link!);
    const link2 = stripQueryParams(attachment2.link!);
    return link1 !== link2;
  });
}

function stripQueryParams (link: string): string {
  const u = new URL(link);
  u.search = '';
  return u.toString();
}
