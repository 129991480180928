import { Breadcrumb } from 'react-breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Route, Switch } from 'react-router-dom';
import { faBriefcase } from '@fortawesome/free-solid-svg-icons';

import JobList from './JobList';
import Job from './Job';
import { correctPath } from 'libraries/gem';

const Jobs = () =>
  <Breadcrumb
    data={{
      title: <><FontAwesomeIcon icon={faBriefcase} />Jobs</>,
      pathname: correctPath('/app/jobs'),
    }}
  >
    <Switch>
      <Route component={JobList} exact path={correctPath('/app/jobs')} />
      <Route component={Job} path={correctPath('/app/jobs/:id')} />
    </Switch>
  </Breadcrumb>
;

export default Jobs;
