import { useMutation, useQuery, useQueryClient } from 'react-query';

import InterviewPlanner from '../../libraries/interviewplanner';
import { QueryKey as StageQueryKey } from './stages';

import type { StageInterview } from '../../types';
import type { UseQueryOptions } from 'react-query';

export enum QueryKey {
  ListStageInterviews = 'ListStageInterviews',
}

interface ListStageInterviewsQuery {
  limit?: number;
  offset?: number;
  job?: string;
  jobs?: string[];
  interview_template?: string;
  training_eligibility?: string;
}

interface ListStageInterviewsData {
  stage_interviews: StageInterview[];
  total: number;
}

export const useStageInterviews = (query: ListStageInterviewsQuery = {}, options: UseQueryOptions<ListStageInterviewsData, Error> = {}) => {
  // This query can't be canceled because the promise being returned from this
  // function (which is a result of the async keyword) doesn't have a cancel
  // function on it. If we want it to be cancellable, we can't use async/await,
  // and we need to make sure the cancel function from the promise returned from
  // InterviewPlanner.request is preserved.
  return useQuery<ListStageInterviewsData, Error>([QueryKey.ListStageInterviews, query], async () => {
    const pageSize = 100;
    const paginatedQuery = {
      limit: pageSize,
      offset: 0,
      ...query,
    };

    const stageInterviews = [];
    let total = 0;
    let totalLimit;

    while (totalLimit === undefined || stageInterviews.length < totalLimit) {
      const response = await InterviewPlanner.request<ListStageInterviewsData>('GET', '/stage-interviews', null, paginatedQuery);
      total = response.total;
      if (response.stage_interviews.length === 0) {
        break;
      }

      stageInterviews.push(...response.stage_interviews);
      totalLimit = query.limit! < total ? query.limit : total;
      paginatedQuery.offset = paginatedQuery.offset + paginatedQuery.limit;
    }

    return { stage_interviews: stageInterviews, total };
  }, options);
};

export interface CreateStageInterviewPayload {
  name: string;
  position: number;
  interview_template_id?: string;
  feedback_form_id?: string;
}

interface CreateStageInterviewMutationVariables {
  jobId: string;
  stageId: string;
  payload?: CreateStageInterviewPayload;
}

export const useCreateStageInterview = () => {
  const queryClient = useQueryClient();

  return useMutation<StageInterview, Error, CreateStageInterviewMutationVariables>(({ jobId, payload, stageId }) => {
    return InterviewPlanner.request('POST', `/jobs/${jobId}/stages/${stageId}/stage-interviews`, payload);
  }, {
    onSuccess: (data, { jobId, stageId }) => {
      queryClient.invalidateQueries([StageQueryKey.RetrieveStage, jobId, stageId]);
    },
  });
};

export interface UpdateStageInterviewPayload {
  name?: string;
  position?: number;
  interview_template_id?: string;
  feedback_form_id?: string;
}

interface UpdateStageInterviewMutationVariables {
  id: string;
  jobId: string;
  stageId: string;
  payload?: UpdateStageInterviewPayload;
}

export const useUpdateStageInterview = () => {
  const queryClient = useQueryClient();

  return useMutation<StageInterview, Error, UpdateStageInterviewMutationVariables>(({ id, jobId, payload, stageId }) => {
    return InterviewPlanner.request('POST', `/jobs/${jobId}/stages/${stageId}/stage-interviews/${id}`, payload);
  }, {
    onSuccess: (data, { jobId, stageId }) => {
      queryClient.invalidateQueries([StageQueryKey.RetrieveStage, jobId, stageId]);
    },
  });
};

interface DeleteStageInterviewMutationVariables {
  id: string;
  jobId: string;
  stageId: string;
}

interface DeleteStageInterviewData {
  success: boolean;
}

export const useDeleteStageInterview = () => {
  const queryClient = useQueryClient();

  return useMutation<DeleteStageInterviewData, Error, DeleteStageInterviewMutationVariables>(({ id, jobId, stageId }) => {
    return InterviewPlanner.request('DELETE', `/jobs/${jobId}/stages/${stageId}/stage-interviews/${id}`);
  }, {
    onSuccess: (data, { jobId, stageId }) => {
      queryClient.invalidateQueries([StageQueryKey.RetrieveStage, jobId, stageId]);
    },
  });
};
