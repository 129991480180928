import { useEffect, useState } from 'react';

import Flash from 'components/library/utils/Flash';
import Section from 'components/library/layout/Section';
import { StyledCheckboxInput } from './styles';
import { useSession } from 'hooks/use-session';
import { useUpdateUser, useUser } from 'hooks/queries/users';

import type { ChangeEvent } from 'react';

const UserEmailNotificationsSection = () => {
  const currentUser = useSession().currentUser!;
  const user = useUser(currentUser.id).data!;

  const [isEditing, setIsEditing] = useState(false);

  const [scheduleConfirmed, setScheduleConfirmed] = useState(user.email_notification_schedule_confirmed);
  const [candidateAvailability, setCandidateAvailability] = useState(user.email_notification_candidate_availability);

  const updateUserMutation = useUpdateUser();

  useEffect(() => {
    setScheduleConfirmed(user.email_notification_schedule_confirmed);
  }, [user.email_notification_schedule_confirmed]);

  useEffect(() => {
    setCandidateAvailability(user.email_notification_candidate_availability);
  }, [user.email_notification_candidate_availability]);

  const handleScheduleConfirmedChange = (e: ChangeEvent<HTMLInputElement>) => setScheduleConfirmed(e.target.checked);
  const handleCandidateAvailabilityChange = (e: ChangeEvent<HTMLInputElement>) => setCandidateAvailability(e.target.checked);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setScheduleConfirmed(user.email_notification_schedule_confirmed);
    setCandidateAvailability(user.email_notification_candidate_availability);
    setIsEditing(false);
    updateUserMutation.reset();
  };

  const handleSave = async () => {
    updateUserMutation.reset();

    try {
      await updateUserMutation.mutateAsync({
        id: user.id,
        payload: {
          email_notification_schedule_confirmed: scheduleConfirmed,
          email_notification_candidate_availability: candidateAvailability,
        },
      });
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };
  return (
    <Section
      isEditable
      isEditing={isEditing}
      isSaving={updateUserMutation.isLoading}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Email notifications"
    >
      <Flash
        message={updateUserMutation.error?.message}
        showFlash={updateUserMutation.isError}
        type="danger"
      />
      <Flash
        isDismissible
        message="Successfully updated!"
        showFlash={updateUserMutation.isSuccess}
        type="success"
      />
      <StyledCheckboxInput
        isChecked={scheduleConfirmed}
        isDisabled={!isEditing || updateUserMutation.isLoading}
        label={<>When I schedule a candidate.</>}
        onChange={handleScheduleConfirmedChange}
      />
      <StyledCheckboxInput
        isChecked={candidateAvailability}
        isDisabled={!isEditing || updateUserMutation.isLoading}
        label={<>When a candidate I&apos;m on the hiring team for submits availability.</>}
        onChange={handleCandidateAvailabilityChange}
      />
    </Section>
  );
};

export default UserEmailNotificationsSection;
