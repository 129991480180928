import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import EditorInput from '../../../library/inputs/EditorInput';
import EmailTemplateAdvancedSettings from '../../../library/inputs/EmailTemplateAdvancedSettings';
import EmailTemplateTypeSelectInput from '../../../library/inputs/EmailTemplateTypeSelectInput';
import Flash from '../../../library/utils/Flash';
import MultiStepFormStep from '../../../library/inputs/MultiStepFormStep';
import TextInput from '../../../library/inputs/TextInput';
import ToggleInput from '../../../library/inputs/ToggleInput';
import TokenInput from '../../../library/inputs/TokenInput';
import useQueryState from '../../../../hooks/use-query-state';
import { DEFAULT_EMAIL_CONTENT, NEW_EMAIL_TEMPLATE } from './helpers';
import { EditorType, EmailTemplateType, Token } from '../../../../types';
import { downloadFileFromUrl, isFile } from '../../../../libraries/form-data';
import { slateValueToHtml } from '../../../../libraries/editor/slate-value-to-html';
import { slateValueToText } from '../../../../libraries/editor/slate-value-to-text';
import { translateSlateValueBetweenEditorTypes } from '../../../../libraries/editor/translate-slate-value-between-editor-types';
import { useAllEmailTokens } from '../../../../hooks/queries/tokens';
import { useCreateEmailTemplate, useEmailTemplate } from '../../../../hooks/queries/email-templates';
import { useSession } from '../../../../hooks/use-session';
import { useSlateEditor } from '../../../../hooks/use-slate-editor';

import type { ChangeEvent, SetStateAction } from 'react';
import type { CreatableEmailTemplate, EditorAttachment, NonManualEmailTemplateType, CreatableEmailTemplateType } from '../../../../types';
import type { CreateEmailTemplatePayload } from '../../../../hooks/queries/email-templates';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../../library/inputs/EmailTemplateTypeSelectInput';
import { correctPath } from 'libraries/gem';

const DEFAULT_EMAIL_TEMPLATE_TYPE_FOR_PREVIEW = EmailTemplateType.ConfirmationEmail;

const EmailTemplateCreateForm = () => {
  const history = useHistory();

  const [baseId] = useQueryState<string>('base', '');
  const { data: baseTemplate } = useEmailTemplate(baseId);

  const { account } = useSession();

  const [emailTemplate, setEmailTemplate] = useState<CreatableEmailTemplate | undefined>(() => ({
    ...NEW_EMAIL_TEMPLATE,
    ...(baseTemplate ? {
      name: `Copy of ${baseTemplate.name}`,
      type: baseTemplate.type as CreatableEmailTemplateType,
      subject: baseTemplate.subject,
      sender_name: baseTemplate.sender_name,
      sender_email: baseTemplate.sender_email,
      cc_emails: baseTemplate.cc_emails,
      bcc_emails: baseTemplate.bcc_emails,
      body: baseTemplate.body,
      attachments: baseTemplate.attachments?.map((attachment, i) => ({ ...attachment, index: i })) || [],
    } : {}),
  }));
  const [error, setError] = useState('');
  const [emailTemplateTypeForPreview, setEmailTemplateTypeForPreview] = useState<NonManualEmailTemplateType>(emailTemplate?.type || DEFAULT_EMAIL_TEMPLATE_TYPE_FOR_PREVIEW);

  const [subjectSlateEditor, subjectSlateValue, setSubjectSlateValue] = useSlateEditor(emailTemplate!.subject, true);
  const [bodySlateEditor, bodySlateValue, setBodySlateValue] = useSlateEditor(emailTemplate!.body);

  const createEmailTemplateMutation = useCreateEmailTemplate();

  const {
    data: tokens,
    error: tokensError,
  } = useAllEmailTokens();

  useEffect(() => {
    if (error) {
      document.querySelector('.content-container')?.scrollTo({ top: 0, behavior: 'smooth' });
    }
  }, [error]);

  const handleTypeChange = (option: OnChangeValue<Option, false>) => {
    // If 'Confirmation Email' is the currently selected email type, but the user is previewing
    // it as a multi-block email, toggle back to single-block tokens before selecting a different email template type.
    // This makes the behavior more smooth if the user re-selects 'Confirmation Email', and it
    // treats the single-block tokens as the 'default' for this email template type.
    if (emailTemplateTypeForPreview === EmailTemplateType.MultiBlockConfirmationEmail) {
      handleTogglePreviewForMultiBlock();
    }

    setEmailTemplate((prevTemplate) => (prevTemplate ? {
      ...prevTemplate,
      type: option ? option.value : undefined,
      subject: prevTemplate.name || (option ? `${account?.name ? `${account.name} ` : ''}${DEFAULT_EMAIL_CONTENT[option.value].subject}` : ''),
    } : undefined));
    setEmailTemplateTypeForPreview(option ? option.value : DEFAULT_EMAIL_TEMPLATE_TYPE_FOR_PREVIEW);
  };

  const handleTogglePreviewForMultiBlock = () => {
    const currentEditorType = emailTemplateTypeForPreview;
    if (currentEditorType !== EmailTemplateType.ConfirmationEmail &&
        currentEditorType !== EmailTemplateType.MultiBlockConfirmationEmail) {
      // This function should only be called if the email type is one of these.
      // This if statement is just here to inform Typescript that it's okay.
      return;
    }
    const newEditorType = currentEditorType === EmailTemplateType.ConfirmationEmail ? EmailTemplateType.MultiBlockConfirmationEmail : EmailTemplateType.ConfirmationEmail;
    setEmailTemplateTypeForPreview(newEditorType);
    setSubjectSlateValue(translateSlateValueBetweenEditorTypes(subjectSlateValue, currentEditorType, newEditorType));
    setBodySlateValue(translateSlateValueBetweenEditorTypes(bodySlateValue, currentEditorType, newEditorType));
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setEmailTemplate((prevTemplate) => (prevTemplate ? {
      ...prevTemplate,
      name,
    } : undefined));
  };

  const handleAttachmentsChange = (fn: SetStateAction<EditorAttachment[]>) => {
    setEmailTemplate((prevTemplate) => {
      if (!prevTemplate) {
        return;
      }
      const attachments = typeof fn === 'function' ? fn(prevTemplate.attachments || []) : fn;
      return {
        ...prevTemplate,
        attachments,
      };
    });
  };

  const handleSubmit = async () => {
    if (!emailTemplate) {
      // This shouldn't ever happen. we're only saying emailTemplate can be
      // undefined because of type compatibilities.
      return;
    }
    // Check that availability request link is included in availability request email
    if (emailTemplate.type === EmailTemplateType.AvailabilityRequestEmail) {
      const body = slateValueToHtml(bodySlateValue);
      if (!/{{\s*AvailabilityRequest\.Link\s*}}/.test(body)) {
        setError(`The template must include the ${Token.AvailabilityRequestLink} token.`);
        return;
      }
    }
    // Check that self-scheduling request link is included in self-scheduling request email
    if (emailTemplate.type === EmailTemplateType.SelfSchedulingRequestEmail) {
      const body = slateValueToHtml(bodySlateValue);
      if (!/{{\s*SelfSchedulingRequest\.Link\s*}}/.test(body)) {
        setError(`The template must include the ${Token.SelfSchedulingRequestLink} token.`);
        return;
      }
    }

    const attachments = await Promise.all((emailTemplate.attachments || []).map(async (file) => {
      if (isFile(file)) {
        // This attachment is a newly added attachment.
        return { file };
      }
      // This attachment is an attachment on the existing template.
      const downloadedFile = await downloadFileFromUrl(file.link!, file.name);
      return { file: downloadedFile };
    }));

    const payload: CreateEmailTemplatePayload = {
      name: emailTemplate.name,
      type: emailTemplate.type!, // It's required, so it will be defined by here.
      subject: emailTemplate.subject,
      sender_name: emailTemplate.sender_name || emailTemplate.sender_email,
      sender_email: emailTemplate.sender_email,
      cc_emails: emailTemplate.cc_emails,
      bcc_emails: emailTemplate.bcc_emails,
      body: emailTemplate.body,
      attachments,
    };
    // Convert multi-block confirmation email tokens back to single-block before saving
    if (emailTemplateTypeForPreview === EmailTemplateType.MultiBlockConfirmationEmail) {
      payload.subject = slateValueToText(
        translateSlateValueBetweenEditorTypes(
          subjectSlateValue, EditorType.MultiBlockConfirmationEmail, EditorType.ConfirmationEmail
        )
      );
      payload.body = slateValueToHtml(
        translateSlateValueBetweenEditorTypes(
          bodySlateValue, EditorType.MultiBlockConfirmationEmail, EditorType.ConfirmationEmail
        )
      );
    } else {
      payload.subject = slateValueToText(subjectSlateValue);
      payload.body = slateValueToHtml(bodySlateValue);
    }

    setError('');
    createEmailTemplateMutation.reset();

    try {
      const data = await createEmailTemplateMutation.mutateAsync({ payload });
      history.push(correctPath(`/app/email-templates/${data.id}`));
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  return (
    <MultiStepFormStep
      isFirstStep
      isLastStep
      isSubmitting={createEmailTemplateMutation.isLoading}
      nextButtonValue="Create Email Template"
      onNext={handleSubmit}
    >
      <Flash
        message={error}
        showFlash={Boolean(error)}
        type="danger"
      />
      <Flash
        message={tokensError?.message}
        showFlash={Boolean(tokensError)}
        type="danger"
      />
      <Flash
        message={createEmailTemplateMutation.error?.message}
        showFlash={createEmailTemplateMutation.isError}
        type="danger"
      />
      <div className="type-name-container">
        <EmailTemplateTypeSelectInput
          onChange={handleTypeChange}
          value={emailTemplate?.type}
        />
        <TextInput
          isRequired
          label="Template Name"
          onChange={handleNameChange}
          value={emailTemplate?.name}
        />
      </div>
      <div className="form-container">
        {tokens?.[emailTemplate?.type || DEFAULT_EMAIL_TEMPLATE_TYPE_FOR_PREVIEW] && (
          <TokenInput
            editor={subjectSlateEditor}
            isRequired
            label="Email Subject"
            pendingPreviewMessage="This token will be filled in when sending an email to a candidate."
            setValue={setSubjectSlateValue}
            tokens={tokens[emailTemplateTypeForPreview]}
            type={emailTemplateTypeForPreview || DEFAULT_EMAIL_TEMPLATE_TYPE_FOR_PREVIEW}
            value={subjectSlateValue}
          />
        )}
      </div>
      {tokens?.[emailTemplate?.type || DEFAULT_EMAIL_TEMPLATE_TYPE_FOR_PREVIEW] && (
        <EditorInput
          allowImages
          attachments={emailTemplate?.attachments}
          editor={bodySlateEditor}
          exampleHtmlContent={emailTemplate?.type ? DEFAULT_EMAIL_CONTENT[emailTemplateTypeForPreview].body : undefined}
          label="Email Body"
          pendingPreviewMessage="This token will be filled in when sending an email to a candidate."
          setAttachments={handleAttachmentsChange}
          setValue={setBodySlateValue}
          tokens={tokens[emailTemplateTypeForPreview]}
          type={emailTemplateTypeForPreview}
          value={bodySlateValue}
        />
      )}
      <EmailTemplateAdvancedSettings
        emailTemplate={emailTemplate}
        setEmailTemplate={setEmailTemplate}
      />
      {emailTemplate?.type === EmailTemplateType.ConfirmationEmail && (
        <ToggleInput
          isOn={emailTemplateTypeForPreview === EmailTemplateType.MultiBlockConfirmationEmail}
          onChange={handleTogglePreviewForMultiBlock}
          onLabel="Preview for multi-block schedule."
        />
      )}
    </MultiStepFormStep>
  );
};

export default EmailTemplateCreateForm;
