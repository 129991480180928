import { useQueryClient } from 'react-query';
import { useEffect, useMemo, useState } from 'react';

import Avatar from '../data-display/Avatar';
import CalendarEventTemplateAdvancedSettings from './CalendarEventTemplateAdvancedSettings';
import CalendarEventTemplateSelectInput from './CalendarEventTemplateSelectInput';
import EditorInput from './EditorInput';
import Flash from '../utils/Flash';
import TextInput from './TextInput';
import TokenInput from './TokenInput';
import { DEFAULT_CALENDAR_EVENT_CONTENT, NEW_CALENDAR_EVENT_TEMPLATE } from '../../Application/CalendarEventTemplates/CalendarEventTemplateCreate/helpers';
import { calendarEventTemplateParams } from '../../../hooks/queries/calendar-event-templates';
import { getTokensFromSlateValue, slateValueToHtml } from '../../../libraries/editor/slate-value-to-html';
import { slateValueToText } from '../../../libraries/editor/slate-value-to-text';
import { useSession } from '../../../hooks/use-session';
import { useSlateEditor } from '../../../hooks/use-slate-editor';
import { videoConferencingLabels, videoConferencingLogos } from '../../../types';

import type { CalendarEventTemplateType, TokensResponse, Token } from '../../../types';
import type { Dispatch, SetStateAction, ChangeEvent } from 'react';
import type { Option } from './CalendarEventTemplateSelectInput';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';

interface Props {
  additionalAttendees: string[];
  additionalOptionalAttendees: string[];
  description: string;
  hideAdvancedSettings?: boolean;
  id?: string;
  isDisabled?: boolean;
  isInlineOnly?: boolean;
  isVideoConferencingEnabled: boolean;
  location?: string;
  locationHelperText?: string;
  pendingPreviewMessage: string;
  setAdditionalAttendees: Dispatch<SetStateAction<string[]>>;
  setAdditionalOptionalAttendees: Dispatch<SetStateAction<string[]>>;
  setDescription: Dispatch<SetStateAction<string>>;
  setErrorTokens: Dispatch<SetStateAction<Token[]>>;
  setId?: Dispatch<SetStateAction<string | undefined>>;
  setIncludedTokens?: Dispatch<SetStateAction<Token[]>>;
  setLocation: Dispatch<SetStateAction<string | undefined>>;
  setTitle: Dispatch<SetStateAction<string>>;
  title: string;
  tokens?: TokensResponse;
  type: `${CalendarEventTemplateType}`;
}

const CalendarEventTemplateForm = ({
  additionalAttendees,
  additionalOptionalAttendees,
  description,
  hideAdvancedSettings = false,
  id,
  isDisabled = false,
  isInlineOnly = false,
  isVideoConferencingEnabled,
  location,
  locationHelperText,
  pendingPreviewMessage,
  setAdditionalAttendees,
  setErrorTokens,
  setIncludedTokens,
  setAdditionalOptionalAttendees,
  setDescription,
  setId,
  setLocation,
  setTitle,
  title,
  tokens,
  type,
}: Props) => {
  const queryClient = useQueryClient();

  const { account } = useSession();

  const [error, setError] = useState('');

  const [titleSlateEditor, titleSlateValue, setTitleSlateValue, setTitleValue] = useSlateEditor(title || '', true);
  const [descriptionSlateEditor, descriptionSlateValue, setDescriptionSlateValue, setDescriptionValue] = useSlateEditor(description || '');

  const tokensUsedInTitle = useMemo(() => getTokensFromSlateValue(titleSlateValue), [titleSlateValue]);
  const tokensUsedInDescription = useMemo(() => getTokensFromSlateValue(descriptionSlateValue), [descriptionSlateValue]);
  const errorTokens = useMemo(() => {
    const errorTitleTokens = (
      tokens && tokensUsedInTitle ?
        tokensUsedInTitle.filter((name) => !tokens[name] || tokens[name].disabled) :
        []
    );
    const errorDescriptionTokens = (
      tokens && tokensUsedInDescription ?
        tokensUsedInDescription.filter((name) => !tokens[name] || tokens[name].disabled) :
        []
    );
    return [
      ...errorTitleTokens,
      ...errorDescriptionTokens,
    ];
  }, [tokens, tokensUsedInTitle, tokensUsedInDescription]);

  useEffect(() => {
    setErrorTokens(errorTokens);
  }, [errorTokens]);

  useEffect(() => {
    setIncludedTokens?.([...tokensUsedInTitle, ...tokensUsedInDescription]);
  }, [setIncludedTokens, tokensUsedInTitle, tokensUsedInDescription]);

  useEffect(() => {
    setTitle(slateValueToText(titleSlateValue));
  }, [titleSlateValue]);

  useEffect(() => {
    setDescription(slateValueToHtml(descriptionSlateValue));
  }, [descriptionSlateValue]);

  const handleCalendarEventTemplateChange = async (option: OnChangeValue<Option, false>) => {
    let newCalendarEventTemplate = NEW_CALENDAR_EVENT_TEMPLATE;
    if (option) {
      try {
        newCalendarEventTemplate = await queryClient.fetchQuery(calendarEventTemplateParams(option.value));
      } catch (err) {
        if (err instanceof Error) {
          setError(err.message);
        }
        return;
      }
    }

    setId?.(newCalendarEventTemplate.id);
    setTitleValue(newCalendarEventTemplate.title);
    setDescriptionValue(newCalendarEventTemplate.description);
    setLocation(newCalendarEventTemplate.location);
    setAdditionalAttendees(newCalendarEventTemplate.additional_attendees || []);
    setAdditionalOptionalAttendees(newCalendarEventTemplate.additional_optional_attendees || []);
  };

  const handleLocationChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLocation(e.target.value);
  };

  return (
    <div className="calendar-event-template-form">
      <Flash
        message={error}
        showFlash={Boolean(error)}
        type="danger"
      />
      {!isInlineOnly && (
        <CalendarEventTemplateSelectInput
          isClearable
          isDisabled={isDisabled}
          onChange={handleCalendarEventTemplateChange}
          type={type}
          value={id}
        />
      )}
      <div className="form-container">
        {tokens && (
          <TokenInput
            editor={titleSlateEditor}
            isDisabled={isDisabled}
            isRequired
            label="Title"
            pendingPreviewMessage={pendingPreviewMessage}
            setValue={setTitleSlateValue}
            tokens={tokens}
            type={type}
            value={titleSlateValue}
          />
        )}
        <TextInput
          className="location-input"
          helperText={locationHelperText}
          isDisabled={isDisabled || isVideoConferencingEnabled}
          label="Location"
          leftIcon={
            isVideoConferencingEnabled && account?.video_conferencing_type ?
              <Avatar
                alt={account.video_conferencing_type}
                className={`avatar-${account.video_conferencing_type}`}
                img={videoConferencingLogos[account.video_conferencing_type]}
                size="small"
                type="company"
              /> :
              undefined
          }
          onChange={handleLocationChange}
          value={isVideoConferencingEnabled && account?.video_conferencing_type ? videoConferencingLabels[account.video_conferencing_type] : location}
        />
      </div>
      {tokens && (
        <EditorInput
          editor={descriptionSlateEditor}
          exampleHtmlContent={DEFAULT_CALENDAR_EVENT_CONTENT[type].description}
          isDisabled={isDisabled}
          label="Description"
          pendingPreviewMessage={pendingPreviewMessage}
          setValue={setDescriptionSlateValue}
          tokens={tokens}
          type={type}
          value={descriptionSlateValue}
        />
      )}
      {!hideAdvancedSettings && (
        <CalendarEventTemplateAdvancedSettings
          additionalAttendees={additionalAttendees}
          additionalOptionalAttendees={additionalOptionalAttendees}
          isDisabled={isDisabled}
          setAdditionalAttendees={setAdditionalAttendees}
          setAdditionalOptionalAttendees={setAdditionalOptionalAttendees}
        />
      )}
    </div>
  );
};

export default CalendarEventTemplateForm;
