import type { ChangeEvent } from 'react';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import DurationInput from '../../../../library/inputs/DurationInput';
import Flash from '../../../../library/utils/Flash';
import RadioButtonGroupInput from '../../../../library/inputs/RadioButtonGroupInput';
import Section from '../../../../library/layout/Section';
import { formatDuration } from '../../../../../libraries/formatters';
import { useAccount } from '../../../../../hooks/queries/accounts';
import { useUpdateUser, useUser } from '../../../../../hooks/queries/users';

import type { RadioButtonOption } from '../../../../library/inputs/RadioButtonGroupInput';

enum OptionValue {
  Enabled = 'enabled',
  Disabled = 'disabled',
}

const MeetingBuffersSection = () => {
  const { id } = useParams<{ id: string }>();

  const { data: user } = useUser(id);
  const { data: account } = useAccount();

  const [isEditing, setIsEditing] = useState(false);
  const [isEnabled, setIsEnabled] = useState(user!.interviewer_meeting_buffer_minutes !== null);
  const [interviewerMeetingBufferMinutes, setInterviewerMeetingBufferMinutes] = useState(user!.interviewer_meeting_buffer_minutes || 0);

  const updateUserMutation = useUpdateUser();

  useEffect(() => {
    setIsEnabled(user!.interviewer_meeting_buffer_minutes !== null);
    setInterviewerMeetingBufferMinutes(user!.interviewer_meeting_buffer_minutes || 0);
  }, [user!.interviewer_meeting_buffer_minutes]);

  const handleInterviewerMeetingBufferMinutesChange = (minutes: number) => {
    // If the user starts editing the buffer, they probably want to enable the override.
    setIsEnabled(true);
    setInterviewerMeetingBufferMinutes(minutes);
  };
  const handleOptionChange = (e: ChangeEvent<HTMLInputElement>) => setIsEnabled(e.target.value === OptionValue.Enabled);

  const options = useMemo<RadioButtonOption[]>(() => [{
    label: `Use the account default (${account?.interviewer_meeting_buffer_minutes ? `${formatDuration(account?.interviewer_meeting_buffer_minutes || 0)} before and after meetings` : 'no buffer around meetings'})`,
    value: OptionValue.Disabled,
  }, {
    label: (
      <DurationInput
        additionalText="before and after meetings"
        className="input-interviewer-meeting-buffer"
        isDisabled={!isEditing || updateUserMutation.isLoading}
        maxMinutes={300}
        minMinutes={0}
        onChange={handleInterviewerMeetingBufferMinutesChange}
        value={interviewerMeetingBufferMinutes}
      />
    ),
    value: OptionValue.Enabled,
  }], [
    account,
    handleInterviewerMeetingBufferMinutesChange,
    interviewerMeetingBufferMinutes,
    isEditing,
    updateUserMutation.isLoading,
  ]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEnabled(user!.interviewer_meeting_buffer_minutes !== null);
    setInterviewerMeetingBufferMinutes(user!.interviewer_meeting_buffer_minutes || 0);
    setIsEditing(false);
  };

  const handleSave = async () => {
    updateUserMutation.reset();

    try {
      await updateUserMutation.mutateAsync({
        id,
        payload: {
          interviewer_meeting_buffer_minutes: isEnabled ? interviewerMeetingBufferMinutes : -1,
        },
      });
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  return (
    <Section
      isEditable
      isEditing={isEditing}
      isSaving={updateUserMutation.isLoading}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Meeting buffers"
    >
      <Flash
        message={updateUserMutation.error?.message}
        showFlash={updateUserMutation.isError}
        type="danger"
      />
      <Flash
        isDismissible
        message="Successfully updated!"
        showFlash={updateUserMutation.isSuccess}
        type="success"
      />
      <Flash
        message="This buffer will only apply to this interviewer and will override the account-level buffer."
        showFlash
        type="info"
      />
      <RadioButtonGroupInput
        helperText="Use this to prevent scheduling back-to-back events for this interviewer."
        isDisabled={!isEditing || updateUserMutation.isLoading}
        layout="vertical"
        name="meeting-buffer"
        onChange={handleOptionChange}
        options={options}
        value={isEnabled ? OptionValue.Enabled : OptionValue.Disabled}
      />
    </Section>
  );
};

export default MeetingBuffersSection;
