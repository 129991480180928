import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize, find, omit } from 'lodash';
import { faCircleNotch, faPlus, faSave } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import Button from '../../../../../../../../library/inputs/Button';
import CheckboxInput from '../../../../../../../../library/inputs/CheckboxInput';
import DurationInput from '../../../../../../../../library/inputs/DurationInput';
import Flash from '../../../../../../../../library/utils/Flash';
import InterviewTemplateAdvancedSettings from '../../../../../../../../library/inputs/InterviewTemplateAdvancedSettings';
import InterviewerTemplatesForm from '../../../../../../../../library/inputs/InterviewerTemplatesForm';
import LoadingSpinner from '../../../../../../../../library/utils/LoadingSpinner';
import PreFillInterviewerTemplatesFlash from '../../../../../../../../library/utils/PreFillInterviewerTemplatesFlash';
import TextInput from '../../../../../../../../library/inputs/TextInput';
import { constructUpdateInterviewTemplatePayload } from '../helpers';
import { liveCodingLabels } from '../../../../../../../../../types';
import { useCreateInterviewTemplate, useInterviewTemplate, useUpdateInterviewTemplate } from '../../../../../../../../../hooks/queries/interview-templates';
import { useCreateStageInterview, useUpdateStageInterview } from '../../../../../../../../../hooks/queries/stage-interviews';
import { useSession } from '../../../../../../../../../hooks/use-session';
import { useStage } from '../../../../../../../../../hooks/queries/stages';
import { useUsersMap } from '../../../../../../../../../hooks/queries/users';

import type { ChangeEvent, Dispatch, SetStateAction } from 'react';
import type { CreateInterviewTemplatePayload } from '../../../../../../../../../hooks/queries/interview-templates';
import type { InterviewTemplatePayload } from '../types';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../../../../../../../library/inputs/SelectInput/types';
import { sortPositions } from '../../../../../../../../../libraries/interview-templates';

interface Props {
  interviewTemplate: InterviewTemplatePayload;
  setClickedSubmit: Dispatch<SetStateAction<boolean>>;
  setInterviewTemplate: (template: Partial<InterviewTemplatePayload>) => void;
  setStageInterviewId: (id: string) => void;
  stageInterviewId: string;
  stageInterviewName: string;
  stageInterviewPosition: number;
}

const InterviewTemplateInlineForm = ({
  interviewTemplate,
  setInterviewTemplate,
  setStageInterviewId,
  setClickedSubmit,
  stageInterviewId,
  stageInterviewName,
  stageInterviewPosition,
}: Props) => {
  const isNewTemplate = !interviewTemplate.id || interviewTemplate.id === 'new';

  const { id: jobId, stageId } = useParams<{ id: string; stageId: string }>();

  const { account } = useSession();
  const { data: stage } = useStage(jobId, stageId);
  const { data: originalInterviewTemplate } = useInterviewTemplate(interviewTemplate.id);
  const users = useUsersMap({ archived: true });

  const [isFetching, setIsFetching] = useState(false);
  const [error, setError] = useState<Error | null>(null);

  const stageInterview = useMemo(() => find(stage?.stage_interviews, ['id', stageInterviewId]), [stage?.stage_interviews]);

  const createInterviewTemplateMutation = useCreateInterviewTemplate();
  const updateInterviewTemplateMutation = useUpdateInterviewTemplate();
  const createStageInterviewMutation = useCreateStageInterview();
  const updateStageInterviewMutation = useUpdateStageInterview();

  useEffect(() => {
    setClickedSubmit(false);
  }, []);

  const atsInterviewers = useMemo(() => stageInterview?.ats_interviewer_ids?.map((id) => users[id]).filter(Boolean).filter(({ directory_archived, user_archived }) => !user_archived && !directory_archived), [stageInterview, users]);

  const handleInterviewerTemplatesChange = useCallback((interviewerTemplates: CreateInterviewTemplatePayload['interviewer_templates']) => {
    setInterviewTemplate({
      interviewer_templates: interviewerTemplates,
    });
  }, [setInterviewTemplate]);

  const handleNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setInterviewTemplate({
      name: e.target.value,
    });
  }, [setInterviewTemplate]);

  const handleDurationChange = useCallback((duration: number) => {
    setInterviewTemplate({
      duration_minutes: duration,
    });
  }, [setInterviewTemplate]);

  const handleLiveCodingEnabledChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setInterviewTemplate({
      live_coding_enabled: e.target.checked,
    });
  }, [setInterviewTemplate]);

  const handleCandidateFacingNameChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    setInterviewTemplate({
      candidate_facing_name: e.target.value || '',
    });
  }, [setInterviewTemplate]);

  const handlePositionsChange = useCallback((option: OnChangeValue<Option<number>, true>) => {
    setInterviewTemplate({
      positions: option.map(({ value }) => value),
    });
  }, [setInterviewTemplate]);

  const handleWindowStartChange = useCallback((option: OnChangeValue<Option<string>, false>) => {
    setInterviewTemplate({
      time_window_start: option?.value || '',
    });
  }, [setInterviewTemplate]);

  const handleWindowEndChange = useCallback((option: OnChangeValue<Option<string>, false>) => {
    setInterviewTemplate({
      time_window_end: option?.value || '',
    });
  }, [setInterviewTemplate]);

  const setCandidateFacingDetails = useCallback((details: string) => {
    setInterviewTemplate({
      candidate_facing_details: details,
    });
  }, [setInterviewTemplate]);

  const handleCreateInterviewTemplate = useCallback(async () => {
    createInterviewTemplateMutation.reset();
    updateInterviewTemplateMutation.reset();
    createStageInterviewMutation.reset();
    updateStageInterviewMutation.reset();

    setError(null);
    setIsFetching(true);

    // Create new interview template
    let newInterviewTemplateId;
    try {
      const data = await createInterviewTemplateMutation.mutateAsync({
        payload: {
          ...omit(interviewTemplate, ['id']),
          positions: (interviewTemplate?.positions || []).length > 0 ? sortPositions(interviewTemplate.positions) : undefined,
          candidate_facing_details: interviewTemplate?.candidate_facing_details && interviewTemplate.candidate_facing_details !== '<br>' ? interviewTemplate.candidate_facing_details : undefined,
        },
      });
      newInterviewTemplateId = data.id;
      setInterviewTemplate({
        ...data,
        interviewer_templates: data.interviewer_templates ? data.interviewer_templates.map((interviewerTemplate) => ({
          ...interviewerTemplate,
          interviewer_filters: (interviewerTemplate.interviewer_filters || []).map((filter) => ({
            ...filter,
            interviewer_filter_expressions: filter.interviewer_filter_expressions || [],
          })),
        })) : [],
      });
    } catch (err) {
      if (err instanceof Error) {
        setError(err);
      }
      setIsFetching(false);
      return;
    }

    // If the interview template is for a new stage interview, create the new stage interview
    // Otherwise, update the stage interview to link to the new interview template
    try {
      if (stageInterviewId.startsWith('new')) {
        const data = await createStageInterviewMutation.mutateAsync({
          jobId,
          stageId,
          payload: {
            name: stageInterviewName,
            interview_template_id: newInterviewTemplateId,
            position: stageInterviewPosition,
          },
        });
        setStageInterviewId(data.id);
      } else {
        await updateStageInterviewMutation.mutateAsync({
          id: stageInterviewId,
          jobId,
          stageId,
          payload: {
            interview_template_id: newInterviewTemplateId,
          },
        });
      }
      setClickedSubmit(true);
    } catch (err) {
      if (err instanceof Error) {
        setError(err);
      }
      setIsFetching(false);
      return;
    }

    setIsFetching(false);
  }, [
    interviewTemplate,
    jobId,
    setClickedSubmit,
    setInterviewTemplate,
    setStageInterviewId,
    stageId,
    stageInterviewId,
    stageInterviewName,
    stageInterviewPosition,
  ]);

  const handleUpdateInterviewTemplate = useCallback(async () => {
    createInterviewTemplateMutation.reset();
    updateInterviewTemplateMutation.reset();
    createStageInterviewMutation.reset();
    updateStageInterviewMutation.reset();

    setClickedSubmit(false);
    setError(null);
    setIsFetching(true);

    const updatePayload = constructUpdateInterviewTemplatePayload(interviewTemplate, originalInterviewTemplate!);
    try {
      await updateInterviewTemplateMutation.mutateAsync({ id: interviewTemplate.id, payload: updatePayload });
    } catch (err) {
      if (err instanceof Error) {
        setError(err);
      }
      setIsFetching(false);
      return;
    }

    if (stageInterview && stageInterview.interview_template_id !== interviewTemplate.id) {
      try {
        await updateStageInterviewMutation.mutateAsync({
          id: stageInterviewId,
          jobId,
          stageId,
          payload: {
            interview_template_id: interviewTemplate.id,
          },
        });
      } catch (err) {
        if (err instanceof Error) {
          setError(err);
        }
        setIsFetching(false);
        return;
      }
    }

    setClickedSubmit(true);
    setIsFetching(false);
  }, [
    interviewTemplate,
    jobId,
    originalInterviewTemplate,
    setClickedSubmit,
    stageId,
    stageInterview?.interview_template_id,
    stageInterviewId,
  ]);

  if (!interviewTemplate) {
    return <LoadingSpinner />;
  }

  const buttons = {
    save: <Button
      color="gem-blue"
      iconLeft={isNewTemplate ? <FontAwesomeIcon icon={faPlus} /> : undefined}
      iconRight={!isNewTemplate ? <FontAwesomeIcon icon={faSave} /> : undefined}
      onClick={isNewTemplate ? handleCreateInterviewTemplate : handleUpdateInterviewTemplate}
      size="small"
      value={isNewTemplate ? 'Create Template' : 'Save Changes'}
    />,
    saving: <Button
      color="gem-blue"
      iconRight={<FontAwesomeIcon icon={faCircleNotch} spin />}
      isDisabled
      size="small"
      value="Saving..."
    />,
  };

  const isSuccess = createInterviewTemplateMutation.isSuccess ||
    updateInterviewTemplateMutation.isSuccess ||
    createStageInterviewMutation.isSuccess ||
    updateStageInterviewMutation.isSuccess;

  return (
    <div className="interview-template-inline-form">
      <div className="inline-form-section details">
        <h6 className="section-heading"><b>1.</b> Details</h6>
        <Flash
          message={`We have pre-filled the interview duration from ${capitalize(account?.ats_type)}. Modify it if needed.`}
          showFlash={isNewTemplate && Boolean(stageInterview && stageInterview.ats_duration_minutes)}
          type="info"
        />
        <TextInput
          helperText={isNewTemplate ? 'We recommend removing the job name if you will reuse this template.' : null}
          isRequired
          label="Template Name"
          name="name"
          onChange={handleNameChange}
          value={interviewTemplate.name}
        />
        <DurationInput
          isRequired
          label="Interview Duration"
          onChange={handleDurationChange}
          value={interviewTemplate.duration_minutes}
        />
        {account?.live_coding_type && (
          <CheckboxInput
            className="live-coding-enabled-input"
            helperText="The link will be added to interviewer calendar events and the Schedule.Agenda token."
            isChecked={interviewTemplate.live_coding_enabled}
            label={`Create a ${liveCodingLabels[account.live_coding_type]} link when scheduling this interview.`}
            onChange={handleLiveCodingEnabledChange}
          />
        )}
        <InterviewTemplateAdvancedSettings
          candidateFacingDetails={interviewTemplate.candidate_facing_details}
          candidateFacingName={interviewTemplate.candidate_facing_name}
          duration={interviewTemplate.duration_minutes}
          handleCandidateFacingNameChange={handleCandidateFacingNameChange}
          handlePositionsChange={handlePositionsChange}
          handleTimeWindowEndChange={handleWindowEndChange}
          handleTimeWindowStartChange={handleWindowStartChange}
          isExpanding
          positions={interviewTemplate.positions}
          setCandidateFacingDetails={setCandidateFacingDetails}
          stageInterviewName={stageInterview?.name}
          timeWindowEnd={interviewTemplate.time_window_end}
          timeWindowStart={interviewTemplate.time_window_start}
        />
      </div>
      <div className="inline-form-section interviewers">
        <h6 className="section-heading"><b>2.</b> Interviewers</h6>
        <PreFillInterviewerTemplatesFlash
          handleInterviewerTemplatesChange={handleInterviewerTemplatesChange}
          interviewerIds={stageInterview && stageInterview.ats_interviewer_ids || []}
          showFlash={Boolean(isNewTemplate && atsInterviewers && atsInterviewers.length > 0)}
        />
        <InterviewerTemplatesForm
          defaultInterviewerTemplates={isNewTemplate && atsInterviewers && atsInterviewers.length > 0 ? atsInterviewers.map((interviewer) => ({
            optional: false,
            include_past_interviewers: true,
            interviewer_filters: [{
              interviewer_filter_expressions: [{
                negated: false,
                filterable_id: interviewer.id,
                filterable_type: 'user',
              }],
            }],
          })) : undefined}
          interviewerTemplates={interviewTemplate.interviewer_templates}
          setInterviewerTemplates={handleInterviewerTemplatesChange}
        />
      </div>
      <div className="button-container">
        <Flash
          message={error?.message}
          showFlash={Boolean(error)}
          type="danger"
        />
        <Flash
          isDismissible
          message="Successfully updated!"
          showFlash={isSuccess}
          type="success"
        />
        {isFetching ? buttons.saving : buttons.save}
      </div>
    </div>
  );
};

export default InterviewTemplateInlineForm;
