import type { BusinessHour } from './business-hours';
import type { Directory } from './integrations';
import type { IgnoreWord } from './ignore-words';

export enum UserRole {
  Owner = 'owner',
  User = 'user',
}

export enum UserAvatarColor {
  AmaranthRed = 'amaranth-red',
  ZestOrange = 'zest-orange',
  GrassYellow = 'grass-yellow',
  LimaGreen = 'lima-green',
  MeadowGreen = 'meadow-green',
  JavaBlue = 'java-blue',
  AzureBlue = 'azure-blue',
  AmethystPurple = 'amethyst-purple',
  OrchidPink = 'orchid-pink',
}

// If you update this, make sure you update the corresponding color in
// src/styles/colors.ts.
export const avatarColorToHex: Record<UserAvatarColor, string> = {
  [UserAvatarColor.AmaranthRed]: '#E52B50',
  [UserAvatarColor.ZestOrange]: '#E38820',
  [UserAvatarColor.GrassYellow]: '#DBBC23',
  [UserAvatarColor.LimaGreen]: '#87AB39',
  [UserAvatarColor.MeadowGreen]: '#18D285',
  [UserAvatarColor.JavaBlue]: '#18C2D2',
  [UserAvatarColor.AzureBlue]: '#315BA1',
  [UserAvatarColor.AmethystPurple]: '#9966CC',
  [UserAvatarColor.OrchidPink]: '#E386E0',
};

export interface User {
  id: string;
  ats_id?: string;
  gem_internal_id?: string;
  gem_can_send_email: boolean;
  video_conferencing_id?: string;
  connected_directory?: boolean;
  connected_video_conferencing?: boolean;
  valid_zoom_token?: boolean;
  name?: string;
  email: string;
  account_id: string;
  account?: {
    id: string;
    directory_type: Directory;
  };
  role: UserRole;
  color: UserAvatarColor;
  thumbnail_photo_url?: string;
  business_hours: BusinessHour[] | null;
  default_business_hours: BusinessHour[] | null;
  default_timezone?: string;
  internal_notes?: string;
  linkedin_url?: string;
  phone_number?: string;
  title?: string;
  daily_interview_limit?: number;
  weekly_interview_limit?: number;
  interviewer_meeting_buffer_minutes: number | null;
  ignore_free_events: boolean | null;
  user_tags?: UserTag[];
  eligibilities?: Eligibility[];
  ignore_words?: IgnoreWord[];
  email_notification_schedule_confirmed: boolean;
  email_notification_candidate_availability: boolean;
  created_at: string;
  updated_at: string;
  user_archived: boolean;
  directory_archived: boolean;
}

export interface UserTag {
  id: string;
  created_at: string;
}

export interface Eligibility {
  id: string;
  trainee: boolean;
  training_program_id?: string;
  // This is only included if the include_count query param is passed in. If the count is 0, it will be undefined.
  user_count?: number;
  created_at: string;
}
