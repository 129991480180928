import { isEmpty } from 'lodash';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';

import BusinessHoursInput from '../../../../../../library/inputs/BusinessHoursInput';
import CheckboxInput from '../../../../../../library/inputs/CheckboxInput';
import Flash from '../../../../../../library/utils/Flash';
import RoomFiltersBuilder from '../../../../../../library/inputs/RoomFiltersBuilder';
import Section from '../../../../../../library/layout/Section';
import ZoomHostFiltersBuilder from '../../../../../../library/inputs/ZoomHostFiltersBuilder';
import { SentinelTimes } from '../../../../../../../types/business-hours';
import { createEmptyRoomFilter } from '../../../../../../../libraries/rooms';
import { createEmptyZoomHostFilter } from '../../../../../../../libraries/zoom-hosts';
import { useSession } from '../../../../../../../hooks/use-session';
import { useStage, useUpdateStage } from '../../../../../../../hooks/queries/stages';

import type { ChangeEvent } from 'react';
import type { EditableBusinessHour } from '../../../../../../../types/business-hours';
import type { EditableRoomFilter, EditableZoomHostFilter } from '../../../../../../../types';
import type { Group, Option } from '../../../../../../library/inputs/SelectInput/types';
import type { SelectInstance } from 'react-select';
import { correctPath } from 'libraries/gem';

const PreferencesSection = () => {
  const { id: jobId, stageId } = useParams<{ id: string; stageId: string }>();

  const { account } = useSession();
  const { data: stage } = useStage(jobId, stageId);
  const scheduleTemplate = stage?.schedule_template;

  const hasVideoConferencingIntegration = Boolean(account?.video_conferencing_type);

  const [businessHours, setBusinessHours] = useState<EditableBusinessHour[]>((scheduleTemplate?.business_hours || []).map((bh) => {
    return {
      ...bh,
      start_time: bh.start_time === '00:00' ? '' : bh.start_time,
      end_time: bh.end_time === '24:00' ? '' : bh.end_time,
    };
  }));
  const [isVideoConferencingEnabled, setIsVideoConferencingEnabled] = useState(scheduleTemplate?.video_conferencing_enabled || false);
  const [roomFilters, setRoomFilters] = useState<EditableRoomFilter[]>(scheduleTemplate?.room_filters || createEmptyRoomFilter());
  const [zoomHostFilters, setZoomHostFilters] = useState<EditableZoomHostFilter[]>(scheduleTemplate?.zoom_host_filters || createEmptyZoomHostFilter());
  const [createHiringChannel, setCreateHiringChannel] = useState(scheduleTemplate?.create_hiring_channel || false);
  const [isEditing, setIsEditing] = useState(false);

  const updateStageMutation = useUpdateStage();

  const windowStartRef = useRef<SelectInstance<Option<string>, false, Group<string, Option<string>>>>(null);

  useEffect(() => {
    if (scheduleTemplate) {
      setBusinessHours((scheduleTemplate.business_hours || []).map((bh) => {
        return {
          ...bh,
          start_time: bh.start_time === '00:00' ? '' : bh.start_time,
          end_time: bh.end_time === '24:00' ? '' : bh.end_time,
        };
      }));
      setIsVideoConferencingEnabled(scheduleTemplate.video_conferencing_enabled);
      setRoomFilters(scheduleTemplate.room_filters || createEmptyRoomFilter());
      setZoomHostFilters(scheduleTemplate.zoom_host_filters || createEmptyZoomHostFilter());
      setCreateHiringChannel(scheduleTemplate.create_hiring_channel);
    }
  }, [scheduleTemplate]);

  useEffect(() => {
    if (isEditing) {
      windowStartRef.current?.focus();
    }
  }, [isEditing]);

  const handleIsVideoConferencingEnabledChange = (e: ChangeEvent<HTMLInputElement>) => {
    const isChecked = e.target.checked;
    setIsVideoConferencingEnabled(isChecked);
  };

  const handleRoomFiltersChange = (filters: EditableRoomFilter[]) => {
    setRoomFilters(filters);
  };

  const handleZoomHostFiltersChange = (filters: EditableZoomHostFilter[]) => {
    setZoomHostFilters(filters);
  };

  const handleCreateHiringChannelChange = (e: ChangeEvent<HTMLInputElement>) => {
    setCreateHiringChannel(e.target.checked);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    updateStageMutation.reset();
    setBusinessHours((scheduleTemplate?.business_hours || []).map((bh) => {
      return {
        ...bh,
        start_time: bh.start_time === '00:00' ? '' : bh.start_time,
        end_time: bh.end_time === '24:00' ? '' : bh.end_time,
      };
    }));
    setIsVideoConferencingEnabled(scheduleTemplate?.video_conferencing_enabled || false);
    setRoomFilters(scheduleTemplate?.room_filters || createEmptyRoomFilter());
    setZoomHostFilters(scheduleTemplate?.zoom_host_filters || createEmptyZoomHostFilter());
    setCreateHiringChannel(scheduleTemplate?.create_hiring_channel || false);
    setIsEditing(false);
  };

  const handleSave = async () => {
    updateStageMutation.reset();

    try {
      await updateStageMutation.mutateAsync({
        id: stageId,
        jobId,
        payload: {
          business_hours: businessHours.map((bh) => ({
            day: bh.day,
            start_time: bh.start_time || '00:00',
            end_time: bh.end_time || '24:00',
            timezone: bh.timezone || undefined,
          })),
          room_filters: (roomFilters || []).filter(({ room_filter_expressions }) => !isEmpty(room_filter_expressions)).map(({ room_filter_expressions }) => ({
            room_filter_expressions: room_filter_expressions.map((exp) => ({
              filterable_id: exp.filterable_id,
              filterable_type: exp.filterable_type,
              negated: exp.negated,
            })),
          })),
          video_conferencing_enabled: isVideoConferencingEnabled,
          zoom_host_filters: isVideoConferencingEnabled && account?.video_conferencing_type === 'zoom' && (zoomHostFilters || []).map(({ zoom_host_filter_expressions }) => ({
            zoom_host_filter_expressions: zoom_host_filter_expressions.map((exp) => ({
              filterable_id: exp.filterable_id,
              filterable_type: exp.filterable_type,
              negated: exp.negated,
            })),
          })) || undefined,
          create_hiring_channel: createHiringChannel,
        },
      });
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  const hasSchedulingWindow = (scheduleTemplate?.business_hours || []).length > 0;

  return (
    <Section
      className="job-stage-preferences"
      isEditable
      isEditing={isEditing}
      isSaving={updateStageMutation.isLoading}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Preferences"
    >
      <Flash
        message="We suggest setting scheduling windows that match your team's working hours."
        showFlash={!hasSchedulingWindow}
        type="info"
      />
      <Flash
        isDismissible
        message="Successfully updated!"
        onDismiss={updateStageMutation.reset}
        showFlash={updateStageMutation.isSuccess}
        type="success"
      />
      <Flash
        message={updateStageMutation.error?.message}
        showFlash={updateStageMutation.isError}
        type="danger"
      />
      <Flash
        message={<>You currently have automatic sends enabled. We <b>will not</b> use any windows that don&apos;t have a timezone when sending the request automatically. Add timezones here so that they will be used.</>}
        showFlash={Boolean((stage?.availability_template?.automatic_sends_enabled_at || stage?.schedule_template?.self_scheduling_automatic_sends_enabled_at) && stage.schedule_template?.business_hours?.some((bh) => !bh.timezone && (bh.start_time !== SentinelTimes.StartOfDay || bh.end_time !== SentinelTimes.EndOfDay)))}
        type="warning"
      />
      <BusinessHoursInput
        businessHours={businessHours}
        helperText={<>Leave start and end blank to allow the entire day.</>}
        isDisabled={!isEditing || updateStageMutation.isLoading}
        isTimezoneClearable
        label="Scheduling Windows"
        setBusinessHours={setBusinessHours}
      />
      <Flash
        message={<span>To schedule video interviews, you must set up a video conferencing integration <Link to={correctPath('/app/integrations')}>here.</Link></span>}
        showFlash={!hasVideoConferencingIntegration && isVideoConferencingEnabled}
        type="warning"
      />
      <div className="job-stage-location-input-container">
        <RoomFiltersBuilder
          filters={roomFilters}
          helperText="We will generate schedules that use these rooms."
          isDisabled={!isEditing || updateStageMutation.isLoading}
          label="Interview Rooms"
          onChange={handleRoomFiltersChange}
        />
        <CheckboxInput
          helperText="We will create a video conferencing link and add it to all events."
          isChecked={isVideoConferencingEnabled}
          isDisabled={!isEditing || updateStageMutation.isLoading}
          label="Add video conferencing."
          onChange={handleIsVideoConferencingEnabledChange}
        />
        {isVideoConferencingEnabled && account?.video_conferencing_type === 'zoom' &&
          <ZoomHostFiltersBuilder
            filters={zoomHostFilters}
            isDisabled={!isEditing || updateStageMutation.isLoading}
            label="Zoom Meeting Host"
            onChange={handleZoomHostFiltersChange}
          />
        }
        {account?.chat_type && (
          <CheckboxInput
            helperText="We will create a private hiring channel for the candidate and invite all current and past interviewers."
            isChecked={createHiringChannel}
            isDisabled={!isEditing || updateStageMutation.isLoading}
            label="Create a hiring channel."
            onChange={handleCreateHiringChannelChange}
          />
        )}
      </div>
    </Section>
  );
};

export default PreferencesSection;
