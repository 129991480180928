import classNames from 'classnames';
import { faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from './Button';
import Tooltip from '../utils/Tooltip';

import type { MouseEventHandler } from 'react';

interface Props {
  alwaysRed?: boolean;
  className?: string;
  id: string;
  isDisabled?: boolean;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  tooltipText?: string;
}

const DeleteButton = ({
  alwaysRed,
  className,
  id,
  isDisabled,
  onClick,
  tooltipText = 'Delete',
}: Props) => (
  <Button
    className={classNames('btn-delete', className, alwaysRed && 'btn-delete-always-red')}
    color="gray"
    iconRight={<FontAwesomeIcon icon={faTrashAlt} />}
    isDisabled={isDisabled}
    onClick={onClick}
    size="large"
    tooltip={
      <Tooltip
        id={`${id}-delete-button`}
        position="top"
        value={tooltipText}
      />
    }
  />
);

export default DeleteButton;
