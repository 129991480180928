import { useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import { useCreateAssignment, useUpdateAssignment, useDeleteAssignment } from 'hooks/queries/assignments';
import Flash from 'components/library/utils/Flash';
import TextAreaInput from 'components/library/inputs/TextAreaInput';
import UserSelectInput from 'components/library/inputs/UserSelectInput';
import { StyledContainer, StyledModal } from './styles';

import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from 'components/library/inputs/SelectInput/types';
import type { ChangeEvent } from 'react';
import type { Assignment } from 'types';

interface Props {
  assignment?: Assignment;
  isOpen: boolean;
  onToggle: () => void;
}

const AssignCandidateModal = ({ assignment, isOpen, onToggle }: Props) => {
  const { id } = useParams<{ id: string }>();

  const createAssignmentMutation = useCreateAssignment();
  const updateAssignmentMutation = useUpdateAssignment();
  const deleteAssignmentMutation = useDeleteAssignment();

  const [assigneeId, setAssigneeId] = useState(assignment?.assignee_id);
  const [note, setNote] = useState(assignment?.note);

  useEffect(() => {
    if (isOpen) {
      setAssigneeId(assignment?.assignee_id);
      setNote(assignment?.note);
    }
  }, [isOpen, assignment]);

  const handleAssigneeChange = (option: OnChangeValue<Option<string>, false>) => {
    setAssigneeId(option?.value || '');
  };

  const handleNoteChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setNote(e.target.value);
  };

  const handleSubmit = async () => {
    createAssignmentMutation.reset();
    updateAssignmentMutation.reset();
    deleteAssignmentMutation.reset();

    if (assigneeId && (!assignment || assigneeId !== assignment.assignee_id)) {
      try {
        await createAssignmentMutation.mutateAsync({
          payload: {
            application_id: id,
            assignee_id: assigneeId,
            note,
          },
        });
      } catch (_) {
        // Since React Query catches the error and attaches it to the mutation, we
        // don't need to do anything with this error besides prevent it from
        // bubbling up.
      }
    } else if (assignment && assigneeId === assignment.assignee_id) {
      try {
        await updateAssignmentMutation.mutateAsync({
          id: assignment.id,
          payload: { note },
        });
      } catch (_) {
        // Since React Query catches the error and attaches it to the mutation, we
        // don't need to do anything with this error besides prevent it from
        // bubbling up.
      }
    } else if (assignment && !assigneeId) {
      try {
        await deleteAssignmentMutation.mutateAsync({
          id: assignment.id,
        });
      } catch (_) {
        // Since React Query catches the error and attaches it to the mutation, we
        // don't need to do anything with this error besides prevent it from
        // bubbling up.
      }
    }

    onToggle();
  };

  const isLoading = createAssignmentMutation.isLoading ||
    updateAssignmentMutation.isLoading ||
    deleteAssignmentMutation.isLoading;

  const errors = [
    createAssignmentMutation.error?.message,
    updateAssignmentMutation.error?.message,
    deleteAssignmentMutation.error?.message,
  ].filter((obj) => Boolean(obj));

  return (
    <StyledModal
      cancelButtonValue="Cancel"
      isOpen={isOpen}
      isSubmitting={isLoading}
      onSubmit={handleSubmit}
      onToggle={onToggle}
      showSubmitButton={assignment?.assignee_id !== assigneeId || assignment?.note !== note}
      submitButtonValue="Save"
      title={assignment ? 'Update assignment?' : 'Assign this candidate to someone on your team?'}
    >
      <Flash
        message={errors.join('. ')}
        showFlash={errors.length > 0}
        type="danger"
      />
      <StyledContainer>
        <UserSelectInput
          currentUserFirst
          isClearable
          isDisabled={isLoading}
          label="Assignee"
          onChange={handleAssigneeChange}
          placeholder="Unassigned"
          value={assigneeId}
        />
        <TextAreaInput
          helperText="Additional information for coordinating these interviews, e.g. preferred interviewers or unique scheduling requirements."
          isDisabled={isLoading}
          isRequired
          label="Note"
          maxLength={500}
          onChange={handleNoteChange}
          value={note}
        />
      </StyledContainer>
    </StyledModal>
  );
};

export default AssignCandidateModal;
