import { findIndex } from 'lodash';
import { useQueryClient } from 'react-query';

import { StyledInterviewerSelectInput } from './styles';
import { isOverrideType, isSessionType, PendingChangeType } from './types';
import { trainingProgramUserParams } from '../../../../../hooks/queries/training-program-users';
import { useUsersMap } from '../../../../../hooks/queries/users';

import type { Dispatch, SetStateAction } from 'react';
import type { PendingChange, PendingChangeUser } from './types';
import type { TrainingProgramUser } from '../../../../../types';

interface Props {
  originalTrainingProgramUsersMap: Record<string, TrainingProgramUser>;
  selectedUser: PendingChangeUser;
  setPendingChanges: Dispatch<SetStateAction<PendingChange[]>>;
  setTrainingProgramUsers: Dispatch<SetStateAction<PendingChangeUser[] | undefined>>;
  trainingProgramId: string;
  trainingProgramUserIds: string[];
}

const TrainingInterviewerSelectInput = ({
  originalTrainingProgramUsersMap,
  selectedUser,
  setPendingChanges,
  setTrainingProgramUsers,
  trainingProgramId,
  trainingProgramUserIds,
}: Props) => {
  const queryClient = useQueryClient();

  const users = useUsersMap({ archived: true });

  return (
    <StyledInterviewerSelectInput
      disabledUserIds={trainingProgramUserIds}
      isRequired
      onChange={async (option) => {
        const originalUser = originalTrainingProgramUsersMap[option!.value];

        setTrainingProgramUsers((prev) => {
          return prev?.map((user) => {
            if (user.uniqueId === selectedUser.uniqueId) {
              // This is the training program user in question, so we should update it.
              return {
                ...user,
                id: option!.value,
                name: users[option!.value]?.name,
                // If we're adding a user back, using their training status. Otherwise, don't change it.
                trainee: originalUser ? originalUser.trainee : user.trainee,
                // For these, we use the original value or clear it out to null.
                training_sessions: (originalUser?.training_sessions || user.training_sessions)?.map((session) => {
                  if (!session.user_id) {
                    return {
                      ...session,
                      user_id: option!.value,
                    };
                  }
                  return session;
                }) || null,
                training_overrides: (originalUser?.training_overrides || user.training_overrides)?.map((override) => {
                  if (!override.user_id) {
                    return {
                      ...override,
                      user_id: option!.value,
                    };
                  }
                  return override;
                }) || null,
              };
            }
            return user;
          });
        });
        setPendingChanges((prev) => {
          const index = findIndex(prev, (change) => change.type === PendingChangeType.TrainingProgramUserCreate && change.user.uniqueId === selectedUser.uniqueId);
          if (index === -1 && !originalUser) {
            // We haven't added this change yet and this is not a user that we're "adding back".
            prev = [
              ...prev,
              {
                type: PendingChangeType.TrainingProgramUserCreate,
                user: {
                  ...selectedUser,
                  id: option!.value,
                  name: users[option!.value]?.name,
                },
              },
            ];
          }

          const hasSessions = originalUser ? originalUser.training_sessions && originalUser.training_sessions.length > 0 : false;
          const hasOverrides = originalUser ? originalUser.training_overrides && originalUser.training_overrides.length > 0 : false;
          return prev.map((change) => {
            // Go through and for every change that references this user's unique ID, update the user to also have
            // it's real ID too.
            if (change.user.uniqueId === selectedUser.uniqueId) {
              return {
                ...change,
                user: {
                  ...change.user,
                  id: option!.value,
                  name: users[option!.value]?.name,
                },
              };
            }
            return change;
          }).filter((change) => {
            if (!originalUser) {
              // This filtering logic only applies if there is an original user, so if we don't have one, just return
              // true for everything to avoid filtering anything out.
              return true;
            }
            // This is a user that we previously deleted and have added back, so we should delete any create/delete
            // pending changes for this user. But if the original user doesn't have an sessions/overrides, we don't want
            // to clear those changes out because we'll be preserving them even after this select input selection
            // resolves.
            if (change.user.id ? change.user.id === originalUser.id : change.user.uniqueId === selectedUser.uniqueId) {
              // This is a change for this user.
              if (!hasSessions && isSessionType(change.type)) {
                return true;
              }
              if (!hasOverrides && isOverrideType(change.type)) {
                return true;
              }
              // Every other change for this user should be filtered out.
              return false;
            }
            // This is a change for another user, so don't touch it.
            return true;
          });
        });

        // Fetch the user from the server so that we can load in any past sessions/overrides.
        if (!originalUser) {
          const fetchedUser = await queryClient.fetchQuery(trainingProgramUserParams(trainingProgramId, option!.value));
          setTrainingProgramUsers((prev) => {
            return prev?.map((user) => {
              if (user.id === fetchedUser.id) {
                // This is the training program user we just fetched, so we should update the sessions and overrides.
                const isAnyExistingSessions = user.training_sessions?.some((session) => !session.id.startsWith('new:'));
                const isAnyExistingOverrides = user.training_overrides?.some((override) => !override.id.startsWith('new:'));
                return {
                  ...user,
                  // If the fetched user has sessions/overrides, prefer that over any manual sessions they added before
                  // selecting the user. If the fetched user doesn't have anything, then prefer anything they've already
                  // added. But we only want to do this if the sessions/overrides are all newly added. Without this
                  // check, it would be possible to "pull" the training history from one user to another by toggling the
                  // select input.
                  training_sessions: fetchedUser.training_sessions || (isAnyExistingSessions ? null : user.training_sessions),
                  training_overrides: fetchedUser.training_overrides || (isAnyExistingOverrides ? null : user.training_overrides),
                };
              }
              return user;
            });
          });
        }
      }}
      placeholder="Select an interviewer"
      value={selectedUser.id}
    />
  );
};

export default TrainingInterviewerSelectInput;
