import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';

// This is inspired by this article:
// https://ericdcobb.medium.com/scrolling-to-an-anchor-in-react-when-your-elements-are-rendered-asynchronously-8c64f77b5f34

const useScrollToLocation = () => {
  const { hash } = useLocation();

  const scrolledRef = useRef(false);
  const hashRef = useRef(hash);

  useEffect(() => {
    if (!hash) {
      // There is no hash, so don't do anything.
      return;
    }

    // We want to reset if the hash has changed.
    if (hashRef.current !== hash) {
      hashRef.current = hash;
      scrolledRef.current = false;
    }

    if (scrolledRef.current) {
      // We've already scrolled for this hash, so don't scroll again.
      return;
    }

    // We delay the scrolling by 100ms so that we give it time for the styles to affect the height of the elements.
    // Without the delay, it doesn't scroll all the way to the element.
    const id = setTimeout(() => {
      const id = hash.replace('#', '');
      const element = document.getElementById(id);
      if (element) {
        // Only scroll (and mark that we've scrolled) if we actually found the element we intended on scrolling to.
        element.scrollIntoView({ behavior: 'smooth' });
        scrolledRef.current = true;
      }
    }, 100);

    return () => clearTimeout(id);
  });
};

export default useScrollToLocation;
