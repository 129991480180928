import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

import { directoryCalendarLabels } from 'types';
import Tooltip from 'components/library/utils/Tooltip';
import { useSession } from 'hooks/use-session';

interface Props {
  id: string;
}

const DirectoryCalendarIconTooltip = ({ id }: Props) => {
  const { account } = useSession();

  if (!account?.directory_type) {
    return null;
  }

  return (
    <Tooltip
      helperText={<FontAwesomeIcon icon={faExternalLinkAlt} />}
      id={id}
      position="top"
      value={`Open in ${directoryCalendarLabels[account.directory_type]}`}
    />
  );
};

export default DirectoryCalendarIconTooltip;
