import { Breadcrumb } from 'react-breadcrumbs';
import { useParams } from 'react-router-dom';

import CandidateSubmitAvailabilityForm from './CandidateSubmitAvailabilityForm';
import Section from '../../../../library/layout/Section';
import { useApplication } from '../../../../../hooks/queries/applications';
import { correctPath } from 'libraries/gem';

const CandidateSubmitAvailability = () => {
  const { id } = useParams<{ id: string }>();

  const application = useApplication(id).data!;

  return (
    <Breadcrumb
      data={{
        title: 'Submit Availability',
        pathname: correctPath(`/app/candidates/${application.id}/submit-availability`),
      }}
    >
      <Section
        className="candidate-submit-availability-container"
        title={`Submit availability for ${application.candidate.name} for ${application.current_stage?.name}`}
      >
        <div className="candidate-submit-availability-steps-container">
          <CandidateSubmitAvailabilityForm />
        </div>
      </Section>
    </Breadcrumb>
  );
};

export default CandidateSubmitAvailability;
