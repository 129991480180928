import styled from 'styled-components';

import HiringMeetingAttendeeTag from 'components/library/data-display/HiringMeetingAttendeeTag';

export const StyledContainer = styled.div`
  background: ${({ theme }) => theme.colors.PRODUCT.TERTIARY_BACKGROUND};
  border: 1px solid ${({ theme }) => theme.colors.PRODUCT.SECONDARY_BORDER};
  display: flex;
  flex-wrap: wrap;
  padding: .5em;
  position: relative;
  top: -1px;
`;

export const StyledHiringMeetingAttendeeTag = styled(HiringMeetingAttendeeTag)`
  margin: 3px;
`;
