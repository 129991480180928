import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import { find, orderBy } from 'lodash';
import { useMemo } from 'react';

import CalendarScheduleDetailsPane from './CalendarScheduleDetailsPane';
import ListItem from '../ListItem';
import PopoverSelectInput from '../../inputs/PopoverSelectInput';
import { isEvent } from '../../../../libraries/schedule';
import { useRoomsMap } from '../../../../hooks/queries/rooms';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Event as CalendarEvent } from '../../../../types';
import type { Option as SelectInputOption } from '../../inputs/SelectInput/types';
import type { PotentialRoom } from './types';

export interface Option extends SelectInputOption<string> {
  conflicts: CalendarEvent[] | null;
}

interface Props {
  date: Date;
  onChange: (newValue: OnChangeValue<Option, false>, actionMeta: ActionMeta<Option>) => void;
  potentialRooms: PotentialRoom[];
  selectedRoomId: string | null;
  targetId: string;
  timezone: string;
}

const CalendarScheduleRoomSelectInput = ({
  date,
  onChange,
  potentialRooms,
  selectedRoomId,
  targetId,
  timezone,
}: Props) => {
  const rooms = useRoomsMap();

  // If conflicts are null, it means that they haven't been loaded yet, so we'll
  // be showing a message to tell the user to click to load, and we want these
  // items to go to the bottom of the list, so we make the sort order 999.
  const options = useMemo<Option[]>(() => {
    return orderBy(potentialRooms.map(({ room_id, conflicts }) => ({
      // The potential ones are guaranteed to have the ID defined.
      value: room_id!,
      label: rooms[room_id!]?.name,
      conflicts: conflicts === undefined ? [] : conflicts,
    })), [({ conflicts }) => conflicts === null ? 999 : conflicts ? conflicts.length : 0]);
  }, [potentialRooms, rooms]);

  return (
    <PopoverSelectInput
      className="calendar-schedule-popover-select rooms"
      displayDetails={(option) => (
        <CalendarScheduleDetailsPane
          conflicts={option!.conflicts}
          date={date}
          id={option!.value}
          resource="room"
          timezone={timezone}
        />
      )}
      formatOptionLabel={(option, { context }) => {
        const nonIgnoredConflictsCount = (option.conflicts || []).filter((conflict) => !isEvent(conflict) || !conflict.ignored).length;
        return (
          context === 'menu' ?
            <ListItem
              label={option.label}
              leftIcon={
                <FontAwesomeIcon
                  className={nonIgnoredConflictsCount > 0 ? 'warning' : undefined}
                  icon={faDoorOpen}
                />
              }
              secondaryText={
                option.conflicts !== null ?
                  <span>
                    <span className={nonIgnoredConflictsCount > 0 ? 'warning' : undefined}>
                      {nonIgnoredConflictsCount || '0'}
                    </span> conflict{nonIgnoredConflictsCount === 1 ? '' : 's'}
                  </span> :
                  <span>Click to load conflicts</span>
              }
            /> :
            option.label
        );
      }}
      isClearable
      onChange={onChange}
      options={options}
      placeholder="Select a room"
      targetId={targetId}
      value={selectedRoomId ? find(options, ['value', selectedRoomId]) : null}
    />
  );
};

export default CalendarScheduleRoomSelectInput;
