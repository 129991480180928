import { capitalize, groupBy } from 'lodash';
import { useMemo } from 'react';

import { StyledCell, StyledContainer, StyledLabel, StyledRow } from './styles';
import { allDays, businessHourDayOrdering } from '../../../../types/business-hours';
import { formatBusinessHoursForWeekday } from '../../../../libraries/formatters';
import { sortBusinessHours } from '../../../../libraries/business-hours';

import type { EditableBusinessHour } from '../../../../types/business-hours';
import type { ReactNode } from 'react';

interface Props {
  businessHours: EditableBusinessHour[];
  className?: string;
  label?: ReactNode;
}

const BusinessHoursSummary = ({ businessHours, className, label }: Props) => {
  const grouped = useMemo(() => groupBy(sortBusinessHours(businessHours), 'day'), [businessHours]);

  if (businessHours.length === 0) {
    return <>No hours set</>;
  }

  return (
    <StyledContainer className={className}>
      {label && <StyledLabel>{label}</StyledLabel>}
      {allDays.map((day) => (
        grouped[day] ?
          <StyledRow key={day}>
            <StyledCell>{capitalize(day)}</StyledCell>
            <StyledCell>{formatBusinessHoursForWeekday(grouped[day], undefined, businessHourDayOrdering[day])}</StyledCell>
          </StyledRow> :
          null
      ))}
    </StyledContainer>
  );
};

export default BusinessHoursSummary;
