import { Breadcrumb } from 'react-breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Redirect, Route, Switch } from 'react-router-dom';
import { faTableList } from '@fortawesome/free-solid-svg-icons';

import DailyBreakdown from './DailyBreakdown';
import NavbarLinks from '../../library/navigation/NavbarLinks';
import UtilizationSummary from './UtilizationSummary';
import { correctPath } from 'libraries/gem';

const InterviewerReports = () => (
  <Breadcrumb
    data={{
      title: <><FontAwesomeIcon icon={faTableList} />Interviewer Reports</>,
      pathname: correctPath('/app/interviewer-reports'),
    }}
  >
    <div className="interviewer-reports-container">
      <NavbarLinks
        links={[{
          label: 'Utilization Summary',
          location: correctPath('/app/interviewer-reports/utilization-summary'),
        }, {
          label: 'Daily Breakdown',
          location: correctPath('/app/interviewer-reports/daily-breakdown'),
        }]}
      />
      <Switch>
        <Redirect exact from={correctPath('/app/interviewer-reports')} to={correctPath('/app/interviewer-reports/utilization-summary')} />
        <Route component={UtilizationSummary} path={correctPath('/app/interviewer-reports/utilization-summary')} />
        <Route component={DailyBreakdown} path={correctPath('/app/interviewer-reports/daily-breakdown')} />
      </Switch>
    </div>
  </Breadcrumb>
);

export default InterviewerReports;
