import { faCheck, faPen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from 'components/library/inputs/Button';
import { StyledButton, StyledContainer, StyledStepNumberContainer, StyledTitleContainer } from './styles';

interface Props {
  canUpdateAfterComplete?: boolean;
  isComplete: boolean;
  stepNumber: number;
  title: string;
  onGetStarted?: () => void;
}

const WelcomeStep = ({ canUpdateAfterComplete, isComplete, stepNumber, title, onGetStarted }: Props) => {
  return (
    <StyledContainer>
      <StyledStepNumberContainer>
        {stepNumber}.
      </StyledStepNumberContainer>
      <StyledTitleContainer>
        <h3>{title}</h3>
      </StyledTitleContainer>
      {isComplete ?
        <>
          {canUpdateAfterComplete && (
            <Button
              color="gem-outline"
              iconLeft={<FontAwesomeIcon icon={faPen} />}
              onClick={onGetStarted}
              size="large"
              value="Edit"
            />
          )}
          <StyledButton
            color="green"
            iconLeft={<FontAwesomeIcon icon={faCheck} />}
            isDisabled
            size="large"
            value="Completed"
          />
        </> :
        <StyledButton
          color="gem-blue"
          onClick={onGetStarted}
          size="large"
          value="Get started"
        />
      }
    </StyledContainer>
  );
};

export default WelcomeStep;
