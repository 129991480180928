import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';

import ConflictKeywordsInput from 'components/library/inputs/ConflictKeywordsInput';
import Flash from 'components/library/utils/Flash';
import OutboundLink from 'components/library/navigation/OutboundLink';
import OutOfOfficeKeywordsInput from 'components/library/inputs/OutOfOfficeKeywordsInput';
import { StyledModal } from './styles';
import { useAccount, useUpdateAccount } from 'hooks/queries/accounts';

import type { EditableIgnoreWord } from 'types';

interface Props {
  isOpen: boolean;
  onToggle: () => void;
}

const DEFAULT_CONFLICT_KEYWORDS = [
  { id: 'lunch', negated: false },
  { id: 'standup', negated: false },
  { id: 'DNS', negated: true },
];

const DEFAULT_OOO_KEYWORDS = [
  { id: 'OOO', negated: false },
  { id: 'PTO', negated: false },
];

const SetConflictKeywordsModal = ({ isOpen, onToggle }: Props) => {
  const { data } = useAccount();
  const account = data!;

  const [conflictKeywords, setConflictKeywords] = useState<EditableIgnoreWord[]>(isEmpty(account!.ignore_words) ? DEFAULT_CONFLICT_KEYWORDS : account.ignore_words);
  const [outOfOfficeKeywords, setOutOfOfficeKeywords] = useState<EditableIgnoreWord[]>(isEmpty(account!.ooo_words) ? DEFAULT_OOO_KEYWORDS : account.ooo_words);

  const updateAccountMutation = useUpdateAccount();

  useEffect(() => {
    setConflictKeywords(isEmpty(account!.ignore_words) ? DEFAULT_CONFLICT_KEYWORDS : account.ignore_words);
  }, [account!.ignore_words]);

  useEffect(() => {
    setOutOfOfficeKeywords(isEmpty(account!.ooo_words) ? DEFAULT_OOO_KEYWORDS : account.ooo_words);
  }, [account!.ooo_words]);

  const handleToggle = () => {
    onToggle();
  };

  const handleSave = async () => {
    updateAccountMutation.reset();

    try {
      await updateAccountMutation.mutateAsync({
        id: account.id,
        payload: {
          ignore_words: conflictKeywords.map(({ id, negated }) => ({ id, negated })),
          ooo_words: outOfOfficeKeywords.map(({ id, negated }) => ({ id, negated })),
        },
      });
      onToggle();
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  return (
    <StyledModal
      isOpen={isOpen}
      isSubmitting={updateAccountMutation.isLoading}
      onSubmit={handleSave}
      onToggle={handleToggle}
      submitButtonValue="Save"
      title="Set up conflict keywords?"
    >
      <Flash
        message={updateAccountMutation.error?.message}
        showFlash={updateAccountMutation.isError}
        type="danger"
      />
      <Flash
        message={<>Adding keywords to ignore certain events is highly recommended, as it allows us to find you more conflict-free schedules. <OutboundLink href="https://support.gem.com/hc/en-us/articles/23491222224407-How-do-I-get-Gem-Scheduling-to-ignore-certain-conflicts" label="Welcome - Conflict Keywords Modal">Learn more</OutboundLink></>}
        showFlash
        type="info"
      />
      <ConflictKeywordsInput
        isDisabled={updateAccountMutation.isLoading}
        keywords={conflictKeywords}
        setKeywords={setConflictKeywords}
      />
      <OutOfOfficeKeywordsInput
        isDisabled={updateAccountMutation.isLoading}
        keywords={outOfOfficeKeywords}
        setKeywords={setOutOfOfficeKeywords}
      />
    </StyledModal>
  );
};

export default SetConflictKeywordsModal;
