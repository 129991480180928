import {
  faBriefcase,
  faCalendarDay,
  faCalendarTimes,
  faComments,
  faDoorOpen,
  faEnvelope,
  faFilter,
  faProjectDiagram,
  faUnlink,
  faUser,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import type { NotificationType } from '../../../types';
import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';

enum TaskColor {
  Red = 'red',
  Blue = 'blue',
  Orange = 'orange',
}

const typeMapping: Record<NotificationType, { label: string; icon: IconDefinition; color: TaskColor }> = {
  candidate_declined: { label: 'Candidate Declined', icon: faUserTie, color: TaskColor.Red },
  google_token_error: { label: 'Broken Integration', icon: faProjectDiagram, color: TaskColor.Red },
  greenhouse_api_key_error: { label: 'Broken Integration', icon: faProjectDiagram, color: TaskColor.Red },
  greenhouse_permission_error: { label: 'Broken Integration', icon: faProjectDiagram, color: TaskColor.Red },
  interviewer_declined: { label: 'Interviewer Declined', icon: faUser, color: TaskColor.Red },
  job_newly_imported: { label: 'New Job', icon: faBriefcase, color: TaskColor.Blue },
  lever_api_key_error: { label: 'Broken Integration', icon: faProjectDiagram, color: TaskColor.Red },
  lever_permission_error: { label: 'Broken Integration', icon: faProjectDiagram, color: TaskColor.Red },
  microsoft365_auth_error: { label: 'Broken Integration', icon: faProjectDiagram, color: TaskColor.Red },
  ready_to_request_availability: { label: 'Ready to Request Availability', icon: faEnvelope, color: TaskColor.Orange },
  availability_request_cancelled: { label: 'Availability Request Cancelled', icon: faUnlink, color: TaskColor.Orange },
  ready_to_schedule: { label: 'Ready to Schedule', icon: faCalendarDay, color: TaskColor.Orange },
  schedule_cancelled: { label: 'Schedule Cancelled', icon: faCalendarTimes, color: TaskColor.Orange },
  room_declined: { label: 'Room Declined', icon: faDoorOpen, color: TaskColor.Red },
  slack_token_error: { label: 'Broken Integration', icon: faProjectDiagram, color: TaskColor.Red },
  stage_interview_newly_imported: { label: 'New Interview', icon: faComments, color: TaskColor.Blue },
  stage_newly_imported: { label: 'New Stage', icon: faFilter, color: TaskColor.Blue },
  zoom_token_error: { label: 'Broken Integration', icon: faProjectDiagram, color: TaskColor.Red },
};

interface Props {
  type: `${NotificationType}`;
}

const TaskLabel = ({ type }: Props) =>
  <div className={`label task-label task-label-color-${typeMapping[type].color}`}>
    <div className="icon-container">
      <FontAwesomeIcon icon={typeMapping[type].icon} size="lg" />
    </div>
    {typeMapping[type].label}
  </div>
;

export default TaskLabel;
