export enum CalendarEventTemplateType {
  CandidateCalendarEvent = 'candidate_calendar_event',
  HiringMeetingCalendarEvent = 'hiring_meeting_calendar_event',
}

export interface CreatableCalendarEventTemplate {
  id?: string;
  type?: CalendarEventTemplateType;
  name: string;
  title: string;
  description: string;
  location?: string;
  additional_attendees?: string[];
  additional_optional_attendees?: string[];
}

export interface CalendarEventTemplate {
  id: string;
  account_id: string;
  name: string;
  type: CalendarEventTemplateType;
  title: string;
  description: string;
  location?: string;
  additional_attendees?: string[];
  additional_optional_attendees?: string[];
  linked_resources: number;
  inline: boolean;
  favorite: boolean;
  created_at: string;
  updated_at: string;
  archived: boolean;
}

export const calendarEventTemplateTypeLabels: Record<CalendarEventTemplateType, string> = {
  [CalendarEventTemplateType.CandidateCalendarEvent]: 'Candidate Calendar Event',
  [CalendarEventTemplateType.HiringMeetingCalendarEvent]: 'Hiring Meeting Calendar Event',
};
