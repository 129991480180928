import styled from 'styled-components';

import Modal from '../../../library/layout/Modal';

export const StyledSubtitle = styled.span`
  color: ${({ theme }) => theme.colors.PRODUCT.SECONDARY_CONTENT};
  font-weight: normal;
`;

export const StyledTabContainer = styled.div`
  overflow: scroll;
  padding-bottom: 50px;
  padding-top: 1em;

  .flash {
    margin-bottom: 1em;

    a {
      padding: 0;
    }
  }
`;

export const StyledCurrentPhaseName = styled.div`
  color: ${({ theme }) => theme.colors.PRODUCT.SECONDARY_CONTENT};
  font-size: 13px;
  font-style: italic;
`;

export const StyledModal = styled(Modal)`
  .flash {
    margin-bottom: 1em;
  }
`;
