import useLocalStorage from './use-local-storage';

export enum UserPreference {
  ShowDirectoryCalendarEditWarning = 'show_directory_calendar_edit_warning',
}

const DEFAULT_PREFERENCES: Record<UserPreference, boolean> = {
  [UserPreference.ShowDirectoryCalendarEditWarning]: true,
};

const useUserPreference = (preference: UserPreference) => {
  return useLocalStorage<boolean>(`user_preference_${preference}`, DEFAULT_PREFERENCES[preference]);
};

export default useUserPreference;
