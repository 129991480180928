import { EmailTemplateType } from '../../../../types';

import type { CreatableEmailTemplate, NonManualEmailTemplateType } from '../../../../types';

export const NEW_EMAIL_TEMPLATE: CreatableEmailTemplate = {
  name: '',
  type: undefined,
  subject: '',
  sender_name: 'scheduler',
  sender_email: 'scheduler',
  cc_emails: [],
  bcc_emails: [],
  body: '',
};

export const EMAIL_TYPES: NonManualEmailTemplateType[] = [
  EmailTemplateType.AvailabilityRequestEmail,
  EmailTemplateType.ConfirmationEmail,
  EmailTemplateType.MultiBlockConfirmationEmail,
  EmailTemplateType.SelfSchedulingRequestEmail,
];

export const DEFAULT_EMAIL_CONTENT: Record<EmailTemplateType, { subject: string; body: string }> = {
  [EmailTemplateType.AvailabilityRequestEmail]: {
    subject: 'Interview Availability',
    body: `<span>Hi {{Candidate.FirstName}},</span><br>
<br>
<span>Thanks for your interest in the {{Job.Name}} position. We're excited to move forward with the interview process.</span><br>
<br>
<span>To help me schedule your {{Job.Stage.Name}} interview, please let me know when you're available by selecting some times at this link: {{AvailabilityRequest.Link}}.</span><br>
<br>
<span>Then, I can coordinate with the team and confirm a time with you. Let me know if you have any questions in the meantime.</span><br>
<br>
<span>Best,</span><br>
<span>{{Requester.FirstName}}</span>`.replace(/\n/g, ''),
  },
  [EmailTemplateType.ConfirmationEmail]: {
    subject: 'Interview Confirmation',
    body: `<span>Hi {{Candidate.FirstName}},</span><br>
<br>
<span>You are confirmed for your {{Job.Stage.Name}} interview for the {{Job.Name}} role for {{Schedule.DateInCandidateTimezone}}! Please find your schedule attached below.</span><br>
<br>
<span>{{Schedule.AgendaInCandidateTimezone}}</span><br>
<br>
<span>The team is looking forward to meeting you. Don't hesitate to reach out with any questions before your interview.</span><br>
<br>
<span>Best,</span><br>
<span>{{Scheduler.FirstName}}</span>`.replace(/\n/g, ''),
  },
  [EmailTemplateType.ManualEmail]: {
    body: '',
    subject: '',
  },
  [EmailTemplateType.MultiBlockConfirmationEmail]: {
    subject: 'Interview Confirmation',
    body: `<span>Hi {{Candidate.FirstName}},</span><br>
<br>
<span>You are confirmed for your {{Job.Stage.Name}} interview for the {{Job.Name}} role for {{Schedule.DatesInCandidateTimezone}}! Please find your schedule attached below.</span><br>
<br>
<span>{{Schedule.AgendaInCandidateTimezone}}</span><br>
<br>
<span>The team is looking forward to meeting you. Don't hesitate to reach out with any questions before your interview.</span><br>
<br>
<span>Best,</span><br>
<span>{{Scheduler.FirstName}}</span>`.replace(/\n/g, ''),
  },
  [EmailTemplateType.SelfSchedulingRequestEmail]: {
    subject: 'Interview Booking',
    body: `<span>Hi {{Candidate.FirstName}},</span><br>
<br>
<span>Thanks for your interest in the {{Job.Name}} position. We're excited to move forward with the interview process.</span><br>
<br>
<span>To schedule your {{Job.Stage.Name}} interview, please select a time that you're available at this link: {{SelfSchedulingRequest.Link}}.</span><br>
<br>
<span>Let me know if you have any questions.</span><br>
<br>
<span>Best,</span><br>
<span>{{Requester.FirstName}}</span>`.replace(/\n/g, ''),
  },
};
