import { entries, find, groupBy, orderBy } from 'lodash';
import { useMemo } from 'react';

import ListItem from '../data-display/ListItem';
import SelectInput from './SelectInput';
import { useFeedbackForms } from '../../../hooks/queries/feedback-forms';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Group, Option } from './SelectInput/types';
import type { ReactNode } from 'react';

interface Props {
  className?: string;
  isDisabled?: boolean;
  label?: ReactNode;
  name?: string;
  onChange: (newValue: OnChangeValue<Option<string>, false>, actionMeta: ActionMeta<Option<string>>) => void;
  selectedFeedbackFormId?: string;
}

const FeedbackFormSelectInput = ({
  className,
  isDisabled = false,
  label,
  name,
  onChange,
  selectedFeedbackFormId,
}: Props) => {
  const { data: feedbackForms } = useFeedbackForms();

  const options = useMemo<Group<string, Option<string>>[]>(() => {
    if (!feedbackForms?.feedback_forms) {
      return [];
    }
    const nonArchivedFeedbackForms = feedbackForms.feedback_forms.filter((form) => !form.archived);
    const optionsByCategory = entries(groupBy(nonArchivedFeedbackForms, 'category')).map(([category, forms]) => ({
      label: category === 'null' ? 'Uncategorized' : category,
      options: orderBy(forms.map((form) => ({
        value: form.id,
        label: form.name,
      })), 'label'),
    }));
    return orderBy(optionsByCategory, 'label');
  }, [feedbackForms]);

  const selectedOption = useMemo(() => {
    if (!selectedFeedbackFormId) {
      return null;
    }

    for (let i = 0; i < options.length; i++) {
      const option = find(options[i].options, ['value', selectedFeedbackFormId]);
      if (option) {
        return option;
      }
    }
  }, [options, selectedFeedbackFormId]);

  return (
    <SelectInput<string, Option<string>>
      className={className}
      formatOptionLabel={(option, { context }) => (
        context === 'menu' ?
          <ListItem
            label={option.label}
            secondaryText={option.secondaryText}
          /> :
          option.label
      )}
      isClearable
      isDisabled={isDisabled}
      label={label}
      name={name}
      onChange={onChange}
      options={options}
      placeholder="Select a form"
      value={selectedOption}
    />
  );
};

export default FeedbackFormSelectInput;
