import styled from 'styled-components';

import Card from 'components/library/layout/Card';

export const StyledCard = styled(Card)`
  margin: 1em 0;

  & .card-content {
    display: flex;
    align-items: center;
    gap: 1em;
  }
`;

export const StyledImage = styled.img`
  border-radius: 50%;
  width: 40px;

  &.integration-teams {
    border-radius: 0;
  }
`;
