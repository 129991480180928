import { useMemo } from 'react';
import { useQueries, useQuery } from 'react-query';

import InterviewPlanner from '../../libraries/interviewplanner';
import { CalendarEventTemplateType } from '../../types';
import { EMAIL_TYPES } from '../../components/Application/EmailTemplates/EmailTemplateCreate/helpers';

import type { EditorType, NonManualEmailTemplateType, TokensResponse, InterviewerFilterableType } from '../../types';
import type { UseQueryOptions } from 'react-query';

const CREATABLE_CALENDAR_EVENT_TYPES: CalendarEventTemplateType[] = Object.values(CalendarEventTemplateType).filter((type) => type !== CalendarEventTemplateType.HiringMeetingCalendarEvent);

export enum QueryKey {
  ListTokens = 'ListTokens',
  Render = 'Render',
}

interface TokensPayloadCandidate {
  email?: string;
  name?: string;
  pronouns?: {
    he_him?: boolean;
    she_her?: boolean;
    they_them?: boolean;
    xe_xem?: boolean;
    ze_hir?: boolean;
    ey_em?: boolean;
    hir_hir?: boolean;
    fae_faer?: boolean;
    hu_hu?: boolean;
    self_describe?: string;
  };
  pronunciation_url?: string;
  resume_url?: string;
  phone_number?: string;
  linkedin_url?: string;
  website?: string;
}

export interface TokensPayloadSchedule {
  application: {
    ats_id?: string;
    candidate?: TokensPayloadCandidate & {
      ats_id: string;
    };
    coordinator?: {
      email: string;
      name?: string;
      phone_number?: string;
    };
    hiring_manager?: {
      email: string;
      name?: string;
      phone_number?: string;
    };
    recruiter?: {
      email: string;
      name?: string;
      phone_number?: string;
    };
    sourcer?: {
      email: string;
      name?: string;
      phone_number?: string;
    };
    current_stage: {
      job: {
        name: string;
        post_name?: string;
      };
    };
    office?: {
      name: string;
    };
  };
  stage: {
    name: string;
    stage_interviews?: {
      name: string;
      position: number;
      interview_template?: {
        duration_minutes: number;
        candidate_facing_name?: string;
        candidate_facing_details?: string;
      };
    }[];
  };
  timezone?: string;
  candidate_timezone?: string;
  candidate_event_location?: string;
  interviews?: {
    start_time: string;
    feedback_form_id?: string;
    video_conferencing_passcode?: string;
    live_coding_url?: string;
    interview_template: {
      duration_minutes: number;
      live_coding_enabled?: boolean;
      candidate_facing_name?: string;
      candidate_facing_details?: string;
    };
    room?: {
      name: string;
    };
    stage_interview: {
      name: string;
      ats_id?: string;
      greenhouse_interview_kit_id?: string;
    };
    interviewers: {
      user: {
        id: string;
        email: string;
        name?: string;
        linkedin_url?: string;
        phone_number?: string;
        title?: string;
      };
      interviewer_template: {
        optional: boolean;
        interviewer_filters: {
          interviewer_filter_expressions: {
            negated: boolean;
            filterable_id: string;
            filterable_type: `${InterviewerFilterableType}`;
          }[];
        }[];
      };
    }[];
  }[];
  schedule_template: {
    candidate_event_location?: string;
    video_conferencing_enabled: boolean;
    onsite: boolean;
  };
}

export interface TokensPayload {
  type: `${EditorType}`;
  text?: string;
  plain_text?: boolean;
  application_id?: string; // only used for manual email
  application?: {
    ats_id: string;
    candidate: TokensPayloadCandidate & {
      ats_id: string;
    };
    coordinator?: {
      email: string;
      name?: string;
      phone_number?: string;
    };
    hiring_manager?: {
      email: string;
      name?: string;
      phone_number?: string;
    };
    recruiter?: {
      email: string;
      name?: string;
      phone_number?: string;
    };
    sourcer?: {
      email: string;
      name?: string;
      phone_number?: string;
    };
    office?: {
      name: string;
    };
  };
  stage?: {
    name: string;
    job: {
      name: string;
      post_name?: string;
    };
    stage_interviews?: {
      name: string;
      position: number;
      interview_template?: {
        duration_minutes: number;
        candidate_facing_name?: string;
        candidate_facing_details?: string;
      };
    }[];
  };
  availability?: {
    application: {
      candidate?: TokensPayloadCandidate;
      coordinator?: {
        email: string;
        name?: string;
        phone_number?: string;
      };
      hiring_manager?: {
        email: string;
        name?: string;
        phone_number?: string;
      };
      recruiter?: {
        email: string;
        name?: string;
        phone_number?: string;
      };
      sourcer?: {
        email: string;
        name?: string;
        phone_number?: string;
      };
      office?: {
        name: string;
      };
    };
    stage: {
      name: string;
      job: {
        name: string;
        post_name?: string;
      };
      stage_interviews?: {
        name: string;
        position: number;
        interview_template?: {
          duration_minutes: number;
          candidate_facing_name?: string;
          candidate_facing_details?: string;
        };
      }[];
    };
  };
  self_scheduling_link?: {
    application: {
      candidate?: TokensPayloadCandidate;
      coordinator?: {
        email: string;
        name?: string;
        phone_number?: string;
      };
      hiring_manager?: {
        email: string;
        name?: string;
        phone_number?: string;
      };
      recruiter?: {
        email: string;
        name?: string;
        phone_number?: string;
      };
      sourcer?: {
        email: string;
        name?: string;
        phone_number?: string;
      };
      office?: {
        name: string;
      };
    };
    stage: {
      name: string;
      job: {
        name: string;
        post_name?: string;
      };
    };
    stage_interview?: {
      name: string;
    };
    interview_template?: {
      duration_minutes?: number;
      candidate_facing_name?: string;
      candidate_facing_details?: string;
    };
  };
  schedule?: TokensPayloadSchedule;
  schedules?: TokensPayloadSchedule[];
  interview_index?: number;
}

export const useTokens = (payload: TokensPayload, options: UseQueryOptions<TokensResponse, Error> = {}) => {
  return useQuery(tokenParams(payload, options));
};

// This returns a data property that's an object, where the key is the email
// template type and the value is the tokens.
export const useAllEmailTokens = (options: UseQueryOptions<TokensResponse, Error> = {}) => {
  const queries = useQueries(EMAIL_TYPES.map((type) => tokenParams({ type }, options)));
  const data = useMemo(() => queries.reduce((map, { data }, i) => {
    return {
      ...map,
      [EMAIL_TYPES[i]]: data,
    };
  }, {}) as Record<NonManualEmailTemplateType, TokensResponse>, [queries]);
  const error = useMemo(() => {
    const e = queries
    .map(({ error }) => error?.message)
    .filter(Boolean)
    .join(', ');
    return e ? new Error(e) : null;
  }, [queries]);

  return { data, error };
};

// This returns a data property that's an object, where the key is the calendar
// event template type and the value is the tokens.
export const useAllCalendarEventTokens = (options: UseQueryOptions<TokensResponse, Error> = {}) => {
  const queries = useQueries(CREATABLE_CALENDAR_EVENT_TYPES.map((type) => tokenParams({ type }, options)));
  const data = useMemo(() => queries.reduce((map, { data }, i) => {
    return {
      ...map,
      [CREATABLE_CALENDAR_EVENT_TYPES[i]]: data,
    };
  }, {}) as Record<CalendarEventTemplateType, TokensResponse>, [queries]);
  const error = useMemo(() => {
    const e = queries
    .map(({ error }) => error?.message)
    .filter(Boolean)
    .join(', ');
    return e ? new Error(e) : null;
  }, [queries]);

  return { data, error };
};

export const tokenParams = (payload: TokensPayload, options: UseQueryOptions<TokensResponse, Error> = {}) => {
  return {
    queryKey: [QueryKey.ListTokens, payload],
    queryFn: () => {
      return InterviewPlanner.request<TokensResponse>('POST', '/tokens', payload);
    },
    ...options,
  };
};

interface RenderData {
  rendered_text: string;
}

export const useRender = (payload: TokensPayload, options: UseQueryOptions<RenderData, Error>) => {
  return useQuery(renderParams(payload, options));
};

export const useMultipleRenders = (payloads: TokensPayload[], options: UseQueryOptions<RenderData, Error> = {}) => {
  const queries = useQueries(payloads.map((payload) => renderParams(payload, options)));
  const data = useMemo(() => queries.map(({ data }) => data), [queries]);
  const errors = useMemo(() => queries.map(({ error }) => error), [queries]);

  return { data, errors };
};

export const renderParams = (payload: TokensPayload, options: UseQueryOptions<RenderData, Error> = {}) => {
  return {
    queryKey: [QueryKey.Render, payload],
    queryFn: () => {
      return InterviewPlanner.request<RenderData>('POST', '/render', payload);
    },
    ...options,
  };
};
