import { find } from 'lodash';

import SelectInput from './SelectInput';
import { CalendarEventTemplateType, calendarEventTemplateTypeLabels } from '../../../types';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { ReactNode } from 'react';

export interface Option {
  value: `${CalendarEventTemplateType}`;
  label: string;
}

const options: Option[] = [{
  value: CalendarEventTemplateType.CandidateCalendarEvent,
  label: calendarEventTemplateTypeLabels[CalendarEventTemplateType.CandidateCalendarEvent],
}];

interface Props {
  className?: string;
  helperText?: ReactNode;
  isDisabled?: boolean;
  onChange: (newValue: OnChangeValue<Option, false>, actionMeta: ActionMeta<Option>) => void;
  value?: `${CalendarEventTemplateType}`;
}

const CalendarEventTemplateTypeSelectInput = ({ className, helperText, isDisabled = false, onChange, value }: Props) => (
  <SelectInput
    className={className}
    helperText={helperText}
    isDisabled={isDisabled}
    isRequired
    label="Template Type"
    onChange={onChange}
    options={options}
    placeholder="Select a template type"
    value={value ? find(options, ['value', value]) : null}
  />
);

export default CalendarEventTemplateTypeSelectInput;
