import { AvailabilityStatus } from '../../../../types';
import { BusinessHourReferenceType } from '../../../../types/business-hours';
import { defaultBusinessHoursWithStartAndEnd } from '../../../../libraries/business-hours';

import type { Account, AvailabilityPreview, User } from '../../../../types';
import type { TokensPayload } from '../../../../hooks/queries/tokens';

export const constructAvailabilityMessageTokens = (user: User, candidateName: string): TokensPayload['availability'] => ({
  application: {
    candidate: {
      email: `${candidateName.toLowerCase()}@company.com`,
      name: candidateName,
      phone_number: '(123) 456-7890',
      linkedin_url: `https://www.linkedin.com/in/${candidateName.toLowerCase().replace(/ /g, '-')}/`,
      website: `https://${candidateName.toLowerCase().replace(/ /g, '')}.com/`,
    },
    coordinator: {
      email: user.email,
      name: user.name,
      phone_number: user.phone_number,
    },
    hiring_manager: {
      email: user.email,
      name: user.name,
      phone_number: user.phone_number,
    },
    recruiter: {
      email: user.email,
      name: user.name,
      phone_number: user.phone_number,
    },
    sourcer: {
      email: user.email,
      name: user.name,
      phone_number: user.phone_number,
    },
    office: {
      name: 'Fake Office',
    },
  },
  stage: {
    name: 'Fake Interview',
    job: {
      name: 'Fake Job',
      post_name: 'Fake Job',
    },
    stage_interviews: [{
      name: 'Fake Interview',
      position: 0,
      interview_template: {
        duration_minutes: 30,
      },
    }],
  },
});

export const constructAvailabilityPreview = (account: Account, candidateName: string): AvailabilityPreview => ({
  account: {
    name: account.name,
    color: account.color,
    logo_url: account.logo_url,
    availability_message: account.availability_message,
    enable_pronouns: account.enable_pronouns,
    enable_pronunciation: account.enable_pronunciation,
  },
  application: {
    candidate: {
      name: candidateName,
    },
  },
  availability_template: {
    enforce: false,
    rolling_window_days: 14,
    business_hours: defaultBusinessHoursWithStartAndEnd(BusinessHourReferenceType.AvailabilityTemplate, '09:00', '17:00'),
    advanced_notice_hours: 24,
    minimum_duration_minutes: 0,
    total_duration_minutes: 0,
  },
  stage: {
    name: 'Fake Interview',
  },
  status: AvailabilityStatus.Requested,
});
