import { Competitor } from 'types';
import FeatureComparisonTable from './FeatureComparisonTable';
import Section from 'components/library/layout/Section';

const Lever = () => {
  return (
    <div>
      <Section title="Overview">
        <p>
          Lever is an applicant tracking system with some built-in scheduling functionality. Their EasyBook links allow candidates to schedule their own interviews. However, coordinating anything more complex&mdash;like a panel interview or multiple interviews at once&mdash;is very manual within Lever.
        </p>
        <p>
          InterviewPlanner is tightly integrated with Lever. We are not an alternative to them by any means, but rather a complementary partner that many teams use to manage and improve their scheduling process.
        </p>
        <FeatureComparisonTable competitors={[Competitor.Lever]} />
      </Section>
      <Section title="Candidate experience">
        <p>
          InterviewPlanner and Lever both allow you to send emails that have a personal touch to them, like they were sent directly from the scheduler&apos;s inbox rather than from an automated tool.
        </p>
        <p>
          InterviewPlanner allows you to customize candidate-facing pages, like availability requests and self-scheduling links, with your company&apos;s logo, brand color, and a custom message. Lever does not let you add any company branding to their EasyBook links.
        </p>
      </Section>
      <Section title="Interviewer experience">
        <p>
          When InterviewPlanner generates schedules, the platform takes into account interviewers&apos; preferences such as interview limits, interviewer working hours, keywords for events that can be booked over. InterviewPlanner also automatically tries to balance interview load when selecting from a pool of interviewers. Constructing a schedule on Lever is done manually by comparing calendars, and you cannot see interviewers&apos; interview limits, interview loads for the week, etc. when doing so.
        </p>
        <p>
          Lever&apos;s EasyBook links do take into account interviewers&apos; availability and working hours. Only InterviewPlanner lets you additionally configure conflict keywords for events that can be booked over and buffers to prevent back-to-back meetings.
        </p>
        <p>
          Only InterviewPlanner lets you place schedules on hold internally before confirming the schedule for the candidate. This is a crucial feature for teams that have extremely busy interviewers and would like to place blockers on their calendars to reserve time for an interview.
        </p>
        <p>
          InterviewPlanner and Lever both send calendar invites to interviewers when they are scheduled for interviews that include the agenda, information about the candidate, link to the Lever scorecard, and hiring team. Only InterviewPlanner includes the candidate&apos;s pronouns and name pronunciation (if the candidate submitted those) on interviewer invites. Lever gives you more customization over the interviewer calendar event description.
        </p>
        <p>
          InterviewPlanner has a Slack integration to create private hiring channels for candidates and keeps the channel members up to date if you modify a schedule.
        </p>
      </Section>
      <Section title="Scheduling">
        <p>
          Only InterviewPlanner automatically generates schedule options that consider interviewer and candidate availability, interviewer preferences, and more. You can re-order interviews, swap interviewers, add video conferencing, etc. from within the scheduling workflow. In both InterviewPlanner and Lever, you can view interviewers&apos; calendars in a side-by-side view as you edit a schedule.
        </p>
        <p>
          Both platforms let you configure default settings for interview loops, including interview durations and interviewers. Only InterviewPlanner lets you add in breaks and position constraints.
        </p>
        <p>
          Rescheduling or updating interviews is possible in both platforms. Only in InterviewPlanner, you can make an update and have the changes automatically synced in all candidate communication containing those details. For example, if you move an interview to a different time, we will automatically update the agenda in the candidate&apos;s calendar event.
        </p>
        <p>
          Only InterviewPlanner allows you to request availability from candidates using a company-branded link where they can drag to select times. Only InterviewPlanner allows you to automatically send a follow-up email to a candidate if they do not respond to the availability request.
        </p>
        <p>
          InterviewPlanner and Lever both allow you to send candidates self-scheduling links for single interviews. Only InterviewPlanner allows you to automatically send a follow-up email to a candidate if they do not respond to the self-scheduling request.
        </p>
      </Section>
      <Section title="Interviewer skills and training">
        <p>
          Only InterviewPlanner lets you tag interviewers with skills and attributes. Only InterviewPlanner lets you set up interviewer training programs. InterviewPlanner automatically moves trainees through the program as they complete interviews, and intelligently updates trainees&apos; roles in all future training sessions if an interview is cancelled or rescheduled.
        </p>
      </Section>
      <Section title="In our opinion">
        <p>
          Lever is a user-friendly ATS with some basic scheduling functionality. Teams tend to quickly outgrow this functionality, especially if they are scheduling panels or multiple interviews in a stage. InterviewPlanner is a valuable add for anyone doing full-cycle recruiting as the automated schedule finding and load balancing frees up recruiters&apos; time to focus on other initiatives. For teams with dedicated coordinators, InterviewPlanner enables each coordinator to schedule a greater volume of candidates with ease, and it provides a space for coordinators to build out and organize their operations, which Lever isn&apos;t the best-suited for.
        </p>
      </Section>
    </div>
  );
};

export default Lever;
