// We use dataloader to be able to make batch requests for events while still
// caching the data on a per-resource level. So if we need to fetch data for 3
// users, 2 rooms, and 1 Zoom host, we make a single request to fetch all of
// this data. But then, if we need to fetch data for 4 users (including the 3 we
// already fetched), and 1 room (excluding any of the rooms we already fetched),
// we will make a single request with the 1 new user and 1 new room.

import { isEmpty, keyBy } from 'lodash';
import Dataloader from 'dataloader';

import InterviewPlanner from '../../libraries/interviewplanner';
import { Cache } from './cache';

import type { EventsCache, EventsItem, ListEventsQuery } from './types';

interface ListEventsData {
  users: EventsItem[];
  rooms: EventsItem[];
  zoom_hosts: EventsItem[];
}

interface QueriesMap {
  [date: string]: {
    [timezone: string]: ListEventsQuery;
  };
}

/**
 * ResponseMap is the same structure as EventsCache, but it's used for a
 * different purpose, so we make a separate type for it. Even if the EventsCache
 * changes structure, this probably won't, and vice versa.
 */
interface ResponsesMap {
  [date: string]: {
    [timezone: string]: {
      users: {
        [id: string]: EventsItem;
      };
      rooms: {
        [id: string]: EventsItem;
      };
      zoom_hosts: {
        [id: string]: EventsItem;
      };
    };
  };
}

/**
 * This is the batch loading function that's passed into Dataloader. It will
 * take in an array of single ID query payloads that need to be fetched. Even
 * though we could make a request for each of these queries, we don't. Instead,
 * we combine them into as few queries as possible to batch it together. This is
 * the main benefit of using dataloader. Once the minimum amount of requests
 * have been made, we then break up the responses into the respective responses,
 * assuming that we made all of the input partial queries individually. This is
 * an invariant of dataloader that we need to abide by.
 *
 * @param partialQueries An array of single ID query payloads generated by
 * transformToSeparateQueries.
 */
const batchLoadFn = async (partialQueries: readonly ListEventsQuery[]) => {
  // Reduce the array of single ID queries into a map of these queries, indexed
  // by date and timezone. If there is more than one query for a given
  // date/timezone combo, it gets combined with all of the others. This map is
  // used for easier lookup of queries by this information.
  const queriesMap = partialQueries.reduce<QueriesMap>((map, query) => {
    if (!map[query.date]) {
      map[query.date] = {};
    }
    if (!map[query.date][query.timezone]) {
      map[query.date][query.timezone] = {
        date: query.date,
        timezone: query.timezone,
        user_ids: [],
        room_ids: [],
        zoom_host_ids: [],
      };
    }

    query.user_ids?.forEach((id) => {
      map[query.date][query.timezone].user_ids!.push(id);
    });
    query.room_ids?.forEach((id) => {
      map[query.date][query.timezone].room_ids!.push(id);
    });
    query.zoom_host_ids?.forEach((id) => {
      map[query.date][query.timezone].zoom_host_ids!.push(id);
    });

    return map;
  }, {});

  // Generate the minimum number of queries that we have to make. We have to
  // make a separate query for each date/timezone combo. In most practical
  // scenarios, we'll only be making a single query at a time, but we needed to
  // build this in a way that accounts for the fact that it's possible for us to
  // make more than a single query.
  const finalQueries: ListEventsQuery[] = [];
  for (const tz of Object.values(queriesMap)) {
    for (const query of Object.values(tz)) {
      finalQueries.push(query);
    }
  }

  // Make all of the queries.
  const responses = await Promise.all(finalQueries.map((query) => {
    return InterviewPlanner.request<ListEventsData>('GET', '/events', null, query);
  }));

  // Reduce all the responses into a map to make it easily accessible by
  // date/timezone again. We also want to be able to pull out the exact event
  // data for a given resource ID as well.
  const responsesMap = finalQueries.reduce<ResponsesMap>((map, query, index) => {
    if (!map[query.date]) {
      map[query.date] = {};
    }
    if (!map[query.date][query.timezone]) {
      map[query.date][query.timezone] = {
        users: {},
        rooms: {},
        zoom_hosts: {},
      };
    }

    const response = responses[index];
    const userMap = keyBy(response.users, 'id');
    const roomMap = keyBy(response.rooms, 'id');
    const zoomHostMap = keyBy(response.zoom_hosts, 'id');

    query.user_ids?.forEach((id) => {
      map[query.date][query.timezone].users[id] = userMap[id];
    });
    query.room_ids?.forEach((id) => {
      map[query.date][query.timezone].rooms[id] = roomMap[id];
    });
    query.zoom_host_ids?.forEach((id) => {
      map[query.date][query.timezone].zoom_hosts[id] = zoomHostMap[id];
    });

    return map;
  }, {});

  // Lastly, we go through the input array of queries so that we can satisfy the
  // dataloader constraint of returning the data in the same input order.
  return partialQueries.map<EventsCache>((query) => {
    const responses = responsesMap[query.date][query.timezone];
    if (!isEmpty(query.user_ids)) {
      // This input partial query was for a user.
      const id = query.user_ids![0];
      return {
        [query.date]: {
          [query.timezone]: {
            users: {
              [id]: responses.users[id],
            },
            rooms: {},
            zoom_hosts: {},
          },
        },
      };
    }
    if (!isEmpty(query.room_ids)) {
      // This input partial query was for a room.
      const id = query.room_ids![0];
      return {
        [query.date]: {
          [query.timezone]: {
            users: {},
            rooms: {
              [id]: responses.rooms[id],
            },
            zoom_hosts: {},
          },
        },
      };
    }
    if (!isEmpty(query.zoom_host_ids)) {
      // This input partial query was for a zoom host.
      const id = query.zoom_host_ids![0];
      return {
        [query.date]: {
          [query.timezone]: {
            users: {},
            rooms: {},
            zoom_hosts: {
              [id]: responses.zoom_hosts[id],
            },
          },
        },
      };
    }
    // We should never get here since we only enable the query if one of the
    // arrays is not empty.
    throw new Error('We received an empty request, which should never happen.');
  });
};

// This needs to be a singleton so that it can reuse the cache.
export const EventsLoader = new Dataloader<ListEventsQuery, EventsCache, string>(batchLoadFn, {
  cacheKeyFn: (query) => {
    // This is a cache key to use that contains the date, timezone, and the ID
    // that it is pertaining to. All queries that go through here are single ID
    // queries, so it's guaranteed that it only has 1 ID in all of it's arrays.
    return `${query.date}:${query.timezone}:${query.user_ids?.[0]}:${query.room_ids?.[0]}:${query.zoom_host_ids?.[0]}`;
  },
  cacheMap: new Cache(5_000), // 5s stale time
});
