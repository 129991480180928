import { EditorType } from 'types';

import type { Application } from 'types';
import type { CreateHiringMeetingPayload } from 'hooks/queries/hiring-meetings';
import type { HiringMeeting } from './use-new-hiring-meeting';
import type { TokensPayload } from '../../../../../hooks/queries/tokens';
import type { UserMap } from 'hooks/queries/users';

export const constructTokensPayload = (application: Application, users: UserMap): TokensPayload => ({
  type: EditorType.HiringMeetingCalendarEvent,
  application: {
    ats_id: application.ats_id,
    candidate: {
      ats_id: application.candidate.ats_id,
      email: application.candidate.email,
      name: application.candidate.name,
      pronouns: application.candidate.pronouns || undefined,
      pronunciation_url: application.candidate.pronunciation_url || undefined,
      resume_url: application.candidate.resume_url || undefined,
      phone_number: application.candidate.phone_number,
      linkedin_url: application.candidate.linkedin_url,
      website: application.candidate.website,
    },
    coordinator: application.coordinator_id && users[application.coordinator_id] ? {
      email: users[application.coordinator_id].email,
      name: users[application.coordinator_id].name,
      phone_number: users[application.coordinator_id].phone_number,
    } : undefined,
    hiring_manager: application.hiring_manager_id && users[application.hiring_manager_id] ? {
      email: users[application.hiring_manager_id].email,
      name: users[application.hiring_manager_id].name,
      phone_number: users[application.hiring_manager_id].phone_number,
    } : undefined,
    recruiter: application.recruiter_id && users[application.recruiter_id] ? {
      email: users[application.recruiter_id].email,
      name: users[application.recruiter_id].name,
      phone_number: users[application.recruiter_id].phone_number,
    } : undefined,
    sourcer: application.sourcer_id && users[application.sourcer_id] ? {
      email: users[application.sourcer_id].email,
      name: users[application.sourcer_id].name,
      phone_number: users[application.sourcer_id].phone_number,
    } : undefined,
    office: application.office ? {
      name: application.office.name,
    } : undefined,
  },
  stage: {
    name: application.current_stage!.name,
    job: {
      name: application.job.name,
      post_name: application.job.post_name,
    },
    stage_interviews: application.current_stage!.stage_interviews?.filter((stageInterview) => !stageInterview.inline && !stageInterview.deleted).map((stageInterview) => ({
      name: stageInterview.name,
      position: stageInterview.position,
      interview_template: stageInterview.interview_template ? {
        duration_minutes: stageInterview.interview_template.duration_minutes,
        candidate_facing_name: stageInterview.interview_template.candidate_facing_name,
        candidate_facing_details: stageInterview.interview_template.candidate_facing_details,
      } : undefined,
    })),
  },
});

export function constructHiringMeetingPayload (hiringMeeting: HiringMeeting): CreateHiringMeetingPayload {
  return {
    send_notifications: true,
    application_id: hiringMeeting.application_id,
    start_time: hiringMeeting.start_time || '',
    timezone: hiringMeeting.timezone,
    scheduling_calendar_email: hiringMeeting.scheduling_calendar_email,
    mark_event_as_private: hiringMeeting.mark_event_as_private,
    zoom_host_id: hiringMeeting.hiring_meeting_template.video_conferencing_enabled ? hiringMeeting.zoom_host_id : undefined,
    zoom_host_type: hiringMeeting.hiring_meeting_template.video_conferencing_enabled ? hiringMeeting.zoom_host_type : undefined,
    hiring_meeting_template: {
      name: hiringMeeting.hiring_meeting_template.name,
      duration_minutes: hiringMeeting.hiring_meeting_template.duration_minutes,
      video_conferencing_enabled: hiringMeeting.hiring_meeting_template.video_conferencing_enabled,
      zoom_host_filters: hiringMeeting.hiring_meeting_template.zoom_host_filters,
      room_filters: hiringMeeting.hiring_meeting_template.room_filters,
      hiring_meeting_attendee_filters: hiringMeeting.hiring_meeting_template.hiring_meeting_attendee_filters,
      calendar_event_template: {
        title: hiringMeeting.hiring_meeting_template.calendar_event_template.title,
        description: hiringMeeting.hiring_meeting_template.calendar_event_template.description,
        location: hiringMeeting.hiring_meeting_template.calendar_event_template.location,
        additional_attendees: hiringMeeting.hiring_meeting_template.calendar_event_template.additional_attendees,
        additional_optional_attendees: hiringMeeting.hiring_meeting_template.calendar_event_template.additional_optional_attendees,
      },
    },
  };
}
