import { useMutation, useQuery, useQueryClient } from 'react-query';

import InterviewPlanner from 'libraries/interviewplanner';
import { QueryKey as ApplicationQueryKey } from './applications';

import type { EditableHiringMeetingAttendeeFilter, HiringMeeting, HiringMeetingAttendeeFilterExpressionFilterableType, HiringMeetingAttendeeUser, ZoomHostFilterableType } from 'types';
import type { InterviewPlannerError } from 'libraries/interviewplanner';
import type { UseQueryOptions } from 'react-query';

export enum QueryKey {
  RetrieveHiringMeeting = 'RetrieveHiringMeeting',
  ResolveHiringMeetingAttendees = 'ResolveHiringMeetingAttendees',
}

export const useHiringMeeting = (id: string, options: UseQueryOptions<HiringMeeting, InterviewPlannerError> = {}) => {
  return useQuery<HiringMeeting, InterviewPlannerError>([QueryKey.RetrieveHiringMeeting, id], () => {
    return InterviewPlanner.request('GET', `/hiring-meetings/${id}`);
  }, {
    enabled: options.enabled !== undefined ? options.enabled : Boolean(id),
    ...options,
  });
};

export interface CreateHiringMeetingPayload {
  send_notifications: boolean;
  hiring_meeting_template: {
    name: string;
    duration_minutes: number;
    video_conferencing_enabled: boolean;
    zoom_host_filters?: {
      zoom_host_filter_expressions: {
        filterable_id: string;
        filterable_type: `${ZoomHostFilterableType}`;
        negated: boolean;
      }[];
    }[];
    room_filters?: {
      room_filter_expressions: {
        negated: boolean;
        filterable_id: string;
        filterable_type: string;
      }[];
    }[];
    calendar_event_template: {
      title: string;
      description: string;
      location?: string;
      additional_attendees?: string[];
      additional_optional_attendees?: string[];
    };
    hiring_meeting_attendee_filters: {
      hiring_meeting_attendee_filter_expressions: {
        negated: boolean;
        filterable_id: string;
        filterable_type: `${HiringMeetingAttendeeFilterExpressionFilterableType}`;
      }[];
    }[];
  };
  application_id: string;
  scheduling_calendar_email: string;
  mark_event_as_private: boolean;
  timezone: string;
  start_time: string;
  room_id?: string;
  zoom_host_id?: string;
  zoom_host_type?: string;
}

interface CreateHiringMeetingMutationVariables {
  payload: CreateHiringMeetingPayload;
}

export const useCreateHiringMeeting = () => {
  const queryClient = useQueryClient();

  return useMutation<HiringMeeting, Error, CreateHiringMeetingMutationVariables>(({ payload }) => {
    return InterviewPlanner.request('POST', '/hiring-meetings', payload, null);
  }, {
    onSuccess: (data, { payload }) => {
      queryClient.invalidateQueries([ApplicationQueryKey.ListApplications]);
      queryClient.invalidateQueries([ApplicationQueryKey.RetrieveApplication, payload.application_id]);
      queryClient.setQueryData([QueryKey.RetrieveHiringMeeting, data.id], data);
    },
  });
};

export interface UpdateHiringMeetingPayload {
  send_notifications: boolean;
  hiring_meeting_template?: {
    duration_minutes?: number;
    video_conferencing_enabled?: boolean;
    hiring_meeting_attendee_filters?: {
      hiring_meeting_attendee_filter_expressions: {
        negated: boolean;
        filterable_id: string;
        filterable_type: `${HiringMeetingAttendeeFilterExpressionFilterableType}`;
      }[];
    }[];
  };
  start_time?: string;
  room_id?: string;
  zoom_host_id?: string;
  zoom_host_type?: string;
}

interface UpdateHiringMeetingMutationVariables {
  id: string;
  payload: UpdateHiringMeetingPayload;
}

export const useUpdateHiringMeeting = () => {
  const queryClient = useQueryClient();

  return useMutation<HiringMeeting, Error, UpdateHiringMeetingMutationVariables>(({ id, payload }) => {
    return InterviewPlanner.request('POST', `/hiring-meetings/${id}`, payload);
  }, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([ApplicationQueryKey.ListApplications]);
      queryClient.invalidateQueries([ApplicationQueryKey.RetrieveApplication, data.application_id]);
      queryClient.setQueryData([QueryKey.RetrieveHiringMeeting, data.id], data);
    },
  });
};

interface DeleteHiringMeetingMutationVariables {
  id: string;
  send_notifications: boolean;
}

export const useDeleteHiringMeeting = () => {
  const queryClient = useQueryClient();

  return useMutation<HiringMeeting, Error, DeleteHiringMeetingMutationVariables>(({ id, send_notifications }) => {
    return InterviewPlanner.request('DELETE', `/hiring-meetings/${id}`, null, { send_notifications });
  }, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([ApplicationQueryKey.ListApplications]);
      queryClient.invalidateQueries([ApplicationQueryKey.RetrieveApplication, data.application_id]);
      queryClient.setQueryData([QueryKey.RetrieveHiringMeeting, data.id], data);
    },
  });
};

interface ResolveHiringMeetingAttendeesQuery {
  applicationId: string;
  hiringMeetingAttendeeFilters: EditableHiringMeetingAttendeeFilter[];
}

export const useResolveHiringMeetingAttendees = (query: ResolveHiringMeetingAttendeesQuery, options?: UseQueryOptions<HiringMeetingAttendeeUser[], Error>) => {
  return useQuery(resolveHiringMeetingAttendeesParams(query, options));
};

export const resolveHiringMeetingAttendeesParams = (query: ResolveHiringMeetingAttendeesQuery, options: UseQueryOptions<HiringMeetingAttendeeUser[], Error> = {}) => {
  const payload = {
    application_id: query.applicationId,
    hiring_meeting_attendee_filters: query.hiringMeetingAttendeeFilters,
  };

  return {
    queryKey: [QueryKey.ResolveHiringMeetingAttendees, payload],
    queryFn: () => {
      return InterviewPlanner.request<HiringMeetingAttendeeUser[]>('POST', '/hiring-meeting-attendees/resolve', payload);
    },
    ...options,
    enabled: options.enabled !== undefined ? options.enabled : Boolean(query.hiringMeetingAttendeeFilters),
  };
};
