import { ElementType } from '../../../../../types';

import type { CustomEditor, CustomElement, ImageElement } from '../../../../../types';

export const withImages = (editor: CustomEditor): CustomEditor => {
  const { isInline, isVoid } = editor;

  editor.isInline = (element: CustomElement) => {
    return isImage(element) ? true : isInline(element);
  };

  editor.isVoid = (element: CustomElement) => {
    return isImage(element) ? true : isVoid(element);
  };

  return editor;
};

/**
 * While this function is usually called with Slate Node objects, that's not
 * always the case, so that's why we can't type it to be Node.
 *
 * @param node An object that is "node-like". It just needs a `type` property.
 */
export function isImage (node: any): node is ImageElement {
  return node.type === ElementType.Image;
}
