import { HiringTeamRoleWithScheduler } from '../../../../types';
import { startCase } from 'lodash';

import type { Option } from '../SelectInput/types';

export function generateOptions (schedulerLabel: string): Option<string>[] {
  return [
    HiringTeamRoleWithScheduler.Scheduler,
    HiringTeamRoleWithScheduler.Sourcer,
    HiringTeamRoleWithScheduler.Recruiter,
    HiringTeamRoleWithScheduler.HiringManager,
    HiringTeamRoleWithScheduler.Coordinator,
  ].map((id) => ({
    label: id === HiringTeamRoleWithScheduler.Scheduler ? schedulerLabel : startCase(id),
    value: id,
  }));
}
