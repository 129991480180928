import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';
import { useMemo } from 'react';

import Flash from './Flash';
import { formatList } from '../../../libraries/formatters';
import { correctPath } from 'libraries/gem';

enum BrandedPage {
  AvailabilityRequest = 'availability',
  SelfSchedulingLink = 'schedule',
}

enum BrandingSetting {
  Logo = 'logo_url',
  Color = 'color',
  Message = 'availability_message',
}

const PAGE_BRANDING_SETTINGS: Record<BrandedPage, BrandingSetting[]> = {
  [BrandedPage.AvailabilityRequest]: [BrandingSetting.Logo, BrandingSetting.Color, BrandingSetting.Message],
  [BrandedPage.SelfSchedulingLink]: [BrandingSetting.Logo, BrandingSetting.Color],
};

const BRANDING_SETTING_DISPLAY_NAMES: Record<BrandingSetting, string> = {
  [BrandingSetting.Logo]: 'logo',
  [BrandingSetting.Color]: 'brand color',
  [BrandingSetting.Message]: 'message',
};

const formatBrandingSettings = (settings: BrandingSetting[]): string => formatList(settings.map((setting) => BRANDING_SETTING_DISPLAY_NAMES[setting]));

interface Props {
  account: {
    [BrandingSetting.Logo]?: string;    // logo_url
    [BrandingSetting.Color]?: string;   // color
    [BrandingSetting.Message]?: string; // availability_message
  };
  page: `${BrandedPage}`;
}

const CompanyBrandingPreviewFlash = ({ account, page }: Props) => {
  const missingBrandingSettings = useMemo(() => {
    const possibleBrandingSettings: BrandingSetting[] = PAGE_BRANDING_SETTINGS[page];

    return possibleBrandingSettings.filter((setting: BrandingSetting) => {
      if (setting === BrandingSetting.Message) {
        return !account[BrandingSetting.Message] || account[BrandingSetting.Message] === '<br>';
      }
      return !account[setting];
    });
  }, [account, page]);

  return (
    <Flash
      isDismissible
      message={<span>This suggestion will only appear in the preview: We recommend setting up a custom {formatBrandingSettings(missingBrandingSettings)} on the <Link to={correctPath('/app/company')}>Company</Link> page.</span>}
      showFlash={!isEmpty(missingBrandingSettings)}
      type="warning"
    />
  );
};

export default CompanyBrandingPreviewFlash;
