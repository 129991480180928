import Banner from './Banner';
import LoadingSpinner from 'components/library/utils/LoadingSpinner';
import OutboundLink from 'components/library/navigation/OutboundLink';
import { Directory, directoryLabels } from 'types';
import { useAccount } from 'hooks/queries/accounts';
import { useOAuthWindow } from 'hooks/use-oauth-window';
import { usePermissionInfo } from 'hooks/queries/permissions';
import { useLDFlags } from 'hooks/use-ld-flags';

const oauthPath = '/api/upgrade-calendar-provider-access';
const consentPath = '/scheduling/microsoft/consent';

const ReauthBanner = () => {
  const { reauthBanner } = useLDFlags();

  const { data: account, isLoading: isAccountLoading } = useAccount();
  const { data: info, isLoading: isInfoLoading, refetch } = usePermissionInfo('directory', {
    refetchOnWindowFocus: 'always',
  });

  const [authorizing, startOAuth] = useOAuthWindow(oauthPath, () => {
    refetch();
  });

  if (!reauthBanner || isAccountLoading || isInfoLoading) {
    return null;
  }

  if (!info?.has_token || (!info?.has_all_permissions && (account?.directory_type !== Directory.Microsoft || info?.has_admin_consent))) {
    // If we don't have a valid token, this needs to be handled first. This is also the message if they don't have all
    // the permissions, but we need to make sure they are either not using Microsoft or they have admin consent because
    // if they are Microsoft and don't have admin consent, has_all_permissions will always be false.
    return (
      <Banner type="warning">
        Upgrade your {directoryLabels[account?.directory_type!]} calendar access to use Gem Scheduling. {authorizing ? (
          <LoadingSpinner />
        ) : (
          <a onClick={startOAuth} role="button">{info?.has_token ? 'Upgrade' : 'Reconnect'} now.</a>
        )}
      </Banner>
    );
  }

  if (account?.directory_type === Directory.Microsoft && !info?.has_admin_consent) {
    // If we have a valid token, but we don't have admin consent, then we tell them to get it.
    return (
      <Banner type="warning">
        Admin Consent needs to be given to access scheduling functionality. Send <OutboundLink href={consentPath} label="Admin Consent Banner">this link</OutboundLink> to your Microsoft admin.
      </Banner>
    );
  }

  // This user has everything set up correctly.
  return null;
};

export default ReauthBanner;
