import classnames from 'classnames';
import { Editor, Element, Node, Range, Transforms } from 'slate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactEditor, useSlate } from 'slate-react';
import { faLink } from '@fortawesome/free-solid-svg-icons';
import { uniqueId } from 'lodash';
import { useMemo, useState } from 'react';

import Button from '../../Button';
import Popover from '../../../utils/Popover';
import TextInput from '../../TextInput';
import { ElementType } from '../../../../../types';
import { getClosestElementOfType, isElementTypeActive, unwrapLink, wrapLink } from '../helper';
import { isLink } from '../plugins/links';

import type { ChangeEvent, FormEvent, MouseEvent } from 'react';
import type { CustomSelection, FormattedText } from '../../../../../types';

interface Props {
  isDisabled: boolean;
}

const LinkToolbarOption = ({ isDisabled }: Props) => {
  const editor = useSlate();

  // We need to keep track of the editor selection when we open the popover
  // because Slate sets the selection to null on blur. So to know where to make
  // our changes, we need to save the selection. There are a few other solutions
  // for this here: https://github.com/ianstormtaylor/slate/issues/3412.
  const [editorSelection, setEditorSelection] = useState<CustomSelection | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [text, setText] = useState('');
  const [address, setAddress] = useState('');

  const id = useMemo(() => uniqueId('editor-input-link-toolbar-option-'), []);

  // This needs to be mouse down instead of click because otherwise, the editor
  // will lose focus on click and the button's effect won't happen.
  const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
    if (isDisabled) {
      return;
    }

    event.preventDefault();

    setEditorSelection(editor.selection);

    if (isElementTypeActive(editor, ElementType.Link)) {
      const [linkNode] = getClosestElementOfType(editor, isLink);
      if (linkNode) {
        setText(Node.string(linkNode));
        setAddress(linkNode.url);
        return;
      }
    }

    setAddress('');

    if (!editor.selection || Range.isCollapsed(editor.selection)) {
      setText('');
    } else {
      setText(Editor.string(editor, editor.selection));
    }
  };

  const handleTextChange = (e: ChangeEvent<HTMLInputElement>) => setText(e.target.value);
  const handleAddressChange = (e: ChangeEvent<HTMLInputElement>) => setAddress(e.target.value);
  const handleAddLink = (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    event.stopPropagation();

    editor.selection = editor.selection || editorSelection || {
      anchor: { path: [0], offset: 0 },
      focus: { path: [0], offset: 0 },
    };
    const [linkNode, linkNodePath] = getClosestElementOfType(editor, isLink);

    if (isElementTypeActive(editor, ElementType.Link) && linkNode && linkNodePath) {
      // Since the selection was on an existing link, we're updating a link
      // instead of adding a new one.

      // Update the selection to make sure it encompasses the whole existing
      // link.
      const anchor = {
        // The anchor's path is the first child of the link node, so it's got
        // the same path as the link node, but with an extra 0 at the end to
        // signify the first child.
        path: [...linkNodePath, 0],
        // The anchor's offset is the first character, so we just set it to 0.
        offset: 0,
      };
      const focus = {
        // The focus's path is the last child of the link node, so it's got the
        // same path as the link node, but the extra value is the last index of
        // all the link node's children.
        path: [...linkNodePath, linkNode.children.length - 1],
        // The focus's offset is the last index of the text of the last child of
        // the link node. This breaks in the case that the link node has
        // non-text children, but I don't know if that's possible.
        offset: (linkNode.children[linkNode.children.length - 1] as FormattedText).text.length,
      };
      editor.selection = { anchor, focus };

      // Update the URL.
      Transforms.setNodes(editor, { url: address }, {
        match: (node) => !Editor.isEditor(node) && Element.isElement(node) && node.type === ElementType.Link,
      });
      // Update the text.
      Transforms.insertText(editor, text);
    } else {
      // Add new link.
      wrapLink(editor, text, address);
    }

    setEditorSelection(null);
    setIsOpen(false);
    ReactEditor.focus(editor);
  };

  const handleRemoveLink = () => {
    editor.selection = editor.selection || editorSelection;

    if (isElementTypeActive(editor, ElementType.Link)) {
      unwrapLink(editor);
    }

    setEditorSelection(null);
    setIsOpen(false);
    ReactEditor.focus(editor);
  };

  return (
    <>
      <div
        className={classnames([
          'editor-toolbar-option',
          isElementTypeActive(editor, ElementType.Link) && 'editor-toolbar-option-active',
        ])}
        id={id}
        onMouseDown={handleMouseDown}
        title="Link"
      >
        <FontAwesomeIcon icon={faLink} />
      </div>
      {!isDisabled && (
        <Popover
          className="editor-toolbar-option-link-popover"
          isOpen={isOpen}
          position="bottom"
          setIsOpen={setIsOpen}
          target={id}
        >
          <form onSubmit={handleAddLink}>
            <TextInput
              isRequired
              label="Text to display"
              onChange={handleTextChange}
              value={text}
            />
            <TextInput
              helperText="Link must begin with http:// or https://."
              isRequired
              label="Link address"
              onChange={handleAddressChange}
              value={address}
            />
            <div className="editor-toolbar-option-link-buttons">
              {isElementTypeActive({ ...editor, selection: editor.selection || editorSelection }, ElementType.Link) && (
                <Button
                  color="no-outline"
                  onClick={handleRemoveLink}
                  size="small"
                  type="reset"
                  value="Remove link"
                />
              )}
              <Button
                color="gem-blue"
                size="small"
                type="submit"
                value={isElementTypeActive({ ...editor, selection: editor.selection || editorSelection }, ElementType.Link) ? 'Update link' : 'Add link'}
              />
            </div>
          </form>
        </Popover>
      )}
    </>
  );
};

export default LinkToolbarOption;
