import Moment from 'moment-timezone';
import { groupBy, isEmpty, min, orderBy } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import Button from '../library/inputs/Button';
import DatePicker from '../library/inputs/DatePicker';
import LoadingSpinner from '../library/utils/LoadingSpinner';
import SelfScheduleConfirmModal from './SelfScheduleConfirmModal';
import TimezoneSelectInput from '../library/inputs/TimezoneSelectInput';
import { constructCreateSchedulePayload, constructUpdateSchedulePayload } from './helpers';
import { formatTimeRange } from '../../libraries/formatters';
import { useCreateSelfSchedulingLinkSchedule, useUpdateSelfSchedulingLinkSchedule } from '../../hooks/queries/self-scheduling-links';

import type { Dispatch, SetStateAction } from 'react';
import type { ScheduleOption, SelfSchedulingLink } from '../../types';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../library/inputs/SelectInput/types';

interface Props {
  brandColor?: string;
  month: string;
  fromMonth?: string;
  isLoading?: boolean;
  isPreview: boolean;
  isRescheduling: boolean;
  onSuccess: (option: ScheduleOption) => void;
  scheduleOptions?: ScheduleOption[];
  selfSchedulingLink: SelfSchedulingLink;
  setMonth: (month: string) => void;
  setTimezone: Dispatch<SetStateAction<string>>;
  timezone: string;
}

const SchedulePicker = ({
  brandColor,
  month,
  fromMonth,
  isLoading,
  isPreview,
  isRescheduling,
  onSuccess,
  scheduleOptions = [],
  selfSchedulingLink,
  setMonth,
  setTimezone,
  timezone,
}: Props) => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' });

  const createSelfSchedulingLinkScheduleMutation = useCreateSelfSchedulingLinkSchedule();
  const updateSelfSchedulingLinkScheduleMutation = useUpdateSelfSchedulingLinkSchedule();

  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [selectedScheduleOption, setSelectedScheduleOption] = useState<ScheduleOption | undefined>();

  const scheduleOptionsByIsoDate = useMemo(() => {
    return groupBy(
      // We're filtering the options based on the month because the generate endpoint doesn't take the client timezone
      // into account. Instead, it returns times based on the given month in the scheduling timezone. So once those
      // times are converted to the client timezone, it's possible to have times that are outside the current month.
      // An example of this is if you have the scheduling timezone be Europe/London and the client timezone be
      // US/Hawaii. This is unfortunate because if there are times that we're filtering out in other months, that means
      // there are technically valid times in this current month that we aren't showing because the generate endpoint
      // didn't know we wanted to show those times (since it doesn't know what the client timezone is). Ideally, the
      // generate endpoint takes the client timezone as a param, and it generates times within the given month relative
      // to that timezone, but it doesn't yet.
      scheduleOptions.filter(({ start_time }) => Moment(start_time).tz(timezone).format('YYYY-MM') === month),
      ({ start_time }) => Moment(start_time).tz(timezone).format('YYYY-MM-DD')
    );
  }, [scheduleOptions, month, timezone]);

  const [selectedDate, setSelectedDate] = useState<string | undefined>();
  useEffect(() => {
    if (isEmpty(scheduleOptionsByIsoDate)) {
      setSelectedDate(`${month}-01`);
    }
    if (!isEmpty(scheduleOptionsByIsoDate) && (!selectedDate || !(selectedDate in scheduleOptionsByIsoDate))) {
      setSelectedDate(min(Object.keys(scheduleOptionsByIsoDate)));
    }
  }, [month, selectedDate, scheduleOptionsByIsoDate]);

  const hasScheduleOptionsOnDate = useCallback((date: Date) => {
    const isoDate = Moment(date).format('YYYY-MM-DD');
    return isoDate in scheduleOptionsByIsoDate;
  }, [scheduleOptionsByIsoDate]);

  const scheduleOptionsOnSelectedDate = useMemo(() => {
    if (!selectedDate) {
      return [];
    }
    const options = scheduleOptionsByIsoDate[selectedDate] || [];
    return orderBy(options, 'start_time');
  }, [selectedDate, scheduleOptionsByIsoDate]);

  const handleTimezoneChange = (option: OnChangeValue<Option<string>, false>) => {
    // The timezone input is not clearable, so option should always be defined.
    setTimezone(option ? option.value : '');
  };

  const handleConfirmModalToggle = () => {
    createSelfSchedulingLinkScheduleMutation.reset();
    updateSelfSchedulingLinkScheduleMutation.reset();
    setIsConfirmModalOpen((prev) => !prev);
  };

  const handleScheduleCreate = async () => {
    if (!selectedScheduleOption) {
      // This should only be called if this is set already.
      return;
    }

    if (isPreview) {
      onSuccess(selectedScheduleOption);
      setIsConfirmModalOpen(false);
      return;
    }

    createSelfSchedulingLinkScheduleMutation.reset();
    updateSelfSchedulingLinkScheduleMutation.reset();

    const payload = constructCreateSchedulePayload(timezone, selfSchedulingLink, selectedScheduleOption);

    try {
      await createSelfSchedulingLinkScheduleMutation.mutateAsync({ id: selfSchedulingLink.id, payload });
      setIsConfirmModalOpen(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  const handleScheduleUpdate = async () => {
    if (!selectedScheduleOption) {
      // This should only be called if this is set already.
      return;
    }

    if (isPreview) {
      onSuccess(selectedScheduleOption);
      setIsConfirmModalOpen(false);
      return;
    }

    createSelfSchedulingLinkScheduleMutation.reset();
    updateSelfSchedulingLinkScheduleMutation.reset();

    const payload = constructUpdateSchedulePayload(timezone, selfSchedulingLink, selectedScheduleOption);

    try {
      await updateSelfSchedulingLinkScheduleMutation.mutateAsync({
        id: selfSchedulingLink.id,
        payload,
        scheduleId: selfSchedulingLink.schedule!.id,
      });
      setIsConfirmModalOpen(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  const timezonePicker = (
    <div className="timezone-picker">
      Interview times are shown in
      <TimezoneSelectInput
        brandColor={brandColor}
        onChange={handleTimezoneChange}
        value={timezone}
      />
    </div>
  );

  return (
    <div className="schedule-picker">
      {isLoading || (selectedDate && selectedDate.replace(/-\d\d$/, '') !== month) ? (
        <div className="loading-spinner-wrapper">
          <LoadingSpinner />
        </div>
      ) : (
        <>
          <div className="date-and-time-picker">
            <DatePicker
              brandColor={brandColor}
              disabledDays={(date) => !hasScheduleOptionsOnDate(date)}
              fromMonth={Moment(fromMonth).toDate()}
              isRange={false}
              modifiers={{ available: hasScheduleOptionsOnDate }}
              modifiersStyles={{
                available: {
                  boxShadow: `0px 0px 0px 1px ${brandColor} inset`,
                },
                outside: {
                  boxShadow: 'none',
                },
              }}
              onChange={(date) => {
                if (hasScheduleOptionsOnDate(date)) {
                  setSelectedDate(Moment(date).format('YYYY-MM-DD'));
                }
              }}
              onMonthChange={(date) => {
                setMonth(Moment(date).format('YYYY-MM'));
              }}
              value={Moment(selectedDate).toDate()}
            />
            {isSmallScreen && timezonePicker}
            <div className="schedule-options-container">
              {isEmpty(scheduleOptions) &&
                <div className="no-options">
                  There are no options available to schedule your interview this month.
                  <br />
                  Go to the next month.
                </div>
              }
              {scheduleOptionsOnSelectedDate.map((scheduleOption) => (
                <Button
                  brandColor={brandColor}
                  color="gem-outline"
                  key={scheduleOption.start_time}
                  onClick={() => {
                    setSelectedScheduleOption(scheduleOption);
                    setIsConfirmModalOpen(true);
                  }}
                  size="small"
                  value={formatTimeRange(
                    Moment(scheduleOption.start_time).tz(timezone),
                    Moment(scheduleOption.end_time).tz(timezone)
                  )}
                />
              ))}
            </div>
          </div>
          {!isSmallScreen && timezonePicker}
        </>
      )}
      <SelfScheduleConfirmModal
        brandColor={brandColor}
        error={createSelfSchedulingLinkScheduleMutation.error || updateSelfSchedulingLinkScheduleMutation.error || undefined}
        isOpen={isConfirmModalOpen}
        isRescheduling={isRescheduling}
        isSubmitting={createSelfSchedulingLinkScheduleMutation.isLoading || updateSelfSchedulingLinkScheduleMutation.isLoading}
        onSubmit={isRescheduling ? handleScheduleUpdate : handleScheduleCreate}
        onToggle={handleConfirmModalToggle}
        selectedScheduleOption={selectedScheduleOption}
        selfSchedulingLink={selfSchedulingLink}
        timezone={timezone}
      />
    </div>
  );
};

export default SchedulePicker;
