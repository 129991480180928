import { PopoverBody, PopoverHeader, Popover as ReactstrapPopover } from 'reactstrap';
import { useEffect, useState } from 'react';

import type { Dispatch, ReactNode, SetStateAction } from 'react';
import type { PopoverProps } from 'reactstrap';

interface Props {
  children: ReactNode;
  className?: string;
  isOpen?: boolean;
  setIsOpen?: Dispatch<SetStateAction<boolean>>;
  position: PopoverProps['placement'];
  target: PopoverProps['target'];
  title?: string;
}

const Popover = ({ children, className, isOpen, position, setIsOpen, title, target }: Props) => {
  // The TooltipPopoverWrapper component (which is what Reactstrap's Popover
  // uses under the hood) treats selector targets a bit differently than ref
  // targets. For a ref target, if it's null, it just returns null. But for a
  // selector target, if it can't find the selector in the DOM, it'll throw a
  // "could not be identified in the dom" error
  // (https://github.com/reactstrap/reactstrap/blob/bd313c23e5d1dee984e65e5fdc167fe83f3831a9/src/utils.js#L287).
  // This can be a bit problematic because sometimes, the Popover will render
  // before the element with the specified target id. To workaround this race
  // condition, we start off by not rendering the Popover component to give the
  // target component time to render.
  const [renderPopover, setRenderPopover] = useState(false);
  const [controlledIsOpen, setControlledIsOpen] = useState(isOpen || false);

  useEffect(() => setRenderPopover(true), []);

  useEffect(() => {
    if (isOpen !== undefined && isOpen !== null && isOpen !== controlledIsOpen) {
      setControlledIsOpen(isOpen);
    }
  }, [isOpen]);

  const handleToggle = () => {
    setControlledIsOpen((prev) => !prev);
    setIsOpen?.((prev) => !prev);
  };

  if (!renderPopover) {
    return null;
  }

  return (
    <ReactstrapPopover
      className={`popover${className ? ` ${className}` : ''}`}
      isOpen={controlledIsOpen}
      onClick={(e) => e.stopPropagation()}
      placement={position}
      target={target}
      toggle={handleToggle}
      trigger="legacy"
    >
      {title &&
        <PopoverHeader>
          {title}
        </PopoverHeader>
      }
      <PopoverBody>
        {children}
      </PopoverBody>
    </ReactstrapPopover>
  );
};

export default Popover;
