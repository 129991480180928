import { concat, find, orderBy } from 'lodash';
import { useMemo, useState } from 'react';

import SelectInput from '../SelectInput';
import Tag from '../../data-display/Tag';
import { RoomFilterExpressionFilterableType } from '../../../../types';
import { useRooms, useRoomTags } from '../../../../hooks/queries/rooms';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';

interface Expression {
  filterable_id: string;
  filterable_type: `${RoomFilterExpressionFilterableType}`;
  isDisabled?: boolean;
  label: string;
  tooltip?: JSX.Element;
  value: string;
}

export interface Option extends Expression {
  name: string;
  negated: boolean;
}

const createOptions = (expressions: Expression[]): Option[] => orderBy(
  concat(
    expressions.map<Option>((expression) => ({
      ...expression,
      negated: false,
      name: expression.label,
    })),
    expressions.filter(({ isDisabled }) => !isDisabled).map<Option>((expression) => ({
      ...expression,
      label: `not ${expression.label}`,
      negated: true,
      name: expression.label,
    }))
  ),
  ['isDisabled', 'name', 'negated'],
  ['asc', 'asc', 'asc']
);

export interface Props {
  isDisabled?: boolean;
  name?: string;
  onChange: (newValue: OnChangeValue<Option, true>, actionMeta: ActionMeta<Option>) => void;
  selectedFilterExpressions: {
    filterable_id: string;
    filterable_type: `${RoomFilterExpressionFilterableType}`;
    negated: boolean;
  }[];
}

const RoomFilterInput = ({
  isDisabled = false,
  name,
  onChange,
  selectedFilterExpressions,
}: Props) => {
  const { data: roomTags } = useRoomTags();
  const { data: rooms } = useRooms({ archived: true });

  const [initialFilterExpressions] = useState(selectedFilterExpressions);

  const tagOptions = useMemo<Expression[]>(() => (roomTags || []).map((tag) => ({
    filterable_type: RoomFilterExpressionFilterableType.Tag,
    filterable_id: tag.id,
    label: tag.id,
    value: tag.id,
  })), [roomTags]);

  const roomOptions = useMemo<Expression[]>(() => (rooms?.rooms || []).filter(({ directory_archived, user_archived }) => !user_archived && !directory_archived).map((room) => ({
    filterable_type: RoomFilterExpressionFilterableType.Room,
    filterable_id: room.id,
    label: room.name,
    value: room.id,
  })), [rooms]);

  const isSingleSelectedValue = useMemo(() => (
    selectedFilterExpressions && selectedFilterExpressions.length === 1 &&
    selectedFilterExpressions[0].filterable_type === RoomFilterExpressionFilterableType.Room &&
    !selectedFilterExpressions[0].negated
  ), [selectedFilterExpressions]);

  const hasSelectedFilters = useMemo(() => selectedFilterExpressions.length > 0, [selectedFilterExpressions]);

  const options = useMemo(() => [{
    label: 'Tags',
    options: isSingleSelectedValue ? [] : createOptions([
      ...tagOptions,
      ...(initialFilterExpressions || []).filter((expression) => expression.filterable_type === RoomFilterExpressionFilterableType.Tag && !find(tagOptions, ['filterable_id', expression.filterable_id])).map((tag) => ({ ...tag, value: tag.filterable_id, label: tag.filterable_id })),
    ]),
    type: RoomFilterExpressionFilterableType.Tag,
  }, {
    label: 'Rooms',
    options: createOptions(roomOptions).filter((option) => {
      if (isSingleSelectedValue) {
        const selectedFilterExpression = selectedFilterExpressions[0];
        return selectedFilterExpression.filterable_type === RoomFilterExpressionFilterableType.Room && selectedFilterExpression.filterable_id === option.filterable_id;
      }
      if (hasSelectedFilters) {
        return option.negated;
      }
      return true;
    }),
    type: RoomFilterExpressionFilterableType.Room,
  }], [tagOptions, roomOptions, selectedFilterExpressions, initialFilterExpressions, isSingleSelectedValue, hasSelectedFilters]);

  return (
    <SelectInput<string, Option, true>
      className="room-filter-input"
      formatOptionLabel={(option) => (
        <div
          className="room-filter-option-container"
          data-for={option.tooltip ? option.tooltip.props.id : undefined}
          data-tip={option.tooltip ? true : undefined}
        >
          <Tag
            isNegated={option.negated}
            type={option.filterable_type}
            value={option.filterable_id}
          />
          {option.tooltip}
        </div>
      )}
      isClearable
      isDisabled={isDisabled}
      isMulti
      maxMenuHeight={300}
      name={name}
      noOptionsMessage={() => isSingleSelectedValue ? <>You&apos;ve already selected a specific room. To add more possible rooms to the pool, click on <b>Add Alternate Rule</b> below.</> : undefined}
      onChange={onChange}
      options={options}
      placeholder="Tag or specific room"
      value={selectedFilterExpressions ?
        selectedFilterExpressions.map((expression) => {
          const optionsOfSameType = find(options, ['type', expression.filterable_type])!.options;
          return find(optionsOfSameType, {
            filterable_id: expression.filterable_id,
            filterable_type: expression.filterable_type,
            negated: expression.negated,
          })!;
        }) :
        undefined
      }
    />
  );
};

export default RoomFilterInput;
