import Moment from 'moment';
import { useMemo } from 'react';

import TextInput from './TextInput';

import type { ReactNode } from 'react';

const calculateDuration = (hours: number, minutes: number): number => (hours * 60) + minutes;

interface Props {
  additionalText?: string;
  className?: string;
  helperText?: ReactNode;
  id?: string;
  isAbbreviatedUnits?: boolean;
  isDisabled?: boolean;
  isHoursOnly?: boolean;
  isRequired?: boolean;
  label?: string;
  maxMinutes?: number;
  minMinutes?: number;
  name?: string;
  onChange: (value: number, name?: string) => void;
  value?: number;
}

const DurationInput = ({
  additionalText,
  className,
  helperText,
  id,
  isAbbreviatedUnits = false,
  isDisabled = false,
  isHoursOnly = false,
  isRequired = false,
  label,
  maxMinutes = 600,
  minMinutes,
  name,
  onChange,
  value,
}: Props) => {
  const { hours, minutes } = useMemo<{ hours: number; minutes: number }>(() => {
    const duration = Moment.duration(value, 'minutes');
    if (maxMinutes < 60) {
      return { hours: 0, minutes: value || 0 };
    }
    return {
      hours: Math.floor(duration.asHours()),
      minutes: duration.minutes(),
    };
  }, [maxMinutes, value]);

  return (
    <div className={`input duration-input${className ? ` ${className}` : ''}`}>
      {label && <label htmlFor={id}>{label}</label>}
      <div className="duration-input-container">
        {maxMinutes >= 60 && (
          <>
            <TextInput
              id={`${id}-hours`}
              isDisabled={isDisabled}
              name={`${name ? `${name}-` : ''}hours`}
              numberMax={maxMinutes ? maxMinutes / 60 : undefined}
              numberMin={minMinutes ? Math.floor(minMinutes / 60) : 0}
              onChange={(e) => {
                const duration = calculateDuration(parseInt(e.target.value, 10) || 0, minutes || 0);
                return onChange(duration, name);
              }}
              placeholder="0"
              type="number"
              value={hours || ''}
            />
            <label
              className="duration-input-unit"
              htmlFor={`${id}-hours`}
            >
              {isAbbreviatedUnits ?
                'h' :
                `hour${hours !== 1 ? 's' : ''}`
              }
              {Boolean(isHoursOnly && additionalText) && ` ${additionalText}`}
            </label>
          </>
        )}
        {!isHoursOnly &&
          <>
            <TextInput
              id={`${id}-minutes`}
              isDisabled={isDisabled}
              isRequired={isRequired && !Boolean(value)}
              name={`${name ? `${name}-` : ''}minutes`}
              numberMax={59}
              numberMin={minMinutes && minMinutes < 60 ? minMinutes : undefined}
              numberStep={5}
              onChange={(e) => {
                const duration = calculateDuration(hours || 0, parseInt(e.target.value, 10) || 0);
                return onChange(duration, name);
              }}
              placeholder="0"
              type="number"
              value={minutes || ''}
            />
            <label
              className="duration-input-unit"
              htmlFor={`${id}-minutes`}
            >
              {isAbbreviatedUnits ?
                'min' :
                `minute${minutes !== 1 ? 's' : ''}`
              }
              {Boolean(!isHoursOnly && additionalText) && ` ${additionalText}`}
            </label>
          </>
        }
      </div>
      {helperText && <div className="helper-text">{helperText}</div>}
    </div>
  );
};

export default DurationInput;
