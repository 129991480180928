import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

import Button from './Button';
import DurationInput from './DurationInput';
import Flash from '../utils/Flash';
import StatusIndicator from '../utils/StatusIndicator';

export const MAX_ADVANCED_NOTICE_MINUTES = 90 * 24 * 60; // 90 days
export const MAX_MINIMUM_DURATION_MINUTES = 24 * 60; // 24 hours
export const MAX_TOTAL_AVAILABILITY_MINUTES = 7 * 24 * 60; // 7 days

interface Props {
  advancedNoticeHours: number;
  handleAdvancedNoticeHoursChange: (value: number, name?: string) => void;
  handleMinimumDurationMinutesChange: (value: number, name?: string) => void;
  handleTotalDurationMinutesChange: (value: number, name?: string) => void;
  isDisabled?: boolean;
  minimumDurationMinutes: number;
  totalDurationMinutes: number;
}

const AvailabilityRequestAdvancedSettings = ({
  advancedNoticeHours,
  handleAdvancedNoticeHoursChange,
  handleMinimumDurationMinutesChange,
  handleTotalDurationMinutesChange,
  isDisabled = false,
  minimumDurationMinutes,
  totalDurationMinutes,
}: Props) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="availability-request-advanced-settings">
      <div className={`button-header status${isExpanded ? ' expanded' : ''}`}>
        <Button
          color="no-outline"
          iconLeft={<StatusIndicator color={Boolean(advancedNoticeHours || minimumDurationMinutes || totalDurationMinutes) ? 'green' : 'gray'} />}
          onClick={toggleIsExpanded}
          size="small"
          value={<span>Advanced settings <FontAwesomeIcon icon={isExpanded ? faAngleUp : faAngleDown } /></span>}
        />
      </div>
      {isExpanded &&
        <div className="expanded-content">
          <Flash
            message="We recommend setting these additional requirements to ensure that you collect enough usable availability from the candidate."
            showFlash
            type="info"
          />
          <div className="advanced-settings">
            <DurationInput
              helperText="This is to enforce a minimum duration for each time block to make sure all submitted times are usable for you."
              id="minimum-duration"
              isDisabled={isDisabled}
              label="Minimum Duration"
              maxMinutes={MAX_MINIMUM_DURATION_MINUTES}
              onChange={handleMinimumDurationMinutesChange}
              value={minimumDurationMinutes}
            />
            <DurationInput
              helperText="We recommend requiring more availability than is strictly needed for the interview so that you have more options when scheduling."
              id="total-duration"
              isDisabled={isDisabled}
              label="Total Availability"
              maxMinutes={MAX_TOTAL_AVAILABILITY_MINUTES}
              onChange={handleTotalDurationMinutesChange}
              value={totalDurationMinutes}
            />
            <DurationInput
              helperText="The candidate must submit times that are at least this far in the future so that you have enough notice to coordinate the interview."
              isDisabled={isDisabled}
              isHoursOnly
              label="Advanced Notice"
              maxMinutes={MAX_ADVANCED_NOTICE_MINUTES}
              onChange={handleAdvancedNoticeHoursChange}
              value={advancedNoticeHours * 60}
            />
          </div>
        </div>
      }
    </div>
  );
};

export default AvailabilityRequestAdvancedSettings;
