const colors = {
  PRODUCT: {
    PRIMARY_ACTION: '#0d61ff',
    PRIMARY_CONTENT: '#17181a',
    SECONDARY_CONTENT: '#404449',
    TERTIARY_CONTENT: '#727682',
    PRIMARY_LABEL: '#ffffff',
    PRIMARY_BACKGROUND: '#ffffff',
    SECONDARY_BACKGROUND: '#f3f4f6',
    TERTIARY_BACKGROUND: '#fafafb',
    PRIMARY_BORDER: '#d2d6dc',
    SECONDARY_BORDER: '#e2e6ec',
    FOCUS_BORDER: '#adcaff',
    HOVER_FILL: '#00000014',
    SCRIM: '#00000066',
  },
  ACCENT: {
    TEAL: '#02b8b8',
    PURPLE: '#a558df',
    GREEN: '#82dca6',
    YELLOW: '#f8da48',
    RED: '#f17f83',
    PINK: '#ff64af',
    BLUE: '#4888ff',
  },
  SEMANTIC: {
    EDUCATION_BACKGROUND: '#ecdbf8',
    EDUCATION_CONTENT: '#49156f',
    INFORMATION_BACKGROUND: '#c5f5f5',
    INFORMATION_CONTENT: '#034949',
    POSITIVE_BACKGROUND: '#def8e4',
    POSITIVE_CONTENT: '#0d4b1c',
    NEGATIVE_BACKGROUND: '#fde4e6',
    NEGATIVE_CONTENT: '#771e2a',
    WARNING_BACKGROUND: '#fff5c2',
    WARNING_CONTENT: '#4d4000',
  },
  GRAYS: {
    GEM_BLACK: '#17181a',
    GRAY_900: '#292b2d',
    GRAY_800: '#404449',
    GRAY_700: '#525457',
    GRAY_600: '#686a6e',
    GRAY_500: '#727682',
    GRAY_400: '#979a9e',
    GRAY_300: '#b2b5b8',
    GRAY_200: '#d2d6dc',
    GRAY_100: '#e2e6ec',
    GRAY_75: '#edeef0',
    GRAY_50: '#f3f4f6',
    GRAY_25: '#fafafb',
    WHITE: '#ffffff',
  },
  TEALS: {
    TEAL_900: '#022929',
    TEAL_800: '#034949',
    TEAL_700: '#036c6c',
    TEAL_600: '#039292',
    TEAL_500: '#02b8b8',
    TEAL_400: '#01d9d9',
    TEAL_300: '#00f2f2',
    TEAL_200: '#9af1f1',
    TEAL_100: '#c5f5f5',
    TEAL_50: '#f3fdfd',
  },
  GREENS: {
    GREEN_900: '#083312',
    GREEN_800: '#0d4b1c',
    GREEN_700: '#136427',
    GREEN_600: '#197f32',
    GREEN_500: '#209a3e',
    GREEN_400: '#27b749',
    GREEN_300: '#2fd455',
    GREEN_200: '#82dca6',
    GREEN_100: '#def8e4',
    GREEN_50: '#eefbf1',
  },
  YELLOWS: {
    YELLOW_900: '#2e2700',
    YELLOW_800: '#4d4000',
    YELLOW_700: '#6f5c00',
    YELLOW_600: '#937a00',
    YELLOW_500: '#ba9a00',
    YELLOW_400: '#e1ba00',
    YELLOW_300: '#f8da48',
    YELLOW_200: '#ffed97',
    YELLOW_100: '#fff5c2',
    YELLOW_50: '#fffbe8',
  },
  REDS: {
    RED_900: '#511820',
    RED_800: '#771e2a',
    RED_700: '#9f2433',
    RED_600: '#c8293b',
    RED_500: '#f23041',
    RED_400: '#fd676d',
    RED_300: '#ff9599',
    RED_200: '#ffbec1',
    RED_100: '#fde4e6',
    RED_50: '#fef1f2',
  },
  PINKS: {
    PINK_900: '#500027',
    PINK_800: '#78003b',
    PINK_700: '#a40050',
    PINK_600: '#d20065',
    PINK_500: '#ff0d81',
    PINK_400: '#ff64af',
    PINK_300: '#ff93c7',
    PINK_200: '#ffbcdd',
    PINK_100: '#ffe2f0',
    PINK_50: '#fff5fa',
  },
  PURPLES: {
    PURPLE_900: '#361052',
    PURPLE_800: '#49156f',
    PURPLE_700: '#5d1b8e',
    PURPLE_600: '#7121ae',
    PURPLE_500: '#8627ce',
    PURPLE_400: '#a558df',
    PURPLE_300: '#be84e7',
    PURPLE_200: '#d5b0f0',
    PURPLE_100: '#ecdbf8',
    PURPLE_50: '#f8f2fc',
  },
  BLUES: {
    BLUE_900: '#001e56',
    BLUE_800: '#002d80',
    BLUE_700: '#003cad',
    BLUE_600: '#004cdb',
    BLUE_500: '#0d61ff',
    BLUE_400: '#4888ff',
    BLUE_300: '#7baaff',
    BLUE_200: '#adcaff',
    BLUE_100: '#dde9ff',
    BLUE_50: '#f5f9ff',
  },
  BRAND: {
    GEM_BLUE: '#0d61ff',
    GEM_BLACK: '#17181a',
    GEM_TEAL: '#00f2f2',
    GEM_PURPLE: '#8627ce',
    GEM_PINK: '#ff0d81',
    GEM_YELLOW: '#ffdf40',
    WHITE: '#ffffff',
  },
};

export default colors;
