import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import ErrorTokenFlash from '../../../../library/utils/ErrorTokenFlash';
import Section from '../../../../library/layout/Section';
import { DEFAULT_CALENDAR_EVENT_CONTENT } from '../../../CalendarEventTemplates/CalendarEventTemplateCreate/helpers';
import { EditorType, videoConferencingLabels, VideoConferencingTool } from '../../../../../types';
import { StyledCheckboxInput, StyledDurationInput, StyledEditorInput, StyledErrorTokenFlash, StyledFlash, StyledFormContainer, StyledHiringMeetingAttendeeSelectInput, StyledRoomSelectInput, StyledTextInput, StyledTokenInput, StyledZoomHostFiltersBuilder } from '../../styles';
import { constructRoomFiltersPayload, parseRoomIdsFromRoomFilters } from '../../../../../libraries/rooms';
import { createEmptyZoomHostFilter } from '../../../../../libraries/zoom-hosts';
import { getTokensFromSlateValue, slateValueToHtml } from '../../../../../libraries/editor/slate-value-to-html';
import { slateValueToText } from '../../../../../libraries/editor/slate-value-to-text';
import { useHiringMeetingTemplate, useUpdateHiringMeetingTemplate } from '../../../../../hooks/queries/hiring-meeting-templates';
import { useSession } from '../../../../../hooks/use-session';
import { useSlateEditor } from '../../../../../hooks/use-slate-editor';
import { useTokens } from '../../../../../hooks/queries/tokens';
import { useUpdateCalendarEventTemplate } from '../../../../../hooks/queries/calendar-event-templates';

import type { ChangeEvent } from 'react';
import type { EditableHiringMeetingAttendeeFilter, EditableZoomHostFilter } from '../../../../../types';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../../../library/inputs/SelectInput/types';
import type { UpdateCalendarEventTemplatePayload } from '../../../../../hooks/queries/calendar-event-templates';
import type { UpdateHiringMeetingTemplatePayload } from '../../../../../hooks/queries/hiring-meeting-templates';

const HiringMeetingTemplateSection = () => {
  const { id } = useParams<{ id: string }>();

  const { account } = useSession();
  const hiringMeetingTemplate = useHiringMeetingTemplate(id).data!;

  const [name, setName] = useState(hiringMeetingTemplate.name);
  const [durationMinutes, setDurationMinutes] = useState(hiringMeetingTemplate.duration_minutes);
  const [isVideoConferencingEnabled, setIsVideoConferencingEnabled] = useState(hiringMeetingTemplate.video_conferencing_enabled);
  const [zoomHostFilters, setZoomHostFilters] = useState<EditableZoomHostFilter[]>(hiringMeetingTemplate.zoom_host_filters || createEmptyZoomHostFilter());
  const [rooms, setRooms] = useState<string[]>(parseRoomIdsFromRoomFilters(hiringMeetingTemplate.room_filters));
  const [attendeeFilters, setAttendeeFilters] = useState<EditableHiringMeetingAttendeeFilter[]>(hiringMeetingTemplate.hiring_meeting_attendee_filters || []);
  const [calendarEventTemplateTitleSlateEditor, calendarEventTemplateTitleSlateValue, setCalendarEventTemplateTitleSlateValue, setCalendarEventTemplateTitle] = useSlateEditor(hiringMeetingTemplate.calendar_event_template.title, true);
  const [calendarEventTemplateDescriptionSlateEditor, calendarEventTemplateDescriptionSlateValue, setCalendarEventTemplateDescriptionSlateValue, setCalendarEventTemplateDescription] = useSlateEditor(hiringMeetingTemplate.calendar_event_template.description);
  const [calendarEventTemplateLocation, setCalendarEventTemplateLocation] = useState(hiringMeetingTemplate.calendar_event_template.location || '');

  const [isLoading, setIsLoading] = useState(false);
  const [isEditing, setIsEditing] = useState(false);

  const updateCalendarEventTemplateMutation = useUpdateCalendarEventTemplate();
  const updateHiringMeetingTemplateMutation = useUpdateHiringMeetingTemplate();

  const {
    data: tokens,
    error: tokensError,
  } = useTokens({
    type: EditorType.HiringMeetingCalendarEvent,
  });

  const tokensUsedInTitle = useMemo(() => getTokensFromSlateValue(calendarEventTemplateTitleSlateValue), [calendarEventTemplateTitleSlateValue]);
  const tokensUsedInDescription = useMemo(() => getTokensFromSlateValue(calendarEventTemplateDescriptionSlateValue), [calendarEventTemplateDescriptionSlateValue]);
  const errorTokens = useMemo(() => {
    const errorTitleTokens = (
      tokens && tokensUsedInTitle ?
        tokensUsedInTitle.filter((name) => !tokens[name] || tokens[name].disabled) :
        []
    );
    const errorDescriptionTokens = (
      tokens && tokensUsedInDescription ?
        tokensUsedInDescription.filter((name) => !tokens[name] || tokens[name].disabled) :
        []
    );
    return [
      ...errorTitleTokens,
      ...errorDescriptionTokens,
    ];
  }, [tokens, tokensUsedInTitle, tokensUsedInDescription]);

  const resetValues = () => {
    setName(hiringMeetingTemplate.name);
    setDurationMinutes(hiringMeetingTemplate.duration_minutes);
    setIsVideoConferencingEnabled(hiringMeetingTemplate.video_conferencing_enabled);
    setZoomHostFilters(hiringMeetingTemplate.zoom_host_filters || createEmptyZoomHostFilter());
    setRooms(parseRoomIdsFromRoomFilters(hiringMeetingTemplate.room_filters));
    setAttendeeFilters(hiringMeetingTemplate.hiring_meeting_attendee_filters || []);
    setCalendarEventTemplateTitle(hiringMeetingTemplate.calendar_event_template.title);
    setCalendarEventTemplateDescription(hiringMeetingTemplate.calendar_event_template.description);
    setCalendarEventTemplateLocation(hiringMeetingTemplate.calendar_event_template.location || '');
  };

  useEffect(() => {
    resetValues();
  }, [hiringMeetingTemplate]);

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => setName(e.target.value);
  const handleDurationMinutesChange = (duration: number) => setDurationMinutes(duration);
  const handleIsVideoConferencingEnabledChange = (e: ChangeEvent<HTMLInputElement>) => setIsVideoConferencingEnabled(e.target.checked);
  const handleZoomHostFiltersChange = (filters: EditableZoomHostFilter[]) => setZoomHostFilters(filters);
  const handleRoomsChange = (options: OnChangeValue<Option<string>, true>) => setRooms(options ? options.map((room) => room.value) : []);
  const handleAttendeeFiltersChange = (filters: EditableHiringMeetingAttendeeFilter[]) => setAttendeeFilters(filters);
  const handleCalendarEventTemplateLocationChange = (e: ChangeEvent<HTMLInputElement>) => setCalendarEventTemplateLocation(e.target.value);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    updateHiringMeetingTemplateMutation.reset();
    updateCalendarEventTemplateMutation.reset();
    setIsEditing(false);
    resetValues();
  };

  const handleSave = async () => {
    if (errorTokens.length > 0) {
      // We don't need to display an error because ErrorTokenFlash already takes
      // care of that. We just need to stop the execution from here.
      return;
    }

    updateCalendarEventTemplateMutation.reset();
    updateHiringMeetingTemplateMutation.reset();
    setIsLoading(true);

    try {
      const payload: UpdateCalendarEventTemplatePayload = {
        title: slateValueToText(calendarEventTemplateTitleSlateValue),
        description: slateValueToHtml(calendarEventTemplateDescriptionSlateValue),
        location: calendarEventTemplateLocation,
      };
      await updateCalendarEventTemplateMutation.mutateAsync({
        id: hiringMeetingTemplate.calendar_event_template_id,
        payload,
      });
    } catch (_) {
      setIsLoading(false);
      return;
    }

    try {
      const payload: UpdateHiringMeetingTemplatePayload = {
        name,
        duration_minutes: durationMinutes,
        video_conferencing_enabled: isVideoConferencingEnabled,
        zoom_host_filters: isVideoConferencingEnabled && account?.video_conferencing_type === VideoConferencingTool.Zoom ? (zoomHostFilters || []).map(({ zoom_host_filter_expressions }) => ({
          zoom_host_filter_expressions: zoom_host_filter_expressions.map((exp) => ({
            filterable_id: exp.filterable_id,
            filterable_type: exp.filterable_type,
            negated: exp.negated,
          })),
        })) : undefined,
        room_filters: constructRoomFiltersPayload(rooms),
        hiring_meeting_attendee_filters: attendeeFilters.map((filter) => ({
          hiring_meeting_attendee_filter_expressions: filter.hiring_meeting_attendee_filter_expressions.map((exp) => ({
            filterable_id: exp.filterable_id,
            filterable_type: exp.filterable_type,
            negated: exp.negated,
          })),
        })),
      };
      await updateHiringMeetingTemplateMutation.mutateAsync({
        id: hiringMeetingTemplate.id,
        payload,
      });
    } catch (_) {
      setIsLoading(false);
      return;
    }

    setIsLoading(false);
    setIsEditing(false);
  };

  return (
    <Section
      isEditable
      isEditing={isEditing}
      isSaving={updateHiringMeetingTemplateMutation.isLoading}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Hiring meeting template"
    >
      <ErrorTokenFlash errorTokens={errorTokens} />
      <StyledFlash
        message={tokensError?.message}
        showFlash={Boolean(tokensError)}
        type="danger"
      />
      <StyledFlash
        message={updateCalendarEventTemplateMutation.error?.message}
        showFlash={updateCalendarEventTemplateMutation.isError}
        type="danger"
      />
      <StyledFlash
        message={updateHiringMeetingTemplateMutation.error?.message}
        showFlash={updateHiringMeetingTemplateMutation.isError}
        type="danger"
      />
      <StyledFlash
        isDismissible
        message="Successfully updated!"
        showFlash={updateHiringMeetingTemplateMutation.isSuccess}
        type="success"
      />
      <StyledErrorTokenFlash errorTokens={errorTokens} />
      <StyledTextInput
        isDisabled={!isEditing || isLoading}
        isRequired
        label="Template Name"
        onChange={handleNameChange}
        value={name}
      />
      <StyledDurationInput
        isDisabled={!isEditing || isLoading}
        isRequired
        label="Meeting Duration"
        maxMinutes={360}
        onChange={handleDurationMinutesChange}
        value={durationMinutes}
      />
      <StyledCheckboxInput
        helperText="We will create a video conferencing link and add it to the event."
        isChecked={isVideoConferencingEnabled}
        isDisabled={!isEditing || isLoading}
        label="Add video conferencing."
        onChange={handleIsVideoConferencingEnabledChange}
      />
      {isVideoConferencingEnabled && account?.video_conferencing_type === 'zoom' &&
        <StyledZoomHostFiltersBuilder
          excludeFirstInterviewerOption
          filters={zoomHostFilters}
          isDisabled={!isEditing || isLoading}
          label="Zoom Meeting Host"
          onChange={handleZoomHostFiltersChange}
        />
      }
      <StyledRoomSelectInput
        isDisabled={!isEditing || isLoading}
        label="Rooms"
        onChange={handleRoomsChange}
        selectedRoomIds={rooms}
      />
      <StyledHiringMeetingAttendeeSelectInput
        isDisabled={!isEditing || isLoading}
        onChange={handleAttendeeFiltersChange}
        value={attendeeFilters}
      />
      <StyledFormContainer>
        <StyledTokenInput
          editor={calendarEventTemplateTitleSlateEditor}
          isDisabled={!isEditing || isLoading}
          isRequired
          label="Calendar Event Title"
          pendingPreviewMessage="You can preview this token when you are scheduling a hiring meeting."
          setValue={setCalendarEventTemplateTitleSlateValue}
          tokens={tokens}
          type="hiring_meeting_calendar_event"
          value={calendarEventTemplateTitleSlateValue}
        />
        <StyledTextInput
          isDisabled={isVideoConferencingEnabled || !isEditing || isLoading}
          label="Calendar Event Location"
          onChange={handleCalendarEventTemplateLocationChange}
          placeholder={isVideoConferencingEnabled ? `This will be set to the ${videoConferencingLabels[account?.video_conferencing_type!]} link` : ''}
          value={isVideoConferencingEnabled ? '' : calendarEventTemplateLocation}
        />
      </StyledFormContainer>
      <StyledEditorInput
        editor={calendarEventTemplateDescriptionSlateEditor}
        exampleHtmlContent={DEFAULT_CALENDAR_EVENT_CONTENT.hiring_meeting_calendar_event.description}
        isDisabled={!isEditing || isLoading}
        label="Calendar Event Description"
        pendingPreviewMessage="You can preview this token when you are scheduling a hiring meeting."
        setValue={setCalendarEventTemplateDescriptionSlateValue}
        tokens={tokens}
        type="hiring_meeting_calendar_event"
        value={calendarEventTemplateDescriptionSlateValue}
      />
    </Section>
  );
};

export default HiringMeetingTemplateSection;
