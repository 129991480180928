import Button from 'components/library/inputs/Button';

import { StyledOutboundLink } from './styles';

const GEM_DEMO_REQUEST_URL = 'https://www.gem.com/request-demo-scheduling';

interface Props {
  label: string;
}

const RequestADemoButton = ({ label }: Props) => {
  return (
    <StyledOutboundLink
      externalLinkIcon={false}
      href={GEM_DEMO_REQUEST_URL}
      label={`${label} Request a Demo Button`}
    >
      <Button
        color="orange"
        size="large"
        value="Request a Demo"
      />
    </StyledOutboundLink>
  );
};

export default RequestADemoButton;
