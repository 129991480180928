import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import Card from 'components/library/layout/Card';

export const StyledCard = styled(Card)`
  font-size: 14px;
  padding: 1em;

  .card-content {
    color: ${({ theme }) => theme.colors.PRODUCT.PRIMARY_CONTENT} !important;
    position: relative;
  }
`;

export const StyledDirectoryCalendarButtonContainer = styled.div`
  position: absolute;
  right: 0;
  top: -1em;
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.BRAND.GEM_BLUE};
  font-size: 1.2em;
  margin-right: 1em;
  width: 1.5em;
`;

export const StyledSection = styled.div`
  display: flex;
  align-items: baseline;
  margin: 1em 0;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
`;

export const StyledSingleLineSection = styled(StyledSection)`
  align-items: center;
`;

export const StyledTimeSection = styled(StyledSection)`
  color: ${({ theme }) => theme.colors.PRODUCT.SECONDARY_CONTENT};
  font-size: .8em;
  font-weight: lighter;
  letter-spacing: .06em;
  margin-bottom: .1em;
`;

export const StyledTitleSection = styled(StyledSection)`
  font-weight: bold;
  margin-top: .1em;
`;

export const StyledPrivateTitleSection = styled.div`
  display: flex;
  align-items: center;

  .fa-lock {
    font-size: 0.9em;
    margin-left: 0.5em;
  }
`;

export const StyledLocationSection = styled(StyledSection)`
  align-items: center;
`;

export const StyledVideoConferencingLogo = styled.div`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.PRODUCT.SECONDARY_CONTENT};
  font-size: .7rem;

  .avatar {
    background: transparent;
    border: 0;
    height: 18px;
    line-height: 18px;
    margin-right: .5em;
    width: 18px;
  }

  .avatar-teams {
    img {
      border-radius: 0;
    }
  }
`;

export const StyledDescriptionSection = styled(StyledSection)`
  white-space: pre-wrap;
  align-items: normal;
`;

export const StyledDescriptionText = styled.div`
  // reverting all normal InterviewPlanner styles so it more accurately
  // represents what it will look like
  h1, h2, h3, h4, h5, h6 {
    color: revert;
    font-family: revert;
    font-size: revert;
    font-weight: revert;
    letter-spacing: revert;
    text-decoration: revert;
    text-transform: revert;
  }
`;
