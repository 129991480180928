import styled from 'styled-components';

import Button from 'components/library/inputs/Button';

export const StyledButton = styled(Button)`
  margin-left: 8px;
  width: 150px;
`;

export const StyledContainer = styled.div`
  align-items: center;
  border: 1px solid ${({ theme }) => theme.colors.PRODUCT.SECONDARY_BORDER};
  border-radius: 10px;
  display: flex;
  flex: 1;
  padding: 14px 24px;
  margin: 8px 0;

  h3 {
    font-weight: normal;
  }

  p {
    color: ${({ theme }) => theme.colors.PRODUCT.SECONDARY_CONTENT};
  }
`;

export const StyledStepNumberContainer = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-right: 8px;
`;

export const StyledTitleContainer = styled.div`
  flex: 1;
`;
