import { useMemo } from 'react';

import { StyledBar, StyledCompletedBar } from './styles';

interface Props {
  percent?: number; // 0 - 100
}

const ProgressBar = ({ percent }: Props) => {
  const normalizedPercent = useMemo(() => {
    if (percent === undefined || percent > 100) {
      return 100;
    }
    if (percent < 0) {
      return 0;
    }
    return percent;
  }, [percent]);

  return (
    <StyledBar>
      <StyledCompletedBar
        $isDeterminate={Boolean(percent)}
        style={{ width: `${normalizedPercent}%` }}
      />
    </StyledBar>
  );
};

export default ProgressBar;
