import Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { find, flatten, groupBy } from 'lodash';
import { useMemo } from 'react';

import Button from './Button';
import FavoriteButton from './FavoriteButton';
import ListItem from '../data-display/ListItem';
import SelectInput from './SelectInput';
import { calendarEventTemplateTypeLabels, FavoriteResourceType } from '../../../types';
import { formatMoment, TimeFormat } from '../../../libraries/time';
import { useCalendarEventTemplates } from '../../../hooks/queries/calendar-event-templates';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { CalendarEventTemplateType } from '../../../types';
import type { Group, Option as SelectOption } from './SelectInput/types';

export interface Option extends SelectOption<string> {
  favorite?: boolean;
}

interface Props {
  isClearable?: boolean;
  isDisabled?: boolean;
  isInlineCreatable?: boolean;
  label?: string;
  onChange: (newValue: OnChangeValue<Option, false>, actionMeta: ActionMeta<Option>) => void;
  type?: `${CalendarEventTemplateType}`;
  value?: string;
}

const CalendarEventTemplateSelectInput = ({
  isInlineCreatable = false,
  isClearable = false,
  isDisabled = false,
  label = 'Calendar Event Template',
  onChange,
  type,
  value,
}: Props) => {
  const calendarEventTemplatesQuery = useCalendarEventTemplates();
  const calendarEventTemplates = useMemo(() => {
    if (!calendarEventTemplatesQuery.data) {
      return [];
    }
    return calendarEventTemplatesQuery.data.calendar_event_templates.filter((calendarEventTemplate) => {
      return !calendarEventTemplate.archived && (!type || calendarEventTemplate.type === type);
    });
  }, [calendarEventTemplatesQuery.data]);

  const options = useMemo<Option[] | Group<string, Option>[]>(() => {
    if (!type) {
      const groupedTemplates = groupBy(calendarEventTemplates, 'type');

      return Object.keys(groupedTemplates).map((typeGroup) => ({
        label: calendarEventTemplateTypeLabels[typeGroup as CalendarEventTemplateType],
        options: groupedTemplates[typeGroup].map((emailTemplate) => ({
          value: emailTemplate.id,
          label: emailTemplate.name,
          secondaryText: `Created on ${formatMoment(Moment(emailTemplate.created_at), TimeFormat.LongMonthDayYear)}`,
          favorite: emailTemplate.favorite,
        })),
      }));
    }

    const templateOptions: Option[] = calendarEventTemplates.map((calendarEventTemplate) => ({
      value: calendarEventTemplate.id,
      label: calendarEventTemplate.name,
      secondaryText: `Created on ${formatMoment(Moment(calendarEventTemplate.created_at), TimeFormat.LongMonthDayYear)}`,
      favorite: calendarEventTemplate.favorite,
    }));

    if (isInlineCreatable) {
      templateOptions.unshift({
        value: 'new',
        label: (
          <Button
            color="no-outline"
            iconLeft={<FontAwesomeIcon icon={faPlus} />}
            size="small"
            value="New Calendar Event Template"
          />
        ),
      });
    }

    return templateOptions;
  }, [calendarEventTemplates, isInlineCreatable, type]);

  const selectedValue = useMemo<Option | undefined>(() => {
    if (!value) {
      return undefined;
    }

    if (!type) {
      const allOptions = flatten((options as Group<string, Option>[]).map((group) => group.options));
      return find(allOptions, ['value', value]);
    }

    return find((options as Option[]), ['value', value]);
  }, [options, value, type]);

  return (
    <SelectInput<string, Option>
      formatOptionLabel={(option, { context }) => (
        context === 'menu' ?
          <ListItem
            label={(
              <div className="favorite-container">
                {option.favorite ? (
                  <FavoriteButton
                    favorite={option.favorite}
                    isDisabled
                    resourceId={option.value}
                    resourceType={FavoriteResourceType.CalendarEventTemplate}
                  />
                ) : undefined}
                {option.label}
              </div>
            )}
            secondaryText={option.secondaryText}
          /> :
          option.label
      )}
      isClearable={isClearable}
      isDisabled={isDisabled}
      label={label}
      onChange={onChange}
      options={options}
      placeholder="Select a template"
      value={selectedValue}
    />
  );
};

export default CalendarEventTemplateSelectInput;
