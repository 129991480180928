import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUnlink } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

import Button from '../../library/inputs/Button';
import Flash from '../../library/utils/Flash';
import Modal from '../../library/layout/Modal';
import Tooltip from '../../library/utils/Tooltip';
import { useDeleteAvailability } from '../../../hooks/queries/availabilities';

import type { Application } from '../../../types';

interface Props {
  application: Application;
  onSuccess: () => void;
}

const CancelAvailabilityButton = ({ application, onSuccess }: Props) => {
  const deleteAvailabilityMutation = useDeleteAvailability();

  const [showModal, setShowModal] = useState(false);

  const availability = (application.active_availabilities ?
    application.active_availabilities.filter(({ ats_id, manual }) => !manual && !Boolean(ats_id))[0] :
    null
  );

  const toggleModal = () => {
    deleteAvailabilityMutation.reset();
    setShowModal(!showModal);
  };

  const handleCancelAvailability = async () => {
    if (!availability) {
      // This shouldn't ever be invoked if there is no availability.
      return;
    }

    deleteAvailabilityMutation.reset();

    try {
      await deleteAvailabilityMutation.mutateAsync({ id: availability.id });
      onSuccess();
      setShowModal(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  if (!availability) {
    return null;
  }

  return (
    <>
      <Button
        className="btn-delete"
        color="gray"
        iconRight={<FontAwesomeIcon icon={faUnlink} />}
        onClick={toggleModal}
        size="large"
        tooltip={
          <Tooltip
            id={`${application.id}-cancel-availability-button`}
            position="top"
            value="Cancel availability request"
          />
        }
      />
      <Modal
        cancelButtonValue="No, keep this availability request"
        isOpen={showModal}
        isSubmitting={deleteAvailabilityMutation.isLoading}
        onSubmit={handleCancelAvailability}
        onToggle={toggleModal}
        submitButtonValue="Yes, cancel availability request"
        submittingButtonValue="Cancelling..."
        title="Cancel availability request?"
      >
        <Flash
          message="If you need to collect additional availability from your candidate, we recommend re-using the same link instead of creating a new one."
          showFlash={!deleteAvailabilityMutation.isLoading && !deleteAvailabilityMutation.isError}
          type="info"
        />
        <Flash
          message={deleteAvailabilityMutation.error?.message}
          showFlash={deleteAvailabilityMutation.isError}
          type="danger"
        />
        <p>
          {/*TODO: replace current_stage with availability.stage once stage is loaded in the availability*/}
          You are cancelling the availability request sent to <b>{application.candidate.name}</b> for <b>{application?.current_stage?.name}</b>.
        </p>
        <p>
          If the candidate tries to access the availability link, they will see a message that the link does not exist. You can re-request availability later if you wish.
        </p>
      </Modal>
    </>
  );
};

export default CancelAvailabilityButton;
