import { Breadcrumb } from 'react-breadcrumbs';
import { Redirect, Route, Switch } from 'react-router-dom';
import { useEffect } from 'react';

import DetailsStep from './DetailsStep';
import Flash from 'components/library/utils/Flash';
import InterviewersStep from './InterviewersStep';
import LoadingSpinner from 'components/library/utils/LoadingSpinner';
import ReviewStep from './ReviewStep';
import Section from 'components/library/layout/Section';
import StepProgressBar from 'components/library/navigation/StepProgressBar';
import { NewInterviewTemplateProvider, useNewInterviewTemplate } from './use-new-interview-template';
import { useInterviewTemplate } from 'hooks/queries/interview-templates';
import useQueryState from 'hooks/use-query-state';
import { correctPath } from 'libraries/gem';

// To enable the inner component to access the context value, it needs to be nested
// under the provider, so we need this wrapper component to add that nesting.
const InterviewTemplateCreate = () => {
  return (
    <NewInterviewTemplateProvider>
      <InterviewTemplateCreateInner />
    </NewInterviewTemplateProvider>
  );
};

const InterviewTemplateCreateInner = () => {
  const [baseId] = useQueryState<string>('base', '');
  const {
    data: baseTemplate,
    error: baseTemplateError,
    isLoading: isBaseTemplateLoading,
  } = useInterviewTemplate(baseId);

  const {
    completedStep,
    interviewTemplate,
    setInterviewTemplate,
  } = useNewInterviewTemplate();

  useEffect(() => {
    if (!isBaseTemplateLoading && baseTemplate) {
      setInterviewTemplate({
        name: `Copy of ${baseTemplate.name}`,
        duration_minutes: baseTemplate.duration_minutes,
        live_coding_enabled: baseTemplate.live_coding_enabled,
        candidate_facing_name: baseTemplate.candidate_facing_name,
        candidate_facing_details: baseTemplate.candidate_facing_details,
        positions: baseTemplate.positions,
        time_window_start: baseTemplate.time_window_start,
        time_window_end: baseTemplate.time_window_end,
        interviewer_templates: (baseTemplate.interviewer_templates || []).map((interviewerTemplate) => ({
          description: interviewerTemplate.description,
          include_past_interviewers: interviewerTemplate.include_past_interviewers,
          optional: interviewerTemplate.optional,
          interviewer_filters: (interviewerTemplate.interviewer_filters || []).map((interviewerFilter) => ({
            interviewer_filter_expressions: (interviewerFilter.interviewer_filter_expressions || []).map((interviewerFilterExpression) => ({
              filterable_id: interviewerFilterExpression.filterable_id,
              filterable_type: interviewerFilterExpression.filterable_type,
              negated: interviewerFilterExpression.negated,
            })),
          })),
        })),
      });
    }
  }, [isBaseTemplateLoading, baseTemplate]);

  if (baseId && !Boolean(baseTemplateError) && !Boolean(interviewTemplate?.name)) {
    return <LoadingSpinner />;
  }

  return (
    <Breadcrumb
      data={{
        title: 'Create',
        pathname: correctPath('/app/interview-templates/create'),
      }}
    >
      <Section
        className="interview-template-create-container"
        title="Create a new interview template"
      >
        <Flash
          message={baseTemplateError?.message}
          showFlash={Boolean(baseTemplateError)}
          type="danger"
        />
        <StepProgressBar
          completedStep={completedStep}
          steps={[{
            label: 'Details',
            location: correctPath(`/app/interview-templates/create/details${baseId ? `?base=${baseId}` : ''}`),
          }, {
            label: 'Interviewers',
            location: correctPath(`/app/interview-templates/create/interviewers${baseId ? `?base=${baseId}` : ''}`),
          }, {
            label: 'Review',
            location: correctPath(`/app/interview-templates/create/review${baseId ? `?base=${baseId}` : ''}`),
          }]}
        />
        <div className="interview-template-create-steps-container">
          <Switch>
            <Redirect exact from={correctPath('/app/interview-templates/create')} to={correctPath(`/app/interview-templates/create/details${baseId ? `?base=${baseId}` : ''}`)} />
            <Route component={DetailsStep} path={correctPath('/app/interview-templates/create/details')} />
            <Route component={InterviewersStep} path={correctPath('/app/interview-templates/create/interviewers')} />
            <Route component={ReviewStep} path={correctPath('/app/interview-templates/create/review')} />
          </Switch>
        </div>
      </Section>
    </Breadcrumb>
  );
};

export default InterviewTemplateCreate;
