import styled from 'styled-components';

import InterviewSummary from '../../library/data-display/InterviewSummary';

export const StyledInterviewSummary = styled(InterviewSummary)`
  margin-bottom: 1em;
  width: 30%;

  @media only screen and (max-width: 1000px) {
    width: 100%;
  }
`;
