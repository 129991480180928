import Moment from 'moment-timezone';
import { isEmpty, orderBy, some } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import CheckboxInput from '../../../../../../../library/inputs/CheckboxInput';
import Flash from '../../../../../../../library/utils/Flash';
import Modal from '../../../../../../../library/layout/Modal';
import { directoryCalendarLabels, liveCodingLabels } from '../../../../../../../../types';
import { calculateInterviewChanges, formatInterviewNames } from '../helpers';
import { constructPayload } from './helpers';
import { formatMoment, TimeFormat } from '../../../../../../../../libraries/time';
import { useRoomsMap } from '../../../../../../../../hooks/queries/rooms';
import { useSchedule, useUpdateSchedule } from '../../../../../../../../hooks/queries/schedules';
import { useSession } from '../../../../../../../../hooks/use-session';
import { useUsersMap } from '../../../../../../../../hooks/queries/users';

import type { ChangeEvent } from 'react';
import type { Schedule, EditableSchedule } from '../../../../../../../../types';

const TOKEN_REGEX = /{{\s*(.+?)\s*}}/g;

interface Props {
  isOpen: boolean;
  onSuccess: (newlyUpdatedSchedule: Schedule) => void;
  onToggle: () => void;
  schedule: EditableSchedule;
  scheduleId: string;
}

const UpdateScheduleConfirmModal = ({ isOpen, onSuccess, onToggle, schedule, scheduleId }: Props) => {
  const { account } = useSession();
  const oldSchedule = useSchedule(scheduleId).data!;
  const rooms = useRoomsMap();
  const users = useUsersMap({ archived: true });

  const updateScheduleMutation = useUpdateSchedule();

  const [updateScheduleOptions, setUpdateScheduleOptions] = useState({
    send_interviewer_notifications: false,
    send_candidate_notifications: false,
    update_names: false,
    update_descriptions: false,
  });

  const oldDate = formatMoment(Moment.tz(oldSchedule.interviews[0].start_time, oldSchedule.timezone), TimeFormat.LongDayOfWeekMonthDay);
  const newDate = formatMoment(Moment.tz(schedule.interviews[0].start_time, schedule.timezone), TimeFormat.LongDayOfWeekMonthDay);

  const oldRoomId = oldSchedule.interviews[0].room_id || null;
  const newRoomId = schedule.interviews[0].room_id || null;

  const oldZoomHostId = oldSchedule.interviews[0].zoom_host_id || null;
  const newZoomHostId = schedule.interviews[0].zoom_host_id || null;
  const newZoomHostType = schedule.interviews[0].zoom_host_type || null;

  const oldVideoConferencingEnabled = oldSchedule.schedule_template.video_conferencing_enabled;
  const newVideoConferencingEnabled = schedule.schedule_template.video_conferencing_enabled;

  const interviewChanges = calculateInterviewChanges(oldSchedule, schedule);
  const interviewsWithUpdatedScorecard = interviewChanges.filter(({ changes }) => changes.scorecard);
  const interviewsWithUpdatedFeedbackForm = interviewChanges.filter(({ changes }) => changes.feedbackForm);
  const interviewsWithUpdatedLiveCodingEnabled = interviewChanges.filter(({ changes }) => changes.liveCodingEnabled);
  const interviewsWithUpdatedInterviewers = interviewChanges.filter(({ changes }) => changes.interviewers);
  const interviewsWithUpdatedOptionalities = interviewChanges.filter(({ changes }) => changes.optionalities);
  const interviewsWithUpdatedTrainings = interviewChanges.filter(({ changes }) => changes.trainings);
  const interviewsWithUpdatedTimes = interviewChanges.filter(({ changes }) => changes.times);
  const removedInterviews = interviewChanges.filter(({ changes }) => changes.removedInterview);
  const newInterviews = interviewChanges.filter(({ changes }) => changes.newInterview);

  const interviewsMissingInterviewers = schedule.interviews.filter(({ interviewers }) => {
    return (interviewers || []).some(({ user_id }) => !user_id);
  });

  const isInterviewsChanged = Boolean(
    oldDate !== newDate ||
    oldRoomId !== newRoomId ||
    oldZoomHostId !== newZoomHostId ||
    oldVideoConferencingEnabled !== newVideoConferencingEnabled ||
    interviewsWithUpdatedScorecard.length ||
    interviewsWithUpdatedFeedbackForm.length ||
    interviewsWithUpdatedLiveCodingEnabled.length ||
    interviewsWithUpdatedInterviewers.length ||
    interviewsWithUpdatedOptionalities.length ||
    interviewsWithUpdatedTrainings.length ||
    interviewsWithUpdatedTimes.length ||
    removedInterviews.length ||
    newInterviews.length
  );

  useEffect(() => {
    setUpdateScheduleOptions((prevOptions) => ({
      ...prevOptions,
      send_interviewer_notifications: isInterviewsChanged,
      update_descriptions: isInterviewsChanged,
    }));
  }, [isInterviewsChanged]);

  const oldScheduleStartTime = Moment.tz(oldSchedule.interviews[0].start_time, oldSchedule.timezone);
  const oldScheduleEndTime = Moment.tz(oldSchedule.interviews[oldSchedule.interviews.length - 1].start_time, oldSchedule.timezone).add(oldSchedule.interviews[oldSchedule.interviews.length - 1].interview_template.duration_minutes, 'minutes');
  const newScheduleOrderedInterviews = orderBy(schedule.interviews, 'start_time');
  const newScheduleStartTime = Moment.tz(newScheduleOrderedInterviews[0].start_time, schedule.timezone);
  const newScheduleEndTime = Moment.tz(newScheduleOrderedInterviews[newScheduleOrderedInterviews.length - 1].start_time, schedule.timezone).add(newScheduleOrderedInterviews[newScheduleOrderedInterviews.length - 1].interview_template.duration_minutes, 'minutes');

  const candidateCalendarEventTokens = useMemo(() => {
    if (oldSchedule && oldSchedule.schedule_template.candidate_calendar_event_template?.description) {
      const regexMatches = [...oldSchedule.schedule_template.candidate_calendar_event_template?.description.matchAll(TOKEN_REGEX)];
      return regexMatches.map((match) => match[1]);
    }
    return [];
  }, [oldSchedule && oldSchedule.schedule_template.candidate_calendar_event_template?.description]);

  const isCandidateEventDescriptionChanged = Boolean(
    (candidateCalendarEventTokens.some((name) => name.startsWith('Schedule.Agenda'))
      && (
        interviewsWithUpdatedLiveCodingEnabled.length ||
        interviewsWithUpdatedInterviewers.length ||
        interviewsWithUpdatedOptionalities.length ||
        interviewsWithUpdatedTimes.length ||
        removedInterviews.length ||
        newInterviews.length
      )
    ) ||
    ((candidateCalendarEventTokens.includes('Schedule.InterviewerFirstNames') ||
      candidateCalendarEventTokens.includes('Schedule.InterviewerFullNames')) &&
      interviewsWithUpdatedInterviewers.length
    ) ||
    ((candidateCalendarEventTokens.includes('Schedule.Date') ||
      candidateCalendarEventTokens.includes('Schedule.DateInCandidateTimezone') ||
      candidateCalendarEventTokens.includes('Schedule.DayOfTheWeek') ||
      candidateCalendarEventTokens.includes('Schedule.DayOfTheWeekInCandidateTimezone')) &&
      oldDate !== newDate
    ) ||
    (candidateCalendarEventTokens.includes('Schedule.RoomName') &&
      oldRoomId !== newRoomId
    ) ||
    ((candidateCalendarEventTokens.includes('Schedule.VideoConferencingLink') ||
      candidateCalendarEventTokens.includes('Schedule.VideoConferencingPasscode')) &&
      oldZoomHostId !== newZoomHostId
    )
  );

  const isCandidateEventChanged = Boolean(
    !oldScheduleStartTime.isSame(newScheduleStartTime) ||
    !oldScheduleEndTime.isSame(newScheduleEndTime) ||
    oldVideoConferencingEnabled !== newVideoConferencingEnabled ||
    oldZoomHostId !== newZoomHostId ||
    isCandidateEventDescriptionChanged
  );

  useEffect(() => {
    setUpdateScheduleOptions((prevOptions) => ({
      ...prevOptions,
      send_candidate_notifications: isCandidateEventChanged,
    }));
  }, [isCandidateEventChanged]);

  const handleUpdateScheduleOptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    setUpdateScheduleOptions((prevOptions) => ({
      ...prevOptions,
      [e.target.name]: e.target.checked,
    }));
  };

  const handleToggle = () => {
    onToggle();
  };

  const handleUpdateSchedule = async () => {
    updateScheduleMutation.reset();

    const payload = {
      ...updateScheduleOptions,
      ...constructPayload(schedule),
    };

    try {
      const newlyUpdatedSchedule = await updateScheduleMutation.mutateAsync({ id: scheduleId, payload });
      onSuccess(newlyUpdatedSchedule);
      onToggle();
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  return (
    <Modal
      cancelButtonValue="Continue editing"
      isOpen={isOpen}
      isSubmitting={updateScheduleMutation.isLoading}
      onSubmit={handleUpdateSchedule}
      onToggle={handleToggle}
      showSubmitButton={isInterviewsChanged || updateScheduleOptions.update_descriptions || updateScheduleOptions.update_names}
      submitButtonIsDisabled={interviewsMissingInterviewers.length > 0}
      submitButtonValue={isInterviewsChanged ? 'Yes, save changes' : 'Yes, update calendar events'}
      submittingButtonValue="Saving..."
      title={isInterviewsChanged ? `Update ${schedule.hold ? 'held ' : ''}schedule?` : 'Update calendar events?'}
    >
      <Flash
        message={<>There {interviewsMissingInterviewers.length === 1 ? 'is an interviewer' : 'are interviewers'} for {formatInterviewNames(interviewsMissingInterviewers)} that {interviewsMissingInterviewers.length === 1 ? 'has' : 'have'} not been selected yet. Either select someone, or remove the interviewer.</>}
        showFlash={interviewsMissingInterviewers.length > 0}
        type="danger"
      />
      <Flash
        message={newZoomHostId && newZoomHostType === 'room' && <span>The Zoom link will be hosted by the Zoom Room in <b>{rooms[newZoomHostId]?.name}</b>, but {newRoomId ? <span>the interview will take place in <b>{rooms[newRoomId]?.name}</b></span> : `we will not book the room in ${directoryCalendarLabels[account!.directory_type]}`}.</span>}
        showFlash={!updateScheduleMutation.isError && isInterviewsChanged && newZoomHostType === 'room' && newZoomHostId !== newRoomId}
        type="warning"
      />
      <Flash
        message={updateScheduleMutation.error?.message}
        showFlash={updateScheduleMutation.isError}
        type="danger"
      />
      {!isInterviewsChanged &&
        <p>
          You have not made any changes to the schedule.
        </p>
      }
      {newDate !== oldDate &&
        <p>You have moved this schedule to <b>{newDate}, {formatMoment(newScheduleStartTime, TimeFormat.Time)}&ndash;{formatMoment(newScheduleEndTime, TimeFormat.TimeWithTimezone)}</b>.</p>
      }
      {!newVideoConferencingEnabled && oldVideoConferencingEnabled &&
        <p>You have removed video conferencing from this schedule.</p>
      }
      {newVideoConferencingEnabled && !oldVideoConferencingEnabled &&
        <p>These interviews will now have a video conferencing link attached.</p>
      }
      {!newRoomId && oldRoomId &&
        <p>You have removed the room from this schedule.</p>
      }
      {newRoomId && newRoomId !== oldRoomId &&
        <p>These interviews will now take place in <b>{rooms[newRoomId]?.name}</b>.</p>
      }
      {newZoomHostId && oldZoomHostId && newZoomHostId !== oldZoomHostId &&
        <p>The Zoom meeting will now be hosted by <b>{schedule.interviews[0].zoom_host_type === 'room' ? rooms[newZoomHostId]?.name : (users[newZoomHostId]?.name || users[newZoomHostId]?.email)}</b>. We will attach the new video conferencing link to all calendar events.</p>
      }
      {!isEmpty(removedInterviews) &&
        <p>
          You have removed {formatInterviewNames(removedInterviews)}.
        </p>
      }
      {!isEmpty(newInterviews) &&
        <p>
          You have added {formatInterviewNames(newInterviews)}.
        </p>
      }
      {!isEmpty(interviewsWithUpdatedScorecard) &&
        <p>
          You have edited the scorecard link for {formatInterviewNames(interviewsWithUpdatedScorecard)}.
        </p>
      }
      {!isEmpty(interviewsWithUpdatedFeedbackForm) &&
        <p>
          You have edited the feedback form for {formatInterviewNames(interviewsWithUpdatedFeedbackForm)}.
        </p>
      }
      {!isEmpty(interviewsWithUpdatedLiveCodingEnabled) &&
        <p>
          You have edited the {account?.live_coding_type ? liveCodingLabels[account.live_coding_type] : 'live coding'} link for {formatInterviewNames(interviewsWithUpdatedLiveCodingEnabled)}.
        </p>
      }
      {!isEmpty(interviewsWithUpdatedInterviewers) &&
        <p>
          You have edited interviewers for {formatInterviewNames(interviewsWithUpdatedInterviewers)}.
        </p>
      }
      {!isEmpty(interviewsWithUpdatedOptionalities) &&
        <p>
          You have edited whether interviewers are optional for {formatInterviewNames(interviewsWithUpdatedOptionalities)}.
        </p>
      }
      {!isEmpty(interviewsWithUpdatedTrainings) &&
        <p>
          You have edited the associated training program for {formatInterviewNames(interviewsWithUpdatedTrainings)}.
        </p>
      }
      {!isEmpty(interviewsWithUpdatedTimes) &&
        <p>
          You have edited times for {formatInterviewNames(interviewsWithUpdatedTimes)}.
        </p>
      }
      {(!oldScheduleStartTime.isSame(newScheduleStartTime) || !oldScheduleEndTime.isSame(newScheduleEndTime)) && newDate === oldDate && !oldSchedule.hold &&
        <p>
          These changes will modify the candidate event to be <b>{formatMoment(newScheduleStartTime, TimeFormat.Time)}&ndash;{formatMoment(newScheduleEndTime, TimeFormat.TimeWithTimezone)}</b>.
        </p>
      }
      <div className="update-schedule-options">
        {/* TODO: Consider how to display options: An update email is not sent to the interviewers if the room is removed, but it is sent if the room is added or swapped. But it's confusing because if you choose to update the description, it will send an update email.
        */}
        {/* Microsoft 365 doesn't allow customizing whether to send email notifications or not. It will always send notifications. */}
        {account?.directory_type !== 'microsoft365' && isCandidateEventChanged && !oldSchedule.hold &&
          <CheckboxInput
            isChecked={updateScheduleOptions.send_candidate_notifications}
            label="Send an updated calendar event email to the candidate."
            name="send_candidate_notifications"
            onChange={handleUpdateScheduleOptionChange}
          />
        }
        {account?.directory_type !== 'microsoft365' && isInterviewsChanged &&
          <CheckboxInput
            isChecked={updateScheduleOptions.send_interviewer_notifications}
            label="Send updated calendar event emails to interviewers."
            name="send_interviewer_notifications"
            onChange={handleUpdateScheduleOptionChange}
          />
        }
        <CheckboxInput
          isChecked={updateScheduleOptions.update_descriptions}
          label={`Overwrite interviewer ${!oldSchedule.hold && some(candidateCalendarEventTokens, (token) => token.startsWith('Schedule.')) ? 'and candidate' : ''} calendar event descriptions with updated schedules.`}
          name="update_descriptions"
          onChange={handleUpdateScheduleOptionChange}
        />
        <CheckboxInput
          isChecked={updateScheduleOptions.update_names}
          label="Overwrite interviewer calendar event titles if they differ."
          name="update_names"
          onChange={handleUpdateScheduleOptionChange}
        />
      </div>
    </Modal>
  );
};

export default UpdateScheduleConfirmModal;
