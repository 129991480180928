import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Fragment } from 'react';
import { NavLink, useLocation } from 'react-router-dom';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { kebabCase } from 'lodash';
import { useLayoutEffect } from 'react';
import { useMediaQuery } from 'react-responsive';

interface Props {
  className?: string;
  isDisabled?: boolean;
  completedStep?: number;
  steps: {
    label: string;
    location: string;
  }[];
}

const StepProgressBar = ({ className, completedStep, isDisabled = false, steps }: Props) => {
  const location = useLocation();

  const isSmallScreen = useMediaQuery({ query: '(max-width: 600px)' });

  useLayoutEffect(() => {
    document.querySelector('.content-container')?.scrollTo(0, 0);
  }, [location.pathname]);

  return (
    <div className={classnames('step-progress-bar', className)}>
      {steps.map((step, i) => (
        <Fragment key={kebabCase(step.label)}>
          <NavLink
            className={`step${isDisabled || (completedStep !== undefined && i > completedStep) ? ' disabled' : ''}`}
            to={step.location}
          >
            <div className={classnames('step-number', completedStep !== undefined && i < completedStep && 'completed')}>
              {i + 1}
            </div>
            <div className="step-label">
              {!isSmallScreen && step.label}
              {completedStep !== undefined && i + 1 <= completedStep && <FontAwesomeIcon className="completed-check" icon={faCheck} />}
            </div>
            <div className="step-divider" />
          </NavLink>
        </Fragment>
      ))}
    </div>
  );
};

export default StepProgressBar;
