import { useMutation, useQuery, useQueryClient } from 'react-query';

import InterviewPlanner from '../../libraries/interviewplanner';

import type { Notification, NotificationType } from '../../types';
import type { UseQueryOptions } from 'react-query';

export enum QueryKey {
  ListNotifications = 'ListNotifications',
}

interface ListNotificationsQuery {
  limit?: number;
  offset?: number;
  read?: boolean;
  user?: string;
  type?: `${NotificationType}`[];
}

interface ListNotificationsData {
  notifications: Notification[];
  total: number;
}

export const useNotifications = (query: ListNotificationsQuery, options: UseQueryOptions<ListNotificationsData, Error> = {}) => {
  return useQuery<ListNotificationsData, Error>([QueryKey.ListNotifications, query], () => {
    return InterviewPlanner.request('GET', '/notifications', null, query);
  }, options);
};

interface UpdateNotificationPayload {
  read?: boolean;
}

interface UpdateNotificationMutationVariables {
  id: string;
  payload?: UpdateNotificationPayload;
}

export const useUpdateNotification = () => {
  const queryClient = useQueryClient();

  return useMutation<Notification, Error, UpdateNotificationMutationVariables>(({ id, payload }) => {
    return InterviewPlanner.request('POST', `/notifications/${id}`, payload, null, false);
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.ListNotifications]);
    },
  });
};
