import Moment from 'moment';

import { formatList } from 'libraries/formatters';
import { formatMoment, TimeFormat } from 'libraries/time';

export const formatDateOptionLabel = (isoDates: string[], isMultiBlock: boolean) => {
  let label = formatList(isoDates.map((date) => formatMoment(Moment(date), TimeFormat.LongDayOfWeekMonthDay)));
  if (isMultiBlock) {
    label = `${isoDates.length === 1 ? 'All interviews on' : 'Split between'} ${label}`;
  }
  return label;
};
