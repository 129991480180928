import { orderBy } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import CheckboxInput from '../../inputs/CheckboxInput';
import FeedbackFormSelectInput from '../../inputs/FeedbackFormSelectInput';
import LoadingSpinner from '../../utils/LoadingSpinner';
import Modal from '../../layout/Modal';
import SelectInput from '../../inputs/SelectInput';
import { ATS, liveCodingLabels } from '../../../../types';
import { useApplication } from 'hooks/queries/applications';
import { useSession } from '../../../../hooks/use-session';
import { useStageInterviews } from 'hooks/queries/stage-interviews';

import type { ChangeEvent } from 'react';
import type { Interview } from './types';
import type { RecursivePartial } from '../../../../types/helpers';

interface Props {
  canUpdateScorecard: boolean;
  interview?: Interview;
  isOpen: boolean;
  onToggle: () => void;
  onUpdateInterview: (interview: RecursivePartial<Interview>) => void;
}

const CalendarScheduleUpdateInterviewModal = ({ canUpdateScorecard = false, interview, isOpen, onToggle, onUpdateInterview }: Props) => {
  const { account } = useSession();

  const { id } = useParams<{ id: string }>();
  const { data: application } = useApplication(id, { enabled: canUpdateScorecard });
  const { data: stageInterviews, isLoading } = useStageInterviews(
    { jobs: application?.job_id ? [application?.job_id] : [] },
    { enabled: Boolean(application?.job_id) }
  );

  const [scorecardStageInterviewId, setScorecardStageInterviewId] = useState(interview?.scorecard_stage_interview_id);
  const [feedbackFormId, setFeedbackFormId] = useState(interview?.feedback_form_id);
  const [isLiveCodingEnabled, setIsLiveCodingEnabled] = useState(interview?.interview_template.live_coding_enabled);

  const stageInterviewOptions = useMemo(() => {
    if (!stageInterviews) {
      return [];
    }
    return orderBy(stageInterviews.stage_interviews, ['stage.position', 'position']).map(({ id, name, stage }) => ({
      value: id,
      label: name,
      secondaryText: stage.name,
    }));
  }, [stageInterviews]);

  useEffect(() => {
    if (interview) {
      setScorecardStageInterviewId(interview.scorecard_stage_interview_id);
      setFeedbackFormId(interview.feedback_form_id);
      setIsLiveCodingEnabled(Boolean(interview.interview_template.live_coding_enabled));
    }
  }, [interview?.id]);

  const handleScorecardStageInterviewChange = (option: any) => setScorecardStageInterviewId(option ? option.value : null);
  const handleFeedbackFormChange = (option: any) => setFeedbackFormId(option ? option.value : null);
  const handleIsLiveCodingEnabledChange = (e: ChangeEvent<HTMLInputElement>) => setIsLiveCodingEnabled(e.target.checked);

  const handleSubmit = () => {
    onUpdateInterview({
      scorecard_stage_interview_id: canUpdateScorecard ? scorecardStageInterviewId : undefined,
      feedback_form_id: account?.ats_type === ATS.Lever ? feedbackFormId : undefined,
      interview_template: {
        live_coding_enabled: Boolean(account?.live_coding_type) ? isLiveCodingEnabled : undefined,
      },
    });
    onToggle();
  };

  return (
    <Modal
      className="calendar-schedule-update-interview-modal"
      isOpen={isOpen}
      onSubmit={handleSubmit}
      onToggle={onToggle}
      submitButtonValue="Update and continue editing"
      title={`Edit ${interview?.name}?`}
    >
      {canUpdateScorecard && (isLoading ? <LoadingSpinner /> :
        <SelectInput
          helperText={<span>We will replace the previous scorecard link with a valid one for the <b>{application?.job.name}</b> role.</span>}
          label="Interview Scorecard"
          onChange={handleScorecardStageInterviewChange}
          options={stageInterviewOptions}
          placeholder="Select a new scorecard"
          value={stageInterviewOptions.find(({ value }) => value === scorecardStageInterviewId)}
        />
      )}
      {account?.ats_type === ATS.Lever && (
        <FeedbackFormSelectInput
          label="Feedback Form"
          onChange={handleFeedbackFormChange}
          selectedFeedbackFormId={feedbackFormId}
        />
      )}
      {account?.live_coding_type && (
        <CheckboxInput
          isChecked={isLiveCodingEnabled}
          label={`Include a ${liveCodingLabels[account.live_coding_type]} link.`}
          onChange={handleIsLiveCodingEnabledChange}
        />
      )}
    </Modal>
  );
};

export default CalendarScheduleUpdateInterviewModal;
