import { Link } from 'react-router-dom';
import { find, isEmpty, orderBy, values } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import Avatar from '../../library/data-display/Avatar';
import Button from '../../library/inputs/Button';
import DurationInput from '../../library/inputs/DurationInput';
import Flash from '../../library/utils/Flash';
import Label from '../../library/utils/Label';
import LoadingSpinner from '../../library/utils/LoadingSpinner';
import OutboundLink from '../../library/navigation/OutboundLink';
import Section from '../../library/layout/Section';
import SelectInput from '../../library/inputs/SelectInput';
import Table from '../../library/data-display/Table';
import TextInput from '../../library/inputs/TextInput';
import useSyncStateWithQuery from '../../../hooks/use-sync-state-with-query';
import { Directory, VideoConferencingTool, videoConferencingLabels } from '../../../types';
import { signUpParams } from '../../../hooks/queries/auth';
import { useAccount, useUpdateAccount } from '../../../hooks/queries/accounts';
import { useCalendars } from '../../../hooks/queries/calendars';
import { useQueryClient } from 'react-query';
import { useSession } from '../../../hooks/use-session';
import { useUsersMap } from '../../../hooks/queries/users';

import type { ChangeEvent } from 'react';
import type { ErrorLike } from './types';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../library/inputs/SelectInput/types';
import type { TableSchema } from '../../library/data-display/Table';
import type { UpdateAccountPayload } from '../../../hooks/queries/accounts';
import type { User } from '../../../types';

const ERROR_CODES: Record<string, JSX.Element> = {
  // this happens when the user goes to /api/auth/zoom/redirect directly
  no_code: <span>There was an error when redirecting you. Try connecting again, and if it continues to happen, <Link to="/contact">let us know</Link>.</span>,
};

const defaultVideoConferencingOptions: Option<`${VideoConferencingTool}`>[] = [
  {
    value: VideoConferencingTool.GoogleMeet,
    label: videoConferencingLabels[VideoConferencingTool.GoogleMeet],
    // Meet starts out disabled and only gets enabled if the directory type is
    // Google and their scheduling calendar supports it.
    isDisabled: true,
  },
  {
    value: VideoConferencingTool.MicrosoftTeams,
    label: videoConferencingLabels[VideoConferencingTool.MicrosoftTeams],
    // Teams starts out disabled and only gets enabled if the directory type is
    // Microsoft365.
    isDisabled: true,
  },
  {
    value: VideoConferencingTool.Zoom,
    label: videoConferencingLabels[VideoConferencingTool.Zoom],
  },
];

const videoConferencingHelpLinks: Record<VideoConferencingTool, string> = {
  [VideoConferencingTool.GoogleMeet]: 'https://support.gem.com/hc/en-us/articles/23491310176151-How-do-I-set-up-Google-Meet',
  [VideoConferencingTool.MicrosoftTeams]: 'https://support.gem.com/hc/en-us/articles/24460911177623-How-do-I-set-up-Microsoft-Teams-for-video-conferencing',
  [VideoConferencingTool.Zoom]: 'https://support.gem.com/hc/en-us/articles/23489941223447-How-do-I-connect-my-Zoom-account',
};

const IntegrationsVideoConferencingSection = () => {
  const queryClient = useQueryClient();

  const { currentUser } = useSession();
  const { data } = useAccount();
  const account = data!;
  const {
    data: calendars,
    isLoading: isCalendarsLoading,
  } = useCalendars();
  const users = useUsersMap({ archived: true });

  const connectedUsers = useMemo(() => orderBy(Object.values(users).filter(({ connected_video_conferencing, directory_archived, user_archived }) => !user_archived && !directory_archived && connected_video_conferencing), [({ id }) => id !== currentUser!.id, 'email']), [users]);

  const [, queryError] = useSyncStateWithQuery<string>('error', '');
  const [, querySuccess] = useSyncStateWithQuery<string>('success', '');

  const [isEditing, setIsEditing] = useState(false);
  const [error, setError] = useState<ErrorLike | null>(ERROR_CODES[queryError] ? { message: ERROR_CODES[queryError] } : null);
  const [isFetching, setIsFetching] = useState(false);
  const [videoConferencingType, setVideoConferencingType] = useState<`${VideoConferencingTool}` | ''>(account.video_conferencing_type || '');
  const [videoConferencingOptions, setVideoConferencingOptions] = useState([...defaultVideoConferencingOptions]);
  const [zoomHostConcurrentMeetingLimit, setZoomHostConcurrentMeetingLimit] = useState<number>(account.zoom_host_concurrent_meeting_limit);
  const [zoomMeetingBufferMinutes, setZoomMeetingBufferMinutes] = useState<number>(account.zoom_meeting_buffer_minutes);

  const updateAccountMutation = useUpdateAccount();

  useEffect(() => {
    setVideoConferencingType(account.video_conferencing_type || '');
  }, [account.video_conferencing_type]);

  useEffect(() => {
    let options = [...defaultVideoConferencingOptions];

    if ((account.directory_type === Directory.Google || account.directory_type === Directory.Microsoft) && calendars?.calendars && !isEmpty(calendars.calendars)) {
      // The account is a Google or Microsoft account, so we need to find out if it can use Google Meet or Microsoft
      // Teams, respectively.
      const allowed: { [key: string]: boolean } = {};

      if (account.scheduling_calendar_email && calendars.calendars[account.scheduling_calendar_email]?.allowed_video_conferencing_types) {
        // They have a scheduling calendar set, so we need to check what's allowed on that calendar.
        calendars.calendars[account.scheduling_calendar_email].allowed_video_conferencing_types.forEach((type) => {
          allowed[type] = true;
        });
      } else {
        // They don't have a scheduling calendar set, so we'll take the union of all the possible scheduling calendars.
        // As far as I know, it's not possible to enable a video conferencing type on a specific calendar in Google or
        // Microsoft. So if any calendar has it enabled, they all should. This is unconfirmed though and may cause
        // issues for some companies.
        values(calendars.calendars).forEach((calendar) => {
          if (calendar.allowed_video_conferencing_types) {
            calendar.allowed_video_conferencing_types.forEach((type) => {
              allowed[type] = true;
            });
          }
        });
      }

      if (!isEmpty(allowed)) {
        options = options.map((option) => allowed[option.value] ? { ...option, isDisabled: false } : option);
      }
    }

    setVideoConferencingOptions(options);
  }, [account, calendars?.calendars]);

  const handleVideoConferencingTypeChange = (option: OnChangeValue<Option<`${VideoConferencingTool}`>, false>) => setVideoConferencingType(option?.value || '');
  const handleZoomHostConcurrentMeetingLimitChange = (e: ChangeEvent<HTMLInputElement>) => setZoomHostConcurrentMeetingLimit(parseInt(e.target.value, 10));
  const handleZoomMeetingBufferMinutesChange = (minutes: number) => setZoomMeetingBufferMinutes(minutes);

  const handleConnectVideoConferencingAccount = async (vcType: `${VideoConferencingTool.Zoom}`) => {
    setIsFetching(true);

    try {
      const { redirect_url } = await queryClient.fetchQuery(signUpParams(vcType));
      window.location.href = redirect_url;
    } catch (err) {
      if (err instanceof Error) {
        setError(err);
      }
      setIsFetching(false);
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setVideoConferencingType(account.video_conferencing_type || '');
    setZoomHostConcurrentMeetingLimit(account.zoom_host_concurrent_meeting_limit);
    setZoomMeetingBufferMinutes(account.zoom_meeting_buffer_minutes);
    setIsEditing(false);
    setError(null);
    updateAccountMutation.reset();
  };

  const handleSave = async () => {
    updateAccountMutation.reset();

    try {
      const payload: UpdateAccountPayload = {};
      if (videoConferencingType !== 'zoom') {
        payload.video_conferencing_type = videoConferencingType || undefined;
      }
      if (zoomHostConcurrentMeetingLimit !== account.zoom_host_concurrent_meeting_limit) {
        payload.zoom_host_concurrent_meeting_limit = zoomHostConcurrentMeetingLimit;
      }
      if (zoomMeetingBufferMinutes !== account.zoom_meeting_buffer_minutes) {
        payload.zoom_meeting_buffer_minutes = zoomMeetingBufferMinutes;
      }
      await updateAccountMutation.mutateAsync({
        id: account.id,
        payload,
      });
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  const columns = useMemo<TableSchema<User>>(() => [{
    header: 'Name',
    displayValue: ({ email, id, name }) => (
      <div className="video-conferencing-hosts-table-name">
        <Avatar
          showUserTooltip={false}
          size="small"
          userId={id}
        />
        {name || email}
        {id === currentUser!.id && <span>&nbsp;<b>(You)</b></span>}
      </div>
    ),
  }, {
    header: 'Email',
    displayValue: ({ email }) => email,
  }, {
    header: 'Connected?',
    displayValue: ({ valid_zoom_token }) => <Label color={valid_zoom_token ? 'green' : 'red'}>{valid_zoom_token ? 'Connected' : 'Disconnected'}</Label>,
  }], [currentUser!.id]);

  const hasVideoConferencingIntegration = Boolean(account.video_conferencing_type);
  const isSaving = isFetching || updateAccountMutation.isLoading;
  const isSuccess = updateAccountMutation.isSuccess || Boolean(querySuccess);

  return (
    <Section
      isEditable
      isEditing={isEditing}
      isSaving={isSaving}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      showSaveButton={videoConferencingType !== 'zoom' || zoomHostConcurrentMeetingLimit !== account.zoom_host_concurrent_meeting_limit || zoomMeetingBufferMinutes !== account.zoom_meeting_buffer_minutes}
      title="Video conferencing"
    >
      <div className="integrations-video-conferencing-form">
        <Flash
          message="Connect your video conferencing tool to add links to remote interviews."
          showFlash={!Boolean(error) && !hasVideoConferencingIntegration}
          type="info"
        />
        <Flash
          message="Switching your video conferencing tool will prevent you from changing the video conferencing settings of existing schedules that have video conferencing enabled."
          showFlash={hasVideoConferencingIntegration && videoConferencingType !== account.video_conferencing_type}
          type="warning"
        />
        <Flash
          isDismissible
          message="Successfully updated!"
          showFlash={isSuccess}
          type="success"
        />
        <Flash
          message={error?.message}
          showFlash={Boolean(error)}
          type="danger"
        />
        <Flash
          message={updateAccountMutation.error?.message}
          showFlash={updateAccountMutation.isError}
          type="danger"
        />
        <div className="form-container">
          <SelectInput
            className="input-video-conferencing-type"
            helperText={isEditing && videoConferencingType ?
              <OutboundLink
                href={videoConferencingHelpLinks[videoConferencingType]}
                label={`${videoConferencingLabels[videoConferencingType]} Video Conferencing Helper Text`}
              >
                Learn more about the {videoConferencingLabels[videoConferencingType]} integration.
              </OutboundLink> :
              null
            }
            isDisabled={!isEditing || isFetching || isCalendarsLoading}
            label="Video Conferencing Tool"
            onChange={handleVideoConferencingTypeChange}
            options={videoConferencingOptions}
            placeholder="Select video conferencing tool..."
            value={find(videoConferencingOptions, ['value', videoConferencingType])}
          />
          {isEditing && videoConferencingType === 'zoom' &&
            <Button
              color="gem-blue"
              iconRight={isFetching || isCalendarsLoading ? <LoadingSpinner /> : undefined}
              isDisabled={isFetching || isCalendarsLoading}
              onClick={() => handleConnectVideoConferencingAccount(videoConferencingType)}
              value={isFetching || isCalendarsLoading ? 'Connecting...' : `${hasVideoConferencingIntegration && account.video_conferencing_type === 'zoom' ? 'Re-' : ''}Connect your account`}
            />
          }
        </div>
        {hasVideoConferencingIntegration && account.video_conferencing_type === 'zoom' && videoConferencingType === 'zoom' && (
          <TextInput
            className="input-zoom-host-concurrent-meetings"
            helperText={(
              <span>
                Zoom restricts the number of meetings that a user can host at the same time.&nbsp;
                <OutboundLink
                  href="https://support.zoom.us/hc/en-us/articles/206122046-Can-I-host-concurrent-meetings-"
                  label="Zoom host concurrent meeting limit link"
                >
                  Learn more.
                </OutboundLink>
              </span>
            )}
            isDisabled={!isEditing || isFetching || isCalendarsLoading}
            label="Zoom Concurrent Hosting Limit"
            onChange={handleZoomHostConcurrentMeetingLimitChange}
            type="number"
            value={zoomHostConcurrentMeetingLimit}
          />
        )}
        {hasVideoConferencingIntegration && account.video_conferencing_type === 'zoom' && videoConferencingType === 'zoom' && (
          <DurationInput
            additionalText="before and after meetings"
            className="input-zoom-meeting-buffer"
            helperText="Use this if you use the same Zoom host for many interviews so that meetings can start early or run a little late."
            isDisabled={!isEditing || isFetching || isCalendarsLoading}
            label="Zoom Meeting Buffer"
            maxMinutes={300}
            minMinutes={0}
            onChange={handleZoomMeetingBufferMinutesChange}
            value={zoomMeetingBufferMinutes}
          />
        )}
        {hasVideoConferencingIntegration && account.video_conferencing_type === 'zoom' && videoConferencingType === 'zoom' &&
          <Table
            data={connectedUsers}
            dataDescriptor="connected team members"
            layout="vertical"
            pageSize={0}
            schema={columns}
            showResultsCount
            totalCount={connectedUsers.length}
          />
        }
      </div>
    </Section>
  );
};

export default IntegrationsVideoConferencingSection;
