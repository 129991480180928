import { isEmpty, isEqual, orderBy, uniq } from 'lodash';
import Moment from 'moment-timezone';
import { useEffect, useMemo, useState } from 'react';

import ListItem from 'components/library/data-display/ListItem';
import SelectInput from 'components/library/inputs/SelectInput';
import { formatDateOptionLabel } from './helpers';
import { StyledContainer } from './styles';

import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from 'components/library/inputs/SelectInput/types';
import type { ScheduleOption, ScheduleOptionFilter } from '../../../types';

// comma-separated list of ISO dates
type DatesPerBlock = string;
// Examples:
//
// 2023-01-01
// 1-block schedule on 1/1/23
//
// 2023-01-01,2023-01-01
// 2-block schedule where both blocks are on 1/1/23
//
// 2023-01-01,2023-01-02
// 2-block schedule where the first block is on 1/1/23 and the second block is on 1/2/23

interface Props {
  numberOfBlocks: number;
  schedules: ScheduleOption[];
  setFilters: (filters: ScheduleOptionFilter[]) => void;
  timezone: string;
}

const ScheduleOptionsViewerFilters = ({ numberOfBlocks, schedules, setFilters, timezone }: Props) => {
  const [selectedDates, setSelectedDates] = useState<DatesPerBlock[]>([]);

  useEffect(() => {
    setSelectedDates([]);
  }, [numberOfBlocks]);

  const dateOptions = useMemo<Option<DatesPerBlock>[]>(() => {
    const dateCounts: {
      [key: string]: {
        count: number;
        highestRankedOption: ScheduleOption; // TODO: Use when calculating how many conflicts are in the best option for the day
      };
    } = {};
    schedules.forEach((schedule) => {
      const isoDatesPerBlock = uniq(schedule.blocks.map(({ interviews }) => Moment.tz(interviews[0].start_time, timezone).format('YYYY-MM-DD'))).join(',');
      if (!dateCounts[isoDatesPerBlock]) {
        dateCounts[isoDatesPerBlock] = {
          count: 0,
          highestRankedOption: schedule,
        };
      }
      dateCounts[isoDatesPerBlock].count += 1;
    });
    const options = Object.entries(dateCounts).map(([isoDatesPerBlock, { count }]) => ({
      value: isoDatesPerBlock,
      label: formatDateOptionLabel(isoDatesPerBlock?.split(',') || [], numberOfBlocks > 1),
      secondaryText: `${count} options`, // TODO: Add `, best option has n conflicts`
    }));
    return orderBy(options, 'value');
  }, [schedules, numberOfBlocks]);

  const handleDatesChange = (options: OnChangeValue<Option<DatesPerBlock>, true>) => {
    setSelectedDates(options?.length > 0 ? options.map((date) => date.value) : []);
  };

  useEffect(() => {
    const dateFilter = (schedule: ScheduleOption) => {
      if (isEmpty(selectedDates)) {
        return true;
      }
      return selectedDates.some((datesPerBlock: DatesPerBlock) => {
        const datesPerBlockForScheduleOption = uniq(schedule.blocks.map(({ interviews }) => Moment.tz(interviews[0].start_time, timezone).format('YYYY-MM-DD'))).join(',');
        return isEqual(datesPerBlock, datesPerBlockForScheduleOption);
      });
    };
    setFilters([dateFilter]);
  }, [selectedDates]);

  return (
    <StyledContainer>
      <SelectInput
        formatOptionLabel={(option, { context }) => (
          context === 'menu' ?
            <ListItem
              label={option.label}
              secondaryText={option.secondaryText}
            /> :
            option.label
        )}
        isClearable
        isMulti
        label="Filter by date"
        maxMenuHeight={300}
        onChange={handleDatesChange}
        options={dateOptions}
        placeholder="All available dates"
        value={dateOptions.filter((option) => selectedDates.includes(option.value))}
      />
    </StyledContainer>
  );
};

export default ScheduleOptionsViewerFilters;
