import Moment from 'moment';
import { useParams } from 'react-router-dom';

import EmailPreview from '../../../../../library/data-display/EmailPreview';
import Flash from '../../../../../library/utils/Flash';
import Section from '../../../../../library/layout/Section';
import { useAvailability } from '../../../../../../hooks/queries/availabilities';

const EmailSection = () => {
  const { availabilityId } = useParams<{ id: string; availabilityId: string }>();

  const { data: availability } = useAvailability(availabilityId);

  const email = availability?.availability_template?.availability_request_email_template?.emails?.[0];

  const wasCreatedOverAnHourAgo = email && Moment(email.created_at).isBefore(Moment().subtract(1, 'hours'));

  return (
    <Section className="email-section" title="Email">
      <Flash
        message="This email failed to send. Please confirm the candidate's email address and re-send an email containing the availability link outside InterviewPlanner."
        showFlash={Boolean(email && email.status === 'failed')}
        type="danger"
      />
      <Flash
        message="This email was never sent. Please contact support."
        showFlash={Boolean(email && !email.sent_at && wasCreatedOverAnHourAgo)}
        type="warning"
      />
      {email ? (
        <EmailPreview
          attachments={availability?.availability_template?.availability_request_email_template?.attachments}
          bccEmails={email.bcc_emails}
          body={email.body}
          ccEmails={email.cc_emails}
          senderEmail={email.sender_email}
          senderName={email.sender_name}
          sentAt={email.sent_at}
          subject={email.subject}
          to={email.recipient_email}
        />
      ) : <span className="no-email">The availability request email was not sent through InterviewPlanner.</span>}
    </Section>
  );
};

export default EmailSection;
