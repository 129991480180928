import type { Assignment } from './assignments';
import type { Availability } from './availabilities';
import type { AvailabilityStatus } from './availabilities';
import type { AvailabilityTemplate } from './availability-templates';
import type { Candidate } from './candidates';
import type { HiringMeetingStatus } from './hiring-meetings';
import type { Interview } from './interviews';
import type { Job, Stage } from './jobs';
import type { Office } from './offices';
import type { RSVP } from './events';
import type { ScheduleStatus } from './schedules';
import type { ScheduleTemplate } from './schedule-templates';
import type { User } from './users';
import type { SelfSchedulingLinkStatus } from './self-scheduling-links';
import type { ZoomHostType } from './zoom';

export enum ApplicationSchedulingStatus {
  AvailabilityRequested = 'availability_requested',
  Cancelled = 'cancelled',
  Inactive = 'inactive',
  ReadyToRequestAvailability = 'ready_to_request_availability',
  ReadyToSchedule = 'ready_to_schedule',
  Scheduled = 'scheduled',
  SchedulingLinkSent = 'scheduling_link_sent',
  Unschedulable = 'unschedulable',
}

export enum ApplicationStatus {
  Active = 'active',
  Hired = 'hired',
  Rejected = 'rejected',
}

export interface Application {
  id: string;
  ats_id: string;
  account_id: string;
  candidate_id: string;
  candidate: Candidate;
  job_id: string;
  job: Job;
  current_stage_id?: string;
  current_stage?: Stage;
  office_id?: string;
  office?: Office;
  status: ApplicationStatus;
  scheduling_status: ApplicationSchedulingStatus;
  coordinator_id?: string;
  coordinator?: User;
  hiring_manager_id?: string;
  hiring_manager?: User;
  recruiter_id?: string;
  recruiter?: User;
  sourcer_id?: string;
  sourcer?: User;
  active_assignments: Assignment[] | null;
  active_availabilities: ApplicationAvailability[] | null;
  all_availabilities: ApplicationAvailability[] | null;
  all_hiring_meetings: ApplicationHiringMeeting[] | null;
  all_self_scheduling_links: ApplicationSelfSchedulingLink[] | null;
  active_schedules: ApplicationSchedule[] | null;
  held_schedules: ApplicationSchedule[] | null;
  all_schedules?: ApplicationSchedule[];
  emails?: Email[];
  hiring_channel_id: string | null;
  hiring_channel_archived: boolean | null;
  favorite: boolean;
  created_at: string;
  updated_at: string;
  ats_created_at: string;
  ats_updated_at: string;
  archived: boolean;
}

export interface ApplicationAvailability {
  id: string;
  application_id?: string;
  stage_id: string;
  stage: Stage;
  availability_template_id?: string;
  availability_template?: AvailabilityTemplate;
  status: AvailabilityStatus;
  ats_id?: string;
  manual: boolean;
  availability_time_slots: ApplicationAvailabilityTimeSlot[] | null;
  creator_id?: string;
  automatic_send_log_id?: string;
  created_at: string;
  updated_at: string;
}

export interface ApplicationHiringMeeting {
  id: string;
  application_id?: string;
  hiring_meeting_template_id?: string;
  stage_id: string;
  stage: Stage;
  creator_id?: string;
  status: HiringMeetingStatus;
  scheduling_calendar_email: string;
  timezone: string;
  start_time: string;
  room_id?: string;
  room_rsvp?: RSVP;
  video_conferencing_meeting_id?: string;
  video_conferencing_passcode?: string;
  zoom_host_id?: string;
  zoom_host_type?: ZoomHostType;
  created_at: string;
  updated_at: string;
}

export interface ApplicationSelfSchedulingLink {
  id: string;
  application_id?: string;
  stage_id: string;
  stage: Stage;
  stage_interview_id: string;
  status: SelfSchedulingLinkStatus;
  creator_id?: string;
  automatic_send_log_id?: string;
  created_at: string;
  updated_at: string;
}

export interface ApplicationSchedule {
  id: string;
  ats_id?: string;
  imported_from_ats: boolean;
  application_id?: string;
  block_id?: string;
  stage_id: string;
  stage: Stage;
  schedule_template_id: string;
  schedule_template: ScheduleTemplate;
  hold: boolean;
  status: ScheduleStatus;
  timezone: string;
  candidate_timezone?: string;
  scheduling_calendar_email: string;
  candidate_scheduling_calendar_email: string;
  candidate_event_title: string;
  candidate_event_description: string;
  candidate_event_location?: string;
  candidate_rsvp: RSVP;
  interviews: Interview[];
  creator_id: string;
  created_at: string;
  updated_at: string;
}

interface ApplicationAvailabilityTimeSlot {
  id: string;
  account_id: string;
  availability_id: string;
  availability: Availability;
  ats_id?: string;
  start_time: string;
  end_time: string;
  timezone: string;
  created_at: string;
  updated_at: string;
}

interface Email {
  id: string;
  application_id?: string;
}
