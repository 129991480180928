import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

import Card from 'components/library/layout/Card';

export const StyledDivider = styled.hr`
  border: .5px solid ${({ theme }) => theme.colors.mercuryGray};
  border-right-width: 0;
  border-left-width: 0;
  width: 100%;
  max-width: 1100px;
`;

export const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 1100px;
  width: 100%;
`;

export const StyledComparisonsMenu = styled.div`
  display: flex;
  flex-direction: column;
  gap: .5em;
  height: max-content;

  a {
    text-decoration: none;
    flex-grow: 1;
    padding: 0;

    &:hover {
      text-decoration: none;
    }

    &.active > .card {
      border-color: ${({ theme }) => theme.colors.curiousBlue};

      & .card-content {
        color: ${({ theme }) => theme.colors.mineshaftBlack};
      }
    }
  }

  @media only screen and (max-width: 930px) {
    flex-direction: row;
  }
`;

export const StyledComparisonCard = styled(Card)`
  width: 200px;

  &:hover {
    background: ${({ theme }) => theme.colors.aliceBlue};
    border-color: ${({ theme }) => theme.colors.curiousBlue};
  }

  & .card-content {
    align-items: center;
    display: flex;
    font-weight: normal;
    gap: 1em;
  }

  img {
    border-radius: 50%;
    width: 40px;
  }

  @media only screen and (max-width: 930px) {
    width: auto;
  }

  @media only screen and (max-width: 700px) {
    & .card-content {
      justify-content: center;
    }
  }
`;

export const StyledComparisonsContainer = styled.div`
  display: flex;
  margin: 1em;
  margin-bottom: 50px;
  width: 95%;

  @media only screen and (max-width: 930px) {
    flex-direction: column;
  }
`;

export const StyledComparisonContainer = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.mercuryGray};
  border-radius: 4px;
  height: max-content;
  flex-grow: 1;
  margin-left: 1em;
  padding: 1em 0;

  p {
    line-height: 1.8;

    &:first-child {
      margin-top: 0;
    }
  }

  @media only screen and (max-width: 930px) {
    margin-left: 0;
    margin-top: 1em;
  }
`;

export const StyledLockIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.mercuryGray};
  height: 60px;
  margin-bottom: 2em;
  margin-top: 25vh;
`;

export const StyledForm = styled.form`
  max-width: 90%;
  min-height: calc(100vh - 100px);
  text-align: center;
  width: 450px;

  .flash {
    margin-bottom: 1em;
  }
`;

export const StyledTitleSection = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 60px;
  text-align: center;
  width: 100%;

  h1 {
    margin: 15px;
    max-width: 90%;
  }

  p {
    color: ${({ theme }) => theme.colors.doveGray};
    line-height: 2;
    max-width: 90%;
  }

  a {
    cursor: pointer;
    text-decoration: underline;
  }

  @media only screen and (max-width: 930px) {
    padding-top: 20px;
  }
`;
