/**
 * This is a function that removes an element from an array at a specific index without mutation the array and without
 * iterating over it.
 */
export function deleteAtIndex<T> (arr: T[], index: number): T[] {
  return [
    ...arr.slice(0, index),
    ...arr.slice(index + 1),
  ];
}
