import { Fragment } from 'react';
import { isEmpty, toPairs } from 'lodash';

export enum DiffType {
  Eligibility = 'eligibility',
  Tag = 'tag',
}

export enum DiffAction {
  Added = 'added',
  Removed = 'removed',
}

export interface Diff {
  type: `${DiffType}`;
  action: `${DiffAction}`;
  value: {
    id: string;
    // Only for eligibilities.
    trainee?: boolean;
    training_program_id?: string;
  };
  items: Record<string, string>;
}

export type EditDiff = Record<string, Diff>;

interface Props {
  editDiff: EditDiff;
}

const TagEditDiffMessage = ({ editDiff }: Props) => {
  if (isEmpty(editDiff)) {
    return null;
  }

  return (
    <>
      The following changes will be applied when you save:
      <ul>
        {Object.values(editDiff).map((diff) => (
          <Fragment key={`${diff.type}:${diff.action}:${diff.value.id}:${diff.value.trainee}`}>
            {diff.value.trainee ? (
              <li>You&apos;ve <b>{diff.action}</b> the following people {diff.action === DiffAction.Removed ? 'from' : 'to'} the <b>{diff.value.id}</b> training program:</li>
            ) : (
              <li>You&apos;ve <b>{diff.action}</b> the {diff.type} <b>{diff.value.id}</b> {diff.action === DiffAction.Removed ? 'from' : 'to'}:</li>
            )}
            <ul>
              {toPairs(diff.items).map(([id, name]) => <li key={id}>{name}</li>)}
            </ul>
          </Fragment>
        ))}
      </ul>
    </>
  );
};

export default TagEditDiffMessage;
