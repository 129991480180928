import styled from 'styled-components';

import Modal from 'components/library/layout/Modal';

export const StyledModal = styled(Modal)`
  .modal-body {
    overflow: visible !important;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;

  .user-select-input-container {
    width: auto;
  }
`;
