import type { TrainingOverride, TrainingPhase, TrainingSession } from '../../../../../types';

export enum PendingChangeType {
  TrainingOverrideCreate = 'TrainingOverrideCreate',
  TrainingOverrideDelete = 'TrainingOverrideDelete',
  TrainingOverrideUpdate = 'TrainingOverrideUpdate',
  TrainingProgramUserCreate = 'TrainingProgramUserCreate',
  TrainingProgramUserDelete = 'TrainingProgramUserDelete',
  TrainingProgramUserGraduate = 'TrainingProgramUserGraduate',
  TrainingProgramUserUngraduate = 'TrainingProgramUserUngraduate',
  TrainingSessionCreate = 'TrainingSessionCreate',
  TrainingSessionDelete = 'TrainingSessionDelete',
  TrainingSessionIgnore = 'TrainingSessionIgnore',
  TrainingSessionUnignore = 'TrainingSessionUnignore',
}

export function isOverrideType (type: PendingChangeType): boolean {
  return type === PendingChangeType.TrainingOverrideCreate ||
    type === PendingChangeType.TrainingOverrideDelete ||
    type === PendingChangeType.TrainingOverrideUpdate;
}

export function isUserType (type: PendingChangeType): boolean {
  return type === PendingChangeType.TrainingProgramUserCreate ||
    type === PendingChangeType.TrainingProgramUserDelete ||
    type === PendingChangeType.TrainingProgramUserGraduate ||
    type === PendingChangeType.TrainingProgramUserUngraduate;
}

export function isSessionType (type: PendingChangeType): boolean {
  return type === PendingChangeType.TrainingSessionCreate ||
    type === PendingChangeType.TrainingSessionDelete ||
    type === PendingChangeType.TrainingSessionIgnore ||
    type === PendingChangeType.TrainingSessionUnignore;
}

// We use this instead of TrainingProgramUser since the training program user doesn't exist for newly added users, so we
// can't assume all changes have a fully created user yet.
export interface PendingChangeUser {
  id?: string;
  name?: string;
  trainee: boolean;
  training_sessions: TrainingSession[] | null;
  training_overrides: TrainingOverride[] | null;
  isNew?: boolean;
  // This is just to be able to uniquely identify new users if there happens to be more than one.
  uniqueId?: string;
}

export interface PendingChangeTrainingOverrideCreate {
  type: PendingChangeType.TrainingOverrideCreate;
  user: PendingChangeUser;
  phase: TrainingPhase;
  override: {
    id: string;
  };
  value: number;
}

export interface PendingChangeTrainingOverrideDelete {
  type: PendingChangeType.TrainingOverrideDelete;
  user: PendingChangeUser;
  phase: TrainingPhase;
  override: TrainingOverride;
}

export interface PendingChangeTrainingOverrideUpdate {
  type: PendingChangeType.TrainingOverrideUpdate;
  user: PendingChangeUser;
  phase: TrainingPhase;
  override: TrainingOverride;
  value: number;
}

export interface PendingChangeTrainingProgramUserCreate {
  type: PendingChangeType.TrainingProgramUserCreate;
  user: PendingChangeUser;
}

export interface PendingChangeTrainingProgramUserDelete {
  type: PendingChangeType.TrainingProgramUserDelete;
  user: PendingChangeUser;
}

export interface PendingChangeTrainingProgramUserGraduate {
  type: PendingChangeType.TrainingProgramUserGraduate;
  user: PendingChangeUser;
}

export interface PendingChangeTrainingProgramUserUngraduate {
  type: PendingChangeType.TrainingProgramUserUngraduate;
  user: PendingChangeUser;
}

export interface PendingChangeTrainingSessionCreate {
  type: PendingChangeType.TrainingSessionCreate;
  user: PendingChangeUser;
  sessions: {
    id: string;
    phase: TrainingPhase;
  }[];
}

export interface PendingChangeTrainingSessionDelete {
  type: PendingChangeType.TrainingSessionDelete;
  user: PendingChangeUser;
  sessions: TrainingSession[];
}

export interface PendingChangeTrainingSessionIgnore {
  type: PendingChangeType.TrainingSessionIgnore;
  user: PendingChangeUser;
  sessions: TrainingSession[];
}

export interface PendingChangeTrainingSessionUnignore {
  type: PendingChangeType.TrainingSessionUnignore;
  user: PendingChangeUser;
  sessions: TrainingSession[];
}

export type PendingChange =
  | PendingChangeTrainingOverrideCreate
  | PendingChangeTrainingOverrideDelete
  | PendingChangeTrainingOverrideUpdate
  | PendingChangeTrainingProgramUserCreate
  | PendingChangeTrainingProgramUserDelete
  | PendingChangeTrainingProgramUserGraduate
  | PendingChangeTrainingProgramUserUngraduate
  | PendingChangeTrainingSessionCreate
  | PendingChangeTrainingSessionDelete
  | PendingChangeTrainingSessionIgnore
  | PendingChangeTrainingSessionUnignore
  ;
