import { useMutation, useQuery, useQueryClient } from 'react-query';

import InterviewPlanner from '../../libraries/interviewplanner';

import type { TrainingSession } from '../../types';
import type { UseQueryOptions } from 'react-query';

export enum QueryKey {
  ListTrainingSessions = 'ListTrainingSessions',
}

interface ListTrainingSessionsQuery {
  limit?: number;
  offset?: number;
  user_id?: string;
  manual?: boolean;
  status?: ('completed' | 'upcoming')[];
}

interface ListTrainingSessionsData {
  training_sessions: TrainingSession[];
  total: number;
}

export const useTrainingSessions = (trainingProgramId: string, query: ListTrainingSessionsQuery = {}, options: UseQueryOptions<ListTrainingSessionsData, Error> = {}) => {
  return useQuery<ListTrainingSessionsData, Error>([QueryKey.ListTrainingSessions, trainingProgramId, query], () => {
    return InterviewPlanner.request<ListTrainingSessionsData>('GET', `/training-programs/${trainingProgramId}/sessions`, null, query);
  }, options);
};

export interface CreateTrainingSessionPayload {
  user_id: string;
  training_phase_id: string;
}

interface CreateTrainingSessionMutationVariables {
  trainingProgramId: string;
  payload: CreateTrainingSessionPayload;
}

export const useCreateTrainingSession = () => {
  const queryClient = useQueryClient();

  return useMutation<TrainingSession, Error, CreateTrainingSessionMutationVariables>(({ trainingProgramId, payload }) => {
    return InterviewPlanner.request('POST', `/training-programs/${trainingProgramId}/sessions`, payload, null, false);
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.ListTrainingSessions]);
    },
  });
};

export interface UpdateTrainingSessionPayload {
  ignored?: boolean;
  training_phase_id?: string;
}

interface UpdateTrainingSessionMutationVariables {
  trainingProgramId: string;
  id: string;
  payload: UpdateTrainingSessionPayload;
}

export const useUpdateTrainingSession = () => {
  const queryClient = useQueryClient();

  return useMutation<TrainingSession, Error, UpdateTrainingSessionMutationVariables>(({ trainingProgramId, id, payload }) => {
    return InterviewPlanner.request('POST', `/training-programs/${trainingProgramId}/sessions/${id}`, payload, null, false);
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.ListTrainingSessions]);
    },
  });
};

interface DeleteTrainingSessionMutationVariables {
  trainingProgramId: string;
  id: string;
}

export const useDeleteTrainingSession = () => {
  const queryClient = useQueryClient();

  return useMutation<TrainingSession, Error, DeleteTrainingSessionMutationVariables>(({ trainingProgramId, id }) => {
    return InterviewPlanner.request('DELETE', `/training-programs/${trainingProgramId}/sessions/${id}`);
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.ListTrainingSessions]);
    },
  });
};
