import { find } from 'lodash';
import { useEffect, useState } from 'react';

import ConflictKeywordsInput from '../../library/inputs/ConflictKeywordsInput';
import Flash from '../../library/utils/Flash';
import OutOfOfficeKeywordsInput from '../../library/inputs/OutOfOfficeKeywordsInput';
import Section from '../../library/layout/Section';
import SelectInput from '../../library/inputs/SelectInput';
import { useAccount, useUpdateAccount } from '../../../hooks/queries/accounts';

import type { EditableIgnoreWord } from '../../../types';
import type { Option } from '../../library/inputs/SelectInput/types';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';

const ignoreFreeEventsOptions: Option<boolean>[] = [{
  label: 'Count as conflicts',
  value: false,
}, {
  label: 'Don\'t count as conflicts',
  value: true,
}];

const CompanyConflictSettingsSection = () => {
  const { data: account } = useAccount();

  const [isEditing, setIsEditing] = useState(false);

  const [conflictKeywords, setConflictKeywords] = useState<EditableIgnoreWord[]>(account!.ignore_words || []);
  const [outOfOfficeKeywords, setOutOfOfficeKeywords] = useState<EditableIgnoreWord[]>(account!.ooo_words || []);
  const [ignoreFreeEvents, setIgnoreFreeEvents] = useState<boolean>(account!.ignore_free_events);

  const updateAccountMutation = useUpdateAccount();

  useEffect(() => {
    setConflictKeywords(account!.ignore_words || []);
  }, [account!.ignore_words]);

  useEffect(() => {
    setOutOfOfficeKeywords(account!.ooo_words || []);
  }, [account!.ooo_words]);

  useEffect(() => {
    setIgnoreFreeEvents(account!.ignore_free_events);
  }, [account!.ignore_free_events]);

  const handleIgnoreFreeEventsChange = (option: OnChangeValue<Option<boolean>, false>) => {
    setIgnoreFreeEvents(option!.value);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setConflictKeywords(account!.ignore_words || []);
    setOutOfOfficeKeywords(account!.ooo_words || []);
    setIgnoreFreeEvents(account!.ignore_free_events);
    setIsEditing(false);
    updateAccountMutation.reset();
  };

  const handleSave = async () => {
    updateAccountMutation.reset();

    try {
      await updateAccountMutation.mutateAsync({
        id: account!.id,
        payload: {
          ignore_words: conflictKeywords.map(({ id, negated }) => ({ id, negated })),
          ooo_words: outOfOfficeKeywords.map(({ id, negated }) => ({ id, negated })),
          ignore_free_events: ignoreFreeEvents,
        },
      });
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  return (
    <Section
      className="conflict-settings-section"
      isEditable
      isEditing={isEditing}
      isSaving={updateAccountMutation.isLoading}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Conflict settings"
    >
      <Flash
        message={updateAccountMutation.error?.message}
        showFlash={updateAccountMutation.isError}
        type="danger"
      />
      <Flash
        isDismissible
        message="Successfully updated!"
        showFlash={updateAccountMutation.isSuccess}
        type="success"
      />
      <ConflictKeywordsInput
        isDisabled={!isEditing || updateAccountMutation.isLoading}
        keywords={conflictKeywords}
        setKeywords={setConflictKeywords}
      />
      <OutOfOfficeKeywordsInput
        isDisabled={!isEditing || updateAccountMutation.isLoading}
        keywords={outOfOfficeKeywords}
        setKeywords={setOutOfOfficeKeywords}
      />
      <SelectInput
        helperText={'Determine whether events marked as "free" should be considered conflicts or not.'}
        isDisabled={!isEditing || updateAccountMutation.isLoading}
        isRequired
        label="Free events"
        onChange={handleIgnoreFreeEventsChange}
        options={ignoreFreeEventsOptions}
        value={find(ignoreFreeEventsOptions, ['value', ignoreFreeEvents])}
      />
    </Section>
  );
};

export default CompanyConflictSettingsSection;
