import { useEffect, useState } from 'react';

import DurationInput from '../../library/inputs/DurationInput';
import Flash from '../../library/utils/Flash';
import Section from '../../library/layout/Section';
import { useAccount, useUpdateAccount } from '../../../hooks/queries/accounts';

const CompanyMeetingBuffersSection = () => {
  const { data: account } = useAccount();

  const [isEditing, setIsEditing] = useState(false);

  const [interviewerMeetingBufferMinutes, setInterviewerMeetingBufferMinutes] = useState(account!.interviewer_meeting_buffer_minutes);

  const updateAccountMutation = useUpdateAccount();

  useEffect(() => {
    setInterviewerMeetingBufferMinutes(account!.interviewer_meeting_buffer_minutes);
  }, [account!.interviewer_meeting_buffer_minutes]);

  const handleInterviewerMeetingBufferMinutesChange = (minutes: number) => setInterviewerMeetingBufferMinutes(minutes);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setInterviewerMeetingBufferMinutes(account!.interviewer_meeting_buffer_minutes);
    setIsEditing(false);
    updateAccountMutation.reset();
  };

  const handleSave = async () => {
    updateAccountMutation.reset();

    try {
      await updateAccountMutation.mutateAsync({
        id: account!.id,
        payload: {
          interviewer_meeting_buffer_minutes: interviewerMeetingBufferMinutes,
        },
      });
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  return (
    <Section
      isEditable
      isEditing={isEditing}
      isSaving={updateAccountMutation.isLoading}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Meeting buffers"
    >
      <Flash
        message={updateAccountMutation.error?.message}
        showFlash={updateAccountMutation.isError}
        type="danger"
      />
      <Flash
        isDismissible
        message="Successfully updated!"
        showFlash={updateAccountMutation.isSuccess}
        type="success"
      />
      <DurationInput
        additionalText="before and after meetings"
        className="input-interviewer-meeting-buffer"
        helperText="Use this to prevent scheduling back-to-back events for your interviewers. This can be overridden for specific interviewers."
        isDisabled={!isEditing || updateAccountMutation.isLoading}
        label="Interviewer Meeting Buffer"
        maxMinutes={300}
        minMinutes={0}
        onChange={handleInterviewerMeetingBufferMinutesChange}
        value={interviewerMeetingBufferMinutes}
      />
    </Section>
  );
};

export default CompanyMeetingBuffersSection;
