import { Link } from 'react-router-dom';
import { useEffect, useMemo, useRef, useState } from 'react';

import CheckboxInput from 'components/library/inputs/CheckboxInput';
import ColorInput from 'components/library/inputs/ColorInput';
import EditorInput from 'components/library/inputs/EditorInput';
import FileDropInput from 'components/library/inputs/FileDropInput';
import Flash from 'components/library/utils/Flash';
import Section from 'components/library/layout/Section';
import TextInput from 'components/library/inputs/TextInput';
import { constructAvailabilityMessageTokens, constructAvailabilityPreview } from './helpers';
import { encodeObjectForPreview } from 'libraries/query-string';
import { slateValueToHtml } from 'libraries/editor/slate-value-to-html';
import { useAccount, useUpdateAccount } from 'hooks/queries/accounts';
import { useRender, useTokens } from 'hooks/queries/tokens';
import { useSession } from 'hooks/use-session';
import { useSlateEditor } from 'hooks/use-slate-editor';

import type { BlobWithName } from 'components/library/inputs/FileDropInput';
import type { ChangeEvent } from 'react';
import type { UpdateAccountPayload } from 'hooks/queries/accounts';
import { correctPath } from 'libraries/gem';

const MAX_LOGO_FILE_SIZE_BYTES = 5242880;

const FAKE_CANDIDATE_NAME = 'Jane Doe';

const exampleAvailabilityMessage = `<span>Hi {{Candidate.FirstName}},</span><br>
<br>
<span>We&apos;re excited to have you interview with us for the {{Job.Name}} position! Please provide some times below.</span><br>
<br>
<span>If you have any questions, don&apos;t hesitate to reach out. Thank you!</span><br>
<br>
<span>–{{Requester.FirstName}}</span>`.replace(/\n/g, '');

const CompanyBrandingSection = () => {
  const { currentUser } = useSession();
  const { data: account } = useAccount();

  const [isEditing, setIsEditing] = useState(false);

  const [name, setName] = useState(account?.name || '');
  const [color, setColor] = useState(account?.color || '');
  const [logo, setLogo] = useState<string | BlobWithName | undefined>(account?.logo_url || undefined);
  const [availabilityMessageSlateEditor, availabilityMessageSlateValue, setAvailabilityMessageSlateValue, setAvailabilityMessage] = useSlateEditor(account?.availability_message || '');
  const [useAvailabilityMessageInSelfSchedulingRequest, setUseAvailabilityMessageInSelfSchedulingRequest] = useState(account?.availability_message_in_self_scheduling);

  const {
    data: tokens,
    error: tokensError,
  } = useTokens({
    type: 'availability_message',
  });

  const {
    data: renderedAvailabilityMessage,
    error: availabilityMessageError,
  } = useRender({
    type: 'availability_message',
    plain_text: false,
    availability: constructAvailabilityMessageTokens(currentUser!, FAKE_CANDIDATE_NAME),
    text: account?.availability_message,
  }, {
    enabled: Boolean(account?.availability_message),
  });

  const availabilityPreview = useMemo(() => {
    return constructAvailabilityPreview({
      ...account!,
      availability_message: renderedAvailabilityMessage?.rendered_text,
    }, FAKE_CANDIDATE_NAME);
  }, [account, renderedAvailabilityMessage?.rendered_text]);

  const updateAccountMutation = useUpdateAccount();

  const nameRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setLogo(account?.logo_url || undefined);
  }, [account?.logo_url]);

  useEffect(() => {
    if (isEditing) {
      nameRef.current?.focus();
    }
  }, [isEditing]);

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => setName(e.target.value);
  const handleColorChange = (color: { hex: string } | null) => setColor(color ? color.hex : '');
  const handleLogoChange = (file: BlobWithName | null) => setLogo(file || undefined);
  const handleUseAvailabilityMessageInSelfSchedulingRequestChange = (e: ChangeEvent<HTMLInputElement>) => setUseAvailabilityMessageInSelfSchedulingRequest(e.target.checked);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setName(account?.name || '');
    setColor(account?.color || '');
    setLogo(account?.logo_url || undefined);
    setAvailabilityMessage(account?.availability_message || '');
    setIsEditing(false);
    updateAccountMutation.reset();
  };

  const handleSave = async () => {
    updateAccountMutation.reset();

    try {
      const payload: UpdateAccountPayload = {
        name,
        color,
        availability_message: slateValueToHtml(availabilityMessageSlateValue).trim() || '',
        availability_message_in_self_scheduling: useAvailabilityMessageInSelfSchedulingRequest,
      };
      if (logo && typeof logo !== 'string') {
        payload.logo = logo;
      }
      await updateAccountMutation.mutateAsync({
        id: account!.id,
        payload,
      });
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  return (
    <Section
      className="company-branding-section"
      isEditable
      isEditing={isEditing}
      isSaving={updateAccountMutation.isLoading}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Company branding"
    >
      <Flash
        message={
          <span>
            We use these settings to customize the availability links that you send to candidates.&nbsp;
            <Link
              target="_blank"
              to={{
                pathname: correctPath('/availability/preview'),
                search: encodeObjectForPreview(availabilityPreview),
              }}
            >
              See a preview
            </Link>
            . You can edit the business hours, timezone, and other availability preferences separately on each stage.
          </span>
        }
        showFlash={!isEditing}
        type="info"
      />
      <Flash
        message={`Please contact support. Something went wrong when validating your availability message: ${availabilityMessageError?.message}`}
        showFlash={Boolean(availabilityMessageError)}
        type="danger"
      />
      <Flash
        message="Save your changes and click on the preview link to see your updates."
        showFlash={isEditing}
        type="info"
      />
      <Flash
        message={updateAccountMutation.error?.message}
        showFlash={updateAccountMutation.isError}
        type="danger"
      />
      <Flash
        message={tokensError?.message}
        showFlash={Boolean(tokensError)}
        type="danger"
      />
      <Flash
        isDismissible
        message="Successfully updated!"
        showFlash={updateAccountMutation.isSuccess}
        type="success"
      />
      <div className="form-container">
        <div className="form-left">
          <FileDropInput
            file={logo}
            fileTypes={['image/png']}
            helperText="We recommend using a logo that's at least 300px tall for clear image quality."
            isDisabled={!isEditing || updateAccountMutation.isLoading}
            label="Logo"
            maxFileSizeBytes={MAX_LOGO_FILE_SIZE_BYTES}
            onChange={handleLogoChange}
          />
        </div>
        <div className="form-right">
          <TextInput
            className="input-name"
            id="input-name"
            isDisabled={!isEditing || updateAccountMutation.isLoading}
            label="Name"
            onChange={handleNameChange}
            ref={nameRef}
            value={name}
          />
          <ColorInput
            isDisabled={!isEditing || updateAccountMutation.isLoading}
            label="Brand Color"
            onChange={handleColorChange}
            selectedColor={color}
          />
          {tokens && (
            <EditorInput
              editor={availabilityMessageSlateEditor}
              exampleHtmlContent={exampleAvailabilityMessage}
              helperText="This message appears at the top of the availability page."
              isDisabled={!isEditing || updateAccountMutation.isLoading}
              label="Availability Message"
              pendingPreviewMessage="This token will be filled in when you request availability from a candidate."
              setValue={setAvailabilityMessageSlateValue}
              tokens={tokens}
              type="availability_message"
              value={availabilityMessageSlateValue}
            />
          )}
          <CheckboxInput
            isChecked={useAvailabilityMessageInSelfSchedulingRequest}
            isDisabled={!isEditing || updateAccountMutation.isLoading}
            label="Show availability message at the top of the self-scheduling request page too."
            onChange={handleUseAvailabilityMessageInSelfSchedulingRequestChange}
          />
        </div>
      </div>
    </Section>
  );
};

export default CompanyBrandingSection;
