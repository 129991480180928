import Moment from 'moment-timezone';
import { isEqual, pick, sortBy } from 'lodash';

import { businessHourDayOrdering, BusinessHourReferenceType, defaultDays } from '../types/business-hours';

import type { BusinessHourDay, EditableBusinessHour } from '../types/business-hours';

interface BusinessHour {
  day: BusinessHourDay;
  start_time: string;
  end_time: string;
  timezone: string;
}

export function defaultBusinessHours (type: BusinessHourReferenceType, timezone?: string): BusinessHour[] {
  const start = type === BusinessHourReferenceType.User ? '09:00' : '00:00';
  const end = type === BusinessHourReferenceType.User ? '17:00' : '24:00';
  return defaultBusinessHoursWithStartAndEnd(type, start, end, timezone);
}

export function defaultBusinessHoursWithStartAndEnd (type: BusinessHourReferenceType, start: string, end: string, timezone?: string): BusinessHour[] {
  return defaultDays.map((day) => ({
    day,
    start_time: start,
    end_time: end,
    timezone: timezone || Moment.tz.guess(),
  }));
}

export function sortBusinessHours<T extends EditableBusinessHour> (businessHours: T[]): T[] {
  return sortBy(businessHours, [(bh) => businessHourDayOrdering[bh.day], 'start_time', 'end_time']);
}

export function isWindowValid (start: Moment.Moment, end: Moment.Moment, businessHours: EditableBusinessHour[]): boolean {
  const relevantBusinessHours = businessHours.filter((bh) => businessHourDayOrdering[bh.day] === start.weekday());
  // We need at least one business hour window for this day to be valid for this passed in time range to be valid.
  return relevantBusinessHours.some((bh) => {
    // We need to take the business hour times and create Moment times that's of the same day as the window we're
    // checking. We're using start to determine the day since end might cross over into another day.
    const bhStart = Moment.tz(`${start.format('YYYY-MM-DD')}T${bh.start_time}`, 'YYYY-MM-DDTHH:mm', bh.timezone!);
    const bhEnd = Moment.tz(`${start.format('YYYY-MM-DD')}T${bh.end_time}`, 'YYYY-MM-DDTHH:mm', bh.timezone!);

    return start.isSameOrAfter(bhStart) && end.isSameOrBefore(bhEnd);
  });
}

export function isSameBusinessHours (businessHours1: BusinessHour[] | EditableBusinessHour[], businessHours2: BusinessHour[] | EditableBusinessHour[]): boolean {
  return isEqual(
    sortBusinessHours(businessHours1.map((bh) => pick(bh, ['day', 'start_time', 'end_time', 'timezone']))),
    sortBusinessHours(businessHours2.map((bh) => pick(bh, ['day', 'start_time', 'end_time', 'timezone'])))
  );
}
