import { find } from 'lodash';
import { useMemo } from 'react';

import ListItem from '../data-display/ListItem';
import SelectInput from './SelectInput';
import type { ListStagesQuery } from '../../../hooks/queries/stages';
import { useStages } from '../../../hooks/queries/stages';

import type { ActionMeta } from 'react-select/dist/declarations/src/types';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option as SelectOption } from './SelectInput/types';

export interface Option extends SelectOption<string> {
}

interface Props<M extends boolean> {
  className?: string;
  helperText?: string;
  isDisabled?: boolean;
  isMulti?: M;
  stageQuery?: ListStagesQuery;
  label?: string;
  name?: string;
  onChange: (newValue: OnChangeValue<Option, M>, actionMeta: ActionMeta<Option>) => void;
  placeholder?: string;
  value?: string | string[];
}

const StageSelectInput = <M extends boolean = false>({
  className,
  helperText,
  isDisabled = false,
  isMulti,
  stageQuery = {},
  label,
  onChange,
  placeholder = 'Select a stage',
  value,
}: Props<M>) => {
  const { data: stages } = useStages(stageQuery);

  const options = useMemo<Option[]>(() => (stages?.stages || [])
  .map((stage) => ({
    value: stage.id,
    label: stage.name,
  })), [stages]);

  const selected = useMemo<OnChangeValue<Option, M> | undefined>(() => {
    if (!isMulti) {
      return value ? find(options, ['value', value])! as OnChangeValue<Option, M> : undefined;
    }

    let selectedStages: string[] | undefined;
    if (typeof value === 'string') {
      selectedStages = [value];
    } else {
      selectedStages = value;
    }
    return selectedStages ?
      // The types with a dynamic isMulti is a bit weird. We need to explicitly
      // cast this to be compatible.
      selectedStages.map((value) => find(options, ['value', value])!) as unknown as OnChangeValue<Option, M> :
      undefined;
  }, [isMulti, options, value]);

  return (
    <SelectInput
      className={className}
      formatOptionLabel={(option, { context }) => (
        context === 'menu' ?
          <ListItem
            label={option.label}
          /> :
          option.label
      )}
      helperText={helperText}
      isClearable
      isDisabled={isDisabled}
      isMulti={isMulti}
      label={label}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      value={selected}
    />
  );
};

export default StageSelectInput;
