import type { Application } from './applications';
import type { EditableInterview, Interview } from './interviews';
import type { RSVP } from './events';
import type { ScheduleTemplate } from './schedule-templates';
import type { SelfSchedulingLinkStatus } from './self-scheduling-links';
import type { Stage } from './jobs';
import type { User } from './users';

export enum ScheduleStatus {
  AvailabilityRequested = 'availability_requested',
  ReadyToSchedule = 'ready_to_schedule',
  Confirming = 'confirming',
  Confirmed = 'confirmed',
  Cancelling = 'cancelling',
  Cancelled = 'cancelled',
}

export interface Schedule {
  id: string;
  ats_id?: string;
  imported_from_ats: boolean;
  account_id: string;
  application_id?: string;
  application?: Application;
  stage_id: string;
  stage: Stage;
  schedule_template_id: string;
  schedule_template: ScheduleTemplate;
  creator_id: string;
  creator: User;
  hold: boolean;
  status: ScheduleStatus;
  timezone: string;
  candidate_timezone?: string;
  scheduling_calendar_email: string;
  candidate_scheduling_calendar_email: string;
  candidate_event_ical_uid?: string;
  candidate_event_title: string;
  candidate_event_description: string;
  candidate_event_location?: string;
  candidate_event_additional_attendees?: string[];
  candidate_event_additional_optional_attendees?: string[];
  candidate_rsvp: RSVP;
  self_scheduling_link_id?: string;
  self_scheduling_link: SelfSchedulingLink;
  block_id?: string;
  interviews: Interview[];
  created_at: string;
  updated_at: string;
  conflicts?: number;
}

interface SelfSchedulingLink {
  id: string;
  stage_id: string;
  status: SelfSchedulingLinkStatus;
  updated_at: string;
}

export interface MatchingPool {
  [blockCount: number]: Matching[];
}

export interface Matching {
  // Blocks
  b: Block[];
  // Conflicts
  c: number;
}

export interface Block {
  // Slots
  s: Slot[];
  // Selected room
  sr?: PossibleRoom;
  // Possible rooms
  pr?: PossibleRoom[];
  // Selected Zoom Host
  sz?: PossibleZoomHost;
  // Possible Zoom Hosts
  pz?: PossibleZoomHost[];
}

export interface Slot {
  // Stage interview index
  x: number;
  // Start time (unix timestamp)
  s: number;
  // End time (unix timestamp)
  e: number;
  // Interviewers
  i: Interviewer[];
}

export interface Interviewer {
  // Selected interviewer
  s: PossibleInterviewer;
  // Possible interviewers
  p: PossibleInterviewer[];
}

export interface PossibleRoom {
  // Room index
  x: number;
  // Conflicts
  c: number;
}

export interface PossibleZoomHost {
  // Zoom Host index
  x: number;
  // Conflicts
  c: number;
}

export interface PossibleInterviewer {
  // User index
  x: number;
  // Conflicts
  c: number;
  // Daily interview count
  d: number;
  // Weekly interview count
  w: number;
}

export interface EditableSchedule {
  id: string;
  application_id?: string;
  stage: Stage;
  timezone: string;
  hold: boolean;
  candidate_event_ical_uid?: string;
  candidate_event_location?: string;
  schedule_template: ScheduleTemplate;
  scheduling_calendar_email: string;
  candidate_scheduling_calendar_email: string;
  interviews: EditableInterview[];
}
