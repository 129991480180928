import colors from './colors';

import type { DefaultTheme } from 'styled-components';

const theme: DefaultTheme = {
  fonts: {
    text: 'Open Sans, sans-serif',
    header: 'Red Hat Display, sans-serif',
  },
  colors,
};

export default theme;
