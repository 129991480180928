export type ThemeColor =
  'palePink' | 'brightRed' | 'amaranthRed' |
  'moroccoBrown' | 'serenadeOrange' | 'neonOrange' | 'zestOrange' |
  'goldenYellow' | 'grassYellow' |
  'limaGreen' | 'funGreen' | 'teaGreen' | 'midnightGreen' | 'mintGreen' | 'meadowGreen' | 'rainyGreen' |
  'aliceBlue' | 'foamBlue' | 'rockBlue' | 'javaBlue' | 'curiousBlue' | 'azureBlue' | 'bayBlue' | 'zodiacBlue' |
  'lightLilac' | 'dustyLavender' | 'amethystPurple' | 'orchidPink' |
  'white' | 'alabasterWhite' |
  'mercuryGray' | 'silverGray' | 'dustyGray' | 'doveGray' |
  'mineshaftBlack';

const colors: Record<ThemeColor, string> = {
  palePink: '#FFF6F6',
  brightRed: '#D35454',
  amaranthRed: '#E52B50',

  moroccoBrown: '#411F00',
  serenadeOrange: '#FFF2E5',
  neonOrange: '#FFB672',
  zestOrange: '#E38820',

  goldenYellow: '#F0D138',
  grassYellow: '#DBBC23',

  limaGreen: '#87AB39',
  funGreen: '#02A74F',
  teaGreen: '#EEF9E4',
  midnightGreen: '#072210',
  mintGreen: '#E5FBEC',
  meadowGreen: '#18D285',
  rainyGreen: '#B6C3AB',

  aliceBlue: '#EFFAFF',
  foamBlue: '#D2F1FF',
  rockBlue: '#9EBAC7',
  javaBlue: '#18C2D2',
  curiousBlue: '#18A0D2',
  azureBlue: '#315BA1',
  bayBlue: '#234E83',
  zodiacBlue: '#132C4C',

  lightLilac: '#EFF1FF',
  dustyLavender: '#A99EC7',
  amethystPurple: '#9966CC',
  orchidPink: '#E386E0',

  white: '#FFFFFF',
  alabasterWhite: '#F8F8F8',

  mercuryGray: '#E6E6E6',
  silverGray: '#CECECE',
  dustyGray: '#999999',
  doveGray: '#666666',

  mineshaftBlack: '#2A2A2A',
};

export default colors;
