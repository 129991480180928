import Moment from 'moment-timezone';
import { Breadcrumb } from 'react-breadcrumbs';
import { Link, Redirect, Route, Switch, useParams } from 'react-router-dom';
import { flatten, isEmpty, keyBy, orderBy } from 'lodash';
import { useEffect, useState } from 'react';

import AvailabilityStep from './AvailabilityStep';
import EmailSettingsWarningModal from '../EmailSettingsWarningModal';
import { EmailTemplateType } from 'types';
import EventsAndEmailsStep from './EventsAndEmailsStep';
import Flash from '../../../../library/utils/Flash';
import InterviewPlanStep from './InterviewPlanStep';
import InterviewersStep from './InterviewersStep';
import LoadingSpinner from '../../../../library/utils/LoadingSpinner';
import ReviewStep from './ReviewStep';
import ScheduleStep from './ScheduleStep';
import Section from '../../../../library/layout/Section';
import StepProgressBar from '../../../../library/navigation/StepProgressBar';
import useQueryState from '../../../../../hooks/use-query-state';
import { NewScheduleProvider, Step, useNewSchedule } from './use-new-schedule';
import { createEmptyZoomHostFilter } from '../../../../../libraries/zoom-hosts';
import { useApplication } from '../../../../../hooks/queries/applications';
import { useSchedule } from '../../../../../hooks/queries/schedules';
import { useSession } from '../../../../../hooks/use-session';

import type { Block, Schedule, TimeSlot } from './types';
import type { ReactNode } from 'react';
import { correctPath } from 'libraries/gem';

// To enable the inner component to access the context value, it needs to be nested
// under the provider, so we need this wrapper component to add that nesting.
const CandidateScheduleCreate = () => {
  return (
    <NewScheduleProvider>
      <CandidateScheduleCreateInner />
    </NewScheduleProvider>
  );
};

export default CandidateScheduleCreate;

const CandidateScheduleCreateInner = () => {
  const { id } = useParams<{ id: string }>();

  const [scheduleParam] = useQueryState<string>('schedule', '');

  const { account, currentUser } = useSession();

  const {
    data: application,
    error: applicationError,
    isLoading: isApplicationLoading,
  } = useApplication(id);
  const {
    data: heldSchedule,
    error: heldScheduleError,
    isLoading: isHeldScheduleLoading,
  } = useSchedule(scheduleParam);

  const {
    completedStep,
    schedule,
    setCompletedStep,
    setSchedule,
  } = useNewSchedule();

  const canSchedule = application && [
    'ready_to_request_availability',
    'availability_requested',
    'scheduling_link_sent',
    'ready_to_schedule',
    'scheduled',
    'cancelled',
  ].includes(application.scheduling_status);

  useEffect(() => {
    if (!isApplicationLoading && !isHeldScheduleLoading && canSchedule && !schedule.application_id) {
      (async () => {
        if (!application.current_stage) {
          // Because we filter by status, this shouldn't ever happen.
          return null;
        }

        const previouslyScheduledStageInterviewIds = application.active_schedules ? flatten(
          application.active_schedules.map(({ interviews }) => interviews.map(({ stage_interview_id }) => stage_interview_id))
        ) : [];

        const submittedAvailabilities = application.active_availabilities?.filter(({ status }) => status === 'submitted') || null;
        const flattenedAvailabilities: TimeSlot[] = submittedAvailabilities ?
          flatten(submittedAvailabilities.map(({ availability_time_slots }) => availability_time_slots || []))
          .filter(({ end_time }) => Moment.utc(end_time).isAfter(Moment()))
          .map(({ start_time, end_time }) => ({
            start_time: Moment(start_time).toDate(),
            end_time: Moment(end_time).toDate(),
          })) :
          [];
        const submittedNonImportedAvailability = submittedAvailabilities?.filter(({ ats_id, manual }) => !Boolean(ats_id) && !manual)[0];

        // Default the candidate timezone based on the last received
        // availability, regardless where it came from.
        let candidateTimezone;
        if (submittedAvailabilities && submittedAvailabilities.length > 0) {
          const lastAvailability = submittedAvailabilities[submittedAvailabilities.length - 1];
          if (lastAvailability.availability_time_slots && lastAvailability.availability_time_slots.length > 0) {
            candidateTimezone = lastAvailability.availability_time_slots[0].timezone;
          }
        }

        // If you add a new property here, make sure you consider if it should be
        // pulled from a held schedule as well.
        const newSchedule: Schedule = {
          timezone: application.current_stage.schedule_template?.business_hours?.[0]?.timezone || Moment.tz.guess(),
          application_id: application.id,
          availabilities: [...flattenedAvailabilities],
          submittedAvailabilities: [...flattenedAvailabilities],
          scheduling_calendar_email: application.current_stage.schedule_template?.scheduling_calendar_email || account!.scheduling_calendar_email!,
          candidate_scheduling_calendar_email: application.current_stage.schedule_template?.candidate_scheduling_calendar_email || account!.candidate_scheduling_calendar_email,
          candidate_timezone: candidateTimezone,
          schedule_template: {
            candidate_calendar_event_template: application.current_stage.schedule_template?.candidate_calendar_event_template && {
              id: application.current_stage.schedule_template.candidate_calendar_event_template.id,
              title: application.current_stage.schedule_template.candidate_calendar_event_template.title,
              description: application.current_stage.schedule_template.candidate_calendar_event_template.description,
              location: application.current_stage.schedule_template.candidate_calendar_event_template.location,
              additional_attendees: application.current_stage.schedule_template.candidate_calendar_event_template.additional_attendees,
              additional_optional_attendees: application.current_stage.schedule_template.candidate_calendar_event_template.additional_optional_attendees,
            },
            business_hours: (application.current_stage.schedule_template?.business_hours || []).map((bh) => {
              return {
                day: bh.day,
                start_time: bh.start_time === '00:00' ? '' : bh.start_time,
                end_time: bh.end_time === '24:00' ? '' : bh.end_time,
                timezone: bh.timezone || Moment.tz.guess(),
              };
            }),
            onsite: application.current_stage.schedule_template?.onsite || false,
            mark_interviewer_events_as_private: application.current_stage.schedule_template?.mark_interviewer_events_as_private || false,
            mark_candidate_events_as_private: application.current_stage.schedule_template?.mark_candidate_events_as_private || false,
            video_conferencing_enabled: application.current_stage.schedule_template?.video_conferencing_enabled || false,
            zoom_host_filters: application.current_stage.schedule_template?.zoom_host_filters || createEmptyZoomHostFilter(),
            create_hiring_channel: application.current_stage.schedule_template?.create_hiring_channel || false,
            confirmation_email_template: application.current_stage.schedule_template?.confirmation_email_template && {
              ...application.current_stage.schedule_template.confirmation_email_template,
              attachments: application.current_stage.schedule_template.confirmation_email_template.attachments?.map((attachment, i) => ({ ...attachment, index: i })),
            },
            room_filters: application.current_stage.schedule_template?.room_filters?.map((filter) => ({
              room_filter_expressions: filter.room_filter_expressions.map((exp) => ({
                negated: exp.negated,
                filterable_id: exp.filterable_id,
                filterable_type: exp.filterable_type,
              })),
            })),
            ...(submittedNonImportedAvailability ? {
              business_hours: (submittedNonImportedAvailability.availability_template!.business_hours || []).map((bh) => {
                return {
                  day: bh.day,
                  start_time: bh.start_time === '00:00' ? '' : bh.start_time,
                  end_time: bh.end_time === '24:00' ? '' : bh.end_time,
                  timezone: bh.timezone || Moment.tz.guess(),
                };
              }),
            } : {}),
          },
          stage: {
            id: application.current_stage.id,
            job_id: application.current_stage.job_id,
          },
          stage_interviews: (application.current_stage.stage_interviews || []).filter(({ deleted, inline }) => !deleted && !inline).map((stageInterview) => ({
            id: stageInterview.id,
            in_schedule: (
              (Boolean(stageInterview.interview_template) || !isEmpty(stageInterview.ats_interviewer_ids)) &&
              !previouslyScheduledStageInterviewIds.includes(stageInterview.id) &&
              (stageInterview.ats_schedulable === null || stageInterview.ats_schedulable)
            ),
            name: stageInterview.name,
            ats_id: stageInterview.ats_id,
            ats_duration_minutes: stageInterview.ats_duration_minutes,
            ats_interviewer_ids: stageInterview.ats_interviewer_ids,
            ats_schedulable: stageInterview.ats_schedulable,
            feedback_form_id: stageInterview.feedback_form_id,
            interview_template: (stageInterview.interview_template ? {
              id: stageInterview.interview_template.id,
              name: stageInterview.interview_template.name,
              duration_minutes: stageInterview.interview_template.duration_minutes,
              live_coding_enabled: account!.live_coding_type ? stageInterview.interview_template.live_coding_enabled : false,
              positions: stageInterview.interview_template.positions,
              time_window_start: stageInterview.interview_template.time_window_start,
              time_window_end: stageInterview.interview_template.time_window_end,
              candidate_facing_name: stageInterview.interview_template.candidate_facing_name,
              candidate_facing_details: stageInterview.interview_template.candidate_facing_details,
              interviewer_templates: (stageInterview.interview_template.interviewer_templates || []).map((interviewerTemplate) => ({
                description: interviewerTemplate.description,
                optional: interviewerTemplate.optional,
                include_past_interviewers: interviewerTemplate.include_past_interviewers,
                interviewer_filters: (interviewerTemplate.interviewer_filters || []).map((filter) => ({
                  position: filter.position,
                  interviewer_filter_expressions: (filter.interviewer_filter_expressions || []).map((exp) => ({
                    negated: exp.negated,
                    filterable_id: exp.filterable_id,
                    filterable_type: exp.filterable_type,
                  })),
                })),
              })),
            } : {
              name: '',
              duration_minutes: stageInterview.ats_duration_minutes || 0,
              live_coding_enabled: false,
              positions: undefined,
              time_window_start: undefined,
              time_window_end: undefined,
              candidate_facing_name: undefined,
              candidate_facing_details: undefined,
              interviewer_templates: (stageInterview.ats_interviewer_ids || []).map((interviewerId) => ({
                optional: false,
                include_past_interviewers: true,
                interviewer_filters: [{
                  position: 0,
                  interviewer_filter_expressions: [{
                    negated: false,
                    filterable_id: interviewerId,
                    filterable_type: 'user',
                  }],
                }],
              })),
            }),
          })),
          hold: false,
          generatedOptionsByNumberOfBlocks: {},
        };

        if (heldSchedule) {
          const interviewsByStageInterviewId = keyBy(heldSchedule.interviews, 'stage_interview_id');

          newSchedule.id = heldSchedule.id;
          newSchedule.block_id = heldSchedule.block_id;
          newSchedule.timezone = heldSchedule.timezone;
          newSchedule.candidate_timezone = heldSchedule.candidate_timezone;
          newSchedule.scheduling_calendar_email = heldSchedule.scheduling_calendar_email;
          newSchedule.candidate_scheduling_calendar_email = heldSchedule.candidate_scheduling_calendar_email;
          newSchedule.schedule_template = {
            ...heldSchedule.schedule_template,
            business_hours: (heldSchedule.schedule_template.business_hours || []).map((bh) => {
              return {
                day: bh.day,
                start_time: bh.start_time === '00:00' ? '' : bh.start_time,
                end_time: bh.end_time === '24:00' ? '' : bh.end_time,
                timezone: bh.timezone || Moment.tz.guess(),
              };
            }),
            zoom_host_filters: heldSchedule.schedule_template.zoom_host_filters || createEmptyZoomHostFilter(),
            multi_block_confirmation_email_template: heldSchedule.schedule_template.multi_block_confirmation_email_template && {
              ...heldSchedule.schedule_template.multi_block_confirmation_email_template,
              attachments: heldSchedule.schedule_template.multi_block_confirmation_email_template.attachments?.map((attachment, i) => ({ ...attachment, index: i })),
            },
            confirmation_email_template: heldSchedule.schedule_template.confirmation_email_template && {
              ...heldSchedule.schedule_template.confirmation_email_template,
              attachments: heldSchedule.schedule_template.confirmation_email_template.attachments?.map((attachment, i) => ({ ...attachment, index: i })),
            },
          };
          newSchedule.stage_interviews = newSchedule.stage_interviews.map((stageInterview) => {
            const interview = interviewsByStageInterviewId[stageInterview.id];
            return {
              ...stageInterview,
              in_schedule: Boolean(interview),
              feedback_form_id: interview ? interview.feedback_form_id : stageInterview.feedback_form_id,
              interview_template: {
                ...interview ? interview.interview_template : stageInterview.interview_template,
                interviewer_templates: (interview && interview.interviewers || []).map(({ interviewer_template, user_id }) => ({
                  description: interviewer_template.description,
                  optional: interviewer_template.optional,
                  include_past_interviewers: interviewer_template.include_past_interviewers,
                  interviewer_filters: [{
                    position: 0,
                    interviewer_filter_expressions: [{
                      filterable_id: user_id,
                      filterable_type: 'user',
                      negated: false,
                    }],
                  }],
                })),
              },
            };
          });

          const allHeldBlockSchedules = orderBy((
            heldSchedule.block_id ?
              application.held_schedules!.filter(({ block_id }) => block_id === heldSchedule.block_id) :
              [heldSchedule]
          ), ({ interviews }) => interviews[0].start_time);

          newSchedule.generatedOptionsByNumberOfBlocks[allHeldBlockSchedules.length] = {
            scheduleOptions: [{
              ...heldSchedule,
              conflicts: 0,
              blocks: allHeldBlockSchedules.map<Block>((heldBlockSchedule) => ({
                schedule_id: heldBlockSchedule.id,
                current_zoom_host_id: heldBlockSchedule.interviews[0].zoom_host_id,
                video_conferencing_link: heldSchedule.schedule_template?.video_conferencing_enabled ? heldBlockSchedule.candidate_event_location : undefined,
                ...(heldBlockSchedule.interviews[0].room_id ? {
                  selected_room: { room_id: heldBlockSchedule.interviews[0].room_id },
                  potential_rooms: [{ room_id: heldBlockSchedule.interviews[0].room_id }],
                } : {}),
                ...(heldBlockSchedule.interviews[0].zoom_host_id && heldBlockSchedule.interviews[0].zoom_host_type ? {
                  selected_zoom_host: {
                    id: heldBlockSchedule.interviews[0].zoom_host_id,
                    type: heldBlockSchedule.interviews[0].zoom_host_type,
                  },
                  potential_zoom_hosts: [{
                    id: heldBlockSchedule.interviews[0].zoom_host_id,
                    type: heldBlockSchedule.interviews[0].zoom_host_type,
                  }],
                } : {}),
                interviews: heldBlockSchedule.interviews.map((interview) => {
                  return {
                    ...interview,
                    interviewers: (interview.interviewers || []).map((interviewer) => {
                      return {
                        ...interviewer,
                        selected_user: { user_id: interviewer.user_id },
                        potential_users: [{ user_id: interviewer.user_id }],
                        interviewer_template: {
                          ...interviewer.interviewer_template,
                          interviewer_filters: (interviewer.interviewer_template.interviewer_filters || []).map((filter) => ({
                            ...filter,
                            interviewer_filter_expressions: filter.interviewer_filter_expressions || [],
                          })),
                        },
                      };
                    }),
                  };
                }),
              })),
            }],
            selectedScheduleOptionIndices: [0],
            scheduleOptionsConflictTotals: {},
            lastGenerateSchedulesPayload: null,
            nextOffset: 0,
            fetchedAllData: true,
          };
        }

        setSchedule(newSchedule);
        setCompletedStep(heldSchedule ? Step.Review : Step.InterviewPlan);
      })();
    }
  }, [Boolean(!isApplicationLoading && !isHeldScheduleLoading && canSchedule && !schedule.application_id)]);

  const canSendEmail = Boolean(account?.email_domain || currentUser?.gem_can_send_email);
  const [isEmailSettingsWarningModalOpen, setIsEmailSettingsWarningModalOpen] = useState(!canSendEmail);

  const isLoading = isApplicationLoading || isHeldScheduleLoading;
  const isError = Boolean(applicationError || heldScheduleError);

  if ((isLoading || (canSchedule && !schedule.application_id)) && !isError) {
    return <LoadingSpinner />;
  }

  const errorMessages: Record<string, ReactNode> = {
    unschedulable: (
      <span>
        You have not enabled scheduling for candidates in the {application?.current_stage && application?.current_stage.name} stage.&nbsp;
        <Link to={correctPath(`/app/jobs/${application?.job_id}/stages/${application?.current_stage_id}`)}>
          Enable scheduling here.
        </Link>
      </span>
    ),
    inactive: 'You cannot schedule an inactive candidate.',
  };

  return (
    <Breadcrumb
      data={{
        title: 'Schedule',
        pathname: correctPath(`/app/candidates/${application?.id}/schedule`),
      }}
    >
      {canSchedule && !isError ?
        <>
          <Section
            className="candidate-schedule-create-container"
            title={`Schedule ${application.candidate.name} for ${application?.current_stage?.name}`}
          >
            {!Boolean(schedule.id) && (
              <EmailSettingsWarningModal
                isOpen={isEmailSettingsWarningModalOpen}
                onToggle={() => setIsEmailSettingsWarningModalOpen(false)}
                type={EmailTemplateType.ConfirmationEmail}
              />
            )}
            <Flash
              message={<span>You have put this schedule on hold. You can make any final changes, and then confirm and create the schedule on <Link to={correctPath(`/app/candidates/${application.id}/schedule/review${schedule.id ? `?schedule=${schedule.id}` : ''}`)}>the Review step</Link>.</span>}
              showFlash={Boolean(schedule.id)}
              type="info"
            />
            <StepProgressBar
              completedStep={completedStep}
              steps={[{
                label: 'Interview Plan',
                location: correctPath(`/app/candidates/${application.id}/schedule/interview-plan${schedule.id ? `?schedule=${schedule.id}` : ''}`),
              }, {
                label: 'Interviewers',
                location: correctPath(`/app/candidates/${application.id}/schedule/interviewers${schedule.id ? `?schedule=${schedule.id}` : ''}`),
              }, {
                label: 'Availability',
                location: correctPath(`/app/candidates/${application.id}/schedule/availability${schedule.id ? `?schedule=${schedule.id}` : ''}`),
              }, {
                label: 'Schedule',
                location: correctPath(`/app/candidates/${application.id}/schedule/schedule${schedule.id ? `?schedule=${schedule.id}` : ''}`),
              }, {
                label: 'Events & Emails',
                location: correctPath(`/app/candidates/${application.id}/schedule/events-and-emails${schedule.id ? `?schedule=${schedule.id}` : ''}`),
              }, {
                label: 'Review',
                location: correctPath(`/app/candidates/${application.id}/schedule/review${schedule.id ? `?schedule=${schedule.id}` : ''}`),
              }]}
            />
            <div className="candidate-schedule-steps-container">
              <Switch>
                <Redirect exact from={correctPath('/app/candidates/:id/schedule')} to={correctPath('/app/candidates/:id/schedule/interview-plan')} />
                <Route component={InterviewPlanStep} path={correctPath('/app/candidates/:id/schedule/interview-plan')} />
                <Route component={InterviewersStep} path={correctPath('/app/candidates/:id/schedule/interviewers')} />
                <Route component={AvailabilityStep} path={correctPath('/app/candidates/:id/schedule/availability')} />
                <Route component={ScheduleStep} path={correctPath('/app/candidates/:id/schedule/schedule')} />
                <Route component={EventsAndEmailsStep} path={correctPath('/app/candidates/:id/schedule/events-and-emails')} />
                <Route component={ReviewStep} path={correctPath('/app/candidates/:id/schedule/review')} />
              </Switch>
            </div>
          </Section>
        </> :
        <>
          <Flash
            message={errorMessages[application!.scheduling_status]}
            showFlash={!canSchedule}
            type="danger"
          />
          <Flash
            message={applicationError?.message}
            showFlash={Boolean(applicationError)}
            type="danger"
          />
          <Flash
            message={heldScheduleError?.message}
            showFlash={Boolean(heldScheduleError)}
            type="danger"
          />
        </>
      }
    </Breadcrumb>
  );
};
