import { useMemo } from 'react';

import SelectInput from './SelectInput';

import type { Dispatch, SetStateAction } from 'react';
import type { EditableIgnoreWord } from '../../../types';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from './SelectInput/types';

interface Props {
  isDisabled?: boolean;
  keywords?: EditableIgnoreWord[];
  setKeywords: Dispatch<SetStateAction<EditableIgnoreWord[]>>;
}

const ConflictKeywordsInput = ({ isDisabled = false, keywords, setKeywords }: Props) => {

  const handleKeywordsChange = (options: OnChangeValue<Option<string>, true>) => {
    setKeywords([
      ...(options || []).map((option) => ({
        id: option.value,
        negated: false,
      })),
    ]);
  };

  const values = useMemo(() => (keywords || []).map((keyword) => ({
    ...keyword,
    value: keyword.id,
  })), [keywords]);

  return (
    <SelectInput
      formatCreateLabel={(value) => `Add keyword "${value}"`}
      formatOptionLabel={(option) => {
        // The type for formatOptionLabel isn't correct. Fix this once
        // https://github.com/JedWatson/react-select/issues/5064 is addressed.
        const { __isNew__, label, value } = option as any;
        return __isNew__ ? label : value;
      }}
      helperText={<span>These keywords are to <b>ignore vacation and PTO events</b> that interviewers are invited to but are not their own.</span>}
      isCreatable
      isDisabled={isDisabled}
      isMenuOpen
      isMulti
      label="Out-of-Office Keywords"
      name="ooo"
      noOptionsMessage={({ inputValue }) => {
        if (inputValue) {
          return 'You have already added this keyword.';
        }
        return null;
      }}
      onChange={handleKeywordsChange}
      options={[]}
      placeholder="Enter a word or phrase"
      value={values}
    />
  );
};

export default ConflictKeywordsInput;
