import type { CacheMap } from 'dataloader';

/**
 * This is the class for each cache entry. It's a thin wrapper around the actual
 * value being cached, but it keep track of when it was created and exposes an
 * isStale function so that we can easily query if this data is fresh or not.
 */
class CacheEntry<T> {
  creationTime: number;

  constructor (public value: T) {
    this.creationTime = Date.now();
  }

  isStale (staleTime: number): boolean {
    const elapsedTime = Date.now() - this.creationTime;
    return elapsedTime > staleTime;
  }
}

/**
 * This is the cache for the dataloader cache. It's a thin wrapper around a
 * normal Map, but it encapsulates all cached data in a CacheEntry object,
 * making the cache aware of freshness. This will ensure that data is not kept
 * around forever, and we're not serving stale data until the user refreshes the
 * page.
 */
export class Cache<K, V> implements CacheMap<K, V> {
  private map: Map<K, CacheEntry<V>>;

  constructor (private staleTime = 0) {
    this.map = new Map();
  }

  get (key: K): void | V {
    const entry = this.map.get(key);
    if (!entry) {
      // We don't have this key saved, so it's a cache miss.
      return;
    }

    if (entry.isStale(this.staleTime)) {
      // The data that we have stored is considered stale, so we report it as a
      // cache miss so it will be refreshed.
      return;
    }

    return entry.value;
  }

  set (key: K, value: V): Cache<K, V> {
    this.map.set(key, new CacheEntry<V>(value));
    return this;
  }

  delete (key: K): boolean {
    return this.map.delete(key);
  }

  clear (): void {
    this.map.clear();
  }
}
