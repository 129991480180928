interface Props {
  logoUrl?: string;
  message?: string;
}

const CompanyBrandedPageHeader = ({ logoUrl, message }: Props) => {
  const hasLogo = Boolean(logoUrl);
  const hasMessage = Boolean(message) && message !== '<br>';

  if (!hasLogo && !hasMessage) {
    return null;
  }

  return (
    <div className="company-branded-page-header">
      {hasLogo && <img alt="Brand Logo" className="logo" src={logoUrl} />}
      {hasMessage &&
        <div
          className="message"
          dangerouslySetInnerHTML={{ __html: message! }}
        />
      }
    </div>
  );
};

export default CompanyBrandedPageHeader;
