import { faPeopleGroup, faThumbtack, faUser } from '@fortawesome/free-solid-svg-icons';
import { startCase } from 'lodash';

import Tooltip from 'components/library/utils/Tooltip';
import { StyledHiringTeamIcon, StyledIconContainer, StyledInterviewerIcon, StyledReasonList, StyledTag, StyledUserIcon } from './styles';
import { formatList } from '../../../../libraries/formatters';

import type { HiringTeamRole } from 'types';
import type { ReactNode } from 'react';

interface Props {
  className?: string;
  hiringTeamRoles?: HiringTeamRole[];
  interviewNames?: string[];
  isChosenAsIndividual: boolean;
  userId: string;
}

const HiringMeetingAttendeeTag = ({ className, hiringTeamRoles, interviewNames, isChosenAsIndividual, userId }: Props) => {
  let icon: ReactNode;
  if (isChosenAsIndividual) {
    icon = <StyledUserIcon icon={faThumbtack} />;
  } else if (hiringTeamRoles) {
    icon = <StyledHiringTeamIcon icon={faUser} />;
  } else if (interviewNames) {
    icon = <StyledInterviewerIcon icon={faPeopleGroup} />;
  }

  const reasons: string[] = [];
  if (isChosenAsIndividual) {
    reasons.push('Listed explicitly');
  }
  if (hiringTeamRoles) {
    reasons.push(`${formatList(hiringTeamRoles.map((role) => startCase(role)))} for the candidate`);
  }
  if (interviewNames) {
    reasons.push(`Interviewer for ${formatList(interviewNames)}`);
  }

  const helperText = interviewNames && !isChosenAsIndividual && !hiringTeamRoles ? 'If you replace this interviewer in the schedule, we will automatically replace them in the hiring meeting.' : undefined;

  return (
    <StyledTag
      className={className}
      rightIcon={icon ? (
        <>
          <StyledIconContainer
            data-for={`${userId}-tag-icon-tooltip`}
            data-tip
          >
            {icon}
          </StyledIconContainer>
          <Tooltip
            helperText={helperText}
            id={`${userId}-tag-icon-tooltip`}
            position="top"
            value={(
              <StyledReasonList className={helperText ? 'has-helper-text' : undefined}>
                {reasons.map((reason) => (
                  <li key={reason}>{reason}</li>
                ))}
              </StyledReasonList>
            )}
          />
        </>
      ) : undefined}
      type="user"
      value={userId}
    />
  );
};

export default HiringMeetingAttendeeTag;
