import { find } from 'lodash';
import { useMemo } from 'react';

import SelectInput from './SelectInput';
import { formatPosition } from '../../../libraries/formatters';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from './SelectInput/types';
import type { ReactNode } from 'react';

const positions = [1, 2, 3, 4, 5, 6, -6, -5, -4, -3, -2, -1];

const positionOptions: Option<number>[] = positions.map((position) => ({
  value: position,
  label: formatPosition(position),
}));

interface Props {
  className?: string;
  helperText?: ReactNode;
  id?: string;
  isDisabled?: boolean;
  label?: string;
  name?: string;
  onChange: (newValue: OnChangeValue<Option<number>, true>, actionMeta: ActionMeta<Option<number>>) => void;
  value?: number[];
}

const PositionInput = ({ className, helperText, id, isDisabled, label, name, onChange, value }: Props) => {
  const selectedOptions = useMemo(() => {
    return (value || [])
    .map((position) => find(positionOptions, ['value', position]))
    .filter((p): p is Option<number> => Boolean(p));
  }, [value]);

  return (
    <SelectInput
      className={`position-input${className ? ` ${className}` : ''}`}
      helperText={helperText}
      id={id}
      isClearable
      isDisabled={isDisabled}
      isMulti
      label={label}
      name={name}
      onChange={onChange}
      options={positionOptions}
      placeholder="Any"
      value={selectedOptions}
    />
  );
};

export default PositionInput;
