import { useMutation, useQuery, useQueryClient } from 'react-query';

import InterviewPlanner from '../../libraries/interviewplanner';

import type { InterviewerFilterableType, InterviewTemplate } from '../../types';
import type { UseQueryOptions } from 'react-query';

export enum QueryKey {
  RetrieveInterviewTemplate = 'RetrieveInterviewTemplate',
  ListInterviewTemplates = 'ListInterviewTemplates',
}

export const useInterviewTemplate = (id: string, options: UseQueryOptions<InterviewTemplate, Error> = {}) => {
  return useQuery(interviewTemplateParams(id, options));
};

export const interviewTemplateParams = (id: string, options: UseQueryOptions<InterviewTemplate, Error> = {}) => {
  return {
    queryKey: [QueryKey.RetrieveInterviewTemplate, id],
    queryFn: () => {
      return InterviewPlanner.request<InterviewTemplate>('GET', `/interview-templates/${id}`);
    },
    ...options,
    enabled: options.enabled !== undefined ? options.enabled : (Boolean(id) && id !== 'new'),
  };
};

interface ListInterviewTemplatesQuery {
  limit?: number;
  offset?: number;
  name?: string;
  training_eligibility?: string;
  archived?: boolean;
}

interface ListInterviewTemplatesData {
  interview_templates: InterviewTemplate[];
  total: number;
}

export const useInterviewTemplates = (query: ListInterviewTemplatesQuery = {}, options: UseQueryOptions<ListInterviewTemplatesData, Error> = {}) => {
  // This query can't be canceled because the promise being returned from this
  // function (which is a result of the async keyword) doesn't have a cancel
  // function on it. If we want it to be cancellable, we can't use async/await,
  // and we need to make sure the cancel function from the promise returned from
  // InterviewPlanner.request is preserved.
  return useQuery<ListInterviewTemplatesData, Error>([QueryKey.ListInterviewTemplates, query], async () => {
    const pageSize = 100;
    const paginatedQuery = {
      limit: pageSize,
      offset: 0,
      ...query,
    };

    const interviewTemplates: InterviewTemplate[] = [];
    let total = 0;
    let totalLimit: number | undefined;

    while (totalLimit === undefined || interviewTemplates.length < totalLimit) {
      const response = await InterviewPlanner.request<ListInterviewTemplatesData>('GET', '/interview-templates', null, paginatedQuery);
      total = response.total;
      if (response.interview_templates.length === 0) {
        break;
      }

      interviewTemplates.push(...response.interview_templates);
      totalLimit = query.limit! < total ? query.limit : total;
      paginatedQuery.offset = paginatedQuery.offset + paginatedQuery.limit;
    }

    return { interview_templates: interviewTemplates, total };
  }, options);
};

export interface CreateInterviewTemplatePayload {
  name: string;
  description?: string;
  duration_minutes: number;
  live_coding_enabled?: boolean;
  candidate_facing_name?: string;
  candidate_facing_details?: string;
  /**
   * @deprecated
   */
  position?: number;
  positions?: number[];
  time_window_start?: string;
  time_window_end?: string;
  interviewer_templates?: {
    description?: string;
    optional: boolean;
    include_past_interviewers: boolean;
    interviewer_filters: {
      interviewer_filter_expressions: {
        filterable_id: string;
        filterable_type: `${InterviewerFilterableType}`;
        negated: boolean;
      }[];
    }[];
  }[];
}

interface CreateInterviewTemplateMutationVariables {
  payload?: CreateInterviewTemplatePayload;
}

export const useCreateInterviewTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation<InterviewTemplate, Error, CreateInterviewTemplateMutationVariables>(({ payload }) => {
    return InterviewPlanner.request('POST', '/interview-templates', payload, null, false);
  }, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKey.ListInterviewTemplates]);
      queryClient.setQueryData([QueryKey.RetrieveInterviewTemplate, data.id], data);
    },
  });
};

export interface UpdateInterviewTemplatePayload {
  archived?: boolean;
  name?: string;
  duration_minutes?: number;
  live_coding_enabled?: boolean;
  candidate_facing_name?: string;
  candidate_facing_details?: string;
  /**
   * @deprecated
   */
  position?: number;
  positions?: number[]; // Use [] to clear it out.
  time_window_start?: string;
  time_window_end?: string;
  interviewer_templates?: {
    description?: string;
    optional: boolean;
    interviewer_filters: {
      interviewer_filter_expressions: {
        filterable_id: string;
        filterable_type: `${InterviewerFilterableType}`;
        negated: boolean;
      }[];
    }[];
  }[];
}

interface UpdateInterviewTemplateMutationVariables {
  id: string;
  payload?: UpdateInterviewTemplatePayload;
}

export const useUpdateInterviewTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation<InterviewTemplate, Error, UpdateInterviewTemplateMutationVariables>(({ id, payload }) => {
    return InterviewPlanner.request('POST', `/interview-templates/${id}`, payload, null, false);
  }, {
    onSuccess: (data: InterviewTemplate) => {
      queryClient.invalidateQueries([QueryKey.ListInterviewTemplates]);
      queryClient.setQueryData([QueryKey.RetrieveInterviewTemplate, data.id], data);
    },
  });
};
