import { isEmpty, isEqual, sortBy } from 'lodash';

import type { InterviewerTemplate, EditableInterviewerTemplate, RoomFilter, RoomFilterExpressionFilterableType, Stage } from 'types';
import type { ScheduleWithType } from '../types';
import type { StageResourceUpdate } from './types';

export const calculateSuggestedUpdatesToStageSettings = (resource: ScheduleWithType, stage: Stage): StageResourceUpdate[] => {
  const changes: StageResourceUpdate[] = [];

  if (resource.type === 'self_scheduling_link') {
    // TODO
    //
  }

  if (resource.type === 'schedule') {
    // Check each scheduled interview to see if it differs from stage settings.
    (resource.interviews || []).forEach((interview) => {
      const stageInterview = stage.stage_interviews?.find((stageInterview) => stageInterview.id === interview.stage_interview_id);

      // Check if the stage interview needs to be updated or created.
      const stageInterviewUpdate: StageResourceUpdate = {
        name: interview.name,
        type: 'stage_interview',
        id: stageInterview?.id,
        updates: calculateSuggestedUpdates(['feedback_form_id'], interview, stageInterview),
      };
      const interviewTemplateUpdates = calculateSuggestedUpdates([
        'duration_minutes',
        'positions',
        'time_window_start',
        'time_window_end',
        'live_coding_enabled',
        'candidate_facing_details', // TODO - Fix: This isn't returned in the interview templates list endpoint.
        'candidate_facing_name',
      ], interview.interview_template, stageInterview?.interview_template);

      // Calculate differences between interviewer templates.
      const potentialInterviewerTemplateUpdates = constructInterviewerTemplatePayloads((interview.interviewers || []).map(({ interviewer_template }) => interviewer_template));
      if (!isEqual(potentialInterviewerTemplateUpdates, constructInterviewerTemplatePayloads(stageInterview?.interview_template?.interviewer_templates || []))) {
        interviewTemplateUpdates.interviewer_templates = potentialInterviewerTemplateUpdates;
      }

      if (!isEmpty(interviewTemplateUpdates)) {
        // Since we can't reliably tell which template an inline interview template originated from,
        // this is a way to predict it with a reasonably high likelihood.
        interviewTemplateUpdates.name = interview.interview_template.name;
        stageInterviewUpdate.updates.interview_template = interviewTemplateUpdates;
      }

      if (!isEmpty(stageInterviewUpdate.updates)) {
        changes.push(stageInterviewUpdate);
      }
    });
  }

  const scheduleTemplateUpdate: StageResourceUpdate = {
    type: 'schedule_template',
    id: stage.schedule_template?.id,
    updates: calculateSuggestedUpdates([
      'video_conferencing_enabled',
    ], resource.schedule_template, stage.schedule_template),
  };

  const potentialRoomFilterUpdates = constructRoomFilterPayloads(resource.schedule_template.room_filters || []);
  if (!isEqual(potentialRoomFilterUpdates, constructRoomFilterPayloads(stage.schedule_template?.room_filters || []))) {
    scheduleTemplateUpdate.updates.room_filters = potentialRoomFilterUpdates;
  }

  if (!isEmpty(scheduleTemplateUpdate.updates)) {
    changes.push(scheduleTemplateUpdate);
  }

  return changes;
};

const calculateSuggestedUpdates = (keys: string[], currentSettings: { [key: string]: any }, defaultSettings?: { [key: string]: any }): { [key: string]: any } => {
  const diff: { [key: string]: any } = {};
  keys.forEach((key) => {
    if (!isEqual(currentSettings[key], defaultSettings?.[key])) {
      diff[key] = currentSettings[key];
    }
  });
  return diff;
};

const constructRoomFilterPayloads = (roomFilters: RoomFilter[]): {
  room_filter_expressions: {
    negated: boolean;
    filterable_id: string;
    filterable_type: `${RoomFilterExpressionFilterableType}`;
  }[];
}[] => {
  return sortBy(roomFilters, 'position').map((roomFilter) => ({
    room_filter_expressions: roomFilter.room_filter_expressions.map((expression) => ({
      filterable_id: expression.filterable_id,
      filterable_type: expression.filterable_type,
      negated: expression.negated,
    })),
  }));
};

export const constructInterviewerTemplatePayloads = (interviewerTemplates: InterviewerTemplate[]): EditableInterviewerTemplate[] => {
  return interviewerTemplates.map((interviewerTemplate) => ({
    optional: interviewerTemplate.optional,
    include_past_interviewers: interviewerTemplate.include_past_interviewers,
    interviewer_filters: (interviewerTemplate.interviewer_filters ? (sortBy(interviewerTemplate.interviewer_filters, 'position')).map((interviewerFilter) => ({
      interviewer_filter_expressions: (interviewerFilter.interviewer_filter_expressions || []).map((expression) => ({
        filterable_id: expression.filterable_id,
        filterable_type: expression.filterable_type,
        negated: expression.negated,
      })),
    })) : null),
  }));
};
