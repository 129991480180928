import Moment from 'moment-timezone';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import AllowedTimesSummary from 'components/library/data-display/AllowedTimesSummary';
import Table from 'components/library/data-display/Table';
import AllowedTimesInput from 'components/library/inputs/AllowedTimesInput';
import Section from 'components/library/layout/Section';
import Flash from 'components/library/utils/Flash';
import { useSelfSchedulingLink, useUpdateSelfSchedulingLink } from 'hooks/queries/self-scheduling-links';
import { formatDuration } from 'libraries/formatters';

import type { UpdateSelfSchedulingLinkPayload } from 'hooks/queries/self-scheduling-links';
import type { EditableAllowedTime, SelfSchedulingLink } from 'types';
import type { TableSchema } from 'components/library/data-display/Table';

function replaceAllowedTimesWithLocalTimezone (allowedTimes: EditableAllowedTime[], timezone: string): EditableAllowedTime[] {
  return allowedTimes.map(({ start_time, end_time }) => {
    const startDate = Moment.tz(start_time, timezone).format('YYYY-MM-DD');
    const endDate = Moment.tz(end_time, timezone).format('YYYY-MM-DD');
    return {
      start_time: Moment(startDate).startOf('day').toISOString(),
      end_time: Moment(endDate).endOf('day').toISOString(),
    };
  });
}

const SelfSchedulingLinkPreferencesSection = () => {
  const { scheduleId } = useParams<{ id: string; scheduleId: string }>();

  const { data: selfSchedulingLink } = useSelfSchedulingLink(scheduleId);

  const updateSelfSchedulingLinkMutation = useUpdateSelfSchedulingLink();

  const timezone = selfSchedulingLink?.schedule_template.business_hours?.[0].timezone || Moment.tz.guess();
  const [isEditing, setIsEditing] = useState(false);
  const [allowedTimes, setAllowedTimes] = useState<EditableAllowedTime[]>(replaceAllowedTimesWithLocalTimezone(selfSchedulingLink?.schedule_template.allowed_times || [], timezone));

  useEffect(() => {
    if (selfSchedulingLink?.schedule_template) {
      setAllowedTimes(replaceAllowedTimesWithLocalTimezone(selfSchedulingLink.schedule_template.allowed_times || [], timezone));
    }
  }, [scheduleId, Boolean(selfSchedulingLink?.schedule_template), timezone]);

  const handleEdit = () => {
    if (allowedTimes.length === 0) {
      setAllowedTimes([{ start_time: '', end_time: '' }]);
    }
    setIsEditing(true);
  };

  const handleCancel = () => {
    setAllowedTimes(replaceAllowedTimesWithLocalTimezone(selfSchedulingLink?.schedule_template.allowed_times || [], timezone));
    setIsEditing(false);
    updateSelfSchedulingLinkMutation.reset();
  };

  const handleSave = async () => {
    updateSelfSchedulingLinkMutation.reset();

    const payload: UpdateSelfSchedulingLinkPayload = {
      schedule_template: {
        allowed_times: allowedTimes
        .filter(({ start_time, end_time }) => Boolean(start_time) && Boolean(end_time))
        .map(({ start_time, end_time }) => ({
          start_time: Moment(start_time).clone().tz(timezone).startOf('day').toISOString(),
          end_time: Moment(end_time).clone().tz(timezone).endOf('day').toISOString(),
        })),
      },
    };

    try {
      await updateSelfSchedulingLinkMutation.mutateAsync({ id: scheduleId, payload });
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  /* eslint-disable react/display-name */
  const schema = useMemo<TableSchema<SelfSchedulingLink>>(() => [{
    header: 'Allowed Dates',
    displayValue: ({ schedule_template }) => <AllowedTimesSummary allowedTimes={schedule_template.allowed_times || []} timezone={timezone} />,
    displayEditValue: () => (
      <AllowedTimesInput
        allowedTimes={allowedTimes}
        setAllowedTimes={setAllowedTimes}
      />
    ),
  }, Boolean(selfSchedulingLink?.schedule_template.self_scheduling_advanced_notice_hours) && {
    header: 'Advanced Notice',
    displayValue: ({ schedule_template }) => formatDuration(schedule_template.self_scheduling_advanced_notice_hours * 60),
  }, {
    header: 'Rescheduling',
    displayValue: ({ schedule_template }) => (
      schedule_template.self_scheduling_reschedule_enabled ?
        `Allowed${schedule_template.self_scheduling_reschedule_notice_hours ? ` unless it's ${formatDuration(schedule_template.self_scheduling_reschedule_notice_hours * 60)} before scheduled time` : ''}` :
        'Not allowed'
    ),
  }, {
    header: 'Cancellations',
    displayValue: ({ schedule_template }) => (
      schedule_template.self_scheduling_cancel_enabled ?
        `Allowed${schedule_template.self_scheduling_cancel_notice_hours ? ` unless it's ${formatDuration(schedule_template.self_scheduling_cancel_notice_hours * 60)} before scheduled time` : ''}` :
        'Not allowed'
    ),
  }], [
    allowedTimes,
    selfSchedulingLink,
    timezone,
  ]);

  if (!selfSchedulingLink) {
    return null;
  }

  return (
    <Section
      className="summary-section"
      isEditable
      isEditing={isEditing}
      isSaving={updateSelfSchedulingLinkMutation.isLoading}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Preferences"
    >
      <Flash
        message={updateSelfSchedulingLinkMutation.error?.message}
        showFlash={updateSelfSchedulingLinkMutation.isError}
        type="danger"
      />
      <Flash
        isDismissible
        message="Successfully updated!"
        onDismiss={updateSelfSchedulingLinkMutation.reset}
        showFlash={updateSelfSchedulingLinkMutation.isSuccess}
        type="success"
      />
      <Table
        data={[selfSchedulingLink]}
        isEditing={isEditing}
        layout="horizontal"
        schema={schema.filter(Boolean)}
      />
    </Section>
  );
};

export default SelfSchedulingLinkPreferencesSection;
