import Moment from 'moment-timezone';
import { flatten, mergeWith, orderBy, range, unionBy, uniqBy } from 'lodash';

import { ConflictType } from '../types';
import { isConflict } from '../../../../../../libraries/schedule';
import { isTraineeSlot } from '../../../../../../libraries/training';

import type { Conflict, GenerateSchedulesPayloadBase, Schedule, ScheduleOption, ScheduleOptionWithTimezone, StageInterview } from '../types';
import type { ConflictTotals, GenerateScheduleOption } from '../../../../../../hooks/queries/schedules';
import type { EventsCache } from '../../../../../../hooks/use-events';
import type { EventsItem } from '../../../../../../hooks/use-events';
import type { ReactNode } from 'react';
import type { User } from '../../../../../../types';

export const constructGeneratePayload = (schedule: Schedule): GenerateSchedulesPayloadBase => ({
  application_id: schedule.application_id,
  availabilities: schedule.availabilities.map((availability) => ({
    start_time: Moment(availability.start_time).format(),
    end_time: Moment(availability.end_time).format(),
  })),
  minimum_minutes_between_blocks: 60,
  timezone: schedule.timezone,
  schedule_template: {
    business_hours: schedule.schedule_template.business_hours.map((bh) => ({
      day: bh.day,
      start_time: bh.start_time || '00:00',
      end_time: bh.end_time || '24:00',
      // All business hours should have a timezone by this point.
      timezone: bh.timezone!,
    })),
    room_filters: schedule.schedule_template.room_filters,
    video_conferencing_enabled: schedule.schedule_template.video_conferencing_enabled,
    zoom_host_filters: schedule.schedule_template.zoom_host_filters,
  },
  stage_interviews: schedule.stage_interviews.filter(({ in_schedule }) => in_schedule).map((stageInterview) => ({
    id: stageInterview.id,
    name: stageInterview.name,
    interview_template: {
      name: stageInterview.interview_template.name || stageInterview.name,
      duration_minutes: stageInterview.interview_template.duration_minutes,
      live_coding_enabled: stageInterview.interview_template.live_coding_enabled,
      positions: stageInterview.interview_template.positions,
      time_window_start: stageInterview.interview_template.time_window_start,
      time_window_end: stageInterview.interview_template.time_window_end,
      candidate_facing_name: stageInterview.interview_template.candidate_facing_name,
      candidate_facing_details: stageInterview.interview_template.candidate_facing_details,
      interviewer_templates: (stageInterview.interview_template.interviewer_templates || []).map((interviewerTemplate) => ({
        description: interviewerTemplate.description,
        optional: interviewerTemplate.optional,
        include_past_interviewers: interviewerTemplate.include_past_interviewers,
        interviewer_filters: interviewerTemplate.interviewer_filters.map((interviewerFilter) => ({
          interviewer_filter_expressions: (interviewerFilter.interviewer_filter_expressions || []).map((interviewerFilterExpression) => ({
            filterable_id: interviewerFilterExpression.filterable_id,
            filterable_type: interviewerFilterExpression.filterable_type,
            negated: interviewerFilterExpression.negated,
          })),
        })),
      })),
    },
    feedback_form_id: stageInterview.feedback_form_id,
  })),
});

export const getZoomHostConflicts = (schedule: ScheduleOptionWithTimezone, zoomHostMeetings: Record<string, EventsItem>): Conflict[] => {
  const conflicts: Conflict[] = [];
  (schedule?.blocks || []).forEach((block) => {
    const meetings = zoomHostMeetings?.[block.selected_zoom_host?.id!]?.meetings || [];
    block.interviews.forEach((interview) => {
      meetings.forEach((event) => {
        if (isConflict(event, interview, schedule, [])) {
          conflicts.push({
            event: {
              id: event.id,
              start_time: event.start_time,
              end_time: Moment.tz(event.start_time, event.timezone).add(event.duration, 'minutes').format(),
              title: event.topic,
              buffer_time: event.buffer_time,
            },
            interview: {
              name: interview.name,
              start_time: interview.start_time,
              duration_minutes: interview.interview_template.duration_minutes,
            },
            resourceId: block.selected_zoom_host!.id!,
            type: block.selected_zoom_host!.type!,
            isZoomConflict: true,
          });
        }
      });
    });
  });
  return conflicts;
};

export const getEventsForDates = (events: EventsCache, dates: string[], timezone: string, resourceType: 'rooms' | 'users' | 'zoom_hosts'): Record<string, EventsItem> => {
  return mergeWith(
    {},
    ...dates.map((date) => events?.[date]?.[timezone]?.[resourceType] || {}),
    (date1ResourceData: EventsItem, date2ResourceData: EventsItem) => {
      if (date1ResourceData && date2ResourceData) {
        return {
          id: date1ResourceData.id,
          events: unionBy(
            (date1ResourceData.events || []),
            (date2ResourceData.events || []),
            'id',
          ),
          meetings: unionBy(
            (date1ResourceData.meetings || []),
            (date2ResourceData.meetings || []),
            'id',
          ),
        };
      }
    }
  );
};

export const getConflicts = (schedule: ScheduleOptionWithTimezone, userEvents: Record<string, EventsItem>, roomEvents: Record<string, EventsItem>, interviewerEventICalUIds: string[], users: Record<string, User>): Conflict[] => {
  return flatten((schedule?.blocks || []).flatMap((block) => block.interviews.map((interview) => {
    const events = roomEvents?.[block.selected_room?.room_id!]?.events || [];
    const roomConflicts = events.filter((event) => isConflict(event, interview, schedule, interviewerEventICalUIds, false)).map((event) => ({
      event,
      interview: {
        name: interview.name,
        start_time: interview.start_time,
        duration_minutes: interview.interview_template.duration_minutes,
      },
      resourceId: block.selected_room!.room_id!,
      type: ConflictType.Room,
    }));

    const requiredInterviewers = interview.interviewers.filter(({ interviewer_template }) => !interviewer_template.optional && !isTraineeSlot(interviewer_template.interviewer_filters));
    const interviewerConflicts = flatten(requiredInterviewers.map(({ selected_user }) => {
      const events = userEvents && userEvents[selected_user.user_id!] && userEvents[selected_user.user_id!].events || [];

      return events.filter((event) => isConflict(event, interview, schedule, interviewerEventICalUIds, false, users[selected_user.user_id!]?.email)).map((event) => ({
        event,
        interview: {
          name: interview.name,
          start_time: interview.start_time,
          duration_minutes: interview.interview_template.duration_minutes,
        },
        resourceId: selected_user.user_id!,
        type: ConflictType.Interviewer,
      }));
    }));

    return [
      ...roomConflicts,
      ...interviewerConflicts,
    ];
  })));
};

export const getOptionalInterviewerConflicts = (schedule: ScheduleOptionWithTimezone, userEvents: Record<string, EventsItem>, interviewerEventICalUIds: string[], users: Record<string, User>): Conflict[] => {
  const interviews = flatten((schedule.blocks || []).map((block) => block.interviews));
  return flatten(interviews.map((interview) => {
    const optionalInterviewers = interview.interviewers.filter(({ interviewer_template }) => interviewer_template.optional || isTraineeSlot(interviewer_template.interviewer_filters));
    return flatten(optionalInterviewers.map(({ selected_user }) => {
      const events = userEvents?.[selected_user.user_id!]?.events || [];

      return events.filter((event) => isConflict(event, interview, schedule, interviewerEventICalUIds, false, users[selected_user.user_id!]?.email)).map((event) => ({
        event,
        interview: {
          name: interview.name,
          start_time: interview.start_time,
          duration_minutes: interview.interview_template.duration_minutes,
        },
        resourceId: selected_user.user_id!,
        type: ConflictType.Interviewer,
      }));
    }));
  }));
};

export const formatInterviewNamesToList = (names: string[]): ReactNode => (
  <span>
    {names.map((name, i) => (
      <span key={`interview-name-${i}`}>
        {i !== 0 && names.length > 2 && ','}
        {i !== 0 && i === names.length - 1 && ' and'}
        <b> {name}</b>
      </span>
    ))}
  </span>
);

export const transformGeneratedSchedulesForOptionsViewer = (schedules: GenerateScheduleOption[], stage: Schedule['stage']): ScheduleOption[] => schedules.map((scheduleOption) => ({
  ...scheduleOption,
  stage,
}));

export const calculatePossibleNumberOfScheduleBlocks = (interviews: StageInterview[], /* availabilities */): number[] => {
  const possibleNumbersOfBlocks: number[] = [];

  range(1, interviews.length + 1).forEach((numberOfBlocks) => {
    // TODO: Validate whether there is a schedule with the numberOfBlocks
    // that fits in the candidate's availability slots.
    possibleNumbersOfBlocks.push(numberOfBlocks);
  });

  return possibleNumbersOfBlocks;
};

/**
 * This function is a recreation of the sortMatchings function in the backend located at pkg/schedulesv2/sorting.go.
 */
export const sortScheduleOptions = (options: ScheduleOption[], timezone: string, preferShorterBlockGap: boolean): ScheduleOption[] => {
  const sortedScheduleOptions: ScheduleOption[] = orderBy(options, [
    // Conflicts.
    (option) => {
      return option.conflicts;
    },
    // Block variance.
    (option) => {
      if (option.blocks.length <= 1) {
        // If these are single-block options, we don't need to calculate anything.
        return 1;
      }
      const slotCounts = option.blocks.map((block) => block.interviews.length);
      const sum = slotCounts.reduce((total, count) => total + count, 0);
      const mean = sum / slotCounts.length;
      const variance = slotCounts.reduce((total, count) => total + (count - mean) * (count - mean), 0);
      return variance / slotCounts.length;
    },
    // Block day span.
    (option) => {
      if (option.blocks.length <= 1) {
        // If these are single-block options, we don't need to calculate anything.
        return 1;
      }

      let count = 1;
      let lastDay = Moment(option.blocks[0].interviews[0].start_time).tz(timezone);

      for (let i = 1; i < option.blocks.length; i++) {
        const block = option.blocks[i];
        const day = Moment(block.interviews[0].start_time).tz(timezone);
        if (!day.isSame(lastDay, 'day')) {
          count++;
          lastDay = day;
        }
      }

      return count;
    },
    // Block gap.
    (option) => {
      if (option.blocks.length <= 1) {
        // If these are single-block options, we don't need to calculate anything.
        return 1;
      }
      if (!preferShorterBlockGap) {
        // If the feature flag to prefer shorter block gaps isn't enabled, we don't need to calculate anything.
        return 1;
      }

      let totalGapMinutes = 0;

      for (let i = 0; i < option.blocks.length - 1; i++) {
        const block1 = option.blocks[i];
        const block2 = option.blocks[i + 1];
        const gap = Moment(block2.interviews[0].start_time).tz(timezone).diff(Moment(block1.interviews[0].start_time).tz(timezone), 'minutes');
        totalGapMinutes += gap;
      }

      return Math.round(totalGapMinutes / (option.blocks.length - 1) / 60 / 24);
    },
    // Start times.
    (option) => {
      // We want to sort based on the start times of each block. If the first blocks both have the same start time, then
      // we move onto the next block and so on. To do this in a single function, we join all the start times (which are
      // as unix timestamps) into a single string. They will be sorted lexicographically and do what we expect it to do.
      return option.blocks.map((block) => Moment(block.interviews[0].start_time).unix()).join(':');
    },
  ], [
    // Conflicts.
    'asc',
    // Block variance.
    'asc',
    // Block day span.
    'desc',
    // Block gap.
    'asc',
    // Start times.
    'asc',
  ]);

  return uniqBy(sortedScheduleOptions, (option) => option.blocks.map((block) => Moment(block.interviews[0].start_time).unix()).join(':'));
};

export const mergeConflictTotals = (existingTotals: ConflictTotals, newTotals: ConflictTotals): ConflictTotals => {
  const output: ConflictTotals = {
    ...existingTotals,
  };
  for (const key in newTotals) {
    output[key] = ((output[key] || 0) + newTotals[key]);
  }
  return output;
};
