import ReactModal from 'react-modal';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';

import Button, { ButtonColor } from '../inputs/Button';
import LoadingSpinner from '../utils/LoadingSpinner';
import Tooltip from '../utils/Tooltip';

import type { MouseEventHandler, ReactNode } from 'react';

interface Props {
  brandColor?: string;
  cancelButtonValue?: string;
  children: ReactNode;
  className?: string;
  isOpen: boolean;
  isSubmitting?: boolean;
  onCancel?: MouseEventHandler<HTMLButtonElement>;
  onSubmit?: MouseEventHandler<HTMLButtonElement>;
  onToggle?: MouseEventHandler<HTMLButtonElement>;
  showSubmitButton?: boolean;
  submitButtonColor?: `${ButtonColor}`;
  submitButtonIsDisabled?: boolean;
  submitButtonValue?: ReactNode;
  submittingButtonValue?: ReactNode;
  title?: string;
}

const Modal = ({
  brandColor,
  cancelButtonValue = 'Cancel',
  children,
  className,
  isOpen,
  isSubmitting,
  onCancel,
  onSubmit,
  onToggle,
  showSubmitButton = true,
  submitButtonColor = ButtonColor.GemBlue,
  submitButtonIsDisabled = false,
  submitButtonValue = 'Submit',
  submittingButtonValue,
  title,
}: Props) => {
  const customStyles = useMemo(() => {
    if (!brandColor) {
      return {};
    }
    return {
      content: {
        border: `1px solid ${brandColor}`,
      },
    };
  }, [brandColor]);

  return (
    <ReactModal
      appElement={document.getElementById('root') || undefined}
      className={classNames('modal', className)}
      closeTimeoutMS={250}
      isOpen={isOpen}
      onRequestClose={onToggle}
      overlayClassName="modal-overlay"
      shouldCloseOnOverlayClick
      style={customStyles}
    >
      <div className="modal-header">
        <h3>{title}</h3>
        <Button
          className="btn-delete"
          color="gray"
          iconRight={<FontAwesomeIcon icon={faTimes} />}
          onClick={onToggle}
          size="large"
          tooltip={
            <Tooltip
              id="modal-close-tooltip"
              position="right"
              value="Close"
            />
          }
        />
      </div>
      <div className="modal-body">
        {children}
      </div>
      <div className="modal-footer">
        <Button
          brandColor={brandColor}
          color="no-outline"
          isDisabled={isSubmitting}
          onClick={onCancel || onToggle}
          size="large"
          value={cancelButtonValue}
        />
        {showSubmitButton && onSubmit && (
          isSubmitting ?
            <Button
              brandColor={brandColor}
              color={submitButtonColor}
              iconRight={<LoadingSpinner />}
              isDisabled
              size="large"
              value={submittingButtonValue}
            /> :
            <Button
              brandColor={brandColor}
              color={submitButtonColor}
              isDisabled={submitButtonIsDisabled}
              onClick={onSubmit}
              size="large"
              value={submitButtonValue}
            />
        )}
      </div>
    </ReactModal>
  );
};

export default Modal;
