import { createContext, useContext, useMemo } from 'react';

import type { ReactNode } from 'react';

interface ColorContextState {
  color: string;
  setColor: (value: { hex: string } | null) => void;
}

const ColorContext = createContext<ColorContextState>({
  color: '',
  setColor: () => {},
});

interface Props {
  children: ReactNode;
  color: string;
  setColor: (value: { hex: string } | null) => void;
}

export const ColorProvider = ({ children, color, setColor }: Props) => {
  const contextValue = useMemo<ColorContextState>(() => ({
    color,
    setColor,
  }), [color, setColor]);

  return (
    <ColorContext.Provider value={contextValue}>
      {children}
    </ColorContext.Provider>
  );
};

export const useColor = () => {
  return useContext(ColorContext);
};
