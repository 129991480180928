import Moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';
import { faUnlink } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';

import Avatar from '../../../../../library/data-display/Avatar';
import CopyToClipboardButton from '../../../../../library/inputs/CopyToClipboardButton';
import Flash from '../../../../../library/utils/Flash';
import ListItem from '../../../../../library/data-display/ListItem';
import LoadingSpinner from '../../../../../library/utils/LoadingSpinner';
import Section from '../../../../../library/layout/Section';
import Table from '../../../../../library/data-display/Table';
import Tooltip from '../../../../../library/utils/Tooltip';
import { formatMoment, TimeFormat } from '../../../../../../libraries/time';
import { useApplication } from '../../../../../../hooks/queries/applications';
import { useCalendars } from '../../../../../../hooks/queries/calendars';
import { useSelfSchedulingLink } from '../../../../../../hooks/queries/self-scheduling-links';
import { useUsersMap } from '../../../../../../hooks/queries/users';

import type { SelfSchedulingLink } from '../../../../../../types';
import type { TableSchema } from '../../../../../library/data-display/Table';
import { correctPath } from 'libraries/gem';

const SelfSchedulingLinkSummarySection = () => {
  const { id, scheduleId } = useParams<{ id: string; scheduleId: string }>();

  const {
    data: calendars,
    error: calendarsError,
    isLoading: isCalendarsLoading,
  } = useCalendars();
  const { data: application } = useApplication(id);
  const { data: selfSchedulingLink } = useSelfSchedulingLink(scheduleId);
  const users = useUsersMap({ archived: true });

  const schema = useMemo<TableSchema<SelfSchedulingLink>>(() => [{
    header: (
      <span className="self-scheduling-link-header">
        Link
        {selfSchedulingLink && (selfSchedulingLink.stage_id !== application?.current_stage_id || selfSchedulingLink.status === 'cancelled') && (
          <>
            <span data-for={`${selfSchedulingLink.id}-link-expired`} data-tip>
              <FontAwesomeIcon icon={faUnlink} />
            </span>
            <Tooltip
              id={`${selfSchedulingLink.id}-link-expired`}
              position="top"
              value={selfSchedulingLink.status === 'cancelled' ? 'Cancelled' : 'Expired'}
            />
          </>
        )}
      </span>
    ),
    displayValue: ({ id, stage_id, status }) => (
      <div className="schedule-link">
        <Link
          className={(stage_id !== application?.current_stage_id || status === 'cancelled') ? 'expired' : undefined}
          target="_blank"
          to={correctPath(`/schedule/${id}`)}
        >
          {window.origin}{correctPath(`/schedule/${id}`)}
        </Link>
        {stage_id === application?.current_stage_id && status !== 'cancelled' && (
          <CopyToClipboardButton textToCopy={`${window.origin}${correctPath(`/schedule/${id}`)}`} />
        )}
      </div>
    ),
  }, {
    header: 'Scheduling Calendar',
    displayValue: ({ scheduling_calendar_email }) => {
      if (calendarsError) {
        return (
          <Flash
            message={calendarsError.message}
            showFlash
            type="danger"
          />
        );
      }

      if (isCalendarsLoading) {
        return <LoadingSpinner />;
      }

      // TODO: Display a better error. This can happen if the current user
      // doesn't have access to the scheduling calendar of this schedule.
      return calendars?.calendars[scheduling_calendar_email]?.name || 'Unknown';
    },
  }, {
    header: 'Scheduling Calendar for Candidate Events',
    displayValue: ({ candidate_scheduling_calendar_email }) => {
      if (calendarsError) {
        return (
          <Flash
            message={calendarsError.message}
            showFlash
            type="danger"
          />
        );
      }

      if (isCalendarsLoading) {
        return <LoadingSpinner />;
      }

      // TODO: Display a better error. This can happen if the current user
      // doesn't have access to the candidate scheduling calendar of this schedule.
      return calendars?.calendars[candidate_scheduling_calendar_email]?.name || 'Unknown';
    },
  }, {
    header: 'Automated Email Follow-up',
    displayValue: ({ schedule_template }) => (
      schedule_template.self_scheduling_email_follow_up_templates?.[0]?.email_follow_up ?
        schedule_template.self_scheduling_email_follow_up_templates[0].email_follow_up.email?.sent_at ?
          `Sent at ${formatMoment(Moment(schedule_template.self_scheduling_email_follow_up_templates[0].email_follow_up.email.sent_at).tz(Moment.tz.guess()), TimeFormat.LongMonthDayYearWithTimeAndTimezone)}` :
          schedule_template.self_scheduling_email_follow_up_templates[0].email_follow_up.scheduled_time ?
            Moment(schedule_template.self_scheduling_email_follow_up_templates[0].email_follow_up.scheduled_time).isAfter(Moment()) ?
              `Will be sent at ${formatMoment(Moment(schedule_template.self_scheduling_email_follow_up_templates[0].email_follow_up.scheduled_time).tz(Moment.tz.guess()), TimeFormat.LongMonthDayYearWithTimeAndTimezone)}` :
              'Sending now' :
            'Follow-up cancelled' :
        'Not enabled'
    ),
  }, {
    header: 'Date Requested',
    displayValue: ({ created_at, creator_id, automatic_send_log_id }) => (
      <div className="date-requested">
        <span>{automatic_send_log_id ? 'Automatically requested' : 'Requested'} on {formatMoment(Moment(created_at), TimeFormat.LongMonthDayYear)}{creator_id && ' by'}</span>
        {creator_id &&
          <ListItem
            label={users[creator_id]?.name || users[creator_id]?.email}
            leftIcon={<Avatar
              showUserTooltip={false}
              size="small"
              userId={creator_id}
            />}
          />
        }
      </div>
    ),
  }], [
    calendars,
    calendarsError,
    isCalendarsLoading,
    selfSchedulingLink,
    users,
  ]);

  if (!selfSchedulingLink) {
    return null;
  }

  return (
    <Section className="summary-section" title="Summary">
      <Table
        data={[selfSchedulingLink]}
        layout="horizontal"
        schema={schema}
      />
    </Section>
  );
};

export default SelfSchedulingLinkSummarySection;
