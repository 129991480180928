import styled from 'styled-components';
import CheckboxInput from '../../library/inputs/CheckboxInput';
import Flash from 'components/library/utils/Flash';

export const StyledContainer = styled.div`
  padding-bottom: 50px;
  width: 100%;

  .flash {
    margin-bottom: 1em;
  }
`;

export const StyledInfoFlash = styled(Flash)`
  margin: 2em;
`;

export const StyledCheckboxInput = styled(CheckboxInput)`
  margin: 1em 0;

  /* These are styles so the New label looks good. */
  .input-label {
    align-items: center;
    display: flex;

    .label {
      margin-left: 1em;
    }
  }
`;
