import { kebabCase } from 'lodash';

import {
  StyledFigure,
  StyledKicker,
  StyledKickerIcon,
  StyledSection,
  StyledText,
  StyledTextContainer,
} from './styles';

import type { Feature } from '../types';

interface Props extends Feature{}

const FeatureSection = ({ kicker, kickerIcon, title, description, figure }: Props) => {
  return (
    <StyledSection id={kebabCase(title)}>
      <StyledTextContainer>
        {(kicker || kickerIcon) && (
          <StyledKicker>
            {kickerIcon && <StyledKickerIcon>{kickerIcon}</StyledKickerIcon>}
            {kicker}
          </StyledKicker>
        )}
        <h2>
          {title}
        </h2>
        <StyledText>
          {description}
        </StyledText>
      </StyledTextContainer>
      <StyledFigure>
        {figure}
      </StyledFigure>
    </StyledSection>
  );
};

export default FeatureSection;
