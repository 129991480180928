import styled from 'styled-components';

export const StyledKicker = styled.h6`
  display: flex;
  align-items: center;
  margin-top: 0;
  gap: 1em;
`;

export const StyledKickerIcon = styled.div`
  color: ${({ theme }) => theme.colors.curiousBlue};

  & .svg-inline--fa {
    font-size: 1.2em;
  }
`;

export const StyledSection = styled.section`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 60px;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
    padding: 0;
    padding-top: 2em;
  }
`;

export const StyledTextContainer = styled.div`
  max-width: 550px;
  width: 50%;

  @media only screen and (max-width: 800px) {
    max-width: 90%;
    width: 90%;
  }
`;

export const StyledText = styled.p`
  color: ${({ theme }) => theme.colors.doveGray};
  line-height: 2em;
`;

export const StyledFigure = styled.figure`
  max-width: 400px;
  width: 50%;

  @media only screen and (max-width: 800px) {
    max-width: 400px;
    width: 90%;
  }
`;
