import ColorPicker from './ColorPicker';
import { useColor } from './use-color';

const ColorPickerMenuList = () => {
  const { color, setColor } = useColor();

  return (
    <ColorPicker
      color={{ hex: color }}
      onChange={setColor}
      onChangeComplete={setColor}
    />
  );
};

export default ColorPickerMenuList;
