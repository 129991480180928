import { isEmpty, omit } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import CheckboxInput from 'components/library/inputs/CheckboxInput';
import ExpandableCheckboxInput from 'components/library/inputs/ExpandableCheckboxInput';
import Flash from 'components/library/utils/Flash';
import { useCreateInterviewTemplate, useUpdateInterviewTemplate } from 'hooks/queries/interview-templates';
import { useUpdateStageInterview } from 'hooks/queries/stage-interviews';
import { StyledModal, StyledUpdateOptionsContainer } from './styles';
import UpdateInterviewActionsInput from './UpdateInterviewActionsInput';
import { UpdateAction } from './types';

import type { InterviewUpdateAction, StageResourceUpdate } from './types';
import type { Stage } from 'types';
import type { CreateInterviewTemplatePayload, UpdateInterviewTemplatePayload } from 'hooks/queries/interview-templates';
import type { UpdateStageInterviewPayload } from 'hooks/queries/stage-interviews';

interface Props {
  isOpen: boolean;
  onToggle: () => void;
  suggestedUpdates: StageResourceUpdate[];
  stage: Stage;
}

const UpdateStageSettingsModal = ({ isOpen, onToggle, stage, suggestedUpdates }: Props) => {
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const [errors, setErrors] = useState<string[]>([]);
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const createInterviewTemplateMutation = useCreateInterviewTemplate();
  const updateInterviewTemplateMutation = useUpdateInterviewTemplate();
  const updateStageInterviewMutation = useUpdateStageInterview();

  // Reset errors whenever the modal is re-opened.
  useEffect(() => {
    if (isOpen) {
      setErrors([]);
    }
  }, [isOpen]);

  const interviewUpdates = useMemo(
    () => suggestedUpdates.filter(({ type }) => type === 'stage_interview'),
    [suggestedUpdates]
  );
  const scheduleTemplateUpdate = useMemo(
    () => suggestedUpdates.find(({ type }) => type === 'schedule_template'),
    [suggestedUpdates]
  );

  const [updateInterviews, setUpdateInterviews] = useState<boolean>(true);
  const [interviewUpdateActions, setInterviewUpdateActions] = useState<{ isChecked: boolean; actions: InterviewUpdateAction[] }[]>([]);
  const [updateVideoConferencing, setUpdateVideoConferencing] = useState<boolean>(true);
  const [updateRooms, setUpdateRooms] = useState<boolean>(true);
  /*
  const [updateCandidateCalendarEvent, setUpdateCandidateCalendarEvent] = useState<boolean>(true);
  const [updateConfirmationEmail, setUpdateConfirmationEmail] = useState<boolean>(false);
  const [updateHiringChannelCreation, setUpdateHiringChannelCreation] = useState<boolean>(false);
 */

  const areStageSettingsEmpty = useMemo(() => {
    if (stage.stage_interviews) {
      return !(stage.stage_interviews.some((stageInterview) => Boolean(stageInterview.interview_template) || !isEmpty(stageInterview.ats_interviewer_ids)));
    }
    return true;
  }, [stage.stage_interviews]);

  const handleSubmit = async () => {
    setIsSubmitting(true);
    setErrors([]);

    const allErrors: string[] = [];

    // Update interview templates
    const updateInterviewTemplatePayloads: { id: string; name: string; payload: UpdateInterviewTemplatePayload }[] = [];
    interviewUpdateActions.forEach(({ isChecked, actions }, i) => {
      const interviewTemplateUpdateAction = actions.find(({ action }) => action === UpdateAction.UpdateInterviewTemplate);
      if (isChecked && interviewTemplateUpdateAction?.parentTemplate && interviewUpdates[i].updates.interview_template) {
        updateInterviewTemplatePayloads.push({
          id: interviewTemplateUpdateAction.parentTemplate.id,
          name: interviewTemplateUpdateAction.parentTemplate.name,
          payload: interviewUpdates[i].updates.interview_template as UpdateInterviewTemplatePayload,
        });
      }
    });
    await Promise.all(updateInterviewTemplatePayloads.map(async ({ id, name, payload }) => {
      try {
        await updateInterviewTemplateMutation.mutateAsync({ id, payload });
      } catch (err) {
        if (err instanceof Error) {
          allErrors.push(`${name}: ${err.message}`);
        }
      }
    }));

    // Create new interview templates and attach them to the stage interviews
    const createInterviewTemplatePayloads: { stageInterviewId: string; payload: CreateInterviewTemplatePayload }[] = [];
    interviewUpdateActions.forEach(({ isChecked, actions }, i) => {
      const interviewTemplateCreateAction = actions.find(({ action }) => action === UpdateAction.CreateInterviewTemplate || action === UpdateAction.UpdateInterviewTemplateNotAllowed);
      if (isChecked && interviewTemplateCreateAction) {
        const interviewUpdate = interviewUpdates[i];
        createInterviewTemplatePayloads.push({
          stageInterviewId: interviewUpdate.id!,
          payload: {
            ...interviewUpdate.updates.interview_template as CreateInterviewTemplatePayload,
            name: interviewTemplateCreateAction.newTemplateName || interviewUpdate.updates.interview_template?.name!,
          },
        });
      }
    });
    await Promise.all(createInterviewTemplatePayloads.map(async ({ stageInterviewId, payload }) => {
      try {
        const { id } = await createInterviewTemplateMutation.mutateAsync({ payload });
        await updateStageInterviewMutation.mutateAsync({
          id: stageInterviewId,
          jobId: stage.job_id,
          stageId: stage.id,
          payload: { interview_template_id: id },
        });
      } catch (err) {
        if (err instanceof Error) {
          allErrors.push(`${payload.name}: ${err.message}`);
        }
      }
    }));

    // Update stage interviews
    const updateStageInterviewPayloads: { id: string; payload: UpdateStageInterviewPayload }[] = [];
    interviewUpdateActions.forEach(({ isChecked, actions }, i) => {
      const interviewTemplateSwapAction = actions.find(({ action }) => action === UpdateAction.SwapInterviewTemplateOnStageInterview);
      const stageInterviewUpdateAction = actions.find(({ action }) => action === UpdateAction.UpdateStageInterview);
      if (isChecked && (interviewTemplateSwapAction || stageInterviewUpdateAction)) {
        const interviewUpdate = interviewUpdates[i];
        updateStageInterviewPayloads.push({
          id: interviewUpdate.id!,
          payload: {
            ...omit(interviewUpdate.updates, 'interview_template'),
            interview_template_id: interviewTemplateSwapAction?.parentTemplate?.id,
          },
        });
      }
    });
    await Promise.all(updateStageInterviewPayloads.map(async ({ id, payload }) => {
      try {
        await updateStageInterviewMutation.mutateAsync({
          id,
          jobId: stage.job_id,
          stageId: stage.id,
          payload,
        });
      } catch (err) {
        if (err instanceof Error) {
          allErrors.push(`${payload.name}: ${err.message}`);
        }
      }
    }));

    setIsSubmitting(false);
    setErrors(allErrors);
    if (allErrors.length === 0) {
      setIsSuccess(true);
    }
  };

  if (isSuccess) {
    return (
      <StyledModal
        cancelButtonValue="Close"
        isOpen={isOpen}
        onToggle={onToggle}
        showSubmitButton={false}
        title={areStageSettingsEmpty ? 'Remember this setup for next time?' : 'Update stage setup?'}
      >
        <Flash
          message="Stage updated with new default preferences!"
          showFlash
          type="success"
        />
      </StyledModal>
    );
  }

  return (
    <StyledModal
      cancelButtonValue="Don't save"
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      onSubmit={handleSubmit}
      onToggle={onToggle}
      submitButtonValue="Save preferences"
      title={areStageSettingsEmpty ? 'Remember this setup for next time?' : 'Update stage setup?'}
    >
      <StyledUpdateOptionsContainer>
        <Flash
          message={(
            <span>
              Schedule the next <b>{stage.name}</b> for <b>{stage.job.name}</b> faster by saving default preferences for this role and stage.
            </span>
          )}
          showFlash={areStageSettingsEmpty}
          type="info"
        />
        <Flash
          message={errors?.join(', ')}
          showFlash={!isSubmitting && !isEmpty(errors)}
          type="danger"
        />
        {!isEmpty(interviewUpdates) && (
          <ExpandableCheckboxInput
            isChecked={updateInterviews}
            label="Save interview preferences."
            onChange={(e) => setUpdateInterviews(e.target.checked)}
          >
            <UpdateInterviewActionsInput
              interviewUpdateActions={interviewUpdateActions}
              setInterviewUpdateActions={setInterviewUpdateActions}
              stage={stage}
              suggestedInterviewUpdates={interviewUpdates}
            />
          </ExpandableCheckboxInput>
        )}
        {scheduleTemplateUpdate?.updates.video_conferencing_enabled !== undefined && (
          <CheckboxInput
            isChecked={updateVideoConferencing}
            label={`${scheduleTemplateUpdate.updates.video_conferencing_enabled === true ? 'Add' : 'Don\'t add'} video conferencing.`}
            onChange={(e) => setUpdateVideoConferencing(e.target.checked)}
          />
        )}
        {scheduleTemplateUpdate?.updates.room_filters && (
          <CheckboxInput
            isChecked={updateRooms}
            label="Update room preferences."
            onChange={(e) => setUpdateRooms(e.target.checked)}
          />
        )}
      </StyledUpdateOptionsContainer>
    </StyledModal>
  );
};

export default UpdateStageSettingsModal;
