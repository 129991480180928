import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { useState } from 'react';

import Button from '../Button';
import RecipientsInput from './RecipientsInput';
import SenderInput from './SenderInput';
import StatusIndicator from '../../utils/StatusIndicator';
import { HiringTeamRoleWithScheduler } from '../../../../types';
import { useSession } from 'hooks/use-session';

import type { Dispatch, SetStateAction } from 'react';

export interface AdvancedSettings {
  bcc_emails?: string[];
  cc_emails?: string[];
  sender_email: string;
  sender_name: string;
}

interface Props<T extends AdvancedSettings> {
  emailTemplate?: T;
  isDisabled?: boolean;
  setEmailTemplate: Dispatch<SetStateAction<T | undefined>>;
}

const EmailTemplateAdvancedSettings = <T extends AdvancedSettings>({
  isDisabled = false,
  emailTemplate,
  setEmailTemplate,
}: Props<T>) => {
  const { account } = useSession();

  const [isExpanded, setIsExpanded] = useState(false);

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  const handleSenderEmailChange = (email: string) => {
    setEmailTemplate((prevTemplate) => (prevTemplate ? {
      ...prevTemplate,
      sender_email: email,
    } : undefined));
  };

  const handleSenderNameChange = (name: string) => {
    setEmailTemplate((prevTemplate) => (prevTemplate ? {
      ...prevTemplate,
      sender_name: name,
    } : undefined));
  };

  const handleCcEmailsChange = (emails: string[]) => {
    setEmailTemplate((prevTemplate) => (prevTemplate ? {
      ...prevTemplate,
      cc_emails: emails,
    } : undefined));
  };

  const handleBccEmailsChange = (emails: string[]) => {
    setEmailTemplate((prevTemplate) => (prevTemplate ? {
      ...prevTemplate,
      bcc_emails: emails,
    } : undefined));
  };

  const hasAdvancedSettings = Boolean(
    emailTemplate && (
      (emailTemplate.sender_email && emailTemplate.sender_email !== HiringTeamRoleWithScheduler.Scheduler) ||
      (emailTemplate.sender_name && emailTemplate.sender_name !== HiringTeamRoleWithScheduler.Scheduler) ||
      (emailTemplate.cc_emails && emailTemplate.cc_emails.length) ||
      (emailTemplate.bcc_emails && emailTemplate.bcc_emails.length)
    )
  );

  const useInterviewPlannerEmailSettings = Boolean(account?.email_domain);

  return (
    <div className="email-template-advanced-settings">
      <div className={`button-header status${isExpanded ? ' expanded' : ''}`}>
        <Button
          color="no-outline"
          iconLeft={<StatusIndicator color={hasAdvancedSettings ? 'green' : 'gray'} />}
          onClick={toggleIsExpanded}
          size="small"
          value={<span>Advanced settings<FontAwesomeIcon icon={isExpanded ? faAngleUp : faAngleDown } /></span>}
        />
      </div>
      {isExpanded &&
        <div className="expanded-content">
          <div className="advanced-settings">
            {useInterviewPlannerEmailSettings && (
              <SenderInput
                email={emailTemplate?.sender_email}
                isDisabled={isDisabled}
                name={emailTemplate?.sender_name}
                setEmail={handleSenderEmailChange}
                setName={handleSenderNameChange}
              />
            )}
            <div className="recipients-input-container">
              <RecipientsInput
                emails={emailTemplate?.cc_emails}
                isDisabled={isDisabled}
                recipientType="cc"
                setEmails={handleCcEmailsChange}
              />
              <RecipientsInput
                emails={emailTemplate?.bcc_emails}
                isDisabled={isDisabled}
                recipientType="bcc"
                setEmails={handleBccEmailsChange}
              />
            </div>
          </div>
        </div>
      }
    </div>
  );
};

export default EmailTemplateAdvancedSettings;
