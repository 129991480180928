import Moment from 'moment-timezone';
import { find } from 'lodash';

import { RSVP, ZoomHostType } from '../../../../../../../../types';

import type { EditableInterview, EditableSchedule } from '../../../../../../../../types';
import type { Schedule as CalendarSchedule } from '../../../../../../../library/data-display/CalendarSchedule/types';
import type { EditableBusinessHour } from '../../../../../../../../types/business-hours';

// We make this new interface because we need to access both EditableSchedule
// and CalendarSchedule, but they aren't super compatible with each other. So we
// just pull out the exact values that we need for this function.
interface ScheduleForBusinessHours {
  timezone: string;
  interviews: {
    start_time: string;
    interview_template: {
      duration_minutes: number;
    };
  }[];
  schedule_template: {
    business_hours?: EditableBusinessHour[] | null;
  };
}

export const constructBusinessHours = (schedule: ScheduleForBusinessHours) => ({
  business_hours: schedule.schedule_template.business_hours?.map((bh) => ({
    ...bh,
    start_time: Moment.tz(schedule.interviews[0].start_time, schedule.timezone).format('HH:mm'),
    end_time: Moment.tz(schedule.interviews[schedule.interviews.length - 1].start_time, schedule.timezone).add(schedule.interviews[schedule.interviews.length - 1].interview_template.duration_minutes, 'minutes').format('HH:mm'),
  })),
});

// This is for our arrays of rooms and users. They ended up behaving very
// similarly, so instead of creating two types that are the same, we just made
// this single type. If they diverge in the future, feel free to split this
// apart.
interface RoomOrUser {
  id: string;
  directory_archived?: boolean;
  user_archived?: boolean;
  // The boolean is for the spliced in value for the current Zoom Host.
  video_conferencing_id?: string | boolean;
}

export const constructCalendarSchedule = (schedule: EditableSchedule, rooms: RoomOrUser[], users: RoomOrUser[]): CalendarSchedule => ({
  id: schedule.id,
  application_id: schedule.application_id,
  candidate_event_ical_uid: schedule.candidate_event_ical_uid,
  candidate_event_location: schedule.candidate_event_location,
  timezone: schedule.timezone,
  schedule_template: {
    ...schedule.schedule_template,
    ...constructBusinessHours(schedule),
  },
  stage: {
    id: schedule.stage.id,
    job_id: schedule.stage.job_id,
  },
  selected_room: {
    room_id: schedule.interviews[0].room_id || null,
  },
  potential_rooms: rooms.map((room) => ({
    room_id: room.id,
  })),
  potential_zoom_hosts: [
    ...rooms.filter(({ directory_archived, user_archived, video_conferencing_id }) => !user_archived && !directory_archived && Boolean(video_conferencing_id)).map((room) => ({ id: room.id, type: ZoomHostType.Room })),
    ...users.filter(({ directory_archived, user_archived, video_conferencing_id }) => !user_archived && !directory_archived && Boolean(video_conferencing_id)).map((user) => ({ id: user.id, type: ZoomHostType.User })),
  ],
  selected_zoom_host: (schedule.schedule_template.video_conferencing_enabled ? {
    id: schedule.interviews[0].zoom_host_id || null,
    type: schedule.interviews[0].zoom_host_type || null,
  } : {
    id: null,
    type: null,
  }),
  interviews: schedule.interviews.map((interview) => ({
    id: interview.id,
    stage_interview_id: interview.stage_interview_id,
    scorecard_stage_interview_id: interview.scorecard_stage_interview_id,
    interviewer_event_id: interview.interviewer_event_id,
    interviewer_event_ical_uid: interview.interviewer_event_ical_uid,
    name: interview.name,
    title: interview.title,
    start_time: interview.start_time,
    feedback_form_id: interview.feedback_form_id,
    interview_template: {
      name: interview.interview_template.name,
      duration_minutes: interview.interview_template.duration_minutes,
      live_coding_enabled: interview.interview_template.live_coding_enabled,
    },
    interviewers: interview.interviewers ? interview.interviewers.map((interviewer) => ({
      id: interviewer.id,
      interviewer_template: {
        id: interviewer.interviewer_template.id,
        description: interviewer.interviewer_template.description,
        optional: interviewer.interviewer_template.optional,
        include_past_interviewers: interviewer.interviewer_template.include_past_interviewers,
        interviewer_filters: (interviewer.interviewer_template.interviewer_filters || []).map((filter) => ({
          interviewer_filter_expressions: filter.interviewer_filter_expressions || [],
        })),
      },
      selected_user: {
        user_id: interviewer.user_id,
      },
      potential_users: [],
    })) : [],
  })),
});

export const constructUpdatedInterviews = (prevInterviews: EditableInterview[], calendarSchedule: CalendarSchedule): EditableInterview[] => {
  return calendarSchedule.interviews.map((interview) => {
    const matchingPrevInterview = interview.id ? find(prevInterviews, ['id', interview.id]) : null;

    return {
      ...(matchingPrevInterview || {
        name: interview.name,
        stage_interview_id: interview.stage_interview_id,
      }),
      interview_template: {
        ...(matchingPrevInterview ? matchingPrevInterview.interview_template : {}),
        ...interview.interview_template,
        name: interview.interview_template.name || 'Interview Template',
        live_coding_enabled: interview.interview_template.live_coding_enabled || false,
      },
      scorecard_stage_interview_id: interview.scorecard_stage_interview_id,
      feedback_form_id: interview.feedback_form_id,
      room_id: calendarSchedule.selected_room?.room_id || undefined,
      zoom_host_id: calendarSchedule.selected_zoom_host?.id || undefined,
      zoom_host_type: calendarSchedule.selected_zoom_host?.type || undefined,
      start_time: interview.start_time,
      interviewers: interview.interviewers.map((interviewer) => {
        const matchingPrevInterviewer = (matchingPrevInterview && interviewer.id) ? find(matchingPrevInterview.interviewers, ['id', interviewer.id]) : null;

        return {
          ...(matchingPrevInterviewer || {}),
          user_id: interviewer.selected_user.user_id,
          interviewer_template: {
            ...(matchingPrevInterviewer ? matchingPrevInterviewer.interviewer_template : {
              include_past_interviewers: true,
            }),
            ...interviewer.interviewer_template,
          },
          rsvp: (matchingPrevInterview && matchingPrevInterviewer
              && matchingPrevInterviewer.user_id === interviewer.selected_user.user_id
              && matchingPrevInterview.start_time === interview.start_time
              && matchingPrevInterview.interview_template.duration_minutes === interview.interview_template.duration_minutes
          ) ? matchingPrevInterviewer.rsvp : RSVP.Pending,
        };
      }),
    };
  });
};
