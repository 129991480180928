import InterviewDetails from './InterviewDetails';
import { StyledContainer } from './styles';

import type { StageInterview } from 'types';

interface Props {
  stageInterviews: StageInterview[];
}

const InterviewPlanMenu = ({ stageInterviews }: Props) => {
  return (
    <StyledContainer>
      {stageInterviews.map((stageInterview) => (
        <InterviewDetails
          key={stageInterview.id}
          stageInterview={stageInterview}
        />
      ))}
    </StyledContainer>
  );
};

export default InterviewPlanMenu;
