import Moment from 'moment';
import { capitalize, findIndex, sortBy, uniq } from 'lodash';
import { useCallback, useMemo } from 'react';

import BusinessHoursActionButtons from './BusinessHoursActionButtons';
import BusinessHoursTimeRangeInput from './BusinessHoursTimeRangeInput';
import { StyledCheckboxRow, StyledCheckboxInput, StyledEmptyCell } from './styles';
import { businessHourDayOrdering } from '../../../../types/business-hours';

import type { BusinessHourDay } from '../../../../types/business-hours';
import type { ChangeEvent, Dispatch, SetStateAction } from 'react';
import type { EditableBusinessHour } from '../../../../types/business-hours';

interface Props {
  businessHours: EditableBusinessHour[];
  day: BusinessHourDay;
  isDisabled: boolean;
  isTimezoneClearable?: boolean;
  setBusinessHours: Dispatch<SetStateAction<EditableBusinessHour[]>>;
}

const BusinessHoursCheckboxInput = ({
  day,
  businessHours,
  isDisabled,
  isTimezoneClearable,
  setBusinessHours,
}: Props) => {
  const indexOfFirstWindowForDay = useMemo<number>(() => findIndex(businessHours, (bh) => bh.day === day), [businessHours, day]);
  const isEnabled = indexOfFirstWindowForDay !== -1;

  const handleEnableChange = useCallback((e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      // Enabling
      setBusinessHours((prev) => {
        // If this is not the first day we're enabling, copy all the windows of the first enabled day to this newly
        // enabled day. If it is the first one, then just use 9a-5p in the client's timezone.
        const windowsToCopy = prev.length > 0 ?
          prev.filter((bh) => bh.day === prev[0].day) :
          [{ day, start_time: '09:00', end_time: '17:00', timezone: Moment.tz.guess() }];

        // We sort every time we add a new window because we want to keep all the windows for a given day together and in
        // day-order. This allows for us to have Tuesday enabled and when we enable Monday, the Copy button shows up for
        // Monday instead of Tuesday. Since we always add the Copy button to the first element, this sort is necessary. We
        // are explicitly not sorting based on start/end times because we want any newly added windows to be added to the
        // bottom of the day list instead of it jumping around as you update the times. It will only reorder itself from the
        // backend response after we save it.
        return sortBy([
          ...prev,
          ...windowsToCopy.map((bh) => ({
            day,
            start_time: bh.start_time,
            end_time: bh.end_time,
            timezone: bh.timezone,
          })),
        ], (bh) => businessHourDayOrdering[bh.day]);
      });
    } else {
      // Disabling
      setBusinessHours((prev) => prev.filter((bh) => bh.day !== day));
    }
  }, [day, setBusinessHours]);

  const handleBusinessHourChange = useCallback((businessHour: EditableBusinessHour, index: number) => {
    setBusinessHours((prev) => [
      ...prev.slice(0, index),
      businessHour,
      ...prev.slice(index + 1),
    ]);
  }, [setBusinessHours]);

  const handleBusinessHourAdd = useCallback(() => {
    setBusinessHours((prev) => {
      // Since the Add Window button is only there if there is already a window for this day, this should always find a
      // window.
      const firstWindowForDay = prev.filter((bh) => bh.day === prev[0].day)[0];

      // We sort every time we add a new window because we want to keep all the windows for a given day together and in
      // day-order. This allows for us to have Tuesday enabled and when we enable Monday, the Copy button shows up for
      // Monday instead of Tuesday. Since we always add the Copy button to the first element, this sort is necessary. We
      // are explicitly not sorting based on start/end times because we want any newly added windows to be added to the
      // bottom of the day list instead of it jumping around as you update the times. It will only reorder itself from the
      // backend response after we save it.
      return sortBy([
        ...prev,
        {
          day,
          start_time: '',
          end_time: '',
          timezone: firstWindowForDay.timezone,
        },
      ], (bh) => businessHourDayOrdering[bh.day]);
    });
  }, [day, setBusinessHours]);

  const handleBusinessHourDelete = useCallback((index: number) => {
    setBusinessHours((prev) => [
      ...prev.slice(0, index),
      ...prev.slice(index + 1),
    ]);
  }, [setBusinessHours]);

  const handleBusinessHourCopyToAll = useCallback(() => {
    setBusinessHours((prev) => {
      // We'll be copying all the windows for this day, which can be more than one.
      const windowsToCopy = prev.filter((bh) => bh.day === day);
      const enabledDays = uniq(prev.map((bh) => bh.day));
      return enabledDays.flatMap((enabledDay) => windowsToCopy.map((bh) => ({
        day: enabledDay,
        start_time: bh.start_time,
        end_time: bh.end_time,
        timezone: bh.timezone,
      })));
    });
  }, [day, setBusinessHours]);

  return (
    <>
      {!isEnabled && (
        <StyledCheckboxRow>
          <StyledCheckboxInput
            isChecked={isEnabled}
            isDisabled={isDisabled}
            label={capitalize(day)}
            onChange={handleEnableChange}
          />
        </StyledCheckboxRow>
      )}
      {isEnabled && businessHours.map((businessHour, index) => {
        if (businessHour.day !== day) {
          // We need the index relative to businessHours, but we only want to render business hours for this day, so we
          // just return null for all the other days.
          return null;
        }

        const isFirstWindowForDay = index === indexOfFirstWindowForDay;

        return (
          <StyledCheckboxRow key={index}>
            {isFirstWindowForDay ? (
              <StyledCheckboxInput
                isChecked={isEnabled}
                isDisabled={isDisabled}
                label={capitalize(day)}
                onChange={handleEnableChange}
              />
            ) : <StyledEmptyCell />}
            <BusinessHoursTimeRangeInput
              businessHour={businessHour}
              isDisabled={isDisabled}
              isTimezoneClearable={isTimezoneClearable}
              onBusinessHourChange={(businessHour) => handleBusinessHourChange(businessHour, index)}
            />
            {!isDisabled && (
              <BusinessHoursActionButtons
                onAddWindow={isFirstWindowForDay ? handleBusinessHourAdd : undefined}
                onCopyToAll={index === 0 ? handleBusinessHourCopyToAll : undefined}
                onDeleteWindow={() => handleBusinessHourDelete(index)}
                tooltipValue={`Copy time${businessHours[index + 1]?.day === day ? 's' : ''} to all`}
              />
            )}
          </StyledCheckboxRow>
        );
      })}
    </>
  );
};

export default BusinessHoursCheckboxInput;
