import type { UpdateStagePayload } from '../hooks/queries/stages';
import type { EditableRoomFilter, RoomFilter } from '../types';

export const constructRoomFiltersPayload = (roomIds: string[]): UpdateStagePayload['room_filters'] => roomIds.map((roomId) => ({
  room_filter_expressions: [{
    filterable_type: 'room',
    negated: false,
    filterable_id: roomId,
  }],
}));

export const parseRoomIdsFromRoomFilters = (roomFilters?: RoomFilter[] | null): string[] => {
  return (roomFilters || []).map(({ room_filter_expressions }) => room_filter_expressions[0].filterable_id);
};

export function createEmptyRoomFilter (): EditableRoomFilter[] {
  return [{
    room_filter_expressions: [],
  }];
}
