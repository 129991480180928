import Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { find } from 'lodash';
import { useMemo } from 'react';

import Button from './Button';
import FavoriteButton from './FavoriteButton';
import ListItem from '../data-display/ListItem';
import SelectInput from './SelectInput';
import { FavoriteResourceType } from '../../../types';
import { formatMoment, TimeFormat } from '../../../libraries/time';
import { useInterviewTemplates } from '../../../hooks/queries/interview-templates';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option as SelectOption } from './SelectInput/types';

interface Option extends SelectOption<string> {
  favorite?: boolean;
}

interface Props {
  isDisabled?: boolean;
  isInlineCreatable?: boolean;
  name?: string;
  onChange: (newValue: OnChangeValue<SelectOption<string>, false>, actionMeta: ActionMeta<SelectOption<string>>) => void;
  value?: string;
}

const InterviewTemplateSelectInput = ({
  isDisabled = false,
  isInlineCreatable = false,
  name,
  onChange,
  value,
}: Props) => {
  const interviewTemplateQuery = useInterviewTemplates();
  const interviewTemplates = useMemo(() => {
    if (!interviewTemplateQuery.data) {
      return [];
    }
    return interviewTemplateQuery.data.interview_templates.filter((interviewTemplate) => !interviewTemplate.archived);
  }, [interviewTemplateQuery.data]);

  const options = useMemo<Option[]>(() => {
    const templateOptions: Option[] = interviewTemplates.map((interviewTemplate) => ({
      value: interviewTemplate.id,
      label: interviewTemplate.name,
      secondaryText: `Created on ${formatMoment(Moment(interviewTemplate.created_at), TimeFormat.LongMonthDayYear)}`,
      favorite: interviewTemplate.favorite,
    }));
    if (isInlineCreatable) {
      templateOptions.unshift({
        value: 'new',
        label: (
          <Button
            color="no-outline"
            iconLeft={<FontAwesomeIcon icon={faPlus} />}
            size="small"
            value="New Interview Template"
          />
        ),
      });
    }
    return templateOptions;
  }, [interviewTemplates, isInlineCreatable]);

  return (
    <SelectInput
      formatOptionLabel={(option, { context }) => (
        context === 'menu' ?
          <ListItem
            label={(
              <div className="favorite-container">
                {option.favorite ? (
                  <FavoriteButton
                    favorite={option.favorite}
                    isDisabled
                    resourceId={option.value}
                    resourceType={FavoriteResourceType.InterviewTemplate}
                  />
                ) : undefined}
                {option.label}
              </div>
            )}
            secondaryText={option.secondaryText}
          /> :
          option.label
      )}
      isClearable
      isDisabled={isDisabled}
      name={name}
      onChange={onChange}
      options={options}
      placeholder="Select a template"
      value={value ? find(options, ['value', value]) : null}
    />
  );
};

export default InterviewTemplateSelectInput;
