import Moment from 'moment-timezone';
import { useMemo, useState } from 'react';

import ArchiveButton from '../../../library/inputs/ArchiveButton';
import FavoriteButton from '../../../library/inputs/FavoriteButton';
import Flash from '../../../library/utils/Flash';
import Label from '../../../library/utils/Label';
import Table from '../../../library/data-display/Table';
import { FavoriteResourceType } from '../../../../types';
import { StyledTableActionButtonsContainer, StyledTableContainer, StyledTableDate, StyledTableName } from './styles';
import { formatMoment, TimeFormat } from '../../../../libraries/time';
import { useUpdateHiringMeetingTemplate } from '../../../../hooks/queries/hiring-meeting-templates';

import type { ChangeEvent } from 'react';
import type { HiringMeetingTemplate } from '../../../../types';
import type { TableSchema } from '../../../library/data-display/Table';
import { correctPath } from 'libraries/gem';

const getRowLink = ({ id }: HiringMeetingTemplate) => correctPath(`/app/hiring-meeting-templates/${id}`);

interface Props {
  onPageNumberChange: (pageNumber: number) => void;
  onShowArchivedChange: (event: ChangeEvent<HTMLInputElement>) => void;
  pageNumber: number;
  showArchived: boolean;
  totalCount: number;
  hiringMeetingTemplates: HiringMeetingTemplate[];
}

const HiringMeetingTemplateListTable = ({
  hiringMeetingTemplates,
  onPageNumberChange,
  onShowArchivedChange,
  pageNumber,
  showArchived,
  totalCount,
}: Props) => {
  const [lastAction, setLastAction] = useState({
    id: '',
    isUndo: false,
    type: '',
  });

  const updateHiringMeetingTemplateMutation = useUpdateHiringMeetingTemplate();

  const handleArchive = (id: string, undo: boolean) => {
    setLastAction({
      id,
      type: 'archive',
      isUndo: undo,
    });
    updateHiringMeetingTemplateMutation.reset();
    updateHiringMeetingTemplateMutation.mutate({ id, payload: { archived: true } });
  };

  const handleUnarchive = (id: string, undo: boolean) => {
    setLastAction({
      id,
      type: 'unarchive',
      isUndo: undo,
    });
    updateHiringMeetingTemplateMutation.reset();
    updateHiringMeetingTemplateMutation.mutate({ id, payload: { archived: false } });
  };

  const handleUndo = () => {
    if (lastAction.type === 'archive') {
      handleUnarchive(lastAction.id, true);
    }
    if (lastAction.type === 'unarchive') {
      handleArchive(lastAction.id, true);
    }
  };

  const columns = useMemo<TableSchema<HiringMeetingTemplate>>(() => [{
    header: '',
    displayValue: ({ favorite, id }) => (
      <FavoriteButton favorite={favorite} resourceId={id} resourceType={FavoriteResourceType.HiringMeetingTemplate} />
    ),
    isClickable: true,
  }, {
    header: 'Name',
    displayValue: ({ name, archived }) => (
      <StyledTableName>
        {name}
        {archived && <Label color="gray">Archived</Label>}
      </StyledTableName>
    ),
  // TODO: bring back once you can attach hiring meeting templates to stages
  // }, {
  //   header: 'Linked Active Stages',
  //   displayValue: ({ linked_resources }) => linked_resources || 0,
  }, {
    header: 'Date Created',
    displayValue: ({ created_at }) => <StyledTableDate>{formatMoment(Moment(created_at), TimeFormat.LongMonthDayYear)}</StyledTableDate>,
  }, {
    header: 'Last Updated',
    displayValue: ({ updated_at }) => <StyledTableDate>{formatMoment(Moment(updated_at), TimeFormat.LongMonthDayYear)}</StyledTableDate>,
  }, {
    header: 'Actions',
    isClickable: true,
    displayValue: ({ archived, id }) => (
      <StyledTableActionButtonsContainer>
        <ArchiveButton
          id={id}
          isArchived={archived}
          onArchive={() => handleArchive(id, false)}
          onUnarchive={() => handleUnarchive(id, false)}
        />
        {/* <DeleteButton id={id} /> */}
      </StyledTableActionButtonsContainer>
    ),
  }], []);

  return (
    <StyledTableContainer>
      <Flash
        isDismissible
        message={(
          <span>
            Hiring meeting template {lastAction.type}d.&nbsp;
            {!lastAction.isUndo && <a href="#" onClick={handleUndo}>Undo?</a>}
          </span>
        )}
        showFlash={lastAction.id ? updateHiringMeetingTemplateMutation.isSuccess : false}
        type="success"
      />
      <Flash
        isDismissible
        message={updateHiringMeetingTemplateMutation.error?.message}
        onDismiss={updateHiringMeetingTemplateMutation.reset}
        showFlash={updateHiringMeetingTemplateMutation.isError}
        type="danger"
      />
      <Table
        data={hiringMeetingTemplates}
        getRowLink={getRowLink}
        isPaginated
        layout="vertical"
        onPageNumberChange={onPageNumberChange}
        onShowArchivedChange={onShowArchivedChange}
        pageNumber={pageNumber}
        schema={columns}
        showArchived={showArchived}
        totalCount={totalCount}
      />
    </StyledTableContainer>
  );
};

export default HiringMeetingTemplateListTable;
