import Moment from 'moment-timezone';
import { groupBy, keyBy, partition } from 'lodash';
import { useMemo } from 'react';

import TrainingProgressStep from './TrainingProgressStep';
import { StepType } from './types';
import { StyledStepContainer, StyledTrainedOutsideText } from './styles';

import type { Step } from './types';
import type { TrainingOverride, TrainingPhase, TrainingProgram, TrainingSession } from '../../../../types';

// These constants are for calculating how much to shift the training program to the left
// in order to have the first step line up closer to the edge of the table cell.
const APPROXIMATE_CHARACTER_WIDTH_PX = 8.5;
const STEP_WIDTH_PX = 130;
const ADDITIONAL_MARGIN_PX = 8;

interface Props {
  graduated: boolean;
  isEditing: boolean;
  onSessionChange: (session: TrainingSession | undefined, phase: TrainingPhase, checked: boolean) => void;
  trainingOverrides: TrainingOverride[];
  trainingProgram: TrainingProgram;
  trainingSessions: TrainingSession[];
}

const TrainingProgressBar = ({
  graduated,
  isEditing,
  onSessionChange,
  trainingOverrides,
  trainingProgram,
  trainingSessions,
}: Props) => {
  const filteredTrainingSessions = useMemo(() => trainingSessions.filter((session) => !session.interviewer || session.interviewer.interview.schedule.status === 'confirming' || session.interviewer.interview.schedule.status === 'confirmed'), [trainingSessions]);
  const overridesByPhaseId = useMemo(() => keyBy(trainingOverrides, 'training_phase_id'), [trainingOverrides]);
  const sessionsByPhaseId = useMemo(() => groupBy(filteredTrainingSessions, 'training_phase_id'), [filteredTrainingSessions]);
  const isTrainedOutside = useMemo(() => graduated && filteredTrainingSessions.length === 0, [graduated, filteredTrainingSessions]);

  const steps = useMemo<Step[]>(() => {
    if (isTrainedOutside) {
      return [];
    }

    return (trainingProgram.training_phases || []).flatMap((phase) => {
      const override = overridesByPhaseId[phase.id];
      const required = override?.number_of_interviews ?? phase.number_of_interviews;
      const sessions = sessionsByPhaseId[phase.id] || [];
      const [heldSessions, confirmedSessions] = partition(sessions, 'interviewer.interview.schedule.hold');
      let nonIgnoredCount = 0;
      const s = confirmedSessions.map((session) => {
        const endTime = session.interviewer ? Moment(session.interviewer.interview.start_time).add(session.interviewer.interview.interview_template.duration_minutes, 'minutes') : undefined;
        if (!session.ignored_at) {
          nonIgnoredCount++;
        }
        return {
          session,
          phase,
          phaseName: session.phase_name,
          type: !endTime || !endTime.isAfter(Moment()) ? StepType.Completed : StepType.Upcoming,
          manuallyReported: !session.interviewer,
          date: endTime?.format(),
          // This is a session in excess if the non-ignored count (which is one-indexed since it's a count) is > the
          // required number of sessions (which is one-indexed since it's a count).
          excess: !session.ignored_at ? nonIgnoredCount > required : false,
        };
      });
      if (graduated || nonIgnoredCount >= required) {
        return s;
      }
      const additionalSteps: Step[] = Array.from({ length: required - nonIgnoredCount }).map((_, i) => ({
        phase,
        phaseName: phase.name,
        type: StepType.Unscheduled,
        heldSessions: i === 0 && heldSessions.length > 0 ? heldSessions : undefined,
        excess: false,
      }));
      return [
        ...s,
        ...additionalSteps,
      ];
    });
  }, [
    graduated,
    isTrainedOutside,
    overridesByPhaseId,
    sessionsByPhaseId,
    trainingProgram,
    trainingSessions,
  ]);

  const pixelsToShiftLeft = useMemo(() => {
    if (!steps || steps.length === 0) {
      // If there aren't any steps, don't shift anything.
      return 0;
    }
    if (!isEditing && steps[0].session?.ignored_at) {
      // We're not editing and the first step is ignored, so we actually need to shift it a bit the other way.
      return 24;
    }
    const approximatePhaseLabelWidthPx = steps[0].phaseName.length * APPROXIMATE_CHARACTER_WIDTH_PX;
    if (approximatePhaseLabelWidthPx >= STEP_WIDTH_PX) {
      // If the approximate width of the phase label is larger than the step, it will be truncated, so no shifting is
      // necessary.
      return 0;
    }
    return -1 * ((STEP_WIDTH_PX - approximatePhaseLabelWidthPx) / 2 - ADDITIONAL_MARGIN_PX);
  }, [isEditing, steps]);

  return (
    <StyledStepContainer style={{ left: `${pixelsToShiftLeft}px` }}>
      {isTrainedOutside && <StyledTrainedOutsideText>Not trained in InterviewPlanner</StyledTrainedOutsideText>}
      {steps.map((step, i) => (
        <TrainingProgressStep
          graduated={graduated}
          isEditing={isEditing}
          key={i}
          onSessionChange={onSessionChange}
          step={step}
          total={steps.length}
        />
      ))}
    </StyledStepContainer>
  );
};

export default TrainingProgressBar;
