import { createContext, useContext, useMemo, useState } from 'react';

import type { Dispatch, SetStateAction, ReactNode } from 'react';
import type { CreateSchedulePayload } from 'hooks/queries/schedules';

export enum Step {
  Schedule,
  EventsAndEmails,
  Review,
}

interface NewManualScheduleContextState {
  completedStep: Step;
  schedule: CreateSchedulePayload;
  setCompletedStep: Dispatch<SetStateAction<Step>>;
  setSchedule: Dispatch<SetStateAction<CreateSchedulePayload>>;
}

function createEmptySchedule (): CreateSchedulePayload {
  // Initializes all required fields on the payload,
  // for type safety
  return {
    application_id: '',
    timezone: '',
    scheduling_calendar_email: '',
    schedule_template: {
      candidate_event_title: '',
      candidate_event_description: '',
      business_hours: [],
      onsite: false,
      mark_interviewer_events_as_private: false,
      mark_candidate_events_as_private: false,
      video_conferencing_enabled: false,
      create_hiring_channel: false,
    },
    schedules: [],
  };
}

// This is the context for keeping track of the new schedule. You should keep track
// of the new link with a useState call in the same component that use use the
// Provider, recreate an array using useMemo, and then pass it into the Provider.
// Make sure you don't pass an array literal into the Provider. This can cause
// re-rendering issues as noted here:
// https://reactjs.org/docs/context.html#caveats.
const NewManualScheduleContext = createContext<NewManualScheduleContextState>({
  completedStep: Step.Schedule,
  schedule: createEmptySchedule(),
  setCompletedStep: () => {},
  setSchedule: () => {},
});

interface Props {
  children: ReactNode;
}

export const NewManualScheduleProvider = ({ children }: Props) => {
  const [completedStep, setCompletedStep] = useState(Step.Schedule);
  const [schedule, setSchedule] = useState(createEmptySchedule());

  const contextValue = useMemo<NewManualScheduleContextState>(() => ({
    completedStep,
    schedule,
    setCompletedStep,
    setSchedule,
  }), [completedStep, schedule]);

  return (
    <NewManualScheduleContext.Provider value={contextValue}>
      {children}
    </NewManualScheduleContext.Provider>
  );
};

export const useNewManualSchedule = () => {
  return useContext(NewManualScheduleContext);
};
