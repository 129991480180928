import styled from 'styled-components';

import Button from 'components/library/inputs/Button';
import SelectInput from 'components/library/inputs/SelectInput';

export const StyledForm = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-bottom: .5em;
`;

export const StyledChatTypeContainer = styled.div`
  display: flex;
  align-items: center;
  flex-wrap: wrap;
`;

// The typing for this is weird because of styled-components. This is inspired from
// https://github.com/styled-components/styled-components/issues/1803#issuecomment-857092410
export const StyledChatTypeSelectInput = styled(SelectInput)`
  margin-right: 1em;
  max-width: 300px;
  width: 300px;
` as typeof SelectInput;

export const StyledConnectButton = styled(Button)`
  margin-bottom: .5rem;
  width: 260px;
`;

export const StyledCheckboxContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1em;
  margin-top: 1em;
`;
