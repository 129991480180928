import Filters from './Filters';
import ResolvedFiltersAvatarGroup from './ResolvedFiltersAvatarGroup';

import type { Props as FiltersProps } from './Filters';

interface Props {
  interviewerTemplates: {
    id?: string;
    description?: string;
    optional: boolean;
    include_past_interviewers: boolean;
    interviewer_filters: FiltersProps['filters'] | null;
  }[];
}

const InterviewerTemplatesSummary = ({ interviewerTemplates }: Props) =>
  <div className="interviewer-templates-summary">
    {interviewerTemplates.map((interviewerTemplate, i) => (
      <div className="interviewer" key={`interviewer-${interviewerTemplate.id || i}`}>
        {interviewerTemplate.description || 'Interviewer'}{interviewerTemplate.optional && <span className="optional-interviewer"> (Optional)</span>}
        <div className="interviewer-details">
          <div className="criteria">
            <h6>Criteria</h6>
            <Filters filters={interviewerTemplate.interviewer_filters || []} />
          </div>
          <div className="pool">
            <ResolvedFiltersAvatarGroup
              filters={interviewerTemplate.interviewer_filters || []}
              includePastInterviewers={interviewerTemplate.include_past_interviewers}
              maxDisplayLength={5}
            />
          </div>
        </div>
      </div>
    ))}
  </div>
;

export default InterviewerTemplatesSummary;
