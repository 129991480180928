import type { TrainingPhase, TrainingSession } from '../../../../types';

export enum StepType {
  Completed = 'completed',
  Upcoming = 'upcoming',
  Unscheduled = 'unscheduled',
}

export interface Step {
  session?: TrainingSession;
  phase: TrainingPhase;
  // Use phaseName for displaying, but phase to get the ID.
  phaseName: string;
  type: `${StepType}`;
  manuallyReported?: boolean;
  date?: string;
  heldSessions?: TrainingSession[];
  excess: boolean;
}
