import { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import useHideZendeskWidget from '../../hooks/use-hide-zendesk-widget';

interface Redirect {
  url: string;
  type: string;
}

const redirects: Record<string, Redirect> = {
  survey: {
    url: 'https://tally.so/r/3laBjB',
    type: 'User Habits',
  },
};

const UNKNOWN_REDIRECT = {
  url: '/',
  type: 'Unknown',
} as const;

const Go = () => {
  useHideZendeskWidget();

  const { slug } = useParams<{ slug: string }>();

  const redirect = redirects[slug] || UNKNOWN_REDIRECT;

  useEffect(() => {
    // In the event that the user has an ad blocker, the track callback won't get called. In that case, we just wait 2s
    // and then redirect the user anyway.
    const id = setTimeout(() => {
      window.location.href = redirect.url;
    }, 2000);
    return () => clearTimeout(id);
  }, [redirect]);

  analytics.track('Go Redirect Link Followed', { slug, type: redirect.type }, () => {
    // If we don't do this in the callback, we can't guarantee that it will send the events.
    window.location.href = redirect.url;
  });

  return null;
};

export default Go;
