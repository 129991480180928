export enum SyncSource {
  Gem = 'gem',
  Google = 'google',
  Greenhouse = 'greenhouse',
  Lever = 'lever',
  Microsoft365 = 'microsoft365',
  Slack = 'slack',
  Zoom = 'zoom',
}

export interface Sync {
  id: string;
  account_id: string;
  source: SyncSource;
  resource: string;
  started_at: string;
  completed_at?: string;
}
