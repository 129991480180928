import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { pullAt, uniqueId } from 'lodash';
import { useMemo } from 'react';

import Button from '../Button';
import ZoomHostFilterInput from './ZoomHostFilterInput';
import Tooltip from '../../utils/Tooltip';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { EditableZoomHostFilter } from '../../../../types';
import type { Option } from './ZoomHostFilterInput';
import type { ReactNode } from 'react';

const ZOOM_HOST_FILTER_INPUT_NAME_PREFIX = 'zoom-host-filter-';

const createEmptyZoomHostFilter = (): EditableZoomHostFilter => ({
  zoom_host_filter_expressions: [],
});

interface Props {
  className?: string;
  excludeFirstInterviewerOption?: boolean;
  filters: EditableZoomHostFilter[];
  isDisabled?: boolean;
  helperText?: ReactNode;
  id?: string;
  label?: string;
  name?: string;
  onChange: (newValue: EditableZoomHostFilter[], name?: string) => void;
}

const ZoomHostFiltersBuilder = ({
  className,
  excludeFirstInterviewerOption = false,
  filters,
  helperText,
  id,
  isDisabled = false,
  label,
  name,
  onChange,
}: Props) => {
  id = useMemo(() => id || uniqueId('zoom-host-filters-builder-'), [id]);

  const handleAddRule = () => {
    onChange([
      ...filters,
      createEmptyZoomHostFilter(),
    ], name);
  };

  const handleRemoveRule = (i: number) => {
    const filtersCopy = filters.slice();
    pullAt(filtersCopy, [i]);
    onChange(filtersCopy, name);
  };

  const handleFilterChange = (options: OnChangeValue<Option, true>, actionMeta: ActionMeta<Option>) => {
    const index = parseInt(actionMeta.name?.replace(ZOOM_HOST_FILTER_INPUT_NAME_PREFIX, '') || '-1', 10);
    const selectedFilter = {
      zoom_host_filter_expressions: (options ?
        options.map((option) => ({
          filterable_id: option.filterable_id,
          filterable_type: option.filterable_type,
          negated: option.negated,
        })) :
        []
      ),
    };
    onChange(filters.map((filter, i) => (i === index ? selectedFilter : filter)), name);
  };

  return (
    <div className={`input zoom-host-filters-builder${className ? ` ${className}` : ''}`}>
      {label && <label htmlFor={id}>{label}</label>}
      {filters.map((filter, i) => (
        <div
          className="filter-input-container"
          key={`filter-input-${i}`}
        >
          {i > 0 && <span className="filter-separator">or</span>}
          <ZoomHostFilterInput
            excludeFirstInterviewerOption={excludeFirstInterviewerOption}
            isDisabled={isDisabled}
            name={`zoom-host-filter-${i}`}
            onChange={handleFilterChange}
            selectedFilterExpressions={filter.zoom_host_filter_expressions}
          />
          {!isDisabled && filters.length > 1 &&
            <Button
              className="btn-delete"
              color="no-outline"
              iconRight={<FontAwesomeIcon icon={faTimes} />}
              onClick={() => handleRemoveRule(i)}
              size="small"
              tooltip={
                <Tooltip
                  id={`remove-zoom-host-filter-${i}-button`}
                  position="top"
                  value="Remove"
                />
              }
            />
          }
        </div>
      ))}
      {helperText && <div className="helper-text">{helperText}</div>}
      {!isDisabled &&
        <Button
          className="btn-add-rule"
          color="no-outline"
          iconLeft={<FontAwesomeIcon icon={faPlus} />}
          onClick={handleAddRule}
          size="small"
          value="Add Alternate Rule"
        />
      }
    </div>
  );
};

export default ZoomHostFiltersBuilder;
