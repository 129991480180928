import classnames from 'classnames';

import type { ReactNode } from 'react';

export enum LabelColor {
  Red = 'red',
  Orange = 'orange',
  Green = 'green',
  Blue = 'blue',
  Gray = 'gray',
}

interface Props {
  children: ReactNode;
  className?: string;
  color: `${LabelColor}`;
  tooltip?: JSX.Element;
}

const Label = ({ children, className, color, tooltip }: Props) =>
  <>
    <span
      className={classnames('label', `label-${color}`, className)}
      data-for={tooltip ? tooltip.props.id : undefined}
      data-tip={tooltip ? true : undefined}
    >
      {children}
    </span>
    {tooltip}
  </>
;

export default Label;
