import { groupBy } from 'lodash';
import { useMemo } from 'react';

import pluralize from '../../../../../libraries/pluralize';
import { PendingChangeType } from './types';

import type { PendingChange } from './types';
import type { ReactNode } from 'react';

const changeTypesWithDetails: { [key in string]: boolean } = {
  [PendingChangeType.TrainingOverrideCreate]: true,
  [PendingChangeType.TrainingOverrideDelete]: true,
  [PendingChangeType.TrainingOverrideUpdate]: true,
  [PendingChangeType.TrainingSessionCreate]: true,
  [PendingChangeType.TrainingSessionDelete]: true,
  [PendingChangeType.TrainingSessionIgnore]: true,
  [PendingChangeType.TrainingSessionUnignore]: true,
};

function generateTopLevelMessage (changes: PendingChange[]) {
  let statusChange: PendingChange | null = null;
  let hasDetailsChange = false;

  for (const change of changes) {
    if (changeTypesWithDetails[change.type]) {
      hasDetailsChange = true;
    } else {
      // Since the change types are partitioned based on whether they are a status change or a detail change, we can
      // just utilize this else block instead of maintaining an explicit list of status-changing types.

      // It's possible to have more than one status change. One example is graduating and then deleting a user. I think
      // the only case of this is doing something and then deleting a user. In that case, we want to only include the
      // messaging for the deletion. Since the deletion should always be last, this should be fine.
      statusChange = change;
    }
  }

  // Since this shouldn't ever be called if changes is empty, we can be sure that one of the flags are set.
  let msg: ReactNode = undefined;
  if (statusChange) {
    switch (statusChange.type) {
      case PendingChangeType.TrainingProgramUserCreate:
        msg = <>You have added <b>{statusChange.user.name}</b> as a {statusChange.user.trainee ? 'trainee' : 'graduate'}</>;
        break;
      case PendingChangeType.TrainingProgramUserDelete:
        msg = <>You have removed <b>{statusChange.user.name}</b></>;
        break;
      case PendingChangeType.TrainingProgramUserGraduate:
        msg = <>You have graduated <b>{statusChange.user.name}</b></>;
        break;
      case PendingChangeType.TrainingProgramUserUngraduate:
        msg = <>You have moved <b>{statusChange.user.name}</b> back into training</>;
        break;
    }
    if (hasDetailsChange) {
      msg = <>{msg} and edited their training program.</>;
    } else {
      msg = <>{msg}.</>;
    }
  } else if (hasDetailsChange) {
    msg = <>You have edited the training program for <b>{changes[0].user.name}</b>.</>;
  }

  return msg;
}

function generateDetailMessage (change: PendingChange) {
  let sessionsByPhaseId;
  let lineItems;
  switch (change.type) {
    case PendingChangeType.TrainingOverrideCreate:
      return <>Customized <b>{change.phase.name}</b> phase to require {change.value} {pluralize('interview', change.value)}</>;
    case PendingChangeType.TrainingOverrideDelete:
      return <>Reverted <b>{change.phase.name}</b> phase back to the default of {change.phase.number_of_interviews} required {pluralize('interview', change.phase.number_of_interviews)}</>;
    case PendingChangeType.TrainingOverrideUpdate:
      return <>Updated custom <b>{change.phase.name}</b> phase to require {change.value} {pluralize('interview', change.value)}</>;
    case PendingChangeType.TrainingSessionCreate:
      sessionsByPhaseId = groupBy(change.sessions, 'phase.id');
      lineItems = Object.values(sessionsByPhaseId).map((sessions) => <>{sessions.length} <b>{sessions[0].phase.name}</b> {pluralize('interview', sessions.length)}</>);
      return <>Manually completed {formatReactNodeList(lineItems)}</>;
    case PendingChangeType.TrainingSessionDelete:
      sessionsByPhaseId = groupBy(change.sessions, 'training_phase_id');
      lineItems = Object.values(sessionsByPhaseId).map((sessions) => <>{sessions.length} <b>{sessions[0].phase_name}</b> {pluralize('interview', sessions.length)}</>);
      return <>Removed {formatReactNodeList(lineItems)}</>;
    case PendingChangeType.TrainingSessionIgnore:
      sessionsByPhaseId = groupBy(change.sessions, 'training_phase_id');
      lineItems = Object.values(sessionsByPhaseId).map((sessions) => <>{sessions.length} <b>{sessions[0].phase_name}</b> {pluralize('interview', sessions.length)}</>);
      return <>Ignored {formatReactNodeList(lineItems)}</>;
    case PendingChangeType.TrainingSessionUnignore:
      sessionsByPhaseId = groupBy(change.sessions, 'training_phase_id');
      lineItems = Object.values(sessionsByPhaseId).map((sessions) => <>{sessions.length} <b>{sessions[0].phase_name}</b> {pluralize('interview', sessions.length)}</>);
      return <>Unignored {formatReactNodeList(lineItems)}</>;
  }
}

function formatReactNodeList (list: ReactNode[]): ReactNode {
  switch (list.length) {
    case 0:
      return '';
    case 1:
      return list[0];
    case 2:
      return <>{list[0]} and {list[1]}</>;
    default:
      return <>{list.slice(0, -1).reduce((acc, item) => <>{acc}, {item}</>, <></>)}, and ${list[list.length - 1]}</>;
  }
}

interface Props {
  changes: PendingChange[];
}

const PendingChangeMessage = ({ changes }: Props) => {
  const topLevelMessage = useMemo(() => generateTopLevelMessage(changes), [changes]);
  const changeDetails = changes.filter(({ type }) => changeTypesWithDetails[type]);

  return (
    <li>
      {topLevelMessage}
      {changeDetails.length > 0 && (
        <ul>
          {changeDetails.map((change, i) => {
            return (
              <li key={i}>{generateDetailMessage(change)}</li>
            );
          })}
        </ul>
      )}
    </li>
  );
};

export default PendingChangeMessage;
