import { uniqueId } from 'lodash';
import { useMemo, useRef } from 'react';

import Popover from '../utils/Popover';
import SelectInput from './SelectInput';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { FormatOptionLabelMeta } from 'react-select/dist/declarations/src/Select';
import type { Group, Option, Value } from './SelectInput/types';
import type { ReactNode } from 'react';
import type { SelectInstance } from 'react-select';

interface Props<V extends Value, O extends Option<V>, M extends boolean, G extends Group<V, O>> {
  actionButton?: ReactNode;
  className?: string;
  displayDetails?: (value: OnChangeValue<O, M>) => ReactNode;
  formatCreateLabel?: (inputValue: string) => ReactNode;
  formatOptionLabel?: (option: O, meta: FormatOptionLabelMeta<O>) => ReactNode;
  id?: string;
  isClearable?: boolean;
  isCreatable?: boolean;
  isMulti?: M;
  isRequired?: boolean;
  isSearchable?: boolean;
  maxMenuHeight?: number;
  name?: string;
  onChange?: (newValue: OnChangeValue<O, M>, actionMeta: ActionMeta<O>) => void;
  options: O[] | G[];
  placeholder?: string;
  value?: OnChangeValue<O, M>;
  targetId: string;
}

const PopoverSelectInput = <V extends Value, O extends Option<V> = Option<V>, M extends boolean = false, G extends Group<V, O> = Group<V, O>>({
  actionButton,
  className,
  displayDetails,
  formatCreateLabel,
  formatOptionLabel,
  id,
  isClearable,
  isCreatable,
  isMulti,
  isRequired,
  isSearchable,
  maxMenuHeight,
  name,
  onChange,
  options,
  placeholder,
  targetId,
  value,
}: Props<V, O, M, G>) => {
  id = useMemo(() => id || uniqueId('popover-select-input-'), [id]);

  const selectInputRef = useRef<SelectInstance<O, M, G> | null>(null);

  return (
    <Popover
      className={`popover-select-input${className ? ` ${className}` : ''}`}
      // key is being used to force a remounting of the Popover component
      // whenever the targetId changes. This is necessary due to the bug in
      // UncontrolledPopover. Read the comment in utils/Popover.tsx for more
      // information.
      key={targetId}
      position="bottom"
      target={targetId}
    >
      <SelectInput<V, O, M, G>
        closeMenuOnSelect={false}
        formatCreateLabel={formatCreateLabel}
        formatOptionLabel={formatOptionLabel}
        id={id}
        isAutofocus
        isClearable={isClearable}
        isCreatable={isCreatable}
        isMenuOpen
        isMulti={isMulti}
        isRequired={isRequired}
        isSearchable={isSearchable}
        maxMenuHeight={maxMenuHeight}
        name={name}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        ref={selectInputRef}
        value={value}
      />
      {displayDetails &&
        <div className="details-pane" onClick={() => selectInputRef.current?.focus()}>
          {value ?
            displayDetails(value) :
            <div className="no-option-selected">
              No option selected
            </div>
          }
        </div>
      }
      {actionButton &&
        <div className="action-button">
          {actionButton}
        </div>
      }
    </Popover>
  );
};

export default PopoverSelectInput;
