import { omit } from 'lodash';

import { calculateInterviewUpdateActions, formatUpdatedInterviewSettingsList } from './helpers';
import CheckboxInput from 'components/library/inputs/CheckboxInput';
import ExpandingDetails from './ExpandingDetails';
import Infotip from 'components/library/utils/Infotip';
import LoadingSpinner from 'components/library/utils/LoadingSpinner';
import { StyledHelperText, StyledInputContainer } from './styles';
import TextInput from 'components/library/inputs/TextInput';
import { UpdateAction } from '../types';
import { useInterviewTemplates } from 'hooks/queries/interview-templates';

import type { ChangeEvent } from 'react';
import type { Stage } from 'types';
import type { InterviewUpdateAction, StageResourceUpdate } from '../types';

interface Props {
  interviewUpdateActions: { isChecked: boolean; actions: InterviewUpdateAction[] }[];
  suggestedInterviewUpdates: StageResourceUpdate[];
  setInterviewUpdateActions: (newState: { isChecked: boolean; actions: InterviewUpdateAction[] }[]) => void;
  stage: Stage;
}

const UpdateInterviewActionsInput = ({ interviewUpdateActions, setInterviewUpdateActions, suggestedInterviewUpdates, stage }: Props) => {
  const { isFetching } = useInterviewTemplates({}, {
    onSuccess: (data) => {
      setInterviewUpdateActions(
        calculateInterviewUpdateActions(suggestedInterviewUpdates, data.interview_templates, stage).map((actions) => ({
          isChecked: true,
          actions,
        }))
      );
    },
  });

  const handleInterviewUpdateActionChange = (e: ChangeEvent<HTMLInputElement>, i: number) => {
    setInterviewUpdateActions(interviewUpdateActions.map((action, j) => i === j ? { ...action, isChecked: e.target.checked } : action));
  };

  const handleInterviewUpdateActionNameChange = (e: ChangeEvent<HTMLInputElement>, i: number) => {
    setInterviewUpdateActions(interviewUpdateActions.map(({ isChecked, actions }, j) => ({
      isChecked,
      actions: i === j ? actions.map((a) => ({
        ...a,
        newTemplateName: a.action === UpdateAction.CreateInterviewTemplate || a.action === UpdateAction.UpdateInterviewTemplateNotAllowed ? e.target.value : a.newTemplateName,
      })) : actions,
    })));
  };

  if (isFetching) {
    return <LoadingSpinner />;
  }

  return (
    <>
      {suggestedInterviewUpdates.map(({ name: interviewName, updates }, i) => {
        if (!interviewUpdateActions[i]) {
          return null;
        }
        const { isChecked, actions } = interviewUpdateActions[i];
        const createAction = actions.find(({ action }) => action === UpdateAction.CreateInterviewTemplate);
        if (createAction) {
          return (
            <StyledInputContainer
              key={`${i}-${interviewName}-input`}
            >
              <CheckboxInput
                isChecked={isChecked}
                label={<span>Save <b>{interviewName}</b> preferences to a template named</span>}
                onChange={(e) => handleInterviewUpdateActionChange(e, i)}
              />
              <TextInput
                isRequired
                onChange={(e) => handleInterviewUpdateActionNameChange(e, i)}
                value={createAction.newTemplateName || ''}
              />
            </StyledInputContainer>
          );
        }
        const updateAction = actions.find(({ action }) => action === UpdateAction.UpdateInterviewTemplate);
        if (updateAction) {
          return (
            <StyledInputContainer
              key={`${i}-${interviewName}-input`}
            >
              <CheckboxInput
                isChecked={isChecked}
                label={<span>Update <b>{interviewName}</b> {formatUpdatedInterviewSettingsList([...Object.keys(omit(updates, 'interview_template')), ...Object.keys(updates.interview_template || {})])}.</span>}
                onChange={(e) => handleInterviewUpdateActionChange(e, i)}
              />
              <ExpandingDetails
                parentTemplate={updateAction.parentTemplate}
                updates={omit(updates.interview_template, 'name')}
              />
            </StyledInputContainer>
          );
        }
        const updateNotAllowedAction = actions.find(({ action }) => action === UpdateAction.UpdateInterviewTemplateNotAllowed);
        if (updateNotAllowedAction) {
          return (
            <StyledInputContainer
              key={`${i}-${interviewName}-input`}
            >
              <CheckboxInput
                isChecked={isChecked}
                label={<span>Save <b>{interviewName}</b> preferences to a <i>new</i> template named </span>}
                onChange={(e) => handleInterviewUpdateActionChange(e, i)}
              />
              <TextInput
                isRequired
                onChange={(e) => handleInterviewUpdateActionNameChange(e, i)}
                value={updateNotAllowedAction.newTemplateName || ''}
              />
              <ExpandingDetails
                additionalDetails={(
                  <StyledHelperText>
                    <Infotip
                      id={`${i}-${interviewName}-tooltip`}
                      text={`What if I want to update the ${updates?.interview_template?.name} template instead?`}
                      tooltipText={<span>Since it&apos;s linked to {updateNotAllowedAction?.parentTemplate?.linked_interviews} interviews, you can only update it from its interview template page.<br />This is to lower the risk of accidentally updating multiple roles and stages.</span>}
                    />
                  </StyledHelperText>
                )}
                parentTemplate={updateNotAllowedAction.parentTemplate}
                updates={omit(updates.interview_template, 'name')}
              />
            </StyledInputContainer>
          );
        }

        const swapInterviewTemplateAction = actions.find(({ action }) => action === UpdateAction.SwapInterviewTemplateOnStageInterview);
        if (swapInterviewTemplateAction?.parentTemplate?.name) {
          return (
            <StyledInputContainer
              key={`${i}-${interviewName}-input`}
            >
              <CheckboxInput
                isChecked={isChecked}
                label={<span>Swap <b>{interviewName}</b> template to <b>{swapInterviewTemplateAction.parentTemplate?.name}</b>.</span>}
                onChange={(e) => handleInterviewUpdateActionChange(e, i)}
              />
              {/* TODO: give option to rename it */}
            </StyledInputContainer>
          );
        }

        // If the only suggested action is to update the stage interview (e.g. for a feedback form update):
        if (actions.some(({ action }) => action === UpdateAction.UpdateStageInterview)) {
          return (
            <StyledInputContainer
              key={`${i}-${interviewName}-input`}
            >
              <CheckboxInput
                isChecked={isChecked}
                label={<span>Update <b>{interviewName}</b> {formatUpdatedInterviewSettingsList(Object.keys(omit(updates, 'interview_template')))}.</span>}
                onChange={(e) => handleInterviewUpdateActionChange(e, i)}
              />
            </StyledInputContainer>
          );
        }

        return null;
      })}
    </>
  );
};

export default UpdateInterviewActionsInput;
