import { ScheduleStatus, SelfSchedulingLinkStatus } from '../../../../../types';
import { StatusIndicatorColor } from '../../../../library/utils/StatusIndicator';

import type { ApplicationSchedule as Schedule, ApplicationSelfSchedulingLink as SelfSchedulingLink } from '../../../../../types';
import type { Option as SelectInputOption } from '../../../../library/inputs/SelectInput/types';

export const statusIndicatorValues: Record<ScheduleStatus | SelfSchedulingLinkStatus, { label: string; color: StatusIndicatorColor }> = {
  [ScheduleStatus.Cancelled]: { label: 'Cancelled', color: StatusIndicatorColor.Red },
  [ScheduleStatus.Cancelling]: { label: 'Cancelling', color: StatusIndicatorColor.Red },
  [ScheduleStatus.Confirmed]: { label: 'Confirmed', color: StatusIndicatorColor.Green },
  [ScheduleStatus.Confirming]: { label: 'Confirming', color: StatusIndicatorColor.Green },
  [SelfSchedulingLinkStatus.Requested]: { label: 'Requested', color: StatusIndicatorColor.Green },
  // This one doesn't actually show up because by then, it has been converted
  // into a regular schedule.
  [SelfSchedulingLinkStatus.Completed]: { label: 'Scheduled', color: StatusIndicatorColor.Green },
  // I don't think these two are used.
  [ScheduleStatus.AvailabilityRequested]: { label: 'Availability Requested', color: StatusIndicatorColor.Green },
  [ScheduleStatus.ReadyToSchedule]: { label: 'Ready to Schedule', color: StatusIndicatorColor.Orange },
};

export enum ScheduleType {
  Schedule = 'schedule',
  SelfSchedulingLink = 'self_scheduling_link',
}

export type ApplicationScheduleWithType =
  | Schedule & { type: ScheduleType.Schedule }
  | SelfSchedulingLink & { type: ScheduleType.SelfSchedulingLink };

export interface Option extends SelectInputOption<string> {
  schedule: ApplicationScheduleWithType;
}
