import { orderBy } from 'lodash';
import Moment from 'moment-timezone';
import { useMemo } from 'react';
import { Link } from 'react-router-dom';

import { correctPath } from 'libraries/gem';
import { formatMoment, TimeFormat } from 'libraries/time';
import { StyledSlotsTable, StyledSlotEmptyText, StyledSlotTimeHeader } from './styles';

import type { TableSchema } from 'components/library/data-display/Table';
import type { InterviewBatchTimeSlot } from 'types';

interface Props {
  slots: InterviewBatchTimeSlot[];
  timezone: string;
}

const InterviewSlotsTable = ({
  slots,
  timezone,
}: Props) => {
  const columns = useMemo<TableSchema<InterviewBatchTimeSlot>>(() => [{
    header: 'Time', // Header not displayed
    displayValue: ({ start_time, end_time }) => (
      <StyledSlotTimeHeader>
        {formatMoment(Moment.tz(start_time, timezone), TimeFormat.Time)} - {formatMoment(Moment.tz(end_time, timezone), TimeFormat.TimeWithTimezone)}
      </StyledSlotTimeHeader>
    ),
  }, {
    header: 'Interviews', // Header not displayed
    displayValue: ({ interviews }) => {
      if (!interviews || interviews.length === 0) {
        return <StyledSlotEmptyText>Not booked</StyledSlotEmptyText>;
      }
      return (
        <span>
          {interviews.map(({ schedule }, i) => (
            <>
              <Link
                key={schedule.id}
                to={correctPath(`/app/candidates/${schedule.application.id}/schedules/${schedule.id}`)}
              >
                {schedule.application.candidate.name}
              </Link>
              {i !== interviews.length - 1 && ', '}
            </>
          ))}
        </span>
      );
    },
  }], [timezone]);

  return (
    <StyledSlotsTable
      data={orderBy(slots, 'start_time')}
      layout="vertical"
      schema={columns}
      showGrid
      showHeaderRow={false}
    />
  );
};

export default InterviewSlotsTable;
