import { useMemo, useState } from 'react';

import ArchiveButton from '../../../library/inputs/ArchiveButton';
import Flash from '../../../library/utils/Flash';
import Label from '../../../library/utils/Label';
import Table from '../../../library/data-display/Table';
import Tag from '../../../library/data-display/Tag';
import TrainingPhaseList from '../../../library/data-display/TrainingPhaseList';
import { StyledTableContainer, StyledTableEligibility } from './styles';
import { useUpdateTrainingProgram } from '../../../../hooks/queries/training-programs';

import type { ChangeEvent } from 'react';
import type { TableSchema } from '../../../library/data-display/Table';
import type { TrainingProgram } from '../../../../types';
import { correctPath } from 'libraries/gem';

const getRowLink = ({ id }: TrainingProgram) => correctPath(`/app/training-programs/${id}`);

interface Props {
  onPageNumberChange: (pageNumber: number) => void;
  onShowArchivedChange: (event: ChangeEvent<HTMLInputElement>) => void;
  pageNumber: number;
  showArchived: boolean;
  totalCount: number;
  trainingPrograms: TrainingProgram[];
}

const TrainingProgramListTable = ({
  trainingPrograms,
  onPageNumberChange,
  onShowArchivedChange,
  pageNumber,
  showArchived,
  totalCount,
}: Props) => {
  const [lastAction, setLastAction] = useState({
    id: '',
    isUndo: false,
    type: '',
  });

  const updateTrainingProgramMutation = useUpdateTrainingProgram();

  const handleArchive = (id: string, undo: boolean) => {
    setLastAction({
      id,
      type: 'archive',
      isUndo: undo,
    });
    updateTrainingProgramMutation.reset();
    updateTrainingProgramMutation.mutate({ id, payload: { archived: true } });
  };

  const handleUnarchive = (id: string, undo: boolean) => {
    setLastAction({
      id,
      type: 'unarchive',
      isUndo: undo,
    });
    updateTrainingProgramMutation.reset();
    updateTrainingProgramMutation.mutate({ id, payload: { archived: false } });
  };

  const handleUndo = () => {
    if (lastAction.type === 'archive') {
      handleUnarchive(lastAction.id, true);
    }
    if (lastAction.type === 'unarchive') {
      handleArchive(lastAction.id, true);
    }
  };

  const columns = useMemo<TableSchema<TrainingProgram>>(() => [{
    header: 'Interview Eligibility',
    displayValue: ({ archived_at, eligibility }) => (
      <StyledTableEligibility>
        <Tag
          hasTrainingProgram
          isTrainee={false}
          type="eligibility"
          value={eligibility}
        />
        {archived_at && <Label color="gray">Archived</Label>}
      </StyledTableEligibility>
    ),
  }, {
    header: 'Trainees',
    displayValue: ({ trainee_count }) => trainee_count || 0,
  }, {
    header: 'Graduates',
    displayValue: ({ graduate_count }) => graduate_count || 0,
  }, {
    header: 'Phases',
    displayValue: ({ training_phases }) => <TrainingPhaseList phases={training_phases} />,
  }, {
    header: 'Actions',
    isClickable: true,
    displayValue: ({ archived_at, id }) => (
      <div className="action-buttons-container">
        <ArchiveButton
          id={id}
          isArchived={Boolean(archived_at)}
          onArchive={() => handleArchive(id, false)}
          onUnarchive={() => handleUnarchive(id, false)}
        />
      </div>
    ),
  }], []);

  return (
    <StyledTableContainer>
      <Flash
        isDismissible
        message={(
          <span>
            Training program {lastAction.type}d.&nbsp;
            {!lastAction.isUndo && <a href="#" onClick={handleUndo}>Undo?</a>}
          </span>
        )}
        showFlash={lastAction.id ? updateTrainingProgramMutation.isSuccess : false}
        type="success"
      />
      <Flash
        isDismissible
        message={updateTrainingProgramMutation.error?.message}
        onDismiss={updateTrainingProgramMutation.reset}
        showFlash={updateTrainingProgramMutation.isError}
        type="danger"
      />
      <Table
        data={trainingPrograms}
        getRowLink={getRowLink}
        isPaginated
        layout="vertical"
        onPageNumberChange={onPageNumberChange}
        onShowArchivedChange={onShowArchivedChange}
        pageNumber={pageNumber}
        schema={columns}
        showArchived={showArchived}
        totalCount={totalCount}
      />
    </StyledTableContainer>
  );
};

export default TrainingProgramListTable;
