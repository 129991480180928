import { useMutation, useQueryClient } from 'react-query';

import InterviewPlanner from '../../libraries/interviewplanner';
import { QueryKey as ApplicationQueryKey } from './applications';

import type { Assignment } from '../../types';

export interface CreateAssignmentPayload {
  application_id: string;
  assignee_id: string;
  note?: string;
}

interface CreateAssignmentMutationVariables {
  payload?: CreateAssignmentPayload;
}

export const useCreateAssignment = () => {
  const queryClient = useQueryClient();

  return useMutation<Assignment, Error, CreateAssignmentMutationVariables>(({ payload }) => {
    return InterviewPlanner.request('POST', '/assignments', payload);
  }, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([ApplicationQueryKey.ListApplications]);
      queryClient.invalidateQueries([ApplicationQueryKey.RetrieveApplication, data.application_id]);
    },
  });
};

export interface UpdateAssignmentPayload {
  note?: string;
}

interface UpdateAssignmentMutationVariables {
  id: string;
  payload?: UpdateAssignmentPayload;
}

export const useUpdateAssignment = () => {
  const queryClient = useQueryClient();

  return useMutation<Assignment, Error, UpdateAssignmentMutationVariables>(({ id, payload }) => {
    return InterviewPlanner.request('POST', `/assignments/${id}`, payload, null, false);
  }, {
    onSuccess: (data: Assignment) => {
      queryClient.invalidateQueries([ApplicationQueryKey.ListApplications]);
      queryClient.invalidateQueries([ApplicationQueryKey.RetrieveApplication, data.application_id]);
    },
  });
};

interface DeleteAssignmentMutationVariables {
  id: string;
}

export const useDeleteAssignment = () => {
  const queryClient = useQueryClient();

  return useMutation<Assignment, Error, DeleteAssignmentMutationVariables>(({ id }) => {
    return InterviewPlanner.request('DELETE', `/assignments/${id}`);
  }, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([ApplicationQueryKey.ListApplications]);
      queryClient.invalidateQueries([ApplicationQueryKey.RetrieveApplication, data.application_id]);
    },
  });
};
