import Moment from 'moment-timezone';
import { isEmpty } from 'lodash';

import Flash from '../../../../../library/utils/Flash';
import pluralize from '../../../../../../libraries/pluralize';
import { formatDateTimeRange } from '../../../../../../libraries/formatters';

interface Props {
  selectedScheduleOptions: {
    label: string;
    blocks: {
      interviews: {
        start_time: string;
        interview_template: {
          duration_minutes: number;
        };
      }[];
    }[];
    conflicts: number;
  }[];
  showFlash: boolean;
  timezone: string;
}

const SelectedHoldsInfoFlash = ({ selectedScheduleOptions, showFlash, timezone }: Props) => {
  return (
    <Flash
      message={(
        <>
          You have selected {selectedScheduleOptions.length} {pluralize('schedule', selectedScheduleOptions.length)} to put on hold:
          <ul>
            {selectedScheduleOptions.map(({ blocks, conflicts, label }, i) => {
              const blockTimes = blocks.map(({ interviews }) => {
                const firstInterview = interviews[0];
                const lastInterview = interviews[interviews.length - 1];
                return {
                  startTime: Moment.tz(firstInterview.start_time, timezone),
                  endTime: Moment.tz(lastInterview.start_time, timezone).add(lastInterview.interview_template.duration_minutes, 'minutes'),
                };
              });
              return (
                <li key={`selected-option-${i}`}>
                  <b>{blocks.length}-block</b> schedule (<b>{label}</b>) with {conflicts === 0 ? 'no' : conflicts} {pluralize('conflict', conflicts)}
                  <ul>
                    {blockTimes.map(({ startTime, endTime }, blockIndex) => (
                      <li key={`block-${blockIndex}`}>
                        {formatDateTimeRange(startTime, endTime, timezone)}
                      </li>
                    ))}
                  </ul>
                </li>
              );
            })}
          </ul>
        </>
      )}
      showFlash={showFlash && !isEmpty(selectedScheduleOptions)}
      type="info"
    />
  );
};

export default SelectedHoldsInfoFlash;
