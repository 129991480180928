import styled from 'styled-components';

export const StyledFooter = styled.footer`
  align-items: center;
  border-top: 1px solid ${({ theme }) => theme.colors.mercuryGray || theme.colors.PRODUCT.SECONDARY_BORDER};
  color: ${({ theme }) => theme.colors.doveGray || theme.colors.PRODUCT.SECONDARY_CONTENT};
  display: flex;
  font-size: .8em;
  justify-content: center;
  letter-spacing: .1em;
  margin-top: auto;
  min-height: 60px;
  padding: 0;
  text-transform: uppercase;
  width: 95%;
`;

export const StyledLogoImage = styled.img`
  margin-left: 2px;
  padding: 0;
  height: 32px;
`;
