import { Breadcrumb } from 'react-breadcrumbs';

import UtilizationSummarySection from './UtilizationSummarySection';
import { correctPath } from 'libraries/gem';

const InterviewerLeaderboard = () => (
  <Breadcrumb
    data={{
      title: 'Utilization Summary',
      pathname: correctPath('/app/interviewer-reports/utilization-summary'),
    }}
  >
    <UtilizationSummarySection />
  </Breadcrumb>
);

export default InterviewerLeaderboard;
