import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';

import Flash from '../utils/Flash';
import InterviewerTemplatesSummary from './InterviewerTemplatesSummary';
import LoadingSpinner from '../utils/LoadingSpinner';
import Table from './Table';
import { formatDuration, formatPositions, formatTimeRange } from '../../../libraries/formatters';
import { liveCodingLabels } from '../../../types';
import { useInterviewTemplate } from '../../../hooks/queries/interview-templates';
import { useSession } from '../../../hooks/use-session';

import type { TableSchema } from './Table';
import type { InterviewTemplate } from '../../../types';
import { useLDFlags } from '../../../hooks/use-ld-flags';
import { correctPath } from 'libraries/gem';

interface Props {
  id: string;
  showFlash?: boolean;
  stageInterviewName: string;
}

const InterviewTemplateSummary = ({ id, showFlash = false, stageInterviewName }: Props) => {
  const { account } = useSession();

  const { candidateFacingInterviewDetails } = useLDFlags();

  const {
    data: interviewTemplate,
    isLoading,
  } = useInterviewTemplate(id);

  const schema = useMemo<TableSchema<InterviewTemplate>>(() => [{
    header: 'Duration',
    displayValue: ({ duration_minutes }) => formatDuration(duration_minutes),
  }, account?.live_coding_type && {
    header: `${liveCodingLabels[account.live_coding_type]} Link`,
    displayValue: ({ live_coding_enabled }) => (
      live_coding_enabled ?
        <FontAwesomeIcon className="check" icon={faCheck} /> :
        <span className="empty">&mdash;</span>
    ),
  }, {
    header: 'Candidate-Facing Name',
    displayValue: ({ candidate_facing_name }) => candidate_facing_name || stageInterviewName,
  }, candidateFacingInterviewDetails && {
    header: 'Candidate-Facing Details',
    displayValue: ({ candidate_facing_details }) => (
      candidate_facing_details ?
        <div dangerouslySetInnerHTML={{ __html: candidate_facing_details }} /> :
        <span className="empty">Not set</span>
    ),
  }, {
    header: 'Positions',
    displayValue: ({ positions }) => formatPositions(positions),
  }, {
    header: 'Time Window',
    displayValue: ({ time_window_start, time_window_end }) => formatTimeRange(time_window_start, time_window_end),
  }, {
    header: 'Panel',
    displayValue: ({ interviewer_templates }) => (
      interviewer_templates === null || isEmpty(interviewer_templates) ?
        <i>No interviewers</i> :
        <InterviewerTemplatesSummary interviewerTemplates={interviewer_templates} />
    ),
  }], [Boolean(account?.live_coding_type), stageInterviewName]);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="interview-template-summary">
      <Table
        data={[interviewTemplate!]}
        layout="horizontal"
        schema={schema}
      />
      <Flash
        message={<span>This template is linked to multiple interviews. You can edit its details from its <Link to={correctPath(`/app/interview-templates/${id}`)}>interview template page</Link>.</span>}
        showFlash={showFlash}
        type="info"
      />
    </div>
  );
};

export default InterviewTemplateSummary;
