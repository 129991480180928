import { find } from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ExpandableCheckboxInput from '../../../../../../library/inputs/ExpandableCheckboxInput';
import Flash from '../../../../../../library/utils/Flash';
import ListItem from '../../../../../../library/data-display/ListItem';
import Section from '../../../../../../library/layout/Section';
import SelectInput from '../../../../../../library/inputs/SelectInput';
import { SchedulingWorkflow } from '../../../../../../../types';
import { useStage, useUpdateStage } from '../../../../../../../hooks/queries/stages';

import type { ChangeEvent } from 'react';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../../../../../library/inputs/SelectInput/types';

const defaultWorkflowOptions: Option<SchedulingWorkflow>[] = [{
  value: SchedulingWorkflow.ScheduleNow,
  label: 'Schedule Now',
  secondaryText: 'View schedule options and schedule the candidate.',
}, {
  value: SchedulingWorkflow.RequestAvailability,
  label: 'Request Availability & Schedule',
  secondaryText: 'Send the candidate a link to collect availability and then schedule the candidate.',
}, {
  value: SchedulingWorkflow.SelfSchedule,
  label: 'Self-Schedule',
  secondaryText: 'Send the candidate a link to book a one-on-one interview.',
}];

const SchedulingSection = () => {
  const { id: jobId, stageId } = useParams<{ id: string; stageId: string }>();

  const { data: stage } = useStage(jobId, stageId);

  const [isSchedulable, setIsSchedulable] = useState(Boolean(stage?.schedule_template_id));
  const [defaultWorkflow, setDefaultWorkflow] = useState(stage?.schedule_template?.default_workflow || SchedulingWorkflow.ScheduleNow);
  const [isEditing, setIsEditing] = useState(false);

  const updateStageMutation = useUpdateStage();

  useEffect(() => {
    setIsSchedulable(Boolean(stage?.schedule_template_id));
    setDefaultWorkflow(stage?.schedule_template?.default_workflow || SchedulingWorkflow.ScheduleNow);
  }, [Boolean(stage?.schedule_template_id), stage?.schedule_template?.default_workflow]);

  const handleIsSchedulableChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsSchedulable(e.target.checked);
  };

  const handleDefaultWorkflowChange = (option: OnChangeValue<Option<SchedulingWorkflow>, false>) => {
    setDefaultWorkflow(option?.value || SchedulingWorkflow.ScheduleNow);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    updateStageMutation.reset();
    setIsSchedulable(Boolean(stage?.schedule_template_id));
    setDefaultWorkflow(stage?.schedule_template?.default_workflow || SchedulingWorkflow.ScheduleNow);
    setIsEditing(false);
  };

  const handleSave = async () => {
    updateStageMutation.reset();

    try {
      await updateStageMutation.mutateAsync({
        id: stageId,
        jobId,
        payload: {
          schedulable: isSchedulable,
          default_workflow: isSchedulable ? defaultWorkflow : undefined,
        },
      });
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  const hasScheduleTemplate = Boolean(stage?.schedule_template_id);

  return (
    <Section
      isEditable
      isEditing={isEditing}
      isSaving={updateStageMutation.isLoading}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Scheduling"
    >
      <Flash
        message="If you disable scheduling, the time and location preferences associated with this stage will also be erased."
        showFlash={hasScheduleTemplate && (isEditing || updateStageMutation.isLoading)}
        type="warning"
      />
      <Flash
        isDismissible
        message="Successfully updated!"
        onDismiss={updateStageMutation.reset}
        showFlash={updateStageMutation.isSuccess}
        type="success"
      />
      <Flash
        message={updateStageMutation.error?.message}
        showFlash={updateStageMutation.isError}
        type="danger"
      />
      <ExpandableCheckboxInput
        isChecked={isSchedulable}
        isDisabled={!isEditing || updateStageMutation.isLoading}
        label="Enable scheduling for this stage."
        onChange={handleIsSchedulableChange}
      >
        <SelectInput
          formatOptionLabel={(option, { context }) => (
            context === 'menu' ?
              <ListItem
                label={option.label}
                secondaryText={option.secondaryText}
              /> :
              option.label
          )}
          isDisabled={!isEditing || updateStageMutation.isLoading}
          label="Default Workflow"
          onChange={handleDefaultWorkflowChange}
          options={defaultWorkflowOptions}
          value={find(defaultWorkflowOptions, ['value', defaultWorkflow])}
        />
      </ExpandableCheckboxInput>
    </Section>
  );
};

export default SchedulingSection;
