import { Link } from 'react-router-dom';

import Button from '../../library/inputs/Button';
import Card from '../../library/layout/Card';

interface Props {
  description: string;
  name: string;
  price: number | string;
}

const PricingTier = ({ description, name, price }: Props) => {
  const isCustom = price === 'Custom';

  return (
    <div className="pricing-tier-container">
      <Card
        className="pricing-tier"
        title={name}
      >
        <div className="description">
          {description}
        </div>
        <div className="monthly-price">
          {isCustom ?
            <span className="price">Custom</span> :
            <div>
              <span className="dollar-sign">$</span>
              <span className="price">{price.toLocaleString()}</span>
              <span className="per-unit">/mo</span>
            </div>
          }
          <del>
          </del>
        </div>
        <div className="action-container">
          {name === 'Enterprise' ?
            <Link to="/contact">
              <Button
                color="blue-outline"
                onClick={() => analytics.track('Enterprise Talk to Us Button Clicked', { label: 'Pricing' })}
                size="large"
                value="Talk to us"
              />
            </Link> :
            <Link to="/signup">
              <Button
                color="orange"
                onClick={() => analytics.track(`${name} Get Started Button Clicked`, { label: 'Pricing' })}
                size="large"
                value="Get started"
              />
            </Link>
          }
        </div>
      </Card>
    </div>
  );
};

export default PricingTier;
