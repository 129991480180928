import { Text } from 'slate';

import type { Descendant } from 'slate';

// This doesn't really handle converting lists into text very well. That should
// be okay though because this is only used for plaintext inputs and for
// required validation.
export function slateValueToText (value: Descendant[]): string {
  // If there's no children yet, just return the empty string.
  if (!value) {
    return '';
  }
  return value.map((node) => toString(node)).join('\n');
}

function toString (node: Descendant): string {
  if (Text.isText(node)) {
    return node.text;
  }

  if (node.type === 'token') {
    return `{{ ${node.token} }}`;
  }

  return node.children.map((node) => toString(node)).join('');
}
