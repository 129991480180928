import { Button, useDayRender } from 'react-day-picker';
import { useId, useRef } from 'react';

import Tooltip from '../../utils/Tooltip';
import { useDatePickerContext } from './use-context';

import type { DayProps } from 'react-day-picker';

/**
 * This is inspired from https://react-day-picker.js.org/guides/custom-components#range-selections-with-shift-key. Using
 * useDayRender is the only way we can get the active modifiers, even if it means that we need to include a bunch of
 * logic that isn't really custom.
 */
const DatePickerDay = (props: DayProps) => {
  const buttonRef = useRef<HTMLButtonElement>(null);

  const tooltipId = useId();

  const dayRender = useDayRender(props.date, props.displayMonth, buttonRef);
  const { dayTooltipText } = useDatePickerContext();

  if (dayRender.isHidden) {
    return <></>;
  }
  if (!dayRender.isButton) {
    return <div {...dayRender.divProps} />;
  }

  return (
    <>
      <Button
        {...dayRender.buttonProps}
        data-for={dayTooltipText?.available && dayRender.activeModifiers.available && tooltipId}
        data-tip={dayTooltipText?.available && dayRender.activeModifiers.available ? true : undefined}
        ref={buttonRef}
      />
      {dayTooltipText?.available && dayRender.activeModifiers.available &&
        <Tooltip
          id={tooltipId}
          position="top"
          value="Candidate is available"
        />
      }
    </>
  );
};

export default DatePickerDay;
