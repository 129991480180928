import { NavLink } from 'react-router-dom';

import Badge from '../../library/utils/Badge';
import Label from '../../library/utils/Label';

import type { ReactNode } from 'react';

interface Props {
  badgeValue?: ReactNode;
  icon?: ReactNode;
  isComingSoon?: boolean;
  isNew?: boolean;
  path: string;
  showBadge?: boolean;
  title: string;
}

const MenuNavLink = ({
  badgeValue,
  icon,
  isComingSoon = false,
  isNew = false,
  path,
  showBadge = false,
  title,
}: Props) => {
  if (isComingSoon) {
    return (
      <span className="nav-link">
        {icon}{title}<Label color="blue">Coming soon!</Label>
      </span>
    );
  }

  return (
    <NavLink
      className="nav-link link"
      to={path}
    >
      {icon}
      <span className="nav-link-text">{title}</span>
      {isNew && <Label color="green">New</Label>}
      {showBadge && <Badge value={badgeValue} />}
    </NavLink>
  );
};

export default MenuNavLink;
