import styled from 'styled-components';
import ListItem from '../../../library/data-display/ListItem';

export const StyledFiltersContainer = styled.div`
  margin-bottom: 1em;
`;

export const StyledTableListItem = styled(ListItem)`
  padding: 0;

  /* This is needed to make a row with a link look the same as a row without a link. */
  &.extra-padding {
    padding: 10px 0;
  }
`;
