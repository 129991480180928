import { useCallback, useState } from 'react';
import { Breadcrumb } from 'react-breadcrumbs';
import { useHistory } from 'react-router-dom';

import JobSelectInput from 'components/library/inputs/JobSelectInput';
import MultiStepFormStep from 'components/library/inputs/MultiStepFormStep';
import TextInput from 'components/library/inputs/TextInput';
import Flash from 'components/library/utils/Flash';
import { useCreateInterviewBatch } from 'hooks/queries/interview-batches';
import { correctPath } from 'libraries/gem';
import EditSuperdayTimesTable from '../EditSuperdayTimesTable';
import { StyledFormContainer, StyledFormRowContainer, StyledSection } from './styles';

import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from 'components/library/inputs/SelectInput/types';
import type { EditableInterviewBatchTime } from 'types';

const SuperdayCreate = () => {
  const history = useHistory();

  const [name, setName] = useState('');
  const [jobId, setJobId] = useState('');
  const [times, setTimes] = useState<EditableInterviewBatchTime[]>([]);

  const createInterviewBatchMutation = useCreateInterviewBatch();

  const handleSubmit = useCallback(async () => {
    createInterviewBatchMutation.reset();

    try {
      const data = await createInterviewBatchMutation.mutateAsync({
        payload: {
          name,
          job_id: jobId,
          times,
        },
      });
      history.push(correctPath(`/app/superdays/${data.id}`));
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  }, [createInterviewBatchMutation, history]);

  return (
    <Breadcrumb
      data={{
        title: 'Create',
        pathname: correctPath('/app/superdays/create'),
      }}
    >
      <StyledSection title="Create a new superday">
        <Flash
          message={createInterviewBatchMutation.error?.message}
          showFlash={createInterviewBatchMutation.isError}
          type="danger"
        />
        <MultiStepFormStep
          isFirstStep
          isLastStep
          isSubmitting={createInterviewBatchMutation.isLoading}
          nextButtonValue="Create Superday"
          onNext={handleSubmit}
        >
          <StyledFormContainer>
            <StyledFormRowContainer>
              <TextInput
                isRequired
                label="Superday Name"
                onChange={(e) => setName(e.target.value)}
                value={name}
              />
              <JobSelectInput
                label="Job"
                onChange={(option: OnChangeValue<Option<string>, false>) => setJobId(option?.value || '')}
                value={jobId}
              />
            </StyledFormRowContainer>
            <EditSuperdayTimesTable
              jobId={jobId}
              setTimes={setTimes}
              times={times}
            />
          </StyledFormContainer>
        </MultiStepFormStep>
      </StyledSection>
    </Breadcrumb>
  );
};

export default SuperdayCreate;
