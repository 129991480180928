import Flash from 'components/library/utils/Flash';

import { StyledContainer, StyledImage } from './styles';

const CandidateSyncDelayMessage = () => (
  <StyledContainer>
    <Flash
      message={
        <>
          <p>
            It typically takes 1-3 minutes to sync a candidate to Gem Scheduling. This page will redirect when the candidate is ready for scheduling.
          </p>
          <p>
            If you continue to see this page for longer than five minutes, please contact support.
          </p>
        </>
      }
      showFlash
      type="info"
    />
    <StyledImage
      alt="Loading ducks"
      src="/scheduling/src/assets/images/schedules-loading.gif"
    />
  </StyledContainer>
);

export default CandidateSyncDelayMessage;
