import styled from 'styled-components';

import Banner from 'components/library/utils/Banner';

export const StyledBanner = styled(Banner)`
  align-items: center;
  background: #dde9ff !important;
  border-bottom: 1px solid #0d61ff;
  color: #17181a;
  flex-wrap: wrap;
  font-size: 1em !important;
  display: flex;
  letter-spacing: 0 !important;
  justify-content: center;
  padding: .5em !important;

  a {
    color: #0d61ff !important;
  }
`;

export const StyledEmoji = styled.span`
  font-size: 1.5em;
  margin-right: .5em;
`;

export const StyledImage = styled.img`
  height: 2em;
  position: relative;
`;
