import styled from 'styled-components';

import Table from 'components/library/data-display/Table';

// The typing for this is weird because of styled-components. This is inspired from
// https://github.com/styled-components/styled-components/issues/1803#issuecomment-857092410
export const StyledTable = styled(Table)`
  tr:nth-child(3) > th {
    vertical-align: middle;
  }
` as typeof Table;

export const StyledDateCreatedContainer = styled.div`
  display: flex;
  align-items: center;

  .list-item {
    padding-top: 0;
    padding-bottom: 0;
  }
`;
