import { useMutation, useQuery, useQueryClient } from 'react-query';

import InterviewPlanner from '../../libraries/interviewplanner';
import { useSession } from '../use-session';

import type { Account, ATS, ChatTool, LiveCodingTool, VideoConferencingTool } from '../../types';
import type { UseQueryOptions } from 'react-query';

export enum QueryKey {
  RetrieveAccount = 'RetrieveAccount',
}

export const useAccount = (options: UseQueryOptions<Account, Error> = {}) => {
  const { session } = useSession();
  const id = session?.account_id;

  return useQuery<Account, Error>([QueryKey.RetrieveAccount, id], () => {
    return InterviewPlanner.request('GET', `/accounts/${id}`);
  }, {
    enabled: options.enabled !== undefined ? options.enabled : Boolean(id),
    ...options,
  });
};

export interface UpdateAccountPayload {
  name?: string;
  logo?: Blob;
  color?: string;
  availability_message?: string;
  availability_message_in_self_scheduling?: boolean;
  interviewer_event_title?: string;
  ignore_words?: {
    id: string;
    negated: boolean;
  }[];
  ooo_words?: {
    id: string;
    negated: boolean;
  }[];
  ats_type?: `${ATS}`;
  ats_api_key?: string;
  greenhouse_subdomain?: string;
  video_conferencing_type?: `${VideoConferencingTool}`;
  chat_type?: `${ChatTool}` | '';
  hiring_channel_prefix?: string; // max 60 characters
  hiring_channel_name_template?: string; // max 60 characters
  chat_prevent_removing_users?: boolean;
  chat_prevent_archiving_channels?: boolean;
  chat_notify_channel_on_archive?: boolean;
  scheduling_calendar_email?: string;
  candidate_scheduling_calendar_email?: string;
  exclude_schedules_attendee?: boolean;
  zoom_host_concurrent_meeting_limit?: number;
  zoom_meeting_buffer_minutes?: number;
  enable_pronouns?: boolean;
  enable_pronunciation?: boolean;
  daily_interview_limit?: number;
  weekly_interview_limit?: number;
  interviewer_meeting_buffer_minutes?: number;
  ignore_free_events?: boolean;
  live_coding_type?: `${LiveCodingTool}` | '';
  live_coding_api_key?: string;
}
interface UpdateAccountMutationVariables {
  id: string;
  payload?: UpdateAccountPayload;
}

export const useUpdateAccount = () => {
  const queryClient = useQueryClient();

  return useMutation<Account, Error, UpdateAccountMutationVariables>(({ id, payload }) => {
    const isMultipartForm = Boolean(payload?.logo);
    return InterviewPlanner.request('POST', `/accounts/${id}`, payload, null, isMultipartForm);
  }, {
    onSuccess: (data: Account, { id }) => {
      queryClient.setQueryData([QueryKey.RetrieveAccount, id], data);
    },
  });
};
