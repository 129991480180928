import Moment from 'moment-timezone';

export function addAdvancedNotice (date: Date, advancedNoticeHours: number): Date {
  if (advancedNoticeHours === 0) {
    // We don't have any advanced notice, so we just return early.
    return date;
  }

  const momentDate = Moment(date);

  while (advancedNoticeHours > 0) {
    switch (momentDate.day()) {
      case 5:
        // The current day is Friday. If we have a lot of advanced notice left,
        // we need to jump to Monday and only subtract the hours of Friday that
        // we advanced.

        const hoursUntilNextDay = Math.ceil(momentDate.clone().startOf('day').add(1, 'day').diff(momentDate, 'hour', true));

        if (hoursUntilNextDay <= advancedNoticeHours) {
          // We need to advance past Friday, so we jump to Monday and only
          // subtract the hours of Friday.
          momentDate.startOf('day').add(3, 'day');
          advancedNoticeHours -= hoursUntilNextDay;
          continue;
        }

        // We have less than a full day's worth of advanced notice to account for,
        // so we just add those hours directly.
        momentDate.add(advancedNoticeHours, 'hours');
        advancedNoticeHours = 0;
        break;
      case 6:
        // The current day is Saturday. We just need to skip to Monday.
        momentDate.startOf('day').add(2, 'day');
        break;
      case 0:
        // The current day is Sunday. We just need to skip to Monday.
        momentDate.startOf('day').add(1, 'day');
        break;
      default:
        // The current day is not Friday, Saturday, or Sunday, so it's just a
        // normal weekday, so we should be able to add up to a day without dealing
        // with weekend logic.

        if (advancedNoticeHours >= 24) {
          // We have at least a day's worth of advanced notice, so we increment by
          // a day, subtract 24 hours, and loop again. We're incrementing by 1 day
          // instead of 24 hours so that we don't get tripped up by DST.
          momentDate.add(1, 'day');
          advancedNoticeHours -= 24;
          continue;
        }

        // We have less than a full day's worth of advanced notice to account for,
        // so we just add those hours directly.
        momentDate.add(advancedNoticeHours, 'hours');
        advancedNoticeHours = 0;
    }
  }

  return momentDate.toDate();
}
