import { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { StyledModal } from './styles';

import type { ActionMeta } from 'react-select';
import type { ComponentType } from 'react';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../inputs/SelectInput/types';

interface SelectInputComponentProps {
  onChange: (newValue: OnChangeValue<Option<string>, false>, actionMeta: ActionMeta<Option<string>>) => void;
  value?: string;
}

interface Props {
  isOpen: boolean;
  onToggle: () => void;
  redirectPath: string;
  resourceName: string;
  selectInputComponent: ComponentType<SelectInputComponentProps>;
}

const SelectBaseTemplateModal = ({
  isOpen,
  onToggle,
  redirectPath,
  resourceName,
  selectInputComponent: SelectInputComponent,
}: Props) => {
  const history = useHistory();

  const [value, setValue] = useState<string>();

  // Reset the value each time the modal is closed.
  useEffect(() => {
    if (isOpen) {
      setValue('');
    }
  }, [isOpen]);

  const handleValueChange = (option: OnChangeValue<Option<string>, false>) => {
    setValue(option?.value);
  };

  const handleSubmit = () => {
    history.push({
      pathname: redirectPath,
      search: `?base=${value}`,
    });
  };

  return (
    <StyledModal
      isOpen={isOpen}
      onSubmit={handleSubmit}
      onToggle={onToggle}
      showSubmitButton={Boolean(value)}
      submitButtonValue="Continue"
      title={`Pick a base ${resourceName}?`}
    >
      <SelectInputComponent
        onChange={handleValueChange}
        value={value}
      />
    </StyledModal>
  );
};

export default SelectBaseTemplateModal;
