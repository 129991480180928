import { useEffect, useState } from 'react';

import { Directory } from 'types';
import Flash from 'components/library/utils/Flash';
import LoadingSpinner from 'components/library/utils/LoadingSpinner';
import SchedulingCalendarSelectInput from 'components/library/inputs/SchedulingCalendarSelectInput';
import { StyledModal } from './styles';
import { useAccount, useUpdateAccount } from 'hooks/queries/accounts';
import { useCalendars } from 'hooks/queries/calendars';

import type { Option } from 'components/library/inputs/SelectInput/types';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';

const directoryMapping: Record<Directory, string> = {
  [Directory.Google]: 'Google',
  [Directory.Microsoft]: 'Outlook',
};

interface Props {
  isOpen: boolean;
  onToggle: () => void;
}

const SetSchedulingCalendarModal = ({ isOpen, onToggle }: Props) => {
  const { data } = useAccount();
  const account = data!;

  const {
    error: calendarsError,
    isLoading: isCalendarsLoading,
  } = useCalendars();

  const [schedulingCalendar, setSchedulingCalendar] = useState<string>(account.scheduling_calendar_email || '');

  const updateAccountMutation = useUpdateAccount();

  useEffect(() => {
    setSchedulingCalendar(account.scheduling_calendar_email || '');
  }, [account.scheduling_calendar_email]);

  const handleSchedulingCalendarChange = (option: OnChangeValue<Option<string>, false>) => setSchedulingCalendar(option ? option.value : '');

  const handleToggle = () => {
    onToggle();
  };

  const handleSave = async () => {
    updateAccountMutation.reset();

    try {
      await updateAccountMutation.mutateAsync({
        id: account.id,
        payload: {
          scheduling_calendar_email: schedulingCalendar,
        },
      });
      onToggle();
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  return (
    <StyledModal
      className="dropdown-modal"
      isOpen={isOpen}
      isSubmitting={updateAccountMutation.isLoading}
      onSubmit={handleSave}
      onToggle={handleToggle}
      submitButtonValue="Save"
      title="Pick a scheduling calendar?"
    >
      {isCalendarsLoading ?
        <LoadingSpinner /> :
        <>
          <Flash
            message={calendarsError?.message}
            showFlash={Boolean(calendarsError)}
            type="danger"
          />
          <Flash
            message={updateAccountMutation.error?.message}
            showFlash={updateAccountMutation.isError}
            type="danger"
          />
          <Flash
            message={<>
              <p>
                We encourage using a shared {directoryMapping[account.directory_type]} calendar to send interviewer and candidate invites.
              </p>
              <p>
                You can also set default scheduling calendars per role and stage, and you can always switch calendars on the fly when scheduling a candidate.
              </p>
            </>}
            showFlash
            type="info"
          />
          <p>
          </p>
          <SchedulingCalendarSelectInput
            helperText="This calendar should be shared and accessible to all team members who schedule interviews."
            isClearable
            isDisabled={updateAccountMutation.isLoading}
            onChange={handleSchedulingCalendarChange}
            schedulingCalendar={schedulingCalendar}
          />
        </>
      }
    </StyledModal>
  );
};

export default SetSchedulingCalendarModal;
