import { useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';

import CalendarEventTemplateAdvancedSettings from '../../../library/inputs/CalendarEventTemplateAdvancedSettings';
import CalendarEventTemplateTypeSelectInput from '../../../library/inputs/CalendarEventTemplateTypeSelectInput';
import EditorInput from '../../../library/inputs/EditorInput';
import ErrorTokenFlash from '../../../library/utils/ErrorTokenFlash';
import Flash from '../../../library/utils/Flash';
import MultiStepFormStep from '../../../library/inputs/MultiStepFormStep';
import TextInput from '../../../library/inputs/TextInput';
import TokenInput from '../../../library/inputs/TokenInput';
import useQueryState from '../../../../hooks/use-query-state';
import { CalendarEventTemplateType } from '../../../../types';
import { DEFAULT_CALENDAR_EVENT_CONTENT } from './helpers';
import { getTokensFromSlateValue, slateValueToHtml } from '../../../../libraries/editor/slate-value-to-html';
import { slateValueToText } from '../../../../libraries/editor/slate-value-to-text';
import { useAllCalendarEventTokens } from '../../../../hooks/queries/tokens';
import { useCalendarEventTemplate, useCreateCalendarEventTemplate } from '../../../../hooks/queries/calendar-event-templates';
import { useSession } from '../../../../hooks/use-session';
import { useSlateEditor } from '../../../../hooks/use-slate-editor';

import type { ChangeEvent } from 'react';
import type { Option } from '../../../library/inputs/CalendarEventTemplateTypeSelectInput';
import type { CreateCalendarEventTemplatePayload } from '../../../../hooks/queries/calendar-event-templates';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import { correctPath } from 'libraries/gem';

const CalendarEventTemplateCreateForm = () => {
  const history = useHistory();

  const [baseId] = useQueryState<string>('base', '');
  const { data: baseTemplate } = useCalendarEventTemplate(baseId);

  const { account } = useSession();

  const [type, setType] = useState<`${CalendarEventTemplateType}`>(baseTemplate?.type || CalendarEventTemplateType.CandidateCalendarEvent);
  const [name, setName] = useState(baseTemplate?.name ? `Copy of ${baseTemplate.name}` : '');
  const [titleSlateEditor, titleSlateValue, setTitleSlateValue, setTitle] = useSlateEditor(baseTemplate?.title || `${account?.name ? `${account.name} ` : ''}${DEFAULT_CALENDAR_EVENT_CONTENT[CalendarEventTemplateType.CandidateCalendarEvent].title}`, true);
  const [descriptionSlateEditor, descriptionSlateValue, setDescriptionSlateValue] = useSlateEditor(baseTemplate?.description || '');
  const [location, setLocation] = useState(baseTemplate?.location || '');
  const [additionalAttendees, setAdditionalAttendees] = useState<string[]>(baseTemplate?.additional_attendees || []);
  const [additionalOptionalAttendees, setAdditionalOptionalAttendees] = useState<string[]>(baseTemplate?.additional_optional_attendees || []);

  const createCalendarEventTemplateMutation = useCreateCalendarEventTemplate();

  const {
    data: tokens,
    error: tokensError,
  } = useAllCalendarEventTokens();

  const tokensUsedInTitle = useMemo(() => getTokensFromSlateValue(titleSlateValue), [titleSlateValue]);
  const tokensUsedInDescription = useMemo(() => getTokensFromSlateValue(descriptionSlateValue), [descriptionSlateValue]);
  const errorTokens = useMemo(() => {
    const errorTitleTokens = (
      tokens?.[type] && tokensUsedInTitle ?
        tokensUsedInTitle.filter((name) => !tokens[type][name] || tokens[type][name].disabled) :
        []
    );
    const errorDescriptionTokens = (
      tokens?.[type] && tokensUsedInDescription ?
        tokensUsedInDescription.filter((name) => !tokens[type][name] || tokens[type][name].disabled) :
        []
    );
    return [
      ...errorTitleTokens,
      ...errorDescriptionTokens,
    ];
  }, [tokens, tokensUsedInTitle, tokensUsedInDescription]);

  const handleTypeChange = (option: OnChangeValue<Option, false>) => {
    // You can't clear the type, so option should never be null.
    setType(option!.value);
    if (!slateValueToText(titleSlateValue)) {
      setTitle(`${account?.name ? `${account.name} ` : ''}${DEFAULT_CALENDAR_EVENT_CONTENT[option!.value].title}`);
    }
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleLocationChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLocation(e.target.value);
  };

  const handleSubmit = async () => {
    if (errorTokens.length > 0) {
      // We don't need to display an error because ErrorTokenFlash already takes
      // care of that. We just need to stop the execution from here.
      return;
    }

    const payload: CreateCalendarEventTemplatePayload = {
      name,
      type,
      title: slateValueToText(titleSlateValue),
      description: slateValueToHtml(descriptionSlateValue),
      location,
      additional_attendees: additionalAttendees,
      additional_optional_attendees: additionalOptionalAttendees,
    };

    createCalendarEventTemplateMutation.reset();

    try {
      const data = await createCalendarEventTemplateMutation.mutateAsync({ payload });
      history.push(correctPath(`/app/calendar-event-templates/${data.id}`));
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  return (
    <MultiStepFormStep
      isFirstStep
      isLastStep
      isSubmitting={createCalendarEventTemplateMutation.isLoading}
      nextButtonValue="Create Calendar Event Template"
      onNext={handleSubmit}
    >
      <Flash
        message={tokensError?.message}
        showFlash={Boolean(tokensError)}
        type="danger"
      />
      <Flash
        message={createCalendarEventTemplateMutation.error?.message}
        showFlash={createCalendarEventTemplateMutation.isError}
        type="danger"
      />
      <ErrorTokenFlash errorTokens={errorTokens} />
      <div className="type-name-container">
        <CalendarEventTemplateTypeSelectInput
          onChange={handleTypeChange}
          value={type}
        />
        <TextInput
          isDisabled={createCalendarEventTemplateMutation.isLoading}
          isRequired
          label="Template Name"
          onChange={handleNameChange}
          value={name}
        />
      </div>
      <div className="title-location-container">
        {tokens?.[type] && (
          <TokenInput
            editor={titleSlateEditor}
            isDisabled={createCalendarEventTemplateMutation.isLoading}
            isRequired
            label="Title"
            pendingPreviewMessage="You can preview this token when you are scheduling a candidate."
            setValue={setTitleSlateValue}
            tokens={tokens[type]}
            type={type}
            value={titleSlateValue}
          />
        )}
        <TextInput
          helperText="We will replace this with the video conferencing link for remote interviews."
          isDisabled={createCalendarEventTemplateMutation.isLoading}
          label="Location"
          onChange={handleLocationChange}
          value={location}
        />
      </div>
      {tokens?.[type] && (
        <EditorInput
          editor={descriptionSlateEditor}
          exampleHtmlContent={DEFAULT_CALENDAR_EVENT_CONTENT[type].description}
          isDisabled={createCalendarEventTemplateMutation.isLoading}
          label="Description"
          pendingPreviewMessage="You can preview this token when you are scheduling a candidate."
          setValue={setDescriptionSlateValue}
          tokens={tokens[type]}
          type={type}
          value={descriptionSlateValue}
        />
      )}
      <CalendarEventTemplateAdvancedSettings
        additionalAttendees={additionalAttendees}
        additionalOptionalAttendees={additionalOptionalAttendees}
        isDisabled={createCalendarEventTemplateMutation.isLoading}
        setAdditionalAttendees={setAdditionalAttendees}
        setAdditionalOptionalAttendees={setAdditionalOptionalAttendees}
      />
    </MultiStepFormStep>
  );
};

export default CalendarEventTemplateCreateForm;
