import { partition } from 'lodash';
import { useMemo } from 'react';

import SelectInput from './SelectInput';

import type { Dispatch, SetStateAction } from 'react';
import type { EditableIgnoreWord } from '../../../types';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from './SelectInput/types';

interface Props {
  isDisabled?: boolean;
  keywords?: EditableIgnoreWord[];
  setKeywords: Dispatch<SetStateAction<EditableIgnoreWord[]>>;
}

const ConflictKeywordsInput = ({ isDisabled = false, keywords, setKeywords }: Props) => {
  const [overrideKeywords, ignoreKeywords] = useMemo(() => partition(keywords, 'negated'), [keywords]);

  const handleIgnoreKeywordsChange = (options: OnChangeValue<Option<string>, true>) => {
    setKeywords([
      ...(options || []).map((option) => ({
        id: option.value,
        negated: false,
      })),
      ...overrideKeywords,
    ]);
  };

  const handleOverrideKeywordsChange = (options: OnChangeValue<Option<string>, true>) => {
    setKeywords([
      ...ignoreKeywords,
      ...(options || []).map((option) => ({
        id: option.value,
        negated: true,
      })),
    ]);
  };

  const values = useMemo(() => ({
    ignore: ignoreKeywords.map((keyword) => ({
      ...keyword,
      value: keyword.id,
    })),
    override: overrideKeywords.map((keyword) => ({
      ...keyword,
      value: keyword.id,
    })),
  }), [ignoreKeywords, overrideKeywords]);

  return (
    <div className="input conflict-keywords-select-input">
      <SelectInput
        formatCreateLabel={(value) => `Add keyword "${value}"`}
        formatOptionLabel={(option) => {
          // The type for formatOptionLabel isn't correct. Fix this once
          // https://github.com/JedWatson/react-select/issues/5064 is addressed.
          const { __isNew__, label, value } = option as any;
          return __isNew__ ? label : value;
        }}
        helperText={<span>When generating schedules, <b>we will ignore conflicts</b> if they contain any of these keywords. These keywords are <i>not</i> case sensitive.</span>}
        isCreatable
        isDisabled={isDisabled}
        isMenuOpen
        isMulti
        label="Ignore Keywords"
        name="ignore"
        noOptionsMessage={({ inputValue }) => {
          if (inputValue) {
            return 'You have already added this keyword.';
          }
          return null;
        }}
        onChange={handleIgnoreKeywordsChange}
        options={[]}
        placeholder="Enter a word or phrase"
        value={values.ignore}
      />
      <SelectInput
        className="override-select-input"
        formatCreateLabel={(value) => `Add override keyword "${value}"`}
        formatOptionLabel={(option) => {
          // The type for formatOptionLabel isn't correct. Fix this once
          // https://github.com/JedWatson/react-select/issues/5064 is addressed.
          const { __isNew__, label, value } = option as any;
          return __isNew__ ? label : value;
        }}
        helperText={<span>If a conflict contains any of these keywords, <b>we will still count it as a conflict</b>, even if it contains &apos;ignore&apos; keywords.</span>}
        isCreatable
        isDisabled={isDisabled}
        isMulti
        label="Overrides"
        name="override"
        noOptionsMessage={({ inputValue }) => {
          if (inputValue) {
            return 'You have already added this keyword.';
          }
          return null;
        }}
        onChange={handleOverrideKeywordsChange}
        options={[]}
        placeholder="Enter a word or phrase"
        value={values.override}
      />
    </div>
  );
};

export default ConflictKeywordsInput;
