import styled from 'styled-components';
import OutboundLink from '../../navigation/OutboundLink';

// This container for the loading spinner makes the space that it takes up similar to the size of the rendered summary.
// This makes it so that, if it's in a popover, positioning is similar.
export const StyledLoadingSpinnerContainer = styled.div`
  align-items: center;
  display: flex;
  height: 200px;
  justify-content: center;
  width: 250px;
`;

export const StyledContainer = styled.div`
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.colors.PRODUCT.PRIMARY_BORDER};
  display: flex;
  flex-direction: column;
  font-size: 14px;
`;

export const StyledHeader = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.PRODUCT.SECONDARY_BORDER};
  display: flex;
  flex-grow: 1;
  margin-left: 1em;
  margin-right: 1em;
  margin-top: 1em;
  padding-bottom: 1em;
`;

export const StyledLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
`;

export const StyledTime = styled.div`
  align-items: center;
  color: ${({ theme }) => theme.colors.PRODUCT.TERTIARY_CONTENT};
  display: flex;
  font-size: 11px;
  font-weight: normal;
  margin-bottom: .5em;
`;

export const StyledVideoConferencingLink = styled(OutboundLink)`
  padding: 0;

  .avatar {
    border: 0;
    height: 1.4em;
    margin: 0 8px;
    width: 1.4em;
  }

  .avatar-teams {
    img {
      border-radius: 0;
    }
  }
`;

export const StyledName = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  font-weight: bold;
  justify-content: space-around;
`;

export const StyledRoom = styled.div`
  color: ${({ theme }) => theme.colors.PRODUCT.SECONDARY_CONTENT};
  font-size: .8em;
`;

export const StyledAlert = styled.div`
  background: ${({ theme }) => theme.colors.SEMANTIC.NEGATIVE_BACKGROUND};
  border-radius: 50%;
  color: ${({ theme }) => theme.colors.ACCENT.RED};
  display: flex;
  height: 1em;
  justify-content: center;
  margin: 0 auto;
  padding: 1em;
  text-align: center;
  width: 1em;
`;

export const StyledContent = styled.div`
  padding: 1em;

  .list-item-label-container {
    overflow: hidden;

    .list-item-label,
    .secondary-text {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }
`;

export const StyledFooter = styled.div`
  background: ${({ theme }) => theme.colors.PRODUCT.TERTIARY_BACKGROUND};
  border-radius: 0 0 6px 6px;
  border-top: 1px solid ${({ theme }) => theme.colors.PRODUCT.SECONDARY_BORDER};
  text-align: center;

  &:not(.stub):hover {
    background: ${({ theme }) => theme.colors.PRODUCT.SECONDARY_BACKGROUND};
  }

  /* This will just be there to take up space so it matches a footer that has a link in it. */
  &.stub {
    background: white;
    border-top: none;
    color: white;
    font-size: .7rem;
    padding: 8px 25px;
    user-select: none;
    -webkit-user-select: none;
  }

  .btn {
    width: 100%;
  }
`;
