import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faCheck, faExternalLinkAlt, faUndo } from '@fortawesome/free-solid-svg-icons';

import Button from '../../library/inputs/Button';
import Config from '../../../../config';
import OutboundLink from '../../library/navigation/OutboundLink';
import Tooltip from '../../library/utils/Tooltip';
import { NotificationType } from '../../../types';
import { useSession } from '../../../hooks/use-session';
import { useSignOut } from '../../../hooks/queries/auth';

import type { MouseEventHandler } from 'react';
import type { NotificationMetadata } from '../../../types';
import { correctPath } from 'libraries/gem';

interface PropsBase {
  id: string;
  isRead: boolean;
  onComplete: MouseEventHandler<HTMLButtonElement>;
  onUncomplete: MouseEventHandler<HTMLButtonElement>;
}

type Props = PropsBase & NotificationMetadata;

const TaskActionButtons = (props: Props) => {
  const { id, isRead, onComplete, onUncomplete } = props;

  const { account } = useSession();

  const signOutMutation = useSignOut();

  const handleSignOut = async () => {
    await signOutMutation.mutateAsync();
  };

  const completeButton = (
    <Button
      color="gray"
      iconRight={<FontAwesomeIcon icon={isRead ? faUndo : faCheck} />}
      onClick={isRead ? onUncomplete : onComplete}
      tooltip={
        <Tooltip
          id={`${id}-task-action-mark-completed`}
          position="top"
          value={isRead ? 'Mark task incomplete' : 'Mark task complete'}
        />
      }
    />
  );

  switch (props.type) {
    case NotificationType.AvailabilityRequestCancelled:
      return (
        <>
          <Link to={correctPath(`/app/candidates/${props.metadata.application_id}/request-availability`)}>
            <Button
              color="orange"
              size="small"
              value="Re-request"
            />
          </Link>
          {completeButton}
        </>
      );
    case NotificationType.CandidateDeclined:
      return (
        <>
          <Link to={correctPath(`/app/candidates/${props.metadata.application_id}/schedules/${props.metadata.schedule_id}`)}>
            <Button
              color="gem-outline"
              size="small"
              value="Cancel Schedule"
            />
          </Link>
          {completeButton}
        </>
      );
    case NotificationType.GoogleTokenError:
      return (
        <>
          <Button
            color="gem-outline"
            onClick={handleSignOut}
            size="small"
            value="Sign Out"
          />
          {completeButton}
        </>
      );
    case NotificationType.GreenhouseAPIKeyError:
      return (
        <>
          <Link to={correctPath('/app/integrations')}>
            <Button
              color="gem-outline"
              size="small"
              value="Edit API Key"
            />
          </Link>
          {completeButton}
        </>
      );
    case NotificationType.GreenhousePermissionError:
      return (
        <>
          <OutboundLink
            externalLinkIcon={false}
            href={`https://${account?.greenhouse_subdomain}.greenhouse.io/configure/dev_center/credentials`}
            label="Greenhouse Permission Error Task"
          >
            <Button
              color="gem-outline"
              iconRight={<FontAwesomeIcon icon={faExternalLinkAlt} />}
              size="small"
              value="Configure API Key"
            />
          </OutboundLink>
          {completeButton}
        </>
      );
    case NotificationType.InterviewerDeclined:
      return (
        <>
          <Link to={correctPath(`/app/candidates/${props.metadata.application_id}/schedules/${props.metadata.schedule_id}`)}>
            <Button
              color="gem-outline"
              size="small"
              value="Edit Schedule"
            />
          </Link>
          {completeButton}
        </>
      );
    case NotificationType.JobNewlyImported:
      return (
        <>
          <Link to={correctPath(`/app/jobs/${props.metadata.job_id}`)}>
            <Button
              color="gem-outline"
              size="small"
              value="Configure Job"
            />
          </Link>
          {completeButton}
        </>
      );
    case NotificationType.LeverAPIKeyError:
      return (
        <>
          <Link to={correctPath('/app/integrations')}>
            <Button
              color="gem-outline"
              size="small"
              value="Edit API Key"
            />
          </Link>
          {completeButton}
        </>
      );
    case NotificationType.LeverPermissionsError:
      return (
        <>
          <OutboundLink
            externalLinkIcon={false}
            href={`${Config.LEVER_HOST}/settings/integrations?tab=api`}
            label="Lever Permission Error Task"
          >
            <Button
              color="gem-outline"
              iconRight={<FontAwesomeIcon icon={faExternalLinkAlt} />}
              size="small"
              value="Configure API Key"
            />
          </OutboundLink>
          {completeButton}
        </>
      );
    case NotificationType.Microsoft365AuthError:
      return (
        <>
          <OutboundLink
            externalLinkIcon={false}
            href="https://support.gem.com/hc/en-us/articles/23488253797655-What-is-admin-consent"
            label="Microsft 365 Auth Error Task"
          >
            <Button
              color="gem-outline"
              iconRight={<FontAwesomeIcon icon={faExternalLinkAlt} />}
              size="small"
              value="Read about Admin Consent"
            />
          </OutboundLink>
          {completeButton}
        </>
      );
    case NotificationType.ReadyToRequestAvailability:
      return (
        <>
          <Link to={correctPath(`/app/candidates/${props.metadata.application_id}/request-availability`)}>
            <Button
              color="orange"
              size="small"
              value="Request Availability"
            />
          </Link>
          {completeButton}
        </>
      );
    case NotificationType.ReadyToSchedule:
      return (
        <>
          <Link to={correctPath(`/app/candidates/${props.metadata.application_id}/${props.metadata.default_workflow === 'self_schedule' ? 'self-' : ''}schedule`)}>
            <Button
              color="orange"
              size="small"
              value={`${props.metadata.default_workflow === 'self_schedule' ? 'Self-' : ''}Schedule`}
            />
          </Link>
          {completeButton}
        </>
      );
    case NotificationType.RoomDeclined:
      return (
        <>
          <Link to={correctPath(`/app/candidates/${props.metadata.application_id}/schedules/${props.metadata.schedule_id}`)}>
            <Button
              color="gem-outline"
              size="small"
              value="Edit Schedule"
            />
          </Link>
          {completeButton}
        </>
      );
    case NotificationType.ScheduleCancelled:
      return (
        <>
          <Link to={correctPath(`/app/candidates/${props.metadata.application_id}/schedule`)}>
            <Button
              color="orange"
              size="small"
              value="Re-schedule"
            />
          </Link>
          {completeButton}
        </>
      );
    case NotificationType.SlackTokenError:
      return (
        <>
          <Link to={correctPath('/app/integrations')}>
            <Button
              color="gem-outline"
              size="small"
              value="Re-connect Zoom"
            />
          </Link>
          {completeButton}
        </>
      );
    case NotificationType.StageInterviewNewlyImported:
      return (
        <>
          <Link to={correctPath(`/app/jobs/${props.metadata.job_id}/stages/${props.metadata.stage_id}/interviews`)}>
            <Button
              color="gem-outline"
              size="small"
              value="Configure Interview"
            />
          </Link>
          {completeButton}
        </>
      );
    case NotificationType.StageNewlyImported:
      return (
        <>
          <Link to={correctPath(`/app/jobs/${props.metadata.job_id}/stages/${props.metadata.stage_id}`)}>
            <Button
              color="gem-outline"
              size="small"
              value="Configure Stage"
            />
          </Link>
          {completeButton}
        </>
      );
    case NotificationType.ZoomTokenError:
      return (
        <>
          <Link to={correctPath('/app/integrations')}>
            <Button
              color="gem-outline"
              size="small"
              value="Re-connect Zoom"
            />
          </Link>
          {completeButton}
        </>
      );
  }
};

export default TaskActionButtons;
