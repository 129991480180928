import { capitalize, uniqueId } from 'lodash';
import { useMemo } from 'react';

import Tooltip from 'components/library/utils/Tooltip';
import { StyledImage } from './styles';
import { useSession } from 'hooks/use-session';

import type { ATS } from 'types';

const atsLogoUrls: Record<`${ATS}`, string> = {
  gem: '/scheduling/src/assets/images/gem-logo-transparent-bg.svg',
  greenhouse: '/scheduling/src/assets/images/greenhouse-logo-transparent-bg.svg',
  lever: '/scheduling/src/assets/images/lever-logo-transparent-bg.svg',
};

const ATSScorecardIcon = () => {
  const { account } = useSession();
  const ats = account!.ats_type!;

  const id = useMemo(() => uniqueId('scorecard-icon-'), []);
  return (
    <>
      <StyledImage
        alt={capitalize(account!.ats_type)}
        data-for={id}
        data-tip
        src={atsLogoUrls[ats]}
      />
      <Tooltip
        id={id}
        position="top"
        value={`Has ${capitalize(ats)} scorecard`}
      />
    </>
  );
};

export default ATSScorecardIcon;
