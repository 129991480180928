import { sortBy } from 'lodash';

import type { Application } from '../../../../../../types';
import type { RoomMap } from '../../../../../../hooks/queries/rooms';
import type { ScheduleWithType } from './types';
import type { TokensPayload } from '../../../../../../hooks/queries/tokens';
import type { UserMap } from '../../../../../../hooks/queries/users';

export const constructScheduleTokens = (application: Application, resource: ScheduleWithType, rooms: RoomMap, users: UserMap): TokensPayload['schedule'] => {
  return {
    application: {
      ats_id: application.ats_id,
      candidate: {
        ats_id: application.candidate.ats_id,
        email: application.candidate.email,
        name: application.candidate.name,
        pronouns: application.candidate.pronouns || undefined,
        pronunciation_url: application.candidate.pronunciation_url || undefined,
        resume_url: application.candidate.resume_url || undefined,
        phone_number: application.candidate.phone_number,
        linkedin_url: application.candidate.linkedin_url,
        website: application.candidate.website,
      },
      coordinator: application.coordinator_id && users[application.coordinator_id] ? {
        email: users[application.coordinator_id].email,
        name: users[application.coordinator_id].name,
        phone_number: users[application.coordinator_id].phone_number,
      } : undefined,
      hiring_manager: application.hiring_manager_id && users[application.hiring_manager_id] ? {
        email: users[application.hiring_manager_id].email,
        name: users[application.hiring_manager_id].name,
        phone_number: users[application.hiring_manager_id].phone_number,
      } : undefined,
      recruiter: application.recruiter_id && users[application.recruiter_id] ? {
        email: users[application.recruiter_id].email,
        name: users[application.recruiter_id].name,
        phone_number: users[application.recruiter_id].phone_number,
      } : undefined,
      sourcer: application.sourcer_id && users[application.sourcer_id] ? {
        email: users[application.sourcer_id].email,
        name: users[application.sourcer_id].name,
        phone_number: users[application.sourcer_id].phone_number,
      } : undefined,
      current_stage: {
        job: {
          name: resource.stage.job.name,
          post_name: resource.stage.job.post_name,
        },
      },
      office: resource.type === 'schedule' && application.office ? {
        name: application.office.name,
      } : undefined,
    },
    stage: {
      name: resource.stage.name,
      stage_interviews: resource.stage.stage_interviews?.filter((stageInterview) => !stageInterview.inline && !stageInterview.deleted).map((stageInterview) => ({
        name: stageInterview.name,
        position: stageInterview.position,
        interview_template: stageInterview.interview_template ? {
          duration_minutes: stageInterview.interview_template.duration_minutes,
          candidate_facing_name: stageInterview.interview_template.candidate_facing_name,
          candidate_facing_details: stageInterview.interview_template.candidate_facing_details,
        } : undefined,
      })),
    },
    timezone: resource.type === 'schedule' ? resource.timezone : resource.schedule_template.business_hours?.[0]?.timezone || undefined,
    candidate_timezone: resource.type === 'schedule' ? resource.candidate_timezone : undefined,
    candidate_event_location: resource.type === 'self_scheduling_link' && resource.schedule_template.video_conferencing_enabled ? undefined : resource.schedule_template.candidate_calendar_event_template?.location,
    interviews: resource.type === 'schedule' ? sortBy(resource.interviews, ['start_time']).map((interview) => ({
      start_time: interview.start_time,
      feedback_form_id: interview.feedback_form_id,
      video_conferencing_passcode: interview.video_conferencing_passcode,
      interview_template: {
        duration_minutes: interview.interview_template.duration_minutes,
        live_coding_enabled: interview.interview_template.live_coding_enabled,
        candidate_facing_name: interview.interview_template.candidate_facing_name,
        candidate_facing_details: interview.interview_template.candidate_facing_details,
      },
      room: interview.room_id && rooms[interview.room_id] ? {
        name: rooms[interview.room_id].name,
      } : undefined,
      stage_interview: {
        name: interview.stage_interview.name,
        ats_id: interview.stage_interview.ats_id,
        greenhouse_interview_kit_id: interview.stage_interview.greenhouse_interview_kit_id,
      },
      interviewers: (interview.interviewers || []).map((interviewer) => ({
        user: {
          id: interviewer.user_id,
          email: users[interviewer.user_id]?.email,
          name: users[interviewer.user_id]?.name,
          linkedin_url: users[interviewer.user_id]?.linkedin_url,
          phone_number: users[interviewer.user_id]?.phone_number,
          title: users[interviewer.user_id]?.title,
        },
        interviewer_template: {
          optional: interviewer.interviewer_template.optional,
          interviewer_filters: (interviewer.interviewer_template.interviewer_filters || []).map((filter) => ({
            interviewer_filter_expressions: (filter.interviewer_filter_expressions || []).map((exp) => ({
              negated: exp.negated,
              filterable_id: exp.filterable_id,
              filterable_type: exp.filterable_type,
            })),
          })),
        },
      })),
    })) : undefined,
    schedule_template: {
      candidate_event_location: resource.schedule_template.candidate_calendar_event_template?.location,
      video_conferencing_enabled: resource.schedule_template.video_conferencing_enabled,
      onsite: resource.schedule_template.onsite,
    },
  };
};
