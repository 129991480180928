import { find } from 'lodash';
import { useEffect, useMemo } from 'react';

import SelectInput from '../SelectInput';
import Tag from '../../data-display/Tag';
import TextInput from '../TextInput';
import { HiringTeamRoleWithScheduler } from '../../../../types';
import { generateOptions } from './helpers';
import { useSession } from '../../../../hooks/use-session';

import type { ChangeEvent } from 'react';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../SelectInput/types';

const options = generateOptions('Default (whoever sends the email in InterviewPlanner)');

interface Props {
  email?: string;
  isDisabled?: boolean;
  name?: string;
  setEmail: (email: string) => void;
  setName: (name: string) => void;
}

const SenderInput = ({ email, isDisabled, name, setEmail, setName }: Props) => {
  const { account } = useSession();

  const hiringTeamEmail = useMemo(() => find(options, ['value', email]), [email]);
  const isEmailAndNameMatching = hiringTeamEmail && email === name;

  const handleEmailChange = (option: OnChangeValue<Option<string>, false>) => {
    const wereOldEmailAndNameMatching = isEmailAndNameMatching;
    const newEmail = option ? option.value : HiringTeamRoleWithScheduler.Scheduler;
    setEmail(newEmail);

    const isNewHiringTeamEmail = find(options, ['value', newEmail]);
    if (wereOldEmailAndNameMatching || !name) {
      setName(isNewHiringTeamEmail ? newEmail : '');
    }
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  useEffect(() => {
    if (!name && hiringTeamEmail) {
      setName(email || '');
    }
  }, [name, hiringTeamEmail, email]);

  return (
    <div className="email-template-sender-input">
      <SelectInput
        formatCreateLabel={(value) => `Use email address "${value}"`}
        formatOptionLabel={(option) => (
          find(options, ['value', option.value]) && option.value !== HiringTeamRoleWithScheduler.Scheduler ?
            <Tag type="hiring_team" value={option.value} /> :
            option.label
        )}
        helperText="You can type in a specific email address or select a member of the hiring team."
        isClearable
        isCreatable
        isDisabled={isDisabled}
        isRequired
        label="From Email"
        noOptionsMessage={() => null}
        onChange={handleEmailChange}
        options={options}
        placeholder="Select a role or enter an email address"
        value={(
          email ?
            (hiringTeamEmail || { value: email, label: email }) :
            undefined
        )}
      />
      <TextInput
        className="sender-name-input"
        helperText={hiringTeamEmail && isEmailAndNameMatching ? `You can optionally display the sender\'s name as something else in the candidate\'s inbox, e.g. ${account?.name || 'InterviewPlanner'} Recruiting.` : 'The name that will be displayed in the candidate\'s inbox.'}
        isDisabled={isDisabled}
        isRequired={!hiringTeamEmail}
        label={'From Name'}
        onChange={handleNameChange}
        placeholder={(
          isEmailAndNameMatching ?
            `Use ${(!name || name === HiringTeamRoleWithScheduler.Scheduler) ? 'sender' : name.replace(/_/g, ' ')}'s name` :
            undefined
        )}
        value={isEmailAndNameMatching ? '' : name}
      />
    </div>
  );
};

export default SenderInput;
