import Moment from 'moment';
import { Breadcrumb } from 'react-breadcrumbs';
import { Redirect, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { isEmpty, orderBy } from 'lodash';
import { useLayoutEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import CandidateHiringMeeting from './CandidateHiringMeeting';
import { formatMoment, TimeFormat } from 'libraries/time';
import ListItem from 'components/library/data-display/ListItem';
import LoadingSpinner from 'components/library/utils/LoadingSpinner';
import SelectInput from 'components/library/inputs/SelectInput';
import {
  StyledContainer,
  StyledMenu,
  StyledNavLink,
  StyledStageName,
  StyledMeetingTime,
  StyledMenuLabel, StyledMenuLabelContent, StyledMenuLabelStatusIndicator,
} from './styles';
import { useApplication } from 'hooks/queries/applications';

import type { ApplicationHiringMeeting } from 'types';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from './types';
import StatusIndicator from '../../../../library/utils/StatusIndicator';
import { statusIndicatorValues } from '../CandidateSchedules/types';
import { correctPath } from 'libraries/gem';

const CandidateHiringMeetings = () => {
  const history = useHistory();
  const location = useLocation();

  const { id } = useParams<{ id: string }>();

  const { data: application } = useApplication(id);

  const hiringMeetings = useMemo<ApplicationHiringMeeting[]>(() => {
    return orderBy(application?.all_hiring_meetings || [], 'created_at');
  }, [application?.all_hiring_meetings]);

  const hiringMeetingOptions = useMemo(() => {
    return hiringMeetings.map((hiringMeeting) => ({
      label: `${hiringMeeting.stage.name} (${formatMoment(Moment(hiringMeeting.start_time), TimeFormat.LongMonthDayYear)})`,
      value: hiringMeeting.id,
      hiringMeeting,
    }));
  }, [hiringMeetings]);

  const [selectedHiringMeetingId, setSelectedHiringMeetingId] = useState('');

  const isSmallScreen = useMediaQuery({ query: '(max-width: 1050px)' });

  // Get selected hiring meeting from url
  useLayoutEffect(() => {
    const splitUrl = location.pathname.split('/hiring-meetings/');
    if (splitUrl.length > 1) {
      const hiringMeetingId = splitUrl[1].split('/')[0];
      if (!selectedHiringMeetingId || hiringMeetingId !== selectedHiringMeetingId) {
        setSelectedHiringMeetingId(hiringMeetingId);
      }
    }
  }, [location.pathname]);

  const handleHiringMeetingChange = (option: OnChangeValue<Option, false>) => {
    // This SelectInput can't be cleared, so option should always be defined.
    setSelectedHiringMeetingId(option?.value || '');
    history.push(correctPath(`/app/candidates/${application!.id}/hiring-meetings/${option?.value}`));
  };

  if (!application) {
    return <LoadingSpinner />;
  }

  return (
    <Breadcrumb
      data={{
        title: 'Hiring Meetings',
        pathname: correctPath(`/app/candidates/${id}/schedules`),
      }}
    >
      <StyledContainer>
        {isSmallScreen ? (
          <SelectInput
            formatOptionLabel={(option, { context }) => (
              context === 'menu' ? (
                <ListItem
                  label={option.hiringMeeting.stage.name}
                  leftIcon={(
                    <StatusIndicator
                      color={statusIndicatorValues[option.hiringMeeting.status].color}
                      tooltipId={`hiring-meeting-${option.hiringMeeting.id}-status-indicator`}
                      tooltipText={statusIndicatorValues[option.hiringMeeting.status].label}
                    />
                  )}
                  secondaryText={formatMoment(Moment(option.hiringMeeting.start_time), TimeFormat.LongMonthDayYear)}
                />
              ) : (
                <span>
                  <StatusIndicator
                    color={statusIndicatorValues[option.hiringMeeting.status].color}
                    tooltipId={`hiring-meeting-${option.hiringMeeting.id}-status-indicator`}
                    tooltipText={statusIndicatorValues[option.hiringMeeting.status].label}
                  />
                  {option.label}
                </span>
              )
            )}
            label="Hiring Meeting"
            onChange={handleHiringMeetingChange}
            options={hiringMeetingOptions}
          />
        ) : (
          <StyledMenu>
            {hiringMeetings.map((hiringMeeting) => (
              <StyledNavLink
                key={hiringMeeting.id}
                to={correctPath(`/app/candidates/${application.id}/hiring-meetings/${hiringMeeting.id}`)}
              >
                <StyledMenuLabel>
                  <StyledMenuLabelStatusIndicator
                    color={statusIndicatorValues[hiringMeeting.status].color}
                    tooltipId={`hiring-meeting-${hiringMeeting.id}-status-indicator`}
                    tooltipText={statusIndicatorValues[hiringMeeting.status].label}
                  />
                  <StyledMenuLabelContent>
                    <StyledStageName>
                      {hiringMeeting.stage.name}
                    </StyledStageName>
                    <StyledMeetingTime>
                      {formatMoment(Moment(hiringMeeting.start_time), TimeFormat.LongMonthDayYear)}
                    </StyledMeetingTime>
                  </StyledMenuLabelContent>
                </StyledMenuLabel>
              </StyledNavLink>
            ))}
          </StyledMenu>
        )}
        {!isEmpty(hiringMeetings) && (
          <Switch>
            <Redirect
              exact
              from={correctPath('/app/candidates/:id/hiring-meetings')}
              to={correctPath(`/app/candidates/${id}/hiring-meetings/${hiringMeetings[hiringMeetings.length - 1].id}`)}
            />
            <Route component={CandidateHiringMeeting} path={correctPath('/app/candidates/:id/hiring-meetings/:hiringMeetingId')} />
          </Switch>
        )}
      </StyledContainer>
    </Breadcrumb>
  );
};

export default CandidateHiringMeetings;
