import { Competitor } from 'types';
import FeatureComparisonTable from './FeatureComparisonTable';
import Section from 'components/library/layout/Section';

const Prelude = () => {
  return (
    <div>
      <Section title="Overview">
        <p>
          Prelude, originally called Interview Schedule, came on the scene shortly after GoodTime. They are now part of Calendly. Prelude&apos;s scheduling workflows and setup process have some limitations, but they offer a unique, highly personalized, customizable candidate experience through their Candidate Journey pages.
        </p>
        <FeatureComparisonTable competitors={[Competitor.Prelude]} />
      </Section>
      <Section title="Candidate experience">
        <p>
          InterviewPlanner and Prelude both allow you to send emails that have a personal touch to them, like they were sent directly from the scheduler&apos;s inbox rather than from an automated tool.
        </p>
        <p>
          InterviewPlanner and Prelude both allow you to customize candidate-facing pages, like availability requests and self-scheduling links, with your company&apos;s logo and brand color.</p>
        <p>
          InterviewPlanner&apos;s availability request workflow allows the candidate to submit their pronouns and name pronunciation, if you enable these features. Prelude does not let you collect this information from the candidate. InterviewPlanner&apos;s availability request workflow lets the candidate drag to select time windows on a calendar-like interface, while Prelude&apos;s has the candidate manually enter in each date and time slot.
        </p>
        <p>
          Prelude has Candidate Journeys, which are company-branded dynamic websites personalized for each candidate that contain information about the interview process, details about interviews, details about interviewers, and more.
        </p>
      </Section>
      <Section title="Interviewer experience">
        <p>
          InterviewPlanner and Prelude both take into account interviewers&apos; preferences such as interview limits, interviewer working hours, meeting buffers, keywords for events that can be booked over. Only InterviewPlanner automatically tries to balance interview load when selecting from a pool of interviewers, even if none of them are at their limit. We believe Prelude&apos;s algorithm only looks at whether interviewers have reached their capacity.
        </p>
        <p>
          InterviewPlanner and Prelude both send calendar invites to interviewers when they are scheduled for interviews. Only InterviewPlanner includes the candidate&apos;s pronouns and name pronunciation (if the candidate submitted those) on interviewer invites. Prelude gives you more customization over the interviewer calendar event description.
        </p>
        <p>
          InterviewPlanner lets you place schedules on hold internally to block off time on busy interviewers&apos; calendars. We believe that Prelude lets you do this as well, as we have heard anecdotally from a customer who switched over from Prelude, but we were unable to find further details in their help center.
        </p>
        <p>
          Both platforms have a Slack integration to create private hiring channels for candidates and post messages in the channel, but they work differently. InterviewPlanner creates the Slack channel automatically (if you select the option to do so) at the end of the scheduling workflow, automatically posts the schedule when it is created or updated, and keeps the channel members up to date. Prelude provides a way within their platform to manually create a Slack channel for a candidate and post messages to it.
        </p>
      </Section>
      <Section title="Scheduling">
        <p>
          InterviewPlanner and Prelude both automatically generate schedule options that consider interviewer and candidate availability, interviewer preferences, and more. You can fully customize every aspect of the schedule in InterviewPlanner, but not in Prelude. You can re-order interviews, swap interviewers, add video conferencing, etc. from within the scheduling workflow. Only in InterviewPlanner, you can also view interviewers&apos; calendars in a side-by-side view as you edit your schedule.
        </p>
        <p>
          Both platforms let you configure default settings for interview loops, including interview durations, interviewers, breaks, and position constraints. Only InterviewPlanner lets you create and name custom interviews that aren&apos;t attached to interviews in your ATS.
        </p>
        <p>
          Rescheduling or updating interviews is easy in both platforms. Only InterviewPlanner automatically updates the agendas and other modified details in interviewer and candidate calendar events.
        </p>
        <p>
          InterviewPlanner and Prelude both have a Chrome extension that adds a button to the candidate profile in the ATS. InterviewPlanner&apos;s Chrome extension adds a button to open the candidate in InterviewPlanner. Prelude&apos;s Chrome extension adds buttons to schedule the candidate and to send them an availability or self-scheduling request, and they open up the workflow in a sidebar on the same page. Some users, especially recruiters who work mostly out of the ATS but need to schedule occasionally, may like the user experience of scheduling with Prelude without leaving the current tab.
        </p>
        <p>
          InterviewPlanner and Prelude both allow you to request availability from candidates via a link where they can submit times. Only InterviewPlanner allows you to automatically send a follow-up email to a candidate if they do not respond to the availability request.
        </p>
        <p>
          InterviewPlanner and Prelude both allow you to send candidates self-scheduling links for single interviews. Only InterviewPlanner can automatically send a follow-up email to a candidate if they do not respond to the self-scheduling request.
        </p>
        <p>
          Only Prelude has functionality for scheduling group interviews, where multiple candidates are interviewed together.
        </p>
      </Section>
      <Section title="Interviewer skills and training">
        <p>
          InterviewPlanner and Prelude both let you tag interviewers with skills and attributes. Both platforms let you use and combine these tags to construct interviewer pools.
        </p>
        <p>
          InterviewPlanner and Prelude both let you define interviewer training programs, including the phases and their interview requirements. Both platforms let you attach a trainee to an interview such that a trainee is automatically selected whenever scheduling that interview.
        </p>
        <p>
          InterviewPlanner always automatically moves trainees through the program. Prelude requires the scheduler to approve moving a trainee forward to the next phase. Both InterviewPlanner and Prelude allow you to make manual changes to an interviewer&apos;s training progress, but only InterviewPlanner shows their completed interview history.
        </p>
      </Section>
      <Section title="In our opinion">
        <p>
          Prelude shines at candidate experience and their Chrome extension makes it easy to take action on candidates without ever leaving your ATS. They also support scheduling group interviews, which may be important for your team. However, we feel that InterviewPlanner is a more intuitive, coordinator-friendly platform than Prelude for scheduling interviews, handling reschedules smoothly, placing holds on busy interviewers&apos; calendars, and fine-tuning options with all relevant information in clear sight. Although Journeys are a step above what other scheduling platforms offer for candidate experience, we feel that products outside of the scheduling platform space that are fully dedicated to creating a landing page for candidates (such as <a href="https://guide.co">Guide</a>) may be better suited for this purpose.
        </p>
      </Section>
    </div>
  );
};

export default Prelude;
