import type { EditableZoomHostFilter, InterviewTemplate, RoomFilterExpressionFilterableType } from 'types';

export enum UpdateAction {
  CreateInterviewTemplate,
  UpdateStageInterview,
  SwapInterviewTemplateOnStageInterview,
  UpdateInterviewTemplate,
  UpdateInterviewTemplateNotAllowed, // is handled by creating an interview template
}

export interface InterviewUpdateAction {
  action: UpdateAction;
  // parentTemplate is present if the suggested action is UpdateInterviewTemplate or SwapInterviewTemplateOnStageInterview
  parentTemplate?: InterviewTemplate;
  // newTemplateName is present if the suggested action is to create a new template
  newTemplateName?: string;
}

export interface StageResourceUpdate {
  id?: string;
  name?: string;
  type: 'schedule_template' | 'stage_interview';
  updates: {
    // stage_interview
    feedback_form_id?: string;
    interview_template?: Partial<InterviewTemplate>;
    // schedule_template
    room_filters?: {
      room_filter_expressions: {
        negated: boolean;
        filterable_id: string;
        filterable_type: `${RoomFilterExpressionFilterableType}`;
      }[];
    }[];
    video_conferencing_enabled?: boolean;
    zoom_host_filters?: EditableZoomHostFilter[] | null;
  };
}
