import { faExpandAlt, faWindowRestore } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { find, flatten, merge, orderBy, uniq, uniqueId } from 'lodash';
import Moment from 'moment-timezone';
import { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';

import Button from 'components/library/inputs/Button';
import Tooltip from 'components/library/utils/Tooltip';
import { isConflict } from 'libraries/schedule';
import { resolveUsersParams } from 'hooks/queries/users';
import { useEvents } from 'hooks/use-events';
import { useSession } from 'hooks/use-session';
import { ATS } from 'types';
import CalendarCompareModal from '../CalendarCompareModal';
import CalendarScheduleAddInterviewModal from './CalendarScheduleAddInterviewModal';
import CalendarScheduleCalendar from './CalendarScheduleCalendar';
import CalendarScheduleUpdateInterviewModal from './CalendarScheduleUpdateInterviewModal';

import type { SlotInfo, stringOrDate } from '../../../../libraries/react-big-calendar';
import type { RecursivePartial } from 'types/helpers';
import type { AvailabilityTimeSlot, Event, Interview, InterviewInitialValues, PotentialRoom, PotentialUser, PotentialZoomHost, Schedule } from './types';

// The minimum amount of buffer (in minutes) that must be at the start and end
// of the calendar. If there is less than this amount, then the window will be
// expanded by an hour.
const MIN_MAX_BUFFER_MINUTES = 30;

interface Props {
  allowAddAndRemoveInterviewers?: boolean;
  allowCompareCalendars?: boolean;
  allowSelectOptionsOutsidePool?: boolean;
  allowUpdateInterview?: boolean;
  allowUpdateScorecards?: boolean;
  availabilityTimeSlots?: AvailabilityTimeSlot[];
  analyticsLabel: string;
  compareCalendarsDefaultSelectedUserIds?: () => string[];
  headerTitle?: string;
  id?: string;
  isDraggable?: boolean;
  schedule: Schedule;
  setSchedule: (newSchedule: Schedule) => void;
  showEventDetailsPopover?: boolean;
  showZoomHost?: boolean;
}

const CalendarSchedule = ({
  allowAddAndRemoveInterviewers = false,
  allowCompareCalendars = false,
  allowSelectOptionsOutsidePool = false,
  allowUpdateInterview = false,
  allowUpdateScorecards = false,
  analyticsLabel,
  availabilityTimeSlots = [],
  compareCalendarsDefaultSelectedUserIds,
  headerTitle,
  id,
  isDraggable = false,
  schedule,
  setSchedule,
  showEventDetailsPopover = false,
  showZoomHost = false,
}: Props) => {
  const queryClient = useQueryClient();

  const { account } = useSession();

  const { fetchEvents } = useEvents();

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleToggleModal = () => {
    if (!isModalOpen) {
      analytics.track('Compare Calendar Modal Opened', { label: analyticsLabel });
    } else {
      analytics.track('Compare Calendar Modal Closed', { label: analyticsLabel });
    }
    setIsModalOpen((prevState) => !prevState);
  };

  const [newInterviewInitialValues, setNewInterviewInitialValues] = useState<InterviewInitialValues | null>(null);
  const handleToggleNewInterviewModal = (initialValues?: InterviewInitialValues) => {
    if (initialValues) {
      setNewInterviewInitialValues(initialValues);
    } else {
      setNewInterviewInitialValues(null);
    }
  };
  const isNewInterviewModalOpen = Boolean(newInterviewInitialValues);

  const [interviewIndexBeingEdited, setInterviewIndexBeingEdited] = useState<number | null>(null);

  const [resolvedUserIds, setResolvedUserIds] = useState<string[] | null>(null);

  id = useMemo(() => id || uniqueId('calendar-schedule-'), [id]);

  const isEditable = Boolean(setSchedule);
  // We currently only support the update interview modal for updating feedback
  // forms or live coding links.
  const isInterviewEditable = allowUpdateInterview && (
    account?.ats_type === ATS.Lever ||
    Boolean(account?.live_coding_type) ||
    allowUpdateScorecards
  );

  const dateString = useMemo(() => Moment.tz(schedule.interviews[0].start_time, schedule.timezone).format('YYYY-MM-DD'), [schedule.interviews[0].start_time, schedule.timezone]);

  useEffect(() => {
    if (isEditable && !resolvedUserIds) {
      const resolvePayloads = flatten(schedule.interviews.map(({ interviewers }) => (interviewers || []).map(({ interviewer_template }) => {
        return {
          filters: interviewer_template.interviewer_filters,
          includePastInterviewers: interviewer_template.include_past_interviewers,
        };
      }))).filter((payload) => Boolean(payload.filters));

      (async () => {
        const pools = await Promise.all(resolvePayloads.map(({ filters, includePastInterviewers }) => queryClient.fetchQuery(resolveUsersParams({
          applicationId: schedule.application_id,
          scheduleId: schedule.id,
          includePastInterviewers,
          interviewerFilters: filters,
        }))));

        setResolvedUserIds(uniq([
          ...flatten(pools.map((pool) => pool.map(({ id }) => id))),
        ]));
      })();
    }
  }, [isEditable, resolvedUserIds, schedule]);

  useEffect(() => {
    if (isEditable && resolvedUserIds) {
      const date = Moment(schedule.interviews[0].start_time).tz(schedule.timezone).format('YYYY-MM-DD');
      const userIds = uniq([
        ...resolvedUserIds,
        ...flatten(schedule.interviews.map(({ interviewers }) => (interviewers || []).map(({ selected_user }) => selected_user.user_id))).filter((id): id is string => Boolean(id)),
      ]);
      const roomIds = schedule.selected_room?.room_id ? [schedule.selected_room.room_id] : undefined;
      const zoomHostIds = schedule.selected_zoom_host?.id ? [schedule.selected_zoom_host.id] : undefined;

      fetchEvents({
        date,
        timezone: schedule.timezone,
        user_ids: userIds,
        room_ids: roomIds,
        zoom_host_ids: zoomHostIds,
      });
    }
  }, [isEditable, resolvedUserIds, dateString]);

  const potentialZoomHosts = useMemo<PotentialZoomHost[]>(() => (schedule.potential_zoom_hosts || []).map((host) => ({
    id: host.id,
    type: host.type,
  })), [id, schedule.potential_zoom_hosts]);

  const handleDateChange = (date: Date) => {
    setSchedule({
      ...schedule,
      interviews: schedule.interviews.map((interview) => ({
        ...interview,
        start_time: Moment.tz(interview.start_time, schedule.timezone).year(date.getFullYear()).month(date.getMonth()).date(date.getDate()).format(),
      })),
    });
  };

  const handleRoomChange = (selectedRoom: PotentialRoom) => {
    setSchedule({
      ...schedule,
      selected_room: selectedRoom,
    });
  };

  const handleZoomHostChange = (selectedZoomHost: PotentialZoomHost) => {
    setSchedule({
      ...schedule,
      selected_zoom_host: selectedZoomHost.id ? { id: selectedZoomHost.id, type: selectedZoomHost.type } : { id: null, type: null },
    });
  };

  const handleInterviewerChange = (interviewIndex: number, interviewerIndex: number, selectedUser: PotentialUser) => {

    setSchedule({
      ...schedule,
      interviews: schedule.interviews.map((interview, i) => (
        i === interviewIndex ?
          {
            ...interview,
            interviewers: interview.interviewers.map((interviewer, j) => (
              j === interviewerIndex ?
                {
                  ...interviewer,
                  selected_user: selectedUser,
                } :
                interviewer
            )),
          } :
          interview
      )),
    });
  };

  const handleInterviewerOptionalityChange = (interviewIndex: number, interviewerIndex: number, optional: boolean) => {
    setSchedule({
      ...schedule,
      interviews: schedule.interviews.map((interview, i) => (
        i === interviewIndex ?
          {
            ...interview,
            interviewers: interview.interviewers.map((interviewer, j) => (
              j === interviewerIndex ?
                {
                  ...interviewer,
                  interviewer_template: {
                    ...interviewer.interviewer_template,
                    optional,
                  },
                } :
                interviewer
            )),
          } :
          interview
      )),
    });
  };

  const handleInterviewerTrainingProgramChange = (interviewIndex: number, interviewerIndex: number, trainingProgramEligibility: string | null) => {
    setSchedule({
      ...schedule,
      interviews: schedule.interviews.map((interview, i) => (
        i === interviewIndex ?
          {
            ...interview,
            interviewers: interview.interviewers.map((interviewer, j) => (
              j === interviewerIndex ?
                {
                  ...interviewer,
                  interviewer_template: {
                    ...interviewer.interviewer_template,
                    interviewer_filters: trainingProgramEligibility ? [{
                      interviewer_filter_expressions: [{
                        filterable_type: 'training',
                        filterable_id: trainingProgramEligibility,
                        negated: false,
                      }],
                    }] : [],
                  },
                } :
                interviewer
            )),
          } :
          interview
      )),
    });
  };

  const handleAddInterviewer = (interviewIndex: number, selectedUser: PotentialUser, optional: boolean, trainingProgramEligibility: string | null, potentialUsers: PotentialUser[]) => {
    let interviewerIndexToSwap: number | null = null;

    const newSchedule: Schedule = {
      ...schedule,
      interviews: schedule.interviews.map((interview, i) => {
        if (interviewerIndexToSwap !== null || i !== interviewIndex) {
          return interview;
        }

        // If they are trying to add an interviewer to an event that has an
        // empty interviewer slot, we should fill that slot first.
        const blankInterviewerIndex = (interview.interviewers || []).findIndex(({ selected_user }) => !selected_user.user_id);
        if (blankInterviewerIndex !== -1) {
          interviewerIndexToSwap = blankInterviewerIndex;
        }

        return {
          ...interview,
          interviewers: [
            ...interview.interviewers,
            {
              interviewer_template: {
                optional,
                include_past_interviewers: true,
                interviewer_filters: trainingProgramEligibility ? [{
                  interviewer_filter_expressions: [{
                    filterable_type: 'training',
                    filterable_id: trainingProgramEligibility,
                    negated: false,
                  }],
                }] : [],
              },
              selected_user: selectedUser,
              potential_users: potentialUsers,
            },
          ],
        };
      }),
    };

    if (interviewerIndexToSwap !== null) {
      handleInterviewerChange(interviewIndex, interviewerIndexToSwap, selectedUser);
      return;
    }

    setSchedule(newSchedule);
  };

  const handleRemoveInterviewer = (interviewIndex: number, interviewerIndex: number) => {
    setSchedule({
      ...schedule,
      interviews: schedule.interviews.map((interview, i) => (
        i === interviewIndex ?
          {
            ...interview,
            interviewers: interview.interviewers.filter((_, j) => j !== interviewerIndex),
          } :
          interview
      )),
    });
  };

  const handleRemoveInterview = (interviewIndex: number) => {
    setSchedule({
      ...schedule,
      interviews: schedule.interviews.filter((interview, i) => i !== interviewIndex),
    });
  };

  const handleAddInterview = (interview: Interview) => {
    setSchedule({
      ...schedule,
      interviews: orderBy([
        ...schedule.interviews,
        {
          ...interview,
          id: uniqueId('new-interview-'),
        },
      ], ({ start_time }) => Moment.utc(start_time).format()),
    });
  };

  const handleUpdateInterview = (interviewIndex: number, updatedInterview: RecursivePartial<Interview>) => {
    setSchedule({
      ...schedule,
      interviews: schedule.interviews.map((interview, i) => (
        i === interviewIndex ?
          merge(interview, updatedInterview) :
          interview
      )),
    });
  };

  const handleEventChange = ({ event, start, end }: { event: Event; start: stringOrDate; end: stringOrDate }) => {
    start = Moment.utc(start).toDate();
    end = Moment.utc(end).toDate();

    if (start.getTime() === end.getTime()) {
      // Since we don't support "instant" events where the start and end are
      // exactly the same, we push the end time to be 5 minutes later, so that
      // the minimum duration of events is 5 minutes.
      end = Moment.utc(end).add(5, 'minutes').toDate();
    }

    const newEvents = events.map((oldEvent) => (
      oldEvent.interviewId === event.interviewId ? {
        ...oldEvent,
        start,
        end,
      } : oldEvent
    ));

    const interviews = orderBy(newEvents.map((e) => {
      const matchingInterview = find(schedule.interviews, ['id', e.interviewId])!;

      return {
        ...matchingInterview,
        start_time: Moment.utc(e.start).format(),
        interview_template: {
          ...matchingInterview.interview_template,
          duration_minutes: Moment.duration(Moment.utc(e.end).diff(Moment.utc(e.start))).asMinutes(),
        },
      };
    }), ({ start_time }) => Moment.utc(start_time).format());

    setSchedule({
      ...schedule,
      interviews,
    });
  };

  const handleSelectCalendarTime = ({ action, start, end }: SlotInfo) => {
    analytics.track('Calendar Time Selected', { action, duration_minutes: Moment(end).diff(Moment(start), 'minutes') });
    if (action !== 'select') {
      return;
    }
    handleToggleNewInterviewModal({ start, end });
  };

  const handleSelectEvent = (event: Event) => {
    setInterviewIndexBeingEdited(event.index);
  };

  const interviewerEventICalUIds = useMemo(() => schedule.interviews.map((interview) => interview.interviewer_event_ical_uid).filter(Boolean), [schedule.interviews]);

  const events = useMemo<Event[]>(() => schedule.interviews.map<Event>((interview, i) => ({
    id: `${id}-interview-${interview.stage_interview_id}-${i}`,
    interviewId: interview.id,
    index: i,
    duration: interview.interview_template.duration_minutes,
    title: interview.name,
    start: Moment.utc(interview.start_time).toDate(),
    end: Moment.utc(interview.start_time).add(interview.interview_template.duration_minutes, 'minutes').toDate(),
    interviewers: interview.interviewers,
    allowSelectOptionsOutsidePool,
    onInterviewerChange: (isEditable ? (interviewerIndex, selectedUser) => handleInterviewerChange(i, interviewerIndex, selectedUser) : undefined),
    onInterviewerOptionalityChange: (isEditable ? (interviewerIndex, optional) => handleInterviewerOptionalityChange(i, interviewerIndex, optional) : undefined),
    onInterviewerTrainingProgramChange: (isEditable ? (interviewerIndex, trainingProgramEligibility) => handleInterviewerTrainingProgramChange(i, interviewerIndex, trainingProgramEligibility) : undefined),
    onAddInterviewer: (allowAddAndRemoveInterviewers ? (selectedUser, optional, trainingProgramEligibility, potentialUsers) => handleAddInterviewer(i, selectedUser, optional, trainingProgramEligibility, potentialUsers) : undefined),
    onRemoveInterviewer: (allowAddAndRemoveInterviewers ? (interviewerIndex) => handleRemoveInterviewer(i, interviewerIndex) : undefined),
    onRemoveInterview: isEditable ? () => handleRemoveInterview(i) : undefined,
    selectedRoom: schedule.selected_room || { room_id: null },
    selectedZoomHost: schedule.selected_zoom_host,
    showEventDetailsPopover,
    timezone: schedule.timezone,
    applicationId: schedule.application_id,
    scheduleId: schedule.id,
    isConflict: (event, checkIfIgnored, userEmail) => isConflict(event, interview, schedule, interviewerEventICalUIds, checkIfIgnored, userEmail),
    isSingleInterview: i === 0 && schedule.interviews.length === 1,
  })), [
    allowAddAndRemoveInterviewers,
    allowSelectOptionsOutsidePool,
    handleAddInterviewer,
    handleInterviewerChange,
    handleInterviewerOptionalityChange,
    handleInterviewerTrainingProgramChange,
    handleRemoveInterview,
    handleRemoveInterviewer,
    interviewerEventICalUIds,
    isEditable,
    schedule.application_id,
    schedule.interviews,
    schedule.timezone,
    showEventDetailsPopover,
  ]);

  // Convert to the start of the _scheduling day_,
  // rather than using the start of the day in the local timezone.
  // TODO: Use a custom react-big-calendar localizer and pass in the scheduling timezone.
  // This math should be done there.
  const defaultDate = Moment.tz(events[0].start, schedule.timezone).startOf('day').toDate();
  const earliestBusinessHour = orderBy(schedule.schedule_template.business_hours || [], [(bh) => bh.start_time], ['asc'])[0];
  const latestBusinessHour = orderBy(schedule.schedule_template.business_hours || [], [(bh) => bh.end_time], ['desc'])[0];

  const minTime = useMemo(() => {
    // This is always set because the schedules show page makes it the bounds of
    // the schedule.
    const businessHoursStart = Moment.tz(earliestBusinessHour?.start_time, 'HH:mm', earliestBusinessHour?.timezone || schedule.timezone);

    let date = Moment.tz(defaultDate, schedule.timezone)
    .hours(businessHoursStart.hours())
    .minutes(businessHoursStart.minutes());

    const withBuffer = date.clone().startOf('hour');
    if (Moment.duration(date.diff(withBuffer)).asMinutes() < MIN_MAX_BUFFER_MINUTES) {
      withBuffer.subtract(1, 'hour');
    }
    const absoluteMin = date.clone().startOf('date'); // 00:00 of the day

    // If subtracting an hour moves it to the previous day, set the time to the
    // absolute minimum time: 00:00 of the day.
    if (withBuffer.date() !== date.date()) {
      date = absoluteMin;
    } else {
      date = withBuffer;
    }

    return date.toDate();
  }, [earliestBusinessHour, schedule.timezone, defaultDate]);

  const maxTime = useMemo(() => {
    // This is always set because the schedules show page makes it the bounds of
    // the schedule.
    // The hours can be greater than 24, which indicates that the maxTime should be on the next day.
    // Moment.hours(6) sets the time to current day, 6 AM. Moment.hours(30) sets the time to next day, 6 AM.
    const businessHoursEndString = latestBusinessHour?.end_time === '00:00' || latestBusinessHour?.end_time === '24:00' ? '23:59' : latestBusinessHour?.end_time;
    const [hours, minutes] = businessHoursEndString.split(':').map((part) => parseInt(part, 10));

    let date = Moment.tz(defaultDate, schedule.timezone).hours(hours).minutes(minutes);

    const withBuffer = date.clone().endOf('hour');
    if (Moment.duration(withBuffer.diff(date)).asMinutes() < MIN_MAX_BUFFER_MINUTES) {
      withBuffer.add(1, 'hour');
    }
    const absoluteMax = date.clone().endOf('date'); // 23:59 of the day

    // If adding an hour moves it to the next day, set the time to the
    // absolute maximum time: 23:59 of the day. Because of the way that
    // react-big-calendar works, we can't include 00:00 of the next day in this
    // calendar view, so if there is an event that ends at 00:00 of the next
    // day, it will disappear from the calendar.
    if (withBuffer.date() !== date.date()) {
      date = absoluteMax;
    } else {
      date = withBuffer;
    }

    return date.toDate();
  }, [latestBusinessHour, schedule.timezone, defaultDate]);

  return (
    <div className="calendar-schedule-container">
      {allowCompareCalendars &&
        <>
          <Button
            className="compare-calendars-button"
            color="gem-outline"
            iconRight={<FontAwesomeIcon icon={faWindowRestore} />}
            onClick={handleToggleModal}
            size="small"
            tooltip={
              <Tooltip
                id={`${id}-compare-calendars-button`}
                position="top"
                value="Expand to compare calendars"
              />
            }
            value={<FontAwesomeIcon icon={faExpandAlt} />}
          />
          <CalendarCompareModal
            calendarSchedule={(
              <CalendarScheduleCalendar
                availabilityTimeSlots={availabilityTimeSlots}
                date={defaultDate}
                eventConflictCheckFunctions={events.map((event) => event.isConflict)}
                events={events.map((event) => ({
                  ...event,
                  id: `${event.id}-compare-modal`,
                }))}
                headerDate={defaultDate}
                isDraggable={isDraggable}
                maxTime={Moment.tz(maxTime, schedule.timezone).isSame(defaultDate, 'date') ? Moment.tz(defaultDate, schedule.timezone).endOf('day').toDate() : maxTime}
                minTime={defaultDate}
                onDateChange={isEditable ? handleDateChange : undefined}
                onEventChange={handleEventChange}
                onRoomChange={isEditable ? handleRoomChange : undefined}
                onSelectCalendarTime={handleSelectCalendarTime}
                onSelectEvent={isInterviewEditable ? handleSelectEvent : undefined}
                onZoomHostChange={isEditable ? handleZoomHostChange : undefined}
                potentialZoomHosts={potentialZoomHosts}
                scheduleId={`${id}-compare-modal`}
                selectedRoomId={schedule.selected_room?.room_id || null}
                selectedZoomHostId={schedule.selected_zoom_host?.id || null}
                showZoomHost={showZoomHost}
                timezone={schedule.timezone}
              />
            )}
            defaultSelectedUserIds={compareCalendarsDefaultSelectedUserIds}
            isOpen={isModalOpen}
            onToggle={handleToggleModal}
            schedule={schedule}
            title={`${headerTitle ? `${headerTitle}: ` : ''}Compare calendars and edit schedule`}
          />
        </>
      }
      <CalendarScheduleCalendar
        availabilityTimeSlots={availabilityTimeSlots}
        date={defaultDate}
        eventConflictCheckFunctions={events.map((event) => event.isConflict)}
        events={events}
        headerDate={defaultDate}
        headerTitle={headerTitle}
        isDraggable={isDraggable}
        maxTime={maxTime}
        minTime={minTime}
        onDateChange={isEditable ? handleDateChange : undefined}
        onEventChange={handleEventChange}
        onRoomChange={isEditable ? handleRoomChange : undefined}
        onSelectCalendarTime={handleSelectCalendarTime}
        onSelectEvent={isInterviewEditable ? handleSelectEvent : undefined}
        onZoomHostChange={isEditable ? handleZoomHostChange : undefined}
        potentialZoomHosts={potentialZoomHosts}
        scheduleId={id}
        selectedRoomId={schedule.selected_room?.room_id || null}
        selectedZoomHostId={schedule.selected_zoom_host?.id || null}
        showZoomHost={showZoomHost}
        timezone={schedule.timezone}
      />
      <CalendarScheduleAddInterviewModal
        initialEnd={newInterviewInitialValues?.end}
        initialStart={newInterviewInitialValues?.start}
        isOpen={isNewInterviewModalOpen}
        onAddInterview={handleAddInterview}
        onToggle={() => handleToggleNewInterviewModal()}
        schedule={schedule}
      />
      {isInterviewEditable && (
        <CalendarScheduleUpdateInterviewModal
          canUpdateScorecard={allowUpdateScorecards}
          interview={interviewIndexBeingEdited !== null ? schedule.interviews[interviewIndexBeingEdited] : undefined}
          isOpen={interviewIndexBeingEdited !== null}
          onToggle={() => setInterviewIndexBeingEdited(null)}
          onUpdateInterview={(updatedInterview) => handleUpdateInterview(interviewIndexBeingEdited!, updatedInterview)}
        />
      )}
    </div>
  );
};

export default CalendarSchedule;
