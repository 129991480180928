import { asyncWithLDProvider } from 'launchdarkly-react-client-sdk';
import { useEffect, useState } from 'react';

import config from '../../../../../config';

import type { ReactNode } from 'react';

// This is to ensure that we only call asyncWithLDProvider exactly once, even if
// this component is remounted. We'll store the promise in this variable, so
// even if it's already resolved, we can just extract the original value instead
// of creating a new promise.
let globalProviderPromise: ReturnType<typeof asyncWithLDProvider>;

interface Props {
  children: ReactNode;
}

const LaunchDarklyProvider = ({ children }: Props) => {
  const [LDProvider, setLDProvider] = useState<Awaited<typeof globalProviderPromise> | null>(null);

  useEffect(() => {
    // Either use the existing singleton of the provider or create one for the
    // first time.
    globalProviderPromise = globalProviderPromise || asyncWithLDProvider({
      clientSideID: config.LAUNCHDARKLY_CLIENT_SIDE_ID,
    });

    globalProviderPromise.then((p) => {
      // This needs to be wrapped in an anonymous function because otherwise,
      // React will invoke p, thinking it's a setState function.
      setLDProvider(() => p);
    });
  }, []);

  if (!LDProvider) {
    // We haven't loaded the provider yet, so render null. This is to avoid
    // flashes of unresolved feature flags. Even if LaunchDarkly can't be
    // reached (e.g. because of an AdBlocker), it will fail open, so this
    // rendering of null should be very brief.
    return null;
  }

  return (
    <LDProvider>
      {children}
    </LDProvider>
  );
};

export default LaunchDarklyProvider;
