import { useHistory } from 'react-router-dom';
import { useMemo, useState } from 'react';

import MultiStepFormStep from '../../../library/inputs/MultiStepFormStep';
import useQueryState from '../../../../hooks/use-query-state';
import { CalendarEventTemplateType, EditorType, videoConferencingLabels, VideoConferencingTool } from '../../../../types';
import { DEFAULT_CALENDAR_EVENT_CONTENT } from '../../CalendarEventTemplates/CalendarEventTemplateCreate/helpers';
import { StyledCheckboxInput, StyledDurationInput, StyledEditorInput, StyledErrorTokenFlash, StyledFlash, StyledFormContainer, StyledHiringMeetingAttendeeSelectInput, StyledRoomSelectInput, StyledTextInput, StyledTokenInput, StyledZoomHostFiltersBuilder } from '../styles';
import { constructRoomFiltersPayload } from '../../../../libraries/rooms';
import { createEmptyZoomHostFilter } from '../../../../libraries/zoom-hosts';
import { getTokensFromSlateValue, slateValueToHtml } from '../../../../libraries/editor/slate-value-to-html';
import { slateValueToText } from '../../../../libraries/editor/slate-value-to-text';
import { useCreateCalendarEventTemplate } from '../../../../hooks/queries/calendar-event-templates';
import { useCreateHiringMeetingTemplate, useHiringMeetingTemplate } from '../../../../hooks/queries/hiring-meeting-templates';
import { useSession } from '../../../../hooks/use-session';
import { useSlateEditor } from '../../../../hooks/use-slate-editor';
import { useTokens } from '../../../../hooks/queries/tokens';

import type { ChangeEvent } from 'react';
import type { CreateCalendarEventTemplatePayload } from '../../../../hooks/queries/calendar-event-templates';
import type { CreateHiringMeetingTemplatePayload } from '../../../../hooks/queries/hiring-meeting-templates';
import type { EditableHiringMeetingAttendeeFilter, EditableZoomHostFilter } from '../../../../types';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../../library/inputs/SelectInput/types';
import { correctPath } from 'libraries/gem';

const HiringMeetingTemplateCreateForm = () => {
  const history = useHistory();

  const [baseId] = useQueryState<string>('base', '');
  const { data: baseTemplate } = useHiringMeetingTemplate(baseId);

  const { account } = useSession();

  const [name, setName] = useState(baseTemplate?.name ? `Copy of ${baseTemplate.name}` : '');
  const [durationMinutes, setDurationMinutes] = useState(baseTemplate?.duration_minutes || 0);
  const [isVideoConferencingEnabled, setIsVideoConferencingEnabled] = useState(baseTemplate?.video_conferencing_enabled || false);
  const [zoomHostFilters, setZoomHostFilters] = useState<EditableZoomHostFilter[]>(baseTemplate?.zoom_host_filters || createEmptyZoomHostFilter());
  const [rooms, setRooms] = useState<string[]>([]);
  const [attendeeFilters, setAttendeeFilters] = useState<EditableHiringMeetingAttendeeFilter[]>(baseTemplate?.hiring_meeting_attendee_filters || []);
  const [calendarEventTemplateTitleSlateEditor, calendarEventTemplateTitleSlateValue, setCalendarEventTemplateTitleSlateValue] = useSlateEditor(baseTemplate?.calendar_event_template.title || '', true);
  const [calendarEventTemplateDescriptionSlateEditor, calendarEventTemplateDescriptionSlateValue, setCalendarEventTemplateDescriptionSlateValue] = useSlateEditor(baseTemplate?.calendar_event_template.description || '');
  const [calendarEventTemplateLocation, setCalendarEventTemplateLocation] = useState(baseTemplate?.calendar_event_template.location || '');

  const [isLoading, setIsLoading] = useState(false);

  const createCalendarEventTemplateMutation = useCreateCalendarEventTemplate();
  const createHiringMeetingTemplateMutation = useCreateHiringMeetingTemplate();

  const {
    data: tokens,
    error: tokensError,
  } = useTokens({
    type: EditorType.HiringMeetingCalendarEvent,
  });

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => setName(e.target.value);
  const handleDurationMinutesChange = (duration: number) => setDurationMinutes(duration);
  const handleIsVideoConferencingEnabledChange = (e: ChangeEvent<HTMLInputElement>) => setIsVideoConferencingEnabled(e.target.checked);
  const handleZoomHostFiltersChange = (filters: EditableZoomHostFilter[]) => setZoomHostFilters(filters);
  const handleRoomsChange = (options: OnChangeValue<Option<string>, true>) => setRooms(options ? options.map((room) => room.value) : []);
  const handleAttendeeFiltersChange = (filters: EditableHiringMeetingAttendeeFilter[]) => setAttendeeFilters(filters);
  const handleCalendarEventTemplateLocationChange = (e: ChangeEvent<HTMLInputElement>) => setCalendarEventTemplateLocation(e.target.value);

  const tokensUsedInTitle = useMemo(() => getTokensFromSlateValue(calendarEventTemplateTitleSlateValue), [calendarEventTemplateTitleSlateValue]);
  const tokensUsedInDescription = useMemo(() => getTokensFromSlateValue(calendarEventTemplateDescriptionSlateValue), [calendarEventTemplateDescriptionSlateValue]);
  const errorTokens = useMemo(() => {
    const errorTitleTokens = (
      tokens && tokensUsedInTitle ?
        tokensUsedInTitle.filter((name) => !tokens[name] || tokens[name].disabled) :
        []
    );
    const errorDescriptionTokens = (
      tokens && tokensUsedInDescription ?
        tokensUsedInDescription.filter((name) => !tokens[name] || tokens[name].disabled) :
        []
    );
    return [
      ...errorTitleTokens,
      ...errorDescriptionTokens,
    ];
  }, [tokens, tokensUsedInTitle, tokensUsedInDescription]);

  const handleSubmit = async () => {
    if (errorTokens.length > 0) {
      // We don't need to display an error because ErrorTokenFlash already takes
      // care of that. We just need to stop the execution from here.
      return;
    }

    createCalendarEventTemplateMutation.reset();
    createHiringMeetingTemplateMutation.reset();
    setIsLoading(true);

    let calendarEventTemplateId = '';
    try {
      const payload: CreateCalendarEventTemplatePayload = {
        name,
        title: slateValueToText(calendarEventTemplateTitleSlateValue),
        description: slateValueToHtml(calendarEventTemplateDescriptionSlateValue),
        location: calendarEventTemplateLocation,
        type: CalendarEventTemplateType.HiringMeetingCalendarEvent,
      };
      const data = await createCalendarEventTemplateMutation.mutateAsync({ payload });
      calendarEventTemplateId = data.id;
    } catch (_) {
      setIsLoading(false);
      return;
    }

    try {
      const payload: CreateHiringMeetingTemplatePayload = {
        name,
        duration_minutes: durationMinutes,
        video_conferencing_enabled: isVideoConferencingEnabled,
        zoom_host_filters: isVideoConferencingEnabled && account?.video_conferencing_type === VideoConferencingTool.Zoom ? (zoomHostFilters || []).map(({ zoom_host_filter_expressions }) => ({
          zoom_host_filter_expressions: zoom_host_filter_expressions.map((exp) => ({
            filterable_id: exp.filterable_id,
            filterable_type: exp.filterable_type,
            negated: exp.negated,
          })),
        })) : undefined,
        room_filters: constructRoomFiltersPayload(rooms),
        hiring_meeting_attendee_filters: attendeeFilters.map((filter) => ({
          hiring_meeting_attendee_filter_expressions: filter.hiring_meeting_attendee_filter_expressions.map((exp) => ({
            filterable_id: exp.filterable_id,
            filterable_type: exp.filterable_type,
            negated: exp.negated,
          })),
        })),
        calendar_event_template_id: calendarEventTemplateId,
      };
      const data = await createHiringMeetingTemplateMutation.mutateAsync({ payload });
      history.push(correctPath(`/app/hiring-meeting-templates/${data.id}`));
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }

    setIsLoading(false);
  };

  return (
    <MultiStepFormStep
      isFirstStep
      isLastStep
      isSubmitting={isLoading}
      nextButtonValue="Create Hiring Meeting Template"
      onNext={handleSubmit}
    >
      <StyledFlash
        message={tokensError?.message}
        showFlash={Boolean(tokensError)}
        type="danger"
      />
      <StyledFlash
        message={createCalendarEventTemplateMutation.error?.message}
        showFlash={createCalendarEventTemplateMutation.isError}
        type="danger"
      />
      <StyledFlash
        message={createHiringMeetingTemplateMutation.error?.message}
        showFlash={createHiringMeetingTemplateMutation.isError}
        type="danger"
      />
      <StyledErrorTokenFlash errorTokens={errorTokens} />
      <StyledTextInput
        isDisabled={isLoading}
        isRequired
        label="Template Name"
        onChange={handleNameChange}
        value={name}
      />
      <StyledDurationInput
        isDisabled={isLoading}
        isRequired
        label="Meeting Duration"
        maxMinutes={360}
        onChange={handleDurationMinutesChange}
        value={durationMinutes}
      />
      <StyledCheckboxInput
        helperText="We will create a video conferencing link and add it to the event."
        isChecked={isVideoConferencingEnabled}
        isDisabled={isLoading}
        label="Add video conferencing."
        onChange={handleIsVideoConferencingEnabledChange}
      />
      {isVideoConferencingEnabled && account?.video_conferencing_type === 'zoom' &&
        <StyledZoomHostFiltersBuilder
          excludeFirstInterviewerOption
          filters={zoomHostFilters}
          isDisabled={isLoading}
          label="Zoom Meeting Host"
          onChange={handleZoomHostFiltersChange}
        />
      }
      <StyledRoomSelectInput
        isDisabled={isLoading}
        label="Rooms"
        onChange={handleRoomsChange}
        selectedRoomIds={rooms}
      />
      <StyledHiringMeetingAttendeeSelectInput
        isDisabled={isLoading}
        onChange={handleAttendeeFiltersChange}
        value={attendeeFilters}
      />
      <StyledFormContainer>
        <StyledTokenInput
          editor={calendarEventTemplateTitleSlateEditor}
          isDisabled={isLoading}
          isRequired
          label="Calendar Event Title"
          pendingPreviewMessage="You can preview this token when you are scheduling a hiring meeting."
          setValue={setCalendarEventTemplateTitleSlateValue}
          tokens={tokens}
          type="hiring_meeting_calendar_event"
          value={calendarEventTemplateTitleSlateValue}
        />
        <StyledTextInput
          isDisabled={isVideoConferencingEnabled || isLoading}
          label="Calendar Event Location"
          onChange={handleCalendarEventTemplateLocationChange}
          placeholder={isVideoConferencingEnabled ? `This will be set to the ${videoConferencingLabels[account?.video_conferencing_type!]} link` : ''}
          value={isVideoConferencingEnabled ? '' : calendarEventTemplateLocation}
        />
      </StyledFormContainer>
      <StyledEditorInput
        editor={calendarEventTemplateDescriptionSlateEditor}
        exampleHtmlContent={DEFAULT_CALENDAR_EVENT_CONTENT.hiring_meeting_calendar_event.description}
        isDisabled={isLoading}
        label="Calendar Event Description"
        pendingPreviewMessage="You can preview this token when you are scheduling a hiring meeting."
        setValue={setCalendarEventTemplateDescriptionSlateValue}
        tokens={tokens}
        type="hiring_meeting_calendar_event"
        value={calendarEventTemplateDescriptionSlateValue}
      />
    </MultiStepFormStep>
  );
};

export default HiringMeetingTemplateCreateForm;
