import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { some } from 'lodash';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';

import type { ReactNode, ReactElement } from 'react';

interface Props {
  // This should be MenuNavLink elements, but we can't type it correctly.
  children?: ReactElement[];
  icon: ReactNode;
  title: string;
}

const MenuAccordionSection = ({ children, icon, title }: Props) => {
  const { pathname } = useLocation();

  const autoExpandSection = children && some(children, (link) => {
    return pathname.startsWith(link.props.path);
  });

  const [isExpanded, setIsExpanded] = useState(autoExpandSection);

  const toggleExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <>
      <a
        className={`nav-link link menu-accordion-section ${isExpanded ? 'expanded' : ''}`}
        onClick={toggleExpanded}
      >
        <FontAwesomeIcon
          className="collapsing-arrow"
          icon={isExpanded ? faAngleDown : faAngleRight}
        />
        {icon}{title}
      </a>
      {isExpanded &&
        <div className="menu-accordion-children">
          {children}
        </div>
      }
    </>
  );
};

export default MenuAccordionSection;
