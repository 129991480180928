import styled from 'styled-components';

import Modal from 'components/library/layout/Modal';

export const StyledModal = styled(Modal)`
  .modal-body {
    overflow: visible !important;
  }
`;

export const StyledPreferencesForm = styled.div`
  margin-top: 2em;

  .flash {
    margin-bottom: 1em;

    p {
      margin-block: auto;
    }
  }

  .checkbox-input {
    margin-bottom: 1em;
  }

  .expandable-checkbox-input {
    margin-bottom: 1em;

    & > label {
      width: fit-content;
    }
  }

  .expandable-checkbox-input-contents-expanded {
    flex-direction: column;
    flex-wrap: nowrap;
    max-height: 30vh;
    overflow-y: scroll;
  }
`;
