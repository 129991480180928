import styled from 'styled-components';

import { NavLink } from 'react-router-dom';
import StatusIndicator from '../../../../library/utils/StatusIndicator';

export const StyledContainer = styled.div`
  display: flex;
  padding: 1em;
`;

export const StyledMenu = styled.div`
  background: ${({ theme }) => theme.colors.PRODUCT.TERTIARY_BACKGROUND};
  border: 1px solid ${({ theme }) => theme.colors.PRODUCT.SECONDARY_BORDER};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  height: max-content;
  max-width: max-content;
  min-width: 225px;
  width: 100%;
`;

export const StyledMenuLabel = styled.div`
  align-items: center;
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const StyledMenuLabelContent = styled.div`
  flex: 1;
  min-width: 0;
`;

export const StyledMenuLabelStatusIndicator = styled(StatusIndicator)`
  /* This is needed for the extra specificity. */
  &.fa-circle {
    margin-right: .8em;
  }
`;

export const StyledStageName = styled.div`
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const StyledMeetingTime = styled.div`
  font-weight: normal;
`;

export const StyledNavLink = styled(NavLink)`
  border-left: 4px solid transparent;
  color: initial;
  padding: 1.5em 1em;
  text-decoration: none;
  transition: all linear .1s;
  white-space: nowrap;

  &:first-child {
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
  }

  &:last-child {
    border-bottom-left-radius: 6px;
    border-bottom-right-radius: 6px;
  }

  &:hover {
    background: ${({ theme }) => theme.colors.PRODUCT.SECONDARY_BACKGROUND};
    cursor: pointer;
  }

  &.active {
    background: white;
    border-left: 4px solid ${({ theme }) => theme.colors.BRAND.GEM_BLUE};
    box-shadow: 0 0 0 1px ${({ theme }) => theme.colors.PRODUCT.PRIMARY_BORDER};

    ${StyledStageName} {
      color: ${({ theme }) => theme.colors.BLUES.BLUE_600};
    }
  }
`;
