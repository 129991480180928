import { find } from 'lodash';
import { useCallback } from 'react';

import SelectInput from './SelectInput';
import { formatInterviewLimit } from '../../../libraries/formatters';
import { useAccount } from '../../../hooks/queries/accounts';

import type { Dispatch, SetStateAction } from 'react';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from './SelectInput/types';

interface Props {
  allowUnsetting?: boolean;
  dailyInterviewLimit?: number;
  helperText?: string;
  isDisabled?: boolean;
  setDailyInterviewLimit: Dispatch<SetStateAction<number>>;
  setWeeklyInterviewLimit: Dispatch<SetStateAction<number>>;
  weeklyInterviewLimit?: number;
}

function generateOptions (count: number, suffix: string = '') {
  return [
    {
      label: formatInterviewLimit(-1, suffix),
      value: -1,
    },
    // Generate options up to count.
    ...Array.from({ length: count }).map((_, index) => ({
      label: formatInterviewLimit(index + 1, suffix),
      value: index + 1,
    })),
  ];
}

const dailyOptions = generateOptions(10, 'per day');
const weeklyOptions = generateOptions(10, 'per week');

const InterviewLimitSelectInput = ({
  allowUnsetting = false,
  dailyInterviewLimit,
  helperText,
  isDisabled = false,
  setDailyInterviewLimit,
  setWeeklyInterviewLimit,
  weeklyInterviewLimit,
}: Props) => {
  const { data: account } = useAccount();

  const selectedDailyOption = find(dailyOptions, ['value', dailyInterviewLimit]);
  const selectedWeeklyOption = find(weeklyOptions, ['value', weeklyInterviewLimit]);

  const handleDailyInterviewLimitChange = useCallback((option: OnChangeValue<Option<number>, false>) => {
    setDailyInterviewLimit(option?.value || 0);
  }, []);

  const handleWeeklyInterviewLimitChange = useCallback((option: OnChangeValue<Option<number>, false>) => {
    setWeeklyInterviewLimit(option?.value || 0);
  }, []);

  return (
    <div className="input interview-limit-select-input">
      <div className="interview-limit-select-input-container">
        <SelectInput
          isClearable={allowUnsetting}
          isDisabled={isDisabled}
          label="Daily interview limit"
          onChange={handleDailyInterviewLimitChange}
          options={dailyOptions}
          placeholder={`Use the account default (${formatInterviewLimit(account?.daily_interview_limit, 'per day')})`}
          value={selectedDailyOption}
        />
        <SelectInput
          isClearable={allowUnsetting}
          isDisabled={isDisabled}
          label="Weekly interview limit"
          onChange={handleWeeklyInterviewLimitChange}
          options={weeklyOptions}
          placeholder={`Use the account default (${formatInterviewLimit(account?.weekly_interview_limit, 'per week')})`}
          value={selectedWeeklyOption}
        />
      </div>
      {helperText && <div className="helper-text">{helperText}</div>}
    </div>
  );
};

export default InterviewLimitSelectInput;
