import { createContext, useContext, useMemo, useState } from 'react';

import type { CreateAvailabilityPayload } from '../../../../../hooks/queries/availabilities';
import type { Dispatch, SetStateAction, ReactNode } from 'react';

export enum Step {
  Preferences,
  SuggestedTimes,
  Email,
  Review,
  SendLink,
}

interface NewAvailabilityContextState {
  availability: CreateAvailabilityPayload;
  availabilityId: string;
  completedStep: Step;
  setAvailability: Dispatch<SetStateAction<CreateAvailabilityPayload>>;
  setAvailabilityId: Dispatch<SetStateAction<string>>;
  setCompletedStep: Dispatch<SetStateAction<Step>>;
}

// This is the context for keeping track of the new availability. You should keep
// track of the new link with a useState call in the same component that use use
// the Provider, recreate an array using useMemo, and then pass it into the
// Provider. Make sure you don't pass an array literal into the Provider. This can
// cause re-rendering issues as noted here:
// https://reactjs.org/docs/context.html#caveats.
const NewAvailabilityContext = createContext<NewAvailabilityContextState>({
  availability: {
    application_id: '',
    manual: false,
  },
  availabilityId: '',
  completedStep: Step.Preferences,
  setAvailability: () => {},
  setAvailabilityId: () => {},
  setCompletedStep: () => {},
});

interface Props {
  children: ReactNode;
}

export const NewAvailabilityProvider = ({ children }: Props) => {
  const [availability, setAvailability] = useState<CreateAvailabilityPayload>({
    application_id: '',
    manual: false,
  });
  const [availabilityId, setAvailabilityId] = useState('');
  const [completedStep, setCompletedStep] = useState(Step.Preferences);

  const contextValue = useMemo<NewAvailabilityContextState>(() => ({
    availability,
    availabilityId,
    completedStep,
    setAvailability,
    setAvailabilityId,
    setCompletedStep,
  }), [availability, availabilityId, completedStep]);

  return (
    <NewAvailabilityContext.Provider value={contextValue}>
      {children}
    </NewAvailabilityContext.Provider>
  );
};

export const useNewAvailability = () => {
  return useContext(NewAvailabilityContext);
};
