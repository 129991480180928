import Moment from 'moment';

import EmailPreview from '../../../../../library/data-display/EmailPreview';
import Flash from '../../../../../library/utils/Flash';
import LoadingSpinner from '../../../../../library/utils/LoadingSpinner';
import Section from '../../../../../library/layout/Section';
import { useSelfSchedulingLink } from '../../../../../../hooks/queries/self-scheduling-links';

interface Props {
  selfSchedulingLinkId?: string;
}

const CandidateSelfSchedulingRequestEmailSection = ({ selfSchedulingLinkId }: Props) => {
  const {
    data: selfSchedulingLink,
    isLoading,
  } = useSelfSchedulingLink(selfSchedulingLinkId);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const emailTemplate = selfSchedulingLink?.schedule_template?.self_scheduling_request_email_template;
  const email = emailTemplate?.emails?.[0];

  const wasCreatedOverAnHourAgo = email && Moment(email.created_at).isBefore(Moment().subtract(1, 'hours'));

  return (
    <Section
      className="candidate-self-scheduling-request-email-section"
      title="Self-scheduling request email"
    >
      <Flash
        message="This email failed to send. Please confirm the candidate's email address and re-send an email containing the self-scheduling link outside InterviewPlanner."
        showFlash={Boolean(email && email.status === 'failed')}
        type="danger"
      />
      <Flash
        message="This email was never sent. Please contact support."
        showFlash={Boolean(email && !email.sent_at && wasCreatedOverAnHourAgo)}
        type="warning"
      />
      {email ? (
        <EmailPreview
          attachments={emailTemplate.attachments}
          bccEmails={email.bcc_emails}
          body={email.body}
          ccEmails={email.cc_emails}
          senderEmail={email.sender_email}
          senderName={email.sender_name}
          sentAt={email.sent_at}
          subject={email.subject}
          to={email.recipient_email}
        />
      ) : <span className="no-email">The self-scheduling request email was not sent through InterviewPlanner.</span>}
    </Section>
  );
};

export default CandidateSelfSchedulingRequestEmailSection;
