import useLocalStorage from './use-local-storage';

function useSavedQueryParams (route: string): [string, (newParams: string) => void] {
  const [value, setValue] = useLocalStorage<{ [route: string]: string }>('saved_query_params', {});
  return [value[route] || '', (newParams: string) => {
    setValue({
      ...value,
      [route]: newParams,
    });
  }];
}

export default useSavedQueryParams;
