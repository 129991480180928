import classnames from 'classnames';
import { capitalize, find } from 'lodash';
import { useMemo } from 'react';

import SelectInput from './SelectInput';
import Tag from '../data-display/Tag';
import Tooltip from '../utils/Tooltip';
import { useSession } from '../../../hooks/use-session';
import { useUsers } from '../../../hooks/queries/users';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from './SelectInput/types';

export enum UserSelectInputLayout {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

interface Props<M extends boolean> {
  annotateCurrentUser?: boolean;
  className?: string;
  currentUserFirst?: boolean;
  disabledUserIds?: string[];
  includeOnlyATSUsers?: boolean;
  excludeArchived?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  isMulti?: M;
  isRequired?: boolean;
  label?: string;
  layout?: `${UserSelectInputLayout}`;
  name?: string;
  onChange?: (newValue: OnChangeValue<Option<string>, M>, actionMeta: ActionMeta<Option<string>>) => void;
  placeholder?: string;
  value?: string | string[];
}

const UserSelectInput = <M extends boolean = false>({
  annotateCurrentUser = false,
  className,
  currentUserFirst = false,
  disabledUserIds = [],
  excludeArchived = true,
  includeOnlyATSUsers = false,
  isClearable = false,
  isDisabled = false,
  isMulti,
  isRequired,
  label,
  layout = UserSelectInputLayout.Vertical,
  name,
  onChange,
  placeholder = 'Everyone',
  value,
}: Props<M>) => {
  const { account, currentUser } = useSession();

  const { data: users } = useUsers({ archived: true });

  const disabledUserIdMap = useMemo<Record<string, boolean>>(() => {
    return disabledUserIds.reduce((acc, id) => {
      if (id === value) {
        // If the selected item is supposed to be disabled, don't disable it cause then it just gets weird.
        return acc;
      }
      return { ...acc, [id]: true };
    }, {});
  }, [disabledUserIds]);

  const options = useMemo<Option<string>[]>(() => {
    if (!users) {
      return [];
    }

    return users.users
    .filter(({ directory_archived, user_archived }) => !excludeArchived || (!user_archived && !directory_archived))
    .sort((a, b) => {
      // Move the current user to the top if we want the current user first.
      if (currentUserFirst && a.id === currentUser?.id) {
        return -1;
      }
      if (currentUserFirst && b.id === currentUser?.id) {
        return 1;
      }
      // Otherwise, sort by name.
      return (a.name || a.email) < (b.name || b.email) ? -1 : 1;
    })
    .map((user) => ({
      value: user.id,
      label: user.name || user.email,
      isDisabled: Boolean(disabledUserIdMap[user.id]) || (includeOnlyATSUsers && !user.ats_id),
      tooltip: (includeOnlyATSUsers && !user.ats_id) ? (
        <Tooltip
          id={`user-select-input-tooltip-${user.id}`}
          position="top"
          value={`${user.name || user.email} doesn't have an account in ${capitalize(account?.ats_type)}, so they can't be selected.`}
        />
      ) : undefined,
    }));
  }, [currentUser?.id, users, disabledUserIdMap]);

  const selected = useMemo<OnChangeValue<Option<string>, M> | undefined>(() => {
    if (!isMulti) {
      return value ? find(options, ['value', value]) as OnChangeValue<Option<string>, M> : undefined;
    }

    let selectedUsers: string[] | undefined;
    if (typeof value === 'string') {
      selectedUsers = [value];
    } else {
      selectedUsers = value;
    }
    return selectedUsers ?
      // The types with a dynamic isMulti is a bit weird. We need to explicitly
      // cast this to be compatible.
      selectedUsers.map((userId) => find(options, ['value', userId])!) as unknown as OnChangeValue<Option<string>, M> :
      undefined;
  }, [isMulti, options, value]);

  return (
    <div className={classnames('user-select-input-container', className)}>
      <SelectInput
        className={classnames('user-select-input', `layout-${layout}`)}
        formatOptionLabel={(option) => (
          <div
            className="user-select-option-container"
            data-for={option.tooltip ? option.tooltip.props.id : undefined}
            data-tip={option.tooltip ? true : undefined}
          >
            <Tag
              annotateCurrentUser={annotateCurrentUser}
              type="user"
              value={option.value}
            />
            {option.tooltip}
          </div>
        )}
        isClearable={isClearable}
        isDisabled={isDisabled}
        isMulti={isMulti}
        isRequired={isRequired}
        label={label}
        maxMenuHeight={300}
        name={name}
        onChange={onChange}
        options={options}
        placeholder={placeholder}
        value={selected}
      />
    </div>
  );
};

export default UserSelectInput;
