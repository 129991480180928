import { useMutation, useQuery, useQueryClient } from 'react-query';

import InterviewPlanner from '../../libraries/interviewplanner';

import type { HiringTeamRole } from '../../types';
import type { Job } from '../../types';
import type { UseQueryOptions } from 'react-query';

export enum QueryKey {
  ListJobs = 'ListJobs',
  RetrieveJob = 'RetrieveJob',
}

export const useJob = (id?: string, options: UseQueryOptions<Job, Error> = {}) => {
  return useQuery<Job, Error>([QueryKey.RetrieveJob, id], () => {
    return InterviewPlanner.request('GET', `/jobs/${id}`);
  }, {
    ...options,
    enabled: options.enabled !== undefined ? options.enabled : Boolean(id),
  });
};

export interface ListJobsQuery {
  limit?: number;
  offset?: number;
  name?: string;
  archived?: boolean;
  status?: string[];
  including_user?: string;
  including_role?: HiringTeamRole[];
  enabled_for_scheduling?: boolean;
}

interface ListJobsData {
  jobs: Job[];
  total: number;
}

export const useJobs = (query: ListJobsQuery = {}, options: UseQueryOptions<ListJobsData, Error> = {}) => {
  return useQuery<ListJobsData, Error>([QueryKey.ListJobs, query], () => {
    return InterviewPlanner.request<ListJobsData>('GET', '/jobs', null, query);
  }, options);
};

interface UpdateJobPayload {
  archived?: boolean;
}

interface UpdateJobMutationVariables {
  id: string;
  payload?: UpdateJobPayload;
}

export const useUpdateJob = () => {
  const queryClient = useQueryClient();

  return useMutation<Job, Error, UpdateJobMutationVariables>(({ id, payload }) => {
    return InterviewPlanner.request('POST', `/jobs/${id}`, payload);
  }, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKey.ListJobs]);
      queryClient.setQueryData([QueryKey.RetrieveJob, data.id], data);
    },
  });
};
