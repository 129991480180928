import Moment from 'moment-timezone';
import { isEmpty, isEqual } from 'lodash';
import { useState } from 'react';

import CheckboxInput from '../../../../../../library/inputs/CheckboxInput';
import Flash from '../../../../../../library/utils/Flash';
import Modal from '../../../../../../library/layout/Modal';
import { directoryCalendarLabels, VideoConferencingTool } from '../../../../../../../types';
import { formatAttendeeName } from './helpers';
import { formatDuration } from '../../../../../../../libraries/formatters';
import { formatMoment, TimeFormat } from '../../../../../../../libraries/time';
import { useRoomsMap } from '../../../../../../../hooks/queries/rooms';
import { useSession } from '../../../../../../../hooks/use-session';
import { useUpdateHiringMeeting } from '../../../../../../../hooks/queries/hiring-meetings';
import { useUsersMap } from '../../../../../../../hooks/queries/users';

import type { ChangeEvent } from 'react';
import type { EditableHiringMeeting, HiringMeeting, HiringMeetingAttendeeUser } from '../../../../../../../types';
import type { TimeSlot } from '../../../../../../library/inputs/AvailabilityPicker/types';
import type { UpdateHiringMeetingPayload } from '../../../../../../../hooks/queries/hiring-meetings';

interface Props {
  isOpen: boolean;
  newHiringMeeting: EditableHiringMeeting;
  newHiringMeetingAttendees?: HiringMeetingAttendeeUser[];
  newTimeSlot: TimeSlot;
  onSuccess: (newlyUpdatedHiringMeeting: HiringMeeting) => void;
  onToggle: () => void;
  originalHiringMeeting: HiringMeeting;
  originalHiringMeetingAttendees?: HiringMeetingAttendeeUser[];
}

const UpdateHiringMeetingConfirmModal = ({
  isOpen,
  newHiringMeeting,
  newHiringMeetingAttendees,
  newTimeSlot,
  onSuccess,
  onToggle,
  originalHiringMeeting,
  originalHiringMeetingAttendees,
}: Props) => {
  const { account } = useSession();
  const rooms = useRoomsMap();
  const users = useUsersMap({ archived: true });

  const updateHiringMeetingMutation = useUpdateHiringMeeting();

  const [sendNotifications, setSendNotifications] = useState(true);

  const oldDate = formatMoment(Moment.tz(originalHiringMeeting.start_time, originalHiringMeeting.timezone), TimeFormat.LongDayOfWeekMonthDay);
  const newDate = formatMoment(Moment.tz(newTimeSlot.start_time, newHiringMeeting.timezone), TimeFormat.LongDayOfWeekMonthDay);

  const oldStartTime = formatMoment(Moment.tz(originalHiringMeeting.start_time, originalHiringMeeting.timezone), TimeFormat.TimeWithTimezone);
  const newStartTime = formatMoment(Moment.tz(newTimeSlot.start_time, newHiringMeeting.timezone), TimeFormat.TimeWithTimezone);

  const oldDuration = originalHiringMeeting.hiring_meeting_template.duration_minutes;
  const newDuration = Moment(newTimeSlot.end_time).diff(newTimeSlot.start_time, 'minutes');

  const oldRoomId = originalHiringMeeting.room_id || null;
  const newRoomId = newHiringMeeting.room_id || null;

  const oldZoomHostId = originalHiringMeeting.zoom_host_id || null;
  const newZoomHostId = newHiringMeeting.zoom_host_id || null;

  const oldVideoConferencingEnabled = originalHiringMeeting.hiring_meeting_template.video_conferencing_enabled;
  const newVideoConferencingEnabled = newHiringMeeting.hiring_meeting_template.video_conferencing_enabled;

  const oldAttendees = originalHiringMeetingAttendees?.map(({ id }) => id).sort((a, b) => users[a]?.name! < users[b]?.name! ? -1 : 1) || [];
  const newAttendees = newHiringMeetingAttendees?.map(({ id }) => id).sort((a, b) => users[a]?.name! < users[b]?.name! ? -1 : 1) || [];
  const addedAttendees = newAttendees.filter((id) => !oldAttendees.includes(id));
  const removedAttendees = oldAttendees.filter((id) => !newAttendees.includes(id));

  const isEventChanged = Boolean(
    oldDate !== newDate ||
    oldStartTime !== newStartTime ||
    oldDuration !== newDuration ||
    oldRoomId !== newRoomId ||
    oldZoomHostId !== newZoomHostId ||
    oldVideoConferencingEnabled !== newVideoConferencingEnabled ||
    !isEqual(oldAttendees, newAttendees)
  );

  const handleSendNotificationsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSendNotifications(e.target.checked);
  };

  const handleToggle = () => {
    onToggle();
  };

  const handleUpdateHiringMeeting = async () => {
    updateHiringMeetingMutation.reset();

    const payload: UpdateHiringMeetingPayload = {
      send_notifications: sendNotifications,
      hiring_meeting_template: {
        duration_minutes: oldDuration !== newDuration ? newDuration : undefined,
        video_conferencing_enabled: oldVideoConferencingEnabled !== newVideoConferencingEnabled ? newVideoConferencingEnabled : undefined,
        hiring_meeting_attendee_filters: (newHiringMeeting.hiring_meeting_template.hiring_meeting_attendee_filters || []).map((filter) => ({
          hiring_meeting_attendee_filter_expressions: filter.hiring_meeting_attendee_filter_expressions.map((exp) => ({
            negated: exp.negated,
            filterable_id: exp.filterable_id,
            filterable_type: exp.filterable_type,
          })),
        })),
      },
      start_time: oldDate !== newDate || oldStartTime !== newStartTime ? Moment(newTimeSlot.start_time).format() : undefined,
      room_id: oldRoomId !== newRoomId ? newRoomId || '' : undefined,
      zoom_host_id: newVideoConferencingEnabled && account?.video_conferencing_type === VideoConferencingTool.Zoom && oldZoomHostId !== newZoomHostId ? newZoomHostId || undefined : undefined,
      // This is intentionally checking if the Zoom host IDs are equal even though it's for the type parameter cause
      // even if the types don't change, we need to provide a type.
      zoom_host_type: newVideoConferencingEnabled && account?.video_conferencing_type === VideoConferencingTool.Zoom && oldZoomHostId !== newZoomHostId ? newHiringMeeting.zoom_host_type : undefined,
    };

    try {
      const newlyUpdatedHiringMeeting = await updateHiringMeetingMutation.mutateAsync({ id: originalHiringMeeting.id, payload });
      onSuccess(newlyUpdatedHiringMeeting);
      onToggle();
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  return (
    <Modal
      cancelButtonValue="Continue editing"
      isOpen={isOpen}
      isSubmitting={updateHiringMeetingMutation.isLoading}
      onSubmit={handleUpdateHiringMeeting}
      onToggle={handleToggle}
      showSubmitButton={isEventChanged}
      submitButtonValue="Yes, save changes"
      submittingButtonValue="Saving..."
      title="Update hiring meeting?"
    >
      <Flash
        message="There aren't any changes to make on the hiring meeting."
        showFlash={!isEventChanged}
        type="info"
      />
      <Flash
        message={newZoomHostId && newHiringMeeting.zoom_host_type === 'room' && <span>The Zoom link will be hosted by the Zoom Room in <b>{rooms[newZoomHostId]?.name}</b>, but {newRoomId ? <span>the interview will take place in <b>{rooms[newRoomId]?.name}</b></span> : `we will not book the room in ${directoryCalendarLabels[account!.directory_type]}`}.</span>}
        showFlash={!updateHiringMeetingMutation.isError && isEventChanged && newHiringMeeting.zoom_host_type === 'room' && newZoomHostId !== newRoomId}
        type="warning"
      />
      <Flash
        message={updateHiringMeetingMutation.error?.message}
        showFlash={updateHiringMeetingMutation.isError}
        type="danger"
      />
      {newDate !== oldDate &&
        <p>
          You have moved this hiring meeting to a different day: <b>{newDate}</b>.
        </p>
      }
      {newDate === oldDate && newStartTime !== oldStartTime &&
        <p>
          You have moved this hiring meeting to a different time: <b>{newStartTime}</b>.
        </p>
      }
      {newDuration !== oldDuration &&
        <p>
          You have changed this hiring meeting&apos;s duration to be <b>{formatDuration(newDuration)}</b>.
        </p>
      }
      {!newVideoConferencingEnabled && oldVideoConferencingEnabled &&
        <p>
          You have removed video conferencing from this hiring meeting.
        </p>
      }
      {newVideoConferencingEnabled && !oldVideoConferencingEnabled &&
        <p>
          You have added video conferencing to this hiring meeting.
        </p>
      }
      {!Boolean(newRoomId) && Boolean(oldRoomId) &&
        <p>
          You have removed the room from this hiring meeting.
        </p>
      }
      {Boolean(newRoomId) && newRoomId !== oldRoomId &&
        <p>
          This hiring meeting will now take place in <b>{rooms[newRoomId!]?.name}</b>.
        </p>
      }
      {Boolean(newZoomHostId) && Boolean(oldZoomHostId) && newZoomHostId !== oldZoomHostId &&
        <p>
          You have changed the Zoom host to be <b>{newHiringMeeting.zoom_host_type === 'room' ? rooms[newZoomHostId!]?.name : (users[newZoomHostId!]?.name || users[newZoomHostId!]?.email)}</b>.
        </p>
      }
      {!isEmpty(removedAttendees) &&
        <p>
          You have removed {formatAttendeeName(removedAttendees, users)} from the hiring meeting.
        </p>
      }
      {!isEmpty(addedAttendees) &&
        <p>
          You have added {formatAttendeeName(addedAttendees, users)} to the hiring meeting.
        </p>
      }
      {account?.directory_type !== 'microsoft365' && isEventChanged &&
      <div className="update-schedule-options">
        {/* TODO: Consider how to display options: An update email is not sent to the interviewers if the room is removed, but it is sent if the room is added or swapped. But it's confusing because if you choose to update the description, it will send an update email.
        */}
        {/* Microsoft 365 doesn't allow customizing whether to send email notifications or not. It will always send notifications. */}
        <CheckboxInput
          isChecked={sendNotifications}
          label="Send an update email to the attendees."
          onChange={handleSendNotificationsChange}
        />
      </div>
      }
    </Modal>
  );
};

export default UpdateHiringMeetingConfirmModal;
