import classNames from 'classnames';
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMemo, useState } from 'react';

import { ButtonColor, ButtonSize } from 'components/library/inputs/Button';
import { StyledButton } from './styles';
import Tooltip from 'components/library/utils/Tooltip';

interface Props {
  color?: `${ButtonColor}`;
  className?: string;
  id: string;
  size?: `${ButtonSize}`;
  src: string;
  tooltipText?: string;
}

const PlayAudioButton = ({
  color = ButtonColor.NoOutline,
  className,
  id,
  src,
  size = ButtonSize.Large,
  tooltipText = 'Play audio',
}: Props) => {
  const [isPlaying, setIsPlaying] = useState(false);

  const audio = useMemo<HTMLAudioElement>(() => {
    const audioElement = new Audio(src);
    audioElement.addEventListener('play', () => setIsPlaying(true));
    audioElement.addEventListener('pause', () => setIsPlaying(false));
    return audioElement;
  }, [src]);

  const handlePlay = () => {
    audio.pause();
    audio.currentTime = 0;
    audio.play();
  };

  return (
    <StyledButton
      className={classNames('play-audio-button', className, { playing: isPlaying })}
      color={color}
      iconRight={<FontAwesomeIcon icon={faVolumeUp} />}
      onClick={handlePlay}
      size={size}
      tooltip={
        <Tooltip
          id={`${id}-tooltip`}
          position="top"
          value={tooltipText}
        />
      }
    />
  );
};

export default PlayAudioButton;
