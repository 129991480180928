import Moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGlobeAmericas } from '@fortawesome/free-solid-svg-icons';
import { find } from 'lodash';
import { useMemo } from 'react';

import SelectInput from './SelectInput';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from './SelectInput/types';
import type { ReactNode } from 'react';

interface Props {
  brandColor?: string;
  className?: string;
  helperText?: ReactNode;
  isClearable?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  label?: string;
  name?: string;
  onChange: (newValue: OnChangeValue<Option<string>, false>, actionMeta: ActionMeta<Option<string>>) => void;
  placeholder?: string;
  value?: string;
}

const TimezoneSelectInput = ({
  brandColor,
  className,
  helperText,
  isClearable = false,
  isDisabled = false,
  isRequired = false,
  label,
  name,
  onChange,
  placeholder,
  value,
}: Props) => {
  const globeIcon = useMemo(() => (
    <FontAwesomeIcon color={brandColor} icon={faGlobeAmericas} />)
  , [brandColor]);

  const options = useMemo(() => {
    const current = Moment.tz.guess();
    return Moment.tz.names()
    .sort((a, b) => {
      if (a === current) {
        return -1;
      }
      if (b === current) {
        return 1;
      }
      return a.localeCompare(b);
    })
    .map((tz) => ({ label: `${tz} (${Moment().tz(tz).format('Z')})`, value: tz }));
  }, []);

  return (
    <SelectInput
      brandColor={brandColor}
      className={`timezone-select-input${className ? ` ${className}` : ''}`}
      helperText={helperText}
      icon={globeIcon}
      isClearable={isClearable}
      isDisabled={isDisabled}
      isRequired={isRequired}
      label={label}
      name={name}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      value={value ? find(options, ['value', value]) : null}
    />
  );
};

export default TimezoneSelectInput;
