import Moment from 'moment';

import { formatNumberAsWord } from 'libraries/formatters';
import Flash from 'components/library/utils/Flash';
import Modal from 'components/library/layout/Modal';
import { NumberOfOptionsContainer } from './styles';
import OutboundLink from 'components/library/navigation/OutboundLink';
import { useLDFlags } from 'hooks/use-ld-flags';

import type { MouseEventHandler } from 'react';

const MILLISECONDS_TO_MANUALLY_TRY_AN_OPTION = 10;
const MAX_MANUAL_COORDINATION_TIME_MILLISECONDS = 7 /* days */
  * 24 /* hours */
  * 60 /* minutes */
  * 60 /* seconds */
  * 1000 /* milliseconds */;

const ESTIMATED_SECONDS_PER_REQUEST = 20;
const GENERATE_PAGE_SIZE = 1_000_000;

interface Props {
  isOpen: boolean;
  numberOfBlocks: number;
  numberOfOptions: number;
  onToggle: MouseEventHandler<HTMLButtonElement>;
}

const ExplainAlgorithmModal = ({ isOpen, numberOfBlocks, numberOfOptions, onToggle }: Props) => {
  const { progressiveScheduleGeneration } = useLDFlags();

  const manualCoordinationEstimateMilliseconds = numberOfOptions * MILLISECONDS_TO_MANUALLY_TRY_AN_OPTION;
  // Max out estimate at a week, because otherwise, it just feels unbelievable
  const manualCoordinationTimeEstimate = manualCoordinationEstimateMilliseconds > MAX_MANUAL_COORDINATION_TIME_MILLISECONDS ? 'a week' : Moment.duration(manualCoordinationEstimateMilliseconds, 'milliseconds').humanize();

  const calculationTimeEstimate = numberOfOptions / GENERATE_PAGE_SIZE * ESTIMATED_SECONDS_PER_REQUEST;

  return (
    <Modal
      cancelButtonValue="Thanks for explaining!"
      className="explain-algorithm-modal"
      isOpen={isOpen}
      onToggle={onToggle}
      showSubmitButton={false}
      title="What's taking so long?"
    >
      <p>
        With this amount of candidate availability and number of interviews being scheduled, there are:
      </p>
      <NumberOfOptionsContainer>
        <h2>
          {numberOfOptions.toLocaleString()}
        </h2>
        <h6>
          possible {formatNumberAsWord(numberOfBlocks)}-block schedules
        </h6>
      </NumberOfOptionsContainer>
      <p>
        We look at every single combination, to make sure that we never miss a potential zero-conflict schedule. (If you were to check each combination manually, it would take over {manualCoordinationTimeEstimate}!)
      </p>
      {progressiveScheduleGeneration ? (
        <p>
          However, we don&apos;t want to keep you waiting around for too long! If we find a zero-conflict schedule, we&apos;ll stop looking early. If it takes longer than 20 seconds to look through all of the options, we&apos;ll pause and show you what we have found.{numberOfBlocks > 1 && ' We first look at interview combinations that split interviewing time more evenly across blocks to create a better candidate experience. Then, you can choose to have us calculate more options.'}
        </p>
      ) : (
        <p>
          We expect this to take under {Moment.duration(calculationTimeEstimate, 'seconds').humanize()}.
        </p>
      )}
      <Flash
        message={(
          <>
            <p>
              Here are some tips to speed up this step.
            </p>
            <ul>
              <li>
                <b>Narrow down your candidate&apos;s availability</b> in the Availability step if possible. This will make the biggest difference in schedule generation time. For example, you can remove availability that is too far in the future.
              </li>
              {progressiveScheduleGeneration && (
                <li>
                  <b>Set up conflict keywords</b> so we can find a zero-conflict option faster. <OutboundLink href="https://support.gem.com/hc/en-us/articles/23491222224407-How-do-I-get-Gem-Scheduling-to-ignore-certain-conflicts" label="Explain Algorithm Modal - Conflict Keywords Support Article">Learn more.</OutboundLink>
                </li>
              )}
              <li>
                <b>Set scheduling windows</b> if there is candidate availability outside of the hours that your team typically interviews.
              </li>
              <li>
                <b>Don&apos;t forget to set position constraints</b> if any interviews need them.
              </li>
            </ul>
          </>
        )}
        showFlash={numberOfOptions > GENERATE_PAGE_SIZE}
        type="info"
      />
    </Modal>
  );
};

export default ExplainAlgorithmModal;
