import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import BusinessHoursInput from '../../../../../library/inputs/BusinessHoursInput';
import BusinessHoursSummary from '../../../../../library/data-display/BusinessHoursSummary';
import DurationInput from 'components/library/inputs/DurationInput';
import Flash from 'components/library/utils/Flash';
import RollingWindowDaysInput from 'components/library/inputs/RollingWindowDaysInput';
import Section from 'components/library/layout/Section';
import Table from 'components/library/data-display/Table';
import { MAX_ADVANCED_NOTICE_MINUTES, MAX_MINIMUM_DURATION_MINUTES, MAX_TOTAL_AVAILABILITY_MINUTES } from 'components/library/inputs/AvailabilityRequestAdvancedSettings';
import { formatDuration } from 'libraries/formatters';
import { useAvailability, useUpdateAvailability } from 'hooks/queries/availabilities';

import type { AvailabilityTemplate } from 'types';
import type { ChangeEvent } from 'react';
import type { EditableBusinessHour } from '../../../../../../types/business-hours';
import type { TableSchema } from 'components/library/data-display/Table';
import type { UpdateAvailabilityPayload } from 'hooks/queries/availabilities';

const PreferencesSection = () => {
  const { availabilityId } = useParams<{ id: string; availabilityId: string }>();

  const { data: availability } = useAvailability(availabilityId);

  const updateAvailabilityMutation = useUpdateAvailability();

  const [isEditing, setIsEditing] = useState(false);
  const [rollingWindowDays, setRollingWindowDays] = useState(availability?.availability_template!.rolling_window_days);
  const [businessHours, setBusinessHours] = useState<EditableBusinessHour[]>((availability?.availability_template!.business_hours || []).map((bh) => {
    return {
      ...bh,
      start_time: bh.start_time === '00:00' ? '' : bh.start_time,
      end_time: bh.end_time === '24:00' ? '' : bh.end_time,
    };
  }));
  const [advancedNoticeHours, setAdvancedNoticeHours] = useState(availability?.availability_template!.advanced_notice_hours);
  const [minimumDurationMinutes, setMinimumDurationMinutes] = useState(availability?.availability_template!.minimum_duration_minutes);
  const [totalDurationMinutes, setTotalDurationMinutes] = useState(availability?.availability_template!.total_duration_minutes);

  useEffect(() => {
    if (availability?.availability_template) {
      setRollingWindowDays(availability?.availability_template.rolling_window_days);
      setBusinessHours((availability?.availability_template!.business_hours || []).map((bh) => {
        return {
          ...bh,
          start_time: bh.start_time === '00:00' ? '' : bh.start_time,
          end_time: bh.end_time === '24:00' ? '' : bh.end_time,
        };
      }));
      setAdvancedNoticeHours(availability?.availability_template!.advanced_notice_hours);
      setMinimumDurationMinutes(availability?.availability_template!.minimum_duration_minutes);
      setTotalDurationMinutes(availability?.availability_template!.total_duration_minutes);
    }
  }, [availabilityId, Boolean(availability?.availability_template)]);

  const handleRollingWindowDaysChange = (e: ChangeEvent<HTMLInputElement>) => setRollingWindowDays(parseInt(e.target.value, 10));
  const handleAdvancedNoticeHoursChange = (duration: number) => setAdvancedNoticeHours(duration / 60);
  const handleMinimumDurationMinutesChange = (duration: number) => setMinimumDurationMinutes(duration);
  const handleTotalDurationMinutesChange = (duration: number) => setTotalDurationMinutes(duration);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setRollingWindowDays(availability?.availability_template!.rolling_window_days);
    setBusinessHours((availability?.availability_template!.business_hours || []).map((bh) => {
      return {
        ...bh,
        start_time: bh.start_time === '00:00' ? '' : bh.start_time,
        end_time: bh.end_time === '24:00' ? '' : bh.end_time,
      };
    }));
    setAdvancedNoticeHours(availability?.availability_template!.advanced_notice_hours);
    setMinimumDurationMinutes(availability?.availability_template!.minimum_duration_minutes);
    setTotalDurationMinutes(availability?.availability_template!.total_duration_minutes);
    setIsEditing(false);
    updateAvailabilityMutation.reset();
  };

  const handleSave = async () => {
    updateAvailabilityMutation.reset();

    const payload: UpdateAvailabilityPayload = {
      availability_template: {
        rolling_window_days: rollingWindowDays,
        business_hours: businessHours.map((bh) => ({
          day: bh.day,
          start_time: bh.start_time || '00:00',
          end_time: bh.end_time || '24:00',
          // All business hours should have a timezone by this point.
          timezone: bh.timezone!,
        })),
        advanced_notice_hours: advancedNoticeHours,
        minimum_duration_minutes: minimumDurationMinutes,
        total_duration_minutes: totalDurationMinutes,
      },
    };

    try {
      const data = await updateAvailabilityMutation.mutateAsync({ id: availabilityId, payload });
      setRollingWindowDays(data.availability_template?.rolling_window_days);
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  /* eslint-disable react/display-name */
  const schema = useMemo<TableSchema<AvailabilityTemplate>>(() => [{
    header: 'Preferred Date Window',
    displayValue: ({ rolling_window_days }) => `Next ${rolling_window_days} days`,
    displayEditValue: () => (
      <RollingWindowDaysInput
        helperText=""
        max={180}
        min={1}
        onChange={handleRollingWindowDaysChange}
        value={rollingWindowDays!}
      />
    ),
  }, {
    header: 'Allowed Times',
    displayValue: (availabilityTemplate) => <BusinessHoursSummary businessHours={availabilityTemplate.business_hours || []} />,
    displayEditValue: () => (
      <BusinessHoursInput
        businessHours={businessHours}
        isDisabled={!isEditing || updateAvailabilityMutation.isLoading}
        isTimezoneClearable
        label="Allowed Times"
        setBusinessHours={setBusinessHours}
      />
    ),
  }, (isEditing || Boolean(availability?.availability_template?.advanced_notice_hours)) && {
    header: 'Advanced Notice',
    displayValue: ({ advanced_notice_hours }) => `${advanced_notice_hours} hours`,
    displayEditValue: () => (
      <DurationInput
        isHoursOnly
        maxMinutes={MAX_ADVANCED_NOTICE_MINUTES}
        onChange={handleAdvancedNoticeHoursChange}
        value={advancedNoticeHours ? advancedNoticeHours * 60 : undefined}
      />
    ),
  }, (isEditing || Boolean(availability?.availability_template?.minimum_duration_minutes)) && {
    header: 'Minimum Duration',
    displayValue: ({ minimum_duration_minutes }) => `${formatDuration(minimum_duration_minutes!)}`,
    displayEditValue: () => (
      <DurationInput
        maxMinutes={MAX_MINIMUM_DURATION_MINUTES}
        onChange={handleMinimumDurationMinutesChange}
        value={minimumDurationMinutes}
      />
    ),
  }, (isEditing || Boolean(availability?.availability_template?.total_duration_minutes)) && {
    header: 'Total Availability',
    displayValue: ({ total_duration_minutes }) => `At least ${formatDuration(total_duration_minutes!)}`,
    displayEditValue: () => (
      <div className="total-availability-input">
        <span>At least</span>
        <DurationInput
          maxMinutes={MAX_TOTAL_AVAILABILITY_MINUTES}
          onChange={handleTotalDurationMinutesChange}
          value={totalDurationMinutes}
        />
      </div>
    ),
  }], [
    availability?.availability_template,
    rollingWindowDays, handleRollingWindowDaysChange,
    businessHours, setBusinessHours,
    advancedNoticeHours, handleAdvancedNoticeHoursChange,
    minimumDurationMinutes, handleMinimumDurationMinutesChange,
    totalDurationMinutes, handleTotalDurationMinutesChange,
  ]);

  if (!availability?.availability_template) {
    // We check for availability template before rendering this tab.
    return null;
  }

  const isEditable = Boolean(availability?.manual) || !Boolean(availability?.ats_id);
  const advancedNoticeDays = (advancedNoticeHours || 0) / 24;
  const isValidAdvancedNoticeAndRollingWindow = advancedNoticeDays && rollingWindowDays && advancedNoticeDays < rollingWindowDays!;

  return (
    <Section
      className={`preferences-section${isEditing ? ' editing' : ''}`}
      isEditable={isEditable}
      isEditing={isEditing}
      isSaveButtonDisabled={!isValidAdvancedNoticeAndRollingWindow}
      isSaving={updateAvailabilityMutation.isLoading}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Preferences"
    >
      <Flash
        message={updateAvailabilityMutation.error?.message}
        showFlash={updateAvailabilityMutation.isError}
        type="danger"
      />
      <Flash
        isDismissible
        message="Successfully updated!"
        onDismiss={updateAvailabilityMutation.reset}
        showFlash={updateAvailabilityMutation.isSuccess}
        type="success"
      />
      <Flash
        message={<span>Your advanced notice requirement of {advancedNoticeHours} hours (<b>{advancedNoticeDays.toFixed(1)} day{advancedNoticeDays === 1 ? '' : 's'}</b>) exceeds the preferred date window (<b>{rollingWindowDays} day{rollingWindowDays === 1 ? '' : 's'}</b>). Increase the preferred date window, or the candidate will not be able to submit any times.</span>}
        showFlash={Boolean(advancedNoticeHours) && Boolean(rollingWindowDays) && !isValidAdvancedNoticeAndRollingWindow}
        type="warning"
      />
      <Table
        data={[availability.availability_template]}
        isEditing={isEditing}
        layout="horizontal"
        schema={schema}
      />
    </Section>
  );
};

export default PreferencesSection;
