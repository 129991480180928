import Moment from 'moment-timezone';
import { Helmet } from 'react-helmet-async';
import { useEffect, useMemo, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import Banner from '../library/utils/Banner';
import CompanyBrandedPageHeader from '../library/utils/CompanyBrandedPageHeader';
import CompanyBrandingPreviewFlash from '../library/utils/CompanyBrandingPreviewFlash';
import Flash from '../library/utils/Flash';
import InvalidSelfSchedulingRequest from './InvalidSelfSchedulingRequest';
import LoadingSpinner from '../library/utils/LoadingSpinner';
import PoweredByGemFooter from '../library/utils/PoweredByGemFooter';
import SchedulePicker from './SchedulePicker';
import SelfScheduleCancelConfirmModal from './SelfScheduleCancelConfirmModal';
import UpdateQuestion from './UpdateQuestion';
import useHideZendeskWidget from '../../hooks/use-hide-zendesk-widget';
import useSyncStateWithQuery from '../../hooks/use-sync-state-with-query';
import { constructOptionsPayload } from './helpers';
import { decodeSearchParamsForPreview } from '../../libraries/query-string';
import { formatMoment, TimeFormat } from '../../libraries/time';
import { useDeleteSelfSchedulingLinkSchedule, useScheduleOptions, useSelfSchedulingLink } from '../../hooks/queries/self-scheduling-links';

import type { ScheduleOption, SelfSchedulingLink } from '../../types';

const SelfSchedule = () => {
  const location = useLocation();

  const { id } = useParams<{ id: string }>();

  const [month, , setMonth] = useSyncStateWithQuery('month', Moment().format('YYYY-MM'), { stripDefault: false });
  const [fromMonth, setFromMonth] = useState(Moment().format('YYYY-MM'));
  const [timezone, setTimezone] = useState(Moment.tz.guess());

  const selfSchedulingLinkPreview = useMemo(() => id === 'preview' ? decodeSearchParamsForPreview(location.search) as SelfSchedulingLink : undefined, [location.search]);
  const isPreview = Boolean(selfSchedulingLinkPreview);

  const {
    data: selfSchedulingLinkReal,
    error: errorRetrievingSelfSchedulingLink,
    isFetching: isSelfSchedulingLinkFetching,
  } = useSelfSchedulingLink(id, { enabled: !isPreview });

  const selfSchedulingLink = selfSchedulingLinkReal || selfSchedulingLinkPreview;

  /*
   * This takes advanced notice hours into account when setting the default month.
   * When it's the end of the month and there is a minimum advanced notice, the
   * calendar should start on the next month.
  */
  useEffect(() => {
    const advancedNoticeHours = selfSchedulingLink?.schedule_template?.self_scheduling_advanced_notice_hours;
    if (advancedNoticeHours) {
      const initialMonthIso = Moment().tz(timezone).add(advancedNoticeHours, 'hours').format('YYYY-MM');
      setFromMonth(initialMonthIso);
      if (month < initialMonthIso) {
        setMonth(initialMonthIso);
      }
    }
  }, [month, timezone, selfSchedulingLink?.schedule_template?.self_scheduling_advanced_notice_hours]);

  const {
    data: scheduleOptions,
    error: errorRetrievingScheduleOptions,
    isFetching: isScheduleOptionsFetching,
    refetch: scheduleOptionsRefetch,
  } = useScheduleOptions(id, constructOptionsPayload(month, timezone, selfSchedulingLinkPreview), {
    enabled: Boolean(selfSchedulingLink?.application),
    // We set the stale time to 5s so that we refresh the data pretty frequently, but if I'm clicking through the months
    // and quickly click the previous month, it doesn't have to load again. Since the backend checks to make sure the
    // time frame is still available before confirming, it's not super imperative to make sure it's refreshed as often
    // as possible.
    staleTime: 5_000,
  });

  const deleteSelfSchedulingLinkScheduleMutation = useDeleteSelfSchedulingLinkSchedule();

  useHideZendeskWidget();

  const [isSuccess, setIsSuccess] = useState(false);
  const [isCancelModalOpen, setIsCancelModalOpen] = useState(false);
  const [previewStartTime, setPreviewStartTime] = useState('');

  const [startTime, endTime] = useMemo(() => {
    if (previewStartTime) {
      const start = Moment(previewStartTime).tz(timezone);
      const end = Moment(previewStartTime).tz(timezone).add(selfSchedulingLink?.interview_template.duration_minutes, 'minutes');

      return [start, end];
    }

    if (!selfSchedulingLink?.schedule) {
      return [];
    }

    const interviews = selfSchedulingLink.schedule.interviews;
    const firstInterview = interviews[0];
    const lastInterview = interviews[interviews.length - 1];

    const start = Moment(firstInterview.start_time).tz(timezone);
    const end = Moment(lastInterview.start_time).tz(timezone).add(lastInterview.interview_template.duration_minutes, 'minutes');

    return [start, end];
  }, [selfSchedulingLink?.schedule, previewStartTime, timezone]);

  useEffect(() => {
    if (selfSchedulingLink?.schedule) {
      setIsSuccess(true);
    }
  }, [selfSchedulingLink?.schedule]);

  const error = errorRetrievingSelfSchedulingLink || errorRetrievingScheduleOptions;

  // TODO: this might need to be isLoading since we refetch the data to keep it fresh, but that might not be necessary if we make the loading state more inconspicuous
  if (isSelfSchedulingLinkFetching || isSuccess && !startTime) {
    // TODO: handle when just the options are loading better. right now, the whole page flashes with the loading spinner which isn't ideal
    return (
      <div className="self-scheduling-container loading">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return <InvalidSelfSchedulingRequest isNotFound={error.status === 404} />;
  }

  if (selfSchedulingLink?.status === 'cancelled' || !selfSchedulingLink?.application || selfSchedulingLink?.application.status !== 'active' || (selfSchedulingLink?.stage_id && selfSchedulingLink.stage_id !== selfSchedulingLink.application.current_stage_id)) {
    return <InvalidSelfSchedulingRequest isCancelled />;
  }

  const handleRescheduleButtonClick = () => {
    // In case it's been a while since we fetched this information, we should
    // refetch it.
    scheduleOptionsRefetch();
    setIsSuccess(false);
  };

  const handleCancelButtonClick = () => {
    setIsCancelModalOpen(true);
  };

  const handleCancelModalToggle = () => {
    setIsCancelModalOpen((prev) => !prev);
  };

  const handleCancel = async () => {
    if (isPreview) {
      setIsCancelModalOpen(false);
      setIsSuccess(false);
      setPreviewStartTime('');
      return;
    }

    if (!selfSchedulingLink?.schedule?.id) {
      return;
    }

    deleteSelfSchedulingLinkScheduleMutation.reset();

    try {
      await deleteSelfSchedulingLinkScheduleMutation.mutateAsync({ id: selfSchedulingLink.id, scheduleId: selfSchedulingLink.schedule.id });
      setIsCancelModalOpen(false);
      setIsSuccess(false);
      // In case it's been a while since we fetched this information, we should
      // refetch it.
      scheduleOptionsRefetch();
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  // Normally, isSuccess gets set by the presence of a schedule on the link, but
  // in the case of previews, that will never happen, so this is only used for
  // that.
  const handleSuccess = (scheduleOption: ScheduleOption) => {
    setIsSuccess(true);
    setPreviewStartTime(scheduleOption.start_time);
  };

  // TODO: Change to stage name when we allow self-scheduling for back-to-back interviews.
  const interviewName = selfSchedulingLink.interview_template.candidate_facing_name || selfSchedulingLink.stage_interview.name;

  return (
    <div className="self-scheduling-container">
      <Helmet>
        <title>{selfSchedulingLink.account.name ? `${selfSchedulingLink.account.name} | ` : ''}Schedule Interview</title>
      </Helmet>
      {isPreview && (
        <Banner>
          This is a preview of <b>{selfSchedulingLink.application.candidate.name}</b>&apos;s self-scheduling link for <b>{interviewName}</b>. This schedule will not be saved.
        </Banner>
      )}
      <CompanyBrandedPageHeader
        logoUrl={selfSchedulingLink.account.logo_url}
        message={selfSchedulingLink.account.availability_message_in_self_scheduling ? selfSchedulingLink.account.availability_message : undefined}
      />
      <div className="flash-container">
        {isPreview && (
          <CompanyBrandingPreviewFlash
            account={selfSchedulingLink.account}
            page="schedule"
          />
        )}
        <Flash
          isDismissible
          message="Your interview has successfully been cancelled."
          showFlash={deleteSelfSchedulingLinkScheduleMutation.isSuccess}
          type="success"
        />
        <Flash
          brandColor={selfSchedulingLink.account.color}
          message={(
            <span>
              Hi, {selfSchedulingLink.application.candidate.name?.split(' ')[0]}! {selfSchedulingLink.schedule ? 'Reschedule' : 'Book'} your <b>{interviewName}</b> interview below.
            </span>
          )}
          showFlash={!isSuccess}
          type="info"
        />
        <Flash
          message={isSuccess && startTime && endTime ? (
            <span>
              You have successfully booked your <b>{interviewName}</b> for <b>{formatMoment(startTime, TimeFormat.LongDayOfWeekMonthDay)}</b> at <b>{formatMoment(startTime, TimeFormat.Time)}&ndash;{formatMoment(endTime, TimeFormat.TimeWithTimezone)}</b>.
            </span>
          ) : null}
          showFlash={isSuccess}
          type="success"
        />
        <Flash
          message="This is a preview. We don't save this schedule anywhere."
          showFlash={isSuccess && isPreview}
          type="info"
        />
      </div>
      {isSuccess && endTime && startTime ? (
        <div className="update-questions">
          <UpdateQuestion
            action="reschedule"
            brandColor={selfSchedulingLink.account.color}
            enabled={selfSchedulingLink.schedule_template.self_scheduling_reschedule_enabled}
            endTime={endTime}
            noticeHours={selfSchedulingLink.schedule_template.self_scheduling_reschedule_notice_hours}
            onButtonClick={handleRescheduleButtonClick}
            startTime={startTime}
          />
          <UpdateQuestion
            action="cancel"
            brandColor={selfSchedulingLink.account.color}
            enabled={selfSchedulingLink.schedule_template.self_scheduling_cancel_enabled}
            endTime={endTime}
            noticeHours={selfSchedulingLink.schedule_template.self_scheduling_cancel_notice_hours}
            onButtonClick={handleCancelButtonClick}
            startTime={startTime}
          />
          <SelfScheduleCancelConfirmModal
            brandColor={selfSchedulingLink.account.color}
            endTime={endTime}
            isOpen={isCancelModalOpen}
            isSubmitting={deleteSelfSchedulingLinkScheduleMutation.isLoading}
            onDelete={handleCancel}
            onToggle={handleCancelModalToggle}
            startTime={startTime}
          />
        </div>
      ) : (
        <div className="schedule-picker-container">
          <SchedulePicker
            brandColor={selfSchedulingLink.account.color}
            fromMonth={fromMonth}
            isLoading={isScheduleOptionsFetching}
            isPreview={isPreview}
            isRescheduling={Boolean(selfSchedulingLink.schedule)}
            month={month}
            onSuccess={handleSuccess}
            scheduleOptions={scheduleOptions}
            selfSchedulingLink={selfSchedulingLink}
            setMonth={setMonth}
            setTimezone={setTimezone}
            timezone={timezone}
          />
        </div>
      )}
      <PoweredByGemFooter />
    </div>
  );
};

export default SelfSchedule;
