import Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { useMediaQuery } from 'react-responsive';
import { useMemo, useState } from 'react';

import ArchiveButton from '../../library/inputs/ArchiveButton';
import Flash from '../../library/utils/Flash';
import Label from '../../library/utils/Label';
import SelectInput from '../../library/inputs/SelectInput';
import Table from '../../library/data-display/Table';
import Tag from '../../library/data-display/Tag';
import { VideoConferencingTool } from '../../../types';
import { formatMoment, TimeFormat } from '../../../libraries/time';
import { useSession } from '../../../hooks/use-session';
import { useUpdateRoom } from '../../../hooks/queries/rooms';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { ChangeEvent } from 'react';
import type { Option } from '../../library/inputs/SelectInput/types';
import type { Room, RoomTag } from '../../../types';
import type { UpdateRoomPayload } from '../../../hooks/queries/rooms';

interface Props {
  isEditing: boolean;
  onPageNumberChange?: (pageNumber: number) => void;
  onShowArchivedChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onTagsChange: (newValue: OnChangeValue<Option<string>, true>, actionMeta: ActionMeta<Option<string>>) => void;
  pageNumber: number;
  roomPayloads: Record<string, UpdateRoomPayload>;
  rooms: Room[];
  showArchived: boolean;
  tagOptions: Option<string>[];
  totalCount?: number;
}

const RoomsTable = ({
  isEditing,
  onPageNumberChange,
  onShowArchivedChange,
  onTagsChange,
  pageNumber,
  showArchived,
  tagOptions,
  totalCount,
  roomPayloads,
  rooms,
}: Props) => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1400px)' });

  const { account } = useSession();
  const hasZoomIntegration = account?.video_conferencing_type === VideoConferencingTool.Zoom;

  const [lastAction, setLastAction] = useState({
    id: '',
    isUndo: false,
    type: '',
  });

  const updateRoomMutation = useUpdateRoom();

  const handleArchive = async (id: string, undo: boolean) => {
    setLastAction({
      id,
      type: 'archive',
      isUndo: undo,
    });
    updateRoomMutation.reset();
    updateRoomMutation.mutate({ id, payload: { archived: true } });
  };

  const handleUnarchive = async (id: string, undo: boolean) => {
    setLastAction({
      id,
      type: 'unarchive',
      isUndo: undo,
    });
    updateRoomMutation.reset();
    updateRoomMutation.mutate({ id, payload: { archived: false } });
  };

  const handleUndo = () => {
    if (lastAction.type === 'archive') {
      handleUnarchive(lastAction.id, true);
    }
    if (lastAction.type === 'unarchive') {
      handleArchive(lastAction.id, true);
    }
  };

  /* eslint-disable react/display-name, react/prop-types */
  const columns = useMemo(() => [{
    header: 'Name',
    displayValue: ({ directory_archived, name, user_archived }: Room) => (
      <div className="rooms-table-name">
        {name}
        {(user_archived || directory_archived) && <Label color="gray">Archived</Label>}
      </div>
    ),
  }, hasZoomIntegration && {
    header: 'Zoom Room',
    displayValue: ({ video_conferencing_id }: Room) => (
      video_conferencing_id ?
        <FontAwesomeIcon className="check" icon={faCheck} /> :
        <span className="empty">&mdash;</span>
    ),
  }, {
    header: 'Tags',
    displayValue: ({ room_tags }: Room) => (
      <div className="rooms-tag-container">
        {room_tags ? room_tags.map((tag) => <Tag key={tag.id} type="tag" value={tag.id} />) : <span className="unset">No tags</span>}
      </div>
    ),
    displayEditValue: ({ id, room_tags }: Room) => (
      <div onClick={(e) => e.stopPropagation()}>
        <SelectInput
          className="select-input-rooms-tag"
          formatCreateLabel={(value) => `Add new tag "${value}"`}
          formatOptionLabel={(option) => {
            // The type for formatOptionLabel isn't correct. Fix this once
            // https://github.com/JedWatson/react-select/issues/5064 is addressed.
            const { __isNew__, label, value } = option as any;
            return (
              __isNew__ ?
                label :
                <Tag
                  isNegated={false}
                  type="tag"
                  value={value}
                />
            );
          }}
          isClearable
          isCreatable
          isMulti
          name={id}
          onChange={onTagsChange}
          options={tagOptions}
          placeholder="Add tags"
          value={(roomPayloads[id]?.room_tags || room_tags || []).map((tag) => {
            const value: string = (tag as RoomTag).id || tag as string;
            return { label: value, value };
          })}
        />
      </div>
    ),
  }, !isSmallScreen && {
    header: 'Date Created',
    displayValue: ({ created_at }: Room) => <span className="date">{formatMoment(Moment(created_at), TimeFormat.LongMonthDayYear)}</span>,
  }, {
    header: 'Actions',
    isClickable: true,
    displayValue: ({ directory_archived, id, user_archived }: Room) => (
      <div className="action-buttons-container">
        <ArchiveButton
          id={`${id}-archive-button`}
          isArchived={user_archived || directory_archived}
          onArchive={() => handleArchive(id, false)}
          onUnarchive={() => handleUnarchive(id, false)}
        />
      </div>
    ),
  }], [
    handleArchive,
    handleUnarchive,
    hasZoomIntegration,
    isSmallScreen,
    onTagsChange,
    roomPayloads,
    tagOptions,
  ]);

  return (
    <div className={`rooms-table-container${hasZoomIntegration ? ' zoom-rooms' : ''}`}>
      <Flash
        isDismissible
        message={(
          <span>
            Room {lastAction.type}d.&nbsp;
            {!lastAction.isUndo && <a href="#" onClick={handleUndo}>Undo?</a>}
          </span>
        )}
        showFlash={lastAction.id ? updateRoomMutation.isSuccess : false}
        type="success"
      />
      <Flash
        message={updateRoomMutation.error?.message}
        showFlash={updateRoomMutation.isError}
        type="danger"
      />
      <Table
        data={rooms}
        dataDescriptor="rooms"
        isEditing={isEditing}
        isPaginated
        layout="vertical"
        onPageNumberChange={onPageNumberChange}
        onShowArchivedChange={onShowArchivedChange}
        pageNumber={pageNumber}
        schema={columns}
        showArchived={showArchived}
        totalCount={totalCount}
      />
    </div>
  );
};

export default RoomsTable;
