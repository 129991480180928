import styled from 'styled-components';

import Section from '../../../library/layout/Section';
import SelectInput from '../../../library/inputs/SelectInput';

export const StyledSection = styled(Section)`
  .flash {
    margin-bottom: 1em;
  }
`;

// The typing for this is weird because of styled-components. This is inspired from
// https://github.com/styled-components/styled-components/issues/1803#issuecomment-857092410
export const StyledEligibilitySelectInput = styled(SelectInput)`
  margin: 1em 0;
` as typeof SelectInput;

export const StyledEligibilityOptionLabel = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  width: 100%;
`;

export const StyledSubmitButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin: 1em 0;
`;
