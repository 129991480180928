import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize } from 'lodash';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

import Avatar from '../data-display/Avatar';
import OutboundLink from '../navigation/OutboundLink';
import Tooltip from '../utils/Tooltip';
import { chatLogos } from '../../../types';
import { constructChatHref } from '../../../libraries/candidates';
import { useSession } from '../../../hooks/use-session';

import type { ChatHrefType, ChatIds } from '../../../libraries/candidates';

interface Props {
  chatIds: ChatIds;
  tooltipValue?: string;
  type: `${ChatHrefType}`;
}

const OpenInChatButton = ({ chatIds, tooltipValue, type }: Props) => {
  const { account } = useSession();

  if (!account?.chat_type) {
    return null;
  }

  if (!chatIds.hiringChannelChatId) {
    // If there is no hiring channel ID, don't show this button. If we add more href types, this logic will need to
    // change.
    return null;
  }

  if (chatIds.hiringChannelChatId === '[REDACTED]') {
    // This candidate had a hiring channel, but the ID has since been redacted, so don't show this button.
    return null;
  }

  return (
    <>
      <OutboundLink
        className="btn btn-large btn-gray"
        externalLinkIcon={false}
        href={constructChatHref(account, type, chatIds)}
        label={`Open in Chat - ${capitalize(type)}`}
        tooltip={(
          <Tooltip
            helperText={<FontAwesomeIcon icon={faExternalLinkAlt} />}
            id="chat-button"
            position="top"
            value={tooltipValue || `Open in ${capitalize(account.chat_type)}`}
          />
        )}
      >
        <Avatar
          alt={capitalize(account.chat_type)}
          img={chatLogos[account.chat_type]}
          size="small"
          type="company"
        />
      </OutboundLink>
    </>
  );
};

export default OpenInChatButton;
