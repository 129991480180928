import type { ApplicationStatus } from './applications';
import type { InterviewBatch } from './interview-batches';
import type { EditableInterviewTemplate, InterviewTemplate as CoreInterviewTemplate } from './interview-templates';
import type { FeedbackForm } from './feedback-forms';
import type { EditableScheduleTemplate, ScheduleTemplate as CoreScheduleTemplate } from './schedule-templates';
import type { ZoomHostFilterableType } from './schedule-templates';

export enum SelfSchedulingLinkStatus {
  Cancelled = 'cancelled',
  Requested = 'requested',
  Completed = 'completed',
}

export interface SelfSchedulingLink {
  id: string;
  account_id: string;
  account: Account;
  application_id?: string;
  application?: Application;
  stage_id: string;
  stage: Stage;
  stage_interview_id: string;
  stage_interview: StageInterview;
  interview_template_id: string;
  interview_template: CoreInterviewTemplate;
  feedback_form_id?: string;
  feedback_form: FeedbackForm;
  schedule_template_id: string;
  schedule_template: CoreScheduleTemplate;
  interview_batch_id?: string;
  interview_batch?: InterviewBatch;
  scheduling_calendar_email: string;
  candidate_scheduling_calendar_email: string;
  creator_id: string;
  automatic_send_log_id?: string;
  schedule?: Schedule;
  status: SelfSchedulingLinkStatus;
  created_at: string;
  updated_at: string;
}

export interface SelfSchedulingLinkPreview {
  application_id: string;
  account: {
    name?: string;
    color?: string;
    logo_url?: string;
    availability_message?: string;
    availability_message_in_self_scheduling?: boolean;
  };
  application: {
    candidate: {
      name?: string;
      email?: string;
    };
    status: `${ApplicationStatus}`;
  };
  interview_template: {
    duration_minutes: number;
    candidate_facing_name?: string;
    candidate_facing_details?: string;
    interviewer_templates?: {
      optional: boolean;
      interviewer_filters: {
        interviewer_filter_expressions: {
          negated: boolean;
          filterable_id: string;
          filterable_type: string;
        }[];
      }[];
    }[];
  };
  schedule_template: {
    business_hours: {
      day: string;
      start_time: string;
      end_time: string;
      timezone: string;
    }[];
    allowed_times: {
      start_time: string;
      end_time: string;
    }[];
    scheduling_interval_minutes: number;
    video_conferencing_enabled: boolean;
    zoom_host_filters?: {
      zoom_host_filter_expressions: {
        filterable_id: string;
        filterable_type: `${ZoomHostFilterableType}`;
        negated: boolean;
      }[];
    }[];
    self_scheduling_advanced_notice_hours: number;
    self_scheduling_reschedule_enabled: boolean;
    self_scheduling_reschedule_notice_hours?: number;
    self_scheduling_cancel_enabled: boolean;
    self_scheduling_cancel_notice_hours?: number;
  };
  stage: {
    name: string;
  };
  stage_interview: {
    name: string;
  };
  status: `${SelfSchedulingLinkStatus}`;
}

interface Account {
  id: string;
  name?: string;
  color?: string;
  logo: boolean;
  logo_url?: string;
  availability_message?: string;
  availability_message_in_self_scheduling: boolean;
}

interface Stage {
  id: string;
  name: string;
  job_id: string;
  job: Job;
  schedule_template_id?: string;
  schedule_template: ScheduleTemplate;
  stage_interviews: StageInterview[] | null;
}

interface Job {
  id: string;
  name: string;
  post_name?: string;
}

interface StageInterview {
  id: string;
  name: string;
  position: number;
  interview_template?: InterviewTemplate;
  inline: boolean;
  deleted: boolean;
}

interface ScheduleTemplate {
  id: string;
  self_scheduling_reschedule_enabled: boolean;
  self_scheduling_reschedule_notice_hours?: number;
  self_scheduling_cancel_enabled: boolean;
  self_scheduling_cancel_notice_hours?: number;
}

interface Application {
  id: string;
  candidate_id: string;
  candidate: Candidate;
  current_stage_id?: string;
  status: ApplicationStatus;
}

interface Candidate {
  id: string;
  name?: string;
  email?: string;
}

interface Schedule {
  id: string;
  candidate_event_location?: string;
  self_scheduling_link_id?: string;
  interviews: SelfSchedulingLinkInterview[];
  created_at: string;
}

export interface SelfSchedulingLinkInterview {
  id: string;
  schedule_id: string;
  stage_interview_id: string;
  interview_template_id: string;
  interview_template: InterviewTemplate;
  live_coding_url?: string;
  start_time: string;
  interviewers?: Interviewer[];
  created_at: string;
}

interface InterviewTemplate {
  id: string;
  duration_minutes: number;
  candidate_facing_name?: string;
  candidate_facing_details?: string;
}

interface Interviewer {
  id: string;
  interview_id: string;
  interviewer_template_id: string;
  user_id: string;
  created_at: string;
}

export interface ScheduleOption {
  room_id?: string;
  zoom_host?: {
    id: string;
    type: 'room' | 'user';
  };
  start_time: string;
  end_time: string;
  interviewers?: ScheduleOptionInterviewer[];
  interview_batch_time_slot_id?: string;
  interview_template_id?: string;
}

interface ScheduleOptionInterviewer {
  user_id: string;
  interviewer_template_id: string;
}

export type EditableSelfSchedulingLink = Omit<SelfSchedulingLink, 'interview_template' | 'schedule_template'> & {
  interview_template: EditableInterviewTemplate;
  schedule_template: EditableScheduleTemplate;
};
