import type { QueryClient } from 'react-query';
import { useMutation, useQueryClient } from 'react-query';

import InterviewPlanner from '../../libraries/interviewplanner';
import { FavoriteResourceType } from '../../types';
import { QueryKey as ApplicationQueryKey } from './applications';
import { QueryKey as CalendarEventTemplateQueryKey } from './calendar-event-templates';
import { QueryKey as EmailTemplateQueryKey } from './email-templates';
import { QueryKey as HiringMeetingTemplateQueryKey } from './hiring-meeting-templates';
import { QueryKey as InterviewTemplateQueryKey } from './interview-templates';
import { QueryKey as JobQueryKey } from './jobs';

import type { Favorite } from '../../types';

export interface CreateFavoritePayload {
  resource_id: string;
  resource_type: string;
}

interface CreateFavoriteMutationVariables {
  payload?: CreateFavoritePayload;
}

export const useCreateFavorite = () => {
  const queryClient = useQueryClient();

  return useMutation<Favorite, Error, CreateFavoriteMutationVariables>(({ payload }) => {
    return InterviewPlanner.request('POST', '/favorites', payload);
  }, {
    onSuccess: (data) => {
      invalidateQueries(queryClient, data.resource_id, data.resource_type);
    },
  });
};

interface DeleteFavoriteMutationVariables {
  id: string;
  type: FavoriteResourceType;
}

interface DeleteFavoriteData {
  success: boolean;
}

export const useDeleteFavorite = () => {
  const queryClient = useQueryClient();

  return useMutation<DeleteFavoriteData, Error, DeleteFavoriteMutationVariables>(({ id, type }) => {
    return InterviewPlanner.request('DELETE', `/favorites/${type}/${id}`);
  }, {
    onSuccess: (data, { id, type }) => {
      invalidateQueries(queryClient, id, type);
    },
  });
};

function invalidateQueries (queryClient: QueryClient, resourceId: string, resourceType: FavoriteResourceType) {
  switch (resourceType) {
    case FavoriteResourceType.Application:
      queryClient.invalidateQueries([ApplicationQueryKey.ListApplications]);
      queryClient.invalidateQueries([ApplicationQueryKey.RetrieveApplication, resourceId]);
      break;
    case FavoriteResourceType.CalendarEventTemplate:
      queryClient.invalidateQueries([CalendarEventTemplateQueryKey.ListCalendarEventTemplates]);
      queryClient.invalidateQueries([CalendarEventTemplateQueryKey.RetrieveCalendarEventTemplate, resourceId]);
      break;
    case FavoriteResourceType.EmailTemplate:
      queryClient.invalidateQueries([EmailTemplateQueryKey.ListEmailTemplates]);
      queryClient.invalidateQueries([EmailTemplateQueryKey.RetrieveEmailTemplate, resourceId]);
      break;
    case FavoriteResourceType.HiringMeetingTemplate:
      queryClient.invalidateQueries([HiringMeetingTemplateQueryKey.ListHiringMeetingTemplates]);
      queryClient.invalidateQueries([HiringMeetingTemplateQueryKey.RetrieveHiringMeetingTemplate, resourceId]);
      break;
    case FavoriteResourceType.InterviewTemplate:
      queryClient.invalidateQueries([InterviewTemplateQueryKey.ListInterviewTemplates]);
      queryClient.invalidateQueries([InterviewTemplateQueryKey.RetrieveInterviewTemplate, resourceId]);
      break;
    case FavoriteResourceType.Job:
      queryClient.invalidateQueries([JobQueryKey.ListJobs]);
      queryClient.invalidateQueries([JobQueryKey.RetrieveJob, resourceId]);
      break;
  }
}
