import styled from 'styled-components';

export const StyledAssignmentBanner = styled.div`
  background: ${({ theme }) => theme.colors.BLUES.BLUE_50};
  border-bottom: 1px solid ${({ theme }) => theme.colors.BLUES.BLUE_100};
  justify-content: start !important;
  padding: 1em 2em !important;
  position: sticky;
  top: 0;
  z-index: 10;
`;

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledNoteContainer = styled.div`
  margin-top: 12px;
`;
