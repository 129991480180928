import { createContext, useContext, useMemo, useState } from 'react';
import { isEmpty, mergeWith } from 'lodash';

import { EventsLoader } from './dataloader';

import type { EventsCache, ListEventsQuery } from './types';
import type { ReactNode } from 'react';

export interface EventsContextState {
  // This is the global event cache that can be accessed from any secure route.
  // As events are fetched using fetchEvents, this will automatically be
  // populated. This should stay stable between renders and will only change as
  // a result of fetchEvents.
  events: EventsCache;
  // This allows you to fetch new events. Ensure the query contains all the IDs
  // that you want to fetch so that they can be fetched together to reduce the
  // number of round-trips to the API. You don't have to worry about checking if
  // an ID has already been fetched. Cache-checking is handled within this
  // function.
  fetchEvents: (query: ListEventsQuery) => Promise<void>;
  // If you have new events from another endpoint, and you want to set it in the
  // cache, call this function with the new events. An example is when you
  // generate schedules and it returns the events that it used in the generation
  // process.
  setEvents: (newEvents: EventsCache) => void;
}

const EventsContext = createContext<EventsContextState>({
  events: {},
  fetchEvents: async () => {},
  setEvents: () => {},
});

interface Props {
  children: ReactNode;
}

export const EventsProvider = ({ children }: Props) => {
  const [events, setEvents] = useState<EventsCache>({});

  const contextValue = useMemo<EventsContextState>(() => ({
    events,
    fetchEvents: async (query) => {
      if (isEmpty(query.user_ids) && isEmpty(query.room_ids) && isEmpty(query.zoom_host_ids)) {
        // We're not asking for any new data, so just return early.
        return;
      }

      // Convert the single large query into several single ID queries.
      const queries = transformToSeparateQueries(query);

      // Fetch from the dataloader. If an ID already exists in the cache, it
      // won't request that data.
      const results = await Promise.all(queries.map(async (query) => EventsLoader.load(query)));

      // Merge all of the responses together so that it's easier to merge it
      // into the state.
      const newEvents = results.reduce<EventsCache>((acc, result) => {
        return mergeWith(acc, result, eventMergerFunction);
      }, {});

      // Merge the responses into the state.
      setEvents((prev) => mergeWith({}, prev, newEvents, eventMergerFunction));
    },
    setEvents: (newEvents) => {
      // Go through the new events that we want to set in the state and prime
      // the dataloader cache. Without this, the dataloader won't know that we
      // already have this data loaded, so it will attempt to make the request
      // again.
      Object.keys(newEvents).forEach((date) => {
        Object.keys(newEvents[date]).forEach((timezone) => {
          // This a bit too "clever", but it avoids having to construct these
          // large objects 3 different times for each resource. If this gets too
          // complicated to maintain, just break out the loop so it's more
          // clear.
          ['user', 'room', 'zoom_host'].forEach((resource) => {
            const pluralResource = `${resource}s` as 'users' | 'rooms' | 'zoom_hosts';
            const resourceWithIds = `${resource}_ids` as 'user_ids' | 'room_ids' | 'zoom_host_ids';

            Object.values(newEvents[date][timezone][pluralResource]).forEach((item) => {
              const key: ListEventsQuery = {
                date,
                timezone,
                [resourceWithIds]: [item.id],
              };
              const value: EventsCache = {
                [date]: {
                  [timezone]: {
                    users: {},
                    rooms: {},
                    zoom_hosts: {},
                    [pluralResource]: { [item.id]: item },
                  },
                },
              };

              EventsLoader.prime(key, value);
            });
          });
        });
      });

      // Actually set the state.
      setEvents((prev) => mergeWith({}, prev, newEvents, eventMergerFunction));
    },
  }), [events]);

  return (
    <EventsContext.Provider value={contextValue}>
      {children}
    </EventsContext.Provider>
  );
};

export const useEvents = () => {
  return useContext(EventsContext);
};

/**
 * This takes in a single List Events query with many IDs set to be fetched, and
 * it returns an array of List Events queries that only has a single ID
 * specified. These broken down versions of the queries are what are used to
 * cache the individual parts of data.
 *
 * @param query The large query that represents all the data that needs to be
 * fetched.
 */
const transformToSeparateQueries = (query: ListEventsQuery): ListEventsQuery[] => {
  const qs: ListEventsQuery[] = [];
  query.user_ids?.forEach((id) => {
    if (!id) {
      return;
    }
    qs.push({
      date: query.date,
      timezone: query.timezone,
      user_ids: [id],
    });
  });
  query.room_ids?.forEach((id) => {
    if (!id) {
      return;
    }
    qs.push({
      date: query.date,
      timezone: query.timezone,
      room_ids: [id],
    });
  });
  query.zoom_host_ids?.forEach((id) => {
    if (!id) {
      return;
    }
    qs.push({
      date: query.date,
      timezone: query.timezone,
      zoom_host_ids: [id],
    });
  });
  return qs;
};

/**
 * The function that we can use with Lodash's `mergeWith` so that event and meeting arrays aren't merged together. This
 * is to prevent the case where there was an event before, but it was cancelled, so in the new response, that event is
 * gone, but the old cached data, the event is there. With the default merging, we will still end up with the cancelled
 * event in the list of events when it shouldn't be.
 *
 * This function checks if the key is either 'events' or 'meetings' (since `key` is just the last part of the full path
 * of the key), and if it is, prefer the `srcValue`. In Lodash's nomenclature, the `obj` is the leftmost object passed
 * into `mergeWith` while the `src` is the rightmost one. Since we pass in cached data to be overwritten on the left and
 * new data to be preferred on the right, we're returning `srcValue`.
 *
 * @param objValue The value of the leftmost object of the `mergeWith` call.
 * @param srcValue The value of the rightmost object of the `mergeWith` call.
 * @param key The last segment of the path to this object.
 */
const eventMergerFunction = (objValue: any, srcValue: any, key: string): any => {
  if (key === 'events' || key === 'meetings') {
    return srcValue;
  }
  return undefined;
};
