import styled from 'styled-components';

export const StyledContainer = styled.div`
  .fa-star {
    color: ${({ theme }) => theme.colors.GRAYS.GRAY_400};

    &.filled {
      color: ${({ theme }) => theme.colors.YELLOWS.YELLOW_300};
    }
  }
  
  &:not(.disabled) {
    cursor: pointer;

    .fa-star {
      &:hover {
        color: ${({ theme }) => theme.colors.PRODUCT.SECONDARY_CONTENT};
      }

      &.filled {
        &:hover {
          color: ${({ theme }) => theme.colors.YELLOWS.YELLOW_400};
        }
      }
    }
  }
`;
