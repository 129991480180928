import classNames from 'classnames';
import { escape } from 'lodash';
import { Fragment } from 'react';

import { StyledContainer, StyledNavLink, StyledSecondaryText } from './styles';

import type { ReactNode } from 'react';

interface Props {
  links: {
    label: ReactNode;
    secondaryText?: ReactNode;
    location: string;
    isActive?: boolean;
    isDisabled?: boolean;
    onClick?: () => void;
    tooltip?: JSX.Element;
  }[];
  showActiveStyle?: boolean;
}

const NavbarLinks = ({ links, showActiveStyle = true }: Props) =>
  <StyledContainer className="navbar-links">
    {links.map((link, i) =>
      <Fragment key={`${escape(link.location)}-${i}`}>
        <StyledNavLink
          activeClassName={classNames({ active: showActiveStyle })}
          className={classNames({ disabled: link.isDisabled })}
          data-for={link.tooltip ? link.tooltip.props.id : undefined}
          data-tip={link.tooltip ? true : undefined}
          isActive={(match) => {
            if (link.isActive !== undefined) {
              return link.isActive;
            }
            return Boolean(match);
          }}
          onClick={(e) => {
            if (link.isDisabled) {
              e.preventDefault();
            } else {
              link.onClick?.();
            }
          }}
          to={link.location}
        >
          {link.label}
          {link.secondaryText && (
            <StyledSecondaryText>{link.secondaryText}</StyledSecondaryText>
          )}
        </StyledNavLink>
        {link.tooltip}
      </Fragment>
    )}
  </StyledContainer>
;

export default NavbarLinks;
