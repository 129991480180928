import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import AutomaticRequesterSelectInput from '../../../../../../library/inputs/AutomaticRequesterSelectInput';
import CheckboxInput from '../../../../../../library/inputs/CheckboxInput';
import DurationInput from '../../../../../../library/inputs/DurationInput';
import ExpandableCheckboxInput from '../../../../../../library/inputs/ExpandableCheckboxInput';
import Flash from '../../../../../../library/utils/Flash';
import RollingWindowDaysInput from '../../../../../../library/inputs/RollingWindowDaysInput';
import Section from '../../../../../../library/layout/Section';
import Tooltip from '../../../../../../library/utils/Tooltip';
import { MAX_ADVANCED_NOTICE_MINUTES, MAX_MINIMUM_DURATION_MINUTES, MAX_TOTAL_AVAILABILITY_MINUTES } from '../../../../../../library/inputs/AvailabilityRequestAdvancedSettings';
import { SentinelTimes } from '../../../../../../../types/business-hours';
import { useSession } from '../../../../../../../hooks/use-session';
import { useStage, useUpdateStage } from '../../../../../../../hooks/queries/stages';

import type { ChangeEvent } from 'react';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../../../../../library/inputs/SelectInput/types';
import { correctPath } from 'libraries/gem';

const PreferencesSection = () => {
  const { id: jobId, stageId } = useParams<{ id: string; stageId: string }>();

  const { currentUser } = useSession();

  const stage = useStage(jobId, stageId).data!;

  const [automaticSendsEnabled, setAutomaticSendsEnabled] = useState(Boolean(stage.availability_template?.automatic_sends_enabled_at) || false);
  const [automaticRequester, setAutomaticRequester] = useState(stage.availability_template?.automatic_requester || currentUser?.id || '');
  const [rollingWindowDays, setRollingWindowDays] = useState<string>(stage.availability_template?.rolling_window_days.toString() || '14');
  const [advancedNoticeHours, setAdvancedNoticeHours] = useState(stage.availability_template?.advanced_notice_hours || 24);
  const [minimumDurationMinutes, setMinimumDurationMinutes] = useState(stage.availability_template?.minimum_duration_minutes || 0);
  const [totalDurationMinutes, setTotalDurationMinutes] = useState(stage.availability_template?.total_duration_minutes || 0);
  const [suggestTimes, setSuggestTimes] = useState(stage.availability_template?.suggest_times || false);
  const [isEditing, setIsEditing] = useState(false);

  const updateStageMutation = useUpdateStage();

  useEffect(() => {
    setAutomaticSendsEnabled(Boolean(stage.availability_template?.automatic_sends_enabled_at) || false);
    setAutomaticRequester(stage.availability_template?.automatic_requester || currentUser?.id || '');
    setRollingWindowDays(stage.availability_template?.rolling_window_days.toString() || '14');
    setAdvancedNoticeHours(stage.availability_template?.advanced_notice_hours || 24);
    setSuggestTimes(stage.availability_template?.suggest_times || false);
    setMinimumDurationMinutes(stage.availability_template?.minimum_duration_minutes || 0);
    setTotalDurationMinutes(stage.availability_template?.total_duration_minutes || 0);
  }, [stage.availability_template]);

  const handleAutomaticSendEnabledChange = (e: ChangeEvent<HTMLInputElement>) => setAutomaticSendsEnabled(e.target.checked);
  const handleAutomaticRequesterChange = (option: OnChangeValue<Option<string>, false>) => setAutomaticRequester(option!.value);
  const handleRollingWindowDaysChange = (e: ChangeEvent<HTMLInputElement>) => setRollingWindowDays(e.target.value);
  const handleAdvancedNoticeHoursChange = (duration: number) => setAdvancedNoticeHours(duration / 60);
  const handleSuggestTimesChange = (e: ChangeEvent<HTMLInputElement>) => setSuggestTimes(e.target.checked);
  const handleMinimumDurationMinutesChange = (duration: number) => setMinimumDurationMinutes(duration);
  const handleTotalDurationMinutesChange = (duration: number) => setTotalDurationMinutes(duration);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    updateStageMutation.reset();
    setAutomaticSendsEnabled(Boolean(stage.availability_template?.automatic_sends_enabled_at) || false);
    setAutomaticRequester(stage.availability_template?.automatic_requester || currentUser?.id || '');
    setRollingWindowDays(stage.availability_template?.rolling_window_days.toString() || '14');
    setAdvancedNoticeHours(stage.availability_template?.advanced_notice_hours || 24);
    setSuggestTimes(stage.availability_template?.suggest_times || false);
    setMinimumDurationMinutes(stage.availability_template?.minimum_duration_minutes || 0);
    setTotalDurationMinutes(stage.availability_template?.total_duration_minutes || 0);
    setIsEditing(false);
  };

  const handleSave = async () => {
    updateStageMutation.reset();

    try {
      await updateStageMutation.mutateAsync({
        id: stageId,
        jobId,
        payload: {
          automatic_sends_enabled: automaticSendsEnabled,
          automatic_requester: automaticSendsEnabled ? automaticRequester : '',
          rolling_window_days: parseInt(rollingWindowDays, 10) || 0,
          advanced_notice_hours: advancedNoticeHours,
          suggest_times: automaticSendsEnabled ? false : suggestTimes,
          minimum_duration_minutes: minimumDurationMinutes,
          total_duration_minutes: totalDurationMinutes,
        },
      });
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  return (
    <Section
      className="preferences-section"
      isEditable
      isEditing={isEditing}
      isSaving={updateStageMutation.isLoading}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Preferences"
    >
      <Flash
        isDismissible
        message="Successfully updated!"
        onDismiss={updateStageMutation.reset}
        showFlash={updateStageMutation.isSuccess}
        type="success"
      />
      <Flash
        message={updateStageMutation.error?.message}
        showFlash={updateStageMutation.isError}
        type="danger"
      />
      <Flash
        message={<>You currently don&apos;t have timezones set for your scheduling windows. We <b>will not</b> use any windows that don&apos;t have a timezone when sending the request automatically. Set timezones <Link to={correctPath(`/app/jobs/${jobId}/stages/${stageId}/scheduling-preferences`)}>here</Link>.</>}
        showFlash={Boolean(stage.availability_template?.automatic_sends_enabled_at && stage.schedule_template?.business_hours?.some((bh) => !bh.timezone && (bh.start_time !== SentinelTimes.StartOfDay || bh.end_time !== SentinelTimes.EndOfDay)))}
        type="warning"
      />
      <ExpandableCheckboxInput
        isChecked={automaticSendsEnabled}
        isDisabled={!isEditing || updateStageMutation.isLoading || !stage.availability_template?.availability_request_email_template}
        label="Automatically send an availability request when the candidate moves into this stage."
        onChange={handleAutomaticSendEnabledChange}
        tooltip={!stage.availability_template?.availability_request_email_template ? (
          <Tooltip
            id="automatically-send-disabled-tooltip"
            position="top"
            value="You can't automatically send the request if there's no email template configured."
          />
        ) : undefined}
      >
        <AutomaticRequesterSelectInput
          isDisabled={!isEditing || updateStageMutation.isLoading}
          onChange={handleAutomaticRequesterChange}
          value={automaticRequester}
        />
      </ExpandableCheckboxInput>
      <RollingWindowDaysInput
        isDisabled={!isEditing || updateStageMutation.isLoading}
        isRequired
        label="Preferred Date Window"
        max={180}
        min={1}
        onChange={handleRollingWindowDaysChange}
        value={rollingWindowDays}
      />
      <DurationInput
        className="minimum-duration-input"
        helperText="This is to enforce a minimum duration for each time block to make sure all submitted times are usable for you."
        id="minimum-duration"
        isDisabled={!isEditing || updateStageMutation.isLoading}
        label="Minimum Duration"
        maxMinutes={MAX_MINIMUM_DURATION_MINUTES}
        onChange={handleMinimumDurationMinutesChange}
        value={minimumDurationMinutes}
      />
      <DurationInput
        className="total-duration-input"
        helperText="We recommend requiring more availability than is strictly needed for the interview so that you have more options when scheduling."
        id="total-duration"
        isDisabled={!isEditing || updateStageMutation.isLoading}
        label="Total Availability"
        maxMinutes={MAX_TOTAL_AVAILABILITY_MINUTES}
        onChange={handleTotalDurationMinutesChange}
        value={totalDurationMinutes}
      />
      <DurationInput
        className="advanced-notice-input"
        helperText="We will prevent the candidate from submitting times too soon in the future, so that your team has enough notice to coordinate the interview."
        isDisabled={!isEditing || updateStageMutation.isLoading}
        isHoursOnly
        label="Advanced Notice"
        maxMinutes={MAX_ADVANCED_NOTICE_MINUTES}
        onChange={handleAdvancedNoticeHoursChange}
        value={advancedNoticeHours * 60}
      />
      <CheckboxInput
        className="suggest-times-input"
        helperText="Suggest times that are preferred by your team. The candidate will still be able to submit availability outside these times."
        isChecked={!automaticSendsEnabled && suggestTimes}
        isDisabled={!isEditing || updateStageMutation.isLoading || automaticSendsEnabled}
        label="Suggest specific times."
        onChange={handleSuggestTimesChange}
        tooltip={automaticSendsEnabled ? (
          <Tooltip
            id="suggested-times-disabled-tooltip"
            position="top"
            value="You can't suggest times if the request is sent out automatically."
          />
        ) : undefined}
      />
    </Section>
  );
};

export default PreferencesSection;
