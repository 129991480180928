import { CustomPicker } from 'react-color';
import { Hue, Saturation } from 'react-color/lib/components/common';

import type { CustomPickerInjectedProps } from 'react-color/lib/components/common/ColorWrap';

const ColorPicker = ({ hsl, hsv, onChange }: CustomPickerInjectedProps) => (
  <div className="color-picker">
    <div className="saturation">
      <Saturation
        hsl={hsl}
        hsv={hsv}
        onChange={onChange}
      />
    </div>
    <div className="hue">
      <Hue
        hsl={hsl}
        onChange={onChange}
      />
    </div>
  </div>
);

export default CustomPicker(ColorPicker);
