import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ExpandableCheckboxInput from 'components/library/inputs/ExpandableCheckboxInput';
import InterviewBatchSelectInput from 'components/library/inputs/InterviewBatchSelectInput';
import ZoomHostFiltersBuilder from 'components/library/inputs/ZoomHostFiltersBuilder';
import Section from 'components/library/layout/Section';
import Flash from 'components/library/utils/Flash';
import { useInterviewBatches } from 'hooks/queries/interview-batches';
import { useSelfSchedulingLink, useUpdateSelfSchedulingLink } from 'hooks/queries/self-scheduling-links';
import { useSession } from 'hooks/use-session';
import { createEmptyZoomHostFilter } from 'libraries/zoom-hosts';
import { constructPayload } from './helpers';
import ScheduleTable from './ScheduleTable';

import type { ChangeEvent } from 'react';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from 'components/library/inputs/SelectInput/types';
import type { EditableSelfSchedulingLink, EditableZoomHostFilter } from 'types';

const SelfSchedulingLinkScheduleSection = () => {
  const { scheduleId } = useParams<{ id: string; scheduleId: string }>();

  const { account } = useSession();
  const { data: originalSelfSchedulingLink } = useSelfSchedulingLink(scheduleId);
  const { data: interviewBatches } = useInterviewBatches(
    { stage_interview_id: originalSelfSchedulingLink?.stage_interview_id },
    { enabled: Boolean(originalSelfSchedulingLink?.stage_interview_id) }
  );

  const updateSelfSchedulingLinkMutation = useUpdateSelfSchedulingLink();

  const [selfSchedulingLink, setSelfSchedulingLink] = useState<EditableSelfSchedulingLink>(originalSelfSchedulingLink!);
  const [isEditing, setIsEditing] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);

  const isEditable = selfSchedulingLink?.status === 'requested';

  useEffect(() => {
    setSelfSchedulingLink(originalSelfSchedulingLink!);
  }, [originalSelfSchedulingLink?.id]);

  useEffect(() => {
    updateSelfSchedulingLinkMutation.reset();
    setIsEditing(false);
  }, [scheduleId]);

  const handleInterviewBatchChange = (option: OnChangeValue<Option<string>, false>) => {
    setSelfSchedulingLink((prevState) => ({
      ...prevState,
      interview_batch_id: option?.value,
    }));
  };
  const handleVideoConferencingEnabledChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelfSchedulingLink((prevState) => ({
      ...prevState,
      schedule_template: {
        ...prevState.schedule_template,
        video_conferencing_enabled: e.target.checked,
      },
    }));
  };
  const handleZoomHostFiltersChange = (filters: EditableZoomHostFilter[]) => {
    setSelfSchedulingLink((prevState) => ({
      ...prevState,
      schedule_template: {
        ...prevState.schedule_template,
        zoom_host_filters: filters,
      },
    }));
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setSelfSchedulingLink(originalSelfSchedulingLink!);
    setIsEditing(false);
  };

  const handleSave = async () => {
    updateSelfSchedulingLinkMutation.reset();

    try {
      const data = await updateSelfSchedulingLinkMutation.mutateAsync({
        id: selfSchedulingLink.id,
        payload: constructPayload(selfSchedulingLink, account),
      });
      setSelfSchedulingLink(data);
      setIsEditing(false);
      setIsSuccess(true);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  return (
    <Section
      className="schedule-section"
      isEditable={isEditable}
      isEditing={isEditing}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Schedule"
    >
      <Flash
        isDismissible
        message="Successfully updated!"
        showFlash={isSuccess}
        type="success"
      />
      <Flash
        message={updateSelfSchedulingLinkMutation.error?.message}
        showFlash={updateSelfSchedulingLinkMutation.isError}
        type="danger"
      />
      {interviewBatches && interviewBatches.total > 0 && (
        <InterviewBatchSelectInput
          className="interview-batch-select-input"
          isDisabled={!isEditing}
          label="Interview Batch"
          onChange={handleInterviewBatchChange}
          stageInterviewId={selfSchedulingLink.stage_interview_id}
          value={selfSchedulingLink.interview_batch_id}
        />
      )}
      {!selfSchedulingLink.interview_batch_id && (
        <ExpandableCheckboxInput
          isChecked={selfSchedulingLink.schedule_template.video_conferencing_enabled}
          isDisabled={!isEditing}
          label="Include video conferencing links."
          onChange={handleVideoConferencingEnabledChange}
        >
          {selfSchedulingLink.schedule_template.video_conferencing_enabled && account?.video_conferencing_type === 'zoom' && (
            <ZoomHostFiltersBuilder
              filters={selfSchedulingLink.schedule_template.zoom_host_filters || createEmptyZoomHostFilter()}
              isDisabled={!isEditing}
              label="Zoom Meeting Host"
              onChange={handleZoomHostFiltersChange}
            />
          )}
        </ExpandableCheckboxInput>
      )}
      <ScheduleTable
        applicationId={selfSchedulingLink.application_id}
        isEditing={isEditing}
        selfSchedulingLink={selfSchedulingLink}
        setSelfSchedulingLink={setSelfSchedulingLink}
      />
    </Section>
  );
};

export default SelfSchedulingLinkScheduleSection;
