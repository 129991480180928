import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled from 'styled-components';

export const StyledContainer = styled.div`
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercuryGray || theme.colors.PRODUCT.SECONDARY_BORDER};
  border-top: 1px solid ${({ theme }) => theme.colors.mercuryGray || theme.colors.PRODUCT.SECONDARY_BORDER};
  line-height: 1.5em;
  margin-top: 2em;

  span {
    color: ${({ theme }) => theme.colors.doveGray || theme.colors.PRODUCT.SECONDARY_CONTENT};
    margin-top: 8px;
  }
`;

export const StyledLoadedIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.funGreen || theme.colors.GREENS.GREEN_300};
`;
