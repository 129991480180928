import ConfettiExplosion from 'react-confetti-explosion';

import { StyledBanner, StyledEmoji, StyledImage } from './styles';

const GemAcquisitionBanner = () => {
  return (
    <StyledBanner>
      <ConfettiExplosion
        duration={5000}
        force={.3}
        zIndex={10}
      />
      <StyledEmoji>🎉</StyledEmoji>
      InterviewPlanner has been acquired by
      <StyledImage src="/scheduling/src/assets/images/gem-logotype.svg" />
      &mdash;&nbsp;<a href="https://interviewplanner.com/blog/interviewplanner-is-joining-gem/">Learn more!</a>
    </StyledBanner>
  );
};

export default GemAcquisitionBanner;
