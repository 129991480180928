import { find } from 'lodash';
import { useParams } from 'react-router-dom';
import { useState } from 'react';

import ConflictKeywordsInput from '../../../../library/inputs/ConflictKeywordsInput';
import Flash from '../../../../library/utils/Flash';
import Section from '../../../../library/layout/Section';
import SelectInput from '../../../../library/inputs/SelectInput';
import { useAccount } from '../../../../../hooks/queries/accounts';
import { useUpdateUser, useUser } from '../../../../../hooks/queries/users';

import type { EditableIgnoreWord } from '../../../../../types';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../../../library/inputs/SelectInput/types';

const ignoreFreeEventsOptions: Option<boolean>[] = [{
  label: 'Count as conflicts',
  value: false,
}, {
  label: 'Don\'t count as conflicts',
  value: true,
}];

const ConflictSettingsSection = () => {
  const { id } = useParams<{ id: string }>();

  const { data: account } = useAccount();
  const { data: user } = useUser(id);

  const [isEditing, setIsEditing] = useState(false);
  const [conflictKeywords, setConflictKeywords] = useState<EditableIgnoreWord[]>(user?.ignore_words || []);
  const [ignoreFreeEvents, setIgnoreFreeEvents] = useState<boolean | null>(user?.ignore_free_events ?? null);

  const updateUserMutation = useUpdateUser();

  const handleIgnoreFreeEventsChange = (option: OnChangeValue<Option<boolean>, false>) => {
    setIgnoreFreeEvents(option ? option.value : null);
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setConflictKeywords(user?.ignore_words || []);
    setIgnoreFreeEvents(user?.ignore_free_events ?? null);
    setIsEditing(false);
  };

  const handleSave = async () => {
    updateUserMutation.reset();

    try {
      await updateUserMutation.mutateAsync({
        id,
        payload: {
          ignore_words: conflictKeywords.map(({ id, negated }) => ({ id, negated })),
          ignore_free_events: ignoreFreeEvents === null ? -1 : ignoreFreeEvents ? 1 : 0,
        },
      });
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  return (
    <Section
      className="conflict-settings-section"
      isEditable
      isEditing={isEditing}
      isSaving={updateUserMutation.isLoading}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Conflict settings"
    >
      <Flash
        message={updateUserMutation.error?.message}
        showFlash={updateUserMutation.isError}
        type="danger"
      />
      <Flash
        isDismissible
        message="Successfully updated!"
        showFlash={updateUserMutation.isSuccess}
        type="success"
      />
      <Flash
        message="These keywords will only apply to events on this interviewer's calendar."
        showFlash
        type="info"
      />
      <ConflictKeywordsInput
        isDisabled={!isEditing || updateUserMutation.isLoading}
        keywords={conflictKeywords}
        setKeywords={setConflictKeywords}
      />
      <SelectInput
        helperText={'Determine whether events marked as "free" should be considered conflicts or not.'}
        isClearable
        isDisabled={!isEditing || updateUserMutation.isLoading}
        label="Free events"
        onChange={handleIgnoreFreeEventsChange}
        options={ignoreFreeEventsOptions}
        placeholder={`Use the account default (${account?.ignore_free_events ? 'don\'t count as conflicts' : 'count as conflicts'})`}
        value={find(ignoreFreeEventsOptions, ['value', ignoreFreeEvents])}
      />
    </Section>
  );
};

export default ConflictSettingsSection;
