export enum Environment {
  Development = 'development',
  Production = 'production',
  Staging = 'staging',
}

export interface Config {
  DEMO_REQUEST_EMAIL_DOMAIN_BLACKLIST: string[];
  EMAIL_COLLECTOR_API_HOST: string;
  ENVIRONMENT: Environment;
  FULLSTORY_ORG_ID: string;
  GEM_HOST: string;
  LAUNCHDARKLY_CLIENT_SIDE_ID: string;
  LEVER_HOST: string;
  SEGMENT_WRITE_KEY: string;
  SENTRY_DSN: string;
  VERSION: string;
  ZENDESK_API_HOST: string;
}
