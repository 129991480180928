import classnames from 'classnames';
import { faBuilding, faGraduationCap, faQuestion, faUser, faUserSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize, includes, kebabCase, startCase, uniqueId } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import Badge from '../utils/Badge';
import Tooltip from '../utils/Tooltip';
import { useUsersMap } from '../../../hooks/queries/users';

const getInitials = (str: string): string => {
  if (str.indexOf('@') > -1) {
    // If the string is an email, take the part before the @.
    str = str.split('@')[0];
  }
  const words = startCase(str).split(' ');
  const firstInitial = words[0].charAt(0);
  const lastInitial = words.length > 1 ? words[words.length - 1].charAt(0) : '';
  return `${firstInitial}${lastInitial}`.toUpperCase();
};

const isHiringTeamMember = (id: string): boolean => includes([
  'coordinator',
  'hiring_manager',
  'recruiter',
  'sourcer',
], id);

export enum AvatarSize {
  Large = 'large',
  Small = 'small',
}

export enum AvatarStatus {
  Danger = 'danger',
  Ok = 'ok',
  Warning = 'warning',
}

export enum AvatarType {
  Candidate = 'candidate',
  Company = 'company',
  User = 'user',
}

interface Props {
  alt?: string;
  className?: string;
  img?: string;
  showOptionalBadge?: boolean;
  showTraineeBadge?: boolean;
  showUserTooltip?: boolean;
  size?: `${AvatarSize}`;
  status?: `${AvatarStatus}`;
  tooltipHelperText?: string;
  type?: `${AvatarType}`;
  userId?: string;
}

const Avatar = ({
  alt,
  className,
  img = '',
  showOptionalBadge = false,
  showTraineeBadge = false,
  showUserTooltip = true,
  size,
  status,
  tooltipHelperText,
  type = AvatarType.User,
  userId = '',
}: Props) => {
  const users = useUsersMap({ archived: true });
  const user = useMemo(() => users[userId], [users, userId]);

  const [imageUrl, setImageUrl] = useState<string>(img || user?.thumbnail_photo_url || '');

  useEffect(() => {
    setImageUrl(img || user?.thumbnail_photo_url || '');
  }, [img, user?.thumbnail_photo_url]);

  const caption = alt ||
    (type === 'user' && (user?.name || user?.email)) ||
    (type === 'user' && isHiringTeamMember(userId) && startCase(userId)) ||
    capitalize(type);

  const handleImageError = () => {
    setImageUrl('');
  };

  const isEmpty = !isHiringTeamMember(userId) && !img && (type === 'user' && !user);

  const placeholder = {
    candidate: alt ? getInitials(caption) : <FontAwesomeIcon icon={faUser} />,
    company: <FontAwesomeIcon icon={faBuilding} />,
    user: isHiringTeamMember(userId) ?
      <FontAwesomeIcon icon={faUser} /> :
      (isEmpty ?
        <FontAwesomeIcon icon={faUserSlash} /> :
        getInitials(caption || user?.name || user?.email)
      ),
  };

  const showTooltip = (type === 'user' || type === 'candidate') && showUserTooltip;
  const tooltipId = useMemo(() => uniqueId(`avatar-${kebabCase(caption)}-`), [caption]);

  return (
    <div
      className={classnames('avatar-container', className)}
      data-for={showTooltip ? tooltipId : undefined}
      data-tip={showTooltip ? true : undefined}
    >
      {imageUrl ?
        <img
          alt={caption}
          className={`avatar avatar-${size}${status ? ` avatar-status-${status}` : ''}`}
          onError={handleImageError}
          src={imageUrl}
        /> :
        <div
          className={`avatar avatar-${size} avatar-${type}${user && user.color ? ` avatar-color-${user.color}` : ''}${isEmpty ? ' avatar-empty' : ''}${isHiringTeamMember(userId) ? ' avatar-hiring-team' : ''}${status ? ` avatar-status-${status}` : ''}`}
        >
          {placeholder[type]}
        </div>
      }
      {showOptionalBadge && <Badge className="optional-badge" value={<FontAwesomeIcon icon={faQuestion} />} />}
      {showTraineeBadge && <Badge className="trainee-badge" value={<FontAwesomeIcon icon={faGraduationCap} />} />}
      {showTooltip &&
        <Tooltip
          helperText={(showOptionalBadge || showTraineeBadge || tooltipHelperText) ?
            `${showOptionalBadge ? 'Optional' : ''}${showTraineeBadge ? `${showOptionalBadge ? ' ' : ''}Trainee` : ''}${(showOptionalBadge || showTraineeBadge) && tooltipHelperText ? ' ' : ''}${tooltipHelperText ? tooltipHelperText : ''}` :
            null
          }
          id={tooltipId}
          position="top"
          value={caption}
        />
      }
    </div>
  );
};

export default Avatar;
