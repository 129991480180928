import { startCase } from 'lodash';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import { ATS } from 'types';
import Flash from 'components/library/utils/Flash';
import OutboundLink from 'components/library/navigation/OutboundLink';
import SelectInput from 'components/library/inputs/SelectInput';
import { signUpParams } from 'hooks/queries/auth';
import { StyledModal } from './styles';
import TextInput from 'components/library/inputs/TextInput';
import { useAccount, useUpdateAccount } from 'hooks/queries/accounts';

import type { ChangeEvent } from 'react';
import type { Option } from 'components/library/inputs/SelectInput/types';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';

const atsOptions: Option<`${ATS}`>[] = [
  { value: ATS.Gem, label: 'Gem' },
  { value: ATS.Greenhouse, label: 'Greenhouse' },
  { value: ATS.Lever, label: 'Lever' },
];

const atsHelpLinks: Record<ATS, string> = {
  [ATS.Gem]: '', // TODO
  [ATS.Greenhouse]: 'https://support.gem.com/hc/en-us/articles/23491781210007-How-do-I-configure-my-Greenhouse-API-key',
  [ATS.Lever]: 'https://support.gem.com/hc/en-us/articles/23491694906903-How-do-I-connect-my-Lever-account',
};

interface Props {
  isOpen: boolean;
  onToggle: () => void;
}

const ConnectATSModal = ({ isOpen, onToggle }: Props) => {
  const queryClient = useQueryClient();

  const { data } = useAccount();
  const account = data!;

  const updateAccountMutation = useUpdateAccount();

  const [leverError, setLeverError] = useState<string>();
  const [isFetching, setIsFetching] = useState(false);
  const [atsType, setAtsType] = useState<`${ATS}` | ''>(account.ats_type || '');
  const [greenhouseSubdomain, setGreenhouseSubdomain] = useState(account.greenhouse_subdomain || '');
  const [atsApiKey, setAtsApiKey] = useState('');

  useEffect(() => {
    setAtsType(account.ats_type || '');
  }, [account.ats_type]);

  useEffect(() => {
    setGreenhouseSubdomain(account.greenhouse_subdomain || '');
  }, [account.greenhouse_subdomain]);

  const handleAtsTypeChange = (option: OnChangeValue<Option<`${ATS}`>, false>) => setAtsType(option ? option.value : '');
  const handleGreenhouseSubdomainChange = (e: ChangeEvent<HTMLInputElement>) => setGreenhouseSubdomain(e.target.value);
  const handleAtsApiKeyChange = (e: ChangeEvent<HTMLInputElement>) => setAtsApiKey(e.target.value);

  const handleToggle = () => {
    onToggle();
  };

  const handleConnectLever = async () => {
    setIsFetching(true);

    try {
      const { redirect_url } = await queryClient.fetchQuery(signUpParams(ATS.Lever));
      window.location.href = redirect_url;
    } catch (err) {
      if (err instanceof Error) {
        setLeverError(err.message || 'There was an error. Please contact support@gem.com.');
      }
      setIsFetching(false);
    }
  };

  const handleSave = async () => {
    updateAccountMutation.reset();

    try {
      if (atsType === 'lever') {
        return handleConnectLever();
      } else if (atsType) {
        await updateAccountMutation.mutateAsync({
          id: account.id,
          payload: {
            ats_type: atsType,
            greenhouse_subdomain: atsType === 'greenhouse' ? greenhouseSubdomain : undefined,
            ats_api_key: atsApiKey,
          },
        });
      }
      onToggle();
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  const isSaving = isFetching || updateAccountMutation.isLoading;

  return (
    <StyledModal
      className="dropdown-modal"
      isOpen={isOpen}
      isSubmitting={isSaving}
      onSubmit={handleSave}
      onToggle={handleToggle}
      submitButtonValue="Connect"
      submittingButtonValue="Connecting"
      title="Connect your ATS?"
    >
      <Flash
        message={leverError}
        showFlash={Boolean(leverError)}
        type="danger"
      />
      <Flash
        message={updateAccountMutation.error?.message}
        showFlash={updateAccountMutation.isError}
        type="danger"
      />
      <SelectInput
        className="input-ats-type"
        isDisabled={isSaving}
        isRequired
        onChange={handleAtsTypeChange}
        options={atsOptions}
        placeholder="Select your ATS"
        value={atsOptions.find(({ value }) => value === atsType)}
      />
      {atsType === 'greenhouse' && (
        <TextInput
          className="input-subdomain"
          helperText={(
            <span>
              If you sign in at https://app2.greenhouse.io, app2 is your subdomain.&nbsp;
              <OutboundLink
                href="https://support.gem.com/hc/en-us/articles/23487293521175-What-s-my-Greenhouse-subdomain"
                label="Greenhouse Subdomain Helper Text"
              >
                Learn more.
              </OutboundLink>
            </span>
          )}
          isDisabled={isSaving}
          isRequired
          label="Greenhouse Subdomain"
          onChange={handleGreenhouseSubdomainChange}
          placeholder="app2"
          value={greenhouseSubdomain}
        />
      )}
      {atsType && atsType !== 'lever' &&
        <TextInput
          className="input-ats-api-key"
          helperText={atsHelpLinks[atsType] ?
            <OutboundLink
              href={atsHelpLinks[atsType]}
              label={`${startCase(atsType)} API Key Helper Text`}
            >
              Where can I find mine?
            </OutboundLink> :
            null
          }
          isDisabled={isSaving}
          isRequired
          label="API Key"
          onChange={handleAtsApiKeyChange}
          value={atsApiKey}
        />
      }
    </StyledModal>
  );
};

export default ConnectATSModal;
