import { groupBy } from 'lodash';

import Flash from '../../../../library/utils/Flash';
import PendingChangeMessage from './PendingChangeMessage';

import type { PendingChange } from './types';
import { useMemo } from 'react';

interface Props {
  pendingChanges: PendingChange[];
}

const TrainingProgramPendingChangesFlash = ({ pendingChanges }: Props) => {
  // When we add a new interviewer, we add a pending change that doesn't have a fully realized user on it yet (i.e.
  // there's no ID). Since that would make the flash look weird, we filter them out. This is a problem for saving
  // because the interviewer select input is required, so they can't save until they select someone, at which point, we
  // will show that pending change in this list.
  const validChanges = useMemo(() => pendingChanges.filter((change) => change.user.id), [pendingChanges]);
  const groupedChanges = useMemo(() => groupBy(validChanges, 'user.id'), [validChanges]);

  return (
    <Flash
      message={(
        <>
          The following changes will be applied when you save:
          <ul>
            {Object.entries(groupedChanges).map(([userId, changes]) => {
              return <PendingChangeMessage changes={changes} key={userId} />;
            })}
          </ul>
        </>
      )}
      showFlash={validChanges.length > 0}
      type="info"
    />
  );
};

export default TrainingProgramPendingChangesFlash;
