import { useQuery } from 'react-query';

import InterviewPlanner from '../../libraries/interviewplanner';

import type { Office } from '../../types';
import type { UseQueryOptions } from 'react-query';

export enum QueryKey {
  ListOffices = 'ListOffices',
}

interface ListOfficesQuery {}

interface ListOfficesData {
  offices: Office[];
  total: number;
}

export const useOffices = (query: ListOfficesQuery = {}, options: UseQueryOptions<ListOfficesData, Error> = {}) => {
  return useQuery<ListOfficesData, Error>([QueryKey.ListOffices, query], () => {
    return InterviewPlanner.request('GET', '/offices', null, query);
  }, {
    ...options,
  });
};
