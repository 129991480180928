import classnames from 'classnames';
import { useFocused, useSelected } from 'slate-react';

import type { ImageElement } from '../../../../types';
import type { RenderElementProps } from 'slate-react';

const Image = ({ attributes, children, element }: RenderElementProps) => {
  // We know this will only be called with ImageElements.
  const { src, alt, width, height } = (element as ImageElement);

  const isSelected = useSelected();
  const isFocused = useFocused();

  return (
    <span {...attributes}>
      <img
        alt={alt}
        className={classnames([
          'editor-input-image',
          isSelected && isFocused && 'focused',
        ])}
        height={height}
        src={src}
        width={width}
      />
      {children}
    </span>
  );
};

export default Image;
