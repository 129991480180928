import bytes from 'bytes-iec';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip, faTimes } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';

import Button from '../Button';
import OutboundLink from '../../navigation/OutboundLink';
import { downloadFileFromUrl } from '../../../../libraries/form-data';
import { isExistingEditorAttachment } from '../../../../types';

import type { EditorAttachment } from '../../../../types';

interface Props {
  file: EditorAttachment;
  isEditing: boolean;
  onRemove: () => void;
}

const Attachment = ({ file, isEditing, onRemove }: Props) => {
  const [fileSize, setFileSize] = useState(!isExistingEditorAttachment(file) && file.size !== undefined ? file.size : null);

  useEffect(() => {
    if (isExistingEditorAttachment(file) && file.link) {
      let active = true;
      (async () => {
        if (isExistingEditorAttachment(file) && file.link) {
          const f = await downloadFileFromUrl(file.link, file.name);
          if (active) {
            setFileSize(f.size ?? null);
          }
        }
      })();
      return () => {
        active = false;
      };
    }
  }, [file]);

  return (
    <div className="editor-input-attachment">
      <div>
        <FontAwesomeIcon className="attachment-icon" icon={faPaperclip} />
        {isExistingEditorAttachment(file) && file.link ?
          <OutboundLink className="file-name" href={file.link} label="Email Template Attachment">
            {file.name}
          </OutboundLink> :
          <span className="file-name">
            {file.name}
          </span>
        }
        {fileSize !== null &&
          <>
            &nbsp;
            <span className="file-size">
              ({bytes.format(fileSize, { mode: 'binary', unitSeparator: ' ' })})
            </span>
          </>
        }
      </div>
      {isEditing &&
        <Button
          className="btn-delete"
          color="gray"
          iconRight={<FontAwesomeIcon icon={faTimes} />}
          onClick={onRemove}
          size="large"
          value="Remove attachment"
        />
      }
    </div>
  );
};

export default Attachment;
