import { htmlToSlateValue } from './html-to-slate-value';

import type { Descendant } from 'slate';

export function textToSlateValue (text: string): Descendant[] {
  if (text) {
    // Roughly sanitize the text into HTML, since we pretty much rely on the
    // HTML deserializer.
    text = text
    .replace(/&/g, '&amp;')
    .replace(/</g, '&lt;')
    .replace(/>/g, '&gt;')
    .replace(/"/g, '&quot;');

    text = `<span>${text}</span>`;
  }

  return htmlToSlateValue(text);
}
