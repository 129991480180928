import Moment from 'moment';
import { Breadcrumb } from 'react-breadcrumbs';
import { NavLink, Redirect, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { capitalize, find, groupBy, isEmpty, maxBy, orderBy, partition, sumBy } from 'lodash';
import { useLayoutEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import AvailabilityMenuLabel from './AvailabilityMenuLabel';
import CandidateAvailability from './CandidateAvailability';
import ListItem from '../../../../library/data-display/ListItem';
import LoadingSpinner from '../../../../library/utils/LoadingSpinner';
import SelectInput from '../../../../library/inputs/SelectInput';
import StatusIndicator from '../../../../library/utils/StatusIndicator';
import { formatDuration } from '../../../../../libraries/formatters';
import { statusIndicatorValues } from './types';
import { useApplication } from '../../../../../hooks/queries/applications';
import { useSession } from '../../../../../hooks/use-session';

import type { AvailabilityWithTotalSubmittedMinutes, Option } from './types';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import { correctPath } from 'libraries/gem';

const CandidateAvailabilities = () => {
  const history = useHistory();
  const location = useLocation();

  const { id } = useParams<{ id: string }>();

  const { account } = useSession();
  const { data: application } = useApplication(id);

  const availabilities = useMemo<AvailabilityWithTotalSubmittedMinutes[]>(() => {
    const [atsAvailabilities, availabilitiesToDisplay] = partition(application?.all_availabilities || [], 'ats_id');

    const atsAvailabilitiesByStage = groupBy(atsAvailabilities, 'stage_id');
    Object.keys(atsAvailabilitiesByStage).forEach((stage_id) => {
      const mostRecentAvailability = maxBy(atsAvailabilitiesByStage[stage_id], 'created_at');
      if (mostRecentAvailability) {
        availabilitiesToDisplay.push(mostRecentAvailability);
      }
    });

    return orderBy(availabilitiesToDisplay.map((availability) => ({
      ...availability,
      totalSubmittedMinutes: sumBy(
        availability.availability_time_slots || [],
        ({ start_time, end_time }) => Moment.duration(Moment.utc(end_time).diff(Moment.utc(start_time))).asMinutes()
      ),
    })), ['created_at']);
  }, [application?.all_availabilities]);

  const availabilityOptions = useMemo<Option[]>(() => availabilities.map((availability) => ({
    label: `${availability.stage.name}`,
    value: availability.id,
    availability,
  })), [availabilities]);

  const [selectedAvailabilityId, setSelectedAvailabilityId] = useState('');

  const isSmallScreen = useMediaQuery({ query: '(max-width: 1050px)' });

  // Get selected availability from url
  useLayoutEffect(() => {
    const splitUrl = location.pathname.split('/availabilities/');
    if (splitUrl.length > 1) {
      const availabilityId = splitUrl[1].split('/')[0];
      if (!selectedAvailabilityId || availabilityId !== selectedAvailabilityId) {
        setSelectedAvailabilityId(availabilityId);
      }
    }
  }, [location.pathname]);

  const handleAvailabilityChange = (option: OnChangeValue<Option, false>) => {
    // This SelectInput can't be cleared, so option should always be defined.
    setSelectedAvailabilityId(option?.value || '');
    history.push(correctPath(`/app/candidates/${application!.id}/availabilities/${option?.value}`));
  };

  if (!application || isEmpty(availabilities)) {
    return <LoadingSpinner />;
  }

  return (
    <Breadcrumb
      data={{
        title: 'Availabilities',
        pathname: correctPath(`/app/candidates/${id}/availabilities`),
      }}
    >
      <div className="candidate-availabilities-container">
        {isSmallScreen ?
          <SelectInput
            formatOptionLabel={(option, { context }) => (
              context === 'menu' ?
                <ListItem
                  label={option.availability.stage.name}
                  leftIcon={(
                    <StatusIndicator
                      color={statusIndicatorValues[option.availability.status].color}
                      tooltipId={`availability-${option.availability.id}-status-indicator`}
                      tooltipText={statusIndicatorValues[option.availability.status].label}
                    />)}
                  secondaryText={`${option.availability.totalSubmittedMinutes ? formatDuration(option.availability.totalSubmittedMinutes) : statusIndicatorValues[option.availability.status].label} ${option.availability.manual ? 'manually entered' : `through ${option.availability.ats_id ? capitalize(account?.ats_type) : 'InterviewPlanner'}`}`}
                /> :
                <span>
                  <StatusIndicator
                    color={statusIndicatorValues[option.availability.status].color}
                    tooltipId={`schedule-${option.availability.id}-availability-indicator`}
                    tooltipText={statusIndicatorValues[option.availability.status].label}
                  />
                  {option.label}
                </span>
            )}
            label="Availability"
            onChange={handleAvailabilityChange}
            options={availabilityOptions}
            value={find(availabilityOptions, ['value', selectedAvailabilityId])}
          /> :
          <div className="availabilities-menu">
            {availabilities.map((availability) => (
              <NavLink
                className="link"
                key={availability.id}
                to={correctPath(`/app/candidates/${application.id}/availabilities/${availability.id}`)}
              >
                <AvailabilityMenuLabel application={application} atsType={account?.ats_type} availability={availability} />
              </NavLink>
            ))}
          </div>
        }
        {!isEmpty(availabilities) &&
          <div className="availability-container">
            <Switch>
              <Redirect exact from={correctPath('/app/candidates/:id/availabilities')} to={correctPath(`/app/candidates/${id}/availabilities/${application.active_availabilities ? application.active_availabilities[0].id : availabilities[availabilities.length - 1].id}`)} />
              <Route component={CandidateAvailability} path={correctPath('/app/candidates/:id/availabilities/:availabilityId')} />
            </Switch>
          </div>
        }
      </div>
    </Breadcrumb>
  );
};

export default CandidateAvailabilities;
