import Moment from 'moment-timezone';
import { useState } from 'react';

import CheckboxInput from '../../../../../library/inputs/CheckboxInput';
import Modal from '../../../../../library/layout/Modal';
import pluralize from '../../../../../../libraries/pluralize';
import { formatMoment, TimeFormat } from '../../../../../../libraries/time';

import type { Application } from '../../../../../../types';
import type { ChangeEvent } from 'react';

interface Props {
  application: Application;
  confirmSchedule: (deleteHeldSchedules: boolean) => Promise<void>;
  isOpen: boolean;
  onToggle: () => void;
  schedule: {
    id?: string;
    block_id?: string | null;
  };
}

const DeleteHeldSchedulesModal = ({ application, confirmSchedule, isOpen, onToggle, schedule }: Props) => {
  const [deleteHeldSchedules, setDeleteHeldSchedules] = useState<boolean>(true);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleDeleteHeldSchedulesChange = (e: ChangeEvent<HTMLInputElement>) => {
    setDeleteHeldSchedules(e.target?.checked);
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    await confirmSchedule(deleteHeldSchedules);
    setIsSubmitting(false);
  };

  if (!application.held_schedules) {
    return null;
  }

  const otherHeldSchedules = schedule.block_id ?
    application.held_schedules.filter(({ block_id }) => block_id !== schedule.block_id) :
    application.held_schedules.filter(({ id }) => id !== schedule.id);

  return (
    <Modal
      cancelButtonValue="Back to Review"
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
      onToggle={onToggle}
      submitButtonColor="gem-outline"
      submitButtonValue="Confirm schedule"
      submittingButtonValue="Confirming..."
      title="Cancel other held schedules?"
    >
      <p>
        There {pluralize('is', otherHeldSchedules.length)} {otherHeldSchedules.length} other held {pluralize('schedule', otherHeldSchedules.length)} for <b>{application.candidate.name} ({application.job.name})</b>. Do you want to cancel {pluralize('it', otherHeldSchedules.length)} as you confirm this one?
      </p>
      <ul className="modal-list-options">
        {otherHeldSchedules.map((heldSchedule) => (
          <li key={heldSchedule.id}>
            {pluralize('interview', heldSchedule.interviews.length, true)} held for <b>{formatMoment(Moment.tz(heldSchedule.interviews[0].start_time, heldSchedule.timezone), TimeFormat.LongDayOfWeekMonthDayAtTimeAndTimezone)}</b>
          </li>
        ))}
      </ul>
      <div className="modal-checkbox-options">
        <CheckboxInput
          isChecked={deleteHeldSchedules}
          label="Cancel the other held schedules."
          onChange={handleDeleteHeldSchedulesChange}
        />
      </div>
    </Modal>
  );
};

export default DeleteHeldSchedulesModal;
