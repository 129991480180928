import type { EmailStatus } from './emails';

export enum EmailTemplateType {
  AvailabilityRequestEmail = 'availability_request_email',
  ConfirmationEmail = 'confirmation_email',
  ManualEmail = 'manual_email',
  MultiBlockConfirmationEmail = 'multi_block_confirmation_email',
  SelfSchedulingRequestEmail = 'self_scheduling_request_email',
}

// The only email template types that can be created are manual emails and
// multi-block confirmation emails.
export type CreatableEmailTemplateType = Exclude<
  Exclude<
    `${EmailTemplateType}`,
    `${EmailTemplateType.MultiBlockConfirmationEmail}`
  >,
  `${EmailTemplateType.ManualEmail}`
>;

// There are times (like when fetching tokens) where we want to include
// multi-block confirmation emails but not manual emails.
export type NonManualEmailTemplateType = Exclude<
  `${EmailTemplateType}`,
  `${EmailTemplateType.ManualEmail}`
>;

export interface CreatableEmailTemplate {
  id?: string;
  type?: CreatableEmailTemplateType;
  name: string;
  subject: string;
  sender_name: string;
  sender_email: string;
  cc_emails?: string[];
  bcc_emails?: string[];
  body: string;
  attachments?: EditableAttachment[];
  archived?: boolean;
}

export interface EmailTemplate {
  id: string;
  account_id: string;
  name: string;
  type: EmailTemplateType;
  linked_resources: number;
  subject: string;
  sender_name: string;
  sender_email: string;
  cc_emails: string[];
  bcc_emails: string[];
  body: string;
  attachments?: Attachment[];
  emails: Email[] | null;
  inline: boolean;
  favorite: boolean;
  created_at: string;
  updated_at: string;
  archived: boolean;
}

export interface Attachment {
  name: string;
  link: string;
  index: number;
}

interface Email {
  id: string;
  email_template_id: string;
  application_id?: string;
  stage_id: string;
  subject: string;
  sender_name: string;
  sender_email: string;
  recipient_name: string | null;
  recipient_email: string;
  cc_emails: string[];
  bcc_emails: string[];
  body: string;
  status: EmailStatus;
  sender_id: string;
  sent_at?: string;
  created_at: string;
  updated_at: string;
}

// This is an editable attachment that is used in EditableEmailTemplate. The
// server-pulled Attachment is compatible with this type, along with
// EditorAttachment. It's also the type that is used for the
// UpdateEmailTemplatePayload.
export type EditableAttachment = {
  name: string;
  // new is required in the update payload, but the input of the mutation
  // doesn't require it because the mutation itself converts it into the right
  // format for the server (i.e. it makes sure that new is defined).
  new?: boolean;
  index?: number;
  file?: File;
  link?: string;
} | File;

export type EditableEmailTemplate = Omit<EmailTemplate, 'attachments'> & {
  attachments?: EditableAttachment[];
};

export const emailTemplateTypeLabels: Record<EmailTemplateType, string> = {
  [EmailTemplateType.AvailabilityRequestEmail]: 'Availability Request',
  [EmailTemplateType.ConfirmationEmail]: 'Schedule Confirmation',
  [EmailTemplateType.ManualEmail]: 'One-off Email',
  [EmailTemplateType.MultiBlockConfirmationEmail]: 'Multiple Block Schedule Confirmation',
  [EmailTemplateType.SelfSchedulingRequestEmail]: 'Self-Scheduling Request',
};
