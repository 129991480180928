import styled from 'styled-components';

import Button from 'components/library/inputs/Button';

export const StyledButton = styled(Button)`
  .icon-right {
    margin-left: 0;

    svg.fa-up-right-from-square {
      margin-left: 0;
    }
  }
`;
