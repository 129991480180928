import QueryString from 'qs';

import type { defaultDecoder } from 'qs';

// The reason we're stringifying a JSON-stringified string is because the query
// string spec is very vague about arrays of nested objects. So encoding an
// array of objects might produce a query string that can't be parsed back into
// an array of objects in the same format. To really be certain that it will not
// change shape, we just JSON-stringify it.
export function encodeObjectForPreview (object: any) {
  return QueryString.stringify({
    preview: JSON.stringify(object),
  }, {
    arrayFormat: 'repeat',
  });
}

export function decodeSearchParamsForPreview (search: string) {
  const parsed = QueryString.parse(search, {
    decoder: (str: string, defaultDecoder: defaultDecoder, charset: string, type: 'key' | 'value') => {
      if (type === 'key') {
        return defaultDecoder(str);
      }
      if (str === 'true') {
        return true;
      }
      if (str === 'false') {
        return false;
      }
      if (str === 'null') {
        return null;
      }
      if (str === 'undefined') {
        return undefined;
      }
      if (/^(\d+|\d*\.\d+)$/.test(str)) {
        return parseFloat(str);
      }
      return defaultDecoder(str);
    },
    ignoreQueryPrefix: true,
  });
  return JSON.parse(parsed.preview as string);
}
