import isUrl from 'is-url';

import { ElementType } from '../../../../../types';
import { wrapLink } from '../helper';

import type { CustomEditor, CustomElement, LinkElement } from '../../../../../types';

export const withLinks = (editor: CustomEditor): CustomEditor => {
  const { insertData, insertText, isInline } = editor;

  editor.isInline = (element: CustomElement) => {
    return isLink(element) ? true : isInline(element);
  };

  editor.insertText = (text: string) => {
    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertText(text);
    }
  };

  editor.insertData = (data: DataTransfer) => {
    const text = data.getData('text/plain');

    if (text && isUrl(text)) {
      wrapLink(editor, text);
    } else {
      insertData(data);
    }
  };

  return editor;
};

/**
 * While this function is usually called with Slate Node objects, that's not
 * always the case, so that's why we can't type it to be Node.
 *
 * @param node An object that is "node-like". It just needs a `type` property.
 */
export function isLink (node: any): node is LinkElement {
  return node.type === ElementType.Link;
}
