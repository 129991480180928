import type { UserMap } from '../../../../../../../hooks/queries/users';

export const formatAttendeeName = (ids: string[], users: UserMap) => (
  <span>
    {ids.map((id, i) => (
      <span key={`attendee-name-${id}`}>
        {i !== 0 && ids.length > 2 && ','}
        {i !== 0 && i === ids.length - 1 && ' and'}
        <b> {users[id]?.name || users[id]?.email}</b>
      </span>
    ))}
  </span>
);
