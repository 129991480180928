import { Breadcrumb } from 'react-breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Route, Switch } from 'react-router-dom';
import { faUsers } from '@fortawesome/free-solid-svg-icons';

import Superday from './Superday';
import SuperdayCreate from './SuperdayCreate';
import SuperdayList from './SuperdayList';
import { correctPath } from 'libraries/gem';

const Superdays = () => (
  <Breadcrumb
    data={{
      title: <><FontAwesomeIcon icon={faUsers} />Superdays</>,
      pathname: correctPath('/app/superdays'),
    }}
  >
    <Switch>
      <Route component={SuperdayList} exact path={correctPath('/app/superdays')} />
      <Route component={SuperdayCreate} path={correctPath('/app/superdays/create')} />
      <Route component={Superday} path={correctPath('/app/superdays/:id')} />
    </Switch>
  </Breadcrumb>
);

export default Superdays;
