import type { AvailabilityTemplate } from './availability-templates';
import type { Pronouns } from './candidates';
import type { BusinessHour } from './business-hours';

export enum AvailabilityStatus {
  Cancelled = 'cancelled',
  Requested = 'requested',
  Submitted = 'submitted',
}

export interface Availability {
  id: string;
  account_id: string;
  account: Account;
  availability_template_id?: string;
  availability_template?: AvailabilityTemplate;
  status: AvailabilityStatus;
  ats_id?: string;
  stage_id: string;
  stage: Stage;
  application_id?: string;
  application?: Application;
  creator_id?: string;
  automatic_send_log_id?: string;
  manual: string;
  availability_time_slots: AvailabilityTimeSlot[] | null;
  created_at: string;
  updated_at: string;
}

export interface AvailabilityPreview {
  account: {
    name?: string;
    color?: string;
    logo_url?: string;
    availability_message?: string;
    enable_pronouns: boolean;
    enable_pronunciation: boolean;
  };
  application: {
    candidate: {
      name?: string;
    };
  };
  availability_template: {
    enforce: boolean;
    rolling_window_days: number;
    business_hours: {
      day: string;
      start_time: string;
      end_time: string;
      timezone: string;
    }[];
    advanced_notice_hours: number;
    minimum_duration_minutes: number;
    total_duration_minutes: number;
    suggested_times?: {
      start_time: string;
      end_time: string;
    }[];
  };
  stage: {
    name: string;
  };
  status: `${AvailabilityStatus}`;
}

export interface AvailabilityTimeSlot {
  id: string;
  account_id: string;
  availability_id: string;
  availability: Availability;
  ats_id?: string;
  start_time: string;
  end_time: string;
  timezone: string;
  created_at: string;
  updated_at: string;
}

interface Account {
  id: string;
  name?: string;
  color?: string;
  logo: boolean;
  logo_url?: string;
  availability_message?: string;
  enable_pronouns: boolean;
  enable_pronunciation: boolean;
}

interface Stage {
  id: string;
  name: string;
  job_id: string;
  job: Job;
  schedule_template_id?: string;
  schedule_template: ScheduleTemplate;
}

interface Job {
  id: string;
  name: string;
}

interface ScheduleTemplate {
  id: string;
  business_hours: BusinessHour[];
}

interface Application {
  id: string;
  candidate_id: string;
  candidate: Candidate;
  current_stage_id?: string;
}

interface Candidate {
  id: string;
  name?: string;
  pronouns: Pronouns | null;
  pronunciation_exists: boolean;
  pronunciation_url: string | null;
}
