import { forwardRef, useEffect } from 'react';

import SuggestionOption from './SuggestionOption';

import type { EditorSuggestion, TokensResponse } from '../../../../../types';
import type { MouseEvent, MutableRefObject, Ref } from 'react';

interface Props {
  active: number;
  filteredSuggestions: EditorSuggestion[];
  onOptionMouseDown: (event: MouseEvent<HTMLSpanElement>, index: number) => void;
  onOptionMouseEnter: (event: MouseEvent<HTMLSpanElement>, index: number) => void;
  onOptionMouseLeave: (event: MouseEvent<HTMLSpanElement>) => void;
  pendingPreviewMessage: string;
  showSuggestions: boolean;
  tokens?: TokensResponse;
}

const SuggestionsInner = ({
  active,
  filteredSuggestions,
  onOptionMouseDown,
  onOptionMouseEnter,
  onOptionMouseLeave,
  pendingPreviewMessage,
  showSuggestions,
  tokens,
}: Props, ref: Ref<HTMLSpanElement>) => {
  useEffect(() => {
    const el = (ref as MutableRefObject<HTMLSpanElement>).current;

    if (!el) {
      return;
    }

    // Positioning
    try {
      const domSelection = window.getSelection();
      if (!domSelection) {
        return;
      }
      const domRange = domSelection.getRangeAt(0);
      let selectionRect = domRange.getBoundingClientRect();
      if (domRange.getClientRects().length === 0) {
        // Our selection rect is all zeroed out, so we should try to get the
        // bounding rect of the div instead of the the position of the
        // selection. This is to handle the edge case where you have a token on
        // a line by itself, you delete that token, and then you try to add
        // another token. For some reason, instead of the selection being of the
        // text node of the "{{" you just typed, it's of the surrounding div.
        // Because of this, the DOM Range doesn't return the correct thing. So
        // when that happens, we just get the rect of that surrounding div. This
        // isn't perfect because we don't have the correct text offset, so the
        // suggestions dropdown appears at the left side of the editor instead
        // of right at the cursor. Since this is a rare edge case to begin with,
        // this seems sufficient for now.
        if (domSelection.focusNode instanceof Element) {
          selectionRect = domSelection.focusNode.getBoundingClientRect();
        }
      }
      const dropdownRect = el.getBoundingClientRect();

      let top = selectionRect.y + selectionRect.height;
      let left = selectionRect.x;

      // If the dropdown would get cut off on the right-side, shift it to the
      // left until it's flush with the right-side of the window.
      if (left + dropdownRect.width > window.innerWidth) {
        left -= (left + dropdownRect.width) - window.innerWidth;
      }

      const modal = el.closest('.ReactModal__Content');
      if (modal) {
        // The editor exists in a modal, so we need to adjust the positioning
        // accordingly because our modals have position: fixed, which interferes
        // with the absolute positioning of the dropdown.
        const modalRect = modal.getBoundingClientRect();
        top -= modalRect.y;
        left -= modalRect.x;
      }

      el.style.top = `${top}px`;
      el.style.left = `${left}px`;
      // Visibility is also being adjusted so that there isn't a flash of the
      // suggestions dropdown in another location before it gets moved to the
      // correct spot.
      el.style.visibility = 'visible';
    } catch (_) {
      // Since this runs all the time, it's possible for it to run on an edge
      // case where the DOM selection will err out. So we wrap this in a
      // try/catch and just ignore any errors.
    }
  });

  if (!showSuggestions) {
    return null;
  }

  if (filteredSuggestions.length === 0) {
    return null;
  }

  return (
    <span
      className="editor-suggestions-dropdown"
      contentEditable="false"
      ref={ref}
      suppressContentEditableWarning
    >
      {filteredSuggestions.map((suggestion, index) => (
        <SuggestionOption
          index={index}
          isActive={active === index}
          key={suggestion.value}
          onMouseDown={onOptionMouseDown}
          onMouseEnter={onOptionMouseEnter}
          onMouseLeave={onOptionMouseLeave}
          pendingPreviewMessage={pendingPreviewMessage}
          token={tokens?.[suggestion.value]}
          value={suggestion.value}
        />))}
    </span>
  );
};

const Suggestions = forwardRef(SuggestionsInner);

export default Suggestions;
