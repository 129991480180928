import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { getCsrfToken } from 'libraries/csrf';

const httpLink = createHttpLink({
  credentials: 'include',
  uri: '/api/graphql',
});

const sessionKeyLink = setContext((_, { headers }) => {
  const cssessionkey = getCsrfToken();
  if (cssessionkey) {
    return {
      headers: {
        ...headers,
        cssessionkey,
      },
    };
  }
  return { headers };
});

export const apolloClient = new ApolloClient({
  cache: new InMemoryCache(),
  link: sessionKeyLink.concat(httpLink),
});
