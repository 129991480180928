import { pick } from 'lodash';
import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import EditorInput from '../../../../library/inputs/EditorInput';
import EmailTemplateAdvancedSettings from '../../../../library/inputs/EmailTemplateAdvancedSettings';
import ErrorTokenFlash from '../../../../library/utils/ErrorTokenFlash';
import Flash from '../../../../library/utils/Flash';
import Section from '../../../../library/layout/Section';
import TextInput from '../../../../library/inputs/TextInput';
import ToggleInput from '../../../../library/inputs/ToggleInput';
import TokenInput from '../../../../library/inputs/TokenInput';
import { EditorType, EmailTemplateType, Token } from '../../../../../types';
import { getTokensFromSlateValue, slateValueToHtml } from '../../../../../libraries/editor/slate-value-to-html';
import { htmlToSlateValue } from '../../../../../libraries/editor/html-to-slate-value';
import { slateValueToText } from '../../../../../libraries/editor/slate-value-to-text';
import { textToSlateValue } from '../../../../../libraries/editor/text-to-slate-value';
import { translateSlateValueBetweenEditorTypes } from '../../../../../libraries/editor/translate-slate-value-between-editor-types';
import { useEmailTemplate, useUpdateEmailTemplate } from '../../../../../hooks/queries/email-templates';
import { useSlateEditor } from '../../../../../hooks/use-slate-editor';
import { useTokens } from '../../../../../hooks/queries/tokens';

import type { ChangeEvent, SetStateAction } from 'react';
import type { EditableEmailTemplate, EditorAttachment } from '../../../../../types';
import type { UpdateEmailTemplatePayload } from '../../../../../hooks/queries/email-templates';

// This doesn't use EmailTemplateForm because it needs to be able to set the
// subject and body when the cancel button is clicked. Read the comment in
// EmailTemplateForm for more information.

const EmailTemplateSection = () => {
  const { id } = useParams<{ id: string }>();

  const { data: originalEmailTemplate } = useEmailTemplate(id);
  const updateEmailTemplateMutation = useUpdateEmailTemplate();

  const [error, setError] = useState('');
  const [isEditing, setIsEditing] = useState(false);
  const [emailTemplate, setEmailTemplate] = useState<EditableEmailTemplate | undefined>(originalEmailTemplate);
  const [emailSubjectSlateEditor, emailSubjectSlateValue, setEmailSubjectSlateValue, setEmailSubject] = useSlateEditor(emailTemplate?.subject || '', true);
  const [emailBodySlateEditor, emailBodySlateValue, setEmailBodySlateValue, setEmailBody] = useSlateEditor(emailTemplate?.body || '');
  const [emailTemplateTypeForPreview, setEmailTemplateTypeForPreview] = useState(emailTemplate!.type);

  const {
    data: tokens,
    error: tokensError,
  } = useTokens({ type: emailTemplateTypeForPreview });

  const tokensUsedInSubject = useMemo(() => getTokensFromSlateValue(emailSubjectSlateValue), [emailSubjectSlateValue]);
  const tokensUsedInBody = useMemo(() => getTokensFromSlateValue(emailBodySlateValue), [emailBodySlateValue]);
  const errorTokens = useMemo(() => {
    const errorSubjectTokens = (
      tokens && tokensUsedInSubject ?
        tokensUsedInSubject.filter((name) => !tokens[name] || tokens[name].disabled) :
        []
    );
    const errorBodyTokens = (
      tokens && tokensUsedInBody ?
        tokensUsedInBody.filter((name) => !tokens[name] || tokens[name].disabled) :
        []
    );
    return [
      ...errorSubjectTokens,
      ...errorBodyTokens,
    ];
  }, [tokens, tokensUsedInSubject, tokensUsedInBody]);

  const resetEditorValues = () => {
    if (!originalEmailTemplate) {
      return;
    }
    setEmailTemplate(originalEmailTemplate);
    if (
      originalEmailTemplate.type === EmailTemplateType.ConfirmationEmail &&
      (
        emailTemplateTypeForPreview === EmailTemplateType.ConfirmationEmail ||
        emailTemplateTypeForPreview === EmailTemplateType.MultiBlockConfirmationEmail
      )
    ) {
      setEmailSubjectSlateValue(
        translateSlateValueBetweenEditorTypes(
          textToSlateValue(originalEmailTemplate.subject || ''),
          EditorType.ConfirmationEmail,
          emailTemplateTypeForPreview,
        )
      );
      setEmailBodySlateValue(
        translateSlateValueBetweenEditorTypes(
          htmlToSlateValue(originalEmailTemplate.body || ''),
          EditorType.ConfirmationEmail,
          emailTemplateTypeForPreview,
        )
      );
    } else {
      setEmailSubject(originalEmailTemplate.subject || '');
      setEmailBody(originalEmailTemplate.body || '');
    }
  };

  useEffect(() => {
    resetEditorValues();
  }, [originalEmailTemplate]);

  const handleTogglePreviewForMultiBlock = () => {
    const currentEditorType = emailTemplateTypeForPreview;
    if (currentEditorType !== EmailTemplateType.ConfirmationEmail &&
        currentEditorType !== EmailTemplateType.MultiBlockConfirmationEmail) {
      // This function should only be called if the email type is one of these.
      // This if statement is just here to inform Typescript that it's okay.
      return;
    }
    const newEditorType = currentEditorType === EmailTemplateType.ConfirmationEmail ? EmailTemplateType.MultiBlockConfirmationEmail : EmailTemplateType.ConfirmationEmail;
    setEmailTemplateTypeForPreview(newEditorType);
    setEmailSubjectSlateValue(translateSlateValueBetweenEditorTypes(emailSubjectSlateValue, currentEditorType, newEditorType));
    setEmailBodySlateValue(translateSlateValueBetweenEditorTypes(emailBodySlateValue, currentEditorType, newEditorType));
  };

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    resetEditorValues();
  };

  const handleSave = async () => {
    // Check that availability request link is included in availability request email
    if (emailTemplate?.type === EmailTemplateType.AvailabilityRequestEmail) {
      const body = slateValueToHtml(emailBodySlateValue);
      if (!/{{\s*AvailabilityRequest\.Link\s*}}/.test(body)) {
        setError(`The template must include the ${Token.AvailabilityRequestLink} token.`);
        return;
      }
    }
    // Check that self-scheduling request link is included in self-scheduling request email
    if (emailTemplate?.type === EmailTemplateType.SelfSchedulingRequestEmail) {
      const body = slateValueToHtml(emailBodySlateValue);
      if (!/{{\s*SelfSchedulingRequest\.Link\s*}}/.test(body)) {
        setError(`The template must include the ${Token.SelfSchedulingRequestLink} token.`);
        return;
      }
    }
    setError('');
    if (errorTokens.length > 0) {
      // We don't need to display an error because ErrorTokenFlash already takes
      // care of that. We just need to stop the execution from here.
      return;
    }

    const payload: UpdateEmailTemplatePayload = pick(emailTemplate, [
      'name',
      'subject',
      'sender_name',
      'sender_email',
      'cc_emails',
      'bcc_emails',
      'body',
      'attachments',
      'archived',
    ]);

    // Convert multi-block confirmation email tokens back to single-block before saving
    if (emailTemplateTypeForPreview === EmailTemplateType.MultiBlockConfirmationEmail) {
      payload.subject = slateValueToText(
        translateSlateValueBetweenEditorTypes(
          emailSubjectSlateValue, EditorType.MultiBlockConfirmationEmail, EditorType.ConfirmationEmail
        )
      );
      payload.body = slateValueToHtml(
        translateSlateValueBetweenEditorTypes(
          emailBodySlateValue, EditorType.MultiBlockConfirmationEmail, EditorType.ConfirmationEmail
        )
      );
    } else {
      payload.subject = slateValueToText(emailSubjectSlateValue);
      payload.body = slateValueToHtml(emailBodySlateValue);
    }

    try {
      await updateEmailTemplateMutation.mutateAsync({ id, payload });
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  const handleEmailNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setEmailTemplate((prevTemplate) => {
      if (!prevTemplate) {
        return;
      }
      return {
        ...prevTemplate,
        name,
      };
    });
  };

  const handleEmailAttachmentsChange = (fn: SetStateAction<EditorAttachment[]>) => {
    setEmailTemplate((prevTemplate) => {
      if (!prevTemplate) {
        return;
      }
      const attachments = typeof fn === 'function' ? fn(prevTemplate.attachments || []) : fn;
      return {
        ...prevTemplate,
        attachments,
      };
    });
  };

  return (
    <Section
      className="email-template-details-email"
      isEditable
      isEditing={isEditing}
      isSaving={updateEmailTemplateMutation.isLoading}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Email template"
    >
      <Flash
        message={error}
        showFlash={Boolean(error)}
        type="danger"
      />
      <Flash
        message={tokensError?.message}
        showFlash={Boolean(tokensError)}
        type="danger"
      />
      <Flash
        message={updateEmailTemplateMutation.error?.message}
        showFlash={updateEmailTemplateMutation.isError}
        type="danger"
      />
      <Flash
        isDismissible
        message="Successfully updated!"
        showFlash={updateEmailTemplateMutation.isSuccess}
        type="success"
      />
      <ErrorTokenFlash errorTokens={errorTokens} />
      <TextInput
        isDisabled={!isEditing}
        isRequired
        label="Template Name"
        onChange={handleEmailNameChange}
        value={emailTemplate?.name}
      />
      <div className="form-container">
        {tokens && (
          <TokenInput
            editor={emailSubjectSlateEditor}
            isDisabled={!isEditing}
            isRequired
            label="Email Subject"
            pendingPreviewMessage="This token will be filled in when sending an email to a candidate."
            setValue={setEmailSubjectSlateValue}
            tokens={tokens}
            type={emailTemplateTypeForPreview}
            value={emailSubjectSlateValue}
          />
        )}
      </div>
      {tokens && (
        <EditorInput
          allowImages
          attachments={emailTemplate?.attachments}
          editor={emailBodySlateEditor}
          isDisabled={!isEditing}
          label="Email Body"
          pendingPreviewMessage="This token will be filled in when sending an email to a candidate."
          setAttachments={handleEmailAttachmentsChange}
          setValue={setEmailBodySlateValue}
          tokens={tokens}
          type={emailTemplateTypeForPreview}
          value={emailBodySlateValue}
        />
      )}
      <EmailTemplateAdvancedSettings
        emailTemplate={emailTemplate}
        isDisabled={!isEditing}
        setEmailTemplate={setEmailTemplate}
      />
      {emailTemplate?.type === EmailTemplateType.ConfirmationEmail && (
        <ToggleInput
          isOn={emailTemplateTypeForPreview === EmailTemplateType.MultiBlockConfirmationEmail}
          onChange={handleTogglePreviewForMultiBlock}
          onLabel="Preview for multi-block schedule."
        />
      )}
    </Section>
  );
};

export default EmailTemplateSection;
