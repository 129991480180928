import { find, flatten, isEmpty, keyBy, mapValues, orderBy } from 'lodash';
import Moment from 'moment-timezone';
import { useEffect, useMemo, useState } from 'react';

import Avatar from 'components/library/data-display/Avatar';
import Button from 'components/library/inputs/Button';
import DoNotDisturbIcon from 'components/library/data-display/DoNotDisturbIcon';
import ListItem from 'components/library/data-display/ListItem';
import PopoverSelectInput from 'components/library/inputs/PopoverSelectInput';
import { useLDFlags } from 'hooks/use-ld-flags';
import { useResolveUsers, useUsersMap } from 'hooks/queries/users';
import { useTrainingPrograms } from 'hooks/queries/training-programs';
import { useTrainingProgramUsers } from 'hooks/queries/training-program-users';
import { trainingProgramEligibilityOfSlot } from 'libraries/training';
import CalendarScheduleDetailsPane from './CalendarScheduleDetailsPane';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Group, Option as SelectInputOption } from 'components/library/inputs/SelectInput/types';
import type { Event as CalendarEvent } from 'types';
import type { DetailEventInterview, DetailEventTrainingSession, InterviewerTemplate, PotentialUser } from './types';

export interface Option extends SelectInputOption<string> {
  conflicts: CalendarEvent[] | null;
  interview_load: DetailEventInterview[];
  training_sessions: DetailEventTrainingSession[];
}

interface Props {
  applicationId?: string;
  endTime: Date;
  interviewerTemplate?: InterviewerTemplate;
  onAdd?: (interviewerId: string, optional: boolean, trainingProgramEligibility: string | null) => void;
  onChange?: (newValue: OnChangeValue<Option, false>, actionMeta: ActionMeta<Option>) => void;
  onOptionalityChange?: (optional: boolean) => void;
  onRemove?: () => void;
  onTrainingProgramChange?: (option: OnChangeValue<SelectInputOption<string>, false>, actionMeta: ActionMeta<SelectInputOption<string>>) => void;
  potentialUsers: PotentialUser[];
  scheduleId?: string;
  selectedUserId: string | null;
  startTime: Date;
  targetId: string;
  timezone: string;
}

const CalendarScheduleInterviewerSelectInput = ({
  applicationId,
  endTime,
  interviewerTemplate,
  onAdd,
  onChange,
  onOptionalityChange,
  onTrainingProgramChange,
  onRemove,
  potentialUsers,
  scheduleId,
  selectedUserId,
  startTime,
  targetId,
  timezone,
}: Props) => {
  const { interviewerTrainingPlatform } = useLDFlags();

  const [interviewerPool, setInterviewerPool] = useState<PotentialUser[]>([]);
  const [additionalInterviewers, setAdditionalInterviewers] = useState<PotentialUser[]>([]);
  const [selectedInterviewerId, setSelectedInterviewerId] = useState(selectedUserId);
  const [optional, setOptional] = useState(interviewerTemplate?.optional || false);
  const [selectedTrainingProgramEligibility, setSelectedTrainingProgramEligibility] = useState(interviewerTrainingPlatform ? trainingProgramEligibilityOfSlot(interviewerTemplate?.interviewer_filters || []) : null);

  const users = useUsersMap({ archived: true });
  const { data: resolvedUsers } = useResolveUsers({
    applicationId,
    scheduleId,
    includePastInterviewers: interviewerTemplate?.include_past_interviewers ?? true,
    interviewerFilters: interviewerTemplate?.interviewer_filters || [],
  });
  const { data: trainingPrograms } = useTrainingPrograms();
  const selectedTrainingProgramId = (trainingPrograms?.training_programs || []).find(({ eligibility }) => eligibility === selectedTrainingProgramEligibility)?.id || '';
  const { data: trainingProgramTrainees } = useTrainingProgramUsers(
    selectedTrainingProgramId,
    { graduated: false },
    { enabled: Boolean(selectedTrainingProgramId) }
  );

  const traineeToTrainingSessionsMap = useMemo<Record<string, DetailEventTrainingSession[]>>(() => {
    if (trainingProgramTrainees) {
      return mapValues(
        keyBy(trainingProgramTrainees.users, 'id'),
        ((trainee) => (trainee.training_sessions || []).map<DetailEventTrainingSession>((trainingSession) => ({
          title: trainingSession.interviewer?.interview?.name || 'Manually Recorded Training',
          phase_name: trainingSession.phase_name,
          start_time: trainingSession.interviewer?.interview?.start_time,
          end_time: Moment(trainingSession.interviewer?.interview?.start_time).add(trainingSession.interviewer?.interview?.interview_template?.duration_minutes, 'minutes').format(),
          candidate: trainingSession.interviewer?.interview?.schedule?.application?.candidate?.name,
          job: trainingSession.interviewer?.interview?.schedule?.application?.job?.name,
        })))
      );
    }
    return {};
  }, [trainingProgramTrainees]);

  useEffect(() => {
    if (isEmpty(users)) {
      return;
    }
    if (!isEmpty(resolvedUsers)) {
      const resolvedUserIds = resolvedUsers!.map(({ id }) => id);
      setInterviewerPool(potentialUsers.filter((user) => resolvedUserIds.includes(user.user_id!)));
      setAdditionalInterviewers(potentialUsers.filter((user) => !resolvedUserIds.includes(user.user_id!)));
    } else {
      if (potentialUsers.length === Object.values(users).filter(({ ats_id, directory_archived, user_archived }) => !user_archived && !directory_archived && Boolean(ats_id)).length) {
        setAdditionalInterviewers(potentialUsers);
      } else {
        setInterviewerPool(potentialUsers);
      }
    }
  }, [resolvedUsers, potentialUsers, users]);

  useEffect(() => {
    setSelectedInterviewerId(selectedUserId);
  }, [selectedUserId]);

  useEffect(() => {
    setOptional(interviewerTemplate?.optional || false);
  }, [interviewerTemplate?.optional]);

  useEffect(() => {
    setSelectedTrainingProgramEligibility(interviewerTrainingPlatform ? trainingProgramEligibilityOfSlot(interviewerTemplate?.interviewer_filters || []) : null);
  }, [interviewerTemplate?.interviewer_filters]);

  // If conflicts are null, it means that they haven't been loaded yet, so we'll
  // be showing a message to tell the user to click to load, and we want these
  // items to go to the bottom of the list, so we make the sort order 999.
  const options = useMemo<Group<string, Option>[]>(() => [{
    label: 'Interviewer Pool',
    options: orderBy(interviewerPool.map(({ user_id, conflicts, interview_load }) => ({
      value: user_id!,
      label: users[user_id!]?.name || users[user_id!]?.email,
      conflicts: conflicts === undefined ? [] : conflicts,
      interview_load: interview_load || [],
      training_sessions: traineeToTrainingSessionsMap[user_id!] || undefined,
    })), [({ conflicts }) => conflicts === null ? 999 : conflicts ? conflicts.length : 0, 'label']),
  }, {
    label: 'All Team Members',
    options: orderBy(additionalInterviewers.map(({ user_id, conflicts, interview_load }) => ({
      value: user_id!,
      label: users[user_id!]?.name || users[user_id!]?.email,
      conflicts: conflicts === undefined ? [] : conflicts,
      interview_load: interview_load || [],
      training_sessions: traineeToTrainingSessionsMap[user_id!] || undefined,
    })), [({ conflicts }) => conflicts === null ? 999 : conflicts ? conflicts.length : 0, ({ interview_load }) => interview_load.length, 'label']),
  }], [interviewerPool, additionalInterviewers, users, traineeToTrainingSessionsMap]);

  const handleChange = onChange || ((option: OnChangeValue<Option, false>) => {
    setSelectedInterviewerId(option?.value || null);
  });

  const handleOptionalityChange = onOptionalityChange || ((optional: boolean) => {
    setOptional(optional);
  });

  const handleTrainingProgramChange = onTrainingProgramChange || ((option: OnChangeValue<SelectInputOption<string>, false>) => {
    setSelectedTrainingProgramEligibility(option?.value || null);
  });

  const handleAdd = onAdd && (() => {
    onAdd(selectedInterviewerId!, optional, selectedTrainingProgramEligibility);
    setSelectedInterviewerId(null);
    setOptional(false);
    setSelectedTrainingProgramEligibility(null);
  });

  const handleRemove = onRemove && (() => onRemove());

  const addActionButton = handleAdd && (
    <Button
      className="btn-action btn-action-add"
      color="gem-blue"
      isDisabled={!selectedInterviewerId}
      onClick={() => handleAdd()}
      size="small"
      value="Add Interviewer"
    />
  );

  const removeActionButton = handleRemove && (
    <Button
      className="btn-action btn-action-remove"
      color="gem-outline"
      isDisabled={!selectedInterviewerId}
      onClick={() => handleRemove()}
      size="small"
      value="Remove Interviewer"
    />
  );

  const actionButton = addActionButton || removeActionButton || null;

  return (
    <PopoverSelectInput<string, Option, false>
      actionButton={actionButton}
      className="calendar-schedule-popover-select interviewers"
      displayDetails={(option) => (
        <CalendarScheduleDetailsPane
          conflicts={option!.conflicts}
          date={startTime}
          id={option!.value}
          interviews={option!.interview_load}
          onOptionalityChange={handleOptionalityChange}
          onTrainingProgramChange={handleTrainingProgramChange}
          optional={optional}
          resource="user"
          selectedTrainingProgram={selectedTrainingProgramEligibility}
          showOptionalityHeader
          showTrainingProgramHeader={interviewerTrainingPlatform}
          timezone={timezone}
          trainingSessions={option!.training_sessions}
        />
      )}
      formatOptionLabel={(option, { context }) => {
        const nonIgnoredConflictsCount = (option.conflicts || []).filter(({ ignored }) => !ignored).length;
        return (
          context === 'menu' ?
            <ListItem
              label={option.label}
              leftIcon={
                <Avatar
                  showUserTooltip={false}
                  size="small"
                  status={nonIgnoredConflictsCount > 0 ? 'warning' : undefined}
                  userId={option.value}
                />
              }
              rightIcon={
                <DoNotDisturbIcon
                  endTime={endTime}
                  startTime={startTime}
                  userId={option.value}
                />
              }
              secondaryText={(
                option.conflicts !== null ?
                  <span>
                    <span className={nonIgnoredConflictsCount > 0 ? 'warning' : undefined}>
                      {nonIgnoredConflictsCount || '0'}
                    </span> conflict{nonIgnoredConflictsCount === 1 ? '' : 's'},&nbsp;
                    <span className={option.interview_load.length > 2 ? 'warning' : undefined}>
                      {option.interview_load.length || '0'}
                    </span> interview{option.interview_load.length === 1 ? '' : 's'} this week
                  </span> :
                  <span>Click to load conflicts</span>
              )}
            /> :
            option.label
        );
      }}
      onChange={handleChange}
      options={options}
      placeholder="Select an interviewer"
      targetId={targetId}
      value={selectedInterviewerId ? find(flatten(options.map((option) => option.options)), ['value', selectedInterviewerId]) : null}
    />
  );
};

export default CalendarScheduleInterviewerSelectInput;
