import Banner from 'components/library/utils/Banner';
import gql from 'graphql-tag';
import { useSession } from 'hooks/use-session';
import { useMutation } from '@apollo/client';
import { useState } from 'react';

const endSuMutation = gql`
  mutation SuBannerEnd {
    endSu {
      ok
    }
  }
`;

export interface SuBannerEndMutation {
 __typename: 'Mutation'; endSu: { __typename: 'EndSu'; ok: boolean | null } | null;
}

const AssumedUserBanner = () => {
  const { session } = useSession();

  const [error, setError] = useState<string>();

  const [endSu] = useMutation<SuBannerEndMutation>(endSuMutation);

  const onEndSu = async () => {
    const { data } = await endSu();
    if (data?.endSu?.ok) {
      window.location.href = `/support/team/${session?.assumed_team_id}`;
    } else {
      setError('Sorry! Something went wrong.');
      // eslint-disable-next-line no-console
      console.log('Error!', data);
    }
  };

  if (!session?.is_super_user) {
    return null;
  }

  return (
    <Banner type="warning">
      You are viewing Gem Scheduling as an assumed user <b>({session.assumed_user_id})</b> on team <b>({session.assumed_team_id})</b> ({session.is_super_user_read_only ? <span>with read permissions</span> : <span className="red-text">with write permissions</span>}). If you want to end the session <a onClick={onEndSu}>click here</a>.
      {error && (
        <>
          <br />
          <span className="red-text">{error}</span>
        </>
      )}
    </Banner>
  );
};

export default AssumedUserBanner;
