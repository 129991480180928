import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationCircle } from '@fortawesome/free-solid-svg-icons';
import { find, uniqueId } from 'lodash';
import { useMemo, useRef } from 'react';
import { useFocused, useSelected } from 'slate-react';

import Leaf from './Leaf';
import Popover from '../../utils/Popover';
import { SUGGESTIONS_BY_TYPE } from '../../../../libraries/editor';

import type { EditorType, TokenElement, TokensResponse } from '../../../../types';
import type { RenderElementProps } from 'slate-react';

interface TokenError {
  prefix: string;
  error: string;
}

const candidateTimezoneError = 'The candidate hasn\'t submitted availability, so we don\'t know what timezone they\'re in. If you know their timezone, you can set it on the Availability step.';

const TOKEN_ERRORS: TokenError[] = [{
  prefix: 'AvailabilityRequest.Link',
  error: 'This candidate has no active availability requests.',
}, {
  prefix: 'Candidate.Email',
  error: 'This candidate has no email.',
}, {
  prefix: 'Candidate.LinkedInURL',
  error: 'This candidate has no LinkedIn URL.',
}, {
  prefix: 'Candidate.Website',
  error: 'This candidate has no website.',
}, {
  prefix: 'Candidate.PhoneNumber',
  error: 'This candidate has no phone number.',
}, {
  prefix: 'Coordinator',
  error: 'This candidate has no coordinator.',
}, {
  prefix: 'HiringManager',
  error: 'This candidate has no hiring manager.',
}, {
  prefix: 'Sourcer',
  error: 'This candidate has no sourcer.',
}, {
  prefix: 'Recruiter',
  error: 'This candidate has no recruiter.',
}, {
  prefix: 'Job.Office',
  error: 'This job has no associated office.',
}, {
  prefix: 'Schedule.AgendaInCandidateTimezone',
  error: candidateTimezoneError,
}, {
  prefix: 'Schedule.CandidateTimezone',
  error: candidateTimezoneError,
}, {
  prefix: 'Schedule.DateInCandidateTimezone',
  error: candidateTimezoneError,
}, {
  prefix: 'Schedule.DateWithoutYearInCandidateTimezone',
  error: candidateTimezoneError,
}, {
  prefix: 'Schedule.DayOfTheWeekInCandidateTimezone',
  error: candidateTimezoneError,
}, {
  prefix: 'Schedule.DatesInCandidateTimezone',
  error: candidateTimezoneError,
}, {
  prefix: 'Schedule.DatesWithoutYearInCandidateTimezone',
  error: candidateTimezoneError,
}, {
  prefix: 'Schedule.DaysOfTheWeekInCandidateTimezone',
  error: candidateTimezoneError,
}, {
  prefix: 'Schedule.EndTimeInCandidateTimezone',
  error: candidateTimezoneError,
}, {
  prefix: 'Schedule.Location',
  error: 'You have not added a location to this schedule\'s candidate calendar event.',
}, {
  prefix: 'Schedule.StartTimeInCandidateTimezone',
  error: candidateTimezoneError,
}, {
  prefix: 'Schedule.VideoConferencingLink',
  error: 'You have not attached video conferencing to this schedule.',
}, {
  prefix: 'Schedule.VideoConferencingLinks',
  error: 'You have not attached video conferencing to this schedule.',
}, {
  prefix: 'Schedule.VideoConferencingPasscode',
  error: 'There is no video conferencing passcode attached to this schedule.',
}, {
  prefix: 'Schedule.VideoConferencingPasscodes',
  error: 'There are no video conferencing passcodes attached to these schedule.',
}, {
  prefix: 'Schedule.Room',
  error: 'This schedule does not take place in any room.',
}, {
  // This needs to be after all of the other Schedule tokens so that those take
  // precedence.
  prefix: 'Schedule',
  error: 'This candidate has no upcoming schedules.',
}, {
  prefix: 'Requester',
  error: 'This candidate has no active availability/self-scheduling requests.',
}, {
  prefix: 'SelfSchedulingRequest.Link',
  error: 'This candidate has no active self-scheduling requests.',
}, {
  prefix: 'Interview.Name',
  error: 'This token isn\'t allowed when the schedule has more than 1 interview.',
}, {
  prefix: 'Interview.ScorecardLink',
  error: 'This interview does not have a scorecard.',
}];

export const getTokenError = (text: string, isValidToken: boolean) => {
  if (isValidToken) {
    const error = find<TokenError>(TOKEN_ERRORS, ({ prefix }) => text.startsWith(prefix));
    if (error) {
      return error.error;
    }
  }
  return `${text} is not a valid token.`;
};

interface Props extends Partial<RenderElementProps> {
  pendingPreviewMessage: string;
  tokens?: TokensResponse;
  type: `${EditorType}`;
}

const Token = ({ attributes, children, element, pendingPreviewMessage, tokens, type }: Props) => {
  // We know this will only be called with TokenElements.
  const text = (element as TokenElement).token;
  const id = useMemo(() => uniqueId(`token-${text.replace(/[\s\\.,]/g, '')}-`), [text]);

  const isSelected = useSelected();
  const isFocused = useFocused();

  const token = tokens?.[text];
  const validTokensForType = useMemo(() => SUGGESTIONS_BY_TYPE[type].map(({ value }) => value), [type]);
  const isValidToken = Boolean(token && !token.excluded) || validTokensForType.includes(text);
  const isError = !isValidToken || token?.disabled;

  // While in most cases, attributes.ref will be set, if we use this component
  // directly, it should still work without a ref being passed through.
  const ref = useRef(null);

  return (
    <span
      className={classnames([
        'editor-token',
        isError && 'error',
        isSelected && isFocused && 'focused',
      ])}
      contentEditable={false}
      data-for={`${id}-tooltip`}
      data-tip
      id={id}
      // @ts-ignore
      ref={ref}
      // This needs to come at the end to avoid weird selection errors.
      {...attributes}
    >
      {/*We use Leaf here since it already has rich text support, and we don't
      have to duplicate that.*/}
      <Leaf leaf={{ ...element, text }} text={{ ...element, text }}>{text}</Leaf>
      {isError && <span className="token-alert"><FontAwesomeIcon icon={faExclamationCircle} /></span>}
      <Popover
        className={classnames(['editor-token-preview-popover', text.startsWith('Schedule.Agenda') && 'hide-arrow'])}
        position={text.startsWith('Schedule.Agenda') ? 'right' : 'bottom'}
        target={attributes?.ref || ref}
      >
        <h6>{isError ? 'Error' : 'Preview'}</h6>
        {token?.value && <span dangerouslySetInnerHTML={{ __html: token.value }} />}
        {!(token?.value) && (
          <span className="pending">
            {isError ? getTokenError(text, isValidToken) : pendingPreviewMessage}
          </span>
        )}
        {!(token?.value) && token?.example && !isError && (
          <>
            <h6 className="example-header">Example Token Value</h6>
            <span dangerouslySetInnerHTML={{ __html: token.example }} />
          </>
        )}
      </Popover>
      {children}
    </span>
  );
};

export default Token;
