import { orderBy } from 'lodash';
import { useEffect, useState } from 'react';
import { Breadcrumb } from 'react-breadcrumbs';
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';

import Section from 'components/library/layout/Section';
import Flash from 'components/library/utils/Flash';
import { useInterviewBatch, useUpdateInterviewBatch } from 'hooks/queries/interview-batches';
import { correctPath } from 'libraries/gem';
import { constructEditableBatchTime, constructUpdatePayload } from './helpers';
import InterviewBatchTimesTable from './InterviewBatchTimesTable';
import EditSuperdayTimesTable from '../../EditSuperdayTimesTable';
import { StyledTabContainer } from '../styles';

import type { EditableInterviewBatchTime } from 'types';

const InterviewBatchTimes = () => {
  const { id: interviewBatchId } = useParams<{ id: string }>();

  const [times, setTimes] = useState<EditableInterviewBatchTime[]>([]);
  const [isEditing, setIsEditing] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { data: interviewBatch, refetch: refetchBatch } = useInterviewBatch(interviewBatchId);

  const updateInterviewBatchMutation = useUpdateInterviewBatch();

  useEffect(() => {
    if (interviewBatch) {
      setTimes(orderBy(interviewBatch.times.map(constructEditableBatchTime), 'start_time'));
    }
  }, [interviewBatch]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setTimes((interviewBatch?.times || []).map(constructEditableBatchTime));
    setIsEditing(false);
  };

  const handleSave = async () => {
    updateInterviewBatchMutation.reset();
    const payload = constructUpdatePayload(times);

    setIsSaving(true);
    try {
      await updateInterviewBatchMutation.mutateAsync({ id: interviewBatchId, payload });
      await refetchBatch();
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
    setIsSaving(false);
  };

  return (
    <Breadcrumb
      data={{
        title: 'Interview Times',
        pathname: correctPath(`/app/superdays/${interviewBatchId}/times`),
      }}
    >
      <StyledTabContainer>
        <Helmet>
          <title>{interviewBatch?.name} | Times | Superdays | Gem Scheduling</title>
        </Helmet>
        <Section
          isEditable
          isEditing={isEditing}
          isSaving={isSaving}
          onCancel={handleCancel}
          onEdit={handleEdit}
          onSave={handleSave}
          title="Interview times"
        >
          <Flash
            message={updateInterviewBatchMutation.error?.message}
            showFlash={updateInterviewBatchMutation.isError}
            type="danger"
          />
          <Flash
            isDismissible
            message="Successfully updated!"
            showFlash={updateInterviewBatchMutation.isSuccess}
            type="success"
          />
          {isEditing ? (
            <EditSuperdayTimesTable
              jobId={interviewBatch?.job_id}
              setTimes={setTimes}
              times={times}
            />
          ) : (
            <InterviewBatchTimesTable
              times={orderBy(interviewBatch?.times || [], 'start_time')}
              totalCount={times.length}
            />
          )}
        </Section>
      </StyledTabContainer>
    </Breadcrumb>
  );
};

export default InterviewBatchTimes;
