import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { pullAt, uniqueId } from 'lodash';
import { useMemo } from 'react';

import Button from '../Button';
import InterviewerFilterInput from './InterviewerFilterInput';
import Tooltip from '../../utils/Tooltip';
import { InterviewerFilterableType } from '../../../../types';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Props as FilterInputProps, Option } from './InterviewerFilterInput';
import type { ReactNode } from 'react';

const INTERVIEWER_FILTER_INPUT_NAME_PREFIX = 'interviewer-filter-';

const createEmptyInterviewerFilter = (): Filter => ({
  interviewer_filter_expressions: [],
});

export interface Filter {
  interviewer_filter_expressions: FilterInputProps['selectedFilterExpressions'];
}

interface Props {
  applicationId?: string;
  className?: string;
  filters: Filter[];
  isDisabled: boolean;
  helperText?: ReactNode;
  id?: string;
  label?: string;
  name?: string;
  onChange: (newValue: Filter[], name?: string) => void;
}

const InterviewerFiltersBuilder = ({
  applicationId,
  className,
  filters,
  helperText,
  id,
  isDisabled = false,
  label,
  name,
  onChange,
}: Props) => {
  id = useMemo(() => id || uniqueId('interviewer-filters-builder-'), [id]);

  const hasTrainingEligibility = useMemo(() => {
    return filters.some(({ interviewer_filter_expressions }) => {
      return interviewer_filter_expressions.some((exp) => exp.filterable_type === InterviewerFilterableType.Training);
    });
  }, [filters]);

  const handleAddRule = () => {
    onChange([
      ...filters,
      createEmptyInterviewerFilter(),
    ], name);
  };

  const handleRemoveRule = (i: number) => {
    const filtersCopy = filters.slice();
    pullAt(filtersCopy, [i]);
    onChange(filtersCopy, name);
  };

  const handleFilterChange = (options: OnChangeValue<Option, true>, actionMeta: ActionMeta<Option>) => {
    const index = parseInt(actionMeta.name?.replace(INTERVIEWER_FILTER_INPUT_NAME_PREFIX, '') || '-1', 10);
    const selectedFilter = {
      interviewer_filter_expressions: (options ?
        options.map((option) => ({
          filterable_id: option.filterable_id,
          filterable_type: option.filterable_type,
          negated: option.negated,
        })) :
        []
      ),
    };
    onChange(filters.map((filter, i) => (i === index ? selectedFilter : filter)), name);
  };

  return (
    <div className={`input interviewer-filters-builder${className ? ` ${className}` : ''}`}>
      {label && <label htmlFor={id}>{label}</label>}
      {filters.map((filter, i) => (
        <div
          className="filter-input-container"
          key={`filter-input-${i}`}
        >
          {i > 0 && <span className="filter-separator">or</span>}
          <InterviewerFilterInput
            applicationId={applicationId}
            filterCount={filters.length}
            isDisabled={isDisabled}
            name={`interviewer-filter-${i}`}
            onChange={handleFilterChange}
            selectedFilterExpressions={filter.interviewer_filter_expressions}
          />
          {!isDisabled && filters.length > 1 &&
            <Button
              className="btn-delete"
              color="no-outline"
              iconRight={<FontAwesomeIcon icon={faTimes} />}
              onClick={() => handleRemoveRule(i)}
              size="small"
              tooltip={
                <Tooltip
                  id={`remove-interviewer-filter-${i}-button`}
                  position="top"
                  value="Remove"
                />
              }
            />
          }
        </div>
      ))}
      {helperText && <div className="helper-text">{helperText}</div>}
      {!isDisabled &&
        <Button
          className="btn-add-rule"
          color="no-outline"
          iconLeft={<FontAwesomeIcon icon={faPlus} />}
          isDisabled={hasTrainingEligibility}
          onClick={handleAddRule}
          size="small"
          tooltip={(hasTrainingEligibility ?
            <Tooltip
              id={`${id}-add-alternate-rule`}
              position="top"
              value="You can't add alternate rules when selecting a trainee."
            /> :
            undefined
          )}
          value="Add Alternate Rule"
        />
      }
    </div>
  );
};

export default InterviewerFiltersBuilder;
