import Flash from './Flash';
import TokenErrorList from '../data-display/TokenErrorList';

interface Props {
  className?: string;
  errorTokens: string[];
}

const ErrorTokenFlash = ({ className, errorTokens }: Props) => {
  return (
    <Flash
      className={className}
      message={(
        <>
          <p>You must remove or replace the following tokens before continuing.</p>
          <TokenErrorList errorTokens={errorTokens} />
        </>
      )}
      showFlash={errorTokens.length > 0}
      type="warning"
    />
  );
};

export default ErrorTokenFlash;
