import { useCallback } from 'react';

import { useCreateAssignment, useDeleteAssignment } from 'hooks/queries/assignments';
import { StyledUserSelectInput } from './styles';

import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from 'components/library/inputs/SelectInput/types';
import type { Assignment } from 'types';

interface Props {
  applicationId: string;
  assignment?: Assignment;
}

const AssigneeHoverInput = ({ applicationId, assignment }: Props) => {
  const createAssignmentMutation = useCreateAssignment();
  const deleteAssignmentMutation = useDeleteAssignment();

  const handleChange = useCallback((option: OnChangeValue<Option<string>, false>) => {
    if (option?.value) {
      const payload = {
        application_id: applicationId,
        assignee_id: option.value,
      };
      createAssignmentMutation.mutate({ payload });
    } else {
      assignment && deleteAssignmentMutation.mutate({ id: assignment.id });
    }
  }, [applicationId, assignment, createAssignmentMutation]);

  return (
    <StyledUserSelectInput
      currentUserFirst
      isClearable
      isDisabled={createAssignmentMutation.isLoading || deleteAssignmentMutation.isLoading}
      onChange={handleChange}
      placeholder="Unassigned"
      value={assignment?.assignee_id}
    />
  );
};

export default AssigneeHoverInput;
