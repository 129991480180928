import { useEffect, useState } from 'react';
import { useLDClient } from 'launchdarkly-react-client-sdk';

import { useSession } from '../../../../hooks/use-session';

import type { ReactNode } from 'react';

interface Props {
  children: ReactNode;
}

// This is a small component that watches changes to the session and updates
// LaunchDarkly as it changes. This needs to be separate since it needs to be
// nested within the LDProvider to use useLDClient successfully.
const LaunchDarklyIdentifier = ({ children }: Props) => {
  const { currentUser } = useSession();
  const ldClient = useLDClient();

  const [identifiedUser, setIdentifiedUser] = useState(false);

  useEffect(() => {
    if (currentUser) {
      ldClient?.identify({
        key: currentUser.id,
        name: currentUser.name,
        email: currentUser.email,
        custom: {
          account_id: currentUser.account_id,
        },
      }).then(() => setIdentifiedUser(true));
    }
  }, [currentUser]);

  if (!identifiedUser) {
    // We haven't identified the user yet, so render null. This is to avoid
    // flashes of unresolved feature flags. Even if LaunchDarkly can't be
    // reached (e.g. because of an AdBlocker), it will fail open, so this
    // rendering of null should be very brief.
    return null;
  }

  return <>{children}</>;
};

export default LaunchDarklyIdentifier;
