import { uniq } from 'lodash';
import Moment from 'moment-timezone';
import { useCallback, useMemo } from 'react';

import DatePicker from 'components/library/inputs/DatePicker';
import Popover from 'components/library/utils/Popover';
import theme from 'theme/gem';

import type { AvailabilityTimeSlot } from './types';

interface Props {
  availabilityTimeSlots: AvailabilityTimeSlot[];
  date: Date;
  onChange: (day: Date) => void;
  targetId: string;
  timezone: string;
}

const CalendarScheduleDateInput = ({ availabilityTimeSlots, date, onChange, targetId, timezone }: Props) => {
  const availableDates = useMemo(() => uniq(
    availabilityTimeSlots.map(({ start }) => Moment(start).tz(timezone).format('YYYY-MM-DD'))
  ), [availabilityTimeSlots, timezone]);

  const hasAvailabilityOnDate = useCallback((dateOption: Date) => {
    const isoDate = Moment(dateOption).tz(timezone).format('YYYY-MM-DD');
    return availableDates.includes(isoDate);
  }, [availableDates, timezone]);

  // This normalizes the date into a JS Date that's correct according to the passed in timezone. For example, the passed
  // in date could be 2023-01-05 23:00 CT and the timezone is America/New_York, so the expected selected date should be
  // 2023-01-06, so we fake it to make sure that the correct date is passed through.
  const normalizedDate = useMemo(() => Moment(Moment.tz(date, timezone).format('YYYY-MM-DD'), 'YYYY-MM-DD').toDate(), [date, timezone]);

  return (
    <Popover
      className="calendar-schedule-date-input"
      // key is being used to force a remounting of the Popover component
      // whenever the targetId changes. This is necessary due to the bug in
      // UncontrolledPopover. Read the comment in utils/Popover.tsx for more
      // information.
      key={targetId}
      position="bottom"
      target={targetId}
    >
      <DatePicker
        brandColor={theme.colors.BRAND.GEM_BLUE}
        dayTooltipText={{
          available: 'Candidate is available',
        }}
        isRange={false}
        modifiers={{ available: hasAvailabilityOnDate }}
        modifiersStyles={{
          available: {
            boxShadow: `0px 0px 0px 1px ${theme.colors.BRAND.GEM_BLUE} inset`,
            borderRadius: '5px',
          },
        }}
        onChange={onChange}
        value={normalizedDate}
      />
    </Popover>
  );
};

export default CalendarScheduleDateInput;
