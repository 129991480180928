import { useCallback } from 'react';

import { StyledTimeRangeInput } from './styles';

import type { EditableBusinessHour } from '../../../../types/business-hours';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../SelectInput/types';

interface Props {
  businessHour: EditableBusinessHour;
  isDisabled: boolean;
  isTimezoneClearable?: boolean;
  onBusinessHourChange: (businessHour: EditableBusinessHour) => void;
}

const BusinessHoursTimeRangeInput = ({ businessHour, isDisabled, isTimezoneClearable, onBusinessHourChange }: Props) => {
  const handleWindowEndChange = useCallback((option: OnChangeValue<Option<string>, false>) => {
    onBusinessHourChange({
      ...businessHour,
      end_time: option?.value || '',
    });
  }, [businessHour, onBusinessHourChange]);

  const handleWindowStartChange = useCallback((option: OnChangeValue<Option<string>, false>) => {
    onBusinessHourChange({
      ...businessHour,
      start_time: option?.value || '',
    });
  }, [businessHour, onBusinessHourChange]);

  const handleTimezoneChange = useCallback((option: OnChangeValue<Option<string>, false>) => {
    onBusinessHourChange({
      ...businessHour,
      timezone: option?.value,
    });
  }, [businessHour, onBusinessHourChange]);

  return (
    <StyledTimeRangeInput
      areTimesClearable
      endValue={businessHour.end_time}
      isDisabled={isDisabled}
      isTimezoneClearable={isTimezoneClearable}
      isTimezoneRequired={isTimezoneClearable !== undefined ? !isTimezoneClearable : undefined}
      onEndChange={handleWindowEndChange}
      onStartChange={handleWindowStartChange}
      onTimezoneChange={handleTimezoneChange}
      startValue={businessHour.start_time}
      timezoneValue={businessHour.timezone || undefined}
      withTimezone
    />
  );
};

export default BusinessHoursTimeRangeInput;
