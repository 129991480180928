import styled from 'styled-components';

export const StyledContainer = styled.div`
  padding-bottom: 50px;
  width: 100%;
`;

export const StyledHeaderActions = styled.div`
  display: flex;
  align-items: end;
  gap: .5em;

  @media only screen and (max-width: 750px) {
    flex-direction: column;
  }

  a {
    padding: 0;
  }
`;

export const StyledFiltersContainer = styled.div`
  display: flex;
  margin-bottom: 1em;

  @media only screen and (max-width: 750px) {
    flex-direction: column;
  }

  .text-input {
    flex-grow: 1;
    margin-bottom: .5em;
    max-width: 50%;
    min-width: 500px;

    @media only screen and (max-width: 750px) {
      max-width: 100%;
    }
  }
`;

export const StyledTableContainer = styled.div`
  .flash {
    margin-bottom: 1em;
  }

  .list-item {
    padding: 0;

    /* This is needed to make a row with a link look the same as a row without a link. */
    &.extra-padding {
      padding: 13.5px 0;
    }
  }
`;

export const StyledTableName = styled.div`
  display: flex;
  align-items: center;

  /* This is for the archived label. */
  .label {
    margin-left: 1em;
  }
`;

export const StyledTableDate = styled.span`
  white-space: nowrap;
`;

export const StyledTableActionButtonsContainer = styled.div`
  display: flex;
  align-items: center;
`;
