import { useState } from 'react';

import CheckboxInput from '../../../../../library/inputs/CheckboxInput';
import Modal from '../../../../../library/layout/Modal';
import pluralize from '../../../../../../libraries/pluralize';

import type { ChangeEvent } from 'react';

interface Props {
  createSchedule: (payload: any) => Promise<void>;
  isOpen: boolean;
  onToggle: () => void;
  schedulePayload: any;
}

const PutSchedulesOnHoldModal = ({ createSchedule, isOpen, onToggle, schedulePayload }: Props) => {
  const [sendInterviewerNotifications, setSendInterviewerNotifications] = useState<boolean>(schedulePayload.send_interviewer_notifications);
  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);

  const handleSendInterviewerNotificationsChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSendInterviewerNotifications(e.target?.checked);
  };

  const onSubmit = async () => {
    setIsSubmitting(true);
    await createSchedule({
      ...schedulePayload,
      send_interviewer_notifications: sendInterviewerNotifications,
    });
    setIsSubmitting(false);
  };

  return (
    <Modal
      cancelButtonValue="Back to Review"
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
      onToggle={onToggle}
      submitButtonColor="gem-outline"
      submitButtonValue={`Put ${pluralize('schedule', schedulePayload.schedules.length)} on hold`}
      submittingButtonValue="Submitting..."
      title="Notify interviewers?"
    >
      <p>
        We will create events on your interviewers&apos; calendars with <b>[HOLD]</b> in the title.
      </p>
      <div className="modal-checkbox-options">
        <CheckboxInput
          isChecked={sendInterviewerNotifications}
          label="Send a calendar event creation email to interviewers."
          onChange={handleSendInterviewerNotificationsChange}
        />
      </div>
    </Modal>
  );
};

export default PutSchedulesOnHoldModal;
