import classNames from 'classnames';
import type { ReactNode } from 'react';

interface Props {
  className?: string;
  value?: ReactNode;
}

const Badge = ({ className, value }: Props) => (
  value ?
    <div className={classNames('badge', className)}>
      {value}
    </div> :
    null
);

export default Badge;
