import { differingValues } from '../../../../../../../../libraries/differing-values';
import { pick } from 'lodash';

import { sortPositions } from '../../../../../../../../libraries/interview-templates';

import type { InterviewTemplate } from '../../../../../../../../types';
import type { InterviewTemplatePayload } from './types';
import type { UpdateInterviewTemplatePayload } from '../../../../../../../../hooks/queries/interview-templates';

export const constructUpdateInterviewTemplatePayload = (interviewTemplate: InterviewTemplatePayload, originalInterviewTemplate: InterviewTemplate): UpdateInterviewTemplatePayload => {
  const updatePayload = differingValues({
    ...interviewTemplate,
    positions: sortPositions(interviewTemplate.positions) || [],
  }, originalInterviewTemplate);

  const payload: UpdateInterviewTemplatePayload = {
    ...pick(updatePayload, [
      'name',
      'description',
      'duration_minutes',
      'live_coding_enabled',
      'positions',
      'time_window_start',
      'time_window_end',
      'candidate_facing_name',
      'candidate_facing_details',
      'interviewer_templates',
      'archived',
    ]),
    candidate_facing_details: updatePayload.candidate_facing_details ? updatePayload.candidate_facing_details !== '<br>' ? updatePayload.candidate_facing_details : '' : undefined,
    interviewer_templates: updatePayload.interviewer_templates ? updatePayload.interviewer_templates.map((interviewerTemplate) => ({
      description: interviewerTemplate.description || '',
      interviewer_filters: interviewerTemplate.interviewer_filters.map(({ interviewer_filter_expressions }) => ({
        interviewer_filter_expressions: (interviewer_filter_expressions || []).map((expression) => ({
          filterable_id: expression.filterable_id,
          filterable_type: expression.filterable_type,
          negated: expression.negated,
        })),
      })),
      optional: interviewerTemplate.optional || false,
      include_past_interviewers: interviewerTemplate.include_past_interviewers ?? true,
    })) : undefined,
  };

  // The undefined value causes some problems down the road, so we just do one
  // find check to get rid of them.
  let key: keyof typeof payload;
  for (key in payload) {
    if (payload[key] === undefined) {
      delete payload[key];
    }
  }

  return payload;
};
