import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;

  .select-input {
    flex: 50%;
  }
`;

export const StyledStageOptionLabel = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  a {
    z-index: 1;

    .btn {
      padding-right: 1px;
    }
  }
`;
