import { Range, Transforms } from 'slate';
import { ReactEditor, useSlate } from 'slate-react';
import { useEffect, useMemo, useRef, useState } from 'react';

import { EditorType, ElementType, googleVideoConferencing } from '../../../../../types';
import { SUGGESTIONS_BY_TYPE } from '../../../../../libraries/editor';
import { Token } from '../../../../../types';
import { getIncompleteToken } from '../helper';
import { useSession } from '../../../../../hooks/use-session';

import type { MouseEvent } from 'react';
import type { TokensResponse } from '../../../../../types';

// This is the number of pixels buffer that should be there when automatically
// scrolling the dropdown when the user uses arrow keys to navigate.
const DROPDOWN_SCROLL_BUFFER = 15;

interface Options {
  tokens?: TokensResponse;
  type: `${EditorType}`;
}

const useSuggestions = ({ tokens, type }: Options) => {
  const editor = useSlate();

  const { account } = useSession();

  const [showSuggestions, setShowSuggestions] = useState(false);
  const [search, setSearch] = useState('');
  const [active, setActive] = useState(0);

  const dropdownRef = useRef<HTMLSpanElement>(null);

  // As the user types (or as the cursor moves left and right), the suggestions
  // should change based on the text between the trigger and the cursor.
  const filteredSuggestions = useMemo(() => {
    let suggestions = SUGGESTIONS_BY_TYPE[type];
    // If they are using a calendar video conferencing tool, they won't be able to reference the video conferencing link
    // in the candidate event description.
    if ((type === EditorType.CandidateCalendarEvent || type === EditorType.CandidateEventDescription || type === EditorType.HiringMeetingCalendarEvent) && googleVideoConferencing.includes(account?.video_conferencing_type!)) {
      suggestions = suggestions.filter(({ value }) => {
        return value !== Token.ScheduleVideoConferencingLink &&
          value !== Token.ScheduleVideoConferencingLinks &&
          value !== Token.ScheduleVideoConferencingPasscode &&
          value !== Token.ScheduleVideoConferencingPasscodes;
      });
    }
    if (tokens) {
      // Filter out any excluded tokens.
      suggestions = suggestions.filter(({ value }) => {
        return !tokens[value]?.excluded;
      });
    }
    return suggestions.filter((suggestion) => {
      const normalizedText = search.toLowerCase().replace(/\s/g, '').replace(/{/g, '');
      return suggestion.normalized.includes(normalizedText);
    });
  }, [search, type]);

  // Whenever the suggestions change, the active index should reset back to 0.
  useEffect(() => {
    setActive(0);
  }, [filteredSuggestions]);

  useEffect(() => {
    const { selection } = editor;

    if (
      !selection ||
      !ReactEditor.isFocused(editor) ||
      !Range.isCollapsed(selection)
    ) {
      setShowSuggestions(false);
      setSearch('');
      return;
    }

    const { text } = getIncompleteToken(editor);

    if (!text) {
      setShowSuggestions(false);
      setSearch('');
      return;
    }

    setShowSuggestions(true);
    setSearch(text);
  }, [editor.selection]);

  // The suggestions dropdown will not autoscroll, so we make it scroll based on
  // the active value.
  const scrollSuggestionsDropdown = (newActive: number) => {
    const dropdownRect = dropdownRef.current?.getBoundingClientRect();
    const optionRect = dropdownRef.current?.children[0] && dropdownRef.current.children[0].getBoundingClientRect();
    if (dropdownRect && optionRect) {
      const heightOfDropdown = dropdownRect.height;
      const heightOfOption = optionRect.height;
      const topOfNewActiveOption = newActive * heightOfOption;
      const bottomOfNewActiveOption = topOfNewActiveOption + heightOfOption;
      const topOfScrollView = dropdownRef.current.scrollTop;
      const bottomOfScrollView = topOfScrollView + heightOfDropdown;
      if (bottomOfNewActiveOption > bottomOfScrollView) {
        dropdownRef.current.scrollTo({ top: topOfScrollView + (bottomOfNewActiveOption - bottomOfScrollView) + DROPDOWN_SCROLL_BUFFER });
      } else if (topOfNewActiveOption < topOfScrollView) {
        dropdownRef.current.scrollTo({ top: topOfScrollView - (topOfScrollView - topOfNewActiveOption) - DROPDOWN_SCROLL_BUFFER });
      }
    }
  };

  // This handles the logic to convert an incomplete token typed in by the user
  // into a Token entity. This is called when the user clicks on a suggestion
  // option or presses tab/enter on a suggestion option.
  const addToken = () => {
    if (!filteredSuggestions[active]) {
      // If active isn't a valid index, no-op.
      return;
    }

    const suggestion = filteredSuggestions[active].value;

    if (tokens?.[suggestion].disabled) {
      // If the selected option is disabled, don't add it.
      return;
    }

    const { range } = getIncompleteToken(editor);
    if (!range) {
      // We weren't able to find an incomplete token, so we should just bail
      // out. However, if we get to this point, there's probably a bug.
      return;
    }
    Transforms.select(editor, range);

    Transforms.insertNodes(editor, {
      type: ElementType.Token,
      token: suggestion,
      children: [{ text: '' }],
    });
    Transforms.move(editor);
    setShowSuggestions(false);
  };

  const handleOptionMouseDown = (event: MouseEvent<HTMLSpanElement>, optionIndex: number) => {
    event.preventDefault();
    setActive(optionIndex);
    addToken();
  };

  const handleOptionMouseEnter = (event: MouseEvent<HTMLSpanElement>, optionIndex: number) => {
    setActive(optionIndex);
  };

  const handleOptionMouseLeave = () => {
    setActive(0);
  };

  return {
    active,
    addToken,
    dropdownRef,
    filteredSuggestions,
    handleOptionMouseDown,
    handleOptionMouseEnter,
    handleOptionMouseLeave,
    scrollSuggestionsDropdown,
    setActive,
    setSearch,
    setShowSuggestions,
    showSuggestions,
  };
};

export default useSuggestions;
