import { useEffect } from 'react';

import Config from '../../../../../config';
import Flash from 'components/library/utils/Flash';
import Modal from 'components/library/layout/Modal';
import { emailTemplateTypeLabels } from 'types';
import { useSession } from 'hooks/use-session';

import type { EmailTemplateType } from 'types';
import type { MouseEventHandler } from 'react';

interface Props {
  isOpen: boolean;
  onToggle: MouseEventHandler<HTMLButtonElement>;
  type: EmailTemplateType.AvailabilityRequestEmail | EmailTemplateType.ConfirmationEmail | EmailTemplateType.SelfSchedulingRequestEmail;
}

const EmailSettingsWarningModal = ({ isOpen, onToggle, type }: Props) => {
  const { account, currentUser, refetchSession } = useSession();

  const canSendEmail = Boolean(account?.email_domain || currentUser?.gem_can_send_email);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!canSendEmail) {
        refetchSession?.();
      }
    }, 1000);

    if (canSendEmail) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [canSendEmail]);

  return (
    <Modal
      cancelButtonValue="Continue without email functionality"
      isOpen={isOpen}
      onSubmit={() => {
        if (canSendEmail) {
          window.location.reload();
        } else {
          window.open(`${Config.GEM_HOST}/settings`);
        }
      }}
      onToggle={onToggle}
      submitButtonValue={canSendEmail ? 'Refresh page' : 'Grant access in Gem settings'}
      title="Grant access to send emails?"
    >
      <Flash
        message="Successfully enabled email sending!"
        showFlash={canSendEmail}
        type="success"
      />
      <Flash
        message={`Sending the ${emailTemplateTypeLabels[type].toLowerCase()} email through Gem Scheduling will be disabled until Gem is granted email access.`}
        showFlash={!canSendEmail}
        type="warning"
      />
      {!canSendEmail && (
        <p>
          Return to this tab after granting email access, and wait for a minute to see a success message.
        </p>
      )}
    </Modal>
  );
};

export default EmailSettingsWarningModal;
