import Avatar from './Avatar';
import AvatarGroup from './AvatarGroup';
import Flash from '../utils/Flash';
import LoadingSpinner from '../utils/LoadingSpinner';
import { useResolveUsers } from '../../../hooks/queries/users';

import type { InterviewerFilter } from '../../../hooks/queries/users';

interface Props {
  applicationId?: string;
  filters: InterviewerFilter[];
  includePastInterviewers: boolean;
  maxDisplayLength?: number;
  scheduleId?: string;
}

const ResolvedFiltersAvatarGroup = ({
  applicationId,
  filters,
  includePastInterviewers,
  maxDisplayLength,
  scheduleId,
}: Props) => {
  const resolveUsersQuery = useResolveUsers({
    applicationId,
    scheduleId,
    includePastInterviewers,
    interviewerFilters: filters,
  });

  return (
    <>
      <h6 className="resolved-filters-avatar-group-header">
        Pool
        {resolveUsersQuery.data && <b>&nbsp;({resolveUsersQuery.data.length})</b>}
      </h6>
      <Flash
        message={resolveUsersQuery.error?.message}
        showFlash={resolveUsersQuery.isError}
        type="danger"
      />
      {!resolveUsersQuery.isSuccess ?
        <LoadingSpinner /> :
        <AvatarGroup
          avatars={resolveUsersQuery.data.map(({ id }) => (
            <Avatar
              key={id}
              size="small"
              type="user"
              userId={id}
            />
          ))}
          maxDisplayLength={maxDisplayLength}
          size="small"
        />
      }
    </>
  );
};

export default ResolvedFiltersAvatarGroup;
