import Moment from 'moment';
import { find, orderBy, values } from 'lodash';
import { useMemo } from 'react';

import ListItem from '../data-display/ListItem';
import OutboundLink from '../navigation/OutboundLink';
import SelectInput from './SelectInput';
import { Directory } from '../../../types';
import { useCalendars } from '../../../hooks/queries/calendars';
import { useSession } from '../../../hooks/use-session';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from './SelectInput/types';
import type { ReactNode } from 'react';

const GOOGLE_GROUP_CALENDAR_DOMAIN = 'group.calendar.google.com';
const GOOGLE_RESOURCE_CALENDAR_DOMAIN = 'resource.calendar.google.com';

const UNKNOWN_CALENDAR_NAME = 'Unknown';
const UNKNOWN_CALENDAR_DESCRIPTION = 'You do not have access to this scheduling calendar.';

const calendarHelpLinks: Record<Directory, string> = {
  google: 'https://support.gem.com/hc/en-us/articles/23489686491927-How-do-I-set-up-a-Scheduling-Calendar-in-G-Suite',
  microsoft365: 'https://support.gem.com/hc/en-us/articles/23489796952215-How-do-I-set-up-a-Scheduling-Calendar-in-Outlook',
};

interface Props {
  helperText?: ReactNode;
  includeHelpLink?: boolean;
  isClearable?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  label?: string;
  onChange: (newValue: OnChangeValue<Option<string>, false>, actionMeta: ActionMeta<Option<string>>) => void;
  placeholder?: string;
  schedulingCalendar: string;
}

const SchedulingCalendarSelectInput = ({
  helperText,
  includeHelpLink = true,
  isClearable = false,
  isDisabled = false,
  isRequired = false,
  label = 'Scheduling Calendar',
  onChange,
  placeholder = 'Select your scheduling calendar',
  schedulingCalendar,
}: Props) => {
  const { account } = useSession();

  const { data: calendars } = useCalendars();

  const items = useMemo(() => {
    const calendarItems = { ...calendars?.calendars };

    const hasSchedulingCalendar = Boolean(schedulingCalendar);
    if (hasSchedulingCalendar && !calendarItems[schedulingCalendar]) {
      calendarItems[schedulingCalendar] = {
        id: schedulingCalendar,
        name: UNKNOWN_CALENDAR_NAME,
        description: UNKNOWN_CALENDAR_DESCRIPTION,
        timezone: Moment.tz.guess(),
        allowed_video_conferencing_types: [],
      };
    }

    return calendarItems;
  }, [calendars?.calendars, schedulingCalendar]);

  const options = useMemo<Option<string>[]>(() => orderBy(values(items), 'name').map((calendar) => {
    let secondaryText = calendar.id;

    if (
      (account?.directory_type === Directory.Google && (calendar.id.endsWith(GOOGLE_GROUP_CALENDAR_DOMAIN) || calendar.id.endsWith(GOOGLE_RESOURCE_CALENDAR_DOMAIN)) && calendar.description) ||
      (account?.directory_type === Directory.Microsoft)
    ) {
      secondaryText = calendar.description;
    }

    return {
      value: calendar.id,
      label: calendar.name,
      secondaryText,
    };
  }), [items]);

  return (
    <SelectInput
      className="scheduling-calendar-select-input"
      formatOptionLabel={(option, { context }) => (
        context === 'menu' ?
          <ListItem
            label={option.label}
            secondaryText={option.secondaryText}
          /> :
          option.label
      )}
      helperText={helperText ?
        <span>
          {helperText}&nbsp;
          {includeHelpLink && (
            <OutboundLink
              href={calendarHelpLinks[account!.directory_type]}
              label={`${label} Helper Text`}
            >
              Learn more.
            </OutboundLink>
          )}
        </span> :
        null
      }
      isClearable={isClearable}
      isDisabled={isDisabled}
      isRequired={isRequired}
      label={label}
      onChange={onChange}
      options={options}
      placeholder={placeholder}
      value={find(options, ['value', schedulingCalendar])}
    />
  );
};

export default SchedulingCalendarSelectInput;
