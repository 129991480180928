import { faDoorOpen } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Moment from 'moment';
import { find, orderBy } from 'lodash';
import { useMemo } from 'react';

import Avatar from 'components/library/data-display/Avatar';
import ListItem from 'components/library/data-display/ListItem';
import PopoverSelectInput from 'components/library/inputs/PopoverSelectInput';
import { useAccount } from 'hooks/queries/accounts';
import { useRoomsMap } from 'hooks/queries/rooms';
import { useUsersMap } from 'hooks/queries/users';
import { ZoomHostType } from 'types';
import CalendarScheduleDetailsPane from './CalendarScheduleDetailsPane';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Group as SelectInputGroup, Option as SelectInputOption } from 'components/library/inputs/SelectInput/types';
import type { Meeting } from 'types';
import type { PotentialZoomHost } from './types';

export interface Option extends SelectInputOption<string> {
  type: ZoomHostType;
  conflicts: Meeting[] | null;
}

interface Group extends SelectInputGroup<string, Option> {
  type: ZoomHostType;
}

interface Props {
  date: Date;
  onChange: (newValue: OnChangeValue<Option, false>, actionMeta: ActionMeta<Option>) => void;
  potentialZoomHosts: PotentialZoomHost[];
  selectedZoomHostId: string | null;
  targetId: string;
  timezone: string;
}

const CalendarScheduleZoomHostSelectInput = ({
  date,
  onChange,
  potentialZoomHosts,
  selectedZoomHostId,
  targetId,
  timezone,
}: Props) => {
  const { data: account } = useAccount();
  const rooms = useRoomsMap();
  const users = useUsersMap({ archived: true });

  // If conflicts are null, it means that they haven't been loaded yet, so we'll
  // be showing a message to tell the user to click to load, and we want these
  // items to go to the bottom of the list, so we make the sort order 999.
  const options = useMemo<Group[]>(() => [{
    label: 'Users',
    type: ZoomHostType.User,
    options: orderBy(potentialZoomHosts.filter(({ type }) => type === 'user').map((host) => ({
      // The potential ones are guaranteed to have the ID defined.
      label: users[host.id!]?.name || users[host.id!]?.email,
      type: ZoomHostType.User,
      value: host.id!,
      conflicts: host.conflicts || null,
    })), [({ conflicts }) => conflicts === null ? 999 : conflicts?.length, 'label']),
  }, {
    label: 'Zoom Rooms',
    type: ZoomHostType.Room,
    options: orderBy(potentialZoomHosts.filter(({ type }) => type === 'room').map((host) => ({
      // The potential ones are guaranteed to have the ID defined.
      label: rooms[host.id!]?.name,
      type: ZoomHostType.Room,
      value: host.id!,
      conflicts: host.conflicts || null,
    })), [({ conflicts }) => conflicts === null ? 999 : conflicts?.length, 'label']),
  }], [potentialZoomHosts, rooms, users]);

  const selectedZoomHost = selectedZoomHostId ? find(potentialZoomHosts, ['id', selectedZoomHostId]) : null;

  return (
    <PopoverSelectInput
      className="calendar-schedule-popover-select zoom-hosts"
      displayDetails={(option) => (
        <CalendarScheduleDetailsPane
          conflicts={(option?.conflicts ? option.conflicts.map((meeting) => ({
            start_time: meeting.start_time,
            end_time: Moment(meeting.start_time).add(meeting.duration, 'minutes').format(),
            title: meeting.topic,
            attendees: [],
          })) : null)}
          date={date}
          id={option!.value}
          resource="zoom_host"
          timezone={timezone}
        />
      )}
      formatOptionLabel={(option, { context }) => (
        context === 'menu' ?
          <ListItem
            label={option.label}
            leftIcon={option.type === 'user' ?
              <Avatar
                showUserTooltip={false}
                size="small"
                status={option.conflicts && option.conflicts.length >= account!.zoom_host_concurrent_meeting_limit ? 'warning' : undefined}
                userId={option.value}
              /> :
              <FontAwesomeIcon
                className={option.conflicts && option.conflicts.length >= account!.zoom_host_concurrent_meeting_limit ? 'warning' : undefined}
                icon={faDoorOpen}
              />
            }
            secondaryText={
              option.conflicts !== null ?
                <span>
                  <span className={option.conflicts.length >= account!.zoom_host_concurrent_meeting_limit ? 'warning' : undefined}>
                    {option.conflicts.length || '0'}
                  </span> conflict{option.conflicts.length === 1 ? '' : 's'}
                </span> :
                <span>Click to load conflicts</span>
            }
          /> :
          option.label
      )}
      onChange={onChange}
      options={options}
      placeholder="Select a Zoom host"
      targetId={targetId}
      value={selectedZoomHost ?
        find(
          find(options, ['type', selectedZoomHost.type])?.options,
          ['value', selectedZoomHost.id]
        ) :
        null
      }
    />
  );
};

export default CalendarScheduleZoomHostSelectInput;
