import { createContext, useContext, useMemo, useState } from 'react';

import type { Dispatch, SetStateAction, ReactNode } from 'react';
import type { HiringMeetingAttendeeFilterExpressionFilterableType, ZoomHostFilterableType } from 'types';

export enum Step {
  Preferences,
  Event,
  Review,
}

export interface HiringMeeting {
  hiring_meeting_template: {
    id?: string;
    name: string;
    duration_minutes: number;
    video_conferencing_enabled: boolean;
    zoom_host_filters?: {
      zoom_host_filter_expressions: {
        filterable_id: string;
        filterable_type: `${ZoomHostFilterableType}`;
        negated: boolean;
      }[];
    }[];
    room_filters?: {
      room_filter_expressions: {
        negated: boolean;
        filterable_id: string;
        filterable_type: string;
      }[];
    }[];
    calendar_event_template: {
      id?: string;
      title: string;
      description: string;
      location?: string;
      additional_attendees?: string[];
      additional_optional_attendees?: string[];
    };
    hiring_meeting_attendee_filters: {
      hiring_meeting_attendee_filter_expressions: {
        negated: boolean;
        filterable_id: string;
        filterable_type: HiringMeetingAttendeeFilterExpressionFilterableType;
      }[];
    }[];
  };
  application_id: string;
  scheduling_calendar_email: string;
  mark_event_as_private: boolean;
  timezone: string;
  start_time: string;
  room_id?: string;
  zoom_host_id?: string;
  zoom_host_type?: string;

  // This is just used to display on the review step.
  hiring_meeting_attendee_ids?: string[];
}

export interface HiringMeetingAttendee {
  user_id: string;
  filterable_id: string;
  filterable_type: string;
}

interface NewHiringMeetingContextState {
  completedStep: Step;
  hiringMeeting: HiringMeeting;
  setCompletedStep: Dispatch<SetStateAction<Step>>;
  setHiringMeeting: Dispatch<SetStateAction<HiringMeeting>>;
}

function createEmptyHiringMeeting (): HiringMeeting {
  return {
    application_id: '',
    start_time: '',
    timezone: '',
    scheduling_calendar_email: '',
    mark_event_as_private: false,
    hiring_meeting_template: {
      name: '',
      duration_minutes: 30,
      video_conferencing_enabled: false,
      hiring_meeting_attendee_filters: [],
      calendar_event_template: {
        title: '',
        description: '',
      },
    },
  };
}

// This is the context for keeping track of the new hiring meeting. You
// should keep track of the new hiring meeting with a useState call in the same component
// that use the Provider, recreate an array using useMemo, and then pass it
// into the Provider. Make sure you don't pass an array literal into the
// Provider. This can cause re-rendering issues as noted here:
// https://reactjs.org/docs/context.html#caveats.
const NewHiringMeetingContext = createContext<NewHiringMeetingContextState>({
  completedStep: Step.Preferences,
  hiringMeeting: createEmptyHiringMeeting(),
  setCompletedStep: () => {},
  setHiringMeeting: () => {},
});

interface Props {
  children: ReactNode;
}

export const NewHiringMeetingProvider = ({ children }: Props) => {
  const [hiringMeeting, setHiringMeeting] = useState(createEmptyHiringMeeting());
  const [completedStep, setCompletedStep] = useState(Step.Preferences);

  const contextValue = useMemo<NewHiringMeetingContextState>(() => ({
    completedStep,
    hiringMeeting,
    setCompletedStep,
    setHiringMeeting,
  }), [completedStep, hiringMeeting]);

  return (
    <NewHiringMeetingContext.Provider value={contextValue}>
      {children}
    </NewHiringMeetingContext.Provider>
  );
};

export const useNewHiringMeeting = () => {
  return useContext(NewHiringMeetingContext);
};
