import { CSSTransition } from 'react-transition-group';
import {
  faAngleDoubleLeft, faBriefcase,
  faBuilding, faCalendar, faChalkboardUser,
  faClone, faComments,
  faDoorOpen, faEnvelope,
  faHome, faProjectDiagram, faSignOutAlt, faTableList,
  faTasks, faTools, faUserTie, faUsers, faUser, faGraduationCap, faPeopleGroup,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useMediaQuery } from 'react-responsive';
import { useRouteMatch } from 'react-router-dom';

import Avatar from '../../library/data-display/Avatar';
import Button from '../../library/inputs/Button';
import MenuAccordionSection from './MenuAccordionSection';
import MenuNavLink from './MenuNavLink';
import Tooltip from '../../library/utils/Tooltip';
import useSavedQueryParams from '../../../hooks/use-saved-query-params';
import { useAccount } from '../../../hooks/queries/accounts';
import { useLDFlags } from '../../../hooks/use-ld-flags';
import { useNotifications } from '../../../hooks/queries/notifications';
import { useSession } from '../../../hooks/use-session';

import type { MouseEventHandler } from 'react';
import { correctPath } from '../../../libraries/gem';
import { formPost } from 'libraries/csrf';

interface Props {
  isExpanded: boolean;
  toggleIsExpanded: MouseEventHandler<HTMLButtonElement>;
}

const Menu = ({ isExpanded, toggleIsExpanded }: Props) => {
  const { batchScheduling, hideTaskBadge } = useLDFlags();

  const { currentUser } = useSession();
  const { data: account } = useAccount();

  const { data: notifications } = useNotifications({ limit: 1 });

  const [savedOverviewParams] = useSavedQueryParams('/app/overview');
  const isOnOverviewPage = useRouteMatch({
    path: correctPath('/app/overview'),
  });

  const handleSignOut = async () => {
    formPost('/api/logout');
  };

  const isMobile = useMediaQuery({ query: '(max-width: 750px)' });
  const companyDomain = currentUser?.email?.split('@')[1];
  const companyName = account?.name;

  return (
    <CSSTransition
      classNames="menu-transition"
      in={isExpanded}
      timeout={250}
    >
      <div className="menu">
        <div className="menu-header">
          <div className="account-details">
            <Avatar
              alt={companyDomain}
              img={account?.logo_url || `https://logo.clearbit.com/${companyDomain}`}
              size="large"
              type="company"
            />
            <span>{companyName || companyDomain}</span>
          </div>
          <Button
            color="gray"
            iconRight={<FontAwesomeIcon icon={faAngleDoubleLeft} />}
            onClick={toggleIsExpanded}
            tooltip={!isMobile ?
              <Tooltip
                id="menu-menu-toggle"
                position="bottom"
                value="Hide menu"
              /> :
              undefined
            }
          />
        </div>
        <div className="menu-content">
          <div className="menu-body">
            <div className="menu-section">
              <MenuNavLink
                icon={<FontAwesomeIcon icon={faHome} />}
                path={correctPath(`/app/overview${isOnOverviewPage ? '' : savedOverviewParams}`)}
                title="Overview"
              />
              {!hideTaskBadge && (
                <MenuNavLink
                  badgeValue={notifications?.total}
                  icon={<FontAwesomeIcon icon={faTasks} />}
                  path={correctPath('/app/tasks')}
                  title="Tasks"
                />
              )}
              <MenuNavLink
                icon={<FontAwesomeIcon icon={faUserTie} />}
                path={correctPath('/app/candidates')}
                title="Candidates"
              />
              {batchScheduling && (
                <MenuNavLink
                  icon={<FontAwesomeIcon icon={faUsers} />}
                  isNew
                  path={correctPath('/app/superdays')}
                  title="Superdays"
                />
              )}
              <MenuNavLink
                icon={<FontAwesomeIcon icon={faTableList} />}
                path={correctPath('/app/interviewer-reports')}
                title="Interviewer reports"
              />
            </div>
            <div className="menu-section">
              <h6>Settings</h6>
              <MenuNavLink
                icon={<FontAwesomeIcon icon={faBriefcase} />}
                path={correctPath('/app/jobs')}
                title="Jobs"
              />
              <MenuAccordionSection
                icon={<FontAwesomeIcon icon={faClone} />}
                title="Templates"
              >
                <MenuNavLink
                  icon={<FontAwesomeIcon icon={faCalendar} />}
                  path={correctPath('/app/calendar-event-templates')}
                  title="Calendar events"
                />
                <MenuNavLink
                  icon={<FontAwesomeIcon icon={faComments} />}
                  path={correctPath('/app/interview-templates')}
                  title="Interviews"
                />
                <MenuNavLink
                  icon={<FontAwesomeIcon icon={faPeopleGroup} />}
                  path={correctPath('/app/hiring-meeting-templates')}
                  title="Hiring meetings"
                />
                <MenuNavLink
                  icon={<FontAwesomeIcon icon={faEnvelope} />}
                  path={correctPath('/app/email-templates')}
                  title="Emails"
                />
              </MenuAccordionSection>
              <MenuNavLink
                icon={<FontAwesomeIcon icon={faChalkboardUser} />}
                path={correctPath('/app/interviewers')}
                title="Interviewers"
              />
              <MenuNavLink
                icon={<FontAwesomeIcon icon={faGraduationCap} />}
                path={correctPath('/app/training-programs')}
                title="Training programs"
              />
              <MenuNavLink
                icon={<FontAwesomeIcon icon={faDoorOpen} />}
                path={correctPath('/app/rooms')}
                title="Rooms"
              />
              <MenuAccordionSection
                icon={<FontAwesomeIcon icon={faTools} />}
                title="Account"
              >
                <MenuNavLink
                  icon={<FontAwesomeIcon icon={faBuilding} />}
                  path={correctPath('/app/company')}
                  title="Company"
                />
                <MenuNavLink
                  icon={<FontAwesomeIcon icon={faProjectDiagram} />}
                  path={correctPath('/app/integrations')}
                  title="Integrations"
                />
                <MenuNavLink
                  icon={<FontAwesomeIcon icon={faUser} />}
                  path={correctPath('/app/user')}
                  title="User"
                />
              </MenuAccordionSection>
            </div>
          </div>
          <div className="menu-footer">
            <div className="nav-link link" onClick={handleSignOut}>
              <FontAwesomeIcon icon={faSignOutAlt} />
              Sign out
            </div>
          </div>
        </div>
      </div>
    </CSSTransition>
  );
};

export default Menu;
