import { useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import CalendarEventTemplateAdvancedSettings from '../../../../library/inputs/CalendarEventTemplateAdvancedSettings';
import EditorInput from '../../../../library/inputs/EditorInput';
import ErrorTokenFlash from '../../../../library/utils/ErrorTokenFlash';
import Flash from '../../../../library/utils/Flash';
import Section from '../../../../library/layout/Section';
import TextInput from '../../../../library/inputs/TextInput';
import TokenInput from '../../../../library/inputs/TokenInput';
import { DEFAULT_CALENDAR_EVENT_CONTENT } from '../../CalendarEventTemplateCreate/helpers';
import { getTokensFromSlateValue, slateValueToHtml } from '../../../../../libraries/editor/slate-value-to-html';
import { slateValueToText } from '../../../../../libraries/editor/slate-value-to-text';
import { useCalendarEventTemplate, useUpdateCalendarEventTemplate } from '../../../../../hooks/queries/calendar-event-templates';
import { useSlateEditor } from '../../../../../hooks/use-slate-editor';
import { useTokens } from '../../../../../hooks/queries/tokens';

import type { ChangeEvent } from 'react';
import type { UpdateCalendarEventTemplatePayload } from '../../../../../hooks/queries/calendar-event-templates';

const CalendarEventTemplateSection = () => {
  const { id } = useParams<{ id: string }>();

  const { data: calendarEventTemplate } = useCalendarEventTemplate(id);
  const updateCalendarEventTemplateMutation = useUpdateCalendarEventTemplate();

  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(calendarEventTemplate!.name);
  const [titleSlateEditor, titleSlateValue, setTitleSlateValue, setTitle] = useSlateEditor(calendarEventTemplate!.title, true);
  const [descriptionSlateEditor, descriptionSlateValue, setDescriptionSlateValue, setDescription] = useSlateEditor(calendarEventTemplate!.description);
  const [location, setLocation] = useState(calendarEventTemplate!.location || '');
  const [additionalAttendees, setAdditionalAttendees] = useState<string[]>(calendarEventTemplate!.additional_attendees || []);
  const [additionalOptionalAttendees, setAdditionalOptionalAttendees] = useState<string[]>(calendarEventTemplate!.additional_optional_attendees || []);

  const {
    data: tokens,
    error: tokensError,
  } = useTokens({ type: calendarEventTemplate!.type });

  const tokensUsedInTitle = useMemo(() => getTokensFromSlateValue(titleSlateValue), [titleSlateValue]);
  const tokensUsedInDescription = useMemo(() => getTokensFromSlateValue(descriptionSlateValue), [descriptionSlateValue]);
  const errorTokens = useMemo(() => {
    const errorTitleTokens = (
      tokens && tokensUsedInTitle ?
        tokensUsedInTitle.filter((name) => !tokens[name] || tokens[name].disabled) :
        []
    );
    const errorDescriptionTokens = (
      tokens && tokensUsedInDescription ?
        tokensUsedInDescription.filter((name) => !tokens[name] || tokens[name].disabled) :
        []
    );
    return [
      ...errorTitleTokens,
      ...errorDescriptionTokens,
    ];
  }, [tokens, tokensUsedInTitle, tokensUsedInDescription]);

  const resetEditorValues = () => {
    if (!calendarEventTemplate) {
      return;
    }
    setName(calendarEventTemplate.name);
    setTitle(calendarEventTemplate.title);
    setDescription(calendarEventTemplate.description);
    setLocation(calendarEventTemplate.location || '');
    setAdditionalAttendees(calendarEventTemplate.additional_attendees || []);
    setAdditionalOptionalAttendees(calendarEventTemplate.additional_optional_attendees || []);
  };

  useEffect(() => {
    resetEditorValues();
  }, [calendarEventTemplate]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    resetEditorValues();
  };

  const handleSave = async () => {
    if (errorTokens.length > 0) {
      // We don't need to display an error because ErrorTokenFlash already takes
      // care of that. We just need to stop the execution from here.
      return;
    }

    const payload: UpdateCalendarEventTemplatePayload = {
      name,
      title: slateValueToText(titleSlateValue),
      description: slateValueToHtml(descriptionSlateValue),
      location,
      additional_attendees: additionalAttendees,
      additional_optional_attendees: additionalOptionalAttendees,
    };

    try {
      await updateCalendarEventTemplateMutation.mutateAsync({ id: calendarEventTemplate!.id, payload });
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleLocationChange = (e: ChangeEvent<HTMLInputElement>) => {
    setLocation(e.target.value);
  };

  return (
    <Section
      className="calendar-event-template-details-email"
      isEditable
      isEditing={isEditing}
      isSaving={updateCalendarEventTemplateMutation.isLoading}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Calendar event template"
    >
      <Flash
        message={tokensError?.message}
        showFlash={Boolean(tokensError)}
        type="danger"
      />
      <Flash
        message={updateCalendarEventTemplateMutation.error?.message}
        showFlash={updateCalendarEventTemplateMutation.isError}
        type="danger"
      />
      <Flash
        isDismissible
        message="Successfully updated!"
        showFlash={updateCalendarEventTemplateMutation.isSuccess}
        type="success"
      />
      <ErrorTokenFlash errorTokens={errorTokens} />
      <TextInput
        isDisabled={!isEditing || updateCalendarEventTemplateMutation.isLoading}
        isRequired
        label="Template Name"
        onChange={handleNameChange}
        value={name}
      />
      <div className="title-location-container">
        {tokens && (
          <TokenInput
            editor={titleSlateEditor}
            isDisabled={!isEditing || updateCalendarEventTemplateMutation.isLoading}
            isRequired
            label="Title"
            pendingPreviewMessage="This token will be filled in when sending a calendar event to a candidate."
            setValue={setTitleSlateValue}
            tokens={tokens}
            type={calendarEventTemplate!.type}
            value={titleSlateValue}
          />
        )}
        <TextInput
          helperText="We will replace this with the video conferencing link for remote interviews."
          isDisabled={!isEditing || updateCalendarEventTemplateMutation.isLoading}
          label="Location"
          onChange={handleLocationChange}
          value={location}
        />
      </div>
      {tokens && (
        <EditorInput
          editor={descriptionSlateEditor}
          exampleHtmlContent={DEFAULT_CALENDAR_EVENT_CONTENT[calendarEventTemplate!.type].description}
          isDisabled={!isEditing || updateCalendarEventTemplateMutation.isLoading}
          label="Description"
          pendingPreviewMessage="This token will be filled in when sending a calendar event to a candidate."
          setValue={setDescriptionSlateValue}
          tokens={tokens}
          type={calendarEventTemplate!.type}
          value={descriptionSlateValue}
        />
      )}
      <CalendarEventTemplateAdvancedSettings
        additionalAttendees={additionalAttendees}
        additionalOptionalAttendees={additionalOptionalAttendees}
        isDisabled={!isEditing || updateCalendarEventTemplateMutation.isLoading}
        setAdditionalAttendees={setAdditionalAttendees}
        setAdditionalOptionalAttendees={setAdditionalOptionalAttendees}
      />
    </Section>
  );
};

export default CalendarEventTemplateSection;
