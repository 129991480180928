import { Breadcrumb } from 'react-breadcrumbs';
import { Redirect, Route, Switch, useParams } from 'react-router-dom';
import { useState } from 'react';

import Button from '../../../../../library/inputs/Button';
import CopyPreferencesFromStageModal from './CopyPreferencesFromStageModal';
import Flash from '../../../../../library/utils/Flash';
import JobStageAvailabilityPreferences from './JobStageAvailabilityPreferences';
import JobStageInterviews from './JobStageInterviews';
import JobStageSchedulingPreferences from './JobStageSchedulingPreferences';
import LoadingSpinner from '../../../../../library/utils/LoadingSpinner';
import NavbarLinks from '../../../../../library/navigation/NavbarLinks';
import Tooltip from '../../../../../library/utils/Tooltip';
import { useStage } from '../../../../../../hooks/queries/stages';
import { correctPath } from 'libraries/gem';

const JobStage = () => {
  const { id: jobId, stageId } = useParams<{ id: string; stageId: string }>();

  const [showCopyPreferencesFromStageModal, setShowCopyPreferencesFromStageModal] = useState(false);

  const {
    data: stage,
    error,
    isLoading,
  } = useStage(jobId, stageId);

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const isSchedulable = Boolean(stage?.schedule_template_id);

  return (
    <Breadcrumb
      data={{
        title: stage ? stage.name : stageId,
        pathname: correctPath(`/app/jobs/${jobId}/stages/${stageId}`),
      }}
    >
      <div className="stage-container">
        {!error &&
          <div className="job-stage-actions">
            <Button
              color="gem-outline"
              onClick={() => {
                setShowCopyPreferencesFromStageModal(true);
                stage && analytics.track('Copy Stage Preferences Modal Opened', {
                  job_id: jobId,
                  job_name: stage.job.name,
                  stage_id: stageId,
                  stage_name: stage.name,
                  is_scheduling_enabled: Boolean(stage.schedule_template_id),
                });
              }}
              size="small"
              value="Copy preferences from another stage"
            />
            <CopyPreferencesFromStageModal
              isOpen={showCopyPreferencesFromStageModal}
              onToggle={() => {
                // If this variable is _currently_ set to true before we set it to false,
                // it means that the modal is being exited.
                if (showCopyPreferencesFromStageModal) {
                  stage && analytics.track('Copy Stage Preferences Modal Closed', {
                    job_id: jobId,
                    job_name: stage.job.name,
                    stage_id: stageId,
                    stage_name: stage.name,
                    is_scheduling_enabled: Boolean(stage.schedule_template_id),
                  });
                }
                setShowCopyPreferencesFromStageModal(!showCopyPreferencesFromStageModal);
              }}
            />
          </div>
        }
        <div className="job-stage-container">
          <Flash
            message={error?.message}
            showFlash={Boolean(error)}
            type="danger"
          />
          {!error &&
            <>
              <NavbarLinks
                links={[{
                  label: 'Scheduling Preferences',
                  location: correctPath(`/app/jobs/${jobId}/stages/${stageId}/scheduling-preferences`),
                }, {
                  label: 'Interviews',
                  location: correctPath(`/app/jobs/${jobId}/stages/${stageId}/interviews`),
                }, {
                  isDisabled: !isSchedulable,
                  label: 'Availability Preferences',
                  location: correctPath(`/app/jobs/${jobId}/stages/${stageId}/availability-preferences`),
                  tooltip: !isSchedulable ? <Tooltip
                    id="stage-disabled-availability-preferences"
                    position="top"
                    value="You cannot configure availability preferences when scheduling is disabled."
                  /> : undefined,
                }]}
              />
              <Switch>
                <Redirect exact from={correctPath('/app/jobs/:id/stages/:stageId')} to={correctPath('/app/jobs/:id/stages/:stageId/scheduling-preferences')} />
                <Route component={JobStageSchedulingPreferences} path={correctPath('/app/jobs/:id/stages/:stageId/scheduling-preferences')} />
                <Route component={JobStageInterviews} path={correctPath('/app/jobs/:id/stages/:stageId/interviews')} />
                {isSchedulable && <Route component={JobStageAvailabilityPreferences} path={correctPath('/app/jobs/:id/stages/:stageId/availability-preferences')} />}
              </Switch>
            </>
          }
        </div>
      </div>
    </Breadcrumb>
  );
};

export default JobStage;
