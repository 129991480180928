// Originally based on https://www.30secondsofcode.org/react/s/use-local-storage, but that implementation had the
// limitation where having 2 instances of useLocalStorage using the same key weren't tied to each other in any way, so
// if I updated the value in one component, while it does update local storage, it wouldn't refresh the usage in another
// component. This new implementation utilizes React contexts so that the underlying state is shared across the app.
import { createContext, useCallback, useContext, useMemo, useState } from 'react';

import type { Dispatch, ReactNode, SetStateAction } from 'react';

interface LocalStorageValues {
 [key: string]: any;
}

interface LocalStorageContextState {
  values: LocalStorageValues;
  setValues: Dispatch<SetStateAction<LocalStorageValues>>;
}

const LocalStorageContext = createContext<LocalStorageContextState>({
  values: {},
  setValues: () => {},
});

interface Props {
  children: ReactNode;
}

export const LocalStorageProvider = ({ children }: Props) => {
  const [values, setValues] = useState<LocalStorageValues>({});

  const contextValue = useMemo<LocalStorageContextState>(() => ({
    values,
    setValues,
  }), [
    values,
  ]);

  return (
    <LocalStorageContext.Provider value={contextValue}>
      {children}
    </LocalStorageContext.Provider>
  );
};

function useLocalStorage<T> (key: string, defaultValue?: T): [T, (newValue: T) => void] {
  const { values, setValues } = useContext(LocalStorageContext);
  const value = useMemo<T>(() => {
    if (values[key]) {
      return values[key];
    }
    try {
      const value = window.localStorage.getItem(key);
      if (value) {
        return JSON.parse(value);
      }
      return defaultValue;
    } catch (error) {
      return defaultValue;
    }
  }, [values, defaultValue]);

  const setValue = useCallback((newValue: T) => {
    window.localStorage.setItem(key, JSON.stringify(newValue));
    setValues((prev) => ({
      ...prev,
      [key]: newValue,
    }));
  }, [key, setValues]);

  return [value, setValue];
}

export default useLocalStorage;
