import { createGlobalStyle } from 'styled-components';

const GlobalStyles = createGlobalStyle`
#root {
  height: 100%;
  width: 100%;
}

* {
  scrollbar-width: none;
}

body {
  background: ${({ theme }) => theme.colors.PRODUCT.PRIMARY_BACKGROUND};
  color: ${({ theme }) => theme.colors.PRODUCT.PRIMARY_CONTENT};
  font-family: ${({ theme }) => theme.fonts.text};
  font-size: 14px;
  margin: 0;
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  text-size-adjust: none;
}

h1 {
  font-family: ${({ theme }) => theme.fonts.header};
  font-size: 24px;
  font-weight: 500;
}

h2 {
  color: ${({ theme }) => theme.colors.zodiacBlue};
  font-family: ${({ theme }) => theme.fonts.header};
  font-size: 20px;
  font-weight: 500;
  letter-spacing: -.017em;
}

h3 {
  font-size: 16px;
  font-weight: 500;
  letter-spacing: -.011em;
}

h4 {
  font-weight: normal;
}

h6 {
  color: ${({ theme }) => theme.colors.doveGray};
  font-size: .7rem;
  font-weight: normal;
  letter-spacing: .1rem;
  text-decoration: none;
  text-transform: uppercase;
}

p {
  line-height: 1.5;
}

a {
  color: ${({ theme }) => theme.colors.BRAND.GEM_BLUE};
  padding: 4px 1px;
  text-decoration: none;

  &:hover {
    color: ${({ theme }) => theme.colors.BLUES.BLUE_600};
    text-decoration: underline;
    transition: .2s text-decoration linear;
  }
}

button {
  background: none;
}

pre {
  background: ${({ theme }) => theme.colors.alabasterWhite};
  overflow: scroll;
  padding: 1em;
}

.btn {
  border: none;
  border-radius: 6px;
  cursor: pointer;
  display: inline-block;
  font-family: inherit;
  font-weight: 400;
  font-size: 14px;
  text-decoration: none;
  transition: .2s background linear, .2s border linear;
  white-space: nowrap;

  &:disabled,
  &.btn-disabled {
    cursor: default;
  }

  &:focus {
    outline: none;
  }

  &.btn-custom-styled:hover {
    filter: brightness(90%);
  }

  &.btn-archive {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.btn-unarchive-icon {
      .icon-arrow {
        font-size: .6em;
        margin-bottom: 2px;
      }
    }
  }

  &.btn-link {
    font-size: .7rem;
    font-weight: 500;
    letter-spacing: .1rem;
  }

  &.btn-small {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0;
    padding: 6px 12px;
  }

  &.btn-large {
    font-size: 16px;
    font-weight: 500;
    letter-spacing: 0;
    padding: 10px 16px;
  }

  &.btn-gem-outline {
    border: 1px solid ${({ theme }) => theme.colors.PRODUCT.PRIMARY_BORDER};
    box-shadow: #17181a1f 0 1px 2px;
    color: ${({ theme }) => theme.colors.PRODUCT.PRIMARY_CONTENT};

    &.btn-disabled,
    &:disabled {
      color: ${({ theme }) => theme.colors.GRAYS.GRAY_500} !important;
    }

    &:not(:disabled):not(.btn-disabled):hover {
      &:not(.btn-custom-styled) {
        background: ${({ theme }) => theme.colors.PRODUCT.SECONDARY_BACKGROUND};
      }

      &.btn-custom-styled {
        background: ${({ theme }) => theme.colors.PRODUCT.TERTIARY_BACKGROUND};
        filter: none;
      }
    }
  }

  &.btn-green {
    background: ${({ theme }) => theme.colors.GREENS.GREEN_400};
    color: white;

    &:not(:disabled):hover {
      background: darken(${({ theme }) => theme.colors.GREENS.GREEN_400}, 10%);
    }
  }

  &.btn-gem-blue {
    background: ${({ theme }) => theme.colors.BRAND.GEM_BLUE};
    border: 1px solid ${({ theme }) => theme.colors.BRAND.GEM_BLUE};
    color: #fff;

    &:not(:disabled):hover {
      background: ${({ theme }) => theme.colors.BLUES.BLUE_600};
      border: 1px solid ${({ theme }) => theme.colors.BLUES.BLUE_600};
    }

    &:disabled {
      background: desaturate(${({ theme }) => theme.colors.BRAND.GEM_BLUE}, 25%);
    }

    .spinner {
      color: inherit;
    }
  }

  &.btn-gray {
    color: ${({ theme }) => theme.colors.PRODUCT.SECONDARY_CONTENT};

    .avatar {
      border: none;
    }

    &:disabled {
      color: ${({ theme }) => theme.colors.PRODUCT.TERTIARY_CONTENT};
    }

    &:not(.btn-disabled):not(:disabled):hover {
      color: ${({ theme }) => theme.colors.PRODUCT.PRIMARY_CONTENT};

      &.btn-delete {
        color: ${({ theme }) => theme.colors.ACCENT.RED};
      }

      .avatar {
        filter: brightness(80%);
      }
    }
  }

  &.btn-delete {
    &.btn-delete-always-red {
      color: ${({ theme }) => theme.colors.ACCENT.RED};

      &.btn-disabled,
      &:disabled {
        color: ${({ theme }) => theme.colors.GRAYS.GRAY_500};
      }
    }

    .icon-right {
      margin-left: 0;
    }
  }

  &.btn-no-outline {
    color: ${({ theme }) => theme.colors.BRAND.GEM_BLUE};

    &.btn-disabled,
    &:disabled {
      color: ${({ theme }) => theme.colors.PRODUCT.SECONDARY_CONTENT} !important;
    }

    &:not(:disabled):not(.btn-disabled) {
      &:hover {
        text-decoration: underline;

        &:not(.btn-custom-styled) {
          color: ${({ theme }) => theme.colors.BLUES.BLUE_600};
        }

        .avatar {
          text-decoration: none;
        }
      }
    }
  }

  .icon-left {
    margin-right: 8px;
  }

  .icon-right {
    margin-left: 8px;
  }
}
`;

export default GlobalStyles;
