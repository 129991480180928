import Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { find, values } from 'lodash';
import { useMemo } from 'react';

import Button from './Button';
import FavoriteButton from './FavoriteButton';
import ListItem from '../data-display/ListItem';
import SelectInput from './SelectInput';
import { FavoriteResourceType } from '../../../types';
import { formatMoment, TimeFormat } from '../../../libraries/time';
import { useHiringMeetingTemplates } from '../../../hooks/queries/hiring-meeting-templates';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option as SelectOption } from './SelectInput/types';

interface Option extends SelectOption<string> {
  favorite?: boolean;
}

interface Props {
  isDisabled?: boolean;
  isInlineCreatable?: boolean;
  label?: string;
  name?: string;
  onChange: (newValue: OnChangeValue<SelectOption<string>, false>, actionMeta: ActionMeta<SelectOption<string>>) => void;
  value?: string;
}

const HiringMeetingTemplateSelectInput = ({
  isDisabled = false,
  isInlineCreatable = false,
  label,
  name,
  onChange,
  value,
}: Props) => {
  const hiringMeetingTemplateQuery = useHiringMeetingTemplates();
  const hiringMeetingTemplates = useMemo(() => {
    if (!hiringMeetingTemplateQuery.data) {
      return [];
    }
    return values(hiringMeetingTemplateQuery.data.hiring_meeting_templates).filter((hiringMeetingTemplate) => !hiringMeetingTemplate.archived);
  }, [hiringMeetingTemplateQuery.data]);

  const options = useMemo<Option[]>(() => {
    const templateOptions: Option[] = hiringMeetingTemplates.map((hiringMeetingTemplate) => ({
      value: hiringMeetingTemplate.id,
      label: hiringMeetingTemplate.name,
      secondaryText: `Created on ${formatMoment(Moment(hiringMeetingTemplate.created_at), TimeFormat.LongMonthDayYear)}`,
      favorite: hiringMeetingTemplate.favorite,
    }));
    if (isInlineCreatable) {
      templateOptions.unshift({
        value: 'new',
        label: (
          <Button
            color="no-outline"
            iconLeft={<FontAwesomeIcon icon={faPlus} />}
            size="small"
            value="New Hiring Meeting Template"
          />
        ),
      });
    }
    return templateOptions;
  }, [hiringMeetingTemplates, isInlineCreatable]);

  return (
    <SelectInput
      formatOptionLabel={(option, { context }) => (
        context === 'menu' ?
          <ListItem
            label={(
              <div className="favorite-container">
                {option.favorite ? (
                  <FavoriteButton
                    favorite={option.favorite}
                    isDisabled
                    resourceId={option.value}
                    resourceType={FavoriteResourceType.HiringMeetingTemplate}
                  />
                ) : undefined}
                {option.label}
              </div>
            )}
            secondaryText={option.secondaryText}
          /> :
          option.label
      )}
      isClearable
      isDisabled={isDisabled}
      label={label}
      name={name}
      onChange={onChange}
      options={options}
      placeholder="Select a template"
      value={value ? find(options, ['value', value]) : null}
    />
  );
};

export default HiringMeetingTemplateSelectInput;
