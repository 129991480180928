import { uniq } from 'lodash';
import { useMemo } from 'react';

import { getTokenError } from '../inputs/EditorInput/Token';

interface Props {
  errorTokens: string[];
}

const TokenErrorList = ({ errorTokens }: Props) => {
  const deduped = useMemo(() => uniq(errorTokens), [errorTokens]);

  return (
    <ul>
      {deduped.map((token) => (
        <li key={token}>
          <b>{token}</b> &ndash; {getTokenError(token, true)}
        </li>
      ))}
    </ul>
  );
};

export default TokenErrorList;
