export enum IgnoreWordType {
  Conflict = 'conflict',
  OOO = 'ooo',
}

export interface IgnoreWord {
  id: string;
  type: IgnoreWordType;
  negated: boolean;
}

export interface EditableIgnoreWord {
  id: string;
  negated: boolean;
}
