import styled from 'styled-components';
import CheckboxInput from '../../inputs/CheckboxInput';

const stepCircleRadiusPx = 13;
const stepCircleBorderWidthPx = 2;

export const StyledStepContainer = styled.div`
  display: flex;
  position: relative;
`;

export const StyledTrainedOutsideText = styled.div`
  color: ${({ theme }) => theme.colors.PRODUCT.SECONDARY_CONTENT};
  font-style: italic;
  padding: 1.5em 1em;
`;

export const StyledStepDivider = styled.div`
  border: 1px solid ${({ theme }) => theme.colors.PRODUCT.SECONDARY_BORDER};
  position: absolute;
  top: 28px;
  width: 130px;

  &.dashed {
    border: none;
    height: 2px;

    /* Inspired by https://kovart.github.io/dashed-border-generator/ but I modified it from using a rect to a line. */
    background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3cline x2='100%25' y1='50%25' x1='0%25' y2='50%25' fill='none' stroke='${({ theme }) => encodeURIComponent(theme.colors.mercuryGray)}' stroke-width='2' stroke-dasharray='5' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e");
  }
`;

export const StyledStep = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 1px;
  padding: 1em 0;
  min-width: 130px;
  position: relative;
  text-align: center;
  width: 130px;

  &:first-child {
    ${StyledStepDivider} {
      right: 0;
      width: 65px;
    }
  }

  &:last-child {
    ${StyledStepDivider} {
      left: 0;
      width: 65px;
    }
  }

  &.ignored {
    min-width: unset;
    width: auto;
  }
`;

export const StyledCircle = styled.div`
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: ${stepCircleRadiusPx * 2}px;
  justify-content: center;
  width: ${stepCircleRadiusPx * 2}px;
  margin-bottom: .5em;
  z-index: 1;

  &.completed {
    border: ${stepCircleBorderWidthPx}px solid ${({ theme }) => theme.colors.GREENS.GREEN_300};
    color: ${({ theme }) => theme.colors.GREENS.GREEN_300};
    background: ${({ theme }) => theme.colors.GREENS.GREEN_100};
    font-size: 14px;
  }

  &.upcoming {
    border: ${stepCircleBorderWidthPx}px solid ${({ theme }) => theme.colors.YELLOWS.YELLOW_300};
    color: ${({ theme }) => theme.colors.YELLOWS.YELLOW_300};
    background: ${({ theme }) => theme.colors.YELLOWS.YELLOW_100};
    font-size: 9px;
  }

  &.unscheduled {
    border: ${stepCircleBorderWidthPx}px solid ${({ theme }) => theme.colors.PRODUCT.SECONDARY_BORDER};
    color: ${({ theme }) => theme.colors.PRODUCT.SECONDARY_BORDER};
    background: ${({ theme }) => theme.colors.PRODUCT.SECONDARY_BACKGROUND};
  }
`;

export const StyledPhaseLabel = styled.div`
  font-size: .95em;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

export const StyledSubtitle = styled.div`
  color: ${({ theme }) => theme.colors.PRODUCT.SECONDARY_CONTENT};
  font-size: .8em;
`;

export const StyledCheckbox = styled(CheckboxInput)`
  border-radius: 4px;
  margin-bottom: .5em;
  z-index: 1;

  &.completed {
    border: 6px solid ${({ theme }) => theme.colors.GREENS.GREEN_50};
  }

  &.upcoming {
    border: 6px solid ${({ theme }) => theme.colors.YELLOWS.YELLOW_50};
  }

  &.unscheduled {
    /* This is to keep the spacing consistent. */
    border: 6px solid transparent;
  }
`;

export const StyledIgnoredCircle = styled.div`
  background: ${({ theme }) => theme.colors.PRODUCT.GRAY_500};
  border-radius: 50%;
  font-size: .7em;
  height: 13px;
  padding: 2px;
  position: relative;
  top: 6.5px;
  width: 13px;
  z-index: 1;

  svg {
    color: white;
  }
`;
