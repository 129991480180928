import Moment from 'moment-timezone';

import type { Application, Stage } from 'types';
import type { CreateSchedulePayload } from 'hooks/queries/schedules';

export const initializeSchedulePayload = (application: Application, stage: Stage): CreateSchedulePayload => {
  const scheduleTemplate = stage.schedule_template;

  return {
    application_id: application.id,
    timezone: scheduleTemplate?.business_hours?.[0]?.timezone || Moment.tz.guess(),
    scheduling_calendar_email: '',
    schedule_template: {
      candidate_event_title: scheduleTemplate?.candidate_calendar_event_template?.title || '',
      candidate_event_description: scheduleTemplate?.candidate_calendar_event_template?.description || '',
      business_hours: [],
      onsite: scheduleTemplate?.onsite || false,
      mark_interviewer_events_as_private: scheduleTemplate?.mark_interviewer_events_as_private || false,
      mark_candidate_events_as_private: scheduleTemplate?.mark_candidate_events_as_private || false,
      video_conferencing_enabled: scheduleTemplate?.video_conferencing_enabled || false,
      create_hiring_channel: scheduleTemplate?.create_hiring_channel || false,
    },
    schedules: [{
      interviews: [],
    }],
  };
};
