import styled from 'styled-components';

import EditorInput from 'components/library/inputs/EditorInput';

export const StyledEditorInput = styled(EditorInput)`
  &.editor-input {
    .editor-wrapper.disabled {
      border: none;
      cursor: default;

      .editor-main {
        background: none;
        cursor: default;
        min-height: auto;
        padding: 0;
      }
    }
  }
`;
