import { Range, Transforms } from 'slate';

import { isElementTypeActive } from '../helper';

import type { CustomEditor, CustomElement, TokenElement } from '../../../../../types';
import { ElementType } from '../../../../../types';

export const withTokens = (editor: CustomEditor): CustomEditor => {
  const { addMark, isInline, isVoid, removeMark } = editor;

  editor.addMark = (key: string, value: any) => {
    const { selection } = editor;

    // This is to add marks to tokens since the default addMark only adds them
    // to text nodes, but we want to add them to the token void nodes as well.
    if (selection) {
      const tokenIsSelected = isElementTypeActive(editor, ElementType.Token);
      if (Range.isExpanded(selection) || tokenIsSelected) {
        Transforms.setNodes(
          editor,
          { [key]: value },
          { match: isToken, hanging: true }
        );
      }
    }

    return addMark(key, value);
  };

  editor.isInline = (element: CustomElement) => {
    return isToken(element) ? true : isInline(element);
  };

  editor.isVoid = (element: CustomElement) => {
    return isToken(element) ? true : isVoid(element);
  };

  editor.removeMark = (key: string) => {
    const { selection } = editor;

    // This is to remove marks from tokens since the default removeMark only
    // remove them from text nodes, but we want to remove them from the token
    // void nodes as well.
    if (selection) {
      const tokenIsSelected = isElementTypeActive(editor, ElementType.Token);
      if (Range.isExpanded(selection) || tokenIsSelected) {
        Transforms.unsetNodes(editor, key, {
          match: isToken,
        });
      }
    }

    return removeMark(key);
  };

  return editor;
};

/**
 * While this function is usually called with Slate Node objects, that's not
 * always the case (e.g. when we manually use the Token component outside of a
 * Slate Editor), so that's why we can't type it to be Node.
 *
 * @param node An object that is "node-like". It just needs a `type` property.
 */
export function isToken (node: any): node is TokenElement {
  return node.type === ElementType.Token;
}
