import { find } from 'lodash';
import Moment from 'moment-timezone';
import { useEffect, useState } from 'react';

import CheckboxInput from 'components/library/inputs/CheckboxInput';
import FeedbackFormSelectInput from 'components/library/inputs/FeedbackFormSelectInput';
import Flash from 'components/library/utils/Flash';
import Modal from 'components/library/layout/Modal';
import SelectInput from 'components/library/inputs/SelectInput';
import { useSession } from 'hooks/use-session';
import { useStage } from 'hooks/queries/stages';
import { formatTimeRange } from 'libraries/formatters';
import { ATS, liveCodingLabels } from 'types';

import type { ChangeEvent } from 'react';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Interview, Schedule } from './types';
import type { Option } from '../../inputs/SelectInput/types';

interface Props {
  initialEnd?: Date | string;
  initialStart?: Date | string;
  isOpen: boolean;
  onAddInterview: (interview: Interview) => void;
  onToggle: () => void;
  schedule: Schedule;
}

const CalendarScheduleAddInterviewModal = ({
  initialEnd,
  initialStart,
  isOpen,
  onAddInterview,
  onToggle,
  schedule,
}: Props) => {
  const { account } = useSession();

  const [stageInterviewId, setStageInterviewId] = useState<string | undefined>();
  const [feedbackFormId, setFeedbackFormId] = useState<string | undefined>();
  const [isLiveCodingEnabled, setIsLiveCodingEnabled] = useState(false);
  const [error, setError] = useState('');

  // Clear selected interview whenever the modal is opened
  useEffect(() => {
    if (isOpen) {
      setStageInterviewId(undefined);
      setFeedbackFormId(undefined);
      setIsLiveCodingEnabled(false);
      setError('');
    }
  }, [isOpen]);

  const { data: stage } = useStage(schedule.stage.job_id, schedule.stage.id);
  const stageInterviews = stage?.stage_interviews;

  if (!stageInterviews) {
    return null;
  }

  const stageInterviewOptions: Option<string>[] = stageInterviews.filter(({ ats_schedulable }) => ats_schedulable === null || ats_schedulable).map((stageInterview) => ({
    label: stageInterview.name,
    value: stageInterview.id,
  }));

  const handleStageInterviewChange = (option: OnChangeValue<Option<string>, false>) => {
    setStageInterviewId(option?.value);

    const selectedStageInterview = find(stageInterviews, ['id', option?.value]);
    if (account?.ats_type === ATS.Lever) {
      setFeedbackFormId(selectedStageInterview?.feedback_form_id);
    }
    if (account?.live_coding_type) {
      setIsLiveCodingEnabled(selectedStageInterview?.interview_template?.live_coding_enabled || false);
    }
  };

  const handleFeedbackFormChange = (option: OnChangeValue<Option<string>, false>) => {
    setFeedbackFormId(option?.value);
  };
  const handleIsLiveCodingEnabledChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsLiveCodingEnabled(e.target.checked);
  };

  const handleSubmit = () => {
    const selectedStageInterview = find(stageInterviews, ['id', stageInterviewId]);
    if (!stageInterviewId || !selectedStageInterview) {
      setError('You must select an interview.');
      return;
    }
    onAddInterview({
      stage_interview_id: stageInterviewId,
      start_time: Moment(initialStart).format(),
      name: selectedStageInterview?.name,
      interview_template_id: selectedStageInterview?.interview_template_id,
      feedback_form_id: account?.ats_type === ATS.Lever ? feedbackFormId : undefined,
      interview_template: {
        name: selectedStageInterview?.name,
        // We round the minutes just in case the user selected a time window
        // right up to the bottom edge of the calendar, which would cause the
        // initialEnd time to be :59 which can cause some issues.
        duration_minutes: Math.round(Moment.duration(Moment(initialEnd).diff(Moment(initialStart))).asMinutes()),
        live_coding_enabled: Boolean(account?.live_coding_type) ?
          isLiveCodingEnabled
          : selectedStageInterview?.interview_template?.live_coding_enabled || false,
        candidate_facing_name: selectedStageInterview?.interview_template?.candidate_facing_name,
        candidate_facing_details: selectedStageInterview?.interview_template?.candidate_facing_details,
      },
      interviewers: selectedStageInterview?.interview_template?.interviewer_templates?.map((interviewerTemplate) => ({
        interviewer_template: {
          ...interviewerTemplate,
          interviewer_filters: (interviewerTemplate.interviewer_filters || []).map((filters) => ({
            ...filters,
            interviewer_filter_expressions: filters.interviewer_filter_expressions || [],
          })),
        },
        potential_users: [],
        selected_user: { user_id: null },
      })) || [],
    });
    onToggle();
  };

  return (
    <Modal
      className="calendar-schedule-add-interview-modal"
      isOpen={isOpen}
      onSubmit={handleSubmit}
      onToggle={onToggle}
      submitButtonValue="Add to schedule"
      title={`Add interview${initialStart && initialEnd ? ` at ${formatTimeRange(Moment(initialStart).tz(schedule.timezone), Moment(initialEnd).tz(schedule.timezone))} ${Moment().tz(schedule.timezone).format('z')}` : ''}?`}
    >
      <Flash
        message={error}
        showFlash={Boolean(error)}
        type="danger"
      />
      <SelectInput
        isAutofocus
        label="Interview"
        onChange={handleStageInterviewChange}
        options={stageInterviewOptions}
        placeholder="Select an interview"
        value={find(stageInterviewOptions, ['value', stageInterviewId])}
      />
      {account?.ats_type === ATS.Lever && (
        <FeedbackFormSelectInput
          label="Feedback Form"
          onChange={handleFeedbackFormChange}
          selectedFeedbackFormId={feedbackFormId}
        />
      )}
      {account?.live_coding_type && (
        <CheckboxInput
          isChecked={isLiveCodingEnabled}
          label={`Include a ${liveCodingLabels[account.live_coding_type]} link.`}
          onChange={handleIsLiveCodingEnabledChange}
        />
      )}
      {/*
        TODO @pallavi: Add following options:
        - ability to edit times
        - interviewers
      */}
    </Modal>
  );
};

export default CalendarScheduleAddInterviewModal;
