import Moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import { faExclamation } from '@fortawesome/free-solid-svg-icons';
import { some } from 'lodash';

import Avatar from '../Avatar';
import Button from '../../inputs/Button';
import ListItem from '../ListItem';
import LoadingSpinner from '../../utils/LoadingSpinner';
import Tag from '../Tag';
import Tooltip from '../../utils/Tooltip';
import {
  StyledAlert,
  StyledContainer,
  StyledContent,
  StyledFooter,
  StyledHeader,
  StyledLeftContainer,
  StyledLoadingSpinnerContainer,
  StyledName,
  StyledRoom,
  StyledTime,
  StyledVideoConferencingLink,
} from './styles';
import { formatMoment, TimeFormat } from '../../../../libraries/time';
import { useInterview } from '../../../../hooks/queries/interviews';
import { useSession } from '../../../../hooks/use-session';
import { videoConferencingLogos } from '../../../../types';

import type { Interview } from '../../../../types';
import { correctPath } from 'libraries/gem';

const hasConflict = ({ interviewers, room_rsvp }: Interview): boolean => {
  return room_rsvp === 'declined' ||
    some(interviewers, ['rsvp', 'declined']);
};

const toolTipCaption = ({ interviewers, room, room_rsvp }: Interview): string => {
  const conflicts = [];
  if (room_rsvp === 'declined') {
    conflicts.push(`${room?.name} declined`);
  }

  interviewers?.forEach(({ rsvp, user }) => {
    if (rsvp === 'declined') {
      conflicts.push(`${user.name || user.email} declined`);
    }
  });

  if (conflicts.length > 2) {
    conflicts[conflicts.length - 1] = ` and ${conflicts[conflicts.length - 1]}`;
  }

  return `${conflicts.join(', ')}.`;
};

interface Props {
  className?: string;
  interview?: Interview;
  interviewId?: string;
}

const InterviewSummary = ({
  className,
  interview: passedInInterview,
  interviewId,
}: Props) => {
  const { account } = useSession();

  const { data: fetchedInterview } = useInterview(interviewId);

  const interview = passedInInterview || fetchedInterview;

  if (!interview) {
    return (
      <StyledLoadingSpinnerContainer>
        <LoadingSpinner />
      </StyledLoadingSpinnerContainer>
    );
  }

  return (
    <StyledContainer className={className}>
      <StyledHeader>
        <StyledLeftContainer>
          <StyledTime>
            {formatMoment(Moment.tz(interview.start_time, interview.schedule.timezone).tz(Moment.tz.guess()), TimeFormat.LongMonthDayYearWithTime)}&ndash;{formatMoment(Moment.tz(interview.start_time, interview.schedule.timezone).tz(Moment.tz.guess()).add(interview.interview_template.duration_minutes, 'minutes'), TimeFormat.TimeWithTimezone)}
            {account?.video_conferencing_type && interview.schedule.schedule_template.video_conferencing_enabled && interview.schedule.candidate_event_location &&
              <StyledVideoConferencingLink
                externalLinkIcon={false}
                href={interview.schedule.candidate_event_location}
                label="Overview Interview Video Conferencing Link"
              >
                <Avatar
                  alt={interview.schedule.candidate_event_location}
                  className={`avatar-${account.video_conferencing_type}`}
                  img={videoConferencingLogos[account.video_conferencing_type]}
                  size="small"
                />
              </StyledVideoConferencingLink>
            }
          </StyledTime>
          <StyledName>
            {interview.name}
          </StyledName>
          {interview.room_id &&
            <StyledRoom>
              <Tag type="room" value={interview.room_id} />
            </StyledRoom>
          }
        </StyledLeftContainer>
        <div>
          {hasConflict(interview) &&
            <>
              <StyledAlert
                data-for={`tooltip-${interview.id}`}
                data-tip
              >
                <FontAwesomeIcon icon={faExclamation} />
              </StyledAlert>
              <Tooltip
                id={`tooltip-${interview.id}`}
                position="top"
                value={toolTipCaption(interview)}
              />
            </>
          }
        </div>
      </StyledHeader>
      <StyledContent>
        <ListItem
          label={interview.schedule.application?.candidate.name || <span className="redacted">Redacted</span>}
          leftIcon={
            <Avatar
              alt={interview.schedule.application?.candidate.name}
              showUserTooltip={false}
              size="large"
              type="candidate"
            />
          }
          secondaryText={interview.schedule.stage.job.name}
        />
      </StyledContent>
      <StyledFooter className={interview.schedule.application ? undefined : 'stub'}>
        {interview.schedule.application ? (
          <Link to={correctPath(`/app/candidates/${interview.schedule.application.id}/schedules/${interview.schedule.id}`)}>
            <Button
              className="btn btn-link"
              color="no-outline"
              value="View Schedule"
            />
          </Link>
        ) : (
          <>&nbsp;</>
        )}
      </StyledFooter>
    </StyledContainer>
  );
};

export default InterviewSummary;
