import { isEmpty, isEqual, omit, uniq } from 'lodash';

import { constructInterviewerTemplatePayloads } from '../helpers';
import { formatList } from 'libraries/formatters';
import { UpdateAction } from '../types';

import type { InterviewTemplate, Stage } from 'types';
import type { InterviewUpdateAction, StageResourceUpdate } from '../types';

export const calculateInterviewUpdateActions = (interviewUpdates: StageResourceUpdate[], interviewTemplates: InterviewTemplate[], stage: Stage): InterviewUpdateAction[][] => {
  // actions[i] is the suggested actions for interviewUpdates[i].
  // An interview update (just a diff between the scheduled interview and the default stage interview) can have multiple suggested actions!
  // For example, if an interview update looks like
  // { feedback_form_id: '...', interview_template: { duration_minutes: 30 } }
  // there would be 2 interview update actions: one to update the stage interview's feedback form, and one to update or create a new interview template
  const actions: InterviewUpdateAction[][] = [];

  interviewUpdates.forEach(({ id, updates }) => {
    const actionsForCurrentInterview: InterviewUpdateAction[] = [];

    // If there are any updates to the stage interview itself (like feedback form), the stage interview should be updated.
    if (!isEmpty(omit(updates, 'interview_template'))) {
      actionsForCurrentInterview.push({ action: UpdateAction.UpdateStageInterview });
    }

    if (updates.interview_template) {
      const potentialParentTemplates: InterviewTemplate[] = interviewTemplates.filter((template) => template.name === updates.interview_template?.name);
      if (isEmpty(potentialParentTemplates)) {
        // This can happen in the following cases:
        // 1. There is no default interview template, and the interview details were manually filled out in the scheduling workflow. --> Suggest creating a new template.
        // 2. There is a default interview template, but it was removed for this candidate. --> Suggest creating a new template.
        actionsForCurrentInterview.push({
          action: UpdateAction.CreateInterviewTemplate,
          newTemplateName: `[${stage.job.name}] ${updates.interview_template.name}`,
        });
      } else {
        // This can happen in the following cases (more below):
        // 1. There is a default interview template. Some settings were changed inline. --> If there is only 1 linked resource, suggest editing the template. Otherwise, suggest saving preferences in a new template.
        const stageInterview = stage.stage_interviews?.find((stageInterview) => stageInterview.id === id);
        const defaultParentTemplate = (potentialParentTemplates || []).find(({ id }) => id === stageInterview?.interview_template_id);
        if (defaultParentTemplate) {
          actionsForCurrentInterview.push({
            action: defaultParentTemplate.linked_interviews <= 1 ? UpdateAction.UpdateInterviewTemplate : UpdateAction.UpdateInterviewTemplateNotAllowed,
            parentTemplate: defaultParentTemplate,
            newTemplateName: defaultParentTemplate.linked_interviews <= 1 ? undefined : `[${stage.job.name}] ${updates.interview_template.name}`,
          });
        } else {
          // 2. A different interview template was used than the default one, and none of its settings were changed --> Suggest linking the stage interview to the other interview template.
          const likelyParentTemplate = (potentialParentTemplates || []).find((template) => Object.entries(updates.interview_template || {}).every(([key, value]) => {
            if (isEmpty(value) && isEmpty(template[key as keyof InterviewTemplate])) {
              return true;
            }
            if (key === 'interviewer_templates') {
              return isEqual(value, constructInterviewerTemplatePayloads(template.interviewer_templates || []));
            }
            return template[key as keyof InterviewTemplate] === value;
          }));
          if (likelyParentTemplate) {
            actionsForCurrentInterview.push({
              action: UpdateAction.SwapInterviewTemplateOnStageInterview,
              parentTemplate: likelyParentTemplate,
            });
          } else {
          // 3. A different interview template was used than the default one, and its settings were changed --> Suggest creating a new template.
          // or (a rare but weird possibility)
          // 4. No interview template was used when scheduling, but the interview name (and thus the name of the inline interview template) matches the name of an existing interview template. --> Suggest creating a new template
            actionsForCurrentInterview.push({
              action: UpdateAction.CreateInterviewTemplate,
              newTemplateName: `[${stage.job.name}] ${updates.interview_template.name}`,
            });
          }
        }
      }

      actions.push(actionsForCurrentInterview);
    }
  });

  return actions;
};

enum InterviewSetting {
  Duration = 'duration_minutes',
  FeedbackForm = 'feedback_form_id',
  Positions = 'positions',
  TimeWindowStart = 'time_window_start',
  TimeWindowEnd = 'time_window_end',
  LiveCodingEnabled = 'live_coding_enabled',
  CandidateFacingName = 'candidate_facing_name',
  CandidateFacingDetails = 'candidate_facing_details',
  InterviewerTemplates = 'interviewer_templates',
  ZoomHost = 'zoom_host',
}

const INTERVIEW_SETTING_LABELS: Record<InterviewSetting, string> = {
  [InterviewSetting.Duration]: 'duration',
  [InterviewSetting.FeedbackForm]: 'feedback form',
  [InterviewSetting.Positions]: 'position',
  [InterviewSetting.TimeWindowStart]: 'time window',
  [InterviewSetting.TimeWindowEnd]: 'time window',
  [InterviewSetting.LiveCodingEnabled]: 'live coding preferences',
  [InterviewSetting.CandidateFacingName]: 'candidate-facing name',
  [InterviewSetting.CandidateFacingDetails]: 'candidate-facing details',
  [InterviewSetting.InterviewerTemplates]: 'interviewers',
  [InterviewSetting.ZoomHost]: 'Zoom host',
};

export const formatUpdatedInterviewSettingsList = (settings: string[]): string => {
  const settingsLabels = uniq(settings.map((setting) => INTERVIEW_SETTING_LABELS[setting as InterviewSetting]).filter((label) => Boolean(label)));
  return formatList(settingsLabels);
};
