import Moment from 'moment-timezone';
import { useMemo } from 'react';

import Avatar from 'components/library/data-display/Avatar';
import Flash from 'components/library/utils/Flash';
import { formatMoment, TimeFormat } from 'libraries/time';
import ListItem from 'components/library/data-display/ListItem';
import LoadingSpinner from 'components/library/utils/LoadingSpinner';
import OutboundLink from 'components/library/navigation/OutboundLink';
import {
  StyledDateScheduledContainer,
  StyledSection,
  StyledSummaryTable,
} from './styles';
import Tag from 'components/library/data-display/Tag';
import { useCalendars } from 'hooks/queries/calendars';
import { useSession } from 'hooks/use-session';
import { useUsersMap } from 'hooks/queries/users';
import { videoConferencingLabels } from 'types';

import type { HiringMeeting } from 'types';
import type { TableSchema } from 'components/library/data-display/Table';

interface Props {
  hiringMeeting: HiringMeeting;
}

const SummarySection = ({ hiringMeeting }: Props) => {
  const { account } = useSession();
  const {
    data: calendars,
    error: calendarsError,
    isLoading: isCalendarsLoading,
  } = useCalendars();
  const users = useUsersMap({ archived: true });

  const schema = useMemo<TableSchema<HiringMeeting>>(() => [{
    header: 'Meeting Date',
    displayValue: ({ start_time, timezone }) => formatMoment(Moment.tz(start_time, timezone), TimeFormat.LongDayOfWeekMonthDayYear),
  }, {
    header: 'Meeting Time',
    displayValue: ({ hiring_meeting_template, start_time, timezone }) => {
      return (
        <span>
          {formatMoment(Moment.tz(start_time, timezone), TimeFormat.Time)}&ndash;{formatMoment(Moment.tz(start_time, timezone).add(hiring_meeting_template.duration_minutes, 'minutes'), TimeFormat.TimeWithTimezone)}
        </span>
      );
    },
  }, Boolean(hiringMeeting.calendar_event_location) && {
    header: 'Location',
    displayValue: ({ calendar_event_location, video_conferencing_meeting_id }) => (
      video_conferencing_meeting_id && calendar_event_location ? (
        <OutboundLink
          href={calendar_event_location}
          label="Hiring Meeting Summary Table Video Conferencing Link"
        >
          {calendar_event_location}
        </OutboundLink>
      ) : calendar_event_location
    ),
  }, Boolean(hiringMeeting.hiring_meeting_template.video_conferencing_enabled && hiringMeeting.video_conferencing_passcode && account?.video_conferencing_type) && {
    header: `${videoConferencingLabels[account!.video_conferencing_type!]} Passcode`,
    displayValue: ({ video_conferencing_passcode }) => video_conferencing_passcode,
  }, Boolean(hiringMeeting.hiring_meeting_template.video_conferencing_enabled && account?.video_conferencing_type === 'zoom' && hiringMeeting.zoom_host_id) && {
    header: 'Zoom Host',
    displayValue: ({ zoom_host_id, zoom_host_type }) => (
      zoom_host_type === 'user' ? (
        <ListItem
          label={users[zoom_host_id!]?.name || users[zoom_host_id!]?.email}
          leftIcon={(
            <Avatar
              showUserTooltip={false}
              size="small"
              userId={zoom_host_id}
            />
          )}
        />
      ) : (
        <Tag
          type="room"
          value={zoom_host_id!}
        />
      )
    ),
  }, Boolean(hiringMeeting.room_id) && {
    header: 'Room',
    displayValue: ({ room_id }) => <Tag type="room" value={room_id!} />,
  }, {
    header: 'Scheduling Calendar',
    displayValue: ({ scheduling_calendar_email }) => {
      if (calendarsError) {
        return (
          <Flash
            message={calendarsError.message}
            showFlash
            type="danger"
          />
        );
      }
      if (isCalendarsLoading) {
        return <LoadingSpinner />;
      }

      // TODO: Display a better error. This can happen if the current user
      // doesn't have access to the scheduling calendar of this schedule.
      return calendars?.calendars[scheduling_calendar_email]?.name || 'Unknown';
    },
  }, {
    header: 'Date Scheduled',
    displayValue: ({ created_at, creator_id, timezone }) => (
      <StyledDateScheduledContainer>
        <span>
          {formatMoment(Moment.tz(created_at, timezone), TimeFormat.LongMonthDayYear)} by
        </span>
        <ListItem
          label={users[creator_id]?.name || users[creator_id]?.email}
          leftIcon={(
            <Avatar
              showUserTooltip={false}
              size="small"
              userId={creator_id}
            />
          )}
        />
      </StyledDateScheduledContainer>
    ),
  }], [
    hiringMeeting,
    calendars,
    calendarsError,
    isCalendarsLoading,
    users,
  ]);

  return (
    <StyledSection title="Summary">
      <StyledSummaryTable
        data={[hiringMeeting]}
        layout="horizontal"
        schema={schema}
      />
    </StyledSection>
  );
};

export default SummarySection;
