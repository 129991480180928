import { faDoorOpen, faPencil, faVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { find, mapValues, some, uniqBy } from 'lodash';
import Moment from 'moment-timezone';
import { useMemo } from 'react';

import Button from 'components/library/inputs/Button';
import Tooltip from 'components/library/utils/Tooltip';
import { useAccount } from 'hooks/queries/accounts';
import { useEvents } from 'hooks/use-events';
import { useRoomsMap } from 'hooks/queries/rooms';
import { useUsersMap } from 'hooks/queries/users';
import { ZoomHostType } from 'types';
import CalendarScheduleDateInput from './CalendarScheduleDateInput';
import CalendarScheduleRoomSelectInput from './CalendarScheduleRoomSelectInput';
import CalendarScheduleZoomHostSelectInput from './CalendarScheduleZoomHostSelectInput';
import { useCalendarScheduleContext } from './use-context';

import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import { formatMoment, TimeFormat } from 'libraries/time';
import type { Option as RoomSelectInputOption } from './CalendarScheduleRoomSelectInput';
import type { Option as ZoomHostSelectInputOption } from './CalendarScheduleZoomHostSelectInput';
import type { PotentialZoomHost } from './types';

const CalendarScheduleHeader = () => {
  const {
    availabilityTimeSlots = [],
    eventConflictCheckFunctions,
    headerDate,
    headerTitle,
    onDateChange,
    onRoomChange,
    onZoomHostChange,
    scheduleId,
    selectedRoomId,
    selectedZoomHostId,
    showZoomHost,
    timezone,
  } = useCalendarScheduleContext();

  const { data: account } = useAccount();
  const rooms = useRoomsMap();
  const users = useUsersMap({ archived: true });

  const date = Moment.tz(headerDate, timezone).format('YYYY-MM-DD');
  const { events } = useEvents();
  const roomEvents = events?.[date]?.[timezone]?.rooms;
  const zoomHostMeetings = events?.[date]?.[timezone]?.zoom_hosts;

  const formattedDate = useMemo(() => formatMoment(Moment.tz(headerDate, timezone), TimeFormat.LongDayOfWeekMonthDay), [headerDate]);

  const allRoomConflicts = useMemo(() => mapValues(
    rooms,
    (room) => roomEvents?.[room.id]?.events?.filter((roomEvent) => some(eventConflictCheckFunctions, (isConflict) => isConflict(roomEvent, true))) || null
  ), [eventConflictCheckFunctions, roomEvents, rooms]);

  const potentialRoomOptions = Object.values(rooms).filter(({ directory_archived, user_archived }) => !user_archived && !directory_archived).map((room) => ({
    room_id: room.id,
    conflicts: allRoomConflicts[room.id] ? uniqBy(allRoomConflicts[room.id], 'id') : null,
  }));

  const allZoomHostConflicts = useMemo(() => showZoomHost ? {
    ...mapValues(
      rooms,
      (room) => zoomHostMeetings?.[room.id]?.meetings?.filter((meeting) => some(eventConflictCheckFunctions, (isConflict) => isConflict(meeting, true))) || null
    ),
    ...mapValues(
      users,
      (user) => zoomHostMeetings?.[user.id]?.meetings?.filter((meeting) => some(eventConflictCheckFunctions, (isConflict) => isConflict(meeting, true))) || null
    ),
  } : {}, [eventConflictCheckFunctions, rooms, showZoomHost, users, zoomHostMeetings]);

  const potentialZoomHosts: PotentialZoomHost[] = [
    ...Object.values(users).filter(({ directory_archived, user_archived, video_conferencing_id }) => !user_archived && !directory_archived && Boolean(video_conferencing_id)).map((host) => ({
      id: host.id,
      type: ZoomHostType.User,
    })),
    ...Object.values(rooms).filter(({ directory_archived, user_archived, video_conferencing_id }) => !user_archived && !directory_archived && Boolean(video_conferencing_id)).map((host) => ({
      id: host.id,
      type: ZoomHostType.Room,
    })),
  ];

  const potentialZoomHostOptions = potentialZoomHosts.map((host) => ({
    id: host.id,
    type: host.type,
    conflicts: host.id && allZoomHostConflicts?.[host.id] ? uniqBy(allZoomHostConflicts[host.id], 'id') : null,
  }));

  const selectedRoom = selectedRoomId ? rooms[selectedRoomId] : null;
  const selectedZoomHost = selectedZoomHostId ? (rooms[selectedZoomHostId] || users[selectedZoomHostId]) : null;

  const isEditable = Boolean(onRoomChange || onZoomHostChange);

  const handleRoomChange = (option: OnChangeValue<RoomSelectInputOption, false>) => {
    onRoomChange?.({
      room_id: option ? option.value : null,
      conflicts: option && option.conflicts && option.conflicts.length > 0 ? option.conflicts : undefined,
    });
  };

  const handleZoomHostChange = (option: OnChangeValue<ZoomHostSelectInputOption, false>) => {
    onZoomHostChange?.({
      id: option ? option.value : null,
      type: option ? option.type : null,
      conflicts: option && option.conflicts && option.conflicts.length > 0 ? option.conflicts : undefined,
    });
  };

  const zoomHostClassName = useMemo(() => {
    if (!selectedZoomHost) {
      return 'empty';
    }
    const zoomHostOption = find(potentialZoomHostOptions, ['id', selectedZoomHost.id]);
    if (zoomHostOption && zoomHostOption.conflicts && zoomHostOption.conflicts.length >= account!.zoom_host_concurrent_meeting_limit) {
      return 'warning';
    }
    return undefined;
  }, [account, selectedZoomHost, potentialZoomHostOptions]);

  return (
    <div className="calendar-header">
      <div className="calendar-header-main-section">
        {headerTitle &&
          <div className="calendar-header-section title">
            {headerTitle}
          </div>
        }
        <div className="calendar-header-section date">
          <Button
            color="no-outline"
            iconRight={isEditable ? <FontAwesomeIcon icon={faPencil} /> : undefined}
            id={`${scheduleId}-date-change`}
            isDisabled={!isEditable}
            onClick={isEditable ? (e) => e.stopPropagation() : undefined}
            tooltip={
              <Tooltip
                helperText="(click to change)"
                id={`${scheduleId}-date-change-button`}
                position="top"
                value={formattedDate}
              />
            }
            value={formattedDate}
          />
          {isEditable && onDateChange &&
            <CalendarScheduleDateInput
              availabilityTimeSlots={availabilityTimeSlots}
              date={headerDate}
              onChange={onDateChange}
              targetId={`${scheduleId}-date-change`}
              timezone={timezone}
            />
          }
        </div>
        {potentialRoomOptions &&
          <div className="calendar-header-section room">
            <Button
              color="no-outline"
              iconLeft={
                <FontAwesomeIcon
                  className={selectedRoom ? ((find(potentialRoomOptions, ['room_id', selectedRoom.id])?.conflicts || []).length > 0 ? 'warning' : undefined) : 'empty'}
                  icon={faDoorOpen}
                />
              }
              iconRight={isEditable ? <FontAwesomeIcon icon={faPencil} /> : undefined}
              id={`${scheduleId}-room-swap`}
              isDisabled={!isEditable}
              onClick={isEditable ? (e) => e.stopPropagation() : undefined}
              tooltip={
                <Tooltip
                  helperText={selectedRoom ? '(click to swap room)' : null}
                  id={`${scheduleId}-room-swap-button`}
                  position="top"
                  value={selectedRoom ? selectedRoom.name : 'Select a room'}
                />
              }
              value={selectedRoom ? selectedRoom.name : 'No room'}
            />
            {isEditable &&
              <CalendarScheduleRoomSelectInput
                date={headerDate}
                onChange={handleRoomChange}
                potentialRooms={potentialRoomOptions}
                selectedRoomId={selectedRoom ? selectedRoom.id : null}
                targetId={`${scheduleId}-room-swap`}
                timezone={timezone}
              />
            }
          </div>
        }
        {showZoomHost && potentialZoomHostOptions &&
          <div className="calendar-header-section zoom-host">
            <Button
              color="no-outline"
              iconLeft={
                <FontAwesomeIcon
                  className={zoomHostClassName}
                  icon={faVideo}
                />
              }
              iconRight={isEditable ? <FontAwesomeIcon icon={faPencil} /> : undefined}
              id={`${scheduleId}-zoom-host-swap`}
              isDisabled={!isEditable}
              onClick={isEditable ? (e) => e.stopPropagation() : undefined}
              tooltip={
                <Tooltip
                  helperText={selectedZoomHost ? '(click to swap host)' : null}
                  id={`${scheduleId}-zoom-host-swap-button`}
                  position="top"
                  value={selectedZoomHost ? selectedZoomHost.name || selectedZoomHost.email : 'Select a host'}
                />
              }
              value={selectedZoomHost ? `Hosted by ${selectedZoomHost.name || selectedZoomHost.email}` : 'No host'}
            />
            {isEditable &&
              <CalendarScheduleZoomHostSelectInput
                date={headerDate}
                onChange={handleZoomHostChange}
                potentialZoomHosts={potentialZoomHostOptions}
                selectedZoomHostId={selectedZoomHost?.id || null}
                targetId={`${scheduleId}-zoom-host-swap`}
                timezone={timezone}
              />
            }
          </div>
        }
      </div>
    </div>
  );
};

export default CalendarScheduleHeader;
