// This is copied from gem/client/useOAuthWindow.tsx and modified slightly.
import { isFunction } from 'lodash';
import { useState } from 'react';

export function startOAuth (
  endpoint?: string,
  onSuccess?: () => void,
  setAuthorizing: (val: boolean) => void = () => {},
) {
  setAuthorizing(true);

  const width = 480;
  const height = 680;
  const left = window.outerWidth / 2 + window.screenX - width / 2;
  const top = window.outerHeight / 2 + window.screenY - height / 2;

  const authWindow = window.open(
    endpoint,
    'authWindow',
    `left=${left}, top=${top}, width=${width}, height=${height}`,
  );

  window.onmessage = async (event) => {
    if (event.source === authWindow && event.data?.isGemOauthResponse) {
      if (event.data.success) {
        // slight delay instead of closing the window immediately, so the user can see the success message
        setTimeout(() => {
          setAuthorizing(false);
          authWindow?.close();
          if (isFunction(onSuccess)) {
            onSuccess();
          }
        }, 1000);
      }
    }
  };

  const poll = setInterval(() => {
    if (authWindow?.closed) {
      clearInterval(poll);
      setAuthorizing(false);
    }
  }, 500);
}

export function useOAuthWindow (
  endpoint?: string,
  onSuccess?: () => void,
): [boolean, () => void] {
  const [authorizing, setAuthorizing] = useState(false);

  function startOAuthInner () {
    startOAuth(endpoint, onSuccess, setAuthorizing);
  }

  return [authorizing, startOAuthInner];
}
