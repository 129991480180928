import MicRecorder from 'mic-recorder-to-mp3';
import { faMicrophone, faPlay, faStop } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useMemo, useRef, useState } from 'react';

import Button, { ButtonColor } from 'components/library/inputs/Button';
import {
  StyledContainer,
  StyledDeleteButton,
  StyledFontAwesomeIcon,
} from './styles';
import Tooltip from 'components/library/utils/Tooltip';

import type { Props as ButtonProps } from 'components/library/inputs/Button';

function formatTime (totalSeconds: number): string {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = Math.floor(totalSeconds % 60);
  return `${minutes.toString().padStart(2, '0')}:${seconds.toString().padStart(2, '0')}`;
}

interface Props extends Omit<ButtonProps, 'color'> {
  file: File | null;
  fileLabel?: string;
  fileName?: string;
  maxSeconds: number;
  onChange: (file: File | null) => void;
  color?: `${ButtonColor}`;
}

const RecorderInput = ({
  file,
  fileLabel,
  fileName,
  maxSeconds,
  onChange,
  color = ButtonColor.BlueOutline,
  ...buttonProps
}: Props) => {
  const [isRecording, setIsRecording] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [offsetSeconds, setOffsetSeconds] = useState<number>(0);
  const [elapsedSeconds, setElapsedSeconds] = useState<number>(0);
  const [encounteredPermissionDeniedError, setEncounteredPermissionDeniedError] = useState(false);

  const player = useMemo<HTMLAudioElement | null>(() => {
    if (file) {
      const audioElement = new Audio(URL.createObjectURL(file));
      audioElement.addEventListener('play', () => setIsPlaying(true));
      audioElement.addEventListener('pause', () => setIsPlaying(false));
      return audioElement;
    }
    return null;
  }, [file]);

  const recorder = useRef<MicRecorder>(new MicRecorder({
    bitRate: 128,
  }));

  // Count the elapsed time of the recording so that it can be displayed.
  useEffect(() => {
    if (isRecording) {
      const id = setInterval(() => {
        setElapsedSeconds(recorder.current.context?.currentTime || 0);
      }, 100);

      return () => {
        clearInterval(id);
      };
    }
  }, [isRecording]);

  useEffect(() => {
    if (isRecording) {
      const id = setTimeout(() => {
        handleStop();
      }, maxSeconds * 1000);

      return () => {
        clearTimeout(id);
      };
    }
  }, [isRecording]);

  const handleRecord = async () => {
    try {
      const startTime = new Date();
      await recorder.current.start();
      const endTime = new Date();
      // If the user needs to grant permission for the browser to use their
      // microphone, that can take some time. But the audio context was created
      // before that, so the currentTime (which is how we determine the
      // elapsedSeconds) has already started. So if the user takes 5s to click
      // "Allow", the timer will start at 00:05. It will still last the correct
      // amount of time, but it will look weird to the user. To mitigate this,
      // we're keep track of how long it took for .start() to resolve. This is
      // the amount that we need to subtract from elapsedSeconds to give the
      // real time.
      setOffsetSeconds((endTime.valueOf() - startTime.valueOf()) / 1000);
      setIsRecording(true);
    } catch (e) {
      setEncounteredPermissionDeniedError(true);
    }
  };

  const handleStop = async () => {
    setIsRecording(false);
    const [buffer, blob] = await recorder.current.stop().getMp3();
    setElapsedSeconds(offsetSeconds);
    setElapsedSeconds(0);
    const file = new File(buffer, 'recording.mp3', {
      type: blob.type,
      lastModified: Date.now(),
    });

    onChange(file);
  };

  const handlePlay = () => {
    if (!player) {
      return;
    }
    player.pause();
    player.currentTime = 0;
    player.play();
  };

  const handleDelete = () => {
    player?.pause();
    onChange(null);
  };

  const buttonValue = useMemo(() => {
    if (isRecording) {
      // The offset time will most likely be slightly more than the elapsed
      // time. To prevent a negative time from showing up, we take the max of it
      // and 0.
      return `Recording: ${formatTime(Math.max(elapsedSeconds - offsetSeconds, 0))} / ${formatTime(maxSeconds)}`;
    }
    if (player) {
      return fileName || 'Play';
    }
    if (fileLabel) {
      return `Record ${fileLabel}`;
    }
    return 'Record';
  }, [isRecording, elapsedSeconds, offsetSeconds, player]);

  return (
    <StyledContainer>
      <Button
        color={color}
        iconLeft={
          <StyledFontAwesomeIcon
            className={isPlaying ? 'playing' : undefined}
            icon={isRecording ? faStop : player ? faPlay : faMicrophone}
          />
        }
        isDisabled={encounteredPermissionDeniedError}
        onClick={isRecording ? handleStop : player ? handlePlay : handleRecord}
        {...buttonProps}
        tooltip={encounteredPermissionDeniedError ? (
          <Tooltip
            id="mic-permission-denied-tooltip"
            position="top"
            value="You have blocked this page from accessing your microphone."
          />
        ) : undefined}
        value={buttonValue}
      />
      {player && (
        <StyledDeleteButton
          id="delete-recording-button"
          onClick={handleDelete}
        />
      )}
    </StyledContainer>
  );
};

export default RecorderInput;
