import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Flash from '../../../../library/utils/Flash';
import InterviewLimitSelectInput from '../../../../library/inputs/InterviewLimitSelectInput';
import Section from '../../../../library/layout/Section';
import { useUpdateUser, useUser } from '../../../../../hooks/queries/users';

const InterviewLimitsSection = () => {
  const { id } = useParams<{ id: string }>();

  const { data: user } = useUser(id);

  const [isEditing, setIsEditing] = useState(false);
  const [dailyInterviewLimit, setDailyInterviewLimit] = useState(user!.daily_interview_limit || 0);
  const [weeklyInterviewLimit, setWeeklyInterviewLimit] = useState(user!.weekly_interview_limit || 0);

  const updateUserMutation = useUpdateUser();

  useEffect(() => {
    setDailyInterviewLimit(user!.daily_interview_limit || 0);
  }, [user!.daily_interview_limit]);

  useEffect(() => {
    setWeeklyInterviewLimit(user!.weekly_interview_limit || 0);
  }, [user!.weekly_interview_limit]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setDailyInterviewLimit(user!.daily_interview_limit || 0);
    setWeeklyInterviewLimit(user!.weekly_interview_limit || 0);
    setIsEditing(false);
  };

  const handleSave = async () => {
    updateUserMutation.reset();

    try {
      await updateUserMutation.mutateAsync({
        id,
        payload: {
          daily_interview_limit: dailyInterviewLimit,
          weekly_interview_limit: weeklyInterviewLimit,
        },
      });
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  return (
    <Section
      isEditable
      isEditing={isEditing}
      isSaving={updateUserMutation.isLoading}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Interview limits"
    >
      <Flash
        message={updateUserMutation.error?.message}
        showFlash={updateUserMutation.isError}
        type="danger"
      />
      <Flash
        isDismissible
        message="Successfully updated!"
        showFlash={updateUserMutation.isSuccess}
        type="success"
      />
      <Flash
        message="These limits will only apply to this interviewer and will override any account-level limits."
        showFlash
        type="info"
      />
      <InterviewLimitSelectInput
        allowUnsetting
        dailyInterviewLimit={dailyInterviewLimit}
        helperText="Use these to set a hard cap on the number of interviews per day and week for this interviewer."
        isDisabled={!isEditing || updateUserMutation.isLoading}
        setDailyInterviewLimit={setDailyInterviewLimit}
        setWeeklyInterviewLimit={setWeeklyInterviewLimit}
        weeklyInterviewLimit={weeklyInterviewLimit}
      />
    </Section>
  );
};

export default InterviewLimitsSection;
