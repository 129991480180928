import classNames from 'classnames';

import EditorInput from './EditorInput';

import type { CustomEditor, EditorType, TokensResponse } from '../../../types';
import type { Descendant } from 'slate';
import type { Dispatch, ReactNode, SetStateAction } from 'react';

interface Props {
  className?: string;
  editor: CustomEditor;
  helperText?: ReactNode;
  id?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  label?: ReactNode;
  pendingPreviewMessage: string;
  setValue: Dispatch<SetStateAction<Descendant[]>>;
  showTokenButton?: boolean;
  tokens?: TokensResponse;
  type: `${EditorType}`;
  value: Descendant[];
}

const TokenInput = ({
  className,
  editor,
  helperText,
  id,
  isDisabled,
  isRequired,
  label,
  pendingPreviewMessage,
  setValue,
  showTokenButton = true,
  tokens,
  type,
  value,
}: Props) => {
  return (
    <EditorInput
      className={classNames('token-input', className)}
      editor={editor}
      helperText={helperText}
      id={id}
      isDisabled={isDisabled}
      isPlainText
      isRequired={isRequired}
      label={label}
      pendingPreviewMessage={pendingPreviewMessage}
      preventNewLines
      setValue={setValue}
      showTokenButton={showTokenButton}
      showToolbar={false}
      tokens={tokens}
      type={type}
      value={value}
    />
  );
};

export default TokenInput;
