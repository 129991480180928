import { useQuery } from 'react-query';

import InterviewPlanner from '../../libraries/interviewplanner';

import type { FeedbackForm } from '../../types';
import type { UseQueryOptions } from 'react-query';

export enum QueryKey {
  ListFeedbackForms = 'ListFeedbackForms',
}

interface ListFeedbackFormsQuery {}

interface ListFeedbackFormsData {
  feedback_forms: FeedbackForm[];
  total: number;
}

export const useFeedbackForms = (query: ListFeedbackFormsQuery = {}, options: UseQueryOptions<ListFeedbackFormsData, Error> = {}) => {
  return useQuery<ListFeedbackFormsData, Error>([QueryKey.ListFeedbackForms, query], () => {
    return InterviewPlanner.request('GET', '/feedback-forms', null, query);
  }, options);
};
