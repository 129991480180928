import { useMutation, useQuery, useQueryClient } from 'react-query';

import InterviewPlanner from '../../libraries/interviewplanner';
import { QueryKey as TrainingProgramQueryKey } from './training-programs';

import type { TrainingProgramUser } from '../../types';
import type { UseQueryOptions } from 'react-query';

export enum QueryKey {
  RetrieveTrainingProgramUser = 'RetrieveTrainingProgramUser',
  ListTrainingProgramUsers = 'ListTrainingProgramUsers',
}

export const useTrainingProgramUser = (trainingProgramId: string, id: string, options: UseQueryOptions<TrainingProgramUser, Error> = {}) => {
  return useQuery(trainingProgramUserParams(trainingProgramId, id, options));
};

export const trainingProgramUserParams = (trainingProgramId: string, id: string, options: UseQueryOptions<TrainingProgramUser, Error> = {}) => {
  return {
    queryKey: [QueryKey.RetrieveTrainingProgramUser, trainingProgramId, id],
    queryFn: () => {
      return InterviewPlanner.request<TrainingProgramUser>('GET', `/training-programs/${trainingProgramId}/users/${id}`);
    },
    ...options,
    enabled: options.enabled !== undefined ? options.enabled : Boolean(trainingProgramId && id),
  };
};

interface ListTrainingProgramUsersQuery {
  limit?: number;
  offset?: number;
  graduated?: boolean;
}

interface ListTrainingProgramUsersData {
  users: TrainingProgramUser[];
  total: number;
}

export const useTrainingProgramUsers = (trainingProgramId: string, query: ListTrainingProgramUsersQuery, options: UseQueryOptions<ListTrainingProgramUsersData, Error> = {}) => {
  return useQuery<ListTrainingProgramUsersData, Error>([QueryKey.ListTrainingProgramUsers, trainingProgramId, query], () => {
    return InterviewPlanner.request('GET', `/training-programs/${trainingProgramId}/users`, null, query);
  }, options);
};

interface CreateTrainingProgramUserPayload {
  user_id: string;
  trainee: boolean;
}

interface CreateTrainingProgramUserMutationVariables {
  trainingProgramId: string;
  payload: CreateTrainingProgramUserPayload;
}

export const useCreateTrainingProgramUser = () => {
  const queryClient = useQueryClient();

  return useMutation<TrainingProgramUser, Error, CreateTrainingProgramUserMutationVariables>(({ trainingProgramId, payload }) => {
    return InterviewPlanner.request('POST', `/training-programs/${trainingProgramId}/users`, payload);
  }, {
    onSuccess: (_, { trainingProgramId, payload }) => {
      queryClient.invalidateQueries([QueryKey.ListTrainingProgramUsers]);
      queryClient.invalidateQueries([QueryKey.RetrieveTrainingProgramUser, payload.user_id]);
      queryClient.invalidateQueries([TrainingProgramQueryKey.RetrieveTrainingProgram, trainingProgramId]);
    },
  });
};

interface DeleteTrainingProgramUserMutationVariables {
  trainingProgramId: string;
  userId: string;
}

export const useDeleteTrainingProgramUser = () => {
  const queryClient = useQueryClient();

  return useMutation<TrainingProgramUser, Error, DeleteTrainingProgramUserMutationVariables>(({ trainingProgramId, userId }) => {
    return InterviewPlanner.request('DELETE', `/training-programs/${trainingProgramId}/users/${userId}`);
  }, {
    onSuccess: (_, { trainingProgramId, userId }) => {
      queryClient.invalidateQueries([QueryKey.ListTrainingProgramUsers]);
      queryClient.invalidateQueries([QueryKey.RetrieveTrainingProgramUser, userId]);
      queryClient.invalidateQueries([TrainingProgramQueryKey.RetrieveTrainingProgram, trainingProgramId]);
    },
  });
};
