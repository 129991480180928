import type { ReactNode } from 'react';

export enum TableLayout {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export interface WithId {
  id?: string;
}

export interface TableSchemaItem<T> {
  displayEditValue?: (item: T, index: number) => ReactNode;
  displayPopoverContent?: (item: T, index: number) => ReactNode;
  displayValue?: (item: T, index: number) => ReactNode;
  getCellClassName?: (item: T, index: number) => string | undefined;
  hasLinkStyleOnHover?: boolean;
  header: ReactNode;
  id?: (item: T, index: number) => string;
  isClickable?: boolean;
  key?: string;
}

export type TableSchema<T> = (TableSchemaItem<T> | undefined | false)[];
export type FilteredTableSchema<T> = TableSchemaItem<T>[];
