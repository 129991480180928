import type { EditableSchedule } from '../../../../../../../../types';
import type { UpdateSchedulePayload } from '../../../../../../../../hooks/queries/schedules';

export const constructPayload = (schedule: EditableSchedule): UpdateSchedulePayload => ({
  schedule_template: {
    candidate_event_title: schedule.schedule_template.candidate_calendar_event_template?.title,
    candidate_event_description: schedule.schedule_template.candidate_calendar_event_template?.description,
    candidate_event_location: schedule.schedule_template.candidate_calendar_event_template?.location,
    video_conferencing_enabled: schedule.schedule_template.video_conferencing_enabled,
  },
  interviews: schedule.interviews.map((interview) => ({
    id: interview.id?.startsWith('new-interview') ? undefined : interview.id,
    interview_template: {
      name: interview.interview_template.name,
      duration_minutes: interview.interview_template.duration_minutes,
      live_coding_enabled: interview.interview_template.live_coding_enabled,
      candidate_facing_name: interview.interview_template.candidate_facing_name,
      candidate_facing_details: interview.interview_template.candidate_facing_details,
    },
    feedback_form_id: interview.feedback_form_id,
    scorecard_stage_interview_id: interview.scorecard_stage_interview_id,
    stage_interview_id: interview.stage_interview_id,
    room_id: interview.room_id,
    zoom_host_id: interview.zoom_host_id,
    zoom_host_type: interview.zoom_host_type,
    start_time: interview.start_time,
    interviewers: (interview.interviewers || []).map((interviewer) => ({
      id: interviewer.id,
      // By this point, we should've validated that the user IDs have been
      // defined.
      user_id: interviewer.user_id!,
      interviewer_template: {
        interviewer_filters: (interviewer.interviewer_template.interviewer_filters || []).map(({ interviewer_filter_expressions }) => ({
          interviewer_filter_expressions: (interviewer_filter_expressions || []).map((expression) => ({
            filterable_id: expression.filterable_id,
            filterable_type: expression.filterable_type,
            negated: expression.negated,
          })),
        })),
        description: interviewer.interviewer_template.description,
        optional: interviewer.interviewer_template.optional,
      },
    })),
  })),
});
