import Moment from 'moment';
import { useMemo, useState } from 'react';

import ArchiveButton from '../../../library/inputs/ArchiveButton';
import FavoriteButton from '../../../library/inputs/FavoriteButton';
import Flash from '../../../library/utils/Flash';
import Label from '../../../library/utils/Label';
import Table from '../../../library/data-display/Table';
import { calendarEventTemplateTypeLabels, FavoriteResourceType } from '../../../../types';
import { formatMoment, TimeFormat } from '../../../../libraries/time';
import { useUpdateCalendarEventTemplate } from '../../../../hooks/queries/calendar-event-templates';

import type { ChangeEvent } from 'react';
import type { CalendarEventTemplate } from '../../../../types';
import type { TableSchema } from '../../../library/data-display/Table';
import { correctPath } from 'libraries/gem';

const getRowLink = ({ id }: CalendarEventTemplate) => correctPath(`/app/calendar-event-templates/${id}`);

interface Props {
  calendarEventTemplates: CalendarEventTemplate[];
  onPageNumberChange: (pageNumber: number) => void;
  onShowArchivedChange: (event: ChangeEvent<HTMLInputElement>) => void;
  pageNumber: number;
  showArchived: boolean;
  totalCount: number;
}

const CalendarEventTemplateListTable = ({
  calendarEventTemplates,
  onPageNumberChange,
  onShowArchivedChange,
  pageNumber,
  showArchived,
  totalCount,
}: Props) => {
  const [lastAction, setLastAction] = useState({
    id: '',
    isUndo: false,
    type: '',
  });

  const updateCalendarEventTemplateMutation = useUpdateCalendarEventTemplate();

  const handleArchive = (id: string, undo: boolean) => {
    setLastAction({
      id,
      type: 'archive',
      isUndo: undo,
    });
    updateCalendarEventTemplateMutation.reset();
    updateCalendarEventTemplateMutation.mutate({ id, payload: { archived: true } });
  };

  const handleUnarchive = (id: string, undo: boolean) => {
    setLastAction({
      id,
      type: 'unarchive',
      isUndo: undo,
    });
    updateCalendarEventTemplateMutation.reset();
    updateCalendarEventTemplateMutation.mutate({ id, payload: { archived: false } });
  };

  const handleUndo = () => {
    if (lastAction.type === 'archive') {
      handleUnarchive(lastAction.id, true);
    }
    if (lastAction.type === 'unarchive') {
      handleArchive(lastAction.id, true);
    }
  };

  const columns = useMemo<TableSchema<CalendarEventTemplate>>(() => [{
    header: '',
    displayValue: ({ favorite, id }) => (
      <FavoriteButton favorite={favorite} resourceId={id} resourceType={FavoriteResourceType.CalendarEventTemplate} />
    ),
    isClickable: true,
  }, {
    header: 'Name',
    displayValue: ({ archived, name }) => (
      <div className="calendar-event-template-list-table-name">
        {name}
        {archived && <Label color="gray">Archived</Label>}
      </div>
    ),
    hasLinkStyleOnHover: true,
  }, {
    header: 'Type',
    displayValue: ({ type }) => calendarEventTemplateTypeLabels[type],
  }, {
    header: 'Linked Active Stages',
    displayValue: ({ linked_resources }) => linked_resources || 0,
  }, {
    header: 'Date Created',
    displayValue: ({ created_at }) => <span className="date">{formatMoment(Moment(created_at), TimeFormat.LongMonthDayYear)}</span>,
  }, {
    header: 'Last Updated',
    displayValue: ({ updated_at }) => <span className="date">{formatMoment(Moment(updated_at), TimeFormat.LongMonthDayYear)}</span>,
  }, {
    header: 'Actions',
    isClickable: true,
    displayValue: ({ archived, id }) => (
      <div className="action-buttons-container">
        <ArchiveButton
          id={id}
          isArchived={archived}
          onArchive={() => handleArchive(id, false)}
          onUnarchive={() => handleUnarchive(id, false)}
        />
        {/* <DeleteButton id={id} /> */}
      </div>
    ),
  }], []);

  return (
    <div className="calendar-event-template-list-table-container">
      <Flash
        isDismissible
        message={(
          <span>
            Calendar event template {lastAction.type}d.&nbsp;
            {!lastAction.isUndo && <a href="#" onClick={handleUndo}>Undo?</a>}
          </span>
        )}
        showFlash={lastAction.id ? updateCalendarEventTemplateMutation.isSuccess : false}
        type="success"
      />
      <Flash
        isDismissible
        message={updateCalendarEventTemplateMutation.error?.message}
        onDismiss={updateCalendarEventTemplateMutation.reset}
        showFlash={updateCalendarEventTemplateMutation.isError}
        type="danger"
      />
      <Table
        data={calendarEventTemplates}
        getRowLink={getRowLink}
        isPaginated
        layout="vertical"
        onPageNumberChange={onPageNumberChange}
        onShowArchivedChange={onShowArchivedChange}
        pageNumber={pageNumber}
        schema={columns}
        showArchived={showArchived}
        totalCount={totalCount}
      />
    </div>
  );
};

export default CalendarEventTemplateListTable;
