import Moment from 'moment';
import { Breadcrumb } from 'react-breadcrumbs';
import { NavLink, Redirect, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { find, isEmpty, orderBy } from 'lodash';
import { useLayoutEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import CandidateSchedule from './CandidateSchedule';
import ListItem from '../../../../library/data-display/ListItem';
import LoadingSpinner from '../../../../library/utils/LoadingSpinner';
import ScheduleMenuLabel from './ScheduleMenuLabel';
import SelectInput from '../../../../library/inputs/SelectInput';
import StatusIndicator from '../../../../library/utils/StatusIndicator';
import { ScheduleType, statusIndicatorValues } from './types';
import { formatMoment, TimeFormat } from '../../../../../libraries/time';
import { useApplication } from '../../../../../hooks/queries/applications';

import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option, ApplicationScheduleWithType } from './types';
import { correctPath } from 'libraries/gem';

const CandidateSchedules = () => {
  const history = useHistory();
  const location = useLocation();

  const { id } = useParams<{ id: string }>();

  const { data: application } = useApplication(id);

  const schedules = useMemo<ApplicationScheduleWithType[]>(() => {
    const schedules: ApplicationScheduleWithType[] = Object.values(application?.all_schedules || [])
    .map((schedule) => ({ ...schedule, type: ScheduleType.Schedule }));
    const links: ApplicationScheduleWithType[] = Object.values(application?.all_self_scheduling_links || [])
    .filter((link) => link.status !== 'completed')
    .map((link) => ({ ...link, type: ScheduleType.SelfSchedulingLink }));

    return orderBy([
      ...schedules,
      ...links,
    ], ['created_at']);
  }, [application?.all_schedules, application?.all_self_scheduling_links]);

  const scheduleOptions = useMemo(() => schedules.map((schedule) => ({
    label: `${schedule.stage.name} (${schedule.type === 'schedule' ? formatMoment(Moment(schedule.interviews[0].start_time), TimeFormat.LongMonthDayYear) : (schedule.status === 'requested' ? 'Pending candidate response' : 'Self-scheduling request')})`,
    value: schedule.id,
    schedule,
  })), [schedules]);

  const [selectedScheduleId, setSelectedScheduleId] = useState('');

  const isSmallScreen = useMediaQuery({ query: '(max-width: 1050px)' });

  // Get selected schedule from url
  useLayoutEffect(() => {
    const splitUrl = location.pathname.split('/schedules/');
    if (splitUrl.length > 1) {
      const scheduleId = splitUrl[1].split('/')[0];
      if (!selectedScheduleId || scheduleId !== selectedScheduleId) {
        setSelectedScheduleId(scheduleId);
      }
    }
  }, [location.pathname]);

  const handleScheduleChange = (option: OnChangeValue<Option, false>) => {
    // This SelectInput can't be cleared, so option should always be defined.
    setSelectedScheduleId(option?.value || '');
    history.push(correctPath(`/app/candidates/${application!.id}/schedules/${option?.value}`));
  };

  if (!application) {
    return <LoadingSpinner />;
  }

  return (
    <Breadcrumb
      data={{
        title: 'Schedules',
        pathname: correctPath(`/app/candidates/${id}/schedules`),
      }}
    >
      <div className="candidate-schedules-container">
        {isSmallScreen ?
          <SelectInput
            formatOptionLabel={(option, { context }) => (
              context === 'menu' ?
                <ListItem
                  label={option.schedule.stage.name}
                  leftIcon={(
                    <StatusIndicator
                      color={statusIndicatorValues[option.schedule.status].color}
                      tooltipId={`schedule-${option.schedule.id}-status-indicator`}
                      tooltipText={statusIndicatorValues[option.schedule.status].label}
                    />
                  )}
                  secondaryText={option.schedule.type === 'schedule' ? formatMoment(Moment(option.schedule.interviews[0].start_time), TimeFormat.LongMonthDayYear) : (option.schedule.status === 'requested' ? 'Pending candidate response' : 'Self-scheduling request')}
                /> :
                <span>
                  <StatusIndicator
                    color={statusIndicatorValues[option.schedule.status].color}
                    tooltipId={`schedule-${option.schedule.id}-status-indicator`}
                    tooltipText={statusIndicatorValues[option.schedule.status].label}
                  />
                  {option.label}
                </span>
            )}
            label="Schedule"
            onChange={handleScheduleChange}
            options={scheduleOptions}
            value={find(scheduleOptions, ['value', selectedScheduleId])}
          /> :
          <div className="schedules-menu">
            {schedules.map((schedule) => (
              <NavLink
                className="link"
                key={schedule.id}
                to={correctPath(`/app/candidates/${application.id}/schedules/${schedule.id}`)}
              >
                <ScheduleMenuLabel application={application} schedule={schedule} />
              </NavLink>
            ))}
          </div>
        }
        {!isEmpty(schedules) &&
          <div className="schedule-container">
            <Switch>
              <Redirect exact from={correctPath('/app/candidates/:id/schedules')} to={correctPath(`/app/candidates/${id}/schedules/${application.active_schedules ? application.active_schedules[0].id : schedules[schedules.length - 1].id}`)} />
              <Route component={CandidateSchedule} path={correctPath('/app/candidates/:id/schedules/:scheduleId')} />
            </Switch>
          </div>
        }
      </div>
    </Breadcrumb>
  );
};

export default CandidateSchedules;
