import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { uniqueId } from 'lodash';
import { useMemo } from 'react';

import Popover from '../../utils/Popover';

import type { ReactNode } from 'react';

interface Props {
  className?: string;
  hasLinkStyleOnHover?: boolean;
  id?: string;
  isClickable?: boolean;
  link?: string;
  popoverContent?: ReactNode;
  value?: ReactNode;
}

const TableCell = ({
  className,
  hasLinkStyleOnHover = false,
  isClickable = false,
  id,
  link,
  popoverContent,
  value,
}: Props) => {
  id = useMemo(() => id || uniqueId('table-cell-'), [id]);

  let content = value;

  if (link && !isClickable) {
    content = (
      <Link className="cell-link" to={link}>
        {value}
      </Link>
    );
  }

  if (link && isClickable) {
    content = (
      <div className="clickable-cell">
        <Link className="cell-link" to={link} />
        <div className="value">
          {value}
        </div>
      </div>
    );
  }

  return (
    <td className={classNames(className, link && hasLinkStyleOnHover && 'hover-link')} id={id}>
      {content}
      {popoverContent &&
        <Popover
          position="bottom"
          target={id}
        >
          {popoverContent}
        </Popover>
      }
    </td>
  );
};

export default TableCell;
