import styled from 'styled-components';

import Modal from 'components/library/layout/Modal';

export const StyledModal = styled(Modal)`
  min-width: 80vw;

  .modal-body {
    overflow: visible !important;
  }
`;

export const StyledUpdateOptionsContainer = styled.div`
  .flash {
    margin-bottom: 2em;
  }

  p {
    margin-block: auto;
  }

  .checkbox-input {
    margin-bottom: 1em;
    min-height: 20px;
  }

  .expandable-checkbox-input {
    margin-bottom: 1em;

    & > label {
      width: fit-content;
    }
  }

  .expandable-checkbox-input-contents-expanded {
    flex-direction: column;
    flex-wrap: nowrap;
    max-height: 40vh;
    overflow-y: scroll;
  }
`;
