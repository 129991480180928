import { keyBy } from 'lodash';
import { useQuery } from 'react-query';

import InterviewPlanner from '../../libraries/interviewplanner';

import type { Calendar } from '../../types';
import type { UseQueryOptions } from 'react-query';

export enum QueryKey {
  ListCalendars = 'ListCalendars',
}

interface ListCalendarsQueryResult {
  calendars: { [id: string]: Calendar };
}

export const useCalendars = (options: UseQueryOptions<ListCalendarsQueryResult, Error> = {}) => {
  // This query can't be canceled because the promise being returned from this
  // function (which is a result of the async keyword) doesn't have a cancel
  // function on it. If we want it to be cancellable, we can't use async/await,
  // and we need to make sure the cancel function from the promise returned from
  // InterviewPlanner.request is preserved.
  return useQuery<ListCalendarsQueryResult, Error>([QueryKey.ListCalendars], async () => {
    const response: Calendar[] = await InterviewPlanner.request('GET', '/calendars');
    return { calendars: keyBy(response, 'id') };
  }, {
    ...options,
  });
};
