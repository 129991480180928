import styled from 'styled-components';

export const StyledContainer = styled.div`
  background: ${({ theme }) => theme.colors.PRODUCT.TERTIARY_BACKGROUND};
  border: 1px solid ${({ theme }) => theme.colors.PRODUCT.SECONDARY_BORDER};
  border-radius: 6px;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  max-width: 250px;
`;

export const StyledInterviewContainer = styled.div<{ expanded: boolean }>`
  background: ${({ expanded }) => expanded ? 'white' : 'inherit'};
  border-left: 4px solid transparent;
  padding: 8px;
  transition: all linear .1s;
  border-top: 1px solid ${({ theme }) => theme.colors.PRODUCT.SECONDARY_BORDER};
  display: flex;
  flex-direction: column;
  max-width: 250px;
`;
