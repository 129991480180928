import { Breadcrumb } from 'react-breadcrumbs';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Route, Switch } from 'react-router-dom';

import InterviewTemplate from './InterviewTemplate';
import InterviewTemplateCreate from './InterviewTemplateCreate';
import InterviewTemplateList from './InterviewTemplateList';
import { correctPath } from 'libraries/gem';

const InterviewTemplates = () =>
  <Breadcrumb
    data={{
      title: <><FontAwesomeIcon icon={faComments} />Interview Templates</>,
      pathname: correctPath('/app/interview-templates'),
    }}
  >
    <Switch>
      <Route component={InterviewTemplateList} exact path={correctPath('/app/interview-templates')} />
      <Route component={InterviewTemplateCreate} path={correctPath('/app/interview-templates/create')} />
      <Route component={InterviewTemplate} path={correctPath('/app/interview-templates/:id')} />
    </Switch>
  </Breadcrumb>
;

export default InterviewTemplates;
