import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import CheckboxInput from '../../../../library/inputs/CheckboxInput';
import DurationInput from '../../../../library/inputs/DurationInput';
import Flash from '../../../../library/utils/Flash';
import InterviewTemplateAdvancedSettings from '../../../../library/inputs/InterviewTemplateAdvancedSettings';
import Section from '../../../../library/layout/Section';
import TextInput from '../../../../library/inputs/TextInput';
import { differingValues } from '../../../../../libraries/differing-values';
import { liveCodingLabels } from '../../../../../types';
import { sortPositions } from '../../../../../libraries/interview-templates';
import { useInterviewTemplate, useUpdateInterviewTemplate } from '../../../../../hooks/queries/interview-templates';
import { useSession } from '../../../../../hooks/use-session';

import type { ChangeEvent } from 'react';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../../../library/inputs/SelectInput/types';
import type { UpdateInterviewTemplatePayload } from '../../../../../hooks/queries/interview-templates';

const SummarySection = () => {
  const { id } = useParams<{ id: string }>();

  const { account } = useSession();

  const { data } = useInterviewTemplate(id);
  const interviewTemplate = data!;

  const [isEditing, setIsEditing] = useState(false);
  const [name, setName] = useState(interviewTemplate.name);
  const [duration, setDuration] = useState(interviewTemplate.duration_minutes);
  const [liveCodingEnabled, setLiveCodingEnabled] = useState(interviewTemplate.live_coding_enabled);
  const [candidateFacingName, setCandidateFacingName] = useState(interviewTemplate.candidate_facing_name || '');
  const [candidateFacingDetails, setCandidateFacingDetails] = useState(interviewTemplate.candidate_facing_details || '');
  const [positions, setPositions] = useState(interviewTemplate.positions);
  const [windowStart, setWindowStart] = useState(interviewTemplate.time_window_start);
  const [windowEnd, setWindowEnd] = useState(interviewTemplate.time_window_end);

  const updateInterviewTemplateMutation = useUpdateInterviewTemplate();

  const nameRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if (isEditing) {
      nameRef.current?.focus();
    }
  }, [isEditing]);

  useEffect(() => {
    setName(interviewTemplate.name);
    setDuration(interviewTemplate.duration_minutes);
    setLiveCodingEnabled(interviewTemplate.live_coding_enabled);
    setCandidateFacingName(interviewTemplate.candidate_facing_name || '');
    setCandidateFacingDetails(interviewTemplate.candidate_facing_details || '');
    setPositions(interviewTemplate.positions);
    setWindowStart(interviewTemplate.time_window_start);
    setWindowEnd(interviewTemplate.time_window_end);
  }, [interviewTemplate]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setName(interviewTemplate.name);
    setDuration(interviewTemplate.duration_minutes);
    setLiveCodingEnabled(interviewTemplate.live_coding_enabled);
    setCandidateFacingName(interviewTemplate.candidate_facing_name || '');
    setCandidateFacingDetails(interviewTemplate.candidate_facing_details || '');
    setPositions(interviewTemplate.positions);
    setWindowStart(interviewTemplate.time_window_start);
    setWindowEnd(interviewTemplate.time_window_end);
  };

  const handleSave = async () => {
    updateInterviewTemplateMutation.reset();

    const payload: UpdateInterviewTemplatePayload = differingValues({
      name,
      duration_minutes: duration,
      live_coding_enabled: liveCodingEnabled,
      candidate_facing_name: candidateFacingName || '',
      candidate_facing_details: candidateFacingDetails && candidateFacingDetails !== '<br>' ? candidateFacingDetails : '',
      positions: sortPositions(positions) || [],
      time_window_start: windowStart || '',
      time_window_end: windowEnd || '',
    }, interviewTemplate);

    try {
      await updateInterviewTemplateMutation.mutateAsync({ id, payload });
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => setName(e.target.value);
  const handleDurationChange = (duration: number) => setDuration(duration);
  const handleLiveCodingEnabledChange = (e: ChangeEvent<HTMLInputElement>) => setLiveCodingEnabled(e.target.checked);
  const handleCandidateFacingNameChange = (e: ChangeEvent<HTMLInputElement>) => setCandidateFacingName(e.target.value);
  const handlePositionsChange = (option: OnChangeValue<Option<number>, true>) => setPositions(option ? option.map(({ value }) => value) : undefined);
  const handleWindowStartChange = (option: OnChangeValue<Option<string>, false>) => setWindowStart(option ? option.value : undefined);
  const handleWindowEndChange = (option: OnChangeValue<Option<string>, false>) => setWindowEnd(option ? option.value : undefined);

  return (
    <Section
      className="interview-template-details-summary"
      isEditable
      isEditing={isEditing}
      isSaving={updateInterviewTemplateMutation.isLoading}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Summary"
    >
      <Flash
        message={updateInterviewTemplateMutation.error?.message}
        showFlash={updateInterviewTemplateMutation.isError}
        type="danger"
      />
      <Flash
        isDismissible
        message="Successfully updated!"
        showFlash={updateInterviewTemplateMutation.isSuccess}
        type="success"
      />
      <TextInput
        className="summary-name"
        id="summary-name"
        isDisabled={!isEditing}
        isRequired
        label="Name"
        onChange={handleNameChange}
        ref={nameRef}
        value={name}
      />
      <DurationInput
        className="summary-duration"
        id="summary-duration"
        isDisabled={!isEditing}
        label="Interview Duration"
        onChange={handleDurationChange}
        value={duration}
      />
      {account!.live_coding_type && (
        <CheckboxInput
          className="summary-live-coding-enabled"
          helperText="The link will be added to interviewer calendar events and the Schedule.Agenda token."
          id="summary-live-coding-enabled"
          isChecked={liveCodingEnabled}
          isDisabled={!isEditing}
          label={`Create a ${liveCodingLabels[account!.live_coding_type]} link when scheduling this interview.`}
          onChange={handleLiveCodingEnabledChange}
        />
      )}
      <InterviewTemplateAdvancedSettings
        candidateFacingDetails={candidateFacingDetails}
        candidateFacingName={candidateFacingName}
        duration={duration}
        handleCandidateFacingNameChange={handleCandidateFacingNameChange}
        handlePositionsChange={handlePositionsChange}
        handleTimeWindowEndChange={handleWindowEndChange}
        handleTimeWindowStartChange={handleWindowStartChange}
        isDisabled={!isEditing}
        isExpanding
        isLoading={updateInterviewTemplateMutation.isLoading}
        positions={positions}
        setCandidateFacingDetails={setCandidateFacingDetails}
        timeWindowEnd={windowEnd}
        timeWindowStart={windowStart}
      />
    </Section>
  );
};

export default SummarySection;
