import styled from 'styled-components';

export const NumberOfOptionsContainer = styled.div`
  text-align: center;

  h2 {
    margin-bottom: 0;
  }

  h6 {
    margin-top: .5em;
  }
`;
