import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import { useEffect, useState } from 'react';

import Button from './Button';
import EditorInput from './EditorInput';
import { slateValueToHtml } from 'libraries/editor/slate-value-to-html';
import PositionInput from './PositionInput';
import StatusIndicator from '../utils/StatusIndicator';
import TextInput from './TextInput';
import TimeRangeInput from './TimeRangeInput';
import { useLDFlags } from 'hooks/use-ld-flags';
import { useSlateEditor } from 'hooks/use-slate-editor';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { ChangeEventHandler } from 'react';
import type { Option } from './SelectInput/types';

const exampleCandidateFacingDetails = '<span>This will be a conversation on company values, operating principles, and your past experiences.</span>';

interface Props {
  candidateFacingName?: string;
  candidateFacingNameInputName?: string;
  candidateFacingDetails?: string;
  duration?: number;
  handleCandidateFacingNameChange: ChangeEventHandler<HTMLInputElement>;
  handlePositionsChange: (newValue: OnChangeValue<Option<number>, true>, actionMeta: ActionMeta<Option<number>>) => void;
  handleTimeWindowEndChange?: (newValue: OnChangeValue<Option<string>, false>, actionMeta: ActionMeta<Option<string>>) => void;
  handleTimeWindowStartChange?: (newValue: OnChangeValue<Option<string>, false>, actionMeta: ActionMeta<Option<string>>) => void;
  isDisabled?: boolean;
  isExpanding?: boolean;
  isLoading?: boolean;
  positions?: number[];
  positionInputName?: string;
  setCandidateFacingDetails: (details: string) => void;
  stageInterviewName?: string;
  timeWindowEnd?: string;
  timeWindowInputName?: string;
  timeWindowStart?: string;
}

const InterviewTemplateAdvancedSettings = ({
  candidateFacingDetails,
  candidateFacingName,
  candidateFacingNameInputName,
  duration,
  handleCandidateFacingNameChange,
  handlePositionsChange,
  handleTimeWindowEndChange,
  handleTimeWindowStartChange,
  isDisabled = false,
  isExpanding = true,
  isLoading = false,
  positions,
  positionInputName,
  setCandidateFacingDetails,
  stageInterviewName,
  timeWindowEnd,
  timeWindowInputName,
  timeWindowStart,
}: Props) => {
  const { candidateFacingInterviewDetails } = useLDFlags();

  const [isExpanded, setIsExpanded] = useState(!isExpanding);

  const [candidateFacingDetailsSlateEditor, candidateFacingDetailsSlateValue, setCandidateFacingDetailsSlateValue] = useSlateEditor(candidateFacingDetails || '');

  useEffect(() => {
    setCandidateFacingDetails(slateValueToHtml(candidateFacingDetailsSlateValue));
  }, [candidateFacingDetailsSlateValue]);

  const toggleIsExpanded = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="interview-template-advanced-settings">
      {isExpanding &&
        <div className={`button-header status${isExpanded ? ' expanded' : ''}`}>
          <Button
            color="no-outline"
            iconLeft={<StatusIndicator color={Boolean((positions && positions.length > 0) || timeWindowStart || timeWindowEnd || candidateFacingName || (candidateFacingDetails && candidateFacingDetails !== '<br>')) ? 'green' : 'gray'} />}
            onClick={toggleIsExpanded}
            size="small"
            value={<span>Advanced settings <FontAwesomeIcon icon={isExpanded ? faAngleUp : faAngleDown } /></span>}
          />
        </div>
      }
      {isExpanded &&
        <div className="expanded-content">
          <div className="advanced-settings">
            <TextInput
              className="advanced-settings-candidate-facing-name"
              helperText={<>This is the name of the interview in the <b>Job.Stage.Agenda</b> and <b>Schedule.Agenda</b> tokens. Recommended if you want a more candidate-friendly name for your interview.</>}
              id="advanced-settings-candidate-facing-name"
              isDisabled={isDisabled}
              label="Candidate-Facing Name"
              name={candidateFacingNameInputName}
              onChange={handleCandidateFacingNameChange}
              placeholder={stageInterviewName || 'Default (the name of the interview)'}
              value={candidateFacingName}
            />
            {candidateFacingInterviewDetails && (
              <EditorInput
                allowTokens={false}
                className="advanced-settings-candidate-facing-details"
                editor={candidateFacingDetailsSlateEditor}
                exampleHtmlContent={exampleCandidateFacingDetails}
                helperText={<>This will be included in the <b>Job.Stage.Agenda</b> and <b>Schedule.Agenda</b> tokens. Recommended if you want to prep the candidate for what to expect.</>}
                isDisabled={isDisabled}
                label="Candidate-Facing Details"
                pendingPreviewMessage="This token will be filled in when you schedule a candidate."
                setValue={setCandidateFacingDetailsSlateValue}
                type="candidate_calendar_event"
                value={candidateFacingDetailsSlateValue}
              />
            )}
            <PositionInput
              className="advanced-settings-position"
              helperText="We will always place the interview at one of these positions in the schedule. Recommended for breaks, greetings, and end-of-day recruiter chats."
              id="advanced-settings-position"
              isDisabled={isDisabled}
              label="Positions"
              name={positionInputName}
              onChange={handlePositionsChange}
              value={positions}
            />
            <TimeRangeInput
              className="advanced-settings-time-window"
              endValue={timeWindowEnd}
              helperText="We will always schedule the interview within this time window. Recommended for interview lunches."
              id="advanced-settings-time-window"
              isDisabled={isDisabled}
              isLoading={isLoading}
              label="Time Window"
              minDuration={duration}
              name={timeWindowInputName}
              onEndChange={handleTimeWindowEndChange}
              onStartChange={handleTimeWindowStartChange}
              startValue={timeWindowStart}
            />
          </div>
        </div>
      }
    </div>
  );
};

export default InterviewTemplateAdvancedSettings;
