import styled from 'styled-components';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import ListItem from '../../../../library/data-display/ListItem';
import MultiStepFormStep from 'components/library/inputs/MultiStepFormStep';
import StepProgressBar from 'components/library/navigation/StepProgressBar';

export const StyledStepProgressBar = styled(StepProgressBar)`
  margin: 1.5em 0;
`;

export const StyledMultiStepFormStep = styled(MultiStepFormStep)`
  margin: 3em 0;
  padding-bottom: 50px;

  .select-input {
    margin: 2em 0;

    &.input-hiring-meeting-attendee-select-input {
      margin-bottom: 0;
    }
  }

  .expandable-checkbox-input-contents-expanded > .input {
    margin: initial;
  }
`;

export const StyledListItem = styled(ListItem)`
  padding: 0;
`;

export const StyledCheckIcon = styled(FontAwesomeIcon)`
  color: ${({ theme }) => theme.colors.funGreen || theme.colors.GREENS.GREEN_300};
`;
