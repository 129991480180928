import { createContext, useContext, useMemo, useState } from 'react';

import type { Dispatch, SetStateAction, ReactNode } from 'react';
import type { Schedule } from './types';

export enum Step {
  InterviewPlan,
  Interviewers,
  Availability,
  Schedule,
  EventsAndEmails,
  Review,
}

interface NewScheduleContextState {
  completedStep: Step;
  schedule: Schedule;
  scheduleId: string;
  setCompletedStep: Dispatch<SetStateAction<Step>>;
  setSchedule: Dispatch<SetStateAction<Schedule>>;
  setScheduleId: Dispatch<SetStateAction<string>>;
}

function createEmptySchedule (): Schedule {
  return {
    timezone: '',
    application_id: '',
    scheduling_calendar_email: '',
    availabilities: [],
    submittedAvailabilities: [],
    hold: false,
    schedule_template: {
      business_hours: [],
      onsite: false,
      mark_interviewer_events_as_private: false,
      mark_candidate_events_as_private: false,
      video_conferencing_enabled: false,
      create_hiring_channel: false,
    },
    stage: {
      id: '',
      job_id: '',
    },
    stage_interviews: [],
    generatedOptionsByNumberOfBlocks: {},
  };
}

// This is the context for keeping track of the new schedule. You should keep track
// of the new link with a useState call in the same component that use use the
// Provider, recreate an array using useMemo, and then pass it into the Provider.
// Make sure you don't pass an array literal into the Provider. This can cause
// re-rendering issues as noted here:
// https://reactjs.org/docs/context.html#caveats.
const NewScheduleContext = createContext<NewScheduleContextState>({
  completedStep: Step.InterviewPlan,
  schedule: createEmptySchedule(),
  scheduleId: '',
  setCompletedStep: () => {},
  setSchedule: () => {},
  setScheduleId: () => {},
});

interface Props {
  children: ReactNode;
}

export const NewScheduleProvider = ({ children }: Props) => {
  const [schedule, setSchedule] = useState(createEmptySchedule());
  const [scheduleId, setScheduleId] = useState('');
  const [completedStep, setCompletedStep] = useState(Step.InterviewPlan);

  const contextValue = useMemo<NewScheduleContextState>(() => ({
    completedStep,
    schedule,
    scheduleId,
    setCompletedStep,
    setSchedule,
    setScheduleId,
  }), [completedStep, schedule, scheduleId]);

  return (
    <NewScheduleContext.Provider value={contextValue}>
      {children}
    </NewScheduleContext.Provider>
  );
};

export const useNewSchedule = () => {
  return useContext(NewScheduleContext);
};
