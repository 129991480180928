import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalize } from 'lodash';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

import Avatar from '../data-display/Avatar';
import OutboundLink from '../navigation/OutboundLink';
import Tooltip from '../utils/Tooltip';
import { atsLogos } from '../../../types';
import { constructAtsHref } from '../../../libraries/candidates';
import { useSession } from '../../../hooks/use-session';

import type { AtsHrefType, AtsIds } from '../../../libraries/candidates';

interface Props {
  atsIds: AtsIds;
  type: `${AtsHrefType}`;
}

const OpenInATSButton = ({ atsIds, type }: Props) => {
  const { account } = useSession();

  if (!account?.ats_type) {
    return null;
  }

  return (
    <>
      <OutboundLink
        className="btn btn-large btn-gray"
        externalLinkIcon={false}
        href={constructAtsHref(account, type, atsIds)}
        label={`Open in ATS - ${capitalize(type)}`}
        tooltip={(
          <Tooltip
            helperText={<FontAwesomeIcon icon={faExternalLinkAlt} />}
            id="ats-button"
            position="top"
            value={`Open in ${capitalize(account.ats_type)}`}
          />
        )}
      >
        <Avatar
          alt={capitalize(account.ats_type)}
          img={atsLogos[account.ats_type]}
          size="large"
          type="company"
        />
      </OutboundLink>
    </>
  );
};

export default OpenInATSButton;
