import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import CheckboxInput from 'components/library/inputs/CheckboxInput';
import CopyInterviewActionsInput from './CopyInterviewActionsInput';
import ExpandableCheckboxInput from 'components/library/inputs/ExpandableCheckboxInput';
import Flash, { FlashType } from 'components/library/utils/Flash';
import { getDefaultCopyInterviewActions } from './helpers';
import { useCopyStage, useStage } from 'hooks/queries/stages';
import StageSelectInput from './StageSelectInput';
import { StyledModal, StyledPreferencesForm } from './styles';
import Tooltip from 'components/library/utils/Tooltip';

import type { Stage } from 'types';
import type { CopyInterviewActions } from './types';

interface Props {
  isOpen: boolean;
  onToggle: () => void;
}

const CopyPreferencesFromStageModal = ({ isOpen, onToggle }: Props) => {
  const { id: jobId, stageId } = useParams<{ id: string; stageId: string }>();
  const [isSuccess, setIsSuccess] = useState<boolean>(false);

  const copyStageMutation = useCopyStage();

  const [selectedTemplateStage, setSelectedTemplateStage] = useState<Stage | undefined>();
  const [copySchedulingPreferences, setCopySchedulingPreferences] = useState<boolean>(false);
  const [copyInterviews, setCopyInterviews] = useState<boolean>(false);
  const [copyInterviewActions, setCopyInterviewActions] = useState<CopyInterviewActions>({ keep: [], add: [] });
  const [copyAvailabilityPreferences, setCopyAvailabilityPreferences] = useState<boolean>(false);

  const { data: stage } = useStage(jobId, stageId);
  const { data: templateStage, remove: clearTemplateStageQueries } = useStage(selectedTemplateStage?.job_id, selectedTemplateStage?.id);

  // Reset the template stage and preferences each time the modal is closed.
  useEffect(() => {
    if (isOpen) {
      setIsSuccess(false);
      copyStageMutation.reset();
      setSelectedTemplateStage(undefined);
      clearTemplateStageQueries();
      setCopySchedulingPreferences(false);
      setCopyInterviews(false);
      setCopyInterviewActions({ keep: [], add: [] });
      setCopyAvailabilityPreferences(false);
    }
  }, [isOpen]);

  // Set the default preferences each time a template stage is selected.
  useEffect(() => {
    if (stage && templateStage) {
      setCopySchedulingPreferences(true);
      setCopyInterviews(!isEmpty(templateStage.stage_interviews));
      setCopyInterviewActions(getDefaultCopyInterviewActions(stage, templateStage));
      setCopyAvailabilityPreferences(Boolean(templateStage.availability_template_id));
    }
  }, [stage, templateStage]);

  const handleCopyStage = async () => {
    copyStageMutation.reset();

    const payload = {
      source_stage_id: templateStage!.id,
      copy_scheduling_preferences: copySchedulingPreferences,
      copy_interviews: copyInterviews ? [
        ...copyInterviewActions.keep.filter(({ isSelected }) => isSelected).map((action) => ({
          action: ('keep' as 'keep' | 'add'),
          destination_stage_interview_id: action.stageInterview.id,
          source_stage_interview_id: action.templateStageInterview?.id,
        })),
        ...copyInterviewActions.add.filter(({ isSelected }) => isSelected).map((action) => ({
          action: ('add' as 'keep' | 'add'),
          source_stage_interview_id: action.templateStageInterview.id,
        })),
      ] : undefined,
      copy_availability_preferences: copyAvailabilityPreferences,
    };

    try {
      await copyStageMutation.mutateAsync({ id: stageId, jobId, payload });
      setIsSuccess(true);
      onToggle();
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  if (!stage) {
    return null;
  }

  return (
    <>
      <Flash
        isDismissible
        message="Successfully updated!"
        showFlash={isSuccess}
        type={FlashType.Success}
      />
      <StyledModal
        isOpen={isOpen}
        isSubmitting={copyStageMutation.isLoading}
        onSubmit={handleCopyStage}
        onToggle={onToggle}
        showSubmitButton={Boolean(templateStage)}
        submitButtonIsDisabled={!(copySchedulingPreferences || copyInterviews || copyAvailabilityPreferences)}
        submitButtonValue="Copy from Stage"
        submittingButtonValue="Saving..."
        title={`Copy preferences for ${stage.name} from another stage?`}
      >
        <Flash
          message={copyStageMutation.error?.message}
          showFlash={copyStageMutation.isError}
          type={FlashType.Danger}
        />
        <StageSelectInput
          currentStage={stage}
          onChange={setSelectedTemplateStage}
          value={selectedTemplateStage}
        />
        {selectedTemplateStage && templateStage &&
          <>
            <StyledPreferencesForm>
              <CheckboxInput
                isChecked={copySchedulingPreferences}
                label="Copy scheduling preferences."
                onChange={(e) => setCopySchedulingPreferences(e.target.checked)}
              />
              <ExpandableCheckboxInput
                isChecked={copyInterviews}
                isDisabled={isEmpty(templateStage.stage_interviews)}
                label="Copy interviews."
                onChange={(e) => setCopyInterviews(e.target.checked)}
                tooltip={isEmpty(templateStage.stage_interviews) ? (
                  <Tooltip
                    id="interviews-copy-preferences-tooltip"
                    value="The stage you are copying from has no interviews."
                  />
                ) : undefined}
              >
                <CopyInterviewActionsInput
                  currentStage={stage}
                  onChange={setCopyInterviewActions}
                  templateStage={templateStage}
                  value={copyInterviewActions}
                />
              </ExpandableCheckboxInput>
              <CheckboxInput
                isChecked={copyAvailabilityPreferences}
                isDisabled={!Boolean(templateStage.availability_template_id)}
                label="Copy availability preferences."
                onChange={(e) => setCopyAvailabilityPreferences(e.target.checked)}
                tooltip={templateStage.availability_template_id ? undefined : (
                  <Tooltip
                    id="availability-copy-preferences-tooltip"
                    value="Requesting availability is not enabled on the stage you are copying from."
                  />
                )}
              />
            </StyledPreferencesForm>
          </>
        }
      </StyledModal>
    </>
  );
};

export default CopyPreferencesFromStageModal;
