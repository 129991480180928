import styled from 'styled-components';

export const StyledHeaderActions = styled.div`
  display: flex;
  align-items: end;
  gap: .5em;

  @media only screen and (max-width: 750px) {
    flex-direction: column;
  }

  a {
    padding: 0;
  }
`;
