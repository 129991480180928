export enum NotificationType {
  AvailabilityRequestCancelled = 'availability_request_cancelled',
  CandidateDeclined = 'candidate_declined',
  GoogleTokenError = 'google_token_error',
  GreenhouseAPIKeyError = 'greenhouse_api_key_error',
  GreenhousePermissionError = 'greenhouse_permission_error',
  InterviewerDeclined = 'interviewer_declined',
  JobNewlyImported = 'job_newly_imported',
  LeverAPIKeyError = 'lever_api_key_error',
  LeverPermissionsError = 'lever_permission_error',
  Microsoft365AuthError = 'microsoft365_auth_error',
  ReadyToRequestAvailability = 'ready_to_request_availability',
  ReadyToSchedule = 'ready_to_schedule',
  RoomDeclined = 'room_declined',
  ScheduleCancelled = 'schedule_cancelled',
  SlackTokenError = 'slack_token_error',
  StageInterviewNewlyImported = 'stage_interview_newly_imported',
  StageNewlyImported = 'stage_newly_imported',
  ZoomTokenError = 'zoom_token_error',
}

interface NotificationBase {
  id: string;
  user_id: string;
  user: User;
  type: NotificationType;
  read_at?: string;
  read_reason?: string;
  created_at: string;
  last_seen_at: string;
}

export interface NotificationAvailabilityRequestCancelledMetadata {
  type: NotificationType.AvailabilityRequestCancelled;
  metadata: {
    application_id: string;
    candidate_name: string;
    job_name: string;
    stage_id: string;
    stage_name: string;
  };
}

export interface NotificationCandidateDeclinedMetadata {
  type: NotificationType.CandidateDeclined;
  metadata: {
    application_id: string;
    candidate_id: string;
    candidate_name: string;
    event_name: string;
    job_name: string;
    schedule_id: string;
  };
}

export interface NotificationGoogleTokenErrorMetadata {
  type: NotificationType.GoogleTokenError;
  metadata: {
    user_id: string;
    user_name?: string;
  };
}

export interface NotificationGreenhouseAPIKeyErrorMetadata {
  type: NotificationType.GreenhouseAPIKeyError;
  metadata: {};
}

export interface NotificationGreenhousePermissionErrorMetadata {
  type: NotificationType.GreenhousePermissionError;
  metadata: {
    permission: string;
  };
}

export interface NotificationInterviewerDeclinedMetadata {
  type: NotificationType.InterviewerDeclined;
  metadata: {
    application_id: string;
    candidate_id: string;
    candidate_name: string;
    event_name: string;
    interviewer_id: string;
    interviewer_name: string;
    job_name: string;
    schedule_id: string;
  };
}

export interface NotificationJobNewlyImportedMetadata {
  type: NotificationType.JobNewlyImported;
  metadata: {
    job_id: string;
    job_name: string;
  };
}

export interface NotificationLeverAPIKeyErrorMetadata {
  type: NotificationType.LeverAPIKeyError;
  metadata: {
    state?: 'invalid' | 'wrong' | 'revoked_token';
  };
}

export interface NotificationLeverPermissionsErrorMetadata {
  type: NotificationType.LeverPermissionsError;
  metadata: {};
}

export interface NotificationMicrosoft365AuthErrorMetadata {
  type: NotificationType.Microsoft365AuthError;
  metadata: {
    user_id: string;
    user_name?: string;
  };
}

export interface NotificationReadyToRequestAvailabilityMetadata {
  type: NotificationType.ReadyToRequestAvailability;
  metadata: {
    application_id: string;
    candidate_name: string;
    job_name: string;
    stage_id: string;
    stage_name: string;
  };
}

export interface NotificationReadyToScheduleMetadata {
  type: NotificationType.ReadyToSchedule;
  metadata: {
    application_id: string;
    candidate_name: string;
    default_workflow?: 'schedule_now' | 'self_schedule';
    job_name: string;
    stage_id: string;
    stage_name: string;
  };
}

export interface NotificationRoomDeclinedMetadata {
  type: NotificationType.RoomDeclined;
  metadata: {
    application_id: string;
    candidate_name: string;
    event_name: string;
    interview_id: string;
    job_name: string;
    room_id: string;
    room_name: string;
    schedule_id: string;
  };
}

export interface NotificationScheduleCancelledMetadata {
  type: NotificationType.ScheduleCancelled;
  metadata: {
    application_id: string;
    candidate_name: string;
    job_name: string;
    stage_id: string;
    stage_name: string;
    stage_enabled_request_availability: 'true' | 'false';
  };
}

export interface NotificationSlackTokenErrorMetadata {
  type: NotificationType.SlackTokenError;
  metadata: {
    user_id: string;
  };
}

export interface NotificationStageInterviewNewlyImportedMetadata {
  type: NotificationType.StageInterviewNewlyImported;
  metadata: {
    job_id: string;
    job_name: string;
    stage_interview_id: string;
    stage_interview_name: string;
    stage_id: string;
    stage_name: string;
  };
}

export interface NotificationStageNewlyImportedMetadata {
  type: NotificationType.StageNewlyImported;
  metadata: {
    job_id: string;
    job_name: string;
    stage_id: string;
    stage_name: string;
  };
}

export interface NotificationZoomTokenErrorMetadata {
  type: NotificationType.ZoomTokenError;
  metadata: {
    user_id: string;
    user_name?: string;
  };
}

// If you add more types, make sure to update TaskDescriptionText.
type NotificationAvailabilityRequestCancelled = NotificationBase & NotificationAvailabilityRequestCancelledMetadata;
type NotificationCandidateDeclined = NotificationBase & NotificationCandidateDeclinedMetadata;
type NotificationGoogleTokenError = NotificationBase & NotificationGoogleTokenErrorMetadata;
type NotificationGreenhouseAPIKeyError = NotificationBase & NotificationGreenhouseAPIKeyErrorMetadata;
type NotificationGreenhousePermissionError = NotificationBase & NotificationGreenhousePermissionErrorMetadata;
type NotificationInterviewerDeclined = NotificationBase & NotificationInterviewerDeclinedMetadata;
type NotificationJobNewlyImported = NotificationBase & NotificationJobNewlyImportedMetadata;
type NotificationLeverAPIKeyError = NotificationBase & NotificationLeverAPIKeyErrorMetadata;
type NotificationLeverPermissionsError = NotificationBase & NotificationLeverPermissionsErrorMetadata;
type NotificationMicrosoft365AuthError = NotificationBase & NotificationMicrosoft365AuthErrorMetadata;
type NotificationReadyToRequestAvailability = NotificationBase & NotificationReadyToRequestAvailabilityMetadata;
type NotificationReadyToSchedule = NotificationBase & NotificationReadyToScheduleMetadata;
type NotificationRoomDeclined = NotificationBase & NotificationRoomDeclinedMetadata;
type NotificationScheduleCancelled = NotificationBase & NotificationScheduleCancelledMetadata;
type NotificationSlackTokenError = NotificationBase & NotificationSlackTokenErrorMetadata;
type NotificationStageInterviewNewlyImported = NotificationBase & NotificationStageInterviewNewlyImportedMetadata;
type NotificationStageNewlyImported = NotificationBase & NotificationStageNewlyImportedMetadata;
type NotificationZoomTokenError = NotificationBase & NotificationZoomTokenErrorMetadata;

export type NotificationMetadata =
  | NotificationAvailabilityRequestCancelledMetadata
  | NotificationCandidateDeclinedMetadata
  | NotificationGoogleTokenErrorMetadata
  | NotificationGreenhouseAPIKeyErrorMetadata
  | NotificationGreenhousePermissionErrorMetadata
  | NotificationInterviewerDeclinedMetadata
  | NotificationJobNewlyImportedMetadata
  | NotificationLeverAPIKeyErrorMetadata
  | NotificationLeverPermissionsErrorMetadata
  | NotificationMicrosoft365AuthErrorMetadata
  | NotificationReadyToRequestAvailabilityMetadata
  | NotificationReadyToScheduleMetadata
  | NotificationRoomDeclinedMetadata
  | NotificationScheduleCancelledMetadata
  | NotificationSlackTokenErrorMetadata
  | NotificationStageInterviewNewlyImportedMetadata
  | NotificationStageNewlyImportedMetadata
  | NotificationZoomTokenErrorMetadata;

export type Notification =
  | NotificationAvailabilityRequestCancelled
  | NotificationCandidateDeclined
  | NotificationGoogleTokenError
  | NotificationGreenhouseAPIKeyError
  | NotificationGreenhousePermissionError
  | NotificationInterviewerDeclined
  | NotificationJobNewlyImported
  | NotificationLeverAPIKeyError
  | NotificationLeverPermissionsError
  | NotificationMicrosoft365AuthError
  | NotificationReadyToRequestAvailability
  | NotificationReadyToSchedule
  | NotificationRoomDeclined
  | NotificationScheduleCancelled
  | NotificationSlackTokenError
  | NotificationStageInterviewNewlyImported
  | NotificationStageNewlyImported
  | NotificationZoomTokenError;

interface User {
  id: string;
  account_id: string;
}
