import styled from 'styled-components';

import TextInput from '../TextInput';

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledInputContainer = styled.div`
  align-items: center;
  display: flex;
`;

export const StyledTextInput = styled(TextInput)`
  &.input.text-input .text-input-container input {
    width: 3.5em;
  }
`;

export const StyledUnitLabel = styled.label`
  .input & {
    color: ${({ theme }) => theme.colors.mineshaftBlack || theme.colors.PRODUCT.PRIMARY_CONTENT};
    font-size: 1em;
    font-weight: normal;
    margin: 0 .7em;
  }
`;
