import TextInput from './TextInput';

import type { ChangeEventHandler } from 'react';

interface Props {
  className?: string;
  helperText?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  label?: string;
  max?: number;
  min?: number;
  name?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
  value: string | number;
}

const RollingWindowDaysInput = ({
  className,
  helperText = 'This is a rolling window based on when the candidate views the availability request',
  isDisabled = false,
  isRequired = false,
  label,
  max,
  min,
  onChange,
  value,
}: Props) => {
  return (
    <div className={`input rolling-window-days-input${className ? ` ${className}` : ''}`}>
      <label>{label}</label>
      <div className="rolling-window-days-input-container">
        <span>Next</span>
        <TextInput
          isDisabled={isDisabled}
          isRequired={isRequired}
          numberMax={max}
          numberMin={min}
          onChange={onChange}
          type="number"
          value={value}
        />
        <span>days</span>
      </div>
      {helperText && <div className="helper-text">{helperText}</div>}
    </div>
  );
};

export default RollingWindowDaysInput;
