import { useEffect, useRef } from 'react';

import type { DependencyList, EffectCallback } from 'react';

/**
 * This hook is very similar to useEffect except that it skips executing on the
 * first render. So it only runs for updates. This is inspired from this SO
 * answer: https://stackoverflow.com/a/53180013.
 */
export function useDidUpdateEffect (effect: EffectCallback, deps?: DependencyList): void {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current) {
      return effect();
    }
    didMountRef.current = true;
  }, deps);
}
