import styled from 'styled-components';

import Button from '../../library/inputs/Button';
import Table from '../../library/data-display/Table';
import TextInput from '../../library/inputs/TextInput';

// The typing for this is weird because of styled-components. This is inspired from
// https://github.com/styled-components/styled-components/issues/1803#issuecomment-857092410
export const StyledTable = styled(Table)`
  th,
  td {
    &:first-child {
      border-right: 1px solid ${({ theme }) => theme.colors.PRODUCT.SECONDARY_BORDER};
    }
  }

  .training-phase-table-index {
    color: ${({ theme }) => theme.colors.PRODUCT.SECONDARY_CONTENT};
    text-align: center;
    width: 1.5em;
  }

  .training-phase-table-number-of-interviews {
    text-align: center;
    width: 200px;

    .input {
      margin: 0 auto;
    }
  }

  /* This is for the header of number of interviews. */
  th:nth-child(3) {
    text-align: center;
  }

  .training-phase-table-actions {
    text-align: center;
    width: 1.5em;

    .btn-delete {
      color: ${({ theme }) => theme.colors.ACCENT.RED};

      .icon-right {
        margin: 0;
      }
    }
  }
` as typeof Table;

export const StyledNumberOfInterviewsTextInput = styled(TextInput)`
  width: 100px;

  &.override {
    /* This increase in width results from the "Default" in the placeholder for overrides. */
    width: 125px;
  }
`;

export const StyledAddPhaseButton = styled(Button)`
  margin: 1em 0;
`;
