import type { ReactNode } from 'react';

interface Props {
  actions?: ReactNode;
  children: ReactNode;
  className?: string;
  icon?: JSX.Element;
  title?: ReactNode;
}

const Card = ({ actions, children, className, icon, title }: Props) =>
  <div className={`card${className ? ` ${className}` : ''}`}>
    {icon &&
      <div className="icon-container">
        {icon}
      </div>
    }
    {(title || actions) &&
      <div className="card-header">
        {title &&
          <div className="card-title">
            <h3>
              {title}
            </h3>
          </div>
        }
        {actions &&
          <div className="card-actions">
            {actions}
          </div>
        }
      </div>
    }
    <div className="card-content">
      {children}
    </div>
  </div>
;

export default Card;
