import Moment from 'moment';
import { Link } from 'react-router-dom';
import { capitalize, kebabCase } from 'lodash';
import { useMemo } from 'react';

import Avatar from '../../../../library/data-display/Avatar';
import ListItem from '../../../../library/data-display/ListItem';
import SchedulingStatusIndicator from '../../../../library/utils/SchedulingStatusIndicator';
import Section from '../../../../library/layout/Section';
import Table from '../../../../library/data-display/Table';
import { formatMoment, TimeFormat } from '../../../../../libraries/time';
import { useUsersMap } from '../../../../../hooks/queries/users';

import type { Application } from '../../../../../types';
import type { TableSchema } from '../../../../library/data-display/Table';
import { correctPath } from 'libraries/gem';

interface Props {
  application: Application;
}

const ApplicationDetailsSection = ({ application }: Props) => {
  const users = useUsersMap({ archived: true });

  const columns = useMemo<TableSchema<Application>>(() => {
    return [{
      header: 'Job',
      displayValue: ({ job }) => <Link to={correctPath(`/app/jobs/${job.id}`)}>{job.name}</Link>,
    }, {
      header: 'Date Applied',
      displayValue: ({ ats_created_at }) => formatMoment(Moment(ats_created_at), TimeFormat.LongMonthDayYear),
    }, application.current_stage && {
      header: application.status === 'rejected' ? 'Last Stage' : 'Current Stage',
      displayValue: ({ current_stage }) => current_stage && <Link to={correctPath(`/app/jobs/${current_stage.job_id}/stages/${current_stage.id}`)}>{current_stage.name}</Link>,
    }, {
      header: 'Status',
      displayValue: ({ scheduling_status }) => <SchedulingStatusIndicator schedulingStatus={scheduling_status} />,
    }, application.scheduling_status === 'inactive' && {
      header: 'Outcome',
      // An application can be inactive if its status is hired or rejected, but
      // it can also be inactive if the underlying candidate is archived, which
      // indicates that the candidate was merged with another.
      displayValue: ({ status }) => status !== 'active' ? capitalize(status) : 'Merged',
    }, application.scheduling_status !== 'inactive' && {
      header: 'Interview Date',
      displayValue: ({ active_schedules, scheduling_status }) => (
        <span className={active_schedules && active_schedules.length > 0 ? '' : 'unset'}>
          {active_schedules && active_schedules.length > 0 ?
            formatMoment(Moment(active_schedules[0].interviews[0].start_time), TimeFormat.LongMonthDayYear) :
            scheduling_status === 'unschedulable' ?
              'N/A' :
              'Not scheduled'
          }
        </span>
      ),
    }, {
      header: 'Last Modified',
      displayValue: ({ ats_updated_at }) => formatMoment(Moment(ats_updated_at), TimeFormat.LongMonthDayYear),
    }, {
      header: 'Hiring Team',
      displayValue: (application) => (
        <div className="hiring-panel-container">
          {[
            { role: 'Sourcer', userID: application.sourcer_id },
            { role: 'Recruiter', userID: application.recruiter_id },
            { role: 'Coordinator', userID: application.coordinator_id },
            { role: 'Hiring Manager', userID: application.hiring_manager_id },
          ].map((member) => {
            const user = member.userID ? users[member.userID] : undefined;
            return (
              <ListItem
                key={`${kebabCase(member.role)}`}
                label={user ? user.name || user.email : <span className="unassigned">Unassigned</span>}
                leftIcon={<Avatar
                  alt={user ? undefined : 'Unassigned'}
                  showUserTooltip={false}
                  size="small"
                  userId={user?.id}
                />}
                secondaryText={member.role}
              />
            );
          })}
        </div>
      ),
    }];
  }, [application, users]);

  return (
    <Section title="Application details">
      <Table
        data={[application]}
        layout="horizontal"
        schema={columns}
      />
    </Section>
  );
};

export default ApplicationDetailsSection;
