import { Link } from 'react-router-dom';

interface Props {
  isCancelled?: boolean;
  isNotFound?: boolean;
}

const AvailabilityNotFound = ({ isCancelled = false, isNotFound = false }: Props) => (
  <div className="availability-container link-not-found">
    <Link className="logo" to="/">
      <img alt="InterviewPlanner logo" src="/scheduling/src/assets/images/logo.svg" />
    </Link>
    <img alt="Broken link" src="/scheduling/src/assets/images/broken-link.svg" />
    <div>
      <h1>
        Oh no!&nbsp;
        {isCancelled && 'This availability link doesn\'t exist anymore.'}
        {isNotFound && 'We can\'t find that availability link.'}
        {!isCancelled && !isNotFound && 'Something went wrong.'}
      </h1>
      <p>
        Please contact the person who sent you this link for assistance.
      </p>
    </div>
  </div>
);

export default AvailabilityNotFound;
