import { Breadcrumb } from 'react-breadcrumbs';

import DailyBreakdownSection from './DailyBreakdownSection';
import { correctPath } from 'libraries/gem';

const DailyBreakdown = () => (
  <Breadcrumb
    data={{
      title: 'Daily Breakdown',
      pathname: correctPath('/app/interviewer-reports/daily-breakdown'),
    }}
  >
    <DailyBreakdownSection />
  </Breadcrumb>
);

export default DailyBreakdown;
