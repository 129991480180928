import { createContext, useContext, useMemo } from 'react';

import type { ReactNode } from 'react';
import type { DateRange, Option } from './types';

interface DateRangeContextState {
  dateRange?: Option;
  disablePastDates?: boolean;
  setDateRange: (value: DateRange) => void;
  isAlwaysCustom?: boolean;
}

const DateRangeContext = createContext<DateRangeContextState>({
  dateRange: undefined,
  disablePastDates: undefined,
  setDateRange: () => {},
  isAlwaysCustom: undefined,
});

interface Props {
  children: ReactNode;
  dateRange?: Option;
  disablePastDates?: boolean;
  setDateRange: (value: DateRange) => void;
  isAlwaysCustom?: boolean;
}

export const DateRangeProvider = ({ children, dateRange, disablePastDates, setDateRange, isAlwaysCustom }: Props) => {
  const contextValue = useMemo<DateRangeContextState>(() => ({
    dateRange,
    disablePastDates,
    setDateRange,
    isAlwaysCustom,
  }), [dateRange, disablePastDates, setDateRange, isAlwaysCustom]);

  return (
    <DateRangeContext.Provider value={contextValue}>
      {children}
    </DateRangeContext.Provider>
  );
};

export const useDateRange = () => {
  return useContext(DateRangeContext);
};
