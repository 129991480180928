import Moment from 'moment';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { find } from 'lodash';

import DateRangeInput from '../../library/inputs/DateRangeInput';
import Flash from '../../library/utils/Flash';
import LoadingSpinner from '../../library/utils/LoadingSpinner';
import Section from '../../library/layout/Section';
import UserSelectInput from '../../library/inputs/UserSelectInput';
import useSyncStateWithQuery from '../../../hooks/use-sync-state-with-query';
import { ScheduleStatus } from '../../../types';
import { StyledInterviewSummary } from './styles';
import { formatDateRange } from '../../../libraries/formatters';
import { useInterviews } from '../../../hooks/queries/interviews';
import { useSession } from '../../../hooks/use-session';
import { useUsersMap } from '../../../hooks/queries/users';

import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option as DateRangeOption } from '../../library/inputs/DateRangeInput/types';
import type { Option } from '../../library/inputs/SelectInput/types';

const DEFAULT_DATE_RANGE_OPTIONS: DateRangeOption[] = [{
  label: 'Today',
  value: 'today',
  startDate: Moment().startOf('day').format(),
  endDate: Moment().endOf('day').format(),
}, {
  label: 'Tomorrow',
  value: 'tomorrow',
  startDate: Moment().add(1, 'days').startOf('day').format(),
  endDate: Moment().add(1, 'days').endOf('day').format(),
}, {
  label: 'Yesterday',
  value: 'yesterday',
  startDate: Moment().subtract(1, 'days').startOf('day').format(),
  endDate: Moment().subtract(1, 'days').endOf('day').format(),
}, {
  label: 'This week',
  value: 'wtd',
  startDate: Moment().startOf('isoWeek').format(),
  endDate: Moment().startOf('isoWeek').add(4, 'days').format(),
}];

const formatDateRangeToString = (queryDateRange: string): string => {
  // To handle the edge case where the user navigates directly to /overview?range=
  if (!queryDateRange) {
    return '';
  }
  if (queryDateRange.includes(':')) {
    const [startDate, endDate] = queryDateRange.split(':');
    return formatDateRange(startDate, endDate);
  }
  const option = find(DEFAULT_DATE_RANGE_OPTIONS, ({ value }) => value === queryDateRange);
  return (option?.label as string).toLowerCase() || '';
};

const InterviewsScheduledSection = () => {
  const { currentUser } = useSession();

  const [dateRange, queryDateRange, setDateRange] = useSyncStateWithQuery<string>('range', 'today');
  const [startDate, setStartDate] = useState(dateRange && dateRange.includes(':') ? dateRange.split(':')[0] : undefined);
  const [endDate, setEndDate] = useState(dateRange && dateRange.includes(':') ? dateRange.split(':')[1] : undefined);
  const [including, queryIncluding, setIncluding] = useSyncStateWithQuery<string>('including', currentUser!.id);

  const users = useUsersMap({ archived: true });
  const includingUser = useMemo(() => users[including], [users, including]);

  const [startTimeStart, startTimeEnd] = useMemo(() => {
    return [
      startDate ? Moment(startDate).startOf('day').format() : undefined,
      endDate ? Moment(endDate).add(1, 'day').startOf('day').format() : undefined,
    ];
  }, [startDate, endDate]);

  const {
    data: interviews,
    error: interviewsError,
    isFetching: isInterviewsFetching,
  } = useInterviews({
    schedule_status: [ScheduleStatus.Confirming, ScheduleStatus.Confirmed],
    start_time_start: startTimeStart,
    start_time_end: startTimeEnd,
    including_user: queryIncluding !== '' ? queryIncluding : undefined,
  }, {
    enabled: Boolean(startDate && endDate),
  });

  // To handle the edge case where the user navigates directly to /overview?range=
  // We don't want to load all interviews for all time, instead we default it to today.
  useEffect(() => {
    if (!queryDateRange) {
      setDateRange('today');
    }
  }, [Boolean(queryDateRange)]);

  const handleDateRangeChange = useCallback((option: OnChangeValue<DateRangeOption, false>) => {
    if (!option) {
      option = DEFAULT_DATE_RANGE_OPTIONS[0];
    }
    setStartDate(option.startDate);
    setEndDate(option.endDate);
    if (option.value === 'custom') {
      const formattedStartDate = Moment(option.startDate).format('YYYY-MM-DD');
      const formattedEndDate = option.endDate ? Moment(option.endDate).format('YYYY-MM-DD') : '';
      setDateRange(`${formattedStartDate}:${formattedEndDate}`);
    } else {
      setDateRange(option.value);
    }
  }, []);

  const handleIncludingChange = useCallback((option: OnChangeValue<Option<string>, false>) => {
    setIncluding(option?.value || '');
  }, []);

  const isSelectedDateRangeInPast = Boolean(endDate && Moment(endDate).isBefore(Moment()));

  return (
    <Section
      headerActions={(
        <>
          <DateRangeInput
            className="header-date-range-input"
            defaultOptions={DEFAULT_DATE_RANGE_OPTIONS}
            isLoading={isInterviewsFetching}
            label="For"
            layout="horizontal"
            onChange={handleDateRangeChange}
            selectedDateRange={{
              value: dateRange && dateRange.includes(':') ? 'custom' : dateRange,
              startDate,
              endDate,
            }}
          />
          <UserSelectInput
            annotateCurrentUser
            className="header-user-select-input"
            currentUserFirst
            isClearable
            label="Including"
            layout="horizontal"
            name="interviews-select-input"
            onChange={handleIncludingChange}
            value={including}
          />
        </>
      )}
      title="Interviews scheduled"
    >
      {isInterviewsFetching ? (
        <LoadingSpinner />
      ) : (
        <>
          <Flash
            message={
              including === '' ?
                `No one ${isSelectedDateRangeInPast ? 'had' : 'has'} any interviews ${formatDateRangeToString(queryDateRange)}.` :
                currentUser!.id === including ?
                  `You ${isSelectedDateRangeInPast ? 'had' : 'have'} no interviews ${formatDateRangeToString(queryDateRange)}.` :
                  includingUser ?
                    `${includingUser?.name || includingUser?.email} ${isSelectedDateRangeInPast ? 'had' : 'has'} no interviews ${formatDateRangeToString(queryDateRange)}.` :
                    <LoadingSpinner />

            }
            showFlash={interviews?.total === 0 || false}
            type="info"
          />
          <Flash
            message={interviewsError?.message}
            showFlash={Boolean(interviewsError)}
            type="danger"
          />
          {interviews && (
            <div className="interviews-container">
              {interviews.interviews.map((interview) => (
                <StyledInterviewSummary
                  interview={interview}
                  key={interview.id}
                />
              ))}
            </div>
          )}
        </>
      )}
    </Section>
  );
};

export default InterviewsScheduledSection;
