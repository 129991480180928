import { faCheck, faTimes } from '@fortawesome/free-solid-svg-icons';

import { StyledCheckmarkIcon, StyledXIcon } from './styles';

import type { FeatureValue } from './data';

interface Props {
  value: FeatureValue;
}

const FeatureDisplayValue = ({ value }: Props) => {
  if (value === true) {
    return <StyledCheckmarkIcon icon={faCheck} />;
  }
  if (value === false) {
    return <StyledXIcon icon={faTimes} />;
  }
  return <>{value}</>;
};

export default FeatureDisplayValue;
