import { Breadcrumb } from 'react-breadcrumbs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Route, Switch } from 'react-router-dom';
import { faPeopleGroup } from '@fortawesome/free-solid-svg-icons';

import HiringMeetingTemplate from './HiringMeetingTemplate';
import HiringMeetingTemplateCreate from './HiringMeetingTemplateCreate';
import HiringMeetingTemplateList from './HiringMeetingTemplateList';
import { correctPath } from 'libraries/gem';

const HiringMeetingTemplates = () =>
  <Breadcrumb
    data={{
      title: <><FontAwesomeIcon icon={faPeopleGroup} />Hiring Meeting Templates</>,
      pathname: correctPath('/app/hiring-meeting-templates'),
    }}
  >
    <Switch>
      <Route component={HiringMeetingTemplateList} exact path={correctPath('/app/hiring-meeting-templates')} />
      <Route component={HiringMeetingTemplateCreate} path={correctPath('/app/hiring-meeting-templates/create')} />
      <Route component={HiringMeetingTemplate} path={correctPath('/app/hiring-meeting-templates/:id')} />
    </Switch>
  </Breadcrumb>
;

export default HiringMeetingTemplates;
