import { capitalize } from 'lodash';

import StatusIndicator from '../../../../library/utils/StatusIndicator';
import { AvailabilityStatus } from '../../../../../types';
import { formatDuration } from '../../../../../libraries/formatters';
import { statusIndicatorValues } from './types';

import type { Application, ATS } from '../../../../../types';
import type { AvailabilityWithTotalSubmittedMinutes } from './types';

interface Props {
  application: Application;
  atsType?: `${ATS}`;
  availability: AvailabilityWithTotalSubmittedMinutes;
}

const AvailabilityMenuLabel = ({ application, atsType, availability }: Props) => {
  // If the stage of the availability request doesn't match the application's stage and the status isn't submitted, it
  // should be cancelled.
  const status = availability.stage_id !== application.current_stage_id && availability.status !== AvailabilityStatus.Submitted ? AvailabilityStatus.Cancelled : availability.status;

  return (
    <div className="availability-menu-label">
      <div className="status">
        <StatusIndicator
          color={statusIndicatorValues[status].color}
          tooltipId={`availability-${availability.id}-status-indicator`}
          tooltipText={statusIndicatorValues[status].label}
        />
      </div>
      <div className="content">
        <div className="stage" title={`${availability.stage.name}${availability.stage.job_id !== application.job_id ? ` (${availability.stage.job.name})` : ''}`}>{availability.stage.name}{availability.stage.job_id !== application.job_id ? ` (${availability.stage.job.name})` : ''}</div>
        <div className="hours">{availability.totalSubmittedMinutes ? `${formatDuration(availability.totalSubmittedMinutes, true)} of availability` : statusIndicatorValues[status].label}</div>
        <div className="source">
          {availability.manual ?
            'Manually entered' :
            `Through ${availability.ats_id ? capitalize(atsType) : `InterviewPlanner${availability.automatic_send_log_id ? ' automatically' : ''}`}`
          }
        </div>
      </div>
    </div>
  );
};

export default AvailabilityMenuLabel;
