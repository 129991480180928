import pluralize from '../../../../libraries/pluralize';
import { StyledContainer, StyledInputContainer, StyledTextInput, StyledUnitLabel } from './styles';

import type { ReactNode } from 'react';

interface Props {
  additionalText?: string;
  className?: string;
  dayLabel?: string;
  helperText?: ReactNode;
  id?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  label?: string;
  max?: number;
  min?: number;
  name?: string;
  onChange: (value: number, name?: string) => void;
  value?: number;
}

const DayDurationInput = ({
  additionalText,
  dayLabel = 'day',
  className,
  helperText,
  id,
  isDisabled = false,
  isRequired = false,
  label,
  max,
  min,
  name,
  onChange,
  value,
}: Props) => {
  return (
    <StyledContainer className={`input${className ? ` ${className}` : ''}`}>
      {label && <label htmlFor={id}>{label}</label>}
      <StyledInputContainer>
        <StyledTextInput
          id={id}
          isDisabled={isDisabled}
          isRequired={isRequired}
          name={name}
          numberMax={max}
          numberMin={min}
          onChange={(e) => onChange(parseInt(e.target.value, 10), name)}
          placeholder="0"
          type="number"
          value={value || ''}
        />
        <StyledUnitLabel htmlFor={id}>
          {pluralize(dayLabel, value)}{additionalText && ` ${additionalText}`}
        </StyledUnitLabel>
      </StyledInputContainer>
      {helperText && <div className="helper-text">{helperText}</div>}
    </StyledContainer>
  );
};

export default DayDurationInput;
