export enum BusinessHourReferenceType {
  AvailabilityTemplate = 'availability_template',
  ScheduleTemplate = 'schedule_template',
  User = 'user',
}

export enum BusinessHourDay {
  Sunday = 'sunday',
  Monday = 'monday',
  Tuesday = 'tuesday',
  Wednesday = 'wednesday',
  Thursday = 'thursday',
  Friday = 'friday',
  Saturday = 'saturday',
}
export enum SentinelTimes {
  StartOfDay = '00:00',
  EndOfDay = '24:00',
}

export const businessHourDayOrdering: Record<BusinessHourDay, number> = {
  [BusinessHourDay.Sunday]: 0,
  [BusinessHourDay.Monday]: 1,
  [BusinessHourDay.Tuesday]: 2,
  [BusinessHourDay.Wednesday]: 3,
  [BusinessHourDay.Thursday]: 4,
  [BusinessHourDay.Friday]: 5,
  [BusinessHourDay.Saturday]: 6,
};

export const allDays = [
  BusinessHourDay.Sunday,
  BusinessHourDay.Monday,
  BusinessHourDay.Tuesday,
  BusinessHourDay.Wednesday,
  BusinessHourDay.Thursday,
  BusinessHourDay.Friday,
  BusinessHourDay.Saturday,
];

export const defaultDays = [
  BusinessHourDay.Monday,
  BusinessHourDay.Tuesday,
  BusinessHourDay.Wednesday,
  BusinessHourDay.Thursday,
  BusinessHourDay.Friday,
];

export interface BusinessHour {
  id: string;
  account_id: string;
  reference_id: string;
  reference_type: BusinessHourReferenceType;
  day: BusinessHourDay;
  start_time: string;
  end_time: string;
  timezone: string | null;
  created_at: string;
  updated_at: string;
}

export interface EditableBusinessHour {
  day: BusinessHourDay;
  start_time: string;
  end_time: string;
  timezone?: string | null;
}
