import { useQuery } from 'react-query';

import InterviewPlanner from '../../libraries/interviewplanner';

import type { UseQueryOptions } from 'react-query';

export enum QueryKey {
  RetrievePermissionInfo = 'RetrievePermissionInfo',
}

interface RetrievePermissionInfoData {
  has_token: boolean;
  has_admin_consent: boolean;
  has_all_permissions: boolean;
}

type RetrievePermissionInfoType = 'directory';

export const usePermissionInfo = (type: RetrievePermissionInfoType, options: UseQueryOptions<RetrievePermissionInfoData, Error> = {}) => {
  return useQuery<RetrievePermissionInfoData, Error>([QueryKey.RetrievePermissionInfo, type], () => {
    return InterviewPlanner.request('GET', `/permissions/${type}`);
  }, {
    ...options,
  });
};
