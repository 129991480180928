import Dropzone from 'react-dropzone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faImage, faUpload } from '@fortawesome/free-solid-svg-icons';
import { uniqueId } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';

import Button from './Button';
import Flash from '../utils/Flash';
import LoadingSpinner from '../utils/LoadingSpinner';
import OutboundLink from '../navigation/OutboundLink';
import TextInput from './TextInput';
import { formatBytes } from '../../../libraries/formatters';

import type { KeyboardEvent, ReactNode, ChangeEvent } from 'react';

// We do some tricky stuff where we want Files and Blobs to behave similarly, so
// we need to make and use this type. A File is a special kind of BlobWithName.
export type BlobWithName = Blob & { name: string };

const downloadFileFromUrl = async (url: string): Promise<BlobWithName> => {
  const formattedUrl = (url.startsWith('https://') || url.startsWith('http://')) ? url : `http://${url}`;
  const res = await fetch(formattedUrl, { cache: 'reload' });
  if (res.ok) {
    const downloadedFile = await res.blob() as BlobWithName;
    downloadedFile.name = formattedUrl;
    return downloadedFile;
  } else {
    throw new Error('There was an error downloading the file.');
  }
};

interface Props {
  allowDownloadFromUrl?: boolean;
  className?: string;
  file?: string | BlobWithName;
  fileTypes?: string[];
  helperText?: ReactNode;
  id?: string;
  isDisabled?: boolean;
  label?: ReactNode;
  maxFileSizeBytes?: number;
  onChange?: (file: BlobWithName | null) => void;
}

const FileDropInput = ({
  allowDownloadFromUrl = false,
  className,
  file,
  fileTypes,
  helperText,
  id,
  isDisabled = false,
  label,
  maxFileSizeBytes,
  onChange,
}: Props) => {
  id = useMemo(() => id || uniqueId('file-drop-input-'), [id]);

  const [controlledFile, setControlledFile] = useState<string | BlobWithName | null | undefined>(file);
  const [fileData, setFileData] = useState<string | null>(null);
  const [fileUrl, setFileUrl] = useState('');
  const [isDownloadedFromUrl, setIsDownloadedFromUrl] = useState(false);
  const [error, setError] = useState('');

  const setFile = onChange || setControlledFile;

  // If a URL is passed into the file prop, download it as a file
  useEffect(() => {
    (async () => {
      if (file && typeof file === 'string') {
        setIsDownloadedFromUrl(false);
        try {
          const downloadedFile = await downloadFileFromUrl(file);
          setFile(downloadedFile);
          setIsDownloadedFromUrl(true);
        } catch {
          setFile(null);
          setError('There was an error downloading the file.');
        }
      }
    })();
  }, [file]);

  useEffect(() => {
    if (!file || typeof file !== 'string') {
      setControlledFile(file);
    }
  }, [file]);

  useEffect(() => {
    if (controlledFile && typeof controlledFile !== 'string') {
      setError('');
      const reader = new FileReader();
      reader.onerror = () => setError('There was an error reading your file.');
      reader.onload = (e) => setFileData(e.target?.result as string || null);
      reader.readAsDataURL(controlledFile);
    }
  }, [controlledFile]);

  const handleDrop = useCallback(([acceptedFile]: File[]) => {
    setError('');
    setFile(acceptedFile);
    setIsDownloadedFromUrl(false);
    setFileData(null);
    if (!acceptedFile) {
      setError('You must select only one file, and it must meet the file type and size requirements.');
    }
  }, []);

  const handleFileUrlChange = (e: ChangeEvent<HTMLInputElement>) => {
    setFileUrl(e.target.value);
  };

  const handleDownloadFromUrl = async () => {
    setError('');
    setFile(null);
    setIsDownloadedFromUrl(false);
    setFileData(null);
    try {
      const downloadedFile = await downloadFileFromUrl(fileUrl);
      setFile(downloadedFile);
      setIsDownloadedFromUrl(true);
      setFileUrl('');
    } catch {
      setError('There was an error downloading the file.');
    }
  };

  const handleFormKeyDown = (e: KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter') {
      e.stopPropagation();
      e.preventDefault();
      handleDownloadFromUrl();
    }
  };

  const isLoading = controlledFile && !fileData;

  return (
    <div className={`input file-drop-input${className ? ` ${className}` : ''}`}>
      {label && <label htmlFor={id}>{label}</label>}
      <div className="dropzone-container">
        <Flash
          message={error}
          showFlash={Boolean(error)}
          type="danger"
        />
        {!isDisabled && controlledFile && controlledFile instanceof Blob &&
          <div className="caption">
            {isDownloadedFromUrl ?
              <OutboundLink
                href={controlledFile.name}
                label="File Drop Input"
              >
                Image URL
              </OutboundLink> :
              controlledFile.name
            }
          </div>
        }
        <Dropzone
          accept={fileTypes}
          disabled={isDisabled}
          maxSize={maxFileSizeBytes}
          multiple={false}
          onDrop={handleDrop}
        >
          {({ getRootProps, getInputProps, isDragActive }) => (
            <div { ...getRootProps({ className: `dropzone${isDisabled ? ' disabled' : ''}` }) }>
              <input { ...getInputProps() } />
              {fileData &&
                <div className="file-preview">
                  <img alt="Uploaded file" src={fileData} />
                </div>
              }
              {isLoading && <div className="instructions"><LoadingSpinner /></div>}
              {!isLoading && isDisabled && !fileData &&
                <div className="instructions">
                  <div>
                    <FontAwesomeIcon icon={faImage} />
                    <div>No file</div>
                  </div>
                </div>
              }
              {!isLoading && !isDisabled &&
                <div className={`instructions${fileData && !isDragActive ? ' hidden' : ''}`}>
                  <div>
                    <FontAwesomeIcon icon={faUpload} />
                    {isDragActive ?
                      <div>
                        <b>Drop file here.</b>
                      </div> :
                      <div>
                        <b>Drag and drop a file here</b><br />
                        or click to browse.
                        {(fileTypes || maxFileSizeBytes) &&
                          <span>
                            <br />Must be
                            {fileTypes && <span> {fileTypes.length > 1 ? 'one of' : 'a'} {fileTypes.map((type) => type.split('/')[1].toUpperCase()).join(', ')}</span>}
                            {fileTypes && maxFileSizeBytes && <span> and</span>}
                            {maxFileSizeBytes && <span> less than {formatBytes(maxFileSizeBytes)}</span>}
                            .
                          </span>
                        }
                      </div>
                    }
                  </div>
                </div>
              }
            </div>
          )}
        </Dropzone>
        {allowDownloadFromUrl && !isDisabled &&
          <div
            className="image-url-form"
            onKeyDown={handleFormKeyDown}
            tabIndex={0}
          >
            <TextInput
              onChange={handleFileUrlChange}
              placeholder="...or paste image URL"
              value={fileUrl}
            />
            <Button
              color="gem-blue"
              onClick={handleDownloadFromUrl}
              size="small"
              value="Go"
            />
          </div>
        }
      </div>
      {helperText &&
        <div className="helper-text">
          {helperText}
        </div>
      }
    </div>
  );
};

export default FileDropInput;
