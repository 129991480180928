import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import classNames from 'classnames';

interface Props {
  className?: string;
}

const LoadingSpinner = ({ className }: Props) => (
  <FontAwesomeIcon className={classNames('spinner', className)} icon={faCircleNotch} spin />
);

export default LoadingSpinner;
