import Moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMoon } from '@fortawesome/free-solid-svg-icons';
import { useMemo } from 'react';

import Tooltip from '../utils/Tooltip';
import { formatBusinessHoursForWeekday } from '../../../libraries/formatters';
import { useUsersMap } from '../../../hooks/queries/users';
import { businessHourDayOrdering } from '../../../types/business-hours';

interface Props {
  endTime: Date;
  startTime: Date;
  userId?: string;
}

const DoNotDisturbIcon = ({ endTime, startTime, userId = '' }: Props) => {
  const users = useUsersMap({ archived: true }, {
    enabled: Boolean(userId),
  });
  const user = useMemo(() => users[userId], [users, userId]);

  const validBusinessHours = useMemo(() => (user?.business_hours || user?.default_business_hours || []).filter((bh) => bh.timezone || user?.default_timezone), [user]);
  const businessHoursForDay = useMemo(() => validBusinessHours.filter((bh) => businessHourDayOrdering[bh.day] === startTime.getDay()), [startTime, validBusinessHours]);

  if (!user || ((user?.business_hours || user?.default_business_hours || []).length > 0 && validBusinessHours.length === 0)) {
    // In the event of no "valid" windows (we can't resolve the timezone for the user), we assume that they don't have
    // business hours set and all times are valid rather than no times are valid.
    return null;
  }

  const isInWorkingHours = businessHoursForDay.some((bh) => {
    // We filter above so by this point, timezone should always be defined.
    const timezone = bh.timezone || user.default_timezone as string;

    const convertedStartTime = Moment(startTime).tz(timezone).format('HH:mm');
    const convertedEndTime = Moment(endTime).tz(timezone).format('HH:mm');
    return (!bh.start_time || bh.start_time <= convertedStartTime) &&
      (!bh.end_time || convertedEndTime <= bh.end_time);
  });

  if (isInWorkingHours) {
    return null;
  }

  const tooltipId = `${user.id}-do-not-disturb-tooltip`;
  const formattedWorkingHours = formatBusinessHoursForWeekday(validBusinessHours, user.default_timezone, startTime.getDay());

  return (
    <div
      className="do-not-disturb"
      data-for={tooltipId}
      data-tip
    >
      <FontAwesomeIcon icon={faMoon} />
      <Tooltip
        helperText={`(${formattedWorkingHours})`}
        id={tooltipId}
        position="top"
        value="Outside working hours"
      />
    </div>
  );
};

export default DoNotDisturbIcon;
