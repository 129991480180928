import Moment from 'moment';
import { pick } from 'lodash';
import { useMemo, useState } from 'react';

import Flash from '../../library/utils/Flash';
import Table from '../../library/data-display/Table';
import TaskActionButtons from './TaskActionButtons';
import TaskDescriptionText from './TaskDescriptionText';
import TaskLabel from './TaskLabel';
import Tooltip from '../../library/utils/Tooltip';
import { formatMoment, TimeFormat } from '../../../libraries/time';
import { useUpdateNotification } from '../../../hooks/queries/notifications';

import type { ChangeEvent } from 'react';
import type { Notification, NotificationMetadata } from '../../../types';
import type { TableSchema } from '../../library/data-display/Table';

interface Props {
  onPageNumberChange?: (pageNumber: number) => void;
  onShowCompletedChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  pageNumber: number;
  showCompleted: boolean;
  tasks: Notification[];
  totalCount?: number;
}

const TasksTable = ({
  onPageNumberChange,
  onShowCompletedChange,
  pageNumber,
  showCompleted,
  tasks,
  totalCount,
}: Props) => {
  const [lastAction, setLastAction] = useState({
    id: '',
    isUndo: false,
    type: '',
  });

  const updateNotificationMutation = useUpdateNotification();

  const handleComplete = (id: string, undo: boolean) => {
    setLastAction({
      id,
      type: 'complete',
      isUndo: undo,
    });
    updateNotificationMutation.reset();
    updateNotificationMutation.mutate({ id, payload: { read: true } });
  };

  const handleUncomplete = async (id: string, undo: boolean) => {
    setLastAction({
      id,
      type: 'uncomplete',
      isUndo: undo,
    });
    updateNotificationMutation.reset();
    updateNotificationMutation.mutate({ id, payload: { read: false } });
  };

  const handleUndo = () => {
    if (lastAction.type === 'complete') {
      handleUncomplete(lastAction.id, true);
    }
    if (lastAction.type === 'uncomplete') {
      handleComplete(lastAction.id, true);
    }
  };

  /* eslint-disable react/display-name, react/prop-types */
  const columns = useMemo<TableSchema<Notification>>(() => [{
    header: 'Category',
    displayValue: ({ type }) => (
      <div className="tasks-list-table-category">
        <TaskLabel type={type} />
      </div>
    ),
  }, {
    header: 'Description',
    displayValue: (task) => {
      const props = pick(task, ['type', 'metadata']) as NotificationMetadata;
      return (
        <div className="tasks-list-table-description">
          <TaskDescriptionText {...props} />
        </div>
      );
    },
  }, {
    header: 'Age',
    displayValue: ({ id, last_seen_at }) => (
      <span
        className="tasks-list-table-age date"
        data-for={`tooltip-age-${id}`}
        data-tip
      >
        {Moment(last_seen_at).fromNow()}
        <Tooltip
          id={`tooltip-age-${id}`}
          position="top"
          value={formatMoment(Moment(last_seen_at), TimeFormat.LongMonthDayYearWithTimeAndTimezone)}
        />
      </span>
    ),
  }, {
    header: 'Actions',
    isClickable: true,
    displayValue: (task) => {
      const { read_at, id } = task;
      const props = pick(task, ['type', 'metadata']) as NotificationMetadata;
      return (
        <div className="action-buttons-container tasks-list-table-action-buttons">
          <TaskActionButtons
            id={id}
            isRead={Boolean(read_at)}
            onComplete={() => handleComplete(id, false)}
            onUncomplete={() => handleUncomplete(id, false)}
            {...props}
          />
        </div>
      );
    },
  }], []);

  return (
    <div className="task-list-table-container">
      <Flash
        isDismissible
        message={(
          <span>
            Task {lastAction.type === 'complete' ? 'completed' : 'marked incomplete'}.&nbsp;
            {!lastAction.isUndo && <a href="#" onClick={handleUndo}>Undo?</a>}
          </span>
        )}
        showFlash={lastAction.id ? updateNotificationMutation.isSuccess : false}
        type="success"
      />
      <Flash
        isDismissible
        message={updateNotificationMutation.error?.message}
        onDismiss={updateNotificationMutation.reset}
        showFlash={updateNotificationMutation.isError}
        type="danger"
      />
      <Table
        data={tasks}
        isPaginated
        layout="vertical"
        onPageNumberChange={onPageNumberChange}
        onShowArchivedChange={onShowCompletedChange}
        pageNumber={pageNumber}
        schema={columns}
        showArchived={showCompleted}
        showArchivedLabel="Show completed"
        totalCount={totalCount}
      />
    </div>
  );
};

export default TasksTable;
