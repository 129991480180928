import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import Flash from 'components/library/utils/Flash';
import OutboundLink from 'components/library/navigation/OutboundLink';
import Section from 'components/library/layout/Section';
import Table from 'components/library/data-display/Table';
import TextInput from 'components/library/inputs/TextInput';
import { useUpdateUser, useUser } from 'hooks/queries/users';

import type { ChangeEvent } from 'react';
import type { TableSchema } from 'components/library/data-display/Table';
import type { User } from 'types';

const TokensSection = () => {
  const { id } = useParams<{ id: string }>();

  const { data: user } = useUser(id);

  const [isEditing, setIsEditing] = useState(false);
  const [linkedinUrl, setLinkedinUrl] = useState(user?.linkedin_url || '');
  const [phoneNumber, setPhoneNumber] = useState(user?.phone_number || '');
  const [title, setTitle] = useState(user?.title || '');

  const updateUserMutation = useUpdateUser();

  const handleLinkedinUrlChange = (e: ChangeEvent<HTMLInputElement>) => setLinkedinUrl(e.target.value || '');
  const handlePhoneNumberChange = (e: ChangeEvent<HTMLInputElement>) => setPhoneNumber(e.target.value || '');
  const handleTitleChange = (e: ChangeEvent<HTMLInputElement>) => setTitle(e.target.value || '');

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setLinkedinUrl(user?.linkedin_url || '');
    setPhoneNumber(user?.phone_number || '');
    setTitle(user?.title || '');
  };

  const handleSave = async () => {
    updateUserMutation.reset();

    try {
      await updateUserMutation.mutateAsync({
        id,
        payload: {
          linkedin_url: linkedinUrl || '',
          phone_number: phoneNumber || '',
          title: title || '',
        },
      });
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  /* eslint-disable react/display-name */
  const columns = useMemo<TableSchema<User>>(() => [{
    header: 'Job Title',
    displayValue: ({ title }) => title || <span className="empty">&mdash;</span>,
    displayEditValue: () => (
      <TextInput
        onChange={handleTitleChange}
        placeholder="VP of Engineering"
        value={title}
      />
    ),
  }, {
    header: 'LinkedIn URL',
    displayValue: ({ linkedin_url }) => (
      linkedin_url ?
        <OutboundLink href={linkedin_url} label="Interviewer LinkedIn URL">
          {linkedin_url}
        </OutboundLink> :
        <span className="empty">&mdash;</span>
    ),
    displayEditValue: () => (
      <TextInput
        onChange={handleLinkedinUrlChange}
        placeholder="https://linkedin.com/in/jane-doe"
        value={linkedinUrl}
      />
    ),
  }, {
    header: 'Phone Number',
    displayValue: ({ phone_number }) => phone_number || <span className="empty">&mdash;</span>,
    displayEditValue: () => (
      <TextInput
        onChange={handlePhoneNumberChange}
        placeholder="(570) 555 1234"
        value={phoneNumber}
      />
    ),
  }], [
    handleLinkedinUrlChange,
    handlePhoneNumberChange,
    handleTitleChange,
    linkedinUrl,
    phoneNumber,
    title,
  ]);

  if (!user) {
    return null;
  }

  return (
    <Section
      className="tokens-section"
      isEditable
      isEditing={isEditing}
      isSaving={updateUserMutation.isLoading}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Additional properties"
    >
      <Flash
        message={updateUserMutation.error?.message}
        showFlash={updateUserMutation.isError}
        type="danger"
      />
      <Flash
        isDismissible
        message="Successfully updated!"
        showFlash={updateUserMutation.isSuccess}
        type="success"
      />
      <Flash
        message={<span>These are used to add interviewer details to <b>Schedule.Agenda</b> tokens. These tokens can be used in candidate calendar events and emails.</span>}
        showFlash
        type="info"
      />
      <Table
        data={[user]}
        isEditing={isEditing}
        layout="horizontal"
        schema={columns}
      />
    </Section>
  );
};

export default TokensSection;
