import { keyBy } from 'lodash';
import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import InterviewPlanner from '../../libraries/interviewplanner';

import type { CalendarEventTemplate } from '../../types';
import type { UseQueryOptions } from 'react-query';

export enum QueryKey {
  ListCalendarEventTemplates = 'ListCalendarEventTemplates',
  RetrieveCalendarEventTemplate = 'RetrieveCalendarEventTemplate',
}

export const useCalendarEventTemplate = (id?: string, options?: UseQueryOptions<CalendarEventTemplate, Error>) => {
  return useQuery(calendarEventTemplateParams(id, options));
};

export const calendarEventTemplateParams = (id: string | undefined, options: UseQueryOptions<CalendarEventTemplate, Error> = {}) => {
  // This query can't be canceled because the promise being returned from this
  // function (which is a result of the async keyword) doesn't have a cancel
  // function on it. If we want it to be cancellable, we can't use async/await,
  // and we need to make sure the cancel function from the promise returned from
  // InterviewPlanner.request is preserved.
  return {
    queryKey: [QueryKey.RetrieveCalendarEventTemplate, id],
    queryFn: async () => {
      const calendarEventTemplate = await InterviewPlanner.request<CalendarEventTemplate>('GET', `/calendar-event-templates/${id}`);
      calendarEventTemplate.additional_attendees = calendarEventTemplate.additional_attendees || [];
      calendarEventTemplate.additional_optional_attendees = calendarEventTemplate.additional_optional_attendees || [];
      return calendarEventTemplate;
    },
    ...options,
    enabled: options.enabled !== undefined ? options.enabled : (Boolean(id) && id !== 'new'),
  };
};

interface ListCalendarEventTemplatesQuery {
  limit?: number;
  offset?: number;
  name?: string;
  archived?: boolean;
}

interface ListCalendarEventTemplatesData {
  calendar_event_templates: CalendarEventTemplate[];
  total: number;
}

export const useCalendarEventTemplates = (query: ListCalendarEventTemplatesQuery = {}, options: UseQueryOptions<ListCalendarEventTemplatesData, Error> = {}) => {
  // This query can't be canceled because the promise being returned from this
  // function (which is a result of the async keyword) doesn't have a cancel
  // function on it. If we want it to be cancellable, we can't use async/await,
  // and we need to make sure the cancel function from the promise returned from
  // InterviewPlanner.request is preserved.
  return useQuery<ListCalendarEventTemplatesData, Error>([QueryKey.ListCalendarEventTemplates, query], async () => {
    const pageSize = 100;
    const paginatedQuery = {
      limit: pageSize,
      offset: 0,
      ...query,
    };

    const calendarEventTemplates = [];
    let total = 0;
    let totalLimit;

    while (totalLimit === undefined || calendarEventTemplates.length < totalLimit) {
      const response = await InterviewPlanner.request<ListCalendarEventTemplatesData>('GET', '/calendar-event-templates', null, paginatedQuery);
      total = response.total;
      if (response.calendar_event_templates.length === 0) {
        break;
      }

      calendarEventTemplates.push(...response.calendar_event_templates);
      totalLimit = query.limit! < total ? query.limit : total;
      paginatedQuery.offset = paginatedQuery.offset + paginatedQuery.limit;
    }

    return { calendar_event_templates: calendarEventTemplates, total };
  }, options);
};

interface CalendarEventTemplateMap {
  [id: string]: CalendarEventTemplate;
}

export const useCalendarEventTemplatesMap = (query?: ListCalendarEventTemplatesQuery, options?: UseQueryOptions<ListCalendarEventTemplatesData, Error>) => {
  const { data: calendarEventTemplates } = useCalendarEventTemplates(query, options);
  return useMemo<CalendarEventTemplateMap>(() => calendarEventTemplates ? keyBy(calendarEventTemplates.calendar_event_templates, 'id') : {}, [calendarEventTemplates]);
};

export interface CreateCalendarEventTemplatePayload {
  name: string;
  type: string;
  title: string;
  description: string;
  location?: string;
  additional_attendees?: string[];
  additional_optional_attendees?: string[];
}

interface CreateCalendarEventTemplateMutationVariables {
  payload?: CreateCalendarEventTemplatePayload;
}

export const useCreateCalendarEventTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation<CalendarEventTemplate, Error, CreateCalendarEventTemplateMutationVariables>(({ payload }) => {
    return InterviewPlanner.request('POST', '/calendar-event-templates', payload);
  }, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKey.ListCalendarEventTemplates]);
      queryClient.setQueryData([QueryKey.RetrieveCalendarEventTemplate, data.id], data);
    },
  });
};

export interface UpdateCalendarEventTemplatePayload {
  name?: string;
  title?: string;
  description?: string;
  location?: string;
  additional_attendees?: string[];
  additional_optional_attendees?: string[];
  archived?: boolean;
}

interface UpdateCalendarEventTemplateMutationVariables {
  id: string;
  payload?: UpdateCalendarEventTemplatePayload;
}

export const useUpdateCalendarEventTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation<CalendarEventTemplate, Error, UpdateCalendarEventTemplateMutationVariables>(({ id, payload }) => {
    return InterviewPlanner.request('POST', `/calendar-event-templates/${id}`, payload);
  }, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKey.ListCalendarEventTemplates]);
      queryClient.setQueryData([QueryKey.RetrieveCalendarEventTemplate, data.id], data);
    },
  });
};
