import Moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useParams } from 'react-router-dom';
import { capitalize, flatten, maxBy, sumBy, uniq } from 'lodash';
import { faEnvelope, faUnlink } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useMemo, useState } from 'react';

import Avatar from '../../../../../library/data-display/Avatar';
import Button from '../../../../../library/inputs/Button';
import CopyToClipboardButton from '../../../../../library/inputs/CopyToClipboardButton';
import EmailCandidateModal from '../../../../../library/data-display/EmailCandidateModal';
import ListItem from '../../../../../library/data-display/ListItem';
import Section from '../../../../../library/layout/Section';
import Table from '../../../../../library/data-display/Table';
import Tooltip from '../../../../../library/utils/Tooltip';
import { formatDuration } from '../../../../../../libraries/formatters';
import { formatMoment, TimeFormat } from '../../../../../../libraries/time';
import { useApplication } from '../../../../../../hooks/queries/applications';
import { useAvailability } from '../../../../../../hooks/queries/availabilities';
import { useUsersMap } from '../../../../../../hooks/queries/users';

import type { Availability } from '../../../../../../types';
import type { TableSchema } from '../../../../../library/data-display/Table';
import { correctPath } from 'libraries/gem';

const SummarySection = () => {
  const { id, availabilityId } = useParams<{ id: string; availabilityId: string }>();

  const { data: application } = useApplication(id);
  const { data: availability } = useAvailability(availabilityId);
  const users = useUsersMap({ archived: true });

  const [emailCandidateModalIsOpen, setEmailCandidateModalIsOpen] = useState(false);

  const toggleEmailCandidateOpen = useCallback(() => setEmailCandidateModalIsOpen((prev) => !prev), [setEmailCandidateModalIsOpen]);

  const schema = useMemo<TableSchema<Availability>>(() => [Boolean(availability?.availability_time_slots && availability.availability_time_slots.length > 0) && {
    header: 'Days Available',
    displayValue: ({ availability_time_slots }) => {
      const timezone = availability_time_slots![0].timezone;
      const allTimes = flatten(availability_time_slots?.map(({ start_time, end_time }) => [Moment.utc(start_time).tz(timezone), Moment.utc(end_time).tz(timezone)]));
      const distinctDays = uniq(allTimes.map((time) => Moment.tz(time, timezone).startOf('day').format())).sort();
      if (distinctDays.length === 1) {
        return formatMoment(Moment.tz(distinctDays[0], timezone), TimeFormat.LongDayOfWeekMonthDay);
      }
      return `${distinctDays.length} days between ${formatMoment(Moment.tz(distinctDays[0], timezone), TimeFormat.LongDayOfWeekMonthDay)} and ${formatMoment(Moment.tz(distinctDays[distinctDays.length - 1], timezone), TimeFormat.LongDayOfWeekMonthDay)}`;
    },
  }, Boolean(availability?.availability_time_slots) && {
    header: 'Total Availability',
    displayValue: ({ availability_time_slots }) => formatDuration(
      sumBy(
        availability_time_slots,
        ({ start_time, end_time }) => Moment.duration(Moment.utc(end_time).diff(Moment.utc(start_time))).asMinutes()
      )
    ),
  }, Boolean(availability?.availability_time_slots && availability.availability_time_slots[0] && availability.availability_time_slots[0].timezone) && {
    header: 'Submitted Timezone',
    displayValue: ({ availability_time_slots }) => availability_time_slots?.[0].timezone,
  }, !Boolean(availability?.ats_id) && !availability?.manual && {
    header: (
      <span className="availability-link-header">
        Link
        {(availability?.stage_id !== application?.current_stage_id || availability?.status === 'cancelled') &&
          <>
            <span data-for={`${availability?.id}-link-expired`} data-tip>
              <FontAwesomeIcon icon={faUnlink} />
            </span>
            <Tooltip
              id={`${availability?.id}-link-expired`}
              position="top"
              value={availability?.status === 'cancelled' ? 'Cancelled' : 'Expired'}
            />
          </>
        }
      </span>
    ),
    displayValue: ({ id, stage_id, status }) => (
      <div className="availability-link">
        <Link
          className={(stage_id !== application?.current_stage_id || status === 'cancelled') ? 'expired' : undefined}
          target="_blank"
          to={correctPath(`/availability/${id}`)}
        >
          {window.origin}{correctPath(`/availability/${id}`)}
        </Link>
        <CopyToClipboardButton textToCopy={`${window.origin}${correctPath(`/availability/${id}`)}`} />
      </div>
    ),
  }, {
    header: 'Status',
    displayValue: ({ availability_time_slots, created_at, creator_id, automatic_send_log_id, manual, status, updated_at }) => {
      if (status === 'submitted') {
        const lastUpdatedDate = formatMoment(Moment(maxBy(availability_time_slots, 'updated_at')?.updated_at), TimeFormat.LongMonthDayYear);
        if (manual && creator_id) {
          return (
            <div className="manually-submitted-status">
              Entered by&nbsp;
              {users[creator_id] ?
                <ListItem
                  label={users[creator_id].name || users[creator_id].email}
                  leftIcon={
                    <Avatar
                      showUserTooltip={false}
                      size="small"
                      userId={creator_id}
                    />
                  }
                /> :
                'user'
              }
              &nbsp;on {lastUpdatedDate}
            </div>
          );
        } else {
          return `Received on ${lastUpdatedDate}`;
        }
      }
      if (status === 'cancelled') {
        return `Cancelled on ${formatMoment(Moment(updated_at), TimeFormat.LongMonthDayYear)}`;
      }
      if (status === 'requested') {
        return (
          <div className="follow-up-link">
            <div className="date-requested">
              <span>
                {automatic_send_log_id ? 'Automatically requested' : 'Requested'} on {formatMoment(Moment(created_at), TimeFormat.LongMonthDayYear)}{creator_id && ' by'}
              </span>
              {creator_id &&
                <ListItem
                  label={users[creator_id]?.name || users[creator_id]?.email}
                  leftIcon={<Avatar
                    showUserTooltip={false}
                    size="small"
                    userId={creator_id}
                  />}
                />
              }
            </div>
            <Button
              className="btn-main-action"
              color="gem-outline"
              iconRight={<FontAwesomeIcon icon={faEnvelope} />}
              onClick={() => toggleEmailCandidateOpen()}
              size="small"
              value="Follow Up"
            />
            <EmailCandidateModal
              applicationId={application!.id}
              isOpen={emailCandidateModalIsOpen}
              onToggle={() => toggleEmailCandidateOpen()}
            />
          </div>
        );
      }
      return capitalize(status);
    },
  }], [application?.id, availability, emailCandidateModalIsOpen, toggleEmailCandidateOpen]);

  if (!availability) {
    return null;
  }

  return (
    <Section className="summary-section" title="Summary">
      <Table
        data={[availability]}
        layout="horizontal"
        schema={schema}
      />
    </Section>
  );
};

export default SummarySection;
