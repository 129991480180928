import { find, orderBy } from 'lodash';

import Avatar from './Avatar';
import AvatarGroup from './AvatarGroup';
import { rsvpAvatarStatus } from '../../../types';
import { useUsersMap } from '../../../hooks/queries/users';

import type { Attendee } from '../../../types';

interface Props {
  attendees: Attendee[];
  maxDisplayLength?: number;
}

const EventAttendeeAvatarGroup = ({ attendees, maxDisplayLength }: Props) => {
  const users = useUsersMap({ archived: true });

  if (attendees.length === 0) {
    return null;
  }

  const avatars = orderBy(attendees, 'email').filter(({ resource }) => !resource).map((attendee) => {
    const user = find(Object.values(users), ['email', attendee.email]);
    if (user) {
      return (
        <Avatar
          key={attendee.email}
          size="small"
          status={rsvpAvatarStatus[attendee.rsvp]?.status}
          tooltipHelperText={rsvpAvatarStatus[attendee.rsvp]?.tooltipHelperText}
          type="user"
          userId={user.id}
        />
      );
    }

    const companyDomain = attendee.email.split('@')[1];
    return (
      <Avatar
        alt={attendee.name || attendee.email}
        img={`https://logo.clearbit.com/${companyDomain}`}
        key={attendee.email}
        size="small"
        status={rsvpAvatarStatus[attendee.rsvp]?.status}
        tooltipHelperText={rsvpAvatarStatus[attendee.rsvp]?.tooltipHelperText}
        type="candidate"
      />
    );
  });

  return (
    <AvatarGroup
      avatars={avatars}
      maxDisplayLength={maxDisplayLength}
      size="small"
    />
  );
};

export default EventAttendeeAvatarGroup;
