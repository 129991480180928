import { createContext, useContext, useMemo } from 'react';

import type { MousePosition } from './types';
import type { ReactNode } from 'react';
import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../SelectInput/types';

interface AvailabilityPickerContextState {
  brandColor?: string;
  mousePosition?: MousePosition;
  timezone: string;
  onTimezoneChange: (newValue: OnChangeValue<Option<string>, false>, actionMeta: ActionMeta<Option<string>>) => void;
}

const AvailabilityPickerContext = createContext<AvailabilityPickerContextState>({
  timezone: '',
  onTimezoneChange: () => {},
});

interface Props {
  brandColor?: string;
  children: ReactNode;
  mousePosition?: MousePosition;
  onTimezoneChange: (newValue: OnChangeValue<Option<string>, false>, actionMeta: ActionMeta<Option<string>>) => void;
  timezone: string;
}

export const AvailabilityPickerProvider = ({ brandColor, children, mousePosition, onTimezoneChange, timezone }: Props) => {
  const contextValue = useMemo<AvailabilityPickerContextState>(() => ({
    brandColor,
    mousePosition,
    onTimezoneChange,
    timezone,
  }), [brandColor, mousePosition, onTimezoneChange, timezone]);

  return (
    <AvailabilityPickerContext.Provider value={contextValue}>
      {children}
    </AvailabilityPickerContext.Provider>
  );
};

export const useAvailabilityPickerContext = () => {
  return useContext(AvailabilityPickerContext);
};
