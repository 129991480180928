import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Flash from '../../../../library/utils/Flash';
import InterviewerTemplatesForm from '../../../../library/inputs/InterviewerTemplatesForm';
import Section from '../../../../library/layout/Section';
import { useInterviewTemplate, useUpdateInterviewTemplate } from '../../../../../hooks/queries/interview-templates';

import type { UpdateInterviewTemplatePayload } from '../../../../../hooks/queries/interview-templates';
import type { InterviewerTemplate } from '../../../../../types';
import type { InterviewerTemplate as InterviewerTemplatesFormInterviewerTemplate } from '../../../../library/inputs/InterviewerTemplatesForm';

// This function can either take in the InterviewerTemplate from the API or from
// the InterviewerTemplateForm, so we accept both.
const constructInterviewerTemplatesPayload = (interviewerTemplates: (InterviewerTemplate | InterviewerTemplatesFormInterviewerTemplate)[] | null): InterviewerTemplatesFormInterviewerTemplate[] => {
  return (interviewerTemplates || []).map((interviewerTemplate) => ({
    description: interviewerTemplate.description || '',
    include_past_interviewers: interviewerTemplate.include_past_interviewers ?? true,
    interviewer_filters: (interviewerTemplate.interviewer_filters || []).map(({ interviewer_filter_expressions }) => ({
      interviewer_filter_expressions: (interviewer_filter_expressions || []).map((expression) => ({
        filterable_id: expression.filterable_id,
        filterable_type: expression.filterable_type,
        negated: expression.negated,
      })),
    })),
    optional: interviewerTemplate.optional || false,
  }));
};

const InterviewersSection = () => {
  const { id } = useParams<{ id: string }>();

  const { data } = useInterviewTemplate(id);
  const interviewTemplate = data!;

  const [isEditing, setIsEditing] = useState(false);
  const [interviewerTemplates, setInterviewerTemplates] = useState<InterviewerTemplatesFormInterviewerTemplate[] | undefined>(constructInterviewerTemplatesPayload(interviewTemplate.interviewer_templates));

  const updateInterviewTemplateMutation = useUpdateInterviewTemplate();

  useEffect(() => {
    if (Boolean(interviewTemplate.interviewer_templates)) {
      setInterviewerTemplates(constructInterviewerTemplatesPayload(interviewTemplate.interviewer_templates));
    }
  }, [Boolean(interviewTemplate.interviewer_templates)]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setIsEditing(false);
    setInterviewerTemplates(constructInterviewerTemplatesPayload(interviewTemplate.interviewer_templates));
  };

  const handleSave = async () => {
    updateInterviewTemplateMutation.reset();

    const payload: UpdateInterviewTemplatePayload = {
      interviewer_templates: constructInterviewerTemplatesPayload(interviewerTemplates!),
    };

    try {
      await updateInterviewTemplateMutation.mutateAsync({ id, payload });
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  return (
    <Section
      className="interview-template-details-interviewers"
      isEditable
      isEditing={isEditing}
      isSaving={updateInterviewTemplateMutation.isLoading}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Interviewers"
    >
      <Flash
        message={updateInterviewTemplateMutation.error?.message}
        showFlash={updateInterviewTemplateMutation.isError}
        type="danger"
      />
      <Flash
        isDismissible
        message="Successfully updated!"
        showFlash={updateInterviewTemplateMutation.isSuccess}
        type="success"
      />
      <InterviewerTemplatesForm
        interviewerTemplates={interviewerTemplates}
        isDisabled={!isEditing}
        setInterviewerTemplates={setInterviewerTemplates}
      />
    </Section>
  );
};

export default InterviewersSection;
