import styled from 'styled-components';

import CheckboxInput from '../CheckboxInput';
import TimeRangeInput from '../TimeRangeInput';

export const StyledTableContainer = styled.div`
  display: table;
`;

export const StyledLabel = styled.label`
  font-size: .7rem;
  font-weight: 600;
  margin: .5em 0;
`;

export const StyledHelperText = styled.div`
  color: ${({ theme }) => theme.colors.PRODUCT.SECONDARY_CONTENT};
  font-size: .7rem;
  margin: .5em 0;
`;

export const StyledCheckboxRow = styled.div`
  display: table-row;
`;

export const StyledEmptyCell = styled.div`
  display: table-cell;
`;

export const StyledCheckboxInput = styled(CheckboxInput)`
  &.input {
    display: table-cell !important;
    padding: 1em 1em 1em 0;
  }
`;

export const StyledTimeRangeInput = styled(TimeRangeInput)`
  &.input {
    display: table-cell;
    padding: .5em 0;

    .time-input-container {
      gap: .5em;
    }

    .range-dash {
      margin: 0 !important;
    }

    .select-input {
      min-width: 10em;
    }

    .timezone-select-input {
      margin-left: 0 !important;
      max-width: 385px !important;
    }
  }
`;

export const StyledActionButtonsContainer = styled.div`
  display: table-cell;
  white-space: nowrap;

  .fa-trash-can {
    color: ${({ theme }) => theme.colors.ACCENT.RED};
  }

  .btn-large {
    padding: 1em;
  }
`;
