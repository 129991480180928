import { faCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Tooltip from './Tooltip';

import type { ReactNode } from 'react';
import classnames from 'classnames';

export enum StatusIndicatorColor {
  Gray = 'gray',
  Green = 'green',
  LightOrange = 'light-orange',
  Orange = 'orange',
  Red = 'red',
}

interface Props {
  children?: ReactNode;
  className?: string;
  color: `${StatusIndicatorColor}`;
  tooltipId?: string;
  tooltipText?: string;
}

const StatusIndicator = ({ children, className, color, tooltipId, tooltipText }: Props) => (
  <span>
    <FontAwesomeIcon
      className={classnames(['status-indicator', `status-indicator-${color}`, className])}
      data-for={tooltipId}
      data-tip={tooltipId ? true : undefined}
      icon={faCircle}
    />
    {tooltipId && tooltipText &&
      <Tooltip
        id={tooltipId}
        position="top"
        value={tooltipText}
      />
    }
    {children}
  </span>
);

export default StatusIndicator;
