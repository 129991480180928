import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

import type { ReactNode } from 'react';

interface Props {
  children: ReactNode;
  className?: string;
  externalLinkIcon?: boolean;
  href: string;
  label: string;
  tooltip?: JSX.Element;
}

const OutboundLink = ({
  children,
  className,
  externalLinkIcon = true,
  href,
  label,
  tooltip,
}: Props) => (
  <a
    className={`outbound-link${className ? ` ${className}` : ''}`}
    data-for={tooltip ? tooltip.props.id : undefined}
    data-tip={tooltip ? true : undefined}
    href={href}
    onClick={() => analytics.track('Outbound Link Clicked', { href, label })}
    rel="noopener noreferrer"
    target="_blank"
  >
    {children}
    {tooltip}
    {externalLinkIcon && <FontAwesomeIcon icon={faExternalLinkAlt} />}
  </a>
);

export default OutboundLink;
