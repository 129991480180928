import { flatten } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { ATS } from 'types';
import CalendarSchedule from 'components/library/data-display/CalendarSchedule';
import LoadingSpinner from 'components/library/utils/LoadingSpinner';
import { constructBusinessHours, constructCalendarSchedule, constructUpdatedInterviews } from './helpers';
import { useApplication } from 'hooks/queries/applications';
import { useRoomsMap } from 'hooks/queries/rooms';
import { useSession } from 'hooks/use-session';
import { useUsersMap } from 'hooks/queries/users';

import type { Dispatch, SetStateAction } from 'react';
import type { EditableSchedule as EditableScheduleType } from '../../../../../../../../types';
import type { Schedule as CalendarScheduleType } from '../../../../../../../library/data-display/CalendarSchedule/types';

interface Props {
  schedule: EditableScheduleType;
  setSchedule: Dispatch<SetStateAction<EditableScheduleType>>;
}

const EditableSchedule = ({ schedule, setSchedule }: Props) => {
  const { id } = useParams<{ id: string }>();

  const { account } = useSession();
  const { data: application } = useApplication(id);
  const rooms = useRoomsMap();
  const users = useUsersMap({ archived: true });

  const createCalendarSchedule = useCallback((): CalendarScheduleType => {
    const { zoom_host_type, zoom_host_id } = schedule.interviews[0];
    const allRooms = Object.values({
      ...(zoom_host_type === 'room' && zoom_host_id ?
        {
          [zoom_host_id]: {
            id: zoom_host_id,
            video_conferencing_id: true,
          },
        } : {}
      ),
      ...rooms,
    });
    const allUsers = Object.values({
      ...(zoom_host_type === 'user' && zoom_host_id ?
        {
          [zoom_host_id]: {
            id: zoom_host_id,
            video_conferencing_id: true,
          },
        } : {}
      ),
      ...users,
    });
    return constructCalendarSchedule(schedule, allRooms, allUsers);
  }, [rooms, schedule.id, schedule.schedule_template.video_conferencing_enabled]);

  const [calendarSchedule, setCalendarSchedule] = useState<CalendarScheduleType>(createCalendarSchedule);

  useEffect(() => {
    setCalendarSchedule(createCalendarSchedule());
  }, [createCalendarSchedule]);

  useEffect(() => {
    if (calendarSchedule) {
      setSchedule((prevSchedule) => ({
        ...prevSchedule,
        interviews: constructUpdatedInterviews(prevSchedule.interviews, calendarSchedule),
      }));
    }
  }, [calendarSchedule]);

  useEffect(() => {
    if (calendarSchedule?.interviews) {
      setCalendarSchedule({
        ...calendarSchedule,
        schedule_template: {
          ...calendarSchedule.schedule_template,
          ...constructBusinessHours(calendarSchedule),
        },
      });
    }
  }, [calendarSchedule?.interviews]);

  const showZoomHost = schedule.schedule_template.video_conferencing_enabled && account?.video_conferencing_type === 'zoom';

  const availabilityTimeSlots = useMemo(() => {
    const availabilities = application?.active_availabilities || [];
    return flatten(availabilities.map(({ availability_time_slots }) => availability_time_slots || []))
    .map(({ start_time, end_time }) => ({
      start: start_time,
      end: end_time,
    }));
  }, [application?.active_availabilities]);

  return (
    calendarSchedule ?
      <CalendarSchedule
        allowAddAndRemoveInterviewers
        allowCompareCalendars
        allowSelectOptionsOutsidePool
        allowUpdateInterview
        allowUpdateScorecards={account?.ats_type === ATS.Greenhouse && schedule.stage.job_id !== application?.job_id}
        analyticsLabel="Edit Schedule Calendar"
        availabilityTimeSlots={availabilityTimeSlots}
        isDraggable
        schedule={calendarSchedule}
        setSchedule={setCalendarSchedule}
        showZoomHost={showZoomHost}
      /> :
      <LoadingSpinner />
  );
};

export default EditableSchedule;
