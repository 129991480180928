import { isEmpty, keyBy, pick } from 'lodash';
import { useMemo } from 'react';
import { useMutation, useQueries, useQuery, useQueryClient } from 'react-query';

import InterviewPlanner from '../../libraries/interviewplanner';
import { QueryKey as InterviewTemplatesQueryKey } from './interview-templates';
import { QueryKey as TrainingProgramsQueryKey } from './training-programs';

import type { EditableInterviewerFilter, Eligibility, User, UserTag } from '../../types';
import type { InterviewerFilterableType } from '../../types';
import type { UseQueryOptions } from 'react-query';

export enum QueryKey {
  RetrieveUser = 'RetrieveUser',
  ListUsers = 'ListUsers',
  ResolveUsers = 'ResolveUsers',
  ListEligibilities = 'ListEligibilities',
  ListUserTags = 'ListUserTags',
}

export const useUser = (id: string, options: UseQueryOptions<User, Error> = {}) => {
  return useQuery<User, Error>([QueryKey.RetrieveUser, id], () => {
    return InterviewPlanner.request('GET', `/users/${id}`);
  }, {
    ...options,
    enabled: options.enabled !== undefined ? options.enabled : Boolean(id),
  });
};

interface ListUsersQuery {
  limit?: number;
  offset?: number;
  name_or_email?: string;
  eligibility?: string[];
  user_tag?: string[];
  archived?: boolean;
}

interface ListUsersData {
  users: User[];
  total: number;
}

export const useUsers = <TData = ListUsersData>(query?: ListUsersQuery, options?: UseQueryOptions<ListUsersData, Error, TData>) => {
  return useQuery<ListUsersData, Error, TData>(usersParams(query, options));
};

export interface UserMap {
  [id: string]: User;
}

export const useUsersMap = (query?: ListUsersQuery, options?: UseQueryOptions<ListUsersData, Error>) => {
  const { data: users } = useUsers(query, options);
  return useMemo<UserMap>(() => users ? keyBy(users.users, 'id') : {}, [users]);
};

export const usersParams = <TData = ListUsersData>(query: ListUsersQuery = {}, options: UseQueryOptions<ListUsersData, Error, TData> = {}) => {
  return {
    queryKey: [QueryKey.ListUsers, query],
    queryFn: () => {
      return InterviewPlanner.request<ListUsersData>('GET', '/users', null, query);
    },
    refetchOnMount: true,
    staleTime: 5000,
    ...options,
  };
};

export interface InterviewerFilter {
  interviewer_filter_expressions: {
    negated: boolean;
    filterable_id: string;
    filterable_type: `${InterviewerFilterableType}`;
  }[] | null;
}

export interface UpdateUserPayload {
  archived?: boolean;
  business_hours?: {
    day: string;
    start_time: string;
    end_time: string;
    timezone?: string;
  }[]; // Set to empty array to null it out.
  eligibilities?: string[];
  eligibilities_v2?: {
    id: string;
    trainee?: boolean;
  }[];
  ignore_words?: {
    id: string;
    negated: boolean;
  }[]; // Set to empty array to null it out.
  linkedin_url?: string; // Set to "" to null it out.
  phone_number?: string; // Set to "" to null it out.
  internal_notes?: string; // Set to "" to null it out.
  title?: string; // Set to "" to null it out.
  daily_interview_limit?: number; // Set to 0 to null it out.
  weekly_interview_limit?: number; // Set to 0 to null it out.
  interviewer_meeting_buffer_minutes?: number; // Set to -1 to null it out.
  ignore_free_events?: number; // Set 1 to enable, 0 to disable, -1 to null it out.
  user_tags?: string[];
  // Only allowed for own user.
  email_notification_schedule_confirmed?: boolean;
  email_notification_candidate_availability?: boolean;
}

interface UpdateUserMutationVariables {
  id: string;
  payload?: UpdateUserPayload;
}

export const useUpdateUser = () => {
  const queryClient = useQueryClient();

  return useMutation<User, Error, UpdateUserMutationVariables>(({ id, payload }) => {
    return InterviewPlanner.request('POST', `/users/${id}`, payload, null, false);
  }, {
    onSuccess: (_data, { id, payload }) => {
      queryClient.invalidateQueries([QueryKey.RetrieveUser, id]);
      queryClient.invalidateQueries([QueryKey.ListUsers]);
      if (payload?.eligibilities) {
        // If we updated eligibilities for a user, we need to reload the eligibilities.
        queryClient.invalidateQueries([QueryKey.ListEligibilities]);
      }
      if (payload?.user_tags) {
        // If we updated user tags for a user, we need to reload the user tags.
        queryClient.invalidateQueries([QueryKey.ListUserTags]);
      }
    },
  });
};

interface ResolveUsersQuery {
  applicationId?: string;
  scheduleId?: string;
  includePastInterviewers?: boolean;
  interviewerFilters: EditableInterviewerFilter[];
}

export const useResolveUsers = (query: ResolveUsersQuery, options?: UseQueryOptions<User[], Error>) => {
  return useQuery(resolveUsersParams(query, options));
};

type ResolvePoolsQuery = Omit<ResolveUsersQuery, 'interviewerFilters'> & {
  pools: EditableInterviewerFilter[][];
};

export const useResolvePools = (query: ResolvePoolsQuery, options?: UseQueryOptions<User[], Error>) => {
  return useQueries(query.pools.map((interviewerFilters) => {
    return resolveUsersParams({
      ...query,
      interviewerFilters,
      // We're not removing pools from the query, but that shouldn't be an issue
      // since resolveUsersParams constructs its own payload.
    }, options);
  }));
};

export const resolveUsersParams = (query: ResolveUsersQuery, options: UseQueryOptions<User[], Error> = {}) => {
  const payload = {
    application_id: query.applicationId,
    schedule_id: query.scheduleId,
    include_past_interviewers: query.includePastInterviewers,
    interviewer_filters: (query.interviewerFilters || []).map(({ interviewer_filter_expressions }) => ({
      interviewer_filter_expressions: (interviewer_filter_expressions || []).map((expression) => pick(expression, ['filterable_id', 'filterable_type', 'negated'])),
    })).filter(({ interviewer_filter_expressions }) => !isEmpty(interviewer_filter_expressions)),
  };

  return {
    queryKey: [QueryKey.ResolveUsers, payload],
    queryFn: () => {
      return InterviewPlanner.request<User[]>('POST', '/users/resolve', payload);
    },
    ...options,
    enabled: options.enabled !== undefined ? options.enabled : Boolean(query.interviewerFilters),
  };
};

interface ListEligibilitiesQuery {
  include_counts?: boolean;
}

export const useEligibilities = (query: ListEligibilitiesQuery = {}, options: UseQueryOptions<Eligibility[], Error> = {}) => {
  return useQuery<Eligibility[], Error>([QueryKey.ListEligibilities], () => {
    return InterviewPlanner.request('GET', '/users/eligibilities', null, query);
  }, {
    initialData: [],
    ...options,
  });
};

export interface RenameEligibilitiesPayload {
  from: string;
  to: string;
}

interface RenameEligibilitiesMutationVariables {
  payload: RenameEligibilitiesPayload;
}

export const useRenameEligibilities = () => {
  const queryClient = useQueryClient();

  return useMutation<User, Error, RenameEligibilitiesMutationVariables>(({ payload }) => {
    return InterviewPlanner.request('POST', '/users/eligibilities/rename', payload);
  }, {
    onSuccess: () => {
      queryClient.invalidateQueries([QueryKey.RetrieveUser]);
      queryClient.invalidateQueries([QueryKey.ListUsers]);
      queryClient.invalidateQueries([TrainingProgramsQueryKey.RetrieveTrainingProgram]);
      queryClient.invalidateQueries([TrainingProgramsQueryKey.ListTrainingPrograms]);
      queryClient.invalidateQueries([InterviewTemplatesQueryKey.RetrieveInterviewTemplate]);
      queryClient.invalidateQueries([InterviewTemplatesQueryKey.ListInterviewTemplates]);
    },
  });
};

export const useUserTags = (options: UseQueryOptions<UserTag[], Error> = {}) => {
  return useQuery<UserTag[], Error>([QueryKey.ListUserTags], () => {
    return InterviewPlanner.request('GET', '/users/tags');
  }, {
    initialData: [],
    ...options,
  });
};
