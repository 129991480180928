import type { Descendant } from 'slate';
import { createEditor } from 'slate';
import type { Dispatch, SetStateAction } from 'react';
import { useCallback, useState } from 'react';
import { withHistory } from 'slate-history';
import { withReact } from 'slate-react';

import { htmlToSlateValue } from '../libraries/editor/html-to-slate-value';
import { textToSlateValue } from '../libraries/editor/text-to-slate-value';
import { withPaste } from '../components/library/inputs/EditorInput/plugins/paste';
import { withLinks } from '../components/library/inputs/EditorInput/plugins/links';
import { withTokens } from '../components/library/inputs/EditorInput/plugins/tokens';
import { withImages } from '../components/library/inputs/EditorInput/plugins/images';
import type { CustomEditor } from '../types';

export const useSlateEditor = (value: string, isPlainText: boolean = false): [CustomEditor, Descendant[], Dispatch<SetStateAction<Descendant[]>>, (value: string) => void] => {
  const [slateEditor] = useState<CustomEditor>(() => withImages(withPaste(withTokens(withLinks(withHistory(withReact(createEditor())))))));
  const [slateValue, setSlateValue] = useState<Descendant[]>(() => isPlainText ? textToSlateValue(value) : htmlToSlateValue(value));
  const setValue = useCallback((newValue: string) => {
    setSlateValue(isPlainText ? textToSlateValue(newValue) : htmlToSlateValue(newValue));
  }, []);

  return [slateEditor, slateValue, setSlateValue, setValue];
};
