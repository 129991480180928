import { find, orderBy } from 'lodash';
import { useMemo } from 'react';

import SelectInput from './SelectInput';
import Tag from '../data-display/Tag';
import { useRooms } from '../../../hooks/queries/rooms';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from './SelectInput/types';

interface Props {
  className?: string;
  helperText?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  label?: string;
  name?: string;
  onChange: (newValue: OnChangeValue<Option<string>, true>, actionMeta: ActionMeta<Option<string>>) => void;
  selectedRoomIds?: string[];
}

const RoomSelectInput = ({
  className,
  helperText,
  isDisabled = false,
  isRequired = false,
  label,
  name,
  onChange,
  selectedRoomIds,
}: Props) => {
  const { data: rooms } = useRooms();

  const options = useMemo<Option<string>[]>(() => orderBy((rooms?.rooms || []).map((room) => ({
    value: room.id,
    label: room.name,
  })), 'name'), [rooms]);

  return (
    <SelectInput
      className={className}
      formatOptionLabel={(option) => (
        <Tag
          type="room"
          value={option.value}
        />
      )}
      helperText={helperText}
      isClearable
      isDisabled={isDisabled}
      isMulti
      isRequired={isRequired}
      label={label}
      name={name}
      onChange={onChange}
      options={options}
      placeholder={isDisabled ? undefined : 'Select rooms'}
      value={selectedRoomIds ? selectedRoomIds.map((roomId) => find(options, ['value', roomId])!) : undefined}
    />
  );
};

export default RoomSelectInput;
