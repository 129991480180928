import classnames from 'classnames';
import { uniqueId } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import type { ChangeEvent, ChangeEventHandler, ReactNode } from 'react';

export enum RadioButtonGroupInputLayout {
  Horizontal = 'horizontal',
  Vertical = 'vertical',
}

export interface RadioButtonOption {
  id?: string;
  isDisabled?: boolean;
  label: ReactNode;
  value: string;
}

interface Props {
  helperText?: ReactNode;
  id?: string;
  isDisabled?: boolean;
  isRequired?: boolean;
  layout: `${RadioButtonGroupInputLayout}`;
  name: string;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  options: RadioButtonOption[];
  value: string;
}

const RadioButtonGroupInput = ({
  helperText,
  id,
  isDisabled = false,
  isRequired = false,
  layout,
  name,
  onChange,
  options,
  value,
}: Props) => {
  const [controlledValue, setControlledValue] = useState(value);
  useEffect(() => {
    setControlledValue(value);
  }, [value]);

  id = useMemo(() => id || uniqueId('radio-button-group-input-'), [id]);

  const handleChange = (e: ChangeEvent<HTMLInputElement>) => setControlledValue(e.target.value);

  return (
    <div className={classnames(['input', 'radio-button-group-input', `radio-button-group-input-${layout}`])}>
      {options.map((option) => (
        <label
          className="radio-button-input"
          htmlFor={option.id || `${id}-${option.value}`}
          key={option.value}
        >
          <input
            checked={controlledValue === option.value}
            className="hidden-input"
            disabled={isDisabled || option.isDisabled}
            id={option.id || `${id}-${option.value}`}
            name={name}
            onChange={onChange || handleChange}
            required={isRequired}
            type="radio"
            value={option.value}
          />
          <span className="radio-button" />
          <span className="input-label">{option.label}</span>
        </label>
      ))}
      {helperText && <div className="helper-text">{helperText}</div>}
    </div>
  );
};

export default RadioButtonGroupInput;
