import { AvatarStatus } from '../components/library/data-display/Avatar';

import type { Meeting } from './zoom';

export enum ShowAs {
  Free = 'free',
  Tentative = 'tentative',
  Busy = 'busy',
  Away = 'away',
  WorkingElsewhere = 'working_elsewhere',
  Unknown = 'unknown',
}

// This is the Event that is returned from the /events endpoint.
export interface Event {
  id: string;
  ical_uid: string;
  attendees: Attendee[] | null;
  title: string;
  start_time: string;
  end_time: string;
  color?: string;
  ignored: boolean;
  show_as: ShowAs;
  buffer_time: boolean;

  // This is true if the current user doesn't have access to the event, not if
  // the visibility of the event is private but the user has enough access to
  // view the event anyway.
  private: boolean;

  // This is only used for interview limit events.
  interview_limit_metadata?: {
    interview_limit: number;
    // This contains the IDs of the interviews that contributed to it, so that
    // we can selectively choose to ignore it.
    interview_ids: string[];
  };
}

export enum RSVP {
  Accepted = 'accepted',
  Declined = 'declined',
  Pending = 'pending',
  Tentative = 'tentative',
}

export interface Attendee {
  name?: string;
  email: string;
  resource?: boolean;
  rsvp: RSVP;
}

export type Conflict = Event | Meeting;

export const rsvpAvatarStatus: Record<RSVP, { status?: AvatarStatus; tooltipHelperText: string }> = {
  [RSVP.Accepted]: { status: AvatarStatus.Ok, tooltipHelperText: 'Accepted invite' },
  [RSVP.Declined]: { status: AvatarStatus.Danger, tooltipHelperText: 'Declined invite' },
  [RSVP.Pending]: { status: undefined, tooltipHelperText: 'Pending reply' },
  [RSVP.Tentative]: { status: AvatarStatus.Warning, tooltipHelperText: 'Tentatively accepted invite' },
};
