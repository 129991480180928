import { StyledCard, StyledImage } from './styles';

interface Props {
  className?: string;
  label: string;
  img: string;
}

const IntegrationLogo = ({ className, label, img }: Props) => {
  return (
    <StyledCard>
      <StyledImage
        alt={`${label} logo`}
        aria-labelledby={`${label}-label`}
        className={className}
        src={img}
      />
      <div id={`${className || label}-label`}>
        {label}
      </div>
    </StyledCard>
  );
};

export default IntegrationLogo;
