import styled from 'styled-components';

export const StyledDivider = styled.hr`
  border: .5px solid ${({ theme }) => theme.colors.mercuryGray};
  border-right-width: 0;
  border-left-width: 0;
  width: 100%;
  max-width: 1100px;
`;

export const StyledPageContainer = styled.div`
  padding-top: 2em;
  width: 100%;
`;

export const StyledTitleSection = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px 0;
  text-align: center;

  h1 {
    margin: 15px;
    max-width: 90%;
  }

  p {
    color: ${({ theme }) => theme.colors.doveGray};
    font-size: 1.15em;
    line-height: 2;
    width: 660px;
    max-width: 90%;
  }

  @media only screen and (max-width: 930px) {
    padding-top: 20px;
  }
`;

export const StyledQuestionsSection = styled.section`
  align-items: center;
  background: ${({ theme }) => theme.colors.alabasterWhite};
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 60px 0;
  text-align: center;

  h1 {
    margin: 15px;
    max-width: 90%;
  }

  p {
    color: ${({ theme }) => theme.colors.doveGray};
    line-height: 2;
    max-width: 90%;
  }
`;

export const StyledIntegrationLogoContainer = styled.div`
  position: relative;
  top: 36px;

  @media only screen and (max-width: 800px) {
    top: 0;
  }
`;
