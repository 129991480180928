// Unfortunately, we can't extend enums, so they just have to be repeated.

export enum HiringTeamRole {
  Coordinator = 'coordinator',
  HiringManager = 'hiring_manager',
  Recruiter = 'recruiter',
  Sourcer = 'sourcer',
}

export enum HiringTeamRoleWithInterviewer {
  Coordinator = 'coordinator',
  HiringManager = 'hiring_manager',
  Interviewer = 'interviewer',
  Recruiter = 'recruiter',
  Sourcer = 'sourcer',
}

export enum HiringTeamRoleWithScheduler {
  Coordinator = 'coordinator',
  HiringManager = 'hiring_manager',
  Scheduler = 'scheduler',
  Recruiter = 'recruiter',
  Sourcer = 'sourcer',
}

export const hiringTeamLabels: Record<HiringTeamRole | HiringTeamRoleWithInterviewer | HiringTeamRoleWithScheduler, string> = {
  [HiringTeamRole.Coordinator]: 'Coordinator',
  [HiringTeamRole.HiringManager]: 'Hiring Manager',
  [HiringTeamRole.Recruiter]: 'Recruiter',
  [HiringTeamRole.Sourcer]: 'Sourcer',
  [HiringTeamRoleWithInterviewer.Interviewer]: 'Interviewer',
  [HiringTeamRoleWithScheduler.Scheduler]: 'Scheduler',
};
