import Moment from 'moment-timezone';
import { keyBy } from 'lodash';

import { InterviewerFilterableType, ScheduleStatus } from '../types';

import type { TrainingPhase, TrainingProgram, TrainingProgramUser, TrainingSession } from '../types';

interface InterviewerFilter {
  interviewer_filter_expressions: {
    negated: boolean;
    filterable_id: string;
    filterable_type: `${InterviewerFilterableType}`;
  }[] | null;
}

/**
 * This function returns whether the given set of filters is for a trainee. This relies on assumption that we've made
 * for the data model, such as how it's not possible to have multiple filters when one of them is a training expression.
 */
export function isTraineeSlot (filters: InterviewerFilter[]): boolean {
  return filters
  .flatMap((filter) => filter.interviewer_filter_expressions || [])
  .some(({ filterable_type, negated }) => {
    return filterable_type === InterviewerFilterableType.Training && !negated;
  });
}

/**
 * This function returns the eligibility string that is linked to the training program for a slot.
 * This relies on assumption that we've made for the data model, such as how it's not possible to have
 * multiple filters when one of them is a training expression.
 */
export function trainingProgramEligibilityOfSlot (filters: InterviewerFilter[]): string | null {
  if (isTraineeSlot(filters)) {
    return filters[0]!.interviewer_filter_expressions![0]!.filterable_id!;
  }
  return null;
}

/**
 * This function is similar to NextPhase pn the backend, but doesn't take upcoming sessions into account at all.
 *
 * TODO: This can probably be updated to use calculateSessionSummary somehow.
 */
export function currentPhase (program: TrainingProgram, user: Pick<TrainingProgramUser, 'training_sessions' | 'training_overrides'>): string {
  const overrides = keyBy(user.training_overrides || [], 'training_phase_id');
  for (const phase of (program.training_phases || [])) {
    const required = overrides[phase.id]?.number_of_interviews ?? phase.number_of_interviews;
    const completed = (user.training_sessions || []).reduce<number>((acc, session) => {
      if (session.training_phase_id !== phase.id) {
        // This is a session for a different phase, so we don't care about it right now.
        return acc;
      }
      if (session.deleted_at || session.ignored_at) {
        // This session is either deleted or ignored, but either way, we don't care about it right now.
        return acc;
      }
      if (session.interviewer?.interview.schedule.hold) {
        // This is a non-manual session, but the schedule is on hold, so we don't want it to contribute to any
        // session counts.
        return acc;
      }
      if (session.interviewer && session.interviewer.interview.schedule.status !== ScheduleStatus.Confirming && session.interviewer.interview.schedule.status !== ScheduleStatus.Confirmed) {
        // This is a non-manual session, but the schedule is cancelled, so we don't want it to contribute to any
        // session counts.
        return acc;
      }
      if (session.interviewer && Moment(session.interviewer.interview.start_time).isAfter(Moment())) {
        // This is a non-manual session, but the start time hasn't happened yet, so this is an upcoming schedule, but
        // when determining the current phase, we don't take upcoming sessions into account.
        return acc;
      }

      // This is either a manually added session or a completed session. In both cases, we consider them to be valid and
      // should count toward the completed count.
      return acc + 1;
    }, 0);

    if (completed < required) {
      return phase.name;
    }
  }

  // We've gone through every phase and determined that we've completed all of them, so just return the empty string.
  return '';
}

type TrainingProgramUserStub = Pick<TrainingProgramUser, 'training_sessions' | 'training_overrides'>;

interface SessionSummary {
  completedSessions: number;
  upcomingSessions: number;
  requiredSessions: number;
  unscheduledSessions: number;
  completed: boolean;
  fullyScheduled: boolean;
  phaseSummaries: PhaseSummary[];
}

interface PhaseSummary {
  completedSessions: number;
  upcomingSessions: number;
  requiredSessions: number;
  unscheduledSessions: number;
  completedPhase: boolean;
  fullyScheduledPhase: boolean;
}

export function calculateSessionSummary (program: TrainingProgram, user: TrainingProgramUserStub, sessions: TrainingSession[], interviewStartTime: string): SessionSummary {
  const summary: SessionSummary = {
    completedSessions: 0,
    upcomingSessions: 0,
    requiredSessions: 0,
    unscheduledSessions: 0,
    completed: true,
    fullyScheduled: true,
    phaseSummaries: [],
  };

  // Make overrides into a map, so we can look them up by training phase ID.
  const overrides = keyBy(user.training_overrides || [], 'training_phase_id');

  for (const phase of (program.training_phases || [])) {
    const phaseSummary: PhaseSummary = {
      completedSessions: 0,
      upcomingSessions: 0,
      requiredSessions: 0,
      unscheduledSessions: 0,
      completedPhase: false,
      fullyScheduledPhase: false,
    };

    phaseSummary.requiredSessions = overrides[phase.id] ? overrides[phase.id].number_of_interviews : phase.number_of_interviews;

    for (const session of sessions) {
      if (session.training_phase_id !== phase.id) {
        // This is a session for a different phase, so we don't care about it right now.
        continue;
      }
      if (session.deleted_at || session.ignored_at) {
        // This session is either deleted or ignored, but either way, we don't care about it right now.
        continue;
      }
      if (session.interviewer && session.interviewer.interview.schedule.hold) {
        // This is a non-manual session, but the schedule is on hold, so we don't want it to contribute to any session
        // counts.
        continue;
      }
      if (session.interviewer && session.interviewer.interview.schedule.status !== ScheduleStatus.Confirming && session.interviewer.interview.schedule.status !== ScheduleStatus.Confirmed) {
        // This is a non-manual session, but the schedule is cancelled, so we don't want it to contribute to any session
        // counts.
        continue;
      }
      if (session.interviewer && Moment(session.interviewer.interview.start_time).isAfter(Moment(interviewStartTime))) {
        // This is a non-manual session, but the start time is after the start time of the interview that we're
        // considering, so it should only count toward upcoming sessions.
        phaseSummary.upcomingSessions++;
        continue;
      }

      // This is either a manually added session, a completed session, or an upcoming (upcoming meaning it hasn't
      // happened yet, but it's still before the interview in question's start time) session. In all cases, we
      // consider them to be valid and should count toward the done count.
      phaseSummary.completedSessions++;
    }

    // We don't want negative values for the unscheduled sessions count because when we add it together to have the
    // total for the whole program, a negative value in one phase could incorrectly make it look like there aren't
    // any unscheduled sessions (e.g. 2 unscheduled sessions for Shadow but -2 for Reverse Shadow).
    phaseSummary.unscheduledSessions = Math.max(0, phaseSummary.requiredSessions - phaseSummary.completedSessions - phaseSummary.upcomingSessions);
    phaseSummary.completedPhase = phaseSummary.completedSessions >= phaseSummary.requiredSessions;
    phaseSummary.fullyScheduledPhase = (phaseSummary.completedSessions + phaseSummary.upcomingSessions) >= phaseSummary.requiredSessions;

    summary.completedSessions += phaseSummary.completedSessions;
    summary.upcomingSessions += phaseSummary.upcomingSessions;
    summary.requiredSessions += phaseSummary.requiredSessions;
    summary.unscheduledSessions += phaseSummary.unscheduledSessions;
    summary.completed = summary.completed && phaseSummary.completedPhase;
    summary.fullyScheduled = summary.fullyScheduled && phaseSummary.fullyScheduledPhase;
    summary.phaseSummaries.push(phaseSummary);
  }

  return summary;
}

/**
 * This function returns the phase that the next session should be assigned to. If there is no next phase, the last
 * phase will be returned. This is in an effort to potentially get the trainee trained up faster.
 *
 * This is directly copied from trainingprograms.NextPhaseWithProgramAndUser on the backend. Keep changes in sync.
 */
export function nextPhase (program: TrainingProgram, user: TrainingProgramUserStub, sessions: TrainingSession[], interviewStartTime: string): TrainingPhase {
  const summary = calculateSessionSummary(program, user, sessions, interviewStartTime);

  for (const phaseIndex in summary.phaseSummaries) {
    const phaseSummary = summary.phaseSummaries[phaseIndex];
    if (!phaseSummary.completedPhase) {
      // This phase isn't completed yet, so it's the next phase.
      return program.training_phases![phaseIndex];
    }
  }

  // All phases are completed, so just return the last phase.
  return program.training_phases![program.training_phases!.length - 1];
}

/**
 * This function takes in a program and user and returns the updated final set of sessions.
 *
 * This is directly copied from trainingprograms.CorrectTrainingSessionsWithProgramAndUser on the backend. Keep changes
 * in sync.
 */
export function correctTrainingSessions (program: TrainingProgram, user: TrainingProgramUserStub): TrainingSession[] {
  if (!user.training_sessions || user.training_sessions.length === 0) {
    // We have no sessions, so just return early.
    return user.training_sessions || [];
  }

  // We slice to prevent modifying the original array.
  const sessions = user.training_sessions.slice().sort((a, b) => {
    if (!a.interviewer && b.interviewer) {
      // The first item is manual while the second is not, so yes, the first is "less than" the second.
      return -1;
    }
    if (a.interviewer && !b.interviewer) {
      // The second item is manual while the first is not, so no, the first is "greater than" the second.
      return 1;
    }
    if (!a.interviewer && !b.interviewer) {
      // They're both manual sessions for the same phase, so we don't really care about which comes first. For
      // determinism's sake, we just sort based on created date.
      return Moment(a.created_at).isBefore(Moment(b.created_at)) ? -1 : 1;
    }

    // Both sessions are non-manual, so now we sort based on interview end time. This should put completed
    // sessions before upcoming ones.
    const aEnd = Moment(a.interviewer!.interview.start_time).add(a.interviewer!.interview.interview_template.duration_minutes, 'minute');
    const bEnd = Moment(b.interviewer!.interview.start_time).add(b.interviewer!.interview.interview_template.duration_minutes, 'minute');
    return aEnd.isBefore(bEnd) ? -1 : 1;
  });

  const now = Moment();
  const lockedInSessions: TrainingSession[] = [];

  return sessions.map((session) => {
    if (session.ignored_at) {
      // This is an ignored session, so we don't want to count it when calculating how many sessions have been
      // completed.
      return session;
    }
    if (session.deleted_at) {
      // This is a deleted manual session, so we don't want to count it when calculated how many sessions have
      // been completed.
      return session;
    }
    if (!session.interviewer) {
      // This is a manual session, which we don't ever update since it's always completed and the phase came
      // directly from the user.
      lockedInSessions.push(session);
      return session;
    }
    if (session.interviewer.interview.schedule.status !== ScheduleStatus.Confirming && session.interviewer.interview.schedule.status !== ScheduleStatus.Confirmed) {
      // This session is for a cancelled schedule, so we don't want to count it when calculate phases.
      return session;
    }
    if (!Moment(session.interviewer.interview.start_time).isAfter(now)) {
      // This session has already started, so since that's what we consider to be completed, we won't be changing
      // the phase of it.
      lockedInSessions.push(session);
      return session;
    }

    // This is an upcoming session that we need to potentially update, so we calculate what the next phase should
    // be, based on the sessions that we've seen, and if we get something different, we update it.
    const phase = nextPhase(program, user, lockedInSessions, session.interviewer.interview.start_time);
    if (session.training_phase_id !== phase.id) {
      // We have calculated that this session should have a different phase than the one assigned to it. This
      // check is to help reduce the number of updates we have to do, but more importantly, it's to preserve any
      // possible differences in the saved phase name on the session.
      return {
        ...session,
        training_phase_id: phase.id,
        phase_name: phase.name,
      };
    }

    lockedInSessions.push(session);
    return session;
  });
}
