import Moment from 'moment-timezone';
import { isEmpty, isEqual } from 'lodash';

import Flash from 'components/library/utils/Flash';
import { formatAttendeeName } from './helpers';
import { formatDuration } from '../../../../../../../libraries/formatters';
import { formatMoment, TimeFormat } from 'libraries/time';
import { useRoomsMap } from 'hooks/queries/rooms';
import { useUsersMap } from 'hooks/queries/users';

import type { EditableHiringMeeting, HiringMeeting, HiringMeetingAttendeeUser } from 'types';
import type { TimeSlot } from '../../../../../../library/inputs/AvailabilityPicker/types';

interface Props {
  newHiringMeeting: EditableHiringMeeting;
  newHiringMeetingAttendees?: HiringMeetingAttendeeUser[];
  newTimeSlot: TimeSlot;
  originalHiringMeeting: HiringMeeting;
  originalHiringMeetingAttendees?: HiringMeetingAttendeeUser[];
}

const UnsavedEditsFlash = ({
  newHiringMeeting,
  newHiringMeetingAttendees,
  newTimeSlot,
  originalHiringMeeting,
  originalHiringMeetingAttendees,
}: Props) => {
  const rooms = useRoomsMap();
  const users = useUsersMap({ archived: true });

  const oldDate = formatMoment(Moment.tz(originalHiringMeeting.start_time, originalHiringMeeting.timezone), TimeFormat.LongDayOfWeekMonthDay);
  const newDate = formatMoment(Moment.tz(newTimeSlot.start_time, newHiringMeeting.timezone), TimeFormat.LongDayOfWeekMonthDay);

  const oldStartTime = formatMoment(Moment.tz(originalHiringMeeting.start_time, originalHiringMeeting.timezone), TimeFormat.TimeWithTimezone);
  const newStartTime = formatMoment(Moment.tz(newTimeSlot.start_time, newHiringMeeting.timezone), TimeFormat.TimeWithTimezone);

  const oldDuration = originalHiringMeeting.hiring_meeting_template.duration_minutes;
  const newDuration = Moment(newTimeSlot.end_time).diff(newTimeSlot.start_time, 'minutes');

  const oldRoomId = originalHiringMeeting.room_id || null;
  const newRoomId = newHiringMeeting.room_id || null;

  const oldZoomHostId = originalHiringMeeting.zoom_host_id || null;
  const newZoomHostId = newHiringMeeting.zoom_host_id || null;

  const oldVideoConferencingEnabled = originalHiringMeeting.hiring_meeting_template.video_conferencing_enabled;
  const newVideoConferencingEnabled = newHiringMeeting.hiring_meeting_template.video_conferencing_enabled;

  const oldAttendees = originalHiringMeetingAttendees?.map(({ id }) => id).sort((a, b) => users[a]?.name! < users[b]?.name! ? -1 : 1) || [];
  const newAttendees = newHiringMeetingAttendees?.map(({ id }) => id).sort((a, b) => users[a]?.name! < users[b]?.name! ? -1 : 1) || [];
  const addedAttendees = newAttendees.filter((id) => !oldAttendees.includes(id));
  const removedAttendees = oldAttendees.filter((id) => !newAttendees.includes(id));

  const hasUnsavedEdits = Boolean(
    oldDate !== newDate ||
    oldStartTime !== newStartTime ||
    oldDuration !== newDuration ||
    oldRoomId !== newRoomId ||
    oldZoomHostId !== newZoomHostId ||
    oldVideoConferencingEnabled !== newVideoConferencingEnabled ||
    !isEqual(oldAttendees, newAttendees)
  );

  return (
    <Flash
      message={(
        <>
          The following changes will be applied when you save:
          <ul>
            {newDate !== oldDate &&
              <li>
                You have moved this hiring meeting to a different day: <b>{newDate}</b>.
              </li>
            }
            {newDate === oldDate && newStartTime !== oldStartTime &&
              <li>
                You have moved this hiring meeting to a different time: <b>{newStartTime}</b>.
              </li>
            }
            {newDuration !== oldDuration &&
              <li>
                You have changed this hiring meeting&apos;s duration to be <b>{formatDuration(newDuration)}</b>.
              </li>
            }
            {!newVideoConferencingEnabled && oldVideoConferencingEnabled &&
              <li>
                You have removed video conferencing from this hiring meeting.
              </li>
            }
            {newVideoConferencingEnabled && !oldVideoConferencingEnabled &&
              <li>
                You have added video conferencing to this hiring meeting.
              </li>
            }
            {!Boolean(newRoomId) && Boolean(oldRoomId) &&
              <li>
                You have removed the room from this hiring meeting.
              </li>
            }
            {Boolean(newRoomId) && newRoomId !== oldRoomId &&
              <li>
                This hiring meeting will now take place in <b>{rooms[newRoomId!]?.name}</b>.
              </li>
            }
            {Boolean(newZoomHostId) && Boolean(oldZoomHostId) && newZoomHostId !== oldZoomHostId &&
              <li>
                You have changed the Zoom host to be <b>{newHiringMeeting.zoom_host_type === 'room' ? rooms[newZoomHostId!]?.name : (users[newZoomHostId!]?.name || users[newZoomHostId!]?.email)}</b>.
              </li>
            }
            {!isEmpty(removedAttendees) &&
              <li>
                You have removed {formatAttendeeName(removedAttendees, users)} from the hiring meeting.
              </li>
            }
            {!isEmpty(addedAttendees) &&
              <li>
                You have added {formatAttendeeName(addedAttendees, users)} to the hiring meeting.
              </li>
            }
          </ul>
        </>
      )}
      showFlash={hasUnsavedEdits}
      type="info"
    />
  );
};

export default UnsavedEditsFlash;
