import { createContext, useContext, useMemo, useState } from 'react';

import type { CreateInterviewTemplatePayload } from '../../../../hooks/queries/interview-templates';
import type { Dispatch, ReactNode, SetStateAction } from 'react';

export enum Step {
  Details,
  Interviewers,
  Review,
}

interface NewInterviewTemplateContextState {
  completedStep: Step;
  interviewTemplate: CreateInterviewTemplatePayload;
  setCompletedStep: Dispatch<SetStateAction<Step>>;
  setInterviewTemplate: Dispatch<SetStateAction<CreateInterviewTemplatePayload>>;
}

// This is the context for keeping track of the new interview template. You
// should keep track of the new link with a useState call in the same component
// that use use the Provider, recreate an array using useMemo, and then pass it
// into the Provider. Make sure you don't pass an array literal into the
// Provider. This can cause re-rendering issues as noted here:
// https://reactjs.org/docs/context.html#caveats.
const NewInterviewTemplateContext = createContext<NewInterviewTemplateContextState>({
  completedStep: Step.Details,
  interviewTemplate: {
    name: '',
    duration_minutes: 0,
    live_coding_enabled: false,
  },
  setCompletedStep: () => {},
  setInterviewTemplate: () => {},
});

interface Props {
  children: ReactNode;
}

export const NewInterviewTemplateProvider = ({ children }: Props) => {
  const [completedStep, setCompletedStep] = useState<Step>(Step.Details);
  const [interviewTemplate, setInterviewTemplate] = useState<CreateInterviewTemplatePayload>({
    name: '',
    duration_minutes: 0,
    live_coding_enabled: false,
  });

  const contextValue: NewInterviewTemplateContextState = useMemo(() => ({
    completedStep,
    interviewTemplate,
    setCompletedStep,
    setInterviewTemplate,
  }), [completedStep, interviewTemplate]);

  return (
    <NewInterviewTemplateContext.Provider value={contextValue}>
      {children}
    </NewInterviewTemplateContext.Provider>
  );
};

export const useNewInterviewTemplate = () => {
  return useContext(NewInterviewTemplateContext);
};
