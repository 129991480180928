import { Breadcrumb } from 'react-breadcrumbs';
import { NavLink, Redirect, Route, Switch, useHistory, useLocation, useParams } from 'react-router-dom';
import { find, isEmpty, sortBy } from 'lodash';
import { useLayoutEffect, useMemo, useState } from 'react';
import { useMediaQuery } from 'react-responsive';

import Flash from '../../../../library/utils/Flash';
import JobStage from './JobStage';
import SelectInput from '../../../../library/inputs/SelectInput';
import StatusIndicator from '../../../../library/utils/StatusIndicator';
import { useJob } from '../../../../../hooks/queries/jobs';

import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../../../library/inputs/SelectInput/types';
import type { Stage } from '../../../../../types';
import { correctPath } from 'libraries/gem';

const JobStageLabel = ({ stage }: { stage: Stage }) => (
  <div className="job-stage-label">
    <StatusIndicator
      color={stage.schedule_template_id ? 'green' : 'gray'}
      tooltipId={`schedulable-status-indicator-${stage.id}`}
      tooltipText={`Scheduling is ${stage.schedule_template_id ? '' : 'not'} enabled`}
    >
      <span className="number">{stage.position + 1}.</span>
      &nbsp;{stage.name}
    </StatusIndicator>
  </div>
);

const JobStages = () => {
  const history = useHistory();
  const location = useLocation();
  const { id } = useParams<{ id: string }>();

  const { data: job } = useJob(id);

  const stages = sortBy((job?.stages || []).filter(({ deleted }) => !deleted), 'position');

  const [selectedStage, setSelectedStage] = useState<Stage | undefined>();

  const isSmallScreen = useMediaQuery({ query: '(max-width: 1050px)' });

  const firstSchedulableStage = useMemo(() => stages.find((stage) => stage.schedule_template_id) || stages?.[0], [stages]);

  // Get selected stage from url
  useLayoutEffect(() => {
    const splitUrl = location.pathname.split('/stages/');
    if (splitUrl.length > 1) {
      const stageId = splitUrl[1].split('/')[0];
      if (!selectedStage || stageId !== selectedStage.id) {
        setSelectedStage(find(job?.stages, ['id', stageId]));
      }
    }
  }, [location.pathname]);

  const handleStageChange = (option: OnChangeValue<Option<string>, false>) => {
    const stageId = option?.value;
    const stage = find(job?.stages, ['id', stageId]);
    setSelectedStage(stage);
    if (stage) {
      history.push(correctPath(`/app/jobs/${id}/stages/${stageId}`));
    }
  };

  return (
    <Breadcrumb
      data={{
        title: 'Stages',
        pathname: correctPath(`/app/jobs/${id}/stages`),
      }}
    >
      <div className="job-stages-container">
        <Flash
          message="You have not added any stages for this job. You can add stages in your ATS."
          showFlash={isEmpty(stages)}
          type="info"
        />
        {!isEmpty(stages) &&
          (isSmallScreen ?
            <SelectInput
              label="Stage"
              onChange={handleStageChange}
              options={stages.map((stage) => ({
                label: <JobStageLabel stage={stage} />,
                value: stage.id,
              }))}
              value={selectedStage ? {
                label: <JobStageLabel stage={selectedStage} />,
                value: selectedStage.id,
              } : undefined}
            /> :
            <div className="stages-menu">
              {stages.filter(({ name }) => name).map((stage) => (
                <NavLink
                  className="link"
                  key={stage.id}
                  to={correctPath(`/app/jobs/${id}/stages/${stage.id}`)}
                >
                  <JobStageLabel stage={stage} />
                </NavLink>
              ))}
            </div>
          )
        }
        {!isEmpty(stages) &&
          <Switch>
            <Redirect exact from={correctPath('/app/jobs/:id/stages')} to={correctPath(`/app/jobs/${id}/stages/${firstSchedulableStage?.id}`)} />
            <Route component={JobStage} path={correctPath('/app/jobs/:id/stages/:stageId')} />
          </Switch>
        }
      </div>
    </Breadcrumb>
  );
};

export default JobStages;
