import { find, orderBy } from 'lodash';
import { useMemo } from 'react';

import SelectInput from './SelectInput';
import Tag, { TagType } from '../data-display/Tag';
import { VideoConferencingTool } from '../../../types';
import { useRooms } from '../../../hooks/queries/rooms';
import { useSession } from '../../../hooks/use-session';
import { useUsers } from '../../../hooks/queries/users';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Group, Option as BaseOption } from './SelectInput/types';
import type { ReactNode } from 'react';

type Option = BaseOption<string> & {
  type: TagType.User | TagType.Room;
};

interface Props {
  helperText?: ReactNode;
  isDisabled?: boolean;
  isRequired?: boolean;
  label?: string;
  name?: string;
  onChange: (newValue: OnChangeValue<Option, true>, actionMeta: ActionMeta<Option>) => void;
  selectedUserIds?: string[];
}

const CalendarResourceSelectInput = ({
  helperText,
  isDisabled = false,
  isRequired = false,
  label,
  name,
  onChange,
  selectedUserIds,
}: Props) => {
  const { account } = useSession();
  const { data: users } = useUsers({ archived: true });
  const { data: rooms } = useRooms({ archived: true });

  const interviewers = useMemo<Option[]>(() => {
    if (!users) {
      return [];
    }

    return users.users.filter(({ ats_id, directory_archived, user_archived }) => {
      return !user_archived && !directory_archived && Boolean(ats_id);
    }).map((user) => ({
      value: user.id,
      label: user.name || user.email,
      type: TagType.User,
    }));
  }, [users]);

  const zoomHosts = useMemo<Option[]>(() => {
    if (account?.video_conferencing_type !== VideoConferencingTool.Zoom) {
      return [];
    }

    return [
      ...(users?.users || []).filter(({ video_conferencing_id, directory_archived, user_archived }) => {
        return !user_archived && !directory_archived && Boolean(video_conferencing_id);
      }).map<Option>((user) => ({
        value: `${user.id}:zoom`,
        label: user.name || user.email,
        type: TagType.User,
      })),
      ...(rooms?.rooms || []).filter(({ video_conferencing_id, directory_archived, user_archived }) => {
        return !user_archived && !directory_archived && Boolean(video_conferencing_id);
      }).map<Option>((room) => ({
        value: `${room.id}:zoom`,
        label: room.name || room.email,
        type: TagType.Room,
      })),
    ];
  }, [account, rooms, users]);

  // TODO: Allow selecting rooms
  const options = useMemo<Group<string, Option>[]>(() => [{
    label: 'Interviewers',
    options: orderBy(interviewers, 'label'),
  }, {
    label: 'Zoom Hosts',
    options: orderBy(zoomHosts, 'label'),
  }], [interviewers, zoomHosts]);

  return (
    <SelectInput
      className="calendar-resource-select-input"
      formatOptionLabel={(option) => (
        <Tag
          isZoomUser={option.value.endsWith(':zoom')}
          type={option.type}
          value={option.value.replaceAll(':zoom', '')}
        />
      )}
      helperText={helperText}
      isClearable
      isDisabled={isDisabled}
      isMulti
      isRequired={isRequired}
      label={label}
      name={name}
      onChange={onChange}
      options={options}
      placeholder={isDisabled ? undefined : `Select interviewer${account?.video_conferencing_type === VideoConferencingTool.Zoom ? ' and Zoom host' : ''} calendars`}
      value={selectedUserIds ?
        selectedUserIds.map((expression) => {
          return find(options.flatMap((group) => group.options), ['value', expression])!;
        }) :
        undefined
      }
    />
  );
};

export default CalendarResourceSelectInput;
