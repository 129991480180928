import Moment from 'moment-timezone';
import { Link, useParams } from 'react-router-dom';
import { useEffect, useState } from 'react';

import CalendarEventTemplateForm from '../../../../../library/inputs/CalendarEventTemplateForm';
import CalendarEventTemplateSummary from '../../../../../library/data-display/CalendarEventTemplateSummary';
import ErrorTokenFlash from '../../../../../library/utils/ErrorTokenFlash';
import Flash from '../../../../../library/utils/Flash';
import Section from '../../../../../library/layout/Section';
import { constructScheduleTokens } from './helpers';
import { useApplication } from '../../../../../../hooks/queries/applications';
import { useRoomsMap } from '../../../../../../hooks/queries/rooms';
import { useSession } from '../../../../../../hooks/use-session';
import { useTokens } from '../../../../../../hooks/queries/tokens';
import { useUpdateSchedule } from '../../../../../../hooks/queries/schedules';
import { useUpdateSelfSchedulingLink } from '../../../../../../hooks/queries/self-scheduling-links';
import { useUsersMap } from '../../../../../../hooks/queries/users';

import type { ScheduleWithType } from './types';
import type { Token } from '../../../../../../types';
import { correctPath } from 'libraries/gem';

interface Props {
  isEditable: boolean;
  resource: ScheduleWithType;
}

const CandidateCalendarEventSection = ({ isEditable, resource }: Props) => {
  const { id, scheduleId } = useParams<{ id: string; scheduleId: string }>();

  const { account } = useSession();
  const { data: application } = useApplication(id);
  const rooms = useRoomsMap();
  const users = useUsersMap({ archived: true });

  const {
    data: tokens,
    error: tokensError,
  } = useTokens({
    type: 'candidate_calendar_event',
    schedule: constructScheduleTokens(application!, resource, rooms, users),
  }, { enabled: isEditable || resource.type === 'self_scheduling_link' });

  const updateScheduleMutation = useUpdateSchedule();
  const updateSelfSchedulingLinkMutation = useUpdateSelfSchedulingLink();
  const mutation = resource.type === 'schedule' ? updateScheduleMutation : updateSelfSchedulingLinkMutation;

  const [isEditing, setIsEditing] = useState(false);
  const [candidateCalendarEventTemplateTitle, setCandidateCalendarEventTemplateTitle] = useState(resource.schedule_template.candidate_calendar_event_template?.title || '');
  const [candidateCalendarEventTemplateDescription, setCandidateCalendarEventTemplateDescription] = useState(resource.schedule_template.candidate_calendar_event_template?.description || '');
  const [candidateCalendarEventTemplateLocation, setCandidateCalendarEventTemplateLocation] = useState(resource.schedule_template.candidate_calendar_event_template?.location);
  const [candidateCalendarEventTemplateAdditionalAttendees, setCandidateCalendarEventTemplateAdditionalAttendees] = useState(resource.schedule_template.candidate_calendar_event_template?.additional_attendees || []);
  const [candidateCalendarEventTemplateAdditionalOptionalAttendees, setCandidateCalendarEventTemplateAdditionalOptionalAttendees] = useState(resource.schedule_template.candidate_calendar_event_template?.additional_optional_attendees || []);
  const [calendarErrorTokens, setCalendarErrorTokens] = useState<Token[]>([]);

  useEffect(() => {
    setCandidateCalendarEventTemplateTitle(resource.schedule_template.candidate_calendar_event_template?.title || '');
    setCandidateCalendarEventTemplateLocation(resource.schedule_template.candidate_calendar_event_template?.location || '');
    setCandidateCalendarEventTemplateDescription(resource.schedule_template.candidate_calendar_event_template?.description || '');
    setCandidateCalendarEventTemplateAdditionalAttendees(resource.schedule_template.candidate_calendar_event_template?.additional_attendees || []);
    setCandidateCalendarEventTemplateAdditionalOptionalAttendees(resource.schedule_template.candidate_calendar_event_template?.additional_optional_attendees || []);
    setIsEditing(false);
  }, [scheduleId]);

  useEffect(() => {
    setCandidateCalendarEventTemplateTitle(resource.schedule_template.candidate_calendar_event_template?.title || '');
  }, [resource.schedule_template.candidate_calendar_event_template?.title]);

  useEffect(() => {
    setCandidateCalendarEventTemplateLocation(resource.schedule_template.candidate_calendar_event_template?.location || '');
  }, [resource.schedule_template.candidate_calendar_event_template?.location]);

  useEffect(() => {
    setCandidateCalendarEventTemplateDescription(resource.schedule_template.candidate_calendar_event_template?.description || '');
  }, [resource.schedule_template.candidate_calendar_event_template?.description]);

  useEffect(() => {
    setCandidateCalendarEventTemplateAdditionalAttendees(resource.schedule_template.candidate_calendar_event_template?.additional_attendees || []);
  }, [resource.schedule_template.candidate_calendar_event_template?.additional_attendees]);

  useEffect(() => {
    setCandidateCalendarEventTemplateAdditionalOptionalAttendees(resource.schedule_template.candidate_calendar_event_template?.additional_optional_attendees || []);
  }, [resource.schedule_template.candidate_calendar_event_template?.additional_optional_attendees]);

  const handleEdit = () => {
    setIsEditing(true);
  };

  const handleCancel = () => {
    setCandidateCalendarEventTemplateTitle(resource.schedule_template.candidate_calendar_event_template?.title || '');
    setCandidateCalendarEventTemplateLocation(resource.schedule_template.candidate_calendar_event_template?.location || '');
    setCandidateCalendarEventTemplateDescription(resource.schedule_template.candidate_calendar_event_template?.description || '');
    setCandidateCalendarEventTemplateAdditionalAttendees(resource.schedule_template.candidate_calendar_event_template?.additional_attendees || []);
    setCandidateCalendarEventTemplateAdditionalOptionalAttendees(resource.schedule_template.candidate_calendar_event_template?.additional_optional_attendees || []);
    setIsEditing(false);
  };

  const handleSave = async () => {
    if (calendarErrorTokens.length > 0) {
      return;
    }

    mutation.reset();

    const payload = {
      schedule_template: {
        candidate_event_title: candidateCalendarEventTemplateTitle,
        candidate_event_location: candidateCalendarEventTemplateLocation,
        candidate_event_description: candidateCalendarEventTemplateDescription,
        candidate_event_additional_attendees: candidateCalendarEventTemplateAdditionalAttendees,
        candidate_event_additional_optional_attendees: candidateCalendarEventTemplateAdditionalOptionalAttendees,
      },
    };

    try {
      await mutation.mutateAsync({ id: scheduleId, payload });
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  return (
    <Section
      className="candidate-calendar-event-section"
      isEditable={isEditable}
      isEditing={isEditing}
      isSaving={mutation.isLoading}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Candidate calendar event"
    >
      <ErrorTokenFlash errorTokens={calendarErrorTokens} />
      <Flash
        isDismissible
        message={<>Successfully updated! {resource.type === 'schedule' ? <>This event invite still has not been sent to the candidate. To send it to them, <Link to={correctPath(`/app/candidates/${id}/schedule/review?schedule=${scheduleId}`)}>confirm this schedule</Link>.</> : ''}</>}
        onDismiss={mutation.reset}
        showFlash={mutation.isSuccess}
        type="success"
      />
      <Flash
        message={tokensError?.message}
        showFlash={Boolean(tokensError)}
        type="danger"
      />
      <Flash
        message={mutation.error?.message}
        showFlash={mutation.isError}
        type="danger"
      />
      <Flash
        message="The candidate has declined their invite."
        showFlash={resource.type === 'schedule' && resource.candidate_rsvp === 'declined'}
        type="warning"
      />
      {isEditing ? (
        <CalendarEventTemplateForm
          additionalAttendees={candidateCalendarEventTemplateAdditionalAttendees}
          additionalOptionalAttendees={candidateCalendarEventTemplateAdditionalOptionalAttendees}
          description={candidateCalendarEventTemplateDescription}
          isVideoConferencingEnabled={resource.schedule_template.video_conferencing_enabled}
          location={candidateCalendarEventTemplateLocation}
          locationHelperText={resource.schedule_template.video_conferencing_enabled && account?.video_conferencing_type === 'meet' ? 'We will add a video conferencing link when the schedule is confirmed.' : undefined}
          pendingPreviewMessage="This token will be filled in when we create the schedule."
          setAdditionalAttendees={setCandidateCalendarEventTemplateAdditionalAttendees}
          setAdditionalOptionalAttendees={setCandidateCalendarEventTemplateAdditionalOptionalAttendees}
          setDescription={setCandidateCalendarEventTemplateDescription}
          setErrorTokens={setCalendarErrorTokens}
          setLocation={setCandidateCalendarEventTemplateLocation}
          setTitle={setCandidateCalendarEventTemplateTitle}
          title={candidateCalendarEventTemplateTitle}
          tokens={tokens}
          type="candidate_calendar_event"
        />
      ) : (
        resource.type === 'schedule' ? (
          <CalendarEventTemplateSummary
            applicationId={application?.id}
            calendarEventTemplate={{
              title: resource.candidate_event_title,
              description: resource.candidate_event_description,
              location: resource.candidate_event_location,
              additional_attendees: resource.candidate_event_additional_attendees,
              additional_optional_attendees: resource.candidate_event_additional_optional_attendees,
              type: 'candidate_calendar_event',
            }}
            candidateName={application?.candidate.name}
            candidateRSVP={resource.candidate_rsvp}
            directoryCalendarId={resource.candidate_scheduling_calendar_email}
            directoryEventId={resource.candidate_event_ical_uid}
            endTime={Moment.tz(resource.interviews[resource.interviews.length - 1].start_time, resource.timezone).add(resource.interviews[resource.interviews.length - 1].interview_template.duration_minutes, 'minutes').toDate()}
            isVideoConferencingEnabled={Boolean(resource.schedule_template.video_conferencing_enabled)}
            startTime={Moment.tz(resource.interviews[0].start_time, resource.timezone).toDate()}
            timezone={resource.timezone}
          />
        ) : (
          tokens && (
            <CalendarEventTemplateSummary
              applicationId={application?.id}
              candidateName={application?.candidate.name}
              id={resource.schedule_template.candidate_calendar_event_template?.id}
              isVideoConferencingEnabled={resource.schedule_template.video_conferencing_enabled}
              pendingPreviewMessage="This token will be filled in when the candidate selects a time for the interview."
              tokens={tokens}
            />
          )
        )
      )}
    </Section>
  );
};

export default CandidateCalendarEventSection;
