import styled from 'styled-components';

import SelectInput from '../SelectInput';

// The typing for this is weird because of styled-components. This is inspired from
// https://github.com/styled-components/styled-components/issues/1803#issuecomment-857092410
export const StyledSelectInput = styled(SelectInput)`
  /* These extra classes are needed to increase specificity and overwrite other styles. */
  &.input.select-input {
    .select-input__control {
      .select-input__multi-value {
        border: none;

        .select-input__multi-value__label {
          overflow: visible;
          padding: 0;
        }
      }
    }
  }
` as typeof SelectInput;
