import { useEffect, useState } from 'react';

import Modal from '../../layout/Modal';
import { StyledTextInput } from './styles';

import type { ChangeEvent, MouseEvent, MouseEventHandler } from 'react';

interface Props {
  isOpen: boolean;
  onSubmit: (newName: string) => void;
  onToggle: MouseEventHandler<HTMLButtonElement>;
  templateName: string;
}

const TemplateNamePromptModal = ({
  isOpen,
  onSubmit,
  onToggle,
  templateName,
}: Props) => {
  // We maintain separate state for the template name and pass it through to the
  // onSubmit handler instead of just accepting a setTemplateName function from
  // the parent because we don't want to update the value behind the modal in
  // real time as the user edits the name within the modal.
  const [name, setName] = useState(`Copy of ${templateName}`);

  useEffect(() => {
    setName(`Copy of ${templateName}`);
  }, [templateName]);

  const handleNameChange = (e: ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };

  const handleSubmit = (e: MouseEvent<HTMLButtonElement>) => {
    onSubmit(name);
    onToggle(e);
  };

  return (
    <Modal
      isOpen={isOpen}
      onSubmit={handleSubmit}
      onToggle={onToggle}
      submitButtonValue="Save"
      title="Change name of new template?"
    >
      <p>
        We strongly recommend saving your new template with a different name so that you can tell it apart from the existing one.
      </p>
      <StyledTextInput
        isAutofocus
        isRequired
        onChange={handleNameChange}
        placeholder="Template name"
        value={name}
      />
    </Modal>
  );
};

export default TemplateNamePromptModal;
