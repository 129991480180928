import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faPlus, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { useId } from 'react';

import Button from '../Button';
import Tooltip from '../../utils/Tooltip';
import { StyledActionButtonsContainer } from './styles';

import type { MouseEventHandler } from 'react';

interface Props {
  onAddWindow?: MouseEventHandler<HTMLButtonElement>;
  onCopyToAll?: MouseEventHandler<HTMLButtonElement>;
  onDeleteWindow: MouseEventHandler<HTMLButtonElement>;
  tooltipValue?: string;
}

const BusinessHoursActionButtons = ({
  onAddWindow,
  onCopyToAll,
  onDeleteWindow,
  tooltipValue = 'Copy time to all',
}: Props) => {
  const id = useId();

  return (
    <StyledActionButtonsContainer>
      <Button
        color="gray"
        iconRight={<FontAwesomeIcon icon={faTrashAlt} />}
        onClick={onDeleteWindow}
        tooltip={
          <Tooltip
            id={`${id}-remove`}
            position="top"
            value="Remove window"
          />
        }
      />
      {onAddWindow && (
        <Button
          color="no-outline"
          iconLeft={<FontAwesomeIcon icon={faPlus} />}
          onClick={onAddWindow}
          size="small"
          value="Add Window"
        />
      )}
      {onCopyToAll && (
        <Button
          color="gray"
          iconRight={<FontAwesomeIcon icon={faCopy} />}
          onClick={onCopyToAll}
          tooltip={
            <Tooltip
              id={id}
              position="top"
              value={tooltipValue}
            />
          }
        />
      )}
    </StyledActionButtonsContainer>
  );
};

export default BusinessHoursActionButtons;
