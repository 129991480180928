import styled from 'styled-components';

import Modal from 'components/library/layout/Modal';

export const StyledModal = styled(Modal)`
  &.dropdown-modal {
    .modal-body {
      overflow: visible !important;
    }
  }

  .flash {
    margin-bottom: 14px;
  }

  .input {
    margin: 14px 0;
  }
`;

export const StyledHeaderContainer = styled.div`
  align-items: center;
  display: flex;
  margin-bottom: 14px;

  h1 {
    flex: 1;
  }
`;

export const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 28px 28px;

  h1 {
    margin: 0;
  }

  .flash {
    margin: 14px 0;
  }
`;
