import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import styled, { keyframes } from 'styled-components';

import DeleteButton from 'components/library/inputs/DeleteButton';

export const StyledContainer = styled.div`
  align-items: center;
  display: flex;
  gap: .5em;
`;

const pulseAnimation = keyframes`
  0% {
    background: rgba(0, 0, 0, .5);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, .5);
  }

  70% {
    background: rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    background: rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
`;

export const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  &.playing {
    animation: ${pulseAnimation} 1s infinite;
    background: rgba(0, 0, 0, .5);
    border-radius: 50%;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 1);
  }
`;

export const StyledDeleteButton = styled(DeleteButton)`
  padding: 0 !important;
`;
