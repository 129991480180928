import { useMutation, useQuery, useQueryClient } from 'react-query';

import InterviewPlanner from '../../libraries/interviewplanner';

import type { TrainingProgram } from '../../types';
import type { UseQueryOptions } from 'react-query';

export enum QueryKey {
  RetrieveTrainingProgram = 'RetrieveTrainingProgram',
  ListTrainingPrograms = 'ListTrainingPrograms',
}

export const useTrainingProgram = (id?: string, options: UseQueryOptions<TrainingProgram, Error> = {}) => {
  return useQuery<TrainingProgram, Error>([QueryKey.RetrieveTrainingProgram, id], () => {
    return InterviewPlanner.request('GET', `/training-programs/${id}`);
  }, {
    enabled: options.enabled !== undefined ? options.enabled : Boolean(id),
    ...options,
  });
};

interface ListTrainingProgramsQuery {
  limit?: number;
  offset?: number;
  eligibility?: string;
  archived?: boolean;
}

interface ListTrainingProgramsData {
  training_programs: TrainingProgram[];
  total: number;
}

export const useTrainingPrograms = (query: ListTrainingProgramsQuery = {}, options: UseQueryOptions<ListTrainingProgramsData, Error> = {}) => {
  // This query can't be canceled because the promise being returned from this
  // function (which is a result of the async keyword) doesn't have a cancel
  // function on it. If we want it to be cancellable, we can't use async/await,
  // and we need to make sure the cancel function from the promise returned from
  // InterviewPlanner.request is preserved.
  return useQuery<ListTrainingProgramsData, Error>([QueryKey.ListTrainingPrograms, query], async () => {
    const pageSize = 100;
    const paginatedQuery = {
      limit: pageSize,
      offset: 0,
      ...query,
    };

    const trainingPrograms: TrainingProgram[] = [];
    let total = 0;
    let totalLimit: number | undefined;

    while (totalLimit === undefined || trainingPrograms.length < totalLimit) {
      const response = await InterviewPlanner.request<ListTrainingProgramsData>('GET', '/training-programs', null, paginatedQuery);
      total = response.total;
      if (response.training_programs.length === 0) {
        break;
      }

      trainingPrograms.push(...response.training_programs);
      totalLimit = query.limit! < total ? query.limit : total;
      paginatedQuery.offset = paginatedQuery.offset + paginatedQuery.limit;
    }

    return { training_programs: trainingPrograms, total };
  }, options);
};

export interface CreateTrainingProgramPayload {
  eligibility: string;
  training_phases: {
    name: string;
    number_of_interviews: number;
    approver?: string;
  }[];
}

interface CreateTrainingProgramMutationVariables {
  payload: CreateTrainingProgramPayload;
}

export const useCreateTrainingProgram = () => {
  const queryClient = useQueryClient();

  return useMutation<TrainingProgram, Error, CreateTrainingProgramMutationVariables>(({ payload }) => {
    return InterviewPlanner.request('POST', '/training-programs', payload, null, false);
  }, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKey.ListTrainingPrograms]);
      queryClient.setQueryData([QueryKey.RetrieveTrainingProgram, data.id], data);
    },
  });
};

export interface UpdateTrainingProgramPayload {
  paused?: boolean;
  training_phases?: {
    id?: string; // Used to indicate updating a phase
    name: string;
    number_of_interviews: number;
    approver?: string; // Not used yet
  }[];
  archived?: boolean;
}

interface UpdateTrainingProgramMutationVariables {
  id: string;
  payload?: UpdateTrainingProgramPayload;
}

export const useUpdateTrainingProgram = () => {
  const queryClient = useQueryClient();

  return useMutation<TrainingProgram, Error, UpdateTrainingProgramMutationVariables>(({ id, payload }) => {
    return InterviewPlanner.request('POST', `/training-programs/${id}`, payload, null, false);
  }, {
    onSuccess: (data: TrainingProgram) => {
      queryClient.invalidateQueries([QueryKey.ListTrainingPrograms]);
      queryClient.setQueryData([QueryKey.RetrieveTrainingProgram, data.id], data);
    },
  });
};
