import { FixedSizeList } from 'react-window';
import { flatten, isArray, min } from 'lodash';
import { useMemo } from 'react';

import { GroupHeading } from './GroupHeading';

import type { Group, Option, Value } from './types';
import type { MenuListProps } from 'react-select/dist/declarations/src/components/Menu';
import type { ReactElement, CSSProperties, ReactNode } from 'react';

const OPTION_HEIGHT = 45;

const SelectInputMenuList = <V extends Value, O extends Option<V>, M extends boolean, G extends Group<V, O>>({ children, maxHeight, options }: MenuListProps<O, M, G>) => {
  if (!children || !isArray(children)) {
    // We need to make sure that we return a ReactElement here since that's what
    // react-select expects, but since children could be undefined, we wrap it
    // in a fragment.
    return <>{children}</>;
  }

  // We've already verified that children is an array, but we need to explicitly
  // cast it for Typescript to be happy.
  let nodeArray = children as ReactNode[];

  const isGroupedOptions = useMemo(() => {
    // We need to cast it as a Group array so we can properly check if it's
    // actually a Group.
    const groups = options as G[];
    return Boolean(groups[0] && groups[0].options);
  }, [options]);

  options = useMemo(() => (isGroupedOptions ?
    flatten((options as G[]).map((option) => option.options)) :
    options
  ), [options]);

  nodeArray = useMemo(() => (
    isGroupedOptions ?
      flatten(nodeArray.map((node) => {
        // We're pretty confident that these are elements, not any other type of
        // ReactNode.
        const element = node as ReactElement;
        return [
          <GroupHeading key={element.props.headingProps.id} {...element.props}>
            {element.props.children}
          </GroupHeading>,
          ...element.props.children,
        ];
      })) :
      nodeArray
  ), [options, nodeArray]);

  const menuHeight = min([
    nodeArray.length * OPTION_HEIGHT,
    maxHeight,
  ]) as number;

  return (
    <FixedSizeList
      height={menuHeight}
      initialScrollOffset={0}
      itemCount={nodeArray.length}
      itemSize={OPTION_HEIGHT}
      style={{ willChange: 'unset' }}
      width="100%"
    >
      {({ index, style }) => (
        // There seems to be an inconsistency with react-window's CSSProperties
        // and our React version's CSSProperties. Not sure what the issue is,
        // but there's no real issue here, just a typing issue, so we're just
        // casting it because we know that it's correct.
        <div style={style as CSSProperties}>
          {nodeArray[index]}
        </div>
      )}
    </FixedSizeList>
  );
};

export default SelectInputMenuList;
