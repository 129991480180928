import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBold, faItalic, faListOl, faListUl, faUnderline } from '@fortawesome/free-solid-svg-icons';
import { useSlate } from 'slate-react';

import { ElementType, InlineStyleType } from '../../../../../types';
import { isElementTypeActive, isMarkActive, toggleBlock, toggleMark } from '../helper';

import type { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import type { ListElementType } from '../../../../../types';
import type { MouseEvent } from 'react';

type BasicStyle = InlineStyleType | ListElementType;

interface Option {
  icon: IconDefinition;
  title: string;
  type: 'inline' | 'block';
}

const options: Record<BasicStyle, Option> = {
  [ElementType.OrderedList]: {
    icon: faListOl,
    title: 'Numbered list',
    type: 'block',
  },
  [ElementType.UnorderedList]: {
    icon: faListUl,
    title: 'Bulleted list',
    type: 'block',
  },
  [InlineStyleType.Bold]: {
    icon: faBold,
    title: 'Bold',
    type: 'inline',
  },
  [InlineStyleType.Italic]: {
    icon: faItalic,
    title: 'Italic',
    type: 'inline',
  },
  [InlineStyleType.Underline]: {
    icon: faUnderline,
    title: 'Underline',
    type: 'inline',
  },
};

interface Props {
  isDisabled: boolean;
  style: BasicStyle;
}

const BasicToolbarOption = ({ isDisabled, style }: Props) => {
  const editor = useSlate();

  const option = options[style];

  // This needs to be mouse down instead of click because otherwise, the editor
  // will lose focus on click and the button's effect won't happen.
  const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
    if (isDisabled) {
      return;
    }

    event.preventDefault();
    switch (option.type) {
      case 'inline':
        toggleMark(editor, style as InlineStyleType);
        break;
      case 'block':
        toggleBlock(editor, style as ListElementType);
    }
  };

  const isActive = option.type === 'inline' ?
    isMarkActive(editor, style as InlineStyleType) :
    isElementTypeActive(editor, style as ListElementType);

  return (
    <div
      className={classnames([
        'editor-toolbar-option',
        isActive && 'editor-toolbar-option-active',
      ])}
      onMouseDown={handleMouseDown}
      title={option.title}
    >
      <FontAwesomeIcon icon={option.icon} />
    </div>
  );
};

export default BasicToolbarOption;
