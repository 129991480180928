import styled from 'styled-components';

export const StyledContainer = styled.div`
  display: flex;
  gap: 28px;

  .file-drop-input {
    max-width: 200px;

    .dropzone {
      height: 170px;
    }
  }
`;

export const StyledInputsContainer = styled.div`
  flex: 1;
  padding: 14px 0;
`;
