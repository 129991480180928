import { useMemo } from 'react';

import PlayAudioButton from 'components/library/data-display/PlayAudioButton';
import { capitalize } from 'lodash';

import { constructAtsHref } from 'libraries/candidates';
import { formatPronouns } from 'libraries/formatters';
import OutboundLink from 'components/library/navigation/OutboundLink';
import Section from 'components/library/layout/Section';
import Table from 'components/library/data-display/Table';
import { useSession } from 'hooks/use-session';

import type { Candidate } from 'types';
import type { TableSchema } from 'components/library/data-display/Table';

interface Props {
  applicationAtsId?: string;
  candidate: Candidate;
}

const CandidateDetailsSection = ({ applicationAtsId, candidate }: Props) => {
  const { account } = useSession();

  const schema = useMemo<TableSchema<Candidate>>(() => [{
    header: 'Name',
    displayValue: ({ id, name, pronunciation_url }) => (
      <span>
        {name ? name : <span className="unset">Not set</span>}
        {pronunciation_url && (
          <PlayAudioButton
            id={`candidate-${id}-details-pronunciation`}
            src={pronunciation_url}
            tooltipText="Pronounce name"
          />
        )}
      </span>
    ),
  }, {
    header: 'Email',
    displayValue: ({ email }) => email ? <a href={`mailto:${email}`}>{email}</a> : <span className="unset">Not set</span>,
  }, {
    header: 'Phone',
    displayValue: ({ phone_number }) => phone_number ? phone_number : <span className="unset">Not set</span>,
  }, Boolean(formatPronouns(candidate.pronouns)) && {
    header: 'Pronouns',
    displayValue: ({ pronouns }) => formatPronouns(pronouns),
  }, {
    header: 'LinkedIn',
    displayValue: ({ linkedin_url }) => linkedin_url ? <OutboundLink href={linkedin_url} label="Candidate LinkedIn URL">{linkedin_url}</OutboundLink> : <span className="unset">Not set</span>,
  }, {
    header: 'Website',
    displayValue: ({ website }) => website ? <OutboundLink href={website} label="Candidate Website">{website}</OutboundLink> : <span className="unset">Not set</span>,
  }, {
    header: 'Resume',
    displayValue: ({ resume_url }) => resume_url ? <OutboundLink href={resume_url} label="Candidate Resume">Link</OutboundLink> : <span className="unset">Not set</span>,
  }, account?.ats_type && {
    header: 'Candidate Profile',
    displayValue: ({ ats_id }) => (
      <OutboundLink
        href={constructAtsHref(account, 'candidate', { applicationAtsId, candidateAtsId: ats_id })}
        label="Candidate ATS Profile"
      >
        Open in {capitalize(account.ats_type)}
      </OutboundLink>
    ),
  }], [candidate.pronouns]);

  return (
    <Section title="Candidate details">
      <Table
        data={[candidate]}
        layout="horizontal"
        schema={schema}
      />
    </Section>
  );
};

export default CandidateDetailsSection;
