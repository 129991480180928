import { capitalize } from 'lodash';

import StatusIndicator, { StatusIndicatorColor } from './StatusIndicator';
import { JobStatus } from '../../../types';

const statusColorMapping: Record<JobStatus, StatusIndicatorColor> = {
  [JobStatus.Open]: StatusIndicatorColor.Green,
  [JobStatus.Closed]: StatusIndicatorColor.Gray,
  [JobStatus.Draft]: StatusIndicatorColor.LightOrange,
};

interface Props {
  status: `${JobStatus}`;
}

const JobStatusIndicator = ({ status }: Props) => {
  return (
    <StatusIndicator color={statusColorMapping[status]}>
      {capitalize(status)}
    </StatusIndicator>
  );
};

export default JobStatusIndicator;
