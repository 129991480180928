import FeatureSection from './FeatureSection';
import { StyledContainer } from './styles';

import type { Feature } from './types';

interface Props {
  features: Feature[];
  isAlternatingLayout?: boolean;
}

const FeaturePage = ({ features, isAlternatingLayout }: Props) => {
  return (
    <StyledContainer isAlternatingLayout={isAlternatingLayout}>
      {features.map((feature) => (
        <FeatureSection
          key={feature.title}
          {...feature}
        />
      ))}
    </StyledContainer>
  );
};

export default FeaturePage;
