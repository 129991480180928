import { keyBy, orderBy, partition } from 'lodash';
import { useEffect, useMemo } from 'react';

import ListItem from '../data-display/ListItem';
import SelectInput from 'components/library/inputs/SelectInput';
import { useResolveZoomHosts } from 'hooks/queries/zoom';
import { useRoomsMap } from 'hooks/queries/rooms';
import { useUsersMap } from 'hooks/queries/users';
import { ZoomHostType } from 'types';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { EditableZoomHostFilter } from 'types';
import type { Group as SelectInputGroup, Option as SelectInputOption } from 'components/library/inputs/SelectInput/types';

export interface Option extends SelectInputOption<string> {
  type: ZoomHostType;
}

interface Group extends SelectInputGroup<string, Option> {
  type: ZoomHostType;
}

interface Props {
  helperText?: string;
  isDisabled?: boolean;
  label?: string;
  onChange?: (newValue: OnChangeValue<Option, false>, actionMeta?: ActionMeta<Option>) => void;
  selectedZoomHostId?: string;
  selectedZoomHostType?: `${ZoomHostType}`;
  zoomHostFilters?: EditableZoomHostFilter[];
}

const ZoomHostSelectInput = ({
  helperText,
  isDisabled = false,
  label,
  onChange,
  selectedZoomHostId,
  selectedZoomHostType,
  zoomHostFilters,
}: Props) => {
  const rooms = useRoomsMap();
  const users = useUsersMap({ archived: true });
  const { data: resolvedZoomHosts } = useResolveZoomHosts({
    zoomHostFilters: (zoomHostFilters || []).map(({ zoom_host_filter_expressions }) => ({
      zoom_host_filter_expressions: zoom_host_filter_expressions.map(({ negated, filterable_id, filterable_type }) => ({
        negated,
        filterable_id,
        filterable_type,
      })),
    })),
  });

  useEffect(() => {
    if (resolvedZoomHosts?.length === 1) {
      onChange?.({ value: resolvedZoomHosts[0].id, type: resolvedZoomHosts[0].type });
    }
  }, [resolvedZoomHosts]);

  const userOptions = useMemo<Option[]>(() => {
    return Object.values(users).filter(({ directory_archived, user_archived, video_conferencing_id }) => !user_archived && !directory_archived && Boolean(video_conferencing_id)).map((user) => ({
      label: user.name || user.email,
      secondaryText: user.email,
      value: user.id,
      type: ZoomHostType.User,
    }));
  }, [users]);

  const roomOptions = useMemo<Option[]>(() => {
    return Object.values(rooms).filter(({ directory_archived, user_archived, video_conferencing_id }) => !user_archived && !directory_archived && Boolean(video_conferencing_id)).map((room) => ({
      label: room.name,
      value: room.id,
      type: ZoomHostType.Room,
    }));
  }, [rooms]);

  const options = useMemo<Group[]>(() => {
    const zoomHostPoolIds = keyBy(resolvedZoomHosts, 'id');
    const [usersInPool, otherUsers] = partition(userOptions, (({ value }) => Boolean(zoomHostPoolIds[value])));
    const [roomsInPool, otherRooms] = partition(roomOptions, (({ value }) => Boolean(zoomHostPoolIds[value])));
    return [{
      label: 'Zoom User Pool',
      type: ZoomHostType.User,
      options: orderBy(usersInPool, 'label'),
    }, {
      label: 'Zoom Room Pool',
      type: ZoomHostType.Room,
      options: orderBy(roomsInPool, 'label'),
    }, {
      label: 'All Zoom Users',
      type: ZoomHostType.User,
      options: orderBy(otherUsers, 'label'),
    }, {
      label: 'All Zoom Rooms',
      type: ZoomHostType.Room,
      options: orderBy(otherRooms, 'label'),
    }];
  }, [resolvedZoomHosts, roomOptions, userOptions]);

  const selectedOption = useMemo<Option | undefined>(() => {
    if (selectedZoomHostId && selectedZoomHostType) {
      const optionsOfSelectedType = selectedZoomHostType === ZoomHostType.User ? userOptions : roomOptions;
      return optionsOfSelectedType.find(({ value }) => value === selectedZoomHostId);
    }
    return undefined;
  }, [selectedZoomHostId, selectedZoomHostType]);

  return (
    <SelectInput<string, Option, false, Group>
      formatOptionLabel={(option, { context }) => (
        context === 'menu' ? (
          <ListItem
            label={option.label}
            secondaryText={option.secondaryText}
          />
        ) :
          option.label
      )}
      helperText={helperText}
      isDisabled={isDisabled}
      isRequired
      label={label}
      maxMenuHeight={300}
      onChange={onChange}
      options={options}
      placeholder="Select a Zoom host"
      value={selectedOption}
    />
  );
};

export default ZoomHostSelectInput;
