import { useState } from 'react';

import { atsLabels, directoryCalendarLabels } from 'types';
import CheckboxInput from 'components/library/inputs/CheckboxInput';
import Flash from 'components/library/utils/Flash';
import Modal from 'components/library/layout/Modal';
import { useSession } from 'hooks/use-session';

import type { ChangeEvent, MouseEvent } from 'react';

interface Props {
  eventUrl: string;
  isOpen: boolean;
  onToggle: (e: MouseEvent<HTMLButtonElement>, shouldHideInFuture?: boolean) => void;
}

const DirectoryCalendarEditWarningModal = ({ eventUrl, isOpen, onToggle }: Props) => {
  const { account } = useSession();

  const [shouldHideInFuture, setShouldHideInFuture] = useState<boolean>(true);

  if (!account?.directory_type) {
    return null;
  }

  return (
    <Modal
      isOpen={isOpen}
      onSubmit={(e) => {
        analytics.track('Outbound Link Clicked', {
          href: eventUrl,
          label: `Open in ${directoryCalendarLabels[account.directory_type]}`,
        });
        window.open(eventUrl);
        onToggle(e, shouldHideInFuture);
      }}
      onToggle={onToggle}
      submitButtonColor="gem-outline"
      submitButtonValue={`Open in ${directoryCalendarLabels[account.directory_type]}`}
      title={`Open event in ${directoryCalendarLabels[account.directory_type]}?`}
    >
      <Flash
        message={(
          <>
            <p>
              If you edit this event in {directoryCalendarLabels[account.directory_type]}, the changes will not be synced to InterviewPlanner. For this reason, <b>we strongly recommend against editing the following fields externally</b>:
            </p>
            <ul>
              <li>Start and end times</li>
              <li>Interviewers</li>
              <li>Video conferencing link</li>
              <li>Location</li>
              <li>Room</li>
            </ul>
            <p>
              These should be modified in InterviewPlanner so that we can keep {atsLabels[account.ats_type!]} and {directoryCalendarLabels[account.directory_type]} in sync.
            </p>
          </>
        )}
        showFlash
        type="warning"
      />
      <br />
      <CheckboxInput
        isChecked={shouldHideInFuture}
        label="Do not show this warning again."
        onChange={(e: ChangeEvent<HTMLInputElement>) => setShouldHideInFuture(e.target.checked) }
      />
    </Modal>
  );
};

export default DirectoryCalendarEditWarningModal;
