import Moment from 'moment-timezone';

import CalendarEventTemplateSummary from 'components/library/data-display/CalendarEventTemplateSummary';
import { StyledSection } from './styles';

import type { HiringMeeting } from 'types';

interface Props {
  hiringMeeting: HiringMeeting;
}

const CalendarEventSection = ({ hiringMeeting }: Props) => {
  return (
    <StyledSection
      title="Calendar event"
    >
      <CalendarEventTemplateSummary
        applicationId={hiringMeeting.application_id}
        calendarEventTemplate={{
          title: hiringMeeting.calendar_event_title,
          description: hiringMeeting.calendar_event_description,
          location: hiringMeeting.calendar_event_location,
          additional_attendees: hiringMeeting.calendar_event_additional_attendees,
          additional_optional_attendees: hiringMeeting.calendar_event_additional_optional_attendees,
          type: 'hiring_meeting_calendar_event',
        }}
        directoryCalendarId={hiringMeeting.scheduling_calendar_email}
        directoryEventId={hiringMeeting.calendar_event_ical_uid}
        endTime={Moment.tz(hiringMeeting.start_time, hiringMeeting.timezone).add(hiringMeeting.hiring_meeting_template.duration_minutes, 'minutes').toDate()}
        hiringMeetingAttendees={(hiringMeeting.hiring_meeting_attendees || []).map(({ attendee_id, rsvp }) => ({
          id: attendee_id,
          rsvp,
        }))}
        isVideoConferencingEnabled={hiringMeeting.hiring_meeting_template.video_conferencing_enabled}
        startTime={Moment.tz(hiringMeeting.start_time, hiringMeeting.timezone).toDate()}
        timezone={hiringMeeting.timezone}
      />
    </StyledSection>
  );
};

export default CalendarEventSection;
