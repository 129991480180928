import Moment from 'moment';
import { formatISO } from 'date-fns';
import { sumBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import AvailabilityPicker from '../../../../library/inputs/AvailabilityPicker';
import Flash from '../../../../library/utils/Flash';
import MultiStepFormStep from '../../../../library/inputs/MultiStepFormStep';
import { useApplication } from '../../../../../hooks/queries/applications';
import { useCreateAvailability } from '../../../../../hooks/queries/availabilities';

import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../../../library/inputs/SelectInput/types';
import type { TimeSlot } from '../../../../library/inputs/AvailabilityPicker/types';
import { correctPath } from 'libraries/gem';

const CandidateSubmitAvailabilityForm = () => {
  const history = useHistory();

  const { id } = useParams<{ id: string }>();

  const application = useApplication(id).data!;

  const createAvailabilityMutation = useCreateAvailability();

  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>([]);
  const [timezone, setTimezone] = useState(Moment.tz.guess());

  useEffect(() => {
    if (createAvailabilityMutation.isError) {
      document.querySelector('.content-container')?.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    }
  }, [createAvailabilityMutation.isError]);

  const handleTimezoneChange = (option: OnChangeValue<Option<string>, false>) => {
    setTimezone(option ? option.value : '');
  };

  const handleSubmit = async () => {
    createAvailabilityMutation.reset();

    const payload = {
      application_id: application.id,
      manual: true,
      timezone,
      time_slots: timeSlots.map(({ start_time, end_time }) => ({
        start_time: start_time instanceof Date ? formatISO(start_time) : start_time,
        end_time: end_time instanceof Date ? formatISO(end_time) : end_time,
      })),
    };

    try {
      const data = await createAvailabilityMutation.mutateAsync({ payload });
      history.push(correctPath(`/app/candidates/${id}/availabilities/${data.id}`));
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  const availabilityTemplate = application.current_stage?.availability_template;
  const scheduleTemplate = application.current_stage?.schedule_template;

  const sumOfInterviewDurations = sumBy(application.current_stage?.stage_interviews, ({ interview_template }) => interview_template ? interview_template.duration_minutes : 0);

  return (
    <MultiStepFormStep
      isFirstStep
      isLastStep
      isSubmitting={createAvailabilityMutation.isLoading}
      nextButtonValue="Submit Availability"
      onNext={handleSubmit}
    >
      <Flash
        message="Fill in the candidate's availabilities."
        showFlash
        type="info"
      />
      <Flash
        message={createAvailabilityMutation.error?.message}
        showFlash={createAvailabilityMutation.isError}
        type="danger"
      />
      <AvailabilityPicker
        availabilities={timeSlots}
        businessHours={availabilityTemplate?.business_hours || scheduleTemplate?.business_hours || []}
        controlledTimezone={timezone}
        minDuration={sumOfInterviewDurations}
        onTimezoneChange={handleTimezoneChange}
        setAvailabilities={setTimeSlots}
        showEventWarnings={false}
      />
    </MultiStepFormStep>
  );
};

export default CandidateSubmitAvailabilityForm;
