import styled, { keyframes } from 'styled-components';

import Button from 'components/library/inputs/Button';

const pulseAnimation = keyframes`
  0% {
    background: rgba(0, 0, 0, .5);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, .5);
  }

  70% {
    background: rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
  }

  100% {
    background: rgba(0, 0, 0, 0);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
  }
`;

export const StyledButton = styled(Button)`
  border-radius: 50%;

  &.btn {
    .icon-right {
      margin: 0;
    }
  }

  &.playing {
    animation: ${pulseAnimation} 1s infinite;
    background: rgba(0, 0, 0, .5);
    box-shadow: 0 0 0 0 rgba(0, 0, 0, .7);
  }
`;
