import styled from 'styled-components';

import Table from 'components/library/data-display/Table';

export const StyledTimesTable = styled(Table)`
  .row-expanded-content {
    background: ${({ theme }) => theme.colors.PRODUCT.TERTIARY_BACKGROUND};

    td {
      padding: 16px;
    }
  }
` as typeof Table;

export const StyledSlotsTable = styled(Table)`
  background: white;

  td:first-child {
    width: 180px;
  }
` as typeof Table;

export const StyledSlotTimeHeader = styled.span`
  font-weight: 500;
`;

export const StyledSlotEmptyText = styled.span`
  color: ${({ theme }) => theme.colors.PRODUCT.TERTIARY_CONTENT};
  font-style: italic;
`;
