import Moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUnlink } from '@fortawesome/free-solid-svg-icons';
import { orderBy, pick } from 'lodash';
import { useEffect, useMemo, useState } from 'react';

import Button from '../../library/inputs/Button';
import CheckboxInput from '../../library/inputs/CheckboxInput';
import Flash from '../../library/utils/Flash';
import Modal from '../../library/layout/Modal';
import Tooltip from '../../library/utils/Tooltip';
import { formatMoment, TimeFormat } from '../../../libraries/time';
import { useDeleteSelfSchedulingLink } from '../../../hooks/queries/self-scheduling-links';

import type { Application } from '../../../types';
import type { ChangeEvent } from 'react';

interface Props {
  application: Application;
  onSuccess: (selfScheduleLinkIds: string[]) => void;
}

const CancelSelfSchedulingLinkButton = ({ application, onSuccess }: Props) => {
  const [showModal, setShowModal] = useState(false);
  const [error, setError] = useState('');

  const deleteSelfSchedulingLinkMutation = useDeleteSelfSchedulingLink();

  const selfSchedulingLinks = useMemo(() => {
    const links = (application.all_self_scheduling_links || [])
    .filter((link) => link.status === 'requested');
    return orderBy(links, ['created_at']);
  }, [application.all_self_scheduling_links]);

  const [selfSchedulingLinksToCancel, setSelfSchedulingLinksToCancel] = useState<Record<string, boolean>>(selfSchedulingLinks.reduce((acc, schedule) => ({
    ...acc,
    // If there is more than one link that can be cancelled, have none of them
    // should be checked from the beginning. If there is only one, then check
    // it.
    [schedule.id]: selfSchedulingLinks.length === 1,
  }), {}));

  useEffect(() => {
    // If the self-scheduling links change, we need to update
    // selfSchedulingLinksToCancel. If we now only have one link, set it to be
    // cancelled (since we don't show the checkboxes for one schedule). If we
    // have more than one, we need to remove any IDs for links that are no
    // longer valid (maybe because they were already cancelled). By doing it
    // this way with pick, it preserves any existing toggles in
    // selfSchedulingLinksToCancel.
    if (selfSchedulingLinks.length === 1) {
      setSelfSchedulingLinksToCancel({ [selfSchedulingLinks[0].id]: true });
    } else {
      setSelfSchedulingLinksToCancel((prev) => pick(prev, selfSchedulingLinks.map(({ id }) => id)));
    }
  }, [selfSchedulingLinks]);

  const toggleModal = () => {
    if (error) {
      setError('');
    }
    setShowModal(!showModal);
  };

  const handleSelfSchedulingLinksToCancelChange = (e: ChangeEvent<HTMLInputElement>) => {
    setSelfSchedulingLinksToCancel((prev) => ({
      ...prev,
      [e.target.name]: e.target.checked,
    }));
  };

  const handleCancelSelfSchedulingLink = async () => {
    deleteSelfSchedulingLinkMutation.reset();

    const selfSchedulingLinkIds = Object.keys(selfSchedulingLinksToCancel)
    .filter((id) => selfSchedulingLinksToCancel[id]);

    const responses = await Promise.all(selfSchedulingLinkIds.map(async (selfSchedulingLinkId) => {
      try {
        await deleteSelfSchedulingLinkMutation.mutateAsync({ id: selfSchedulingLinkId });
      } catch (error) {
        // I don't know a better way to surface all potential errors.
        if (error instanceof Error) {
          return error;
        }
      }
    }));

    const errors = responses.filter((e): e is Error => Boolean(e));
    if (errors.length > 0) {
      setError(errors.map(({ message }) => message).join(', '));
    } else {
      onSuccess(selfSchedulingLinkIds);
      setShowModal(false);
    }
  };

  if (selfSchedulingLinks.length === 0) {
    return null;
  }

  return (
    <>
      <Button
        className="btn-delete"
        color="gray"
        iconRight={<FontAwesomeIcon icon={faUnlink} />}
        onClick={toggleModal}
        size="large"
        tooltip={
          <Tooltip
            id={`${application.id}-cancel-self-scheduling-link-button`}
            position="top"
            value={`Cancel self-scheduling request${selfSchedulingLinks.length === 1 ? '' : 's'}`}
          />
        }
      />
      <Modal
        cancelButtonValue={`No, keep ${selfSchedulingLinks.length === 1 ? 'this self-scheduling request' : 'these self-scheduling requests'}`}
        isOpen={showModal}
        isSubmitting={deleteSelfSchedulingLinkMutation.isLoading}
        onSubmit={handleCancelSelfSchedulingLink}
        onToggle={toggleModal}
        showSubmitButton={Object.values(selfSchedulingLinksToCancel).filter(Boolean).length > 0}
        submitButtonValue={`Yes, cancel ${selfSchedulingLinks.length === 1 ? 'this request' : 'these requests'}`}
        submittingButtonValue="Cancelling..."
        title={`Cancel self-scheduling request${selfSchedulingLinks.length === 1 ? '' : 's'}?`}
      >
        <Flash
          message={error}
          showFlash={Boolean(error)}
          type="danger"
        />
        {selfSchedulingLinks.length === 1 ? (
          <p>
            You are cancelling the outstanding <b>{selfSchedulingLinks[0].stage.name}</b> request for <b>{application.candidate.name}</b>.
          </p>
        ) : (
          <p>
            You are cancelling the outstanding{selfSchedulingLinks.every((schedule) => schedule.stage.id === selfSchedulingLinks[0].stage.id) ? <>&nbsp;<b>{selfSchedulingLinks[0].stage.name}</b></> : ''} requests for <b>{application.candidate.name}</b>.
          </p>
        )}
        {selfSchedulingLinks.length > 1 && (
          <div className="modal-checkbox-options">
            {selfSchedulingLinks.map((selfSchedulingLink) => (
              <CheckboxInput
                isChecked={selfSchedulingLinksToCancel[selfSchedulingLink.id]}
                key={selfSchedulingLink.id}
                label={<span>Cancel self-scheduling request created on <b>{formatMoment(Moment.tz(selfSchedulingLink.created_at, Moment.tz.guess()), TimeFormat.LongDayOfWeekMonthDayAtTimeAndTimezone)}</b></span>}
                name={selfSchedulingLink.id}
                onChange={handleSelfSchedulingLinksToCancelChange}
              />
            ))}
          </div>
        )}
        <p>
          The link will no longer be valid for the candidate to use to schedule their interview. You can always recreate and send a new link to the candidate at a later time, or you can schedule the candidate yourself.
        </p>
      </Modal>
    </>
  );
};

export default CancelSelfSchedulingLinkButton;
