import { Breadcrumb } from 'react-breadcrumbs';
import { faUserTie } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Route, Switch } from 'react-router-dom';

import Candidate from './Candidate';
import CandidateATSRedirect from './CandidateATSRedirect';
import CandidateList from './CandidateList';
import { correctPath } from 'libraries/gem';

const Candidates = () =>
  <Breadcrumb
    data={{
      title: <><FontAwesomeIcon icon={faUserTie} />Candidates</>,
      pathname: correctPath('/app/candidates'),
    }}
  >
    <Switch>
      <Route component={CandidateList} exact path={correctPath('/app/candidates')} />
      <Route component={CandidateATSRedirect} exact path={correctPath('/app/candidates/ats')} />
      <Route component={Candidate} path={correctPath('/app/candidates/:id')} />
    </Switch>
  </Breadcrumb>
;

export default Candidates;
