import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDoorOpen, faGraduationCap, faPeopleGroup } from '@fortawesome/free-solid-svg-icons';
import { startCase } from 'lodash';
import { useMemo } from 'react';

import Avatar from './Avatar';
import ListItem from './ListItem';
import { useRoomsMap } from '../../../hooks/queries/rooms';
import { useSession } from '../../../hooks/use-session';
import { useUsersMap } from '../../../hooks/queries/users';
import { videoConferencingLogos, VideoConferencingTool } from '../../../types';

import type { ReactNode } from 'react';

export enum TagType {
  Eligibility = 'eligibility',
  HiringMeetingInterviewers = 'hiring_meeting_interviewers',
  HiringTeam = 'hiring_team',
  InterviewerPosition = 'interviewer_position',
  Role = 'role',
  Room = 'room',
  Tag = 'tag',
  Training = 'training',
  User = 'user',
}

const interviewerPositionMapping: Record<string, string> = {
  1: 'First Interviewer',
};

interface Props {
  annotateCurrentUser?: boolean;
  className?: string;
  isNegated?: boolean;
  isZoomUser?: boolean;
  rightIcon?: ReactNode;
  type: `${TagType}`;
  value: string;
  // Only required for eligibilities/trainings.
  hasTrainingProgram?: boolean;
  isTrainee?: boolean;
}

const Tag = ({
  annotateCurrentUser = false,
  className,
  hasTrainingProgram,
  isNegated = false,
  isTrainee,
  isZoomUser = false,
  rightIcon,
  type,
  value,
}: Props) => {
  const { currentUser } = useSession();
  const rooms = useRoomsMap({}, {
    enabled: type === 'room',
  });
  const room = useMemo(() => rooms[value], [rooms, value]);
  const users = useUsersMap({ archived: true }, {
    enabled: type === 'user',
  });
  const user = useMemo(() => users[value], [users, value]);
  const isEligibilityOrTraining = type === TagType.Eligibility || type === TagType.Training;
  const isHiringMeetingInterviewers = type === TagType.HiringMeetingInterviewers;

  const formattedValue = type === TagType.HiringTeam || type === TagType.Role ? startCase(value) : type === TagType.InterviewerPosition ? interviewerPositionMapping[value] : value;
  const isCurrentUser = user && currentUser?.id === user.id;

  const negatedSpan = isNegated && <span className="negated">not&nbsp;</span>;
  const currentUserSpan = annotateCurrentUser && isCurrentUser && <span className="current-user-annotation">&nbsp;(You)</span>;

  return (
    <div
      className={classnames(
        'tag',
        `tag-${type}`,
        isNegated && 'tag-negated',
        className,
      )}
    >
      {!room && !user && !isEligibilityOrTraining && !isHiringMeetingInterviewers && negatedSpan}
      {room &&
        <ListItem
          label={<span>{negatedSpan}{room.name}</span>}
          leftIcon={<FontAwesomeIcon icon={faDoorOpen} />}
          rightIcon={isZoomUser ? (
            <Avatar
              alt="Zoom room"
              img={videoConferencingLogos[VideoConferencingTool.Zoom]}
              size="small"
            />
          ) : rightIcon}
        />
      }
      {user &&
        <ListItem
          label={<span>{negatedSpan}{user.name || user.email}{currentUserSpan}</span>}
          leftIcon={<Avatar
            showUserTooltip={false}
            size="small"
            type="user"
            userId={formattedValue}
          />}
          rightIcon={isZoomUser ? (
            <Avatar
              alt="Zoom user"
              img={videoConferencingLogos[VideoConferencingTool.Zoom]}
              size="small"
            />
          ) : rightIcon}
        />
      }
      {isEligibilityOrTraining &&
        <ListItem
          label={<span>{negatedSpan}{value}{isTrainee ? <> <b>Trainee</b></> : ''}</span>}
          leftIcon={hasTrainingProgram ? <FontAwesomeIcon icon={faGraduationCap} /> : undefined}
        />
      }
      {isHiringMeetingInterviewers && !user &&
        <ListItem
          label={<span>{negatedSpan}Interviewers for <b>{value}</b></span>}
          leftIcon={<FontAwesomeIcon icon={faPeopleGroup} />}
        />
      }
      {!room && !user && !isEligibilityOrTraining && !isHiringMeetingInterviewers && formattedValue}
    </div>
  );
};

export default Tag;
