import { createContext, useContext, useMemo } from 'react';

import type { ReactNode } from 'react';

export interface DayTooltipText {
  available?: string;
}

interface DatePickerContextState {
  brandColor?: string;
  dayTooltipText?: DayTooltipText;
  fromMonth?: Date;
}

const DatePickerContext = createContext<DatePickerContextState>({});

interface Props {
  brandColor?: string;
  dayTooltipText?: DayTooltipText;
  children: ReactNode;
  fromMonth?: Date;
}

export const DatePickerProvider = ({ brandColor, dayTooltipText, children, fromMonth }: Props) => {
  const contextValue = useMemo<DatePickerContextState>(() => ({
    brandColor,
    dayTooltipText,
    fromMonth,
  }), [brandColor, dayTooltipText, fromMonth]);

  return (
    <DatePickerContext.Provider value={contextValue}>
      {children}
    </DatePickerContext.Provider>
  );
};

export const useDatePickerContext = () => {
  return useContext(DatePickerContext);
};
