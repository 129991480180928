import type { GroupProps } from 'react-select';
import type { Group, Option, Value } from './types';

export const GroupHeading = <V extends Value, O extends Option<V>, M extends boolean, G extends Group<V, O>>({
  Heading,
  className,
  cx,
  getStyles,
  headingProps,
  label,
  selectProps,
  theme,
}: GroupProps<O, M, G>) => (
    <Heading
      {...headingProps}
      className={className}
      cx={cx}
      getStyles={getStyles}
      selectProps={selectProps}
      theme={theme}
    >
      {label}
    </Heading>
  );
