import { Link } from 'react-router-dom';

import Flash from 'components/library/utils/Flash';
import { StyledModal } from './styles';
import { useAccount } from 'hooks/queries/accounts';
import { correctPath } from 'libraries/gem';

interface Props {
  isOpen: boolean;
  onToggle: () => void;
}

const SetUpIntegrationsModal = ({ isOpen, onToggle }: Props) => {
  const { refetch } = useAccount();

  return (
    <StyledModal
      isOpen={isOpen}
      onSubmit={() => {
        refetch();
        onToggle();
      }}
      onToggle={onToggle}
      submitButtonValue="I'm finished"
      title="Connect video conferencing and other integrations?"
    >
      <Flash
        message={<>
          <p>
            <Link rel="noopener noreferrer" target="_blank" to={correctPath('/app/integrations')}>Click here to open the Integrations page in a separate tab</Link> and connect your tools.
          </p>
          <p>
            You must connect these in Gem Scheduling even if you have separately connected them in Gem.
          </p>
          <p>
            When you are done, come back to this tab and click <b>I&apos;m finished</b>.
          </p>
        </>}
        showFlash
        type="info"
      />
    </StyledModal>
  );
};

export default SetUpIntegrationsModal;
