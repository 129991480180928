import { find, startCase } from 'lodash';

import SelectInput from '../SelectInput';
import Tag from '../../data-display/Tag';
import { generateOptions } from './helpers';

import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../SelectInput/types';

const options = generateOptions('Whoever sends the email in InterviewPlanner');

enum RecipientType {
  CC = 'cc',
  BCC = 'bcc',
}

interface Props {
  emails?: string[];
  isDisabled: boolean;
  recipientType: `${RecipientType}`;
  setEmails: (emails: string[]) => void;
}

const RecipientsInput = ({
  emails,
  isDisabled,
  recipientType,
  setEmails,
}: Props) => {
  const handleEmailsChange = (options: OnChangeValue<Option<string>, true>) => {
    setEmails(options ? options.map(({ value }) => value) : []);
  };

  return (
    <SelectInput
      formatCreateLabel={(value) => `Use email address "${value}"`}
      formatOptionLabel={(option) => (
        find(options, ['value', option.value]) && option.value !== 'scheduler' ?
          <Tag type="hiring_team" value={option.value} /> :
          option.label
      )}
      isClearable
      isCreatable
      isDisabled={isDisabled}
      isMulti
      label={startCase(recipientType)}
      noOptionsMessage={() => null}
      onChange={handleEmailsChange}
      options={options}
      placeholder="Select hiring team members or type emails"
      value={emails ? emails.map((email) => find(options, ['value', email]) || { value: email, label: email }) : undefined}
    />
  );
};

export default RecipientsInput;
