import styled from 'styled-components';

import CheckboxInput from '../../library/inputs/CheckboxInput';
import Section from '../../library/layout/Section';
import TextInput from '../../library/inputs/TextInput';

export const StyledWrapper = styled.div`
  max-width: 1400px;
  width: 100%;
`;

export const StyledSection = styled(Section)`
  box-sizing: border-box;
  width: 100%;

  @media only screen and (max-width: 750px) {
    padding: 1em .5em;
  }
`;

export const StyledOptionalText = styled.span`
  color: ${({ theme }) => theme.colors.doveGray || theme.colors.PRODUCT.TERTIARY_CONTENT};
`;

export const StyledPronounsContainer = styled.div`
  max-width: 600px;
`;

export const StyledTable = styled.table`
  border-spacing: 0;
  margin-top: 1em;
  width: 100%;
`;

export const StyledCheckboxInput = styled(CheckboxInput)`
  margin: 1em 0;

  .input-label {
    display: flex;
    flex-direction: column;
    gap: .5em;
  }
`;

export const StyledHr = styled.hr`
  border: none;
  border-bottom: 1px solid ${({ theme }) => theme.colors.mercuryGray || theme.colors.PRODUCT.SECONDARY_BORDER};
`;

export const StyledPronunciationContainer = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  gap: 1em;
  margin: 2em 0;

  @media only screen and (max-width: 750px) {
    align-items: flex-start;
    flex-direction: column;
  }
`;

export const StyledTextInput = styled(TextInput)`
  margin-top: 1em;
`;
