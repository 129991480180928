import styled from 'styled-components';

export const StyledDivider = styled.hr`
  border: .5px solid ${({ theme }) => theme.colors.mercuryGray};
  border-right-width: 0;
  border-left-width: 0;
  width: 100%;
  max-width: 1100px;
`;

export const StyledPageContainer = styled.div`
  padding-top: 2em;
  width: 100%;

  section {
    gap: 60px;

    img {
      max-width: 400px;
      width: 100%;
    }

    @media only screen and (max-width: 800px) {
      gap: 0;
    }
  }
`;

export const StyledTitleSection = styled.section`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 0 !important;
  justify-content: center;
  padding: 60px 0;
  text-align: center;

  h1 {
    margin: 15px;
    max-width: 90%;
  }

  p {
    color: ${({ theme }) => theme.colors.doveGray};
    font-size: 1.15em;
    line-height: 2;
    width: 660px;
    max-width: 90%;
  }

  @media only screen and (max-width: 930px) {
    padding-top: 20px;
  }
`;

export const StyledRequestDemoSection = styled.section`
  align-items: center;
  background: ${({ theme }) => theme.colors.alabasterWhite};
  display: flex;
  flex-direction: column;
  gap: 0 !important;
  justify-content: center;
  margin-top: 60px;
  padding: 60px 0;
  text-align: center;

  h1 {
    color: ${({ theme }) => theme.colors.zodiacBlue};
    margin: 15px;
    max-width: 90%;
  }

  p {
    font-size: 18px;
  }

  form {
    margin-top: 1em;
    max-width: 90%;
  }
`;
