import Moment from 'moment';
import { Breadcrumb } from 'react-breadcrumbs';
import { NavLink, Redirect, Route, Switch, useParams } from 'react-router-dom';
import { useMediaQuery } from 'react-responsive';

import ArchiveButton from '../../../library/inputs/ArchiveButton';
import Flash from '../../../library/utils/Flash';
import JobDetails from './JobDetails';
import JobStages from './JobStages';
import JobStatusIndicator from '../../../library/utils/JobStatusIndicator';
import Label from '../../../library/utils/Label';
import OpenInATSButton from '../../../library/inputs/OpenInATSButton';
import PageHeader from '../../../library/layout/PageHeader';
import { FavoriteResourceType } from '../../../../types';
import { formatMoment, TimeFormat } from '../../../../libraries/time';
import { useJob, useUpdateJob } from '../../../../hooks/queries/jobs';
import { correctPath } from 'libraries/gem';

const Job = () => {
  const { id } = useParams<{ id: string }>();

  const {
    data: job,
    error: jobError,
    isLoading: isJobLoading,
  } = useJob(id);

  const updateJobMutation = useUpdateJob();

  const isSmallScreen = useMediaQuery({ query: '(max-width: 1000px)' });

  const handleArchive = () => {
    updateJobMutation.reset();
    updateJobMutation.mutate({ id, payload: { archived: true } });
  };

  const handleUnarchive = () => {
    updateJobMutation.reset();
    updateJobMutation.mutate({ id, payload: { archived: false } });
  };

  if (isJobLoading) {
    return null;
  }

  if (jobError) {
    return (
      <Breadcrumb
        data={{
          title: id,
          pathname: correctPath(`/app/jobs/${id}`),
        }}
      >
        <div className="job-container">
          <Flash
            message={jobError.message}
            showFlash
            type="danger"
          />
        </div>
      </Breadcrumb>
    );
  }

  if (!job) {
    // If we're not loading and there's no error, there should be a job, but
    // this is just for th typings.
    return null;
  }

  const schedulableStages = (job.stages || []).filter(({ schedule_template_id }) => Boolean(schedule_template_id)).length;

  return (
    <Breadcrumb
      data={{
        title: job.name,
        pathname: correctPath(`/app/jobs/${id}`),
      }}
    >
      <div className="job-container">
        <Flash
          isDismissible
          message={updateJobMutation.error?.message}
          onDismiss={updateJobMutation.reset}
          showFlash={updateJobMutation.isError}
          type="danger"
        />
        <Flash
          isDismissible
          message={`Job ${job.archived ? 'archived' : 'unarchived'}.`}
          onDismiss={updateJobMutation.reset}
          showFlash={updateJobMutation.isSuccess}
          type="success"
        />
        <PageHeader
          details={
            <>
              <JobStatusIndicator status={job.status} />
              {!isSmallScreen && <span className="separator">|</span>}
              {!isSmallScreen && <span>Created {formatMoment(Moment(job.ats_created_at), TimeFormat.LongMonthDayYear)}</span>}
              <span className="separator">|</span>
              <NavLink to={correctPath(`/app/candidates?job=${id}`)}>{job.schedulable_candidates_count || 0} candidate{job.schedulable_candidates_count !== 1 ? 's' : ''}</NavLink> in schedulable stages
            </>
          }
          favorite={job.favorite}
          navbarLinks={[{
            label: 'Stages',
            location: correctPath(`/app/jobs/${job.id}/stages`),
          }, {
            label: 'Details',
            location: correctPath(`/app/jobs/${job.id}/details`),
          }]}
          resourceId={job.id}
          resourceType={FavoriteResourceType.Job}
          title={job.name}
          titleIcon={(
            <>
              {Moment(job.ats_created_at).isAfter(Moment().add(-1, 'week')) && schedulableStages === 0 ? <Label color="green">New</Label> : undefined}
              {job.archived ? <Label color="gray">Archived</Label> : undefined}
            </>
          )}
        >
          <OpenInATSButton
            atsIds={{
              jobAtsId: job.ats_id,
            }}
            type="job"
          />
          <ArchiveButton
            id="archive-button"
            isArchived={job.archived}
            onArchive={handleArchive}
            onUnarchive={handleUnarchive}
          />
        </PageHeader>
        <Switch>
          <Redirect exact from={correctPath('/app/jobs/:id')} to={correctPath('/app/jobs/:id/stages')} />
          <Route component={JobDetails} path={correctPath('/app/jobs/:id/details')} />
          <Route component={JobStages} path={correctPath('/app/jobs/:id/stages')} />
        </Switch>
      </div>
    </Breadcrumb>
  );
};

export default Job;
