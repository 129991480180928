import Moment from 'moment';
import { formatISO } from 'date-fns';
import { sumBy } from 'lodash';
import { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';

import AvailabilityPicker from '../../../../../library/inputs/AvailabilityPicker';
import Button from '../../../../../library/inputs/Button';
import Flash from '../../../../../library/utils/Flash';
import LoadingSpinner from '../../../../../library/utils/LoadingSpinner';
import Section from '../../../../../library/layout/Section';
import { BusinessHourReferenceType } from '../../../../../../types/business-hours';
import { defaultBusinessHours } from '../../../../../../libraries/business-hours';
import { useApplication } from '../../../../../../hooks/queries/applications';
import { useAvailability, useCreateAvailability, useUpdateAvailability } from '../../../../../../hooks/queries/availabilities';

import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../../../../library/inputs/SelectInput/types';
import type { TimeSlot } from '../../../../../library/inputs/AvailabilityPicker/types';
import type { UpdateAvailabilityPayload } from '../../../../../../hooks/queries/availabilities';
import { correctPath } from 'libraries/gem';

const TimesSection = () => {
  const history = useHistory();

  const { id, availabilityId } = useParams<{ id: string; availabilityId: string }>();

  const { data: application } = useApplication(id);
  const { data: availability } = useAvailability(availabilityId);

  const updateAvailabilityMutation = useUpdateAvailability();
  const createAvailabilityMutation = useCreateAvailability();

  const [isEditing, setIsEditing] = useState(false);
  const [timezone, setTimezone] = useState(Moment.tz.guess());
  const [timeSlots, setTimeSlots] = useState<TimeSlot[]>(availability?.availability_time_slots || []);

  useEffect(() => {
    if (availability?.stage) {
      setTimezone(
        availability.availability_template?.business_hours?.find((bh) => bh.timezone)?.timezone ||
        availability.stage.schedule_template?.business_hours?.find((bh) => bh.timezone)?.timezone ||
        Moment.tz.guess()
      );
    }
  }, [availabilityId, Boolean(availability?.stage)]);

  useEffect(() => {
    setTimeSlots(availability?.availability_time_slots || []);
  }, [availability?.availability_time_slots]);

  const handleTimezoneChange = (option: OnChangeValue<Option<string>, false>) => {
    setTimezone(option?.value || Moment.tz.guess());
  };

  const handleEdit = () => {
    setIsEditing(true);
    createAvailabilityMutation.reset();
  };

  const handleCancel = () => {
    setTimeSlots(availability?.availability_time_slots || []);
    setIsEditing(false);
    updateAvailabilityMutation.reset();
  };

  const handleSave = async () => {
    updateAvailabilityMutation.reset();

    const payload: UpdateAvailabilityPayload = {
      timezone: availability?.availability_time_slots && availability.availability_time_slots.length > 0 ? availability.availability_time_slots[0].timezone : timezone,
      time_slots: timeSlots.map(({ id, start_time, end_time }) => ({
        id,
        start_time: start_time instanceof Date ? formatISO(start_time) : start_time,
        end_time: end_time instanceof Date ? formatISO(end_time) : end_time,
      })),
    };

    try {
      const data = await updateAvailabilityMutation.mutateAsync({ id: availabilityId, payload });
      setTimeSlots(data.availability_time_slots || []);
      setIsEditing(false);
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  const handleCopy = async () => {
    createAvailabilityMutation.reset();

    const payload = {
      application_id: application!.id,
      manual: true,
      allow_past_times: true,
      timezone: availability?.availability_time_slots && availability.availability_time_slots.length > 0 ? availability.availability_time_slots[0].timezone : timezone,
      time_slots: availability?.availability_time_slots?.map(({ start_time, end_time }) => ({
        start_time,
        end_time,
      })),
    };

    try {
      const data = await createAvailabilityMutation.mutateAsync({ payload });
      history.push(correctPath(`/app/candidates/${id}/availabilities/${data.id}`));
    } catch (_) {
      // Since React Query catches the error and attaches it to the mutation, we
      // don't need to do anything with this error besides prevent it from
      // bubbling up.
    }
  };

  const isEditable = Boolean(availability?.manual || !Boolean(availability?.ats_id));

  const sumOfInterviewDurations = sumBy(application?.current_stage?.stage_interviews || [], ({ interview_template }) => interview_template ? interview_template.duration_minutes : 0);

  return (
    <Section
      additionalHeaderActions={!isEditing && application?.current_stage_id !== availability?.stage_id && (
        <Button
          color="gem-outline"
          isDisabled={createAvailabilityMutation.isLoading}
          onClick={handleCopy}
          size="small"
          value="Copy to current stage"
        />
      )}
      additionalHeaderActionsIndex={0}
      className="times-section"
      isEditable={isEditable}
      isEditing={isEditing}
      isSaving={updateAvailabilityMutation.isLoading}
      onCancel={handleCancel}
      onEdit={handleEdit}
      onSave={handleSave}
      title="Available times"
    >
      <Flash
        message={updateAvailabilityMutation.error?.message}
        showFlash={updateAvailabilityMutation.isError}
        type="danger"
      />
      <Flash
        message={createAvailabilityMutation.error?.message}
        showFlash={createAvailabilityMutation.isError}
        type="danger"
      />
      <Flash
        isDismissible
        message="Successfully updated!"
        onDismiss={updateAvailabilityMutation.reset}
        showFlash={updateAvailabilityMutation.isSuccess}
        type="success"
      />
      {availability?.stage ?
        ((availability.availability_time_slots || isEditing) ?
          <AvailabilityPicker
            allowPastTimes={Boolean(availability.availability_time_slots)}
            availabilities={timeSlots || []}
            businessHours={availability.availability_template?.business_hours || availability.stage.schedule_template?.business_hours || defaultBusinessHours(BusinessHourReferenceType.AvailabilityTemplate, timezone)}
            controlledTimezone={timezone}
            isDraggable={isEditing}
            minDuration={sumOfInterviewDurations}
            onTimezoneChange={handleTimezoneChange}
            setAvailabilities={isEditing ? setTimeSlots : undefined}
            showEventWarnings={false}
          /> :
          <div className="no-availability">No availability received</div>
        ) :
        <LoadingSpinner />
      }
    </Section>
  );
};

export default TimesSection;
