import styled from 'styled-components';

import CheckboxInput from 'components/library/inputs/CheckboxInput';

export const StyledCheckboxInput = styled(CheckboxInput)`
  margin-bottom: 1em;

  &:first-child { margin-top: .5em; }
  &:last-child { margin-bottom: .5em; }
`;

export const StyledKeepInterviewActionInput = styled.div`
  display: flex;
  align-items: flex-start;
  gap: .5em;
`;
