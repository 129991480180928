import { Breadcrumb } from 'react-breadcrumbs';
import { Link, Redirect, Route, Switch, useParams } from 'react-router-dom';
import { isEmpty } from 'lodash';
import { useEffect, useState } from 'react';
import { useQueryClient } from 'react-query';

import EmailSettingsWarningModal from '../EmailSettingsWarningModal';
import { EmailTemplateType } from 'types';
import EventsAndEmailsStep from './EventsAndEmailsStep';
import Flash from '../../../../library/utils/Flash';
import LoadingSpinner from '../../../../library/utils/LoadingSpinner';
import PreferencesStep from './PreferencesStep';
import ReviewStep from './ReviewStep';
import Section from '../../../../library/layout/Section';
import SendLinkStep from './SendLinkStep';
import StepProgressBar from '../../../../library/navigation/StepProgressBar';
import { AtsHrefType, constructAtsHref } from '../../../../../libraries/candidates';
import { NewSelfSchedulingLinkProvider, Step, useNewSelfSchedulingLink } from './use-new-self-scheduling-link';
import { createEmptyZoomHostFilter } from '../../../../../libraries/zoom-hosts';
import { defaultValuesForStageInterview } from './helpers';
import { useApplication } from '../../../../../hooks/queries/applications';
import { useSession } from '../../../../../hooks/use-session';
import { useUsersMap } from '../../../../../hooks/queries/users';

import type { ReactNode } from 'react';
import { correctPath } from 'libraries/gem';
import OutboundLink from 'components/library/navigation/OutboundLink';

// To enable the inner component to access the context value, it needs to be nested
// under the provider, so we need this wrapper component to add that nesting.
const CandidateRequestSelfScheduling = () => {
  return (
    <NewSelfSchedulingLinkProvider>
      <CandidateRequestSelfSchedulingInner />
    </NewSelfSchedulingLinkProvider>
  );
};

export default CandidateRequestSelfScheduling;

const CandidateRequestSelfSchedulingInner = () => {
  const queryClient = useQueryClient();

  const { id } = useParams<{ id: string }>();

  const { account, currentUser } = useSession();
  const users = useUsersMap({ archived: true });

  const {
    data: application,
    isLoading,
  } = useApplication(id);

  const [isError, setIsError] = useState(false);

  const {
    completedStep,
    selfSchedulingLink,
    setSelfSchedulingLink,
  } = useNewSelfSchedulingLink();

  useEffect(() => {
    if (!isLoading && !selfSchedulingLink.application_id && !isEmpty(users)) {
      if (!application?.current_stage?.stage_interviews) {
        setIsError(true);
        return;
      }
      (async () => {
        // Find the first non-deleted, ATS-schedulable stage interview that has an interview
        // template or info imported from the ATS. If none of them match that
        // description, just pick the first stage interview.
        const eligibleStageInterviews = application.current_stage?.stage_interviews?.filter(({ ats_schedulable, deleted }) => !deleted && (ats_schedulable === null || ats_schedulable)) || [];
        let stageInterview = eligibleStageInterviews.find((interview) => {
          return Boolean(interview.interview_template || (interview.ats_interviewer_ids && interview.ats_interviewer_ids.length > 0) || interview.ats_duration_minutes);
        });
        if (!stageInterview) {
          stageInterview = eligibleStageInterviews[0];
        }
        const {
          businessHours,
          duration,
          feedbackFormId,
          interviewerTemplates,
          liveCodingEnabled,
          candidateFacingName,
          candidateFacingDetails,
        } = await defaultValuesForStageInterview(queryClient, account!, application, users, stageInterview);

        setSelfSchedulingLink({
          application_id: application.id,
          scheduling_calendar_email: application.current_stage?.schedule_template?.scheduling_calendar_email || account!.scheduling_calendar_email!,
          candidate_scheduling_calendar_email: application.current_stage?.schedule_template?.candidate_scheduling_calendar_email || account!.candidate_scheduling_calendar_email,
          schedule_template: {
            candidate_calendar_event_template: {
              id: application.current_stage?.schedule_template?.candidate_calendar_event_template?.id,
              title: application.current_stage?.schedule_template?.candidate_calendar_event_template?.title || '',
              description: application.current_stage?.schedule_template?.candidate_calendar_event_template?.description || '',
              location: application.current_stage?.schedule_template?.candidate_calendar_event_template?.location,
              additional_attendees: application.current_stage?.schedule_template?.candidate_calendar_event_template?.additional_attendees,
              additional_optional_attendees: application.current_stage?.schedule_template?.candidate_calendar_event_template?.additional_optional_attendees,
            },
            business_hours: businessHours,
            allowed_times: [{ start_time: '', end_time: '' }],
            scheduling_interval_minutes: application.current_stage?.schedule_template?.scheduling_interval_minutes || 15,
            onsite: application.current_stage?.schedule_template?.onsite || false,
            mark_interviewer_events_as_private: application.current_stage?.schedule_template?.mark_interviewer_events_as_private || false,
            mark_candidate_events_as_private: application.current_stage?.schedule_template?.mark_candidate_events_as_private || false,
            video_conferencing_enabled: application.current_stage?.schedule_template?.video_conferencing_enabled || false,
            zoom_host_filters: application.current_stage?.schedule_template?.zoom_host_filters || createEmptyZoomHostFilter(),
            create_hiring_channel: application.current_stage?.schedule_template?.create_hiring_channel || false,
            confirmation_email_template: application.current_stage?.schedule_template?.confirmation_email_template && {
              id: application.current_stage?.schedule_template?.confirmation_email_template.id,
              name: application.current_stage?.schedule_template?.confirmation_email_template.name,
              subject: application.current_stage?.schedule_template?.confirmation_email_template.subject,
              sender_name: application.current_stage?.schedule_template?.confirmation_email_template.sender_name,
              sender_email: application.current_stage?.schedule_template?.confirmation_email_template.sender_email,
              cc_emails: application.current_stage?.schedule_template?.confirmation_email_template.cc_emails,
              bcc_emails: application.current_stage?.schedule_template?.confirmation_email_template.bcc_emails,
              body: application.current_stage?.schedule_template?.confirmation_email_template.body,
              attachments: application.current_stage?.schedule_template?.confirmation_email_template.attachments,
            },
            self_scheduling_advanced_notice_hours: application.current_stage?.schedule_template?.self_scheduling_advanced_notice_hours || 24,
            self_scheduling_reschedule_enabled: application.current_stage?.schedule_template?.self_scheduling_reschedule_enabled || false,
            self_scheduling_reschedule_notice_hours: application.current_stage?.schedule_template?.self_scheduling_reschedule_notice_hours,
            self_scheduling_cancel_enabled: application.current_stage?.schedule_template?.self_scheduling_cancel_enabled || false,
            self_scheduling_cancel_notice_hours: application.current_stage?.schedule_template?.self_scheduling_cancel_notice_hours,
            self_scheduling_email_follow_up_templates: application.current_stage?.schedule_template?.self_scheduling_email_follow_up_templates || undefined,
            self_scheduling_request_email_template: application.current_stage?.schedule_template?.self_scheduling_request_email_template && {
              id: application.current_stage?.schedule_template?.self_scheduling_request_email_template.id,
              name: application.current_stage?.schedule_template?.self_scheduling_request_email_template.name,
              subject: application.current_stage?.schedule_template?.self_scheduling_request_email_template.subject,
              sender_name: application.current_stage?.schedule_template?.self_scheduling_request_email_template.sender_name,
              sender_email: application.current_stage?.schedule_template?.self_scheduling_request_email_template.sender_email,
              cc_emails: application.current_stage?.schedule_template?.self_scheduling_request_email_template.cc_emails,
              bcc_emails: application.current_stage?.schedule_template?.self_scheduling_request_email_template.bcc_emails,
              body: application.current_stage?.schedule_template?.self_scheduling_request_email_template.body,
              attachments: application.current_stage?.schedule_template?.self_scheduling_request_email_template.attachments,
            },
            // TODO: add room_filters when we're ready to add them
          },
          stage_interview: {
            id: stageInterview?.id,
            feedback_form_id: feedbackFormId,
            interview_template: {
              name: 'Inline Interview Template',
              duration_minutes: duration,
              live_coding_enabled: liveCodingEnabled,
              candidate_facing_name: candidateFacingName,
              candidate_facing_details: candidateFacingDetails,
              interviewer_templates: interviewerTemplates,
            },
          },
        });
      })();
    }
  }, [Boolean(!isLoading && !selfSchedulingLink.application_id), users]);

  const canRequestSelfScheduling = application?.current_stage?.schedule_template && [
    'availability_requested',
    'cancelled',
    'ready_to_request_availability',
    'ready_to_schedule',
    'scheduled',
    'scheduling_link_sent',
  ].includes(application?.scheduling_status);

  const canSendEmail = Boolean(account?.email_domain || currentUser?.gem_can_send_email);
  const [isEmailSettingsWarningModalOpen, setIsEmailSettingsWarningModalOpen] = useState(!canSendEmail);

  if (isLoading || (canRequestSelfScheduling && !selfSchedulingLink.application_id && !isError)) {
    return <LoadingSpinner />;
  }

  const errorMessages: Record<string, ReactNode> = {
    unschedulable: (
      <span>
        You have not enabled scheduling for candidates in the {application!.current_stage?.name} stage.&nbsp;
        <Link to={correctPath(`/app/jobs/${application!.job_id}/stages/${application!.current_stage_id}`)}>
          Enable scheduling here.
        </Link>
      </span>
    ),
    inactive: 'You cannot schedule an inactive candidate.',
  };

  return (
    <Breadcrumb
      data={{
        title: 'Self-Scheduling',
        pathname: correctPath(`/app/candidates/${application!.id}/self-schedule`),
      }}
    >
      {canRequestSelfScheduling && !isError ? (
        <Section
          className="candidate-self-schedule-container"
          title={`Send ${application.candidate.name} a link to schedule ${application.current_stage?.name}`}
        >
          <EmailSettingsWarningModal
            isOpen={isEmailSettingsWarningModalOpen}
            onToggle={() => setIsEmailSettingsWarningModalOpen(false)}
            type={EmailTemplateType.SelfSchedulingRequestEmail}
          />
          <StepProgressBar
            completedStep={completedStep}
            // We don't want them to be able to click back after it's already
            // been created.
            isDisabled={completedStep === Step.SendLink}
            steps={[{
              label: 'Preferences',
              location: correctPath(`/app/candidates/${application.id}/self-schedule/preferences`),
            }, {
              label: 'Events & Emails',
              location: correctPath(`/app/candidates/${application.id}/self-schedule/events-and-emails`),
            }, {
              label: 'Review',
              location: correctPath(`/app/candidates/${application.id}/self-schedule/review`),
            }, !selfSchedulingLink.schedule_template.self_scheduling_request_email_template && completedStep >= Step.Preferences && {
              label: 'Send Link',
              location: correctPath(`/app/candidates/${application.id}/self-schedule/send`),
            }].filter((step): step is { label: string; location: string } => Boolean(step))}
          />
          <div className="candidate-self-schedule-steps-container">
            <Switch>
              <Redirect exact from={correctPath('/app/candidates/:id/self-schedule')} to={correctPath('/app/candidates/:id/self-schedule/preferences')} />
              <Route component={PreferencesStep} path={correctPath('/app/candidates/:id/self-schedule/preferences')} />
              <Route component={EventsAndEmailsStep} path={correctPath('/app/candidates/:id/self-schedule/events-and-emails')} />
              <Route component={ReviewStep} path={correctPath('/app/candidates/:id/self-schedule/review')} />
              <Route component={SendLinkStep} path={correctPath('/app/candidates/:id/self-schedule/send')} />
            </Switch>
          </div>
        </Section>
      ) : (
        <>
          <Flash
            message={errorMessages[application!.scheduling_status]}
            showFlash={Boolean(errorMessages[application!.scheduling_status])}
            type="danger"
          />
          <Flash
            message={
              <>
                This stage doesn&apos;t have any interviews configured. You can add interviews {constructAtsHref(account!, AtsHrefType.InterviewPlan, { jobAtsId: application?.job.ats_id }) ? <OutboundLink href={constructAtsHref(account!, AtsHrefType.InterviewPlan, { jobAtsId: application?.job.ats_id })} label="ATS Interview Plan">here</OutboundLink> : <Link to={correctPath(`/app/jobs/${application?.job_id}/stages/${application?.current_stage_id}/interviews`)}>here</Link>}.
              </>
            }
            showFlash={!application!.current_stage?.stage_interviews}
            type="danger"
          />
        </>
      )}
    </Breadcrumb>
  );
};
