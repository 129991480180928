import Moment from 'moment-timezone';
import { isEmpty } from 'lodash';
import { useMemo } from 'react';

import Avatar from 'components/library/data-display/Avatar';
import ListItem from 'components/library/data-display/ListItem';
import { StyledHiringMeetingTable } from '../styles';
import { formatMoment, TimeFormat } from 'libraries/time';
import { rsvpAvatarStatus } from 'types';
import { useSession } from 'hooks/use-session';
import { useUsersMap } from 'hooks/queries/users';

import type { HiringMeeting } from 'types';
import type { TableSchema } from 'components/library/data-display/Table';

interface Props {
  hiringMeeting: HiringMeeting;
}

const HiringMeetingTable = ({ hiringMeeting }: Props) => {
  const { account } = useSession();
  const users = useUsersMap({ archived: true });

  const schema = useMemo<TableSchema<HiringMeeting>>(() => [{
    header: 'Time',
    displayValue: ({ hiring_meeting_template: { duration_minutes }, start_time, timezone }) => (
      <span>
        {formatMoment(Moment.tz(start_time, timezone), TimeFormat.Time)}&ndash;{formatMoment(Moment.tz(start_time, timezone).add(duration_minutes, 'minutes'), TimeFormat.TimeWithTimezone)}
      </span>
    ),
  }, {
    header: 'Attendees',
    displayValue: ({ hiring_meeting_attendees }) => (
      <div className="interview-panel">
        {isEmpty(hiring_meeting_attendees) ?
          <ListItem label={<i>No attendees</i>} /> :
          (hiring_meeting_attendees || []).map(({ attendee_id, rsvp }, i) => (
            <ListItem
              key={`interviewer-${i}`}
              label={users[attendee_id!]?.name || users[attendee_id!]?.email}
              leftIcon={
                <Avatar
                  size="small"
                  status={rsvpAvatarStatus[rsvp].status}
                  tooltipHelperText={rsvpAvatarStatus[rsvp].tooltipHelperText}
                  userId={attendee_id!}
                />
              }
            />
          ))
        }
      </div>
    ),
  }], [account, users]);

  return (
    <StyledHiringMeetingTable
      data={[hiringMeeting]}
      layout="vertical"
      schema={schema}
    />
  );
};

export default HiringMeetingTable;
