import { capitalize, groupBy, sortBy } from 'lodash';
import { useMemo } from 'react';

import pluralize from '../../libraries/pluralize';
import { businessHourDayOrdering } from '../../types/business-hours';
import { formatBusinessHours, formatBusinessHoursForWeekday, formatDuration } from '../../libraries/formatters';
import { sortBusinessHours } from '../../libraries/business-hours';

import type { BusinessHourDay, EditableBusinessHour } from '../../types/business-hours';
import type { SuggestedTime } from '../../types';

interface Props {
  businessHours: EditableBusinessHour[];
  candidateName: string;
  minimumDurationMinutes?: number;
  rollingWindowDays?: number;
  stageName?: string;
  suggestedTimes?: SuggestedTime[] | null;
  totalDurationMinutes?: number;
}

const AvailabilityInstructions = ({
  businessHours,
  candidateName,
  minimumDurationMinutes,
  rollingWindowDays,
  stageName = 'interview',
  suggestedTimes,
  totalDurationMinutes,
}: Props) => {
  const formattedBusinessHours = formatBusinessHours(businessHours);
  const grouped = useMemo(() => groupBy(sortBusinessHours(businessHours), 'day'), [businessHours]);
  const days = useMemo<BusinessHourDay[]>(() => sortBy<BusinessHourDay>(Object.keys(grouped) as BusinessHourDay[], [(day) => businessHourDayOrdering[day]]), [grouped]);

  const requirements = [
    minimumDurationMinutes && (
      <span>
        Each time block must be at least <b>{formatDuration(minimumDurationMinutes)} long</b>.
      </span>
    ),
    formattedBusinessHours && (
      <span>
        We conduct interviews on <b>{formattedBusinessHours}</b>.
      </span>
    ),
    !formattedBusinessHours && (
      <span>
        We conduct interviews on the following days:
        <ul>
          {days.map((day) => (
            <li key={day}>
              {capitalize(day)}: {formatBusinessHoursForWeekday(grouped[day], undefined, businessHourDayOrdering[day])}
            </li>
          ))}
        </ul>
      </span>
    ),
    totalDurationMinutes && (!minimumDurationMinutes || totalDurationMinutes > minimumDurationMinutes) && (
      <span>
        Please select at least <b>{formatDuration(totalDurationMinutes)} of availability in total</b>.
        <ul>
          <li>You can select as many time blocks as you want. The combined availability of all the time blocks must be at least {formatDuration(totalDurationMinutes)}.</li>
        </ul>
      </span>
    ),
  ].filter((req): req is JSX.Element => Boolean(req));

  return (
    <div>
      <p>
        Hi, {candidateName.split(' ')[0]}! Let&apos;s get you scheduled for your upcoming {stageName}.
      </p>
      <p>
        Fill in your availability{rollingWindowDays ? `, preferably within the next ${rollingWindowDays} ${pluralize('day', rollingWindowDays)}` : ''}.{suggestedTimes && suggestedTimes.length > 0 && ' We\'ve provided some suggested times that work best with our team\'s schedule, but you can submit times outside of those suggestions as well.'}{requirements.length > 0 && ' To help our team coordinate the best schedule for you, please note the following.'}
      </p>
      {requirements.length > 0 &&
        <ul>
          {requirements.map((requirement, i) => <li key={`requirement-${i}`}>{requirement}</li>)}
        </ul>
      }
    </div>
  );
};

export default AvailabilityInstructions;
