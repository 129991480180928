import { cloneDeep } from 'lodash';
import { Text } from 'slate';

import type { Descendant } from 'slate';

import { EditorType, Token } from '../../types';

// This helper function is only used for translating between single-block and multi-block confirmation emails.
type SupportedEditorType = EditorType.ConfirmationEmail | EditorType.MultiBlockConfirmationEmail;

type TokenTranslationMap = {
  [key in Token]?: Token;
};

const EQUIVALENT_TOKENS_BETWEEN_EDITOR_TYPES: { editorType: SupportedEditorType; token: Token }[][] = [[
  { editorType: EditorType.ConfirmationEmail, token: Token.ScheduleDate },
  { editorType: EditorType.MultiBlockConfirmationEmail, token: Token.ScheduleDates },
], [
  { editorType: EditorType.ConfirmationEmail, token: Token.ScheduleDateInCandidateTimezone },
  { editorType: EditorType.MultiBlockConfirmationEmail, token: Token.ScheduleDatesInCandidateTimezone },
], [
  { editorType: EditorType.ConfirmationEmail, token: Token.ScheduleDateWithoutYear },
  { editorType: EditorType.MultiBlockConfirmationEmail, token: Token.ScheduleDatesWithoutYear },
], [
  { editorType: EditorType.ConfirmationEmail, token: Token.ScheduleDateWithoutYearInCandidateTimezone },
  { editorType: EditorType.MultiBlockConfirmationEmail, token: Token.ScheduleDatesWithoutYearInCandidateTimezone },
], [
  { editorType: EditorType.ConfirmationEmail, token: Token.ScheduleDateWithoutYearInCandidateTimezone },
  { editorType: EditorType.MultiBlockConfirmationEmail, token: Token.ScheduleDatesWithoutYearInCandidateTimezone },
], [
  { editorType: EditorType.ConfirmationEmail, token: Token.ScheduleDayOfTheWeek },
  { editorType: EditorType.MultiBlockConfirmationEmail, token: Token.ScheduleDaysOfTheWeek },
], [
  { editorType: EditorType.ConfirmationEmail, token: Token.ScheduleDayOfTheWeekInCandidateTimezone },
  { editorType: EditorType.MultiBlockConfirmationEmail, token: Token.ScheduleDaysOfTheWeekInCandidateTimezone },
], [
  { editorType: EditorType.ConfirmationEmail, token: Token.ScheduleRoomName },
  { editorType: EditorType.MultiBlockConfirmationEmail, token: Token.ScheduleRoomNames },
], [
  { editorType: EditorType.ConfirmationEmail, token: Token.ScheduleVideoConferencingLink },
  { editorType: EditorType.MultiBlockConfirmationEmail, token: Token.ScheduleVideoConferencingLinks },
], [
  { editorType: EditorType.ConfirmationEmail, token: Token.ScheduleVideoConferencingPasscode },
  { editorType: EditorType.MultiBlockConfirmationEmail, token: Token.ScheduleVideoConferencingPasscodes },
]];

export function getTokenTranslations (previousEditorType: `${SupportedEditorType}`, newEditorType: `${SupportedEditorType}`): TokenTranslationMap {
  const tokenTranslations: TokenTranslationMap = {};
  EQUIVALENT_TOKENS_BETWEEN_EDITOR_TYPES.forEach((equivalentTokens) => {
    const tokenInPreviousEditorType = equivalentTokens.find(({ editorType }) => editorType === previousEditorType)?.token;
    const translatedToken = equivalentTokens.find(({ editorType }) => editorType === newEditorType)?.token;
    if (tokenInPreviousEditorType && translatedToken) {
      tokenTranslations[tokenInPreviousEditorType] = translatedToken;
    }
  });
  return tokenTranslations;
}

export function translateSlateValueBetweenEditorTypes (
  value: Descendant[],
  previousEditorType: `${SupportedEditorType}`,
  newEditorType: `${SupportedEditorType}`
): Descendant[] {
  const tokenTranslations = getTokenTranslations(previousEditorType, newEditorType);
  const newSlateValue = cloneDeep(value);
  newSlateValue.forEach((node) => translateNode(node, tokenTranslations));
  return newSlateValue;
}

function translateNode (node: Descendant, translations: TokenTranslationMap) {
  if (!Text.isText(node)) {
    if (node.type === 'token') {
      node.token = translations[node.token] || node.token;
    } else {
      node.children.forEach((node) => translateNode(node, translations));
    }
  }
}
