import { forwardRef, useEffect, useMemo, useState } from 'react';
import { uniqueId } from 'lodash';

import type { ChangeEventHandler, FocusEventHandler, ReactNode, Ref, ChangeEvent } from 'react';

interface Props {
  className?: string;
  helperText?: ReactNode;
  id?: string;
  isAutofocus?: boolean;
  isDisabled?: boolean;
  isRequired?: boolean;
  label?: string;
  maxLength?: number;
  name?: string;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onFocus?: FocusEventHandler<HTMLTextAreaElement>;
  placeholder?: string;
  value?: string;
}

const TextAreaInputInner = ({
  className,
  helperText,
  id,
  isAutofocus = false,
  isDisabled = false,
  isRequired = false,
  label,
  maxLength,
  name,
  onChange,
  onFocus,
  placeholder,
  value = '',
}: Props, ref: Ref<HTMLTextAreaElement>) => {
  const [controlledValue, setControlledValue] = useState(value);
  useEffect(() => {
    setControlledValue(value);
  }, [value]);

  id = useMemo(() => id || uniqueId('text-area-input-'), [id]);

  const handleChange = (e: ChangeEvent<HTMLTextAreaElement>) => setControlledValue(e.target.value);

  return (
    <div className={`input text-area-input${className ? ` ${className}` : ''}`}>
      {label && <label htmlFor={id}>{label}</label>}
      <textarea
        autoFocus={isAutofocus}
        disabled={isDisabled}
        id={id}
        maxLength={maxLength}
        name={name}
        onChange={onChange || handleChange}
        onFocus={onFocus}
        placeholder={placeholder}
        ref={ref}
        required={isRequired}
        value={value || controlledValue}
      />
      {(helperText || maxLength) &&
        <div className="helper-text">
          {helperText}
          &nbsp;
          {maxLength &&
            <span className={controlledValue.length > maxLength ? 'helper-text-warning' : undefined}>
              {controlledValue.length}/{maxLength} characters
            </span>
          }
        </div>
      }
    </div>
  );
};

const TextAreaInput = forwardRef(TextAreaInputInner);

export default TextAreaInput;
