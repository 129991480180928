import 'react-day-picker/dist/style.css';
import Moment from 'moment';
import classnames from 'classnames';
import { DayPicker } from 'react-day-picker';
import { useCallback, useEffect, useMemo, useState } from 'react';

import DatePickerDay from './DatePickerDay';
import DateRangePickerNavbar from './DateRangePickerNavbar';
import { DatePickerProvider } from './use-context';
import { getAccessibleBackgroundColor, getAccessibleTextColor } from '../../../../libraries/color';

import type { DayModifiers, ModifiersStyles, MonthChangeEventHandler } from 'react-day-picker';
import type { DayTooltipText } from './use-context';

const weekdays = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];

export interface RangeValue {
  endDate?: Date;
  startDate?: Date;
}

interface BaseProps {
  brandColor?: string;
  dayTooltipText?: DayTooltipText;
  disabledDays?: (date: Date) => boolean;
  fromMonth?: Date;
  onMonthChange?: MonthChangeEventHandler;
  modifiers?: DayModifiers;
  modifiersStyles?: object;
}

interface RangeProps {
  isRange: true;
  onChange: (range: RangeValue) => void;
  value?: RangeValue;
}

interface NonRangeProps {
  isRange: false;
  onChange: (day: Date) => void;
  value?: Date;
}

type Props = BaseProps & (RangeProps | NonRangeProps);

const DatePicker = (props: Props) => {
  // The non-base props are intentionally left out of the destructuring because
  // Typescript is smart enough to know that if isRange is true, the value is a
  // RangeValue, but once destructured, it loses that information.
  const {
    brandColor,
    dayTooltipText,
    disabledDays,
    fromMonth,
    onMonthChange,
    modifiers = {},
    modifiersStyles = {},
  } = props;

  const [selectingRangeEnd, setSelectingRangeEnd] = useState<Date | undefined>(undefined);

  const from = useMemo(() => props.isRange && props.value && props.value.startDate ? Moment(props.value.startDate).toDate() : undefined, [props.isRange, props.value]);
  const to = useMemo(() => props.isRange && props.value && props.value.endDate ? Moment(props.value.endDate).toDate() : undefined, [props.isRange, props.value]);
  const [month, setMonth] = useState(props.isRange ? from : props.value);
  const startDate = useMemo(() => props.isRange ? from : props.value, [from, props.isRange, props.value]);

  useEffect(() => {
    if (startDate) {
      setMonth(startDate);
    }
  }, [startDate]);

  const handleDayClick = (day: Date) => {
    if (!props.isRange) {
      props.onChange(day);
      return;
    }
    if (from && to) {
      props.onChange({
        startDate: day,
        endDate: undefined,
      });
      return;
    }
    if (!from) {
      setSelectingRangeEnd(undefined);
      props.onChange({
        startDate: day,
        endDate: undefined,
      });
    } else {
      const isBeforeStart = day < from;
      setSelectingRangeEnd(isBeforeStart ? day : from);
      props.onChange({
        startDate: isBeforeStart ? day : from,
        endDate: isBeforeStart ? from : day,
      });
    }
  };

  const handleDayMouseEnter = (day: Date) => {
    if (from) {
      setSelectingRangeEnd(day);
    }
  };

  const handleMonthChange = useCallback((month: Date) => {
    setMonth(month);
    onMonthChange?.(month);
  }, [onMonthChange]);

  const customModifiersStyles = useMemo<ModifiersStyles>(() => {
    if (!brandColor) {
      return {};
    }

    return {
      selected: {
        background: getAccessibleBackgroundColor(brandColor, '#666666'),
      },
      today: {
        color: getAccessibleTextColor('white', [brandColor]),
      },
      ...modifiersStyles,
    };
  }, [brandColor, modifiersStyles]);

  return (
    <DatePickerProvider brandColor={brandColor} dayTooltipText={dayTooltipText} fromMonth={fromMonth}>
      <DayPicker
        className={classnames('date-picker', props.isRange && 'date-range-picker')}
        components={{
          Caption: DateRangePickerNavbar,
          Day: DatePickerDay,
        }}
        disabled={disabledDays}
        formatters={{
          formatWeekdayName: (day: Date) => weekdays[day.getDay()],
        }}
        fromMonth={fromMonth}
        labels={{
          labelNext: () => 'Next month',
          labelPrevious: () => 'Previous month',
        }}
        modifiers={{
          ...modifiers,
          ...(props.isRange ? { start: from, end: to } : {}),
        }}
        modifiersClassNames={{
          ...Object.keys(modifiersStyles || {}).reduce((acc, key) => ({ ...acc, [key]: `rdp-day_${key}` }), {}),
          start: 'rdp-day_start',
          end: 'rdp-day_end',
        }}
        modifiersStyles={customModifiersStyles}
        month={month}
        numberOfMonths={1}
        onDayClick={handleDayClick}
        onDayMouseEnter={props.isRange ? handleDayMouseEnter : undefined}
        onMonthChange={handleMonthChange}
        selected={props.isRange ? [{ from, to: to || selectingRangeEnd }] : props.value}
      />
    </DatePickerProvider>
  );
};

export default DatePicker;
