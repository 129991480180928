import { useMemo, useState } from 'react';

import ArchiveButton from '../../../library/inputs/ArchiveButton';
import Avatar from '../../../library/data-display/Avatar';
import Flash from '../../../library/utils/Flash';
import Label from '../../../library/utils/Label';
import SelectInput from '../../../library/inputs/SelectInput';
import Table from '../../../library/data-display/Table';
import Tag from '../../../library/data-display/Tag';
import { useSession } from '../../../../hooks/use-session';
import { useUpdateUser } from '../../../../hooks/queries/users';

import type { ActionMeta } from 'react-select';
import type { ChangeEvent } from 'react';
import type { EligibilityOption, UpdateUserPayload } from './types';
import type { OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Option } from '../../../library/inputs/SelectInput/types';
import type { TableSchema } from '../../../library/data-display/Table';
import type { User, UserTag } from '../../../../types';
import { correctPath } from 'libraries/gem';

const getRowLink = ({ id }: User) => correctPath(`/app/interviewers/${id}`);

interface Props {
  eligibilityOptions: EligibilityOption[];
  isEditing: boolean;
  onEligibilitiesChange: (newValue: OnChangeValue<EligibilityOption, true>, actionMeta: ActionMeta<EligibilityOption>) => void;
  onPageNumberChange: (pageNumber: number) => void;
  onShowArchivedChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onTagsChange: (newValue: OnChangeValue<Option<string>, true>, actionMeta: ActionMeta<Option<string>>) => void;
  pageNumber: number;
  showArchived: boolean;
  tagOptions: Option<string>[];
  totalCount: number;
  userPayloads: Record<string, UpdateUserPayload>;
  users: User[];
}

const InterviewersTable = ({
  eligibilityOptions,
  isEditing,
  onEligibilitiesChange,
  onPageNumberChange,
  onShowArchivedChange,
  onTagsChange,
  pageNumber,
  showArchived,
  tagOptions,
  totalCount,
  userPayloads,
  users,
}: Props) => {
  const { currentUser } = useSession();

  const [lastAction, setLastAction] = useState({
    id: '',
    isUndo: false,
    type: '',
  });

  const updateUserMutation = useUpdateUser();

  const handleArchive = async (id: string, undo: boolean) => {
    setLastAction({
      id,
      type: 'archive',
      isUndo: undo,
    });
    updateUserMutation.reset();
    updateUserMutation.mutate({ id, payload: { archived: true } });
  };

  const handleUnarchive = async (id: string, undo: boolean) => {
    setLastAction({
      id,
      type: 'unarchive',
      isUndo: undo,
    });
    updateUserMutation.reset();
    updateUserMutation.mutate({ id, payload: { archived: false } });
  };

  const handleUndo = () => {
    if (lastAction.type === 'archive') {
      handleUnarchive(lastAction.id, true);
    }
    if (lastAction.type === 'unarchive') {
      handleArchive(lastAction.id, true);
    }
  };

  const columns = useMemo<TableSchema<User>>(() => [{
    header: 'Name',
    displayValue: ({ directory_archived, id, name, user_archived }) => (
      <div className="interviewers-table-name">
        <Avatar
          showUserTooltip={false}
          size="small"
          userId={id}
        />
        {name || <span className="unset">No name</span>}
        {(user_archived || directory_archived) && <Label color="gray">Archived</Label>}
      </div>
    ),
  }, {
    header: 'Email',
    displayValue: ({ email }) => (
      <div className="interviewers-table-email">
        {email}
      </div>
    ),
  }, {
    header: 'Interview Eligibilities',
    displayValue: ({ eligibilities }) => (
      <div className="interviewers-tag-container">
        {eligibilities ? eligibilities.map((eligibility) => (
          <Tag
            hasTrainingProgram={Boolean(eligibility.training_program_id)}
            isTrainee={eligibility.trainee}
            key={`${eligibility.id}-${eligibility.trainee}`}
            type="eligibility"
            value={eligibility.id}
          />
        )) : <span className="unset">No eligibilities</span>}
      </div>
    ),
    displayEditValue: ({ id, eligibilities }) => {
      const selectedEligibilities = (userPayloads[id]?.eligibilities || eligibilities || []);
      // Create a map of eligibilities, so we can do a quick lookup to see if a certain eligibility is selected.
      const selectedEligibilitiesMap = selectedEligibilities
      .reduce<Record<string, boolean>>((acc, eligibility) => {
        return ({ ...acc, [`${eligibility.id}:${eligibility.trainee || false}`]: true });
      }, {});

      return (
        <div onClick={(e) => e.stopPropagation()}>
          <SelectInput
            className="select-input-interviewers-eligibility"
            formatCreateLabel={(value) => `Add new interview eligibility "${value}"`}
            formatOptionLabel={(option) => {
              // The type for formatOptionLabel isn't correct. Fix this once
              // https://github.com/JedWatson/react-select/issues/5064 is addressed.
              const { __isNew__, label, training_program_id, trainee } = option as any;
              return (
                __isNew__ ?
                  label :
                  <Tag
                    hasTrainingProgram={Boolean(training_program_id)}
                    isNegated={false}
                    isTrainee={trainee}
                    type="eligibility"
                    value={label}
                  />
              );
            }}
            isClearable
            isCreatable
            isMulti
            name={id}
            onChange={onEligibilitiesChange}
            options={eligibilityOptions.filter((option) => {
              if (!option.trainee) {
                // If this isn't a trainee tag, always show it.
                return true;
              }
              return !selectedEligibilitiesMap[`${option.label}:false`];
            })}
            placeholder="Add interview eligibilities"
            value={selectedEligibilities.map((eligibility) => {
              return {
                label: eligibility.id,
                value: `${eligibility.id}${eligibility.trainee || false}`,
                trainee: eligibility.trainee,
                training_program_id: eligibility.training_program_id,
              };
            })}
          />
        </div>
      );
    },
  }, {
    header: 'Tags',
    displayValue: ({ user_tags }) => (
      <div className="interviewers-tag-container">
        {user_tags ? user_tags.map((tag) => <Tag key={tag.id} type="tag" value={tag.id} />) : <span className="unset">No tags</span>}
      </div>
    ),
    displayEditValue: ({ id, user_tags }) => (
      <div onClick={(e) => e.stopPropagation()}>
        <SelectInput
          className="select-input-interviewers-tag"
          formatCreateLabel={(value) => `Add new tag "${value}"`}
          formatOptionLabel={(option) => {
            // The type for formatOptionLabel isn't correct. Fix this once
            // https://github.com/JedWatson/react-select/issues/5064 is addressed.
            const { __isNew__, label, value } = option as any;
            return (
              __isNew__ ?
                label :
                <Tag
                  isNegated={false}
                  type="tag"
                  value={value}
                />
            );
          }}
          isClearable
          isCreatable
          isMulti
          name={id}
          onChange={onTagsChange}
          options={tagOptions}
          placeholder="Add tags"
          value={(userPayloads[id]?.user_tags || user_tags || []).map((tag) => {
            const value: string = (tag as UserTag).id || tag as string;
            return { label: value, value };
          })}
        />
      </div>
    ),
  }, {
    header: 'Actions',
    isClickable: true,
    displayValue: ({ directory_archived, id, user_archived }) => (
      <div className="action-buttons-container">
        {id !== currentUser?.id && (
          <ArchiveButton
            id={`${id}-archive-button`}
            isArchived={user_archived || directory_archived}
            onArchive={() => handleArchive(id, false)}
            onUnarchive={() => handleUnarchive(id, false)}
            size="small"
          />
        )}
      </div>
    ),
  }], [
    eligibilityOptions,
    handleArchive,
    handleUnarchive,
    onEligibilitiesChange,
    onTagsChange,
    tagOptions,
    userPayloads,
  ]);

  return (
    <div className="interviewers-table-container">
      <Flash
        isDismissible
        message={(
          <span>
            Interviewer {lastAction.type}d.&nbsp;
            {!lastAction.isUndo && <a href="#" onClick={handleUndo}>Undo?</a>}
          </span>
        )}
        showFlash={lastAction.id ? updateUserMutation.isSuccess : false}
        type="success"
      />
      <Flash
        message={updateUserMutation.error?.message}
        showFlash={updateUserMutation.isError}
        type="danger"
      />
      <Table
        data={users}
        dataDescriptor="interviewers"
        getRowLink={isEditing ? undefined : getRowLink}
        isEditing={isEditing}
        isPaginated
        layout="vertical"
        onPageNumberChange={onPageNumberChange}
        onShowArchivedChange={onShowArchivedChange}
        pageNumber={pageNumber}
        schema={columns}
        showArchived={showArchived}
        totalCount={totalCount}
      />
    </div>
  );
};

export default InterviewersTable;
