import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';
import type { ReactElement, ReactNode } from 'react';
import { useEffect, useMemo, useState } from 'react';

import Button, { ButtonSize } from '../../inputs/Button';
import Tooltip from '../../utils/Tooltip';

interface Props {
  arrowButtonSize?: `${ButtonSize}`;
  arrowLabel?: ReactNode;
  className?: string;
  collapseTooltipText?: string;
  expandTooltipText?: string;
  expandedContent?: ReactNode;
  isExpanded?: boolean;
  numColumns: number;
  row: ReactElement;
}

const TableExpandingRow = ({
  arrowButtonSize = ButtonSize.Large,
  arrowLabel,
  className,
  collapseTooltipText = 'Collapse',
  expandTooltipText = 'Expand',
  expandedContent,
  isExpanded = false,
  numColumns,
  row,
}: Props) => {
  const [controlledIsExpanded, setControlledIsExpanded] = useState(isExpanded);

  useEffect(() => {
    if (isExpanded !== null) {
      setControlledIsExpanded(isExpanded);
    }
  }, [isExpanded]);

  const toggleExpand = () => setControlledIsExpanded(!controlledIsExpanded);

  const icon = useMemo(() => <FontAwesomeIcon icon={controlledIsExpanded ? faAngleUp : faAngleDown} />, [controlledIsExpanded]);

  if (!expandedContent) {
    return (
      <tr className={className}>
        {[
          ...row.props.children,
          <td className="expand-action" key="expand-action" />,
        ]}
      </tr>
    );
  }

  return (
    <>
      <tr className={`row-link row-expanding${controlledIsExpanded ? ' row-expanded' : ''}`} onClick={toggleExpand}>
        {[
          ...row.props.children,
          <td className="expand-action" key="expand-action">
            <Button
              color="gray"
              iconRight={icon}
              onClick={toggleExpand}
              size={arrowButtonSize}
              tooltip={<Tooltip
                id={`${row.key}-expand-button`}
                position="left"
                value={controlledIsExpanded ? collapseTooltipText : expandTooltipText}
              />}
              value={arrowLabel || null}
            />
          </td>,
        ]}
      </tr>
      {controlledIsExpanded &&
        <tr className="row-expanded-content">
          <td colSpan={numColumns + 1}>
            {expandedContent}
          </td>
        </tr>
      }
    </>
  );
};

export default TableExpandingRow;
