import { Transforms } from 'slate';
import { uniqueId } from 'lodash';
import { useMemo } from 'react';
import { useSlate } from 'slate-react';

import Tooltip from '../../../utils/Tooltip';

import type { MouseEvent } from 'react';

interface Props {
  isDisabled: boolean;
  showText?: boolean;
  showTooltip?: boolean;
}

const TokenToolbarOption = ({ isDisabled, showText = true, showTooltip = false }: Props) => {
  const editor = useSlate();

  const id = useMemo(() => uniqueId('editor-input-token-toolbar-option-'), []);

  const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
    if (isDisabled) {
      return;
    }

    event.preventDefault();

    Transforms.insertText(editor, '{{');
  };

  return (
    <>
      <div
        className={`editor-toolbar-option${showText ? ' editor-toolbar-option-long' : ''}`}
        data-for={showTooltip && !isDisabled ? `tooltip-token-${id}` : undefined}
        data-tip={showTooltip && !isDisabled ? true : undefined}
        onMouseDown={handleMouseDown}
        title="Add token"
      >
        <div className="editor-toolbar-option-icon">{'{}'}</div>
        {showText && <div className="editor-toolbar-option-text">Token</div>}
      </div>
      {showTooltip && !isDisabled && (
        <Tooltip
          id={`tooltip-token-${id}`}
          position="top"
          value="Add token"
        />
      )}
    </>
  );
};

export default TokenToolbarOption;
