import styled from 'styled-components';

export const StyledTabContainer = styled.div`
  overflow: scroll;
  padding-bottom: 50px;
  padding-top: 1em;

  .flash {
    margin-bottom: 1em;

    a {
      padding: 0;
    }
  }
`;
