import QueryString from 'qs';
import { isEqual } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';

import type { IStringifyOptions, IParseOptions } from 'qs';

const queryStringParseOptions: IParseOptions = { ignoreQueryPrefix: true };
const queryStringStringifyOptions: IStringifyOptions = { arrayFormat: 'repeat' };

export type HistoryMethod = 'push' | 'replace';

export interface SetQueryValueOptions {
  method?: HistoryMethod;
}

export interface Options {
  array?: boolean;
  stripDefault?: boolean;
}

const useQueryState = <T extends string | string[]>(queryKey: string, defaultValue: T, options: Options = {}): [T, (value: T | undefined, options?: SetQueryValueOptions) => void] => {
  // Prevent defaultValue from changing
  [defaultValue] = useState(defaultValue);

  const stripDefault = useMemo(() => options.stripDefault !== undefined ? options.stripDefault : true, [options.stripDefault]) ;

  const history = useHistory();
  const params = useMemo(() => {
    return QueryString.parse(history.location.search, queryStringParseOptions);
  }, [history.location.search]);

  const queryValue = useMemo(() => params[queryKey] as string | string[], [params, queryKey]);

  const setQueryValue = useCallback((value: T | undefined, opts: SetQueryValueOptions = {}): void => {
    // If we want to strip the default value from the URL, we check if the
    // desired value equals the default and if it does, set `undefined` instead.
    if (stripDefault) {
      if (isEqual(value, defaultValue)) {
        value = undefined;
      }
    }

    const queryString = QueryString.stringify({
      ...params,
      // If it's an array, setting the value to the empty array clears out the
      // query param, which can be problematic if the default is not an empty
      // value. So instead of using the empty array, we set it to null, which
      // will keep the query param, but make it have no value e.g. `a=`.
      [queryKey]: options.array && value?.length === 0 ? null : value,
    }, queryStringStringifyOptions);

    history[opts.method || 'push'](`${history.location.pathname}?${queryString}${history.location.hash}`);
  }, [history, params]);

  useEffect(() => {
    // If the value in the URL isn't set, use the default value. And if we don't
    // want to strip the default from the URL, set the URL to be the default as
    // well.
    if (queryValue === undefined && !stripDefault) {
      setQueryValue(defaultValue, { method: 'replace' });
    }
  }, [defaultValue, queryValue, setQueryValue, stripDefault]);

  // If the value in the URL isn't set, use the default value. And if we don't
  // want to strip the default from the URL, set the URL to be the default as
  // well.
  if (queryValue === undefined) {
    return [defaultValue, setQueryValue];
  }

  if (options.array && !Array.isArray(queryValue)) {
    if (queryValue !== null) {
      return [[queryValue] as T, setQueryValue];
    }
  }

  return [queryValue as T, setQueryValue];
};

export default useQueryState;
