import { keyBy } from 'lodash';
import { useMemo } from 'react';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import InterviewPlanner from '../../libraries/interviewplanner';

import type { EditableAttachment, EmailTemplate } from '../../types';
import type { UseQueryOptions } from 'react-query';

export enum QueryKey {
  ListEmailTemplates = 'ListEmailTemplates',
  RetrieveEmailTemplate = 'RetrieveEmailTemplate',
}

export const useEmailTemplate = (id?: string, options?: UseQueryOptions<EmailTemplate, Error>) => {
  return useQuery(emailTemplateParams(id, options));
};

export const emailTemplateParams = (id: string | undefined, options: UseQueryOptions<EmailTemplate, Error> = {}) => {
  // This query can't be canceled because the promise being returned from this
  // function (which is a result of the async keyword) doesn't have a cancel
  // function on it. If we want it to be cancellable, we can't use async/await,
  // and we need to make sure the cancel function from the promise returned from
  // InterviewPlanner.request is preserved.
  return {
    queryKey: [QueryKey.RetrieveEmailTemplate, id],
    queryFn: async () => {
      const emailTemplate = await InterviewPlanner.request<EmailTemplate>('GET', `/email-templates/${id}`);
      emailTemplate.cc_emails = emailTemplate.cc_emails || [];
      emailTemplate.bcc_emails = emailTemplate.bcc_emails || [];
      return emailTemplate;
    },
    ...options,
    enabled: options.enabled !== undefined ? options.enabled : (Boolean(id) && id !== 'new'),
  };
};

interface ListEmailTemplatesQuery {
  limit?: number;
  offset?: number;
  name?: string;
  archived?: boolean;
}

interface ListEmailTemplatesData {
  email_templates: EmailTemplate[];
  total: number;
}

export const useEmailTemplates = (query: ListEmailTemplatesQuery = {}, options: UseQueryOptions<ListEmailTemplatesData, Error> = {}) => {
  // This query can't be canceled because the promise being returned from this
  // function (which is a result of the async keyword) doesn't have a cancel
  // function on it. If we want it to be cancellable, we can't use async/await,
  // and we need to make sure the cancel function from the promise returned from
  // InterviewPlanner.request is preserved.
  return useQuery<ListEmailTemplatesData, Error>([QueryKey.ListEmailTemplates, query], async () => {
    const pageSize = 100;
    const paginatedQuery = {
      limit: pageSize,
      offset: 0,
      ...query,
    };

    const emailTemplates = [];
    let total = 0;
    let totalLimit;

    while (totalLimit === undefined || emailTemplates.length < totalLimit) {
      const response = await InterviewPlanner.request<ListEmailTemplatesData>('GET', '/email-templates', null, paginatedQuery);
      total = response.total;
      if (response.email_templates.length === 0) {
        break;
      }

      emailTemplates.push(...response.email_templates);
      totalLimit = query.limit! < total ? query.limit : total;
      paginatedQuery.offset = paginatedQuery.offset + paginatedQuery.limit;
    }

    return { email_templates: emailTemplates, total };
  }, options);
};

interface EmailTemplateMap {
  [id: string]: EmailTemplate;
}

export const useEmailTemplatesMap = (query?: ListEmailTemplatesQuery, options?: UseQueryOptions<ListEmailTemplatesData, Error>) => {
  const { data: emailTemplates } = useEmailTemplates(query, options);
  return useMemo<EmailTemplateMap>(() => emailTemplates ? keyBy(emailTemplates.email_templates, 'id') : {}, [emailTemplates]);
};

export interface CreateEmailTemplatePayload {
  name: string;
  type: string;
  subject: string;
  sender_name: string;
  sender_email: string;
  cc_emails?: string[];
  bcc_emails?: string[];
  body: string;
  attachments?: {
    file: File;
  }[];
}

interface CreateEmailTemplateMutationVariables {
  payload?: CreateEmailTemplatePayload;
}

export const useCreateEmailTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation<EmailTemplate, Error, CreateEmailTemplateMutationVariables>(({ payload }) => {
    const isMultipartForm = Boolean(payload?.attachments?.length! > 0);
    return InterviewPlanner.request('POST', '/email-templates', payload, null, isMultipartForm);
  }, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKey.ListEmailTemplates]);
      queryClient.setQueryData([QueryKey.RetrieveEmailTemplate, data.id], data);
    },
  });
};

export interface UpdateEmailTemplatePayload {
  name?: string;
  subject?: string;
  sender_name?: string;
  sender_email?: string;
  cc_emails?: string[];
  bcc_emails?: string[];
  body?: string;
  attachments?: EditableAttachment[];
  archived?: boolean;
}

interface UpdateEmailTemplateMutationVariables {
  id: string;
  payload?: UpdateEmailTemplatePayload;
}

export const useUpdateEmailTemplate = () => {
  const queryClient = useQueryClient();

  return useMutation<EmailTemplate, Error, UpdateEmailTemplateMutationVariables>(({ id, payload }) => {
    let isMultipartForm = false;

    if (payload?.attachments) {
      payload.attachments = payload.attachments.map((attachment) => {
        const isNew = attachment instanceof File;
        isMultipartForm = isMultipartForm || isNew;
        return {
          name: attachment.name,
          new: isNew,
          index: isNew ? undefined : attachment.index,
          file: isNew ? attachment : undefined,
        };
      });
    }

    return InterviewPlanner.request('POST', `/email-templates/${id}`, payload, null, isMultipartForm);
  }, {
    onSuccess: (data) => {
      queryClient.invalidateQueries([QueryKey.ListEmailTemplates]);
      queryClient.setQueryData([QueryKey.RetrieveEmailTemplate, data.id], data);
    },
  });
};
