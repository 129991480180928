import Moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { find, flatten, groupBy } from 'lodash';
import { useMemo } from 'react';

import Button from './Button';
import FavoriteButton from './FavoriteButton';
import ListItem from '../data-display/ListItem';
import SelectInput from './SelectInput';
import { EmailTemplateType, emailTemplateTypeLabels, FavoriteResourceType } from '../../../types';
import { formatMoment, TimeFormat } from '../../../libraries/time';
import { useEmailTemplates } from '../../../hooks/queries/email-templates';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';
import type { Group, Option as SelectOption } from './SelectInput/types';
import type { NonManualEmailTemplateType } from '../../../types';

interface Option extends SelectOption<string> {
  favorite?: boolean;
}

interface Props {
  isDisabled?: boolean;
  isInlineCreatable?: boolean;
  label?: string;
  onChange: (newValue: OnChangeValue<SelectOption<string>, false>, actionMeta: ActionMeta<SelectOption<string>>) => void;
  type?: `${EmailTemplateType}`;
  value?: string;
}

const EmailTemplateSelectInput = ({
  isInlineCreatable = false,
  isDisabled = false,
  label = 'Email Template',
  onChange,
  type,
  value,
}: Props) => {
  const emailTemplatesQuery = useEmailTemplates();
  const emailTemplates = useMemo(() => {
    if (!emailTemplatesQuery.data) {
      return [];
    }
    return emailTemplatesQuery.data.email_templates.filter((emailTemplate) => {
      return !emailTemplate.archived && (!type || emailTemplate.type === type || type === EmailTemplateType.ManualEmail);
    });
  }, [emailTemplatesQuery.data]);

  const options = useMemo<Option[] | Group<string, Option>[]>(() => {
    if (!type || type === EmailTemplateType.ManualEmail) {
      const groupedTemplates = groupBy(emailTemplates, 'type');

      return Object.keys(groupedTemplates).map((typeGroup) => ({
        label: emailTemplateTypeLabels[typeGroup as NonManualEmailTemplateType],
        options: groupedTemplates[typeGroup].map((emailTemplate) => ({
          value: emailTemplate.id,
          label: emailTemplate.name,
          secondaryText: `Created on ${formatMoment(Moment(emailTemplate.created_at), TimeFormat.LongMonthDayYear)}`,
          favorite: emailTemplate.favorite,
        })),
      }));
    }

    const templateOptions: Option[] = emailTemplates.map((emailTemplate) => ({
      value: emailTemplate.id,
      label: emailTemplate.name,
      secondaryText: `Created on ${formatMoment(Moment(emailTemplate.created_at), TimeFormat.LongMonthDayYear)}`,
      favorite: emailTemplate.favorite,
    }));

    if (isInlineCreatable) {
      templateOptions.unshift({
        value: 'new',
        label: (
          <Button
            color="no-outline"
            iconLeft={<FontAwesomeIcon icon={faPlus} />}
            size="small"
            value="New Email Template"
          />
        ),
      });
    }

    return templateOptions;
  }, [emailTemplates, isInlineCreatable, type]);

  const selectedValue = useMemo<Option | undefined>(() => {
    if (!value) {
      return undefined;
    }

    if (!type || type === EmailTemplateType.ManualEmail) {
      const allOptions = flatten((options as Group<string, Option>[]).map((group) => group.options));
      return find(allOptions, ['value', value]);
    }

    return find((options as Option[]), ['value', value]);
  }, [options, value, type]);

  return (
    <SelectInput<string, Option>
      formatOptionLabel={(option, { context }) => (
        context === 'menu' ?
          <ListItem
            label={(
              <div className="favorite-container">
                {option.favorite ? (
                  <FavoriteButton
                    favorite={option.favorite}
                    isDisabled
                    resourceId={option.value}
                    resourceType={FavoriteResourceType.EmailTemplate}
                  />
                ) : undefined}
                {option.label}
              </div>
            )}
            secondaryText={option.secondaryText}
          /> :
          option.label
      )}
      isClearable
      isDisabled={isDisabled}
      label={label}
      onChange={onChange}
      options={options}
      placeholder="Select a template"
      value={selectedValue}
    />
  );
};

export default EmailTemplateSelectInput;
