import Moment from 'moment-timezone';
import { useMemo } from 'react';

import Flash from '../library/utils/Flash';
import Modal from '../library/layout/Modal';
import { formatMoment, TimeFormat } from '../../libraries/time';

import type { MouseEventHandler } from 'react';
import type { ScheduleOption, SelfSchedulingLink } from '../../types';

interface Props {
  brandColor?: string;
  error?: Error;
  isOpen: boolean;
  isRescheduling: boolean;
  isSubmitting: boolean;
  onSubmit: MouseEventHandler<HTMLButtonElement>;
  onToggle: MouseEventHandler<HTMLButtonElement>;
  selectedScheduleOption?: ScheduleOption;
  selfSchedulingLink: SelfSchedulingLink;
  timezone: string;
}

const SelfScheduleConfirmModal = ({
  brandColor,
  error,
  isOpen,
  isRescheduling,
  isSubmitting,
  onSubmit,
  onToggle,
  selectedScheduleOption,
  selfSchedulingLink,
  timezone,
}: Props) => {
  const startTime = useMemo(() => Moment(selectedScheduleOption?.start_time).tz(timezone), [selectedScheduleOption]);
  const endTime = useMemo(() => Moment(selectedScheduleOption?.end_time).tz(timezone), [selectedScheduleOption]);

  return (
    <Modal
      brandColor={brandColor}
      cancelButtonValue="Pick a Different Time"
      isOpen={isOpen}
      isSubmitting={isSubmitting}
      onSubmit={onSubmit}
      onToggle={onToggle}
      submitButtonValue={isRescheduling ? 'Reschedule' : 'Confirm'}
      submittingButtonValue={isRescheduling ? 'Rescheduling...' : 'Confirming...'}
      title={`Confirm${isRescheduling ? ' new' : ''} interview time?`}
    >
      <Flash
        message={error?.message}
        showFlash={Boolean(error)}
        type="danger"
      />
      <p>
        Your interview will be {isRescheduling ? 'rescheduled for' : 'on'} <b>{formatMoment(startTime, TimeFormat.LongDayOfWeekMonthDay)}</b> at <b>{formatMoment(startTime, TimeFormat.Time)}&ndash;{formatMoment(endTime, TimeFormat.TimeWithTimezone)}</b>.
      </p>
      <p>
        We will {isRescheduling ? 'update your' : 'send a'} calendar invite{!isRescheduling && selfSchedulingLink.schedule_template.confirmation_email_template_id ? ' and confirmation email' : ''}{isRescheduling ? ' that was sent' : ''} to <b>{selfSchedulingLink.application!.candidate.email}</b>.
      </p>
    </Modal>
  );
};

export default SelfScheduleConfirmModal;
