import Moment from 'moment-timezone';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCaretDown, faCaretUp } from '@fortawesome/free-solid-svg-icons';
import { useMemo, useState } from 'react';

import Button from '../library/inputs/Button';
import Flash from '../library/utils/Flash';
import { formatDuration } from '../../libraries/formatters';

import type { InterviewPlannerError } from '../../libraries/interviewplanner';

interface Props {
  error?: InterviewPlannerError | null;
  timeSlots: {
    start_time: Date | Moment.Moment | string;
    end_time: Date | Moment.Moment | string;
  }[];
  totalDurationMinutes?: number;
}

const AvailabilityErrorFlash = ({ error, timeSlots, totalDurationMinutes }: Props) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const totalTimeSlotsDurationMinutes = useMemo(() => {
    return timeSlots.reduce((total, slot) => {
      return total + Moment.duration(Moment(slot.end_time).diff(Moment(slot.start_time))).asMinutes();
    }, 0);
  }, [timeSlots]);
  const durationDifferenceMinutes = useMemo<number>(() => {
    if (!totalDurationMinutes) {
      return 0;
    }
    return totalDurationMinutes - totalTimeSlotsDurationMinutes;
  }, [totalTimeSlotsDurationMinutes, totalDurationMinutes]);

  const toggleIsExpanded = () => {
    if (!totalDurationMinutes) {
      // This should only be called when this is passed in.
      return;
    }

    setIsExpanded((prev) => !prev);
    analytics.track(`${isExpanded ? 'Closed' : 'Opened'} Total Availability Error Explanation`, {
      submitted: formatDuration(totalTimeSlotsDurationMinutes),
      required: formatDuration(totalDurationMinutes),
    });
  };

  return (
    <Flash
      message={error?.code === 'availability_time_slot_total_duration' && totalDurationMinutes ? (
        <>
          {error.message}
          <Button
            color="no-outline"
            iconRight={<FontAwesomeIcon icon={isExpanded ? faCaretUp : faCaretDown} />}
            onClick={toggleIsExpanded}
            size="small"
            value="Why am I seeing this error?"
          />
          {isExpanded && (
            durationDifferenceMinutes > 0 ?
              <p>You have only submitted <b>{formatDuration(totalTimeSlotsDurationMinutes)}</b> of availability in total. Please submit at least <b>{formatDuration(durationDifferenceMinutes)}</b> more to meet the requirement. You can extend the time block{timeSlots.length === 1 ? '' : 's'} you have selected or add more time blocks.</p> :
              <p>You have provided <b>{formatDuration(totalTimeSlotsDurationMinutes)}</b>, which meets the required amount of time. <b>Make sure to click the Submit button down below to save it.</b></p>
          )}
        </>
      ) : error?.message}
      showFlash={Boolean(error)}
      type="danger"
    />
  );
};

export default AvailabilityErrorFlash;
