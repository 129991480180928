import { capitalize, concat, find, orderBy, startCase } from 'lodash';
import { useMemo, useState } from 'react';

import SelectInput from '../SelectInput';
import Tag from '../../data-display/Tag';
import Tooltip from '../../utils/Tooltip';
import { InterviewerFilterableType, HiringTeamRole } from '../../../../types';
import { useApplication } from '../../../../hooks/queries/applications';
import { useEligibilities, useUsers, useUserTags } from '../../../../hooks/queries/users';
import { useSession } from '../../../../hooks/use-session';

import type { ActionMeta, OnChangeValue } from 'react-select/dist/declarations/src/types';

interface Expression {
  filterable_id: string;
  filterable_type: `${InterviewerFilterableType}`;
  isDisabled?: boolean;
  label: string;
  tooltip?: JSX.Element;
  value: string;
  // Only required for eligibilities/trainings.
  training_program_id?: string;
  trainee?: boolean;
}

export interface Option extends Expression {
  name: string;
  negated: boolean;
}

const createOptions = (expressions: Expression[]): Option[] => orderBy(
  concat(
    expressions.map<Option>((expression) => ({
      ...expression,
      negated: false,
      name: expression.label,
    })),
    expressions.filter(({ isDisabled }) => !isDisabled).map<Option>((expression) => ({
      ...expression,
      label: `not ${expression.label}`,
      negated: true,
      name: expression.label,
    }))
  ),
  ['isDisabled', 'name', 'trainee', 'negated'],
  ['asc', 'asc', 'desc', 'asc']
);

export interface Props {
  applicationId?: string;
  filterCount: number;
  isDisabled?: boolean;
  name?: string;
  onChange: (newValue: OnChangeValue<Option, true>, actionMeta: ActionMeta<Option>) => void;
  selectedFilterExpressions: {
    filterable_id: string;
    filterable_type: `${InterviewerFilterableType}`;
    negated: boolean;
  }[];
}

const InterviewerFilterInput = ({
  applicationId,
  filterCount,
  isDisabled = false,
  name,
  onChange,
  selectedFilterExpressions,
}: Props) => {
  const { account } = useSession();
  const { data: application } = useApplication(applicationId || '');
  const { data: eligibilities } = useEligibilities();
  const { data: tags } = useUserTags();
  const { data: users } = useUsers({ archived: true });

  const [initialFilterExpressions] = useState(selectedFilterExpressions);

  const selectedTrainingEligibility = useMemo(() => {
    return selectedFilterExpressions.find((exp) => exp.filterable_type === InterviewerFilterableType.Training)?.filterable_id;
  }, [selectedFilterExpressions]);

  const eligibilityOptions = useMemo<Expression[]>(() => (eligibilities || []).map((eligibility) => {
    // If we have more than one filter rule, we want to disable all training eligibilities.
    const hasAlternateRules = filterCount > 1 && eligibility.trainee;
    // When we have a training eligibility selected, we want to disable all other training eligibilities out except the
    // one that we've selected.
    const hasTrainingSelected = Boolean(selectedTrainingEligibility && eligibility.trainee && eligibility.id !== selectedTrainingEligibility);
    const isDisabled = hasAlternateRules || hasTrainingSelected;

    return {
      filterable_type: eligibility.trainee ? InterviewerFilterableType.Training : InterviewerFilterableType.Eligibility,
      filterable_id: eligibility.id,
      label: eligibility.id,
      value: `${eligibility.id}:${eligibility.trainee}`,
      training_program_id: eligibility.training_program_id,
      trainee: eligibility.trainee,
      isDisabled,
      tooltip: isDisabled ? (
        <Tooltip
          id={`${eligibility.id}:${eligibility.trainee}`}
          position="top"
          value={(
            (hasAlternateRules && 'You can\'t use a training eligibility when you have more than one alternate rule.') ||
            (hasTrainingSelected && 'You can only use one training eligibility per interviewer.')
          ) || ''} // This `|| ''` is only needed for typing. It shouldn't ever happen.
        />
      ) : undefined,
    };
  }), [eligibilities, filterCount, selectedTrainingEligibility]);
  const tagOptions = useMemo<Expression[]>(() => (tags || []).map((tag) => ({
    filterable_type: InterviewerFilterableType.Tag,
    filterable_id: tag.id,
    label: tag.id,
    value: tag.id,
  })), [tags]);
  const hiringTeamMembers = useMemo<Expression[]>(() => Object.entries(HiringTeamRole).map(([, role]) => ({
    filterable_type: InterviewerFilterableType.HiringTeam,
    filterable_id: role,
    label: startCase(role),
    value: startCase(role),
    isDisabled: Boolean(application && !application[`${role}_id`]), // disable it if there's an application and it doesn't have the hiring team id set
    tooltip: application && !application[`${role}_id`] ? (
      <Tooltip
        id={role}
        position="top"
        value={`There is no ${role.replace('_', ' ')} assigned to ${application.candidate.name} in ${capitalize(account?.ats_type)}.`}
      />
    ) : undefined,
  })), [application]);
  const userOptions = useMemo<Expression[]>(() => (users?.users || []).filter(({ directory_archived, user_archived }) => !user_archived && !directory_archived).map((user) => ({
    filterable_type: InterviewerFilterableType.User,
    filterable_id: user.id,
    label: user.name || user.email,
    value: user.id,
    isDisabled: !user.ats_id,
    tooltip: !user.ats_id ? (
      <Tooltip
        id={user.id}
        position="top"
        value={`${user.name || user.email} doesn't have an account in ${capitalize(account?.ats_type)}, so they can't be scheduled.`}
      />
    ) : undefined,
  })), [users]);

  const isSingleSelectedValue = useMemo(() => (
    selectedFilterExpressions && selectedFilterExpressions.length === 1 &&
    (selectedFilterExpressions[0].filterable_type === InterviewerFilterableType.User || selectedFilterExpressions[0].filterable_type === InterviewerFilterableType.HiringTeam) &&
    !selectedFilterExpressions[0].negated
  ), [selectedFilterExpressions]);

  const hasSelectedFilters = useMemo(() => selectedFilterExpressions.length > 0, [selectedFilterExpressions]);

  const options = useMemo(() => [{
    label: 'Eligibilities',
    options: isSingleSelectedValue ? [] : [
      ...createOptions([
        ...eligibilityOptions,
        ...(initialFilterExpressions || []).filter((expression) => expression.filterable_type === InterviewerFilterableType.Eligibility && !find(eligibilityOptions, ['filterable_id', expression.filterable_id])).map((eligibility) => ({ ...eligibility, label: eligibility.filterable_id, value: eligibility.filterable_id })),
      ]),
    ],
    type: InterviewerFilterableType.Eligibility,
  }, {
    label: 'Tags',
    options: isSingleSelectedValue ? [] : createOptions([
      ...tagOptions,
      ...(initialFilterExpressions || []).filter((expression) => expression.filterable_type === InterviewerFilterableType.Tag && !find(tagOptions, ['filterable_id', expression.filterable_id])).map((tag) => ({ ...tag, value: tag.filterable_id, label: tag.filterable_id })),
    ]),
    type: InterviewerFilterableType.Tag,
  }, {
    label: 'Hiring Team',
    options: createOptions(hiringTeamMembers).filter((option) => {
      if (isSingleSelectedValue) {
        const selectedFilterExpression = selectedFilterExpressions[0];
        return selectedFilterExpression.filterable_type === InterviewerFilterableType.HiringTeam && selectedFilterExpression.filterable_id === option.filterable_id;
      }
      if (hasSelectedFilters) {
        return option.negated;
      }
      return true;
    }),
    type: InterviewerFilterableType.HiringTeam,
  }, {
    label: 'Individuals',
    options: createOptions(userOptions).filter((option) => {
      if (isSingleSelectedValue) {
        const selectedFilterExpression = selectedFilterExpressions[0];
        return selectedFilterExpression.filterable_type === InterviewerFilterableType.User && selectedFilterExpression.filterable_id === option.filterable_id;
      }
      if (hasSelectedFilters) {
        return option.negated;
      }
      return true;
    }),
    type: InterviewerFilterableType.User,
  }], [eligibilityOptions, tagOptions, hiringTeamMembers, userOptions, selectedFilterExpressions, initialFilterExpressions, isSingleSelectedValue, hasSelectedFilters]);

  return (
    <SelectInput<string, Option, true>
      className="interviewer-filter-input"
      formatOptionLabel={(option) => (
        <div
          className="interviewer-filter-option-container"
          data-for={option.tooltip ? option.tooltip.props.id : undefined}
          data-tip={option.tooltip ? true : undefined}
        >
          <Tag
            hasTrainingProgram={Boolean(option.training_program_id)}
            isNegated={option.negated}
            isTrainee={option.trainee}
            type={option.filterable_type}
            value={option.filterable_id}
          />
          {option.tooltip}
        </div>
      )}
      isClearable
      isDisabled={isDisabled}
      isMulti
      isRequired
      maxMenuHeight={300}
      name={name}
      noOptionsMessage={() => isSingleSelectedValue ? <>You&apos;ve already selected a specific interviewer. To add more interviewers to the pool, click on <b>Add Alternate Rule</b> below.</> : undefined}
      onChange={onChange}
      options={options}
      placeholder="Eligibility, tag, hiring team role, or interviewer name"
      value={selectedFilterExpressions ?
        selectedFilterExpressions.map((expression) => {
          const optionsOfSameType = find(options, ['type', expression.filterable_type === InterviewerFilterableType.Training ? InterviewerFilterableType.Eligibility : expression.filterable_type])!.options;
          return find(optionsOfSameType, {
            filterable_id: expression.filterable_id,
            filterable_type: expression.filterable_type,
            negated: expression.negated,
          })!;
        }) :
        undefined
      }
    />
  );
};

export default InterviewerFilterInput;
