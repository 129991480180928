import type { AvailabilityTemplate } from './availability-templates';
import type { FeedbackForm } from './feedback-forms';
import type { InterviewTemplate } from './interview-templates';
import type { Office } from './offices';
import type { ScheduleTemplate } from './schedule-templates';

export enum JobStatus {
  Open = 'open',
  Closed = 'closed',
  Draft = 'draft',
}

export interface Job {
  id: string;
  ats_id: string;
  name: string;
  account_id: string;
  status: JobStatus;
  recruiter_id?: string;
  sourcer_id?: string;
  hiring_manager_id?: string;
  coordinator_id?: string;
  post_name?: string;
  schedulable_candidates_count?: number;
  offices: Office[];
  stages?: Stage[];
  opened_at?: string;
  favorite: boolean;
  created_at: string;
  updated_at: string;
  ats_created_at: string;
  ats_updated_at: string;
  archived: boolean;
}

export interface Stage {
  id: string;
  account_id: string;
  ats_id: string;
  job_id: string;
  job: Job;
  schedule_template_id?: string;
  schedule_template?: ScheduleTemplate;
  availability_template_id?: string;
  availability_template?: AvailabilityTemplate;
  name: string;
  position: number;
  stage_interviews?: StageInterview[];
  created_at: string;
  updated_at: string;
  ats_created_at: string;
  ats_updated_at: string;
  deleted: boolean;
}

export interface StageInterview {
  id: string;
  account_id: string;
  ats_id?: string;
  stage_id: string;
  stage: Stage;
  interview_template_id?: string;
  interview_template?: InterviewTemplate;
  feedback_form_id?: string;
  feedback_form?: FeedbackForm;
  name: string;
  position: number;
  ats_duration_minutes: number | null;
  ats_interviewer_ids: string[] | null;
  greenhouse_interview_kit_id?: string;
  ats_schedulable: boolean | null;
  created_at: string;
  updated_at: string;
  inline: boolean;
  deleted: boolean;
}
