import type { Stage } from 'types';
import type { AddInterviewAction, KeepInterviewAction, CopyInterviewActions } from './types';

export const getDefaultCopyInterviewActions = (stage: Stage, templateStage: Stage): CopyInterviewActions => {
  const matchedTemplateStageInterviewIds: string[] = [];

  const interviewsToKeep: KeepInterviewAction[] = (stage.stage_interviews || [])
  .map((stageInterview) => {
    const matchingTemplateStageInterview = templateStage.stage_interviews?.find(({ name }) => name === stageInterview.name);
    if (matchingTemplateStageInterview) {
      matchedTemplateStageInterviewIds.push(matchingTemplateStageInterview.id);
    }
    return {
      stageInterview,
      templateStageInterview: matchingTemplateStageInterview,
      isSelected: Boolean(matchingTemplateStageInterview) || Boolean(stageInterview.ats_id),
    };
  });

  const interviewsToAdd: AddInterviewAction[] = (templateStage.stage_interviews || [])
  .filter(({ ats_id, id }) => !Boolean(ats_id) && !matchedTemplateStageInterviewIds.includes(id))
  .map((templateStageInterview) => ({
    templateStageInterview,
    isSelected: true,
  }));

  return {
    keep: interviewsToKeep,
    add: interviewsToAdd,
  };
};
