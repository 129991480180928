import Moment from 'moment';
import { useMemo, useState } from 'react';

import ArchiveButton from '../../../library/inputs/ArchiveButton';
import FavoriteButton from '../../../library/inputs/FavoriteButton';
import Flash from '../../../library/utils/Flash';
import Label from '../../../library/utils/Label';
import Table from '../../../library/data-display/Table';
import { FavoriteResourceType } from '../../../../types';
import { formatMoment, TimeFormat } from '../../../../libraries/time';
import { useUpdateInterviewTemplate } from '../../../../hooks/queries/interview-templates';

import type { ChangeEvent } from 'react';
import type { InterviewTemplate } from '../../../../types';
import type { TableSchema } from '../../../library/data-display/Table';
import { correctPath } from 'libraries/gem';

const getRowLink = ({ id }: InterviewTemplate) => correctPath(`/app/interview-templates/${id}`);

interface Props {
  interviewTemplates: InterviewTemplate[];
  onPageNumberChange: (pageNumber: number) => void;
  onShowArchivedChange: (event: ChangeEvent<HTMLInputElement>) => void;
  pageNumber: number;
  showArchived: boolean;
  totalCount: number;
}

const InterviewTemplateListTable = ({
  interviewTemplates,
  onPageNumberChange,
  onShowArchivedChange,
  pageNumber,
  showArchived,
  totalCount,
}: Props) => {
  const [lastAction, setLastAction] = useState({
    id: '',
    isUndo: false,
    type: '',
  });

  const updateInterviewTemplateMutation = useUpdateInterviewTemplate();

  const handleArchive = (id: string, undo: boolean) => {
    setLastAction({
      id,
      type: 'archive',
      isUndo: undo,
    });
    updateInterviewTemplateMutation.reset();
    updateInterviewTemplateMutation.mutate({ id, payload: { archived: true } });
  };

  const handleUnarchive = (id: string, undo: boolean) => {
    setLastAction({
      id,
      type: 'unarchive',
      isUndo: undo,
    });
    updateInterviewTemplateMutation.reset();
    updateInterviewTemplateMutation.mutate({ id, payload: { archived: false } });
  };

  const handleUndo = () => {
    if (lastAction.type === 'archive') {
      handleUnarchive(lastAction.id, true);
    }
    if (lastAction.type === 'unarchive') {
      handleArchive(lastAction.id, true);
    }
  };

  const columns = useMemo<TableSchema<InterviewTemplate>>(() => [{
    header: '',
    displayValue: ({ favorite, id }) => (
      <FavoriteButton favorite={favorite} resourceId={id} resourceType={FavoriteResourceType.InterviewTemplate} />
    ),
    isClickable: true,
  }, {
    header: 'Name',
    displayValue: ({ archived, name }) => (
      <div className="interview-template-list-table-name">
        {name}
        {archived && <Label color="gray">Archived</Label>}
      </div>
    ),
    hasLinkStyleOnHover: true,
  }, {
    header: 'Linked Active Interviews',
    displayValue: ({ linked_interviews }) => linked_interviews || 0,
  }, {
    header: 'Date Created',
    displayValue: ({ created_at }) => <span className="date">{formatMoment(Moment(created_at), TimeFormat.LongMonthDayYear)}</span>,
  }, {
    header: 'Last Updated',
    displayValue: ({ updated_at }) => <span className="date">{formatMoment(Moment(updated_at), TimeFormat.LongMonthDayYear)}</span>,
  }, {
    header: 'Actions',
    isClickable: true,
    displayValue: ({ archived, id }) => (
      <div className="action-buttons-container">
        <ArchiveButton
          id={id}
          isArchived={archived}
          onArchive={() => handleArchive(id, false)}
          onUnarchive={() => handleUnarchive(id, false)}
        />
        {/* <DeleteButton id={id} /> */}
      </div>
    ),
  }], []);

  return (
    <div className="interview-template-list-table-container">
      <Flash
        isDismissible
        message={(
          <span>
            Interview template {lastAction.type}d.&nbsp;
            {!lastAction.isUndo && <a href="#" onClick={handleUndo}>Undo?</a>}
          </span>
        )}
        showFlash={lastAction.id ? updateInterviewTemplateMutation.isSuccess : false}
        type="success"
      />
      <Flash
        isDismissible
        message={updateInterviewTemplateMutation.error?.message}
        onDismiss={updateInterviewTemplateMutation.reset}
        showFlash={updateInterviewTemplateMutation.isError}
        type="danger"
      />
      <Table
        data={interviewTemplates}
        getRowLink={getRowLink}
        isPaginated
        layout="vertical"
        onPageNumberChange={onPageNumberChange}
        onShowArchivedChange={onShowArchivedChange}
        pageNumber={pageNumber}
        schema={columns}
        showArchived={showArchived}
        totalCount={totalCount}
      />
    </div>
  );
};

export default InterviewTemplateListTable;
