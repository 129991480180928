import classnames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faStar as faStarOutline } from '@fortawesome/free-regular-svg-icons';
import { faStar } from '@fortawesome/free-solid-svg-icons';
import { useCallback, useId } from 'react';

import Tooltip from '../../utils/Tooltip';
import { StyledContainer } from './styles';
import { useCreateFavorite, useDeleteFavorite } from '../../../../hooks/queries/favorites';

import type { FavoriteResourceType } from '../../../../types';

interface Props {
  className?: string;
  favorite: boolean;
  isDisabled?: boolean;
  resourceId: string;
  resourceType: FavoriteResourceType;
}

const FavoriteButton = ({ className, favorite, isDisabled = false, resourceId, resourceType }: Props) => {
  const id = useId();

  const createMutation = useCreateFavorite();
  const deleteMutation = useDeleteFavorite();

  const handleClick = useCallback(() => {
    if (isDisabled) {
      // Don't do anything if this is disabled.
      return;
    }

    if (createMutation.isLoading || deleteMutation.isLoading) {
      // Don't do anything if it's already making a request.
      return;
    }

    if (favorite) {
      deleteMutation.mutate({ id: resourceId, type: resourceType });
    } else {
      createMutation.mutate({ payload: { resource_id: resourceId, resource_type: resourceType } });
    }
  }, [favorite, createMutation, deleteMutation]);

  return (
    <StyledContainer
      className={classnames(['favorite-button', className, isDisabled && 'disabled']) }
      data-for={isDisabled ? undefined : id}
      data-tip={!isDisabled}
      onClick={handleClick}
    >
      <FontAwesomeIcon
        className={classnames([favorite && 'filled'])}
        icon={favorite ? faStar : faStarOutline}
      />
      {!isDisabled && (
        <Tooltip
          id={id}
          value={favorite ? 'Remove from favorites' : 'Add to favorites'}
        />
      )}
    </StyledContainer>
  );
};

export default FavoriteButton;
