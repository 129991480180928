import CheckboxInput from './CheckboxInput';

import type { ChangeEventHandler, ReactNode } from 'react';
import classnames from 'classnames';

interface Props {
  children?: ReactNode;
  className?: string;
  helperText?: ReactNode;
  isChecked: boolean;
  isDisabled?: boolean;
  label?: ReactNode;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  tooltip?: JSX.Element;
}

const ExpandableCheckboxInput = ({
  children,
  className,
  helperText,
  isChecked,
  isDisabled = false,
  label,
  onChange,
  tooltip,
}: Props) => {
  return (
    <div className={classnames(['expandable-checkbox-input', className])}>
      <CheckboxInput
        className={isChecked && children ? 'expandable-checkbox-input-expanded' : undefined}
        helperText={helperText}
        isChecked={isChecked}
        isDisabled={isDisabled}
        label={label}
        onChange={onChange}
        tooltip={tooltip}
      />
      {isChecked && children && (
        <div className="expandable-checkbox-input-contents-expanded">
          {children}
        </div>
      )}
    </div>
  );
};

export default ExpandableCheckboxInput;
