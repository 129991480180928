import ReactTooltip from 'react-tooltip';

import type { Place } from 'react-tooltip';
import type { ReactNode } from 'react';

interface Props {
  event?: string;
  eventOff?: string;
  helperText?: ReactNode;
  id: string;
  position?: Place;
  value: ReactNode;
}

const Tooltip = ({ event, eventOff, helperText, id, position = 'top', value }: Props) =>
  <ReactTooltip
    className="tooltip"
    clickable={false}
    effect="solid"
    event={event}
    eventOff={eventOff}
    id={id}
    place={position}
  >
    <span className="tooltip-value">
      {value}
    </span>
    {helperText && (
      <span className="tooltip-helper-text">
        {helperText}
      </span>
    )}
  </ReactTooltip>
;

export default Tooltip;
