import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTimes } from '@fortawesome/free-solid-svg-icons';
import { isEmpty, pullAt } from 'lodash';
import { useEffect, useMemo } from 'react';
import { useMediaQuery } from 'react-responsive';

import Button from './Button';
import CheckboxInput from './CheckboxInput';
import InterviewerFiltersBuilder from './InterviewerFiltersBuilder';
import OutboundLink from '../navigation/OutboundLink';
import ResolvedFiltersAvatarGroup from '../data-display/ResolvedFiltersAvatarGroup';
import TextInput from './TextInput';

import type { ChangeEvent } from 'react';
import type { CreateInterviewTemplatePayload } from '../../../hooks/queries/interview-templates';
import type { Filter } from './InterviewerFiltersBuilder';

const helpLink = 'https://support.gem.com/hc/en-us/articles/23487548771735-How-do-I-create-an-interviewer-pool';

// TODO: Introduce these features back when desired
const SHOW_DESCRIPTION = false;

export interface InterviewerTemplate {
  description?: string;
  include_past_interviewers: boolean;
  interviewer_filters: Filter[];
  optional: boolean;
}

const createEmptyInterviewerTemplate = (): InterviewerTemplate => ({
  description: '',
  include_past_interviewers: true,
  interviewer_filters: [{
    interviewer_filter_expressions: [],
  }],
  optional: false,
});

interface Props {
  applicationId?: string;
  defaultInterviewerTemplates?: InterviewerTemplate[];
  interviewerTemplates?: InterviewerTemplate[];
  isDisabled?: boolean;
  isAlwaysSingleInterviewer?: boolean;
  scheduleId?: string;
  setInterviewerTemplates: (templates: CreateInterviewTemplatePayload['interviewer_templates']) => void;
}

const InterviewerTemplatesForm = ({
  applicationId,
  defaultInterviewerTemplates,
  interviewerTemplates,
  isDisabled = false,
  isAlwaysSingleInterviewer,
  scheduleId,
  setInterviewerTemplates,
}: Props) => {
  const isSmallScreen = useMediaQuery({ query: '(max-width: 1050px)' });

  // Even though interviewerTemplates is optional, because of this useEffect,
  // interviewerTemplates should always be set. It might be empty, but it should
  // always be an array.
  useEffect(() => {
    if (!interviewerTemplates) {
      setInterviewerTemplates(defaultInterviewerTemplates || [createEmptyInterviewerTemplate()]);
    }
    // For the `single-required` variant of the input, there is expected to be exactly one
    // template at all times. If this isn't the case, create an empty interviewer template.
    if (isAlwaysSingleInterviewer) {
      if (
        isEmpty(interviewerTemplates)
        || (interviewerTemplates !== undefined && isEmpty(interviewerTemplates[0].interviewer_filters))
      ) {
        setInterviewerTemplates([createEmptyInterviewerTemplate()]);
      }
    }
  }, [Boolean(interviewerTemplates)]);

  const maxPoolDisplayLength = useMemo(() => {
    if (isSmallScreen) {
      return 50;
    }
    return isDisabled ? 16 : 40;
  }, [isDisabled, isSmallScreen]);

  const handleAddInterviewerTemplate = () => {
    setInterviewerTemplates([
      ...(interviewerTemplates || []),
      createEmptyInterviewerTemplate(),
    ]);
  };

  const handleRemoveInterviewerTemplate = (i: number) => {
    const interviewerTemplatesCopy = interviewerTemplates!.slice();
    pullAt(interviewerTemplatesCopy, [i]);
    setInterviewerTemplates(interviewerTemplatesCopy);
  };

  const handleDescriptionChange = (e: ChangeEvent<HTMLInputElement>) => {
    const index = parseInt(e.target.name.replace('interviewer-template-description-', ''), 10);
    setInterviewerTemplates(interviewerTemplates!.map((interviewerTemplate, i) => ({
      ...interviewerTemplate,
      description: i === index ? e.target.value : interviewerTemplate.description,
    })));
  };

  const handleIsOptionalChange = (e: ChangeEvent<HTMLInputElement>) => {
    const index = parseInt(e.target.name.replace('interviewer-template-optional-', ''), 10);
    setInterviewerTemplates(interviewerTemplates!.map((interviewerTemplate, i) => ({
      ...interviewerTemplate,
      optional: i === index ? e.target.checked : interviewerTemplate.optional,
    })));
  };

  const handleIsIncludePastInterviewersChange = (e: ChangeEvent<HTMLInputElement>) => {
    const index = parseInt(e.target.name.replace('interviewer-template-include-past-interviewers-', ''), 10);
    setInterviewerTemplates(interviewerTemplates!.map((interviewerTemplate, i) => ({
      ...interviewerTemplate,
      include_past_interviewers: i === index ? e.target.checked : interviewerTemplate.include_past_interviewers,
    })));
  };

  const handleFiltersChange = (interviewerFilters: Filter[], name?: string) => {
    // We always pass in a name, so name should always be set.
    const index = parseInt(name!.replace('interviewer-template-filters-', ''), 10);
    setInterviewerTemplates(interviewerTemplates!.map((interviewerTemplate, i) => ({
      ...interviewerTemplate,
      interviewer_filters: i === index ? interviewerFilters : interviewerTemplate.interviewer_filters,
    })));
  };

  return (
    <div className="interviewer-templates">
      {interviewerTemplates && interviewerTemplates.map((interviewerTemplate, i) => (
        <div className="create-interviewer-template" key={`interviewer-template-${i}`}>
          <div className="inputs-container">
            {!isDisabled && !isAlwaysSingleInterviewer &&
              <Button
                className="btn-delete btn-delete-interviewer"
                color="gray"
                iconRight={<FontAwesomeIcon icon={faTimes} />}
                onClick={() => handleRemoveInterviewerTemplate(i)}
                size="large"
                value="Remove interviewer"
              />
            }
            {SHOW_DESCRIPTION &&
              <TextInput
                className="create-interviewer-template-description"
                isDisabled={isDisabled}
                label="Description"
                name={`interviewer-template-description-${i}`}
                onChange={handleDescriptionChange}
                placeholder={`Interviewer ${i + 1}`}
                value={interviewerTemplate.description}
              />
            }
            {!isAlwaysSingleInterviewer && (
              <CheckboxInput
                className="create-interviewer-template-optional"
                isChecked={interviewerTemplate.optional}
                isDisabled={isDisabled}
                label="This interviewer is optional."
                name={`interviewer-template-optional-${i}`}
                onChange={handleIsOptionalChange}
              />
            )
            }
            <InterviewerFiltersBuilder
              applicationId={applicationId}
              className="create-interviewer-template-filters"
              filters={interviewerTemplate.interviewer_filters || []}
              helperText={isDisabled ? null : <span>In each rule, you can combine eligibilities, tags, and exceptions (<b><u>or</u></b> select a specific hiring team member or individual). We will find potential interviewers who match any of these rules. <OutboundLink href={helpLink} label="Interviewer Filters Builder Helper Text">Learn more.</OutboundLink></span>}
              isDisabled={isDisabled}
              label="Criteria"
              name={`interviewer-template-filters-${i}`}
              onChange={handleFiltersChange}
            />
          </div>
          <div className="pool">
            <div className="avatars-container">
              <ResolvedFiltersAvatarGroup
                applicationId={applicationId}
                filters={interviewerTemplate.interviewer_filters || []}
                includePastInterviewers={interviewerTemplate.include_past_interviewers}
                maxDisplayLength={maxPoolDisplayLength}
                scheduleId={scheduleId}
              />
            </div>
            <CheckboxInput
              className="create-interviewer-template-include-past-interviewers"
              isChecked={interviewerTemplate.include_past_interviewers}
              isDisabled={isDisabled}
              label="Include past interviewers for candidate."
              name={`interviewer-template-include-past-interviewers-${i}`}
              onChange={handleIsIncludePastInterviewersChange}
            />
          </div>
        </div>
      ))}
      {!isDisabled && !isAlwaysSingleInterviewer &&
        <Button
          color="no-outline"
          iconLeft={<FontAwesomeIcon icon={faPlus} />}
          onClick={handleAddInterviewerTemplate}
          size="small"
          value="Add Interviewer"
        />
      }
      {isDisabled && isEmpty(interviewerTemplates) &&
        <div className="empty">No interviewers</div>
      }
    </div>
  );
};

export default InterviewerTemplatesForm;
