import classNames from 'classnames';
import { useMemo } from 'react';

import { getAccessibleContrastColors, getAccessibleTextColor } from '../../../libraries/color';

import type { ReactNode, MouseEventHandler, TouchEventHandler } from 'react';

export enum ButtonColor {
  GemBlue = 'gem-blue',
  GemOutline = 'gem-outline',
  BlueOutline = 'blue-outline',
  Gray = 'gray',
  Green = 'green',
  NoOutline = 'no-outline',
  Orange = 'orange',
}

export enum ButtonIconPosition {
  Left = 'left',
  Right = 'right',
}

export enum ButtonSize {
  Large = 'large',
  Small = 'small',
}

export enum ButtonType {
  Button = 'button',
  Reset = 'reset',
  Submit = 'submit',
}

export interface Props {
  brandColor?: string;
  className?: string;
  color: `${ButtonColor}`;
  id?: string;
  isDisabled?: boolean;
  iconLeft?: JSX.Element;
  iconRight?: JSX.Element;
  onClick?: MouseEventHandler<HTMLButtonElement>;
  onTouchEnd?: TouchEventHandler<HTMLButtonElement>;
  role?: string;
  size?: `${ButtonSize}`;
  style?: object;
  tooltip?: JSX.Element;
  type?: `${ButtonType}`;
  value?: ReactNode;
}

const Button = ({
  brandColor,
  className,
  color,
  iconLeft,
  iconRight,
  id,
  isDisabled = false,
  onClick,
  onTouchEnd,
  role = 'button',
  size = ButtonSize.Small,
  style,
  tooltip,
  type = ButtonType.Button,
  value,
}: Props) => {
  const customStyles = useMemo(() => {
    if (!brandColor) {
      return style;
    }
    switch (color) {
      case 'blue-outline':
        return {
          border: `1px solid ${brandColor}`,
          color: getAccessibleContrastColors(brandColor).text,
          ...(style || {}),
        };
      case 'no-outline':
        return {
          color: getAccessibleTextColor('white', [brandColor, '#666666']),
          ...(style || {}),
        };
      default:
        return {
          background: brandColor,
          border: `1px solid ${brandColor}`,
          color: getAccessibleTextColor(brandColor, ['white', '#2a2a2a']),
          ...(style || {}),
        };
    }
  }, [brandColor, color, style]);

  const contents = (
    <>
      {iconLeft && <span className="icon-left">{iconLeft}</span>}
      {value}
      {iconRight && <span className="icon-right">{iconRight}</span>}
    </>
  );

  return (
    <>
      <button
        className={classNames([
          'btn',
          `btn-${size}`,
          `btn-${color}`,
          className,
          customStyles && 'btn-custom-styled',
          isDisabled && 'btn-disabled',
        ])}
        data-cy="button"
        disabled={isDisabled}
        id={id || undefined}
        onClick={isDisabled ? undefined : onClick as any}
        onTouchEnd={isDisabled ? undefined : onTouchEnd as any}
        role={role}
        style={customStyles}
        type={type}
      >
        {tooltip ? (
          // This inner div is so that tooltips still work when disabled:
          // https://github.com/ReactTooltip/react-tooltip/issues/304#issuecomment-876501080
          <div
            data-for={tooltip ? tooltip.props.id : undefined}
            data-tip={tooltip ? true : undefined}
          >
            {contents}
          </div>
        ) : contents}
      </button>
      {tooltip}
    </>
  );
};

export default Button;
