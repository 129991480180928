import Moment from 'moment-timezone';

import Config from '../../config';

// @ts-ignore
if (!window.Cypress) {
  analytics.load(Config.SEGMENT_WRITE_KEY);
}

export const logPageView = () => {
  analytics.page({
    url: window.location.href,
    path: window.location.pathname + window.location.hash,
  }, {}, () => {
    // Strip any UTM parameters after tracking the pageview to prevent link
    // sharing from skewing data and to have a more clean URL.
    // Reference: https://davidwalsh.name/remove-utm-parameters-urls
    const hasUTM = window.location.search.indexOf('utm_') !== -1;
    if (hasUTM) {
      history.replaceState({}, '', window.location.toString().replace(/(\&|\?)utm([_a-zA-Z0-9=]+)/g, ''));
    }
  });
};

export interface TimeSlot {
  start_time: Date;
  end_time: Date;
}

/**
 * This function is a helper function to calculate the usable amount of availability passed in so that we can use it as
 * an analytics event property.
 */
export const hoursOfAvailability = (slots: TimeSlot[]): number => {
  const now = Moment();
  let totalHours = 0;
  for (const slot of slots) {
    const end = Moment(slot.end_time);
    if (end.isBefore(now)) {
      // This availability is completely in the past, so we skip over it.
      continue;
    }

    let start = Moment(slot.start_time);
    if (start.isBefore(now)) {
      // This availability starts in the past but ends in the future, so we're in the middle of it right now. So we
      // adjust the start time to be now, since that's effectively what the backend does.
      start = Moment();
    }

    const diff = end.diff(start, 'hours', true);
    totalHours += diff;
  }
  return Math.round(totalHours);
};
