import classnames from 'classnames';
import { Editor, Element, Transforms } from 'slate';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactEditor, useSlate } from 'slate-react';
import { faImage } from '@fortawesome/free-solid-svg-icons';
import { uniqueId } from 'lodash';
import { useMemo, useState } from 'react';

import Button from '../../Button';
import DimensionsInput from '../../DimensionsInput';
import Flash from '../../../utils/Flash';
import Popover from '../../../utils/Popover';
import TextInput from '../../TextInput';
import { ElementType } from '../../../../../types';
import { dimensionsForUrl, getClosestElementOfType, insertImage, isElementTypeActive } from '../helper';
import { isImage } from '../plugins/images';

import type { ChangeEvent, MouseEvent } from 'react';
import type { CustomSelection } from '../../../../../types';

interface Props {
  isDisabled: boolean;
}

const ImageToolbarOption = ({ isDisabled }: Props) => {
  const editor = useSlate();

  const id = useMemo(() => uniqueId('editor-input-image-toolbar-option-'), []);

  // We need to keep track of the editor selection when we open the popover
  // because Slate sets the selection to null on blur. So to know where to make
  // our changes, we need to save the selection. There are a few other solutions
  // for this here: https://github.com/ianstormtaylor/slate/issues/3412.
  const [editorSelection, setEditorSelection] = useState<CustomSelection | null>(null);
  const [isOpen, setIsOpen] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [src, setSrc] = useState('');
  const [alt, setAlt] = useState('');
  const [height, setHeight] = useState('');
  const [width, setWidth] = useState('');
  const [aspectRatio, setAspectRatio] = useState<number | undefined>(undefined);

  // This needs to be mouse down instead of click because otherwise, the editor
  // will lose focus on click and the button's effect won't happen.
  const handleMouseDown = (event: MouseEvent<HTMLDivElement>) => {
    if (isDisabled) {
      return;
    }

    event.preventDefault();

    setError(null);
    setEditorSelection(editor.selection);

    if (isElementTypeActive(editor, ElementType.Image)) {
      const [imageNode] = getClosestElementOfType(editor, isImage);
      if (imageNode) {
        setSrc(imageNode.src);
        setAlt(imageNode.alt || '');
        setHeight(imageNode.height || '');
        setWidth(imageNode.width || '');
        const h = parseInt(imageNode.height || '', 10);
        const w = parseInt(imageNode.width || '', 10);
        setAspectRatio(!isNaN(h) && h !== 0 ? w / h : undefined);
        return;
      }
    }

    // We don't have any image active.
    setSrc('');
    setAlt('');
    setHeight('');
    setWidth('');
    setAspectRatio(undefined);
  };

  const handleSrcChange = (e: ChangeEvent<HTMLInputElement>) => setSrc(e.target.value);
  const handleAltChange = (e: ChangeEvent<HTMLInputElement>) => setAlt(e.target.value);

  const handleSrcBlur = async () => {
    if (!src) {
      return;
    }

    setError(null);
    const dimensions = await dimensionsForUrl(src);

    if (dimensions.error) {
      setError('That URL doesn\'t point to a valid image. Make sure you\'ve copied it correctly.');
    } else {
      setHeight(`${dimensions.height}`);
      setWidth(`${dimensions.width}`);
      setAspectRatio(dimensions.height !== 0 ? dimensions.width / dimensions.height : undefined);
    }
  };

  const handleAddImage = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
    event.stopPropagation();

    editor.selection = editor.selection || editorSelection || {
      anchor: { path: [0], offset: 0 },
      focus: { path: [0], offset: 0 },
    };

    if (isElementTypeActive(editor, ElementType.Image)) {
      // Since the selection was on an existing image, we're updating an image
      // instead of adding a new one.

      // Update the selection to make sure it is only on the image.
      const [, path] = getClosestElementOfType(editor, isImage);
      const anchor = {
        path: [...path!],
        // For voids, there's no characters, so we always use offset 0.
        offset: 0,
      };
      // The anchor and the focus should be the same.
      const focus = {
        path: [...path!],
        offset: 0,
      };
      editor.selection = { anchor, focus };

      // Update the properties.
      Transforms.setNodes(editor, {
        alt,
        height,
        src,
        width,
      }, {
        match: (node) => !Editor.isEditor(node) && Element.isElement(node) && node.type === 'image',
      });
    } else {
      // Add new image.
      insertImage(editor, {
        alt,
        height,
        src,
        width,
      });
    }

    setEditorSelection(null);
    setIsOpen(false);
    ReactEditor.focus(editor);
  };

  const handleRemoveImage = () => {
    editor.selection = editor.selection || editorSelection;

    if (isElementTypeActive(editor, ElementType.Image)) {
      Transforms.removeNodes(editor, {
        match: (node) => !Editor.isEditor(node) && Element.isElement(node) && node.type === ElementType.Image,
      });
    }

    setEditorSelection(null);
    setIsOpen(false);
    ReactEditor.focus(editor);
  };

  return (
    <>
      <div
        className={classnames([
          'editor-toolbar-option',
          isElementTypeActive(editor, ElementType.Image) && 'editor-toolbar-option-active',
        ])}
        id={id}
        onMouseDown={handleMouseDown}
        title="Image"
      >
        <FontAwesomeIcon icon={faImage} />
      </div>
      {!isDisabled && (
        <Popover
          className="editor-toolbar-option-image-popover"
          isOpen={isOpen}
          position="bottom"
          setIsOpen={setIsOpen}
          target={id}
        >
          <Flash
            message={error}
            showFlash={Boolean(error)}
            type="danger"
          />
          {/*We're explicitly not listening for form submit right now because
          there's a weird case that happens if you update one of the dimensions
          of the image and then press enter to add the image. This doesn't give
          enough time for the other dimension to update to maintain the aspect
          ratio, so you end up with a very skewed image. This doesn't happen if
          you click on the button.*/}
          <form>
            <TextInput
              helperText="To ensure your image shows up for the candidate, we recommend using PNGs, JPEGs, and GIFs only."
              isRequired
              label="URL to image"
              onBlur={handleSrcBlur}
              onChange={handleSrcChange}
              value={src}
            />
            <TextInput
              helperText="This will show up if the candidate has email images disabled."
              isRequired
              label="Description of image"
              onChange={handleAltChange}
              value={alt}
            />
            <DimensionsInput
              aspectRatio={aspectRatio}
              constrainProportions
              heightValue={height}
              helperText="We keep the proportions constrained so your image doesn't get skewed."
              isRequired
              label="Dimensions (px)"
              setHeight={setHeight}
              setWidth={setWidth}
              widthValue={width}
            />
            <div className="editor-toolbar-option-image-buttons">
              {isElementTypeActive({ ...editor, selection: editor.selection || editorSelection }, ElementType.Image) && (
                <Button
                  color="no-outline"
                  onClick={handleRemoveImage}
                  size="small"
                  type="reset"
                  value="Remove image"
                />
              )}
              <Button
                color="gem-blue"
                onClick={handleAddImage}
                size="small"
                value={isElementTypeActive({ ...editor, selection: editor.selection || editorSelection }, ElementType.Image) ? 'Update image' : 'Add image'}
              />
            </div>
          </form>
        </Popover>
      )}
    </>
  );
};

export default ImageToolbarOption;
